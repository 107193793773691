import React from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { ReactComponent as FocusSvg } from 'assets/icons/focus.svg';
import { ReactComponent as UnFocusSvg } from 'assets/icons/unfocus.svg';

const TrackingPositionBox = (props) => {
  const { focus, setFocus } = props;

  return (
    <div
      style={{
        background: `transparent`,
        padding: 5,
        position: 'absolute',
        right: '10px',
        bottom: '120px',
      }}
    >
      <Button
        shape='circle'
        size='large'
        onClick={() => {
          setFocus(true);
        }}
        icon={
          <Icon
            component={focus === true ? FocusSvg : UnFocusSvg}
            style={{ fontSize: '20px' }}
          />
        }
      />
    </div>
  );
};

export default TrackingPositionBox;
