import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, message, Select, InputNumber, Switch } from 'antd';
import {
  useUpdateTrailerLaneMutation,
  useCreateTrailerLaneMutation,
} from 'reducers/masterData/trailer/api';

import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import { useFindAllLocationMastersByYardQuery } from 'reducers/masterData/locationMaster/api';
import { Sorter } from 'utils/sorter';
import { getCurrentUser } from 'reducers/user';
const { Option } = Select;

const TrailerLaneFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const [locationOptions, setLocationOptions] = useState([]);
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const {
    data: dataLocationMaster,
    error: errorLocationMaster,
    isFetching: isLocationMasterFetching,
    refetch: refetchLocationMaster,
  } = useFindAllLocationMastersByYardQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataLocationMaster) {
      let mapData = dataLocationMaster.map((d) => ({
        id: d.id,
        label: d.short_name,
        value: d.name,
      }));
      setLocationOptions(Sorter.DEFAULTLIST(mapData, 'label'));
      // setDefaulttLocationOptions(Sorter.DEFAULTLIST(mapData, 'value'));
      // setLocationToOptions(Sorter.DEFAULTLIST(mapData, 'value'));
    }
  }, [dataLocationMaster]);

  useEffect(() => {
    if (errorLocationMaster) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load location',
        okText: 'Retry',
        onOk: refetchLocationMaster,
        cancelText: 'Cancel',
      });
    }
  }, [errorLocationMaster, refetchLocationMaster]);

  const [
    createTrailerLane,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateTrailerLaneMutation();

  const [
    updateTrailerLane,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateTrailerLaneMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let locationYard = locationOptions.find(
      (c) => c.label === validData.yard
    );
    let postData = {
      id: formData && formData.id,
      ...validData,
      yard: locationYard && locationYard.id,
    };

    if (isCreate) {
      createTrailerLane(postData);
    } else {
      updateTrailerLane(postData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Yard'
        name='yard'
        initialValue={
          formData &&
          formData.yard &&
          formData.yard.short_name
        }
        rules={[
          {
            required: true,
            message: 'Please input yard',
          },
        ]}
      >
        {/* <Input placeholder={roureCodelist && roureCodelist.next_route_code} */}

        <Select
          showSearch
          placeholder={'Select a yard'}
          optionFilterProp='children'
          loading={isLocationMasterFetching}
          // disabled={form.getFieldValue('location_from')}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
          allowClear
          onClear={() => {
            setLocationOptions(locationOptions);
          }}
        >
          {locationOptions &&
            locationOptions.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Number Lane'
        name='number_lane'
        initialValue={formData && formData.number_lane}
        rules={[
          {
            required: true,
            message: 'Please input number lane',
          },
        ]}
      >
        <InputNumber
          min={0}
          disabled={readonly}
          placeholder={'Number Lane'}
        />
      </Form.Item>
      <Form.Item
        label='Lane Status '
        name='is_active'
        initialValue={formData && formData.is_active}
        valuePropName='checked'
      >
        <Switch disabled={readonly} checkedChildren="Full"  unCheckedChildren="Vacancy" />
      </Form.Item>
    </Form>
  );
};

export default TrailerLaneFormComponent;
