import React, { useState, useEffect, useCallback } from "react"
import AntTable from "common_components/AntTable"
import TableToolbar from "./Toolbar"
import { useDispatch } from "react-redux"
import useWindowSize from "hooks/windowSize"
import {
  downloadReportApi,
  useConfirmDownloadReportMutation,
} from "reducers/downloadReport/api"
import styled from "styled-components"
import { message, Button } from "antd"
import { CloudDownloadOutlined } from "@ant-design/icons"
import { dateFormatterYear } from "common_components/AntTable/tableUtils"
import { useErrorHandler } from "common_components/ErrorContext"

const CallingSheetTableComponent = (props) => {
  const { setErrorData } = useErrorHandler()

  const { setTotalRows } = props
  const [isLoading, setIsLoading] = useState(false)
  const [showFilter, setShowFilter] = useState(true)

  const [dataSource, setDataSource] = useState([])
  const [tableHeight, setTableHeight] = useState(0)
  const dispatch = useDispatch()
  const { height } = useWindowSize()
  const loadItems = useCallback(
    async (value) => {
      let result = await dispatch(
        downloadReportApi.endpoints.findAllDownloadReportByPagination.initiate(
          value,
          {
            forceRefetch: true,
          }
        )
      )
      // console.log("result = ", result)
      if (result && result.error) {
        message.error(result.error.data.errorList[0].errorDetail)
      } else {
        if (result.data) {
          let arrData = result.data.map((d, i) => ({
            ...d,
            id: i + 1,
          }))
          setDataSource(arrData)
          setTotalRows(arrData.length)
        }
      }
    },
    [dispatch, setTotalRows]
  )

  useEffect(() => {
    loadItems({})
  }, [loadItems])

  useEffect(() => {
    if (height) {
      let h = height - 360
      if (!showFilter) {
        h = h + 100
      }
      setTableHeight(h)
    }
  }, [height, showFilter])

  const [
    apiDownload,
    {
      data: onDownload,
      error: errorOnDownload,
      isLoading: isLoadingOnDownload,
    },
  ] = useConfirmDownloadReportMutation()

  useEffect(() => {
    if (onDownload) {
      message.success(onDownload?.message)
    }
  }, [onDownload])

  useEffect(() => {
    if (errorOnDownload) {
      setErrorData(errorOnDownload)
    }
  }, [errorOnDownload, setErrorData])

  const onQuery = async (value) => {
    try {
      setIsLoading(true)
      if (value) {
        await apiDownload(value)
        loadItems(value)
        // let result = await dispatch(
        //   downloadReportApi.endpoints.findAllDownloadReportByPagination.initiate(
        //     value,
        //     {
        //       forceRefetch: true,
        //     }
        //   )
        // )

        // if (result && result.error) {
        //   message.error(result.error.data.errorList[0].errorDetail)
        // } else {
        //   if (result.data) {
        //     let arrData = result.data.map((d, i) => ({
        //       ...d,
        //       id: i + 1,
        //     }))
        //     setDataSource(arrData)
        //     setTotalRows(arrData.length)
        //   }
        // }
      }
    } catch (err) {
      console.log("err", err)
    } finally {
      setIsLoading(false)
    }
  }

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: "Report",
        dataIndex: "report",
        align: "center",
        width: 100,
        movable: true,
      },
      {
        title: "Start",
        dataIndex: "start",
        align: "center",
        width: 100,
        movable: true,
        render: (text, record) => {
          return dateFormatterYear({ value: text })
        },
      },
      {
        title: "End",
        dataIndex: "end",
        align: "center",
        width: 100,
        render: (text, record) => {
          return dateFormatterYear({ value: text })
        },
        movable: true,
      },
      {
        title: "Company",
        dataIndex: "company",
        align: "center",
        width: 100,
        movable: true,
        render: (text, record) => {
          return text ? text.join(",  ") : null
        },
      },
      {
        title: "Requested",
        dataIndex: "requested_at",
        align: "center",
        width: 100,
        movable: true,
        render: (text, record) => {
          return dateFormatterYear({ value: text })
        },
      },

      {
        title: "Expired",
        dataIndex: "expired_at",
        width: 100,
        align: "center",
        movable: true,
        render: (text, record) => {
          return dateFormatterYear({ value: text })
        },
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (text, record) => {
          if (text) {
            return text
          } else {
            return "-"
          }
        },
        width: 100,
        align: "center",
        movable: true,
      },
      {
        title: "Url",
        dataIndex: "url",
        render: (text, record) => {
          // if (text) {
          return (
            <a href={text}>
              {" "}
              <Button
                type="primary"
                icon={<CloudDownloadOutlined />}
                size={"small"}
                disabled={record.status !== "Ready"}
              >
                Download
              </Button>
            </a>
          )
          // }
        },
        width: 100,
        align: "center",
        movable: true,
      },
    ]

    return cols
  })

  return (
    <Container>
      <TableToolbar
        onQuery={onQuery}
        columns={columns}
        isLoading={isLoading}
        setColumns={setColumns}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        isLoadingOnDownload={isLoadingOnDownload}
      />
      <StyledAntTable
        rowKey="id"
        tableId={"download_report"}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
      />
    </Container>
  )
}

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body {
      height: ${(props) => {
        return props.height + "px"
      }};
    }
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: auto;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`
const Container = styled.div`
  padding: 0 10px;
`
export default CallingSheetTableComponent
