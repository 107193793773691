import React from "react";
import { useDispatch } from "react-redux";

import styled from "styled-components";
import {
  Row,
  Col,
  Button,
  Select,
  Collapse,
} from "antd";
import "./index.css";

const ToolbarSummaryYardComponent = (props) => {
  const {
    //filter
    filterWorkingDate,
    setFilterWorkingDate,
    filterYard,
    setFilterYard,
    filterRegion,
    setFilterRegion,
    //option
    filterOptionWorkingDate,
    filterOptionYard,
    filterOptionRegion,
    isLoadingSummaryYard,
    planJobId,
    getSearchChartTrailerAndTotal,
    getSearchChartRegionFilter,
    getSearchChartRegionNoFilter,
    functionForTableDate,
    //loading
    isLoadingChartRegionFilter,
    isLoadingSearchChartRegionNoFilter,
    disableFilter
  } = props;
  const { Panel } = Collapse;
  const { Option } = Select;

  const onSearch = async () => {
    let payload = {
      working_date: filterWorkingDate,
      plan_job_id: planJobId,
      location_from_id: filterYard,
    };
      await getSearchChartTrailerAndTotal(payload);

    if (filterRegion !== "All") {
      let payload = {
        working_date: filterWorkingDate,
        plan_job_id: planJobId,
        location_from_id: filterYard,
        region_name: filterRegion,
      };

      await getSearchChartRegionFilter(payload);
    } else {
      let payload = {
        working_date: filterWorkingDate,
        plan_job_id: planJobId,
        location_from_id: filterYard,
      };

      await getSearchChartRegionNoFilter(payload);
    }

    functionForTableDate(filterYard);
  };

  return (
    <Container>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header="Search & Filter" key={1}>
          <Row
            gutter={[20]}
            style={{ marginBottom: "10px" }}
            align="middle"
            justify="center"
          >
            <Col span={2} align="center">
              Working Date
            </Col>
            <Col span={4}>
              <Select
                mode="single"
                style={{
                  width: "100%",
                }}
                allowClear={true}
                loading={isLoadingSummaryYard}
                disabled={
                  disableFilter ||
                  isLoadingSummaryYard ||
                  isLoadingChartRegionFilter ||
                  isLoadingSearchChartRegionNoFilter
                }
                maxTagCount="responsive"
                placeholder="Select Working Date"
                value={filterWorkingDate}
                onChange={(values) => {
                  setFilterWorkingDate(values);
                }}
                showSearch
                optionFilterProp="children"
              >
                {filterOptionWorkingDate &&
                  filterOptionWorkingDate.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={2} align="center">
              Yard
            </Col>
            <Col span={4}>
              <Select
                mode="single"
                style={{
                  width: "100%",
                }}
                allowClear={true}
                disabled={
                  disableFilter ||
                  isLoadingSummaryYard ||
                  isLoadingChartRegionFilter ||
                  isLoadingSearchChartRegionNoFilter
                }
                loading={isLoadingSummaryYard}
                maxTagCount="responsive"
                placeholder="Select Yard"
                value={filterYard}
                onChange={(values) => {
                  setFilterYard(values);
                }}
                showSearch
                optionFilterProp="children"
              >
                {filterOptionYard &&
                  filterOptionYard.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={2} align="center">
              Region
            </Col>
            <Col span={4}>
              <Select
                mode="single"
                style={{
                  width: "100%",
                }}
                allowClear={true}
                disabled={
                  disableFilter ||
                  isLoadingSummaryYard ||
                  isLoadingChartRegionFilter ||
                  isLoadingSearchChartRegionNoFilter
                }
                loading={isLoadingSummaryYard}
                maxTagCount="responsive"
                placeholder="Select Region"
                defaultValue={"All"}
                value={filterRegion}
                onChange={(values) => {
                  setFilterRegion(values);
                }}
                showSearch
                optionFilterProp="children"
              >
                {filterOptionRegion &&
                  filterOptionRegion.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={2}> </Col>
            <Col span={2}>
              <Button
                type="primary"
                style={{
                  width: "100%",
                }}
                onClick={onSearch}
                disabled={
                  disableFilter ||
                  isLoadingSummaryYard ||
                  !filterWorkingDate ||
                  !filterYard ||
                  isLoadingChartRegionFilter ||
                  isLoadingSearchChartRegionNoFilter
                }
                loading={isLoadingSummaryYard}
              >
                <p>Search</p>
              </Button>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};
const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  padding: 10px;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default ToolbarSummaryYardComponent;
