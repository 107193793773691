import React from 'react';
import { PageHeader, Divider, Typography, Row, Col, Space } from 'antd';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { goBack } from 'connected-react-router';

import styled from 'styled-components';

import { mapResetMap } from 'reducers/map';
const { Text, Title } = Typography;

const InfoPanel = (props) => {
  const { panelData, gpsData, orderId, polling } = props;

  const order =
    panelData &&
    panelData.order_truck_schedule &&
    panelData.order_truck_schedule.length > 0
      ? panelData.order_truck_schedule.find((i) => i.id === orderId)
      : null;

  const dispatch = useDispatch();
  return (
    <>
      <StyledPageHeader
        ghost={false}
        onBack={() => {
          dispatch(mapResetMap());
          setTimeout(() => {
            polling(0);
            dispatch(goBack());
          }, 100);
        }}
        title={<Text style={{ fontSize: '24px' }}> Tracking Dashboard </Text>}
      >
        {panelData && (
          <>
            <Title level={3} style={{ marginBottom: '0px' }}>
              {panelData.calling_sheet_no}
            </Title>
            <Space>
              <Text style={{ fontSize: '18px' }}>{`Last update: `}</Text>
              <Text style={{ fontSize: '18px' }} strong>{`${
                gpsData
                  ? moment.unix(gpsData.ts).format('DD/MM/YYYY HH:mm')
                  : '-'
              }`}</Text>
            </Space>

            <StyledDivider orientation='left'></StyledDivider>
            <Row>
              <Col style={{ fontSize: '18px' }} xs={9}>
                Route Group
              </Col>
              <Col xs={15}>
                <Text style={{ fontSize: '18px' }} strong>
                  {panelData.route_group ? panelData.route_group : '-'}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col style={{ fontSize: '18px' }} xs={9}>
                Sub-Route
              </Col>
              <Col xs={15}>
                <Text style={{ fontSize: '18px' }} strong>
                  {panelData.sub_group ? panelData.sub_group : '-'}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col style={{ fontSize: '18px' }} xs={9}>
                Cluster
              </Col>
              <Col xs={15}>
                <Text style={{ fontSize: '18px' }} strong>
                  {panelData.cluster_name}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col style={{ fontSize: '18px' }} xs={9}>
                Trailer Code
              </Col>
              <Col xs={15}>
                <Text style={{ fontSize: '18px' }} strong>
                  {panelData.delivery_realtime_tracking.truck_license}
                </Text>
              </Col>
            </Row>

            <Row style={{ marginTop: 25 }}>
              <Text style={{ fontSize: '18px' }} strong>
                ETA
              </Text>
            </Row>

            <StyledDivider
              orientation='left'
              style={{ fontSize: '18' }}
            ></StyledDivider>
            <Row>
              <Col style={{ fontSize: '18px' }} xs={9}>
                Original ETA
              </Col>
              <Col xs={15}>
                <Text style={{ fontSize: '18px' }} strong>
                  {moment(panelData.plan_arrival_time).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                </Text>
              </Col>
            </Row>
            <Row>
              <Col style={{ fontSize: '18px' }} xs={9}>
                Update ETA
              </Col>
              <Col xs={15}>
                <Text
                  style={{ fontSize: '18px' }}
                  strong
                  type={(() => {
                    if (panelData && panelData.eva) {
                      switch (panelData.eva) {
                        case 'delay':
                          return 'danger';
                        case 'warning':
                          return 'warning';
                        default:
                          return 'success';
                      }
                    } else {
                      return 'default';
                    }
                  })()}
                >
                  {panelData.eta
                    ? moment(panelData.eta).format('DD/MM/YYYY HH:mm')
                    : '-'}
                </Text>
              </Col>
            </Row>
            {order && (
              <>
                <Row style={{ marginTop: 25 }}>
                  <Text style={{ fontSize: '18px' }} strong>
                    VIN
                  </Text>
                </Row>

                <StyledDivider
                  orientation='left'
                  style={{ fontSize: '22px' }}
                ></StyledDivider>
                <Row>
                  <Col style={{ fontSize: '18px' }} xs={9}>
                    Vin No.
                  </Col>
                  <Col xs={15}>
                    <Text style={{ fontSize: '18px' }} strong>
                      {order.vin_no}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: '18px' }} xs={9}>
                    Model Code
                  </Col>
                  <Col xs={15}>
                    <Text style={{ fontSize: '18px' }} strong>
                      {order.model}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: '18px' }} xs={9}>
                    Engine
                  </Col>
                  <Col xs={15}>
                    <Text style={{ fontSize: '18px' }} strong>
                      {order.engine}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: '18px' }} xs={9}>
                    Final destination
                  </Col>
                  <Col xs={15}>
                    <Text style={{ fontSize: '18px' }} strong>
                      {order.location_shortname}
                    </Text>
                  </Col>
                </Row>
                <Row>
                  <Col style={{ fontSize: '18px' }} xs={9}>
                    Color
                  </Col>
                  <Col xs={15}>
                    <Text style={{ fontSize: '18px' }} strong>
                      {order.color}
                    </Text>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </StyledPageHeader>
    </>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
  .ant-page-header-content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 4px;
  margin-bottom: 4px;
`;

export default InfoPanel;
