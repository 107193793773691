/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  Typography,
  Modal,
  Collapse,
  Card,
} from 'antd';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ToolbarComponent = (props) => {
  const {
    onInit,
    filterWorkingDate,
    setFilterWorkingDate,
    filterOptionWorkingDate,
    filterStatusPoint,
    setFilterStatusPoint,
    filterStatusPointOption,
    filterStatus,
    setFilterStatus,
    filterStatusOption,
    filterRegion,
    setFilterRegion,
    filterRegionOption,
    filterShift,
    setFilterShift,
    filterShiftOption,
    filterJobcode,
    setFilterJobcode,
    filterJobcodeOption,
    filterTripType,
    setFilterTripType,
    filterTripTypeOption,
    filterTrailerType,
    setFilterTrailerType,
    filterTrailerTypeOption,
    filterFrom,
    setFilterFrom,
    filterFromOption,
    filterTo,
    setFilterTo,
    filterToOption,
    isLoadingFilterDropdown,
    apiSearchTable,
    isLoading,
    onBtnSubmit,
    isLoadingSubmit,
    dataInitDisabled,
    setPage,
    onBtnDownload,
    isLoadingDownloadCSV,
    dataInitRef,
    dataSourceTable,
    onBtnSave,
    disablePage,
  } = props;

  const { Option } = Select;
  const { Panel } = Collapse;

  const [checkDisableSubmit, setCheckDisableSubmit] = useState();

  useEffect(() => {
    if (onInit && dataInitRef?.open_to_bzp === true) {
      let search = {
        working_date: filterWorkingDate,
        start_point: filterStatusPoint,
        status: filterStatus,
        region: filterRegion,
        shift: filterShift,
        job_code: filterJobcode,
        trip_type: filterTripType,
        trailer_type: filterTrailerType,
        from: filterFrom,
        to: filterTo,
      };
      onInit(search);
    }
  }, [onInit, dataInitRef]);

  useEffect(() => {
    setPage(1);
  }, [
    filterJobcode,
    filterWorkingDate,
    filterStatusPoint,
    filterStatus,
    filterRegion,
    filterShift,
    filterTripType,
    filterTrailerType,
    filterFrom,
    filterTo,
    setPage,
  ]);

  const onBtnSearch = async () => {
    let search = {
      working_date: filterWorkingDate,
      start_point: filterStatusPoint,
      status: filterStatus,
      region: filterRegion,
      shift: filterShift,
      job_code: filterJobcode,
      trip_type: filterTripType,
      trailer_type: filterTrailerType,
      from: filterFrom,
      to: filterTo,
    };
    apiSearchTable(search);
  };

  const onBtnSubmitCheck = () => {
    Modal.confirm({
      title: 'Are you sure want to submit?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: onBtnSubmit,
    });
  };


  useEffect(() => {
    if (dataSourceTable) {
      let check = disableSubmit(dataSourceTable);
      setCheckDisableSubmit(check);
    }
  }, [dataSourceTable]);

  const disableSubmit = (array) => {
    if (array) {
      for (let index = 0; index < array.length; index++) {
        if (array[index].manage_tour_list_status_bzp === '-') {
          return true;
        }
      }
    } else if (dataInitDisabled || dataInitRef?.allow_edit === false) {
      return true;
    } else {
      return false;
    }
  
  };

  return (
    <div>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Row gutter={[10]}>
            <Col span={21}>
              <CardStyle>
                <Row>
                  <Col span={20}>
                    <Row
                      gutter={[20]}
                      style={{ marginBottom: '5px' }}
                      align='middle'
                      justify='center'
                    >
                      <Col span={4} align='start'>
                        <Typography>Working Date</Typography>
                        <Select
                          disabled={dataInitDisabled}
                          mode='multiple'
                          showSearch
                          style={{
                            textAlign: 'center',
                            width: '100%',
                          }}
                          defaultValue={'All'}
                          loading={isLoadingFilterDropdown}
                          maxTagCount='responsive'
                          placeholder={'working date'}
                          value={filterWorkingDate}
                          onChange={(values) => {
                            setFilterWorkingDate(values);
                          }}
                          size='small'
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterOptionWorkingDate?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col span={4} align='start'>
                        <Typography>Start point</Typography>
                        <Select
                          disabled={dataInitDisabled}
                          showSearch
                          defaultValue={'All'}
                          style={{
                            width: '100%',
                          }}
                          size='small'
                          allowClear={true}
                          placeholder={'Start point'}
                          value={filterStatusPoint}
                          onChange={(values) => {
                            setFilterStatusPoint(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterStatusPointOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={4} align='start'>
                        <Typography>Status</Typography>
                        <Select
                          disabled={dataInitDisabled}
                          mode='multiple'
                          showSearch
                          style={{
                            width: '100%',
                          }}
                          defaultValue={'All'}
                          size='small'
                          allowClear={true}
                          placeholder={'Status'}
                          value={filterStatus}
                          onChange={(values) => {
                            setFilterStatus(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterStatusOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={4} align='start'>
                        <Typography>Region (To)</Typography>
                        <Select
                          mode='multiple'
                          disabled={dataInitDisabled}
                          showSearch
                          style={{
                            width: '100%',
                          }}
                          defaultValue={'All'}
                          size='small'
                          allowClear={true}
                          placeholder={'Region (To)'}
                          value={filterRegion}
                          onChange={(values) => {
                            setFilterRegion(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterRegionOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={4} align='start'>
                        <Typography>Shift</Typography>
                        <Select
                          disabled={dataInitDisabled}
                          showSearch
                          style={{
                            width: '100%',
                          }}
                          defaultValue={'All'}
                          size='small'
                          allowClear={true}
                          placeholder={'Shift'}
                          value={filterShift}
                          onChange={(values) => {
                            setFilterShift(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterShiftOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>

                    <Row gutter={[20]} align='middle' justify='center'>
                      <Col span={4} align='start'>
                        <Typography>Job code</Typography>
                        <Select
                          disabled={dataInitDisabled}
                          mode='multiple'
                          style={{
                            width: '100%',
                          }}
                          allowClear={true}
                          defaultValue={'All'}
                          size='small'
                          placeholder={'Job code'}
                          value={filterJobcode}
                          onChange={(values) => {
                            setFilterJobcode(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterJobcodeOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col span={4} align='start'>
                        <Typography>Trip type</Typography>
                        <Select
                          disabled={dataInitDisabled}
                          mode='multiple'
                          style={{
                            width: '100%',
                          }}
                          allowClear={true}
                          defaultValue={'All'}
                          size='small'
                          placeholder={'Trip type'}
                          value={filterTripType}
                          onChange={(values) => {
                            setFilterTripType(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterTripTypeOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col span={4} align='start'>
                        <Typography>Trailer type</Typography>
                        <Select
                          disabled={dataInitDisabled}
                          mode='multiple'
                          style={{
                            width: '100%',
                          }}
                          allowClear={true}
                          defaultValue={'All'}
                          size='small'
                          placeholder={'Trailer type'}
                          value={filterTrailerType}
                          onChange={(values) => {
                            setFilterTrailerType(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {filterTrailerTypeOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col span={4} align='start'>
                        <Typography>From</Typography>
                        <Select
                          mode='multiple'
                          showSearch
                          style={{
                            width: '100%',
                          }}
                          allowClear={true}
                          defaultValue={'All'}
                          size='small'
                          placeholder={'From'}
                          value={filterFrom}
                          onChange={(values) => {
                            setFilterFrom(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          disabled={dataInitDisabled}
                        >
                          {filterFromOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>

                      <Col span={4} align='start'>
                        <Typography>To</Typography>
                        <Select
                          mode='multiple'
                          showSearch
                          style={{
                            width: '100%',
                          }}
                          allowClear={true}
                          defaultValue={'All'}
                          size='small'
                          placeholder={'To'}
                          value={filterTo}
                          onChange={(values) => {
                            setFilterTo(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                          disabled={dataInitDisabled}
                        >
                          {filterToOption?.map((c, index) => (
                            <Option key={index} value={c.value}>
                              {c.label}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4}>
                    <Button
                      type='primary'
                      size='small'
                      style={{ width: '80%', marginTop: '23px' }}
                      disabled={dataInitDisabled}
                      onClick={onBtnDownload}
                      loading={isLoadingDownloadCSV}
                    >
                      Download
                    </Button>
                    <Button
                      type='primary'
                      size='small'
                      style={{ width: '80%', marginTop: '23px' }}
                      onClick={onBtnSearch}
                      loading={isLoading}
                      disabled={dataInitDisabled}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </CardStyle>
            </Col>

            <Col span={3}>
              <CardStyleSubmit>
                <Button
                  type='primary'
                  style={{
                    marginTop: 0,
                    marginBottom: 8,
                    width: '80%',
                    height: '50px',
                  }}
                  onClick={onBtnSubmitCheck}
                  loading={isLoadingSubmit || isLoading}
                  disabled={
                    checkDisableSubmit || dataInitRef?.allow_edit !== true
                  }
                >
                  Submit
                </Button>
                <Button
                  type='primary'
                  style={{
                    // marginTop: 13,
                    // marginBottom: 13,
                    marginBottom: 0,
                    width: '80%',
                    // height: '50px',
                  }}
                  onClick={onBtnSave}
                  // loading={isLoadingSubmitSave || isLoading}
                  disabled={!disablePage}
                >
                  Save
                </Button>
              </CardStyleSubmit>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </div>
  );
};

const CardStyle = styled(Card)`
  .ant-card-body {
    padding-top: 11px;
    padding-bottom: 22px;
    padding-left: 20px;
    padding-right: 0px;
  }

  // width: 777px;
  border: 1px solid #d9d9d9;
`;

const CardStyleSubmit = styled(Card)`
  .ant-card-body {
    padding-top: 18px;
    padding-bottom: 17px;
    padding-left: 28px;
    padding-right: 0px;
  }

  // width: 164px;
  border: 1px solid #d9d9d9;
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;

export default ToolbarComponent;
