/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, message, Modal, Typography } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import Toolbar from './Toolbar';
import Status from './Status';
import Table from './Table';
import {
  useFindDataInitBzpQuery,
  useSubmitManageTourBzpMutation,
  useUpdateManageTourBzpMutation,
  manageTourListBzpApi,
  useFindStatusTotalRecordBzpQuery,
} from 'reducers/manageTourListBzp/api';
import { useFindDownloadCSVManageTourListMutation } from 'reducers/manageTourList/api';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;
const LIMIT = 100;
const PAGE_SIZE = 100;
const ManageTourListIndexComponent = () => {
  const user = useSelector(getCurrentUser);
  const dataTableEditRef = useRef();
  const headerDateTableRef = useRef([]);
  const dataInitRef = useRef();
  const valueStatusListRef = useRef();
  const payloadRef = useRef(null);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const [disablePage, setDisablePage] = useState(false);
  const [dataSourceTableEdit, setDataSourceTableEdit] = useState([]);
  const [valueStatusList, setValueStatusList] = useState();
  const [dataInitEdit, setDataInitEdit] = useState();
  const [filterWorkingDate, setFilterWorkingDate] = useState();
  const [filterWorkingOptionDate, setFilterWorkingOptionDate] = useState();
  const [filterOptionWorkingDate, setFilterOptionWorkingDate] = useState();
  const [filterCompany, setFilterCompany] = useState();
  const [filterCompanyOption, setFilterCompanyOption] = useState();
  const [filterStatusPoint, setFilterStatusPoint] = useState();
  const [filterStatusPointOption, setFilterStatusPointOption] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [filterStatusOption, setFilterStatusOption] = useState();
  const [filterRegion, setFilterRegion] = useState();
  const [filterRegionOption, setFilterRegionOption] = useState();
  const [filterShift, setFilterShift] = useState();
  const [filterShiftOption, setFilterShiftOption] = useState();

  const [filterJobcode, setFilterJobcode] = useState();
  const [filterJobcodeOption, setFilterJobcodeOption] = useState();
  const [filterTripType, setFilterTripType] = useState();
  const [filterTripTypeOption, setFilterTripTypeOption] = useState();
  const [filterTrailerType, setFilterTrailerType] = useState();
  const [filterTrailerTypeOption, setFilterTrailerTypeOption] = useState();
  const [filterFrom, setFilterFrom] = useState();
  const [filterFromOption, setFilterFromOption] = useState();
  const [filterTo, setFilterTo] = useState();
  const [filterToOption, setFilterToOption] = useState();
  const [dataInitDisabled, setDataInitDisabled] = useState(false);

  const [dataSourceTable, setDataSourceTable] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(null);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);

  const onInit = useCallback(
    (payload) => {
      if (offset > 0) {
        payloadRef.current = { ...payload, limit: LIMIT, offset: offset };
        return;
      }
      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      headerDateTableRef.current = [];
      setDataSourceTable([]);
      executeQuery(0, [], queryPayload);
    },
    // eslint-disable-next-line no-use-before-define
    [executeQuery, offset]
  );

  const onQuery = async (payload) => {
    try {
      if (!isLoading) {
        let bufferData = [];
        let requiredRows = page * PAGE_SIZE;
        requiredRows =
          requiredRows > totalRows && totalRows !== 0
            ? totalRows
            : requiredRows;

        if (requiredRows > bufferData.length) {
          // Fetch more data
          let curOffset = 0;
          payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };

          do {
            let queryRes = await executeQuery(
              curOffset,
              bufferData,
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            curOffset += LIMIT;
            bufferData = queryRes.data;
            requiredRows =
              requiredRows > queryRes.totalRows
                ? queryRes.totalRows
                : requiredRows;
          } while (requiredRows > bufferData.length);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          manageTourListBzpApi.endpoints.findSearchDatatableBzpByPagination.initiate(
            payload,
            {
              forceRefetch: forceRefetch ? true : false,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              no: i + rowNo,
            }));
            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            headerDateTableRef.current = newData;
            refetchDataInit();
            setDataSourceTable(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  const {
    data: dataInit,
    error: errorDataInit,
    isLoading: isLoadingDataInit,
    refetch: refetchDataInit,
  } = useFindDataInitBzpQuery();

  useEffect(() => {
    if (dataInit) {
      dataInitRef.current = dataInit;
      setDataInitEdit(dataInit);
      if (
        dataInit.open_to_bzp === false ||
        dataInit.message === 'plan job step 5 incompleted' ||
        dataInit.message === 'plan job does not exist'
      ) {
        Modal.warning({
          title: 'ไม่สามารถใช้งานหน้า Manage Tour List BzP ได้',
          width: 500,
          content: (
            <div>
              <p>เนื่องจากยังไม่ได้มีการจ่ายงานเข้ามา</p>
            </div>
          ),
          onOk: () => {
            setDataInitDisabled(true);
          },
        });
      } else {
        setDataInitDisabled(false);
        let workingDateOptions = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        workingDateOptions.push(
          ...dataInit.filters?.working_date?.map((d) => ({
            label: moment(d.name).format('DD/MM/YY'),
            value: moment(d.value).format('DD/MM/YY'),
          }))
        );

        setFilterOptionWorkingDate(workingDateOptions);

        let findStatusPoint = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findStatusPoint.push(
          ...dataInit.filters?.start_point?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterStatusPointOption(findStatusPoint);

        // let findStatusCompany = [
        //   {
        //     label: 'All',
        //     value: 'All',
        //   },
        // ];

        // findStatusCompany.push(
        //   ...dataInit.filters?.company?.map((d) => ({
        //     label: d.name,
        //     value: d.value,
        //   }))
        // );

        // setFilterCompanyOption(findStatusCompany);
        // setFilterCompanyOptionBZP(findStatusCompany);

        let findStatus = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findStatus.push(
          ...dataInit.filters?.status?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterStatusOption(findStatus);

        let findRegionTo = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findRegionTo.push(
          ...dataInit.filters?.region?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterRegionOption(findRegionTo);

        let findShift = dataInit.filters?.shift?.map((d) => ({
          label: d.name,
          value: d.value,
        }));

        setFilterShiftOption(findShift);

        let findJobCode = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findJobCode.push(
          ...dataInit.filters?.job_code?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterJobcodeOption(findJobCode);

        let findTripType = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findTripType.push(
          ...dataInit.filters?.trip_type?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterTripTypeOption(findTripType);

        let findTrailerType = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findTrailerType.push(
          ...dataInit.filters?.trailer_type?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterTrailerTypeOption(findTrailerType);
        let findLocationFroms = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findLocationFroms.push(
          ...dataInit.filters?.from?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterFromOption(findLocationFroms);

        let findLocationTo = [
          {
            label: 'All',
            value: 'All',
          },
        ];

        findLocationTo.push(
          ...dataInit.filters?.to?.map((d) => ({
            label: d.name,
            value: d.value,
          }))
        );

        setFilterToOption(findLocationTo);
      }
    }
  }, [dataInit]);

  useEffect(() => {
    if (errorDataInit) {
      setErrorData(errorDataInit);
    }
  }, [errorDataInit, setErrorData]);

  const [
    updateManageTourBzp,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
    },
  ] = useUpdateManageTourBzpMutation();

  useEffect(() => {
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, setErrorData]);

  const refetchDataAll = () => {
    let search = {
      working_date: filterWorkingDate,
      start_point: filterStatusPoint,
      status: filterStatus,
      region: filterRegion,
      shift: filterShift,
      job_code: filterJobcode,
      trip_type: filterTripType,
      trailer_type: filterTrailerType,
      from: filterFrom,
      to: filterTo,
    };

    onQuery(search);
    refetchDataStatusTotal();
    refetchDataInit();
    setDisablePage(false);
  };

  const {
    data: dataStatusTotal,
    error: errorStatusTotal,
    isFetching: isLoadingStatusTotal,
    refetch: refetchDataStatusTotal,
  } = useFindStatusTotalRecordBzpQuery(user?.company_job_name, {
    skip:
      !dataInitRef.current ||
      dataInitRef.current?.message === 'plan job does not exist' ||
      dataInitRef.current?.open_to_bzp === false,
  });

  useEffect(() => {
    if (dataStatusTotal) {
      if (dataInitRef.current?.open_to_bzp === true) {
        valueStatusListRef.current = dataStatusTotal;
        let arrData = [dataStatusTotal]?.map((d, i) => ({
          ...d,
          id: i + 1,
        }));
        setValueStatusList(arrData);
      }
    }
  }, [dataStatusTotal]);

  useEffect(() => {
    if (errorStatusTotal) {
      setErrorData(errorStatusTotal);
    }
  }, [errorStatusTotal, setErrorData]);

  useEffect(() => {
    if (!isUninitUpdating && isSuccessUpdating) {
      message.success('Data was successfully saved');

      refetchDataAll();
    }
  }, [isSuccessUpdating, isUninitUpdating]);

  const [
    onBtnSubmit,
    {
      data: btnSubmit,
      isSuccess: isSuccessSubmit,
      isUninitialized: isUninitSubmit,
      error: errorSubmit,
      isLoading: isLoadingSubmit,
    },
  ] = useSubmitManageTourBzpMutation();

  useEffect(() => {
    if (errorSubmit) {
      setErrorData(errorSubmit?.message);
    }
  }, [errorSubmit, setErrorData]);

  useEffect(() => {
    if (!isUninitSubmit && isSuccessSubmit) {
      setIsLoading(false);
      refetchDataAll();
      message.success(btnSubmit?.message);
    }
  }, [isSuccessSubmit, isUninitSubmit]);

  const [
    apiDownloadCSV,
    {
      data: dataDownloadCSV,
      error: errorDownloadCSV,
      isLoading: isLoadingDownloadCSV,
    },
  ] = useFindDownloadCSVManageTourListMutation();

  useEffect(() => {
    if (dataDownloadCSV) {
      downloadCSV(dataDownloadCSV);
    }
  }, [dataDownloadCSV]);

  const downloadCSV = async (response) => {
    const outputFilename = `${moment().format('DDMMYY')}_Manage_Tour_List_${
      user?.company_job_name
    }.csv`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(
      new Blob([`\ufeff${response}`], { type: 'text/csv;charset=utf-8' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (errorDownloadCSV) {
      setErrorData(errorDownloadCSV);
    }
  }, [errorDownloadCSV, setErrorData]);

  const onBtnDownload = () => {
    let search = {
      company: filterCompany,
      working_date: filterWorkingDate,
      start_point: filterStatusPoint,
      status: filterStatus,
      region: filterRegion,
      shift: filterShift,
      job_code: filterJobcode,
      trip_type: filterTripType,
      trailer_type: filterTrailerType,
      from: filterFrom,
      to: filterTo,
    };
    apiDownloadCSV(search);
  };

  useEffect(() => {
    if (dataSourceTable && dataInitRef) {
      let arrEdit = dataSourceTable.map((d) => ({
        ...d,
        allow_edit: dataInitRef?.allow_edit,
        original_memo: d?.manage_tour_list_memo,
        original_status_bzp: d?.manage_tour_list_status_bzp,
      }));
      // setDataTableEdit(arrEdit);
      dataTableEditRef.current = arrEdit;
      setDataSourceTableEdit(arrEdit);
    }
  }, [dataSourceTable, dataInitRef]);

  const updateDataTable = async (objValue, id_jobcode) => {
    let arrDataTable = [];
    arrDataTable = dataTableEditRef.current
      .filter((item) => item.id === id_jobcode)
      .map((item) => ({
        ...item,
        ...objValue,
      }));

    let dataTableNew = dataTableEditRef.current;
    const index = dataTableEditRef.current.findIndex(
      (item) => item.id === id_jobcode
    );
    dataTableNew.splice(index, 1, ...arrDataTable);
    dataTableEditRef.current = [...dataTableNew];

    setDataSourceTableEdit([...dataTableNew]);
    let checkEdit = dataTableEditRef.current?.filter(
      (d) =>
        (d.edit === true &&
          d.original_memo !== d.manage_tour_list_memo &&
          d.original_status_bzp.toLowerCase() !==
            d.manage_tour_list_status_bzp.toLowerCase()) ||
        (d.edit === true &&
          d.original_memo !== d.manage_tour_list_memo &&
          d.original_status_bzp.toLowerCase() ===
            d.manage_tour_list_status_bzp.toLowerCase())
    );
    if (checkEdit.length > 0) {
      setDisablePage(true);
    } else {
      setDisablePage(false);
    }
  };

  const onBtnSave = () => {
    Modal.confirm({
      title: 'Are you sure want to save?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        let dataArr = dataTableEditRef.current
          .filter(
            (d) =>
              (d.edit === true &&
                d.original_memo !== d.manage_tour_list_memo &&
                d.original_status_bzp.toLowerCase() !==
                  d.manage_tour_list_status_bzp.toLowerCase()) ||
              (d.edit === true &&
                d.original_memo !== d.manage_tour_list_memo &&
                d.original_status_bzp.toLowerCase() ===
                  d.manage_tour_list_status_bzp.toLowerCase())
          )
          .map((m) => ({
            id: m.id,
            manage_tour_list_status_bzp:
              m.manage_tour_list_status_bzp === '-'
                ? ''
                : m.manage_tour_list_status_bzp.toLowerCase(),
            manage_tour_list_memo: m.manage_tour_list_memo,
          }));

        for (let index = 0; index < dataArr.length; index++) {
          updateManageTourBzp({ ...dataArr[index] });
        }
      },
    });
  };
  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Manage Tour List BzP'
        extra={
          totalRows != null && <Title level={5}>{`Total ${totalRows}`}</Title>
        }
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <>
          <Toolbar
            onInit={onInit}
            setPage={setPage}
            onBtnSubmit={onBtnSubmit}
            onBtnSave={onBtnSave}
            isLoadingSubmit={isLoadingSubmit}
            filterWorkingDate={filterWorkingDate}
            setFilterWorkingDate={setFilterWorkingDate}
            filterOptionWorkingDate={filterOptionWorkingDate}
            setFilterOptionWorkingDate={setFilterOptionWorkingDate}
            filterWorkingOptionDate={filterWorkingOptionDate}
            setFilterWorkingOptionDate={setFilterWorkingOptionDate}
            filterStatusPoint={filterStatusPoint}
            setFilterStatusPoint={setFilterStatusPoint}
            filterStatusPointOption={filterStatusPointOption}
            setFilterStatusPointOption={setFilterStatusPointOption}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            filterStatusOption={filterStatusOption}
            setFilterStatusOption={setFilterStatusOption}
            filterRegion={filterRegion}
            setFilterRegion={setFilterRegion}
            filterRegionOption={filterRegionOption}
            setFilterRegionOption={setFilterRegionOption}
            filterShift={filterShift}
            setFilterShift={setFilterShift}
            filterShiftOption={filterShiftOption}
            setFilterShiftOption={setFilterShiftOption}
            filterJobcode={filterJobcode}
            setFilterJobcode={setFilterJobcode}
            filterJobcodeOption={filterJobcodeOption}
            setFilterJobcodeOption={setFilterJobcodeOption}
            filterTripType={filterTripType}
            setFilterTripType={setFilterTripType}
            filterTripTypeOption={filterTripTypeOption}
            setFilterTripTypeOption={setFilterTripTypeOption}
            filterTrailerType={filterTrailerType}
            setFilterTrailerType={setFilterTrailerType}
            filterTrailerTypeOption={filterTrailerTypeOption}
            setFilterTrailerTypeOption={setFilterTrailerTypeOption}
            filterFrom={filterFrom}
            setFilterFrom={setFilterFrom}
            filterFromOption={filterFromOption}
            setFilterFromOption={setFilterFromOption}
            filterTo={filterTo}
            setFilterTo={setFilterTo}
            filterToOption={filterToOption}
            setFilterToOption={setFilterToOption}
            isLoadingDataInit={isLoadingDataInit}
            apiSearchTable={onQuery}
            isLoading={isLoading}
            dataInitDisabled={dataInitDisabled}
            onBtnDownload={onBtnDownload}
            isLoadingDownloadCSV={isLoadingDownloadCSV}
            dataInitRef={dataInitRef.current}
            dataSourceTable={dataSourceTable}
            disablePage={disablePage}
          />
          <Status
            dataInitDisabled={dataInitDisabled}
            // dataInitRef={dataInitRef.current}
            isLoadingStatusTotal={isLoadingStatusTotal}
            valueStatusListRef={valueStatusListRef}
            valueStatusList={valueStatusList}
          />

          <Table
            dataSourceTable={dataSourceTable}
            headerDateTableRef={headerDateTableRef}
            updateManageTourBzp={updateManageTourBzp}
            PAGE_SIZE={PAGE_SIZE}
            totalRows={totalRows}
            page={page}
            offset={offset}
            payloadRef={payloadRef}
            LIMIT={LIMIT}
            setPage={setPage}
            executeQuery={executeQuery}
            isLoading={isLoading}
            dataInitRef={dataInitEdit}
            updateDataTable={updateDataTable}
            dataSourceTableEdit={dataSourceTableEdit}
            setDataSourceTableEdit={setDataSourceTableEdit}
            disablePage={disablePage}
            onBtnSave={onBtnSave}
          />
        </>
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default ManageTourListIndexComponent;
