import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
  Select,
  Collapse,
  Divider,
  Switch,
  Row,
  Radio,
  Col,
  Typography,
  message,
} from "antd";
import { useParams } from "react-router-dom";
import { goBack } from "connected-react-router";
import { useErrorHandler } from "common_components/ErrorContext";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import {
  useCreateFunctionPermissionMutation,
  useUpdateFunctionPermissionMutation,
  useFindPermissionByIdQuery,
} from "reducers/configurationPermission/api";

const { Option } = Select;
const { Panel } = Collapse;

const FormPermissionComponent = (props) => {
  const {
    dataCompanyDiagramOwner,
    dataFunctionList,
    dataRightClick,
    setdataCompanyDiagramOwner,
    setDataFunctionList,
    setDataRightClick,

    isCreate,
    readonly,
    datasource,
    dataName,
    setDataName,
  } = props;

  const optionsCompanyDiagramOwner = [
    {
      label: "Not All",
      value: 0,
    },
    {
      label: "All",
      value: 1,
    },
  ];

  const optionsFuctionList = [
    {
      label: "Can use, Can edit",
      value: 1,
    },
    {
      label: "View only",
      value: 2,
    },
    {
      label: "Cannot use",
      value: 3,
    },
  ];
  const { id } = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();

  const onChangeName = (e) => {
    setDataName(e.target.value);
  };

  const onChangeCompanyDiagramOwner = (checkedValues, subject, name, index) => {
    let newPrivileges = [];

    for (let i = 0; i < dataCompanyDiagramOwner.length; i++) {
      if (dataCompanyDiagramOwner[i].subject == subject) {
        dataCompanyDiagramOwner[i].actions = checkedValues.target.value;
      }
    }

    for (let i = 0; i < dataCompanyDiagramOwner.length; i++) {
      newPrivileges.push(dataCompanyDiagramOwner[i]);
    }

    setdataCompanyDiagramOwner(newPrivileges);
  };

  const onChangeFunctionList = (checkedValues, subject, name, index) => {
    let newPrivileges = [];

    for (let i = 0; i < dataFunctionList.length; i++) {
      if (dataFunctionList[i].subject == subject) {
        dataFunctionList[i].actions = checkedValues.target.value;
      }
    }

    for (let i = 0; i < dataFunctionList.length; i++) {
      newPrivileges.push(dataFunctionList[i]);
    }

    setDataFunctionList(newPrivileges);
  };

  const onChangeRightClick = (checkedValues, subject, name, index) => {
    let newPrivileges = [];

    for (let i = 0; i < dataRightClick.length; i++) {
      if (dataRightClick[i].subject == subject) {
        dataRightClick[i].actions = checkedValues.target.value;
      }
    }

    for (let i = 0; i < dataRightClick.length; i++) {
      newPrivileges.push(dataRightClick[i]);
    }

    setDataRightClick(newPrivileges);
  };

  const onFinish = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to submit?",
      okText: "Confirm",
      cancelText: "Cancel",
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let privileges = [];
    let ojectData = {
      actions: 0,
      subject: "",
    };
    for (const i in dataCompanyDiagramOwner) {
      if (Object.hasOwnProperty.call(dataCompanyDiagramOwner, i)) {
        if (dataCompanyDiagramOwner[i].actions != null) {
          ojectData = {
            actions: dataCompanyDiagramOwner[i].actions,
            subject: dataCompanyDiagramOwner[i].subject,
          };
          privileges.push(ojectData);
        }
      }
    }

    for (const i in dataFunctionList) {
      if (Object.hasOwnProperty.call(dataFunctionList, i)) {
        if (dataFunctionList[i].actions != null) {
          ojectData = {
            actions: dataFunctionList[i].actions,
            subject: dataFunctionList[i].subject,
          };
          privileges.push(ojectData);
        }
      }
    }

    for (const i in dataRightClick) {
      if (Object.hasOwnProperty.call(dataRightClick, i)) {
        if (dataRightClick[i].actions != null) {
          ojectData = {
            actions: dataRightClick[i].actions,
            subject: dataRightClick[i].subject,
          };
          privileges.push(ojectData);
        }
      }
    }

    let postData = {
      id: id,
      name: dataName,
      privilege: privileges,
    };

    if (isCreate) {
      createFunctionPermission(postData);
    } else {
      updateFunctionPermission(postData);
    }
  };

  const [
    createFunctionPermission,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateFunctionPermissionMutation();

  const [
    updateFunctionPermission,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateFunctionPermissionMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success("Data was successfully saved");
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  return (
    <Container>
      <Row
        gutter={[20]}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        align="middle"
        justify="center"
      >
        <Col span={12} align="right">
          <Button type="primary" onClick={onFinish}>
            Submit
          </Button>
        </Col>
      </Row>

      <Row
        gutter={[20]}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        align="middle"
        justify="center"
      >
        <Col span={3}>
          <StyledText>Name :</StyledText>
        </Col>
        <Col span={14}>
          <Input
            placeholder="Name permission"
            value={datasource?.name}
            onChange={onChangeName}
          />
        </Col>
      </Row>

      <Row
        gutter={[20]}
        style={{ marginTop: "10px", marginBottom: "10px" }}
        align="middle"
        justify="center"
      >
        <Col span={3} align="start" style={{ marginTop: "12px" }}>
          <StyledText>Function Permissions :</StyledText>
        </Col>
        <Col span={14}>
          <Collapse defaultActiveKey={["1", "2", "3"]}>
            <Panel header="Default View" key="1">
              {dataCompanyDiagramOwner.map((p, pIndex) => {
                return (
                  <div key={pIndex}>
                    <StyledDivider key={`divider-${pIndex}`} orientation="left">
                      {p.name}
                    </StyledDivider>

                    <Radio.Group
                      options={optionsCompanyDiagramOwner}
                      value={p.actions}
                      onChange={(checkedValues) => {
                        onChangeCompanyDiagramOwner(
                          checkedValues,
                          p.subject,
                          p.name,
                          p.index
                        );
                      }}
                    ></Radio.Group>
                  </div>
                );
              })}
            </Panel>

            <Panel header="Function List" key="2">
              {dataFunctionList.map((p, pIndex) => {
                return (
                  <div key={pIndex}>
                    <StyledDivider key={`divider-${pIndex}`} orientation="left">
                      {p.name}
                    </StyledDivider>

                    <Radio.Group
                      options={optionsFuctionList}
                      value={p.actions}
                      onChange={(checkedValues) => {
                        onChangeFunctionList(
                          checkedValues,
                          p.subject,
                          p.name,
                          p.index
                        );
                      }}
                    ></Radio.Group>
                  </div>
                );
              })}
            </Panel>

            <Panel header="Right click List" key="3">
              {dataRightClick.map((p, pIndex) => {
                return (
                  <div key={pIndex}>
                    <StyledDivider key={`divider-${pIndex}`} orientation="left">
                      {p.name}
                    </StyledDivider>

                    <Radio.Group
                      options={optionsFuctionList}
                      value={p.actions}
                      onChange={(checkedValues) => {
                        onChangeRightClick(
                          checkedValues,
                          p.subject,
                          p.name,
                          p.index
                        );
                      }}
                    ></Radio.Group>
                  </div>
                );
              })}
            </Panel>
          </Collapse>
        </Col>
      </Row>
    </Container>
  );
};

const StyledText = styled(Typography)`
  font-size: 14px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0px !important;
`;

export default FormPermissionComponent;
