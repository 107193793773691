import React from 'react';
import { Marker } from '@react-google-maps/api';

const CarMarker = (props) => {
  const { blackBoxId, direction, latitude, longitude, color } = props;
  return (
    <Marker
      options={{
        zIndex: 1000,
      }}
      position={{ lat: latitude, lng: longitude }}
      icon={{
        path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
        scale: 5,
        fillColor: color || 'green',
        fillOpacity: 0.8,
        strokeWeight: 1.5,
        rotation: direction,
      }}
      key={blackBoxId}
    ></Marker>
  );
};

export default CarMarker;
