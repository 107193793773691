// auth middleware
const permission = {
  checkPermission: (user, subject, actions) => {
    if (!user || !subject) {
      return false;
    }

    if (permission.isSuperAdmin(user)) {
      return true;
    }

    let privileges = user.privileges;
    if (!privileges) {
      return false;
    }
    const privilege = privileges.filter((p) => p.subject === subject);
    if (privilege.length === 0) {
      return false;
    }

    if (!actions || actions.length === 0) {
      return true;
    }

    return actions.some((v) => privilege[0].actions.includes(v));
  },
  checkPermissionToDisplayMenu: (user, menuItem) => {
    if (!user || !menuItem) {
      return false;
    }
    if (menuItem.subItems) {
      for (let subItem of menuItem.subItems) {
        if (subItem.permission) {
          let checkResult = permission.checkPermission(
            user,
            subItem.permission.subject,
            subItem.permission.actions
          );
          if (checkResult === true) {
            return true;
          }
        } else {
          return true;
        }
      }
    } else {
      if (menuItem.permission) {
        return permission.checkPermission(
          user,
          menuItem.permission.subject,
          menuItem.permission.actions
        );
      } else {
        return true;
      }
    }
    return false;
  },
  isSuperAdmin: (user) => {
    if (!user) {
      return false;
    }
    return user.is_superadmin === true;
  },
  createPermissionOptions: (user, subject, isGroupAdmin, extraOptions) => {
    let options = [
      { label: 'List', value: 'l', disabled: true },
      { label: 'Create', value: 'c', disabled: true },
      { label: 'Read', value: 'r', disabled: true },
      { label: 'Update', value: 'u', disabled: true },
      { label: 'Delete', value: 'd', disabled: true },
    ];
    if (extraOptions) {
      options = [...options, ...extraOptions];
    }

    if (!user || !subject) {
      return options;
    }

    if (isGroupAdmin && !permission.isSuperAdmin(user)) {
      return options;
    }

    if (permission.isSuperAdmin(user)) {
      return options.map((o) => ({ ...o, disabled: false }));
    }

    if (user.privileges) {
      let privileges = user.privileges.filter((p) => p.subject === subject);
      if (privileges && privileges.length > 0) {
        for (let privilege of privileges) {
          options = options.map((o) => ({
            ...o,
            disabled: privilege.actions.includes(o.value) ? false : o.disabled,
          }));
        }
      }
    }

    return options;
  },
  createPaymentOptions: (user, subject, isGroupAdmin, extraOptions) => {
    let options = [
      { label: 'Active', value: '1', disabled: true },
      { label: 'Inactive', value: '0', disabled: true },
    ];
    if (extraOptions) {
      options = [...options, ...extraOptions];
    }

    if (!user || !subject) {
      return options;
    }

    if (isGroupAdmin && !permission.isSuperAdmin(user)) {
      return options;
    }

    if (permission.isSuperAdmin(user)) {
      return options.map((o) => ({ ...o, disabled: false }));
    }

    if (user.privileges) {
      let privileges = user.privileges.filter((p) => p.subject === subject);
      if (privileges && privileges.length > 0) {
        for (let privilege of privileges) {
          options = options.map((o) => ({
            ...o,
            disabled: privilege.actions.includes(o.value) ? false : o.disabled,
          }));
        }
      }
    }

    return options;
  },

  createDiagramOwnerOptions: (user, subject, isGroupAdmin, extraOptions) => {
    let options = [
      { label: 'Not All', value: 0, disabled: false },
      { label: 'All', value: 1, disabled: false },
    ];

    // let options = [
    //   { label: 'Can use, Can edit', value: 1, disabled: true },
    //   { label: 'View only', value: 2, disabled: true },
    //   { label: 'Cannot use', value: 3, disabled: true },
    // ];
    if (extraOptions) {
      options = [...options, ...extraOptions];
    }

    if (!user || !subject) {
      return options;
    }

    if (isGroupAdmin && !permission.isSuperAdmin(user)) {
      return options;
    }

    if (permission.isSuperAdmin(user)) {
      return options.map((o) => ({ ...o, disabled: false }));
    }

    if (user.privileges) {
      let privileges = user.privileges.filter((p) => p.subject === subject);
      if (privileges && privileges.length > 0) {
        for (let privilege of privileges) {
          options = options.map((o) => ({
            ...o,
            disabled: privilege.actions.includes(o.value) ? false : o.disabled,
          }));
        }
      }
    }
    console.log('options', options);
    return options;
  },

  getFunctionPermissionsBySubject: (permissions, subject) => {
    if (!permissions || !subject) {
      return [];
    }
    let actions = [];
    for (let permission of permissions) {
      if (permission.privilege) {
        let privileges = permission.privilege.filter(
          (p) => p.subject === subject
        );
        actions = actions.concat(privileges.map((p) => p.actions));
      }
    }

    actions = actions.flat();
    let uniqueActions = [...new Set(actions)];

    return uniqueActions;
  },
  getPermissionsBySubject: (permissions, subject) => {
    if (!permissions || !subject) {
      return [];
    }

    let actions = [];
    for (let permission of permissions) {
      if (permission.privilege) {
        let privileges = permission.privilege.filter(
          (p) => p.subject === subject
        );
        actions = actions.concat(privileges.map((p) => p.actions));
      }
    }
    actions = actions.flat();
    let uniqueActions = [...new Set(actions)];
    return uniqueActions && uniqueActions.toString();
  },
  mergePrivileges: (permissions) => {
    if (!permissions) {
      return [];
    }

    let privileges = [];

    for (let permission of permissions) {
      if (permission.privilege) {
        permission.privilege.forEach((e) => {
          let findPrivilege = privileges.find((p) => p.subject === e.subject);
          if (findPrivilege) {
            findPrivilege.actions = findPrivilege.actions.concat(e.actions);
          } else {
            privileges.push({ ...e });
          }
        });
      }
    }

    // Flaten and remove duplication
    let result = privileges.map((p) => {
      let actions = p?.actions?.flat();
      return {
        subject: p.subject,
        actions: [...new Set(actions)],
      };
    });

    return result;
  },
  mergePermissionsPrivileges: (permissions) => {
    if (!permissions) {
      return [];
    }
    let privileges = permissions ? permissions : [];
    return privileges;
  },
  getDefaultLandingPage: (path, user) => {
    if (path !== '/app') {
      return path;
    }

    if (!user) {
      return '/login';
    }

    // if (user) {
    //   let checked = false;
    //   for (let index = 0; index < user.privileges.length; index++) {
    //     if (user.privileges[index].actions[0] === '1') {
    //       checked = true;
    //       break;
    //     }
    //   }
    //   console.log('checked', checked);
    // }

    if (
      permission.isSuperAdmin(user) ||
      permission.checkPermission(user, 'dispatch_board', ['l'])
    ) {
      return '/app/delivery_mng/lists';
    }

    if (permission.checkPermission(user, 'dm_operation_dashboard', ['l'])) {
      return '/app/digitalmap/monitoring_jobs';
    }

    if (permission.checkPermission(user, 'dm_ttt_operation_dashboard', ['l'])) {
      return '/app/digitalmap/ttt_monitoring_jobs';
    }

    if (permission.checkPermission(user, 'driver_confirm_message', ['l'])) {
      return '/app/driver_confirm';
    }
  },
};

export default permission;
