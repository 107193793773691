import React, { useState, useEffect, useCallback, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { message, Checkbox } from 'antd';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import TableToolbar from './Toolbar';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { confirmDriverTrailerApi } from 'reducers/dispatchBoard/ConfirmDriverTrailer/api';
import { CheckCircleTwoTone, CloseCircleTwoTone } from '@ant-design/icons';

const TableComponent = (props) => {
  const {
    dataTrip,
    loadingUnit,
    clusterId,
    driverSameJob,
    clearTable,
    setClearTable,
    selectedRowsDriver1,
    selectedRowsDriver2,
    setSelectedRowsDriver1,
    setSelectedRowsDriver2,
    onDriverConfirm,
  } = props;
  const payloadRef = useRef(null);
  const selectDiverRef = useRef(null);
  const selectedRowsRef = useRef([]);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  const { height } = useWindowSize();

  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const columns = [
    {
      title: 'TLEP code',
      dataIndex: 'tlep_code',
      width: 80,
      align: 'center',
      movable: true,
    },

    {
      title: 'Driver name',
      dataIndex: 'driver_name',
      width: 80,
      align: 'center',
      movable: true,
      render: (text, record) => {
        return record.driver_name_th;
      },
    },
    {
      title: 'Experience',
      dataIndex: 'experience',
      movable: true,
      align: 'center',
      width: 120,
      render: (text, record) => {
        return text === 'Yes' ? (
          <>
            <CheckCircleTwoTone twoToneColor='#52c41a' /> {text}
          </>
        ) : (
          <>
            <CloseCircleTwoTone twoToneColor='#ff0000' /> {text}
          </>
        );
      },
    },
    {
      title: 'TLEP Type',
      dataIndex: ['tlep_type', 'name_en'],
      movable: true,
      align: 'center',
      width: 120,
    },
    {
      title: 'Status driver',
      dataIndex: 'status_driver',
      movable: true,
      align: 'center',
      width: 120,
    },

    {
      title: 'Remark',
      dataIndex: 'remark',
      align: 'center',
      width: 100,
      movable: true,
      ellipsis: true,
    },
  ];

  useEffect(() => {
    if (height) {
      let h = height - 520;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  useEffect(() => {
    if (clearTable) {
      setDataSource([]);
      setClearTable(false);
      setSelectedRowsDriver1(null);
      setSelectedRowsDriver2(null);
      setSelectedRowKeys([]);
      setChecked(false);
    }
  }, [clearTable, setClearTable]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows.length <= 2) {
        setSelectedRowKeys(selectedRowKeys);
        selectedRowsRef.current = selectedRows;
        setSelectedRowsDriver1(
          selectedRows[0] ? selectedRows[0]?.driver_name_th : null
        );
        setSelectedRowsDriver2(
          selectedRows[1] ? selectedRows[1]?.driver_name_th : null
        );
        onDriverConfirm(selectedRows);
      }
    },
  };

  const executeQuery = useCallback(
    async (payload) => {
      let newData = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          confirmDriverTrailerApi.endpoints.findAllConfirmDrivers.initiate(
            payload,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            newData = result.data;
            let filteredArr = [];
            if (selectedRowsRef.current.length > 0 && result.data.length > 0) {
              let new_select = [];
              new_select.push(...selectedRowsRef.current);
              new_select.push(...newData);

              const arr = new_select;
              const uniqueIds = {};

              filteredArr = arr.filter((obj) => {
                if (!uniqueIds[obj.id]) {
                  uniqueIds[obj.id] = true;
                  return true;
                }
                return false;
              });
              setDataSource(filteredArr);
            } else {
              setDataSource(newData);
            }
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData };
      }
    },
    [dispatch, setErrorData]
  );

  const onInit = useCallback(
    (payload) => {
      let queryPayload = { ...payload };
      payloadRef.current = queryPayload;
      setDataSource([]);
      executeQuery(queryPayload);
    },
    [executeQuery]
  );

  const onQuery = async (payload) => {
    try {
      selectDiverRef.current = selectedRowKeys;
      let payloadNew = {
        ...payload,
        actual_cluster_to_id: clusterId,
      };
      if (!isLoading) {
        payloadRef.current = { ...payloadNew };
        await executeQuery(
          {
            ...payloadRef.current,
          },
          true
        );
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const onSelectDriver = async (e) => {
    setChecked(e.target.checked);
    let tripCheck = dataTrip.find((d) => d.trailer);
    let arrDriver = [];
    if (tripCheck) {
      if (e.target.checked) {
        let selectDriver = {
          experience: 'All',
          tlep_type: tripCheck.driver_1?.tlep_type?.code,
          tlep_code_driver_1: tripCheck.driver_1?.tlep_code,
          tlep_code_driver_2: tripCheck.driver_2?.tlep_code,
          bzp_id: tripCheck?.bzp.id,
          loading_unit: loadingUnit,
          actual_cluster_to_id: clusterId,
        };

        let dataDriver = await executeQuery(selectDriver);

        if (tripCheck.driver_1 && tripCheck.driver_2) {
          setSelectedRowKeys([tripCheck.driver_1.id, tripCheck.driver_2.id]);
          arrDriver.push(tripCheck.driver_1);
          arrDriver.push(tripCheck.driver_2);
        } else {
          setSelectedRowKeys([tripCheck.driver_1?.id]);
          arrDriver.push(tripCheck.driver_1);
        }
        setSelectedRowsDriver1(
          tripCheck.driver_1
            ? `${tripCheck?.driver_1?.fullname_th} ${tripCheck.driver_1?.surname_th}`
            : null
        );
        setSelectedRowsDriver2(
          tripCheck?.driver_2
            ? `${tripCheck.driver_2?.fullname_th} ${tripCheck.driver_2?.surname_th}`
            : null
        );

        onDriverConfirm(
          dataDriver.data.filter(
            (d) =>
              d.id === tripCheck.driver_1?.id || d.id === tripCheck.driver_2?.id
          )
        );
      }
    }
  };
  const onRow = (record) => {
    return {
      onClick: () => {
        const index = selectedRowKeys.indexOf(record.id);
        let newSelectedRowKeys = [];

        if (index === -1) {
          newSelectedRowKeys = [...selectedRowKeys, record.id];
        } else {
          newSelectedRowKeys = [
            ...selectedRowKeys.slice(0, index),
            ...selectedRowKeys.slice(index + 1),
          ];
        }
        if (newSelectedRowKeys.length <= 2) {
          let findRowDriver1 = dataSource.find(
            (n) => n.id === newSelectedRowKeys[0]
          );
          let findRowDriver2 = dataSource.find(
            (n) => n.id === newSelectedRowKeys[1]
          );
          setSelectedRowsDriver1(
            findRowDriver1 ? findRowDriver1?.driver_name_th : null
          );
          setSelectedRowsDriver2(
            findRowDriver2 ? findRowDriver2?.driver_name_th : null
          );
          if (findRowDriver1 && !findRowDriver2) {
            onDriverConfirm([findRowDriver1]);
            selectedRowsRef.current = [findRowDriver1];
          } else if (findRowDriver1 && findRowDriver2) {
            onDriverConfirm([findRowDriver1, findRowDriver2]);
            selectedRowsRef.current = [findRowDriver1, findRowDriver2];
          } else {
            onDriverConfirm([]);
            selectedRowsRef.current = [];
          }
          setSelectedRowKeys(newSelectedRowKeys);
        }
      },
    };
  };

  return (
    <Container>
      <TableToolbar
        onInit={onInit}
        onQuery={onQuery}
        columns={columns}
        isLoading={isLoading}
        selectedRowsDriver1={selectedRowsDriver1}
        selectedRowsDriver2={selectedRowsDriver2}
        bzp={dataTrip && dataTrip[0]?.bzp?.id}
        loadingUnit={loadingUnit}
        clusterId={clusterId}
        clearTable={clearTable}
        selectDiverRef={selectDiverRef}
      />
      <Checkbox
        disabled={driverSameJob}
        checked={checked}
        onChange={onSelectDriver}
        style={{ fontWeight: 'bold', marginBottom: 5 }}
      >
        Select Driver same Job Code
      </Checkbox>
      <AntTable
        rowKey='id'
        tableId={'confirmDriver'}
        rowSelection={{
          ...rowSelection,
        }}
        loading={isLoading}
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        onRow={onRow}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
