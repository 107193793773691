import React, { useEffect } from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import Form from './TripAllowanceForm';

import { useSelector } from 'react-redux';
import { dntaApi } from 'reducers/dnta/api';

const DntaDetailsComponent = (props) => {
  const { setErrorData } = useErrorHandler();
  const dispatch = useDispatch();
  const result = useSelector((state) =>
    dntaApi.endpoints.fetchDeliveryNotes.select()(state)
  );
  const { data, error, isLoading } = result;
  useEffect(() => {
    dispatch(dntaApi.endpoints.fetchDeliveryNotes.initiate());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Delivery Note & Trip Allowance Details'
        subTitle=''
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        {isLoading === true ? (
          <RootCircular>
            <CenterSpin indicator={antIcon} size='large' />
          </RootCircular>
        ) : (
          <Form formData={data} isLoading={isLoading} />
        )}
      </Content>
    </Container>
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: hidden;
`;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default DntaDetailsComponent;
