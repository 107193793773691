import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  Typography,
  Collapse,
  Modal,
  Upload,
  Space,
  message,
} from 'antd';
import styled from 'styled-components';
import {
  ExclamationCircleOutlined,
  UploadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
const { Option } = Select;
const { Panel } = Collapse;

const TableToolbar = (props) => {
  const {
    //parameter
    filterOptionTrailerType,
    filterOptionCompany,
    setFilterSelectStatus,
    setFilterSelectCompany,
    setFilterSelectTrailerType,
    planJobId,
    filterSelectTrailerType,
    filterSelectStatus,
    filterSelectCompany,

    //function
    isLoadingEntry,
    apiSearchTable,
    apiUploadFile,
    apiMatching,
    apiDeleteAllRow,
    isLoadingUploadFile,
    isLoading,
    dataSource,
    setUploadFile,
    uploadFile,
    isLoadingMatching,
    isLoadingDeleteAllRow,
    onDownload,
    isLoadingDownLoad,
    isLoadingEditFixRow,
    onBtnDownloadJobCodeCalendar,
    isLoadingDownloadJobCodeCSV,
    fileList,
    setFileList,
    disableFrom,
    apiCheckFixTourBypass,
    apiConfirmOverDiagramBypass,

    findAlternative,
    isLoadingFindAlternative,
    dataFindAlternative,
    errorFindAlternative,
    FailedJobcodeTours,
  } = props;
  const [IsByPassEnabled, SetIsByPassEnabled] = useState(false);

  useEffect(() => {
    if (!dataSource || dataSource.length === 0) {
      SetIsByPassEnabled(false);
      return;
    }
    const yetToMatch = dataSource.filter((i) => i.status === '');
    if (yetToMatch.length > 0) {
      SetIsByPassEnabled(false);
      return;
    }

    const hasUnMatched = dataSource.filter((i) => i.status === '');
    SetIsByPassEnabled(hasUnMatched.length === 0);
  }, [dataSource]);

  // upload file excel
  const onUpload = (files) => {
    console.log('files', files);
    setUploadFile(files);
  };

  const onBtnSearch = async () => {
    let filterCompany =
      filterSelectCompany && filterSelectCompany.find((f) => f === 'All');
    let filterTrailerType =
      filterSelectTrailerType &&
      filterSelectTrailerType.find((f) => f === 'All');

    let payload = {
      plan_job_id: planJobId,
      status: filterSelectStatus,
      company: filterCompany ? null : filterSelectCompany,
      trailer_type: filterTrailerType ? null : filterSelectTrailerType,
    };
    apiSearchTable(payload);
  };

  const onBtnDownload = async () => {
    onDownload(planJobId);
  };
  const onBtnUploadFile = async () => {
    var data = new FormData();
    data.append('uploadfile', uploadFile);
    data.append('plan_job_id', planJobId);
    if (data) {
      apiUploadFile(data);
    }
  };

  const onBtnDeleteAllRow = () => {
    Modal.confirm({
      title: 'Would you like to delete all row?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        apiDeleteAllRow(planJobId);
        setUploadFile(null);
        setFileList([]);
      },
    });
  };
  const onBtnFindAlternative = async () => {
    Modal.confirm({
      title: 'Would you like to resolve failed job codes?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        let payload = {
          plan_job_id: planJobId,
        };
        console.log('ok find alternative', payload);
        findAlternative(payload);
      },
    });
  };
  const onBtnMatching = async () => {
    Modal.confirm({
      title: 'Would you like to matching?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      cancelText: 'No',
      onOk: () => {
        let payload = {
          plan_job_id: planJobId,
        };
        apiMatching(payload);
      },
    });
  };
  const onChangeFile = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onBtnCheckFixTourBypass = async () => {
    let payload = {
      plan_job_id: planJobId,
    };
    apiCheckFixTourBypass(payload);
  };
  const onBtnOverDiagramBypass = async () => {
    let payload = {
      plan_job_id: planJobId,
    };
    apiConfirmOverDiagramBypass(payload);
  };

  function checkFailedStatus(data) {
    for (const result of data.results) {
      if (result.status === 'Failed') {
        console.log(false);
        return false;
      }
    }
    return true;
  }

  const onCheckFailed = () => {
    if (dataSource?.length === 0) {
      return true;
    } else {
      for (const e of dataSource) {
        if (e.status !== 'Failed') {
          return true;
        }
      }
    }
  };

  return (
    <Container>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Space direction='vertical' style={{ width: '76%' }}>
            <Row
              gutter={[20]}
              style={{ marginBottom: '10px' }}
              align='middle'
              justify='center'
            >
              <Col>
                <Space direction='horizontal'>
                  <Typography>Status</Typography>
                  <Select
                    disabled={disableFrom}
                    value={filterSelectStatus}
                    style={{ width: 120 }}
                    onChange={(value) => {
                      setFilterSelectStatus(value);
                    }}
                    defaultValue={['All']}
                  >
                    <Option>All</Option>
                    <Option value='Matched'>Matched</Option>
                    <Option value='Failed'>Failed</Option>
                  </Select>
                </Space>
              </Col>

              <Col>
                <Space direction='horizontal'>
                  <Typography>Company</Typography>
                  <Select
                    disabled={disableFrom}
                    mode='multiple'
                    style={{
                      textAlign: 'center',
                      width: '200px',
                    }}
                    loading={isLoadingEntry}
                    allowClear={true}
                    maxTagCount='responsive'
                    placeholder={'Company'}
                    value={filterSelectCompany}
                    options={filterOptionCompany}
                    onChange={(values) => {
                      setFilterSelectCompany(values);
                    }}
                    defaultValue={['All']}
                  />
                </Space>
              </Col>
              <Col>
                <Space direction='horizontal'>
                  <Typography>Trailer type</Typography>

                  <Select
                    disabled={disableFrom}
                    mode='multiple'
                    style={{
                      textAlign: 'center',
                      width: '200px',
                    }}
                    loading={isLoadingEntry}
                    allowClear={true}
                    maxTagCount='responsive'
                    placeholder={'Trailer type'}
                    value={filterSelectTrailerType}
                    options={filterOptionTrailerType}
                    onChange={(values) => {
                      setFilterSelectTrailerType(values);
                    }}
                    defaultValue={['All']}
                  />
                </Space>
              </Col>
              <Col>
                <Button
                  type='primary'
                  style={{ width: '100px', marginLeft: '5px' }}
                  onClick={onBtnSearch}
                  loading={isLoading}
                  disabled={
                    !planJobId ||
                    disableFrom ||
                    filterSelectCompany === '' ||
                    isLoading ||
                    isLoadingEditFixRow
                  }
                >
                  Search
                </Button>
              </Col>
            </Row>
            <Row
              gutter={[15]}
              style={{ marginTop: '10px' }}
              align='top'
              justify='top'
            >
              {/* <Col span={2}></Col> */}
              <Col span={6} align='end'>
                <Upload
                  disabled={disableFrom}
                  showUploadList={true}
                  maxCount={1}
                  fileList={fileList}
                  onChange={onChangeFile}
                  accept='.csv'
                  beforeUpload={(file) => {
                    // Modal.confirm({
                    //   title: 'Are you sure want to upload?',
                    //   icon: <ExclamationCircleOutlined />,
                    //   content: `filename: ${file && file.name}`,
                    //   okText: 'Confirm',
                    //   cancelText: 'Cancel',
                    //   onOk: () => {
                    //     onUpload(file);
                    //   },
                    // });

                    // return false;
                    setFileList([file]);
                    const isCSV = file.type === 'text/csv';
                    if (!isCSV) {
                      message.error(`${file.name} is not a .csv file`);
                    } else {
                      onUpload(file);
                      return false;
                    }
                  }}
                >
                  <Button
                    style={{ borderRadius: '8px' }}
                    icon={<UploadOutlined />}
                    disabled={isLoadingEditFixRow || disableFrom}
                  >
                    Select File
                  </Button>
                </Upload>
              </Col>

              <Col span={2}>
                <Button
                  style={{ textAlign: 'center' }}
                  type='primary'
                  loading={isLoadingUploadFile}
                  onClick={onBtnUploadFile}
                  // disabled={!uploadFile || uploadFile === undefined || isLoadingEditFixRow}
                  disabled={
                    fileList.length === 0 ||
                    uploadFile === undefined ||
                    isLoadingEditFixRow ||
                    !planJobId ||
                    disableFrom
                  }
                >
                  Upload
                </Button>
              </Col>

              <Col align='end' style={{ paddingTop: '5px' }}></Col>
              <Col>
                <Button
                  type='ghost'
                  style={{ minWidth: '100px' }}
                  loading={isLoadingFindAlternative}
                  onClick={onBtnFindAlternative}
                  disabled={
                    FailedJobcodeTours.length === 0 ||
                    !dataSource?.length ||
                    isLoadingEditFixRow ||
                    disableFrom ||
                    isLoadingFindAlternative
                  }
                >
                  Job Code resolver
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  style={{ minWidth: '100px' }}
                  loading={isLoadingMatching}
                  onClick={onBtnMatching}
                  disabled={
                    !dataSource?.length || isLoadingEditFixRow || disableFrom
                  }
                >
                  Matching
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  style={{ minWidth: '150px' }}
                  loading={isLoadingDeleteAllRow}
                  onClick={onBtnDeleteAllRow}
                  disabled={
                    !dataSource?.length || isLoadingEditFixRow || disableFrom
                  }
                >
                  Delete all row
                </Button>
              </Col>
              <Col>
                <Button
                  type='primary'
                  style={{ minWidth: '100px' }}
                  loading={isLoadingDownLoad}
                  onClick={onBtnDownload}
                  disabled={!planJobId || disableFrom}
                >
                  Download
                </Button>
              </Col>
              <Col>
                <Button
                  icon={<DownloadOutlined />}
                  type='primary'
                  style={{ minWidth: '180px' }}
                  onClick={onBtnDownloadJobCodeCalendar}
                  loading={isLoadingDownloadJobCodeCSV}
                  disabled={!planJobId || disableFrom}
                >
                  Job Code Calendar
                </Button>
              </Col>
            </Row>
          </Space>
          <Space style={{ marginLeft: 20 }}>
            <Button
              type='primary'
              style={{
                width: '120px',
                height: '70px',
                marginLeft: '5px',
                top: '10px',
              }}
              onClick={onBtnCheckFixTourBypass}
              loading={isLoading}
              disabled={!IsByPassEnabled}
            >
              Fix Tour <br /> Bypass
            </Button>
            <Button
              type='primary'
              style={{
                width: '120px',
                height: '70px',
                marginLeft: '5px',
                top: '10px',
              }}
              onClick={onBtnOverDiagramBypass}
              loading={isLoading}
              disabled={dataSource?.length === 0}
            >
              Import
              <br />
              Over Diagram
            </Button>
          </Space>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default TableToolbar;
