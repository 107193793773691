import React, { useState, useEffect } from 'react';
import {
  Badge,
  Popover,
  Checkbox,
  Button,
  Space,
  Input,
  Select,
  Typography,
  DatePicker,
  Modal,
  message,
  Collapse,
  Statistic,
  Switch,
  InputNumber,
} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useFindOrderLocationsQuery } from 'reducers/digitalmap/operationDashboard/api';
import { getCurrentUser } from 'reducers/user';

import { useSelector, useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { useDebounce } from 'hooks/debounce';
import { FilterOutlined, DownloadOutlined } from '@ant-design/icons';
import { setOperationDashboardFilter } from 'reducers/digitalmap/operationDashboard';
const { Option } = Select;
const { Panel } = Collapse;
const { Countdown } = Statistic;

const validateDatetime = (limitDay, filterDatetime) => {
  if (!filterDatetime || !filterDatetime.fromTime || !filterDatetime.toTime) {
    message.error('Please input datetime range');
    return false;
  }
  if (
    !moment(filterDatetime.toTime).isSameOrAfter(
      moment(filterDatetime.fromTime)
    )
  ) {
    message.error('Datetime range is invalid');
    return false;
  }
  if (
    moment(filterDatetime.toTime).diff(
      moment(filterDatetime.fromTime),
      'days'
    ) > limitDay
  ) {
    message.error(`Datetime range is longer than ${limitDay}`);
    return false;
  }
  return true;
};

const createPayload = ({
  filterToDealers,
  filterToYards,
  filterVehicleStatus,
  filterDeliveryStatus,
  filterEvaStatus,
  filterVinNo,
  filterCallingNo,
  filterDatetime,
}) => {
  return {
    vehicle_status: filterVehicleStatus,
    delivery_status: filterDeliveryStatus,
    eva:
      filterEvaStatus && filterEvaStatus.length > 0
        ? filterEvaStatus.join('&eva=')
        : filterEvaStatus,
    dealers:
      filterToDealers && filterToDealers.length > 0
        ? filterToDealers.join('&dealers=')
        : filterToDealers,
    yards:
      filterToYards && filterToYards.length > 0
        ? filterToYards.join('&yards=')
        : filterToYards,
    vin_no: filterVinNo,
    calling_no: filterCallingNo,
    original_eta_date_from: filterDatetime.fromTime
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:00'),
    original_eta_date_to: filterDatetime.toTime
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:00'),
  };
};

const defaultFilter = {
  filterToDealers: [],
  filterToYards: [],
  filterVehicleStatus: 'on the way',
  filterDeliveryStatus: 'all',
  filterEvaStatus: ['warning', 'delay'],
  filterVinNo: null,
  filterCallingNo: null,
  filterDatetime: {
    fromTime: moment('00:00', 'HH:mm').subtract(7, 'days'),
    toTime: moment('23:59', 'HH:mm').add(3, 'days'),
  },
};

const TableToolbar = (props) => {
  const {
    isLoading,
    columns,
    setColumns,
    onInit,
    onQuery,
    onDownload,
    showFilter,
    setShowFilter,
    onAutoRefetch,
  } = props;
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  // Store filters
  const storedFilters = useSelector((state) => {
    return state.operationDashboard.filters;
  });

  const { setErrorData } = useErrorHandler();
  const [toDealerOptions, setToDealerOptions] = useState([]);
  const [toYardOptions, setToYardOptions] = useState([]);
  const [auto, setAuto] = useState(false);
  const [countdownMins, setCountdownMins] = useState(5);
  const [filterDatetime, setFilterDatetime] = useState(
    storedFilters && storedFilters.filterDatetime
      ? storedFilters.filterDatetime
      : defaultFilter.filterDatetime
  );
  const [filterToDealers, setFilterToDealers] = useState(
    storedFilters && storedFilters.filterToDealers
      ? storedFilters.filterToDealers
      : defaultFilter.filterToDealers
  );
  const [filterToYards, setFilterToYards] = useState(
    storedFilters && storedFilters.filterToYards
      ? storedFilters.filterToYards
      : defaultFilter.filterToYards
  );
  const [filterVehicleStatus, setFilterVehicleStatus] = useState(
    storedFilters && storedFilters.filterVehicleStatus
      ? storedFilters.filterVehicleStatus
      : defaultFilter.filterVehicleStatus
  );
  const [filterDeliveryStatus, setFilterDeliveryStatus] = useState(
    storedFilters && storedFilters.filterDeliveryStatus
      ? storedFilters.filterDeliveryStatus
      : defaultFilter.filterDeliveryStatus
  );
  const [filterEvaStatus, setFilterEvaStatus] = useState(
    storedFilters && storedFilters.filterEvaStatus
      ? storedFilters.filterEvaStatus
      : defaultFilter.filterEvaStatus
  );
  const [filterVinNo, setFilterVinNo] = useState(
    storedFilters && storedFilters.filterVinNo
      ? storedFilters.filterVinNo
      : defaultFilter.filterVinNo
  );
  const [filterCallingNo, setFilterCallingNo] = useState(
    storedFilters && storedFilters.filterCallingNo
      ? storedFilters.filterCallingNo
      : defaultFilter.filterCallingNo
  );
  useEffect(() => {
    if (onInit) {
      onInit(createPayload(defaultFilter));
    }
  }, []);

  const debouncedFilterDatetime = useDebounce(filterDatetime, 1000);

  useEffect(() => {
    dispatch(
      setOperationDashboardFilter({
        filterDatetime: debouncedFilterDatetime,
        filterToDealers,
        filterToYards,
        filterVehicleStatus,
        filterDeliveryStatus,
        filterEvaStatus,
        filterVinNo,
        filterCallingNo,
      })
    );
  }, [
    debouncedFilterDatetime,
    filterToDealers,
    filterToYards,
    filterVehicleStatus,
    filterDeliveryStatus,
    filterEvaStatus,
    filterVinNo,
    filterCallingNo,
    dispatch,
  ]);

  const {
    data: dataOrderLocations,
    error: errorOrderLocations,
    isFetching: isOrderLocationsLoading,
  } = useFindOrderLocationsQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (errorOrderLocations) {
      setErrorData(errorOrderLocations);
    }
  }, [errorOrderLocations, setErrorData]);

  useEffect(() => {
    if (dataOrderLocations) {
      let dealerOptions = [];
      let yardOptions = [];
      dataOrderLocations.forEach((d) => {
        if (['001', '002', '003'].includes(d.location_type_code)) {
          dealerOptions.push({
            label: d.location_shortname,
            value: d.location_shortname,
          });
        }
        if (d.location_type_code === '004') {
          yardOptions.push({
            label: d.location_shortname,
            value: d.location_shortname,
          });
        }
      });

      setToDealerOptions(dealerOptions);
      setToYardOptions(yardOptions);
    }
  }, [dataOrderLocations]);

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '200px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isOrderLocationsLoading,
  };

  const inputProps = {
    style: {
      width: '200px',
    },
  };

  const onColsChange = (e, col) => {
    const nextColumns = [...columns];
    let selectedCol = nextColumns.find((c) => c.title === col.title);
    selectedCol['hide'] = !e.target.checked;
    setColumns(nextColumns);
  };
  const onHideAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = true;
    }
    setColumns(nextColumns);
  };
  const onShowAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = false;
    }
    setColumns(nextColumns);
  };

  const onDisplayButton = () => {
    if (!validateDatetime(10, filterDatetime)) {
      return;
    }
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterToDealers,
          filterToYards,
          filterVehicleStatus,
          filterDeliveryStatus,
          filterEvaStatus,
          filterVinNo,
          filterCallingNo,
          filterDatetime,
        }),
        mode: 'display',
      });
    }
  };
  const onDownloadButton = () => {
    if (!validateDatetime(31, filterDatetime)) {
      return;
    }
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to download?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        if (onDownload) {
          onDownload({
            ...createPayload({
              filterToDealers,
              filterToYards,
              filterVehicleStatus,
              filterDeliveryStatus,
              filterEvaStatus,
              filterVinNo,
              filterCallingNo,
              filterDatetime,
            }),
            mode: 'download',
          });
        }
      },
    });
  };
  const onCountdownFinish = () => {
    if (!validateDatetime(5, filterDatetime)) {
      return;
    }
    if (onAutoRefetch) {
      onAutoRefetch({
        ...createPayload({
          filterToDealers,
          filterToYards,
          filterVehicleStatus,
          filterDeliveryStatus,
          filterEvaStatus,
          filterVinNo,
          filterCallingNo,
          filterDatetime,
        }),
        mode: 'display',
      });
    }
  };
  return (
    <Container>
      <Space direction='horizontal' size={16} align='end'>
        <Popover
          key={'popover'}
          zIndex='10000'
          placement='right'
          content={(() => {
            return [
              <div key={'show-hide-all'}>
                <Button type='link' onClick={onHideAllCols}>
                  Hide all
                </Button>
                /
                <Button type='link' onClick={onShowAllCols}>
                  Show all
                </Button>
                ,
              </div>,
              columns
                .filter((c) => c.title)
                .map((c, index) => {
                  return (
                    <div key={`root-${index}`}>
                      <Checkbox
                        key={index}
                        checked={!c.hide}
                        onChange={(e) => onColsChange(e, c)}
                      >
                        {c.title}
                      </Checkbox>
                    </div>
                  );
                }),
            ];
          })()}
          title='Show/hide Columns'
          trigger='click'
        >
          <Badge dot={columns && columns.some((c) => c.hide)}>
            <Button icon={<FilterOutlined />} size='small' />
          </Badge>
        </Popover>
        <StyledCollapse
          defaultActiveKey={1}
          onChange={() => {
            setShowFilter(!showFilter);
          }}
        >
          <Panel header='Filter Options' key={1}>
            <Space
              direction='horizontal'
              size={16}
              style={{ padding: '5px' }}
              align='end'
            >
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>To Destination</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select destination'}
                      value={filterToDealers}
                      options={toDealerOptions}
                      onChange={(values) => {
                        setFilterToDealers(values);
                      }}
                    />
                  </div>
                  <div>
                    <Typography>To other Yard</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select yard'}
                      value={filterToYards}
                      options={toYardOptions}
                      onChange={(values) => {
                        setFilterToYards(values);
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Calling no.</Typography>
                    <Input
                      {...inputProps}
                      value={filterCallingNo}
                      placeholder='Calling no.'
                      allowClear
                      onChange={(event) => {
                        setFilterCallingNo(event.target.value);
                      }}
                    />
                  </div>
                  <div>
                    <Typography>Vin no.</Typography>
                    <Input
                      {...inputProps}
                      value={filterVinNo}
                      placeholder='Vin no.'
                      allowClear
                      onChange={(event) => {
                        setFilterVinNo(event.target.value);
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Vehicle Status</Typography>
                    <Select
                      defaultValue={defaultFilter.filterVehicleStatus}
                      value={filterVehicleStatus}
                      style={{ width: 180 }}
                      onChange={(value) => {
                        setFilterVehicleStatus(value);
                      }}
                    >
                      <Option value='all'>All</Option>
                      <Option value='arrival'>Arrival</Option>
                      <Option value='gps loss'>GPS Loss</Option>
                      <Option value='on the way'>On the way</Option>
                    </Select>
                  </div>
                  <div>
                    <Typography>Delivery Status</Typography>
                    <Select
                      defaultValue={defaultFilter.filterDeliveryStatus}
                      value={filterDeliveryStatus}
                      style={{ width: 180 }}
                      onChange={(value) => {
                        setFilterDeliveryStatus(value);
                      }}
                    >
                      <Option value='all'>All</Option>
                      <Option value='in-time'>In-time</Option>
                      <Option value='delay'>Delay</Option>
                    </Select>
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>EVA</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select EVA'}
                      defaultValue={defaultFilter.filterEvaStatus}
                      value={filterEvaStatus}
                      style={{ width: 190 }}
                      onChange={(value) => {
                        setFilterEvaStatus(value);
                      }}
                    >
                      <Option value='normal'>Normal</Option>
                      <Option value='warning'>Warning</Option>
                      <Option value='delay'>Delay</Option>
                    </Select>
                  </div>
                  <StatusContainer>
                    <StatusDot>
                      <GreenDot />
                      <Typography style={{ fontSize: '10px' }}>
                        Normal
                      </Typography>
                    </StatusDot>
                    <StatusDot>
                      <OrangeDot />
                      <Typography style={{ fontSize: '10px' }}>
                        Warning
                      </Typography>
                    </StatusDot>
                    <StatusDot>
                      <RedDot />
                      <Typography style={{ fontSize: '10px' }}>
                        Delay
                      </Typography>
                    </StatusDot>
                  </StatusContainer>
                </Space>
              </SearchBox>

              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Original ETA date from</Typography>
                    <DatePicker
                      {...inputProps}
                      value={filterDatetime.fromTime}
                      showTime
                      format={'DD/MM/YYYY HH:mm'}
                      onChange={(value) => {
                        setFilterDatetime({
                          ...filterDatetime,
                          fromTime: value,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Space>
                      <Switch
                        checkedChildren='Auto'
                        unCheckedChildren='Manual'
                        defaultChecked={auto}
                        onChange={(value) => {
                          setAuto(value);
                        }}
                      />
                      {auto && (
                        <InputNumber
                          placeholder='mins.'
                          min={5}
                          max={60}
                          value={countdownMins}
                          onChange={(value) => {
                            setCountdownMins(value);
                          }}
                          formatter={(value) => `${value} mins`}
                          parser={(value) => value.replace(' mins', '')}
                        />
                      )}
                    </Space>
                    {auto && (
                      <StyledCountdown
                        value={Date.now() + 1000 * 60 * countdownMins}
                        onFinish={onCountdownFinish}
                      />
                    )}
                  </div>
                </Space>
              </SearchBox>

              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Original ETA date to</Typography>
                    <DatePicker
                      {...inputProps}
                      value={filterDatetime.toTime}
                      showTime={{
                        defaultValue: moment('23:59', 'HH:mm'),
                      }}
                      format={'DD/MM/YYYY HH:mm'}
                      onChange={(value) => {
                        setFilterDatetime({
                          ...filterDatetime,
                          toTime: value,
                        });
                      }}
                    />
                  </div>
                  <div>
                    <Space direction='vertical' style={{ width: '100%' }}>
                      <StyledButton
                        type='primary'
                        loading={isLoading}
                        onClick={onDisplayButton}
                      >
                        Display
                      </StyledButton>
                      <StyledButton
                        type='primary'
                        loading={isLoading}
                        onClick={onDownloadButton}
                        icon={<DownloadOutlined />}
                      >
                        Download
                      </StyledButton>
                    </Space>
                  </div>
                </Space>
              </SearchBox>
            </Space>
          </Panel>
        </StyledCollapse>
      </Space>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  width: 120px;
`;
const StatusContainer = styled.div`
  display: flex;
  justify-content: center;
`;
const StatusDot = styled.div`
  flex: 1;
  text-align: center;
  margin-top: 5px;
`;
const GreenDot = styled.div`
  height: 25px;
  width: 25px;
  margin: auto;
  background-color: green;
  border-radius: 50%;
  border: 4px solid #ececec;
`;
const OrangeDot = styled.div`
  height: 25px;
  width: 25px;
  margin: auto;
  background-color: #ffa701;
  border-radius: 50%;
  border: 4px solid #ececec;
`;
const RedDot = styled.div`
  height: 25px;
  width: 25px;
  margin: auto;
  background-color: red;
  border-radius: 50%;
  border: 4px solid #ececec;
`;
const SearchBox = styled.div`
  display: block;
  padding: 0px 5px;
  // border-radius: 5px;
  border: 1px none #ececec;

  height: 140px;
`;
const Container = styled.div`
  padding: 10px 0;
`;

const StyledCountdown = styled(Countdown)`
  &&& {
    .ant-statistic-content {
      font-size: 20px;
    }
  }
`;
export default TableToolbar;
