import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Typography, Modal, Space, Row, Col, Card, Spin } from 'antd';
import {
  useClearNotifyIsAckMutation,
  useFindAllTripNotifyIsAckQuery,
  useLazyFindDraftDispatchBoardByIdQuery,
} from '../reducers/user/api';
import AntTable from 'common_components/AntTable';
import {
  BellOutlined,
  InfoCircleOutlined,
  ZoomInOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import styled from 'styled-components';

import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import useWindowSize from 'hooks/windowSize';
import { useErrorHandler } from 'common_components/ErrorContext';

const { Text, Link } = Typography;

const NotifyTripIsAckComponent = () => {
  const location = useLocation();
  const { setErrorData } = useErrorHandler();

  const [isModalNotifyIsAckVisible, setIsModalNotifyIsAckVisible] =
    useState(false);
  const [isModalDetailsVisible, setIsModalDetailsVisible] = useState(false);
  const [draftDispatchList, setDraftDispatchList] = useState([]);
  const [selectedDraftDispatchId, setSelectedDraftDispatchId] = useState(null);
  const { data: notifyIsAckData, isFetching: isFetchingNotifyIsAckData } =
    useFindAllTripNotifyIsAckQuery(null, {
      skip: !location?.pathname.includes('delivery_mng/lists'),
      pollingInterval: 60000, //1 min
    });

  const [
    getDraftDispatchBoardById,
    {
      data: draftDispatch,
      error: errorDraftDispatch,
      isFetching: loadingDraftDispatch,
    },
  ] = useLazyFindDraftDispatchBoardByIdQuery();

  const [clearNotifyIsAck] = useClearNotifyIsAckMutation();

  const [tableHeight, setTableHeight] = useState(0);
  const { height } = useWindowSize();

  useEffect(() => {
    if (height) {
      let h = height - 420;
      setTableHeight(h);
    }
  }, [height]);

  useEffect(() => {
    if (location?.pathname.includes('delivery_mng/lists')) {
      console.log('notifyIsAckData', notifyIsAckData);
      if (notifyIsAckData) {
        if (notifyIsAckData?.length > 0) {
          setDraftDispatchList(
            notifyIsAckData.map((d) => d.draft_dispatch_board)
          );
          setIsModalNotifyIsAckVisible(true);
        }
      }
    }
  }, [location?.pathname, notifyIsAckData]);

  const [columnsPopupConfirm] = useState(() => {
    let cols = [
      {
        title: 'Notice Date/Time',
        dataIndex: 'notify_datetime',
        width: 100,
        align: 'center',
        render: (text) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },

      {
        title: 'Receive job before change',
        dataIndex: 'before_receive_job_datetime',
        width: 100,
        align: 'center',
        render: (text) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },

      {
        title: 'Receive job after change',
        dataIndex: 'after_receive_job_datetime',
        width: 100,
        align: 'center',
        render: (text) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Edit by',
        dataIndex: ['edit_by', 'yard', 'name'],
        width: 80,
        align: 'center',
      },
      {
        title: 'Effect to yard',
        dataIndex: ['effect_to_yard', 'name'],
        width: 80,
        align: 'center',
      },
      {
        title: 'Topic',
        dataIndex: 'topic',
        width: 140,
        align: 'center',
      },
      {
        title: 'Detail',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        render: (_, record) => {
          if (!record?.id) return '-';
          return (
            <Link
              onClick={() => {
                if (
                  loadingDraftDispatch &&
                  record.id === selectedDraftDispatchId
                )
                  return;
                setSelectedDraftDispatchId(record.id);
                getDraftDispatchBoardById(record.id);
                setIsModalDetailsVisible(true);
              }}
            >
              <u>
                {loadingDraftDispatch &&
                selectedDraftDispatchId === record.id ? (
                  <LoadingOutlined
                    style={{
                      fontSize: 20,
                    }}
                  />
                ) : (
                  <ZoomInOutlined
                    style={{
                      fontSize: 20,
                    }}
                  />
                )}
              </u>
            </Link>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        align: 'center',
      },
      {
        title: 'Action Date/ Time',
        dataIndex: 'action_date_time',
        width: 100,
        align: 'center',
        render: (text) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Action By',
        dataIndex: ['action_by', 'yard', 'name'],
        width: 100,
        align: 'center',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: 100,
        align: 'center',
      },
    ];
    return cols;
  });

  useEffect(() => {
    if (errorDraftDispatch) {
      setErrorData(errorDraftDispatch);
      setIsModalDetailsVisible(false);
    }
  }, [errorDraftDispatch, setErrorData]);

  return (
    <>
      <Modal
        title={
          <Text>
            <BellOutlined
              style={{
                fontSize: 16,
              }}
            />{' '}
            Notification Acknowledge (Accept or Reject) List
          </Text>
        }
        width={1500}
        style={{ textAlign: 'center' }}
        visible={isModalNotifyIsAckVisible}
        maskClosable={false}
        onCancel={() => {
          setIsModalNotifyIsAckVisible(false);
        }}
        footer={null}
      >
        <>
          <AntTable
            rowKey={(record) => record.id}
            tableId={'NotificationIsAck'}
            bordered
            showSorterTooltip={false}
            columns={columnsPopupConfirm}
            dataSource={draftDispatchList}
            loading={isFetchingNotifyIsAckData}
            pagination={false}
            width={750}
            height={tableHeight}
            scroll={{
              y: tableHeight,
            }}
          />
        </>
      </Modal>
      <Modal
        title={
          <Text>
            <>
              <InfoCircleOutlined
                style={{ color: '#1890ff', fontSize: '22px' }}
              />
              {'  Combined/Continued Trip Detail'}
            </>
          </Text>
        }
        width={580}
        style={{ textAlign: 'center' }}
        visible={isModalDetailsVisible}
        onCancel={() => {
          setIsModalDetailsVisible(false);
          clearNotifyIsAck();
        }}
        centered
        footer={null}
      >
        {loadingDraftDispatch ? (
          <Spin />
        ) : (
          <>
            Trip no. {draftDispatch?.first_trip_no} Have{' '}
            <Text type='danger'>Change</Text>
            <br />
            Job_code = {draftDispatch ? draftDispatch.job_code : null}
            <br />
            <CardStyle>
              <Row gutter={[24]} align='center' justify='center'>
                <Space size={30}>
                  <Col style={{ textAlign: 'left' }}>
                    <div style={{ textAlign: 'center' }}>
                      <b>Before</b>
                    </div>
                    Trip No. :{' '}
                    {draftDispatch?.dispatch_before
                      ? draftDispatch?.dispatch_before?.trip_no
                      : ''}
                    <br />
                    From :{' '}
                    {draftDispatch?.dispatch_before
                      ? draftDispatch?.dispatch_before?.actual_from?.name
                      : ''}
                    <br />
                    To :{' '}
                    {draftDispatch?.dispatch_before
                      ? draftDispatch?.dispatch_before?.actual_cluster_to?.name
                      : ''}
                    <br />
                    Recieve Job time :
                    <br />
                    {draftDispatch?.dispatch_before
                      ? dateTimeTimestamptzFormatter({
                          value:
                            draftDispatch?.dispatch_before
                              ?.receive_job_datetime,
                        })
                      : ''}
                  </Col>
                  <Col style={{ textAlign: 'left' }}>
                    <div style={{ textAlign: 'center' }}>
                      <b>After</b>
                    </div>
                    Trip No. : {draftDispatch?.dispatch_after?.trip_no}
                    <br />
                    From : {draftDispatch?.dispatch_after?.actual_from?.name}
                    <br />
                    To :{' '}
                    {draftDispatch?.dispatch_after?.actual_cluster_to?.name}
                    <br />
                    Recieve Job time :
                    <br />
                    <Text type='danger'>
                      {dateTimeTimestamptzFormatter({
                        value:
                          draftDispatch?.dispatch_after?.receive_job_datetime,
                      })}
                    </Text>
                  </Col>
                </Space>
              </Row>
            </CardStyle>
          </>
        )}
      </Modal>
    </>
  );
};

export default NotifyTripIsAckComponent;

const CardStyle = styled(Card)`
  margin: 0px 60px;
  margin-top: 20px;
`;
