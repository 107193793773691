import React, { useEffect, useState } from 'react';
import { useFindTrailerByIdQuery } from 'reducers/masterData/trailer/api';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Form from './Form';
import permission from 'permissions';
import { getCurrentUser } from 'reducers/user';
import { useSelector } from 'react-redux';

const TrailerDetailComponent = (props) => {
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(getCurrentUser);
  const { setErrorData } = useErrorHandler();

  const { data, error, isFetching } = useFindTrailerByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (user) {
      if (!permission.checkPermission(user, 'trailer')) {
        message.error('Permission denied');
        dispatch(goBack());
      } else {
        if (!permission.checkPermission(user, 'trailer', ['u'])) {
          setReadonly(true);
        } else {
          setReadonly(false);
        }
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Trailer Details'
        subTitle=''
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        {isFetching === true ? (
          <RootCircular>
            <CenterSpin indicator={antIcon} size='large' />
          </RootCircular>
        ) : (
          <Form
            formData={data || {}}
            isCreate={!id}
            readonly={id ? readonly : false}
          />
        )}
      </Content>
    </Container>
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default TrailerDetailComponent;
