import React, { useEffect, useState } from 'react';
import { useFindPermissionByIdQuery } from 'reducers/configurationPermission/api';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Form from './Form';
import { setSidebarVisibility } from 'reducers/ui';

const PermissionDetailComponent = (props) => {
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { setErrorData } = useErrorHandler();

  const permissionCompanyDiagramOwner = [
    // {
    //   index: 1,
    //   name: 'Company',
    //   subject: 'view_company',
    //   actions: 0,
    // },
    {
      index: 0,
      name: 'Diagram Owner',
      subject: 'view_diagram_owner',
      actions: 1,
    },
  ];
  const permissionFunctionList = [
    {
      name: 'Filter Diagram Owner',
      subject: 'fb_filter_diagram_owner',
      actions: 3,
    },
    {
      name: 'Filter Company',
      subject: 'fb_filter_company',
      actions: 3,
    },
    {
      name: 'Search & Filter',
      subject: 'fp_search_filter_part',
      actions: 3,
    },
    {
      name: 'Auto Back Default',
      subject: 'fp_auto_back_default',
      actions: 3,
    },
    {
      name: 'Download',
      subject: 'fp_download',
      actions: 3,
    },
    {
      name: 'Back Default',
      subject: 'fp_back_default',
      actions: 3,
    },
    {
      name: 'Create trip',
      subject: 'fp_create_trip',
      actions: 3,
    },
    {
      name: 'See Calling complete No. click go calling list',
      subject: 'fp_calling_complete',
      actions: 3,
    },
  ];

  const permissionRightClick = [
    {
      name: 'See Waiting input trailer & Driver click filter',
      subject: 'fp_see_and_click_filter_tr_and_dr',
      actions: 3,
    },
    {
      name: 'Confrim Trailer/Driver',
      subject: 'fp_confirm_tr_or_dr',
      actions: 3,
    },
    {
      name: 'View Calling',
      subject: 'fp_view_calling',
      actions: 3,
    },
    {
      name: 'Edit Plan',
      subject: 'fp_edit_plan',
      actions: 3,
    },
    {
      name: 'Status Change',
      subject: 'fp_status_change',
      actions: 3,
    },
    {
      name: 'Cancel Matching Calling & Diagram',
      subject: 'fp_cancel_matching_calling_and_diagram',
      actions: 3,
    },
    {
      name: 'Cancel Matching TR & DV',
      subject: 'fp_cancel_matching_tr_and_dr',
      actions: 3,
    },
    {
      name: 'Cancel Diagram',
      subject: 'fp_cancel_diagram',
      actions: 3,
    },
    {
      name: 'Print D/N',
      subject: 'fp_print_dn',
      actions: 3,
    },
  ];

  const [dataCompanyDiagramOwner, setdataCompanyDiagramOwner] = useState(
    permissionCompanyDiagramOwner
  );
  const [dataFunctionList, setDataFunctionList] = useState(
    permissionFunctionList
  );
  const [dataRightClick, setDataRightClick] = useState(permissionRightClick);
  const [datasource, setDatasource] = useState([]);
  const [dataName, setDataName] = useState();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const { data: dataPermission, error: errorPermission } =
    useFindPermissionByIdQuery(id, {
      skip: !id,
    });

  useEffect(async () => {
    if (dataPermission) {
      // console.log('dataPermission', dataPermission);
      setDatasource(dataPermission);

      let privilegeList = dataPermission.privilege;

      for (const i in privilegeList) {
        if (Object.hasOwnProperty.call(privilegeList, i)) {
          // if (privilegeList[i].subject === 'view_company') {
          //   permissionCompanyDiagramOwner[0].actions = privilegeList[i].actions;
          // } else

          if (privilegeList[i].subject === 'view_diagram_owner') {
            permissionCompanyDiagramOwner[0].actions = privilegeList[i].actions;
          }
        }
      }

      for (const i in privilegeList) {
        if (Object.hasOwnProperty.call(privilegeList, i)) {
          // if (privilegeList[i].subject === 'fb_filter_company') {
          //   permissionFunctionList[0].actions = privilegeList[i].actions;
          // } else
          if (privilegeList[i].subject === 'fb_filter_diagram_owner') {
            permissionFunctionList[0].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fb_filter_company') {
            permissionFunctionList[1].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_search_filter_part') {
            permissionFunctionList[2].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_auto_back_default') {
            permissionFunctionList[3].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_download') {
            permissionFunctionList[4].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_back_default') {
            permissionFunctionList[5].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_create_trip') {
            permissionFunctionList[6].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_calling_complete') {
            permissionFunctionList[7].actions = privilegeList[i].actions;
          }
        }
      }

      for (const i in privilegeList) {
        if (Object.hasOwnProperty.call(privilegeList, i)) {
          if (
            privilegeList[i].subject === 'fp_see_and_click_filter_tr_and_dr'
          ) {
            permissionRightClick[0].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_confirm_tr_or_dr') {
            permissionRightClick[1].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_view_calling') {
            permissionRightClick[2].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_edit_plan') {
            permissionRightClick[3].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_status_change') {
            permissionRightClick[4].actions = privilegeList[i].actions;
          } else if (
            privilegeList[i].subject ===
            'fp_cancel_matching_calling_and_diagram'
          ) {
            permissionRightClick[5].actions = privilegeList[i].actions;
          } else if (
            privilegeList[i].subject === 'fp_cancel_matching_tr_and_dr'
          ) {
            permissionRightClick[6].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_cancel_diagram') {
            permissionRightClick[7].actions = privilegeList[i].actions;
          } else if (privilegeList[i].subject === 'fp_print_dn') {
            permissionRightClick[8].actions = privilegeList[i].actions;
          }
        }
      }

      setdataCompanyDiagramOwner(permissionCompanyDiagramOwner);
      setDataFunctionList(permissionFunctionList);
      setDataRightClick(permissionRightClick);
    }
  }, [dataPermission]);

  useEffect(() => {
    if (errorPermission) {
      setErrorData(errorPermission);
    }
  }, [errorPermission, setErrorData]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Add Permission'
        subTitle=''
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Form
          dataCompanyDiagramOwner={dataCompanyDiagramOwner}
          dataFunctionList={dataFunctionList}
          dataRightClick={dataRightClick}
          setdataCompanyDiagramOwner={setdataCompanyDiagramOwner}
          setDataFunctionList={setDataFunctionList}
          setDataRightClick={setDataRightClick}
          datasource={datasource}
          isCreate={!id}
          readonly={id && readonly}
          dataName={dataName}
          setDataName={setDataName}
        />
      </Content>
    </Container>
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default PermissionDetailComponent;
