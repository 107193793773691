import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MARKER_SELECTING, SET_USE_TOLL } from 'reducers/map';
import Icon from '@ant-design/icons';
import { ReactComponent as TollWaySvg } from 'assets/icons/tollway.svg';

class WPFloatingBox extends Component {
  handleChange = (e) => {
    if (e.target.checked) {
      this.props.SET_USE_TOLL(0);
    } else {
      this.props.SET_USE_TOLL(1);
    }
  };

  render() {
    let checked = false;
    if (this.props.use_toll === 0) {
      checked = true;
    }
    let totalDistance = 0;
    let totalETA = 0;
    let infoDistance = 0;
    let infoETA;

    return (
      <div
        style={{
          background: `white`,
          border: `1px solid #ccc`,
          padding: 5,
          position: 'absolute',
          left: '10px',
          top: '100px',
        }}
      >
        {!this.props.map_loading && (
          <center>
            <b>Waypoints</b>
          </center>
        )}

        {this.props.waypoints &&
          !this.props.map_loading &&
          this.props.waypoints.map((leg, index) => {
            let divStyle = {
              cursor: 'pointer',
            };
            if (
              this.props.marker_selecting === index ||
              this.props.marker_MovingAdding === index
            )
              divStyle = {
                color: 'red',
                cursor: 'pointer',
              };

            if (index > 0) {
              try {
                totalDistance =
                  totalDistance +
                  Number(this.props.modified_route.Legs[index - 1].Distance);
                totalETA =
                  totalETA +
                  Number(this.props.modified_route.Legs[index - 1].ETA);

                infoDistance = (totalDistance / 1000).toFixed(2) + ' KM';
                infoETA = totalETA + ' min';
              } catch (e) {
                infoDistance = (totalDistance / 1000).toFixed(2) + ' KM';
                infoETA = totalETA + ' min';
              }
            } else {
              infoDistance = '0 KM';
              infoETA = '0 min';
            }

            return (
              <div
                style={divStyle}
                key={index}
                onClick={() => this.props.MARKER_SELECTING(index)}
              >
                {index}# {infoDistance}: {infoETA}
              </div>
            );
          })}
        {this.props.TollUsage === 1 && (
          <div>
            <center>
              <Icon component={TollWaySvg} style={{ fontSize: '28px' }} />
            </center>
          </div>
        )}
        <div valign='center'>
          <input
            type='checkbox'
            defaultChecked={checked}
            name='toll'
            value={this.props.use_toll}
            ref='complete'
            onChange={this.handleChange}
            style={{ marginRight: '5px' }}
          />
          Avoid Tolls
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    modified_route: state.map.modified_route,
    marker_selecting: state.map.marker_selecting,
    marker_MovingAdding: state.map.marker_MovingAdding,
    marker_Dragging: state.map.marker_MovingAdding,
    map_loading: state.map.map_loading,
    waypoints: state.map.waypoints,
    TollUsage: state.map.TollUsage,
    use_toll: state.map.use_toll,
  };
};

const mapActionsToProps = {
  MARKER_SELECTING: MARKER_SELECTING,
  SET_USE_TOLL: SET_USE_TOLL,
};

export default connect(mapStateToProps, mapActionsToProps)(WPFloatingBox);
