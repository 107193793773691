import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  DatePicker,
  Col,
  Row,
  Card,
  message,
} from 'antd';
import {
  useUpdateYardInMutation,
  useCreateYardInMutation,
} from 'reducers/yardin/api';

import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { goBack } from 'connected-react-router';
import moment from 'moment';
import { useFindAllCallingSheetsQuery } from 'reducers/callingsheet/api';
import { useFindAllDriversQuery } from 'reducers/masterData/driver/api';
import { useFindAllTrailersQuery } from 'reducers/masterData/trailer/api';
import AntTable from 'common_components/AntTable';
import MaskedInput from 'antd-mask-input';

const YardInFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  // Query all driver
  const { data: dataDriver, error: errorDriver } = useFindAllDriversQuery();
  useEffect(() => {
    if (errorDriver) {
      setErrorData(errorDriver);
    }
  }, [errorDriver, setErrorData]);

  useEffect(() => {
    if (dataDriver) {
      setCallingsheet(
        dataDriver.map((d) => ({ id: d.id, label: d.employee_code }))
      );
    }
  }, [dataDriver]);

  // Query all Trailer
  const { data: dataTrailer, error: errorTrailer } = useFindAllTrailersQuery();
  useEffect(() => {
    if (errorTrailer) {
      setErrorData(errorTrailer);
    }
  }, [errorTrailer, setErrorData]);

  useEffect(() => {
    if (dataTrailer) {
      setCallingsheet(
        dataTrailer.map((d) => ({ id: d.id, label: d.trailer_card_id }))
      );
    }
  }, [dataTrailer]);

  // Query all callingsheets
  const {
    data: dataCallingsheet,
    error: errorCallingsheet,
    refetch: refetchCallingsheet,
  } = useFindAllCallingSheetsQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataCallingsheet) {
      setCallingsheet(
        dataCallingsheet.map((d) => ({ id: d.id, label: d.callingsheet_no }))
      );
    }
  }, [dataCallingsheet]);

  useEffect(() => {
    if (errorCallingsheet) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load calling sheets',
        okText: 'Retry',
        onOk: refetchCallingsheet,
        cancelText: 'Cancel',
      });
    }
  }, [errorCallingsheet, refetchCallingsheet]);

  const [
    createYardIn,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateYardInMutation();

  const [
    updateYardIn,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateYardInMutation();

  const [callingsheets, setCallingsheet] = useState(
    formData && formData.callingsheet
      ? [
          {
            id: formData.callingsheet.id,
            label: formData.callingsheet.callingsheet_no,
          },
        ]
      : []
  );

  const [dataSourceVins, setdataSourceVins] = useState(
    formData && formData.callingsheet ? formData.callingsheet.vins : []
  );

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);

    let postTrailer = dataTrailer.find(
      (c) => c.trailer_card_id === validData.trailer
    );

    let postDriver1 = dataDriver.find(
      (c) => c.employee_code === validData.driver_1
    );
    let postDriver2 = dataDriver.find(
      (c) => c.employee_code === validData.driver_2
    );
    let postCallingsheet = callingsheets.find(
      (c) => c.label === validData.callingsheet
    );
    let postYardinTime = moment(validData.yardin_time)
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:ss');

    let postData = {
      id: formData && formData.id,
      ...validData,
      trailer: postTrailer && postTrailer.id,
      driver_1: postDriver1 && postDriver1.id,
      driver_2: postDriver2 && postDriver2.id,
      callingsheet: postCallingsheet && postCallingsheet.id,
      yardin_time: postYardinTime,
    };

    if (isCreate) {
      createYardIn(postData);
    } else {
      updateYardIn(postData);
    }
  };

  function handleEnter(event) {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  }

  function onChangeCallingSheet(e) {
    let findVins = dataCallingsheet.find(
      (c) => c.callingsheet_no === e.target.value
    );
    if (findVins !== undefined) {
      setdataSourceVins(findVins.vins);
    } else {
      setdataSourceVins([]);
    }
  }

  const columns = [
    {
      title: 'Vin No.',
      dataIndex: 'vin_no',
      columnWidth: 100,
      searchable: true,
    },
  ];

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 15,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Card
        bordered={true}
        title='Calling Detail'
        extra={
          <>
            <Button
              type='primary'
              htmlType='submit'
              loading={isCreating || isUpdating}
              disabled={readonly}
              style={{ marginRight: 10 }}
            >
              Submit
            </Button>
            <Button
              type='secondary'
              htmlType='reset'
              onClick={() =>
                setdataSourceVins(
                  formData && formData.callingsheet
                    ? formData.callingsheet.vins
                    : []
                )
              }
            >
              Clear
            </Button>
          </>
        }
      >
        <Row>
          <Col flex={1}>
            <Form.Item
              label='Driver 1'
              name='driver_1'
              initialValue={
                formData && formData.driver_1 && formData.driver_1.employee_code
              }
              rules={[
                {
                  required: true,
                  message: 'Please input driver 1',
                },
              ]}
              onKeyDown={handleEnter}
            >
              <MaskedInput
                mask='AAA11111'
                name='driver_1'
                placeholder='Driver 1'
                disabled={readonly}
              />
            </Form.Item>
            <Form.Item
              label='Trailer'
              name='trailer'
              initialValue={
                formData && formData.trailer && formData.trailer.trailer_card_id
              }
              rules={[
                {
                  required: true,
                  message: 'Please input trailer',
                },
              ]}
              onKeyDown={handleEnter}
            >
              <MaskedInput
                mask='AAA111'
                name='trailer'
                placeholder='Trailer'
                disabled={readonly}
              />
            </Form.Item>
            <Form.Item
              label='Calling sheet no.'
              name='callingsheet'
              initialValue={
                formData &&
                formData.callingsheet &&
                formData.callingsheet.callingsheet_no
              }
              rules={[
                {
                  required: true,
                  message: 'Please select calling sheet no.',
                },
              ]}
              onKeyDown={handleEnter}
            >
              <MaskedInput
                mask='111111-A1111'
                name='callingsheet'
                placeholder='Calling sheet no.'
                disabled={readonly}
                onChange={onChangeCallingSheet}
              />
            </Form.Item>

            <Form.Item
              label='Yard In Time'
              name='yardin_time'
              initialValue={formData && moment(formData.yardin_time)}
              rules={[
                {
                  required: true,
                  message: 'Please select yard in time',
                },
              ]}
            >
              <DatePicker showTime disabled={readonly} />
            </Form.Item>
            <Form.Item
              label='Status'
              name='status'
              initialValue={formData && formData.status}
            >
              <Input disabled={readonly} placeholder={'Status'} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item
              label='Driver 2'
              name='driver_2'
              initialValue={
                formData && formData.driver_2 && formData.driver_2.employee_code
              }
              rules={[
                {
                  required: true,
                  message: 'Please input driver 2',
                },
              ]}
              onKeyDown={handleEnter}
            >
              <MaskedInput
                mask='AAA11111'
                name='driver_2'
                placeholder='Driver 2'
                disabled={readonly}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card title='Vin Detail'>
        <AntTable
          rowKey='id'
          tableId={'vin'}
          bordered
          columns={columns}
          dataSource={dataSourceVins === undefined ? [] : dataSourceVins}
          scroll={{ x: 'max-content' }}
          showContextMenu={true}
        />
      </Card>
    </Form>
  );
};

export default YardInFormComponent;
