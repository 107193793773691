import React, { useState, useEffect, useRef, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import EditButtonRenderer from 'common_components/EditButtonRenderer';
import TableToolbar from './Toolbar';
import { push } from 'connected-react-router';
import { message, Modal } from 'antd';
import LinkRenderer from 'common_components/LinkRenderer';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import permission from 'permissions';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import {
  useDeleteOriginalETAMasterMutation,
  originalETAMasterApi,
} from 'reducers/masterData/originalETAMaster/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
// import {
//   setOriginType,
//   setDestinationType,
//   setOriginLocation,
//   setDestinationLocation,
// } from 'reducers/digitalmap/routeMaster';

import {
  setCurrentPage,
  setCurrentOffset,
  setOriginalETAMasterDataSource,
  setSearchFilters,
} from 'reducers/masterData/originalETAMaster';
import useWindowSize from 'hooks/windowSize';
import { useVT } from 'virtualizedtableforantd4';

const LIMIT = 100;
const PAGE_SIZE = 15;

const TableComponent = (props) => {
  const { setTotalRows, totalRows } = props;

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const { height } = useWindowSize();

  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'route_group')
  );

  // Redux Store
  const storedPage = useSelector((state) => {
    return state.originalETAMaster.currentPage;
  });
  const storedOffset = useSelector((state) => {
    return state.originalETAMaster.currentOffset;
  });
  const storedDataSource = useSelector((state) => {
    return state.originalETAMaster.dataSource;
  });

  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const ref = useRef();

  const [offset, setOffset] = useState(storedOffset || 0);
  const [page, setPage] = useState(storedPage || 1);
  const [tableHeight, setTableHeight] = useState(0);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const payloadRef = useRef(null);

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: '',
        dataIndex: 'edit',
        align: 'center',
        render: (text, record) => {
          return (
            <EditButtonRenderer data={record} onClick={handleEditClicked} />
          );
        },
        width: 50,
      },
      {
        title: 'No.',
        dataIndex: 'rowNo',
        width: 40,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Route Code',
        dataIndex: 'route_code',
        width: 100,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },

      {
        title: 'Seq',
        dataIndex: 'seq',
        width: 100,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Origin',
        dataIndex: 'location_from',
        movable: true,
        width: 150,
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='location_from'
              name='short_name'
              path='/app/master/location_masters'
            />
          );
        },
      },
      {
        title: 'Destination',
        dataIndex: 'location_to',
        movable: true,
        width: 150,
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='location_to'
              name='short_name'
              path='/app/master/location_masters'
            />
          );
        },
      },
      {
        title: 'distance',
        dataIndex: 'distance',
        width: 100,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Speed',
        dataIndex: 'speed',
        width: 100,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Created By',
        dataIndex: 'created_by',
        render: (text, record) => {
          return text.username;
        },
        width: 100,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        ellipsis: true,
      },
      {
        title: 'Updated By',
        dataIndex: 'updated_by',
        render: (text, record) => {
          return text.username;
        },
        width: 100,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        ellipsis: true,
      },
      {
        title: 'Created Time',
        dataIndex: 'created_at',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        width: 150,
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
        ellipsis: true,
      },
      {
        title: 'Updated Time',
        dataIndex: 'updated_at',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        width: 150,
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
        ellipsis: true,
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }
    return cols;
  });

  const [
    deleteOriginalETAMaster,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
      isLoading: isDeleting,
    },
  ] = useDeleteOriginalETAMasterMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          originalETAMasterApi.endpoints.findAllOriginalETAMastersByPagination.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              rowNo: i + rowNo,
            }));

            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            setDataSource(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      clearSelectedRows();

      let queryPayload = { ...payloadRef.current, limit: LIMIT, offset: 0 };
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(0, [], queryPayload, true);
    }
  }, [isSuccessDeleting, isUninitDeleting, executeQuery]);

  const onInit = useCallback(
    (payload) => {
      // If having data, no need to load on init
      // if (offset > 0) {
      //   payloadRef.current = { ...payload, limit: LIMIT, offset: offset };
      //  debugger
      //   return;
      // }
      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(0, [], queryPayload, true);
    },
    [executeQuery]
  );

  const onQuery = async (payload) => {
    if (!isLoading) {
      let bufferData = [];
      let requiredRows = page * PAGE_SIZE;
      requiredRows =
        requiredRows > totalRows && totalRows !== 0 ? totalRows : requiredRows;

      if (requiredRows > bufferData.length) {
        // Fetch more data
        let curOffset = 0;
        payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };

        do {
          let queryRes = await executeQuery(
            curOffset,
            bufferData,
            {
              ...payloadRef.current,
              limit: LIMIT,
              offset: curOffset,
            },
            true
          );
          curOffset += LIMIT;
          bufferData = queryRes.data;
          requiredRows =
            requiredRows > queryRes.totalRows
              ? queryRes.totalRows
              : requiredRows;
        } while (requiredRows > bufferData.length);
      }
    }
    setPage(1);
  };

  // const onSearch = useCallback(
  //   (payload) => {
  //     const {
  //       searchFromRegion,
  //       searchToRegion,
  //       searchFromCluster,
  //       searchToCluster,
  //       searchFromLocation,
  //       searchToLocation,
  //     } = payload;
  //     dispatch(setSearchFilters(payload));
  //     clearSelectedRows();
  //     let queryPayload = {
  //       ...payloadRef.current,
  //       limit: LIMIT,
  //       offset: 0,
  //       location_from_region_name:
  //         searchFromRegion && searchFromRegion.length > 0
  //           ? searchFromRegion.join('&location_from_region_name=')
  //           : searchFromRegion,
  //       location_to_region_name:
  //         searchToRegion && searchToRegion.length > 0
  //           ? searchToRegion.join('&location_to_region_name=')
  //           : searchToRegion,
  //       location_from_name:
  //         searchFromCluster && searchFromCluster.length > 0
  //           ? searchFromCluster.join('&location_from_name=')
  //           : searchFromCluster,
  //       location_to_name:
  //         searchToCluster && searchToCluster.length > 0
  //           ? searchToCluster.join('&location_to_name=')
  //           : searchToCluster,
  //       location_from_short_name:
  //         searchFromLocation && searchFromLocation.length > 0
  //           ? searchFromLocation.join('&location_from_short_name=')
  //           : searchFromLocation,
  //       location_to_short_name:
  //         searchToLocation && searchToLocation.length > 0
  //           ? searchToLocation.join('&location_to_short_name=')
  //           : searchToLocation,
  //     };

  //     payloadRef.current = queryPayload;
  //     setOffset(0);
  //     setPage(1);
  //     setDataSource([]);
  //     executeQuery(0, [], queryPayload, true);
  //   },
  //   [executeQuery, dispatch]
  // );

  const onCreate = () => {
    // Clear OD redux state
    // dispatch(setOriginLocation(null));
    // dispatch(setOriginType(null));
    // dispatch(setDestinationLocation(null));
    // dispatch(setDestinationType(null));

    dispatch(push(`/app/master/original_eta_master/create`));
  };

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/master/original_eta_master/${cell.id}`));
  };

  const onDelete = () => {
    //TODO: Bulk delete
    let text = [];
    let data = [];
    for (let row of selectedRows) {
      data = dataSource
        .filter((item) => item.route_code === row.route_code)
        .map((item) => ({
          id: item.id,
          seq: item.seq,
        }));
      for (let index = 0; index < data.length; index++) {
        if (data[index].seq >= row.seq) {
          text = text.concat(data[index].seq);
        }
      }
    }
    Modal.confirm({
      title: `Do you want to confirm the seq after that seq ${text} will also be deleted?`,
      icon: <ExclamationCircleOutlined />,
      // content: 'test',
      //   <>
      //     {t('reasonDashboard.confirm.content')}
      //     <br />
      //     {t('reasonDashboard.confirm.group')} : {validData.group}
      //     <br />
      //     {t('reasonDashboard.confirm.subject')} : {validData.subject}
      //     <br />
      //     {t('reasonDashboard.confirm.description')} : {validData.description}
      //     <br />
      //   </>
      // ),
      onOk: handleConfirmAction,
      // onCancel: 'cancelModalConfirm',
      cancelText: 'Cancel',
      okText: 'Confirm',
      width: 500,
    });
    // console.log(`text`, text)
    // clearSelectedRows();
  };

  const handleConfirmAction = () => {
    for (let row of selectedRows) {
     let data = dataSource
        .filter((item) => item.route_code === row.route_code)
        .map((item) => ({
          id: item.id,
          seq: item.seq,
        }));

      for (let index = 0; index < data.length; index++) {
        if (data[index].seq >= row.seq) {
          deleteOriginalETAMaster({ id: data[index].id });
        }
      }
    }
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setShowDelete(false);
  };

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (!showFilter) {
        h = h + 240;
      }
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  const [vt] = useVT(
    () => ({
      onScroll: async ({ top, isEnd }) => {
        if (isEnd && dataSource.length < totalRows) {
          await executeQuery(offset, dataSource, {
            ...payloadRef.current,
            limit: LIMIT,
            offset: offset,
          });
          ref.current?.scrollTo(top);
        }
      },
      ref,
      scroll: { y: tableHeight },
    }),
    [dataSource, tableHeight]
  );

  useEffect(() => {
    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(setCurrentOffset(offset));
  }, [offset, dispatch]);

  useEffect(() => {
    dispatch(setOriginalETAMasterDataSource(dataSource));
  }, [dataSource, dispatch]);

  const onReload = async () => {
    clearSelectedRows();

    let bufferData = [];
    let requiredRows = page * PAGE_SIZE;
    requiredRows =
      requiredRows > totalRows && totalRows !== 0 ? totalRows : requiredRows;

    if (requiredRows > bufferData.length) {
      // Fetch more data
      let curOffset = 0;
      do {
        let queryRes = await executeQuery(
          curOffset,
          bufferData,
          {
            ...payloadRef.current,
            limit: LIMIT,
            offset: curOffset,
          },
          true
        );
        curOffset += LIMIT;
        bufferData = queryRes.data;
        requiredRows =
          requiredRows > queryRes.totalRows ? queryRes.totalRows : requiredRows;
      } while (requiredRows > bufferData.length);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);

      if (permission.checkPermission(user, 'route_group', ['d'])) {
        setShowDelete(selectedRows.length > 0 ? true : false);
      }
    },
  };

  return (
    <Container>
      <TableToolbar
        onCreate={onCreate}
        onDelete={onDelete}
        onReload={onReload}
        showDelete={showDelete}
        showReload={true}
        isLoading={isLoading}
        isDeleting={isDeleting}
        showAdd={permission.checkPermission(user, 'route_group', ['c'])}
        showSearch={true}
        columns={columns}
        setColumns={setColumns}
        // onSearch={onSearch}
        showFilter={true}
        setShowFilter={setShowFilter}
        onInit={onInit}
        onQuery={onQuery}
      />
      <AntTable
        rowKey='id'
        tableId={'route_group'}
        // rowSelection={rowSelection}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        showSorterTooltip={false}
        loading={isLoading || isDeleting}
        columns={columns}
        dataSource={dataSource}
        // height={tableHeight}
        scroll={{
          y: tableHeight,
          // scrollToFirstRowOnChange: true,
        }}
        components={vt}
        pagination={false}
        // Below is for right click context menu
        showContextMenu={true}
        showFilterDropdown={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: 'Edit', action: (row) => handleEditClicked(row) },
          {
            label: 'Delete',
            action: (row) => {
              deleteOriginalETAMaster({ id: row.id });
            },
            delete: true,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
