import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const operationDashboardSlice = createSlice({
  name: 'dm_operation_dashboard',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setOperationDashboardTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setOperationDashboardDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setOperationDashboardFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setOperationDashboardFilter,
  setCurrentPage,
  setCurrentOffset,
  setOperationDashboardDataSource,
  setOperationDashboardTotalRows,
} = operationDashboardSlice.actions;

export const operationDashboardSelector = (state) => state.operationDashboard;

export default operationDashboardSlice.reducer;
