import React, { useState, useRef } from 'react';
import { Modal as AntModal } from 'antd';
import styled from 'styled-components';
import './index.css';
import Draggable from 'react-draggable';

const Modal = (props) => {
  const { visible, onCancel, onOk, titleModal, children, ...otherModalProps } =
    props;
  const [disabled, setDisabled] = useState(false);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const draggleRef = useRef(null);
  const onStart = (_event, uiData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();

    if (!targetRect) {
      return;
    }

    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };
  return (
    <>
      <StyledModal
        maskClosable={false}
        {...otherModalProps}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}} // end
          >
            {titleModal}
          </div>
        }
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {children}
      </StyledModal>
    </>
  );
};

const StyledModal = styled(AntModal)`
  &&& {
    height: calc(100vh - 80px);
    padding: 20px;
  }
`;

export default Modal;
