import React from 'react';
import PDFCalling from './PDFCalling';
import constants from 'common_components/PrintDocument/constants';

const PrintCalling = ({ componentRef, callingDataList }) => {
  return (
    <div
      id={'print-calling'}
      style={{
        display: 'none',
        width: `${constants.A4.portrait[0]}px`,
        height: `${constants.A4.portrait[1]}px`,
      }}
    >
      <div ref={componentRef} style={{ height: `100%` }}>
        <PDFCalling callingDataList={callingDataList} />
      </div>
    </div>
  );
};

export default PrintCalling;
