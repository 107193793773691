import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  Typography,
  DatePicker,
  Modal,
  Collapse,
  Statistic,
  message,
  PageHeader,
  Divider,
  Layout,
  Input,
  Space,
} from 'antd';
import { push, goBack } from 'connected-react-router';
import styled from 'styled-components';
import moment from 'moment';
import {
  useGetDataOptionCompanyQuery,
  useGetDataOptionDiagramOwnerQuery,
} from 'reducers/dispatchBoard/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { validateDatetime, createPayload } from './utils';
import { useDebounce } from 'hooks/debounce';
import {
  setTTTOperationDashboardFilter,
  setTTTCurrentOffset,
} from 'reducers/digitalmap/tttOperationDashboard';
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  SearchOutlined,
  ReloadOutlined,
  DownloadOutlined,
} from '@ant-design/icons';

import { useSelector, useDispatch } from 'react-redux';
import { setDispatchBoardFilter } from 'reducers/dispatchBoard';
import { Sorter } from 'utils/sorter';
import { RemoveDuplicates } from 'utils/removeDuplicates';
import dispatchBoardConstants from './constants';

const { Title } = Typography;
const { Panel } = Collapse;
const { Countdown } = Statistic;
const { Header } = Layout;
const { Link } = Typography;
const { Option } = Select;

const TableToolbar = (props) => {
  const {
    isDownloading,
    onInit,
    onInitForJobCode,
    onQuery,
    onDownload,
    showFilter,
    setShowFilter,
    onAdd,
    diagramTypeOptions,
    filterOptionFrom,
    setPage,
    summary,
    waitInputTRDVCount,
    fpCompany,
    fpDiagramOwner,
    fpCallingComplete,
    fpCreateTrip,
    totalRows,
    isLoading,
    fromUrl,
    clusterUrl,
    trailer_or_driver,
    numberCallingComplete,
    // createPayload,
    jobCodeOptions,
  } = props;

  const dispatch = useDispatch();

  // Store filters
  const storedFilters = useSelector((state) => {
    return state.dispatchBoard.filters;
  });

  const [filterDatetime, setFilterDatetime] = useState(
    storedFilters && storedFilters.filterDatetime
      ? storedFilters.filterDatetime
      : {
          toTime: moment(),
          fromTime: moment().subtract(
            dispatchBoardConstants.LIMIT_SEARCH_DAYS,
            'days'
          ),
        }
  );

  const [filterCompany, setFilterCompany] = useState(
    storedFilters && storedFilters.filterCompany
      ? storedFilters.filterCompany
      : []
  );

  const [filterCompanyOptions, setFilterCompanyOptions] = useState(
    storedFilters && storedFilters.filterCompanyOptions
      ? storedFilters.filterCompanyOptions
      : []
  );

  const [filterDiagramOwner, setfilterDiagramOwner] = useState(
    storedFilters && storedFilters.filterDiagramOwner
      ? storedFilters.filterDiagramOwner
      : []
  );

  const [filterFromYards, setFilterFromYards] = useState(
    storedFilters && storedFilters.filterFromYards
      ? storedFilters.filterFromYards
      : ['All']
  );

  const [filterJobCode, setFilterJobCode] = useState(
    storedFilters && storedFilters.filterJobCode
      ? storedFilters.filterJobCode
      : []
  );

  const [filterDiagramType, setFilterDiagramType] = useState(
    storedFilters && storedFilters.filterDiagramType
      ? storedFilters.filterDiagramType
      : ['All']
  );

  const [filterStatus, setFilterStatus] = useState(
    storedFilters && storedFilters.filterStatus
      ? storedFilters.filterStatus
      : ['Yard out not complete']
  );

  const [filterCalling, setFilterCalling] = useState(
    storedFilters?.filterCalling
  );

  const debouncedFilterCalling = useDebounce(filterCalling, 1000);

  const [disableFilterUrl, setDisableFilterUrl] = useState(false);

  const deadlineTimeAutoRefresh = Date.now() + 1000 * 60 * 5;

  const onFinishAutoRefresh = () => {
    onCountdownFinish();
  };

  useEffect(() => {
    if (storedFilters?.filterDatetime) {
      setFilterDatetime(storedFilters?.filterDatetime);
    }
  }, [storedFilters?.filterDatetime]);

  const onClickCallingComplete = () => {
    if (fpCallingComplete === 2 || fpCallingComplete === 3) {
      return;
    } else {
      if (defaultDiagramOwner != null) {
        dispatch(push(`/app/delivery_mng/calling_list/${defaultDiagramOwner}`));
      } else {
        dispatch(push(`/app/delivery_mng/calling_list/calling_lists`));
      }
    }
  };

  const { setErrorData } = useErrorHandler();
  const {
    data: companyData,
    error: errorCompany,
    isLoading: isLoadingCompany,
  } = useGetDataOptionCompanyQuery();

  const [companyOptions, setCompanyOptions] = useState();

  useEffect(() => {
    if (companyData) {
      // console.log('companyData', companyData)
      let dataOptionCompany = companyData?.companys;
      let arrayOptionCompany = [];

      //all option company
      for (let index = 0; index < dataOptionCompany?.length; index++) {
        arrayOptionCompany.push({
          label: dataOptionCompany[index].company,
          value: dataOptionCompany[index].bzp_code[0],
        });
      }
      arrayOptionCompany = Sorter.DEFAULTLIST(arrayOptionCompany, 'label');
      arrayOptionCompany = RemoveDuplicates.LISTKEY(
        arrayOptionCompany,
        'label'
      );
      setCompanyOptions(arrayOptionCompany);
      //default value company

      let dataDefualtCompany = [];

      for (let i = 0; i < arrayOptionCompany.length; i++) {
        dataDefualtCompany.push(arrayOptionCompany[i].value);
      }

      if (
        filterCompanyOptions.length !== dataDefualtCompany.length ||
        filterCompanyOptions.some((e) => !dataDefualtCompany.includes(e))
      ) {
        setFilterCompanyOptions([...dataDefualtCompany]);
        setFilterCompany(dataDefualtCompany);
      }
    }
  }, [companyData, filterCompanyOptions, setFilterCompany]);

  useEffect(() => {
    if (fromUrl && clusterUrl) {
      if (trailer_or_driver === 'TR_DV') {
        setFilterStatus(['Yard out not complete']);
      } else {
        setFilterStatus(['All']);
      }
    }
  }, [fromUrl, clusterUrl, trailer_or_driver]);

  useEffect(() => {
    if (errorCompany) {
      setErrorData(errorCompany);
    }
  }, [errorCompany, setErrorData]);

  const [defaultDiagramOwner, setDefaultDiagramOwner] = useState(
    storedFilters && storedFilters.defaultDiagramOwner
      ? storedFilters.defaultDiagramOwner
      : []
  );
  const [diagramOwnerOptions, setDiagramOwnerOptions] = useState();

  const { data: diagramOwnerData, isLoading: isLoadingDiagramOwner } =
    useGetDataOptionDiagramOwnerQuery();

  useEffect(() => {
    if (diagramOwnerData) {
      let dataOptionDiagramOwner = diagramOwnerData?.diagram_owners;
      let diagramOwnersDefault = diagramOwnerData?.defualt_diagram_owner
        ? diagramOwnerData?.defualt_diagram_owner?.id
        : diagramOwnerData?.diagram_owners[0].id;
      let arrayDiagramOwner = [];

      for (let index = 0; index < dataOptionDiagramOwner.length; index++) {
        arrayDiagramOwner.push({
          label: dataOptionDiagramOwner[index].name,
          value: dataOptionDiagramOwner[index].id,
        });
      }
      arrayDiagramOwner = RemoveDuplicates.LISTKEY(arrayDiagramOwner, 'value');
      arrayDiagramOwner = Sorter.DEFAULTLIST(arrayDiagramOwner, 'value');

      if (filterDiagramOwner && filterDiagramOwner.length === 0) {
        setfilterDiagramOwner([diagramOwnersDefault]);
      }
      setDefaultDiagramOwner(diagramOwnersDefault);
      setDiagramOwnerOptions(arrayDiagramOwner);
    }
  }, [diagramOwnerData, filterDiagramOwner, setfilterDiagramOwner]);

  const onClickWaitInputTRDV = () => {
    setPage(1);
    onQuery(
      {
        ...createPayload(dispatch, {
          cluster_to_code:
            clusterUrl && clusterUrl !== 'All' ? [clusterUrl] : null,
          filterCompany,
          filterDiagramOwner,
          filterStatus,
          filterFromYards,
          filterDiagramType,
          filterCalling,
          filterJobCode,
          filterDatetime,
          wait_Input_Tr_Dv: true,
        }),
        mode: 'display',
      },
      1
    );
  };

  const onSearch = () => {
    if (
      !validateDatetime(
        dispatchBoardConstants.LIMIT_SEARCH_DAYS,
        filterDatetime
      )
    ) {
      return;
    }
    if (onQuery) {
      setPage(1);
      onQuery(
        {
          ...createPayload(dispatch, {
            cluster_to_code:
              clusterUrl && clusterUrl !== 'All' ? [clusterUrl] : null,
            filterCompany,
            filterDiagramOwner,
            filterStatus,
            filterFromYards,
            filterDiagramType,
            filterCalling,
            filterJobCode,
            filterDatetime,
          }),
          mode: 'display',
        },
        1
      );
    }
  };

  const onDownloadButton = () => {
    if (
      !validateDatetime(
        dispatchBoardConstants.LIMIT_DOWNLOAD_DAYS,
        filterDatetime
      )
    ) {
      return;
    }
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to download?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        if (onDownload) {
          onDownload({
            ...createPayload(dispatch, {
              cluster_to_code:
                clusterUrl && clusterUrl !== 'All' ? [clusterUrl] : null,
              filterCompany,
              filterDiagramOwner,
              filterStatus,
              filterFromYards,
              filterDiagramType,
              filterCalling,
              filterJobCode,
              filterDatetime,
            }),
            mode: 'download',
          });
        }
      },
    });
  };

  const onCountdownFinish = () => {
    if (
      !validateDatetime(
        dispatchBoardConstants.LIMIT_SEARCH_DAYS,
        filterDatetime
      )
    ) {
      return;
    }
    window.location.reload();
  };

  const onClickOutYard = (mode) => {
    if (
      (summary?.out_yard?.otw_delay !== 0 &&
        summary?.out_yard?.otw_delay !== undefined) ||
      (summary?.out_yard?.arrival_delay !== 0 &&
        summary?.out_yard?.arrival_delay !== undefined)
    ) {
      // Prepare digitalmap tracking filter option before moving to digitalmap tracking page
      let from = filterDatetime.fromTime;
      let to = filterDatetime.toTime;
      if (!to) {
        to = moment().endOf('day');
      } else {
        to = filterDatetime.toTime.clone().endOf('day');
      }

      if (!from) {
        from = to
          .clone()
          .subtract(dispatchBoardConstants.LIMIT_SEARCH_DAYS, 'days')
          .startOf('day');
      } else {
        from = filterDatetime.fromTime.clone().startOf('day');
      }

      let filterDelay = {};
      if (mode === 'otw_delay') {
        filterDelay = {
          filterEvaOnTheWay: ['delay'],
          filterReasonOnTheWay: ['true'],
          checkedOnTheWayList: ['true'],
          filterEvaFinished: [],
          filterReasonFinished: [],
          checkedFinishedList: [],
        };
      } else {
        filterDelay = {
          filterEvaOnTheWay: [],
          filterReasonOnTheWay: [],
          checkedOnTheWayList: [],
          filterEvaFinished: ['delay'],
          filterReasonFinished: [true],
          checkedFinishedList: [true, 'true'],
        };
      }
      dispatch(
        setTTTOperationDashboardFilter({
          filterTTTDatetime: {
            fromTime: from,
            toTime: to,
          },
          ...filterDelay,
        })
      );
      dispatch(setTTTCurrentOffset(0));
      setTimeout(() => {
        dispatch(push(`/app/digitalmap/ttt_monitoring_jobs`));
      }, 200);
    } else {
      message.error('Please check value Out yard !!');
    }
  };

  useEffect(() => {
    if (
      !onInit ||
      filterCompany?.length === 0 ||
      filterDiagramOwner?.length === 0
    ) {
      return;
    }

    if (fromUrl && clusterUrl !== 'All') {
      setFilterFromYards(fromUrl);
      onInit(
        createPayload(dispatch, {
          cluster_to_code: [clusterUrl],
          filterStatus: filterStatus,
          filterFromYards: [fromUrl],
          filterJobCode: filterJobCode,
          filterDiagramType: filterDiagramType,
          filterCompany: filterCompany,
          filterDiagramOwner: filterDiagramOwner,
          filterCalling,
          filterDatetime,
        })
      );
      onInitForJobCode(
        createPayload(dispatch, {
          filterStatus: ['All'],
          filterFromYards: [fromUrl],
          filterJobCode: filterJobCode,
          filterDiagramType: ['All'],
          filterCompany: filterCompany,
          filterDiagramOwner: filterDiagramOwner,
          filterCalling,
          filterDatetime,
        })
      );
    } else if (fromUrl && clusterUrl === 'All') {
      setFilterFromYards(fromUrl);
      onInit(
        createPayload(dispatch, {
          filterStatus: filterStatus,
          filterFromYards: [fromUrl],
          filterJobCode: filterJobCode,
          filterDiagramType: filterDiagramType,
          filterCompany: filterCompany,
          filterDiagramOwner: filterDiagramOwner,
          filterCalling,
          filterDatetime,
        })
      );
      onInitForJobCode(
        createPayload(dispatch, {
          filterStatus: ['All'],
          filterJobCode: filterJobCode,
          filterDiagramType: ['All'],
          filterCompany: filterCompany,
          filterDiagramOwner: filterDiagramOwner,
          filterCalling,
          filterDatetime,
        })
      );
    } else {
      onInit(
        createPayload(dispatch, {
          filterStatus: filterStatus,
          filterFromYards: filterFromYards,
          filterJobCode: filterJobCode,
          filterDiagramType: filterDiagramType,
          filterCompany: filterCompany,
          filterCalling,
          filterDiagramOwner: filterDiagramOwner,
          filterDatetime,
        })
      );
      onInitForJobCode(
        createPayload(dispatch, {
          filterStatus: ['All'],
          filterJobCode: filterJobCode,
          filterDiagramType: ['All'],
          filterCompany: filterCompany,
          filterDiagramOwner: filterDiagramOwner,
          filterCalling,
          filterDatetime,
        })
      );
    }
    //TODO: Check this dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterDiagramOwner,
    filterCompany,
    //onInit,
    // fromUrl,
    // clusterUrl,
    // setFilterFromYards,
    // createPayload,
    // filterJobCode,
    // onInitForJobCode,
    // filterDatetime,
  ]);

  useEffect(() => {
    dispatch(
      setDispatchBoardFilter({
        filterCompany,
        filterDiagramOwner,
        filterDatetime,
        filterStatus,
        filterFromYards,
        filterDiagramType,
        filterJobCode,
        defaultDiagramOwner,
        filterOptionFrom,
        diagramTypeOptions,
        filterCompanyOptions,
        filterCalling: debouncedFilterCalling,
      })
    );
  }, [
    filterCompany,
    filterDiagramOwner,
    filterDatetime,
    filterStatus,
    filterFromYards,
    filterDiagramType,
    filterJobCode,
    defaultDiagramOwner,
    filterOptionFrom,
    diagramTypeOptions,
    filterCompanyOptions,
    debouncedFilterCalling,
    dispatch,
  ]);

  return (
    <Container style={{ paddingTop: '0px' }}>
      <Header
        style={{
          backgroundColor: 'white',
          paddingTop: '0px',
          paddingRight: '0px',
          paddingLeft: '0px',
          height: '40px',
          lineHeight: '40px',
        }}
      >
        <Row gutter={24} align='middle'>
          <Col span={4}>
            <StyledPageHeader
              ghost={false}
              onBack={() => dispatch(goBack())}
              title='Dispatch Board'
            ></StyledPageHeader>
          </Col>
          <Col span={14}>
            <Row justify='end' gutter={24}>
              <Space
                hidden={fpCompany === 3}
                style={{
                  alignSelf: 'center',
                  textAlign: 'right',
                }}
              >
                <StyledText>Company</StyledText>
                <Select
                  disabled={disableFilterUrl}
                  mode='multiple'
                  style={{
                    width: '150px',
                    textAlign: 'center',
                  }}
                  size='small'
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Company'}
                  loading={isLoadingCompany}
                  value={filterCompany}
                  options={companyOptions}
                  filterOption={(input, option) => {
                    return (
                      option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                        0 ||
                      option.value.toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    );
                  }}
                  onChange={(values) => {
                    setFilterCompany(values);
                  }}
                />
              </Space>
              <Space
                hidden={fpDiagramOwner === 3}
                style={{
                  alignSelf: 'center',
                  textAlign: 'right',
                  marginLeft: '10px',
                }}
              >
                <StyledText>Diagram Owner</StyledText>
                <Select
                  style={{
                    width: '150px',
                    textAlign: 'center',
                  }}
                  size='small'
                  allowClear={true}
                  disabled={fpDiagramOwner === 2 || disableFilterUrl}
                  maxTagCount='responsive'
                  placeholder={'Diagram SR'}
                  loading={isLoadingDiagramOwner}
                  value={filterDiagramOwner}
                  options={diagramOwnerOptions}
                  onChange={(values) => {
                    setfilterDiagramOwner(values);
                  }}
                />
              </Space>
              <Space
                hidden={fpDiagramOwner === 3}
                style={{
                  alignSelf: 'center',
                  textAlign: 'right',
                  marginLeft: '10px',
                }}
              >
                <StyledText>Calling No.</StyledText>
                <Input
                  allowClear
                  size='small'
                  style={{ width: '140px' }}
                  placeholder={`Calling no`}
                  value={filterCalling}
                  onChange={(e) => {
                    setFilterCalling(e.target.value.trim());
                  }}
                />
              </Space>
            </Row>
          </Col>
          <Col span={4} align='right'>
            <Space size='middle'>
              <StyledText>
                <ReloadOutlined style={{ marginRight: '5px' }} />
                Auto Refresh
              </StyledText>
              <StyledCountdown
                style={{
                  textAlign: 'center',
                  background: '#FFC0CB',
                  padding: '0 14px',
                }}
                value={deadlineTimeAutoRefresh}
                onFinish={onFinishAutoRefresh}
              />
            </Space>
          </Col>
          <Col span={2} align='right' style={{ marginTop: '4px' }}>
            <Title level={5}>{`Total: ${totalRows}`}</Title>
          </Col>
        </Row>
      </Header>

      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
        style={{ marginTop: '5px' }}
      >
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[24]}
            style={{ marginTop: '10px', marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col
              span={2}
              style={{
                marginTop: '15px',
                textAlign: 'right',
              }}
            >
              <StyledText>Working Date</StyledText>
            </Col>
            <Col span={2} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <StyledText>From</StyledText>
              <DatePicker
                disabled={disableFilterUrl}
                value={filterDatetime.fromTime}
                size='small'
                format={'DD-MM-YY'}
                onChange={(value) => {
                  setFilterDatetime({
                    ...filterDatetime,
                    fromTime: value,
                  });
                }}
              />
            </Col>

            <Typography
              style={{
                marginTop: '15px',
                paddingLeft: '2px',
                paddingRight: '2px',
              }}
            >
              -
            </Typography>

            <Col span={2} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
              <StyledText>To</StyledText>
              <DatePicker
                disabled={disableFilterUrl}
                value={filterDatetime.toTime}
                size='small'
                format={'DD-MM-YY'}
                onChange={(value) => {
                  setFilterDatetime({
                    ...filterDatetime,
                    toTime: value,
                  });
                }}
              />
            </Col>

            <Col span={3}>
              <StyledText>Job code</StyledText>

              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                maxTagCount='responsive'
                // defaultValue={["All"]}
                placeholder={'Job code'}
                value={filterJobCode}
                onChange={(values) => {
                  setFilterJobCode(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {jobCodeOptions &&
                  jobCodeOptions.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3}>
              <StyledText>From</StyledText>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                style={{
                  width: '100%',
                  align: 'center',
                }}
                size='small'
                allowClear={true}
                maxTagCount='responsive'
                placeholder={'From'}
                defaultValue={'All'}
                value={filterFromYards}
                options={filterOptionFrom}
                filterOption={(input, option) => {
                  return (
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >=
                      0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={(values) => {
                  setFilterFromYards(values);
                }}
              />
            </Col>
            <Col span={3}>
              <StyledText>Diagram</StyledText>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                style={{
                  width: '100%',
                  textAlign: 'center',
                }}
                size='small'
                // loading={isDiagramTypesFetching}
                allowClear={true}
                maxTagCount='responsive'
                placeholder={'Diagram Type'}
                defaultValue={'All'}
                value={filterDiagramType}
                options={diagramTypeOptions}
                onChange={(values) => {
                  setFilterDiagramType(values);
                }}
              />
            </Col>
            <Col span={3}>
              <StyledText>Status</StyledText>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                size='small'
                allowClear={true}
                maxTagCount='responsive'
                placeholder={'Status'}
                defaultValue={['Yard out not complete']}
                value={filterStatus}
                options={[
                  'All',
                  'Yard out not complete',
                  'On the way',
                  'Arrival',
                  'Cancel plan',
                ].map((d) => ({ label: d, value: d }))}
                onChange={(values) => {
                  setFilterStatus(values);
                }}
              />
            </Col>
            <Col span={2} style={{ marginTop: '15px' }}>
              <StyledButton
                disabled={disableFilterUrl}
                loading={isLoading}
                type='primary'
                onClick={onCountdownFinish}
                icon={<ReloadOutlined />}
              >
                Refresh
              </StyledButton>
            </Col>
            <Col span={2} style={{ marginTop: '15px' }}>
              <StyledButton
                disabled={disableFilterUrl}
                type='primary'
                loading={isLoading}
                onClick={onSearch}
                icon={<SearchOutlined />}
              >
                Search
              </StyledButton>
            </Col>
          </Row>

          <Divider style={{ marginTop: 2, marginBottom: 0 }} />

          <Row
            gutter={24}
            style={{ marginTop: 10, marginBottom: 5 }}
            align='middle'
            justify='center'
          >
            <Col span={3} style={{ marginTop: 'auto', paddingBottom: '2px' }}>
              <Row align='middle' justify='end'>
                <Col>
                  <StyledText
                    style={{
                      paddingTop: '5px',
                      paddingLeft: '20px',
                    }}
                    hidden={fpCallingComplete === 3}
                    disabled={fpCallingComplete === 2}
                  >
                    Calling complete
                  </StyledText>
                  <ReadonlyText
                    style={{
                      boder: '1px solid',
                      padding: '0px',
                      cursor: 'pointer',
                      fontWeight: 'bold',
                      textDecoration: 'underline',

                      width: '130px',
                    }}
                    onClick={onClickCallingComplete}
                    hidden={fpCallingComplete === 3}
                    disabled={fpCallingComplete === 2}
                  >
                    <Link
                      style={{
                        color: '#000000',
                      }}
                      disabled={fpCallingComplete === 2}
                    >
                      {numberCallingComplete ? numberCallingComplete : '0'}
                    </Link>
                  </ReadonlyText>
                </Col>
              </Row>
              <Row align='middle' justify='end'>
                <Col>
                  <StyledText
                    style={{
                      paddingTop: '5px',
                      paddingLeft: '20px',
                    }}
                  >
                    Wait input TR/DV
                  </StyledText>
                  <ReadonlyText
                    onClick={onClickWaitInputTRDV}
                    style={{
                      boder: '1px solid',
                      width: '130px',
                      padding: '0px',
                      fontWeight: 'bold',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                  >
                    <Link
                      style={{
                        color: '#000000',
                      }}
                    >
                      {waitInputTRDVCount ? waitInputTRDVCount : '0'}
                    </Link>
                  </ReadonlyText>
                </Col>
              </Row>
            </Col>
            {/* this is Receive job show 4 values */}
            <Col span={6}>
              <Row align='midle' justify='center'>
                <Col>
                  <Row
                    align='midle'
                    justify='center'
                    style={{
                      height: '51px',
                      width: '70px',
                      textAlign: 'center',
                      paddingTop: '15px',
                    }}
                  >
                    <StyledTextSummary>Receive job</StyledTextSummary>
                  </Row>
                  <Box1
                    style={{
                      textAlign: 'center',
                      padding: '3.43px',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>Calling</StyledTextSummary>
                  </Box1>
                  <Box1
                    style={{
                      textAlign: 'center',
                      padding: '3.43px',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>TR/DV</StyledTextSummary>
                  </Box1>
                </Col>
                <Col style={{ width: '70px', textAlign: 'center' }}>
                  <Box2>
                    <StyledTextSummary>Warning</StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 4,
                      backgroundColor: 'yellow',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.receive_job.calling_warning : 0}
                    </StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 3,
                      backgroundColor: 'yellow',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.receive_job.tr_dv_warning : 0}
                    </StyledTextSummary>
                  </Box2>
                </Col>
                <Col style={{ width: '70px', textAlign: 'center' }}>
                  <Box2>
                    <StyledTextSummary>Over</StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 4,
                      backgroundColor: '#FFC0CB',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.receive_job.calling_over : 0}
                    </StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 3,
                      backgroundColor: '#FFC0CB',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.receive_job.tr_dv_over : 0}
                    </StyledTextSummary>
                  </Box2>
                </Col>
                <Col></Col>
              </Row>
            </Col>
            {/* this is In yard show 4 values */}
            <Col span={6}>
              <Row align='midle' justify='center'>
                <Col>
                  <Row
                    align='midle'
                    justify='center'
                    style={{
                      height: '51px',
                      width: '70px',
                      textAlign: 'center',
                      paddingTop: '12px',
                    }}
                  >
                    <StyledTextSummary>In yard</StyledTextSummary>
                  </Row>
                  <Box1
                    style={{
                      textAlign: 'center',
                      padding: '4px',
                      width: '75px',
                    }}
                  >
                    <StyledTextSummary>Receive job</StyledTextSummary>
                  </Box1>
                  <Box1
                    style={{
                      textAlign: 'center',
                      padding: '3px',
                      width: '75px',
                    }}
                  >
                    <StyledTextSummary>Yard Out</StyledTextSummary>
                  </Box1>
                </Col>
                <Col style={{ width: '70px', textAlign: 'center' }}>
                  <Box2>
                    <StyledTextSummary>Warning</StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 4,
                      backgroundColor: 'yellow',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.in_yard.receive_job_warning : 0}
                    </StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 3,
                      backgroundColor: 'yellow',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.in_yard.yard_out_warning : 0}
                    </StyledTextSummary>
                  </Box2>
                </Col>
                <Col style={{ width: '70px', textAlign: 'center' }}>
                  <Box2>
                    <StyledTextSummary>Over</StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 4,
                      backgroundColor: '#FFC0CB',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.in_yard.receive_job_over : 0}
                    </StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 3,
                      backgroundColor: '#FFC0CB',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.in_yard.yard_out_over : 0}
                    </StyledTextSummary>
                  </Box2>
                </Col>
                <Col></Col>
              </Row>
            </Col>
            {/* this is Out yard show 3 values */}
            <Col span={6}>
              <Row align='midle' justify='center'>
                <Col>
                  <Row
                    align='midle'
                    justify='center'
                    style={{
                      height: '50px',
                      width: '70px',
                      textAlign: 'center',
                      paddingTop: '12px',
                    }}
                  >
                    <StyledTextSummary>Out yard</StyledTextSummary>
                  </Row>
                  <Box1
                    style={{
                      textAlign: 'center',
                      padding: '4px',
                      width: '75px',
                    }}
                  >
                    <StyledTextSummary>On the way</StyledTextSummary>
                  </Box1>
                  <Box1
                    style={{
                      textAlign: 'center',
                      padding: '3px',
                      width: '75px',
                    }}
                  >
                    <StyledTextSummary>Arrival</StyledTextSummary>
                  </Box1>
                </Col>
                <Col style={{ width: '70px', textAlign: 'center' }}>
                  <Box2 style={{ padding: '5px' }}>
                    <StyledTextSummary>Delay / No Reason</StyledTextSummary>
                  </Box2>
                  <Box2
                    onClick={() => {
                      onClickOutYard('otw_delay');
                    }}
                    style={{
                      textAlign: 'center',
                      padding: 4,
                      cursor: 'pointer',
                      backgroundColor: '#FFC0CB',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary?.out_yard?.otw_delay : 0}
                    </StyledTextSummary>
                  </Box2>
                  <Box2
                    onClick={() => {
                      onClickOutYard('arrival_delay');
                    }}
                    style={{
                      textAlign: 'center',
                      cursor: 'pointer',
                      padding: 3,
                      backgroundColor: '#FFC0CB',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.out_yard.arrival_delay : 0}
                    </StyledTextSummary>
                  </Box2>
                </Col>
                <Col style={{ width: '70px', textAlign: 'center' }}>
                  <Box2 style={{ padding: '5px' }}>
                    <StyledTextSummary>GPS Loss / No ATA</StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 4,
                      backgroundColor: 'black',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary style={{ color: 'white' }}>
                      {summary ? summary.out_yard.arrival_delay : 0}
                    </StyledTextSummary>
                  </Box2>
                  <Box2
                    style={{
                      textAlign: 'center',
                      padding: 3,
                      backgroundColor: '',
                      width: '70px',
                    }}
                  >
                    <StyledTextSummary>
                      {summary ? summary.out_yard.arrival_no_ata : 0}
                    </StyledTextSummary>
                  </Box2>
                </Col>
                <Col></Col>
              </Row>
            </Col>
            <Col span={3}>
              <StyledButton
                disabled={disableFilterUrl}
                style={{
                  width: '100px',
                  align: 'center',
                  marginLeft: '3px',
                  marginBottom: '10px',
                  textAlign: 'center',
                }}
                hidden={fpCreateTrip === 3}
                type='primary'
                onClick={onAdd}
                icon={<PlusCircleOutlined />}
              >
                Create Trip
              </StyledButton>

              <StyledButton
                disabled={disableFilterUrl}
                style={{
                  width: '100px',
                  marginLeft: '3px',
                  align: 'center',
                }}
                type='primary'
                loading={isDownloading}
                onClick={onDownloadButton}
                icon={<DownloadOutlined />}
              >
                Download
              </StyledButton>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};
const StyledText = styled(Typography)`
  font-size: 12px;
`;

const StyledTextSummary = styled(Typography)`
  font-size: 12px;
`;
const ReadonlyText = styled(Typography)`
  background: #fff;
  border: 1px solid #d9d9d9;
  padding: 2px;
  text-align: center;
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  display: block;
  font-size: 10px;
  text-align: center;
`;

const Container = styled.div`
  padding: 10px 0;
`;

const StyledCountdown = styled(Countdown)`
  &&& {
    .ant-statistic-content {
      font-size: 13px;
    }
  }
`;
const Box1 = styled.div`
  border: 1px #ececec;
  border-radius: 0 0 2px 2px;
  width: '50px';
  border: 1px solid #d9d9d9;
  padding: 2px 10px;
`;
const Box2 = styled.div`
  border: 1px #ececec;
  width: '50px';
  border-radius: 0 0 2px 2px;
  border: 1px solid #d9d9d9;
  padding: 15px 5px;
`;
const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;
export default TableToolbar;
