import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  InputNumber,
  TimePicker,
  message,
  Select,
} from 'antd';
import {
  useUpdateClusterMutation,
  useCreateClusterMutation,
} from 'reducers/masterData/cluster/api';
import { useFindAllRegionsQuery } from 'reducers/masterData/region/api';
import { getCurrentUser } from 'reducers/user';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { goBack } from 'connected-react-router';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';

const { Option } = Select;

const FormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const [regions, setRegions] = useState(
    formData && formData.region
      ? [{ id: formData.region.id, label: formData.region.name }]
      : []
  );
  const { setErrorData } = useErrorHandler();
  const [
    createCluster,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateClusterMutation();

  const [
    updateCluster,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateClusterMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  // Query all regions
  const {
    data: dataRegions,
    error: errorRegions,
    isFetching: isRegionsFetching,
    refetch: refetchRegions,
  } = useFindAllRegionsQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataRegions) {
      setRegions(dataRegions.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataRegions]);

  useEffect(() => {
    if (errorRegions) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load regions',
        okText: 'Retry',
        onOk: refetchRegions,
        cancelText: 'Cancel',
      });
    }
  }, [errorRegions, refetchRegions]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let operationTime = moment(validData.operation_time).format('HH:mm:ss');
    let ttlRest = moment(validData.ttl_rest).format('HH:mm:ss');
    let driverRest = moment(validData.driver_rest).format('HH:mm:ss');
    if (operationTime === 'Invalid date') {
      operationTime = null;
    }
    if (ttlRest === 'Invalid date') {
      ttlRest = null;
    }
    if (driverRest === 'Invalid date') {
      driverRest = null;
    }

    let region = regions.find((c) => c.label === validData.region);

    let postData = {
      id: formData && formData.id,
      ...validData,
      operation_time: operationTime,
      ttl_rest: ttlRest,
      driver_rest: driverRest,
      region: region && region.id,
    };

    if (isCreate) {
      createCluster(postData);
    } else {
      updateCluster(postData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Code'
        name='code'
        initialValue={formData && formData.code}
        rules={[
          {
            required: true,
            message: 'Please input valid Code',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Name'
        name='name'
        initialValue={formData && formData.name}
        rules={[
          {
            required: true,
            message: 'Please input your Name',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Name (EN)'
        name='name_en'
        initialValue={formData && formData.name_en}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Name (TH)'
        name='name_th'
        initialValue={formData && formData.name_th}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Zone'
        name='zone'
        initialValue={formData && formData.zone}
        rules={[
          {
            required: true,
            message: 'Please input your Zone',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Region'
        name='region'
        initialValue={formData && formData.region && formData.region.name}
        rules={[
          {
            required: user && user.is_superadmin ? false : true,
            message: 'Please select region',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a region'
          optionFilterProp='children'
          loading={isRegionsFetching}
          disabled={!user || !user.is_superadmin}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {regions &&
            regions.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Category'
        name='category'
        initialValue={formData && formData.category}
        rules={[
          {
            required: true,
            message: 'Please input your Category',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Standard Allowance'
        name='std_allowance'
        initialValue={formData && formData.std_allowance}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Standard OT Hours'
        name='std_ot_hours'
        initialValue={formData && formData.std_ot_hours}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Loading Unit'
        name='loading_unit'
        initialValue={formData && formData.loading_unit}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>

      <Form.Item
        label='Calling Code'
        name='calling_code'
        initialValue={formData && formData.calling_code}
        rules={[
          {
            required: true,
            message: 'Please input your Calling Code',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Calling Shirt Name'
        name='calling_shortname'
        initialValue={formData && formData.calling_shortname}
        rules={[
          {
            required: true,
            message: 'Please input your Calling Shirt Name',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Total Lead Time Hours'
        name='total_lead_time_hours'
        initialValue={formData && formData.total_lead_time_hours}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Loading'
        name='loading'
        initialValue={formData && formData.loading}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Operation Time'
        name='operation_time'
        initialValue={
          formData && formData.operation_time
            ? moment(formData.operation_time, 'HH:mm:ss')
            : null
        }
      >
        <TimePicker style={{ width: 150 }} disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='TTL Rest'
        name='ttl_rest'
        initialValue={
          formData && formData.ttl_rest
            ? moment(formData.ttl_rest, 'HH:mm:ss')
            : null
        }
      >
        <TimePicker style={{ width: 150 }} disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Unload'
        name='unload'
        initialValue={formData && formData.unload}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Driver Rest'
        name='driver_rest'
        initialValue={
          formData && formData.driver_rest
            ? moment(formData.driver_rest, 'HH:mm:ss')
            : null
        }
      >
        <TimePicker style={{ width: 150 }} disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Trailer Lead Time'
        name='trailer_lead_time'
        initialValue={formData && formData.trailer_lead_time}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Driver Lead Time'
        name='driver_lead_time'
        initialValue={formData && formData.trailer_lead_time}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Trailer Requirement'
        name='trailer_req'
        initialValue={formData && formData.trailer_req}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Dealer Requirement'
        name='dealer_req'
        initialValue={formData && formData.dealer_req}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
    </Form>
  );
};

export default FormComponent;
