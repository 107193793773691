import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useErrorHandler } from "common_components/ErrorContext";
import { PageHeader, Divider, message, Modal, Row, Col } from "antd";
import styled from "styled-components";
import { goBack } from "connected-react-router";
import { setSidebarVisibility } from "reducers/ui";
import { Sorter } from "utils/sorter";
import moment from "moment";
import TableResult from "./TableResult";
import TableSub from "./TableSub";
import Toolbar from "./Toolbar";
import DialogCreateByManual from "./DialogCreateByManual";

import {
  useFindSearchTourMutation,
  useCreateBySystemMutation,
  useCreateByManualMutation,
  useCreateByRemainTripMutation,
  useGetDownloadCSVCreateTourMutation,
  useGetProgressBySystemMutation,
  useCreateTourManualDraftMutation,
  createTourApi,
} from "reducers/createTour/api";

const CreateTourComponent = () => {
  const remainTotalRef = useRef(0);
  const LIMIT = 100;
  const PAGE_SIZE = 50;
  const payloadRef = useRef(null);
  const [totalRows, setTotalRows] = useState(0);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const { setErrorData } = useErrorHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDownloadCSV, setIsLoadingDownloadCSV] = useState(false);
  //parameter selected
  const [flterDateFromOriginal, setFlterDateFromOriginal] = useState("");
  const [flterDateToOriginal, setFlterDateToOriginal] = useState("");
  const [filterDateFrom, setFilterDateFrom] = useState("");
  const [filterDateTo, setFilterDateTo] = useState("");
  const [filterVehicleType, setFilterVehicleType] = useState(["All"]);
  const [filterSelectFrom, setFilterSelectFrom] = useState(["All"]);
  const [filterSelectTo, setFilterSelectTo] = useState(["All"]);
  // option
  const [filterOptionDateFrom, setFilterOptionDateFrom] = useState([]);
  const [filterOptionDateTo, setFilterOptionDateTo] = useState([]);
  const [filterOptionVehicleType, setFilterOptionVehicleType] = useState([]);
  const [filterOptionSelectFrom, setFilterOptionSelectFrom] = useState([]);
  const [filterOptionSelectTo, setFilterOptionSelectTo] = useState([]);
  // for table
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowNew, setSelectedRowsNew] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataSourceTable, setDataSourceTable] = useState([]);
  //init
  const [planJobId, setPlanJobId] = useState();
  const [currentJobCode, setCurrentJobCode] = useState();
  //status check
  const [planJobStatus, setPlanJobStatus] = useState(false);
  const [createTourState, setCreateTourState] = useState("ready");
  const progressPercentRef = useRef(0);
  const [loadingProgressBySystem, setLoadingProgressBySystem] = useState(false);
  //modal create by manual
  const [modalCreateTourManual, setModalCreateTourManual] = useState(false);
  const [checkTour, setCheckTour] = useState(null);
  const [ManualList, SetManualList] = useState([]);
  const [DraftList, SetDraftList] = useState([]);
  const [IsDraftLoading, SetDraftLoading] = useState(false);

  // ----------------------------------------------------------------
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const handleRowSelect = (record) => {
    SetManualList([...ManualList, record]);
  };

  function handleRowUnselect(record) {
    const updatedList = ManualList.filter((i) => i.id !== record.id);
    SetManualList([...updatedList]);
  }

  // button
  const onCreateBySystem = () => {
    let payload = {
      plan_job_id: planJobId,
    };
    apiCreateBySystem(payload);
  };
  const onCreateByManual = (_selectedRows) => {
    // let total = ManualList?.length + selectedRowNew?.length;
    // console.log("_selectedRows?.length :>> ", _selectedRows?.length);
    // console.log("selectedRowNew?.length :>> ", selectedRowNew?.length);
    // console.log("ManualList:>> ", ManualList);
    // console.log("total :>> ", total);
    if (_selectedRows?.length <= 5) {
      let selectdata = [];

      const filteredArr = [];
      const ids = [];
      if (selectedRowNew && selectedRowNew.length > 0) {
        selectdata.push(...selectedRowNew);
        selectdata.push(..._selectedRows);
      } else {
        selectdata.push(..._selectedRows);
      }

      for (let i = 0; i < selectdata.length; i++) {
        if (!ids.includes(selectdata[i].id)) {
          filteredArr.push(selectdata[i]);
          ids.push(selectdata[i].id);
        }
      }

      if (selectdata.length > 0) {
        let arrTrips = [];

        for (let i = 0; i < selectdata.length; i++) {
          arrTrips.push(selectdata[i].id);
        }
        const uniqueArr = arrTrips.filter((val, index) => {
          return arrTrips.indexOf(val) === index;
        });

        setSelectedRowKeys(uniqueArr);
        apiCreateByManualDraft(uniqueArr.join());
        //
      } else {
        setSelectedRowKeys([]);
      }
    } else {
      message.error("Please noted that your selected max 5 Trips.");
      handleRowUnselect(_selectedRows);
    }
  };

  const onCreateByRemainTrip = () => {
    let payload = {
      plan_job_id: planJobId,
    };
    apiCreateByRemainTrip(payload);
  };

  const onClickModalCreateTourManual = async () => {
    let createByManualCheck = null;

    if (ManualList.length > 0) {
      let arrTrips = ManualList.map((i) => i.id);
      createByManualCheck = await apiCreateByManualCheck(arrTrips.join());
    }

    if (createByManualCheck?.status === 400) {
      setModalCreateTourManual(false);
      Modal.error({
        title: "Error  Create tour (by manual) result",
        content: (
          <div style={{ padding: "0px" }}>
            {createByManualCheck?.data?.errors.map((val) => (
              <>
                {" "}
                <span key={val}> {val}</span>
                <br />
              </>
            ))}
          </div>
        ),
        width: 600,
      });
    } else {
      setModalCreateTourManual(true);
    }
  };

  const apiCreateByManualCheck = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTourApi.endpoints.createTourManualCheck.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          resData = result.error;
        } else {
          if (result.data) {
            setCheckTour(result.data);
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log("err", err);
    }
  };

  const [
    apiCreateByManualDraft,
    { data: dataCreateByManualDraft, error: errorCreateByManualDraft },
  ] = useCreateTourManualDraftMutation();

  useEffect(() => {
    if (dataCreateByManualDraft) {
      SetDraftList(
        dataCreateByManualDraft ? dataCreateByManualDraft?.data?.updated : []
      );
      SetDraftLoading(false);
    }
  }, [dataCreateByManualDraft, selectedRowNew]);

  useEffect(() => {
    const listIDs = ManualList.map((i) => i.id);
    setSelectedRowKeys(listIDs);

    if (listIDs.length === 0) {
      SetDraftList([]);
      return;
    }
    SetDraftLoading(true);
    apiCreateByManualDraft(listIDs.join());
  }, [ManualList, apiCreateByManualDraft, setSelectedRowKeys]);

  useEffect(() => {
    if (errorCreateByManualDraft) {
      setSelectedRows(
        errorCreateByManualDraft
          ? errorCreateByManualDraft?.data?.data?.updated
          : []
      );
    }
  }, [errorCreateByManualDraft, selectedRowNew]);

  const getInitCreateTour = useCallback(async () => {
    let resData = [];
    try {
      let result = await dispatch(
        createTourApi.endpoints.findCreateTourInit.initiate(null, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data && result.data) {
          let dataInit = result.data;
          if (dataInit?.message === "plan job does not exist") {
            setPlanJobStatus(false);

            Modal.warning({
              title: "ไม่สามารถใช้งานหน้า Create Tour ได้",
              content: (
                <div>
                  <p>เนื่องจาก ขั้นตอนหน้า Create Tour สำเร็จแล้ว</p>
                </div>
              ),
              width: 500,
              onOk() {},
            });
          } else if (dataInit?.message === "plan job step 4 incompleted") {
            setPlanJobStatus(false);

            Modal.warning({
              title: "ไม่สามารถใช้งานหน้า Create Tour ได้",
              content: (
                <div>
                  <p>
                    เนื่องจาก ขั้นตอนหน้า Manage Trip Dashboard ยังไม่สมบูรณ์
                  </p>
                </div>
              ),
              width: 500,
              onOk() {},
            });
          } else {
            setPlanJobId(dataInit?.plan_job_id);
            setPlanJobStatus(true);
            setCurrentJobCode(dataInit?.current_job_code);
            setCreateTourState(dataInit?.create_tour?.state);

            remainTotalRef.current = dataInit?.create_tour?.remaining_trips;
            if (dataInit?.create_tour?.progress) {
              progressPercentRef.current = dataInit?.create_tour?.progress;
            } else {
              progressPercentRef.current = 0;
            }

            setDataResponseInint(dataInit);

            if (dataInit?.create_tour?.state !== "ready") {
              if (progressPercentRef.current === 100) {
              } else {
                if (dataInit?.create_tour?.state === "manual_started") {
                } else {
                  if (dataInit?.create_tour?.state !== "remain_started") {
                    const id = setInterval(frame, 2000);
                    function frame() {
                      if (progressPercentRef.current === 100) {
                        clearInterval(id);
                      } else {
                        let payload = {
                          plan_job_id: dataInit?.plan_job_id,
                        };
                        apiCheckProgressBySystem(payload);
                      }
                    }
                  }
                }
              }
            }
          }

          resData = dataInit;
        }
      }

      return resData;
    } catch (err) {
      console.log("err", err);
    }
  });

  function setDataResponseInint(res) {
    // for selected
    let optionFilter = {};
    optionFilter = res.filters;
    let optionVehicleTypeFilter = optionFilter?.vehicle_type
      ? optionFilter?.vehicle_type
      : [];
    let optionLocationToFilter = optionFilter?.location_to
      ? optionFilter?.location_to
      : [];
    let optionLocationFromFilter = optionFilter?.location_from
      ? optionFilter?.location_from
      : [];

    //------------------------------------------------------------------------------
    // set optionVehicleTypeFilter
    let findOptionVehicleTypeFilter = optionVehicleTypeFilter.map((d) => ({
      code: d.code,
      label: d.name,
      value: d.is_mobile,
    }));

    // set optionLocationToFilter
    let findOptionLocationToFilter = optionLocationToFilter.map((d) => ({
      code: d.code,
      label: d.name,
      value: d.id,
    }));

    // set optionLocationFromFilter
    let findOptionLocationFromFilter = optionLocationFromFilter.map((d) => ({
      code: d.code,
      label: d.name,
      value: d.id,
    }));

    //-----------------------------------------------------------------------

    let optionVehicleTypeList = [
      {
        label: "All",
        value: "All",
        code: "All",
      },
    ];

    let optionLocationFromList = [
      {
        label: "All",
        value: "All",
        code: "All",
      },
    ];

    let optionLocationToList = [
      {
        label: "All",
        value: "All",
        code: "All",
      },
    ];

    optionVehicleTypeList.push(...findOptionVehicleTypeFilter);
    optionLocationFromList.push(...findOptionLocationFromFilter);
    optionLocationToList.push(...findOptionLocationToFilter);

    optionVehicleTypeList = Sorter.DEFAULTLIST(optionVehicleTypeList, "label");

    optionLocationFromList = Sorter.DEFAULTLIST(
      optionLocationFromList,
      "label"
    );
    optionLocationToList = Sorter.DEFAULTLIST(optionLocationToList, "label");

    setFilterOptionVehicleType(optionVehicleTypeList);
    setFilterOptionSelectFrom(optionLocationFromList);
    setFilterOptionSelectTo(optionLocationToList);

    // for dropdown date
    let from_date = res?.period[0];
    let to_date = res?.period[1];
    setFlterDateFromOriginal(from_date);
    setFlterDateToOriginal(to_date);
    setFilterDateFrom(moment(from_date).format("DD/MM/YY"));
    setFilterDateTo(moment(to_date).format("DD/MM/YY"));

    var arr_days_dropdown = [];
    var date_moment_from = moment(from_date);
    var date_moment_to = moment(to_date);
    var diff_date = date_moment_to.diff(date_moment_from, "days");

    for (let i = 0; i <= diff_date; i++) {
      var date_add_days = moment(date_moment_from).add(i, "days").toDate();
      var date_add_format = moment(date_add_days).format("DD/MM/YY");
      arr_days_dropdown.push(date_add_format);
    }
    // for dropdown days
    let datalist_days = arr_days_dropdown.map((d) => ({
      label: d,
      value: d,
    }));

    setFilterOptionDateFrom(datalist_days);
    setFilterOptionDateTo(datalist_days);

    let payload = {
      from: from_date,
      to: to_date,
      vehicle_type: filterVehicleType,
      location_from: filterSelectFrom,
      location_to: filterSelectTo,
      limit: LIMIT,
      offset: offset,
    };

    getSearchByfilter(payload);
  }

  useEffect(() => {
    getInitCreateTour();
  }, []);

  // ----------------------------------------------------------------
  const [
    apiCreateBySystem,
    {
      data: dataCreateBySystem,
      error: errorCreateBySystem,
      isLoading: isLoadingCreateBySystem,
    },
  ] = useCreateBySystemMutation(planJobId);

  useEffect(() => {
    if (dataCreateBySystem) {
      setLoadingProgressBySystem(true);
      progressPercentRef.current = dataCreateBySystem?.progress;
      setCreateTourState(dataCreateBySystem?.state);

      const id = setInterval(frame, 2000);
      async function frame() {
        if (progressPercentRef?.current === 100) {
          clearInterval(id);
          setLoadingProgressBySystem(false);
          let res = await getInitCreateTour();
          if (dataCreateBySystem?.message === "success") {
            Modal.success({
              title: "Create by system success ",
              content: (
                <div style={{ marginTop: "20px" }}>
                  <p>
                    Total tour :{" "}
                    {res?.create_tour?.total_tour_created
                      ? res?.create_tour?.total_tour_created
                      : 0}
                  </p>
                  <p>Remain trip: {res?.create_tour?.remaining_trips}</p>
                </div>
              ),
              onOk() {},
            });
            setDataSourceTable([]);
          }
        } else {
          let payload = {
            plan_job_id: planJobId,
          };
          apiCheckProgressBySystem(payload);
        }
      }
    }
  }, [dataCreateBySystem]);

  useEffect(() => {
    if (errorCreateBySystem) {
    }
  }, [errorCreateBySystem, setErrorData]);

  // ----------------------------------------------------------------
  const [
    apiCheckProgressBySystem,
    { data: dataCheckProgressBySystem, error: errorCheckProgressBySystem },
  ] = useGetProgressBySystemMutation();

  useEffect(() => {
    if (dataCheckProgressBySystem) {
      progressPercentRef.current = dataCheckProgressBySystem?.progress;
    }
  }, [dataCheckProgressBySystem]);

  useEffect(() => {
    if (errorCheckProgressBySystem) {
      setErrorData(errorCheckProgressBySystem);
    }
  }, [errorCheckProgressBySystem, setErrorData]);
  // ----------------------------------------------------------------

  const [
    apiCreateByManual,
    {
      data: dataCreateByManual,
      error: errorCreateByManual,
      isLoading: isLoadingCreateByManual,
    },
  ] = useCreateByManualMutation(planJobId);

  useEffect(() => {
    if (dataCreateByManual) {
      getInitCreateTour();
      Modal.success({
        title: "Create tour completed.",
        content: (
          <div style={{ marginTop: "20px" }}>
            <p>Total tour : {dataCreateByManual?.total_tour}</p>
            <p>Remain trip: {dataCreateByManual?.remaining_trips}</p>
            <p>Recently assiged trip: {dataCreateByManual?.assigned_trips}</p>
            <p>
              Job code created:{" "}
              <ul>
                {dataCreateByManual?.created.split(",").map((code) => (
                  <li>{code}</li>
                ))}
              </ul>
            </p>
          </div>
        ),
        onOk() {
          setSelectedRows([]);
          setSelectedRowKeys([]);
          setModalCreateTourManual(false);
          onBtnSearch();
          setCreateTourState("manual_started");
          SetDraftList([]);
          SetManualList([]);
        },
      });
    }
  }, [dataCreateByManual]);

  useEffect(() => {
    // TODO: check this if throw errors as it should from BE
    if (errorCreateByManual) {
      Modal.error({
        title: "Error  Create tour (by manual) result",
        content: (
          <div>
            <p>Can not match condition</p>
            <p>{errorCreateByManual?.data?.errors}</p>
          </div>
        ),
      });
    }
  }, [errorCreateByManual]);
  // ----------------------------------------------------------------

  const [
    apiCreateByRemainTrip,
    {
      data: dataCreateByRemainTrip,
      error: errorCreateByRemainTrip,
      isLoading: isLoadingCreateByRemainTrip,
    },
  ] = useCreateByRemainTripMutation(planJobId);

  useEffect(() => {
    if (dataCreateByRemainTrip) {
      const modal = Modal.success({
        title: "Create Tour by remain success ",
        width: 500,
        content: (
          <div>
            <Row style={{ paddingTop: "20px" }}>
              <Col style={{ marginRight: "20px" }}>
                <p>1. Total Tour : </p>
                <p>2. Remaining Trips : </p>
              </Col>
              <Col>
                <p>{dataCreateByRemainTrip?.total_tour}</p>
                <p>{dataCreateByRemainTrip?.remaining_trips}</p>
              </Col>
            </Row>
          </div>
        ),
        onOk() {
          setTimeout(async () => {
            modal.destroy();
            progressPercentRef.current = 0;
            getInitCreateTour();
            setDataSourceTable([]);
            setSelectedRows([]);
            setSelectedRowKeys([]);
            setTotalRows(0);
          }, 5000);
        },
      });
    }
  }, [dataCreateByRemainTrip]);

  useEffect(() => {
    if (errorCreateByRemainTrip) {
      setErrorData(errorCreateByRemainTrip);
    }
  }, [errorCreateByRemainTrip, setErrorData]);
  // ----------------------------------------------------------------

  const [
    trigger,
    { download, isLoadingDownload, isFetchingDownload, isErrorDownload },
  ] = useGetDownloadCSVCreateTourMutation();

  const apiDownloadCSV = async () => {
    setIsLoadingDownloadCSV(true);
    trigger(false)
      .unwrap()
      .then((response) => {
        const outputFilename = `create_Tour_${moment().format("YYYYMMDD")}.csv`;
        // If you want to download file automatically using link attribute.
        const url = URL.createObjectURL(
          new Blob([`\ufeff${response}`], { type: "text/csv;charset=utf-8" })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", outputFilename);
        document.body.appendChild(link);
        link.click();
      })
      .finally(() => {
        setIsLoadingDownloadCSV(false);
      });
  };

  const onBtnDownload = () => {
    apiDownloadCSV();
  };
  const onConvertDate = (date) => {
    if (date !== "") {
      const [dateFormat] = date.split(" ");
      const [day, month, year] = dateFormat.split("/");
      const result = ["20" + year, month, day].join("-");
      return result;
    } else {
      return "";
    }
  };
  const [
    getSearchByfilter,
    { data: dataSearch, error: errorSearch, isLoading: isLoadingSearch },
  ] = useFindSearchTourMutation();

  useEffect(() => {
    if (dataSearch) {
      let results = dataSearch?.results;

      let arrResults = results.map((item, index) => ({
        ...item,
        key: index + 1,
        arrival_time: item.arrival_time,
        departure_time: item.departure_time,
        id: item.id,
        location_from: item.location_from,
        location_to: item.location_to,
        plan_job_id: item.plan_job_id,
        receive_job_time: item.receive_job_time,
        return_time: item.return_time,
        unit: item.unit,
        vehicle_type: item.vehicle_type,
        yard_out_time: item.yard_out_time,
      }));

      setDataSourceTable(arrResults);

      let payload = {
        from: onConvertDate(filterDateFrom !== "" ? filterDateFrom : ""),
        to: onConvertDate(filterDateTo !== "" ? filterDateTo : ""),
        vehicle_type: filterVehicleType,
        location_from: filterSelectFrom,
        location_to: filterSelectTo,
        limit: LIMIT,
        offset: offset,
      };

      onBtnSearch(payload);
    }
  }, [dataSearch]);

  const onBtnSearch = async (payload) => {
    try {
      if (!isLoading) {
        setSelectedRowsNew(selectedRows);
        setPage(1);
        let bufferData = [];
        let requiredRows = page * PAGE_SIZE;
        requiredRows =
          requiredRows > totalRows && totalRows !== 0
            ? totalRows
            : requiredRows;
        if (requiredRows > bufferData.length) {
          // Fetch more data
          let curOffset = 0;
          payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };
          do {
            let queryRes = await executeQuery(
              curOffset,
              bufferData,
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            curOffset += LIMIT;
            bufferData = queryRes.data;
            requiredRows =
              requiredRows > queryRes.totalRows
                ? queryRes.totalRows
                : requiredRows;
          } while (requiredRows > bufferData.length);
        }
      }
    } catch (err) {
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (errorSearch) {
      setErrorData(errorSearch);
    }
  }, [errorSearch, setErrorData]);

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          createTourApi.endpoints.findSearchTour.initiate(payload, {
            forceRefetch: forceRefetch ? true : false,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((item, i) => ({
              ...item,
              no: i + rowNo,
              arrival_time: item.arrival_time,
              departure_time: item.departure_time,
              id: item.id,
              location_from: item.location_from,
              location_to: item.location_to,
              plan_job_id: item.plan_job_id,
              receive_job_time: item.receive_job_time,
              return_time: item.return_time,
              unit: item.unit,
              vehicle_type: item.vehicle_type,
              yard_out_time: item.yard_out_time,
            }));
            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            setDataSourceTable(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        console.log(err);
        message.error("Something went wrong.");
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title="Create Tour"
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Toolbar
          // onInit={onInit}
          LIMIT={LIMIT}
          offset={offset}
          flterDateFromOriginal={flterDateFromOriginal}
          setFlterDateFromOriginal={setFlterDateFromOriginal}
          flterDateToOriginal={flterDateToOriginal}
          setFlterDateToOriginal={setFlterDateToOriginal}
          filterDateFrom={filterDateFrom}
          setFilterDateFrom={setFilterDateFrom}
          filterDateTo={filterDateTo}
          setFilterDateTo={setFilterDateTo}
          filterVehicleType={filterVehicleType}
          setFilterVehicleType={setFilterVehicleType}
          filterOptionVehicleType={filterOptionVehicleType}
          setFilterOptionVehicleType={setFilterOptionVehicleType}
          filterSelectFrom={filterSelectFrom}
          setFilterSelectFrom={setFilterSelectFrom}
          filterOptionSelectFrom={filterOptionSelectFrom}
          setFilterOptionSelectFrom={setFilterOptionSelectFrom}
          filterSelectTo={filterSelectTo}
          setFilterSelectTo={setFilterSelectTo}
          filterOptionSelectTo={filterOptionSelectTo}
          setFilterOptionSelectTo={setFilterOptionSelectTo}
          planJobStatus={planJobStatus}
          setPlanJobStatus={setPlanJobStatus}
          filterOptionDateFrom={filterOptionDateFrom}
          filterOptionDateTo={filterOptionDateTo}
          isLoadingCreateByRemainTrip={isLoadingCreateByRemainTrip}
          // isLoadingInit={isLoadingInit}
          remainTotal={remainTotalRef.current}
          // setRemainTotal={remainTotal}
          onCreateBySystem={onCreateBySystem}
          // onCreateByManual={onCreateByManual}
          onCreateByRemainTrip={onCreateByRemainTrip}
          onBtnSearch={onBtnSearch}
          createTourState={createTourState}
          onBtnDownload={onBtnDownload}
          isLoadingDownloadCSV={isLoadingDownloadCSV}
          isLoadingSearch={isLoadingSearch}
          loadingProgressBySystem={loadingProgressBySystem}
          progressPercentRef={progressPercentRef.current}
          isLoadingCreateBySystem={isLoadingCreateBySystem}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          isLoadingCreateByManualCheck={isLoadingCreateByManual}
          onClickModalCreateTourManual={onClickModalCreateTourManual}
          totalRows={totalRows}
          // onInit={onInit}
        />

        <TableResult
          onCreateByManual={onCreateByManual}
          checkRow={handleRowSelect}
          uncheckRow={handleRowUnselect}
          totalRows={totalRows}
          setTotalRows={setTotalRows}
          filterDateFrom={filterDateFrom}
          setFilterDateFrom={setFilterDateFrom}
          filterDateTo={filterDateTo}
          setFilterDateTo={setFilterDateTo}
          filterVehicleType={filterVehicleType}
          setFilterVehicleType={setFilterVehicleType}
          filterOptionVehicleType={filterOptionVehicleType}
          setFilterOptionVehicleType={setFilterOptionVehicleType}
          filterSelectFrom={filterSelectFrom}
          setFilterSelectFrom={setFilterSelectFrom}
          filterOptionSelectFrom={filterOptionSelectFrom}
          setFilterOptionSelectFrom={setFilterOptionSelectFrom}
          filterSelectTo={filterSelectTo}
          setFilterSelectTo={setFilterSelectTo}
          filterOptionSelectTo={filterOptionSelectTo}
          setFilterOptionSelectTo={setFilterOptionSelectTo}
          dataSourceTable={dataSourceTable}
          setDataSourceTable={setDataSourceTable}
          setSelectedRows={setSelectedRows}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          planJobStatus={planJobStatus}
          setPlanJobStatus={setPlanJobStatus}
          isLoadingCreateByRemainTrip={isLoadingCreateByRemainTrip}
          PAGE_SIZE={PAGE_SIZE}
          page={page}
          offset={offset}
          payloadRef={payloadRef}
          LIMIT={LIMIT}
          setPage={setPage}
          executeQuery={executeQuery}
          isLoading={isLoading}
        />
        <TableSub
          selectedRows={DraftList}
          setManualList={SetManualList}
          setSelectedRows={setSelectedRows}
          isLoadingCreateByRemainTrip={
            IsDraftLoading || isLoadingCreateByRemainTrip
          }
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          planJobStatus={planJobStatus}
          setPlanJobStatus={setPlanJobStatus}
          loadingProgressBySystem={loadingProgressBySystem}
        />
        <DialogCreateByManual
          dataCreateByManualCheck={checkTour}
          selectedRows={selectedRows}
          currentJobCode={currentJobCode}
          setSelectedRows={setSelectedRows}
          modalCreateTourManual={modalCreateTourManual}
          setModalCreateTourManual={setModalCreateTourManual}
          warningCheckTour={checkTour}
          checkTour={checkTour}
          apiCreateByManual={apiCreateByManual}
          isLoadingCreateByManual={isLoadingCreateByManual}
        />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default CreateTourComponent;
