import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Modal } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import {
  useFindManageTripBoardTrailerUsageSummaryQuery,
  manageTripBoardApi,
  useConfirmManageTripBoardMutation,
} from 'reducers/manageTripBoard/api';
import Table from './TableMain';
import { dateFormatterVLP } from 'common_components/AntTable/tableUtils';

const CreateLotIndexComponent = () => {
  const statusEditRowRef = useRef(false);
  const planJobIdRef = useRef();
  const workingDateRef = useRef();
  const locationFromIdRef = useRef();
  const dataSourceTableMainRef = useRef();

  const dispatch = useDispatch();

  const { setErrorData } = useErrorHandler();
  // for table

  const [filterSelectDate, setFilterSelectDate] = useState();
  const [detailGraph, setDetailGraph] = useState();
  const [detail, setDetail] = useState();
  const [yardDate, setYardDate] = useState();
  const [filterOptionDate, setFilterOptionDate] = useState();
  const [filterSelectFrom, setFilterSelectFrom] = useState();
  const [filterOptionFrom, setFilterOptionFrom] = useState();

  const [dataClusterByRegionTable, setDataClusterByRegionTable] = useState([]);
  const [dataSubClusterTable, setDataSubClusterTable] = useState([]);
  const [dataEditUnit, setDataEditUnit] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // for table Volume
  const [dataSourceTableMain, setDataSourceTableMain] = useState([]);
  const [planJobId, setPlanJobId] = useState();
  const [isLoading, setIsLoading] = useState(false);
  //status manage trip
  const [statusManageTrip, setStatusManageTrip] = useState(null);
  // save record and row for search after update success
  const [saveRecord, setSaveRecord] = useState();
  const [textRecord, setTextRecord] = useState();

  const titleDateRef = useRef([]);
  const dataSourceSubClusterTableRef = useRef([]);

  // for API Confirm
  const [
    confirmManageTripBoard,
    { data: dataConfirm, error: errorConfirm, isLoading: isLoadingConfirm },
  ] = useConfirmManageTripBoardMutation();

  useEffect(() => {
    if (dataConfirm) {
      Modal.success({
        title: 'Confirm Manage TripBoard',
        onOk() {},
      });

      refetch();
    }
  }, [dataConfirm]);

  useEffect(() => {
    if (errorConfirm) {
      setErrorData(errorConfirm);
    }
  }, [errorConfirm, setErrorData]);

  //button------------------------------------------------------------
  const onSearchTable = async (statusSave) => {
    let payload = {
      plan_job_id: planJobId,
      working_date: filterSelectDate,
      location_from_id: filterSelectFrom,
    };

    planJobIdRef.current = planJobId;
    workingDateRef.current = filterSelectDate;
    locationFromIdRef.current = filterSelectFrom;

    onQuery(payload);
  };

  const onQuery = async (value) => {
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          manageTripBoardApi.endpoints.findAllManageTripBoardSummaryTrip.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            setDataSourceTableMain(result.data);
            dataSourceTableMainRef.current = result.data;

            setDetail([]);
            setDetailGraph([]);

            // headerDateTableRef.current = result.data.results;
            // setDataSource(result.data.results);
            // setDataSource([result.data.reason_and_lane]);
          }
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const {
    data,
    isLoading: isLoadingEntry,
    refetch,
  } = useFindManageTripBoardTrailerUsageSummaryQuery();

  useEffect(() => {
    if (data) {
      setStatusManageTrip(data.status_m_trip_dash);
      let findSelectFrom = data.location_from.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));
      let findSelectDate = data.date.map((d) => ({
        label: dateFormatterVLP({ value: d.fdate }),
        value: d.fdate,
      }));

      var yard = Object.keys(data.trailer_usage_summary);
      let arrYard = [];
      for (let index = 0; index < yard.length; index++) {
        let keyYard = yard[index];
        arrYard.push({
          id: index,
          code: keyYard,
          yard: data.trailer_usage_summary[keyYard].yard,
          date: data.trailer_usage_summary[keyYard].date,
        });
      }

      setPlanJobId(data?.plan_job_id);
      setFilterOptionDate(findSelectDate);
      setFilterOptionFrom(findSelectFrom);
      setYardDate(arrYard);

      titleDateRef.current = data.date;
    }
  }, [data]);

  const callOnLinkRegion = (record, time) => {
    let payload = null;
    if (record?.title === 'Usage all region') {
      payload = {
        plan_job_id: planJobIdRef.current,
        working_date: workingDateRef.current,
        location_from_id: locationFromIdRef.current,
        time: time,
      };
    } else if (!record?.children) {
      let arrMain = [];
      for (
        let index = 0;
        index < dataSourceTableMainRef.current.length;
        index++
      ) {
        if (dataSourceTableMainRef.current[index]?.children) {
          for (
            let index2 = 0;
            index2 < dataSourceTableMainRef.current[index].children.length;
            index2++
          ) {
            if (
              dataSourceTableMainRef.current[index]?.children[index2]?.title ===
              record?.title
            ) {
              arrMain.push(dataSourceTableMainRef.current[index]);
            }
          }
        }
      }

      payload = {
        plan_job_id: planJobIdRef.current,
        working_date: workingDateRef.current,
        location_from_id: locationFromIdRef.current,
        time: time,
        mode: 'cluster',
        region_name: arrMain.length > 0 ? arrMain[0]?.title : null,
        cluster_code: record?.title,
      };
    } else {
      payload = {
        plan_job_id: planJobIdRef.current,
        working_date: workingDateRef.current,
        location_from_id: locationFromIdRef.current,
        time: time,
        mode: 'region',
        region_name: record?.title,
      };
    }
    apiManageTripDetailGraph(payload);
    apiManageTripDetail(payload);
  };

  const apiManageTripDetailGraph = async (value) => {
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          manageTripBoardApi.endpoints.findManageTripDetailGraph.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            setDetailGraph(result.data);
          }
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };
  const apiManageTripDetail = async (value) => {
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          manageTripBoardApi.endpoints.findManageTripDetail.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let arrAddEditRowTable = result.data.map((item) => ({
              ...item,
              editingRow: false,
            }));
            statusEditRowRef.current = false;
      
            setDetail(arrAddEditRowTable);
          }
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const callUpdateDetail = (record) => {
    let payload = {
      id: record?.id,
      from_id: record?.from_id,
      cluster_to_id: record?.cluster_to_id,
      receive_job_time: record?.receive_job_time,
      yard_out_time: record?.yard_out_time,
      arrival_time: record?.arrival_time,
      departure_time: record?.departure_time,
    };
    apiUpdateDetail(payload);
  };

  const apiUpdateDetail = async (value) => {
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          manageTripBoardApi.endpoints.updateManageTripDetail.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            setDetail(result.data);
          }
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dataSubClusterTable) {
      dataSourceSubClusterTableRef.current = dataSubClusterTable.sub_cluster;
    }
  }, [dataSubClusterTable]);

  // ----------------------------------------------------------------

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Manage Trip DashBoard'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Table
          //parameter
          yardDate={yardDate}
          titleDate={titleDateRef}
          createLotSummaryAll={data}
          filterSelectDate={filterSelectDate}
          filterOptionDate={filterOptionDate}
          filterSelectFrom={filterSelectFrom}
          filterOptionFrom={filterOptionFrom}
          setFilterSelectDate={setFilterSelectDate}
          setFilterOptionDate={setFilterOptionDate}
          setFilterSelectFrom={setFilterSelectFrom}
          setFilterOptionFrom={setFilterOptionFrom}
          isLoadingEntry={isLoadingEntry}
          dataSourceTableMain={dataSourceTableMain}
          dataClusterByRegionTable={dataClusterByRegionTable}
          setDataClusterByRegionTable={setDataClusterByRegionTable}
          dataSubClusterTable={dataSubClusterTable}
          setDataSubClusterTable={setDataSubClusterTable}
          dataEditUnit={dataEditUnit}
          setDataEditUnit={setDataEditUnit}
          //function
          apiSearchTable={onSearchTable}
          callOnLinkRegion={callOnLinkRegion}
          dataSourceSubClusterTableRef={dataSourceSubClusterTableRef}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          isLoading={isLoading}
          detailGraph={detailGraph}
          detail={detail}
          setDetail={setDetail}
          updateDetail={callUpdateDetail}
          // for confirm
          planJobId={planJobId}
          confirmManageTripBoard={confirmManageTripBoard}
          isLoadingConfirm={isLoadingConfirm}
          //status
          statusManageTrip={statusManageTrip}
          //for update time success
          saveRecord={saveRecord}
          textRecord={textRecord}
          setSaveRecord={setSaveRecord}
          setTextRecord={setTextRecord}
          apiSearchTableSave={onSearchTable}
          statusEditRowRef={statusEditRowRef}
        />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default CreateLotIndexComponent;
