import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Space,
  Select,
  Typography,
  Collapse,
  Row,
  message,
  Card,
  Col,
  Input,
} from 'antd';
import styled from 'styled-components';

import { useErrorHandler } from 'common_components/ErrorContext';
import { useDispatch } from 'react-redux';
import { dnListApi } from 'reducers/dnList/api';
import { SearchOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { Option } = Select;

const createPayload = ({
  filterDnNo,
  filterVin,
  filterCallingNo,
  filterYard,
  filterCompany,
  filterVehicleType,
}) => {
  return {
    dn_no: filterDnNo ? filterDnNo : null,
    vin_no: filterVin ? filterVin : null,
    calling_no: filterCallingNo ? filterCallingNo : null,
    yard_id: filterYard,
    company_id:
      filterCompany && filterCompany.length > 0 ? filterCompany : null,
    vehicle_type_id:
      filterVehicleType && filterVehicleType.length > 0
        ? filterVehicleType
        : null,
  };
};

const TableToolbar = (props) => {
  const { isLoading, onQuery, showFilter, setShowFilter } = props;
  const dispatch = useDispatch();

  const { setErrorData } = useErrorHandler();
  const [yardOptions, setYardOptions] = useState([]);
  const onQueryOption = useCallback(async () => {
    try {
      setIsLoadingFilter(true);
      let result = await dispatch(
        dnListApi.endpoints.findAllDnListBySelection.initiate()
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          let arrCompany = [
            {
              label: 'All',
              value: 'All',
            },
          ];
          let arrVehicleType = [
            {
              label: 'All',
              value: 'All',
            },
          ];
          let yard = result.data.yard.map((d) => ({
            label: d.short_name,
            value: d.id,
          }));
          let company = result.data.company.map((d) => ({
            label: d.job_name,
            value: d.id,
          }));
          let vehicleType = result.data.vehicle_type.map((d) => ({
            label: d.name,
            value: d.id,
          }));

          arrCompany.push(...company);
          arrVehicleType.push(...vehicleType);

          if (result?.data?.yard_default) {
            let findYard = yard.find(
              (y) => y.label === result.data.yard_default.short_name
            );
            if (!findYard) {
              yard.unshift({
                label: result.data.yard_default.short_name,
                value: result.data.yard_default.id,
              });
            }
          }

          setYardOptions(yard);
          setVehicleTypeOptions(arrVehicleType);
          setCompanyOptions(arrCompany);
          setFilterYard(result?.data?.yard_default?.short_name);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
    setIsLoadingFilter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setErrorData]);

  useEffect(() => {
    onQueryOption();
  }, [onQueryOption]);

  const [companyOptions, setCompanyOptions] = useState(['All']);
  const [vehicleTypeOptions, setVehicleTypeOptions] = useState(['All']);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  const [filterDnNo, setFilterDnNo] = useState(null);
  const [filterVin, setFilterVin] = useState(null);
  const [filterCallingNo, setFilterCallingNo] = useState(null);

  const [filterYard, setFilterYard] = useState();
  const [filterCompany, setFilterCompany] = useState(['All']);
  const [filterVehicleType, setFilterVehicleType] = useState(['All']);

  const onDisplayButton = () => {
    // if (!filterYard) {
    //   message.error('Please select yard');
    //   return;
    // }
    let selectedYard = yardOptions.find((y) => y.label === filterYard);

    if (onQuery) {
      onQuery({
        ...createPayload({
          filterDnNo,
          filterVin,
          filterCallingNo,
          filterYard: selectedYard?.value,
          filterCompany,
          filterVehicleType,
        }),
      });
    }
  };

  const selectProps = {
    showSearch: true,
    style: {
      width: '160px',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoadingFilter,
  };

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Filter Options' key={1}>
          <CardStyleFrom>
            <Space size={16}>
              <Space direction='vertical'>
                <Row gutter={[12]}>
                  <Col>
                    <Space direction='vertical'>
                      <StyledText>DN No.</StyledText>
                      <Input
                        style={{
                          width: '180px',
                          align: 'center',
                        }}
                        placeholder='DN No.'
                        allowClear
                        value={filterDnNo}
                        onChange={(e) => {
                          setFilterDnNo(e.target.value.trim());
                        }}
                      />
                    </Space>
                  </Col>
                  <Col>
                    <Space direction='vertical'>
                      <>
                        <StyledText>Vin No.</StyledText>
                        <Input
                          style={{
                            width: '200px',
                            align: 'center',
                          }}
                          placeholder='Vin No.'
                          allowClear
                          value={filterVin}
                          onChange={(e) => {
                            setFilterVin(e.target.value.trim());
                          }}
                        />
                      </>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction='vertical'>
                      <>
                        <StyledText>Calling No.</StyledText>
                        <Input
                          style={{
                            width: '180px',
                            align: 'center',
                          }}
                          placeholder='Calling No.'
                          allowClear
                          value={filterCallingNo}
                          onChange={(e) => {
                            setFilterCallingNo(e.target.value.trim());
                          }}
                        />
                      </>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction='vertical'>
                      <>
                        <StyledText>From</StyledText>
                        <Select
                          {...selectProps}
                          style={{ width: '280px' }}
                          placeholder={'Yard'}
                          value={filterYard}
                          onChange={(value) => {
                            setFilterYard(value);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {yardOptions &&
                            yardOptions.map((c, index) => (
                              <Option key={index} value={c.label}>
                                {c.label}
                              </Option>
                            ))}
                        </Select>
                      </>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction='vertical'>
                      <>
                        <StyledText>Company</StyledText>
                        <Select
                          {...selectProps}
                          placeholder={'Company'}
                          value={filterCompany}
                          onChange={(values) => {
                            setFilterCompany(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {companyOptions &&
                            companyOptions.map((c, index) => (
                              <Option key={index} value={c.value}>
                                {c.label}
                              </Option>
                            ))}
                        </Select>
                      </>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction='vertical'>
                      <>
                        <StyledText>Vehicle Type</StyledText>
                        <Select
                          {...selectProps}
                          placeholder={'Yard'}
                          value={filterVehicleType}
                          onChange={(values) => {
                            setFilterVehicleType(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {vehicleTypeOptions &&
                            vehicleTypeOptions.map((c, index) => (
                              <Option key={index} value={c.value}>
                                {c.label}
                              </Option>
                            ))}
                        </Select>
                      </>
                    </Space>
                  </Col>
                  <Col>
                    <Space direction='vertical'>
                      <>
                        <Button
                          type='primary'
                          style={{ marginTop: 29, width: 180 }}
                          loading={isLoading}
                          onClick={onDisplayButton}
                          icon={<SearchOutlined />}
                        >
                          Search
                        </Button>
                      </>
                    </Space>
                  </Col>
                </Row>
              </Space>
            </Space>
          </CardStyleFrom>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;
const Container = styled.div`
  padding: 10px 0;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
const CardStyleFrom = styled(Card)`
  .ant-card-body {
    padding-top: 10px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 0px;
  }
  width: 100%;
  border: 1px solid #d9d9d9;
`;
export default TableToolbar;
