import React, { useEffect, useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { connect } from 'react-redux';
import {
  mapSelectedMarkerSearch,
  mapMoveMarker,
  mapSetMarkerPlace,
} from 'reducers/map';

const MarkerPlace = (props) => {
  const [selected, setSelected] = useState(false);
  useEffect(() => {
    setSelected(false);
  }, []);

  useEffect(() => {
    if (props.marker_search_selecting === 'place_id') {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props.marker_search_selecting]);

  const selectInfo = (arg) => {
    props.mapSelectedMarkerSearch(props.id);
  };
  const closeInfo = () => {
    props.mapSelectedMarkerSearch('');
    setSelected(false);
  };
  const handleClearSearchMarker = () => {
    props.mapSetMarkerPlace(null);
    setSelected(false);
  };

  let icon = new window.google.maps.MarkerImage(
    '/img/placesearch_marker.png',
    null /* size is determined at runtime */,
    null /* origin is 0,0 */,
    null /* anchor is bottom center of the scaled image */,
    new window.google.maps.Size(30, 40)
  );

  return (
    <Marker
      {...props}
      position={props.position}
      icon={icon}
      key={props.key}
      id={props.id}
      title={props.name || ''}
      zIndex={props.zIndex}
      onClick={selectInfo}
      markerCilck={selectInfo}
      onUnmount={() => {
        setSelected(false);
      }}
    >
      {selected && (
        <InfoWindow onCloseClick={closeInfo}>
          <div>
            {props.name || ''}{' '}
            <button onClick={handleClearSearchMarker}>Remove</button>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

const mapStateToProps = (state) => {
  return {
    marker_search_selecting: state.map.marker_search_selecting,
  };
};

const mapActionsToProps = {
  mapSelectedMarkerSearch,
  mapSetMarkerPlace,
  MARKER_MOVE: mapMoveMarker,
};

export default connect(mapStateToProps, mapActionsToProps)(MarkerPlace);
