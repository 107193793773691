export const alphaNumericSort = (arr = [], key) => {
  const sorter = (in1, in2) => {
    let a = in1[key];
    let b = in2[key];
    const isNumber = (v) => (+v).toString() === v;
    const aPart = a.match(/\d+|\D+/g);
    const bPart = b.match(/\d+|\D+/g);
    let i = 0;
    let len = Math.min(aPart.length, bPart.length);
    while (i < len && aPart[i] === bPart[i]) {
      i++;
    }
    if (i === len) {
      return aPart.length - bPart.length;
    }
    if (isNumber(aPart[i]) && isNumber(bPart[i])) {
      return aPart[i] - bPart[i];
    }
    return aPart[i].localeCompare(bPart[i]);
  };
  if (arr && arr.length > 0) {
    arr.sort(sorter);
  }
};
