import React from 'react';
import { Result, Button } from 'antd';
import { push, goBack } from 'connected-react-router';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getCurrentUser } from '../reducers/user';
import { useLocation } from 'react-router-dom';
// import AppLogoImage from 'assets/images/TTT_logo.png';

const PageNotFound = (props) => {
  const { home } = props;
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const location = useLocation();
  const onCheckPayment = () => {
    let checked = false;
    for (let index = 0; index < user?.privileges.length; index++) {
      if (user?.privileges[index]?.actions[0] === '1') {
        checked = true;
        break;
      }
    }
    if (
      (location.pathname === '/app') ||
      (checked && location.pathname === '/app/payment')
    ) {
      return 'Welcome to TNOS Application';
    } else {
      return 'Sorry, the page you visited does not exist.';
    }
  };
  const onCheckPaymentTitle = () => {
    let checked = false;
    for (let index = 0; index < user?.privileges.length; index++) {
      if (user?.privileges[index]?.actions[0] === '1') {
        checked = true;
        break;
      }
    }
    // console.log('object :>> ', object);
    if (
      (location.pathname === '/app') ||
      (checked && location.pathname === '/app/payment')
    ) {
      return 'Welcome';
    } else {
      return '404';
    }
  };
  const onCheckPaymentText = () => {
    let checked = false;
    for (let index = 0; index < user?.privileges.length; index++) {
      if (user?.privileges[index]?.actions[0] === '1') {
        checked = true;
        break;
      }
    }
    if (
      (location.pathname === '/app') ||
      (checked && location.pathname === '/app/payment')
    ) {
      return false;
    } else {
      return true;
    }
  };
  return (
    <StyledResult
      child
      status='404'
      title={onCheckPaymentTitle()}
      subTitle={onCheckPayment()}
      extra={
        onCheckPaymentText() ? (
          <Button
            type='primary'
            onClick={() => {
              if (home) {
                dispatch(push('/'));
              } else {
                dispatch(goBack());
              }
            }}
          >
            Back
          </Button>
        ) : (
          ''
        )
      }
    />
  );
};

const StyledResult = styled(Result)`
  height: ${(props) => (props.child ? '90%' : '100%')};
`;
export default PageNotFound;
