import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { DatePicker, Select, Modal } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import './index.css';
import moment from 'moment';
import TableVin from './TableVin';
import { statusChangeApi } from 'reducers/statusChange/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { useDispatch } from 'react-redux';
import { InfoCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const TableComponent = (props) => {
  const dispatch = useDispatch();
  const idVinRef = useRef(null);
  const idJobCodeRef = useRef(null);
  const vinDescriptionRef = useRef(null);

  const {
    rowData,
    isLoading,
    fromField,
    receiveJobTimeField,
    yardOutTimeField,
    trailersField,
    tripTypeField,
    driverCode1Field,
    driverCode2Field,
    setSelectedRowsData,
    selectedRowsData,
    savePopupVin,
    arrivalTimeField,
    driverCode1Options,
    driverCode2Options,
  } = props;
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { height } = useWindowSize();
  const [vinDescription, setVinDescription] = useState([]);
  const [callingNo, setCallingNo] = useState(null);
  const [totalUnit, setTotalUnit] = useState(null);
  const [tableHeight, setTableHeight] = useState(0);
  const dataSourceRef = useRef([]);
  const { setErrorData } = useErrorHandler();

  useEffect(() => {
    if (rowData) {
      dataSourceRef.current = rowData;
    }
  }, [rowData]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      if (selectedRows) {
        setSelectedRowsData(selectedRows);
      }
    },
  };

  useEffect(() => {
    if (selectedRowsData.length === 0) {
      setSelectedRowKeys([]);
    }
  }, [selectedRowsData]);

  const handleRightClick = (row, row_id, actual_arrival_time, arrival, key) => {
    try {
      if (row) {
        arrivalTimeField(row_id, actual_arrival_time, arrival);
        idJobCodeRef.current = row.id;
        idVinRef.current = row.vin_description.id;
        setCallingNo(row.vin_description.calling_no);
        setTotalUnit(row.vin_description.total_unit);

        let arrVin = row.vin_description.vin_description.map((item) => ({
          ...item,
          location_from: row.actual_from_edit
            ? row.actual_from_edit
            : row.actual_from
            ? row.actual_from
            : null,
          actual_receive_job_datetime: row?.actual_receive_job_datetime_temp
            ? row?.actual_receive_job_datetime_temp
            : row?.actual_receive_job_datetime,
          actual_yardout_datetime: row?.actual_yardout_datetime_temp
            ? row?.actual_yardout_datetime_temp
            : row?.actual_yardout_datetime,
          key: key,
          combine: row?.combine ? row?.combine : row?.combine_code,
        }));
        vinDescriptionRef.current = arrVin;
        setVinDescription(arrVin);

        setIsModalConfirmVisible(true);
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const checkEffectDNApi = async (payload) => {
    let resData = null;
    try {
      if (payload) {
        let result = await dispatch(
          statusChangeApi.endpoints.findStatusChangEffectDN.initiate(payload, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          resData = result.data;
        }
      }

      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const checkVehicleTypeAccidentApi = async (payload) => {
    let resData = null;
    try {
      if (payload) {
        let result = await dispatch(
          statusChangeApi.endpoints.findStatusChangVehicleTypeAccident.initiate(
            payload,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }

      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const selectProps = {
    style: {
      fontSize: '14px',
      width: '140px',
    },
    allowClear: false,
    maxTagCount: 'responsive',
  };
  const selectPropsMiddle = {
    style: {
      fontSize: '14px',
      width: '120px',
    },
    allowClear: false,
    maxTagCount: 'responsive',
  };

  const disabledDate = (current) => {
    // ปิดใช้งานวันที่ที่น้อยกว่า xx หรือมากกว่า xx
    return current && current > moment().startOf('day').add(1, 'days');
  };
  const onCheckYardout = (record, value, key) => {
    let val = spliteSeconds(
      value,
      record.actual_receive_job_datetime,
      record.actual_yardout_datetime,
      record.actual_arrival_time
    );
    if (!record.actual_receive_job_datetime) {
      return Modal.error({
        title: 'โปรดเลือกเวลารับงานก่อน Yard out time ',
      });
    }

    if (moment(val.value_seconds).isSameOrAfter(moment())) {
      Modal.error({
        title: 'เวลาที่เลือกมากกว่าเวลาปัจจุบัน โปรดเลือกเวลาใหม่อีกครั้ง',
      });
    } else {
      if (record?.combine) {
        if (
          moment(val?.receive_job_seconds).isSameOrAfter(val?.value_seconds)
        ) {
          Modal.error({
            title: 'กรุณาใส่เวลา Yard out time มากกว่าเวลารับงาน',
          });
        } else if (
          moment(val?.value_seconds).isSameOrAfter(
            record.actual_arrival_time_max_date
              ? record.actual_arrival_time_max_date
              : val?.arrival_seconds
          )
        ) {
          Modal.error({
            title: 'กรุณาใส่เวลา Yard out time น้อยกว่าเวลาจบงาน',
          });
        } else {
          yardOutTimeField(
            value ? value.seconds(0).milliseconds(0) : null,
            record.id,
            record.std_lead_time,
            'Yard Out',
            record?.actual_yardout_datetime_before,
            record
          );
        }
      } else {
        // let format = 'YYYY-MM-DDTHH:mm:ssZ';
        for (let index = 0; index < dataSourceRef.current.length; index++) {
          if (index !== 0 && key !== 0) {
            if (
              Date.parse(val.value_seconds) >
                Date.parse(
                  dataSourceRef.current[index - 1].actual_receive_job_datetime
                ) &&
              Date.parse(val.value_seconds) <
                Date.parse(dataSourceRef.current[index - 1].actual_arrival_time)
            ) {
              // เงื่อนไขเป็นจริง
              Modal.error({
                title:
                  'กรุณาใส่เวลา Yard out time มากกว่าเวลารับงานและเวลาจบงานของ trip ก่อนหน้า',
              });
            } else if (
              moment(
                dataSourceRef.current[index - 1].actual_receive_job_datetime
              ).isSameOrAfter(val.value_seconds)
            ) {
              Modal.error({
                title:
                  'กรุณาใส่เวลา Yard out time มากกว่าเวลารับงานของ trip ก่อนหน้า',
              });
            } else if (
              // dataSourceRef.current[index]?.actual_arrival_datetime_edit &&
              Date.parse(val.value_seconds) >
              Date.parse(
                dataSourceRef.current[index].actual_arrival_time_max_date
                  ? dataSourceRef.current[index].actual_arrival_time_max_date
                  : val?.arrival_seconds
              )
            ) {
              Modal.error({
                title: 'กรุณาใส่เวลา Yard out time น้อยกว่าเวลาจบงาน',
              });
            } else if (
              dataSourceRef.current[index]?.actual_arrival_datetime_edit &&
              Date.parse(val.value_seconds) >
                Date.parse(
                  dataSourceRef.current[index - 1].actual_arrival_time_max_date
                    ? dataSourceRef.current[index - 1]
                        .actual_arrival_time_max_date
                    : val?.arrival_seconds
                )
            ) {
              Modal.error({
                title:
                  'กรุณาใส่เวลา Yard out time มากกว่าเวลาจบงานของ trip ก่อนหน้า',
              });
            } else if (
              moment(val?.receive_job_seconds).isSameOrAfter(val?.value_seconds)
            ) {
              Modal.error({
                title: 'กรุณาใส่เวลา Yard out time มากกว่าเวลารับงาน',
              });
            } else {
              if (index === key) {
                yardOutTimeField(
                  value ? value.seconds(0).milliseconds(0) : null,
                  record.id,
                  record.std_lead_time,
                  'Yard Out',
                  record?.actual_yardout_datetime_before,
                  record
                );
              }
            }
          } else {
            if (index === key) {
              if (
                Date.parse(val?.value_seconds) <
                  Date.parse(val?.receive_job_seconds) &&
                Date.parse(val?.value_seconds) >
                  Date.parse(val?.arrival_seconds)
              ) {
                Modal.error({
                  title:
                    'กรุณาใส่เวลา Yard out time มากกว่าเวลารับงานและเวลาจบงาน',
                });
              }

              if (
                moment(val?.receive_job_seconds).isSameOrAfter(
                  val?.value_seconds
                )
              ) {
                Modal.error({
                  title: 'กรุณาใส่เวลา Yard out time มากกว่าเวลารับงาน',
                });
              } else if (
                moment(val?.value_seconds).isSameOrAfter(
                  record.actual_arrival_time_max_date
                    ? record.actual_arrival_time_max_date
                    : val?.arrival_seconds
                )
              ) {
                Modal.error({
                  title: 'กรุณาใส่เวลา Yard out time น้อยกว่าเวลาจบงาน',
                });
              } else {
                yardOutTimeField(
                  value ? value.seconds(0).milliseconds(0) : null,
                  record.id,
                  record.std_lead_time,
                  'Yard Out',
                  record?.actual_yardout_datetime_before,
                  record
                );
              }
            }
          }
        }
      }
    }
  };
  const spliteSeconds = (
    value,
    actual_receive_job_datetime,
    actual_yardout_datetime,
    actual_arrival_time
  ) => {
    let format = 'YYYY-MM-DDTHH:mm:ssZ';
    let value_seconds = null;
    let receive_job_seconds = null;
    let yardout_seconds = null;
    let arrival_seconds = null;
    if (value) {
      value_seconds = moment(value, format).seconds(0).format(format);
    }
    if (actual_receive_job_datetime) {
      receive_job_seconds = moment(actual_receive_job_datetime, format)
        .seconds(0)
        .format(format);
    }
    if (actual_yardout_datetime) {
      yardout_seconds = moment(actual_yardout_datetime, format)
        .seconds(0)
        .format(format);
    }

    if (actual_arrival_time) {
      arrival_seconds = moment(actual_arrival_time, format)
        .seconds(0)
        .format(format);
    }

    let val = {
      value_seconds: value_seconds,
      receive_job_seconds: receive_job_seconds,
      yardout_seconds: yardout_seconds,
      arrival_seconds: arrival_seconds,
    };
    return val;
  };

  const onCheckReceiveJob = (record, value, key) => {
    if (moment(value).isSameOrAfter(moment())) {
      Modal.error({
        title: 'เวลาที่เลือกมากกว่าเวลาปัจจุบัน โปรดเลือกเวลาใหม่อีกครั้ง',
      });
    } else {
      let val = spliteSeconds(
        value,
        null,
        record.actual_yardout_datetime,
        record.actual_arrival_time
      );
      if (record?.combine) {
        if (moment(val?.value_seconds).isSameOrAfter(val?.yardout_seconds)) {
          Modal.error({
            title: 'กรุณาใส่เวลารับงานน้อยกว่าเวลา Yard out time',
            ok: () => {},
          });
        } else {
          receiveJobTimeField(
            value ? value.seconds(0).milliseconds(0) : null,
            record.id,
            record?.receive_job_datetime,
            'Receive Job',
            record?.actual_receive_job_datetime_before,
            record
          );
        }
      } else {
        for (let index = 0; index < dataSourceRef.current.length; index++) {
          if (index !== 0 && key !== 0) {
            if (
              moment(
                dataSourceRef.current[index - 1].actual_arrival_time ||
                  dataSourceRef.current[index - 1].actual_arrival_time_max_date
              ).isSameOrAfter(value)
            ) {
              Modal.error({
                title:
                  'กรุณาใส่เวลารับงานมากกว่า Arrival time ของ trip ก่อนหน้า',
                ok: () => {},
              });
            } else {
              if (index === key) {
                if (
                  moment(val?.arrival_seconds).isSameOrAfter(val?.value_seconds)
                ) {
                  Modal.error({
                    title: 'กรุณาใส่เวลารับงานมากกว่า Arrival time',
                    ok: () => {},
                  });
                } else {
                  receiveJobTimeField(
                    value ? value.seconds(0).milliseconds(0) : null,
                    record.id,
                    record?.receive_job_datetime,
                    'Receive Job',
                    record?.actual_receive_job_datetime_before,
                    record
                  );
                }
              }
            }
          } else {
            if (index === key) {
              if (
                moment(val?.value_seconds).isSameOrAfter(val?.yardout_seconds)
              ) {
                Modal.error({
                  title: 'กรุณาใส่เวลารับงานน้อยกว่าเวลา Yard out time',
                  ok: () => {},
                });
              } else {
                receiveJobTimeField(
                  value ? value.seconds(0).milliseconds(0) : null,
                  record.id,
                  record?.receive_job_datetime,
                  'Receive Job',
                  record?.actual_receive_job_datetime_before,
                  record
                );
              }
            }
          }
        }
      }
    }
  };

  const columns = [
    {
      title: 'Job code',
      dataIndex: 'job_code',
      align: 'center',
      width: 70,
      className: 'bg-grey',
    },
    {
      title: 'Trip no.',
      dataIndex: 'trip_no',
      align: 'center',
      width: 70,
      className: 'bg-grey',
    },
    {
      title: 'Combine Code',
      dataIndex: 'combine_code',
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return record.combine ? record.combine : text;
      },
    },

    {
      title: 'From',
      dataIndex: 'actual_from',
      width: 250,
      align: 'center',
      render: (text, record, index) => {
        return (
          <Select
            value={
              record.actual_from_edit
                ? record.actual_from_edit.code
                : record.actual_from
                ? record.actual_from.code
                : null
            }
            style={{    width: '250px',}}
            size='small'
            loading={isLoading}
            placeholder={'Select a from'}
            onChange={(values) => {
              // fromRowData(dataSourceRef.current);
              fromField(values, record.id, record.locationFromToOptions);
            }}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {record.locationFromToOptions.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        );
      },
    },

    {
      title: (
        <>
          Destination{' '}
          <InfoCircleOutlined
            style={{ paddingBottom: '2px', fontSize: '10px' }}
          />
        </>
      ),
      dataIndex: 'actual_cluster_to',
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (text, record, key) => {
        return (
          <div
            onContextMenu={() => {
              handleRightClick(
                record,
                record.id,
                record.actual_arrival_time,
                'Arrival',
                key
              );
            }}
          >
            {record.actual_destination_edit
              ? record.actual_destination_edit.cluster &&
                record.actual_destination_edit.cluster.name
              : record.actual_cluster_to && record.actual_cluster_to.name}
          </div>
        );
      },
    },
    {
      title: 'Return Place',
      dataIndex: 'actual_return_place_before',
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return record.actual_return_place && record.actual_return_place.id
          ? record.actual_return_place.name
          : text;
      },
    },

    {
      title: 'Trailer type',
      dataIndex: ['trailer_type', 'name'],
      width: 120,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return record.trailer_type_edit ? record.trailer_type_edit?.name : text;
      },
    },
    {
      title: 'Calling no.',
      dataIndex: ['callingsheet', 'callingsheet_no'],
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
    },
    {
      title: 'Trip Type',
      dataIndex: 'trip_type',
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (text) {
          return (
            <Select
              value={
                record.trip_type_edit
                  ? record.trip_type_edit.id
                  : record.trip_type
                  ? record.trip_type.id
                  : null
              }
              // defaultValue={
              //   record.trip_type && record.trip_type ? record.trip_type.name : null
              // }
              // disabled={record.disableFormRow}
              {...selectPropsMiddle}
              showSearch
              size='small'
              placeholder={'Select a trip type'}
              onChange={(value) => {
                // fromRowData(dataSourceRef.current);
                tripTypeField(value, record.id, record.tripTypeOptions);
              }}
              loading={isLoading}
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {record?.tripTypeOptions?.map((c, index) => (
                <Option key={index} value={c.value}>
                  {c.label}
                </Option>
              ))}
            </Select>
            // </Form.Item>
          );
        } else {
          return null;
        }
        //   return record.trip_type && record.trip_type.name_en
        //     ? capFirst(record.trip_type.name_en)
        //     : null;
        // }
      },
    },
    {
      title: 'Trailer code',
      dataIndex: ['trailers', '0', 'id'],
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        return (
          <Select
            value={
              record.trailersNew ? record.trailersNew.value : text ? text : null
            }
            {...selectProps}
            defaultValue={
              record.trip_type && record.trip_type
                ? record.trip_type.name
                : null
            }
            showSearch
            allowClear={false}
            maxTagCount={'responsive'}
            size='small'
            placeholder={'Select a Trailer code'}
            onChange={(value) => {
              trailersField(
                value,
                record.id,
                record.trailerCodeOptions,
                record
              );
            }}
            loading={isLoading}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {record?.trailerCodeOptions
              .filter((t) => t.maximum_loading >= record.actual_loading_unit)
              .map((c, index) => (
                <Option key={index} value={c.value}>
                  {c.label}
                </Option>
              ))}
          </Select>
        );

        // } else {
        //   return text.length > 0 ? text[0].trailer_card_id : null;
        // }
      },
    },
    {
      title: 'Driver code 1',
      dataIndex: 'drivers',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        return (
          // <Form.Item
          //   // name='trip_type'
          //   rules={[
          //     {
          //       required: true,
          //       message: false,
          //     },
          //   ]}
          // >
          <Select
            value={
              record && record.driversCode1 && record.driversCode1.value
                ? record.driversCode1.value
                : record?.drivers[0]?.id
            }
            // defaultValue={
            //   record.trip_type && record.trip_type ? record.trip_type.name : null
            // }
            // disabled={record.disableFormRow}
            onDropdownVisibleChange={(d) => {
              driverCode1Options(
                record.id,
                d,
                record.driverOptionsDefault,
                record
              );
            }}
            {...selectProps}
            size='small'
            placeholder={'Select a driver code'}
            onChange={(value) => {
              driverCode1Field(
                value,
                record.id,
                record.driverCode1Options,
                record
              );
            }}
            showSearch
            loading={isLoading}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {record?.driverCode1Options?.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
          // </Form.Item>
        );
      },
    },
    {
      title: 'Driver code 2',
      dataIndex: 'drivers',
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        return (
          <Select
            value={
              record && record.driversCode2 && record.driversCode2.value
                ? record.driversCode2.value
                : record?.drivers[1]?.id
            }
            onDropdownVisibleChange={(d) => {
              driverCode2Options(
                record.id,
                d,
                record.driverOptionsDefault,
                record
              );
            }}
            {...selectProps}
            size='small'
            placeholder={'Select a driver code'}
            onChange={(value) => {
              driverCode2Field(
                value,
                record.id,
                record.driverCode2Options,
                record
              );
            }}
            showSearch
            loading={isLoading}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {record?.driverCode2Options?.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Receive Job Time',
      dataIndex: 'actual_receive_job_datetime',
      width: 140,
      align: 'center',
      render: (text, record, key) => {
        return (
          <DatePicker
            disabledDate={disabledDate}
            value={
              record && record.actual_receive_job_datetime_temp
                ? moment(record && record.actual_receive_job_datetime_temp)
                : text
                ? moment(text)
                : null
            }
            loading={isLoading}
            allowClear={true}
            size='small'
            showTime
            format={'DD/MM/YY HH:mm'}
            onChange={(value) => {
              if (value === null) {
                if (
                  (record.actual_yardout_datetime &&
                    record.actual_arrival_time_max_date) ||
                  (record.actual_yardout_datetime && record.actual_arrival_time)
                ) {
                  Modal.error({
                    title:
                      'ไม่สามารถลบได้ เพราะมี Yard out time และ Arrival time อยู่แล้ว',
                  });
                } else if (record.actual_yardout_datetime) {
                  Modal.error({
                    title: 'ไม่สามารถลบได้ เพราะมี Yard out time อยู่แล้ว',
                  });
                } else if (
                  record.actual_arrival_time_max_date ||
                  (record.actual_arrival_time &&
                    record.actual_arrival_time_max_date !== null)
                ) {
                  Modal.error({
                    title: 'ไม่สามารถลบได้ เพราะมี Arrival time อยู่แล้ว',
                  });
                } else {
                  receiveJobTimeField(
                    value,
                    record.id,
                    record?.receive_job_datetime,
                    'Receive Job',
                    record?.actual_receive_job_datetime_before,
                    record
                  );
                }
              }
            }}
            onOk={(value) => {
              onCheckReceiveJob(record, value, key);
            }}
            showNow={false}
          />
        );
      },
    },
    {
      title: 'Yard out time',
      dataIndex: 'actual_yardout_datetime',
      width: 140,
      align: 'center',
      ellipsis: true,
      render: (text, record, key) => {
        return (
          <DatePicker
            disabledDate={disabledDate}
            value={
              record && record.actual_yardout_datetime_temp
                ? moment(record && record.actual_yardout_datetime_temp)
                : text
                ? moment(text)
                : null
            }
            loading={isLoading}
            allowClear={true}
            size='small'
            showTime
            format={'DD/MM/YY HH:mm'}
            onChange={(value) => {
              if (value === null) {
                if (
                  record.actual_arrival_time_max_date ||
                  (record.actual_arrival_time &&
                    record.actual_arrival_time_max_date !== null)
                ) {
                  Modal.error({
                    title: 'ไม่สามารถลบได้ เพราะมี Arrival time อยู่แล้ว',
                  });
                } else {
                  yardOutTimeField(
                    value,
                    record?.id,
                    record?.std_lead_time,
                    'Yard Out',
                    record?.actual_yardout_datetime_before,
                    record
                  );
                }
              }
            }}
            onOk={(value) => {
              onCheckYardout(record, value, key);
            }}
            showNow={false}
          />
        );
      },
    },
    {
      title: (
        <>
          Arrival time{' '}
          <InfoCircleOutlined
            style={{ paddingBottom: '2px', fontSize: '10px' }}
          />
        </>
      ),
      dataIndex: 'actual_arrival_time',
      width: 110,
      align: 'center',
      ellipsis: true,
      render: (text, record, key) => {
        let value;
        if (typeof record?.actual_arrival_time_max_date !== 'undefined') {
          value = record?.actual_arrival_time_max_date;
        } else {
          value = text;
        }
        return (
          <div
            onContextMenu={() => {
              handleRightClick(
                record,
                record.id,
                record.actual_arrival_time,
                'Arrival',
                key
              );
            }}
          >
            {dateTimeTimestamptzFormatter({
              value,
            }) || '-'}
          </div>
        );
      },
    },
    {
      title: 'Last update',
      dataIndex: 'updated_at',
      width: 110,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return dateTimeTimestamptzFormatter({ value: text });
      },
    },
    {
      title: 'Updater',
      dataIndex: ['updated_by', 'fullname_en'],
      width: 110,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
    },
  ];

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  const onSavePopup = (value, idJobCodeRef, id_vin_temp) => {
    savePopupVin(value, idJobCodeRef, id_vin_temp);
  };

  const onCustomerField = (value, row_id, customerOption) => {
    let objCustomer = customerOption.find((v) => v.value === value);
    let row = {
      customer_edit: objCustomer,
    };

    updateDataTableVin(row, row_id);
  };
  const onDestinationField = (value, row_id, locationDestinationOptions) => {
    let objDestination = locationDestinationOptions.find(
      (v) => v.value === value
    );

    let row = {
      location_destination_edit: objDestination,
      actual_arrival_time_edit: 'clear',
      actual_arrival_time_clear: 'clear',
      actual_arrival_time_clear_destination: 'destination',
    };
    updateDataTableVin(row, row_id);
  };

  const onCheckAccidentField = async (
    value,
    row_id,
    vehicleTypeOptions,
    record
  ) => {
    let objVehicleType = vehicleTypeOptions.find((v) => v.value === value);

    if (
      (objVehicleType && objVehicleType.value === '12') ||
      (objVehicleType && objVehicleType.value === '13')
    ) {
      let playloadVehicleTypeAccident = {
        calling_no: record.callingNo,
        vin_no: record.vin_no,
        vehicle_type: objVehicleType.id,
      };

      let vehicleTypeAccident = await checkVehicleTypeAccidentApi(
        playloadVehicleTypeAccident
      );
      if (!vehicleTypeAccident) {
        Modal.warning({
          title:
            'VIN no. มีการใช้ทำ Calling แล้ว และ รอรับงานอยู่ โปรดตรวจสอบ และนำ VIN NO นี้ออกจาก Calling',
        });
      }
    }

    if (
      (objVehicleType && objVehicleType.value === '13') ||
      (objVehicleType && objVehicleType.value === '14')
    ) {
      let row = {
        vehicle_type_edit: objVehicleType,
        actual_arrival_time_edit: undefined,
        actual_arrival_time_clear: 'clear',
      };
      updateDataTableVin(row, row_id);
    } else {
      if (objVehicleType) {
        let row = {
          vehicle_type_edit: objVehicleType,
          // actual_arrival_time_edit: record.actual_arrival_time,
          actual_arrival_time_clear: null,
        };
        updateDataTableVin(row, row_id);
      }
    }
  };
  const onActualArrivalTimeField = (
    value,
    row_id,
    vehicleTypeCode,
    vehicleTypeOptions,
    record
  ) => {
    if (value) {
      let objVehicleType = vehicleTypeOptions.find(
        (v) => v.value === vehicleTypeCode
      );
      if (
        (objVehicleType && objVehicleType.value === '13') ||
        (objVehicleType && objVehicleType.value === '14')
      ) {
        let row = {
          vehicle_type_edit: null,
          actual_arrival_time_edit: value,
        };
        updateDataTableVin(row, row_id);
      } else {
        let row = {
          actual_arrival_time_edit: value,
        };
        updateDataTableVin(row, row_id);
      }
    } else {
      let row = {
        actual_arrival_time: value,
        actual_arrival_time_edit: value,
      };
      updateDataTableVin(row, row_id);
    }
  };

  const onInvoiceAbleField = (value, row_id) => {
    let row = {
      is_invoice_able: value,
    };
    updateDataTableVin(row, row_id);
  };
  const onIsPaymentAbleField = (value, row_id) => {
    let row = {
      is_payment_able: value,
    };
    updateDataTableVin(row, row_id);
  };

  const updateDataTableVin = async (objValue, row_id) => {
    let arrDataTable = [];

    arrDataTable = vinDescriptionRef.current
      .filter((item) => item.id === row_id)
      .map((item) => ({
        ...item,
        ...objValue,
      }));

    if (arrDataTable && arrDataTable.length > 0) {
      let dataTableNew = vinDescriptionRef.current;
      const index = vinDescriptionRef.current.findIndex(
        (item) => item.id === row_id
      );

      dataTableNew.splice(index, 1, ...arrDataTable);

      // Update actual arrival time edit of all vins that have the same group (customer, location_to, vehicle_type)
      // Using xxx_edit to compare if having, instead of original data
      let updateDataTable = dataTableNew.map((d) => {
        let customer_id = d.customer_edit?.id || d.customer?.id;
        let location_id = d.location_destination_edit?.id || d.location_to?.id;
        let vehicle_type_id = d.vehicle_type_edit?.id || d.vehicle_type?.id;

        let in_customer_id =
          arrDataTable[0].customer_edit?.id || arrDataTable[0].customer?.id;
        let in_location_id =
          arrDataTable[0].location_destination_edit?.id ||
          arrDataTable[0].location_to?.id;
        let in_vehicle_type_id =
          arrDataTable[0].vehicle_type_edit?.id ||
          arrDataTable[0].vehicle_type?.id;

        if (
          customer_id === in_customer_id &&
          location_id === in_location_id &&
          vehicle_type_id === in_vehicle_type_id
        ) {
          return {
            ...d,
            actual_arrival_time_edit: arrDataTable[0].actual_arrival_time_edit,
          };
        } else {
          return { ...d };
        }
      });

      vinDescriptionRef.current = updateDataTable;
      setVinDescription(updateDataTable);
    }
  };

  return (
    <Container>
      <StyledTable
        rowKey='id'
        tableId={'newStatusChange'}
        className={'statusChange'}
        rowSelection={{
          hideSelectAll: true,
          ...rowSelection,
        }}
        loading={isLoading}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={rowData}
        pagination={false}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          x: 'max-content',
        }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        // setSelectedRowsData={setSelectedRowsData}
        menuActions={
          [
            // {
            //   label: 'Add trip before',
            //   action: (row) => handleClicked(row, dataSource, 'before'),
            // },
          ]
        }
      />

      <TableVin
        customerField={onCustomerField}
        destinationField={onDestinationField}
        checkAccidentField={onCheckAccidentField}
        actualArrivalTimeField={onActualArrivalTimeField}
        isInvoiceAbleField={onInvoiceAbleField}
        isPaymentAbleField={onIsPaymentAbleField}
        savePopup={onSavePopup}
        callingNo={callingNo}
        totalUnit={totalUnit}
        vinDescription={vinDescription}
        idJobCodeRef={idJobCodeRef.current}
        idVin={idVinRef.current}
        isModalConfirmVisible={isModalConfirmVisible}
        setIsModalConfirmVisible={setIsModalConfirmVisible}
        rowData={rowData}
        // vinDescriptionBefore={vinDescriptionBefore}
      />
    </Container>
  );
};
const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body tr td {
      // padding: 3px;
      font-size: 14px;
    }
    .disabled-row {
      background-color: #000;
    }
  }
`;
export default TableComponent;
