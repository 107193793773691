import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { getCurrentUser } from 'reducers/user';
import { useFindAllTruckStatusesQuery } from 'reducers/digitalmap/operationDashboard/api';

const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

const VehicleStatusRenderer = (props) => {
  const { data } = props;
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);

  const {
    data: statusOptions,
    error,
    isFetching,
  } = useFindAllTruckStatusesQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  if (isFetching) {
    return <Spin indicator={antIcon} />;
  }
  if (statusOptions && data) {
    const vehicleStatus = statusOptions.find((d) => d.code === data.toString());
    if (vehicleStatus) {
      return vehicleStatus.name;
    } else {
      return 'Unknown status';
    }
  } else {
    return '';
  }
};
export default VehicleStatusRenderer;
