import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import html2canvas from 'html2canvas';

import { printSelector } from 'reducers/print';
import wsClient from 'libs/websocketPrint';
import { PDFDocument, PageSizes } from 'pdf-lib';

const getPdfDocPageSizes = (format, orientation) => {
  // Calculate scale between input canvas and output pdf
  // Size	 72 PPI     96 PPI      150 PPI       300 PPI
  // A4	   595 x 842	794 x 1123	1240 x 1754	  2480 x 3508
  // A5	   420 x 595	559 x 794   874 x 1240	  1748 x 2480
  if (format === 'A4' && orientation === 'PORTRAIT') {
    return PageSizes.A4;
  } else if (format === 'A4' && orientation === 'LANDSCAPE') {
    return [PageSizes.A4[1], PageSizes.A4[0]];
  } else if (format === 'A5' && orientation === 'PORTRAIT') {
    return PageSizes.A5;
  } else if (format === 'A5' && orientation === 'LANDSCAPE') {
    return [PageSizes.A5[1], PageSizes.A5[0]];
  } else {
    return PageSizes.A4;
  }
};
const usePrint = (printType, data) => {
  const [printData, setPrintData] = useState([]);
  const printConfigState = useSelector(printSelector);
  const printTypeState = printConfigState[printType];

  // Sent Base64 to TNOS Print Service
  const base64ToPrinting = (base64, printType) => {
    try {
      wsClient.submit({
        type: printType,
        url: 'file.pdf',
        file_content: base64,
      });
    } catch (err) {
      console.log(err.message);
    }
  };

  //https://stackoverflow.com/questions/19272933/jspdf-multi-page-pdf-with-html-renderer
  //https://www.techumber.com/how-to-convert-html-to-pdf-using-javascript-multipage
  const sentToPrintService = useCallback(
    (element, divId) => {
      html2canvas(element, {
        scale: 1,
        onclone: function (clonedDoc) {
          clonedDoc.getElementById(divId || 'print').style.display = 'block';
        },
      }).then(async (canvas) => {
        const pdfDoc = await PDFDocument.create();
        let pageSize = getPdfDocPageSizes(
          printTypeState?.pageSize,
          printTypeState?.pageOrientation
        );

        let pdfPageWidth = pageSize[0];
        let pdfPageHeight = pageSize[1];

        // Canvas may have different DPI as follows
        // Calculate scale between input canvas and output pdf
        // Size	 72 PPI     96 PPI      150 PPI       300 PPI
        // A4	   595 x 842	794 x 1123	1240 x 1754	  2480 x 3508
        // A5	   420 x 595	559 x 794   874 x 1240	  1748 x 2480
        const pScale = canvas.width / pdfPageWidth;

        const imgHeight = Math.ceil(pdfPageHeight * pScale);
        const imgWidth = Math.ceil(pdfPageWidth * pScale);

        const canvasHeight = canvas.height;

        // Estimate page count maybe incorrect if has many page and each page contains margin or padding
        // Alternatively, can get for input data list length
        let pagecount = Math.ceil(canvasHeight / imgHeight);

        const imagePieces = [];

        // Estimate one image height from html component height
        const srcImgHight = canvasHeight / pagecount;
        const dTop = 0;

        for (let i = 0; i < pagecount; i++) {
          let subCanvas = document.createElement('canvas'),
            ctx = subCanvas.getContext('2d');
          // width/hight must be the same as dw/dh of context drawImage function
          subCanvas.width = imgWidth;
          subCanvas.height = imgHeight;

          // Fill background to white
          ctx.fillStyle = 'white';
          ctx.fillRect(0, 0, subCanvas.width, subCanvas.height);

          // Clip image and draw on context
          ctx.drawImage(
            canvas,
            // Source image props
            0,
            i * srcImgHight,
            imgWidth,
            srcImgHight,
            // Destination image props
            0,
            dTop,
            imgWidth,
            imgHeight
          );
          imagePieces.push(subCanvas.toDataURL('image/jpeg'));
        }
        // PDF Output
        let marginLeft = 30;
        let marginTop = 10;
        let offsetW = 30;
        let offsetH = 10;

        const imageHeightInPdf = pdfPageHeight - marginTop - offsetH;
        const yOffset = (pdfPageHeight - imageHeightInPdf) / 2;
        const adjustedMarginTop = yOffset;

        for (let image of imagePieces) {
          const page = pdfDoc.addPage(pageSize);
          const jpgImage = await pdfDoc.embedJpg(image);

          page.drawImage(jpgImage, {
            x: marginLeft,
            y: adjustedMarginTop,
            width: pdfPageWidth - marginLeft - offsetW,
            height: imageHeightInPdf
          });
        }
        if (wsClient.isConnected()) {
          const base64String = await pdfDoc.saveAsBase64();
          base64ToPrinting(base64String, printType);
        } else {
          const pdfBytes = await pdfDoc.save();
          const bytes = new Uint8Array(pdfBytes);
          const blob = new Blob([bytes], { type: 'application/pdf' });
          window.open(window.URL.createObjectURL(blob));
        }
      });
    },
    [printType, printTypeState?.pageOrientation, printTypeState?.pageSize]
  );

  useEffect(() => {
    const pData = [];
    if (data) {
      const n = printTypeState?.quantity || 1;
      for (let d of data) {
        for (let i = 0; i < n; i++) {
          pData.push({ ...d });
        }
      }
    }
    setPrintData(pData);
  }, [data, printTypeState?.quantity]);

  const print = useCallback(
    (element, divId) => {
      sentToPrintService(element, divId);
    },
    [sentToPrintService]
  );

  // Export function
  return [printData, print];
};

export default usePrint;
