import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  filters: null,
  dataSource: [],
  dataListVinEdit: [],
  currentOffset: 0,
  currentPage: 1,
  totalRows: 0,
};
export const vinListSlice = createSlice({
  name: "vin_list",
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setvinListDataSelection: (state, { payload }) => {
      state.dataSource = payload;
    },
    setvinListDataModeEditVin: (state, { payload }) => {
      state.dataListVinEdit = payload;
    },
    setVinTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },

    setVinFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setCurrentPage,
  setCurrentOffset,
  setvinListDataSelection,
  setVinFilter,
  setvinListDataModeEditVin,
  setVinTotalRows,
} = vinListSlice.actions;

export const vinListSelector = (state) => state.vin;

export default vinListSlice.reducer;
