import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { Button, Select, Typography, Tooltip, Row } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import { getLanguage } from 'reducers/language';
import { useSelector } from 'react-redux';
const { Text } = Typography;
const { Option } = Select;

const TableComponent = (props) => {
  const {
    rowData,
    selectedRowKeyData,
    onClearSelectedRows,
    onSelectedRowKeys,
    selectedLane,
    selectedClearLane,
  } = props;

  const { height } = useWindowSize();
  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const laneRef = useRef();

  const laneChangeRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const language = useSelector(getLanguage);
  const columns = [
    {
      title: 'Cluster',
      dataIndex: ['actual_cluster_to', 'name'],
      width: 100,
      align: 'center',
      render: (text, record) => {
        return record.dispatch_board?.cluster
          ? record.dispatch_board.cluster.name
          : text;
      },
    },

    {
      title: 'BZP',
      dataIndex: ['bzp', 'job_name'],
      width: 150,
      align: 'center',
      render: (text, record) => {
        return record.dispatch_board?.bzp
          ? record.dispatch_board.bzp.job_name
          : text;
      },
    },
    {
      title: 'Calling No.',
      dataIndex: ['callingsheet', 'callingsheet_no'],
      align: 'center',
      width: 120,
      render: (text, record) => {
        return (
          <>
            {record.combine ? (
              <>
                <Text type='danger'>* </Text> {record.callingsheet_no}
              </>
            ) : record.callingsheet_no ? (
              record.callingsheet_no
            ) : (
              text
            )}
          </>
        );
      },
    },
    {
      title: 'Plan Receive Job',
      dataIndex: 'receive_job_datetime',
      align: 'center',
      width: 120,
      render: (text, record) => {
        return dateTimeTimestamptzFormatter({
          value: text,
        });
      },
    },
    {
      title: 'Actual Receive Job',
      dataIndex: 'actual_job_datetime',
      width: 120,
      align: 'center',
      render: (text, record) => {
        return record.actual_receive_job_datetime
          ? dateTimeTimestamptzFormatter({
              value: record.actual_receive_job_datetime,
            })
          : dateTimeTimestamptzFormatter({ value: text });
      },
    },
    {
      title: 'Different (HH:MM)',
      dataIndex: 'diff',
      width: 100,
      align: 'center',
    },
    {
      title: 'Reason',
      dataIndex: ['reason', 'label'],
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {record.reason && record.reason?.id ? (
              <Tooltip
                placement='bottom'
                title={
                  language === 'th'
                    ? record.reason && record.reason.reason_description
                      ? record.reason && record.reason.reason_description
                      : record.reason && record.reason.subject_reason.name_th
                    : record.reason && record.reason.reason_description
                    ? record.reason && record.reason.reason_description
                    : record.reason && record.reason.subject_reason.name_en
                }
              >
                <Button
                  shape='round'
                  size='small'
                  style={{
                    fontSize: 12,
                    whiteSpace: 'normal',
                    height: 'auto',
                    margin: '5px',
                    cursor: 'not-allowed',
                  }}
                >
                  {record.reason && record.reason.subject_reason.code}
                </Button>
              </Tooltip>
            ) : text === 'delay' ? (
              <Button
                size='small'
                style={{
                  backgroundColor: '#ffccc7',
                  width: '60px',
                  top: 1,
                }}
                danger
                onClick={() => showModal(record)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            ) : text === '-' || text === null ? (
              text
            ) : (
              <Tooltip
                placement='bottom'
                title={
                  language === 'th'
                    ? record.reason && record.reason.value.description
                      ? record.reason && record.reason.value.description
                      : record.reason &&
                        record.reason.value.subject.substring(5)
                    : record.reason && record.reason.value.description
                    ? record.reason && record.reason.value.description
                    : record.reason && record.reason.value.subject.substring(5)
                }
              >
                <Button
                  shape='round'
                  size='small'
                  style={{
                    fontSize: 12,
                    whiteSpace: 'normal',
                    height: 'auto',
                    margin: '5px',
                  }}
                  onClick={() => showModal(record)}
                >
                  {text}
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'Trailer lane',
      dataIndex: 'trailerLaneOptions',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return record.trailer_lane ? (
          record?.trailer_lane?.lane_no
        ) : record?.trailer_lane === null ? (
          '-'
        ) : laneRef.current &&
          laneRef.current.toString() === record.id &&
          record.reason &&
          record.reason.label !== 'delay' &&
          record.reason?.label !== null ? (
          <Select
            id={'trailer_lane'}
            style={{
              width: '100px',
            }}
            showSearch
            placeholder={'select'}
            optionFilterProp='children'
            onSelect={(e) => {
              selectedLane(e);
              laneChangeRef.current = e;
            }}
            defaultValue={text?.length > 0 ? laneChangeRef.current : undefined}
          >
            {text &&
              text.map((c, index) => (
                <Option key={index} value={c.id}>
                  {c.label}
                </Option>
              ))}
          </Select>
        ) : text === null ? null : (
          ''
        );
      },
    },
  ];

  useEffect(() => {
    setSelectedRowKeys(onSelectedRowKeys);
    laneRef.current = onSelectedRowKeys;
    setDataSource(rowData);
  }, [onSelectedRowKeys, rowData]);

  useEffect(() => {
    if (onClearSelectedRows) {
      clearSelectedRows();
    }
  }, [onClearSelectedRows]);

  useEffect(() => {
    if (selectedClearLane === null) {
      laneRef.current = '';
      laneChangeRef.current = '';
    }
  }, [selectedClearLane]);

  const clearSelectedRows = () => {
    setSelectedRowKeys([]);
  };

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  const showModal = (value) => {
    props.editReason(value);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      if (onSelectedRowKeys) {
        setSelectedRowKeys(selectedRowKeys);
      }

      if (selectedRowKeyData) {
        selectedRowKeyData(selectedRowKeys);
      }
    },
  };

  return (
    <AntTable
      rowKey='id'
      tableId={'receive_job'}
      rowSelection={{
        type: 'radio',
        ...rowSelection,
      }}
      showSorterTooltip={false}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      height={tableHeight}
      scroll={{
        y: tableHeight,
        scrollToFirstRowOnChange: true,
      }}
    />
  );
};

export default TableComponent;
