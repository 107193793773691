import React, { useEffect, useState } from 'react';
import {
  useFindDiagramOwnersByIdQuery,
  useCreateDiagramOwnersMutation,
  useUpdateDiagramOwnersMutation,
} from 'reducers/diagramOwners/api';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';

import { Form, Input, Select, Button, Divider, message, Modal } from 'antd';
import { useFindAllLocationMastersQuery } from 'reducers/masterData/locationMaster/api';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Sorter } from 'utils/sorter';
import { RemoveDuplicates } from 'utils/removeDuplicates';
import Table from './Table';
import permission from 'permissions';
import { getCurrentUser } from 'reducers/user';
import { useSelector } from 'react-redux';
import { diagramOwnerApi } from 'reducers/diagramOwners/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const DiagramOwnerFormComponent = (props) => {
  const { isCreate, readonly } = props;
  const { id, mode } = useParams();
  const { Option } = Select;
  //parameter
  const [nameDiagram, setNameDiagram] = useState([]);
  const [locationFrom, setLocationFrom] = useState([]);
  const [locationTo, setLocationTo] = useState([]);
  const [locationList, setLocationList] = useState([]);

  const [locationFromptions, setLocationFromptions] = useState([]);
  const [locationToOptions, setLocationToOptions] = useState([]);
  const [formData] = Form.useForm();

  const [privileges, setPrivileges] = useState();
  const { setErrorData } = useErrorHandler();
  const [companies, setCompanies] = useState();
  const dispatch = useDispatch();

  const onClickSubmit = async () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let item_list = [];
    console.log('locationList', locationList);
    for (let i = 0; i < locationList.length; i++) {
      let item = [];
      item.push(locationList[i]?.locationFrom?.code);
      item.push(locationList[i]?.locationTo?.code);
      item_list.push(item);
    }

    let body = {
      name: formData.getFieldsValue().name,
      id: id ? id : '',
      privilege: [
        {
          actions: item_list,
          subject: 'owner_from_to',
        },
      ],
    };
    if (mode === 'create') {
      createDiagramOwners(body);
    } else {
      updateDiagramOwners(body);
    }
  };

  //api
  // Query Location master Options
  const {
    data: locationData,
    error: errorLocationData,
    isFetching: isLocationDataFetching,
    isLoading: isLoadinglocationData,
    refetch: refetchLocationMaster,
  } = useFindAllLocationMastersQuery();

  useEffect(() => {
    if (locationData) {
      let defaultAll = {
        code: 'ALL',
        id: 'ALL',
        label: 'ALL',
        value: 'ALL',
      };

      let mapData = locationData.map((d) => ({
        id: d.id,
        code: d.code,
        label: d.short_name,
        value: d.name,
      }));
      mapData.push(defaultAll);
      mapData = RemoveDuplicates.LISTKEY(mapData, 'value');
      mapData = Sorter.DEFAULTLIST(mapData, 'value');

      setLocationToOptions(mapData);
      setLocationFromptions(mapData);
      if (mode != 'create') {
        findDiagramOwnersById(mapData);
      }
    }
  }, [locationData]);

  //create DiagramOwners
  const [
    createDiagramOwners,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateDiagramOwnersMutation();

  //update DiagramOwners
  const [
    updateDiagramOwners,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateDiagramOwnersMutation();

  // get by id
  const findDiagramOwnersById = async (mapData) => {
    try {
      let result = await dispatch(
        diagramOwnerApi.endpoints.findDiagramOwnersById.initiate(id)
      );
      if (result && result.error) {
        // setIsErrorSearch(result.error);
      } else {
        if (result.data) {
          formData.setFieldsValue({ name: result.data?.name });

          let result_data = result.data;
          let results_actions = result_data?.privilege[0]?.actions;

          let result_list = [];

          for (let i = 0; i < results_actions.length; i++) {
            let findLacationTo = mapData
              .filter((p) => p.code === results_actions[i][0])
              .map((d) => ({
                label: d.label,
                value: d.value,
                id: d.id,
                code: d.code,
              }));

            let findLacationFrom = mapData
              .filter((p) => p.code === results_actions[i][1])
              .map((d) => ({
                label: d.label,
                value: d.value,
                id: d.id,
                code: d.code,
              }));

            let obj = {
              locationFrom: findLacationTo[0],
              locationTo: findLacationFrom[0],
            };
            result_list.push(obj);
          }

          setLocationList(result_list);
        }
      }
    } catch (err) {
    } finally {
    }
  };

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  const onCheck = async () => {
    let check_dupicate_result_to = false;
    let check_dupicate_result_from = false;
    let total_list = [];
    let old_data = locationList;
    let data_obj = {};

    // let objForm = locationFromptions.find(
    //   (l) => l.value === locationFrom?.value
    // );
    // let objTo = locationToOptions.find((l) => l.value === locationTo?.value);
    console.log('locationFrom?.label :>> ', locationFrom?.label);
    console.log('locationTo?.label :>> ', locationTo?.label);
    console.log('locationList :>> ', locationList);

    if (locationFrom.length != 0 || locationTo.length != 0) {
      if (locationList.length != 0) {
        //check location from
        for (let i = 0; i < locationList.length; i++) {
          if (
            locationList[i]?.locationFrom?.label === locationFrom?.label &&
            locationList[i]?.locationTo?.label === locationTo?.label
          ) {
            console.log(
              'locationList[i]?.locationFrom?.label :>> ',
              locationList[i]?.locationFrom?.label
            );
            console.log('locationFrom?.label :>> ', locationFrom?.label);
            check_dupicate_result_from = true;
          }
        }

        if (check_dupicate_result_from === true) {
          setLocationList(old_data);
          message.error('data is duplicate !!');
        } else {
          total_list = old_data;
          data_obj = {
            locationFrom: locationFrom,
            locationTo: locationTo,
          };
          total_list.push(data_obj);
          console.log('total_list', total_list);
          setLocationList([...total_list]);
        }
      } else {
        data_obj = {
          locationFrom: locationFrom,
          locationTo: locationTo,
        };
        total_list.push(data_obj);
        setLocationList(total_list);
      }
    } else {
      message.error('Please select location !!');
    }
  };

  return (
    <Form
      name='basic'
      form={formData}
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      style={{ padding: '20px' }}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 16,
          span: 8,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          disabled={locationList.length < 1}
          // loading={isCreating || isUpdating}
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Name'
        name='name'
        initialValue={formData && formData.name}
        rules={[
          {
            required: true,
            message: 'Please input diafgram owner name',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Diafgram owner name'} />
      </Form.Item>

      <Form.Item
        name='location_from'
        label='Location From'
        rules={[
          {
            required: true,
            message: 'Please input Location From',
          },
        ]}
      >
        <Select
          showSearch
          allowClear={true}
          maxTagCount='responsive'
          placeholder={'Location From'}
          loading={isLoadinglocationData}
          value={locationFrom}
          // options={locationFromptions}
          onChange={(values, obj, data) => {
            console.log('values :>> ', values);
            console.log('obj :>> ', obj);
            console.log('data :>> ', data);
            let obj_convert = {
              label: obj?.children,
              code: obj?.value,
            };
            setLocationFrom(obj_convert);
          }}
          optionFilterProp='children'
          filterOption={(input, option) => {
            return option.children.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {locationFromptions &&
            locationFromptions.map((c, index) => (
              <Option key={index} value={c.code}>
                {c.label}
              </Option>
            ))}
        </Select>

        {/* <Select
          mode="single"
          showSearch
          allowClear={true}
          maxTagCount="responsive"
          placeholder={"Location From"}
          loading={isLoadinglocationData}
          value={locationFrom}
          options={locationFromptions}
          onChange={(values, obj) => {
            setLocationFrom(obj);
          }}
        />

        {/* <Select
          mode="single"
          size="small"
          allowClear={true}
          loading={isLoadinglocationData}
          maxTagCount="responsive"
          placeholder={"Location From"}
          value={locationFrom}
          onChange={(values, obj) => {
            console.log('values', values)
            console.log('values', obj)
            setLocationFrom(obj);
          }}
          showSearch
          optionFilterProp="children"
        >
          {locationFromptions &&
            locationFromptions.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
        </Select> */}
      </Form.Item>

      <Form.Item
        name='location_to'
        label='Location To'
        rules={[
          {
            required: true,
            message: 'Please input Location To',
          },
        ]}
      >
        <Select
          mode='single'
          showSearch
          allowClear={true}
          maxTagCount='responsive'
          placeholder={'Location To'}
          loading={isLoadinglocationData}
          value={locationTo}
          onChange={(values, obj) => {
            console.log('values :>> ', values);
            console.log('obj :>> ', obj);
            let obj_convert = {
              label: obj?.children,
              code: obj?.value,
            };
            setLocationTo(obj_convert);
          }}
          optionFilterProp='children'
          filterOption={(input, option) => {
            return option.children.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {locationToOptions &&
            locationToOptions.map((c, index) => (
              <Option key={index} value={c.code}>
                {c.label}
              </Option>
            ))}
        </Select>

        {/* <Select
          mode="single"
          showSearch
          allowClear={true}
          maxTagCount="responsive"
          placeholder={"Location To"}
          loading={isLoadinglocationData}
          value={locationTo}
          options={locationToOptions}
          onChange={(values, obj) => {
            setLocationTo(obj);
          }}
        />
        {/* <Select
          mode="single"
          size="small"
          allowClear={true}
          loading={isLoadinglocationData}
          maxTagCount="responsive"
          placeholder={"Location To"}
          value={locationTo}
          onChange={(values, obj) => {
            setLocationTo(obj);
          }}
          showSearch
          optionFilterProp="children"
        >
          {locationToOptions &&
            locationToOptions.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
        </Select> */}
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 4,
          span: 8,
        }}
      >
        <Button type='primary' htmlType='submit' onClick={onCheck}>
          Add item to Table
        </Button>
      </Form.Item>

      <Form.Item
        wrapperCol={{
          offset: 3,
          span: 16,
        }}
      >
        <Table
          locationList={locationList}
          setLocationList={setLocationList}
        ></Table>
      </Form.Item>
    </Form>
  );
};
const StyledDivider = styled(Divider)`
  margin: 8px 0px !important;
`;

export default DiagramOwnerFormComponent;
