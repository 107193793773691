import React from 'react';
import { Result, Button } from 'antd';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

const PageNotFound = (props) => {
  const dispatch = useDispatch();

  return (
    <StyledResult
      child
      status='500'
      title='500'
      subTitle='Sorry, something went wrong.'
      extra={
        <Button
          type='primary'
          onClick={() => {
            dispatch(goBack());
          }}
        >
          Back
        </Button>
      }
    />
  );
};

const StyledResult = styled(Result)`
  height: ${(props) => (props.child ? '90%' : '100%')};
`;
export default PageNotFound;
