import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import styled from 'styled-components';
import Json2CSV from 'utils/json2csv';
import moment from 'moment';
import { Row, Col, Button, Spin, Modal } from 'antd';
import './index.css';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useFindSummaryAlLocationCompanyQuery } from 'reducers/summaryAllocation/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { DetailHeaderHandler } from './else';
import { LoadingOutlined } from '@ant-design/icons';

const TableSummaryDetailComponent = (props) => {
  // const { l } = props;
  const { setErrorData } = useErrorHandler();
  const timer = useRef(null);
  const onBtnGotoTourList = () => {
    dispatch(push(`/app/planning/manage_tour_list`));
  };

  const headerTableRef = useRef([
    {
      title: 'Total',
      title_value: '',
      dataIndex: 'total',
      key: 1,
    },
    {
      title: 'Mon',
      title_value: '2022-01-01',
      dataIndex: 'date1Value',
      key: 2,
    },
    {
      title: 'Tue',
      title_value: '2022-01-02',
      dataIndex: 'date2Value',
      key: 3,
    },
    {
      title: 'Wed',
      title_value: '2022-01-03',
      dataIndex: 'date3Value',
      key: 4,
    },
    {
      title: 'Thu',
      title_value: '2022-01-04',
      dataIndex: 'date4Value',
      key: 5,
    },
    {
      title: 'Fri',
      title_value: '2022-01-05',
      dataIndex: 'date5Value',
      key: 6,
    },
    {
      title: 'Sat',
      title_value: '2022-01-06',
      dataIndex: 'date6Value',
      key: 7,
    },
    {
      title: 'Sun',
      title_value: '2022-01-07',
      dataIndex: 'date7Value',
      key: 8,
    },
  ]);
  const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;
  const dispatch = useDispatch();

  const [dataSourceCompany, setDataSourceCompany] = useState([]);

  const [columns, setColumns] = useState([]);
  const [IsDownloading, SetIsDownloading] = useState(false);

  // -------------API------------------------------------------
  const {
    data: dataCompany,
    error: errorCompany,
    isLoading: isLoadingOverall,
    refetch,
    isFetching,
  } = useFindSummaryAlLocationCompanyQuery();

  useEffect(() => {
    if (isFetching) {
      return;
    }
    clearInterval(timer);
    timer.current = setTimeout(async () => {
      await refetch();
    }, 5000);
    return () => clearInterval(timer);
  }, [isFetching, refetch]);

  useEffect(() => {
    if (!dataCompany) return;
    const { header } = dataCompany;
    setColumns([
      {
        title: () => {
          return <b>Company</b>;
        },
        dataIndex: 'company',
        width: 90,
        align: 'center',
        className: 'color-head-6',
        render: (text, record, index) => {
          return {
            children: (
              <>
                <h3>{text || '-'}</h3>
                {+record.percent_payment.toFixed(1)} %
              </>
            ),
          };
        },
      },
      ...(header || []).map((one, idx) => DetailHeaderHandler(one, idx)),
    ]);

    // console.log("[useEffect] dataCompany", dataCompany);
    setDataSourceCompany(dataCompany?.data ? dataCompany?.data : []);
    if (dataCompany?.message === 'plan job does not exist') {
      Modal.warning({
        title: 'ไม่สามารถใช้งานหน้า Summary Allocation ได้',
        content: (
          <div>
            <p>เนื่องจาก ขั้นตอนหน้า Create Tour ยังไม่สมบูรณ์</p>
          </div>
        ),
        width: 500,
        onOk() {},
      });
    }
    let arr_res = dataCompany?.header;
    let arr_list = [
      {
        title: 'Total',
        title_value: '',
        dataIndex: 'total',
        key: 1,
      },
      ...(arr_res || []).map((col, idx) => ({
        title: col.title,
        title_value: col.title_value,
        dataIndex: 'date1Value',
        key: idx + 1,
      })),
    ];
    headerTableRef.current = arr_list;

    return () => {
      headerTableRef.current = null;
    };
  }, [dataCompany]);

  useEffect(() => {
    if (errorCompany) {
      setErrorData(errorCompany);
    }
  }, [errorCompany, setErrorData]);

  async function onDownload() {
    SetIsDownloading(true);
    const now = moment().format('YYYYMMDD_HHmmss');
    try {
      const csvData = dataSourceCompany.map((row, idx) => ({
        no: idx + 1,
        bzp: row.company,
        payment: row.total_payment,
        allocation_ratio_payment: row.percent_payment.toFixed(1),
        capacity: row.total_capacity,
        use: row.total_use,
        remain: row.total_capacity - row.total_use,
      }));
      Json2CSV(csvData, `allocation-${now}.csv`, ',');
    } catch (err) {
      console.log('download err: ', err);
    } finally {
      SetIsDownloading(false);
    }
  }

  return (
    <Container>
      <Row align='end' style={{ marginRight: '15px', marginBottom: '15px' }}>
        <Col>
          <Button
            type='ghost'
            size='small'
            style={{ marginTop: '20px', marginRight: '1rem' }}
            onClick={onDownload}
            disabled={IsDownloading}
            loading={IsDownloading}
          >
            Download Report
          </Button>
        </Col>
        <Col>
          <Button
            type='primary'
            size='small'
            style={{ marginTop: '20px' }}
            onClick={onBtnGotoTourList}
          >
            Manage Tour List
          </Button>
        </Col>
      </Row>

      {isLoadingOverall === true ? (
        <RootCircular>
          <CenterSpin indicator={antIcon} size='large' />
        </RootCircular>
      ) : (
        <>
          {columns && dataSourceCompany && (
            <StyledAntTable
              rowKey='id'
              tableId={'id'}
              bordered
              showSorterTooltip={false}
              columns={columns}
              dataSource={dataSourceCompany}
              pagination={false}
              scroll={{ x: 'max-content' }}
            />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;
const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
export default TableSummaryDetailComponent;
