import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';

import {
  Button,
  Space,
  Select,
  Typography,
  Collapse,
  Row,
  message,
  Card,
  Col,
  Input,
} from 'antd';
import styled from 'styled-components';

import { useErrorHandler } from 'common_components/ErrorContext';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setCallingSheetFilter } from 'reducers/callingsheet';
import { callingsheetApi } from 'reducers/callingsheet/api';
import { SearchOutlined } from '@ant-design/icons';
import { Sorter } from 'utils/sorter';
const { Panel } = Collapse;
const { Option } = Select;
const createPayload = ({
  filterLocationFroms,
  filterRegions,
  filterClusters,
  filterCalling,
  diagram_owner_id,
}) => {
  return {
    location_from:
      filterLocationFroms && filterLocationFroms.length > 0
        ? filterLocationFroms
        : ['All'],
    region_id:
      filterRegions && filterRegions.length > 0 ? filterRegions : ['All'],
    cluster_id:
      filterClusters && filterClusters.length > 0 ? filterClusters : ['All'],
    callingsheet_no: filterCalling ? filterCalling : null,
    mode: diagram_owner_id ? 'dispatch_board' : null,
    diagram_owner_id: diagram_owner_id ? diagram_owner_id : null,
  };
};

const TableToolbar = (props) => {
  const {
    isLoading,
    onInit,
    onQuery,
    showFilter,
    setShowFilter,
    callingCompleteCount,
    limit,
  } = props;
  const dispatch = useDispatch();
  const clusterRef = useRef();
  const regionRef = useRef();

  // Store filters
  const storedFilters = useSelector((state) => {
    return state.callingsheet.filters;
  });
  const { diagram_owner_id, fromUrl, clusterUrl, regionUrl } = useParams();
  const [disableFilterUrl, setDisableFilterUrl] = useState(false);
  const { setErrorData } = useErrorHandler();
  const [locationFromOptions, setLocationFromOptions] = useState(
    storedFilters && storedFilters.locationFromOptions
      ? storedFilters.locationFromOptions
      : []
  );
  const [regionOptions, setRegionOptions] = useState(
    storedFilters && storedFilters.regionOptions
      ? storedFilters.regionOptions
      : []
  );
  const [clusterOptions, setClusterOptions] = useState(
    storedFilters && storedFilters.clusterOptions
      ? storedFilters.clusterOptions
      : []
  );
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [filterLocationFroms, setFilterLocationFroms] = useState(
    storedFilters && storedFilters.filterLocationFroms
      ? storedFilters.filterLocationFroms
      : ['All']
  );
  const [filterRegions, setFilterRegions] = useState(
    storedFilters && storedFilters.filterRegions
      ? storedFilters.filterRegions
      : ['All']
  );
  const [filterClusters, setFilterClusters] = useState(
    storedFilters && storedFilters.filterClusters
      ? storedFilters.filterClusters
      : ['All']
  );
  const [filterCalling, setFilterCalling] = useState(
    storedFilters && storedFilters.filterCalling
      ? storedFilters.filterCalling
      : undefined
  );

  const defaultFilter = useMemo(() => {
    return {
      location_from: ['All'],
      cluster_id: ['All'],
      region_id: ['All'],
      mode: diagram_owner_id ? 'dispatch_board' : null,
      diagram_owner_id: diagram_owner_id ? diagram_owner_id : null,
    };
  }, [diagram_owner_id]);

  const onQueryOption = useCallback(
    async (payload) => {
      let query = {
        ...payload,
        location_from: ['All'], // Fix location from to all
      };
      try {
        setIsLoadingFilter(true);
        let result = await dispatch(
          callingsheetApi.endpoints.findAllCallingSheetsBySelection.initiate(
            query,
            {
              forceRefetch: true,
            }
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let arrLocationFrom = [
              {
                label: 'All',
                value: 'All',
              },
            ];

            let arrRegion = [
              {
                label: 'All',
                value: 'All',
              },
            ];
            let arrCluster = [
              {
                label: 'All',
                value: 'All',
              },
            ];
            let locationFrom = result.data.localtion_froms.map((d) => ({
              label: d.short_name,
              value: d.code,
            }));
            let region = result.data.regions.map((d) => ({
              label: d.name,
              value: d.id,
            }));
            let cluster = result.data.clusters.map((d) => ({
              label: d.name,
              value: d.id,
            }));

            arrLocationFrom.push(...locationFrom);
            arrRegion.push(...region);
            arrCluster.push(...cluster);

            arrLocationFrom = Sorter.DEFAULTLIST(arrLocationFrom, 'label');
            arrRegion = Sorter.DEFAULTLIST(arrRegion, 'label');
            arrCluster = Sorter.DEFAULTLIST(arrCluster, 'label');

            setLocationFromOptions(arrLocationFrom);
            setRegionOptions(arrRegion);
            setClusterOptions(arrCluster);
            let newArrCluster = [];

            if (clusterUrl && clusterUrl !== 'regionUrl') {
              let arr = clusterUrl.split(',');

              for (let index = 0; index < arr.length; index++) {
                let filterCluster = arrCluster.find(
                  (f) => f.label === arr[index]
                );
                if (filterCluster) {
                  newArrCluster.push(filterCluster.value);
                }
              }
              setFilterClusters(newArrCluster);
              clusterRef.current = newArrCluster;
            }
            let newArrRegion = [];
            if (regionUrl) {
              newArrRegion = arrRegion.find((f) => f.label === regionUrl);
              if (newArrRegion) {
                setFilterRegions(newArrRegion.value);
                regionRef.current = newArrRegion.value;
              }
            }
            if (clusterUrl || regionUrl || (clusterUrl && regionUrl)) {
              setDisableFilterUrl(true);
              setFilterLocationFroms(fromUrl);
              if (newArrRegion) {
                setFilterRegions(newArrRegion?.value);
                onInit({
                  ...createPayload({
                    filterLocationFroms: fromUrl,
                    filterClusters: newArrCluster,
                    filterRegions: newArrRegion?.value,
                    diagram_owner_id,
                  }),
                });
              }
            } else {
              setDisableFilterUrl(false);
            }
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      }
      setIsLoadingFilter(false);
    },
    [dispatch, limit, setErrorData]
  );

  useEffect(() => {
    if (onInit) {
      if (!storedFilters) {
        if (clusterUrl || regionUrl || (clusterUrl && regionUrl)) {
          onQueryOption({
            ...createPayload({
              filterLocationFroms,
              filterClusters: clusterRef.current,
              filterRegions: regionRef.current,
              diagram_owner_id,
            }),
          });
        } else {
          onInit(createPayload(defaultFilter));
          onQueryOption(createPayload(defaultFilter));
        }
      } else {
        onInit(
          createPayload({
            filterLocationFroms,
            filterClusters,
            filterRegions,
            diagram_owner_id,
          })
        );
        if (clusterUrl || regionUrl || (clusterUrl && regionUrl)) {
          setDisableFilterUrl(true);
        } else {
          setDisableFilterUrl(false);
        }
      }
    }
  }, [onInit]);

  useEffect(() => {
    dispatch(
      setCallingSheetFilter({
        filterLocationFroms,
        filterClusters,
        filterRegions,
        locationFromOptions,
        regionOptions,
        clusterOptions,
      })
    );
  }, [
    filterLocationFroms,
    filterClusters,
    filterRegions,
    dispatch,
    locationFromOptions,
    regionOptions,
    clusterOptions,
  ]);

  const onDisplayButton = () => {
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterLocationFroms,
          filterClusters,
          filterRegions,
          filterCalling,
          diagram_owner_id,
        }),
      });

      onQueryOption({
        ...createPayload({
          filterLocationFroms,
          filterClusters,
          filterRegions,
          filterCalling,
          diagram_owner_id,
        }),
      });
    }
  };

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '200px',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoadingFilter,
  };

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Filter Options' key={1}>
          <Space style={{ padding: 10 }}>
            <CardStyle>
              <Space direction={'vertical'} size={3}>
                <ReadonlyTextHead>Calling Complete</ReadonlyTextHead>

                <ReadonlyText>
                  {callingCompleteCount ? callingCompleteCount : '-'}
                </ReadonlyText>
              </Space>
            </CardStyle>
            <CardStyleFrom>
              <Space size={16}>
                <Space direction='vertical'>
                  <Row gutter={[12]}>
                    <Col>
                      <Space direction='vertical'>
                        <StyledText>From</StyledText>
                        <Select
                          disabled={disableFilterUrl}
                          {...selectProps}
                          value={filterLocationFroms}
                          placeholder='From'
                          onChange={(values) => {
                            setFilterLocationFroms(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {locationFromOptions &&
                            locationFromOptions.map((c, index) => (
                              <Option key={index} value={c.value}>
                                {c.label}
                              </Option>
                            ))}
                        </Select>
                      </Space>
                    </Col>
                    <Col>
                      <Space direction='vertical'>
                        <>
                          <StyledText>Cluster</StyledText>
                          <Select
                            disabled={disableFilterUrl}
                            {...selectProps}
                            placeholder={'Cluster'}
                            value={filterClusters}
                            onChange={(values) => {
                              setFilterClusters(values);
                            }}
                            optionFilterProp='children'
                            filterOption={(input, option) => {
                              return option.children
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          >
                            {clusterOptions &&
                              clusterOptions.map((c, index) => (
                                <Option key={index} value={c.value}>
                                  {c.label}
                                </Option>
                              ))}
                          </Select>
                        </>
                      </Space>
                    </Col>
                    <Col>
                      <Space direction='vertical'>
                        <>
                          <StyledText>Region</StyledText>
                          <Select
                            disabled={disableFilterUrl}
                            {...selectProps}
                            placeholder={'Region'}
                            value={filterRegions}
                            onChange={(values) => {
                              setFilterRegions(values);
                            }}
                            optionFilterProp='children'
                            filterOption={(input, option) => {
                              return option.children
                                .toLowerCase()
                                .includes(input.toLowerCase());
                            }}
                          >
                            {regionOptions &&
                              regionOptions.map((c, index) => (
                                <Option key={index} value={c.value}>
                                  {c.label}
                                </Option>
                              ))}
                          </Select>
                        </>
                      </Space>
                    </Col>
                    <Col>
                      <Space direction='vertical'>
                        <>
                          <StyledText>Calling No.</StyledText>
                          <Input
                            allowClear
                            style={{ width: '200px', fontSize: '13px' }}
                            placeholder={`Calling no`}
                            value={filterCalling}
                            onChange={(e) => {
                              setFilterCalling(e.target.value.trim());
                            }}
                          />
                        </>
                      </Space>
                    </Col>
                    <Col>
                      <Space direction='vertical'>
                        <>
                          <Button
                            disabled={disableFilterUrl}
                            type='primary'
                            style={{ marginTop: 30 }}
                            loading={isLoading}
                            onClick={() => onDisplayButton()}
                            icon={<SearchOutlined />}
                          >
                            Search
                          </Button>
                        </>
                      </Space>
                    </Col>
                  </Row>
                </Space>
              </Space>
            </CardStyleFrom>
          </Space>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;
const ReadonlyTextHead = styled(Typography)`
  border-radius: 2px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
`;
const ReadonlyText = styled(Typography)`
  background: #fff2cc;
  border: 1px solid #000000;
  border-radius: 3px;
  font-weight: bold;
  padding: 2px;
  text-align: center;
`;

const Container = styled.div`
  padding: 10px 0;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
const CardStyle = styled(Card)`
  .ant-card-body {
    padding-top: 11px;
    padding-bottom: 22px;
    padding-left: 20px;
    padding-right: 0px;
  }
  width: 164px;
  border: 1px solid #d9d9d9;
`;
const CardStyleFrom = styled(Card)`
  .ant-card-body {
    padding-top: 10px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 20px;
  }
  width: '100%';
  border: 1px solid #d9d9d9;
`;
export default TableToolbar;
