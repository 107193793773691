import React, { useState } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  Typography,
  Collapse,
} from 'antd';
import styled from 'styled-components';
import AntTable from 'common_components/AntTable';
import { dateFormatterVLP } from 'common_components/AntTable/tableUtils';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
const { Panel } = Collapse;

const TableToolbar = (props) => {
  const {
    //parameter
    yardDate,
    titleDate,
    filterSelectFrom,
    filterOptionFrom,
    filterSelectDate,
    setFilterSelectDate,
    filterOptionDate,
    setFilterSelectFrom,
    isLoadingSearchTable,
    //function
    isLoadingEntry,
    apiSearchTable,
    isLoading,
    //for confirm
    planJobId,
    confirmManageTripBoard,
    isLoadingConfirm,
    statusManageTrip,
  } = props;

  const dispatch = useDispatch();
  const onBtnConfirm = () => {
    let payload = {
      plan_job_id: planJobId,
    };
    confirmManageTripBoard(payload);
  };

  const onBtnSearch = () => {
    apiSearchTable();
  };


  const [columnsHeader, setColumnsHeader] = useState([
    {
      title: 'Yard / Date',
      dataIndex: 'yard',
      align: 'center',
      width: 150,
    },
    {
      title: () => {
        let day = titleDate.current && titleDate.current[0]?.day;
        let date = titleDate.current && titleDate.current[0]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const res = text[0]?.value;
        return res && res;
      },
    },
    {
      title: () => {
        let day = titleDate.current && titleDate.current[1]?.day;
        let date = titleDate.current && titleDate.current[1]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const res = text[1]?.value;
        return res && res;
      },
    },
    {
      title: () => {
        let day = titleDate.current && titleDate.current[2]?.day;
        let date = titleDate.current && titleDate.current[2]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const res = text[2]?.value;
        return res && res;
      },
    },
    {
      title: () => {
        let day = titleDate.current && titleDate.current[3]?.day;
        let date = titleDate.current && titleDate.current[3]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const res = text[3]?.value;
        return res && res;
      },
    },
    {
      title: () => {
        let day = titleDate.current && titleDate.current[4]?.day;
        let date = titleDate.current && titleDate.current[4]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const res = text[4]?.value;
        return res && res;
      },
    },
    {
      title: () => {
        let day = titleDate.current && titleDate.current[5]?.day;
        let date = titleDate.current && titleDate.current[5]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const res = text[5]?.value;
        return res && res;
      },
    },
    {
      title: () => {
        let day = titleDate.current && titleDate.current[6]?.day;
        let date = titleDate.current && titleDate.current[6]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date',
      align: 'center',
      width: 150,
      render: (text, record) => {
        const res = text[6]?.value;
        return res && res;
      },
    },
  ]);

  //---------------------------------------------------------

  //Model----------------------------------------------------

  // ---------------------------------------------------------
  return (
    <Container>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[20]}
            style={{ margin: '0px 10px 5px 10px' }}
            align='middle'
            justify='center'
          >
            <Col span='12'>
              {' '}
              <Row gutter={[10]} style={{ marginBottom: '10px' }}>
                {/* <Col span={12}> */}
                <Col span={4} align='end'>
                  <Typography>Working date</Typography>
                </Col>
                <Col>
                  <Select
                    mode='single'
                    style={{
                      textAlign: 'center',
                      width: '200px',
                    }}
                    loading={isLoadingEntry}
                    maxTagCount='responsive'
                    placeholder={'working date'}
                    value={filterSelectDate}
                    options={filterOptionDate}
                    onChange={(values) => {
                      setFilterSelectDate(values);
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    type='primary'
                    style={{ width: '100px' }}
                    onClick={onBtnSearch}
                    loading={isLoading}
                    disabled={!filterSelectFrom || !filterSelectDate}
                  >
                    {/* Create Tour */}
                    Search
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    style={{ width: '100px' }}
                    onClick={onBtnConfirm}
                    loading={
                      isLoadingSearchTable ||
                      isLoadingConfirm ||
                      !statusManageTrip
                    }
                    disabled={
                      statusManageTrip === 'read' ||
                      statusManageTrip === 'finish' ||
                      !statusManageTrip
                    }
                  >
                    {/* Create Tour */}
                    Confirm
                  </Button>
                </Col>

              </Row>
              <Row gutter={[10]}>
                <Col span={4} align='end'>
                  <Typography>From</Typography>
                </Col>
                <Col>
                  <Select
                    mode='single'
                    style={{
                      textAlign: 'center',
                      width: '200px',
                    }}
                    loading={isLoadingEntry}
                    maxTagCount='responsive'
                    placeholder={'from'}
                    value={filterSelectFrom}
                    options={filterOptionFrom}
                    onChange={(values) => {
                      setFilterSelectFrom(values);
                    }}
                  />
                </Col>

                <Col>
                  <Button
                    type='primary'
                    style={{ width: '100px' }}
                    onClick={() => {
                      dispatch(push(`/app/planning/create_lot`));
                    }}
                    loading={isLoadingSearchTable}
                  >
                    Create Lot
                  </Button>
                </Col>
                <Col>
                  <Button
                    type='primary'
                    style={{ width: '100px' }}
                    onClick={() => {
                      dispatch(push(`/app/planning/create_tour`));
                    }}
                    loading={isLoadingSearchTable}
                    disabled={statusManageTrip !== 'finish'}
                  >
                    Create Tour
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span='12'>
              {' '}
              <Row
                gutter={[20]}
                style={{ marginBottom: '20px' }}
                align='middle'
                justify='center'
              >
                <Col>
                  <Typography style={{ marginBottom: '5px' }}>
                    {' '}
                    Summary all unit
                  </Typography>
                  <AntTable
                    rowKey='id'
                    tableId={'id'}
                    bordered
                    showSorterTooltip={false}
                    columns={columnsHeader}
                    dataSource={yardDate}
                    pagination={false}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default TableToolbar;
