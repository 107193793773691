import React, { useEffect, useState } from 'react';
import permission from '../permissions';
import { EditTwoTone } from '@ant-design/icons';

const EditBtnRenderer = (props) => {
  const { user, subject, data, onClick } = props;

  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (permission.checkPermission(user, subject, ['u'])) {
      setReadOnly(false);
    } else {
      setReadOnly(true);
    }
  }, [user, subject]);

  const invokeParentMethod = (e) => {
    e.stopPropagation();
    e.preventDefault();
    onClick(data, readOnly);
  };

  return <EditTwoTone onClick={invokeParentMethod} />;
};

export default EditBtnRenderer;
