import React, { Component } from 'react';
import { connect } from 'react-redux';
import ImPolyline from './ImPolyline';
import WayPoints from './RouteMasterEditWP/WayPoints';
import WPFloatingBox from './RouteMasterEditWP/WPFloatingBox';
import RoutesViewBoxes from './RouteMasterEditWP/RoutesViewBoxes';

import uuidv4 from 'uuid/v4';
import { Marker } from '@react-google-maps/api';

class RouteMaster extends Component {
  render() {
    return (
      <>
        {this.props.show_master_or_candidate !== 2 &&
          this.props.masterPolylineList &&
          this.props.masterPolylineList.length > 0 &&
          this.props.masterPolylineList.map((polyline, index) => {
            return (
              <ImPolyline
                key={index}
                id={polyline.id}
                path={polyline.path}
                editable={polyline.editable}
                info={polyline.info}
                routeType='route_master'
              />
            );
          })}
        {this.props.show_master_or_candidate === 2 &&
          this.props.candidatePolylineList &&
          this.props.candidatePolylineList.length > 0 &&
          this.props.candidatePolylineList.map((polyline, index) => {
            return (
              <ImPolyline
                key={index}
                id={polyline.id}
                path={polyline.path}
                editable={polyline.editable}
                info={polyline.info}
                routeType='route_candidate'
              />
            );
          })}
        <WayPoints
          key={uuidv4()}
          legs={this.props.legs}
          waypoints={this.props.waypoints}
        />
        {this.props.waypoints &&
          this.props.waypoints.constructor === Array &&
          this.props.waypoints.length > 1 && <WPFloatingBox key={uuidv4()} />}

        {this.props.routesview &&
          this.props.routesview.constructor === Array &&
          this.props.routesview.length > 0 && (
            <RoutesViewBoxes
              key={uuidv4()}
              routesview={this.props.routesview}
            />
          )}
        <Marker
          key={uuidv4()}
          position={this.props.marker_origin}
          icon={{
            url: 'http://www.google.com/mapfiles/dd-start.png',
            labelOrigin: new window.google.maps.Point(19, 14),
            scaledSize: new window.google.maps.Size(22, 36),
            size: new window.google.maps.Size(22, 36),
          }}
        />
        <Marker
          key={uuidv4()}
          position={this.props.marker_desc}
          icon={{
            url: 'http://www.google.com/mapfiles/dd-end.png',
            labelOrigin: new window.google.maps.Point(19, 14),
            scaledSize: new window.google.maps.Size(22, 36),
            size: new window.google.maps.Size(22, 36),
          }}
        />
      </>
    );
  }
}
const mapStateToProps = function (state) {
  return {
    formManage: state.ui.formManage,
    markerList: state.map.markerList,
    circleList: state.map.circleList,
    candidatePolylineList: state.map.candidatePolylineList,
    masterPolylineList: state.map.masterPolylineList,
    legs: state.map.legs,
    waypoints: state.map.waypoints,
    routesview: state.map.routesview,
    marker_origin: state.map.marker_origin,
    marker_desc: state.map.marker_desc,
    show_master_or_candidate: state.map.show_master_or_candidate,
  };
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(RouteMaster);
