import { decode } from '@googlemaps/polyline-codec';

// auth middleware
const digitalMapUtils = {
  setMarkers: (data, editable) => {
    let markerList = [];
    let marker = {};
    if (data) {
      data.forEach((d, i) => {
        if (
          d.location_master &&
          d.location_master.lat &&
          d.location_master.lng
        ) {
          let point = d.location_master;
          //Marker
          marker = {
            id: point.id,
            position: { lat: point.lat, lng: point.lng },
            info: {
              name: d.code,
              description: d.name,
            },
            isGroup: false,
            editable: editable,
          };

          markerList.push(marker);
        }
      });
    }
    return markerList;
  },
  setCircles: (data, editable) => {
    let circleList = [];
    let circle = {};
    if (data) {
      data.forEach((d, i) => {
        if (
          d.location_master &&
          d.location_master.circle_lat &&
          d.location_master.circle_lng
        ) {
          let point = d.location_master;
          if (point.geofence_type === 'circle') {
            circle = {
              id: point.id,
              center: { lat: point.circle_lat, lng: point.circle_lng },
              radius: point.radius,
              info: {
                name: d.code,
                description: point.name,
              },
              isGroup: false,
              editable: editable,
            };
            circleList.push(circle);
          }
        }
      });
    }

    return circleList;
  },
  setPolygons: (data, editable) => {
    let polygonList = [];
    let polygon = {};

    if (data) {
      data.forEach((d, i) => {
        if (d.location_master && d.location_master.polygon) {
          let point = d.location_master;
          if (point.geofence_type === 'polygon') {
            var decodePath = decode(point.polygon);
            var paths = [];
            decodePath.forEach(function (p) {
              paths.push({ lat: p[0], lng: p[1] });
            });
            polygon = {
              id: point.id,
              path: paths,
              info: {
                name: d.code,
                description: d.name,
              },
              isGroup: false,
              editable: editable,
            };
            polygonList.push(polygon);
          }
        }
      });
      return polygonList;
    }
  },
  setLocationMasterBounds: (data) => {
    let bounds = [];
    if (data) {
      data.forEach((d, i) => {
        if (
          d.location_master &&
          d.location_master.lat &&
          d.location_master.lng
        ) {
          let point = d.location_master;
          bounds.push({ lat: point.lat, lng: point.lng });
          if (point.geofence_type === 'circle') {
            bounds.push({
              lat: point.circle_lat,
              lng: point.circle_lng,
            });
          } else if (point.geofence_type === 'polygon') {
            var decodePath = decode(point.polygon);
            decodePath.forEach(function (p) {
              bounds.push({ lat: p[0], lng: p[1] });
            });
          }
        }
      });
    }
    return bounds;
  },
  setOdBounds: (origin, destination) => {
    let bounds = [];
    if (origin) {
      bounds.push({
        lat: origin.lat,
        lng: origin.lng,
      });
    }
    if (destination) {
      bounds.push({
        lat: destination.lat,
        lng: destination.lng,
      });
    }
    return bounds;
  },
  createCandidateRoutesBounds: (data) => {
    let bounds = [];
    if (data) {
      data.forEach((item, i) => {
        if (item.routes) {
          item = item.routes;
        }
        var decodePath = decode(
          decodeURIComponent(item.MergeRoute ? item.MergeRoute : item.Route)
        );

        decodePath.forEach(function (p) {
          bounds.push({ lat: p[0], lng: p[1] });
        });
      });
    }
    return bounds;
  },
  createRouteCandidatePolylines: (data, editable) => {
    let candidatePolylineList = [];
    let polyline = {};
    if (data) {
      data.forEach((item, i) => {
        let id = 0;
        if (item.id) id = item.id;
        else id = item.Vehicle;

        if (item.routes) item = item.routes;

        var decodePath = decode(
          decodeURIComponent(item.MergeRoute ? item.MergeRoute : item.Route)
        );
        var paths = [];

        decodePath.forEach(function (p) {
          paths.push({ lat: p[0], lng: p[1] });
        });

        polyline = {
          id: id,
          path: paths,
          info: {
            name: item.name,
            description: item.detail,
            logisticsPoint: item.logisticsPoint,
          },
          isGroup: false,
          editable: editable,
        };
        candidatePolylineList.push(polyline);
      });
      return candidatePolylineList;
    }
  },
  createRouteMasterPolylines: (data, editable) => {
    let routePolylineList = [];
    let polyline = {};
    if (data) {
      data.forEach((item, i) => {
        let id = 0;
        if (item.id) id = item.id;
        else id = item.Vehicle;

        if (item.routes) item = item.routes;

        var decodePath = decode(decodeURIComponent(item.MergeRoute));

        var paths = [];

        decodePath.forEach(function (p) {
          paths.push({ lat: p[0], lng: p[1] });
        });

        polyline = {
          id: id,
          path: paths,
          info: {
            name: item.name,
            description: item.detail,
            logisticsPoint: item.logisticsPoint,
          },
          isGroup: false,
          editable: editable,
        };
        routePolylineList.push(polyline);
      });
      return routePolylineList;
    }
  },
  createRouteMasterBounds: (data) => {
    let bounds = [];
    if (data) {
      data.forEach((item, i) => {
        if (item.routes) {
          item = item.routes;
        }
        var decodePath = decode(
          decodeURIComponent(item.MergeRoute ? item.MergeRoute : item.Route)
        );

        decodePath.forEach(function (p) {
          bounds.push({ lat: p[0], lng: p[1] });
        });
      });
    }
    return bounds;
  },
  setLegs: (data, clickable) => {
    let LegPolylineList = [];
    let polyline = {};
    if (data) {
      data.forEach((item, i) => {
        var decodePath = decode(decodeURIComponent(item.Route));
        var paths = [];
        decodePath.forEach(function (p) {
          paths.push({ lat: p[0], lng: p[1] });
        });
        polyline = {
          id: item.Seq,
          path: paths,
          info: {
            name: item.Seq,
            description: '',
            logisticsPoint: '',
            clickable: clickable,
          },
        };
        LegPolylineList.push(polyline);
      });
      return LegPolylineList;
    }
  },
  setBoundsWaypoints: (Legs) => {
    let bounds = [];
    if (Legs) {
      Legs.forEach((item, i) => {
        var decodePath = decode(decodeURIComponent(item.Route));

        decodePath.forEach(function (p) {
          bounds.push({ lat: p[0], lng: p[1] });
        });
      });
    }
    return bounds;
  },
  sortByKey: (list, key) => {
    if (list) {
      return list.sort((a, b) => {
        return a[key] - b[key];
      });
    }
  },
};

export default digitalMapUtils;
