import React, { useState, useEffect } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { mapSelectedMarker, mapMoveMarker } from 'reducers/map';

const ImMarker = (props) => {
  const { id } = props;
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (props.marker_selecting === id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props.marker_selecting, id]);

  const selectInfo = (arg) => {
    props.MARKER_SELECTING(props.id);
  };

  const onDragEnd = (e) => {
    props.MARKER_MOVE({
      ...props,
      position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    });
  };

  const closeInfo = () => {
    props.MARKER_SELECTING(-1);
    setSelected(false);
  };

  return (
    <Marker
      position={props.position}
      icon={props.icon}
      key={props.key}
      id={props.id}
      onClick={selectInfo}
      markerCilck={selectInfo}
      mapClearInfo={props.mapClearInfo}
      onDragEnd={onDragEnd}
      draggable={props.editable}
      zIndex={props.zIndex}
      onUnmount={() => {
        setSelected(false);
      }}
    >
      {selected ? (
        <InfoWindow onCloseClick={closeInfo}>
          <div>
            <b>Information: </b>
            <br />
            {props.info.logisticsPoint
              ? 'Logistics Point : ' + props.info.logisticsPoint
              : ''}
            <br />
            <b>Name: </b>
            <br />
            {props.info.name}
            <br />
            <b>Description: </b>
            <br />
            {props.info.description ? props.info.description : ''}
            <br />
            <br />
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

const mapStateToProps = (state) => {
  return {
    marker_selecting: state.map.marker_selecting,
  };
};

const mapActionsToProps = {
  MARKER_SELECTING: mapSelectedMarker,
  MARKER_MOVE: mapMoveMarker,
};

export default connect(mapStateToProps, mapActionsToProps)(ImMarker);
