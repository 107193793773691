import React, { useEffect, useState, useRef } from 'react';
import { Table as AntTable, Input, Space, Button, Badge } from 'antd';
import styled from 'styled-components';
import { Resizable } from 'react-resizable';
import ReactDragListView from 'react-drag-listview';
import './index.css';
import { useDispatch } from 'react-redux';
import { setTableState } from 'reducers/table';
import { SearchOutlined } from '@ant-design/icons';
import DeleteModal from 'common_components/DeleteModal';
import { RightOutlined } from '@ant-design/icons';
import useWindowSize from 'hooks/windowSize';
import objectByString from 'utils/objectByString';

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;
  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      handle={
        <span
          className='react-resizable-handle'
          onClick={(e) => {
            e.stopPropagation();
          }}
        />
      }
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
    >
      <th {...restProps} />
    </Resizable>
  );
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm({ closeDropdown: false });
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const Table = (props) => {
  const {
    menuActions,
    setSelectedRowKeys,
    selectedRowKeys,
    columns: propsColumns,
    rowSelection,
    tableId,
    showContextMenu,
    showPermanentDelete,
    components,
    onRow,
    showFilterDropdown,
    ...otherTableProps
  } = props;
  const [columns, setColumns] = useState(propsColumns);
  const [childPanel, setChildPanel] = useState(null);
  const dispatch = useDispatch();
  const searchInputRef = useRef();
  const deleteActionRef = useRef();
  const panelRef = useRef({});
  const [activeMenu, setActiveMenu] = useState(null);
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 0,
    y: 0,
  });
  const { height } = useWindowSize();

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  useEffect(() => {
    setColumns(propsColumns);
  }, [propsColumns]);

  const ChildPopup = ({ panelKey, menu, record, x, y }) =>
    activeMenu === panelKey ? (
      <ul
        className='popup'
        style={{
          left: `${x}px`,
          top: `${y}px`,
        }}
      >
        {menu.map((d) => (
          <li
            key={`child-${d.label}`}
            onClick={() => {
              if (d.action) {
                setTimeout(() => {
                  if (d.delete) {
                    deleteActionRef.current = d.action;
                    setShowConfirmDelete(true);
                  } else {
                    d.action(record);
                  }
                }, 100);
              }
            }}
          >
            {d.label}
          </li>
        ))}
      </ul>
    ) : (
      <div />
    );

  const Popup = ({ record, visible, x, y }) => {
    // Calculate top position to prevent popup over than total height
    const ROW_HEIGHT = 28;
    const MIN_WIDTH = 360;
    const estHeight = y + ROW_HEIGHT * (menuActions.length + 1);
    let top = y;
    if (estHeight > height) {
      let offset = estHeight - height + 60;
      top = y - offset;
    }
    let left = x;
    const { clientWidth } = window.document.documentElement;
    if (clientWidth - x < MIN_WIDTH) {
      left = clientWidth - MIN_WIDTH;
    }

    return (
      visible && (
        <ul
          className='popup'
          style={{
            left: `${left}px`,
            top: `${top}px`,
            zIndex: 1000,
          }}
        >
          {menuActions.map((d) => {
            if (d.children) {
              return (
                <div key={`root-${d.label}`}>
                  <li
                    key={d.label}
                    ref={(ref) => {
                      panelRef.current[d.label] = ref;
                    }}
                    onMouseEnter={(e) => {
                      let width = 0;
                      let childTop = 0;
                      setActiveMenu(d.label);
                      if (panelRef.current && panelRef.current[d.label]) {
                        width =
                          panelRef.current[d.label].getBoundingClientRect()
                            .width;
                        childTop =
                          panelRef.current[d.label].getBoundingClientRect().top;
                      }
                      e.preventDefault();
                      e.stopPropagation();
                      setChildPanel({
                        panelKey: d.label,
                        menu: d.children,
                        record,
                        visible,
                        x: left + width + 2,
                        y: childTop !== 0 ? childTop : top,
                      });
                    }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {d.label}
                      <RightOutlined style={{ marginLeft: 'auto' }} />
                    </div>
                  </li>
                </div>
              );
            } else {
              return (
                <li
                  key={d.label}
                  onMouseEnter={() => {
                    setActiveMenu(d.label);
                  }}
                  onClick={() => {
                    if (d.action) {
                      setTimeout(() => {
                        if (d.delete) {
                          deleteActionRef.current = d.action;
                          setShowConfirmDelete(true);
                        } else {
                          d.action(record);
                        }
                      }, 100);
                    }
                  }}
                >
                  {d.label}
                </li>
              );
            }
          })}
          {/* <li
            key={'li-close'}
            onClick={() => {
              setContextMenu({ ...contextMenu, visible: false });
            }}
          >
            Close
          </li> */}
        </ul>
      )
    );
  };

  // Save show/hide status to Redux
  useEffect(() => {
    if (propsColumns) {
      if (tableId) {
        dispatch(
          setTableState({
            [tableId]: propsColumns.map((c) => ({
              dataIndex: c.dataIndex,
              hide: c.hide,
            })),
          })
        );
      }
    }
  }, [propsColumns, tableId, dispatch]);

  const onRowWithContextMenu = (record, rowIndex) => {
    let onRowOjectes = null;
    if (onRow) {
      onRowOjectes = onRow(record, rowIndex);
    }

    const rowKey = otherTableProps.rowKey;
    return {
      ...onRowOjectes,
      onContextMenu: (event) => {
        event.preventDefault();
        if (!contextMenu.visible) {
          document.addEventListener(`click`, function onClickOutside() {
            setContextMenu({ ...contextMenu, record, visible: false });
            setChildPanel(null);
            document.removeEventListener(`click`, onClickOutside);

            // Unclick selected row keys when popup close
            if (setSelectedRowKeys) {
              setSelectedRowKeys(
                selectedRowKeys.filter((r) => r !== record[rowKey])
              );
            }
          });
        }

        setContextMenu({
          record,
          visible: true,
          x: event.clientX,
          y: event.clientY,
        });
        if (setSelectedRowKeys) {
          // Show checkbox only active row
          setSelectedRowKeys([record[rowKey]]);
        }
      },
    };
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      let offset = rowSelection ? 1 : 0;

      const newColumns = [...columns].filter((c) => !c.hide);
      if (
        newColumns[toIndex - offset] &&
        newColumns[toIndex - offset]['movable']
      ) {
        const item = newColumns.splice(fromIndex - offset, 1)[0];
        newColumns.splice(toIndex - offset, 0, item);
        setColumns(newColumns);
      }
    },
    nodeSelector: 'th',
    handleSelector: '.dragHandler',
    ignoreSelector: 'react-resizable-handle',
    lineClassName: 'table-header-drag-line',
  };

  let resizeComponents = {
    header: {
      cell: ResizableTitle,
    },
  };

  const handleResize =
    (index) =>
    (e, { size }) => {
      const nextColumns = [...columns];
      nextColumns[index] = {
        ...nextColumns[index],
        width: size.width,
      };
      setColumns(nextColumns);
    };

  const sortableColumns = columns.map((column, index) => {
    const { movable, title, sorter, dataIndex, ...otherColumnProps } = column;

    let returnColumn = {
      ...otherColumnProps,
      dataIndex,
      title: movable ? <span className='dragHandler'>{title}</span> : title,
      onHeaderCell: (column) => ({
        width: column.width || 100,
        onResize: handleResize(index),
      }),
    };

    if (sorter) {
      const { compare, field, array, ...otherSorterProps } = sorter;

      returnColumn = {
        ...returnColumn,
        sorter: {
          compare: (rowA, rowB) => {
            if (array) {
              rowA = rowA[dataIndex]
                ? rowA[dataIndex].map((d) => d[field]).join(',')
                : '';
              rowB = rowB[dataIndex]
                ? rowB[dataIndex].map((d) => d[field]).join(',')
                : '';
              return compare(rowA, rowB);
            } else {
              return field
                ? compare(
                    rowA[dataIndex] ? rowA[dataIndex][field] : '',
                    rowB[dataIndex] ? rowB[dataIndex][field] : ''
                  )
                : compare(rowA[dataIndex], rowB[dataIndex]);
            }
          },
          ...otherSorterProps,
        },
      };
    }

    return returnColumn;
  });

  let showColumns = sortableColumns.filter((c) => !c.hide);

  // Filter dropdown
  const filterDropdown = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            searchInputRef.current = node;
          }}
          placeholder={`Search...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => {
      return (
        <Badge dot={filtered ? true : false} offset={[-7, 0]}>
          <SearchOutlined
            style={{
              padding: '0 5px 0 2px',
              color: filtered ? '#1890ff' : undefined,
            }}
          />
        </Badge>
      );
    },
    onFilter: (value, record) => {
      if (record[dataIndex] !== null) {
        let dataField = record[dataIndex];
        if (Array.isArray(dataIndex)) {
          const stringKeys = dataIndex.join('.');
          dataField = objectByString(record, stringKeys);
        }
        return dataField.toString().toLowerCase().includes(value.toLowerCase());
      } else {
        return false;
      }
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInputRef.current.select(), 100);
      }
    },
  });

  // Add filter columns
  if (showFilterDropdown) {
    showColumns = showColumns.map((c) =>
      c.searchable ? { ...c, ...filterDropdown(c.dataIndex) } : c
    );
  }

  return (
    <>
      <ReactDragListView.DragColumn {...dragProps}>
        <StyledTable
          columns={showColumns}
          components={{ ...components, ...resizeComponents }}
          rowSelection={rowSelection}
          onRow={onRowWithContextMenu}
          {...otherTableProps}
          rowClassName={(record, index) =>
            index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
          }
        />
        {showContextMenu && <Popup {...contextMenu} />}
        {showContextMenu && <ChildPopup {...childPanel} />}
      </ReactDragListView.DragColumn>
      {showContextMenu && (
        <DeleteModal
          visible={showConfirmDelete}
          onCancel={() => {
            setShowConfirmDelete(false);
          }}
          onDelete={(isPermanentDelete) => {
            if (deleteActionRef.current) {
              deleteActionRef.current(contextMenu.record, isPermanentDelete);
            }
            setShowConfirmDelete(false);
          }}
          showPermanentDelete={showPermanentDelete}
        />
      )}
    </>
  );
};

const StyledTable = styled(AntTable)`
  &&& {
    /* Prevent scroll-x display when adding resize column*/
    .ant-table > .ant-table-container > .ant-table-content > table {
      min-width: 99% !important;
    }
    tr {
      th {
        padding: 4px;
        background-color: #f0f0f0;
        font-size: 12px;
      }
    }
    tr {
      td {
        padding: 4px;
        font-size: 12px;
      }
    }
    table {
      tr:hover {
        td {
          background-color: #ebf5fb;
        }
      }
    }
    .table-row-light {
      background-color: #ffffff;
    }
    .table-row-dark {
      background-color: #fbfbfb;
    }
    // table {
    //   tr:nth-child(n) {
    //     td {
    //       // background-color: transparent;
    //     }
    //   }
    //   tr:nth-child(2n) {
    //     td {
    //       background-color: #fafafa;
    //     }
    //   }
    // }
  }
`;

export default Table;
