import React, { useState, useEffect, useCallback, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { reasonMasterApi } from 'reducers/masterData/reasonMaster/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import { message, Typography } from 'antd';
import LinkRenderer from 'common_components/LinkRenderer';
import TableToolbar from './Toolbar';
import useWindowSize from 'hooks/windowSize';
import { setReasonDataSource } from 'reducers/masterData/reasonMaster';

import { CheckCircleTwoTone } from '@ant-design/icons';
const { Link } = Typography;

const ReasonMasterTableComponent = (props) => {
  // Redux Store
  const storedDataSource = useSelector((state) => {
    return state.reasonMaster.dataSource;
  });
  const dispatch = useDispatch();
  const payloadRef = useRef(null);
  const { rowDataDisplay } = props;
  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'reason_master')
  );

  const [dataSource, setDataSource] = useState(storedDataSource, []);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [tableHeight, setTableHeight] = useState(0);
  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Group Code',
        dataIndex: ['subject_reasons', 'group'],
        movable: true,
        width: 100,
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record.subject_reason}
              field='group'
              name='code'
              path='/app/master/group_reasons'
            />
          );
        },
        searchable: true,
      },
      {
        title: 'Group Name',
        dataIndex: ['subject_reason', 'group'],
        width: 200,
        movable: true,
        render: (text, record) => {
          return record.subject_reason.group.name;
        },
        searchable: true,
      },
      {
        title: 'Code',
        dataIndex: ['subject_reason, code'],
        movable: true,
        width: 100,
        render: (text, record) => {
          const id = record.subject_reason.id;
          return (
            <Link
              onClick={() => {
                if (id) {
                  dispatch(push('/app/master/subject_reasons/' + id));
                }
              }}
            >
              {record.subject_reason.code}
            </Link>
          );
        },
        searchable: true,
      },
      {
        title: 'Name (EN)',
        dataIndex: ['subject_reason', 'name_en'],
        width: 200,
        movable: true,
        searchable: true,
      },
      {
        title: 'Name (TH)',
        dataIndex: ['subject_reason', 'name_th'],
        width: 200,
        movable: true,
        searchable: true,
      },
      {
        title: 'Receive Job',
        dataIndex: 'moduleActive',
        movable: true,
        width: 100,
        searchable: true,
        render: (text, record) => {
          return text.some(
            (e) => e.module_name === 'Receive Job' && e.is_active === true
          ) ? (
            <CheckCircleTwoTone
              style={{ fontSize: 20 }}
              twoToneColor='#52c41a'
            />
          ) : (
            '-'
          );
        },
        align: 'center',
      },
      {
        title: 'Yard Out',
        dataIndex: 'moduleActive',
        movable: true,
        width: 100,
        searchable: true,
        render: (text, record) => {
          return text.some(
            (e) => e.module_name === 'Yard Out' && e.is_active === true
          ) ? (
            <CheckCircleTwoTone
              style={{ fontSize: 20 }}
              twoToneColor='#52c41a'
            />
          ) : (
            '-'
          );
        },
        align: 'center',
      },
      {
        title: 'Arrival',
        dataIndex: 'moduleActive',
        movable: true,
        width: 100,
        searchable: true,
        render: (text, record) => {
          return text.some(
            (e) => e.module_name === 'Arrival' && e.is_active === true
          ) ? (
            <CheckCircleTwoTone
              style={{ fontSize: 20 }}
              twoToneColor='#52c41a'
            />
          ) : (
            '-'
          );
        },
        align: 'center',
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }

    return cols;
  });

  const { setErrorData } = useErrorHandler();
  const { height } = useWindowSize();

  useEffect(() => {
    dispatch(setReasonDataSource(dataSource));
  }, [dataSource, dispatch]);

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (!showFilter) {
        h = h + 170;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  const onInit = useCallback(
    (payload) => {
      let queryPayload = { ...payload };
      payloadRef.current = queryPayload;
      setDataSource([]);
      executeQuery(queryPayload, true);
    },
    // eslint-disable-next-line no-use-before-define
    [executeQuery]
  );

  const onQuery = async (payload) => {
    if (!isLoading) {
      payloadRef.current = { ...payload };
      await executeQuery({ ...payloadRef.current }, true);
    }
  };

  const executeQuery = useCallback(
    async (payload, forceRefetch) => {
      let newData = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          reasonMasterApi.endpoints.findAllOrderReasonMasters.initiate(
            payload,
            {
              forceRefetch: forceRefetch ? true : false,
            }
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            const data = result.data;
            newData = Object.values(
              data.reduce((r, o) => {
                if (rowDataDisplay[o.subject_reason.code]) {
                  r[o.subject_reason.code] = {
                    ...o,
                    moduleActive: rowDataDisplay[o.subject_reason.code],
                  };
                }
                return r;
              }, {})
            );
            setDataSource(newData);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData };
      }
    },
    [dispatch, rowDataDisplay, setErrorData]
  );

  const onAutoRefetch = async (payload) => {
    payloadRef.current = { ...payload };
    await executeQuery({ ...payloadRef.current }, true);
  };
  return (
    <Container>
      <TableToolbar
        onQuery={onQuery}
        onInit={onInit}
        onAutoRefetch={onAutoRefetch}
        isLoading={isLoading}
        columns={columns}
        setColumns={setColumns}
        showFilter={true}
        setShowFilter={setShowFilter}
      />
      <AntTable
        rowKey='id'
        tableId={'reason_master'}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        scroll={{
          y: tableHeight,
        }}
        pagination={false}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default ReasonMasterTableComponent;
