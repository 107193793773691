import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';
import { Button, Tooltip } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import { getLanguage } from 'reducers/language';
import { useSelector } from 'react-redux';
const TableComponent = (props) => {
  const { rowData } = props;
  const { height } = useWindowSize();
  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const language = useSelector(getLanguage);
  const columns = [
    {
      title: 'Estimate yard out',
      dataIndex: 'plan_yard_out',
      align: 'center',
      width: 120,
      render: (text, record) => {
        return dateTimeTimestamptzFormatter({
          value: text,
        });
      },
    },
    {
      title: 'Actual Yard Out',
      dataIndex: 'actual_yard_out_datetime',
      width: 120,
      align: 'center',
      render: (text, record) => {
        return dateTimeTimestamptzFormatter({ value: text });
      },
    },
    {
      title: 'Different (HH:MM)',
      dataIndex: 'diff',
      width: 100,
      align: 'center',
    },
    {
      title: 'Reason',
      dataIndex: ['reason', 'label'],
      width: 100,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        return (
          <>
            {text === 'delay' ? (
              <Button
                size='small'
                style={{
                  backgroundColor: '#ffccc7',
                  width: '60px',
                  top: 1,
                }}
                danger
                onClick={() => showModal(record)}
              >
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Button>
            ) : text === '-' ? (
              text
            ) : (
              <Tooltip
                placement='bottom'
                title={
                  language === 'th'
                    ? record.reason && record.reason.value.description
                      ? record.reason && record.reason.value.description
                      : record.reason &&
                        record.reason.value.subject.substring(5)
                    : record.reason && record.reason.value.description
                    ? record.reason && record.reason.value.description
                    : record.reason && record.reason.value.subject.substring(5)
                }
              >
                <Button
                  shape='round'
                  size='small'
                  style={{
                    fontSize: 12,
                    whiteSpace: 'normal',
                    height: 'auto',
                    margin: '5px',
                  }}
                  onClick={() => showModal(record)}
                >
                  {text}
                </Button>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  const showModal = (value) => {
    props.editReason(value);
  };

  return (
    <Container>
      <AntTable
        bordered
        rowKey='id'
        tableId={'yard_out'}
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
