import Papa from 'papaparse';

export class CSV {
  read(file, onChange = () => {}, { index = 0, name } = {}) {
    const reader = new FileReader();
    reader.onload = async ({ target }) => {
      const csv = Papa.parse(target.result, {
        header: true,
        skipEmptyLines: true,
        complete: function (results) {},
      });
      onChange(csv);
    };
    reader.onerror = (e) => {
      onChange({ error: e.target.error.name });
    };
    reader.readAsText(file);
  }
}

export const csv = new CSV();
export default csv;
