import * as d3 from 'd3';

function pinPathCubicBezier(width, height) {
  const deltaX = width * Math.sqrt(3);
  const deltaY = (height * 4) / 3;
  return `M 0,0 C ${-deltaX},${-deltaY}
      ${deltaX},${-deltaY} 0,0 z`;
}
export const createMarkerNumber = (
  width,
  height,
  color,
  text,
  isAlt = false
) => {
  let canvas, ctx;

  canvas = document.createElement('canvas');
  canvas.width = width * 1;
  canvas.height = height * 1;

  const x = width / 2;
  const y = height / 2;

  ctx = canvas.getContext('2d');

  ctx.clearRect(0, 0, width, height);

  // Fill background for debugging
  // ctx.fillStyle = 'rgba(255, 255, 155, 0.6)';
  // ctx.fillRect(0, 0, width , height );

  // Move origin from top one pixel to avoid clipping
  ctx.translate(0, 1);

  ctx.beginPath();
  // Top arc
  ctx.arc(x, height / 3, (width - 2) / 2, Math.PI, 0, false); // (width -2 ) add -2 to avoid left/right clipping

  // Right bend
  ctx.bezierCurveTo(
    width - 1,
    height / 3 + height / 4,
    x + width / 3,
    y,
    x,
    height - 1
  );

  // Left bend
  ctx.moveTo(1, height / 3);
  ctx.bezierCurveTo(
    1,
    height / 3 + height / 4,
    x - width / 3,
    y,
    x,
    height - 1
  );

  ctx.fillStyle = !isAlt ? 'white' : 'black';
  ctx.fill();
  ctx.strokeStyle = color;
  ctx.lineWidth = 2;
  ctx.stroke();

  let offset = text.length * 3;
  if (text.length <= 2) {
    ctx.font = 'bold 12px Arial';
    ctx.translate(width / 2 - offset, height / 2);
  } else if (text.length === 3) {
    ctx.font = 'bold 11px Arial';
    ctx.translate(width / 2 - offset, height / 2);
  } else {
    ctx.translate(width / 2 - offset + 2, height / 2);
    ctx.font = 'bold 9px Arial';
  }
  ctx.fillStyle = !isAlt ? 'black' : 'white';
  ctx.fillText(text, 0, 0);

  return canvas.toDataURL();
};
export const markerPinNumberSvgIcon = (
  text,
  width,
  height,
  background,
  border
) => {
  const path = pinPathCubicBezier(width, height);
  let radius = Math.min(width, height) / 2;

  let svg = d3.create('svg');
  svg
    .attr('width', width)
    .attr('height', height)
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + height + ')')
    .append('path')
    .attr('d', path)
    .style('stroke', border)
    .style('fill', background);

  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('circle')
    .attr('cx', 0)
    .attr('cy', 0)
    .attr('r', radius - 4)
    .attr('fill', '#ffffff');

  svg
    .append('g')
    .attr('transform', 'translate(' + radius + ',' + radius + ')')
    .append('text')
    .attr('dy', `3px`)
    .style('text-anchor', 'middle')
    .attr('class', 'inner-circle')
    .attr('font-size', '12px')
    .attr('font-family', 'sans-serif')
    .attr('font-weight', 'bold')
    .attr('fill', 'black')
    .text(() => text);

  let svgString = new XMLSerializer().serializeToString(svg.node());

  let svgUrl = `data:image/svg+xml,` + encodeURIComponent(svgString);

  return svgUrl;
};
