import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Layout,
  Col,
  Row,
  Menu,
  Card,
  Typography,
  Dropdown,
  Image,
  Modal,
  message,
} from 'antd';
import { UserOutlined, LockOutlined, DownOutlined } from '@ant-design/icons';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearState, userSelector } from '../reducers/user';
import { loginUser } from '../reducers/user/api';
import { showError } from '../utils/notistack';
import { setLanguage } from '../reducers/language';
import { ReactComponent as ThaiSvg } from '../assets/flags/TH.svg';
import { ReactComponent as UsSvg } from '../assets/flags/USA.svg';
import AppLogoImage from '../assets/images/TTT_logo.png';
import PreviewImage from '../assets/images/TTT_login_preview.jpg';
import ForgotPasswordForm from './ForgotPassword';
import Configs from 'config/config';
import QRCode from 'qrcode.react';

const { Content } = Layout;

const LoginForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const { isLoading, isLoginSuccess, error } = useSelector(userSelector);
  const [forgotVisible, setForgotVisible] = useState(false);
  const [showMfaModal, setShowMfaModal] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [isMfaVerified, setIsMfaVerified] = useState(false);
  const [isMfaRegistering, setIsMfaRegistering] = useState(false);
  const [mfaToken, setMfaToken] = useState(['', '', '', '', '', '']);
  const inputRefs = useRef([]);
  const [isMfaTokenComplete, setIsMfaTokenComplete] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSuccessfulLogin = () => {
    dispatch(clearState());
    dispatch(setLanguage(i18n.language));
    setTimeout(() => {
      dispatch(push('/'));
    }, 100);
  };

  useEffect(() => {
    if (error) {
      showError(error);
      dispatch(clearState());
    }

    if (isLoginSuccess && isMfaVerified) {
      handleSuccessfulLogin();
    }
  }, [error, isLoginSuccess, isMfaVerified, dispatch, i18n]);

  useEffect(() => {
    if (!showMfaModal) {
      setMfaToken(['', '', '', '', '', '']);
    }
  }, [showMfaModal]);

  const loginSubmit = async (values) => {
    try {
      const actionResult = await dispatch(loginUser(values));

      if (loginUser.fulfilled.match(actionResult)) {
        const result = actionResult.payload;
        setLoginData(result);

        if (result.is_mfa === false && result.qrcode) {
          setIsMfaRegistering(true);
          setShowMfaModal(true);
        } else if (result.is_mfa === true) {
          setShowMfaModal(true);
        }

        if (
          !(result.is_mfa === false && result.qrcode) &&
          !(result.is_mfa === true)
        ) {
          handleSuccessfulLogin();
        }
      }
    } catch (err) {
      showError(err);
    } finally {
      dispatch(clearState());
    }
  };

  const handleMFAInputChange = (index, e) => {
    const newValue = e.target.value;
    if (/^\d*$/.test(newValue) && newValue.length <= 1) {
      const newMfaToken = [...mfaToken];
      newMfaToken[index] = newValue;
      setMfaToken(newMfaToken);

      const isComplete = newMfaToken.every((digit) => digit !== '');
      setIsMfaTokenComplete(isComplete);

      if (newValue !== '' && index < newMfaToken.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleMFAInputKeyDown = (index, e) => {
    if (e.key === 'Backspace' && mfaToken[index] === '' && index > 0) {
      const newMfaToken = [...mfaToken];
      newMfaToken[index - 1] = '';
      setMfaToken(newMfaToken);
      inputRefs.current[index - 1].focus();
    }
  };

  const handleMFAVerify = async () => {
    try {
      setIsSubmitting(true);
      const mfaResponse = await fetch(
        `${Configs[process.env.NODE_ENV].BACKEND_MFA_HOST}/mfa/verify/`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${loginData.token}`,
            'X-Session-Token': `Bearer ${loginData.session}`,
          },
          body: JSON.stringify({
            otp_code: mfaToken.join(''),
          }),
        },
      );

      if (mfaResponse.ok) {
        const mfaRes = await mfaResponse.json();
        localStorage.setItem('token', mfaRes.token);
        localStorage.setItem('session', mfaRes.session);
        setShowMfaModal(false);
        setIsMfaVerified(true);
        handleSuccessfulLogin();
      } else {
        message.error(t('mfa.invalid-token'));
      }
    } catch (err) {
      showError(err);
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, 500);
    }
  };

  const handleMFARegister = async () => {
    try {
      const mfaRegisterResponse = await fetch(
        `${Configs[process.env.NODE_ENV].BACKEND_MFA_HOST}/mfa/enable/`,
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${loginData.token}`,
            'X-Session-Token': `Bearer ${loginData.session}`,
          },
          body: JSON.stringify({
            otp_code: mfaToken.join(''),
            password: form.getFieldValue('password'),
          }),
        },
      );

      if (mfaRegisterResponse.ok) {
        const mfaReRes = await mfaRegisterResponse.json();
        localStorage.setItem('token', mfaReRes.token);
        localStorage.setItem('session', mfaReRes.session);
        setIsMfaRegistering(false);
        message.success(t('mfa.registration-success'));
        setShowMfaModal(false);
        handleSuccessfulLogin();
      } else {
        const errorData = await mfaRegisterResponse.json();
        console.log(errorData);
        message.error(t('mfa.registration-failed'));
      }
    } catch (err) {
      showError(err);
    }
  };

  const handleLanguageMenuClick = (event) => {
    const newLang = event.key === 'th' ? 'th' : 'en';
    dispatch(setLanguage(newLang));
    i18n.changeLanguage(newLang);
  };

  const languageMenu = (
    <Menu onClick={handleLanguageMenuClick}>
      <Menu.Item key='th' icon={<ThaiSvg />}>
        {t('language.thai')}
      </Menu.Item>
      <Menu.Item key='en' icon={<UsSvg />}>
        {t('language.english')}
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Content style={{ overflow: 'hidden' }}>
        <Row
          gutter={24}
          justify='center'
          align='middle'
          style={{ minHeight: '100vh', padding: '20px' }}
        >
          <Col xs={{ span: 0 }} md={{ span: 12 }}>
            <Image preview={false} src={PreviewImage} />
          </Col>
          <Col xs={{ span: 20 }} md={{ offset: 1, span: 9 }}>
            <Card style={{ borderRadius: 15 }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Image
                  preview={false}
                  width={100}
                  height={100}
                  src={AppLogoImage}
                />
              </div>
              <Typography.Title
                level={4}
                style={{ textAlign: 'center', color: '#282E7C' }}
              >
                TRANSPORT NETWORK OPERATING SYSTEM
              </Typography.Title>
              <Form
                form={form}
                layout='vertical'
                name='login'
                onFinish={loginSubmit}
              >
                <Form.Item
                  label={t('login.username')}
                  name='username'
                  rules={[
                    {
                      required: !forgotVisible,
                      // type: 'username',
                      message: t('login.username-input-warning'),
                    },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined />}
                    placeholder={t('login.username')}
                    type='username'
                  />
                </Form.Item>
                <Form.Item
                  label={t('login.password')}
                  name='password'
                  rules={[
                    {
                      required: !forgotVisible,
                      message: t('login.password-input-warning'),
                    },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className='site-form-item-icon' />}
                    type='password'
                    placeholder={t('login.password')}
                  />
                </Form.Item>
                <Form.Item style={{ marginTop: '10px' }}>
                  <Button
                    loading={isLoading}
                    style={{ borderRadius: 5 }}
                    type='primary'
                    htmlType='submit'
                    block
                  >
                    {t('login.submit')}
                  </Button>
                </Form.Item>
                {/* <Form.Item style={{ textAlign: 'center', marginBottom: '0px' }}>
                    <Typography.Link onClick={onForgotPassword}>
                      {t('login.forgot-password')}
                    </Typography.Link>
                  </Form.Item> */}
              </Form>
              <div style={{ display: 'flex', justifyContent: 'end' }}>
                <Dropdown overlay={languageMenu} trigger={['click']}>
                  <Button
                    onClick={(e) => e.preventDefault()}
                    style={{
                      marginLeft: 'auto',
                      backgroundColor: 'transparent',
                      border: 'none',
                    }}
                  >
                    {i18n.language === 'th' ? <ThaiSvg /> : <UsSvg />}
                    <DownOutlined style={{ marginLeft: '4px' }} />
                  </Button>
                </Dropdown>
              </div>
            </Card>
          </Col>
        </Row>
      </Content>
      <ForgotPasswordForm
        isLoading={isLoading}
        onClose={() => {
          setForgotVisible(false);
        }}
        isVisible={forgotVisible}
        setVisible={setForgotVisible}
      />
      <Modal
        visible={showMfaModal}
        title={isMfaRegistering ? t('mfa.setup-title') : t('mfa.verify-title')}
        footer={null}
        onCancel={() => setShowMfaModal(false)}
      >
        {isMfaRegistering && (
          <div style={{ textAlign: 'center', marginBottom: 16 }}>
            <QRCode value={loginData?.qrcode} size={200} />
          </div>
        )}
        <Form onFinish={isMfaRegistering ? handleMFARegister : handleMFAVerify}>
          <Typography.Paragraph>
            {isMfaRegistering
              ? t('mfa.setup-instructions')
              : t('mfa.verify-instruction')}
          </Typography.Paragraph>
          <Form.Item>
            <Input.Group compact>
              {mfaToken.map((digit, index) => (
                <Input
                  key={index}
                  ref={(el) => (inputRefs.current[index] = el)}
                  value={digit}
                  onChange={(e) => handleMFAInputChange(index, e)}
                  onKeyDown={(e) => handleMFAInputKeyDown(index, e)}
                  style={{
                    width: '16.66%',
                    textAlign: 'center',
                    fontSize: '24px',
                    height: '50px',
                  }}
                  maxLength={1}
                />
              ))}
            </Input.Group>
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              block
              disabled={!isMfaTokenComplete}
              loading={isSubmitting}
            >
              {isMfaRegistering
                ? t('mfa.setup-submit')
                : t('mfa.verify-submit')}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Layout>
  );
};

export default LoginForm;
