import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage, getLanguage } from '../reducers/language';

const LanguageLayout = ({ children }) => {
  const dispatch = useDispatch();

  const language = useSelector(getLanguage);

  const { i18n } = useTranslation();

  useEffect(() => {
    if (language === 'th') {
      dispatch(setLanguage('th'));
      i18n.changeLanguage('th');
    } else {
      dispatch(setLanguage('en'));
      i18n.changeLanguage('en');
    }
  }, [i18n, dispatch, language]);

  return <>{children}</>;
};

export default LanguageLayout;
