import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';
import { dateFormatterYear } from 'common_components/AntTable/tableUtils';

const RecommendTripSubTableComponent = (props) => {
  const { dataSource, isLoadingSearch, selectedRowKeys, setSelectedRowKeys } =
    props;
  const [columns] = useState(() => {
    let cols = [
      {
        title: 'Receive Job Time',
        dataIndex: 'receive_job_datetime',
        align: 'center',
        width: 90,
        render(text, record) {
          return {
            props: {
              style: {
                background:
                  text && record.higlight_receive_job_time === 'yellow'
                    ? '#FFFF00'
                    : text && record.higlight_receive_job_time === 'red'
                    ? '#FFA39E'
                    : '',
              },
            },
            children: (
              <>{text ? dateFormatterYear({ value: text }) : ''}</>
            ),
          };
        },
        movable: true,
      },
      {
        title: 'From',
        dataIndex: ['actual_from', 'short_name'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Cluster',
        dataIndex: ['actual_cluster_to', 'name'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Region',
        dataIndex: ['actual_cluster_to', 'region', 'name'],
        align: 'center',
        width: 50,
        movable: true,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        align: 'center',
        width: 150,
        movable: true,
      },
      {
        title: 'Trailer type',
        dataIndex: ['trailer_type', 'name'],
        align: 'center',
        width: 80,
        movable: true,
      },
      {
        title: 'Combine',
        dataIndex: 'combine',
        align: 'center',
        width: 80,
        movable: true,
      },
      {
        title: 'Trailer usage',
        dataIndex: 'trailer_usage',
        width: 100,
        align: 'center',
        movable: true,
        render(text, record) {
          return text ? text + ' Day' : '';
        },
      },
      {
        title: 'Diagram type',
        dataIndex: ['diagram_type', 'name_en'],
        width: 100,
        align: 'center',
        movable: true,
      },
      {
        title: 'Trip no',
        dataIndex: 'trip_no',
        width: 100,
        align: 'center',
        movable: true,
      },
    ];

    return cols;
  });
  useEffect(() => {
    if (dataSource.length > 0) {
      setSelectedRowKeys([dataSource[0].id]);
    }
  }, [dataSource, setSelectedRowKeys]);
  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <AntTable
        rowKey='id'
        tableId={'recommendTrip'}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource || []}
        scroll={{ x: 'max-content' }}
        loading={isLoadingSearch}
        // Below is for right click context menu
        rowSelection={
          dataSource.length > 0
            ? {
                type: 'radio',
                ...rowSelection,
              }
            : null
        }
      />
    </div>
  );
};

export default RecommendTripSubTableComponent;
