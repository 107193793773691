import React, { useState, useEffect } from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { PageHeader } from 'antd';
import styled from 'styled-components';
import { setSidebarVisibility } from 'reducers/ui';

import TableMain from './TableMain';
import { setIsNeedRefresh } from 'reducers/callingsheet';

const RecommendTripListComponent = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => {
          dispatch(setIsNeedRefresh(true));
          dispatch(goBack());
        }}
        title='Recommend Trip'
        subTitle=''
      />
      <Content>
        <TableMain />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default RecommendTripListComponent;
