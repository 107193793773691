import { notification } from 'antd';

export const showError = (error, isPersist = false) => {
  if (!error) return;
  let errorMsg = 'Something went wrong';
  if (typeof error === 'string') {
    if (error.errorDetail) {
      errorMsg = error.errorDetail;
    } else {
      errorMsg = error;
    }
  } else {
    if (error && error.data) {
      if (error.data.errorList && error.data.errorList.length > 0) {
        errorMsg = error.data.errorList
          .map(
            (e) =>
              `${e.errorMessage}\n${
                e.errorDetail && 'Details: ' + e.errorDetail
              }`
          )
          .join('\n');
      }
    }

    if (error && error.errorList) {
      if (error.errorList.length > 0) {
        errorMsg = error.errorList
          .map(
            (e) =>
              `${e.errorMessage}\n${
                e.errorDetail && 'Details: ' + e.errorDetail
              }`
          )
          .join('\n');
      }
    }
  }
  notification['error']({
    message: 'Error',
    description: errorMsg,
    duration: isPersist ? 0 : 3,
    style: {
      backgroundColor: '#fff2f0',
      border: '1px solid #ffccc7',
    },
  });
};
