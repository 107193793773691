import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Select,
  DatePicker,
  message,
  Row,
  Col,
} from 'antd';
import {
  useUpdateCallingSheetMutation,
  useCreateCallingSheetMutation,
} from 'reducers/callingsheet/api';
import { useFindAllYardsQuery } from 'reducers/masterData/yard/api';
import { useFindAllClustersQuery } from 'reducers/masterData/cluster/api';
import { useFindAllRegionsQuery } from 'reducers/masterData/region/api';
import { useFindAllBzpsQuery } from 'reducers/masterData/bzp/api';
import moment from 'moment';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { goBack } from 'connected-react-router';
import VinTable from './Vin';
const { Option } = Select;

const CallingSheetFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  const [yards, setYards] = useState(
    formData && formData.yard
      ? [{ id: formData.yard.id, label: formData.yard.code }]
      : []
  );
  const [clusters, setClusters] = useState(
    formData && formData.cluster
      ? [{ id: formData.cluster.id, label: formData.cluster.code }]
      : []
  );
  const [regions, setRegions] = useState(
    formData && formData.region
      ? [{ id: formData.region.id, label: formData.region.name }]
      : []
  );
  const [bzps, setBzps] = useState(
    formData && formData.bzp
      ? [{ id: formData.bzp.id, label: formData.bzp.name }]
      : []
  );
  const [
    createCallingSheet,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateCallingSheetMutation();

  const [
    updateCallingSheet,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateCallingSheetMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  // Query all yards
  const {
    data: dataYards,
    error: errorYards,
    isFetching: isYardsFetching,
    refetch: refetchYards,
  } = useFindAllYardsQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataYards) {
      setYards(dataYards.map((d) => ({ id: d.id, label: d.code })));
    }
  }, [dataYards]);

  useEffect(() => {
    if (errorYards) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load yards',
        okText: 'Retry',
        onOk: refetchYards,
        cancelText: 'Cancel',
      });
    }
  }, [errorYards, refetchYards]);

  // Query all regions
  const {
    data: dataRegions,
    error: errorRegions,
    isFetching: isRegionsFetching,
    refetch: refetchRegions,
  } = useFindAllRegionsQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataRegions) {
      setRegions(dataRegions.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataRegions]);

  useEffect(() => {
    if (errorRegions) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load regions',
        okText: 'Retry',
        onOk: refetchRegions,
        cancelText: 'Cancel',
      });
    }
  }, [errorRegions, refetchRegions]);

  // Query all bzps
  const {
    data: dataBzps,
    error: errorBzps,
    refetch: refetchBzps,
  } = useFindAllBzpsQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataBzps) {
      setBzps(dataBzps.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataBzps]);

  useEffect(() => {
    if (errorBzps) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load bzps',
        okText: 'Retry',
        onOk: refetchBzps,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzps, refetchBzps]);

  // Query all clusters
  const {
    data: dataClusters,
    error: errorClusters,
    isFetching: isClustersFetching,
    refetch: refetchClusters,
  } = useFindAllClustersQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataClusters) {
      setClusters(dataClusters.map((d) => ({ id: d.id, label: d.code })));
    }
  }, [dataClusters]);

  useEffect(() => {
    if (errorClusters) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load clusters',
        okText: 'Retry',
        onOk: refetchClusters,
        cancelText: 'Cancel',
      });
    }
  }, [errorClusters, refetchClusters]);

  const onFinish = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let postYard = yards.find((c) => c.label === validData.yard);
    let postRegion = regions.find((c) => c.label === validData.region);
    let postCluster = clusters.find((c) => c.label === validData.cluster);
    let postBzp = bzps.find((c) => c.label === validData.bzp);
    let postMakingDate = validData.making_date.toISOString();
    let postReceivedDate = validData.received_date.toISOString();

    let postData = {
      id: formData && formData.id,
      ...validData,
      yard: postYard && postYard.id,
      region: postRegion && postRegion.id,
      cluster: postCluster && postCluster.id,
      bzp: postBzp && postBzp.id,
      making_date: postMakingDate,
      received_date: postReceivedDate,
    };
    // delete postData['trailer'];
    if (isCreate) {
      createCallingSheet(postData);
    } else {
      updateCallingSheet(postData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 14,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Row>
        <Col span={20}>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Calling No'
                name='callingsheet_no'
                initialValue={formData && formData.callingsheet_no}
                rules={[
                  {
                    required: true,
                    message: 'Please input calling sheet no',
                  },
                ]}
              >
                <Input disabled={readonly} placeholder={'Calling Sheet No'} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Region'
                name='region'
                initialValue={
                  formData && formData.region && formData.region.name
                }
                rules={[
                  {
                    required: user && user.is_superadmin ? false : true,
                    message: 'Please select region',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Select a region'
                  optionFilterProp='children'
                  loading={isRegionsFetching}
                  disabled={!user || !user.is_superadmin}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {regions &&
                    regions.map((c, index) => (
                      <Option key={index} value={c.label}>
                        {c.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Yard'
                name='yard'
                initialValue={formData && formData.yard && formData.yard.code}
                rules={[
                  {
                    required: user && user.is_superadmin ? false : true,
                    message: 'Please select yard',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Select a yard'
                  optionFilterProp='children'
                  loading={isYardsFetching}
                  disabled={!user || !user.is_superadmin}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {yards &&
                    yards.map((c, index) => (
                      <Option key={index} value={c.label}>
                        {c.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Cluster'
                name='cluster'
                initialValue={
                  formData && formData.cluster && formData.cluster.code
                }
                rules={[
                  {
                    required: user && user.is_superadmin ? false : true,
                    message: 'Please select cluster',
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Select a cluster'
                  optionFilterProp='children'
                  loading={isClustersFetching}
                  disabled={!user || !user.is_superadmin}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {clusters &&
                    clusters.map((c, index) => (
                      <Option key={index} value={c.label}>
                        {c.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col offset={12} span={12}>
              <Form.Item
                label='Calling Date'
                name='calling_date'
                initialValue={formData && moment(formData.calling_date)}
              >
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <Button>Print</Button>
        </Col>
      </Row>
      <Button
        style={{ marginBottom: '10px' }}
        type='primary'
        htmlType='submit'
        loading={isCreating || isUpdating}
        disabled={readonly}
      >
        Add Vin
      </Button>

      <VinTable />

      <Button
        style={{ display: 'block', marginLeft: 'auto', marginTop: '10px' }}
        type='primary'
        htmlType='submit'
        loading={isCreating || isUpdating}
        disabled={readonly}
      >
        Submit
      </Button>
    </Form>
  );
};
export default CallingSheetFormComponent;
