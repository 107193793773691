import UserList from 'dms_components/User/List';
import UserDetails from 'dms_components/User/Details';
import CompanyList from 'dms_components/Company/List';
import CompanyDetails from 'dms_components/Company/Details';
import GroupList from 'dms_components/Group/List';
import GroupDetails from 'dms_components/Group/Details';
const userMngRoutes = [
  {
    path: '/app/user_mng/users',
    name: 'Users',
    Component: UserList,
  },
  {
    path: '/app/user_mng/users/create',
    name: 'Create User',
    Component: UserDetails,
  },
  {
    path: '/app/user_mng/users/:id',
    name: 'User Detail',
    Component: UserDetails,
  },
  {
    path: '/app/user_mng/groups',
    name: 'Groups',
    Component: GroupList,
  },
  {
    path: '/app/user_mng/groups/create',
    name: 'Create Group',
    Component: GroupDetails,
  },
  {
    path: '/app/user_mng/groups/:id',
    name: 'Group Details',
    Component: GroupDetails,
  },

  {
    path: '/app/user_mng/companies',
    name: 'Companies',
    Component: CompanyList,
  },
  {
    path: '/app/user_mng/companies/create',
    name: 'Create Company',
    Component: CompanyDetails,
  },
  {
    path: '/app/user_mng/companies/:id',
    name: 'Company Detail',
    Component: CompanyDetails,
  },
];

export default userMngRoutes;
