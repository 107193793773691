import React from 'react';
import LocationMaster from '../../../digitalmap_components/Map/LocationMaster';
import DrawingMap from '../../../digitalmap_components/Map/DrawingMap';
import BaseMap from 'digitalmap_components/Map/BaseMap';
import DrawingDeleteBox from 'digitalmap_components/Map/RouteMasterEditWP/DrawingDeleteBox';

const LocationMasterMap = (props) => {
  return (
    <BaseMap {...props}>
      <LocationMaster />
      {props.showDrawing && <DrawingMap />}
      {props.showDrawing && <DrawingDeleteBox />}
    </BaseMap>
  );
};

export default LocationMasterMap;
