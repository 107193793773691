import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, message, Modal } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import TableMain from './TableMain';
import {
  useFindCreateLotSummaryAllUnitQuery,
  useFindCreateLotMutation,
  useFindCreateLotBySubClusterMutation,
  useFindCreateLotClusterByRegionMutation,
  createLotApi,
} from 'reducers/createLot/api';
const CreateLotIndexComponent = () => {
  const dispatch = useDispatch();

  const { setErrorData } = useErrorHandler();

  const filterSelectFromRef = useRef();
  const countUnitRef = useRef();
  const planIdRef = useRef();
  const statusRef = useRef();
  const bgSelectRef = useRef();
  const [disabledBtnConfirm, setDisabledBtnConfirm] = useState(false);
  const [statusSummary, setStatusSummary] = useState(null);
  const [countUnit, setCountUnit] = useState(null);
  const [filterDateFrom, setFilterDateFrom] = useState();
  const [popupConfirm, setPopupConfirm] = useState();
  const [isDataCreateLotSummary, setIsDataCreateLotSummary] = useState([]);
  const [filterDateTo, setFilterDateTo] = useState();
  const [filterSelectFrom, setFilterSelectFrom] = useState([]);
  const [filterOptionFrom, setFilterOptionFrom] = useState();
  const [statusCreateLot, setStatusCreateLot] = useState(false);
  const [enableSearch, setEnableSearch] = useState(true);
  const [dataClusterByRegionTable, setDataClusterByRegionTable] = useState([]);
  const [dataSubClusterTable, setDataSubClusterTable] = useState([]);
  const [dataEditUnit, setDataEditUnit] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [dataSourceTableMain, setDataSourceTableMain] = useState([]);
  const dataSourceSubClusterTableRef = useRef([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRegion, setIsLoadingRegion] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState();
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [isLoadingSaveManualLot, setIsLoadingSaveManualLot] = useState(false);

  const onSearchTable = async (action) => {
    let payload = {
      plan_id: planIdRef.current,
      from_id: filterSelectFrom,
    };
    console.log('payload', payload);
    filterSelectFromRef.current = filterSelectFrom;

    onQuery(payload, action);
  };

  const {
    data: dataCreateLotSummary,
    isLoading: isLoadingEntry,
    refetch: refetchCreateLotSummary,
  } = useFindCreateLotSummaryAllUnitQuery(null, {
    skip: false,
    pollingInterval: progress === 100 ? 0 : 5000, //5 วิ
  });

  useEffect(() => {
    if (dataCreateLotSummary && dataCreateLotSummary?.id !== null) {
      setProgress(
        dataCreateLotSummary?.progress ? dataCreateLotSummary?.progress : 0
      );
      setStatus(dataCreateLotSummary?.summary_all_unit);
      let findSelectFrom = dataCreateLotSummary?.location_froms?.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));
      setIsDataCreateLotSummary(dataCreateLotSummary);
      planIdRef.current = dataCreateLotSummary?.id;
      statusRef.current = dataCreateLotSummary?.status;
      setStatusSummary(dataCreateLotSummary);
      setEnableSearch(false);
      setFilterOptionFrom(findSelectFrom);
    } else {
      setEnableSearch(true);
      statusRef.current = dataCreateLotSummary?.status;
      setStatusSummary(dataCreateLotSummary);
    }
  }, [dataCreateLotSummary]);

  const onCallOnLinkRegion = (region_id) => {
    let payload = {
      plan_id: planIdRef.current,
      region_id: region_id,
      from_id: filterSelectFromRef.current,
    };

    callOnLinkRegion(payload);
  };
  const onCallApiGetShowSubCluster = (cluster_id) => {
    let payload = {
      plan_id: planIdRef.current,
      cluster_id: cluster_id,
      from_id: filterSelectFromRef.current,
    };

    callApiGetShowSubCluster(payload);
  };
  const [
    callOnLinkRegion,
    {
      data: dataGetClusterByRegion,
      error: errorGetClusterByRegion,
      isLoading: isLoadingGetClusterByRegion,
    },
  ] = useFindCreateLotClusterByRegionMutation();

  useEffect(() => {
    if (dataGetClusterByRegion) {
      setSelectedRowKeys([]);
      setDataSubClusterTable([]);
      setCountUnit(null);
      setDataClusterByRegionTable(dataGetClusterByRegion?.region);
    }
  }, [dataGetClusterByRegion]);

  const [
    callApiGetShowSubCluster,
    {
      data: dataGetShowSubCluster,
      error: errorGetShowSubCluster,
      isLoading: isLoadingGetShowSubCluster,
    },
  ] = useFindCreateLotBySubClusterMutation();

  useEffect(() => {
    if (errorGetShowSubCluster) {
      setErrorData(errorGetShowSubCluster);
    }
  }, [errorGetShowSubCluster, setErrorData]);

  useEffect(() => {
    if (dataGetShowSubCluster) {
      let mapSub = dataGetShowSubCluster?.region.map((r, index) => ({
        cluster: r.cluster,
        first_unassigned_time: r.first_unassigned_time,
        remain: r.remain,
        main: index === 0 ? r.cluster?.name : null,
        selectAuto: index === 0 ? 1 : null,
        std_loading: dataGetShowSubCluster?.std_loading,
      }));
      setCountUnit(1);
      countUnitRef.current = 1;

      setDataSubClusterTable({
        std_loading: dataGetShowSubCluster?.std_loading,
        region: mapSub,
      });
    }
  }, [dataGetShowSubCluster]);

  useEffect(() => {
    if (dataSubClusterTable) {
      dataSourceSubClusterTableRef.current = dataSubClusterTable.region;
    }
  }, [dataSubClusterTable]);

  const onApiCheckManualLot = (dataCluster) => {
    let arrTo = dataCluster.map((d) => ({
      cluster_id: d.cluster?.id,
      unit_count: d.newDataUnit ? d.newDataUnit : d.selectAuto,
    }));
    let pushArrTo = [];
    for (let index = 0; index < arrTo.length; index++) {
      if (arrTo[index]?.unit_count) {
        pushArrTo.push(arrTo[index]);
      }
    }

    let payload = {
      plan_id: planIdRef.current,
      from_yard_id: filterSelectFromRef.current,
      to: pushArrTo,
    };
    apiCreateLotCheck(payload);
  };

  const apiCreateLotCheck = async (value) => {
    try {
      setDisabledBtnConfirm(true);
      if (value) {
        let result = await dispatch(
          createLotApi.endpoints.findCheckManualLot.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            if (result.data?.status === 'error') {
              setIsModalConfirmVisible(false);
              Modal.error({
                title: <div style={{ color: 'red' }}>Cannot match</div>,
                content: (
                  <div style={{ color: 'red' }}>
                    {result.data?.errorList[0] &&
                      result.data?.errorList[0].cannot_match.map((e, index) => (
                        <div key={index}>{e}</div>
                      ))}
                  </div>
                ),
              });
            } else if (result.data?.payload.length > 0) {
              if (
                result.data?.total_count < dataGetShowSubCluster?.std_loading
              ) {
                Modal.warning({
                  title: (
                    <div style={{ color: 'red' }}>
                      The selected units are less than STD loading
                    </div>
                  ),
                  onOk: () => {
                    setIsModalConfirmVisible(true);
                    setPopupConfirm(result.data);
                  },
                });
              } else {
                setIsModalConfirmVisible(true);
                setPopupConfirm(result.data);
              }
            }
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setDisabledBtnConfirm(false);
    }
  };

  const onSaveManualLot = (popupConfirm) => {
    try {
      let mapArr = popupConfirm?.payload.map((p) => ({
        cluster_id: p.cluster_id,
        unit_count: p.unit_count,
      }));
      let payload = {
        plan_id: planIdRef.current,
        from_yard_id: filterSelectFromRef.current,
        to: mapArr,
        save_lot: true,
      };
      apiSaveManualLot(payload);
    } catch (err) {
      console.log('err', err);
    }
  };

  const apiSaveManualLot = async (value) => {
    try {
      setIsLoadingSaveManualLot(true);
      if (value) {
        let result = await dispatch(
          createLotApi.endpoints.findSaveManualLot.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            setPopupConfirm(result.data);
            setDataSubClusterTable([]);
            // setDataClusterByRegionTable([]);
            setCountUnit(null);
            countUnitRef.current = null;
            setSelectedRowKeys([]);
            onSearchTable(true);
            refetchCreateLotSummary();
            onCallOnLinkRegion(bgSelectRef.current);
            // bgSelectRef.current = null;
            setIsLoadingSaveManualLot(false);
            message.success('Data was successfully saved');
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const [
    apiCreateLot,
    {
      data: dataCreateLot,
      error: errorCreateLot,
      isLoading: isLoadingCreateLot,
    },
  ] = useFindCreateLotMutation();

  useEffect(() => {
    if (dataCreateLot) {
      console.log('dataCreateLot', dataCreateLot);
      if (dataCreateLot.status !== 'completed') {
        setEnableSearch(false);
      } else {
        setEnableSearch(true);
      }
      setStatusCreateLot(dataCreateLot.status);
      refetchCreateLotSummary();
    }
  }, [dataCreateLot]);

  useEffect(() => {
    if (errorCreateLot) {
      setErrorData(errorCreateLot);
    }
  }, [errorCreateLot, setErrorData]);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const onQuery = async (value, action) => {
    console.log('value', value);
    try {
      setIsLoading(true);
      setIsLoadingRegion(true);
      if (value) {
        let result = await dispatch(
          createLotApi.endpoints.findCreateLotSearchByFrom.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            setDataSourceTableMain(result.data?.results);
            if (!action) {
              setDataClusterByRegionTable([]);
              setDataSubClusterTable([]);
            }
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
      setIsLoadingRegion(false);
    }
  };

  const onSetSelectedRowKeys = (rowKeys) => {
    setSelectedRowKeys(rowKeys);
    setDataSubClusterTable([]);
    setCountUnit(null);
    countUnitRef.current = null;
  };
  const onModalConfirmVisible = () => {
    setIsModalConfirmVisible(false);
    setDisabledBtnConfirm(false);
  };

  useEffect(() => {
    refetchCreateLotSummary();
  }, []);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Create Lot'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <TableMain
          //parameter
          createLotSummaryAll={isDataCreateLotSummary}
          filterDateFrom={filterDateFrom}
          filterDateTo={filterDateTo}
          filterSelectFrom={filterSelectFrom}
          filterOptionFrom={filterOptionFrom}
          setFilterDateFrom={setFilterDateFrom}
          setFilterDateTo={setFilterDateTo}
          setFilterSelectFrom={setFilterSelectFrom}
          setFilterOptionFrom={setFilterOptionFrom}
          isLoadingEntry={isLoadingEntry}
          dataSourceTableMain={dataSourceTableMain}
          dataClusterByRegionTable={dataClusterByRegionTable}
          dataSubClusterTable={dataSubClusterTable}
          setDataSubClusterTable={setDataSubClusterTable}
          dataEditUnit={dataEditUnit}
          setDataEditUnit={setDataEditUnit}
          //function
          enableSearch={enableSearch}
          apiSearchTable={onSearchTable}
          apiCreateLot={apiCreateLot}
          callOnLinkRegion={onCallOnLinkRegion}
          onSaveApiCreateLot={onSaveManualLot}
          callApiGetShowSubCluster={onCallApiGetShowSubCluster}
          dataSourceSubClusterTableRef={dataSourceSubClusterTableRef}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={onSetSelectedRowKeys}
          isLoadingGetFilter={isLoading}
          planId={planIdRef.current}
          statusSummary={statusSummary}
          isLoadingCreateLot={isLoadingCreateLot}
          getApiCheckManualLot={onApiCheckManualLot}
          popupConfirm={popupConfirm}
          progress={progress}
          status={status}
          statusCreateLot={statusCreateLot}
          bgSelectRef={bgSelectRef}
          countUnit={countUnit}
          setCountUnit={setCountUnit}
          countUnitRef={countUnitRef}
          isLoadingGetClusterByRegion={isLoadingGetClusterByRegion}
          isLoadingGetShowSubCluster={isLoadingGetShowSubCluster}
          isLoadingRegion={isLoadingRegion}
          isModalConfirmVisible={isModalConfirmVisible}
          onModalConfirmVisible={onModalConfirmVisible}
          disabledBtnConfirm={disabledBtnConfirm}
          isLoadingSaveManualLot={isLoadingSaveManualLot}
        />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  // overflow: auto;
`;

export default CreateLotIndexComponent;
