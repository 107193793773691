import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  DNList: {
    pageSize: 'A5',
    pageOrientation: 'LANDSCAPE',
    quantity: 2,
  },
  Calling: {
    pageSize: 'A4',
    pageOrientation: 'PORTRAIT',
    quantity: 2,
  },
  Kakudai: {
    pageSize: 'A4',
    pageOrientation: 'PORTRAIT',
    quantity: 1,
  },
  DeliveryActual: {
    pageSize: 'A5',
    pageOrientation: 'LANDSCAPE',
    quantity: 2,
  },
};
export const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    clearState: (_) => {
      return initialState;
    },
    setPrintConfig: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  extraReducers: {},
});

export const { clearState, setPrintConfig } = printSlice.actions;
export const printSelector = (state) => state.print;

export default printSlice.reducer;
