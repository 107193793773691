import React from 'react';
import DN from './DN';

const PrintAllDN = ({ componentRef, deiveryNotesData }) => {
  return (
    <div style={{ display: 'none' }}>
      <div ref={componentRef}>
        {deiveryNotesData.map((dnData, idx) => (
          <div style={{ pageBreakAfter: 'always' }}>
            <DN dnData={dnData} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrintAllDN;
