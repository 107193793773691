import React, { useEffect } from 'react';
import withAuth from './libs/withAuth';
import Routers from './routes/routers';
import AdminLayoutHoc from './layout/AdminLayoutHoc';
import { useSelector } from 'react-redux';
import { getLanguage } from './reducers/language';


const App = (props) => {
  const { language, setLanguage } = props;
  const languageStore = useSelector(getLanguage);

  useEffect(() => {
    if (!language || !languageStore) {
      return;
    }

    if (language !== languageStore) {
      setLanguage(languageStore);
    }
  }, [language, languageStore, setLanguage]);

  if (language !== languageStore) {
    return <div />;
  }

  return (
    <AdminLayoutHoc>
      <Routers {...props} />
    </AdminLayoutHoc>
  );
};

export default withAuth(App);
//export default App;
