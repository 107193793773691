import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { Sorter } from 'utils/sorter';
import styled from 'styled-components';
import { Row, Col, Modal, Button, Divider } from 'antd';
import { dateTimeNotYearTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const TableSub = (props) => {
  const {
    selectedRows,
    setSelectedRows,
    selectedRowKeys,
    setSelectedRowKeys,
    planJobStatus,
    isLoadingCreateByRemainTrip,
    loadingProgressBySystem,
    setManualList,
  } = props;

  const selectedRef = useRef([]);
  const selectedRowKeyRef = useRef([]);
  const dispatch = useDispatch();
  const { confirm } = Modal;
  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: '',
        dataIndex: '',
        key: 'x',
        align: 'center',
        width: 50,

        sorter: {
          compare: Sorter.DEFAULT,
        },
        render: (record) => {
          return {
            props: {
              style: { color: '#FA0707' },
            },
            children: (
              <div>
                <DeleteOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => deleteRowSelected(e, record)}
                />
              </div>
            ),
          };
        },

        movable: true,
        searchable: true,
      },

      {
        title: 'Trip no.',
        width: 50,
        align: 'center',
        render: (text, key, index) => {
          return index + 1;
        },
      },

      {
        title: 'Receive job time',
        dataIndex: 'receive_job_time',
        width: 100,
        align: 'center',
        render: (text) => {
          return dateTimeNotYearTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Vehicle type',
        width: 100,
        dataIndex: 'vehicle_type',
        align: 'center',
        render: (text) => {
          return text?.name;
        },
      },
      {
        title: 'From',
        dataIndex: 'location_from',
        width: 100,
        align: 'center',
        render: (text) => {
          return text?.name;
        },
      },
      {
        title: 'To',
        width: 100,
        align: 'center',
        dataIndex: 'location_to',
        render: (text) => {
          return text?.name;
        },
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        width: 100,
        align: 'center',
        render: (text) => {
          return text;
        },
      },
      {
        title: 'Arrival time',
        width: 100,
        dataIndex: 'arrival_time',
        align: 'center',
        render: (text, record) => {
          return dateTimeNotYearTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Return time',
        width: 100,
        dataIndex: 'return_time',
        align: 'center',
        render: (text, record) => {
          return dateTimeNotYearTimestamptzFormatter({ value: text });
        },
      },
    ];

    return cols;
  });
  const onBtnClearAll = () => {
    confirm({
      icon: <ExclamationCircleOutlined />,
      content: "คุณต้องการ 'ลบข้อมูลที่เลือกทั้งหมด' ใช่หรือไม่",
      onOk() {
        selectedRef.current = [];
        selectedRowKeyRef.current = [];
        setSelectedRows([]);
        setSelectedRowKeys([]);
        setManualList([])
      },
    });
  };
  const onBtnGotoTourList = () => {
    dispatch(push(`/app/planning/manage_tour_list`));
  };
  useEffect(() => {
    selectedRef.current = selectedRows;
    selectedRowKeyRef.current = selectedRowKeys;
  }, [selectedRows, selectedRowKeys]);

  const deleteRowSelected = (e, record) => {
    // console.log('delete row selected:: e', e)
    console.log('delete row selected:: record', record);
    let dataList = selectedRef.current;
    // let dataKeyList = selectedRowKeyRef.current;
    let currDataList = dataList.filter((d) => d.id !== record.id);

    // console.log('dataList :>> ', dataList);
    //  console.log('dataListDelete :>> ', dataListDelete);
    // let arr_data_list_key = [];
    // for (const i in dataKeyList) {
    //   if (Object.hasOwnProperty.call(dataKeyList, i)) {
    //     if (dataKeyList[i] !== record.id) {
    //       arr_data_list_key.push(dataKeyList[i]);
    //     }
    //   }
    // }
    setManualList([...currDataList]);
    // uncheckRow(record)
    // setSelectedRows(dataListDelete);
    // setSelectedRowKeys(arr_data_list_key);
    // console.log('dataListDelete :>> ', dataListDelete);
    // console.log('arr_data_list_key :>> ', arr_data_list_key);
    // onCreateByManual(dataListDelete);
  };

  return (
    <Container style={{ marginTop: '10px' }}>
      <Row
        gutter={[20]}
        style={{ marginBottom: '10px', marginRight: '10px' }}
        align='end'
        justify='end'
      >
        <Col span={2}>
          <Button
            type='primary'
            style={{
              textAlign: 'center',
            }}
            disabled={
              isLoadingCreateByRemainTrip === true ||
              loadingProgressBySystem === true
            }
            onClick={onBtnGotoTourList}
          >
            Manage Tour List
          </Button>
        </Col>
        &nbsp; &nbsp; &nbsp; &nbsp;
        <Col span={2}>
          <Button
            type='primary'
            style={{
              textAlign: 'center',
              width: '100px',
            }}
            disabled={
              planJobStatus === false ||
              selectedRows?.length === 0 ||
              isLoadingCreateByRemainTrip === true ||
              loadingProgressBySystem === true
            }
            onClick={onBtnClearAll}
          >
            Clear All
          </Button>
        </Col>
      </Row>

      <StyledAntTable
        rowKey='id'
        tableId={'id'}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={selectedRows}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />

      <Divider />

    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default TableSub;
