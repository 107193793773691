import React, { useState } from 'react';
import { Modal, Button, Input } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const DeleteModal = (props) => {
  const { visible, onCancel, onDelete, showPermanentDelete } = props;
  const [confirm, setConfirm] = useState(false);
  const [text, setText] = useState('');
  return (
    <Modal
      visible={visible}
      title='Are your sure want to delete?'
      icon={<ExclamationCircleOutlined />}
      onCancel={() => {
        setConfirm(false);
        setText('');
        onCancel();
      }}
      footer={[
        <Button
          key='cancel'
          onClick={() => {
            setConfirm(false);
            setText('');
            onCancel();
          }}
        >
          Cancel
        </Button>,
        <Button
          key='delete'
          disabled={!confirm}
          danger
          onClick={() => {
            setConfirm(false);
            setText('');
            onDelete();
          }}
        >
          Delete
        </Button>,
        showPermanentDelete && (
          <Button
            key='destroy'
            disabled={!confirm}
            danger
            type='primary'
            onClick={() => {
              setConfirm(false);
              setText('');
              onDelete(true);
            }}
          >
            Permanently Delete
          </Button>
        ),
      ]}
    >
      <Input
        placeholder={`Type 'delete' to confirm`}
        value={text}
        onChange={(e) => {
          if (e.target.value === 'delete') {
            setConfirm(true);
          } else {
            setConfirm(false);
          }
          setText(e.target.value);
        }}
      />
    </Modal>
  );
};

export default DeleteModal;
