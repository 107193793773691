import React, { useState, useEffect, useCallback } from "react";
import AntTable from "common_components/AntTable";
import EditButtonRenderer from "common_components/EditButtonRenderer";
import { dateTimeTimestamptzFormatter } from "common_components/AntTable/tableUtils";
import TableToolbar from "common_components/AntTable/Toolbar";
import { push } from "connected-react-router";
import permission from "permissions";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentUser } from "reducers/user";
import { useDeleteDiagramOwnerMutation } from "reducers/diagramOwners/api";
import { useErrorHandler } from "common_components/ErrorContext";
import { Sorter } from "utils/sorter";
import styled from "styled-components";
import { selectTableStateById } from "reducers/table";
import { message } from "antd";
import { diagramOwnerApi } from "reducers/diagramOwners/api";

const DiagramGroupTableComponent = (props) => {
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, "group")
  );

  const onSearch = useCallback(
    (searchTerm) => {
      if (rowData && rowData.length > 0) {
        let searchCols = columns.filter((c) => c.searchable);
        if (searchCols.length > 0 && searchTerm) {
          let filterData = rowData.filter((d) =>
            searchCols.some(
              (s) =>
                d[s.dataIndex] &&
                d[s.dataIndex]
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
            )
          );
          setDataSource(filterData);
        } else {
          setDataSource(rowData);
        }
      }
      clearSelectedRows();
    },
    [columns, rowData]
  );

  const onCreate = () => {
    dispatch(push(`/app/user_mng/configuration_diagram_owner/create/details`));
  };

  const handleEditClicked = (cell, readOnly) => {
    let id = cell.id;
    dispatch(push(`/app/user_mng/configuration_diagram_owner/edit/${id}`));
  };

  const onDelete = () => {
    // TODO: Bulk delete
    for (let row of selectedRows) {
      deleteDiagram(row.id);
    }

    clearSelectedRows();
  };

  const onReload = () => {
    props.refetchData();
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setShowDelete(false);
  };

  const deleteDiagram = async (id) => {
    try {
      let result = await dispatch(
        diagramOwnerApi.endpoints.deleteDiagramOwners.initiate(id)
      );
      if (result && result.error) {
        // setIsErrorSearch(result.error);
      } else {
        if (result.data) {
          console.log(result.data);
        }
      }
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      message.success("Delete success.");
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);

      if (permission.checkPermission(user, "group", ["d"])) {
        setShowDelete(selectedRows.length > 0 ? true : false);
      }
    },
  };
  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: "",
        dataIndex: "edit",
        align: "center",
        render: (text, record) => {
          return (
            <EditButtonRenderer data={record} onClick={handleEditClicked} />
          );
        },
        width: 50,
      },
      {
        title: "Name",
        dataIndex: "name",
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: "Created time",
        dataIndex: "created_at",
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DATE,
        },
        movable: true,
      },
      {
        title: "Updated time",
        dataIndex: "updated_at",
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DATE,
        },
        movable: true,
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }
    return cols;
  });
  return (
    <Container>
      <TableToolbar
        onSearch={onSearch}
        onCreate={onCreate}
        onDelete={onDelete}
        onReload={onReload}
        showDelete={showDelete}
        showReload={true}
        showSearch={true}
        isLoading={isLoading}
        showAdd={permission.checkPermission(user, "group", ["c"])}
        columns={columns}
        setColumns={setColumns}
      />
      <AntTable
        rowKey="id"
        tableId={"group"}
        rowSelection={rowSelection}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: "max-content" }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: "Edit", action: (row) => handleEditClicked(row) },
          {
            label: "Delete",
            action: (row) => deleteDiagram({ id: row.id }),
            delete: true,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default DiagramGroupTableComponent;
