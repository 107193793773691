import React, { useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { dateTimeYearTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import { Sorter } from 'utils/sorter';
import styled from 'styled-components';
import { Button, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { orderBy } from 'lodash';

const VinTableMenuComponent = (props) => {
  const {
    dataSource,
    getDataKakudai,
    param_mode,
    param_from,
    isLoadingSearch,
    setDataKakudai,
    selectedRows,
    setSelectedRows,
    selectedRowKeys,
    setSelectedRowKeys,
    toTalSelect,
    setToTalSelect,
    onClearAll,
    showModalEditVinlist,
    dataSourceForModeAddVin,
    pageSize,
    setPageSize,
    totalRows,
    page,
    setPage,
    onSearch,
  } = props;
  const selectedRef = useRef();
  const selectedRowKeyRef = useRef();

  const columns = [
    {
      title: 'VIN',
      dataIndex: 'vin_no',
      align: 'center',
      width: 150,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Series',
      dataIndex: 'series',
      align: 'center',
      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      align: 'center',
      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Cluster',
      dataIndex: 'cluster',
      align: 'center',
      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
        field: 'name',
      },
      render: (text, record) => {
        return {
          children: (
            <div style={record?.editStatus === true ? { color: 'red' } : {}}>
              {text ? text : ''}
            </div>
          ),
        };
      },

      movable: true,
      searchable: true,
    },
    {
      title: 'From',
      dataIndex: 'location_from',
      align: 'center',
      width: 150,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'To',
      dataIndex: 'location_to',
      align: 'center',
      width: 150,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Destination',
      dataIndex: 'location_destination',
      width: 150,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Vehicle type',
      dataIndex: 'vehicle_type',
      width: 100,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 100,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Parking Lane',
      dataIndex: ['parking_lane', 'lane_no'],
      width: 60,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Lot no.',
      dataIndex: 'lot_no_cbu',
      width: 60,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Upload date',
      dataIndex: 'upload_date',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return text ? moment(text).format('DD/MM/YY') : '';
      },
      sorter: {
        compare: Sorter.DATE,
      },
      movable: true,
    },
    {
      title: 'Vehicle Status',
      dataIndex: 'vehicle_status',
      align: 'center',
      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
  ];

  const columnsSub = [
    {
      title: 'Delete',
      dataIndex: '',
      key: 'x',
      align: 'center',
      width: 20,

      sorter: {
        compare: Sorter.DEFAULT,
      },
      render: (text, record) => {
        return {
          props: {
            style: { color: '#FA0707' },
          },
          children: (
            <div>
              <DeleteOutlined
                style={{ cursor: 'pointer' }}
                onClick={(e) => deleteRowSelected(e, record)}
              />
            </div>
          ),
        };
      },

      movable: true,
      searchable: true,
    },

    {
      title: 'VIN',
      dataIndex: 'vin_no',
      align: 'center',
      width: 150,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Series',
      dataIndex: 'series',
      align: 'center',
      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Color',
      dataIndex: 'color',
      align: 'center',
      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Cluster',
      dataIndex: 'cluster',
      align: 'center',

      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
        field: 'name',
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'From',
      dataIndex: 'location_from',
      align: 'center',
      width: 50,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'To',
      dataIndex: 'location_to',
      align: 'center',
      width: 50,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Destination',
      dataIndex: 'location_destination',
      width: 100,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Vehicle type',
      dataIndex: 'vehicle_type',
      width: 100,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 100,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Parking Lane',
      dataIndex: 'parking_lane',
      width: 100,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Lot no.',
      dataIndex: 'lot_no_cbu',
      width: 100,
      align: 'center',
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
    {
      title: 'Upload date',
      dataIndex: 'upload_date',
      align: 'center',
      width: 100,
      render: (text, record) => {
        if (text === '') {
          text = null;
        }
        return dateTimeYearTimestamptzFormatter({ value: text });
      },
      sorter: {
        compare: Sorter.DATE,
      },
      movable: true,
    },
    {
      title: 'Vehicle Status',
      dataIndex: 'vehicle_status',
      align: 'center',
      width: 100,
      sorter: {
        compare: Sorter.DEFAULT,
      },
      movable: true,
      searchable: true,
    },
  ];

  const deleteRowSelected = (e, record) => {
    let dataList = selectedRef.current;
    let dataKeyList = selectedRowKeyRef.current;
    let arr_data_list = [];
    let arr_data_list_key = [];

    for (const i in dataList) {
      if (Object.hasOwnProperty.call(dataList, i)) {
        if (dataList[i].id !== record.id) {
          arr_data_list.push(dataList[i]);
        }
      }
    }

    for (const i in dataKeyList) {
      if (Object.hasOwnProperty.call(dataKeyList, i)) {
        if (dataKeyList[i] !== record.id) {
          arr_data_list_key.push(dataKeyList[i]);
        }
      }
    }

    selectedRef.current = arr_data_list;
    selectedRowKeyRef.current = arr_data_list_key;
    setSelectedRows(arr_data_list);
    setSelectedRowKeys(arr_data_list_key);
    setToTalSelect(arr_data_list.length);
  };
  const convertVinToDisplay = (result_res) => {
    setDataKakudai(result_res);
    return result_res.map((d, idx) => ({
      parking_lane_select: [],
      editStatus: false,
      vin_no: d?.vin_no ? d?.vin_no : '',
      id: d?.id ? d?.id : idx + 1,
      assigned_date: d?.assigned_date ? d?.assigned_date : '',
      model_code: d?.model_code ? d?.model_code : '',
      series: d?.series ? d?.series : '',
      engine: d?.engine ? d?.engine : '',
      color: d?.color ? d?.color : '',
      lot_no_cbu: d?.lot_no_cbu ? d?.lot_no_cbu : '',
      cluster: d?.location_to ? d?.location_to?.cluster?.name : '',

      from: d?.from ? d?.from : '',
      location_from: d?.location_from?.short_name
        ? d?.location_from?.short_name
        : '',
      location_to: d?.location_to?.short_name ? d?.location_to?.short_name : '',

      location_to_id: d?.location_to?.id ? d?.location_to?.id : '',

      location_destination: d?.location_destination?.short_name
        ? d?.location_destination?.short_name
        : '',
      parking_lane_old: d?.parking || { id: '', lane_no: '' },
      parking_lane: d?.parking || { id: '', lane_no: '' },
      destination: d?.destination ? d?.destination : '',
      vehicle_type: d?.vehicle_type?.name ? d?.vehicle_type?.name : '',
      vehicle_type_id: d?.vehicle_type?.id ? d?.vehicle_type?.id : '',
      customer: d?.customer?.code ? d?.customer?.code : '',
      customer_id: d?.customer?.id ? d?.customer?.id : '',
      customer_code: d?.customer?.code ? d?.customer?.code : '',

      print_dn: d?.print_dn ? d?.print_dn : '',
      upload_date: d?.upload_date ? d?.upload_date : '',
      vehicle_status: d?.vehicle_status?.name_en
        ? d?.vehicle_status?.name_en
        : '',
      location_destination_kakudai: d?.location_destination?.name || '',
      cluster_kakudai: d?.location_destination?.cluster?.name || '',
    }));
  };
  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      if (
        param_mode === 'ADD_VIN' ||
        param_mode === 'CREATE_NEW_CALLING' ||
        param_mode === 'CREATE_NEW_CALLING_FIRST'
      ) {
        if (dataSourceForModeAddVin?.length + selectedRows?.length < 11) {
          let numberCheck = selectedRowKeys.length - 1;

          const found = dataSourceForModeAddVin.find(
            (element) => element.vin_no === selectedRowKeys[numberCheck]
          );

          if (found) {
            message.warning('Vin no is Duplicate !!!');
          } else {
            selectedRef.current = selectedRows;
            selectedRowKeyRef.current = selectedRowKeys;
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
            setToTalSelect(selectedRows?.length);
            convertVinToDisplay(selectedRows);
          }
        } else {
          message.error('Please noted that your selected max 10 vin no.');
        }
      } else {
        selectedRef.current = selectedRows;
        selectedRowKeyRef.current = selectedRowKeys;
        setSelectedRowKeys(selectedRowKeys);
        setSelectedRows(selectedRows);
        setToTalSelect(selectedRows?.length);
        convertVinToDisplay(selectedRows);
      }
    },
  };
  const onChangePage = (newPage, newPageSize) => {
    if (pageSize !== newPageSize) {
      setPage(1);
      setPageSize(newPageSize);
      onSearch(false, 1, newPageSize);
    } else if (newPage !== page) {
      setPage(newPage);
      onSearch(false, newPage, newPageSize);
    }
  };
  return (
    <Container>
      <AntTable
        rowKey='id'
        tableId={'vin_table'}
        bordered
        rowSelection={rowSelection}
        showSorterTooltip={false}
        loading={isLoadingSearch}
        columns={columns}
        dataSource={dataSource}
        height={500}
        scroll={{ x: 'max-content' }}
        showContextMenu={true}
        menuActions={
          param_mode === 'VIEW' || param_mode === 'EDIT_VIN'
            ? [
                {
                  label: 'Print Kakudai',
                  action: (row) => getDataKakudai(row),
                },
                {
                  label: 'Edit',
                  action: (row) => showModalEditVinlist(row),
                },
              ]
            : []
        }
        pagination={{
          size: 'small',
          defaultPageSize: pageSize,
          total: totalRows,
          showSizeChanger: true,
          pageSizeOptions: ['50', '100', '200', '500'],
          showQuickJumper: true,
          current: page,
          onChange: onChangePage,
        }}
      />
      <Button
        style={{ marginTop: '10px', marginBottom: '10px' }}
        type='primary'
        onClick={onClearAll}
        disabled={
          param_from === 'MENU' ||
          toTalSelect === 0 ||
          param_mode === 'EDIT_VIN'
        }
        // disabled
      >
        Clear All
      </Button>

      <AntTable
        rowKey='id'
        tableId={'vin_table_sub'}
        dataSource={
          param_mode === 'ADD_VIN' ||
          param_mode === 'CREATE_NEW_CALLING' ||
          param_mode === 'CREATE_NEW_CALLING_FIRST'
            ? (() => {
                let sortedData = orderBy(selectedRows, ['location_to']);
                return sortedData;
              })()
            : []
        }
        // dataSource={selectedRows}
        bordered
        columns={columnsSub}
        height={500}
        scroll={{ x: 'max-content' }}
        showSorterTooltip={false}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default VinTableMenuComponent;
