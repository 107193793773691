// import Foo from 'dms_components/Foo';
import DownloadReport from 'dms_components/DownloadReport';

const downloadReportRoutes = [
  {
    path: '/app/download_report',
    name: 'Download Report',
    Component: DownloadReport,
  },
];

export default downloadReportRoutes;
