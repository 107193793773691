import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import './index.css';
import styled from 'styled-components';
import { Typography, Row, Col, Input } from 'antd';
import Toolbar from './Toolbar';
import ChartLineComponent from './Chart';
import TableClusterByRegion from './TableSub';

const { Link } = Typography;
const TableComponent = (props) => {
  const {
    yardDate,
    titleDate,
    selectedRowKeys,
    setSelectedRowKeys,
    createLotSummaryAll,
    filterSelectDate,
    filterOptionDate,
    filterSelectFrom,
    filterOptionFrom,
    setFilterSelectDate,
    setFilterOptionDate,
    setFilterSelectFrom,
    setFilterOptionFrom,
    isLoadingEntry,
    dataSourceTableMain,
    // setdataSourceTableMain,
    onGetFilterDataVolumeTable,
    getEntryAssignVolumeQuery,
    apiCreateLotCheck,
    apiSearchTable,
    isLoadingSearchTable,
    callOnLinkRegion,
    statusManageTrip,
    isLoading,

    callApiGetShowSubCluster,
    // respon api
    detailGraph,
    detail,
    setDetail,
    updateDetail,
    // for confirm
    planJobId,
    confirmManageTripBoard,
    isLoadingConfirm,
    //for update time success
    saveRecord,
    textRecord,
    setSaveRecord,
    setTextRecord,
    apiSearchTableSave,
    statusEditRowRef
  } = props;

  const rowMeargeRef = useRef([]);
  const dataSourceTableMainRef = useRef([]);
  const headerDateTableRef = useRef([]);

  const onLinkRegion = (record, text) => {
    setSaveRecord(record);
    setTextRecord(text);
    callOnLinkRegion(record, text);
  };

  useEffect(() => {
    if (dataSourceTableMain) {
      dataSourceTableMainRef.current = {
        cal: (dataSourceTableMain[0]?.time_00_30 * 90) / 100,
        default: dataSourceTableMain[0]?.time_00_30,
      };
    }
  }, [dataSourceTableMain]);

  const checkColors = (title, text) => {
    if (title === 'Usage all region') {
      if (text > dataSourceTableMainRef.current.default) {
        return '#FA5050';
      } else if (
        text >= dataSourceTableMainRef.current?.cal &&
        text <= dataSourceTableMainRef.current?.default
      ) {
        return '#FBF54F';
      }
    }
  };

  const [columnsHeader, setColumnsHeader] = useState([
    {
      title: 'Time',
      dataIndex: 'title',
      align: 'center',
      className: 'bg-blue',
      width: 100,
      render(text, record) {
        return {
          props: {
            style: {
              background: '#E4EEFA',
            },
          },
          children:
            record?.title === 'Max trailer lane' ||
            record?.title === 'Usage all region' ? (
              text
            ) : (text && record?.time_07_30) ||
              (text && record?.time_08_30) ||
              (text && record?.time_09_30) ||
              (text && record?.time_10_30) ||
              (text && record?.time_11_30) ||
              (text && record?.time_12_30) ||
              (text && record?.time_13_30) ||
              (text && record?.time_14_30) ||
              (text && record?.time_15_30) ||
              (text && record?.time_16_30) ||
              (text && record?.time_17_30) ||
              (text && record?.time_18_30) ||
              (text && record?.time_19_30) ||
              (text && record?.time_20_30) ||
              (text && record?.time_21_30) ||
              (text && record?.time_22_30) ||
              (text && record?.time_23_30) ||
              (text && record?.time_00_30) ||
              (text && record?.time_01_30) ||
              (text && record?.time_02_30) ||
              (text && record?.time_03_30) ||
              (text && record?.time_04_30) ||
              (text && record?.time_05_30) ||
              (text && record?.time_06_30) ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, null)}
              >
                {text}
              </Link>
            ) : text ? (
              text
            ) : null,
        };
      },
    },
    {
      title: '07:30',
      dataIndex: 'time_07_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '07:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '08:30',
      dataIndex: 'time_08_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '08:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '09:30',
      dataIndex: 'time_09_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '09:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '10:30',
      dataIndex: 'time_10_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '10:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '11:30',
      dataIndex: 'time_11_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '11:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '12:30',
      dataIndex: 'time_12_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '12:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '13:30',
      dataIndex: 'time_13_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '13:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '14:30',
      dataIndex: 'time_14_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '14:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '15:30',
      dataIndex: 'time_15_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '15:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '16:30',
      dataIndex: 'time_16_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '16:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '17:30',
      dataIndex: 'time_17_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '17:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '18:30',
      dataIndex: 'time_18_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '18:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '19:30',
      dataIndex: 'time_19_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '19:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '20:30',
      dataIndex: 'time_20_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '20:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '21:30',
      dataIndex: 'time_21_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '21:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '22:30',
      dataIndex: 'time_22_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '22:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '23:30',
      dataIndex: 'time_23_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '23:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '00:30',
      dataIndex: 'time_00_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '00:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '01:30',
      dataIndex: 'time_01_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '01:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '02:30',
      dataIndex: 'time_02_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '02:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '03:30',
      dataIndex: 'time_03_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '03:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '04:30',
      dataIndex: 'time_04_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '04:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '05:30',
      dataIndex: 'time_05_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '05:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },
    {
      title: '06:30',
      dataIndex: 'time_06_30',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background: checkColors(record?.title, text),
            },
          },
          children:
            record?.title === 'Max trailer lane' ? (
              text
            ) : text ? (
              <Link
                style={{
                  textDecoration: 'underline',
                  color: '#000',
                }}
                onClick={() => onLinkRegion(record, '06:30')}
              >
                {text}
              </Link>
            ) : null,
        };
      },
      width: 30,
    },

    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      className: 'bg-blue',
      width: 30,
    },
  ]);

  const selectProps = {
    style: {
      width: 150,
    },
    allowClear: true,
    maxTagCount: 'responsive',
  };
  return (
    <Container style={{ paddingTop: '10px' }}>
      <Toolbar
        //parameter
        yardDate={yardDate}
        titleDate={titleDate}
        createLotSummaryAll={createLotSummaryAll}
        filterSelectDate={filterSelectDate}
        filterOptionDate={filterOptionDate}
        filterSelectFrom={filterSelectFrom}
        filterOptionFrom={filterOptionFrom}
        setFilterSelectDate={setFilterSelectDate}
        setFilterOptionDate={setFilterOptionDate}
        setFilterSelectFrom={setFilterSelectFrom}
        setFilterOptionFrom={setFilterOptionFrom}
        isLoadingEntry={isLoadingEntry}
        isLoadingSearchTable={isLoadingSearchTable}
        //function
        apiCreateLotCheck={apiCreateLotCheck}
        onGetFilterDataVolumeTable={onGetFilterDataVolumeTable}
        getEntryAssignVolumeQuery={getEntryAssignVolumeQuery}
        rowMeargeRef={rowMeargeRef}
        apiSearchTable={apiSearchTable}
        headerDateTableRef={headerDateTableRef}
        isLoading={isLoading}
        // for confirm
        planJobId={planJobId}
        confirmManageTripBoard={confirmManageTripBoard}
        isLoadingConfirm={isLoadingConfirm}
        statusManageTrip={statusManageTrip}
      />

      <StyledAntTable
        rowKey='id'
        tableId={'id'}
        bordered
        showSorterTooltip={false}
        columns={columnsHeader}
        loading={isLoading}
        dataSource={dataSourceTableMain}
        pagination={false}
        statusManageTrip={statusManageTrip}
        scroll={{ x: 'max-content' }}
      />
      <Row
        gutter={[20]}
        align='center'
        style={{ marginTop: 30, marginBottom: 10 }}
      >
        <Col>Time</Col>
        <Col span='2'>
          <Input value={detailGraph?.time?.start_time} disabled />
        </Col>
        -
        <Col span='2'>
          <Input value={detailGraph?.time?.end_date} disabled />
        </Col>
        <Col>Region</Col>
        <Col>
          <Input
            value={detailGraph?.region}
            disabled
            style={{ width: '150px' }}
          />
        </Col>
        <Col>Cluster</Col>
        <Col>
          <Input value={detailGraph?.cluster} disabled />
        </Col>
      </Row>

      <ChartLineComponent detailGraph={detailGraph} />
      <TableClusterByRegion
        apiSearchTableSave={apiSearchTableSave}
        callApiGetShowSubCluster={callApiGetShowSubCluster}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        detail={detail}
        setDetail={setDetail}
        updateDetail={updateDetail}
        statusManageTrip={statusManageTrip}
        planJobId={planJobId}
        onLinkRegion={onLinkRegion}
        saveRecord={saveRecord}
        textRecord={textRecord}
        statusEditRowRef={statusEditRowRef}
      />
    </Container>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
    .ant-table table {
      border-collapse: collapse;
    }
  }
`;
const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
