import React, { useRef, useEffect } from 'react';
import { Form, Input, Row, Col, TimePicker, DatePicker, Card } from 'antd';
import Table from 'common_components/AntTable';
import _ from 'lodash';
import ButtonPrint from './Printing/ButtonPrint';
import PrintDN from './Printing/PrintDN';
import { PrinterOutlined } from '@ant-design/icons';
import moment from 'moment';

/* eslint-enable no-template-curly-in-string */
const columns = [
  {
    title: 'แบบรถ',
    dataIndex: 'type',
    width: 200,
  },
  {
    title: 'หมายเลขเครื่อง',
    dataIndex: 'engineNo',
    width: 200,
  },
  {
    title: 'เครื่องหมายตัวถัง',
    dataIndex: 'vin',
    width: 200,
  },
  {
    title: 'สี',
    dataIndex: 'color',
    width: 200,
  },
  {
    title: 'หมายเหตุ',
    dataIndex: 'remark',
    width: 400,
  },
];

const DeliveryNote = (props) => {
  const { data } = props;
  const [form] = Form.useForm();
  const printDNComponentRef = useRef();

  useEffect(() => {
    if (data) {
      const fieldData = _.cloneDeep(data);
      fieldData.deliveryDate = moment(fieldData.deliveryDate);
      fieldData.fromTime = moment(fieldData.fromTime);
      form.setFieldsValue(fieldData);
    }
  }, [data, form]);

  return (
    <Card bordered={true}>
      <Form form={form} name={props.name}>
        <Row gutter={[8, 16]}>
          <Col flex='200px'>
            <Form.Item name='deliveryDate' label='วันที่'>
              <DatePicker disabled={true} />
            </Form.Item>
          </Col>
          <Col flex='180px'>
            <Form.Item name='no' label='เลขที่'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col flex='auto'>
            <Form.Item
              name='addressHead'
              label='ที่อยู่'
              labelCol={{ flex: '60px' }}
              // style={{ flex: 1 }}
              // wrap={false}
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col flex='50px'>
            <PrintDN componentRef={printDNComponentRef} dnData={data}></PrintDN>
            <ButtonPrint
              type='primary'
              // onClick={() => {
              //   dispatch(actions.main.print(printAllComponentRef))
              // }}
              componentRef={printDNComponentRef}
              // style={{ marginRight: 20 }}
              icon={<PrinterOutlined />}
            ></ButtonPrint>
            {/* <Button type="primary"
                onClick={() => { }}
                // style={{ marginRight: 20 }}
                icon={<PrinterOutlined />}
              >

              </Button> */}
          </Col>
        </Row>
        <hr />
        <Row gutter={[8, 16]}>
          <Col xs={8}>
            <Form.Item name='customerNo' label='รหัสลูกค้า'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col xs={8}></Col>
          <Col xs={8}>
            <Form.Item name='driver' label='ชื่อ พชร.'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col flex={1}>
            <Form.Item name='seller' label='ชื่อตัวแทนจำหน่าย'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name='from' label='จาก'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col flex='300px'>
            <Form.Item name='trialNo' label='หมายเลขรถเทรลเลอร์'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 16]}>
          <Col flex={1}>
            <Form.Item name='address' label='ที่อยู่'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col flex={1}>
            <Form.Item name='to' label='ถึง'>
              <Input disabled={true} />
            </Form.Item>
          </Col>
          <Col flex='190px'>
            <Form.Item name='fromTime' label='เวลาออกรถ'>
              <TimePicker disabled={true} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        </Row>
        <hr />
        <div style={{ width: '100%', height: '280px' }}>
          <div
            className='ag-theme-balham'
            style={{
              height: '150px',
              width: '100%',
              marginTop: '10px',
            }}
          >
            <Table
              dataSource={data.cars}
              columns={columns}
              pagination={{ pageSize: 8 }}
              scroll={{ y: 200 }}
              size='small'
            />
          </div>
        </div>
      </Form>
    </Card>
  );
};
export default DeliveryNote;
