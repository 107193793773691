import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import './index.css';
import styled from 'styled-components';
import { Typography, Row, Col, Table } from 'antd';
import Toolbar from './Toolbar';
import TableClusterByRegion from './TableClusterByRegion';
import TableMatchingCluster from './TableMatchingCluster';
import { dateTimeFormatterWorking } from 'common_components/AntTable/tableUtils';

const { Text, Link } = Typography;
const TableComponent = (props) => {
  const {
    popupConfirm,
    getApiCheckManualLot,
    isLoadingGetFilter,
    selectedRowKeys,
    setSelectedRowKeys,
    createLotSummaryAll,
    filterDateFrom,
    filterDateTo,
    filterSelectFrom,
    filterOptionFrom,
    setFilterDateFrom,
    setFilterDateTo,
    setFilterSelectFrom,
    setFilterOptionFrom,
    isLoadingEntry,
    dataSourceTableMain,
    onGetFilterDataVolumeTable,
    getEntryAssignVolumeQuery,
    apiCreateLot,
    enableSearch,
    apiSearchTable,
    callOnLinkRegion,
    onSaveApiCreateLot,
    callApiGetShowSubCluster,
    dataClusterByRegionTable,
    dataSubClusterTable,
    setDataSubClusterTable,
    dataEditUnit,
    setDataEditUnit,
    dataSourceSubClusterTableRef,
    isLoadingCreateLot,
    statusSummary,
    progress,
    statusCreateLot,
    bgSelectRef,
    countUnit,
    setCountUnit,
    countUnitRef,
    status,
    isLoadingGetShowSubCluster,
    isLoadingGetClusterByRegion,
    isLoadingRegion,
    isModalConfirmVisible,
    onModalConfirmVisible,
    disabledBtnConfirm,
    isLoadingSaveManualLot,
  } = props;

  const rowMeargeRef = useRef([]);
  const headerDateTableRef = useRef([]);
  const statusCheckRef = useRef();

  useEffect(() => {
    if (dataSourceTableMain) {
      headerDateTableRef.current = dataSourceTableMain;
    }
  }, [dataSourceTableMain]);

  useEffect(() => {
    if (statusSummary) {
      console.log('statusSummary22', statusSummary);

      statusCheckRef.current = statusSummary?.summary_all_unit?.remain;
    }
  }, [statusSummary]);

  const onLinkRegion = (region_id) => {
    callOnLinkRegion(region_id);
    bgSelectRef.current = region_id;
  };

  const [columnsHeader, setColumnsHeader] = useState([
    {
      title: 'Region',
      dataIndex: ['region', 'name'],
      align: 'center',
      className: 'bg-blue',
      render(text, record) {
        return {
          props: {
            style: {
              background: '#E4EEFA',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Total',
      dataIndex: 'total_remain',
      align: 'center',
      className: 'bg-blue',
      render: (text, record) => {
        return {
          props: {
            style: {
              background:
                bgSelectRef.current === record.region.id ? '#E4EEFA' : null,
            },
          },
          children:
            statusCheckRef.current !== 0 ? (
              <Link
                style={{
                  textDecoration: 'underline',
                }}
                onClick={() => onLinkRegion(record.region.id)}
              >
                {text}
              </Link>
            ) : (
              text
            ),
        };
      },
    },
    {
      title: 'Remain',
      dataIndex: 'remain',
      align: 'center',
      className: 'bg-blue',
      children: [
        {
          title: 'Assign Date',
          dataIndex: 'assign_date',
          align: 'center',
          className: 'bg-blue',
          children: [
            {
              title: () => {
                let date =
                  headerDateTableRef.current &&
                  headerDateTableRef.current[0]?.date1?.date;
                return (
                  <div>
                    {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                  </div>
                );
              },
              dataIndex: ['date1', 'count'],
              key: 1,
              className: 'bg-blue',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: {
                      background:
                        bgSelectRef.current === record.region.id
                          ? '#E4EEFA'
                          : null,
                    },
                  },
                  children: <div>{text}</div>,
                };
              },
            },
            {
              title: () => {
                let date =
                  headerDateTableRef.current &&
                  headerDateTableRef.current[0]?.date2?.date;
                return (
                  <div>
                    {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                  </div>
                );
              },
              dataIndex: ['date2', 'count'],
              key: 2,
              className: 'bg-blue',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: {
                      background:
                        bgSelectRef.current === record.region.id
                          ? '#E4EEFA'
                          : null,
                    },
                  },
                  children: <div>{text}</div>,
                };
              },
            },
            {
              title: () => {
                let date =
                  headerDateTableRef.current &&
                  headerDateTableRef.current[0]?.date3?.date;
                return (
                  <div>
                    {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                  </div>
                );
              },
              dataIndex: ['date3', 'count'],
              key: 3,
              className: 'bg-blue',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: {
                      background:
                        bgSelectRef.current === record.region.id
                          ? '#E4EEFA'
                          : null,
                    },
                  },
                  children: <div>{text}</div>,
                };
              },
            },
            {
              title: () => {
                let date =
                  headerDateTableRef.current &&
                  headerDateTableRef.current[0]?.date4?.date;
                return (
                  <div>
                    {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                  </div>
                );
              },
              dataIndex: ['date4', 'count'],
              key: 4,
              className: 'bg-blue',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: {
                      background:
                        bgSelectRef.current === record.region.id
                          ? '#E4EEFA'
                          : null,
                    },
                  },
                  children: <div>{text}</div>,
                };
              },
            },
            {
              title: () => {
                let date =
                  headerDateTableRef.current &&
                  headerDateTableRef.current[0]?.date5?.date;
                return (
                  <div>
                    {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                  </div>
                );
              },
              dataIndex: ['date5', 'count'],
              key: 5,
              className: 'bg-blue',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: {
                      background:
                        bgSelectRef.current === record.region.id
                          ? '#E4EEFA'
                          : null,
                    },
                  },
                  children: <div>{text}</div>,
                };
              },
            },
            {
              title: () => {
                let date =
                  headerDateTableRef.current &&
                  headerDateTableRef.current[0]?.date6?.date;
                return (
                  <div>
                    {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                  </div>
                );
              },
              dataIndex: ['date6', 'count'],
              key: 6,
              className: 'bg-blue',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: {
                      background:
                        bgSelectRef.current === record.region.id
                          ? '#E4EEFA'
                          : null,
                    },
                  },
                  children: <div>{text}</div>,
                };
              },
            },
            {
              title: () => {
                let date =
                  headerDateTableRef.current &&
                  headerDateTableRef.current[0]?.date7?.date;
                return (
                  <div>
                    {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                  </div>
                );
              },
              dataIndex: ['date7', 'count'],
              key: 7,
              className: 'bg-blue',
              align: 'center',
              render(text, record) {
                return {
                  props: {
                    style: {
                      background:
                        bgSelectRef.current === record.region.id
                          ? '#E4EEFA'
                          : null,
                    },
                  },
                  children: <div>{text}</div>,
                };
              },
            },
          ],
        },
      ],
    },
    {
      title: 'Lot',
      dataIndex: 'lot',
      align: 'center',
      className: 'bg-blue',
      render(text, record) {
        return {
          props: {
            style: {
              background:
                bgSelectRef.current === record.region.id ? '#E4EEFA' : null,
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
      className: 'bg-blue',
      render(text, record) {
        return {
          props: {
            style: {
              background:
                bgSelectRef.current === record.region.id ? '#E4EEFA' : null,
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
  ]);

  return (
    <Container style={{ paddingTop: '10px' }}>
      <Toolbar
        isLoadingGetFilter={isLoadingGetFilter}
        enableSearch={enableSearch}
        createLotSummaryAll={createLotSummaryAll}
        filterDateFrom={filterDateFrom}
        filterDateTo={filterDateTo}
        filterSelectFrom={filterSelectFrom}
        filterOptionFrom={filterOptionFrom}
        setFilterDateFrom={setFilterDateFrom}
        setFilterDateTo={setFilterDateTo}
        setFilterSelectFrom={setFilterSelectFrom}
        setFilterOptionFrom={setFilterOptionFrom}
        isLoadingEntry={isLoadingEntry}
        apiCreateLot={apiCreateLot}
        onGetFilterDataVolumeTable={onGetFilterDataVolumeTable}
        getEntryAssignVolumeQuery={getEntryAssignVolumeQuery}
        rowMeargeRef={rowMeargeRef}
        apiSearchTable={apiSearchTable}
        headerDateTableRef={headerDateTableRef.current}
        isLoadingCreateLot={isLoadingCreateLot}
        progress={progress}
        statusCreateLot={statusCreateLot}
        status={status}
        statusSummary={statusSummary}
      />

      <Row gutter={[20]}>
        <Col span={12}>
          <StyledAntTable
            rowKey='id'
            tableId={'id'}
            loading={isLoadingGetFilter || isLoadingRegion}
            bordered
            showSorterTooltip={false}
            columns={columnsHeader}
            dataSource={dataSourceTableMain}
            scroll={{ x: 'max-content' }}
            pagination={false}
            summary={(pageData) => {
              let total_remains = 0;
              let total_date1 = 0;
              let total_date2 = 0;
              let total_date3 = 0;
              let total_date4 = 0;
              let total_date5 = 0;
              let total_date6 = 0;
              let total_date7 = 0;
              let total_lots = 0;
              let totals = 0;
              pageData.forEach(
                ({
                  total_remain,
                  date1,
                  date2,
                  date3,
                  date4,
                  date5,
                  date6,
                  date7,
                  lot,
                  total,
                }) => {
                  total_remains += total_remain;
                  total_date1 += date1?.count;
                  total_date2 += date2?.count;
                  total_date3 += date3?.count;
                  total_date4 += date4?.count;
                  total_date5 += date5?.count;
                  total_date6 += date6?.count;
                  total_date7 += date7?.count;
                  total_lots += lot;
                  totals += total;
                }
              );
              return (
                <>
                  <Table.Summary.Row style={{ backgroundColor: '#D9D9D9' }}>
                    <Table.Summary.Cell index={0} align='center'>
                      Total
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={1} align='center'>
                      <Text>{total_remains >= 0 ? total_remains : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={2} align='center'>
                      <Text>{total_date1 >= 0 ? total_date1 : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={3} align='center'>
                      <Text>{total_date2 >= 0 ? total_date2 : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={4} align='center'>
                      <Text>{total_date3 >= 0 ? total_date3 : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={5} align='center'>
                      <Text>{total_date4 >= 0 ? total_date4 : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={6} align='center'>
                      <Text>{total_date5 >= 0 ? total_date5 : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={7} align='center'>
                      <Text>{total_date6 >= 0 ? total_date6 : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align='center'>
                      <Text>{total_date7 >= 0 ? total_date7 : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align='center'>
                      <Text>{total_lots >= 0 ? total_lots : null}</Text>
                    </Table.Summary.Cell>
                    <Table.Summary.Cell index={8} align='center'>
                      <Text>{totals >= 0 ? totals : null}</Text>
                    </Table.Summary.Cell>
                  </Table.Summary.Row>
                </>
              );
            }}
          />
        </Col>
        <Col span={12}>
          <Row justify='center' gutter={[24]}>
            <Col span={12}>
              <TableClusterByRegion
                dataClusterByRegionTable={dataClusterByRegionTable}
                callApiGetShowSubCluster={callApiGetShowSubCluster}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                dataSubClusterTable={dataSubClusterTable}
                isLoadingGetClusterByRegion={isLoadingGetClusterByRegion || isLoadingRegion}
              />
            </Col>
            <Col span={12}>
              <TableMatchingCluster
                getApiCheckManualLot={getApiCheckManualLot}
                filterOptionFrom={filterOptionFrom}
                dataSubClusterTable={dataSubClusterTable}
                setDataSubClusterTable={setDataSubClusterTable}
                dataEditUnit={dataEditUnit}
                setDataEditUnit={setDataEditUnit}
                dataSourceSubClusterTableRef={dataSourceSubClusterTableRef}
                filterSelectFrom={filterSelectFrom}
                selectedRowKeys={selectedRowKeys}
                popupConfirm={popupConfirm}
                countUnit={countUnit}
                setCountUnit={setCountUnit}
                countUnitRef={countUnitRef}
                onSaveApiCreateLot={onSaveApiCreateLot}
                isLoadingGetShowSubCluster={isLoadingGetShowSubCluster || isLoadingRegion}
                isModalConfirmVisible={isModalConfirmVisible}
                onModalConfirmVisible={onModalConfirmVisible}
                disabledBtnConfirm={disabledBtnConfirm}
                isLoadingSaveManualLot={isLoadingSaveManualLot}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;
const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
