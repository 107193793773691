import React from 'react';
import Kakudai from './Kakudai';
import constants from 'common_components/PrintDocument/constants';

const PrintKakudai = ({ componentRef, KakudaiData }) => {
  return (
    <div
      id={'print-kakudai'}
      style={{
        display: 'none',
        width: `${constants.A4.portrait[0]}px`,
      }}
    >
      <div ref={componentRef}>
        <Kakudai KakudaiData={KakudaiData} />
      </div>
    </div>
  );
};

export default PrintKakudai;
