import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  DatePicker,
  Typography,
  message,
  Collapse,
  Divider,
  Modal,
  Upload,
  Progress,
} from 'antd';
import styled from 'styled-components';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import {
  useSetDiagramPeriodAssignVolumeMutation,
  useUploadFileAssignVolumeMutation,
  useCreateDefaultTrailerCalendarMutation,
} from 'reducers/assignVolume/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
const { Panel } = Collapse;
const { Option } = Select;
const TableToolbar = (props) => {
  const {
    filterDateFrom,
    filterDateTo,
    filterSelectFrom,
    filterOptionFrom,
    setFilterDateFrom,
    setFilterDateTo,
    setFilterSelectFrom,
    isLoadingSearchYardTable,
    statusCheckReadyEntry,
    setStatusCheckReadyEntry,
    statusConfirm,
    isLoadingClearDataAll,
    uploadFile,
    setUploadFile,
    loadingConfirm,
    //function
    isLoadingEntry,
    onGetFilterDataVolumeTable,
    getEntryAssignVolumeQuery,
    apiSearchYardTable,
    apiClearDataAll,
    apiConfirmData,
    apiConfirmDataVerify,
    progressPercent,
    fileList,
    setFileList,
    selectFrom,
    checkEditDetailVolume,
  } = props;
  const isSelectFromRef = useRef([]);
  const { setErrorData } = useErrorHandler();
  const [isModalVisibleConfirm, setIsModalVisibleConfirm] = useState(false);
  const [isSelectFrom, setIsSelectFrom] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectFrom) {
      setIsSelectFrom(isSelectFrom);
      isSelectFromRef.current = selectFrom;
    }
  }, [selectFrom]);

  //modal Delete Data
  const handleOkConfirm = () => {
    let payload = {
      period_from: filterDateFrom.format('YYYY-MM-DD'),
      period_to: filterDateTo.format('YYYY-MM-DD'),
    };
    apiClearDataAll(payload);
    setIsModalVisibleConfirm(false);
    setFileList([]);
  };
  const handleCancelConfirm = () => {
    setIsModalVisibleConfirm(false);
  };
  //function---------------------------------------------------------
  // upload file excel
  const onUpload = (files) => {
    setUploadFile(files);
  };

  //button-----------------------------------------------------------
  const onBtnSetPeriod = async () => {
    if (filterDateFrom != '' || filterDateTo != '') {
      let payload = {
        period_from: filterDateFrom.format('YYYY-MM-DD'),
        period_to: filterDateTo.format('YYYY-MM-DD'),
      };
      apiSetPeriod(payload);
      payload = {
        year: filterDateFrom.format('YYYY'),
        month: filterDateFrom.format('MM'),
      };
      createDeFaultTrailerCalendar(payload);
    } else {
      message.success('Please select Data From & To !!');
    }
  };
  const onBtnSearch = async () => {
    let payload = {
      period_from: filterDateFrom.format('YYYY-MM-DD'),
      period_to: filterDateTo.format('YYYY-MM-DD'),
      location_from: filterSelectFrom,
    };

    apiSearchYardTable(payload);
    onGetFilterDataVolumeTable(payload);
  };
  const onGoToCreateLot = async () => {
    dispatch(push(`/app/planning/create_lot`));
  };
  const onBtnUploadFile = async () => {
    var data = new FormData();
    data.append('uploadfile', uploadFile);
    if (data) {
      apiUploadFile(data);
    }
  };

  const onBtnClearDataAll = async () => {
    isSelectFromRef.current = [];
    setIsModalVisibleConfirm(true);
  };
  const onBtnConfirm = async () => {
    let payload = {
      period_from: filterDateFrom?.format('YYYY-MM-DD'),
      period_to: filterDateTo?.format('YYYY-MM-DD'),
      location_from: [],
    };
    apiConfirmDataVerify(payload);
  };

  // api
  //apiSetPeriod -------------------------------------------------------
  const [
    apiSetPeriod,
    {
      data: dataSetPeriod,
      error: errorSetPeriod,
      isLoading: isLoadingSetPeriod,
    },
  ] = useSetDiagramPeriodAssignVolumeMutation();

  useEffect(() => {
    if (dataSetPeriod) {
      setStatusCheckReadyEntry(true);
      if (dataSetPeriod.length !== 0) {
        message.success('Set Period success !!');
      }
    }
  }, [dataSetPeriod]);

  useEffect(() => {
    if (errorSetPeriod) {
      Modal.error({
        title: errorSetPeriod?.data?.errorList[0]?.errorDetail,
        icon: <ExclamationCircleOutlined />,
      });
    }
  }, [errorSetPeriod]);

  //apiUpload -------------------------------------------------------
  const [
    apiUploadFile,
    {
      data: dataUploadFile,
      error: errorUploadFile,
      isLoading: isLoadingUploadFile,
    },
  ] = useUploadFileAssignVolumeMutation();

  const [createDeFaultTrailerCalendar] =
    useCreateDefaultTrailerCalendarMutation();

  useEffect(() => {
    if (dataUploadFile) {
      if (dataUploadFile.upload_success === false) {
        Modal.error({
          title: 'Error Message file upload !!',
          content: (
            <div>
              <Row style={{ paddingTop: '20px' }}>
                <Col>
                  <p>
                    1. Date error :{' '}
                    {dataUploadFile?.result['Date error'].length > 0
                      ? `[${dataUploadFile?.result['Date error'].toString()}]`
                      : '-'}
                  </p>
                  <p>
                    2. From not found :{' '}
                    {dataUploadFile?.result['From not found'].length > 0
                      ? `[${dataUploadFile?.result[
                          'From not found'
                        ].toString()}]`
                      : '-'}
                  </p>
                  <p>
                    3. To not found :{' '}
                    {dataUploadFile?.result['To not found'].length > 0
                      ? `[${dataUploadFile?.result['To not found'].toString()}]`
                      : '-'}
                  </p>
                  <p>
                    4. Unit error :{' '}
                    {dataUploadFile?.result['Unit error'].length > 0
                      ? `[${dataUploadFile?.result['Unit error'].toString()}]`
                      : '-'}
                  </p>
                  <p>
                    5. Vehicle type error :{' '}
                    {dataUploadFile?.result['Vehicle type error'].length > 0
                      ? `[${dataUploadFile?.result[
                          'Vehicle type error'
                        ].toString()}]`
                      : '-'}
                  </p>
                  <p>
                    6. Route Speed error :{' '}
                    {dataUploadFile?.result['Route Speed error'].length > 0
                      ? `[${dataUploadFile?.result[
                          'Route Speed error'
                        ].toString()}]`
                      : '-'}
                  </p>
                  <p>
                    7. Production Time error :{' '}
                    {dataUploadFile?.result['Production Time error'].length > 0
                      ? `[${dataUploadFile?.result[
                          'Production Time error'
                        ].toString()}]`
                      : '-'}
                  </p>
                </Col>
              </Row>
            </div>
          ),

          onOk() {},
          width: 600,
        });
      } else {
        getEntryAssignVolumeQuery();
        Modal.success({
          title: 'Success Message file upload !!',
          content: (
            <div>
              <Row style={{ paddingTop: '20px' }}>
                <Col style={{ marginRight: '20px' }}>
                  <p>1. Added Record : </p>
                  <p>2. Updated Record : </p>
                </Col>
                <Col>
                  <p> [{dataUploadFile?.result.added_record}]</p>
                  <p> [{dataUploadFile?.result.updated_record}]</p>
                </Col>
              </Row>
            </div>
          ),

          onOk() {
            setFileList([]);
          },
        });
      }
    }
  }, [dataUploadFile]);

  useEffect(() => {
    if (errorUploadFile) {
      Modal.error({
        title: errorUploadFile?.data?.errorList[0]?.errorDetail,
        icon: <ExclamationCircleOutlined />,
      });
    }
  }, [errorUploadFile, setErrorData]);

  const onChangeFile = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  //Model----------------------------------------------------

  // ---------------------------------------------------------

  return (
    <Container>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[20]}
            style={{ marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='end'>
              <Typography>From {statusCheckReadyEntry}</Typography>
            </Col>
            <Col span={4}>
              <DatePicker
                value={filterDateFrom}
                loading={isLoadingEntry}
                placeholder='Select from'
                disabled={
                  statusCheckReadyEntry === true || loadingConfirm === true
                }
                format={'DD/MM/YY'}
                onChange={(value) => {
                  setFilterDateFrom(value);
                }}
              />
            </Col>
            <Col span={1} align='start'>
              <Typography>To</Typography>
            </Col>
            <Col span={4}>
              <DatePicker
                value={filterDateTo}
                format={'DD/MM/YY'}
                placeholder='Select to'
                disabled={
                  statusCheckReadyEntry === true || loadingConfirm === true
                }
                loading={isLoadingEntry}
                onChange={(value) => {
                  setFilterDateTo(value);
                }}
              />
            </Col>
            <Col span={2} align='start'>
              <Button
                type='primary'
                loading={isLoadingSetPeriod}
                onClick={onBtnSetPeriod}
                disabled={
                  filterDateTo === '' ||
                  filterDateFrom === '' ||
                  statusCheckReadyEntry === true ||
                  isLoadingEntry === true ||
                  loadingConfirm === true
                }
              >
                Set Period
              </Button>
            </Col>
            <Col span={2} align='end'>
              <Typography>From </Typography>
            </Col>
            <Col span={3}>
              <Select
                mode='single'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingEntry}
                disabled={
                  loadingConfirm === true ||
                  checkEditDetailVolume ||
                  filterOptionFrom?.length === 0
                }
                maxTagCount='responsive'
                placeholder={'from'}
                value={filterSelectFrom}
                onChange={(values) => {
                  setFilterSelectFrom(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionFrom &&
                  filterOptionFrom.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Button
                type='primary'
                style={{ width: '100px' }}
                onClick={onBtnSearch}
                loading={isLoadingSearchYardTable}
                disabled={
                  // statusCheckReadyEntry === false ||
                  !filterSelectFrom ||
                  filterSelectFrom.length === 0 ||
                  loadingConfirm === true ||
                  checkEditDetailVolume
                }
              >
                Search
              </Button>
            </Col>
            <Col span={3} align='start'>
              <Button
                type='primary'
                style={{ width: '100px' }}
                disabled={
                  statusConfirm == false ||
                  loadingConfirm === true ||
                  checkEditDetailVolume
                }
                onClick={onGoToCreateLot}
              >
                Create Lot
              </Button>
            </Col>
          </Row>
          <Divider style={{ margin: '0px' }}></Divider>
          <Row
            gutter={[22]}
            style={{ marginTop: '10px' }}
            align='top'
            justify='top'
          >
            <Col span={6} align='end'>
              <Upload
                showUploadList={true}
                maxCount={1}
                fileList={fileList}
                onChange={onChangeFile}
                accept='.csv'
                beforeUpload={(file) => {
                  setFileList([file]);
                  const isCSV = file.type === 'text/csv';
                  if (!isCSV) {
                    message.error(`${file.name} is not a .csv file`);
                  } else {
                    onUpload(file);
                    return false;
                  }
                }}
              >
                <Button
                  style={{ borderRadius: '8px' }}
                  icon={<UploadOutlined />}
                  disabled={
                    statusCheckReadyEntry === false ||
                    statusConfirm === true ||
                    loadingConfirm === true ||
                    checkEditDetailVolume
                  }
                >
                  Select File
                </Button>
              </Upload>
            </Col>

            <Col span={2}>
              <Button
                style={{ textAlign: 'center' }}
                type='primary'
                loading={isLoadingUploadFile}
                onClick={onBtnUploadFile}
                disabled={
                  fileList.length === 0 ||
                  uploadFile === undefined ||
                  loadingConfirm === true
                }
              >
                Upload
              </Button>
            </Col>
            <Col span={7}></Col>
            <Col span={3} align='end' style={{ paddingTop: '5px' }}>
              <Progress percent={progressPercent} steps={5} />
            </Col>
            <Col span={3} align='end'>
              <Button
                type='primary'
                style={{ width: '100px' }}
                onClick={onBtnClearDataAll}
                loading={isLoadingClearDataAll}
                disabled={
                  statusCheckReadyEntry === false ||
                  isLoadingSearchYardTable === true ||
                  loadingConfirm === true ||
                  isLoadingUploadFile === true ||
                  checkEditDetailVolume
                }
              >
                Clear All
              </Button>
            </Col>
            <Col span={3} align='start'>
              <Button
                type='primary'
                style={{ width: '100px' }}
                loading={loadingConfirm === true}
                onClick={onBtnConfirm}
                disabled={
                  isSelectFromRef.current?.length === 0 ||
                  progressPercent === 100 ||
                  checkEditDetailVolume
                }
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>

      <Modal
        title='Would you like to clear all data ?'
        visible={isModalVisibleConfirm}
        onOk={handleOkConfirm}
        onCancel={handleCancelConfirm}
      ></Modal>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default TableToolbar;
