import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Select, InputNumber, message } from 'antd';
import {
  useUpdateYardMutation,
  useCreateYardMutation,
} from 'reducers/masterData/yard/api';
import {
  useFindAllCompaniesQuery,
  useFindCompanyByIdQuery,
} from 'reducers/company/api';
import { useFindAllLocationMastersByTypeQuery } from 'reducers/masterData/locationMaster/api';

import { useFindAllClustersQuery } from 'reducers/masterData/cluster/api';
import { goBack } from 'connected-react-router';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
const { Option } = Select;

const FormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);

  const [locationMasters, setLocationMasters] = useState(
    formData && formData.location_master
      ? [
          {
            id: formData.location_master.id,
            label: formData.location_master.name,
          },
        ]
      : []
  );

  const [companies, setCompanies] = useState(
    formData && formData.company
      ? [{ id: formData.company.id, label: formData.company.name }]
      : []
  );
  const [clusters, setClusters] = useState(
    formData && formData.cluster
      ? [{ id: formData.cluster.id, label: formData.cluster.name }]
      : []
  );

  //Query all location masters
  const {
    data: dataLocationMasters,
    error: errorLocationMasters,
    isFetching: isLocationMastersFetching,
    refetch: refetchLocationMasters,
  } = useFindAllLocationMastersByTypeQuery('Yard', {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataLocationMasters) {
      setLocationMasters(
        dataLocationMasters.map((d) => ({ id: d.id, label: d.name }))
      );
    }
  }, [dataLocationMasters]);

  useEffect(() => {
    if (errorLocationMasters) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load location masters',
        okText: 'Retry',
        onOk: refetchLocationMasters,
        cancelText: 'Cancel',
      });
    }
  }, [errorLocationMasters, refetchLocationMasters]);

  // Query all clusters
  const {
    data: dataClusters,
    error: errorClusters,
    isFetching: isClustersFetching,
    refetch: refetchClusters,
  } = useFindAllClustersQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataClusters) {
      setClusters(dataClusters.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataClusters]);

  useEffect(() => {
    if (errorClusters) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load clusters',
        okText: 'Retry',
        onOk: refetchClusters,
        cancelText: 'Cancel',
      });
    }
  }, [errorClusters, refetchClusters]);

  // Query all companies
  const {
    data: dataCompanies,
    error: errorCompanies,
    isFetching: isCompaniesFetching,
    refetch: refetchCompanies,
  } = useFindAllCompaniesQuery(
    { is_active: true },
    {
      skip: !user || readonly || !user.is_superadmin,
    }
  );

  useEffect(() => {
    if (dataCompanies) {
      setCompanies(dataCompanies.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataCompanies]);

  useEffect(() => {
    if (errorCompanies) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load companies',
        okText: 'Retry',
        onOk: refetchCompanies,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanies, refetchCompanies]);

  // Query company by ID, when admin of a company
  const {
    data: dataCompanyById,
    error: errorCompanyById,
    isFetching: isCompanyByIdFetching,
    refetch: refetchCompanyById,
  } = useFindCompanyByIdQuery(user && user.company, {
    skip: !user || readonly || user.is_superadmin,
  });

  useEffect(() => {
    if (dataCompanyById) {
      setCompanies([{ id: dataCompanyById.id, label: dataCompanyById.name }]);
      form.setFieldsValue({ company: dataCompanyById.name });
    }
  }, [dataCompanyById, form]);

  useEffect(() => {
    if (errorCompanyById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load company',
        okText: 'Retry',
        onOk: refetchCompanyById,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanyById, refetchCompanyById]);

  const [
    createYard,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateYardMutation();
  const [
    updateYard,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateYardMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let cluster = clusters.find((c) => c.label === validData.cluster);
    let company = companies.find((c) => c.label === validData.company);
    let location_master = locationMasters.find(
      (c) => c.label === validData.location_master
    );
    let postData = {
      id: formData && formData.id,
      ...validData,
      cluster: cluster && cluster.id,
      company: company && company.id,
      location_master: location_master && location_master.id,
    };

    if (isCreate) {
      createYard(postData);
    } else {
      updateYard(postData);
    }
  };
  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Code'
        name='code'
        initialValue={formData && formData.code}
        rules={[
          {
            required: true,
            message: 'Please input valid Code',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Name'
        name='name'
        initialValue={formData && formData.name}
        rules={[
          {
            required: true,
            message: 'Please input your Name',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Name (EN)'
        name='name_en'
        initialValue={formData && formData.name_en}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Name (TH)'
        name='name_th'
        initialValue={formData && formData.name_th}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Cluster Code'
        name='cluster'
        initialValue={formData && formData.cluster && formData.cluster.name}
        rules={[
          {
            required: true,
            message: 'Please input cluster',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a cluster code'
          optionFilterProp='children'
          loading={isClustersFetching || isCompanyByIdFetching}
          disabled={!user || !user.is_superadmin}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {clusters &&
            clusters.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Location master'
        name='location_master'
        initialValue={
          formData && formData.location_master && formData.location_master.name
        }
      >
        <Select
          showSearch
          placeholder='Select location master'
          optionFilterProp='children'
          loading={isLocationMastersFetching}
          disabled={!user || !user.is_superadmin}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {locationMasters &&
            locationMasters.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Company'
        name='company'
        initialValue={formData && formData.company && formData.company.name}
        rules={[
          {
            required: true,
            message: 'Please select company',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a company'
          optionFilterProp='children'
          loading={isCompaniesFetching || isCompanyByIdFetching}
          disabled={!user || !user.is_superadmin}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {companies &&
            companies.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='DN Created Code'
        name='dn_created_code'
        initialValue={formData && formData.dn_created_code}
        rules={[
          {
            required: true,
            message: 'Please input your DN Created Code',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Yard Type'
        name='yard_type'
        initialValue={formData && formData.yard_type}
        rules={[
          {
            required: true,
            message: 'Please input your Yard Type',
          },
        ]}
      >
        <Input disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Trailer Total Lane'
        name='trailer_total_lane'
        initialValue={formData && formData.trailer_total_lane}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Trailer Lane 8L'
        name='trailer_lane_8l'
        initialValue={formData && formData.trailer_lane_8l}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
      <Form.Item
        label='Trailer Lane 4L'
        name='trailer_lane_4l'
        initialValue={formData && formData.trailer_lane_4l}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
        />
      </Form.Item>
    </Form>
  );
};

export default FormComponent;
