import React, { useState } from 'react';
import {
  Button,
  Space,
  Typography,
  Collapse,
  Row,
  Card,
  Col,
  Input,
} from 'antd';
import styled from 'styled-components';
import { SearchOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const createPayload = ({ filterCallingNo }) => {
  return {
    calling_no: filterCallingNo ? filterCallingNo : null,
  };
};

const TableToolbar = (props) => {
  const { isLoading, onQuery, showFilter, setShowFilter } = props;
  const [filterCallingNo, setFilterCallingNo] = useState(null);

  const onDisplayButton = () => {
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterCallingNo,
        }),
      });
    }
  };

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Filter Options' key={1}>
          <CardStyleFrom align='center'>
            <Space size={16}>
              <Row gutter={[24]} align='center' justify='center'>
                <Col>
                  <Space direction='horizontal' style={{ marginTop: 7 }}>
                    <>
                      <StyledText>Calling No.</StyledText>
                      <Input
                        style={{
                          width: '165px',
                          align: 'center',
                        }}
                        size='small'
                        placeholder='Calling No.'
                        allowClear
                        value={filterCallingNo}
                        onChange={(e) => {
                          setFilterCallingNo(e.target.value.trim());
                        }}
                      />
                    </>
                  </Space>
                </Col>

                <Col>
                  <Space direction='vertical'>
                    <>
                      <Button
                        type='primary'
                        loading={isLoading}
                        onClick={() => onDisplayButton()}
                        icon={<SearchOutlined />}
                      >
                        Search
                      </Button>
                    </>
                  </Space>
                </Col>
              </Row>
              {/* </Space> */}
            </Space>
          </CardStyleFrom>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;
const Container = styled.div`
  padding: 10px 0;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
const CardStyleFrom = styled(Card)`
  .ant-card-body {
    padding-top: 10px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 0px;
  }
  width: 100%;
  border: 1px solid #d9d9d9;
`;
export default TableToolbar;
