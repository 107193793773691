import React, { useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import './index.css';
import styled from 'styled-components';
import AntModal from 'common_components/AntModal';
import { Typography, Row, Col, Input, Button, Space, InputNumber } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';

const { Text } = Typography;
const TableSubCluster = (props) => {
  const {
    dataSubClusterTable,
    dataSourceSubClusterTableRef,
    getApiCheckManualLot,
    popupConfirm,
    countUnit,
    setCountUnit,
    onSaveApiCreateLot,
    isLoadingGetShowSubCluster,
    isModalConfirmVisible,
    onModalConfirmVisible,
    disabledBtnConfirm,
  } = props;

  const countUnitRef = useRef(1);
  const stdLoadingRef = useRef(0);
  const onChangeUnit = (value, record) => {
    let dataAll = dataSourceSubClusterTableRef.current;
    let objectData = {
      ...record,
      newDataUnit: value,
    };
    var res = dataAll?.map(
      (obj) => [objectData].find((o) => o.cluster.id === obj.cluster.id) || obj
    );

    let arrAddEditRowTable = res.map((item) => ({
      ...item,
    }));
    let count = 0;
    for (let index = 0; index < arrAddEditRowTable.length; index++) {
      if (arrAddEditRowTable[index].newDataUnit) {
        count += arrAddEditRowTable[index].newDataUnit;
      }
    }
    setCountUnit(count);
    checkCount(arrAddEditRowTable);
    dataSourceSubClusterTableRef.current = arrAddEditRowTable;
  };

  useEffect(() => {
    if (dataSubClusterTable) {
      stdLoadingRef.current = dataSubClusterTable?.std_loading;
    }
  }, [dataSubClusterTable]);

  useEffect(() => {
    if (countUnit) {
      countUnitRef.current = countUnit;
    }
  }, [countUnit]);

  const columnsHeader = [
    {
      title: 'To',
      dataIndex: ['cluster', 'name'],
      align: 'center',
      width: '100px',
    },
    {
      title: 'Remain',
      dataIndex: 'remain',
      align: 'center',
      width: '100px',
    },
    {
      title: 'Select',
      dataIndex: 'select',
      align: 'center',
      width: '100px',
      render: (text, record) => {
        return (
          <InputNumber
            onChange={(value) => {
              onChangeUnit(value, record);
            }}
            style={
              countUnitRef.current > record.std_loading
                ? { background: 'red' }
                : null
            }
            defaultValue={record.main === record.cluster?.name ? 1 : null}
            min={record.main === record.cluster?.name ? 1 : 0}
            max={record.remain}
            placeholder={record.main === record.cluster?.name ? 1 : null}
          ></InputNumber>
        );
      },
    },
  ];

  const checkCount = (arr) => {
    let arrTo = arr?.map((d) => ({
      cluster_id: d.cluster?.id,
      unit_count: d.newDataUnit ? d.newDataUnit : d.selectAuto,
    }));
    let pushArrTo = [];
    console.log('arrTo', arrTo);
    if (arrTo.length > 0) {
      for (let index = 0; index < arrTo.length; index++) {
        if (arrTo[index]?.unit_count) {
          pushArrTo.push(arrTo[index]);
        }
      }
    }

    // นำเข้าฟังก์ชัน reduce จากโมดูลของ Array
    const reduce = Array.prototype.reduce;

    // ใช้ฟังก์ชัน reduce เพื่อบวก unit_count ของแต่ละ element ของ array pushArrTo
    const totalUnitCount = reduce.call(
      pushArrTo,
      function (acc, item) {
        console.log('acc', acc);
        return acc + item.unit_count;
      },
      0
    );
    setCountUnit(totalUnitCount);
  };

  const onSaveModal = () => {
    getApiCheckManualLot(dataSourceSubClusterTableRef.current);
  };
  return (
    <Container>
      <Space size={10} style={{ marginBottom: '10px' }}>
        <Space size={10} direction='vertical'>
          <Row>
            <Col>
              <Text>Can matching cluster</Text>
            </Col>
          </Row>

          <Row gutter={[10]}>
            <Col>
              {/* {checkCount()} */}
              <Text style={{ marginRight: '5px' }}>Unit</Text>
              <Input
                value={countUnit}
                disabled
                style={
                  countUnit > dataSubClusterTable.std_loading
                    ? { color: 'red', width: '50px' }
                    : { color: '#000', width: '50px' }
                }
              />
              {' / '}
              <Input
                disabled
                value={dataSubClusterTable.std_loading}
                style={{ width: '50px', color: '#000' }}
              />
            </Col>
            <Col>
              <Button
                disabled={
                  countUnit > dataSubClusterTable.std_loading ||
                  !countUnit ||
                  disabledBtnConfirm
                }
                onClick={onSaveModal}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </Space>
      </Space>
      <AntTable
        rowKey='id'
        tableId={'id'}
        bordered
        showSorterTooltip={false}
        columns={columnsHeader}
        dataSource={dataSubClusterTable?.region}
        scroll={{ y: 300 }}
        pagination={false}
        loading={isLoadingGetShowSubCluster}
      />

      <AntModal
        visible={isModalConfirmVisible}
        onCancel={() => {
          onModalConfirmVisible(false);
        }}
        onOk={() => {
          onModalConfirmVisible(false);
          onSaveApiCreateLot(popupConfirm);
        }}
        titleModal={<Text>Confirm create lot</Text>}
        centered
        width={500}
        style={{ textAlign: 'center' }}
      >
        <>
          <Row align='center'>
            <Col style={{ textAlign: 'right', marginRight: '20px' }}>
              From:
              <br />
              To :
              <br />
              Main :
              <br />
              {popupConfirm?.payload.map((p, index) => {
                return (
                  <div>
                    {index !== 0 ? (
                      <>
                        Sub {index + 0} :<br />
                      </>
                    ) : null}
                  </div>
                );
              })}
              Unit :
              <br />
              Lot time :
            </Col>
            <Col>
              {popupConfirm?.from?.short_name}
              <br />
              {popupConfirm?.to?.name}
              <br />
              {`${popupConfirm?.payload[0]?.name} ${popupConfirm?.payload[0]?.unit_count} unit`}
              <br />
              {popupConfirm?.payload.map((p, index) => {
                return (
                  <div>
                    {index !== 0 ? (
                      <>
                        {p.name} {p.unit_count} unit
                        <br />
                      </>
                    ) : null}
                  </div>
                );
              })}

              {` ${popupConfirm?.total_count} / ${dataSubClusterTable?.std_loading}`}
              <br />
              {popupConfirm?.lot_time
                ? dateTimeTimestamptzFormatter({
                    value: popupConfirm?.lot_time,
                  })
                : null}
            </Col>
          </Row>
        </>
      </AntModal>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableSubCluster;
