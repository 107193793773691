import React, { useState, useEffect } from 'react';
import { useUpdateCriteriaMutation } from 'reducers/criteria/api';
import EditButtonRenderer from 'common_components/EditButtonRenderer';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import {
  Table,
  Input,
  InputNumber,
  Form,
  message,
  Popconfirm,
  Row,
  Col,
  Button,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import 'common_components/AntTable/index.css';
import { useTranslation } from 'react-i18next';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'number' ? (
      <InputNumber min={record.min} max={record.max} />
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ConfigurationTableComponent = (props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState(rowData ? rowData : []);
  const [editingKey, setEditingKey] = useState('');
  const { setErrorData } = useErrorHandler();

  const [
    updateConfiguration,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
    },
  ] = useUpdateCriteriaMutation();

  useEffect(() => {
    if (!isUninitUpdating && isSuccessUpdating) {
      message.success('Data was successfully saved');
    }
  }, [isSuccessUpdating, isUninitUpdating]);
  useEffect(() => {
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, setErrorData]);

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      value: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (id) => {
    try {
      const row = await form.validateFields();

      const newData = [...dataSource];
      const index = newData.findIndex((item) => id === item.id);

      let postData = {
        id: id,
        value: row.value,
      };

      updateConfiguration(postData);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, { ...item, ...row });
        setDataSource(newData);
        setEditingKey('');
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey('');
      }
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };
  const onReload = () => {
    props.refetchData();
  };

  const columns = [
    {
      title: '',
      dataIndex: 'edit',
      align: 'center',
      width: '15%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Popconfirm
              title={t('configuration.titleSave')}
              onConfirm={() => save(record.id)}
            >
              <Button style={{ marginRight: 8 }} type="primary" size="small">
                {t('configuration.okText')}
              </Button>
            </Popconfirm>
            <Popconfirm
              title={t('configuration.titleCancel')}
              onConfirm={cancel}
            >
              <Button size="small"> {t('configuration.cancelText')}</Button>
            </Popconfirm>
          </>
        ) : (
          <EditButtonRenderer onClick={() => edit(record)} />
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'item',
      width: '40%',
      searchable: true,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      width: '20%',
      editable: true,
    },
    {
      title: 'Default',
      dataIndex: 'default',
      width: '25%',
      render: (text, record) => {
        return (
          <h1>
            ({record.min} - {record.max} default: {text})
          </h1>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'value' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Container>
      <Form form={form} component={false}>
        <Row align="middle" style={{ padding: 10 }}>
          <RightAlignedCol offset="12" span="12">
            <Button
              loading={isLoading}
              icon={<ReloadOutlined />}
              size="small"
              onClick={onReload}
            >
              Reload
            </Button>
          </RightAlignedCol>
        </Row>

        <StyledTable
          rowKey="id"
          tableId={'configuration'}
          scroll={{ x: 'max-content' }}
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          loading={isLoading}
          bordered
          dataSource={dataSource}
          columns={mergedColumns}
          pagination={{
            onChange: cancel,
          }}
          showSorterTooltip={false}
          showContextMenu={true}
        />
      </Form>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(Table)`
  &&& {
    /* Prevent scroll-x display when adding resize column*/
    .ant-table > .ant-table-container > .ant-table-content > table {
      min-width: 99% !important;
    }
    tr {
      th {
        padding: 4px;
        background-color: #f0f0f0;
        font-size: 12px;
      }
    }
    tr {
      td {
        padding: 4px;
        font-size: 12px;
      }
    }
    table {
      tr:hover {
        td {
          background-color: #ebf5fb !important;
        }
      }
    }
    table {
      tr:nth-child(2n) {
        td {
          background-color: #fafafa;
        }
      }
    }
  }
`;
const RightAlignedCol = styled(Col)`
  text-align: right;
  margin-left: auto;
`;
export default ConfigurationTableComponent;
