import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Col,
  Row,
  Card,
  message,
  Space,
  Select,
  DatePicker,
} from 'antd';
import {
  useFindStatusChangeAllSelectionQuery,
  statusChangeApi,
  useFindStatusChangeVinDescriptionSelectionDataQuery,
} from 'reducers/statusChange/api';
import styled from 'styled-components';
import { useErrorHandler } from 'common_components/ErrorContext';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { timeFormatter } from 'common_components/AntTable/tableUtils';
import Table from './Table';
import TableDisplay from './TableDisplay';
import { useParams } from 'react-router-dom';
import Title from 'antd/lib/typography/Title';
import FormReason from './FormReason';
import { changeReasonLaneApi } from 'reducers/changeReasonLane/api';
import { receivejobApi } from 'reducers/receivejob/api';
import { yardoutApi } from 'reducers/yardout/api';
import { createTripApi } from 'reducers/createTrip/api';
import { useFindAllCriteriaQuery } from 'reducers/criteria/api';
import { sortByActualReceiveJobDatetime } from './utils';
import { orderBy } from 'lodash';

const { Option } = Select;

const StatusChangeFormComponent = (props) => {
  const { formData } = props;
  const { id_param } = useParams();
  const trailerInputRef = useRef(null);
  const jobDesSelectionRef = useRef(null);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [selcetOptionVin, setSelcetOptionVin] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [dataTableDisplay, setDataTableDisplay] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modeModule, setModeModule] = useState(null);
  const dataSelcetOptionVinRef = useRef(null);
  const statusDiff = useRef();
  const displayBefore = useRef(null);
  const displayAfter = useRef(null);
  const dataCriteriaRef = useRef([]);
  const dataTableRef = useRef();
  const receiveJobTimeFieldRef = useRef();
  const yardOutTimeFieldRef = useRef();
  const arrivalTimeFieldRef = useRef();
  const dataLocationFromToRef = useRef();
  const locationClusterOptionsRef = useRef();
  const locationDestinationOptionsRef = useRef();
  const disableSaveDraftRef = useRef(true);
  const disableBtnSaveRef = useRef(true);
  const requiredFormRef = useRef(false);
  const findDestinationRef = useRef(false);
  const findClusterRef = useRef(false);
  const idJobCode = useRef();
  const rowIdRef = useRef(false);
  const combineRef = useRef(false);
  const combineData = useRef();

  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const dispatch = useDispatch();
  const [callingNoInput, setCallingNoInput] = useState(null);
  const [vinNoInput, setVinNoInput] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [editTableDisabled, setEditTableDisabled] = useState(true);
  // const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [fromOptions, setFromOptions] = useState([]);
  const [toOptions, setToOptions] = useState([]);

  const {
    data: dataSelection,
    error: errorSelection,
    // refetch: refetchSelection,
  } = useFindStatusChangeAllSelectionQuery();

  const {
    data: dataCriteria,
    error: errorCriteria,
    refetch: refetchCriteria,
  } = useFindAllCriteriaQuery();

  useEffect(() => {
    if (dataCriteria) {
      dataCriteriaRef.current = dataCriteria;
    }
  }, [dataCriteria]);

  useEffect(() => {
    if (formData) {
      let payload = {
        working_day:
          formData && formData.working_day
            ? moment(formData.working_day).format('DD/MM/YYYY')
            : undefined,
        job_code: formData && formData.job_code,
      };
      onQuery(payload);
      dataTableRef.current = [formData];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataSelection) {
      setFromOptions(
        dataSelection.from.map((b) => ({
          label: b.short_name,
          value: b.id,
        }))
      );
      setToOptions(
        dataSelection.to.map((b) => ({
          label: b.short_name,
          value: b.id,
        }))
      );
    }
  }, [dataSelection]);

  const createGenarateJobCode = async (value) => {
    let newData = [];

    if (value) {
      let result = await dispatch(
        statusChangeApi.endpoints.statusChangeGenarateJobCode.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          newData = result.data?.jobcode;
        }
      }
    }
    return newData;
  };
  const statusChangeSave = async (value) => {
    let newData = null;

    if (value) {
      let result = await dispatch(
        statusChangeApi.endpoints.updateStatusChange.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          newData = result.data;
        }
      }
    }
    return newData;
  };
  const checkTlepCode = () => {
    if (!dataTableRef.current || dataTableRef.current.length === 0) {
      return true;
    }
    let trailerCodeOptions = dataTableRef.current[0].trailerCodeOptions;
    let driver1Options = dataTableRef.current[0].driverCode1Options;
    let driver2Options = dataTableRef.current[0].driverCode2Options;

    let current_trailer_type = null;
    if (
      dataTableRef.current[0].trailers &&
      dataTableRef.current[0].trailers.length > 0
    ) {
      current_trailer_type = dataTableRef.current[0].trailers[0].trailer_type;
    }
    let trailerType =
      dataTableRef.current[0].trailer_type_edit || current_trailer_type;
    let driver1 =
      dataTableRef.current[0].driversCode1New ||
      dataTableRef.current[0].driversCode1;
    let driver2 =
      dataTableRef.current[0].driversCode2New ||
      dataTableRef.current[0].driversCode2;

    let selectedTrailerType = trailerCodeOptions.find(
      (t) => t.trailer_type.id === trailerType.id
    );
    if (!selectedTrailerType) {
      return true;
    }
    if (driver1 && driver1Options) {
      let selectedDriver1 = driver1Options.find((d) => d.id === driver1.id);
      if (
        selectedTrailerType.trailer_type.code ===
        selectedDriver1.trailer_type_code
      ) {
        return true;
      }
    }
    if (driver2 && driver2Options) {
      let selectedDriver2 = driver2Options.find((d) => d.id === driver2.id);
      if (
        selectedTrailerType.trailer_type.code ===
        selectedDriver2.trailer_type_code
      ) {
        return true;
      }
    }
    return false;
  };

  const checkButton = async (value) => {
    let newData = [];
    try {
      if (value) {
        let result = await dispatch(
          statusChangeApi.endpoints.statusChangeByCheckButton.initiate(value, {
            forceRefetch: true,
          })
        );
        if (result && result.error) {
          Modal.error({
            title: result.error.data.errorList[0].errorMessage,
            icon: <ExclamationCircleOutlined />,
            content: result.error.data.errorList[0].errorDetail,
          });
          disableBtnSaveRef.current = true;
        } else {
          if (result.data) {
            disableBtnSaveRef.current = false;
            Modal.success({
              title: 'Check window time passed!',
              icon: <CheckCircleOutlined />,
            });
            setDataTableDisplay(dataTableRef.current);
          }
        }
      }
    } catch (err) {
      disableSaveDraftRef.current = true;
      console.log('err', err);
    } finally {
      // setIsLoading(false);
      return newData;
    }
  };

  const selectProps = {
    showSearch: true,
    size: 'small',
    style: {
      width: '100%',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoading,
  };

  const onOperationLeadtimes = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          statusChangeApi.endpoints.findOperationLeadtimes.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
    // setDataTable(arrDataTable);
  };
  const onRouteSpeedDistanceETATime = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          statusChangeApi.endpoints.findRouteSpeedDistance.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail, 10);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const onFromField = async (value, id, locationFromToOptions) => {
    rowIdRef.current = id;
    let findFrom = locationFromToOptions.find((d) => d.value === value);
    let arr = {
      actual_from_edit: findFrom && findFrom,
    };
    updateDataTable(arr, id);
  };
  const onReceiveJobTimeField = async (
    value,
    id,
    receive_job_datetime,
    receive_job,
    receive_job_datetime_before,
    row
  ) => {
    refetchCriteria();
    idJobCode.current = id;
    rowIdRef.current = id;
    if (value) {
      receiveJobTimeFieldRef.current = {
        receive_job_datetime: value,
        receive_job_datetime_before: receive_job_datetime_before,
      };

      let callingNo = {
        calling_no: row.callingsheet?.callingsheet_no,
      };
      let callingRow = await onQueryReasonsLane(callingNo);

      let arrEarly = dataCriteriaRef.current.find((val) => val.code === 301);
      let arrLate = dataCriteriaRef.current.find((val) => val.code === 302);

      let setDateTime = value.format('YYYY-MM-DDTHH:mm:ssZ');
      if (
        (callingRow && !callingRow.reason_receive_job) ||
        (callingRow && !callingRow.reason_receive_job.reason) ||
        receive_job_datetime_before !== setDateTime
      ) {
        if (
          moment(value).diff(moment(receive_job_datetime), 'minutes') >=
            arrLate.value &&
          moment(value).diff(moment(receive_job_datetime), 'minutes') <=
            arrEarly.value
        ) {
          setIsModalVisible(false);
        } else if (
          moment(value).diff(moment(receive_job_datetime), 'minutes') >
          arrLate.value
        ) {
          statusDiff.current = 'delay';
          setIsModalVisible(true);
        } else if (
          moment(value).diff(moment(receive_job_datetime), 'minutes') <
          arrEarly.value
        ) {
          statusDiff.current = 'early';
          setIsModalVisible(true);
        }
        setModeModule(receive_job);
      }
      let receiveJobDatetime = {
        actual_receive_job_datetime: value,
        actual_receive_job_datetime_edit: value,
        reason_receive_job: null,
      };

      updateDataTable(receiveJobDatetime, id);
    } else {
      let receiveJobDatetime = {
        actual_receive_job_datetime: value,
        actual_receive_job_datetime_edit: value,
        reason_receive_job: null,
      };
      updateDataTable(receiveJobDatetime, id);
    }
  };
  const onYardOutTimeField = async (
    value,
    id,
    std_lead_time,
    yard_out,
    yardout_datetime_before,
    row
  ) => {
    idJobCode.current = id;
    rowIdRef.current = id;
    if (value) {
      yardOutTimeFieldRef.current = {
        yardout_datetime: value,
        yardout_datetime_before: yardout_datetime_before,
      };

      refetchCriteria();
      let callingNo = {
        calling_no: row.callingsheet?.callingsheet_no,
      };
      let callingRow = await onQueryReasonsLane(callingNo);

      let arrEarly = dataCriteriaRef.current.find((val) => val.code === 501);
      let arrLate = dataCriteriaRef.current.find((val) => val.code === 502);

      let setDateTime = value.format('YYYY-MM-DDTHH:mm:ssZ');
      if (
        (callingRow && !callingRow.reason_yard_out) ||
        (callingRow && !callingRow.reason_yard_out.reason) ||
        yardout_datetime_before !== setDateTime
      ) {
        let estimated = moment(row?.actual_receive_job_datetime).add(
          std_lead_time,
          'minutes'
        );

        if (
          moment(value).diff(moment(estimated), 'minutes') >= arrLate.value &&
          moment(value).diff(moment(estimated), 'minutes') <= arrEarly.value
        ) {
          setIsModalVisible(false);
        } else if (
          moment(value).diff(moment(estimated), 'minutes') > arrLate.value
        ) {
          statusDiff.current = 'delay';
          setIsModalVisible(true);
        } else if (
          moment(value).diff(moment(estimated), 'minutes') < arrEarly.value
        ) {
          statusDiff.current = 'early';
          setIsModalVisible(true);
        }

        setModeModule(yard_out);
      }
      let actualYardoutDatetime = {
        actual_yardout_datetime: value,
        actual_yardout_datetime_edit: value,
        reason_yard_out: null,
      };
      updateDataTable(actualYardoutDatetime, id);
    } else {
      let actualYardoutDatetime = {
        actual_yardout_datetime: value,
        actual_yardout_datetime_edit: value,
        reason_yard_out: null,
      };
      updateDataTable(actualYardoutDatetime, id);
    }
  };

  const onArrivalTimeField = async (id, actual_arrival_time, arrival) => {
    idJobCode.current = id;
    rowIdRef.current = id;
    arrivalTimeFieldRef.current = actual_arrival_time;
    setModeModule(arrival);
  };
  const onTrailersField = async (value, id, trailersOptions, row) => {
    rowIdRef.current = id;
    let findTrailer = trailersOptions.find((d) => d.value === value);
    if (row?.findTrailer?.findTrailer === row?.driversCode1New?.label) {
    }
    let trailers = {
      trailersNew: findTrailer && findTrailer,
      trailer_type_edit: findTrailer?.trailer_type,
    };
    updateDataTable(trailers, id);
  };

  const onTripTypeField = async (value, id, tripTypeOptions) => {
    rowIdRef.current = id;
    let findTripType = tripTypeOptions.find((d) => d.value === value);
    let trip_type = {
      trip_type_edit: findTripType,
    };

    updateDataTable(trip_type, id);
  };
  const onDriverCode1Options = async (
    id,
    value,
    driverOptionsDefault,
    record
  ) => {
    let findDrivers = driverOptionsDefault.filter(
      (d) => d.id !== record.driversCode2?.id
    );
    let drivers1 = {
      driverCode1Options: findDrivers,
    };

    updateDataTable(drivers1, id);
  };

  const onDriverCode2Options = async (
    id,
    value,
    driverOptionsDefault,
    record
  ) => {
    let findDrivers = driverOptionsDefault.filter(
      (d) => d.id !== record.driversCode1?.id
    );
    let drivers2 = {
      driverCode2Options: findDrivers,
    };

    updateDataTable(drivers2, id);
  };
  const tlepErrorModal = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div style={{ color: 'red' }}>
            -Trailer Type is not matching TLEP Type.
          </div>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };
  const onDriverCode1Field = async (value, id, driverCode1Options, row) => {
    rowIdRef.current = id;
    let findDrivers = driverCode1Options.find((d) => d.value === value);
    let trailerLast = row.trailersNew
      ? row.trailersNew.trailer_type?.code
      : row?.trailers.length > 0
      ? row?.trailers[0].trailer_type?.code
      : null;
    if (findDrivers?.trailer_type_code !== trailerLast) {
      tlepErrorModal();
    } else {
      let drivers1 = {
        driversCode1: findDrivers,
        driversCode1New: findDrivers,
      };

      updateDataTable(drivers1, id);
    }
  };

  const onDriverCode2Field = async (value, id, driverOptionsDefault, row) => {
    rowIdRef.current = id;
    let findDrivers = driverOptionsDefault.find((d) => d.value === value);

    let trailerLast = row.trailersNew
      ? row.trailersNew.trailer_type?.code
      : row?.trailers.length > 0
      ? row?.trailers[0].trailer_type?.code
      : null;
    if (findDrivers?.trailer_type_code !== trailerLast) {
      tlepErrorModal();
    } else {
      let drivers2 = {
        driversCode2: findDrivers,
        driversCode2New: findDrivers,
      };
      updateDataTable(drivers2, id);
    }
  };

  const onResetDestinationOption = (id) => {
    let defaultLocationDestinationOptions = {
      cluster: undefined,
      actual_cluster_to: [],
      locationDestinationOptions: locationDestinationOptionsRef.current,
    };

    findClusterRef.current = null;
    updateDataTable(defaultLocationDestinationOptions, id);
  };
  const onResetResetClustrOption = (id) => {
    let defaultLocationClusterOptions = {
      actual_cluster_to: null,
      clusterCode: null,
      locationClusterOptions: locationClusterOptionsRef.current,
    };
    findDestinationRef.current = null;

    updateDataTable(defaultLocationClusterOptions, id);
  };

  const updateDataTable = async (objValue, id_jobcode, vin) => {
    let arrDataTable = [];

    if (vin) {
      arrDataTable = dataTableRef.current
        .filter((item) => item.id === id_jobcode)
        .map((item) => ({
          ...item,
          ...objValue,
          vin_description: { ...item.vin_description, vin_description: vin },
        }));
    } else {
      arrDataTable = dataTableRef.current
        .filter((item) => item.id === id_jobcode)
        .map((item) => ({
          ...item,
          ...objValue,
        }));
    }
    let dataTableNew = dataTableRef.current;
    const index = dataTableRef.current.findIndex(
      (item) => item.id === id_jobcode
    );

    dataTableNew.splice(index, 1, ...arrDataTable);
    dataTableRef.current = [...dataTableNew];
    setDataTable([...dataTableNew]);
    disableBtnSaveRef.current = true;
  };

  const postCheckReturnPlace = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.checkReturnPlace.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const createNewTripNoAndJobCode = async (jobs) => {
    let groupEditKeyTrailerId = {};
    let groupNotEditKeyJobCode = {};

    let isNeedNewJobCode = false;
    let combineJobs = jobs.filter((j) => j.combine !== null);
    if (combineJobs.length > 0) {
      let combineCodes = combineJobs.map((j) => j.combine);
      let uniqueCombineCodes = [...new Set(combineCodes)];
      if (uniqueCombineCodes.length > 0) {
        // Force to always create new job_code
        isNeedNewJobCode = true;
      } else {
        let countTripOfCombineJob = jobs.filter(
          (j) => j.combine === uniqueCombineCodes[0]
        ).length;
        // If only one combine code and
        // trip number of combine job is not equal to total jobs,
        // then need to create new job_code
        if (countTripOfCombineJob !== jobs.length) {
          isNeedNewJobCode = true;
        }
      }
    } else {
      if (jobs.length > 0) {
        // Force to always create new job_code
        isNeedNewJobCode = true;
      }
    }

    if (!isNeedNewJobCode) {
      for (let job of jobs) {
        if (!groupNotEditKeyJobCode[job.job_code]) {
          groupNotEditKeyJobCode[job.job_code] = [];
        }
        groupNotEditKeyJobCode[job.job_code].push(job);
      }
    } else {
      for (let job of jobs) {
        if (job.moved === true) {
          continue;
        }
        if (
          job.trailers &&
          job.trailers.length > 0 &&
          job.trailersNew &&
          job.trailers[0].id !== job.trailersNew.id
        ) {
          // If no any job of the same job_code has the same new trailer_id, then create new job_code
          if (
            !jobs.some(
              (j) =>
                j.job_code === job.job_code &&
                j.trailers[0].id === job.trailersNew.id &&
                j.id !== job.id
            )
          ) {
            if (!groupEditKeyTrailerId[job.trailersNew.id]) {
              groupEditKeyTrailerId[job.trailersNew.id] = [];
            }
            groupEditKeyTrailerId[job.trailersNew.id].push(job);

            //Move same combine job to the same edit group
            //Regardless of changing trailer or not
            if (job.combine) {
              let combinedJobs = jobs.filter(
                (j) => j.combine === job.combine && j.id !== job.id
              );
              for (let cj of combinedJobs) {
                cj.moved = true;
                groupEditKeyTrailerId[job.trailersNew.id].push(cj);
              }

              // Remove previous save on not edit group, if any
              for (let jobCode of Object.keys(groupNotEditKeyJobCode)) {
                let combineJobs = groupNotEditKeyJobCode[jobCode].filter(
                  (j) => j.combine === job.combine
                );
                for (let cj of combineJobs) {
                  let removeIndex = groupNotEditKeyJobCode[jobCode].findIndex(
                    (j) => j.id === cj.id
                  );
                  if (removeIndex !== -1) {
                    groupNotEditKeyJobCode[jobCode].splice(removeIndex, 1);
                  }
                }
              }
            }
          } else {
            if (!groupNotEditKeyJobCode[job.job_code]) {
              groupNotEditKeyJobCode[job.job_code] = [];
            }
            groupNotEditKeyJobCode[job.job_code].push(job);
          }
        } else {
          if (!groupNotEditKeyJobCode[job.job_code]) {
            groupNotEditKeyJobCode[job.job_code] = [];
          }
          groupNotEditKeyJobCode[job.job_code].push(job);
        }
      }
    }
    let isEditTrailer = Object.keys(groupEditKeyTrailerId).length > 0;
    // Resort not edit group by receive job datetime
    for (let jobCode of Object.keys(groupNotEditKeyJobCode)) {
      if (isEditTrailer) {
        groupNotEditKeyJobCode[jobCode] = sortByActualReceiveJobDatetime(
          groupNotEditKeyJobCode[jobCode]
        );
      } else {
        groupNotEditKeyJobCode[jobCode] = groupNotEditKeyJobCode[jobCode].map(
          (d) => ({ ...d, trip_no_new: d.trip_no })
        );
      }
    }

    // Create new job code for group edit
    for (let trailerIdKey of Object.keys(groupEditKeyTrailerId)) {
      if (groupEditKeyTrailerId[trailerIdKey].length > 0) {
        let newJobCode = await requestNewJobCode(
          groupEditKeyTrailerId[trailerIdKey][0]
        );
        for (let job of groupEditKeyTrailerId[trailerIdKey]) {
          job.job_code_new = newJobCode;
        }
        groupEditKeyTrailerId[trailerIdKey] = sortByActualReceiveJobDatetime(
          groupEditKeyTrailerId[trailerIdKey]
        );
      }
    }

    // Union 2 groups and return new jobs
    let newJobs = {};
    for (let jobCode of Object.keys(groupNotEditKeyJobCode)) {
      for (let job of groupNotEditKeyJobCode[jobCode]) {
        let jobCode = job.job_code;
        if (!newJobs[jobCode]) {
          newJobs[jobCode] = [];
        }
        newJobs[jobCode].push(job);
      }
    }
    for (let trailerIdKey of Object.keys(groupEditKeyTrailerId)) {
      for (let job of groupEditKeyTrailerId[trailerIdKey]) {
        let jobCode = job.job_code_new;
        if (!newJobs[jobCode]) {
          newJobs[jobCode] = [];
        }
        newJobs[jobCode].push(job);
      }
    }
    return newJobs;
  };

  const requestNewJobCode = async (job) => {
    let workingDay = form.getFieldValue('working_day');

    let payload = {
      trailer: job?.trailersNew?.id,
      plan_type: job?.plan_type?.id,
      trailer_type: job?.trailersNew?.trailer_type?.id,
      working_day: workingDay ? workingDay.format('YYYY-MM-DD') : null,
      shift: job?.shift,
    };
    let newJobCode = await createGenarateJobCode(payload);
    return newJobCode || job.job_code;
  };

  const onBtnCheck = async (jobs) => {
    try {
      setIsLoading(true);
      if (jobs) {
        let newJobs = await createNewTripNoAndJobCode(jobs);

        // Query return place
        for (let jobCode of Object.keys(newJobs)) {
          let arrReturnPlace = newJobs[jobCode].map((d) => ({
            trip_no: d.trip_no_new,
            location_from: {
              code: d.actual_from_edit
                ? d.actual_from_edit.label
                : d.actual_from.short_name,
            },
          }));

          let payload = {
            dispatch_boards: arrReturnPlace,
          };

          let res = await postCheckReturnPlace(payload);
          for (const resItem of res.result) {
            newJobs[jobCode]
              .filter(
                (j) =>
                  (j.trip_no_new === resItem.trip_no &&
                    j.actual_from.short_name === resItem.location_from.code)
              )
              .forEach((j) => {
                j.actual_return_place = resItem.return_place.code;
              });
          }
        }

        // Flatten new jobs to list
        let newJobsList = [];
        for (let jobCode of Object.keys(newJobs)) {
          for (let job of newJobs[jobCode]) {
            newJobsList.push(job);
          }
        }

        dataTableRef.current = newJobsList;

        // Sort by original order
        let sortData = orderBy(newJobsList, ['job_code', 'trip_no']);
        setDataTable(sortData);

        // Check return place
        let arrDataTable = [];
        arrDataTable = newJobsList.map((item) => ({
          job_code: item.job_code_new || item.job_code,
          trip_no: item.trip_no_new,
          combine: item.combine,
          location_from:
            item.actual_from_edit?.label || item.actual_from?.short_name,
          return_place:
            item.actual_return_place?.name || item.actual_return_place,
        }));
        let bodyCheck = {
          return_places: arrDataTable,
        };

        await checkButton(bodyCheck);
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onQueryReasonsLane = async (value) => {
    let resData = null;
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.findStatusChangeByCallingNo.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
        } else {
          if (result.data) {
            resData = result.data.reason_and_lane;
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
      return resData;
    }
  };

  const onSaveReason = async (value) => {
    try {
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.updateChangeReasonLaneByReason.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail, 10);
        } else {
          if (result.data) {
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const createReceiveJob = async (value) => {
    try {
      if (value) {
        let result = await dispatch(
          receivejobApi.endpoints.createReceiveJobMobile.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail, 10);
        } else {
          if (result.data) {
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const createYardOut = async (value) => {
    try {
      if (value) {
        let result = await dispatch(
          yardoutApi.endpoints.createYardOut.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail, 10);
        } else {
          if (result.data) {
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const finallyConfirmAction = async () => {
    // Actual arrival time null means want to clear data
    let arrDataTableVin = dataTable.map((d) => {
      if (!d.vin_description) {
        return null;
      } else {
        return d.vin_description.vin_description.map((item) => {
          let actual_arrival_time = item.actual_arrival_time;
          if (typeof item.actual_arrival_time_edit !== 'undefined') {
            actual_arrival_time = item.actual_arrival_time_edit;
          }
          if (moment(actual_arrival_time).isValid()) {
            actual_arrival_time = moment(actual_arrival_time).format();
          } else {
            actual_arrival_time = null;
          }

          return {
            id: item.id,
            vin_no: item.vin_no ? item.vin_no : null,
            customer: item.customer_edit
              ? item.customer_edit.id
              : item.customer
              ? item.customer.id
              : null,
            location_to: item.location_destination_edit
              ? item.location_destination_edit.id
              : item.location_to
              ? item.location_to.id
              : null,
            vehicle_type: item.vehicle_type_edit
              ? item.vehicle_type_edit.id
              : item.vehicle_type
              ? item.vehicle_type.id
              : null,
            actual_arrival_time: actual_arrival_time,
            is_invoice_able: item.is_invoice_able,
            is_payment_able: item.is_payment_able,
          };
        });
      }
    });

    let checkArrVin = arrDataTableVin.filter(Boolean);
    let arrVin = [];
    for (let index = 0; index < checkArrVin.length; index++) {
      arrVin.push(...checkArrVin[index]);
    }
    let mapDataTable = dataTable.map((d) => {
      let actual_arrival_time = d.actual_arrival_time;
      if (typeof d.actual_arrival_time_max_date !== 'undefined') {
        actual_arrival_time = d.actual_arrival_time_max_date;
      }
      if (moment(actual_arrival_time).isValid()) {
        actual_arrival_time = moment(actual_arrival_time).format();
      } else {
        actual_arrival_time = null;
      }
      return {
        id: d.id,
        job_code: d.job_code_new || d.job_code,
        trip_no: d.trip_no_new,
        combine: d.combine,
        actual_from: d.actual_from_edit
          ? d.actual_from_edit.id
          : d.actual_from?.id,
        actual_destination: d.actual_destination_edit
          ? d.actual_destination_edit.id
          : d.actual_cluster_to ? d.actual_destination?.id : null,
        actual_return_place:
          d.actual_return_place && d.actual_return_place.id
            ? d.actual_return_place.name
            : d.actual_return_place,
        trailer_type: d.trailer_type_edit
          ? d.trailer_type_edit?.id
          : d.trailer_type
          ? d.trailer_type?.id
          : null,
        trip_type: d.trip_type_edit ? d.trip_type_edit.id : d.trip_type?.id,
        trailer: d.trailersNew
          ? d.trailersNew.id
          : d.trailers.length > 0
          ? d.trailers[0].id
          : null,
        driver_1: d.driversCode1
          ? d.driversCode1.id
          : d.drivers.length > 0
          ? d.drivers[0].id
          : null,
        driver_2: d.driversCode2
          ? d.driversCode2.id
          : d.drivers.length > 1
          ? d.drivers[1].id
          : null,
        actual_receive_job_datetime: d.actual_receive_job_datetime
          ? moment(d.actual_receive_job_datetime).format()
          : null,
        actual_yardout_datetime: d.actual_yardout_datetime
          ? moment(d.actual_yardout_datetime).format()
          : null,
        actual_arrival_time: actual_arrival_time,
      };
    });

    for (let index = 0; index < dataTable.length; index++) {
      if (
        dataTable[index].callingsheet &&
        dataTable[index].callingsheet.callingsheet_no
      ) {
        let callingNo = {
          calling_no: dataTable[index].callingsheet.callingsheet_no,
        };
        let callingRow = await onQueryReasonsLane(callingNo);
        if (
          dataTable[index].actual_receive_job_datetime_before &&
          dataTable[index].actual_receive_job_datetime_edit
        ) {
          if (dataTable[index].reason_receive_job) {
            let objReasonConfirmReceiveJob = {
              mode: 'Receive Job',
              receive_job_and_yard_out_id: callingRow.receive_job_id,
              reason_id: dataTable[index].reason_receive_job.value.id,
              reason_description:
                dataTable[index].reason_receive_job.value.description,
            };
            await onSaveReason(objReasonConfirmReceiveJob);
          }
        } else {
          if (
            dataTable[index].actual_receive_job_datetime_before === null &&
            dataTable[index].actual_receive_job_datetime_edit
          ) {
            let postDriverConfirm = {
              is_mobile: true,
              receive_job_datetime: dataTable[index].receive_job_datetime,
              actual_receive_job_datetime: dataTable[index]
                .actual_receive_job_datetime
                ? moment(dataTable[index].actual_receive_job_datetime).format()
                : null,

              reason: dataTable[index].reason_receive_job?.value.id,
              reason_description:
                dataTable[index].reason_receive_job?.value?.description,

              dispatch_board: dataTable[index].id,
              trailer_lane: null,
            };
            await createReceiveJob(postDriverConfirm);
          }
        }

        if (
          dataTable[index].actual_yardout_datetime_before &&
          dataTable[index].actual_yardout_datetime_edit
        ) {
          if (dataTable[index].reason_yard_out) {
            let objReasonConfirmYardOut = {
              mode: 'Yard Out',
              receive_job_and_yard_out_id: callingRow.yard_out_id,
              reason_id: dataTable[index].reason_yard_out.value.id,
              reason_description:
                dataTable[index].reason_yard_out.value.description,
            };
            await onSaveReason(objReasonConfirmYardOut);
          }
        } else {
          if (
            dataTable[index].actual_yardout_datetime_before === null &&
            dataTable[index].actual_yardout_datetime_edit
          ) {
            let postDriverConfirm = {
              yardout_datetime: dataTable[index].actual_yardout_datetime
                ? moment(dataTable[index].actual_yardout_datetime).format()
                : null,

              reason: dataTable[index].reason_yard_out?.value.id,
              reason_description:
                dataTable[index].reason_yard_out?.value?.description,

              dispatch_board: dataTable[index].id,
              trailer_lane: null,
            };
            await createYardOut(postDriverConfirm);
          }
        }

        if (dataTable[index].reason_arrive) {
          let objReasonConfirmArrive = {
            id: callingRow.realtime_tracking_id,
            reason_id: dataTable[index].reason_arrive.value.id,
            reason_description:
              dataTable[index].reason_arrive.value.description,
            reason_version: callingNo?.reason_arrive?.reason_version,
          };
          await onSaveReasonArrive(objReasonConfirmArrive);
        }
      }
    }

    let payload = {
      job_description: mapDataTable,
      vin_description: arrVin,
    };

    let res = await statusChangeSave(payload);
    if (res) {
      message.success('Data was successfully saved');
      setSelectedRowsData([]);

      // Query get job_description again to update display
      onDisplayButton();
    }
  };

  const onSaveModal = () => {
    let objJobCode = [];
    let objTrip = [];
    let objNewFrom = [];
    let objNewTo = [];
    let objNewTrailers = [];
    let objNewDriver1 = [];
    let objNewDriver2 = [];

    let objJobCodeAfter = [];
    let objTripAfter = [];
    let objNewFromAfter = [];
    let objNewToAfter = [];
    let objNewTrailersAfter = [];
    let objNewDriver1After = [];
    let objNewDriver2After = [];
    for (let index = 0; index < dataTable.length; index++) {
      objJobCode.push(
        dataTable[index].job_code_new || dataTable[index].job_code
      );
      objTrip.push(dataTable[index].trip_no_new.toString());
      objNewFrom.push(dataTable[index].actual_from.name);
      objNewTo.push(dataTable[index].actual_cluster_to?.name);
      objNewTrailers.push(
        dataTable[index].trailers.length > 0
          ? dataTable[index].trailers[0].trailer_card_id
          : false
      );
      objNewDriver1.push(
        dataTable[index].drivers.length > 0
          ? dataTable[index].drivers[0].tlep_code
          : false
      );
      objNewDriver2.push(
        dataTable[index].drivers.length > 1
          ? dataTable[index].drivers[1].tlep_code
          : false
      );

      objJobCodeAfter.push(
        dataTable[index].job_code_new || dataTable[index].job_code
      );
      objTripAfter.push(dataTable[index].trip_no_new.toString());
      objNewFromAfter.push(
        dataTable[index].actual_from_edit &&
          dataTable[index].actual_from_edit.label
          ? dataTable[index].actual_from_edit.label
          : dataTable[index].actual_from.name
      );
      objNewToAfter.push(
        dataTable[index].actual_destination_edit
          ? dataTable[index].actual_destination_edit?.cluster?.name
          : dataTable[index].actual_cluster_to?.name
      );
      objNewTrailersAfter.push(
        dataTable[index].trailersNew
          ? dataTable[index].trailersNew.label
          : dataTable[index].trailers.length > 0
          ? dataTable[index].trailers[0].trailer_card_id
          : false
      );
      objNewDriver1After.push(
        dataTable[index].driversCode1
          ? dataTable[index].driversCode1.tlep_code
            ? dataTable[index].driversCode1.tlep_code
            : dataTable[index].driversCode1.label
          : dataTable[index].drivers.length > 0
          ? dataTable[index].drivers[0].tlep_code
          : false
      );
      objNewDriver2After.push(
        dataTable[index].driversCode2
          ? dataTable[index].driversCode2.tlep_code
            ? dataTable[index].driversCode2.tlep_code
            : dataTable[index].driversCode2.label
          : dataTable[index].drivers.length > 1
          ? dataTable[index].drivers[1].tlep_code
          : false
      );
    }

    let objDisplayBefore = {
      jobCode: objJobCode.filter(Boolean).toString(),
      trip: objTrip.filter(Boolean).toString(),
      from: objNewFrom.filter(Boolean).toString(),
      destination: objNewTo.filter(Boolean).toString(),
      trailers: objNewTrailers.filter(Boolean).toString(),
      driver1: objNewDriver1.filter(Boolean).toString(),
      driver2: objNewDriver2.filter(Boolean).toString(),
    };

    let objDisplayAfter = {
      jobCode: objJobCodeAfter.filter(Boolean).toString(),
      trip: objTripAfter.filter(Boolean).toString(),
      from: objNewFromAfter.filter(Boolean).toString(),
      destination: objNewToAfter.filter(Boolean).toString(),
      trailers: objNewTrailersAfter.filter(Boolean).toString(),
      driver1: objNewDriver1After.filter(Boolean).toString(),
      driver2: objNewDriver2After.filter(Boolean).toString(),
    };

    displayBefore.current = objDisplayBefore;
    displayAfter.current = objDisplayAfter;

    Modal.confirm({
      title: 'Final confirm for saving the edit trip.',
      icon: <ExclamationCircleOutlined />,
      // content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        setTimeout(() => {
          finallyConfirmAction();
        }, 100);
      },
    });
  };

  const onDisplayButton = () => {
    let validData = form.getFieldsValue(true);
    let payload = {
      working_day: validData.working_day
        ? moment(validData.working_day).format('DD/MM/YYYY')
        : null,
      location_from_id: validData.from,
      location_to_id: validData.to,
      job_code: validData.job_code && validData.job_code.trim(),
      calling_no: validData.calling_no && validData.calling_no.trim(),
      vin_no: validData.vin_no && validData.vin_no.trim(),
    };

    onQuery(payload);
  };

  const onQuery = async (payload) => {
    try {
      setIsLoading(true);
      if (payload) {
        let result = await dispatch(
          statusChangeApi.endpoints.findStatusChangeSearch.initiate(payload, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          Modal.error({
            title:
              result &&
              result.error &&
              result.error.data.errorList[0].errorDetail,
            icon: <ExclamationCircleOutlined />,
          });
        } else {
          if (result && result.data) {
            await onQueryJobDescriptionSelection(
              result.data && result.data.job_description
            );
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };
  const { data: dataSelcetOptionVin, refetch: refetchSelcetOptionVin } =
    useFindStatusChangeVinDescriptionSelectionDataQuery({
      skip: !selcetOptionVin,
    });

  useEffect(() => {
    if (dataSelcetOptionVin) {
      dataSelcetOptionVinRef.current = dataSelcetOptionVin;
    }
  }, [dataSelcetOptionVin]);

  const onGetChangeVinDescriptionByCallingId = async (calling_id) => {
    let resData = null;
    try {
      if (calling_id) {
        let result = await dispatch(
          statusChangeApi.endpoints.findStatusChangeVinDescriptionByCalling.initiate(
            calling_id,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let arrFilterDestination =
              dataSelcetOptionVinRef.current.destination.map((d) => ({
                id: d.id,
                label: d.short_name,
                value: d.code,
                code: d.code,
              }));
            let arrFilterCustomer = dataSelcetOptionVinRef.current.customer.map(
              (d) => ({
                id: d.id,
                label: d.code,
                value: d.code,
                code: d.code,
              })
            );
            let arrFilterVehicleType =
              dataSelcetOptionVinRef.current.vehicle_type.map((d) => ({
                id: d.id,
                label: d.name,
                value: d.code,
                code: d.code,
              }));

            let arrDataTable = result.data.vin_description.map((item) => ({
              ...item,
              customerOption: arrFilterCustomer,
              locationDestinationOptions: arrFilterDestination,
              vehicleTypeOptions: arrFilterVehicleType,
              callingNo: result.data.calling_no,
            }));

            resData = {
              calling_no: result.data.calling_no,
              total_unit: result.data.total_unit,
              vin_description: arrDataTable,
            };
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onQueryJobDescriptionSelection = async (jobSearch) => {
    try {
      setIsLoading(true);
      let result = await dispatch(
        statusChangeApi.endpoints.findStatusChangeAllJobDescriptionSelection.initiate(
          {
            forceRefetch: true,
          }
        )
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result && result.data) {
          jobDesSelectionRef.current = result.data;
          const from = result.data.from.map((b) => ({
            id: b.id,
            label: b.short_name,
            value: b.code,
            name: b.name,
            code: b.code,
          }));

          const trip_type = result.data.trip_type.map((b) => ({
            id: b.id,
            label: b.name_en,
            value: b.id,
          }));
          const trailer_code = result.data.trailer_code.map((b) => ({
            id: b.id,
            label: b.trailer_card_id,
            value: b.id,
            maximum_loading: b.trailer_type?.maximum_loading,
            trailer_type: b?.trailer_type,
          }));
          const driver_code_1 = result.data.driver_code_1.map((b) => ({
            id: b.id,
            label: b.tlep_code,
            value: b.id,
            trailer_type_code: b.trailer_type?.code,
          }));
          const driver_code_2 = result.data.driver_code_2.map((b) => ({
            id: b.id,
            label: b.tlep_code,
            value: b.id,
            trailer_type_code: b.trailer_type?.code,
          }));

          let arrDataTable = [];
          arrDataTable =
            jobSearch &&
            jobSearch.map((item) => ({
              ...item,
              editingRow: true,
              locationFromToOptions: from,
              tripTypeOptions: trip_type,
              trailerCodeOptions: trailer_code,
              driverCode1Options: driver_code_1,
              driverCode2Options: driver_code_2,
              driverOptionsDefault: driver_code_1,
              driversCode1: item.drivers.length > 0 ? item.drivers[0] : null,
              driversCode2: item.drivers.length > 1 ? item.drivers[1] : null,
              actual_return_place_before: item.actual_return_place,
              actual_receive_job_datetime_before:
                item.actual_receive_job_datetime,
              actual_yardout_datetime_before: item.actual_yardout_datetime,
              actual_arrival_time_before: item.actual_arrival_time,
              actual_from_before: item.actual_fro?.name,
              actual_cluster_to_before: item.actual_cluster_to?.name,
              combine_before: item?.combine,
            }));

          setSelcetOptionVin(true);
          refetchSelcetOptionVin();

          for (let jobData of arrDataTable) {
            if (jobData?.callingsheet?.id) {
              let callingNoId = jobData.callingsheet.id;
              let callingRow = await onGetChangeVinDescriptionByCallingId(
                callingNoId
              );
              jobData['vin_description'] = callingRow;
            }
          }

          dataLocationFromToRef.current = from;
          dataTableRef.current = [...arrDataTable];
          setDataTable([...arrDataTable]);
          setDataTableDisplay([]);
          disableBtnSaveRef.current = true;
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = (status, id_jobCode) => {
    setIsModalVisible(status);
    let actual_datetime = {
      actual_receive_job_datetime_temp: null,
      actual_yardout_datetime_temp: null,
      actual_arrival_datetime_temp: null,
    };
    updateDataTable(actual_datetime, id_jobCode);
  };

  const postReason = (value, id_jobCode) => {
    let reasonLabel = {
      label: value?.subject?.substring(1, 4),
      value: value,
    };
    if (value.mode_module === 'Receive Job') {
      let data = {
        reason_receive_job: reasonLabel,
      };
      updateDataTable(data, id_jobCode);
    } else if (value.mode_module === 'Yard Out') {
      let data = {
        reason_yard_out: reasonLabel,
      };
      updateDataTable(data, id_jobCode);
    } else if (value.mode_module === 'Arrival') {
      let data = {
        reason_arrival: reasonLabel,
      };
      updateDataTable(data, id_jobCode);
    }

    if (receiveJobTimeFieldRef.current?.receive_job_datetime) {
      let receiveJobDatetime = {
        actual_receive_job_datetime_temp: null,
        actual_receive_job_datetime:
          receiveJobTimeFieldRef.current?.receive_job_datetime,
        actual_receive_job_datetime_edit:
          receiveJobTimeFieldRef.current?.receive_job_datetime,
        receive_job_datetime_before:
          receiveJobTimeFieldRef.current?.receive_job_datetime_before,
      };
      updateDataTable(receiveJobDatetime, id_jobCode);
      idJobCode.current = null;
      rowIdRef.current = null;
      receiveJobTimeFieldRef.current = null;
    }

    if (yardOutTimeFieldRef.current?.yardout_datetime) {
      let yardOutDatetime = {
        actual_yardout_datetime_temp: null,
        actual_yardout_datetime: yardOutTimeFieldRef.current?.yardout_datetime,
        actual_yardout_datetime_edit:
          yardOutTimeFieldRef.current?.yardout_datetime,
        yardout_datetime_before:
          yardOutTimeFieldRef.current?.yardout_datetime_before,
      };
      updateDataTable(yardOutDatetime, id_jobCode);
      idJobCode.current = null;
      rowIdRef.current = null;
      yardOutTimeFieldRef.current = null;
    }

    if (arrivalTimeFieldRef.current) {
      let arrivalDatetime = {
        actual_arrival_datetime_temp: null,
        actual_arrival_datetime: arrivalTimeFieldRef.current,
        actual_arrival_datetime_edit: arrivalTimeFieldRef.current,
      };
      updateDataTable(arrivalDatetime, id_jobCode);
      idJobCode.current = null;
      rowIdRef.current = null;
      arrivalTimeFieldRef.current = null;
    }
  };

  const onSaveReasonArrive = async (value) => {
    try {
      if (value) {
        // TODO: Currently no updateReason api implementation --> Do nothing
        let result = await dispatch(
          changeReasonLaneApi.endpoints.updateReason.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail, 10);
        } else {
          if (result.data) {
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const onSavePopupVin = async (value, id_jobCode, id_vin_temp) => {
    try {
      rowIdRef.current = id_jobCode;
      let location_from = null;
      let location_to = [];
      for (let index = 0; index < value.length; index++) {
        if (
          value[index].location_to ||
          value[index].location_destination_edit
        ) {
          location_from = value[index].location_from.id;
          location_to.push(
            value[index].location_destination_edit
              ? value[index].location_destination_edit.id
              : value[index].location_to.id
          );
        }
      }

      let bodyLeadTime = {
        location_from: location_from,
        location_to: location_to,
      };
      let leadTime = await onRouteSpeedDistanceETATime(bodyLeadTime);

      let momentsEdit = value.map((d) => {
        if (
          d?.actual_arrival_time_edit === 'clear' ||
          d?.actual_arrival_time_clear === 'clear'
        ) {
          return undefined;
        }

        if (typeof d?.actual_arrival_time_edit !== 'undefined') {
          return moment(d?.actual_arrival_time_edit);
        }
        if (d?.actual_arrival_time) {
          return moment(d?.actual_arrival_time);
        }
        return undefined;
      });

      let checkDateTime = momentsEdit.filter(Boolean);
      let maxDateEdit =
        checkDateTime.length > 0 ? moment.max(checkDateTime) : null;

      // If any actual arrival time is null, set max to null
      maxDateEdit = momentsEdit.some((d) => Boolean(d) === false)
        ? null
        : maxDateEdit;

      let data = {
        idJobCodeRef: id_jobCode,
        id_vin_temp: id_vin_temp,
        actual_arrival_time_max_date:
          maxDateEdit && maxDateEdit.isValid() ? maxDateEdit : null,
        actual_destination_edit: leadTime ? leadTime.location_to : null,
      };
      let arrVin = value.map((item) => {
        let actual_arrival_time = undefined;
        if (
          item.actual_arrival_time_edit === 'clear' ||
          item.actual_arrival_time_clear === 'clear'
        ) {
          actual_arrival_time = undefined;
        } else {
          if (item.actual_arrival_time_edit) {
            actual_arrival_time = item.actual_arrival_time_edit;
          } else {
            actual_arrival_time = item.actual_arrival_time;
          }
        }
        return {
          ...item,
          actual_arrival_time,
        };
      });
      await updateDataTable(data, id_jobCode, arrVin);
    } catch (err) {
      console.log('err', err);
    }
    // setSavePopupVin(value);
  };
  const createGenarateCombineCode = async () => {
    let newData = null;
    let result = await dispatch(
      createTripApi.endpoints.createGenarateCombineCode.initiate(null, {
        forceRefetch: true,
      })
    );
    if (result && result.error) {
      setErrorData(result.error);
    } else {
      if (result.data) {
        newData = result.data.combine_code;
      }
    }
    return newData;
  };

  const confirmCombine = async () => {
    combineRef.current = true;
    let genarateCombineCode = await createGenarateCombineCode();

    const combine = [...dataTable];
    for (let index = 0; index < combine.length; index++) {
      for (let index2 = 0; index2 < selectedRowsData.length; index2++) {
        if (
          (combine[index].trip === selectedRowsData[index2].trip &&
            combine[index].combine_before === null) ||
          (combine[index].trip === selectedRowsData[index2].trip &&
            combine[index].combine === null &&
            combine[index]?.combine_before)
        ) {
          combine[index].trip = 1;
          combine[index].trip_no_original = index + 1;
          combine[index].combine = genarateCombineCode;
        }
      }
    }

    let newCombine = combine.filter((d) => d.combine);
    let objTrip = [];
    let objNewCombineFrom = [];
    let objNewCombineTo = [];
    let objNewCombineReceiveTime = [];
    for (let index = 0; index < newCombine.length; index++) {
      objTrip.push(newCombine[index].trip_no);
      objNewCombineFrom.push(newCombine[index]?.actual_from?.short_name);
      objNewCombineTo.push(newCombine[index].actual_cluster_to?.name);
      objNewCombineReceiveTime.push(
        timeFormatter({
          value: newCombine[index].receive_job_datetime,
        })
      );
    }
    let displayCombine = {
      trip: [...new Set(objTrip)].toString(),
      from: objNewCombineFrom.toString(),
      to: objNewCombineTo.toString(),
      receive_job_datetime: objNewCombineReceiveTime.toString(),
    };

    combineData.current = displayCombine;
    disableSaveDraftRef.current = true;
    disableBtnSaveRef.current = true;
    setDataTable(combine);
  };
  const confirmCancelCombine = () => {
    combineData.current = null;
    combineRef.current = false;
    const combine = [...dataTable];
    for (let index2 = 0; index2 < selectedRowsData.length; index2++) {
      let valueCombine1 = selectedRowsData[index2]?.combine;
      for (let index = 0; index < dataTable.length; index++) {
        let valueCombine2 = combine[index]?.combine;
        if (valueCombine1 === valueCombine2) {
          combine[index].trip = index + 1;
          combine[index].trip_no_original = index + 1;
          combine[index].combine = null;
          combine[index].first_combine = false;
        }
      }
    }

    dataTableRef.current = combine;
    disableSaveDraftRef.current = true;
    disableBtnSaveRef.current = true;
    setDataTable(combine);
    setSelectedRowsData([]);
  };
  const popupCombine = () => {
    Modal.confirm({
      title: 'Confirm Combine Trip',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk: confirmCombine,
      cancelText: 'Cancel',
    });
  };
  const cancelCombine = () => {
    Modal.confirm({
      title: 'Cancel Combine Trip',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk: confirmCancelCombine,
      cancelText: 'Cancel',
    });
  };

  const checkSelectdRowsData = (dataTable) => {
    let objCombineCheck = true;
    for (let index = 0; index < dataTable.length; index++) {
      if (dataTable[index]?.combine && dataTable.length >= 2) {
        objCombineCheck = false;
      }
    }
    return objCombineCheck;
  };

  return (
    <>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{ padding: '20px' }}
        onFinish={onDisplayButton}
        autoComplete='off'
      >
        <CardStyleForm>
          <Row>
            <Col span={21}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label='Working date'
                    name='working_day'
                    initialValue={
                      formData && moment(formData && formData.working_day)
                    }
                    rules={[
                      {
                        required: callingNoInput ? false : true,
                        message: 'Please select working date',
                      },
                    ]}
                  >
                    <DatePicker
                      disabled={id_param}
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        color: '#000',
                      }}
                      size='small'
                      format={'DD/MM/YY'}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Job Code'
                    name='job_code'
                    initialValue={formData && formData.job_code}
                  >
                    <Input
                      disabled={id_param}
                      size='small'
                      placeholder={'Job Code'}
                      ref={trailerInputRef}
                      style={{ color: '#000' }}
                      onChange={() => {
                        setVinNoInput(null);
                        form.setFieldsValue({
                          vin_no: null,
                        });
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Calling no.' name='calling_no'>
                    <Input
                      size='small'
                      style={{ color: '#000' }}
                      placeholder={'Calling no.'}
                      onChange={(val) => {
                        setCallingNoInput(val.target.value.trim());
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item label='Vin no.' name='vin_no'>
                    <Input
                      size='small'
                      style={{ color: '#000' }}
                      onChange={(val) => {
                        setVinNoInput(val.target.value.trim());
                        form.setFieldsValue({
                          job_code: null,
                        });
                      }}
                      placeholder={'Vin no.'}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='From'
                    name='from'
                    rules={[
                      {
                        required: vinNoInput ? true : false,
                        message: 'Please select from',
                      },
                    ]}
                  >
                    <Select
                      {...selectProps}
                      placeholder={'From'}
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {fromOptions.map((c, index) => (
                        <Option key={index} value={c.value}>
                          {c.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='To'
                    name='to'
                    rules={[
                      {
                        required: vinNoInput ? true : false,
                        message: 'Please select to',
                      },
                    ]}
                  >
                    <Select
                      {...selectProps}
                      placeholder={'To'}
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {toOptions.map((c, index) => (
                        <Option key={index} value={c.value}>
                          {c.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <Col>
                <Form.Item wrapperCol={{ offset: 3 }} shouldUpdate>
                  {() => (
                    <Space
                      size={25}
                      style={{ marginLeft: '10px', marginTop: '52px' }}
                    >
                      <Button
                        type='primary'
                        htmlType='submit'
                        style={{ width: 100 }}
                        icon={<SearchOutlined />}
                      >
                        Search
                      </Button>
                    </Space>
                  )}
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </CardStyleForm>

        <Row style={{ margin: 10, marginBottom: 15 }}>
          <Col flex={2} style={{ textAlign: 'right' }}>
            <Space size={25}>
              <Button
                style={{ width: 100 }}
                type='primary'
                onClick={() => onBtnCheck(dataTable)}
                loading={isLoading}
                disabled={!dataTable.length > 0}
              >
                Check
              </Button>

              <Button
                loading={isLoading}
                style={{ width: 100 }}
                disabled={disableBtnSaveRef.current}
                type='secondary'
                onClick={onSaveModal}
              >
                Save
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Title level={5} style={{ margin: 10, marginBottom: 15 }}>
        {' '}
        Job description{' '}
      </Title>

      <Col flex={2} style={{ marginLeft: 10, marginBottom: 15 }}>
        <Space size={25}>
          {selectedRowsData.length >= 2 &&
          checkSelectdRowsData(selectedRowsData) ? (
            <Button
              loading={isLoading}
              style={{ width: 100 }}
              type='secondary'
              onClick={() => popupCombine()}
              disabled={
                combineData.current && selectedRowsData.find((d) => d.combine)
              }
            >
              Combine
            </Button>
          ) : null}
          {selectedRowsData.find((d) => d.combine) ? (
            <Button
              loading={isLoading}
              type='danger'
              onClick={() => cancelCombine()}
            >
              Cancel combine
            </Button>
          ) : null}
        </Space>
      </Col>
      <Table
        arrivalTimeField={onArrivalTimeField}
        editTableDisabled={editTableDisabled}
        setSelectedRowsData={setSelectedRowsData}
        selectedRowsData={selectedRowsData}
        requiredForm={requiredFormRef.current}
        resetDestinationOption={onResetDestinationOption}
        resetClustrOption={onResetResetClustrOption}
        fromField={onFromField}
        receiveJobTimeField={onReceiveJobTimeField}
        yardOutTimeField={onYardOutTimeField}
        trailersField={onTrailersField}
        tripTypeField={onTripTypeField}
        driverCode1Field={onDriverCode1Field}
        driverCode1Options={onDriverCode1Options}
        driverCode2Options={onDriverCode2Options}
        driverCode2Field={onDriverCode2Field}
        rowData={dataTable}
        isLoading={isLoading}
        savePopupVin={onSavePopupVin}
      />
      {dataTableDisplay.length > 0 ? (
        <>
          {' '}
          <Title level={5} style={{ margin: 10, marginBottom: 15 }}>
            {' '}
            Revise Job description{' '}
          </Title>
          <TableDisplay rowData={dataTableDisplay} isLoading={isLoading} />
        </>
      ) : null}

      <FormReason
        id_jobCode={idJobCode.current}
        modeModule={modeModule}
        showModal={isModalVisible}
        setIsModalVisible={handleCancel}
        postReason={postReason}
        statusDiff={statusDiff.current}
      />
    </>
  );
};

const CardStyleForm = styled(Card)`
  .ant-card-body {
    padding-bottom: 0px;
  }
  .ant-picker-input > input[disabled] {
    color: #000;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #000;
  }
`;

export default StatusChangeFormComponent;
