const Json2CSV = function (objArray, filename, delim) {
  var getKeys = function (obj) {
      var keys = [];
      for (var key in obj) {
        keys.push(key);
      }
      return keys.join(delim || '\t');
    },
    array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray,
    str = '';
  for (var i = 0; i < array.length; i++) {
    var line = '';
    for (var index in array[i]) {
      if (line !== '') line += delim || '\t';
      if (array[i][index] != null) {
        line += array[i][index];
      } else {
        line += '';
      }
    }
    const escaped_line = line.replace('#', '')
    str += escaped_line + '\r\n';
  }

  str = getKeys(objArray[0]) + '\r\n' + str;

  var a = document.createElement('a');
  a.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(str)
  );
  // var blob = new Blob([str], { type: 'application/octet-stream' });
  // a.href = window.URL.createObjectURL(blob);
  a.download = filename;
  a.click();
  return true;
};

export default Json2CSV;
