import React, { Component } from 'react';
import { Polyline } from '@react-google-maps/api';
import { connect } from 'react-redux';

import { MAP_SELECT_CANDIDATE, MAP_SELECT_ROUTE } from 'reducers/map';

class ImPolyline extends Component {
  routeClick = (id) => {
    if (this.props.routeType === 'route_candidate') {
      this.props.MAP_SELECT_CANDIDATE(this.props.id);
    } else if (this.props.routeType === 'route_master') {
      this.props.MAP_SELECT_ROUTE(this.props.id);
    } else {
      // Do nothing
    }
  };

  render() {
    const id = this.props.id;
    let selected = false;

    if (
      this.props.routeType === 'route_candidate' &&
      this.props.routeCandidate_selecting === id
    ) {
      selected = true;
    }

    if (
      this.props.routeType === 'route_master' &&
      this.props.routeMaster_selecting === id
    ) {
      selected = true;
    }

    if (this.props.marker_selecting === id) {
      selected = true;
    }

    if (this.props.suggestion_selecting === id) {
      selected = true;
    }

    let strokeWeight = 4;
    if (this.props.path_onMouseOver === id) {
      strokeWeight = 7;
    }

    const normalSymbol = {
      zIndex: 0,
      strokeColor: '#808080',
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: [],
    };

    // strokeColor: "red",
    const higlightSymbol = {
      zIndex: 1,
      strokeColor:
        this.props.routeType === 'route_master' ? '#1E90FF' : '#7F00FF',
      strokeOpacity: 1,
      strokeWeight: strokeWeight,
      icons: [],
    };

    return (
      <React.Fragment>
        <Polyline
          {...this.props}
          onClick={() => this.routeClick(this.props.id)}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          options={selected ? higlightSymbol : normalSymbol}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routeCandidate_selecting: state.map.routeCandidate_selecting,
    routeMaster_selecting: state.map.routeMaster_selecting,
    marker_selecting: state.map.routeCandidate_selecting,
    suggestion_selecting: state.map.suggestion_selecting,
  };
};

const mapActionsToProps = {
  MAP_SELECT_CANDIDATE: MAP_SELECT_CANDIDATE,
  MAP_SELECT_ROUTE: MAP_SELECT_ROUTE,
};

export default connect(mapStateToProps, mapActionsToProps)(ImPolyline);
