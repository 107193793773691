import React, { useState, useEffect } from 'react';
import {
  Layout,
  Typography,
  Avatar,
  Dropdown,
  Menu,
  Modal,
  Button,
  Badge,
  Space,
  message,
  Row,
  Col,
  Card,
} from 'antd';
import AntTable from 'common_components/AntTable';
import styled from 'styled-components';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  ExclamationCircleOutlined,
  DownOutlined,
  BellOutlined,
  CustomerServiceFilled,
  ZoomInOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getSidebarOpen, setSidebarVisibility } from '../reducers/ui';
import { useHistory, useLocation } from 'react-router-dom';
import Breadcrumbs from './Breadcrumbs';
import {
  logoutUser,
  useFindAllCreateTripNotifyQuery,
  useFindAllNotifyByUserQuery,
  useUpdateStatusDraftMutation,
  rootApi,
} from '../reducers/user/api';
import { getCurrentUser } from '../reducers/user';
import { getBreadcrumbs } from '../reducers/ui';
import { ReactComponent as ThaiSvg } from '../assets/flags/TH.svg';
import { ReactComponent as UsSvg } from '../assets/flags/USA.svg';
import { useTranslation } from 'react-i18next';
import { setLanguage, getLanguage } from '../reducers/language';
import AppLogoImage from '../assets/images/TTT_mini_logo.jpg';
import { useErrorHandler } from 'common_components/ErrorContext';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import useWindowSize from 'hooks/windowSize';
import permission from 'permissions';
import moment from 'moment';
import NotifyTripIsAckComponent from './NotifyTripIsAck';

const { Header } = Layout;
const { Text, Link } = Typography;

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const collapsed = useSelector(getSidebarOpen);
  const user = useSelector(getCurrentUser);
  const crumbs = useSelector(getBreadcrumbs);
  const location = useLocation();
  const language = useSelector(getLanguage);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [isModalConfirmAgainVisible, setIsModalConfirmAgainVisible] =
    useState(false);
  const [dataSource, setDataSource] = useState(null);
  const [idDispatchBoard, setIdDispatchBoard] = useState(null);
  const [onClickNotifiy, setOnClickNotifiy] = useState(false);
  const [isDetail, setIsDetail] = useState(false);
  const [superadmin, setSuperadmin] = useState(false);

  const toggle = () => {
    dispatch(setSidebarVisibility(!collapsed));
  };

  useEffect(() => {
    if (user) {
      setSuperadmin(user.is_superadmin);
    }
  }, [user]);

  const onCheckPayment = () => {
    let isPayment = false;

    // Only payment permission contains actions '1' (active) or '0' (inactive)
    isPayment = user?.privileges.some((p) => p.actions.some((a) => a === '1'));

    if (
      (permission.checkPermission(user, 'driver_confirm_message', ['l']) &&
        superadmin === false &&
        isPayment !== true) ||
      (user?.company_job_name !== 'TTT' && isPayment !== true) ||
      isPayment === true ||
      !permission.checkPermission(user, 'dispatch_board', ['l'])
    ) {
      return true;
    }

    return false;
  };

  // Query how many trip impact notification numbers
  const {
    data,
    error,
    refetch: refetchNotifyByUser,
  } = useFindAllNotifyByUserQuery(null, {
    skip: onCheckPayment(),
    pollingInterval: 60000, //1 min
  });

  const [tableHeight, setTableHeight] = useState(0);
  const { height } = useWindowSize();

  useEffect(() => {
    if (height) {
      let h = height - 420;
      setTableHeight(h);
    }
  }, [height]);

  useEffect(() => {
    if (isModalConfirmVisible) {
      refetchNotifyByUser();
    }
  }, [isModalConfirmVisible, refetchNotifyByUser]);

  const {
    data: notifyData,
    error: ErrorNotifyData,
    isFetching: isFetchingNotifyData,
    refetch: refetchNotifyData,
  } = useFindAllCreateTripNotifyQuery(null, {
    skip: !onClickNotifiy || onCheckPayment(),
    pollingInterval: 60000, //1 min
  });

  // const [
  //   updateViewNotify,
  //   {
  //     isSuccess: isSuccessUpdatingViewNotify,
  //     isUninitialized: isUninitUpdatingViewNotify,
  //     error: errorUpdatingViewNotify,
  //   },
  // ] = useUpdateNotifyByUserMutation();

  const [
    upDateStatusDraft,
    {
      isSuccess: isSuccessUpdateStatusDraft,
      isUninitialized: isUninitUpdateStatusDraft,
      error: errorUpdateStatusDraft,
      isLoading: isUpdateStatusDraft,
    },
  ] = useUpdateStatusDraftMutation();

  // useEffect(() => {
  //   if (errorUpdatingViewNotify) {
  //     setErrorData(errorUpdatingViewNotify);
  //   }
  // }, [setErrorData, errorUpdatingViewNotify]);

  // useEffect(() => {
  //   if (!isUninitUpdatingViewNotify && isSuccessUpdatingViewNotify) {
  //     refetchNotifyByUser();
  //   }
  // }, [dispatch, isSuccessUpdatingViewNotify, isUninitUpdatingViewNotify]);

  useEffect(() => {
    if (errorUpdateStatusDraft) {
      setErrorData(errorUpdateStatusDraft);
    }
  }, [setErrorData, errorUpdateStatusDraft]);

  useEffect(() => {
    if (!isUninitUpdateStatusDraft && isSuccessUpdateStatusDraft) {
      if (isSuccessUpdateStatusDraft) {
        message.success('Data was successfully saved');
        setIsModalConfirmAgainVisible(false);
        refetchNotifyData();
      }
    }
  }, [
    dispatch,
    isSuccessUpdateStatusDraft,
    isUninitUpdateStatusDraft,
    refetchNotifyData,
  ]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  useEffect(() => {
    if (ErrorNotifyData) {
      setErrorData(ErrorNotifyData);
    }
  }, [ErrorNotifyData, setErrorData]);

  // Show notification when having
  useEffect(() => {
    if (location?.pathname.includes('delivery_mng/lists')) {
      // Set true to start query notification data
      setOnClickNotifiy(true);

      if (notifyData) {
        let now = moment();
        let isPopup = notifyData.some((d) => {
          if (!d.after_receive_job_datetime || d.status === 'closed') {
            return false;
          }
          let notifyMoment = moment(d.after_receive_job_datetime);
          let diffHours = now.diff(notifyMoment, 'hours');

          // If now is close to receive job datetime less than 2 hours
          // Display notification modal automatically
          if (diffHours > -2) {
            return true;
          } else {
            return false;
          }
        });
        setIsModalConfirmVisible(isPopup);

        for (let index = 0; index < notifyData.length; index++) {
          if (
            notifyData[index]?.topic === 'Cancel Calling' &&
            notifyData[index].is_view === false
          ) {
            Modal.info({
              content: (
                <div style={{ textAlign: 'center' }}>
                  {notifyData[index]?.popup?.map((item) => (
                    <p key={item}>{item}</p>
                  ))}
                </div>
              ),
              width: 500,
              // onOk() {
              //   let body = { id: notifyData[index]?.id, is_view: true };
              //   updateViewNotify(body);
              // },
            });
          }
        }
      }
    } else {
      setOnClickNotifiy(false);
    }
  }, [location?.pathname, notifyData]);

  const menu = (user, dispatch) => (
    <Menu>
      <Menu.Item key={0}>{user.username}</Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={1}
        onClick={() => {
          Modal.confirm({
            title: t('modal.confirm'),
            icon: <ExclamationCircleOutlined />,
            content: t('logout.logout-confirm'),
            okText: t('modal.confirm'),
            cancelText: t('modal.cancel'),
            onOk: () => {
              history.push('/login');
              setTimeout(() => {
                dispatch(logoutUser());
              }, 100);
            },
          });
        }}
      >
        {t('logout.text')}
      </Menu.Item>
    </Menu>
  );

  const openModalConfirm = async (id, detail) => {
    if (detail) {
      setIsDetail(true);
    } else {
      setIsDetail(false);
    }
    // let body = { id: id, is_view: true };
    // updateViewNotify(body);
    setIdDispatchBoard(id);
    let newData = [];
    try {
      let result = await dispatch(
        rootApi.endpoints.findDraftDispatchBoardById.initiate(id)
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          setIsModalConfirmVisible(true);
          newData = result.data;
          setDataSource(result.data);
        }
      }
    } catch (e) {
      setIsModalConfirmVisible(false);
      console.log('e', e);
    } finally {
      return newData;
    }
  };

  const onModalNotification = () => {
    setOnClickNotifiy(true);
    setIsModalConfirmVisible(true);
  };

  const handleLanguageMenuClick = (event) => {
    if (event.key === 'th') {
      dispatch(setLanguage('th'));
      i18n.changeLanguage('th');
    } else {
      dispatch(setLanguage('en'));
      i18n.changeLanguage('en');
    }
  };

  const upDateStatusDraftAction = (key) => {
    try {
      let payload;
      if (key.target.innerText === 'Accept') {
        payload = {
          draft_status: 'accept',
          id: idDispatchBoard,
        };
      } else if (key.target.innerText === 'Reject') {
        payload = {
          draft_status: 'reject',
          id: idDispatchBoard,
        };
      }
      upDateStatusDraft(payload);
    } catch (error) {
      console.log('error', error);
    }
  };
  const languageMenu = (
    <Menu onClick={handleLanguageMenuClick}>
      <Menu.Item key='th' icon={<ThaiSvg />}>
        {t('language.thai')}
      </Menu.Item>
      <Menu.Item key='en' icon={<UsSvg />}>
        {t('language.english')}
      </Menu.Item>
    </Menu>
  );

  const [columnsPopupConfirm] = useState(() => {
    let cols = [
      {
        title: 'Notice Date/Time',
        dataIndex: 'notify_datetime',
        width: 100,
        align: 'center',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },

      {
        title: 'Receive job before change',
        dataIndex: 'before_receive_job_datetime',
        width: 100,
        align: 'center',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },

      {
        title: 'Receive job after change',
        dataIndex: 'after_receive_job_datetime',
        width: 100,
        align: 'center',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Edit by',
        dataIndex: ['edit_by', 'yard', 'name'],
        width: 80,
        align: 'center',
      },
      {
        title: 'Effect to yard',
        dataIndex: ['effect_to_yard', 'name'],
        width: 80,
        align: 'center',
      },
      {
        title: 'Topic',
        dataIndex: 'topic',
        width: 140,
        align: 'center',
      },
      {
        title: 'Detail',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        render: (text, record) => {
          return (
            <Link
              onClick={() => {
                if (record.topic === 'Cancel Calling') {
                  Modal.info({
                    content: (
                      <div style={{ textAlign: 'center' }}>
                        {record.popup.map((item) => (
                          <p key={item}>{item}</p>
                        ))}
                      </div>
                    ),
                    width: 500,
                    // onOk() {
                    //   let body = { id: record.id, is_view: true };
                    //   updateViewNotify(body);
                    // },
                  });
                } else {
                  openModalConfirm(record.draft_dispatch_board_id, 'detail');
                  setIsModalConfirmAgainVisible(true);
                }
              }}
            >
              <u>
                <ZoomInOutlined
                  style={{
                    fontSize: 20,
                  }}
                />
              </u>
            </Link>
          );
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        align: 'center',
        render: (text, record) => {
          return text?.toLowerCase() === 'closed' || !record?.can_edit ? (
            text
          ) : (
            <Link
              onClick={() => {
                openModalConfirm(record.draft_dispatch_board_id);
                setIsModalConfirmAgainVisible(true);
              }}
            >
              <u>{text}</u>
            </Link>
          );
        },
      },
      {
        title: 'Action Date/ Time',
        dataIndex: 'action_date_time',
        width: 100,
        align: 'center',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Action By',
        dataIndex: ['action_by', 'yard', 'name'],
        width: 100,
        align: 'center',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: 100,
        align: 'center',
      },
    ];
    return cols;
  });

  return (
    <HeaderLayout>
      {React.createElement(
        collapsed ? StyledMenuUnfoldOutlined : StyledMenuFoldOutlined,
        {
          onClick: toggle,
        }
      )}
      {/* <Logo /> */}
      <Avatar src={AppLogoImage} />
      <AppName>T-NOS</AppName>
      <Breadcrumbs crumbs={crumbs} />
      <RightMenu>
        <Space size={10}>
          {onCheckPayment() ? null : (
            <Badge
              count={data}
              showZero
              style={{
                backgroundColor: '#fadb14',
                borderColor: '#fadb14',
                color: '#000',
              }}
            >
              <BellOutlined
                onClick={() => onModalNotification()}
                style={{
                  fontSize: 20,
                }}
              />
            </Badge>
          )}
          <Dropdown overlay={languageMenu} trigger={['click']}>
            <Button
              onClick={(e) => e.preventDefault()}
              style={{
                marginRight: '10px',
                marginTop: 'auto',
                marginBottom: 'auto',
                color: 'white',
                backgroundColor: 'transparent',
                border: 'none',
              }}
            >
              {language === 'th' ? <ThaiSvg /> : <UsSvg />}
              <DownOutlined style={{ marginLeft: '4px' }} />
            </Button>
          </Dropdown>
        </Space>
        <Dropdown
          overlay={() => {
            if (user) {
              return menu(user, dispatch);
            }
          }}
          trigger={['click']}
        >
          <Avatar
            style={{ marginLeft: 'auto', backgroundColor: '#f56a00' }}
            icon={<UserOutlined />}
          />
        </Dropdown>
      </RightMenu>

      <a
        href='https://ttmi-help-desk.vhdus.com/index.php'
        target='_blank'
        rel='noreferrer'
      >
        <Avatar
          style={{ marginLeft: '10px', backgroundColor: '#f56a00' }}
          icon={<CustomerServiceFilled style={{ color: '#ffffff' }} />}
        />
      </a>

      <Modal
        title={
          <Text>
            <BellOutlined
              style={{
                fontSize: 16,
              }}
            />{' '}
            Notification List
          </Text>
        }
        width={1500}
        style={{ textAlign: 'center' }}
        visible={isModalConfirmVisible}
        maskClosable={false}
        onCancel={() => {
          setOnClickNotifiy(false);
          setIsModalConfirmVisible(false);
          setIsModalConfirmAgainVisible(false);
        }}
        footer={false}
      >
        <>
          <AntTable
            rowKey={(record) => record.id}
            tableId={'Notification'}
            bordered
            showSorterTooltip={false}
            columns={columnsPopupConfirm}
            dataSource={notifyData}
            loading={isFetchingNotifyData}
            pagination={false}
            width={750}
            height={tableHeight}
            scroll={{
              y: tableHeight,
            }}
          />
        </>
        {/* )} */}
      </Modal>
      <Modal
        title={
          <Text>
            {!isDetail ? (
              'Would you like to accept the combined/continued trip?'
            ) : (
              <>
                <InfoCircleOutlined
                  style={{ color: '#1890ff', fontSize: '22px' }}
                />
                {'  Combined/Continued Trip Detail'}
              </>
            )}
          </Text>
        }
        width={580}
        style={{ textAlign: 'center' }}
        visible={isModalConfirmAgainVisible}
        // maskClosable={false}
        onCancel={() => {
          setIsModalConfirmAgainVisible(false);
          // setDisabledShuttleIn(false);
        }}
        centered
        footer={
          !isDetail ? (
            <div style={{ textAlign: 'center' }}>
              <Button
                style={{ width: 150 }}
                key='Accept'
                type='primary'
                loading={isUpdateStatusDraft}
                onClick={upDateStatusDraftAction}
                disabled={isDetail}
              >
                Accept
              </Button>
              <Button
                style={{ width: 150 }}
                key='reject'
                loading={isUpdateStatusDraft}
                onClick={upDateStatusDraftAction}
                disabled={isDetail}
              >
                Reject
              </Button>
            </div>
          ) : (
            <Button
              type='primary'
              onClick={() => {
                setIsModalConfirmAgainVisible(false);
              }}
            >
              OK
            </Button>
          )
        }
        onOk={() => upDateStatusDraftAction}
      >
        Trip no. {dataSource?.first_trip_no} Have{' '}
        <Text type='danger'>Change</Text>
        <br />
        Job_code = {dataSource ? dataSource.job_code : null}
        <br />
        Effect to your plan
        <br />
        <CardStyle>
          <Row gutter={[24]} align='center' justify='center'>
            <Space size={30}>
              <Col style={{ textAlign: 'left' }}>
                <div style={{ textAlign: 'center' }}>
                  <b>Before</b>
                </div>
                Trip No. :{' '}
                {dataSource?.dispatch_before
                  ? dataSource?.dispatch_before?.trip_no
                  : ''}
                <br />
                From :{' '}
                {dataSource?.dispatch_before
                  ? dataSource?.dispatch_before?.actual_from?.name
                  : ''}
                <br />
                To :{' '}
                {dataSource?.dispatch_before
                  ? dataSource?.dispatch_before?.actual_cluster_to?.name
                  : ''}
                <br />
                Recieve Job time :
                <br />
                {dataSource?.dispatch_before
                  ? dateTimeTimestamptzFormatter({
                      value: dataSource?.dispatch_before?.receive_job_datetime,
                    })
                  : ''}
              </Col>
              <Col style={{ textAlign: 'left' }}>
                <div style={{ textAlign: 'center' }}>
                  <b>After</b>
                </div>
                Trip No. : {dataSource?.dispatch_after?.trip_no}
                <br />
                From : {dataSource?.dispatch_after?.actual_from?.name}
                <br />
                To : {dataSource?.dispatch_after?.actual_cluster_to?.name}
                <br />
                Recieve Job time :
                <br />
                <Text type='danger'>
                  {dateTimeTimestamptzFormatter({
                    value: dataSource?.dispatch_after?.receive_job_datetime,
                  })}
                </Text>
              </Col>
            </Space>
          </Row>
        </CardStyle>
      </Modal>
      <NotifyTripIsAckComponent />
    </HeaderLayout>
  );
};
const HeaderLayout = styled(Header)`
  background: #ff781f;
  padding-left: 0px;
  padding-right: 10px;
  height: 48px;
  display: flex;
  align-items: center;
`;

const StyledMenuFoldOutlined = styled(MenuFoldOutlined)`
  padding: 0 16px;
  font-size: 18px;
  line-height: 48px;
  cursor: pointer;
  transition: color 0.3s;
  color: white;
`;

const StyledMenuUnfoldOutlined = styled(MenuUnfoldOutlined)`
  padding: 0 16px;
  font-size: 18px;
  line-height: 48px;
  cursor: pointer;
  transition: color 0.3s;
  color: white;
`;

const AppName = styled(Typography)`
  font-weight: 500;
  font-size: 20px;
  color: white;
  margin-left: 10px;
`;

const CardStyle = styled(Card)`
  margin: 0px 60px;
  margin-top: 20px;
`;

const RightMenu = styled.div`
  margin-left: auto;
`;
export default HeaderComponent;
