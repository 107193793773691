import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Space,
  Select,
  Typography,
  Collapse,
  Row,
  message,
  Card,
  Col,
} from 'antd';
import styled from 'styled-components';

import { useErrorHandler } from 'common_components/ErrorContext';
import { useSelector, useDispatch } from 'react-redux';
import { statusSummaryApi } from 'reducers/statusSummary/api';
import { SearchOutlined } from '@ant-design/icons';
const { Panel } = Collapse;
const { Option } = Select;

const createPayload = ({ filterRegion, filterCompany }) => {
  return {
    region_id: filterRegion,
    vl_id: filterCompany,
  };
};

const TableToolbar = (props) => {
  const { isLoading, onQuery, showFilter, setShowFilter, vlOptions } = props;
  const dispatch = useDispatch();
  // Store filters
  const storedFilters = useSelector((state) => {
    return state.statusSummary.filters;
  });
  const { setErrorData } = useErrorHandler();

  const onQueryOption = useCallback(async () => {
    try {
      setIsLoadingFilter(true);
      let resultVl = await dispatch(
        statusSummaryApi.endpoints.findAllStatusSummaryBySelectionVl.initiate()
      );
      let resultRegion = await dispatch(
        statusSummaryApi.endpoints.findAllStatusSummaryBySelectionRegion.initiate()
      );
      if (
        (resultVl && resultVl.error) ||
        (resultRegion && resultRegion.error)
      ) {
        if (resultVl.error) {
          setErrorData(resultVl.error);
        }
        if (resultRegion.error) {
          setErrorData(resultRegion.error);
        }
      } else {
        if (resultVl.data) {
          let arrVl = [
            {
              label: 'All',
              value: 'All',
              code: 'All'
            },
          ];
          let vl = resultVl.data?.vl.map((d) => ({
            label: d.short_name,
            value: d.id,
            code: d.code,
          }));
          arrVl.push(...vl);
          setCompanyOptions(arrVl);
          vlOptions(arrVl)
          setFilterCompany(resultVl.data.default_vl?.id);
        }
        if (resultRegion.data) {
          let arrRegion = [
            {
              label: 'All',
              value: 'All',
            },
          ];
          let region = resultRegion.data?.region.map((d) => ({
            label: d.name,
            value: d.id,
          }));
          arrRegion.push(...region);
          setRegionOptions(arrRegion);
        }
        onQuery({
          ...createPayload({
            filterRegion,
            filterCompany: resultVl.data.default_vl?.id,
          }),
        });
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
    setIsLoadingFilter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setErrorData]);

  useEffect(() => {
    onQueryOption();
  }, [onQueryOption]);

  const [companyOptions, setCompanyOptions] = useState(
    storedFilters && storedFilters.companyOptions
      ? storedFilters.companyOptions
      : ['All']
  );
  const [regionOptions, setRegionOptions] = useState(
    storedFilters && storedFilters.regionOptions
      ? storedFilters.regionOptions
      : ['All']
  );
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  const [filterRegion, setFilterRegion] = useState(
    storedFilters && storedFilters.filterRegion
      ? storedFilters.filterRegion
      : ['All']
  );
  const [filterCompany, setFilterCompany] = useState(
    storedFilters && storedFilters.filterCompany
      ? storedFilters.filterCompany
      : ['All']
  );

  const onDisplayButton = () => {
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterRegion,
          filterCompany,
        }),
      });
    }
  };

  const selectProps = {
    showSearch: true,
    // size: 'small',
    style: {
      width: '280px',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoadingFilter,
  };

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Filter Options' key={1}>
          <CardStyleFrom>
            {/* <Space size={16}> */}
            {/* <Space direction='vertical'> */}
            <Row
              gutter={[12]}
              style={{ marginTop: '10px' }}
              align='center'
              justify='center'
            >
              <Col>
                <Space direction='horizontal'>
                  <>
                    <StyledText>VL</StyledText>
                    <Select
                      {...selectProps}
                      placeholder={'Company'}
                      value={filterCompany}
                      onChange={(values) => {
                        setFilterCompany(values);
                      }}
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {companyOptions &&
                        companyOptions.map((c, index) => (
                          <Option key={index} value={c.value}>
                            {c.label}
                          </Option>
                        ))}
                    </Select>
                  </>
                </Space>
              </Col>
              <Col>
                <Space direction='horizontal'>
                  <>
                    <StyledText>Region</StyledText>
                    <Select
                      {...selectProps}
                      style={{width: 120}}
                      placeholder={'Region'}
                      value={filterRegion}
                      onChange={(values) => {
                        setFilterRegion(values);
                      }}
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {regionOptions &&
                        regionOptions.map((c, index) => (
                          <Option key={index} value={c.value}>
                            {c.label}
                          </Option>
                        ))}
                    </Select>
                  </>
                </Space>
              </Col>

              <Col>
                <Space direction='horizontal'>
                  <>
                    <Button
                      type='primary'
                      style={{ marginLeft: 10 , width: 200}}
                      loading={isLoading}
                      onClick={() => onDisplayButton()}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </Button>
                  </>
                </Space>
              </Col>
            </Row>
          </CardStyleFrom>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;
const Container = styled.div`
  padding: 10px 0;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
const CardStyleFrom = styled(Card)`
  .ant-card-body {
    padding-top: 10px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 0px;
  }
  width: 100%;
  border: 1px solid #d9d9d9;
`;
export default TableToolbar;
