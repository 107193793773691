import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const ConfirmDriverTrailerSlice = createSlice({
  name: 'receive_job',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.CurrentPage = payload;
    },
    setTTTCurrentOffset: (state, { payload }) => {
      state.CurrentOffset = payload;
    },
    setConfirmDriverTrailerTotalRows: (state, { payload }) => {
      state.TotalRows = payload;
    },
    setConfirmDriverTrailerDataSource: (state, { payload }) => {
      state.DataSource = payload;
    },
    setConfirmDriverTrailerFilter: (state, { payload }) => {
      // debugger
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setConfirmDriverTrailerConfirm: (state, { payload }) => {
      // debugger
      state.filters = {
        ...state.confirm,
        ...payload,
      };
    },
  },
});

export const {
  setConfirmDriverTrailerFilter,
  setCurrentPage,
  setCurrentOffset,
  setConfirmDriverTrailerDataSource,
  setConfirmDriverTrailerTotalRows,
  setConfirmDriverTrailerConfirm
} = ConfirmDriverTrailerSlice.actions;

export const ConfirmDriverTrailerSelector = (state) => state.confirmDriverTrailer;

export default ConfirmDriverTrailerSlice.reducer;
