import React, { useState, useEffect } from 'react';
import {
  Badge,
  Row,
  Col,
  Popover,
  Checkbox,
  Button,
  Space,
  Input,
  Upload,
  Modal,
  Typography,
  Collapse,
  Select,
} from 'antd';
import styled from 'styled-components';
import { useDebounce } from 'hooks/debounce';
import {
  FilterOutlined,
  DeleteTwoTone,
  PlusCircleOutlined,
  UploadOutlined,
  ExportOutlined,
  ReloadOutlined,
  ClearOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import DeleteModal from 'common_components/DeleteModal';
import { useSelector, useDispatch } from 'react-redux';
import { useFindAllLocationMastersOriginalETAQuery } from 'reducers/masterData/locationMaster/api';
import { useFindAllRegionsQuery } from 'reducers/masterData/region/api';
import { useFindAllClustersQuery } from 'reducers/masterData/cluster/api';
import { getCurrentUser } from 'reducers/user';
import { setOriginalETAMasterFilter } from 'reducers/masterData/originalETAMaster';
import { Sorter } from 'utils/sorter';
const { Text } = Typography;
const { Panel } = Collapse;
const { Option } = Select;
const createPayload = ({
  filterFromRegion,
  filterToRegion,
  filterFromCluster,
  filterToCluster,
  filterFromLocation,
  filterToLocation,
  filterRouteCode,
}) => {
  return {
    location_from_region_name:
      filterFromRegion && filterFromRegion.length > 0
        ? filterFromRegion.join('&location_from_region_name=')
        : null,
    location_to_region_name:
      filterToRegion && filterToRegion.length > 0
        ? filterToRegion.join('&location_to_region_name=')
        : null,
    location_from_name:
      filterFromCluster && filterFromCluster.length > 0
        ? filterFromCluster.join('&location_from_name=')
        : null,
    location_to_name:
      filterToCluster && filterToCluster.length > 0
        ? filterToCluster.join('&location_to_name=')
        : null,
    location_from_short_name:
      filterFromLocation && filterFromLocation.length > 0
        ? filterFromLocation.join('&location_from_short_name=')
        : null,
    location_to_short_name:
      filterToLocation && filterToLocation.length > 0
        ? filterToLocation.join('&location_to_short_name=')
        : null,
    search: filterRouteCode ? filterRouteCode : null,
  };
};

const defaultFilter = {
  filterFromRegion: [],
  filterToRegion: [],
  filterFromCluster: [],
  filterToCluster: [],
  filterFromLocation: [],
  filterToLocation: [],
  filterRouteCode: null,
};

const TableToolbar = (props) => {
  const dispatch = useDispatch();
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {
    showDelete,
    showAdd,
    showExport,
    showUpload,
    showReload,
    showPermanentDelete,
    onDelete,
    onCreate,
    onExport,
    onUpload,
    onReload,
    isDeleting,
    isExporting,
    isUploading,
    isLoading,
    columns,
    setColumns,
    // onSearch,
    showFilter,
    setShowFilter,
    onInit,
    onQuery,
  } = props;

  // Redux Store
  const storedFilters = useSelector((state) => {
    return state.originalETAMaster.filters;
  });

  // const storedfilterFromRegion = useSelector((state) => {
  //   return state.originalETAMaster.filterFromRegion;
  // });
  // const storedfilterToRegion = useSelector((state) => {
  //   return state.originalETAMaster.filterToRegion;
  // });
  // const storedfilterFromLocation = useSelector((state) => {
  //   return state.originalETAMaster.filterFromLocation;
  // });
  // const storedfilterToLocation = useSelector((state) => {
  //   return state.originalETAMaster.filterToLocation;
  // });
  // const storedSearchDestination = useSelector((state) => {
  //   return state.originalETAMaster.searchDestination;
  // });
  const user = useSelector(getCurrentUser);
  // const [searchFilters, setSearchFilters] = useState({
  //   filterFromRegion: storedfilterFromRegion,
  //   filterToRegion: storedfilterToRegion,
  //   filterFromLocation: storedfilterFromLocation,
  //   filterToLocation: storedfilterToLocation,
  //   // searchDestination: storedSearchDestination,
  // });

  const [filterFromRegion, setFilterFromRegion] = useState(
    storedFilters && storedFilters.filterFromRegion
      ? storedFilters.filterFromRegion
      : defaultFilter.filterFromRegion
  );
  const [filterToRegion, setFilterToRegion] = useState(
    storedFilters && storedFilters.filterToRegion
      ? storedFilters.filterToRegion
      : defaultFilter.filterToRegion
  );
  const [filterFromCluster, setFilterFromCluster] = useState(
    storedFilters && storedFilters.filterFromCluster
      ? storedFilters.filterFromCluster
      : defaultFilter.filterFromCluster
  );
  const [filterToCluster, setFilterToCluster] = useState(
    storedFilters && storedFilters.filterToCluster
      ? storedFilters.filterToCluster
      : defaultFilter.filterToCluster
  );
  const [filterFromLocation, setFilterFromLocation] = useState(
    storedFilters && storedFilters.filterFromLocation
      ? storedFilters.filterFromLocation
      : defaultFilter.filterFromLocation
  );
  const [filterToLocation, setFilterToLocation] = useState(
    storedFilters && storedFilters.filterToLocation
      ? storedFilters.filterToLocation
      : defaultFilter.filterToLocation
  );
  const [filterRouteCode, setFilterRouteCode] = useState(
    storedFilters && storedFilters.filterRouteCode
      ? storedFilters.filterRouteCode
      : defaultFilter.filterRouteCode
  );
  const debouncedFlter = useDebounce(filterRouteCode, 1000);
  useEffect(() => {
    if (onInit) {
      // onInit(createPayload(defaultFilter));
      onInit({
        ...createPayload({
          filterFromRegion,
          filterToRegion,
          filterFromCluster,
          filterToCluster,
          filterFromLocation,
          filterToLocation,
          filterRouteCode,
        }),
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      setOriginalETAMasterFilter({
        filterFromRegion,
        filterToRegion,
        filterFromCluster,
        filterToCluster,
        filterFromLocation,
        filterToLocation,
        filterRouteCode: debouncedFlter,
      })
    );
  }, [
    filterFromRegion,
    filterToRegion,
    filterFromCluster,
    filterToCluster,
    filterFromLocation,
    filterToLocation,
    dispatch,
    debouncedFlter,
    filterRouteCode,
  ]);
  // const debouncedSearchFilters = useDebounce(searchFilters, 500);
  const [locationOptions, setLocationOptions] = useState([]);
  const [regionsOptions, setRegionsOptions] = useState([]);
  const [clustersOptions, setClustersOptions] = useState([]);
  const [trailerTypesOptions, setTrailerTypesOptions] = useState([]);
  const {
    data: dataLocationMaster,
    error: errorLocationMaster,
    isFetching: isLocationMasterFetching,
    refetch: refetchLocationMaster,
  } = useFindAllLocationMastersOriginalETAQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataLocationMaster) {
      let mapData = dataLocationMaster.map((d) => ({
        id: d.id,
        label: d.short_name,
        value: d.short_name,
      }));
      setLocationOptions(Sorter.DEFAULTLIST(mapData, 'value'));
    }
  }, [dataLocationMaster]);

  useEffect(() => {
    if (errorLocationMaster) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load companies',
        okText: 'Retry',
        onOk: refetchLocationMaster,
        cancelText: 'Cancel',
      });
    }
  }, [errorLocationMaster, refetchLocationMaster]);

  const {
    data: dataRegions,
    error: errorRegions,
    isFetching: isRegionsFetching,
    refetch: refetchRegions,
  } = useFindAllRegionsQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataRegions) {
      let mapData = dataRegions.map((d) => ({
        id: d.id,
        label: d.name,
        value: d.name,
      }));
      setRegionsOptions(Sorter.DEFAULTLIST(mapData, 'value'));
    }
  }, [dataRegions]);

  useEffect(() => {
    if (errorRegions) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load companies',
        okText: 'Retry',
        onOk: refetchRegions,
        cancelText: 'Cancel',
      });
    }
  }, [errorRegions, refetchRegions]);
  const {
    data: dataClusters,
    error: errorClusters,
    isFetching: isClustersFetching,
    refetch: refetchClusters,
  } = useFindAllClustersQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataClusters) {
      let mapData = dataClusters.map((d) => ({
        id: d.id,
        label: d.name,
        value: d.name,
      }));
      setClustersOptions(Sorter.DEFAULTLIST(mapData, 'value'));
    }
  }, [dataClusters]);

  useEffect(() => {
    if (errorClusters) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load companies',
        okText: 'Retry',
        onOk: refetchClusters,
        cancelText: 'Cancel',
      });
    }
  }, [errorClusters, refetchClusters]);

  // useEffect(() => {
  //   console.log(`onSearch`, onSearch);
  //   console.log(`debouncedSearchFilters`, debouncedSearchFilters);
  //   if (onSearch && debouncedSearchFilters) {
  //     onSearch(debouncedSearchFilters);
  //   }
  // }, [debouncedSearchFilters, onSearch]);

  // const onInputfilterFromRegionChange = (event) => {
  //   console.log(`event.target.value`, event.target.value)
  //   // setSearchFilters((prevState) => ({
  //   //   ...prevState,
  //   //   filterFromRegion: event.target.value,
  //   // }));
  // };
  // const onInputfilterToRegionChange = (event) => {
  //   setSearchFilters((prevState) => ({
  //     ...prevState,
  //     filterToRegion: event.target.value,
  //   }));
  // };
  // const onInputfilterFromClusterChange = (event) => {
  //   setSearchFilters((prevState) => ({
  //     ...prevState,
  //     filterFromCluster: event.target.value,
  //   }));
  // };
  // const onInputfilterToClusterChange = (event) => {
  //   console.log(`event`, event.target.value);
  //   setSearchFilters((prevState) => ({
  //     ...prevState,
  //     filterToCluster: event.target.value,
  //   }));
  // };

  // const onInputfilterFromLocationChange = (event) => {
  //   setSearchFilters((prevState) => ({
  //     ...prevState,
  //     filterFromLocation: event.target.value,
  //   }));
  // };

  // const onInputfilterToLocationChange = (event) => {
  //   setSearchFilters((prevState) => ({
  //     ...prevState,
  //     filterToLocation: event.target.value,
  //   }));
  // };

  const onColsChange = (e, col) => {
    const nextColumns = [...columns];
    let selectedCol = nextColumns.find((c) => c.title === col.title);
    selectedCol['hide'] = !e.target.checked;
    setColumns(nextColumns);
  };

  const onHideAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = true;
    }
    setColumns(nextColumns);
  };

  const onShowAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = false;
    }
    setColumns(nextColumns);
  };

  const onCancelDelete = () => {
    setShowConfirmDelete(false);
  };

  const onOkDelete = (isPermanentDelete) => {
    setShowConfirmDelete(false);
    onDelete(isPermanentDelete);
  };

  const onDisplayButton = () => {
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterFromRegion,
          filterToRegion,
          filterFromCluster,
          filterToCluster,
          filterFromLocation,
          filterToLocation,
          filterRouteCode,
        }),
      });

      // dispatch(
      //   setOriginalETAMasterFilter({
      //     ...createPayload({
      //       filterFromRegion,
      //       filterToRegion,
      //       filterFromCluster,
      //       filterToCluster,
      //       filterFromLocation,
      //       filterToLocation,
      //     }),
      //   })
      // );
    }
  };

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '250px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    // loading: isOrderGroupLoading,
  };
  const selectSingleProps = {
    style: {
      width: '200px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    // loading: isOrderGroupLoading,
  };

  const inputProps = {
    style: {
      width: '200px',
    },
  };

  // const inputProps = {
  //   style: {
  //     width: '200px',
  //   },
  // };

  return (
    <Container>
      <Row style={{ marginBottom: '10px' }}>
        {/* <Col span={16} style={{ display: 'flex', alignItems: 'center' }}> */}

        {/* <Space direction='horizontal' size={24} align='end'> */}
        <Popover
          key={'popover'}
          zIndex='10000'
          placement='right'
          content={(() => {
            return [
              <div key={'show-hide-all'}>
                <Button type='link' onClick={onHideAllCols}>
                  Hide all
                </Button>
                /
                <Button type='link' onClick={onShowAllCols}>
                  Show all
                </Button>
                ,
              </div>,
              columns
                .filter((c) => c.title)
                .map((c, index) => {
                  return (
                    <div key={`root-${index}`}>
                      <Checkbox
                        key={index}
                        checked={!c.hide}
                        onChange={(e) => onColsChange(e, c)}
                      >
                        {c.title}
                      </Checkbox>
                    </div>
                  );
                }),
            ];
          })()}
          title='Show/hide Columns'
          trigger='click'
        >
          <Badge dot={columns && columns.some((c) => c.hide)}>
            <Button icon={<FilterOutlined />} size='small' />
          </Badge>
        </Popover>
        {/*   
          <Space
            align='horizontal'
            size='small'
            style={{ marginLeft: '10px', alignItems: 'center' }}
          >
            <Text strong>Search:</Text>
            <Input
              placeholder='Search...'
              value={searchFilters && searchFilters.filterFromLocation}
              onChange={onInputfilterFromLocationChange}
            />
          </Space> */}
        {/* <Row align='middle'>
        <Col span={16} style={{ display: 'flex', alignItems: 'center' }}> */}
        <StyledCollapse
          defaultActiveKey={1}
          onChange={() => {
            setShowFilter(!showFilter);
          }}
        >
          <Panel header='Filter Options' key={1}>
            <Space
              direction='horizontal'
              size={16}
              style={{ padding: '5px' }}
              align='end'
            >
              {/* <Space
              align='horizontal'
              size='small'
              style={{ marginLeft: '10px', alignItems: 'center' }}
            > */}
              {/* <Text strong>From Region:</Text>
              <Input
                placeholder='Origin'
                value={searchFilters && searchFilters.filterToLocation}
                onChange={onInputfilterToLocationChange}
              />
              <Text strong>To Region:</Text>
              <Input
                placeholder='Destination'
                value={searchFilters && searchFilters.searchDestination}
                onChange={onInputfilterFromLocationChange}
              />
       */}

              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>From Region</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select from region'}
                      value={filterFromRegion}
                      options={regionsOptions}
                      loading={isRegionsFetching}
                      onChange={(values) => {
                        setFilterFromRegion(values);
                      }}
                    />
                  </div>

                  <div>
                    <Typography>To Region</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select to region'}
                      value={filterToRegion}
                      options={regionsOptions}
                      loading={isRegionsFetching}
                      onChange={(values) => {
                        setFilterToRegion(values);
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>From Cluster</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select from cluster'}
                      value={filterFromCluster}
                      options={clustersOptions}
                      loading={isClustersFetching}
                      onChange={(values) => {
                        setFilterFromCluster(values);
                      }}
                    />
                  </div>

                  <div>
                    <Typography>To Cluster</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select to cluster'}
                      value={filterToCluster}
                      options={clustersOptions}
                      loading={isClustersFetching}
                      onChange={(values) => {
                        setFilterToCluster(values);
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>

              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>From Origination</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select origination'}
                      value={filterFromLocation}
                      options={locationOptions}
                      loading={isLocationMasterFetching}
                      onChange={(values) => {
                        setFilterFromLocation(values);
                      }}
                    />
                  </div>

                  <div>
                    <Typography>To Destination</Typography>
                    <Select
                      {...selectProps}
                      placeholder={'Select destination'}
                      value={filterToLocation}
                      options={locationOptions}
                      loading={isLocationMasterFetching}
                      onChange={(values) => {
                        setFilterToLocation(values);
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Route Code</Typography>
                    <Input
                      {...inputProps}
                      value={filterRouteCode}
                      placeholder='Route Code'
                      allowClear
                      onChange={(event) => {
                        setFilterRouteCode(event.target.value);
                      }}
                    />
                  </div>
                  {/* <div>
                    <StyledButton
                      type='primary'
                      loading={isLoading}
                      onClick={onDisplayButton}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </StyledButton>
                  </div> */}
                  {/* <Space direction='horizontal' style={{ marginTop: '15px' }}> */}
                  <div style={{ marginTop: '23px' }}>
                    <StyledButton
                      type='primary'
                      loading={isLoading}
                      onClick={onDisplayButton}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </StyledButton>
                  </div>
                </Space>
                {/* </Space> */}
              </SearchBox>
              {/* <SearchBox>
                <Space direction='horizontal' style={{ marginTop: '84px' }}>
                  <div>
                    <StyledButton
                      type='primary'
                      loading={isLoading}
                      onClick={onDisplayButton}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </StyledButton>
                  </div> */}
              {/* 
                  <div>
                  <StyledButton
                      loading={isLoading}
                      // onClick={onDownloadButton}
                      icon={<ClearOutlined />}
                    >
                      Clear
                    </StyledButton>
    
                  </div> */}
              {/* </Space>
              </SearchBox> */}

              {/* <SearchBox>
              <Space direction='vertical' style={{ marginTop: '84px' }}>
                <Row gutter={[12]}>
                  <Space direction='horizontal'>
                    <StyledButton
                      type='primary'
                      loading={isLoading}
                      // onClick={onSearch}
                      icon={<SearchOutlined />}
                    >
                      Search
                    </StyledButton>
                    <StyledButton
                      type='primary'
                      loading={isLoading}
                      // onClick={onDownloadButton}
                      icon={<ClearOutlined />}
                    >
                      Clear
                    </StyledButton>
                  </Space>
                </Row>
              </Space>
            </SearchBox> */}

              {/* <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Space
                      direction='vertical'
                      style={{ width: '100%', marginTop: '22px' }}
                    >
                      <StyledButton
                        type='primary'
                        loading={isLoading}
                        onClick={onDisplayButton}
                      >
                        Display
                      </StyledButton>
                    </Space>
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Space
                      direction='vertical'
                      style={{ width: '100%', marginTop: '22px' }}
                    >
                      <StyledButton
                        icon={<ClearOutlined />}
                        loading={isLoading}
                        // onClick={() => {
                        //   setFilterModule(defaultFilter.filterModule);
                        //   setFilterGroup(defaultFilter.filterGroup);
                        //   setFilterSearchAll(defaultFilter.filterSearchAll);
                        //   onInit();
                        // }}
                      >
                        Clear
                      </StyledButton>
                    </Space>
                  </div>
                </Space>
              </SearchBox> */}
              {/* <Button
                icon={<ClearOutlined />}
                size='small'
                onClick={() => {
                  setSearchFilters({
                    filterFromLocation: '',
                    filterToLocation: '',
                    searchDestination: '',
                  });
                }}
              >
                Clear
              </Button> */}
            </Space>
          </Panel>
        </StyledCollapse>
        {/* </Col>
       <Col span={8} style={{ display: 'flex', alignItems: 'center' }}> */}
        <RightAlignedCol>
          <Space size='middle' style={{ marginTop: 15 }}>
            {showDelete && (
              <Button
                loading={isDeleting}
                icon={<DeleteTwoTone twoToneColor='white' />}
                size='small'
                style={{ backgroundColor: 'red', marginRight: '20px' }}
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              />
            )}
            {showExport && (
              <Button
                loading={isExporting}
                icon={<ExportOutlined />}
                size='small'
                onClick={onExport}
              >
                Export
              </Button>
            )}
            {showUpload && (
              <Upload
                // onChange={}
                showUploadList={false}
                accept='.xlsx'
                beforeUpload={(file) => {
                  Modal.confirm({
                    title: 'Are you sure want to upload?',
                    icon: <ExclamationCircleOutlined />,
                    content: `filename: ${file && file.name}`,
                    okText: 'Confirm',
                    cancelText: 'Cancel',
                    onOk: () => {
                      onUpload(file);
                    },
                  });

                  return false;
                }}
              >
                <Button
                  raised='true'
                  loading={isUploading}
                  icon={<UploadOutlined />}
                  size='small'
                >
                  Upload
                </Button>
              </Upload>
            )}
            {showReload && (
              <Button
                loading={isLoading}
                icon={<ReloadOutlined />}
                size='small'
                onClick={onReload}
              >
                Reload
              </Button>
            )}
            {showAdd && (
              <Button
                icon={<PlusCircleOutlined />}
                size='small'
                onClick={onCreate}
              >
                Add
              </Button>
            )}
          </Space>
        </RightAlignedCol>
        {/* </Space> */}
      </Row>
      <DeleteModal
        visible={showConfirmDelete}
        onCancel={onCancelDelete}
        onDelete={onOkDelete}
        showPermanentDelete={showPermanentDelete}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 0;
`;

const RightAlignedCol = styled(Col)`
  text-align: right;
  margin-left: auto;
`;

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  width: 120px;
`;
const SearchBox = styled.div`
  display: block;
  padding: 0px 5px;
  margin: 0px 5px;
  // border-radius: 5px;
  border: 1px none #ececec;

  height: 140px;
`;
export default TableToolbar;
