import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const dispatchBoardSlice = createSlice({
  name: 'dispatch_board',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setDispatchBoardTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setDispatchBoardDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setDispatchBoardFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setDispatchBoardSummary: (state, { payload }) => {
      state.summary = payload;
    },
    setDispatchBoardCallingCompleteCount: (state, { payload }) => {
      state.callingCompleteCount = payload;
    },
    setDispatchBoardWaitInputTRDVCount: (state, { payload }) => {
      state.WaitInputTRDVCount = payload;
    },
  },
});

export const {
  setDispatchBoardFilter,
  setCurrentPage,
  setCurrentOffset,
  setDispatchBoardDataSource,
  setDispatchBoardTotalRows,
  setDispatchBoardCallingCompleteCount,
  setDispatchBoardWaitInputTRDVCount,
  setDispatchBoardSummary,
} = dispatchBoardSlice.actions;

export const dispatchBoardSelector = (state) => state.dispatchBoard;

export default dispatchBoardSlice.reducer;
