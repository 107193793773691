import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Form,
  Modal,
  Input,
  Button,
  message,
  Space,
  Col,
  Row,
  Radio,
  Card,
} from 'antd';
import { useErrorHandler } from 'common_components/ErrorContext';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { receivejobApi } from 'reducers/receivejob/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const ReceiveJobFormComponent = (props) => {
  const {
    onQuery,
    onClearSelectedRows,
    clearFrom,
    radioReceiveJob,
    selectedLane,
    driverProp,
  } = props;
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const trailerInputRef = useRef(null);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const mapDriver1Ref = useRef();
  const mapDriver2Ref = useRef();
  useEffect(() => {
    if (trailerInputRef.current) {
      trailerInputRef.current.focus();
    }
  }, []);

  const onReset = useCallback(() => {
    form.resetFields();
    radioReceiveJob(null);
    selectedLane(null);
    setTimeout(() => {
      if (trailerInputRef.current) {
        trailerInputRef.current.focus();
      }
    }, 100);
    if (onQuery) {
      onQuery([]);
    }
    if (onClearSelectedRows) {
      onClearSelectedRows('clear');
    }
  }, [form, onClearSelectedRows, onQuery, radioReceiveJob, selectedLane]);

  useEffect(() => {
    if (clearFrom) {
      onReset();
    }
  }, [clearFrom, onReset]);

  const onFinish = async (values) => {
    try {
      if (
        (values.trailer && values.driver1_name) ||
        (values.trailer && values.driver2_name) ||
        (values.trailer && values.driver1_name && values.driver2_name)
      ) {
        setIsLoading(true);
        let trailerAndDriver = null;
        if (
          (values.trailer &&
            values.driver1_name &&
            values.radioReceiveJob === 'cancel') ||
          (values.trailer &&
            values.driver2_name &&
            values.radioReceiveJob === 'cancel')
        ) {
          if (values.trailer && values.driver1_name) {
            trailerAndDriver = {
              offset: 0,
              limit: 100,
              trailer_card_id: values.trailer,
              driver_1_tlep_code: values.driver_1,
            };
          }
          if (values.trailer && values.driver1_name && values.driver2_name) {
            trailerAndDriver = {
              offset: 0,
              limit: 100,
              trailer_card_id: values.trailer,
              driver_1_tlep_code: values.driver_1,
              driver_2_tlep_code: values.driver_2,
            };
          }
        } else {
          if (values.trailer && values.driver1_name) {
            trailerAndDriver = {
              limit: 100,
              trailer_card_id: values.trailer.trim(),
              drivers_tlep_code: values.driver_1.trim(),
            };
          }
          if (values.trailer && values.driver1_name && values.driver2_name) {
            trailerAndDriver = {
              limit: 100,
              trailer_card_id: values.trailer.trim(),
              drivers_tlep_code: [
                values.driver_1.trim(),
                values.driver_2.trim(),
              ],
            };
          }
        }
        let filterReceiveJob = null;
        if (values.radioReceiveJob === 'cancel') {
          radioReceiveJob(values.radioReceiveJob);
          filterReceiveJob = await getReceiveJobsCancel(trailerAndDriver);
        } else {
          radioReceiveJob(values.radioReceiveJob);
          filterReceiveJob = await getDispatchBoardsReceiveJob(
            trailerAndDriver
          );
        }
        if (onQuery) {
          driverProp([mapDriver1Ref.current, mapDriver2Ref.current]);
          onQuery(filterReceiveJob);
        }
      } else {
        radioReceiveJob(null);
        if (onQuery) {
          onQuery([]);
        }
        msgErr();
      }
    } catch (err) {
      message.error('Something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const getReceiveJobsCancel = async (value) => {
    let resData = [];
    try {
      if (value) {
        let result = await dispatch(
          receivejobApi.endpoints.findReceiveJobCancelById.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data && result.data.results.length) {
            resData = result.data.results;
          } else {
            msgErr();
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onChangeTrailerAndDriver = async (value, label) => {
    try {
      let driver1_name = null;
      let driver2_name = null;
      if (label === 'trailer') {
        await getTrailer(value);
      }

      if (label === 'driver_1') {
        driver1_name = await getDriver1(value, label);
        form.setFieldsValue({
          driver1_name: driver1_name,
        });
      }
      if (label === 'driver_2') {
        driver2_name = await getDriver2(value, label);
        form.setFieldsValue({
          driver2_name: driver2_name,
        });
      }
    } catch (err) {
      message.error('Something went wrong');
    }
  };

  const handleEnter = (event, name) => {
    if (event.keyCode === 13) {
      if (name) {
        onChangeTrailerAndDriver(event.target.value.trim(), name);
      }

      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  const getTrailer = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          receivejobApi.endpoints.findReceiveJobTrailerbyCardId.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data && result.data.length > 0) {
            const trailer = result.data[0];
            resData = trailer;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const getDriver1 = async (value, label) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          receivejobApi.endpoints.findReceiveJobDriverbyCode.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data && result.data.length > 0) {
            const driver = result.data[0];
            let mapDriver1 = {
              id: driver.id,
              tlep_code: driver.tlep_code,
            };
            mapDriver1Ref.current = mapDriver1;

            if (label) {
              resData = driver?.driver_name_th;
            } else {
              resData = mapDriver1;
            }
          } else {
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const getDriver2 = async (value, label) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          receivejobApi.endpoints.findReceiveJobDriverbyCode.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data && result.data.length > 0) {
            const driver = result.data[0];
            let mapDriver2 = {
              id: driver.id,
              tlep_code: driver.tlep_code,
            };
            mapDriver2Ref.current = mapDriver2;
            if (label) {
              resData = resData = driver?.driver_name_th;
            } else {
              resData = mapDriver2;
            }
          } else {
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const msgErr = () => {
    Modal.error({
      title: 'Not found data for receive job',
      icon: <ExclamationCircleOutlined />,
    });
  };
  const getDispatchBoardsReceiveJob = async (value) => {
    let newData = [];
    try {
      if (value) {
        let result = await dispatch(
          receivejobApi.endpoints.findAllDispatchBoardsReceiveJobByPagination.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );
        if (result && result.error) {
          msgErr();
        } else {
          if (result.data && result.data.results.length) {
            newData = result.data.results;
          } else {
            msgErr();
          }
        }
      }
      return newData;
    } catch (err) {
      console.log('err', err);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 17,
      }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Row style={{ padding: 10 }}>
        <Col span={3}>
          <CardStyle style={{ height: '100%', marginRight: 5 }}>
            <Col span={24} style={{ top: 65 }}>
              <Form.Item
                name='radioReceiveJob'
                wrapperCol={{ offset: 2 }}
                style={{ marginBottom: '14px' }}
                rules={[
                  {
                    required: true,
                    message: 'Please Select',
                  },
                ]}
                initialValue={'confirm'}
              >
                <Radio.Group>
                  <Space direction='vertical'>
                    <Radio value='confirm'>Receive Job</Radio>
                    <Radio value='cancel'>Cancel job</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </Col>
          </CardStyle>
        </Col>

        <Col span={21}>
          <CardStyle2>
            <Row>
              <Col span={8}>
                <Form.Item
                  label='Trailer'
                  name='trailer'
                  onKeyDown={(e) => {
                    handleEnter(e);
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input trailer',
                    },
                  ]}
                  labelCol={{
                    span: 6,
                  }}
                >
                  <Input
                    placeholder={'(for scan)'}
                    ref={trailerInputRef}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        form.setFieldsValue({ trailer: e.target.value.trim() });
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='Driver 1'
                  name='driver_1'
                  onKeyDown={(e) => {
                    handleEnter(e, 'driver_1');
                  }}
                  rules={[
                    {
                      required: true,
                      message: 'Please input driver 1',
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      onChangeTrailerAndDriver(
                        e.target.value.trim(),
                        'driver_1'
                      );
                    }}
                    placeholder={'(for scan)'}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='Driver 2'
                  name='driver_2'
                  onKeyDown={(e) => {
                    handleEnter(e, 'driver_2');
                  }}
                >
                  <Input
                    onChange={(e) => {
                      onChangeTrailerAndDriver(
                        e.target.value.trim(),
                        'driver_2'
                      );
                    }}
                    placeholder={'(for scan)'}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={8}></Col>
              <Col span={8}>
                <Form.Item
                  label='Driver 1 name'
                  name='driver1_name'
                  onKeyDown={(e) => {
                    handleEnter(e);
                  }}
                >
                  <Input
                    style={{ color: '#000', backgroundColor: 'transparent' }}
                    placeholder={'(auto fill)'}
                    disabled
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label='Driver 2 name'
                  name='driver2_name'
                  onKeyDown={(e) => {
                    handleEnter(e);
                  }}
                >
                  <Input
                    style={{ color: '#000', backgroundColor: 'transparent' }}
                    placeholder={'(auto fill)'}
                    disabled
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row align='end'>
              <Col>
                <Form.Item shouldUpdate style={{ marginBottom: 0 }}>
                  {() => (
                    <Space size={25}>
                      <Button
                        style={{ width: 100 }}
                        type='primary'
                        htmlType='submit'
                        loading={isLoading}
                      >
                        Search
                      </Button>
                      <Button style={{ width: 100 }} onClick={onReset}>
                        Clear all
                      </Button>
                    </Space>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </CardStyle2>
        </Col>
      </Row>
    </Form>
  );
};

const CardStyle = styled(Card)`
  .ant-card-body {
    padding: 0px;
  }
`;
const CardStyle2 = styled(Card)`
  .ant-card-body {
    padding-left: 0px;
  }
`;
export default ReceiveJobFormComponent;
