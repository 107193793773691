import React, { useState, useEffect, useCallback, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { message, Checkbox, Typography, Modal } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import TableToolbar from './Toolbar';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { confirmDriverTrailerApi } from 'reducers/dispatchBoard/ConfirmDriverTrailer/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';
const { Link } = Typography;
const TableComponent = (props) => {
  const {
    dataTrip,
    loadingUnit,
    trailerSameJob,
    clearTable,
    setClearTable,
    clusterId,
    trailersConfirm,
  } = props;
  const payloadRef = useRef(null);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  const { height } = useWindowSize();
  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const columns = [
    {
      title: 'Trailer',
      dataIndex: 'trailer_card_id',
      width: 80,
      align: 'center',
      movable: true,
    },

    {
      title: 'Trailer Type',
      dataIndex: ['trailer_type', 'name'],
      width: 80,
      align: 'center',
      movable: true,
    },
    {
      title: 'Fuel type',
      dataIndex: ['fuel_type', 'name_en'],
      movable: true,
      align: 'center',
      width: 120,
    },
    {
      title: 'Status Trailer',
      dataIndex: 'status_trailer',
      movable: true,
      align: 'center',
      width: 120,
      render: (text, record) => {
        return record.realtime_tracking_id && text === 'Working' ? (
          <Link onClick={() => onLink(record)}>{text}</Link>
        ) : text === 'Working' && !record.realtime_tracking_id ? (
          <Link onClick={() => checkStatusTrailer(record)}>{text}</Link>
        ) : (
          text
        );
      },
    },

    {
      title: 'Plan return place',
      dataIndex: 'plan_return_place',
      align: 'center',
      width: 100,
      movable: true,
      ellipsis: true,
    },
    {
      title: 'Plan return time',
      dataIndex: 'plan_return_time',
      movable: true,
      align: 'center',
      width: 120,
      render: (text, record) => {
        return dateTimeTimestamptzFormatter({
          value: text,
        });
      },
    },
  ];

  const checkStatusTrailer = (record = null) => {
    Modal.confirm({
      title: 'ยังไม่ได้ yard out',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk() {
        window.open(
          `/app/digitalmap/ttt_monitoring_jobs/realtime_tracking/${
            record && record.realtime_tracking_id
          }`,
          '_blank'
        );
      },
      cancelText: 'Cancel',
    });
  };
  const onLink = (record) => {
    window.open(
      `/app/digitalmap/ttt_monitoring_jobs/realtime_tracking/${record.realtime_tracking_id}`,
      '_blank'
    );
  };
  useEffect(() => {
    if (height) {
      let h = height - 520;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  useEffect(() => {
    if (clearTable) {
      setDataSource([]);
      setClearTable(false);
      setSelectedRows(null);
      setSelectedRowKeys([]);
      setChecked(false);
    }
  }, [clearTable, setClearTable]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows[0].trailer_card_id);
      trailersConfirm(selectedRows);
    },
  };

  const executeQuery = useCallback(
    async (payload, forceRefetch) => {
      let newData = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          confirmDriverTrailerApi.endpoints.findAllConfirmTrailers.initiate(
            payload,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            newData = result.data;
            setDataSource(newData);
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData };
      }
    },
    [dispatch, setErrorData]
  );

  const onInit = useCallback(
    (payload) => {
      let queryPayload = { ...payload };
      payloadRef.current = queryPayload;
      setDataSource([]);
      executeQuery(queryPayload);
    },
    [executeQuery]
  );

  const onQuery = async (payload) => {
    try {
      let payloadNew = {
        ...payload,
        actual_cluster_to_id: clusterId,
      };
      if (!isLoading) {
        payloadRef.current = { ...payloadNew };
        await executeQuery(
          {
            ...payloadRef.current,
          },
          true
        );
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const onSelectTrailer = async (e) => {
    setChecked(e.target.checked);
    let tripCheck = dataTrip.find((d) => d.trailer);
    if (tripCheck) {
      if (e.target.checked) {
        let selectTrailer = {
          trailer_type_id: tripCheck.trailer?.trailer_type.id,
          trailer_id: 'All',
          fuel_type_id: tripCheck.trailer?.fuel_type?.id,
          trailer_card_id: tripCheck.trailer?.trailer_card_id,
          bzp_id: tripCheck?.bzp.id,
          loading_unit: loadingUnit,
          actual_cluster_to_id: clusterId,
        };
        executeQuery(selectTrailer);
        setSelectedRowKeys([tripCheck.trailer?.id]);
        setSelectedRows(tripCheck.trailer?.trailer_card_id);
        trailersConfirm([tripCheck?.trailer]);
      }
    }
  };

  return (
    <Container>
      <TableToolbar
        onInit={onInit}
        onQuery={onQuery}
        columns={columns}
        isLoading={isLoading}
        selectedRows={selectedRows}
        bzp={dataTrip && dataTrip[0]?.bzp?.id}
        clusterId={clusterId && clusterId}
        loadingUnit={loadingUnit}
        clearTable={clearTable}
      />

      <Checkbox
        disabled={trailerSameJob}
        checked={checked}
        onChange={onSelectTrailer}
        style={{ fontWeight: 'bold', marginBottom: 5 }}
      >
        Select Trailer same Job Code
      </Checkbox>
      <AntTable
        rowKey='id'
        tableId={'confirmTrailer'}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        loading={isLoading}
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        onRow={(record) => ({
          onClick: () => {
            setSelectedRowKeys([record?.id]);
            setSelectedRows([record?.trailer_card_id]);
            trailersConfirm([record]);
          },
        })}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
