import React from 'react';
import { Route, Switch } from 'react-router';
import { useDispatch } from 'react-redux';
import UserMngRoutes from './userMng';
import deliveryListRoutes from './deliveryList';
import masterDataRoutes from './masterData';
import planningRoutes from './planningList';
import masterDataControlRoutes from './masterDataControl';
import digitalmapRoutes from './digitalmap';
import downloadReport from './downloadReport';
import { uiSetBreadcrumbs } from 'reducers/ui';
import PageNotFound from 'common_components/PageNotFound';

const ContentRoutes = (props) => {
  const dispatch = useDispatch();
  const routes = [].concat(
    UserMngRoutes,
    deliveryListRoutes,
    planningRoutes,
    masterDataRoutes,
    digitalmapRoutes,
    masterDataControlRoutes,
    downloadReport
  );

  return (
    <Switch>
      {[
        routes.map(({ path, _, Component }, key) => {
          return (
            <Route
              exact
              path={path}
              key={key}
              render={(props) => {
                const crumbs = routes
                  // Get all routes that contain the current one.
                  .filter(({ path }) => props.match.path.includes(path))
                  // Swap out any dynamic routes with their param values.
                  // E.g. "/pizza/:pizzaId" will become "/pizza/1"
                  .map(({ path, ...rest }) => ({
                    path: Object.keys(props.match.params).length
                      ? Object.keys(props.match.params).reduce(
                          (path, param) =>
                            path.replace(
                              `:${param}`,
                              props.match.params[param]
                            ),
                          path
                        )
                      : path,
                    ...rest,
                  }));
                // Defer a bit to let children component renders completely
                // before updating breadcrumbs on header bar which is parent compopnent
                // to avoid warning updating during other component rendering
                setTimeout(() => {
                  dispatch(uiSetBreadcrumbs(crumbs));
                }, 200);

                return <Component {...props} />;
              }}
            />
          );
        }),
        <Route
          key={'child-not-found'}
          path='*'
          component={() => <PageNotFound child />}
        />,
      ]}
    </Switch>
  );
};
export default ContentRoutes;
