import AssignVolumeList from "planning_components/AssignVolume";
import CreateLotList from "planning_components/CreateLot";
import FixTourList from "planning_components/FixTour";
import ManageTripBoardList from "planning_components/ManageTripBoard";
import ManageTourList from "planning_components/ManageTour";
import ManageTourListBzp from "planning_components/ManageTourBzp";
import CreateTourList from "planning_components/CreateTour";
import SummaryAllocation from "planning_components/SummaryAllocation";
import StatusCheck from "planning_components/StatusCheck";
import SummaryYard from "planning_components/SummaryYard";

const planingListRoutes = [
  {
    path: "/app/planning/assign_volume/",
    name: "Assign Volume",
    Component: AssignVolumeList,
  },
  {
    path: "/app/planning/create_tour/",
    name: "Create Tour",
    Component: CreateTourList,
  },
  {
    path: "/app/planning/create_lot",
    name: "Create Lot",
    Component: CreateLotList,
  },
  {
    path: "/app/planning/fix_tour",
    name: "Fix Tour",
    Component: FixTourList,
  },
  {
    path: "/app/planning/manage_trip_board",
    name: "Manage Trip Board",
    Component: ManageTripBoardList,
  },
  {
    path: "/app/planning/manage_tour_list/matched=:matched&tour=:tour&working_day=:working_day",
    name: "Manage Tour",
    Component: ManageTourList,
  },
  {
    path: "/app/planning/manage_tour_list/:company",
    name: "Manage Tour",
    Component: ManageTourList,
  },
  {
    path: "/app/planning/manage_tour_list",
    name: "Manage Tour",
    Component: ManageTourList,
  },
  {
    path: "/app/planning/manage_tour_bzp_list/",
    name: "Manage Tour BzP",
    Component: ManageTourListBzp,
  },
  {
    path: "/app/planning/summary_allocation",
    name: "Summary Allocation",
    Component: SummaryAllocation,
  },
  {
    path: "/app/planning/status_check",
    name: "Status Check",
    Component: StatusCheck,
  },
  {
    path: "/app/planning/summary_yard",
    name: "Summary Yard",
    Component: SummaryYard,
  },
];

export default planingListRoutes;
