import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { Sorter } from 'utils/sorter';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';

const VinTableComponent = (props) => {
  const { locationList, setLocationList } = props;
  const dataSourceRef = useRef();

  useEffect(() => {
    dataSourceRef.current = [...locationList];
    console.log('dataSourceRef.current', dataSourceRef.current)
  }, [locationList]);
  
  const deleteRowSelected = (e, record) => {
    let dataList = dataSourceRef.current;

    dataList.splice(dataList.indexOf(record), 1);
    setLocationList(dataList);
  };

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Delete',
        dataIndex: '',
        key: 'x',
        align: 'center',
        width: 20,

        sorter: {
          compare: Sorter.DEFAULT,
        },
        render: (text, record) => {
          return {
            props: {
              style: { color: '#FA0707' },
            },
            children: (
              <div>
                <DeleteOutlined
                  style={{ cursor: 'pointer' }}
                  onClick={(e) => deleteRowSelected(e, record)}
                />
              </div>
            ),
          };
        },

        movable: true,
        searchable: true,
      },

      {
        title: 'Location From',
        dataIndex: ['locationFrom', 'label'],
        align: 'center',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Location To',
        dataIndex: ['locationTo', 'label'],
        align: 'center',
        width: 100,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
    ];

    return cols;
  });

  return (
    <Container>
      <AntTable
        rowKey='id'
        tableId={'id'}
        bordered
        dataSource={locationList}
        showSorterTooltip={false}
        columns={columns}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default VinTableComponent;
