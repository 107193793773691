import { createSlice } from '@reduxjs/toolkit';
import { loginUser, passwordRecovery } from './api';
const initialState = {
  user: null,
  token: null,
  session: null,
  force_logout: false,
  isLoading: false,
  isLoginSuccess: false,
  isForgotPwdSuccess: false,
  error: null,
};
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clearState: (state) => {
      state.error = null;
      state.isLoginSuccess = false;
      state.isLoading = false;
      state.isForgotPwdSuccess = false;
    },
    setUser: (state, { payload }) => {
      state.user = {
        ...state.user,
        id: payload.id,
        username: payload.username,
        fullname_en: payload.fullname_en,
        fullname_th: payload.fullname_th,
        email: payload.email,
        is_superadmin: payload.is_superadmin,
        company_id: payload.company_id,
        company_job_name: payload.company_job_name,
        privileges: payload.privileges,
      };
    },
  },
  extraReducers: {
    [loginUser.fulfilled]: (state, { payload }) => {
      if (payload) {
        state.user = {
          id: payload.id,
          email: payload.email,
          username: payload.username,
          fullname_en: payload.fullname_en,
          fullname_th: payload.fullname_th,
          is_superadmin: payload.is_superadmin,
          privileges: payload.privileges,
          company_id: payload.company_id,
          company_job_name: payload.company_job_name,
        };

        state.token = payload.token;
        state.session = payload.session;
        localStorage.setItem('token', payload.token);
        localStorage.setItem('session', payload.session);

        state.isLoading = false;
        state.isLoginSuccess = true;
      } else {
        state.isLoading = false;
        state.isLoginSuccess = false;
        state.error = 'Failed to fetch data';
      }
    },
    [loginUser.pending]: (state, _) => {
      state.isLoading = true;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
    [passwordRecovery.fulfilled]: (state, _) => {
      state.isLoading = false;
      state.error = null;
      state.isForgotPwdSuccess = true;
    },
    [passwordRecovery.pending]: (state, _) => {
      state.isLoading = true;
    },
    [passwordRecovery.rejected]: (state, { payload }) => {
      state.isLoading = false;
      state.error = payload;
    },
  },
});

export const { clearState, forceLogout, setUser } = userSlice.actions;
export const getCurrentUser = (state) => state.user.user;

export const userSelector = (state) => state.user;

export default userSlice.reducer;
