import React, { useState, useEffect } from 'react';
import { PageHeader, Divider, Typography } from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import Table from './Table';
import { setOriginalETAMasterTotalRows } from 'reducers/masterData/originalETAMaster';
const { Title } = Typography;

const RouteMasterComponent = (props) => {
  const storedTotalRows = useSelector((state) => {
    return state.originalETAMaster.totalRows;
  });
  const dispatch = useDispatch();
  const [totalRows, setTotalRows] = useState(storedTotalRows || null);

  useEffect(() => {
    if (totalRows) {
      dispatch(setOriginalETAMasterTotalRows(totalRows));
    }
  }, [totalRows, dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Original ETA Master List'
        subTitle=''
        extra={
          totalRows != null && <Title level={5}>{`Total ${totalRows}`}</Title>
        }
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Table totalRows={totalRows} setTotalRows={setTotalRows} />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default RouteMasterComponent;
