import React, { useState } from 'react';
import Login from './dms_components/Login';
import { Route, Switch, Redirect } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import Configs from './config/config';

import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import LanguageLayout from './layout/LauguageLayout';
import enUS from 'antd/es/locale/en_US';
import thTH from 'antd/es/locale/th_TH';

import { PersistGate } from 'redux-persist/integration/react';

import './i18n';
import App from './App';
import PageNotFound from './common_components/PageNotFound';
import { ErrorHandler } from './common_components/ErrorContext';

import 'antd/dist/antd.css';
import rootStore, { history } from './reducers/rootReducer';

const { store, persistor } = rootStore;

const env = process.env.NODE_ENV;

let clearTokenTimer = null;

const clearTokenFunction = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('session');
};

clearTokenTimer = setTimeout(clearTokenFunction, Configs[env].IDLE_TIMEOUT);

const timerEventHandler = () => {
  if (clearTokenTimer) {
    clearTimeout(clearTokenTimer);
    clearTokenTimer = null;
    clearTokenTimer = setTimeout(clearTokenFunction, Configs[env].IDLE_TIMEOUT);
  }
};
// Listen window events and reset clear cookie timer
const listenerEvents = ['mousemove', 'click', 'keypress'];
listenerEvents.forEach((_, index) => {
  window.addEventListener(listenerEvents[index], () => {
    timerEventHandler();
  });
});

const Root = (props) => {
  const [language, setLanguage] = useState('en');

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConfigProvider locale={language === 'th' ? thTH : enUS}>
          <LanguageLayout>
            <ConnectedRouter history={history}>
              <ErrorHandler>
                <Switch>
                  <Redirect exact from='/' to='/app' />
                  <Route exact path='/login' component={Login} />
                  <Route
                    exact
                    path='/notfound'
                    component={() => <PageNotFound home />}
                  />
                  <Route
                    path='/app'
                    component={() => (
                      <App language={language} setLanguage={setLanguage} />
                    )}
                  />
                  <Route path='*' component={PageNotFound} />
                </Switch>
              </ErrorHandler>
            </ConnectedRouter>
          </LanguageLayout>
        </ConfigProvider>
      </PersistGate>
    </Provider>
  );
};

export default Root;
