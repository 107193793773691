import React, { useState, useEffect, useRef } from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import {
  PageHeader,
  Divider,
  Modal,
  Row,
  Col,
  Button,
  message,
  Space,
  Typography,
} from 'antd';
import styled from 'styled-components';
import Form from './Form';
import Table from './Table';
import moment from 'moment';
import {
  useCreateDriverConfirmMutation,
  useCreateReceiveJobMutation,
  receivejobApi,
  useDeleteReceiveJobMutation,
  useLazyFindAllDriverConfirmByUserQuery,
} from 'reducers/receivejob/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import FormReason from './FormReason';
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import {
  useFindTrailerLaneByIdQuery,
  trailerApi,
} from 'reducers/masterData/trailer/api';
import { useFindAllCriteriaQuery } from 'reducers/criteria/api';
import AntTable from 'common_components/AntTable';
import AntModal from 'common_components/AntModal';
import { useInterval } from 'hooks/interval';
const { Title, Text } = Typography;

const ReceiveJobComponent = (props) => {
  const statusDiff = useRef();
  const dataTrailerLaneIdRef = useRef();
  const trailerLaneOptionsRef = useRef();
  const [dataDriverConfirmByUser, setDataDriverConfirmByUser] = useState(null);
  const confirmRef = useRef();
  const confirmRowRef = useRef();
  const cancelDataRowSelectRef = useRef();
  const selectdTableRef = useRef();
  const radioRef = useRef();
  const [defaultRowData, setDefaultRowData] = useState([]);
  const [reasonDelay, setReasonDelay] = useState(true);
  const [rowData, setRowData] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState([]);
  const [clearFrom, setClearFrom] = useState(false);
  const [onSelectedRowKeys, setOnSelectedRowKeys] = useState(null);
  const [selectedLane, setSelectedLane] = useState(null);
  const [selectedLaneTemp, setSelectedLaneTemp] = useState(null);

  const [disabledReceive, setDisabledReceive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [isModalCancelReceiveJobVisible, setIsModalCancelReceiveJobVisible] =
    useState(false);
  const [formData, setFormData] = useState(null);
  const [criteriaEarly, setCriteriaEarly] = useState(null);
  const [criteriaLate, setCriteriaLate] = useState(null);
  const [radioReceiveJob, setRadioReceiveJob] = useState(null);
  const [drivers, setDrivers] = useState([]);
  const [laneId, setLaneId] = useState(null);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const [isLoading, setIsLoading] = useState(false);
  const [trailerLaneLoading, setTrailerLaneLoading] = useState(false);

  const apiFindCurrentTimeReceivejobApi = async () => {
    let resData = null;
    try {
      let result = await dispatch(
        receivejobApi.endpoints.findCurrentTimeReceiveJob.initiate(null, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        resData = result.error;
      } else {
        if (result.data) {
          resData = result.data;
        }
      }

      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const {
    data: dataCriteria,
    error: errorCriteria,
    refetch: refetchCriteria,
  } = useFindAllCriteriaQuery();

  useEffect(() => {
    if (dataCriteria) {
      let arrEarly = dataCriteria.find((val) => val.code === 301);
      let arrLate = dataCriteria.find((val) => val.code === 302);
      setCriteriaEarly(arrEarly);
      setCriteriaLate(arrLate);
    }
  }, [dataCriteria]);

  useEffect(() => {
    if (errorCriteria) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load TrailerLane',
        okText: 'Retry',
        onOk: refetchCriteria,
        cancelText: 'Cancel',
      });
    }
  }, [errorCriteria, refetchCriteria]);

  const { data: dataTrailerLaneId, refetch: refetchTrailerLaneId } =
    useFindTrailerLaneByIdQuery(laneId, {
      skip: !laneId,
    });

  useEffect(() => {
    if (dataTrailerLaneId) {
      dataTrailerLaneIdRef.current = dataTrailerLaneId;
    }
  }, [dataTrailerLaneId]);

  const [findAllDriverConfirmByUser, { data: dataDriverConfirmByUsers }] =
    useLazyFindAllDriverConfirmByUserQuery();

  useInterval(() => {
    if (dataDriverConfirmByUser !== true && confirmRef.current) {
      findAllDriverConfirmByUser();
    }
  }, 5000); //5s

  useEffect(() => {
    if (dataDriverConfirmByUsers) {
      if (
        confirmRef.current &&
        dataDriverConfirmByUsers.confirm_status &&
        selectedLane
      ) {
        setDataDriverConfirmByUser(dataDriverConfirmByUsers.confirm_status);
      } else {
        setDataDriverConfirmByUser(null);
      }
    }
  }, [dataDriverConfirmByUsers]);

  const [
    createReceiveJob,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
    },
  ] = useCreateReceiveJobMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
  }, [errorCreating, setErrorData]);

  const [
    createDriverConfirm,
    {
      isSuccess: isDriverConfirmSuccessCreating,
      isUninitialized: isDriverConfirmUninitCreating,
      error: errorDriverConfirmCreating,
    },
  ] = useCreateDriverConfirmMutation();

  const [
    deleteReceiveJob,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
    },
  ] = useDeleteReceiveJobMutation();

  const checkLaneAndDriverConfirm = async () => {
    if (dataTrailerLaneIdRef.current) {
      let total_used = dataTrailerLaneIdRef.current.used + 1;
      if (
        total_used === dataTrailerLaneIdRef.current.amount ||
        total_used < dataTrailerLaneIdRef.current.amount
      ) {
        await onCreateReceiveJob();
      } else if (total_used > dataTrailerLaneIdRef.current.amount) {
        message.error('Error used more than > amount');
      }
    } else {
      message.error('Error Trailer lane not found');
    }
  };

  const onCreateReceiveJob = () => {
    let data = rowData.find((c) => c.id === onSelectedRowKeys[0]);

    let arrDriver = [];
    if (drivers.length > 1) {
      arrDriver = {
        driver_1: drivers[0]?.id,
        driver_2: drivers[1]?.id,
      };
    } else {
      arrDriver = {
        driver_1: drivers[0]?.id,
      };
    }
    let postDriverConfirm = {
      ...arrDriver,
      receive_job_datetime: moment(data.receive_job_datetime).format(),
      actual_receive_job_datetime: moment(data.actual_job_datetime).format(),
      reason: data.reason && data.reason.value && data.reason.value.id,
      reason_description:
        data.reason && data.reason.value && data.reason.value.description,
      dispatch_board: data.id,
      trailer_lane: selectedLane,
    };

    createReceiveJob(postDriverConfirm);
  };

  useEffect(() => {
    if (!isUninitCreating && isSuccessCreating) {
      message.success('Data was successfully saved');
      clearAll();
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessCreating, isUninitCreating]);

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      setIsModalCancelReceiveJobVisible(false);
      clearAll();
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccessDeleting, isUninitDeleting]);

  useEffect(() => {
    if (errorDriverConfirmCreating) {
      setErrorData(errorDriverConfirmCreating);
    }
  }, [errorDriverConfirmCreating, setErrorData]);

  useEffect(() => {
    if (!isDriverConfirmUninitCreating && isDriverConfirmSuccessCreating) {
      setDataDriverConfirmByUser(null);
    }
  }, [
    isDriverConfirmSuccessCreating,
    isDriverConfirmUninitCreating,
    dispatch,
    props,
  ]);

  function timeDiffCalc(dateNow, dateFuture) {
    let diffInMilliSeconds =
      Math.abs(moment(dateNow).diff(moment(dateFuture))) / 1000;
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    diffInMilliSeconds -= days * 24;
    // calculate hours
    let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    hours += days * 24;
    // calculate minutes
    let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    let difference = '';
    let differenceReason = '';

    difference += hours === 0 || hours === 1 ? `${hours}:` : `${hours}:`;

    difference += minutes === 0 || hours === 1 ? `${minutes}` : `${minutes}`;

    if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') >=
        criteriaLate.dateNow &&
      moment(dateNow).diff(moment(dateFuture), 'minutes') <= criteriaEarly.value
    ) {
      setIsModalVisible(false);
    } else if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') > criteriaLate.value
    ) {
      differenceReason = `+ ` + difference;
      if (radioRef.current !== 'cancel') {
        statusDiff.current = 'delay';
        setIsModalVisible(true);
      }
    } else if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') < criteriaEarly.value
    ) {
      differenceReason = `- ` + difference;
      if (radioRef.current !== 'cancel') {
        statusDiff.current = 'early';
        setIsModalVisible(true);
      }
    }

    if (moment(dateNow).isSameOrAfter(moment(dateFuture), 'minutes')) {
      difference = `+ ` + difference;
    } else {
      difference = `- ` + difference;
    }

    let objDif = {
      differenceReason: differenceReason,
      difference: difference,
    };
    return objDif;
  }
  const [columnsPopup] = useState(() => {
    let cols = [
      {
        title: 'From',
        dataIndex: ['from', 'short_name'],
        width: 100,
        align: 'center',
      },
      {
        title: 'Cluster',
        dataIndex: ['cluster', 'name'],
        width: 60,
        align: 'center',
      },
      {
        title: 'Calling No',
        dataIndex: ['calling_no', 'callingsheet_no'],
        width: 100,
        align: 'center',
      },
    ];
    return cols;
  });

  const [columnsCancelPopup] = useState(() => {
    let cols = [
      {
        title: 'From',
        dataIndex: ['dispatch_board', 'actual_from', 'name'],
        width: 100,
        align: 'center',
      },
      {
        title: 'Cluster',
        dataIndex: ['dispatch_board', 'cluster', 'name'],
        width: 60,
        align: 'center',
      },
      {
        title: 'Calling No.',
        dataIndex: ['callingsheet', 'callingsheet_no'],
        width: 100,
        align: 'center',
      },
    ];
    return cols;
  });
  const showModalConfirm = async () => {
    let findData = rowData.find((row) => row.id === onSelectedRowKeys[0]);
    if (radioReceiveJob === 'confirm') {
      confirmRef.current = null;
      setDataDriverConfirmByUser(null);
      await getReceiveJobsConfirm(findData.id);
    }

    setDisabledReceive(true);
    setIsModalConfirmVisible(true);
  };

  const btnReceiveCheck = async () => {
    try {
      setIsLoading(true);
      setIsModalConfirmVisible(false);
      let nowServer = await apiFindCurrentTimeReceivejobApi();
      let newData = [];
      for (let index = 0; index < rowData.length; index++) {
        if (onSelectedRowKeys[0] === rowData[index].id) {
          var then = rowData[index].receive_job_datetime;
          let timeDiff = timeDiffCalc(
            moment(nowServer).seconds(0).milliseconds(0).toISOString(),
            then
          );
          let reasonLabelText = {
            label: '-',
          };
          let reasonLabelDelay = {
            label: 'delay',
          };

          setReasonDelay(
            timeDiff?.differenceReason.includes('-') ||
              timeDiff?.differenceReason.includes('+')
              ? true
              : false
          );

          newData = [
            {
              ...rowData[index],
              actual_job_datetime: nowServer,
              diff: timeDiff?.difference,
              reason:
                timeDiff?.differenceReason.includes('-') ||
                timeDiff?.differenceReason.includes('+')
                  ? reasonLabelDelay
                  : reasonLabelText,
            },
          ];
        }
      }
      let res = rowData.map(
        (obj) => newData.find((o) => o.id === obj.id) || obj
      );

      let arrFinal = res.map((item) => ({
        ...item,
        actual_job_datetime:
          onSelectedRowKeys[0] === item?.id ? item?.actual_job_datetime : null,
        diff: onSelectedRowKeys[0] === item?.id ? item?.diff : null,
        reason:
          onSelectedRowKeys[0] === item?.id ? item?.reason : { label: null },
      }));

      setRowData(arrFinal);
      let postDriverConfirm = {
        dispatch_board: onSelectedRowKeys[0],
      };
      createDriverConfirm(postDriverConfirm);
    } catch (err) {
      console.log(`err`, err);
    } finally {
      setIsLoading(false);
    }
  };
  const btnReceive = () => {
    try {
      showModalConfirm();
      confirmRef.current = null;
      setDataDriverConfirmByUser(null);
    } catch (err) {
      console.log(`err`, err);
    }
  };
  const btnCancelReceive = async () => {
    try {
      await refetchTrailerLaneId();
      let findData = rowData.find((row) => row.id === onSelectedRowKeys[0]);
      setLaneId(findData && findData.id);
      cancelDataRowSelectRef.current = findData;
      await setIsModalCancelReceiveJobVisible(true);
    } catch (err) {
      console.log(`err`, err);
    }
  };
  const handleConfirmAction = () => {
    checkLaneAndDriverConfirm();
  };

  const btnSave = async () => {
    if (rowData) {
      let data = rowData.find((c) => c.id === onSelectedRowKeys[0]);
      if (onSelectedRowKeys[0] && data.actual_job_datetime) {
        let findLane = data.trailerLaneOptions.find(
          (t) => t.id === selectedLane
        );
        setLaneId(findLane && findLane.id);
        await refetchTrailerLaneId();
        Modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: 'Would you like to confirm for the receive job?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk: handleConfirmAction,
        });
      }
    }
  };

  const onSelectRow = (value) => {
    selectdTableRef.current = value;
    setOnSelectedRowKeys(value);
    setDisabledReceive(false);
    setSelectedLaneTemp(null);
    setReasonDelay(true);

    let arrFinal = defaultRowData.map((item) => ({
      ...item,
      actual_job_datetime:
        onSelectedRowKeys[0] === item?.id ? item?.actual_job_datetime : null,
      diff: onSelectedRowKeys[0] === item?.id ? item?.diff : null,
      reason:
        onSelectedRowKeys[0] === item?.id ? item?.reason : { label: null },
    }));

    setRowData(arrFinal);
    onCleckReloadLane(arrFinal);
  };
  const onClearSelectedRows = (value) => {
    setClearSelectedRows(value);
    setFormData(null);
    setReasonDelay(true);
  };
  const onClearSelectedLane = () => {
    setSelectedLane(null);
  };

  const onCleckReloadLane = async (payload) => {
    try {
      setTrailerLaneLoading(true);
      let arr = [];
      let label = [];
      for (let index = 0; index < payload.length; index++) {
        if (
          // payload[index]?.id === onSelectedRowKeys[0] &&
          payload[index]?.actual_from?.code
        ) {
          let laneIndex = await findTrailerLaneByCode(
            payload[index].actual_from?.code
          );
          let labelOption = laneIndex.map((item) => ({
            ...item,
            label: item?.lane_no,
            value: item?.id,
          }));

          label.push(labelOption);
        }
      }
      arr = payload.map((item, index) => ({
        ...item,
        trailerLaneOptions: [...label[index]],
      }));

      setRowData(arr);
    } catch (err) {
      console.log('err :>> ', err);
    } finally {
      setTrailerLaneLoading(false);
    }
  };

  const findTrailerLaneByCode = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          trailerApi.endpoints.findTrailerLaneByCode.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onQuery = async (payload) => {
    try {
      setDataDriverConfirmByUser(null);
      confirmRef.current = null;
      let arr = [];
      let label = [];
      for (let index = 0; index < payload.length; index++) {
        if (payload[index].actual_from?.code) {
          let laneIndex = await findTrailerLaneByCode(
            payload[index].actual_from?.code
          );

          let labelOption = laneIndex.map((item) => ({
            ...item,
            label: item?.lane_no,
            value: item?.id,
          }));
          label.push(labelOption);
        }
      }

      setSelectedLane(null);
      setDisabledReceive(false);
      setClearFrom(false);
      setIsModalVisible(false);
      setDefaultRowData(payload);

      if (radioRef.current === 'cancel') {
        let reasonLabelText = {
          label: '-',
        };

        arr = payload.map((item) => ({
          ...item,
          // trailerLaneOptions: [...trailerLaneOptionsRef.current],
          diff: timeDiffCalc(
            item.actual_receive_job_datetime,
            item.receive_job_datetime
          ).difference,
          reason:
            timeDiffCalc(
              item.actual_receive_job_datetime,
              item.receive_job_datetime
            ).differenceReason.includes('-') ||
            timeDiffCalc(
              item.actual_receive_job_datetime,
              item.receive_job_datetime
            ).differenceReason.includes('+')
              ? item.reason
              : reasonLabelText,
        }));
      } else {
        arr = payload.map((item, index) => ({
          ...item,
          trailerLaneOptions: [...label[index]],
        }));
      }

      setRowData(arr);

      if (payload) {
        let selected = null;
        for (let index = 0; index < defaultRowData.length; index++) {
          if (onSelectedRowKeys[0] === defaultRowData[index].id) {
            selected = defaultRowData[index].diff;
          }
        }
        if (selected) {
          setOnSelectedRowKeys(defaultRowData);
        } else {
          setOnSelectedRowKeys(payload.length > 0 ? [payload[0].id] : 0);
        }
      }
    } catch (err) {
      console.log(`err`, err);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const onRadioReceiveJob = (value) => {
    radioRef.current = value;
    setRadioReceiveJob(value);
  };

  const postReason = (value) => {
    let reasonLabel = {
      label: value.subject.substring(1, 4),
      value: value,
    };

    let newData = [];
    for (let index = 0; index < rowData.length; index++) {
      if (onSelectedRowKeys[0] === rowData[index].id) {
        newData = [
          {
            ...rowData[index],
            reason: reasonLabel,
          },
        ];
      }
    }
    setReasonDelay(false);
    let res = rowData.map((obj) => newData.find((o) => o.id === obj.id) || obj);
    setRowData(res);
  };

  const editReason = (value) => {
    setIsModalVisible(true);
    setFormData(value.reason.value);
  };

  const getReceiveJobsConfirm = async (value) => {
    let resData = null;
    if (value) {
      let result = await dispatch(
        receivejobApi.endpoints.findReceiveJobConfirmById.initiate(value, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          confirmRef.current = result.data;
          let rowNo = result.data.callings.length + 1;
          let resData = result.data.callings.map((d, i) => ({
            ...d,
            id: i + rowNo,
          }));
          confirmRowRef.current = resData;
          resData = result.data;
        }
      }
    }
    return resData;
  };

  const onCancelReceiveConfirm = async () => {
    if (radioReceiveJob === 'cancel') {
      let findData = rowData.find((row) => row.id === onSelectedRowKeys[0]);
      let bodyCancel = {
        id: findData && findData.id,
      };
      await deleteReceiveJob(bodyCancel);
    }
  };
  const onSelectedLane = (lane) => {
    setSelectedLane(lane);
    setSelectedLaneTemp(lane);
    setDisabledReceive(true);
  };
  const onClearLaneRef = () => {
    setSelectedLane(null);
  };

  const clearAll = () => {
    setFormData(null);
    radioRef.current = null;
    setDisabledReceive(false);
    setSelectedLaneTemp(null);
    setIsModalVisible(false);
    setSelectedLane(null);
    setReasonDelay(true);
    setClearFrom(true);
    setDefaultRowData([]);
    setRowData([]);
    confirmRef.current = null;
    confirmRowRef.current = null;
    cancelDataRowSelectRef.current = null;
    selectdTableRef.current = null;
    setRadioReceiveJob(null);
    setOnSelectedRowKeys(null);
    // Clear driver confirm flag for next receive job
    setDataDriverConfirmByUser(null);
  };
  const onDriverProp = (value) => {
    setDrivers(value);
  };

  return (
    <Container>
      <>
        <StyledPageHeader
          ghost={false}
          onBack={() => dispatch(goBack())}
          title='Receive Job & Cancel Receive Job'
          subTitle=''
        />
        <Content>
          <Form
            onQuery={onQuery}
            onClearSelectedRows={onClearSelectedRows}
            driverProp={onDriverProp}
            clearFrom={clearFrom}
            radioReceiveJob={onRadioReceiveJob}
            selectedLane={onClearSelectedLane}
          />

          <Divider style={{ margin: 20, width: 'unset', minWidth: 'unset' }} />

          <Title level={5} style={{ margin: 20 }}>
            <Row align='end' style={{ marginRight: 15 }}>
              <Col>
                <Space size={25}>
                  <Button
                    style={{ width: 100 }}
                    disabled={
                      !onSelectedRowKeys ||
                      radioReceiveJob === 'cancel' ||
                      disabledReceive ||
                      (disabledReceive && selectedLaneTemp)
                    }
                    type='primary'
                    onClick={() => btnReceive()}
                  >
                    Receive
                  </Button>

                  <Button
                    style={{ width: 100 }}
                    disabled={
                      (!selectedLane && dataDriverConfirmByUser !== true) ||
                      dataDriverConfirmByUser !== true
                    }
                    type='primary'
                    onClick={() => btnSave()}
                  >
                    Save
                  </Button>
                </Space>
              </Col>
            </Row>
          </Title>
          <Divider style={{ margin: 20, width: 'unset', minWidth: 'unset' }} />
          <ContainerTable align='end'>
            <div style={{ textAlign: 'right' }}>
              <Button
                disabled={reasonDelay}
                style={{ marginBottom: 10 }}
                icon={<ReloadOutlined />}
                size='small'
                onClick={() => {
                  onCleckReloadLane(rowData);
                }}
                loading={trailerLaneLoading}
              >
                Reload Trailer lane
              </Button>
            </div>
            <Table
              rowData={rowData}
              selectedRowKeyData={onSelectRow}
              onClearSelectedRows={clearSelectedRows}
              editReason={editReason}
              onSelectedRowKeys={onSelectedRowKeys}
              selectedLane={onSelectedLane}
              onClearLaneRef={onClearLaneRef}
              selectedClearLane={selectedLane}
            />
          </ContainerTable>
          <Row align='end' style={{ marginRight: '36px', marginTop: '20px' }}>
            <Space size={25}>
              <Button
                disabled={!onSelectedRowKeys || radioReceiveJob !== 'cancel'}
                type='primary'
                onClick={() => btnCancelReceive()}
              >
                Cancel receive
              </Button>
            </Space>
          </Row>
        </Content>
        <FormReason
          formData={formData}
          showModal={isModalVisible}
          setIsModalVisible={handleCancel}
          postReason={postReason}
          statusDiff={statusDiff.current}
        />
        <AntModal
          visible={isModalConfirmVisible}
          onCancel={() => {
            setIsModalConfirmVisible(false);
            setDisabledReceive(false);
          }}
          loading={isLoading}
          onOk={() => btnReceiveCheck()}
          titleModal={<Text>Confirm Receive Job</Text>}
          centered
          width={600}
          style={{ textAlign: 'center' }}
          maskClosable={false}
        >
          {confirmRef.current && (
            <>
              <AntTable
                rowKey={(record) => record.id}
                tableId={'receive_job_confirm'}
                bordered
                showSorterTooltip={false}
                columns={columnsPopup}
                dataSource={confirmRowRef.current}
                pagination={false}
                width={450}
              />
              <br />
              <Row align='center'>
                <div style={{ textAlign: 'left' }}>
                  Trailer no: {confirmRef.current.trailer[0].trailer_card_id}
                  <br />
                  Driver 1: {drivers.length > 0
                    ? drivers[0].tlep_code
                    : ''}{' '}
                  <br />
                  Driver 2:{' '}
                  {confirmRef.current.drivers.length > 1
                    ? drivers[1].tlep_code
                    : ''}{' '}
                  <br />
                  {confirmRef.current?.diff_from_calling?.map((d, index) => (
                    <>
                      <div style={{ color: '#0050b3', marginTop: '10px' }}>
                        Combine Calling: {d.combine_calling} <br />
                        From: {d.from} <br />
                        Cluster: {d.cluster}
                      </div>
                    </>
                  ))}
                </div>
              </Row>
            </>
          )}
        </AntModal>
        <AntModal
          visible={isModalCancelReceiveJobVisible}
          onCancel={() => setIsModalCancelReceiveJobVisible(false)}
          onOk={() => onCancelReceiveConfirm()}
          titleModal={
            <>
              {' '}
              Confirm <Text type='danger'>Cancel Receive Job</Text>{' '}
            </>
          }
          centered
          width={600}
          style={{ textAlign: 'center' }}
          maskClosable={false}
        >
          {cancelDataRowSelectRef.current && (
            <>
              <AntTable
                rowKey={'id'}
                tableId={'canecel_receive_job'}
                bordered
                showSorterTooltip={false}
                columns={columnsCancelPopup}
                dataSource={[cancelDataRowSelectRef.current]}
                pagination={false}
                width={450}
              />
              <br />

              <Row align='center'>
                <div style={{ textAlign: 'left' }}>
                  Trailer No:{' '}
                  {cancelDataRowSelectRef.current.trailer.trailer_card_id}
                  <br />
                  Driver 1:{' '}
                  {cancelDataRowSelectRef.current.driver_1
                    ? cancelDataRowSelectRef.current.driver_1.tlep_code
                    : ''}{' '}
                  <br />
                  Driver 2:{' '}
                  {cancelDataRowSelectRef.current.driver_2
                    ? cancelDataRowSelectRef.current.driver_2.tlep_code
                    : ''}{' '}
                  <br />
                </div>
              </Row>
            </>
          )}
        </AntModal>
      </>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;
const ContainerTable = styled.div`
  padding: 0 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default ReceiveJobComponent;
