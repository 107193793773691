import React, { useState, useRef, useEffect } from "react";
import AntTable from "common_components/AntTable";
import styled from "styled-components";

const TableOverAllComponent = (props) => {
  const {
    dataSourceTable,
    setDataSourceTable,
    selectedRows,
    setSelectedRows,
    selectedRowKeys,
    setSelectedRowKeys,
    isLoadingInite,
    statusCheck,
  } = props;
  const selectedRowRef = useRef();
  const selectedRowKeyRef = useRef();

  function styleBackgroundTotal(record) {
    if (record.company === "Total") {
      return { background: "#E4EEFA" };
    }
  }
  function styleTextColorStatus(record) {
    if (record.status === "Complete") {
      return { color: "#27A73B" };
    } else if (record.status === "On making TTT") {
      return { color: "red" };
    } else if (record.status === "Reply from BzP") {
      return { color: "black" };
    } else if (record.status === "BzP Checking") {
      return { color: "#EB8D00" };
    } else {
      return { color: "black" };
    }
  }

  const [columns, setColumns] = useState([
    {
      title: () => {
        return <b>Company</b>;
      },
      dataIndex: "company",
      width: 100,
      align: "center",
      className: "bg-grey",
      render: (text, record, index) => {
        return {
          props: {
            style: styleBackgroundTotal(record),
          },
          children: <h4>{text ? text : "-"}</h4>,
        };
      },
    },
    {
      title: () => {
        return <b>Status</b>;
      },
      dataIndex: "status",
      align: "center",
      className: "bg-grey",
      render: (text, record, index) => {
        return {
          props: {
            style: styleBackgroundTotal(record),
          },
          children: (
            <h4 style={styleTextColorStatus(record)}>{text ? text : ""}</h4>
          ),
        };
      },
    },

    {
      title: () => {
        return (
          <div>
            <b>Record Detail</b>
          </div>
        );
      },
      className: "bg-grey",
      align: "center",
      children: [
        {
          title: () => {
            return <b>Total</b>;
          },
          align: "center",
          dataIndex: "total",
          className: "color-head-6-grey",
          key: 1,

          render: (text, record, index) => {
            return {
              props: {
                style: styleBackgroundTotal(record),
              },
              children: <h4>{text ? text : 0}</h4>,
            };
          },
        },
        {
          title: () => {
            return <b>Blank</b>;
          },

          align: "center",
          dataIndex: "blank",
          className: "color-head-6-green ",
          key: 2,
          render: (text, record, index) => {
            return {
              props: {
                style: styleBackgroundTotal(record),
              },
              children: <h4>{text ? text : 0}</h4>,
            };
          },
        },
        {
          title: () => {
            return <b>Accept</b>;
          },

          align: "center",
          dataIndex: "accept",
          className: "color-head-6-orange",
          key: 3,
          render: (text, record, index) => {
            return {
              props: {
                style: styleBackgroundTotal(record),
              },
              children: <h4>{text ? text : 0}</h4>,
            };
          },
        },
        {
          title: () => {
            return <b>Request</b>;
          },

          align: "center",
          dataIndex: "request",
          className: "color-head-6-pink",
          key: 3,
          render: (text, record, index) => {
            return {
              props: {
                style: styleBackgroundTotal(record),
              },
              children: <h4>{text ? text : 0}</h4>,
            };
          },
        },
        {
          title: () => {
            return <b>Reject</b>;
          },

          align: "center",
          dataIndex: "reject",
          className: "color-head-6-red",
          key: 3,
          render: (text, record, index) => {
            return {
              props: {
                style: styleBackgroundTotal(record),
              },
              children: <h4>{text ? text : 0}</h4>,
            };
          },
        },
      ],
    },
  ]);
  const [selectionType, setSelectionType] = useState("checkbox");
  useEffect(() => {
    setSelectedRows(selectedRowRef.current);
  }, [selectedRows, selectedRowRef.current]);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);
      selectedRowRef.current = selectedRows;
      selectedRowKeyRef.current = selectedRowKeys;
    },
    getCheckboxProps: (record) => ({
      disabled: record.company === "Total",
      // Column configuration not to be checked
      company: record.company,
    }),
  };
  return (
    <Container style={{ paddingTop: "10px" }}>
      <StyledAntTable
        rowKey="company"
        tableId={"company"}
        bordered
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSourceTable}
        pagination={false}
        scroll={{ x: "max-content" }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default TableOverAllComponent;
