import React, { useEffect } from 'react';
import { Row, Col, Button, Select, Typography, Collapse } from 'antd';
import styled from 'styled-components';
import './index.css';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
const { Option } = Select;
const ToolbarComponent = (props) => {
  const {
    onInit,
    filterWorkingDate,
    setFilterWorkingDate,
    filterOptionWorkingDate,
    filterCompany,
    setFilterCompany,
    filterCompanyOption,
    filterStatusPoint,
    setFilterStatusPoint,
    filterStatusPointOption,
    filterStatus,
    setFilterStatus,
    filterStatusOption,
    filterRegion,
    setFilterRegion,
    filterRegionOption,
    filterShift,
    setFilterShift,
    filterShiftOption,
    filterJobcode,
    setFilterJobcode,
    filterJobcodeOption,
    filterTripType,
    setFilterTripType,
    filterTripTypeOption,
    filterTrailerType,
    setFilterTrailerType,
    filterTrailerTypeOption,
    filterFrom,
    setFilterFrom,
    filterFromOption,
    filterTo,
    setFilterTo,
    filterToOption,
    isLoadingFilterDropdown,
    apiSearchTable,
    filterAndDowlandEnable,
    isLoading,
    setFilterMatch,
    setFilterTour,
  } = props;

  const location = useLocation();
  const { Panel } = Collapse;
  const { company, matched, tour, working_day } = useParams();
  useEffect(() => {
    if (company && filterCompanyOption) {
      let newArrCompany = [];

      let arr = company.split(',');

      for (let index = 0; index < arr.length; index++) {
        let filterCompany = filterCompanyOption?.find(
          (f) => f.label === arr[index]
        );

        newArrCompany.push(filterCompany?.value);
      }
      setFilterCompany(newArrCompany);

      if (onInit) {
        let search = {
          company: newArrCompany ? newArrCompany : filterCompany,
          working_day: filterWorkingDate,
          start_point: filterStatusPoint,
          status: filterStatus,
          region: filterRegion,
          shift: filterShift,
          job_code: filterJobcode,
          trip_type: filterTripType,
          trailer_type: filterTrailerType,
          from: filterFrom,
          to: filterTo,
        };
        onInit(search);
      }
    }
  }, [filterCompanyOption]);

  useEffect(() => {
    if (matched || tour || working_day) {
      setFilterWorkingDate(working_day);

      setFilterMatch(matched);
      setFilterTour(tour);
      if (onInit) {
        let search = {
          matched: matched === 'total' ? null : matched,
          tour: tour === 'total' ? null : tour,
          company: filterCompany,
          working_day: working_day ? working_day : filterWorkingDate,
          start_point: filterStatusPoint,
          status: filterStatus,
          region: filterRegion,
          shift: filterShift,
          job_code: filterJobcode,
          trip_type: filterTripType,
          trailer_type: filterTrailerType,
          from: filterFrom,
          to: filterTo,
        };
        onInit(search);
      }
    }
  }, [matched, tour, working_day]);

  useEffect(() => {
    if (location?.pathname === '/app/planning/manage_tour_list' && onInit) {
      let search = {
        matched: matched === 'total' ? null : matched,
        tour: tour === 'total' ? null : tour,
        company: filterCompany,
        working_day: filterWorkingDate,
        start_point: filterStatusPoint,
        status: filterStatus,
        region: filterRegion,
        shift: filterShift,
        job_code: filterJobcode,
        trip_type: filterTripType,
        trailer_type: filterTrailerType,
        from: filterFrom,
        to: filterTo,
      };
      onInit(search);
    }
  }, [onInit]);

  const onBtnSearch = async () => {
    let search = {
      matched: matched === 'total' ? null : matched,
      tour: tour === 'total' ? null : tour,
      company: filterCompany,
      working_day: filterWorkingDate,
      start_point: filterStatusPoint,
      status: filterStatus,
      region: filterRegion,
      shift: filterShift,
      job_code: filterJobcode,
      trip_type: filterTripType,
      trailer_type: filterTrailerType,
      from: filterFrom,
      to: filterTo,
    };
    apiSearchTable(search);
  };

  return (
    <div>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[20]}
            style={{ marginBottom: '5px' }}
            align='middle'
            justify='center'
          >
            <Col span={4} align='start'>
              <Typography>Working Date</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                mode='multiple'
                showSearch
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                loading={isLoadingFilterDropdown}
                maxTagCount='responsive'
                placeholder={'working date'}
                value={filterWorkingDate}
                onChange={(values) => {
                  setFilterWorkingDate(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                size='small'
              >
                {filterOptionWorkingDate?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={4} align='start'>
              <Typography>Company</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                mode='multiple'
                showSearch
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                size='small'
                allowClear={true}
                placeholder={'Company'}
                value={filterCompany}
                onChange={(values) => {
                  setFilterCompany(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterCompanyOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={4} align='start'>
              <Typography>Start point</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                showSearch
                defaultValue={'All'}
                style={{
                  width: '100%',
                }}
                size='small'
                allowClear={true}
                placeholder={'Start point'}
                value={filterStatusPoint}
                onChange={(values) => {
                  setFilterStatusPoint(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterStatusPointOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={4} align='start'>
              <Typography>Status</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                showSearch
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                size='small'
                allowClear={true}
                placeholder={'Status'}
                value={filterStatus}
                onChange={(values) => {
                  setFilterStatus(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterStatusOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={4} align='start'>
              <Typography>Region (To)</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                showSearch
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                size='small'
                allowClear={true}
                placeholder={'Region (To)'}
                value={filterRegion}
                onChange={(values) => {
                  setFilterRegion(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterRegionOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={4} align='start'>
              <Typography>Shift</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                showSearch
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                size='small'
                allowClear={true}
                placeholder={'Shift'}
                value={filterShift}
                onChange={(values) => {
                  setFilterShift(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterShiftOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row gutter={[20]} align='middle' justify='center'>
            <Col span={4} align='start'>
              <Typography>Job code</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                mode='multiple'
                style={{
                  width: '100%',
                }}
                allowClear={true}
                defaultValue={'All'}
                size='small'
                placeholder={'Job code'}
                value={filterJobcode}
                onChange={(values) => {
                  setFilterJobcode(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterJobcodeOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={4} align='start'>
              <Typography>Trip type</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                mode='multiple'
                style={{
                  width: '100%',
                }}
                allowClear={true}
                defaultValue={'All'}
                size='small'
                placeholder={'Trip type'}
                value={filterTripType}
                onChange={(values) => {
                  setFilterTripType(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterTripTypeOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={4} align='start'>
              <Typography>Trailer type</Typography>
              <Select
                disabled={filterAndDowlandEnable}
                mode='multiple'
                style={{
                  width: '100%',
                }}
                allowClear={true}
                defaultValue={'All'}
                size='small'
                placeholder={'Trailer type'}
                value={filterTrailerType}
                onChange={(values) => {
                  setFilterTrailerType(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterTrailerTypeOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col span={4} align='start'>
              <Typography>From</Typography>
              <Select
                mode='multiple'
                disabled={filterAndDowlandEnable}
                showSearch
                style={{
                  width: '100%',
                }}
                allowClear={true}
                defaultValue={'All'}
                size='small'
                placeholder={'From'}
                value={filterFrom}
                onChange={(values) => {
                  setFilterFrom(values);
                }}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterFromOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={4} align='start'>
              <Typography>To</Typography>
              <Select
                mode='multiple'
                disabled={filterAndDowlandEnable}
                showSearch
                style={{
                  width: '100%',
                }}
                allowClear={true}
                defaultValue={'All'}
                size='small'
                placeholder={'To'}
                value={filterTo}
                onChange={(values) => {
                  setFilterTo(values);
                }}
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterToOption?.map((c, index) => (
                  <Option key={index} value={c.value}>
                    {c.label}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col span={4} align='start'>
              <Button
                type='primary'
                size='small'
                style={{ width: '80%', marginTop: '20px' }}
                onClick={onBtnSearch}
                loading={isLoading}
                disabled={filterAndDowlandEnable}
              >
                Search
              </Button>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </div>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;

export default ToolbarComponent;
