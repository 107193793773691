import React, { useEffect } from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { PageHeader, Divider} from 'antd';
import styled from 'styled-components';
import { setSidebarVisibility } from 'reducers/ui';
import Table from './Table';
const ChangeReasonLaneComponent = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Change Reason & Lane'
        subTitle=''
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Table />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default ChangeReasonLaneComponent;
