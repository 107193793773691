import React from 'react';
import { Result, Button, Space } from 'antd';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { push } from 'connected-react-router';

const PageNoPermission = (props) => {
  const dispatch = useDispatch();

  return (
    <StyledResult
      status='warning'
      title='You do not have permission to perform this action.'
      extra={
        <Space size='large'>
          <Button
            type='primary'
            onClick={() => {
              dispatch(goBack());
            }}
          >
            Back
          </Button>
          <Button
            type='primary'
            onClick={() => {
              dispatch(push('/login'));
            }}
          >
            Go to login
          </Button>
        </Space>
      }
    />
  );
};

const StyledResult = styled(Result)`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
`;
export default PageNoPermission;
