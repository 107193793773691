const WebsocketPrintClient = (() => {
  // Instance stores a reference to the singleton
  let instance;

  const init = (options) => {
    // Private methods and variables
    const defaults = {
      url: 'ws://127.0.0.1:12212/printer',
      onConnect: function () {},
      onDisconnect: function () {},
      onUpdate: function () {},
      onSend: function () {},
      autoReconnect: true,
      maxRetryCount: 30,
    };

    let settings = { ...defaults, ...options };
    let websocket = null;
    let connected = false;
    let retryCount = 0;

    const onMessage = (evt) => {
      settings.onUpdate(evt.data);
    };

    const onConnect = () => {
      connected = true;
      settings.onConnect();
    };

    const onDisconnect = () => {
      connected = false;
      settings.onDisconnect();
      websocket = null;
      if (settings?.autoReconnect && retryCount < defaults.maxRetryCount) {
        retryCount += 1;
        reconnect();
      }
    };

    const connect = () => {
      if (websocket === null) {
        websocket = new WebSocket(settings.url);
        websocket.onopen = onConnect;
        websocket.onclose = onDisconnect;
        websocket.onmessage = onMessage;
      }
    };

    const reconnect = () => {
      connect();
    };

    // Start connect websocket
    connect();

    return {
      // Public methods and variables
      config: (options) => {
        settings = { ...settings, ...options };
      },
      connect: () => {
        connect();
      },
      submit: (data) => {
        if (Array.isArray(data)) {
          data.forEach(function (element) {
            websocket.send(JSON.stringify(element));
          });
        } else {
          websocket.send(JSON.stringify(data));
        }
        if (settings?.onSend) {
          settings?.onSend();
        }
      },
      close: () => {
        if (!websocket) return;
        websocket.close();
        websocket = null;
      },
      isConnected: () => {
        return connected;
      },
    };
  };

  return {
    // Get the singleton instance if one exists
    // or create one if it doesn't
    getInstance: (options) => {
      if (!instance) {
        instance = init(options);
      }

      return instance;
    },
  };
})();

const wsPrintClient = WebsocketPrintClient.getInstance();

export default wsPrintClient;
