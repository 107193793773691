import React from 'react';
import { useHistory } from 'react-router-dom';
import { showError } from '../utils/notistack';
import PageNotFound from './PageNotFound';
import PageNoPermission from './PageNoPermission';
import PageError500 from './PageError500';
// A context will be the way that we allow components lower down
// the tree to trigger the display of an error page
const ErrorHandlerContext = React.createContext();

// The top level component that will wrap our app's core features
export const ErrorHandler = ({ children }) => {
  const history = useHistory();
  const [errorData, setErrorData] = React.useState();

  // Make sure to "remove" this status code whenever the user
  // navigates to a new URL. If we didn't do that, then the user
  // would be "trapped" into error pages forever
  React.useEffect(() => {
    // Listen for changes to the current location.
    const unlisten = history.listen(() => setErrorData(undefined));
    // cleanup the listener on unmount
    return unlisten;
  }, [history]);

  // This is what the component will render. If it has an
  // errorData that matches an API error, it will only render
  // an error page. If there is no error status, then it will render
  // the children as normal
  const renderContent = () => {
    if (errorData) {
      if (errorData.status === 404 || errorData.originalStatus === 404) {
        return <PageNotFound />;
      } else if (errorData.status === 401 || errorData.originalStatus === 401) {
        return <PageNoPermission />;
      } else if (errorData.status === 500 || errorData.originalStatus === 500) {
        return <PageError500 />;
      } else {
        // Defer a bit to prevent warning Cannot update during an existing state transition
        setTimeout(() => {
          showError(errorData.data, true);
        }, 100);
      }
    }
    return children;
  };

  // We wrap it in a useMemo for performance reasons. More here:
  // https://kentcdodds.com/blog/how-to-optimize-your-context-value/
  const contextPayload = React.useMemo(
    () => ({ setErrorData }),
    [setErrorData]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ErrorHandlerContext.Provider value={contextPayload}>
      {renderContent()}
    </ErrorHandlerContext.Provider>
  );
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useErrorHandler = () => React.useContext(ErrorHandlerContext);
