import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Button,
  Space,
  Select,
  Typography,
  Row,
  message,
  Col,
  Input,
} from 'antd';
import styled from 'styled-components';

import { useErrorHandler } from 'common_components/ErrorContext';
import { useDispatch } from 'react-redux';
import { setConfirmDriverTrailerFilter } from 'reducers/dispatchBoard/ConfirmDriverTrailer';
import { confirmDriverTrailerApi } from 'reducers/dispatchBoard/ConfirmDriverTrailer/api';
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;
const createPayload = ({
  filterTrailerType,
  filterTrailer,
  filterFuelType,
  bzpId,
  unit,
  cluster_Id,
}) => {
  return {
    trailer_type_id: filterTrailerType,
    trailer_id: filterTrailer,
    fuel_type_id: filterFuelType,
    bzp_id: bzpId,
    loading_unit: unit,
    actual_cluster_to_id: cluster_Id,
  };
};

const TableToolbar = (props) => {
  const {
    isLoading,
    onInit,
    onQuery,
    selectedRows,
    bzp,
    loadingUnit,
    clearTable,
    clusterId,
  } = props;

  const dispatch = useDispatch();

  const clusterIdRef = useRef();
  const { setErrorData } = useErrorHandler();
  const [trailerTypesOptions, setTrailerTypesOptions] = useState([]);
  const [bzpId, setBzpId] = useState(bzp);
  const [unit, setUnit] = useState(loadingUnit);
  const [cluster_Id, setCluster_Id] = useState(clusterId);
  const [fuelTypesOptions, setFuelTypesOptions] = useState([]);
  const [trailersOptions, setTrailersOptions] = useState([]);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [filterTrailerType, setfilterTrailerType] = useState(['All']);
  const [filterTrailer, setfilterTrailer] = useState(['All']);
  const [filterFuelType, setfilterFuelType] = useState(['All']);
  const defaultFilter = useMemo(() => {
    return {
      filterTrailerType: ['All'],
      filterFuelType: ['All'],
      filterTrailer: ['All'],
      bzpId: bzp,
      unit: loadingUnit,
      actual_cluster_to: clusterId,
    };
  }, [bzp, loadingUnit, clusterId]);

  const resetToDefault = useCallback(() => {
    setfilterTrailerType(defaultFilter.filterTrailerType);
    setfilterFuelType(defaultFilter.filterFuelType);
    setfilterTrailer(defaultFilter.filterTrailer);
    setBzpId(defaultFilter.bzpId);
    setUnit(defaultFilter.unit);
    setCluster_Id(defaultFilter.cluster_Id);
    onDisplayButton();
  }, [
    defaultFilter.filterTrailerType,
    defaultFilter.filterFuelType,
    defaultFilter.filterTrailer,
    defaultFilter.bzpId,
    defaultFilter.unit,
    defaultFilter.cluster_Id,
  ]);

  const onQueryOption = useCallback(
    async (payload) => {
      try {
        let payloadNew = {
          ...payload,
          actual_cluster_to_id: clusterIdRef.current,
        };
        setIsLoadingFilter(true);
        let result = await dispatch(
          confirmDriverTrailerApi.endpoints.findAllTrailersBySelection.initiate(
            payloadNew,
            {
              forceRefetch: true,
            }
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let arrTrailerTypes = [
              {
                label: 'All',
                value: 'All',
              },
            ];

            let arrFuelTypes = [
              {
                label: 'All',
                value: 'All',
              },
            ];
            let arrTrailers = [
              {
                label: 'All',
                value: 'All',
              },
            ];
            let trailerTypes = result.data.trailer_types.map((d) => ({
              label: d.name,
              value: d.id,
            }));
            let fuelTypes = result.data.fuel_types.map((d) => ({
              label: d.name,
              value: d.id,
            }));
            let trailers = result.data.trailers.map((d) => ({
              label: d.trailer_card_id,
              value: d.id,
            }));

            arrTrailerTypes.push(...trailerTypes);
            arrFuelTypes.push(...fuelTypes);
            arrTrailers.push(...trailers);

            setTrailerTypesOptions(arrTrailerTypes);
            setFuelTypesOptions(arrFuelTypes);
            setTrailersOptions(arrTrailers);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      }
      setIsLoadingFilter(false);
    },
    [dispatch, setErrorData]
  );

  useEffect(() => {
    if (clearTable) {
      resetToDefault();
    }
  }, [clearTable, resetToDefault]);

  useEffect(() => {
    if (clusterId) {
      clusterIdRef.current = clusterId;
    }
  }, [clusterId]);

  useEffect(() => {
    if (bzp && loadingUnit && clusterId) {
      setBzpId(bzp);
      setUnit(loadingUnit);
      setCluster_Id(clusterId);
      if (onInit) {
        onQueryOption(createPayload(defaultFilter));
        onQuery(createPayload(defaultFilter));
      }
    }
  }, [bzp, loadingUnit, clusterId, onInit]);

  useEffect(() => {
    dispatch(
      setConfirmDriverTrailerFilter({
        filterTrailerType,
        filterFuelType,
        filterTrailer,
        trailerTypesOptions,
        fuelTypesOptions,
        trailersOptions,
        bzpId,
        unit,
        cluster_Id,
      })
    );
  }, [
    filterTrailerType,
    filterFuelType,
    filterTrailer,
    trailerTypesOptions,
    fuelTypesOptions,
    trailersOptions,
    bzpId,
    dispatch,
    unit,
    cluster_Id,
  ]);

  const onDisplayButton = () => {
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterTrailerType,
          filterFuelType,
          filterTrailer,
          bzpId,
          unit,
          cluster_Id,
        }),
      });

      onQueryOption({
        ...createPayload({
          filterTrailerType,
          filterFuelType,
          filterTrailer,
          bzpId,
          unit,
          cluster_Id,
        }),
      });
    }
  };
  const handleEnterTrailer = (event) => {
    if (event.keyCode === 13) {
      onDisplayButton();
    }
  };
  return (
    <Container>
      <Space style={{ padding: 10 }}>
        <Space size={16}>
          <Space direction='vertical'>
            <Row gutter={[12]}>
              <Col>
                <Space direction='horizontal'>
                  <StyledText>Trailer</StyledText>
                  <Input
                    value={selectedRows && selectedRows}
                    style={{
                      width: '240px',
                      color: '#000',
                      backgroundColor: 'transparent',
                    }}
                    disabled
                  />
                </Space>
              </Col>
            </Row>
            <Row gutter={[12]}>
              <Col>
                <Space direction='horizontal'>
                  <StyledText>Trailer Type</StyledText>
                  <Select
                    style={{
                      width: '160px',
                    }}
                    allowClear={true}
                    maxTagCount='responsive'
                    placeholder={'Trailer Type'}
                    loading={isLoadingFilter}
                    value={filterTrailerType}
                    onChange={(values) => {
                      setfilterTrailerType(values);
                    }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    onKeyDown={(e) => {
                      handleEnterTrailer(e);
                    }}
                  >
                    {trailerTypesOptions &&
                      trailerTypesOptions.map((c, index) => (
                        <Option key={index} value={c.value}>
                          {c.label}
                        </Option>
                      ))}
                  </Select>
                </Space>
              </Col>
              <Col>
                <Space direction='horizontal'>
                  <>
                    <StyledText>Fuel Type</StyledText>
                    <Select
                      style={{
                        width: '160px',
                      }}
                      allowClear={true}
                      maxTagCount='responsive'
                      loading={isLoadingFilter}
                      placeholder={'Fuel Type'}
                      value={filterFuelType}
                      onChange={(values) => {
                        setfilterFuelType(values);
                      }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onKeyDown={(e) => {
                        handleEnterTrailer(e);
                      }}
                    >
                      {fuelTypesOptions &&
                        fuelTypesOptions.map((c, index) => (
                          <Option key={index} value={c.value}>
                            {c.label}
                          </Option>
                        ))}
                    </Select>
                  </>
                </Space>
              </Col>
            </Row>
            <Row>
              <Space size={88}>
                <Col>
                  <Space direction='horizontal'>
                    <StyledText>Trailer</StyledText>
                    <Select
                      style={{
                        width: '240px',
                      }}
                      allowClear={true}
                      maxTagCount='responsive'
                      placeholder={'Trailer'}
                      loading={isLoadingFilter}
                      value={filterTrailer}
                      onChange={(values) => {
                        setfilterTrailer(values);
                      }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onKeyDown={(e) => {
                        handleEnterTrailer(e);
                      }}
                    >
                      {trailersOptions &&
                        trailersOptions.map((c, index) => (
                          <Option key={index} value={c.value}>
                            {c.label}
                          </Option>
                        ))}
                    </Select>
                  </Space>
                </Col>
                <Col>
                  <Space direction='vertical'>
                    <>
                      <Button
                        type='primary'
                        loading={isLoading}
                        onClick={() => onDisplayButton()}
                        icon={<SearchOutlined />}
                      >
                        Search
                      </Button>
                    </>
                  </Space>
                </Col>
              </Space>
            </Row>
          </Space>
        </Space>
      </Space>
    </Container>
  );
};

const Container = styled.div`
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
  padding: 10px 0;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
export default TableToolbar;
