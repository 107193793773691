/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Button, Select, Modal, Popover, TimePicker } from 'antd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  dateTimeFormatterWorking,
  timeFormatterNoHyphen,
} from 'common_components/AntTable/tableUtils';

import moment from 'moment';
import {
  CheckCircleOutlined,
  CalendarOutlined,
  RightSquareFilled,
  CheckOutlined,
  DownloadOutlined,
} from '@ant-design/icons';
import './index.css';
import { push } from 'connected-react-router';
import { StyledAntTable } from './misc';
import ApplyJobcodeModal from './ApplyJobcodeModal';
import EditRecvJobTimeModal from './EditRecvJobTimeModal';

const TableComponent = (props) => {
  const {
    dataSourceTable,
    headerDateTableRef,
    PAGE_SIZE,
    totalRows,
    page,
    offset,
    payloadRef,
    LIMIT,
    setPage,
    executeQuery,
    isLoading,
    getEditInit,
    dataTableEditInit,
    loadingDataTableEditInit,
    loadingDataTableEditOptionInit,
    filterCompanyEdit,
    setFilterCompanyEdit,
    filterCompanyEditOption,
    filterTripTypeEdit,
    setFilterTripTypeEdit,
    filterTripTypeEditOption,
    filterTrailerTypeEdit,

    setFilterTrailerTypeEdit,
    filterTrailerTypeEditOption,
    filterMatchJobCodeEdit,
    filterMatchJobCodeEditOption,
    setFilterMatchJobCodeEdit,
    setFilterJobCodeEdit,
    filterJobCodeEditOption,
    filterJobCodeEdit,
    receiveJobTimeField,
    dataTableEdit,
    isLoadingEdit,

    dataHourCalEdit,
    modalSaveEdit,
    isLoadingCheckAndSave,
    handleOk,
    isEditRecvJobTimeModalVisible,
    isManualJobcodeModalVisible,
    openPopupEdit,
    deleteManageTour,
    releaseJobcode,
    purgeTour,
    onBtnResetEdit,
    onBtnBackEdit,
    onBtnDownload,
    onBtnDownloadJobCodeCalendar,
    isLoadingDownloadJobCodeCSV,
    isLoadingDownloadCSV,
    checkCalculate,
    setCheckCalculate,
    dataInitRef,
    setFilterJobCodeEditOption,
    filterAndDowlandEnable,
    tourIdRef,
    executeQueryEdit,
    onBtnCheck,
    refetchDataAll,
  } = props;
  const rowSelectDataRef = useRef();
  const dataTableEditInitRef = useRef();
  const filterJobCodeEditRef = useRef();
  const dataHourCalEditRef = useRef();
  const dataInitPeriodRef = useRef();
  const dispatch = useDispatch();
  const [isModalVisibleDeleteData, setIsModalVisibleDeleteData] =
    useState(false);
  const [IsReleaseTourModalVisible, setIsReleaseTourModalVisible] =
    useState(false);
  const { Option } = Select;
  const btnBackEdit = () => {
    onBtnBackEdit();
  };

  useEffect(() => {
    if (dataInitRef) {
      dataInitPeriodRef.current = dataInitRef;
    }
  }, [dataInitRef]);

  const contentMemo = (value) => {
    return (
      <>
        <h4>
          <RightSquareFilled style={{ color: 'grey' }} />{' '}
          {value?.manage_tour_list_memo_by?.fullname_th}
        </h4>
        <h6 style={{ color: 'grey' }}>
          {value?.manage_tour_list_memo_at
            ? moment(value?.manage_tour_list_memo_at).format('DD/MM/YY HH:mm')
            : null}{' '}
        </h6>
        <h4 style={{ whiteSpace: 'pre-line' }}>
          {value?.manage_tour_list_memo}
        </h4>
      </>
    );
  };

  const ModalEditTable = async (job_code) => {
    getEditInit(rowSelectDataRef.current?.id, job_code);
    const opt = job_code.substring(0, 3) === 'TEM' ? 'jobcode' : 'recv-time';
    console.log('opt: ', opt);
    openPopupEdit(opt);
  };
  useEffect(() => {
    if (dataHourCalEdit) {
      dataHourCalEditRef.current = dataHourCalEdit;
    }
  }, [dataHourCalEdit]);

  useEffect(() => {
    if (dataTableEditInit) {
      dataTableEditInitRef.current = dataTableEditInit;
    }
  }, [dataTableEditInit]);

  const columns = [
    {
      title: 'Status',
      dataIndex: 'manage_tour_list_status',
      align: 'center',
      className: 'color-head-6',
      fixed: 'left',
      width: 80,
    },

    {
      title: 'Memo',
      dataIndex: 'manage_tour_list_memo',
      align: 'center',
      className: 'color-head-6',
      fixed: 'left',
      width: 50,
      render: (text, record) => {
        return record?.manage_tour_list_status === 'BzP checking' ||
          record?.manage_tour_list_status === '-' ? (
          ''
        ) : text ? (
          <Popover content={contentMemo(record)} placement='bottom'>
            <CheckCircleOutlined style={{ color: 'green' }} />
          </Popover>
        ) : (
          ''
        );
      },
    },

    {
      title: 'Job code',
      dataIndex: 'job_code',
      align: 'center',
      className: 'color-head-6',
      fixed: 'left',
      width: 80,
    },
    {
      title: 'Trailer type',
      dataIndex: 'trailer_type',
      align: 'center',
      className: 'color-head-6',
      fixed: 'left',
      width: 70,
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      align: 'center',
      className: 'color-head-6',
      fixed: 'left',
      width: 40,
    },
    {
      title: 'Trip type',
      dataIndex: 'trip_type',
      align: 'center',
      className: 'color-head-6',
      fixed: 'left',
      width: 80,
    },
    {
      title: 'Working hour',
      dataIndex: 'working_hour',
      align: 'center',
      className: 'color-head-6',
      fixed: 'left',
      width: 60,
    },

    {
      title: 'Trip No.1',
      align: 'center',
      className: 'color-head-3',
      children: [
        {
          title: 'From',
          className: 'bg_from_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'To',
              dataIndex: 'trip_1_to',
              className: 'bg_to_trip_no',
              align: 'center',
              width: 70,
              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#d8e2dc',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record?.trips[0]?.location_from?.name ? (
                          record?.trips[0]?.location_from?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b>
                        {record?.trips[0]?.location_to?.name ? (
                          record?.trips[0]?.location_to?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Receive',

          className: 'bg_receive_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Arrive',
              dataIndex: 'trip_1_arrival_time',
              className: 'bg_arrive_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#ffe5d9',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record.trips[0]?.receive_job_time ? (
                          <div>
                            {(record?.trips[0]?.receive_job_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[0]?.receive_job_time?.day_offset !==
                            0
                              ? '(+' +
                                record?.trips[0]?.receive_job_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[0]?.arrival_time ? (
                          <div>
                            {(record?.trips[0]?.arrival_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[0]?.arrival_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[0]?.arrival_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Out',
          className: 'bg_out_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Departure',
              dataIndex: 'trip_1_yard_out_time',
              className: 'bg_out_color_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#F9CE7D',
                    },
                  },
                  children: (
                    <div>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[0]?.yard_out_time ? (
                          <div>
                            {(record?.trips[0]?.yard_out_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[0]?.yard_out_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[0]?.yard_out_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[0]?.departure_time ? (
                          <div>
                            {(record?.trips[0]?.departure_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[0]?.departure_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[0]?.departure_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },

        {
          title: 'Unit',
          dataIndex: ['trip_1_unit'],
          align: 'center',
          className: 'bg-unit-trip_no',
          width: 50,
          render(text, record) {
            return {
              props: {
                style: {
                  background: '#f4acb7',
                },
              },
              children: record?.trips[0]?.unit,
            };
          },
        },
      ],
    },

    {
      title: 'Trip No.2',
      align: 'center',
      className: 'color-head-3',
      children: [
        {
          title: 'From',
          className: 'bg_from_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'To',
              dataIndex: 'trip_2_to',
              className: 'bg_to_trip_no',
              align: 'center',
              width: 70,
              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#d8e2dc',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record?.trips[1]?.location_from?.name ? (
                          record?.trips[1]?.location_from?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b>
                        {record?.trips[1]?.location_to?.name ? (
                          record?.trips[1]?.location_to?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Receive',

          className: 'bg_receive_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Arrive',
              dataIndex: 'trip_2_arrival_time',
              className: 'bg_arrive_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#ffe5d9',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record.trips[1]?.receive_job_time ? (
                          <div>
                            {(record?.trips[1]?.receive_job_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[1]?.receive_job_time?.day_offset !==
                            0
                              ? '(+' +
                                record?.trips[1]?.receive_job_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[1]?.arrival_time ? (
                          <div>
                            {(record?.trips[1]?.arrival_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[1]?.arrival_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[1]?.arrival_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Out',
          className: 'bg_out_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Departure',
              dataIndex: 'trip_2_yard_out_time',
              className: 'bg_out_color_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#F9CE7D',
                    },
                  },
                  children: (
                    <div>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[1]?.yard_out_time ? (
                          <div>
                            {(record?.trips[1]?.yard_out_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[1]?.yard_out_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[1]?.yard_out_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[1]?.departure_time ? (
                          <div>
                            {(record?.trips[1]?.departure_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[1]?.departure_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[1]?.departure_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },

        {
          title: 'Unit',
          dataIndex: ['trip_2_unit'],
          align: 'center',
          className: 'bg-unit-trip_no',
          width: 50,
          render(text, record) {
            return {
              props: {
                style: {
                  background: '#f4acb7',
                },
              },
              children: record?.trips[1]?.unit,
            };
          },
        },
      ],
    },

    {
      title: 'Trip No.3',
      align: 'center',
      className: 'color-head-3',
      children: [
        {
          title: 'From',
          className: 'bg_from_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'To',
              dataIndex: 'trip_3_to',
              className: 'bg_to_trip_no',
              align: 'center',
              width: 70,
              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#d8e2dc',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record?.trips[2]?.location_from?.name ? (
                          record?.trips[2]?.location_from?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b>
                        {record?.trips[2]?.location_to?.name ? (
                          record?.trips[2]?.location_to?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Receive',

          className: 'bg_receive_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Arrive',
              dataIndex: 'trip_3_arrival_time',
              className: 'bg_arrive_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#ffe5d9',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record.trips[2]?.receive_job_time ? (
                          <div>
                            {(record?.trips[2]?.receive_job_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[2]?.receive_job_time?.day_offset !==
                            0
                              ? '(+' +
                                record?.trips[2]?.receive_job_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[2]?.arrival_time ? (
                          <div>
                            {(record?.trips[2]?.arrival_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[2]?.arrival_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[2]?.arrival_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Out',
          className: 'bg_out_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Departure',
              dataIndex: 'trip_3_yard_out_time',
              className: 'bg_out_color_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#F9CE7D',
                    },
                  },
                  children: (
                    <div>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[2]?.yard_out_time ? (
                          <div>
                            {(record?.trips[2]?.yard_out_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[2]?.yard_out_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[2]?.yard_out_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[2]?.departure_time ? (
                          <div>
                            {(record?.trips[2]?.departure_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[2]?.departure_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[2]?.departure_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },

        {
          title: 'Unit',
          dataIndex: ['trip_3_unit'],
          align: 'center',
          className: 'bg-unit-trip_no',
          width: 50,
          render(text, record) {
            return {
              props: {
                style: {
                  background: '#f4acb7',
                },
              },
              children: record?.trips[2]?.unit,
            };
          },
        },
      ],
    },
    {
      title: 'Trip No.4',
      align: 'center',
      className: 'color-head-3',
      children: [
        {
          title: 'From',
          className: 'bg_from_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'To',
              dataIndex: 'trip_4_to',
              className: 'bg_to_trip_no',
              align: 'center',
              width: 70,
              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#d8e2dc',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record?.trips[3]?.location_from?.name ? (
                          record?.trips[3]?.location_from?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b>
                        {record?.trips[3]?.location_to?.name ? (
                          record?.trips[3]?.location_to?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Receive',

          className: 'bg_receive_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Arrive',
              dataIndex: 'trip_4_arrival_time',
              className: 'bg_arrive_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#ffe5d9',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record.trips[3]?.receive_job_time ? (
                          <div>
                            {(record?.trips[3]?.receive_job_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[3]?.receive_job_time?.day_offset !==
                            0
                              ? '(+' +
                                record?.trips[3]?.receive_job_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[3]?.arrival_time ? (
                          <div>
                            {(record?.trips[3]?.arrival_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[3]?.arrival_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[3]?.arrival_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Out',
          className: 'bg_out_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Departure',
              dataIndex: 'trip_1_yard_out_time',
              className: 'bg_out_color_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#F9CE7D',
                    },
                  },
                  children: (
                    <div>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[3]?.yard_out_time ? (
                          <div>
                            {(record?.trips[3]?.yard_out_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[3]?.yard_out_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[3]?.yard_out_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[3]?.departure_time ? (
                          <div>
                            {(record?.trips[3]?.departure_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[3]?.departure_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[3]?.departure_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },

        {
          title: 'Unit',
          dataIndex: ['trip_4_unit'],
          align: 'center',
          className: 'bg-unit-trip_no',
          width: 50,
          render(text, record) {
            return {
              props: {
                style: {
                  background: '#f4acb7',
                },
              },
              children: record?.trips[3]?.unit,
            };
          },
        },
      ],
    },
    {
      title: 'Trip No.5',
      align: 'center',
      className: 'color-head-3',
      children: [
        {
          title: 'From',
          className: 'bg_from_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'To',
              dataIndex: 'trip_5_to',
              className: 'bg_to_trip_no',
              align: 'center',
              width: 70,
              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#d8e2dc',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record?.trips[4]?.location_from?.name ? (
                          record?.trips[4]?.location_from?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b>
                        {record?.trips[4]?.location_to?.name ? (
                          record?.trips[4]?.location_to?.name
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Receive',

          className: 'bg_receive_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Arrive',
              dataIndex: 'trip_5_arrival_time',
              className: 'bg_arrive_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#ffe5d9',
                    },
                  },
                  children: (
                    <div>
                      <b>
                        {record.trips[4]?.receive_job_time ? (
                          <div>
                            {(record?.trips[4]?.receive_job_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[4]?.receive_job_time?.day_offset !==
                            0
                              ? '(+' +
                                record?.trips[4]?.receive_job_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[4]?.arrival_time ? (
                          <div>
                            {(record?.trips[4]?.arrival_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[4]?.arrival_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[4]?.arrival_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },
        {
          title: 'Out',
          className: 'bg_out_trip_no',
          align: 'center',
          width: 70,
          children: [
            {
              title: 'Departure',
              dataIndex: 'trip_4_yard_out_time',
              className: 'bg_out_color_trip_no',
              align: 'center',
              width: 70,

              render(text, record) {
                return {
                  props: {
                    style: {
                      background: '#F9CE7D',
                    },
                  },
                  children: (
                    <div>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[4]?.yard_out_time ? (
                          <div>
                            {(record?.trips[4]?.yard_out_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[4]?.yard_out_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[4]?.yard_out_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                      <h5>------------</h5>
                      <b style={{ fontWeight: '400' }}>
                        {record.trips[4]?.departure_time ? (
                          <div>
                            {(record?.trips[4]?.departure_time?.time).substring(
                              0,
                              5,
                            )}{' '}
                            {record?.trips[4]?.departure_time?.day_offset !== 0
                              ? '(+' +
                                record?.trips[4]?.departure_time?.day_offset +
                                ')'
                              : ''}
                          </div>
                        ) : (
                          <>&nbsp;</>
                        )}
                      </b>
                    </div>
                  ),
                };
              },
            },
          ],
        },

        {
          title: 'Unit',
          dataIndex: ['trip_5_unit'],
          align: 'center',
          className: 'bg-unit-trip_no',
          width: 50,
          render(text, record) {
            return {
              props: {
                style: {
                  background: '#f4acb7',
                },
              },
              children: record?.trips[4]?.unit,
            };
          },
        },
      ],
    },

    {
      title: (
        <>
          <CalendarOutlined style={{ fontSize: '15px' }} /> Calendar{' '}
        </>
      ),

      dataIndex: 'calendar',
      width: 70,
      fixed: 'right',
      className: 'color-head-6',
      render: (text, record) => {
        return text;
      },

      align: 'center',
      children: [
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[0]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '0', 'work'],
          key: 1,
          align: 'center',
          fixed: 'right',
          className: 'color-head-6',
          width: 70,
          render: (text, record) => {
            if (text) {
              return <CheckOutlined style={{ fontSize: '15px' }} />;
            } else {
              return null;
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[1]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '1', 'work'],
          key: 2,
          fixed: 'right',
          align: 'center',
          className: 'color-head-6',
          width: 70,
          render: (text, record) => {
            if (text) {
              return <CheckOutlined style={{ fontSize: '15px' }} />;
            } else {
              return null;
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[2]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '2', 'work'],
          key: 3,
          align: 'center',
          fixed: 'right',
          className: 'color-head-6',
          width: 70,
          render: (text, record) => {
            if (text) {
              return <CheckOutlined style={{ fontSize: '15px' }} />;
            } else {
              return null;
            }
          },
        },

        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[3]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '3', 'work'],
          key: 4,
          fixed: 'right',
          align: 'center',
          className: 'color-head-6',
          width: 70,
          render: (text, record) => {
            if (text) {
              return <CheckOutlined style={{ fontSize: '15px' }} />;
            } else {
              return null;
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[4]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '4', 'work'],
          key: 5,
          fixed: 'right',
          align: 'center',
          className: 'color-head-6',
          width: 70,
          render: (text, record) => {
            if (text) {
              return <CheckOutlined style={{ fontSize: '15px' }} />;
            } else {
              return null;
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[5]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '5', 'work'],
          key: 6,
          fixed: 'right',
          align: 'center',
          className: 'color-head-6',
          width: 70,
          render: (text, record) => {
            if (text) {
              return <CheckOutlined style={{ fontSize: '15px' }} />;
            } else {
              return null;
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[6]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '6', 'work'],
          className: 'color-head-6',
          key: 7,
          fixed: 'right',
          align: 'center',
          width: 70,
          render: (text, record) => {
            if (text) {
              return <CheckOutlined style={{ fontSize: '15px' }} />;
            } else {
              return null;
            }
          },
        },
      ],
    },
  ];

  function range(start, end) {
    return Array(end - start)
      .fill(0)
      .map((_, index) => start + index);
  }

  const disabledTime = (value) => {
    // สร้างช่วงเวลา 7:30 - 19:30
    return {
      disabledHours: () => {
        let shiftCurrent = filterJobCodeEditRef.current
          ? dataHourCalEditRef.current?.shift
          : dataTableEditInitRef.current?.shift;

        if (shiftCurrent === 'D') {
          return range(0, 7).concat(range(20, 24));
        } else if (shiftCurrent === 'N') {
          return range(8, 19);
        }
      },
      disabledMinutes: () => {
        let shiftCurrent = filterJobCodeEditRef.current
          ? dataHourCalEditRef.current?.shift
          : dataTableEditInitRef.current?.shift;

        var nowTime = value.format('HH');
        if (shiftCurrent === 'D') {
          if (nowTime === '07') {
            return range(0, 30);
          } else if (nowTime === '19') {
            return range(30, 60);
          } else {
            return [];
          }
        } else if (shiftCurrent === 'N') {
          if (nowTime === '19') {
            return range(0, 30);
          } else if (nowTime === '07') {
            return range(30, 60);
          } else {
            return [];
          }
        }
      },
      disabledSeconds: () => [],
    };
  };

  function getNonZeroTimeValue(newValue) {
    const startTime = moment('00:00', 'HH:mm');
    const endTime = moment('07:30', 'HH:mm');
    const currentTime = moment(newValue, 'HH:mm'); // เวลาปัจจุบัน

    return currentTime.isBetween(startTime, endTime, null, '[)') ? 1 : 0;
  }

  function addTime(og, time) {
    const date = new Date(og);
    console.log('before: ', date.toISOString());
    date.setHours(time.split(':')[0]);
    date.setMinutes(time.split(':')[1]);
    console.log('after: ', date.toISOString());

    return moment(date).format();
  }

  const [columnsEditTour, setColumnsEditTour] = useState(() => {
    let cols = [
      {
        title: 'Trip No.',
        dataIndex: 'trip_no',
        align: 'center',
        className: 'bg-grey',
      },
      {
        title: 'From',
        dataIndex: ['lots', 'location_from__name'],
        align: 'center',
        className: 'bg-grey',
      },
      {
        title: 'Receive job',
        dataIndex: ['lots', 'receive_job_time', 'time'],
        align: 'center',
        className: 'bg-grey',
        render: (text, record) => {
          if (record?.id === 1) {
            return (
              <div>
                <TimePicker
                  hideDisabledOptions={true}
                  disabledHours={(value) => {
                    let shiftCurrent = filterJobCodeEditRef.current
                      ? dataHourCalEditRef.current?.shift
                      : dataTableEditInitRef.current?.shift;

                    if (shiftCurrent === 'D') {
                      return range(0, 7).concat(range(20, 24));
                    } else if (shiftCurrent === 'N') {
                      return range(8, 19);
                    }
                  }}
                  disabledMinutes={(value, value2) => {
                    let shiftCurrent = filterJobCodeEditRef.current
                      ? dataHourCalEditRef.current?.shift
                      : dataTableEditInitRef.current?.shift;

                    var nowTime = value;

                    if (shiftCurrent === 'D') {
                      if (nowTime === 7) {
                        return range(0, 30);
                      } else if (nowTime === 19) {
                        return range(30, 60);
                      } else {
                        return [];
                      }
                    } else if (shiftCurrent === 'N') {
                      if (nowTime === 19) {
                        return range(0, 30);
                      } else if (nowTime === 7) {
                        return range(30, 60);
                      } else {
                        return [];
                      }
                    }
                  }}
                  value={text ? moment(text, 'HH:mm') : null}
                  size='small'
                  style={{
                    textAlign: 'center',
                    width: '70px',
                    fontSize: 12,
                  }}
                  format={'HH:mm'}
                  onChange={(old, newValue) => {
                    if (newValue) {
                      let lots = {
                        lots: {
                          ...record?.lots,
                          receive_job_time: {
                            time: newValue,
                            day_offset: getNonZeroTimeValue(newValue),
                            timestamp: addTime(
                              record?.lots?.receive_job_time?.timestamp,
                              newValue,
                            ),
                          },
                        },
                      };
                      receiveJobTimeField(newValue ? lots : null, record.id);
                    }
                  }}
                />
                {record?.lots?.receive_job_time?.day_offset !== 0
                  ? '(+' + record?.lots?.receive_job_time?.day_offset + ')'
                  : ''}
              </div>
            );
          } else {
            return (
              <div>
                {record?.receive_job_datetime
                  ? timeFormatterNoHyphen({
                      value: record?.receive_job_datetime,
                    })
                  : text?.substring(0, 5)}

                {''}
                {record?.receive_job_datetime_plus === 0
                  ? null
                  : record?.receive_job_datetime_plus
                  ? ' (+' + record?.receive_job_datetime_plus + ')'
                  : record?.lots?.receive_job_time?.day_offset !== 0
                  ? ' (+' + record?.lots?.receive_job_time?.day_offset + ')'
                  : ''}
              </div>
            );
          }
        },
      },
      {
        title: 'Unit',
        dataIndex: ['lots', 'unit'],
        align: 'center',
        className: 'bg-grey',
      },
      {
        title: 'Yard out',
        dataIndex: ['lots', 'yard_out_time', 'timestamp'],
        align: 'center',
        className: 'bg-grey',
        render: (text, record) => {
          return (
            <div>
              {record?.yardout_datetime
                ? timeFormatterNoHyphen({ value: record?.yardout_datetime })
                : timeFormatterNoHyphen({ value: text })}{' '}
              {''}
              {record?.yardout_datetime_plus === 0
                ? null
                : record?.yardout_datetime_plus
                ? '(+' + record?.yardout_datetime_plus + ')'
                : record?.lots?.yard_out_time?.day_offset !== 0
                ? '(+' + record?.lots?.yard_out_time?.day_offset + ')'
                : ''}
            </div>
          );
        },
      },
      {
        title: 'To',
        dataIndex: ['lots', 'location_to__name'],
        align: 'center',
        className: 'bg-grey',
      },
      {
        title: 'Arrive time',
        dataIndex: ['lots', 'arrival_time', 'timestamp'],
        align: 'center',
        className: 'bg-grey',
        render: (text, record) => {
          return (
            <div>
              {record?.eta_datetime
                ? timeFormatterNoHyphen({ value: record?.eta_datetime })
                : timeFormatterNoHyphen({ value: text })}{' '}
              {''}
              {record?.arrival_time_plus === 0
                ? null
                : record?.arrival_time_plus
                ? '(+' + record?.arrival_time_plus + ')'
                : record?.lots?.arrival_time?.day_offset !== 0
                ? '(+' + record?.lots?.arrival_time?.day_offset + ')'
                : ''}
            </div>
          );
        },
      },
      {
        title: 'Departure time',
        dataIndex: ['lots', 'departure_time', 'timestamp'],
        align: 'center',
        className: 'bg-grey',
        render: (text, record) => {
          return (
            <div>
              {record?.departure_time
                ? timeFormatterNoHyphen({ value: record?.departure_time })
                : timeFormatterNoHyphen({ value: text })}{' '}
              {''}
              {record?.departure_time_plus === 0
                ? null
                : record?.departure_time_plus
                ? '(+' + record?.departure_time_plus + ')'
                : record?.lots?.departure_time?.day_offset !== 0
                ? '(+' + record?.lots?.departure_time?.day_offset + ')'
                : ''}
            </div>
          );
        },
      },
      {
        title: (
          <>
            <CalendarOutlined style={{ fontSize: '15px' }} /> Calendar{' '}
          </>
        ),

        dataIndex: 'calendar',
        width: 50,
        fixed: 'right',
        className: 'color-head-6',
        render: (text, record) => {
          return text;
        },

        align: 'center',
        children: [
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[0]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '0', 'work'],
            key: 1,
            align: 'center',
            fixed: 'right',
            className: 'color-head-6',
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[1]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '1', 'work'],
            key: 2,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[2]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '2', 'work'],
            key: 3,
            align: 'center',
            fixed: 'right',
            className: 'color-head-6',
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },

          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[3]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '3', 'work'],
            key: 4,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[4]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '4', 'work'],
            key: 5,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[5]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '5', 'work'],
            key: 6,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[6]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '6', 'work'],
            className: 'color-head-6',
            key: 7,
            fixed: 'right',
            align: 'center',
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
        ],
      },
    ];

    return cols;
  });

  const handleOkDeleteData = (id) => {
    purgeTour(id);
    setIsModalVisibleDeleteData(false);
  };

  const handleReleaseTour = (id, job_code) => {
    const isTEM = job_code.substring(0, 3) === 'TEM';
    if (isTEM) {
      deleteManageTour(id);
    } else {
      releaseJobcode(id);
    }
    setIsReleaseTourModalVisible(false);
  };

  const handleCancelDeleteData = () => {
    setIsModalVisibleDeleteData(false);
  };

  const ModalDeleteData = async () => {
    setIsModalVisibleDeleteData(true);
  };

  const ModalReleaseTour = async () => {
    setIsReleaseTourModalVisible(true);
  };

  return (
    <div>
      <Container style={{ padding: 10 }}>
        <Row
          gutter={[20]}
          style={{ margin: '-27px -10px -4px' }}
          align='middle'
          justify='center'
        >
          <Col span={4} align='start'>
            <Button
              type='primary'
              size='small'
              style={{ width: '80%', marginTop: '20px' }}
              onClick={() => {
                dispatch(push(`/app/planning/summary_allocation`));
              }}
            >
              Summary Allocation
            </Button>
          </Col>
          <Col span={4} align='start'>
            <Button
              type='primary'
              size='small'
              style={{ width: '80%', marginTop: '20px' }}
              onClick={() => {
                dispatch(push(`/app/planning/summary_yard`));
              }}
            >
              Summary Yard
            </Button>
          </Col>

          <Col span={4} align='start'>
            <Button
              type='primary'
              size='small'
              style={{ width: '80%', marginTop: '20px' }}
              onClick={() => {
                dispatch(push(`/app/planning/status_check`));
              }}
            >
              Status Check
            </Button>
          </Col>
          <Col span={3}></Col>

          <Col span={4} align='start'>
            <Button
              type='primary'
              size='small'
              style={{ width: '80%', marginTop: '20px' }}
              disabled={filterAndDowlandEnable}
              onClick={onBtnDownload}
              loading={isLoadingDownloadCSV}
            >
              Download
            </Button>
          </Col>
          <Col span={5} align='start'>
            <Button
              icon={<DownloadOutlined />}
              type='primary'
              size='small'
              style={{ width: '82%', marginTop: '20px' }}
              onClick={onBtnDownloadJobCodeCalendar}
              loading={isLoadingDownloadJobCodeCSV}
              disabled={filterAndDowlandEnable}
            >
              Job Code Calendar
            </Button>
          </Col>
        </Row>
        <StyledAntTable
          style={{ paddingTop: 15 }}
          rowKey='no'
          tableId={'manageTour'}
          bordered
          size='small'
          columns={columns}
          dataSource={dataSourceTable}
          loading={isLoading}
          showContextMenu={true}
          scroll={{
            x: 'max-content',
            y: 'calc(100vh - 270px)',
            scrollToFirstRowOnChange: true,
          }}
          pagination={{
            pageSize: PAGE_SIZE,
            total: totalRows,
            current: page,
            size: 'small',
            showSizeChanger: false,
            showQuickJumper: true,
            onChange: async (page, _) => {
              let bufferData = dataSourceTable;
              let requiredRows = page * PAGE_SIZE;
              requiredRows =
                requiredRows > totalRows && totalRows !== 0
                  ? totalRows
                  : requiredRows;

              if (requiredRows > bufferData.length) {
                // Fetch more data
                let curOffset = offset;
                do {
                  let queryRes = await executeQuery(curOffset, bufferData, {
                    ...payloadRef.current,
                    limit: LIMIT,
                    offset: curOffset,
                  });
                  curOffset += LIMIT;
                  bufferData = queryRes.data;
                  requiredRows =
                    requiredRows > queryRes.totalRows
                      ? queryRes.totalRows
                      : requiredRows;
                } while (requiredRows > bufferData.length);
              }
              setPage(page);
            },
          }}
          menuActions={[
            {
              label: 'Edit',
              action: (row) => {
                rowSelectDataRef.current = row;
                if (row?.manage_tour_list_status === 'BzP checking') {
                  Modal.warning({ title: 'ไม่สามารถ Edit tour ได้' });
                } else {
                  let valTripType = filterTripTypeEditOption?.find(
                    (f) => f.label === dataTableEditInit?.trip_type,
                  );
                  setFilterTripTypeEdit(valTripType?.value);

                  let valTrailer = filterTrailerTypeEditOption?.find(
                    (f) => f.label === dataTableEditInit?.trailer_type,
                  );
                  setFilterTrailerTypeEdit(valTrailer?.value);
                  ModalEditTable(row?.job_code);
                }
              },
            },
            {
              label: 'Release',
              action: (row) => {
                rowSelectDataRef.current = row;
                ModalReleaseTour();
              },
            },
            {
              label: 'Delete',
              action: (row) => {
                rowSelectDataRef.current = row;
                ModalDeleteData();
              },
            },
          ]}
        />

        <Modal
          title={`Would you like to release ${
            rowSelectDataRef.current?.job_code.substring(0, 3) === 'TEM'
              ? 'tour'
              : 'job code'
          }?`}
          visible={IsReleaseTourModalVisible}
          onOk={() => {
            const currTour = rowSelectDataRef.current;
            handleReleaseTour(currTour.id, currTour.job_code);
          }}
          onCancel={() => {
            setIsReleaseTourModalVisible(false);
          }}
        >
          <Row gutter={[20]} align='center'>
            <Col span={20}>
              <p>Job code: {rowSelectDataRef.current?.job_code}</p>
              {rowSelectDataRef.current?.job_code.substring(0, 3) === 'TEM' ? (
                <p>Trips ใน tour นี้จะกลับไปอยู่ในขั้นตอน create tour</p>
              ) : (
                <p>Trips ทั้งหมดจะถูกแยกออกเป็น TEM4 tour ตามจำนวนวัน</p>
              )}
            </Col>
          </Row>
        </Modal>

        <Modal
          title='Would you like to Delete ?'
          visible={isModalVisibleDeleteData}
          onOk={() => {
            handleOkDeleteData(rowSelectDataRef.current?.id);
          }}
          onCancel={handleCancelDeleteData}
        >
          <Row gutter={[20]} align='center'>
            <Col span={20}>
              <p>Job Code : {rowSelectDataRef.current?.job_code}</p>
            </Col>
          </Row>
        </Modal>

        <ApplyJobcodeModal
          isVisible={isManualJobcodeModalVisible}
          handleOk={handleOk}
          onBtnBackEdit={onBtnBackEdit}
          initialTour={dataTableEditInit}
          btnBackEdit={btnBackEdit}
          loadingDataTableEditInit={loadingDataTableEditInit}
          onBtnResetEdit={onBtnResetEdit}
          // modalSaveEdit={modalSaveEdit}
          // isLoadingCheckAndSave={isLoadingCheckAndSave}
          // checkCalculate={checkCalculate}
          headerDateTableRef={headerDateTableRef}
          refetchDataAll={refetchDataAll}
        />

        <EditRecvJobTimeModal
          isVisible={isEditRecvJobTimeModalVisible}
          handleOk={handleOk}
          onBtnBackEdit={onBtnBackEdit}
          initialTour={dataTableEditInit}
          btnBackEdit={btnBackEdit}
          loadingDataTableEditInit={loadingDataTableEditInit}
          onBtnResetEdit={onBtnResetEdit}
          modalSaveEdit={modalSaveEdit}
          isLoadingCheckAndSave={isLoadingCheckAndSave}
          checkCalculate={checkCalculate}
          headerDateTableRef={headerDateTableRef}
          refetchDataAll={refetchDataAll}
          columnsEditTour={columnsEditTour}
          columnsDisplayEditTour={columnsEditTour}
          dataTableEdit={dataTableEdit}
        />
      </Container>
    </div>
  );
};

const Container = styled.div`
  font-size: 13px;
`;

export default TableComponent;
