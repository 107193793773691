import React, { useMemo, useEffect, useState } from 'react';
import AntTable from 'common_components/AntTable';
import styled from 'styled-components';
import { Button, Row, Col, Select } from 'antd';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { orderBy } from 'lodash';

const TableManbageCalling = (props) => {
  const {
    dataSource,
    setDataSource,
    selectedRowKeys,
    setSelectedRowKeys,
    loadingSearchCallingNo,
    loadingCreateManageCalling,
    onCancelCalling,
    filterCallingno,
    checkoutDataSource,
    tableHeight,
    onDeleteVinlist,
    filterOptionParking,
  } = props;
  const { Option } = Select;

  const [parkingLaneOptions, setParkingLaneOptions] =
    useState(filterOptionParking);

  useEffect(() => {
    setParkingLaneOptions(filterOptionParking);
  }, [filterOptionParking]);

  const columns = useMemo(() => {
    const changParkingLane = (e, record, value) => {
      let updatedParkingOptions = [...parkingLaneOptions];

      if (record?.parking_lane?.lane_no) {
        // Decrease parking lane used count
        let findPrevParkingOption = parkingLaneOptions.find(
          (p) => p.label === record.parking_lane.lane_no
        );
        if (findPrevParkingOption) {
          findPrevParkingOption = {
            ...findPrevParkingOption,
            used: findPrevParkingOption.used - 1,
          };
        } else {
          findPrevParkingOption = filterOptionParking.find(
            (p) => p.label === record.parking_lane.lane_no
          );
          if (findPrevParkingOption) {
            findPrevParkingOption = {
              ...findPrevParkingOption,
              used: findPrevParkingOption.used,
            };
          } else {
            // Find from vins
            findPrevParkingOption = {
              value: record.parking_lane.id,
              label: record.parking_lane.lane_no,
              amount: dataSource.filter(
                (v) =>
                  v.parking_lane_old.lane_no === record.parking_lane.lane_no
              ).length,
              used:
                dataSource.filter(
                  (v) =>
                    v.parking_lane_old.lane_no === record.parking_lane.lane_no
                ).length - 1,
            };
          }
        }
        // Upsert
        let upsertOption = parkingLaneOptions.find(
          (p) => p.label === findPrevParkingOption.label
        );
        if (!upsertOption) {
          updatedParkingOptions.push(findPrevParkingOption);
        } else {
          updatedParkingOptions = updatedParkingOptions.map((p) =>
            p.label === findPrevParkingOption.label
              ? { ...findPrevParkingOption }
              : p
          );
        }
      }

      // Increase parking lane used count
      updatedParkingOptions = updatedParkingOptions.map((p) =>
        p.label === value?.children ? { ...p, used: p.used + 1 } : p
      );

      // Update vin parking lane info
      let findCurParkingOption = updatedParkingOptions.find(
        (p) => p.label === value?.children
      );
      let resultData = {
        ...record,
        parking_lane: {
          id: findCurParkingOption.value,
          lane_no: findCurParkingOption.label,
        },
      };
      var res = dataSource.map(
        (obj) => [resultData].find((o) => o.vin_no.trim()  === obj.vin_no.trim()) || obj
      );

      setDataSource(res);
    };

    let cols = [
      {
        title: 'Vin',
        dataIndex: 'vin_no',
        width: 200,
        movable: true,
        align: 'center',
        render: (text) => {
          return text;
        },
      },
      {
        title: 'Series',
        dataIndex: 'series',
        width: 100,
        align: 'center',
        render: (text) => {
          return text;
        },
        movable: true,
      },

      {
        title: 'Cluster',
        dataIndex: 'cluster',
        width: 100,
        align: 'center',
        render: (text) => {
          return text ? text : '';
        },
        movable: true,
      },
      {
        title: 'To',
        dataIndex: 'location_to',
        width: 100,
        align: 'center',
        render: (text) => {
          return text ? text : '-';
        },
        movable: true,
      },
      {
        title: 'Parking Lane',
        dataIndex: ['parking_lane', 'lane_no'],
        width: 100,
        align: 'center',
        movable: true,
        ellipsis: true,
        editable: true,

        render: (text, record) => {
          return {
            props: {
              style: { color: '#14A734' },
            },
            children: (
              <div>
                <Select
                  placeholder={'Parking Lane'}
                  style={{ width: '100%' }}
                  value={text}
                  onSelect={(e, value) => {
                    changParkingLane(e, record, value);
                  }}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {parkingLaneOptions.map((c, index) => (
                    <Option key={index} value={c.id}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              </div>
            ),
          };
        },
      },
      {
        title: 'Vehicle type',
        dataIndex: 'vehicle_type',
        width: 100,
        align: 'center',
        render: (text) => {
          return text;
        },
        movable: true,
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        width: 100,
        align: 'center',
        render: (text) => {
          return text;
        },
        movable: true,
      },
      {
        title: 'DN',
        dataIndex: 'print_dn',
        width: 100,
        align: 'center',
        render: (text) => {
          return {
            props: {
              style: text ? { color: 'green' } : { color: 'red' },
            },
            children: (
              <div>
                {text ? (
                  <CheckCircleOutlined color='green' />
                ) : (
                  <ExclamationCircleOutlined color='red' />
                )}
              </div>
            ),
          };
        },
        movable: true,
      },
    ];
    return cols;
  }, [dataSource, filterOptionParking, parkingLaneOptions, setDataSource]);

  return (
    <Container>
      <StyledTable
        rowKey='vin_no'
        loading={loadingSearchCallingNo || loadingCreateManageCalling}
        columns={columns}
        rowClassName={() => 'editable-row'}
        dataSource={(() => {
          let sortedData = orderBy(dataSource, ['cluster', 'location_to']);
          return sortedData;
        })()}
        pagination={false}
        scroll={{
          y: tableHeight,
        }}
        Below
        is
        for
        right
        click
        context
        menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          {
            label: 'remove vin',
            action: (row) => onDeleteVinlist(row),
          },
        ]}
      />
      <Row gutter={20}>
        <Col span={24} align='end' style={{ marginTop: '5px' }}>
          <Button
            style={{ textAlign: 'center' }}
            type='primary'
            onClick={onCancelCalling}
            disabled={!filterCallingno || checkoutDataSource !== true}
          >
            Cancel Calling
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(AntTable)`
  &&& {
    @media screen and (max-width: 992px) {
      body {
        background-color: blue;
        color: white;
        font-size: 20px;
      }

      .ant-table > .ant-table-container > .ant-table-body {
        height: ${(props) => {
          return props.height + 'px';
        }};
      }
      .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
        cursor: auto;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1890ff !important;
        border-color: #1890ff !important;
      }
      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: white;
      }
      .ant-table > .ant-table-container > .ant-table-body tr td {
        padding: 3px;
        // font-size: 11px;
      }
    }
  }
`;
export default TableManbageCalling;
