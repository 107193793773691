import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Switch, Select, message } from 'antd';
import {
  useUpdateTrailerMutation,
  useCreateTrailerMutation,
  useFindAllTrailerKindsQuery,
  useFindAllTrailerTypesQuery,
} from 'reducers/masterData/trailer/api';
import {
  useFindAllBzpsQuery,
  useFindBzpByIdQuery,
} from 'reducers/masterData/bzp/api';
import { goBack } from 'connected-react-router';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
const { Option } = Select;

const TrailerFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const [bzps, setBzps] = useState(
    formData && formData.bzp
      ? [{ id: formData.bzp.id, label: formData.bzp.name }]
      : []
  );

  const [trailerTypes, setTrailerTypes] = useState(
    formData && formData.trailer_type
      ? [{ id: formData.trailer_type.id, label: formData.trailer_type.name }]
      : []
  );

  // Query all trailer types
  const {
    data: dataTrailerTypes,
    error: errorTrailerTypes,
    isFetching: isTrailerTypesFetching,
    refetch: refetchTrailerTypes,
  } = useFindAllTrailerTypesQuery(null, {
    skip: !user || readonly,
  });

  useEffect(() => {
    if (dataTrailerTypes) {
      setTrailerTypes(
        dataTrailerTypes.map((d) => ({ id: d.id, label: d.name }))
      );
    }
  }, [dataTrailerTypes]);

  useEffect(() => {
    if (errorTrailerTypes) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load trailer types',
        okText: 'Retry',
        onOk: refetchTrailerTypes,
        cancelText: 'Cancel',
      });
    }
  }, [errorTrailerTypes, refetchTrailerTypes]);

  // Query all bzps
  const {
    data: dataBzps,
    error: errorBzps,
    isFetching: isBzpsFetching,
    refetch: refetchBzps,
  } = useFindAllBzpsQuery(
    {
      ordering: 'job_name',
      location_type__code: '005',
      display: 'bzp',
      exclude_job_name: ''
    },
    {
      skip: !user || readonly || !user.is_superadmin,
    }
  );

  useEffect(() => {
    if (dataBzps) {
      setBzps(dataBzps.map((d) => ({ id: d.id, label: d.job_name })));
    }
  }, [dataBzps]);

  useEffect(() => {
    if (errorBzps) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load bzps',
        okText: 'Retry',
        onOk: refetchBzps,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzps, refetchBzps]);

  // Query bzp by ID, when admin of a bzp
  const {
    data: dataBzpById,
    error: errorBzpById,
    isFetching: isBzpByIdFetching,
    refetch: refetchBzpById,
  } = useFindBzpByIdQuery(user && user.bzp_id, {
    skip: !user || readonly || user.is_superadmin,
  });

  useEffect(() => {
    if (dataBzpById) {
      setBzps([{ id: dataBzpById.id, label: dataBzpById.name }]);
      form.setFieldsValue({ bzp: dataBzpById.name });
    }
  }, [dataBzpById, form]);

  useEffect(() => {
    if (errorBzpById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load bzp',
        okText: 'Retry',
        onOk: refetchBzpById,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzpById, refetchBzpById]);

  const [
    createTrailer,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateTrailerMutation();

  const [
    updateTrailer,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateTrailerMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const onBzpChange = (bzpName) => {
    // let bzp = bzps.find((c) => c.label === bzpName);
    // // setSelectedBzpId(bzp.id);
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let bzp = bzps.find((c) => c.label === validData.bzp);

    let trailer_type = trailerTypes.find(
      (c) => c.label === validData.trailer_type
    );
    let postData = {
      id: formData && formData.id,
      ...validData,
      trailer_type: trailer_type && trailer_type.id,
      bzp: bzp && bzp.id,
    };

    if (isCreate) {
      createTrailer(postData);
    } else {
      updateTrailer(postData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Trailer Type'
        name='trailer_type'
        initialValue={
          formData && formData.trailer_type && formData.trailer_type.name
        }
        rules={[
          {
            required: true,
            message: 'Please select trailer type',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a trailer type'
          optionFilterProp='children'
          loading={isTrailerTypesFetching}
          disabled={!user}
          // onChange={onTrailerKindChange}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {trailerTypes &&
            trailerTypes.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Trailer Card ID'
        name='trailer_card_id'
        initialValue={formData && formData.trailer_card_id}
        rules={[
          {
            required: true,
            message: 'Please input trailer card ID',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Trailer card ID'} />
      </Form.Item>
      <Form.Item
        label='Trailer Gps Provider'
        name='trailer_gps_provider'
        initialValue={formData && formData.trailer_gps_provider}
        rules={[
          {
            required: true,
            message: 'Please input trailer gps provider',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Trailer Gps Provider'} />
      </Form.Item>
      <Form.Item
        label='Passport Expiry Date'
        name='passport_expiry_date'
        initialValue={formData && formData.passport_expiry_date}
        rules={[
          {
            required: true,
            message: 'Please input passport expiry date',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Passport Expiry Date'} />
      </Form.Item>
      <Form.Item
        label='Lashing Type'
        name='lashing_type'
        initialValue={formData && formData.lashing_type}
        rules={[
          {
            required: true,
            message: 'Please input lashing type',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Lashing Type'} />
      </Form.Item>
      <Form.Item
        label='Register Date Wtih TTT'
        name='register_date_wtih_ttt'
        initialValue={formData && formData.register_date_wtih_ttt}
        rules={[
          {
            required: true,
            message: 'Please input register date wtih ttt',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Register Date Wtih TTT'} />
      </Form.Item>
      {/* <Form.Item
        label='Trailer Loading'
        name='trailer_loading'
        initialValue={formData && formData.trailer_loading}
        rules={[
          {
            required: true,
            message: 'Please input trailer loading',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Trailer loading'} />
      </Form.Item> */}
      <Form.Item
        label='License Plate Head'
        name='license_plate_head'
        initialValue={formData && formData.license_plate_head}
        rules={[
          {
            required: true,
            message: 'Please input license plate head',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'License plate head'} />
      </Form.Item>
      <Form.Item
        label='License Plate Tail'
        name='license_plate_tail'
        initialValue={formData && formData.license_plate_tail}
        rules={[
          {
            required: true,
            message: 'Please input license plate tail',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'License plate tail'} />
      </Form.Item>
      <Form.Item
        label='GPS Code'
        name='trailer_gps_code'
        initialValue={formData && formData.trailer_gps_code}
        rules={[
          {
            required: true,
            message: 'Please input trailer GPS code',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Trailer GPS code'} />
      </Form.Item>
      <Form.Item
        label='CNG Allowance'
        name='cng_allowance'
        initialValue={formData && formData.cng_allowance}
        rules={[
          {
            required: true,
            message: 'Please input CNG allowance',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'CNG allowance'} />
      </Form.Item>

      <Form.Item
        label='Trailer Age'
        name='trailer_age'
        initialValue={formData && formData.trailer_age}
        rules={[
          {
            required: true,
            message: 'Please input trailer age',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Trailer age'} />
      </Form.Item>
      <Form.Item
        label='Bzp'
        name='bzp'
        initialValue={formData && formData.bzp && formData.bzp.job_name}
        rules={[
          {
            required: user && user.is_superadmin ? false : true,
            message: 'Please select bzp',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a bzp'
          optionFilterProp='children'
          loading={isBzpsFetching || isBzpByIdFetching}
          disabled={!user || !user.is_superadmin}
          onChange={onBzpChange}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {bzps &&
            bzps.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Is Active'
        name='is_active'
        initialValue={formData && formData.is_active}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default TrailerFormComponent;
