/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useErrorHandler } from 'common_components/ErrorContext';
import {
  PageHeader,
  Divider,
  Modal,
  message,
  Typography,
  Row,
  Col,
} from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import Toolbar from './Toolbar';
import Status from './Status';
import Table from './Table';
import {
  useFindDataInitMutation,
  useFindStatusTotalRecordMutation,
  useSubmitManageTourMutation,
  useMatchManageTourMutation,
  useNotifyManageTourMutation,
  useNotifyBzpManageTourMutation,
  manageTourListApi,
  useUpdateManageTourEditMutation,
  useUpdateManageTourHourCalMutation,
  useFindDataNotifyBzpCheckMutation,
  useDeleteManageTourMutation,
  useReleaseJobcodeMutation,
  usePurgeTourMutation,
  useFindDownloadCSVManageTourListMutation,
  useFindManageTourListStatusQuery,
  useFindDownloadJobCodeCSVManageTourListMutation,
} from 'reducers/manageTourList/api';
import {
  createTripApi,
  useFindAllLocationFromToQuery,
} from 'reducers/createTrip/api';
import moment from 'moment';
import './index.css';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { useParams } from 'react-router-dom';
import ConflictRecvJobTimeErrMessage from './ConflictRecvJobTimeErrMessage';

const { Title, Text } = Typography;
const LIMIT = 100;
const PAGE_SIZE = 100;

const ManageTourListIndexComponent = () => {
  const { matched } = useParams();
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const [progress, setProgress] = useState(0);
  const [isStatusCreateLot, setIsStatusCreateLot] = useState(false);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const shiftCalulateRef = useRef();
  const errorETARef = useRef();
  const dataTableEditRef = useRef();
  const yardOutTimeRef = useRef();
  const dataLocationFromToRef = useRef();
  const headerDateTableRef = useRef([]);
  const payloadRef = useRef(null);
  const dataInitRef = useRef();
  const tourIdRef = useRef();

  const [receiveJobDatetime, setReceiveJobDatetime] = useState(null);
  const [totalRows, setTotalRows] = useState(null);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [checkCalculate, setCheckCalculate] = useState(false);
  const [filterCompanyEdit, setFilterCompanyEdit] = useState();
  const [filterCompanyEditOption, setFilterCompanyEditOption] = useState();
  const [filterTripTypeEdit, setFilterTripTypeEdit] = useState();
  const [filterTripTypeEditOption, setFilterTripTypeEditOption] = useState();
  const [filterTrailerTypeEdit, setFilterTrailerTypeEdit] = useState();
  const [filterTrailerTypeEditOption, setFilterTrailerTypeEditOption] =
    useState();
  const [filterMatchJobCodeEdit, setFilterMatchJobCodeEdit] = useState([0]);
  const [filterMatchJobCodeEditOption, setFilterMatchJobCodeEditOption] =
    useState();
  const [filterJobCodeEdit, setFilterJobCodeEdit] = useState();
  const [filterJobCodeEditOption, setFilterJobCodeEditOption] = useState();

  // parameter for toolbar 1
  const [filterWorkingDate, setFilterWorkingDate] = useState();
  const [filterMatch, setFilterMatch] = useState();
  const [filterTour, setFilterTour] = useState();
  const [loadingDataTableEditInit, setLoadingDataTableEditInit] = useState();
  const [loadingDataTableEditOptionInit, setLoadingDataTableEditOptionInit] =
    useState();

  const [dataHourCalEdit, setDataHourCalEdit] = useState();
  const [dataTableEdit, setDataTableEdit] = useState();
  const [dataTableEditInit, setDataTableEditInit] = useState();
  const [filterWorkingOptionDate, setFilterWorkingOptionDate] = useState();
  const [filterOptionWorkingDate, setFilterOptionWorkingDate] = useState();
  const [filterCompany, setFilterCompany] = useState(['All']);
  const [filterCompanyOption, setFilterCompanyOption] = useState();
  const [filterStatusPoint, setFilterStatusPoint] = useState();
  const [filterStatusPointOption, setFilterStatusPointOption] = useState();
  const [filterStatus, setFilterStatus] = useState();
  const [filterStatusOption, setFilterStatusOption] = useState();
  const [filterRegion, setFilterRegion] = useState();
  const [filterRegionOption, setFilterRegionOption] = useState();
  const [filterShift, setFilterShift] = useState();
  const [filterShiftOption, setFilterShiftOption] = useState();
  // parameter for toolbar 2
  const [filterJobcode, setFilterJobcode] = useState();
  const [filterJobcodeOption, setFilterJobcodeOption] = useState();
  const [filterTripType, setFilterTripType] = useState();
  const [filterTripTypeOption, setFilterTripTypeOption] = useState();
  const [filterTrailerType, setFilterTrailerType] = useState();
  const [filterTrailerTypeOption, setFilterTrailerTypeOption] = useState();
  const [filterFrom, setFilterFrom] = useState();
  const [filterFromOption, setFilterFromOption] = useState();
  const [filterTo, setFilterTo] = useState();
  const [filterToOption, setFilterToOption] = useState();
  const [filterCompanyBZP, setFilterCompanyBZP] = useState();
  const [filterCompanyOptionBZP, setFilterCompanyOptionBZP] = useState();
  //for status total record
  const [valueStatusList, setValueStatusList] = useState();
  const [valueTotalList, setValueTotalList] = useState();
  const [dataInitDisabled, setDataInitDisabled] = useState(false);
  const [filterAndDowlandEnable, setFilterAndDowlandEnable] = useState(false);

  //for data api
  const [dataSourceTable, setDataSourceTable] = useState([]);
  const [isManualJobcodeModalVisible, setIsManualJobcodeModalVisible] =
    useState(false);
  const [isEditRecvJobTimeModalVisible, setIsEditRecvJobTimeModalVisible] =
    useState(false);

  const openPopupEdit = (what) => {
    // console.log('openPopupEdit 1', what);
    what = what || 'jobcode';
    // console.log('openPopupEdit 2', what);
    if (what === 'jobcode') {
      setIsManualJobcodeModalVisible(true);
    } else {
      setIsEditRecvJobTimeModalVisible(true);
    }
  };
  const handleOk = () => {
    // setIsModalVisible(false);
    setIsManualJobcodeModalVisible(false);
    setIsEditRecvJobTimeModalVisible(false);
  };

  const handleCancel = () => {
    onBtnResetEdit();
    setIsManualJobcodeModalVisible(false);
    setIsEditRecvJobTimeModalVisible(false);
  };
  const onBtnResetEdit = () => {
    setFilterCompanyEdit(undefined);
    let valTripType = filterTripTypeEditOption?.find(
      (f) => f.label === dataTableEditInit?.trip_type,
    );
    setFilterTripTypeEdit(valTripType?.value);

    let valTrailer = filterTrailerTypeEditOption?.find(
      (f) => f.label === dataTableEditInit?.trailer_type,
    );
    setFilterTrailerTypeEdit(valTrailer?.value);

    setFilterMatchJobCodeEdit([0]);
    setFilterJobCodeEdit(undefined);
    setFilterJobCodeEditOption(undefined);
    onShiftCalulateRef(null);
    setCheckCalculate(false);
    setDataHourCalEdit(null);
  };
  const onBtnBackEdit = () => {
    handleCancel();
  };

  const executeQueryEdit = useCallback(
    async (payload) => {
      let newData = null;

      try {
        setLoadingDataTableEditOptionInit(true);
        let result = await dispatch(
          manageTourListApi.endpoints.findDataManageTourEditInitJobcode.initiate(
            payload,
            {
              forceRefetch: true,
            },
          ),
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let findMatchJobcode = result.data?.jobcodes?.map((d) => ({
              label: d.name,
              value: d.value,
            }));
            setFilterJobCodeEditOption(findMatchJobcode);
            setFilterJobCodeEdit(
              findMatchJobcode.length > 0
                ? findMatchJobcode[0]?.value
                : undefined,
            );
            // use the same jobcode --, no check necessary
            // if (result.data?.jobcodes.length > 0) {
            //   setCheckCalculate(true);
            // } else {
            //   setCheckCalculate(false);
            // }
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingDataTableEditOptionInit(false);
        return { data: newData };
      }
    },
    [dispatch, setErrorData],
  );

  // edit recv job doesn't care about jobcode validation anymore #TV-13
  // useEffect(() => {
  //   if (
  //     receiveJobDatetime ||
  //     (filterCompanyEdit && filterMatchJobCodeEdit === 0) ||
  //     (filterCompanyEdit && filterMatchJobCodeEdit === 1) ||
  //     (filterCompanyEdit && filterMatchJobCodeEdit && filterTripTypeEdit) ||
  //     (filterCompanyEdit && filterMatchJobCodeEdit) ||
  //     (filterCompanyEdit && filterMatchJobCodeEdit && filterTripTypeEdit)
  //   ) {
  //     let lots = dataTableEditRef.current.map((d) => ({
  //       trip_no: d.trip_no,
  //       date: d.calendarSave ? d.calendarSave : d.lots?.date,
  //       location_from__name: d.lots?.location_from__name,
  //       location_to__name: d.lots?.location_to__name,
  //       unit: d.lots?.unit,
  //       lot_datetime: d.lots?.lot_datetime?.timestamp,
  //       receive_job_datetime: d.receive_job_datetime
  //         ? moment(d.receive_job_datetime).format()
  //         : d.lots?.receive_job_time?.timestamp,
  //       yard_out_time: d.yardout_datetime
  //         ? moment(d.yardout_datetime).format()
  //         : d.lots?.yard_out_time?.timestamp,
  //       arrival_time: d.eta_datetime
  //         ? moment(d.eta_datetime).format()
  //         : d.lots?.arrival_time?.timestamp,
  //       departure_time: d.departure_time
  //         ? moment(d.departure_time).format()
  //         : d.lots?.departure_time?.timestamp,
  //       return_time: d.return_time
  //         ? moment(d.return_time).format()
  //         : d.lots?.return_time?.timestamp,
  //     }));
  //     // no need to change all these
  //     // if (filterCompanyEdit && filterTripTypeEdit) {
  //     //   let payload = {
  //     //     tour_id: tourIdRef.current,
  //     //     company: filterCompanyEdit,
  //     //     trailer_type: filterTrailerTypeEdit,
  //     //     match_jobcode:
  //     //       filterMatchJobCodeEdit?.length > 0
  //     //         ? filterMatchJobCodeEdit[0]
  //     //         : filterMatchJobCodeEdit,
  //     //     trip_type: filterTripTypeEdit,
  //     //     job_code: filterJobCodeEdit,
  //     //     lots: lots,
  //     //   };
  //     //   executeQueryEdit(payload);
  //     // }
  //   }
  // }, [
  //   receiveJobDatetime,
  //   filterCompanyEdit,
  //   filterTripTypeEdit,
  //   filterTrailerTypeEdit,
  //   filterMatchJobCodeEdit,
  // ]);

  const {
    data: dataManageTourListStatus,
    isLoading: isLoadingEntry,
    refetch: refetchCreateLotSummary,
  } = useFindManageTourListStatusQuery(null, {
    skip: false,
    pollingInterval: progress === 100 ? 0 : 5000, //5 วิ
  });

  useEffect(() => {
    if (dataManageTourListStatus) {
      setProgress(
        dataManageTourListStatus?.progress
          ? dataManageTourListStatus?.progress
          : 0,
      );

      if (
        dataManageTourListStatus?.progress === 100 &&
        isStatusCreateLot === true
      ) {
        // message.success(dataManageTourListStatus?.message);
        setIsStatusCreateLot(false);
        Modal.success({
          title: 'Match Job code !!',
          content: (
            <div>
              <Row style={{ paddingTop: '20px' }}>
                <Col style={{ marginRight: '20px' }}>
                  <p>
                    TEM Before :{' '}
                    {dataManageTourListStatus?.result?.pre_tem_tour_count}
                  </p>

                  <p>
                    TEM After : {dataManageTourListStatus?.result?.total?.TEM}{' '}
                  </p>

                  <p>
                    TEM Matched :{' '}
                    {dataManageTourListStatus?.result?.total?.assigned}{' '}
                  </p>
                </Col>
              </Row>
            </div>
          ),

          onOk() {},
        });
        refetchDataAll();
      }
    }
  }, [dataManageTourListStatus]);

  //apiDataInit -------------------------------------------------------
  const [
    apiDataInit,
    {
      data: dataInit,
      error: errorDataInit,
      isLoading: isLoadingDataInit,
      refetch: refetchDataInit,
    },
  ] = useFindDataInitMutation();

  const onInItData = (_dataInit) => {
    let workingDateOptions = [
      {
        label: 'All',
        value: 'all',
      },
    ];

    workingDateOptions.push(
      ..._dataInit.filters?.working_date?.map((d) => ({
        label: moment(d.name).format('DD/MM/YY'),
        value: moment(d.value).format('YYYY-MM-DD'),
      })),
    );

    setFilterOptionWorkingDate(workingDateOptions);

    let findStatusPoint = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findStatusPoint.push(
      ..._dataInit.filters?.start_point?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterStatusPointOption(findStatusPoint);

    let findStatusCompany = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findStatusCompany.push(
      ..._dataInit.filters?.company?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterCompanyOption(findStatusCompany);
    setFilterCompanyOptionBZP(
      _dataInit.filters?.company?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    let findStatus = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findStatus.push(
      ..._dataInit.filters?.status?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterStatusOption(findStatus);

    let findRegionTo = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findRegionTo.push(
      ..._dataInit.filters?.region?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterRegionOption(findRegionTo);

    let findShift = _dataInit.filters?.shift?.map((d) => ({
      label: d.name,
      value: d.value,
    }));

    setFilterShiftOption(findShift);

    let findJobCode = [
      {
        label: 'All',
        value: 'All',
      },
      {
        label: 'TEM',
        value: 'TEM',
      },
    ];

    findJobCode.push(
      ..._dataInit.filters?.job_code?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterJobcodeOption(findJobCode);

    let findTripType = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findTripType.push(
      ..._dataInit.filters?.trip_type?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterTripTypeOption(findTripType);

    let findTrailerType = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findTrailerType.push(
      ..._dataInit.filters?.trailer_type?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterTrailerTypeOption(findTrailerType);
    let findLocationFroms = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findLocationFroms.push(
      ..._dataInit.filters?.from?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );
    setFilterFromOption(findLocationFroms);

    let findLocationTo = [
      {
        label: 'All',
        value: 'All',
      },
    ];

    findLocationTo.push(
      ..._dataInit.filters?.to?.map((d) => ({
        label: d.name,
        value: d.value,
      })),
    );

    setFilterToOption(findLocationTo);
  };

  useEffect(() => {
    if (dataInit) {
      dataInitRef.current = dataInit;

      if (dataInit.message === 'plan job does not exist') {
        Modal.warning({
          title: 'ไม่สามารถใช้งาน หน้า Manage Tour List ได้',
          width: 500,
          content: (
            <div>
              <p>เนื่องจากไม่พบ plan job</p>
            </div>
          ),
          onOk: () => {
            setDataInitDisabled(true);
            if (dataInit.plan_job_id) {
              setFilterAndDowlandEnable(false);
              onInItData(dataInit);
            } else {
              setFilterAndDowlandEnable(true);
            }
          },
        });
      } else {
        onInItData(dataInit);
      }
    }
  }, [dataInit]);

  useEffect(() => {
    if (errorDataInit) {
      setErrorData(errorDataInit);
    }
  }, [errorDataInit, setErrorData]);

  useEffect(() => {
    apiDataInit();
  }, []);

  const [
    apiStatusTotal,
    {
      data: dataStatusTotal,
      error: errorStatusTotal,
      isLoading: isLoadingStatusTotal,
    },
  ] = useFindStatusTotalRecordMutation();

  useEffect(() => {
    if (dataStatusTotal) {
      let sum =
        dataStatusTotal?.status?.blank +
        dataStatusTotal?.status?.accept +
        dataStatusTotal?.status?.request +
        dataStatusTotal?.status?.reject;
      let arr_status = [
        {
          blank: dataStatusTotal?.status?.blank
            ? dataStatusTotal?.status?.blank
            : 0,
          accept: dataStatusTotal?.status?.accept
            ? dataStatusTotal?.status?.accept
            : 0,
          request: dataStatusTotal?.status?.request
            ? dataStatusTotal?.status?.request
            : 0,
          reject: dataStatusTotal?.status?.reject
            ? dataStatusTotal?.status?.reject
            : 0,
          total: sum ? sum : 0,
        },
      ];

      let arr_total = [
        {
          total_record: dataStatusTotal?.total ? dataStatusTotal?.total : 0,
          not_match_bzp: dataStatusTotal?.no_bzp_matched
            ? dataStatusTotal?.no_bzp_matched
            : 0,
        },
      ];

      let res_arr_status = arr_status.map((d, i) => ({
        ...d,
        id: i + arr_status.length + 1,
      }));
      let res_arr_total = arr_total.map((d, i) => ({
        ...d,
        id: i + arr_total.length + 1,
      }));

      setValueStatusList(res_arr_status);
      setValueTotalList(res_arr_total);
    }
  }, [dataStatusTotal]);

  useEffect(() => {
    if (errorStatusTotal) {
      setErrorData(errorStatusTotal);
    }
  }, [errorStatusTotal, setErrorData]);

  useEffect(() => {
    let bodyStatus = { company: 'All' };
    apiStatusTotal(bodyStatus);
  }, [apiStatusTotal]);

  const onQuery = async (payload) => {
    try {
      if (!isLoading) {
        let bufferData = [];
        let requiredRows = page * PAGE_SIZE;
        requiredRows =
          requiredRows > totalRows && totalRows !== 0
            ? totalRows
            : requiredRows;
        if (requiredRows > bufferData.length) {
          // Fetch more data
          let curOffset = 0;
          payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };
          do {
            let queryRes = await executeQuery(
              curOffset,
              bufferData,
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true,
            );
            curOffset += LIMIT;
            bufferData = queryRes.data;
            requiredRows =
              requiredRows > queryRes.totalRows
                ? queryRes.totalRows
                : requiredRows;
          } while (requiredRows > bufferData.length);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let bodyStatus = { company: payload?.company };
      apiStatusTotal(bodyStatus);
      let newData = null;
      let totalRows = null;
      try {
        let tem =
          payload?.job_code?.filter((item) => item?.includes('TEM')) || [];
        let body = null;
        if (tem?.length > 0) {
          setFilterMatch(null);
          const result = payload?.job_code?.filter(
            (item) => !item.includes('TEM'),
          );
          body = {
            ...payload,
            matched: false,
            job_code: result,
          };
        } else {
          setFilterMatch(matched);
        }

        setIsLoading(true);
        let result = await dispatch(
          manageTourListApi.endpoints.findSearchManageTourListPagination.initiate(
            body ? body : payload,
            {
              forceRefetch: forceRefetch ? true : false,
            },
          ),
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              no: i + rowNo,
            }));
            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            headerDateTableRef.current = newData;
            setDataSourceTable(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows],
  );

  const onInit = useCallback(
    (payload) => {
      if (offset > 0) {
        payloadRef.current = { ...payload, limit: LIMIT, offset: offset };
        return;
      }
      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      headerDateTableRef.current = [];
      setDataSourceTable([]);
      executeQuery(0, [], queryPayload);
    },
    [executeQuery, offset],
  );

  const [
    onBtnSubmit,
    {
      isSuccess: isSuccessSubmit,
      isUninitialized: isUninitSubmit,
      error: errorSubmit,
      isLoading: isLoadingSubmit,
    },
  ] = useSubmitManageTourMutation();

  useEffect(() => {
    if (errorSubmit) {
      setErrorData(errorSubmit);
    }
  }, [errorSubmit, setErrorData]);

  useEffect(() => {
    if (!isUninitSubmit && isSuccessSubmit) {
      message.success('Data was successfully saved');
      refetchDataInit();
    }
  }, [isSuccessSubmit, isUninitSubmit]);

  const [
    onMatchSubmit,
    {
      data: dataMatchSubmit,
      isSuccess: isSuccessMatch,
      isUninitialized: isUninitMatch,
      error: errorMatch,
      isLoading: isLoadingMatch,
    },
  ] = useMatchManageTourMutation();

  useEffect(() => {
    if (errorMatch) {
      setErrorData(errorMatch);
    }
  }, [errorMatch, setErrorData]);

  useEffect(() => {
    if (dataMatchSubmit) {
      setProgress(0);
      refetchDataAll();
    }
  }, [dataMatchSubmit]);

  const [
    onNotifySubmit,
    {
      data: dataNotifySubmit,
      isSuccess: isSuccessNotify,
      isUninitialized: isUninitNotify,
      error: errorNotify,
      isLoading: isLoadingOpenToBZP,
    },
  ] = useNotifyManageTourMutation();

  useEffect(() => {
    if (errorNotify) {
      setErrorData(errorNotify);
    }
  }, [errorNotify, setErrorData]);

  const onCallCheckAndSaveEdit = (value, noTemp) => {
    let lots = dataTableEditRef.current.map((d, dIdx) => {
      return {
        trip_no: d.trip_no,
        date: d.calendarSave ? d.calendarSave : d.lots?.date,
        location_from__name: d.lots?.location_from__name,
        location_to__name: d.lots?.location_to__name,
        unit: d.lots?.unit,
        lot_datetime: d.lots?.lot_datetime?.timestamp,
        receive_job_datetime: d.receive_job_datetime
          ? moment(d.receive_job_datetime).format()
          : d.lots?.receive_job_time?.timestamp,
        yard_out_time: d.yardout_datetime
          ? moment(d.yardout_datetime).format()
          : d.lots?.yard_out_time?.timestamp,
        arrival_time: d.eta_datetime
          ? moment(d.eta_datetime).format()
          : d.lots?.arrival_time?.timestamp,
        departure_time: d.departure_time
          ? moment(d.departure_time).format()
          : d.lots?.departure_time?.timestamp,
        return_time: d.return_time
          ? moment(d.return_time).format()
          : d.lots?.return_time?.timestamp,
      };
    });
    let payload = {
      tour_id: tourIdRef.current,
      job_code: noTemp ? dataTableEditInit?.job_code : filterJobCodeEdit,
      lots: lots,
      confirm: value,
      trip_type: filterTripTypeEdit,
    };
    callCheckAndSaveEdit(payload);
  };
  const [
    callCheckAndSaveEdit,
    {
      data: dataCheckAndSave,
      error: errorCheckAndSave,
      isLoading: isLoadingCheckAndSave,
    },
  ] = useUpdateManageTourEditMutation();

  const modalSaveEdit = async () => {
    if (!filterJobCodeEdit) {
      onCallCheckAndSaveEdit(false, 'noTemp');
    } else {
      onCallCheckAndSaveEdit(false);
    }
  };

  const refetchDataAll = () => {
    let search = {
      company: filterCompany,
      working_day: filterWorkingDate,
      start_point: filterStatusPoint,
      status: filterStatus,
      region: filterRegion,
      shift: filterShift,
      job_code: filterJobcode,
      trip_type: filterTripType,
      trailer_type: filterTrailerType,
      from: filterFrom,
      to: filterTo,
    };
    setCheckCalculate(false);
    onBtnResetEdit();
    apiDataInit();
    let bodyStatus = { company: 'All' };
    apiStatusTotal(bodyStatus);
    onQuery(search);
  };

  const [
    deleteManageTour,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
    },
  ] = useDeleteManageTourMutation();

  const [
    releaseJobcode,
    {
      isSuccess: isSuccessReleasing,
      isUninitialized: isUninitReleasing,
      error: errorReleasing,
    },
  ] = useReleaseJobcodeMutation();

  const [
    purgeTour,
    {
      isSuccess: isSuccessPurging,
      isUninitialized: isUninitPurging,
      error: errorPurging,
    },
  ] = usePurgeTourMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  useEffect(() => {
    if (isSuccessDeleting) {
      message.success('Trips was successfully released');
      refetchDataAll();
    }
  }, [isSuccessDeleting]);

  useEffect(() => {
    if (isSuccessReleasing) {
      message.success('Job code were successfully released');
      refetchDataAll();
    }
  }, [isSuccessReleasing]);

  useEffect(() => {
    if (isSuccessPurging) {
      message.success('Data was successfully deleted');
      refetchDataAll();
    }
  }, [isSuccessPurging]);

  useEffect(() => {
    if (dataCheckAndSave?.success === true) {
      message.success('Data was successfully saved');
      // setIsModalVisible(false);
      setIsManualJobcodeModalVisible(false);
      setIsEditRecvJobTimeModalVisible(false);

      refetchDataAll();
    } else {
      if (dataCheckAndSave?.warning) {
        Modal.confirm({
          title: 'Would you like to edit ?',
          content: (
            <div>
              {dataCheckAndSave?.warning?.length > 0 ? (
                <>
                  <Row gutter={[20]} align='center'>
                    <Col span={20}>
                      <div style={{ color: 'red' }}>Warning</div>
                      <div style={{ color: 'red' }}>
                        {dataCheckAndSave?.warning?.map((d, index) => {
                          return (
                            <div>
                              - {d}
                              <br />
                            </div>
                          );
                        })}
                      </div>
                    </Col>
                  </Row>
                </>
              ) : null}
              {dataCheckAndSave?.eff_tours?.length > 0 ? (
                <ConflictRecvJobTimeErrMessage
                  effTours={dataCheckAndSave?.eff_tours}
                />
              ) : null}
            </div>
          ),
          width: 500,
          onOk() {
            if (!filterJobCodeEdit) {
              onCallCheckAndSaveEdit(true, 'noTemp');
            } else {
              onCallCheckAndSaveEdit(true);
            }
          },
        });
      }
    }
  }, [dataCheckAndSave]);
  useEffect(() => {
    if (errorCheckAndSave) {
      setErrorData(errorCheckAndSave);
    }
  }, [errorCheckAndSave, setErrorData]);

  useEffect(() => {
    if (!isUninitNotify && isSuccessNotify && dataNotifySubmit) {
      message.success(dataNotifySubmit?.message);
      refetchDataAll();
      let bodyStatus = { company: 'All' };
      apiStatusTotal(bodyStatus);
    }
  }, [dataNotifySubmit, isSuccessNotify, isUninitNotify]);

  const [callHourCalEdit, { data: dataHourCal }] =
    useUpdateManageTourHourCalMutation();

  useEffect(() => {
    if (dataHourCal) {
      setDataHourCalEdit(dataHourCal);
    }
  }, [dataHourCal]);
  const [
    callDataNotifyBzpCheck,
    {
      data: dataNotifyBzpCheck,
      error: errorNotifyBzpCheck,
      isLoading: isLoadingReConfirmToBzP,
    },
  ] = useFindDataNotifyBzpCheckMutation();

  useEffect(() => {
    if (errorNotifyBzpCheck) {
      setErrorData(errorNotifyBzpCheck);
    }
  }, [errorNotifyBzpCheck, setErrorData]);

  useEffect(() => {
    if (dataNotifyBzpCheck) {
      if (dataNotifyBzpCheck?.tour_count !== 0) {
        Modal.confirm({
          title: 'คุณต้องการ ReConfirm to BzP หรือไม่',
          content: (
            <div>
              <Row style={{ paddingTop: '20px' }}>
                <Col style={{ marginRight: '20px' }}>
                  <p> Company : {filterCompanyBZP} </p>
                  <p> Number of record : {dataNotifyBzpCheck?.tour_count} </p>
                </Col>
              </Row>
            </div>
          ),
          onOk() {
            onNotifyBzpSubmit({
              bzp: filterCompanyBZP,
            });
          },
        });
      } else {
        Modal.error({
          title: 'No tour for this bzp',
        });
      }
    }
  }, [dataNotifyBzpCheck]);

  const [
    onNotifyBzpSubmit,
    {
      data: dataNotifyBzpSubmit,
      isSuccess: isSuccessBzpNotify,
      isUninitialized: isUninitBzpNotify,
      error: errorBzpNotify,
    },
  ] = useNotifyBzpManageTourMutation();

  useEffect(() => {
    if (errorBzpNotify) {
      Modal.error({
        title: errorBzpNotify?.data?.message,
      });
    }
  }, [errorBzpNotify, setErrorData]);

  useEffect(() => {
    if (!isUninitBzpNotify && isSuccessBzpNotify) {
      message.success(dataNotifyBzpSubmit?.message);
      refetchDataAll();
    }
  }, [dataNotifyBzpSubmit, isSuccessBzpNotify, isUninitBzpNotify]);

  const getEditInit = async (value, company) => {
    let resData = null;
    tourIdRef.current = value;
    try {
      setLoadingDataTableEditInit(true);
      let result = await dispatch(
        manageTourListApi.endpoints.findDataManageTourEditInit.initiate(value, {
          forceRefetch: true,
        }),
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          setDataTableEditInit(result.data);

          let arrData = result.data?.lots?.map((d, i) => ({
            ...d,
            id: i + 1,
            receive_job_ts_original: d?.lots?.receive_job_time?.timestamp,
            receive_job_day_offset_original:
              d?.lots?.receive_job_time?.day_offset,
          }));
          dataTableEditRef.current = arrData;
          setDataTableEdit(arrData);

          let findCompany = result.data?.filters?.company?.map((d) => ({
            label: d.name,
            value: d.value,
          }));

          setFilterCompanyEditOption(findCompany);

          setFilterCompanyEdit(
            company.slice(0, 3) !== 'TEM' ? company.slice(0, 3) : null,
          );
          let findTripType = result.data?.filters?.trip_type?.map((d) => ({
            label: d.name,
            value: d.value,
          }));

          setFilterTripTypeEditOption(findTripType);

          let valTripType = findTripType?.find(
            (f) => f.label === result.data?.trip_type,
          );
          setFilterTripTypeEdit(valTripType?.value);

          let findTrailerType = result.data?.filters?.trailer_type?.map(
            (d) => ({
              label: d.name,
              value: d.value,
            }),
          );

          setFilterTrailerTypeEditOption(findTrailerType);
          let findMatchJobcode = result.data?.filters?.match_jobcode?.map(
            (d) => ({
              label: d.name,
              value: d.value,
            }),
          );

          setFilterMatchJobCodeEditOption(findMatchJobcode);

          let valTrailer = findTrailerType?.find(
            (f) => f.label === result.data?.trailer_type,
          );
          setFilterTrailerTypeEdit(valTrailer?.value);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingDataTableEditInit(false);
      return resData;
    }
  };

  const updateDataTable = async (objValue, id_jobcode) => {
    let arrDataTable = [];
    arrDataTable = dataTableEditRef.current
      .filter((item) => item.id === id_jobcode)
      .map((item) => ({
        ...item,
        ...objValue,
      }));

    let dataTableNew = dataTableEditRef.current;
    const index = dataTableEditRef.current.findIndex(
      (item) => item.id === id_jobcode,
    );
    dataTableNew.splice(index, 1, ...arrDataTable);
    dataTableEditRef.current = [...dataTableNew];
    setDataTableEdit([...dataTableNew]);
  };
  const onReceiveJobTimeField = async (value, row_id) => {
    setCheckCalculate(false);
    setReceiveJobDatetime(value);
    updateDataTable(value, row_id);
    onBtnCheck(true);
  };

  const { data: dataLocationFromTo } = useFindAllLocationFromToQuery();

  useEffect(() => {
    if (dataLocationFromTo) {
      dataLocationFromToRef.current = dataLocationFromTo;
    }
  }, [dataLocationFromTo]);

  const onRouteSpeedDistanceETATime = async (value, row_id) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findRouteSpeedDistance.initiate(value, {
            forceRefetch: true,
          }),
        );

        if (result && result.error) {
          let obj_eta_time = {
            eta_datetime: null,
          };
          updateDataTable(obj_eta_time, row_id);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onOperationLeadtimes = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findOperationLeadtimes.initiate(value, {
            forceRefetch: true,
          }),
        );

        if (result && result.error) {
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onGetETA = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findGetETA.initiate(value, {
            forceRefetch: true,
          }),
        );

        if (result && result.error) {
          Modal.warning({
            title: result.error.data.errorList[0].errorDetail,
            icon: <ExclamationCircleOutlined />,
            okText: 'OK',
          });
        } else {
          if (result.data) {
            errorETARef.current = true;
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const getETASortAndReturnTime = async (value, loading_time, minutes) => {
    try {
      let loadingTime = loading_time;
      errorETARef.current = false;
      let from = dataLocationFromToRef.current.from.find(
        (f) => f.name === value.lots?.location_from__name,
      );
      let to = dataLocationFromToRef.current.destination.find(
        (f) => f.cluster?.code === value.lots?.location_to__name,
      );
      if (from && to) {
        let bodyLeadTime = {
          location_from: from?.id,
          location_to: to?.id,
        };
        let leadTime = await onRouteSpeedDistanceETATime(
          bodyLeadTime,
          value.id,
        );

        let bodyUnLoadTime = {
          location: leadTime?.location_to?.code,
          unit: value.lots?.unit,
          type: 'unloading',
          mode: 'display',
        };

        let unLoadTime = await onOperationLeadtimes(bodyUnLoadTime);
        let location_return = dataLocationFromToRef.current.from.find(
          (f) => f.name === value.lots?.location_return__name,
        );

        let body = {
          location_from: from?.id,
          location_to: leadTime
            ? leadTime?.location_to?.id
            : to
            ? to?.id
            : null,
          location_return: location_return?.id,
          receive_job_time: value.receive_job_datetime
            ? moment(value.receive_job_datetime).format()
            : value.lots?.receive_job_time?.timestamp,
          loading_time: loadingTime,
          unloading_time: unLoadTime[0]?.time,
        };
        let resETA = await onGetETA(body, value.id);

        let obj_eta_time = {
          eta_datetime: value.lots?.arrival_time?.timestamp
            ? moment(value.lots?.arrival_time?.timestamp).add(
                minutes,
                'minutes',
              )
            : 'none',
          return_time: resETA?.return_time,
          destination_farthest: leadTime?.location_to,
          departure_time: value.lots?.departure_time?.timestamp
            ? moment(value.lots?.departure_time?.timestamp).add(
                minutes,
                'minutes',
              )
            : 'none',
          tempError: resETA ? true : false,
        };
        return obj_eta_time;
      } else {
        let un_obj_eta_time = {
          eta_datetime: null,
          eta_datetime_ref: null,
          return_place_datetime: null,
        };
        return un_obj_eta_time;
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const getYardOutTimeSort = async (value, minutes) => {
    try {
      let actual_from = dataLocationFromToRef.current.from.find(
        (f) => f.name === value.lots?.location_from__name,
      );
      if (
        (value.receive_job_datetime && actual_from) ||
        (value.lots?.receive_job_time?.timestamp && actual_from)
      ) {
        let body = {
          location: actual_from?.code,
          unit: value.lots?.unit,
          type: 'loading',
          mode: 'display',
        };
        let load_time = await onOperationLeadtimes(body);
        if (!load_time.length) {
          Modal.warning({
            title: 'Yard out time: Not found loading.',
            icon: <ExclamationCircleOutlined />,
            okText: 'OK',
          });
        }
        if (load_time.length > 0) {
          let yardout_datetime = value.receive_job_datetime
            ? moment(value.receive_job_datetime).add(load_time[0].time, 'm')
            : moment(value.lots?.receive_job_time?.timestamp).add(
                load_time[0].time,
                'm',
              );

          yardOutTimeRef.current = yardout_datetime;

          let obj_yard_out_time = null;
          obj_yard_out_time = {
            yardout_datetime: value.lots?.yard_out_time?.timestamp
              ? moment(value.lots?.yard_out_time?.timestamp).add(
                  minutes,
                  'minutes',
                )
              : 'none',
            loading_time: load_time[0].time,
          };

          return obj_yard_out_time;
        }
      } else {
        let un_yard_out_time = {
          yardout_datetime: null,
        };
        return un_yard_out_time;
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const calDay = (receiveJobTime, currentTime) => {
    if (currentTime !== 'none') {
      const returnDate = moment(currentTime).clone().startOf('day');
      const receiveJobDate = moment(receiveJobTime).clone().startOf('day');
      return returnDate.diff(receiveJobDate, 'days');
    } else {
      return null;
    }
  };

  function getOffsetDisplay(workingDay, tmsp) {
    let a = moment(workingDay).format('YYYY-MM-DD');
    let b = moment(tmsp).format('YYYY-MM-DD');
    let res = moment(b).diff(a, 'days');
    return res;
  }

  function getNonZeroTimeValue(newValue) {
    const startTime = moment('00:00', 'HH:mm');
    const endTime = moment('07:30', 'HH:mm');
    const currentTime = moment(newValue, 'HH:mm'); // เวลาปัจจุบัน

    return currentTime.isBetween(startTime, endTime, null, '[)') ? 1 : 0;
  }
  const onBtnCheck = async (check) => {
    // check from EditRecvJobTime
    try {
      setIsLoadingEdit(true);
      let dataTableEdit = dataTableEditRef.current;
      const receive_job_time = moment(
        dataTableEdit[0]?.receive_job_ts_original,
      );

      const receive_job_time_last = moment(
        dataTableEdit[0]?.lots?.receive_job_time?.timestamp,
      );

      const duration = moment.duration(
        receive_job_time_last.diff(receive_job_time),
      );
      let minutes = duration.asMinutes();

      for (let index = 0; index < dataTableEdit.length; index++) {
        let day_offset = dataTableEdit[0]?.lots?.receive_job_time?.day_offset;
        if (
          dataTableEdit[0]?.receive_job_day_offset_original === 1 &&
          day_offset === 0
        ) {
          day_offset = -1;
        } else if (
          dataTableEdit[0]?.receive_job_day_offset_original === 1 &&
          day_offset === 1
        ) {
          day_offset = 0;
        }
        if (index !== 0 && !dataTableEdit[index].combine) {
          //receive_job
          let return_time = moment(
            dataTableEdit[index - 1]?.lots?.return_time?.timestamp,
          ).add(minutes, 'minutes');

          dataTableEdit[index]['receive_job_datetime'] = return_time;

          let day_offset_return_time = getOffsetDisplay(
            dataTableEdit[index]?.lots?.date,
            moment(return_time).add(day_offset, 'days').format(),
          );
          dataTableEdit[index]['receive_job_datetime_plus'] =
            day_offset_return_time;
        }

        let yardout_datetime = moment(
          dataTableEdit[index]?.lots?.yard_out_time?.timestamp,
        ).add(minutes, 'minutes');
        dataTableEdit[index]['yardout_datetime'] = yardout_datetime;

        //yardout_datetime
        let day_offset_yardout = getOffsetDisplay(
          dataTableEdit[index]?.lots?.date,
          moment(yardout_datetime).add(day_offset, 'days').format(),
        );

        //arrival_time
        let arrival_time = moment(
          dataTableEdit[index]?.lots?.arrival_time?.timestamp,
        ).add(minutes, 'minutes');

        let day_offset_arrival_time = getOffsetDisplay(
          dataTableEdit[index]?.lots?.date,
          moment(arrival_time).add(day_offset, 'days').format(),
        );
        //departure_time
        let departure_time = moment(
          dataTableEdit[index]?.lots?.departure_time?.timestamp,
        ).add(minutes, 'minutes');

        let day_offset_departure_time = getOffsetDisplay(
          dataTableEdit[index]?.lots?.date,
          moment(departure_time).add(day_offset, 'days').format(),
        );

        dataTableEdit[index]['yardout_datetime_plus'] = day_offset_yardout;
        dataTableEdit[index]['arrival_time_plus'] = day_offset_arrival_time;
        dataTableEdit[index]['departure_time_plus'] = day_offset_departure_time;

        let recv_job_time_obj = dataTableEdit[0].lots?.receive_job_time;
        let current_calendar_1 = moment(recv_job_time_obj?.timestamp)
          .subtract(recv_job_time_obj?.day_offset, 'days')
          .format('YYYY-MM-DD');
        // console.log('current_calendar_1', current_calendar_1);
        // console.log(
        //   'current_calendar_1 2',
        //   dataTableEdit[0].receive_job_datetime
        // );
        // console.log('current_calendar_1 3', dataTableEdit[0].lots);
        // console.log(
        //   'current_calendar_1 4',
        //   dataTableEdit[0].lots?.receive_job_time
        // );

        dataTableEdit[index]['calendarSave'] = current_calendar_1;

        dataTableEdit[index]['eta_datetime'] = moment(
          dataTableEdit[index]?.lots?.arrival_time?.timestamp,
        ).add(minutes, 'minutes');

        dataTableEdit[index]['departure_time'] = moment(
          dataTableEdit[index]?.lots?.departure_time?.timestamp,
        ).add(minutes, 'minutes');

        dataTableEdit[index]['return_time'] = moment(
          dataTableEdit[index]?.lots?.return_time?.timestamp,
        ).add(minutes, 'minutes');
      }

      dataTableEditRef.current = dataTableEdit;
      setDataTableEdit(dataTableEdit);
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoadingEdit(false);
      setCheckCalculate(true);
    }
  };

  const onShiftCalulateRef = () => {
    shiftCalulateRef.current = null;
  };

  const [
    apiDownloadCSV,
    {
      data: dataDownloadCSV,
      error: errorDownloadCSV,
      isLoading: isLoadingDownloadCSV,
    },
  ] = useFindDownloadCSVManageTourListMutation();

  useEffect(() => {
    if (dataDownloadCSV) {
      downloadCSV(dataDownloadCSV);
    }
  }, [dataDownloadCSV]);

  const [
    apiDownloadJobCodeCSV,
    {
      data: dataDownloadJobCodeCSV,
      error: errorDownloadJobCodeCSV,
      isLoading: isLoadingDownloadJobCodeCSV,
    },
  ] = useFindDownloadJobCodeCSVManageTourListMutation();

  useEffect(() => {
    if (dataDownloadJobCodeCSV) {
      downloadJobCodeCSV(dataDownloadJobCodeCSV);
    }
  }, [dataDownloadJobCodeCSV]);

  const downloadJobCodeCSV = async (response) => {
    // response here is text/csv
    const outputFilename = `${moment().format('DDMMYY')}_Job_Code_Calendar_${
      user?.company_job_name
    }.csv`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(
      new Blob([`\ufeff${response}`], { type: 'text/csv;charset=utf-8' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  const downloadCSV = async (response) => {
    // response here is text/csv
    const outputFilename = `${moment().format(
      'DDMMYY',
    )}_Manage_Tour_List_${filterCompany}.csv`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(
      new Blob([`\ufeff${response}`], { type: 'text/csv;charset=utf-8' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    if (errorDownloadCSV) {
      setErrorData(errorDownloadCSV);
    }
  }, [errorDownloadCSV, setErrorData]);

  useEffect(() => {
    if (errorDownloadJobCodeCSV) {
      setErrorData(errorDownloadJobCodeCSV);
    }
  }, [errorDownloadJobCodeCSV, setErrorData]);

  const onBtnDownload = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to download?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        let search = {
          company: filterCompany,
          working_day: filterWorkingDate,
          start_point: filterStatusPoint,
          status: filterStatus,
          region: filterRegion,
          shift: filterShift,
          job_code: filterJobcode,
          trip_type: filterTripType,
          trailer_type: filterTrailerType,
          from: filterFrom,
          to: filterTo,
        };
        apiDownloadCSV(search);
      },
    });
  };

  const onBtnDownloadJobCodeCalendar = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to download?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        let payload = {
          plan_id: dataInitRef.current?.plan_job_id,
        };
        apiDownloadJobCodeCSV(payload);
      },
    });
  };

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title={
          <div>
            Manage Tour List{' '}
            {filterMatch ? (
              <Text type='secondary'> match BzP={filterMatch} </Text>
            ) : null}{' '}
            {filterTour ? (
              <Text type='secondary'> tour={filterTour} </Text>
            ) : null}
          </div>
        }
        extra={
          totalRows != null && <Title level={5}>{`Total ${totalRows}`}</Title>
        }
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <>
          <Toolbar
            onInit={onInit}
            onBtnSubmit={onBtnSubmit}
            isLoadingSubmit={isLoadingSubmit}
            filterOptionWorkingDate={filterOptionWorkingDate}
            setFilterOptionWorkingDate={setFilterOptionWorkingDate}
            filterWorkingDate={filterWorkingDate}
            setFilterWorkingDate={setFilterWorkingDate}
            setFilterMatch={setFilterMatch}
            setFilterTour={setFilterTour}
            filterWorkingOptionDate={filterWorkingOptionDate}
            setFilterWorkingOptionDate={setFilterWorkingOptionDate}
            filterCompany={filterCompany}
            setFilterCompany={setFilterCompany}
            filterCompanyOption={filterCompanyOption}
            setFilterCompanyOption={setFilterCompanyOption}
            filterStatusPoint={filterStatusPoint}
            setFilterStatusPoint={setFilterStatusPoint}
            filterStatusPointOption={filterStatusPointOption}
            setFilterStatusPointOption={setFilterStatusPointOption}
            filterStatus={filterStatus}
            setFilterStatus={setFilterStatus}
            filterStatusOption={filterStatusOption}
            setFilterStatusOption={setFilterStatusOption}
            filterRegion={filterRegion}
            setFilterRegion={setFilterRegion}
            filterRegionOption={filterRegionOption}
            setFilterRegionOption={setFilterRegionOption}
            filterShift={filterShift}
            setFilterShift={setFilterShift}
            filterShiftOption={filterShiftOption}
            setFilterShiftOption={setFilterShiftOption}
            filterJobcode={filterJobcode}
            setFilterJobcode={setFilterJobcode}
            filterJobcodeOption={filterJobcodeOption}
            setFilterJobcodeOption={setFilterJobcodeOption}
            filterTripType={filterTripType}
            setFilterTripType={setFilterTripType}
            filterTripTypeOption={filterTripTypeOption}
            setFilterTripTypeOption={setFilterTripTypeOption}
            filterTrailerType={filterTrailerType}
            setFilterTrailerType={setFilterTrailerType}
            filterTrailerTypeOption={filterTrailerTypeOption}
            setFilterTrailerTypeOption={setFilterTrailerTypeOption}
            filterFrom={filterFrom}
            setFilterFrom={setFilterFrom}
            filterFromOption={filterFromOption}
            setFilterFromOption={setFilterFromOption}
            filterTo={filterTo}
            setFilterTo={setFilterTo}
            filterToOption={filterToOption}
            setFilterToOption={setFilterToOption}
            filterCompanyBZP={filterCompanyBZP}
            setFilterCompanyBZP={setFilterCompanyBZP}
            filterCompanyOptionBZP={filterCompanyOptionBZP}
            setFilterCompanyOptionBZP={setFilterCompanyOptionBZP}
            apiSearchTable={onQuery}
            isLoading={isLoading}
            // dataInitDisabled={dataInitDisabled}
            filterAndDowlandEnable={filterAndDowlandEnable}
            isLoadingDataInit={isLoadingDataInit}
          />
          <Status
            filterCompanyBZP={filterCompanyBZP}
            setFilterCompanyBZP={setFilterCompanyBZP}
            filterCompanyOptionBZP={filterCompanyOptionBZP}
            setFilterCompanyOptionBZP={setFilterCompanyOptionBZP}
            valueStatusList={valueStatusList}
            valueTotalList={valueTotalList}
            callMatchJobcode={onMatchSubmit}
            callNotifySubmit={onNotifySubmit}
            isLoadingMatch={isLoadingMatch}
            dataInitDisabled={dataInitDisabled}
            dataInitRef={dataInitRef.current}
            callDataNotifyBzpCheck={callDataNotifyBzpCheck}
            dataNotifyBzpCheck={dataNotifyBzpCheck}
            isLoadingOpenToBZP={isLoadingOpenToBZP}
            isLoadingReConfirmToBzP={isLoadingReConfirmToBzP}
            progress={progress}
            setIsStatusCreateLot={setIsStatusCreateLot}
            isStatusCreateLot={isStatusCreateLot}
            dataManageTourListStatus={dataManageTourListStatus}
            isLoadingStatusTotal={isLoadingStatusTotal}
          />

          <Table
            dataSourceTable={dataSourceTable}
            setDataSourceTable={setDataSourceTable}
            getEditInit={getEditInit}
            dataTableEditInit={dataTableEditInit}
            headerDateTableRef={headerDateTableRef}
            isLoadingDataInit={isLoadingDataInit}
            PAGE_SIZE={PAGE_SIZE}
            totalRows={totalRows}
            page={page}
            offset={offset}
            payloadRef={payloadRef}
            LIMIT={LIMIT}
            setPage={setPage}
            executeQuery={executeQuery}
            isLoading={isLoading}
            filterCompanyEdit={filterCompanyEdit}
            setFilterCompanyEdit={setFilterCompanyEdit}
            filterCompanyEditOption={filterCompanyEditOption}
            filterTripTypeEdit={filterTripTypeEdit}
            setFilterTripTypeEdit={setFilterTripTypeEdit}
            filterTripTypeEditOption={filterTripTypeEditOption}
            filterTrailerTypeEdit={filterTrailerTypeEdit}
            setFilterTrailerTypeEdit={setFilterTrailerTypeEdit}
            filterTrailerTypeEditOption={filterTrailerTypeEditOption}
            filterMatchJobCodeEdit={filterMatchJobCodeEdit}
            filterMatchJobCodeEditOption={filterMatchJobCodeEditOption}
            setFilterMatchJobCodeEdit={setFilterMatchJobCodeEdit}
            setFilterJobCodeEdit={setFilterJobCodeEdit}
            filterJobCodeEditOption={filterJobCodeEditOption}
            filterJobCodeEdit={filterJobCodeEdit}
            loadingDataTableEditInit={loadingDataTableEditInit}
            loadingDataTableEditOptionInit={loadingDataTableEditOptionInit}
            receiveJobTimeField={onReceiveJobTimeField}
            dataTableEdit={dataTableEdit}
            isLoadingEdit={isLoadingEdit}
            setFilterJobCodeEditOption={setFilterJobCodeEditOption}
            dataHourCalEdit={dataHourCalEdit}
            shiftCalulateRef={shiftCalulateRef}
            onShiftCalulateRef={onShiftCalulateRef}
            modalSaveEdit={modalSaveEdit}
            isLoadingCheckAndSave={isLoadingCheckAndSave}
            handleOk={handleOk}
            handleCancel={handleCancel}
            // isModalVisible={isModalVisible}
            isEditRecvJobTimeModalVisible={isEditRecvJobTimeModalVisible}
            isManualJobcodeModalVisible={isManualJobcodeModalVisible}
            openPopupEdit={openPopupEdit}
            deleteManageTour={deleteManageTour}
            releaseJobcode={releaseJobcode}
            purgeTour={purgeTour}
            onBtnResetEdit={onBtnResetEdit}
            onBtnBackEdit={onBtnBackEdit}
            dataInitDisabled={dataInitDisabled}
            onBtnDownload={onBtnDownload}
            onBtnDownloadJobCodeCalendar={onBtnDownloadJobCodeCalendar}
            isLoadingDownloadCSV={isLoadingDownloadCSV}
            checkCalculate={checkCalculate}
            setCheckCalculate={setCheckCalculate}
            dataInitRef={dataInitRef.current}
            filterAndDowlandEnable={filterAndDowlandEnable}
            tourIdRef={tourIdRef}
            executeQueryEdit={executeQueryEdit}
            onBtnCheck={onBtnCheck}
            isLoadingDownloadJobCodeCSV={isLoadingDownloadJobCodeCSV}
            refetchDataAll={refetchDataAll}
          />
        </>
      </Content>
    </Container>
  );
};
const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default ManageTourListIndexComponent;
