import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Modal } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import Toolbar from './Toolbar';
import DataChart from './Chart';
import TableDate from './TableDate';
import './index.css';
import {
  useFindEntrySummaryYardMutation,
  useSearchChartTotalAndTrailerSummaryYardMutation,
  useSearchChartRegionFilterSummaryYardMutation,
  useSearchChartRegionNoFilterSummaryYardMutation,
} from 'reducers/summaryYard/api';
import { dateFormatterVLP } from 'common_components/AntTable/tableUtils';

const SummaryYardComponent = () => {
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  //filter
  const [filterWorkingDate, setFilterWorkingDate] = useState();
  const [filterYard, setFilterYard] = useState();
  const [filterRegion, setFilterRegion] = useState('All');
  //option
  const [filterOptionWorkingDate, setFilterOptionWorkingDate] = useState([]);
  const [filterOptionYard, setFilterOptionYard] = useState([]);
  const [filterOptionRegion, setFilterOptionRegion] = useState([]);
  //initial
  const [planJobId, setPlanJobId] = useState('');
  const [statusSummary, setStatusSummary] = useState('');
  //data chart
  const [dataChart, setDataChart] = useState([]);
  const trailerListRef = useRef([]);
  const [trailerUsageSummary, setTrailerUsageSummary] = useState();
  const headerTableDate = useRef([]);
  const filterWorkingDateRef = useRef([]);
  const [disableFilter, setDisableFilter] = useState(false);
  //data source table data
  const [dataSourceDate, setDataSourceDate] = useState([
    {
      key: 1,
      date1Value: '-',
      date2Value: '-',
      date3Value: '-',
      date4Value: '-',
      date5Value: '-',
      date6Value: '-',
      date7Value: '-',
    },
  ]);
  //for table date

  const functionForTableDate = async (filterYard) => {
    let regionCode = '';
    let arrDate = [];
    let arrValue = {
      key: 1,
      date1Value: 'Over',
      date2Value: 'OK',
      date3Value: 'OK',
      date4Value: 'Over',
      date5Value: 'Over',
      date6Value: 'OK',
      date7Value: 'OK',
    };
    let arrHeaderDate = [
      {
        title: 'Mon',
        title_value: '2022-01-01',
        dataIndex: 'date1Value',
        key: 1,
      },
      {
        title: 'Tue',
        title_value: '2022-01-02',
        dataIndex: 'date2Value',
        key: 2,
      },
      {
        title: 'Wed',
        title_value: '2022-01-03',
        dataIndex: 'date3Value',
        key: 3,
      },
      {
        title: 'Thu',
        title_value: '2022-01-04',
        dataIndex: 'date4Value',
        key: 4,
      },
      {
        title: 'Fri',
        title_value: '2022-01-05',
        dataIndex: 'date5Value',
        key: 5,
      },
      {
        title: 'Sat',
        title_value: '2022-01-06',
        dataIndex: 'date6Value',
        key: 6,
      },
      {
        title: 'Sun',
        title_value: '2022-01-07',
        dataIndex: 'date7Value',
        key: 7,
      },
    ];

    for (let i = 0; i < filterOptionYard.length; i++) {
      if (filterOptionYard[i].value === filterYard) {
        regionCode = filterOptionYard[i].label;
      }
    }

    for (const i in trailerUsageSummary) {
      if (Object.hasOwnProperty.call(trailerUsageSummary, i)) {
        if (trailerUsageSummary[i].yard === regionCode) {
          arrDate = trailerUsageSummary[i].date;
        }
      }
    }
    if (arrDate?.length !== 0) {
      arrValue.date1Value = arrDate[0]?.value;
      arrValue.date2Value = arrDate[1]?.value;
      arrValue.date3Value = arrDate[2]?.value;
      arrValue.date4Value = arrDate[3]?.value;
      arrValue.date5Value = arrDate[4]?.value;
      arrValue.date6Value = arrDate[5]?.value;
      arrValue.date7Value = arrDate[6]?.value;

      for (let k = 0; k < arrHeaderDate.length; k++) {
        arrHeaderDate[k].title = arrDate[k]?.day;
        arrHeaderDate[k].title_value = arrDate[k]?.fdate;
      }
    }
    filterWorkingDateRef.current = filterWorkingDate;
    setDataSourceDate([arrValue]);
  };

  // api getEntrySummaryYard ---------------------------------------------------------------
  const [
    getEntrySummaryYard,
    {
      data: dataSummaryYard,
      error: errorSummaryYard,
      isLoading: isLoadingSummaryYard,
    },
  ] = useFindEntrySummaryYardMutation();

  useEffect(() => {
    if (dataSummaryYard) {
      headerTableDate.current = dataSummaryYard.date;
      let resultDate = dataSummaryYard.date;
      let resultYard = dataSummaryYard.location_from;
      let resultRegion = dataSummaryYard.region;

      // set option date
      let findOptionDate = resultDate.map((d) => ({
        label: dateFormatterVLP({ value: d.fdate }),
        value: d.fdate,
      }));

      // set option date
      let findOptionYard = resultYard.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));

      // set option region
      let findOptionRegion = resultRegion.map((d) => ({
        label: d,
        value: d,
      }));
      // add all filter
      let optionRegionList = [
        {
          label: 'All',
          value: 'All',
          code: 'All',
        },
      ];
      optionRegionList.push(...findOptionRegion);

      //set value
      setFilterOptionWorkingDate(findOptionDate);
      setFilterOptionYard(findOptionYard);
      setFilterOptionRegion(optionRegionList);
      setPlanJobId(dataSummaryYard.plan_job_id);
      setStatusSummary(dataSummaryYard.status_m_trip_dash);
      setTrailerUsageSummary(dataSummaryYard.trailer_usage_summary);
    }
  }, [dataSummaryYard]);

  useEffect(() => {
    if (errorSummaryYard) {
      Modal.error({
        title: errorSummaryYard.data?.errorList[0]?.errorDetail,
        onOk: () => {
          setDisableFilter(true);
        },
      });
    }
  }, [errorSummaryYard, setErrorData]);

  useEffect(() => {
    getEntrySummaryYard();
  }, [getEntrySummaryYard]);

  // api getSearchChartTrailerAndTotal ---------------------------------------------------------------
  const [
    getSearchChartTrailerAndTotal,
    { data: dataSearchSummaryYard, error: errorSearchSummaryYard },
  ] = useSearchChartTotalAndTrailerSummaryYardMutation();

  useEffect(() => {
    if (dataSearchSummaryYard) {
      setDataChart([]);
      trailerListRef.current = dataSearchSummaryYard.usage_all_by_min;
    }
  }, [dataSearchSummaryYard]);

  useEffect(() => {
    if (errorSearchSummaryYard) {
      setErrorData(errorSearchSummaryYard);
    }
  }, [errorSearchSummaryYard, setErrorData]);

  // api getSearchChartRegionFilter ---------------------------------------------------------------
  const [
    getSearchChartRegionFilter,
    {
      data: dataChartRegionFilter,
      error: errorChartRegionFilter,
      isLoading: isLoadingChartRegionFilter,
    },
  ] = useSearchChartRegionFilterSummaryYardMutation();

  useEffect(() => {
    if (dataChartRegionFilter) {
      let arrRegion = [];
      let arrTrailerUsage = [];

      arrRegion = dataChartRegionFilter?.usage_all_by_min;
      arrTrailerUsage = trailerListRef.current;

      if (dataSearchSummaryYard?.usage_all_by_min) {
        const arraySummary = arrTrailerUsage.concat(arrRegion);
        setDataChart(arraySummary);
      }
    }
  }, [dataChartRegionFilter]);

  useEffect(() => {
    if (errorChartRegionFilter) {
      setErrorData(errorChartRegionFilter);
    }
  }, [errorChartRegionFilter, setErrorData]);

  // api getSearchChartRegionNoFilter ---------------------------------------------------------------
  const [
    getSearchChartRegionNoFilter,
    {
      data: dataSearchChartRegionNoFilter,
      error: errorSearchChartRegionNoFilter,
      isLoading: isLoadingSearchChartRegionNoFilter,
    },
  ] = useSearchChartRegionNoFilterSummaryYardMutation();

  useEffect(() => {
    if (dataSearchChartRegionNoFilter) {
      let arrRegion = [];
      let arrTrailerUsage = [];

      arrRegion = dataSearchChartRegionNoFilter?.usage_all_by_min;
      arrTrailerUsage = trailerListRef.current;

      if (dataSearchSummaryYard?.usage_all_by_min) {
        const arraySummary = arrTrailerUsage.concat(arrRegion);
        setDataChart(arraySummary);
      }
    }
  }, [dataSearchChartRegionNoFilter]);

  useEffect(() => {
    if (errorSearchChartRegionNoFilter) {
      setErrorData(errorSearchChartRegionNoFilter);
    }
  }, [errorSearchChartRegionNoFilter, setErrorData]);

  //---------------------------------------------------------------

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Summary Yard'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Toolbar
          //filter
          filterWorkingDate={filterWorkingDate}
          setFilterWorkingDate={setFilterWorkingDate}
          filterYard={filterYard}
          setFilterYard={setFilterYard}
          filterRegion={filterRegion}
          setFilterRegion={setFilterRegion}
          //option
          filterOptionWorkingDate={filterOptionWorkingDate}
          filterOptionYard={filterOptionYard}
          filterOptionRegion={filterOptionRegion}
          isLoadingSummaryYard={isLoadingSummaryYard}
          //initial
          planJobId={planJobId}
          statusSummary={statusSummary}
          //search
          getSearchChartTrailerAndTotal={getSearchChartTrailerAndTotal}
          getSearchChartRegionFilter={getSearchChartRegionFilter}
          getSearchChartRegionNoFilter={getSearchChartRegionNoFilter}
          dataChart={dataChart}
          //table date
          functionForTableDate={functionForTableDate}
          //loading
          isLoadingChartRegionFilter={isLoadingChartRegionFilter}
          isLoadingSearchChartRegionNoFilter={
            isLoadingSearchChartRegionNoFilter
          }
          disableFilter={disableFilter}
        />
        <DataChart
          dataChart={dataChart}
          isLoadingChartRegionFilter={isLoadingChartRegionFilter}
          isLoadingSearchChartRegionNoFilter={
            isLoadingSearchChartRegionNoFilter
          }
        />
        <TableDate
          dataSourceDate={dataSourceDate}
          headerTableRef={headerTableDate}
          filterWorkingDateRef={filterWorkingDateRef}
          setDataSourceDate={setDataSourceDate}
        />
      </Content>
    </Container>
  );
};
const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default SummaryYardComponent;
