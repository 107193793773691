import React, { useState, useEffect, useRef } from 'react';
import { dateTimeDDMM } from 'common_components/AntTable/tableUtils';
import './index.css';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { Form, Modal, Table, Button, DatePicker, message } from 'antd';
import {
  EditOutlined,
  CheckOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { useUpdateManageTripDetailMutation } from 'reducers/manageTripBoard/api';
import { timeFormatterNoHyphen } from 'common_components/AntTable/tableUtils';

const TableSubCluster = (props) => {
  const {
    detail,
    setDetail,
    planJobId,
    statusManageTrip,
    apiSearchTableSave,
    statusEditRowRef,
  } = props;
  const dateFormat = 'DD/MM/YY HH:mm';
  const recordRowRef = useRef();
  const recordItemIDRef = useRef();
  const recordRef = useRef();
  const receiveJobDateTimeRef = useRef();

  // const statusEditRowRef = useRef(false);
  const [form] = Form.useForm();
  const { confirm } = Modal;
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [visibleModalLoading, setVisibleModalLoading] = useState(false);

  const handleOkLoading = () => {
    setVisibleModalLoading(false);
  };

  const handleCancelLoading = () => {
    setVisibleModalLoading(false);
  };

  const [
    updateDetail,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
    },
  ] = useUpdateManageTripDetailMutation();

  useEffect(() => {
    if (!isUninitUpdating && isSuccessUpdating) {
      setVisibleModalLoading(false);
      setIsLoadingSearch(false);

      message.success('Data was successfully saved');
      apiSearchTableSave();

      let arrAddEditRowTable = detail.map((item) => ({
        ...item,
        editingRow: false,
      }));
      statusEditRowRef.current = false;

      setDetail(arrAddEditRowTable);
    }
  }, [isSuccessUpdating, isUninitUpdating]);

  useEffect(() => {
    if (errorUpdating) {
      setVisibleModalLoading(false);
      setIsLoadingSearch(false);

      Modal.error({
        title: 'Error',
        icon: <ExclamationCircleOutlined />,
        content: errorUpdating.data.errorList[0].errorDetail,
      });

      let arrAddEditRowTable = detail.map((item) => ({
        ...item,
        editingRow: false,
      }));
      statusEditRowRef.current = false;

      setDetail(arrAddEditRowTable);
    }
  }, [errorUpdating]);

  // new

  const onBtnEditVolume = async (record) => {
    recordRowRef.current = record;
    recordItemIDRef.current = record.id;

    let arrAddEditRowTable = detail.map((item) => ({
      ...item,
      editingRow: false,
    }));
    statusEditRowRef.current = true;

    for (let i = 0; i < arrAddEditRowTable.length; i++) {
      if (arrAddEditRowTable[i].id === record.id) {
        arrAddEditRowTable[i].editingRow = true;
      }
    }

    setDetail(arrAddEditRowTable);
  };

  const onBtnCancelEditVolume = async () => {
    let arrAddEditRowTable = detail.map((item) => ({
      ...item,
      editingRow: false,
    }));
    statusEditRowRef.current = false;

    setDetail(arrAddEditRowTable);
  };
  const onChange = (value, dateString) => {
    receiveJobDateTimeRef.current = value;
  };
  const onOk = (value) => {};

  const onBtnSaveEditVolume = async () => {
    let recordRow = recordRowRef.current;
    let payload = {
      plan_job_id: planJobId,
      diagram_lot_id: parseInt(recordRow?.id),
      id: parseInt(recordRow?.id),
      from_id: recordRow?.from_id,
      cluster_to_id: recordRow?.cluster_to_id,
      before_receive_job_time: recordRow?.receive_job_time,
      updated_receive_job_time: receiveJobDateTimeRef.current
        ? moment(receiveJobDateTimeRef.current).format()
        : null,
      yard_out_time: recordRow?.yard_out_time,
      arrival_time: recordRow?.arrival_time,
      departure_time: recordRow?.departure_time,
    };

    if (
      recordRef.current.lot_time !== null &&
      moment(recordRow.lot_time).diff(
        moment(receiveJobDateTimeRef.current),
        'minutes'
      ) > 0
    ) {
      confirm({
        title: 'Warning !!',
        icon: <ExclamationCircleOutlined />,
        content: 'Receive job time less than lot time.',
        onOk() {
          updateDetail(payload);
          setVisibleModalLoading(true);
          setIsLoadingSearch(true);
        },
        onCancel() {},
      });
    } else {
      setVisibleModalLoading(true);
      setIsLoadingSearch(true);
      updateDetail(payload);
    }
  };

  const columns = [
    {
      title: 'No. job',
      dataIndex: 'no_job',
      align: 'center',
      width: 50,
    },
    {
      title: 'From',
      dataIndex: 'from',
      align: 'center',
      width: 80,
    },
    {
      title: 'Cluster',
      dataIndex: 'cluster_to_code',
      align: 'center',
      width: 80,
    },
    {
      title: 'Lot time',
      dataIndex: 'lot_time',
      align: 'center',
      width: 100,
      render: (text) => {
        return dateTimeDDMM({ value: text });
      },
    },
    {
      title: 'Receive job time',
      dataIndex: 'receive_job_time',
      align: 'center',
      width: 100,
      editable: true,
      render: (text, record) => {
        if (record.editingRow === true) {
          recordRef.current = record;
          receiveJobDateTimeRef.current = record.receive_job_time;
          return (
            <DatePicker
              defaultValue={moment(record.receive_job_time)}
              showTime
              onChange={onChange}
              onOk={onOk}
              format={dateFormat}
            />
          );
        } else {
          return dateTimeDDMM({ value: text });
        }
      },
    },
    {
      title: 'Yard out time',
      dataIndex: 'yard_out_time',
      align: 'center',
      width: 100,
      render: (text, record) => {
        return dateTimeDDMM({ value: text });
      },
    },
    {
      title: 'Detail Can receive time',
      dataIndex: 'detail_can_receive_time',
      align: 'center',
      width: 200,
      render: (text, record) => {
        let arrDate = record.detail_can_receive_time;
        let date_list = [];

        if (arrDate !== null) {
          for (const i in arrDate) {
            if (Object.hasOwnProperty.call(arrDate, i)) {
              date_list.push(arrDate[i].start_time.substring(0, 5) + '-');

              if (parseInt(i) !== parseInt(arrDate.length - 1)) {
                date_list.push(arrDate[i].end_time.substring(0, 5) + ' , ');
              } else {
                date_list.push(arrDate[i].end_time.substring(0, 5));
              }
            }
          }
        }

        return date_list;
      },
    },
    {
      title: 'Action',
      align: 'center',
      dataIndex: 'id',
      width: 100,

      render: (text, record) => {
        if (
          statusEditRowRef.current === false &&
          statusManageTrip === 'ready'
        ) {
          return (
            <div>
              <Button
                type='primary'
                shape='circle'
                size='small'
                onClick={() => {
                  onBtnEditVolume(record);
                }}
                icon={<EditOutlined />}
              ></Button>
            </div>
          );
        } else if (
          statusEditRowRef.current === true &&
          record.editingRow === true
        ) {
          return (
            <div>
              <Button
                shape='circle'
                size='small'
                style={{
                  color: 'green',
                  marginRight: '10px',
                }}
                onClick={onBtnSaveEditVolume}
                icon={<CheckOutlined />}
              ></Button>

              <Button
                style={{
                  color: 'red',
                }}
                shape='circle'
                size='small'
                onClick={onBtnCancelEditVolume}
                icon={<CloseOutlined />}
              ></Button>
            </div>
          );
        }
      },
    },
  ];

  return (
    <Container>
      <Form form={form} component={false}>
        <StyledTable
          rowKey='id'
          tableId={'id'}
          scroll={{ x: 'max-content' }}
          bordered
          dataSource={detail}
          columns={columns}
          showSorterTooltip={false}
          showContextMenu={true}
          pagination={{
            pageSize: 50,
            showSizeChanger: false,
            showQuickJumper: true,
          }}
        />
      </Form>

      <Modal
        visible={visibleModalLoading}
        onOk={handleOkLoading}
        onCancel={handleCancelLoading}
        okButtonProps={false}
        maskClosable={false}
        closable={false}
        footer={[
          <Button
            key='submit'
            type='primary'
            loading={isLoadingSearch}
            onClick={handleOkLoading}
          >
            Ok
          </Button>,
        ]}
      >
        <p>Please wait in progress...</p>
      </Modal>
    </Container>
  );
};

const StyledTable = styled(Table)`
  &&& {
    /* Prevent scroll-x display when adding resize column*/
    .ant-table > .ant-table-container > .ant-table-content > table {
      min-width: 99% !important;
    }
    tr {
      th {
        padding: 4px;
        background-color: #f0f0f0;
        font-size: 12px;
      }
    }
    tr {
      td {
        padding: 4px;
        font-size: 12px;
      }
    }
    table {
      tr:hover {
        td {
          background-color: #ebf5fb !important;
        }
      }
    }
    table {
      tr:nth-child(2n) {
        td {
          background-color: #fafafa;
        }
      }
    }
  }
`;

const Container = styled.div`
  padding: 0 10px;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export default TableSubCluster;
