import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Row, Col, Button, Modal } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
// import { Sorter } from 'utils/sorter';
// import { RemoveDuplicates } from 'utils/removeDuplicates';
import {
  useFindInitDataMutation,
  usePostSelectDataMutation,
} from 'reducers/statuscheck/api';
import './index.css';
import Table from './Table';
import { push } from 'connected-react-router';

const ManageTourListIndexComponent = () => {
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  //for data api
  const [dataSourceTable, setDataSourceTable] = useState();
  const [statusCheck, setStatusCheck] = useState();
  const [planJobId, setPlanJobId] = useState();

  const [isModalVisibleConfirm, setIsModalVisibleConfirm] = useState(false);
  //modal Delete Data
  const handleOkConfirm = () => {
    apiPostDataStatusCheck(planJobId);
    setIsModalVisibleConfirm(false);
  };
  const handleCancelConfirm = () => {
    setIsModalVisibleConfirm(false);
  };
  const ModalConfirm = async () => {
    setIsModalVisibleConfirm(true);
  };

  const onGoToManageTourList = async () => {
    let param = '';

    if (selectedRowKeys.length !== 0) {
      param = `/${selectedRowKeys}`;
    }
    dispatch(push(`/app/planning/manage_tour_list${param}`));
  };
  // -------------API--------------------------------------------------
  const [
    apiInitDataStatusCheck,
    { data: dataInite, error: errorInite, isLoading: isLoadingInite },
  ] = useFindInitDataMutation();

  useEffect(async () => {
    if (dataInite) {
      setPlanJobId(dataInite?.plan_job_id);
      await setStatusCheck(dataInite?.status);

      setDataSourceTable(
        dataInite?.result_tour.length !== 0 ? dataInite?.result_tour : []
      );
    }
  }, [dataInite]);

  useEffect(() => {
    if (errorInite) {
      // setErrorData(errorInite);
      setStatusCheck('error');
      Modal.warning({
        title: 'ไม่สามารถใช้งานหน้า Status check ได้',
        content: (
          <div>
            <p>เนื่องจาก ยังไม่มีการจ่ายงานให้ BzP</p>
          </div>
        ),
        width: 500,
        onOk() {},
      });
    }
  }, [errorInite, setErrorData]);

  useEffect(() => {
    apiInitDataStatusCheck();
  }, [apiInitDataStatusCheck]);
  //----------------------------------------------------------------------
  const [
    apiPostDataStatusCheck,
    { data: dataPost, error: errorPost, isLoading: isLoadingPost },
  ] = usePostSelectDataMutation(selectedRows);

  useEffect(() => {
    if (dataPost) {
      if (dataPost?.updated_status_plan_job !== 0) {
        Modal.success({
          title: 'Final confirm success !!',
          width: 500,
          content: (
            <div>
              <p>Updated status plan job : {dataPost?.updated_status_plan_job}</p>
              <p>Created dispatch board  : {dataPost?.created_dispatch_board}</p>
            </div>
          ),
          onOk() {},
        });
      } else {
        Modal.error({
          title: 'Final confirm Error !!',
          width: 500,
          content: (
            <div>
              <p>Updated status plan job : {dataPost?.updated_status_plan_job}</p>
              <p>Created dispatch board : {dataPost?.created_dispatch_board}</p>
            </div>
          ),
          onOk() {},
        });
      }
      apiInitDataStatusCheck();
    }
  }, [dataPost]);

  useEffect(() => {
    if (errorPost) {
      setErrorData(errorPost);
    }
  }, [errorPost, setErrorData]);
  //----------------------------------------------------------------------

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Status Check'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Row
          align='end'
          justify='end'
          style={{ margin: '10px', marginRight: '25px' }}
        >
          <Col>
            <Button
              type='primary'
              onClick={onGoToManageTourList}
              disabled={selectedRowKeys.length === 0}
            >
              Manage tour list
            </Button>
          </Col>

          <Col style={{ marginLeft: '10px' }}>
            <Button
              type='primary'
              onClick={ModalConfirm}
              loading={isLoadingPost}
              disabled={
                statusCheck === 'read' ||
                statusCheck === 'finish' ||
                statusCheck === 'error' ||
                isLoadingInite === true
              }
            >
              Final confirm
            </Button>
          </Col>
        </Row>
        <Table
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          dataSourceTable={dataSourceTable}
          setDataSourceTable={setDataSourceTable}
          isLoadingInite={isLoadingInite}
          statusCheck={statusCheck}
        />
      </Content>

      <Modal
        visible={isModalVisibleConfirm}
        onOk={handleOkConfirm}
        onCancel={handleCancelConfirm}
      >
        <p>May I finish the whole process ?</p>
      </Modal>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default ManageTourListIndexComponent;
