import React, { useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Typography,
  Collapse,
  Divider,
  Modal,
  Upload,
  message,
  DatePicker,
} from 'antd';
import styled from 'styled-components';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setManageCallingNoStatusMode } from 'reducers/manageCalling';

import { useParams } from 'react-router-dom';

const { Panel } = Collapse;
const { Option } = Select;

const ToolbarVin = (props) => {
  const {
    disableFilterUrl,
    onUpload,
    onModalComfrimUpload,
    isLoadingUploadVin,
    dataUploadFile,
    fileList,
    setFileList,
    isLoadingMasterData,
    param_mode,
    param_from,
    showFilter,
    setShowFilter,
    onAddToCalling,
    onSearch,
    filterFrom,
    setFilterFrom,
    filterCluster,
    setFilterCluster,
    filterVehicle,
    setFilterVehicle,
    filterTo,
    setFilterTo,
    filterVehicleType,
    setFilterVehicleType,
    filterVinno,
    setFilterVinno,
    filterDestination,
    setFilterDestination,
    filterLotno,
    setFilterLotno,
    filterCallingno,
    setFilterCallingno,
    filterUploadDate,
    setfilterUploadDate,
    isLoadingSearch,
    // Option
    filterOptionFrom,
    filterOptionVehicleTypes,
    filterOptionStatus,
    filterOptionCluster,
    filterOptionTo,
    filterOptionDestination,
    toTalSelect,
    loadingonAddToCalling,
  } = props;
  const { mode } = useParams();
  const { confirm } = Modal;
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      mode === 'create_new_calling' &&
      filterVehicle &&
      filterVehicle.length > 0
    ) {
      onSearch(true);
    }
  }, [mode, filterVehicle]);

  function onChangeDate(date, dateString) {
    setfilterUploadDate(date);
  }

  const onChangeFile = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPopUpAddToCalling = async () => {
    if (param_mode === 'ADD_VIN' || mode === 'create_new_calling') {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: 'คุณต้องการเพิ่ม vin no ที่เลือกไปยัง calling ใช่หรือไม่',
        onOk() {
          let paramMode = 'CREATE_NEW_CALLING';
          if (param_mode === 'ADD_VIN') {
            paramMode = 'ADD_VIN';
          }
          dispatch(
            setManageCallingNoStatusMode({
              param_mode: paramMode,
            })
          );
          onAddToCalling();
        },
      });
    } else {
      onAddToCalling();
    }
  };

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[22]}
            style={{ marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={7}></Col>
            <Col span={2} align='end'>
              <Typography>Cluster</Typography>
            </Col>
            <Col span={4}>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                maxTagCount='responsive'
                placeholder={'cluster'}
                loading={isLoadingMasterData}
                value={filterCluster}
                onChange={(values) => {
                  setFilterCluster(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionCluster &&
                  filterOptionCluster.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Typography>Vehicle Status</Typography>
            </Col>
            <Col span={4}>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                maxTagCount='responsive'
                placeholder={'Vehicle Status'}
                loading={isLoadingMasterData}
                value={filterVehicle}
                onChange={(values) => {
                  setFilterVehicle(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionStatus &&
                  filterOptionStatus.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
              {/* <Select
                disabled={disableFilterUrl}
                mode="single"
                size="small"
                style={{
                  textAlign: "center",
                  width: "100%",
                }}
                allowClear={true}
                maxTagCount="responsive"
                placeholder={"Status"}
                loading={isLoadingMasterData}
                defaultValue={["All"]}
                options={filterOptionStatus}
                value={filterVehicle}
                onChange={(values) => {
                  setFilterVehicle(values);
                }}
              /> */}
            </Col>
          </Row>

          <Row
            gutter={[22]}
            style={{ marginTop: '10px', marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='end'>
              <Typography>From</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                maxTagCount='responsive'
                placeholder={'From'}
                loading={isLoadingMasterData}
                disabled={param_mode !== 'VIEW' || disableFilterUrl}
                value={filterFrom}
                onChange={(values) => {
                  setFilterFrom(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionFrom &&
                  filterOptionFrom.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Typography>To</Typography>
            </Col>
            <Col span={4}>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingMasterData}
                maxTagCount='responsive'
                placeholder={'to'}
                value={filterTo}
                onChange={(values) => {
                  setFilterTo(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionTo &&
                  filterOptionTo.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Typography>Vehicle Type</Typography>
            </Col>
            <Col span={4}>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                maxTagCount='responsive'
                // defaultValue={["All"]}
                placeholder={'Vehicle Type'}
                loading={isLoadingMasterData}
                value={filterVehicleType}
                onChange={(values) => {
                  setFilterVehicleType(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionVehicleTypes &&
                  filterOptionVehicleTypes.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>

          <Row
            gutter={[22]}
            style={{ marginTop: '10px', marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='end'>
              <Typography>Vin no.</Typography>
            </Col>
            <Col span={4}>
              <Input
                disabled={disableFilterUrl}
                size='small'
                style={{
                  width: '100%',
                  align: 'center',
                }}
                placeholder='Input vin no.'
                allowClear
                value={filterVinno}
                onChange={(e) => {
                  setFilterVinno(e.target.value.trim());
                }}
              />
            </Col>
            <Col span={3} align='end'>
              <Typography>Destination</Typography>
            </Col>
            <Col span={4}>
              <Select
                disabled={disableFilterUrl}
                mode='multiple'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingMasterData}
                maxTagCount='responsive'
                placeholder={'destination'}
                value={filterDestination}
                onChange={(values) => {
                  setFilterDestination(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionDestination &&
                  filterOptionDestination.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Typography>Lot no.(CBU)</Typography>
            </Col>
            <Col span={4}>
              <Input
                disabled={disableFilterUrl}
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                  align: 'center',
                }}
                placeholder='Lot no.(CBU)'
                allowClear
                value={filterLotno}
                onChange={(e) => {
                  setFilterLotno(e.target.value);
                }}
              />
            </Col>
          </Row>

          <Row
            gutter={[22]}
            style={{ marginTop: '10px', marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='end'>
              <Typography>Calling no.</Typography>
            </Col>
            <Col span={4}>
              <Input
                size='small'
                style={{
                  width: '100%',
                  align: 'center',
                }}
                disabled={param_mode !== 'VIEW' || disableFilterUrl}
                placeholder='input calling no.'
                allowClear
                value={filterCallingno}
                onChange={(e) => {
                  setFilterCallingno(e.target.value.trim());
                }}
              />
            </Col>
            <Col span={3} align='end'>
              <Typography>Upload Date</Typography>
            </Col>
            <Col span={4}>
              <DatePicker
                disabled={disableFilterUrl}
                size='small'
                format='DD-MM-YYYY'
                onChange={onChangeDate}
                value={filterUploadDate}
                style={{
                  width: '100%',
                  align: 'center',
                }}
              />
            </Col>
            <Col span={3}></Col>
            <Col span={4} align='end'>
              <Button
                style={{ width: '80%', textAlign: 'center' }}
                type='primary'
                onClick={() => {
                  onSearch(true);
                }}
                loading={isLoadingSearch}
                disabled={
                  dataUploadFile?.length !== 0 ||
                  param_mode === 'EDIT_VIN' ||
                  isLoadingMasterData === true ||
                  loadingonAddToCalling === true ||
                  disableFilterUrl
                }
              >
                Search
              </Button>
            </Col>
          </Row>

          <Divider style={{ margin: '0px' }}></Divider>

          <Row
            gutter={[22]}
            style={{ marginTop: '10px' }}
            align='top'
            justify='top'
          >
            <Col span={2}></Col>
            <Col span={6} align='end'>
              <Upload
                maxCount={1}
                fileList={fileList}
                onChange={onChangeFile}
                multiple={false}
                accept='.csv'
                beforeUpload={(file) => {
                  setFileList([file]);
                  const isCSV = file.type === 'text/csv';
                  if (!isCSV) {
                    message.error(`${file.name} is not a .csv file`);
                  } else {
                    onUpload(file);
                    return false;
                  }
                }}
              >
                <Button
                  style={{ borderRadius: '8px' }}
                  loading={isLoadingUploadVin}
                  icon={<UploadOutlined />}
                  disabled={
                    param_mode === 'CREATE_NEW_CALLING' ||
                    param_mode === 'EDIT_VIN' ||
                    param_mode === 'ADD_VIN' ||
                    mode === 'create_new_calling' ||
                    disableFilterUrl
                  }
                >
                  Select File
                </Button>
              </Upload>
            </Col>

            <Col span={2}>
              <Button
                style={{ textAlign: 'center' }}
                type='primary'
                loading={isLoadingUploadVin}
                disabled={dataUploadFile?.length === 0 || disableFilterUrl}
                onClick={onModalComfrimUpload}
              >
                Upload
              </Button>
            </Col>
            <Col span={3}></Col>
            <Col span={3} align='end'>
              <Typography>No. of select </Typography>
            </Col>
            <Col span={2} align='start'>
              <Input
                disabled={disableFilterUrl}
                size='small'
                placeholder='number'
                allowClear
                readOnly
                defaultValue={'0'}
                value={toTalSelect}
              />
            </Col>

            <Col span={4} align='end'>
              <Button
                style={{ width: '80%', textAlign: 'center' }}
                type='primary'
                onClick={onPopUpAddToCalling}
                loading={loadingonAddToCalling}
                disabled={
                  param_from === 'MENU' ||
                  (toTalSelect === 0 && param_mode === 'ADD_VIN') ||
                  (toTalSelect === 0 && mode === 'create_new_calling')
                }
              >
                {param_mode === 'EDIT_VIN'
                  ? `Back to calling`
                  : `Add to Calling`}
              </Button>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default ToolbarVin;
