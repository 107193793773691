import React from 'react';
import {
  SettingOutlined,
  TeamOutlined,
  UserOutlined,
  ContainerOutlined,
  TableOutlined,
  ProfileOutlined,
  ImportOutlined,
  ExportOutlined,
  ApartmentOutlined,
  ExceptionOutlined,
  ClusterOutlined,
  UserSwitchOutlined,
  HddOutlined,
  ProjectOutlined,
  GatewayOutlined,
  DiffOutlined,
  TabletOutlined,
  FormOutlined,
  BarChartOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { ReactComponent as RouteSvg } from 'assets/icons/route.svg';
import { ReactComponent as DigitalMapSvg } from 'assets/icons/digitalmap.svg';
import { ReactComponent as JobTrackingSvg } from 'assets/icons/job_tracking.svg';
import { ReactComponent as VinSvg } from 'assets/icons/vin.svg';
import { ReactComponent as LocationTypeSvg } from 'assets/icons/location_type.svg';
import { ReactComponent as ShuttleSvg } from 'assets/icons/shullte_in.svg';
import { ReactComponent as DnListSvg } from 'assets/icons/dn_list.svg';
const adminLayoutRoutes = [
  {
    itemName: 'Planning',
    itemIcon: <ProjectOutlined />,
    itemLink: '/app/planning',
    subItems: [
      {
        itemName: 'Assign Volume',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/assign_volume/',
        permission: { subject: 'assign_volume', actions: ['l'] },
      },

      {
        itemName: 'FixTour',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/fix_tour',
        permission: { subject: 'assign_fixtour', actions: ['l'] },
      },
      {
        itemName: 'Create Lot',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/create_lot',
        permission: { subject: 'create_lot', actions: ['l'] },
      },

      {
        itemName: 'Manage Trip Dashboard',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/manage_trip_board',
        permission: { subject: 'manage_trip_board', actions: ['l'] },
      },
      {
        itemName: 'Create Tour',
        itemIcon: <TabletOutlined />,
        itemLink: '/app/planning/create_tour/',
        permission: { subject: 'create_tour', actions: ['l'] },
      },
      {
        itemName: 'Manage Tour List',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/manage_tour_list',
        permission: { subject: 'manage_tour_list', actions: ['l'] },
      },
      {
        itemName: 'Manage Tour List Bzp',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/manage_tour_bzp_list',
        permission: { subject: 'manage_tour_list_bzp', actions: ['l'] },
      },
      {
        itemName: 'Summary Allocation',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/summary_allocation',
        permission: { subject: 'summary_allocation', actions: ['l'] },
      },
      {
        itemName: 'Summary Yard',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/summary_yard',
        permission: { subject: 'summary_yard', actions: ['l'] },
      },
      {
        itemName: 'Status Check',
        itemIcon: <GatewayOutlined />,
        itemLink: '/app/planning/status_check',
        permission: { subject: 'status_check', actions: ['l'] },
      },
    ],
  },
  {
    itemName: 'Operation',
    itemIcon: <ContainerOutlined />,
    itemLink: '/app/delivery_mng',
    subItems: [
      {
        itemName: 'Dispatch Board',
        itemIcon: <TableOutlined />,
        itemLink: '/app/delivery_mng/lists',
        permission: { subject: 'dispatch_board', actions: ['l'] },
      },
      {
        itemName: 'Calling List',
        itemIcon: <ProfileOutlined />,
        itemLink: '/app/delivery_mng/calling_lists',
        permission: { subject: 'callingsheet', actions: ['l'] },
      },
      {
        itemName: 'Manage Calling',
        itemIcon: <HddOutlined />,
        itemLink: '/app/delivery_mng/manage_calling/no_calling',
        permission: { subject: 'manage_callingsheets', actions: ['l'] },
      },
      {
        itemName: 'VIN List',
        itemIcon: <Icon component={VinSvg} style={{ fontSize: '18px' }} />,
        itemLink: '/app/delivery_mng/vins/menu/view/calling_no/filterfrom',
        permission: { subject: 'vin', actions: ['l'] },
      },
      // {
      //   itemName: 'Delivery Note',
      //   itemIcon: <FileDoneOutlined />,
      //   itemLink: '/app/delivery_mng/dntas',
      //   permission: { subject: 'delivery_note', actions: ['l'] },
      // },
      {
        itemName: 'Receive Job & Cancel Receive Job',
        itemIcon: <ImportOutlined />,
        itemLink: '/app/delivery_mng/receive_jobs',
        permission: { subject: 'receive_job', actions: ['l'] },
      },
      {
        itemName: 'Yard Out',
        itemIcon: <ExportOutlined />,
        itemLink: '/app/delivery_mng/yardouts',
        permission: { subject: 'yard_out', actions: ['l'] },
      },
      {
        itemName: 'Shuttle In',
        itemIcon: <Icon component={ShuttleSvg} style={{ fontSize: '18px' }} />,
        itemLink: '/app/delivery_mng/shuttle_in',
        permission: { subject: 'shuttle_in', actions: ['l'] },
      },
      {
        itemName: 'DN List',
        itemIcon: <Icon component={DnListSvg} style={{ fontSize: '18px' }} />,
        itemLink: '/app/delivery_mng/dn_list',
        permission: { subject: 'dn_list', actions: ['l'] },
      },
      {
        itemName: 'Create Trip',
        itemIcon: (
          <Icon component={DiffOutlined} style={{ fontSize: '18px' }} />
        ),
        itemLink: '/app/delivery_mng/create_trip',
        permission: { subject: 'create_trip', actions: ['l'] },
      },
      {
        itemName: 'Status Summary',
        itemIcon: (
          <Icon component={BarChartOutlined} style={{ fontSize: '18px' }} />
        ),
        itemLink: '/app/delivery_mng/status_summary',
        permission: { subject: 'status_summary', actions: ['l'] },
      },
      {
        itemName: 'Status Change',
        itemIcon: <FormOutlined />,
        itemLink: '/app/delivery_mng/status_change',
        permission: { subject: 'status_change', actions: ['l'] },
      },
      {
        itemName: 'Change Reason & Lane',
        itemIcon: <ExceptionOutlined />,
        itemLink: '/app/delivery_mng/change_reason_lane',
        permission: { subject: 'change_reason_and_lane', actions: ['l'] },
      },
    ],
  },
  {
    itemName: 'User Management',
    itemIcon: <TeamOutlined />,
    itemLink: '/app/user_mng',
    subItems: [
      {
        itemName: 'User',
        itemIcon: <UserOutlined />,
        itemLink: '/app/user_mng/users',
        permission: { subject: 'user', actions: ['l'] },
      },
      {
        itemName: 'Group',
        itemIcon: <ApartmentOutlined />,
        itemLink: '/app/user_mng/groups',
        permission: { subject: 'group', actions: ['l'] },
      },
      // {
      //   itemName: 'Company',
      //   itemIcon: <Icon component={CompanySvg} style={{ fontSize: '18px' }} />,
      //   itemLink: '/app/user_mng/companies',
      //   permission: { subject: 'company', actions: ['l'] },
      // },
      {
        itemName: 'Diagram Owner',
        itemIcon: <ClusterOutlined />,
        itemLink: '/app/user_mng/configuration_diagram_owner',
        permission: { subject: 'diagram_owner', actions: ['l'] },
      },
      {
        itemName: 'Function Permission',
        itemIcon: <UserSwitchOutlined />,
        itemLink: '/app/user_mng/configuration_permission',
        permission: { subject: 'function_permission', actions: ['l'] },
      },
    ],
  },
  {
    itemName: 'Driver Confirm',
    itemIcon: <UserOutlined />,
    itemLink: '/app/driver_confirm',
    permission: { subject: 'driver_confirm_message', actions: ['l'] },
  },
  {
    itemName: 'Digital Map',
    itemIcon: <Icon component={DigitalMapSvg} style={{ fontSize: '18px' }} />,
    itemLink: '/app/digitalmap',
    subItems: [
      {
        itemName: 'Vehicle Status Monitoring',
        itemIcon: (
          <Icon component={JobTrackingSvg} style={{ fontSize: '18px' }} />
        ),
        itemLink: '/app/digitalmap/monitoring_jobs',
        permission: { subject: 'dm_operation_dashboard', actions: ['l'] },
      },
      {
        itemName: 'Trailer Status Monitoring',
        itemIcon: (
          <Icon component={JobTrackingSvg} style={{ fontSize: '18px' }} />
        ),
        itemLink: '/app/digitalmap/ttt_monitoring_jobs',
        permission: { subject: 'dm_ttt_operation_dashboard', actions: ['l'] },
      },
    ],
  },
  {
    itemName: 'Download Report',
    itemIcon: <CloudDownloadOutlined />,
    itemLink: '/app/download_report',
    permission: { subject: 'download_report', actions: ['l'] },
  },
];

export default adminLayoutRoutes;
