import React, { useEffect } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import {
  useUpdateLocationTypeMutation,
  useCreateLocationTypeMutation,
} from 'reducers/digitalmap/locationType/api';

import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { goBack } from 'connected-react-router';

const LocationTypeFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();

  const dispatch = useDispatch();
  const [
    createLocationType,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateLocationTypeMutation();

  const [
    updateLocationType,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateLocationTypeMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);

    let postData = {
      id: formData && formData.id,
      ...validData,
    };

    if (isCreate) {
      createLocationType(postData);
    } else {
      updateLocationType(postData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Name'
        name='name'
        initialValue={formData && formData.name}
        rules={[
          {
            required: true,
            message: 'Please input location type name',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'location type name'} />
      </Form.Item>
    </Form>
  );
};

export default LocationTypeFormComponent;
