import { useSelector } from 'react-redux';
import { List, Card, Row, Col, Space } from 'antd';
import { getCurrentUser } from 'reducers/user';
import styled from 'styled-components';
import moment from 'moment';
const Barcode = require('react-barcode');

const DEFAULT_TEXT_LENGTH = 15;
const Calling = ({ callingDataList }) => {
  let calling_no;
  let vinList = [];
  let dataListSection1 = [];
  let dataListSection2 = [];
  let locationFrom = '';
  let locationTo = '';

  let dateToday = moment().format('DD-MM-YYYY HH:mm:ss');
  let user = useSelector(getCurrentUser);

  if (callingDataList !== undefined) {
    calling_no = callingDataList[0]?.callingsheet_no;
    vinList = callingDataList[0]?.vins;
    locationFrom = callingDataList[0]?.calling_location_from?.short_name;
    locationTo = callingDataList[0]?.farthest_cluster?.name;

    for (const i in vinList) {
      if (Object.hasOwnProperty.call(vinList, i)) {
        if (i < 8) {
          dataListSection1.push(vinList[i]);
        } else {
          dataListSection2.push(vinList[i]);
        }
      }
    }
  }

  const generateVinComponent = (dataList) => {
    return (
      <List
        itemLayout='vertical'
        dataSource={dataList}
        renderItem={(item, idx) => (
          <List.Item
            id={`${item?.vin_no}-${idx}`}
            style={{
              padding: '0px',
              borderColor: 'transparent',
              textAlign: 'left',
              borderBottom: '0px',
            }}
          >
            <div style={{ padding: '0px 6px', border: '1px solid grey' }}>
              <Row gutter={[24]} align='center' justify='center'>
                <Col span={4} style={{ paddingTop: '0px' }}>
                  <VinTitle>Vin no.</VinTitle>
                </Col>
                <Col
                  span={18}
                  align='center'
                  style={{ marginRight: 'auto', marginTop: '6px' }}
                >
                  <Barcode
                    value={item.vin_no ? item.vin_no : ' '}
                    flat
                    fontSize={18}
                    height={28}
                    width={2}
                  />
                </Col>
              </Row>
              <Row
                gutter={[24]}
                align='center'
                justify='center'
                style={{ marginTop: '-6px' }}
              >
                <Col span={18}>
                  <VinContent>
                    Model code : {item.model_code ? item?.model_code : '-'}
                  </VinContent>
                  <VinContent>
                    Fin.Dest :{' '}
                    {item.location_to.short_name
                      ? item.location_to?.short_name
                      : '-'}
                  </VinContent>
                  <VinContent>
                    Series : {item.series ? item?.series : '-'}{' '}
                  </VinContent>
                  <VinContent>
                    Colour : {item.color ? item?.color : '-'}
                  </VinContent>
                </Col>
                <Col span={6} align='center'>
                  <VinContent>แถวจอด</VinContent>
                  <VinContent>{item.parking_lane?.lane_no}</VinContent>
                </Col>
              </Row>
            </div>
          </List.Item>
        )}
      />
    );
  };

  return (
    <Card style={{ height: '100%', borderColor: 'transparent' }}>
      <Row align='start' justify='center'>
        <Col align='center'>
          <PageTitle style={{}}>แบบฟอร์มการเรียกรถเทรลเลอร์</PageTitle>
        </Col>
      </Row>
      <Row gutter={[24]}>
        <Col span={12} style={{ marginTop: '0px' }}>
          {generateVinComponent(dataListSection1)}
        </Col>
        <Col span={12} align='center' style={{ padding: '0px' }}>
          <Row gutter={[24]} align='center' justify='center'>
            <Col span={24}>
              <TextH2>Calling No. {calling_no}</TextH2>
              <Barcode
                value={calling_no ? calling_no : ' '}
                flat
                fontSize={18}
                height={34}
                width={2}
              />
              <div style={{ textAlign: 'left', marginLeft: '20px' }}>
                <TextH2>Transport route</TextH2>
                <LocationText
                  textSize={
                    locationFrom && locationTo
                      ? locationFrom.length + locationTo.length
                      : DEFAULT_TEXT_LENGTH
                  }
                >
                  {locationFrom}
                  <span style={{ color: 'blue', margin: '0 5px' }}> To </span>
                  {locationTo}
                </LocationText>
                <TextH2>วัน - เวลา : {dateToday}</TextH2>
                <TextH2>ผู้ออกเอกสาร : {user?.fullname_en}</TextH2>
              </div>
            </Col>
          </Row>
          <Row gutter={[24]} style={{ paddingTop: '20px' }}>
            <Col align='start' span={24}>
              <DriverTitleText>ส่วนของพนักงานขับรถ</DriverTitleText>
              <DriverTextContainer>
                <DriverContentLabel>บริษัท :</DriverContentLabel>
                <DriverContentFill>
                  _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                </DriverContentFill>
              </DriverTextContainer>
              <DriverTextContainer>
                <DriverContentLabel>หมายเลขเทรลเลอร์ :</DriverContentLabel>
                <DriverContentFill>
                  _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                </DriverContentFill>
              </DriverTextContainer>
              <DriverTextContainer>
                <DriverContentLabel>พนักงานขับรถคนที่1 :</DriverContentLabel>
                <DriverContentFill>
                  _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                </DriverContentFill>
              </DriverTextContainer>
              <DriverTextContainer>
                <DriverContentLabel>พนักงานขับรถคนที่2 :</DriverContentLabel>
                <DriverContentFill>
                  _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                </DriverContentFill>
              </DriverTextContainer>
              <DriverTextContainer>
                <DriverContentLabel>วันที่รับงาน :</DriverContentLabel>
                <DriverContentFill>
                  _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                </DriverContentFill>
              </DriverTextContainer>
              <DriverTextContainer>
                <DriverContentLabel>เวลาเข้า :</DriverContentLabel>
                <DriverContentFill>
                  _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                </DriverContentFill>
              </DriverTextContainer>
              <DriverTextContainer>
                <DriverContentLabel>เวลาออก :</DriverContentLabel>
                <DriverContentFill>
                  _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _
                </DriverContentFill>
              </DriverTextContainer>
            </Col>
          </Row>

          <div
            hidden={dataListSection2.length === 0}
            style={{ marginTop: '0px' }}
          >
            {generateVinComponent(dataListSection2)}
          </div>
        </Col>
      </Row>
    </Card>
  );
};

const LocationText = styled.div`
  font-size: ${(props) =>
    props.textSize > DEFAULT_TEXT_LENGTH ? '26px' : '36px'};
  font-weight: 500;
  text-align: center;
  margin-bottom: 20px;
`;

const VinTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
`;

const VinContent = styled.div`
  font-size: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const PageTitle = styled.div`
  font-size: 28px;
  font-weight: 500;
`;

const DriverTitleText = styled.div`
  text-align: center;
  font-size: 22px;
  text-decoration: underline;
  font-weight: 500;
`;

const DriverTextContainer = styled.div`
  display: flex;
  font-size: 18px;
  margin: 28px 10px;
`;
const DriverContentLabel = styled.div`
  width: 160px;
  text-align: right;
`;
const DriverContentFill = styled.div`
  flex: 1;
  margin-left: 4px;
`;
const TextH2 = styled.div`
  margin-top: 0;
  margin-bottom: 0.5em;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  font-size: 20px;
`;
export default Calling;
