import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

const OrderMarker = ({ title, location, locationTo, infoLocation }) => {
  let arrLocation = locationTo.delivery_location.find(
    (l) => l.truck_schedule_id === infoLocation
  );
  const [onHover, setOnHover] = useState(false);
  const info = arrLocation && arrLocation.location;

  return (
    <Marker
      position={location}
      onMouseOver={() => {
        setOnHover(true);
      }}
      onMouseOut={() => {
        setOnHover(false);
      }}
    >
      {onHover && info && (
        <InfoWindow>
          <>
            {info ? `Shortname : ${info?.short_name}` : ''}
            <br />
            {info?.name ? `Name : ${info?.name}` : ''}
          </>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default OrderMarker;
