/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import AntTable from 'common_components/AntTable';
import './index.css';
import styled from 'styled-components';
import moment from 'moment';
// import { useFindStatusTotalRecordBzpQuery } from 'reducers/manageTourListBzp/api';
const StatusComponent = (props) => {
  const { isLoadingStatusTotal, valueStatusListRef, valueStatusList } = props;

  const onDisplayTextBlank = (value) => {
    if (value >= 0) {
      return value;
    } else {
      return null;
    }
  };
  const onDisplayTextAccept = (value) => {
    if (value >= 0) {
      return value;
    } else {
      return null;
    }
  };
  const onDisplayTextRequest = (value) => {
    if (value >= 0) {
      return value;
    } else {
      return null;
    }
  };
  const onDisplayTextReject = (value) => {
    if (value >= 0) {
      return value;
    } else {
      return null;
    }
  };

  const [columnsStatus, setColumnsStatus] = useState(() => {
    let cols = [
      {
        title: '',
        width: 50,
        className: 'color-head-1s',
        align: 'center',
        children: [
          {
            title: '',
            className: ['color-head-3', 'hide-border-bottom'],
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return <div style={{ paddingBottom: 18 }}>Tour</div>;
                },
                dataIndex: 'blank',
                className: ['color-head-3', 'hide-border-top'],
                align: 'center',
                width: 50,
                children: [
                  {
                    title: '',
                    dataIndex: 'blank',
                    align: 'center',
                    width: 50,
                    className: ['color-head-3s', 'hide-border-bottom'],
                    render(text, record) {
                      return {
                        props: {
                          style: {
                            background: '#9D8189',
                            borderTop: 'transparent',
                          },
                        },
                        children: (
                          <div
                            style={{
                              paddingBottom: 18,
                              color: '#fff',
                            }}
                          >
                            Status
                          </div>
                        ),
                      };
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: 'Total',
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.total
                    ? `${valueStatusListRef.current?.total?.tour_complete} / ${valueStatusListRef.current?.total?.tour_total}`
                    : null;
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.total?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.total?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.total?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.total?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: () => {
          return (
            <div>
              {valueStatusListRef.current?.['day-1']?.subtitle
                ? valueStatusListRef.current?.['day-1']?.subtitle
                : ''}
              <br />
              {valueStatusListRef.current?.['day-1']?.title
                ? moment(valueStatusListRef.current?.['day-1']?.title).format(
                    'DD/MM/YY'
                  )
                : ''}
            </div>
          );
        },
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.['day-1']
                    ? `${valueStatusListRef.current?.['day-1']?.tour_complete} / ${valueStatusListRef.current?.['day-1']?.tour_total}`
                    : null;
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.['day-1']?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.['day-1']?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.['day-1']?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.['day-1']?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: () => {
          return (
            <div>
              {valueStatusListRef.current?.['day-2']?.subtitle
                ? valueStatusListRef.current?.['day-2']?.subtitle
                : ''}
              <br />
              {valueStatusListRef.current?.['day-2']?.title
                ? moment(valueStatusListRef.current?.['day-2']?.title).format(
                    'DD/MM/YY'
                  )
                : ''}
            </div>
          );
        },
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.['day-2']
                    ? `${valueStatusListRef.current?.['day-2']?.tour_complete} / ${valueStatusListRef.current?.['day-2']?.tour_total}`
                    : null;
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.['day-2']?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.['day-2']?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.['day-2']?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.['day-2']?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: () => {
          return (
            <div>
              {valueStatusListRef.current?.['day-3']?.subtitle
                ? valueStatusListRef.current?.['day-3']?.subtitle
                : ''}
              <br />
              {valueStatusListRef.current?.['day-3']?.title
                ? moment(valueStatusListRef.current?.['day-3']?.title).format(
                    'DD/MM/YY'
                  )
                : ''}
            </div>
          );
        },
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.['day-3']
                    ? `${valueStatusListRef.current?.['day-3']?.tour_complete} / ${valueStatusListRef.current?.['day-3']?.tour_total}`
                    : null;
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.['day-3']?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.['day-3']?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.['day-3']?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.['day-3']?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: () => {
          return (
            <div>
              {valueStatusListRef.current?.['day-4']?.subtitle
                ? valueStatusListRef.current?.['day-4']?.subtitle
                : ''}
              <br />
              {valueStatusListRef.current?.['day-4']?.title
                ? moment(valueStatusListRef.current?.['day-4']?.title).format(
                    'DD/MM/YY'
                  )
                : ''}
            </div>
          );
        },
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.['day-4']
                    ? `${valueStatusListRef.current?.['day-4']?.tour_complete} / ${valueStatusListRef.current?.['day-4']?.tour_total}`
                    : null;
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.['day-4']?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.['day-4']?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.['day-4']?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.['day-4']?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: () => {
          return (
            <div>
              {valueStatusListRef.current?.['day-5']?.subtitle
                ? valueStatusListRef.current?.['day-5']?.subtitle
                : ''}
              <br />
              {valueStatusListRef.current?.['day-5']?.title
                ? moment(valueStatusListRef.current?.['day-5']?.title).format(
                    'DD/MM/YY'
                  )
                : ''}
            </div>
          );
        },
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.['day-5']
                    ? `${valueStatusListRef.current?.['day-5']?.tour_complete} / ${valueStatusListRef.current?.['day-5']?.tour_total}`
                    : null;
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.['day-5']?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.['day-5']?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.['day-5']?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.['day-5']?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: () => {
          return (
            <div>
              {valueStatusListRef.current?.['day-6']?.subtitle
                ? valueStatusListRef.current?.['day-6']?.subtitle
                : ''}
              <br />
              {valueStatusListRef.current?.['day-6']?.title
                ? moment(valueStatusListRef.current?.['day-6']?.title).format(
                    'DD/MM/YY'
                  )
                : ''}
            </div>
          );
        },
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.['day-6']
                    ? `${valueStatusListRef.current?.['day-6']?.tour_complete} / ${valueStatusListRef.current?.['day-6']?.tour_total}`
                    : null;
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.['day-6']?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.['day-6']?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.['day-6']?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.['day-6']?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        title: () => {
          return (
            <div>
              {valueStatusListRef.current?.['day-7']?.subtitle
                ? valueStatusListRef.current?.['day-7']?.subtitle
                : ''}
              <br />
              {valueStatusListRef.current?.['day-7']?.title
                ? moment(valueStatusListRef.current?.['day-7']?.title).format(
                    'DD/MM/YY'
                  )
                : ''}
            </div>
          );
        },
        width: 50,
        className: 'color-head-6',
        align: 'center',
        children: [
          {
            title: 'Complete / Total',
            dataIndex: 'blank',
            className: 'color-head-3',
            align: 'center',
            width: '10%',
            children: [
              {
                title: () => {
                  return valueStatusListRef.current?.['day-7']
                    ? `${valueStatusListRef.current?.['day-7']?.tour_complete} / ${valueStatusListRef.current?.['day-7']?.tour_total}`
                    : '';
                },
                dataIndex: 'blank',
                className: 'color-head-8',
                align: 'center',
                width: 50,
                children: [
                  {
                    title: 'Blank',
                    dataIndex: '-',
                    className: 'color-head-8',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextBlank(
                        valueStatusListRef.current?.['day-7']?.['-']
                      );
                    },
                  },
                  {
                    title: 'Accept',
                    dataIndex: 'accept',
                    className: 'color-head-9',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextAccept(
                        valueStatusListRef.current?.['day-7']?.accept
                      );
                    },
                  },
                  {
                    title: 'Request',
                    dataIndex: 'request',
                    className: 'color-head-10',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextRequest(
                        valueStatusListRef.current?.['day-7']?.request
                      );
                    },
                  },
                  {
                    title: 'Reject',
                    dataIndex: 'reject',
                    className: 'color-head-11',
                    align: 'center',
                    width: 50,
                    render: () => {
                      return onDisplayTextReject(
                        valueStatusListRef.current?.['day-7']?.reject
                      );
                    },
                  },
                ],
              },
            ],
          },
        ],
      },
    ];

    return cols;
  });

  return (
    <div>
      <Container style={{ padding: 10 }}>
        <StyledAntTable
          rowKey='id'
          tableId={'id'}
          bordered
          size='small'
          scroll={{ x: 'max-content' }}
          showSorterTooltip={false}
          columns={columnsStatus}
          dataSource={valueStatusList}
          loading={isLoadingStatusTotal}
          pagination={false}
        />
      </Container>
    </div>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;

export default StatusComponent;
