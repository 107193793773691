const config = {
  development: {
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    BACKEND_HOST: 'http://localhost:8000/api/v1',
    BACKEND_MFA_HOST: 'http://localhost:8000/api/v2',
    // BACKEND_HOST: 'http://54.169.243.49:8000/api/v1',
    // BACKEND_HOST: 'http://52.221.228.227:8000/api/v1',
    //BASE_URL_MSW: 'https://gamma.ttmi-52.10z.dev/api/v1',
    // BACKEND_HOST: 'https://gamma.ttmi-52.10z.dev/api/v1',
    // BACKEND_HOST: 'https://admin-dev.mobility-daas.com/api/v1',
    MAP_KEY: 'AIzaSyAR8tDtV_F2TuYSqZHAkdAzG7g8Hif8Cz8',
  },
  production: {
    IDLE_TIMEOUT: 2 * 60 * 60 * 1000, // 2Hr
    BACKEND_HOST: process.env.REACT_APP_BACKEND_HOST,
    BACKEND_MFA_HOST: process.env.REACT_APP_BACKEND_MFA_HOST,
    MAP_KEY: process.env.REACT_APP_MAP_KEY,
  },
};

export default config;
