import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

// It's required to create separete Marker from BaseMap component
// Due to window.google object will be created by BaseMap first
const StartMarker = ({ location }) => {
  const [onHover, setOnHover] = useState(false);
  const info = location;
  return (
    <Marker
      position={{
        lat: location && location.latitude,
        lng: location && location.longitude,
      }}
      icon={{
        url: 'http://www.google.com/mapfiles/dd-start.png',
        labelOrigin: window.google && new window.google.maps.Point(19, 14),
        scaledSize: window.google && new window.google.maps.Size(24, 38),
        size: window.google && new window.google.maps.Size(24, 38),
      }}
      onMouseOver={() => {
        setOnHover(true);
      }}
      onMouseOut={() => {
        setOnHover(false);
      }}
    >
      {onHover && location && (
        <InfoWindow>
          <>
            {info?.short_name ? 'Shortname : ' + info?.short_name : ''}
            <br />
            {info?.name ? 'Name : ' + info?.name : ''}
          </>
        </InfoWindow>
      )}
    </Marker>
  );
};
export default StartMarker;
