import React, { useEffect } from 'react';
import { setSidebarVisibility } from 'reducers/ui';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { useFindRouteGroupByIdQuery } from 'reducers/digitalmap/routeMaster/api';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Spin } from 'antd';
import BaseMap from '../RouteMasterMap';
import Form from './RouteGroupForm';
import { useErrorHandler } from 'common_components/ErrorContext';

const RouteGroupDetails = (props) => {
  const { id } = useParams();
  const { setErrorData } = useErrorHandler();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const { data, error, isFetching } = useFindRouteGroupByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  return (
    <SplitPane split='vertical'>
      <Pane initialSize='40%'>
        <div style={{ height: 'calc(100vh - 48px)', overflowY: 'auto' }}>
          {isFetching === true ? (
            <RootCircular>
              <CenterSpin indicator={antIcon} size='large' />
            </RootCircular>
          ) : (
            <Form formData={data} isCreate={!id} />
          )}
        </div>
      </Pane>
      <Pane>
        <BaseMap />
      </Pane>
    </SplitPane>
  );
};

const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default RouteGroupDetails;
