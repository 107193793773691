import React, { useEffect, useState } from 'react';
import { Line } from '@ant-design/plots';
import { LoadingOutlined } from '@ant-design/icons';

import styled from 'styled-components';
import { Collapse, Spin } from 'antd';
import './index.css';
const DataChartSummaryYardComponent = (props) => {
  const {
    dataChart,
    isLoadingChartRegionFilter,
    isLoadingSearchChartRegionNoFilter,
  } = props;
  const [data, setData] = useState([
    {
      label: 'Trailer lane',
      time: '',
      value: null,
    },
    {
      label: 'Total usage',
      time: '',
      value: null,
    },
    {
      label: 'Region usage',
      time: '',
      value: null,
    },
  ]);

  const [meta, setMeta] = useState({
    time: {
      type: 'cat',
    },
    max: data?.trailer_lane?.max,
    min: data?.trailer_lane?.min,
  });

  useEffect(() => {
    if (dataChart?.length !== 0) {
      setData(dataChart);
    }
  }, [dataChart]);

  const config = {
    data,
    height: 200,
    meta,
    xField: 'time',
    yField: 'value',

    seriesField: 'label',

    color: ({ label }) => {
      if (label === 'Trailer lane') {
        return '#FF0000';
      } else if (label === 'Region usage') {
        return '#4169EE';
      } else {
        return '#000000';
      }
    },
    lineStyle: ({ label }) => {
      if (label === 'Region usage') {
        return {
          lineDash: [4, 4],
          opacity: 0.5,
        };
      }

      if (label === 'Total usage') {
        return { opacity: 1 };
      }

      return {
        opacity: 0.5,
      };
    },
    tooltip: {
      showMarkers: true,
    },
  };

  return (
    <Container>
      <StyledCollapse style={{ padding: '20px' }}>
        {isLoadingChartRegionFilter === true ||
        isLoadingSearchChartRegionNoFilter === true ? (
          <RootCircular>
            <CenterSpin indicator={antIcon} size='large' />
          </RootCircular>
        ) : (
          <Line {...config} />
        )}
      </StyledCollapse>
    </Container>
  );
};
const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  padding: 10px;
  padding-bottom: 10px;
  font-size: 13px;
`;
const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

export default DataChartSummaryYardComponent;
