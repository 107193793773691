import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setUser, clearState } from '../reducers/user';
import LoadingConnect from '../layout/Loading';
import { getToken, getSession } from 'utils/token';
import { useHistory, useLocation } from 'react-router-dom';
import { showError } from '../utils/notistack';
import permission from 'permissions';
import wsConfigClient from 'libs/websocketConfig';
import { setPrintConfig } from 'reducers/print';
import Configs from 'config/config';

const { getDefaultLandingPage } = permission;
const env = process.env.NODE_ENV;

const withAuth = (WrappedComponent) => {
  return (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const token = getToken();
    const session = getSession();
    const location = useLocation();

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
      const checkAuth = async () => {
        if (!token || !session) {
          dispatch(clearState());
          history.push('/login');
          return;
        }

        try {
          const response = await fetch(
            `${Configs[env].BACKEND_MFA_HOST}/isauth/`,
            {
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
                'X-Session-Token': `Bearer ${session}`,
              },
            },
          );

          if (!response.ok) {
            const errorData = await response.json();
            const errorMessage =
              (errorData.errorList && errorData.errorList[0].errorMessage) ||
              'Authentication failed';
            throw new Error(errorMessage);
          }

          const data = await response.json();

          // Check if MFA is required but not completed
          if (data.is_mfa === false || data.is_verify === false) {
            throw new Error('MFA required');
          }

          setUserData(data);
          dispatch(setUser(data));

          // Listen ws and update print redux
          wsConfigClient.config({
            onUpdate: (data) => {
              dispatch(setPrintConfig(data));
            },
          });
        } catch (err) {
          setError(err.message);
          dispatch(clearState());
          await new Promise((resolve) => {
            showError(err.message);
            resolve();
          });
          history.push('/login');
        } finally {
          setIsLoading(false);
        }
      };

      checkAuth();
    }, [dispatch, history, token, session]);

    if (isLoading) {
      return <LoadingConnect />;
    }

    if (error) {
      setTimeout(() => {
        showError(error);
        if (error === 'MFA required') {
          // Redirect to MFA page or show MFA modal
          history.push('/login');
        }
      }, 200);
      return <LoadingConnect />;
    }

    if (userData) {
      // If path is '/app', redirect to default landing page based on user privileges
      let path = getDefaultLandingPage(location.pathname, userData);

      // Prevent infinite loop re-render
      if (path === 'payment') {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/app/payment`;
        }, 200);
      } else if (location.pathname !== path && path) {
        setTimeout(() => {
          history.push(path);
        }, 200);
      } else {
        return <WrappedComponent {...props} />;
      }
    }

    return <LoadingConnect />;
  };
};

export default withAuth;
