/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Col,
  Row,
  Card,
  message,
  Typography,
  Space,
  Select,
  DatePicker,
} from 'antd';
import {
  useFindCreateTripAllBzpsQuery,
  useFindAllLocationFromToQuery,
  useFindAllDiagramOwnerQuery,
  useDeleteSaveDraftCreateTripMutation,
  createTripApi,
} from 'reducers/createTrip/api';
import { push } from 'connected-react-router';
import styled from 'styled-components';
import { useErrorHandler } from 'common_components/ErrorContext';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import {
  timeFormatter,
  dateTimeTimestamptzFormatter,
} from 'common_components/AntTable/tableUtils';
import Table from './Table';
import AntModal from 'common_components/AntModal';
import { useParams } from 'react-router-dom';
import { Sorter } from 'utils/sorter';
const { Text } = Typography;
const { Option } = Select;

const CreateTripFormComponent = (props) => {
  const { formData } = props;
  const { id } = useParams();
  const trailerInputRef = useRef(null);
  const [selectedRowsData, setSelectedRowsData] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [dataDraft, setDataDraft] = useState([]);
  const [disabledDraftTable, setDisabledDraftTable] = useState(false);

  const isCheckButtonRef = useRef(false);
  const findFromRef = useRef();
  const maximumLoadingRef = useRef();
  const dataTableRef = useRef();
  const receiveJobTimeFieldRef = useRef();
  const dataLocationFromToRef = useRef();
  const locationClusterOptionsRef = useRef();
  const locationFromOptionsRef = useRef();
  const locationDestinationOptionsRef = useRef();
  const tripTypeDefaultRef = useRef();
  const yardOutTimeRef = useRef();
  const planTypeRef = useRef();
  const disableFormRowRef = useRef(true);
  const disableSaveDraftRef = useRef(true);
  const disableBtnSaveRef = useRef(true);
  const showDeleteRef = useRef();
  const requiredFormRef = useRef(false);
  const findDestinationRef = useRef(false);
  const tripTypeRef = useRef();
  const findClusterRef = useRef(false);
  const indexDataTableRef = useRef(false);
  const addTripBefore = useRef();
  const addTripAfter = useRef(false);
  const warningSaveDraft = useRef(false);
  const diagramOwnerListRef = useRef(false);
  const dataDiagramOwnerCheckRef = useRef(false);
  const editDisabled = useRef(false);
  const combineRef = useRef(false);
  const combineData = useRef(null);
  const continueRef = useRef();

  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [editTableDisabled, setEditTableDisabled] = useState(true);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [bzpOptions, setBzpOptions] = useState([]);
  const [filterTrailerType, setFilterTrailerType] = useState([]);
  const [trailerTypeOptions, setTrailerTypeOptions] = useState([]);
  const {
    data: dataBzps,
    error: errorBzps,
    refetch: refetchBzps,
  } = useFindCreateTripAllBzpsQuery();
  const {
    data: dataLocationFromTo,
    error: errorLocationFromTo,
    isLoading: isLoadingLocationFromTo,
    refetch: refetchLocationFromTo,
  } = useFindAllLocationFromToQuery();
  const { data: dataDiagramOwner } = useFindAllDiagramOwnerQuery();

  useEffect(() => {
    if (dataDiagramOwner) {
      diagramOwnerListRef.current = dataDiagramOwner;
    }
  }, [dataDiagramOwner]);
  useEffect(() => {
    if (dataBzps) {
      setBzpOptions(
        dataBzps.map((b) => ({
          label: b.job_name,
          value: b.id,
        }))
      );
    }
  }, [dataBzps]);

  useEffect(() => {
    if (errorBzps) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load TrailerLane',
        okText: 'Retry',
        onOk: refetchBzps,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzps, refetchBzps]);

  useEffect(() => {
    if (errorLocationFromTo) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load TrailerLane',
        okText: 'Retry',
        onOk: refetchLocationFromTo,
        cancelText: 'Cancel',
      });
    }
  }, [errorLocationFromTo, refetchLocationFromTo]);

  function capFirst(str = null) {
    if (str) {
      return str[0].toUpperCase() + str.slice(1);
    }
  }

  const getTripType = async (
    value,
    actual_from,
    actual_destination,
    cluster,
    cell,
    dataSource,
    newRow,
    text
  ) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findTripTypes.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          let tripTypeDefault = result.data.map((b) => ({
            label: capFirst(b.name_en),
            value: b.id,
          }));
          tripTypeDefaultRef.current = tripTypeDefault;

          if (result.data && !newRow && !formData) {
            const tripType = result.data.map((b) => ({
              label: capFirst(b.name_en),
              value: b.id,
            }));

            let arrTable = [
              {
                id: 1,
                index: 1,
                newTrip: true,
                key: 0,
                receive_job_datetime: null,
                actual_from: null,
                yardout_datetime: null,
                actual_destination: null,
                cluster: null,
                actual_loading_unit: null,
                trip_type: 'Normal',
                tripTypeOptions: tripType,
                combine: null,
                eta_datetime_edit: 'none',
                actual_return_place: null,
                return_place_datetime_edit: 'none',
                plan_type: null,
                inputData: true,
                disableFormRow: disableFormRowRef.current,
                locationFromToOptions: actual_from,
                locationDestinationOptions: actual_destination,
                locationClusterOptions: cluster,
              },
            ];

            dataTableRef.current = arrTable;
            setDataTable(arrTable);
          } else if (result.data && formData && newRow) {
            let arrLoading = [];
            for (
              let index = 1;
              index <= formData.trailer_type.maximum_loading;
              index++
            ) {
              arrLoading.push({
                label: index,
                value: index,
              });
            }

            const tripType = result.data.map((b) => ({
              label: capFirst(b.name_en),
              value: b.id,
            }));

            const index = dataSource.findIndex(
              (item) => cell.index === item.index
            );

            indexDataTableRef.current = index;

            let return_place_datetime_edit = 'none';
            if (text === 'after') {
              dataSource.map((obj, index) => {
                if (index === cell.trip - 1) {
                  return_place_datetime_edit = obj.return_place_datetime_edit;
                }
              });
            } else if (text === 'before') {
              dataSource.map((obj, index) => {
                if (index === cell.trip - 2) {
                  return_place_datetime_edit = obj.return_place_datetime_edit;
                }
              });
            }
            let new_receive_job_datetime = null;
            if (return_place_datetime_edit) {
              new_receive_job_datetime = moment(return_place_datetime_edit).add(
                1,
                'minutes'
              );
              receiveJobTimeFieldRef.current =
                new_receive_job_datetime.format();
            }

            const newData = {
              id: dataSource.length + 1,
              index: dataSource.length + 1,
              nextTrip: true,
              trip: null,
              combine: null,
              trip_no_original: null,
              key: dataSource.length + 1,
              receive_job_datetime:
                index === 0 && text === 'before'
                  ? null
                  : new_receive_job_datetime
                  ? new_receive_job_datetime.format()
                  : null,
              receive_job_add_trip_before: true,
              actual_loading_unit: formData?.trailer_type.maximum_loading,
              actual_from: null,
              yardout_datetime: null,
              actual_destination: null,
              cluster: null,
              loadingUnitOptions: arrLoading,
              trip_type: 'Normal',
              tripTypeOptions: tripType,
              eta_datetime_edit: 'none',
              actual_return_place: null,
              return_place_datetime_edit: 'none',
              plan_type: null,
              inputData: true,
              locationFromToOptions: actual_from,
              locationDestinationOptions: actual_destination,
              locationClusterOptions: cluster,
            };

            if (text === 'after') {
              dataSource.splice(index + 1, 0, { ...newData });
            } else if (text === 'before') {
              dataSource.splice(index, 0, { ...newData });
            }

            dataTableRef.current = [...dataSource];
            setDataTable([...dataSource]);
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      return resData;
    }
  };
  const getPlanType = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findTripTypes.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const getDraft = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findCreateTripByDraftDispatchBoard.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          // setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
            setDataDraft(result.data);
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const getDataLoationAll = (
    dataLocationFromTo,
    cell,
    dataSource,
    newRow,
    text
  ) => {
    setIsLoading(true);
    dataLocationFromToRef.current = dataLocationFromTo;
    const from = dataLocationFromTo?.from.map((b) => ({
      label: b.short_name,
      value: b.code,
    }));

    const destination = dataLocationFromTo?.destination.map((b) => ({
      label: b.short_name,
      value: b.code,
      cluster: b.cluster && b.cluster.code,
    }));

    const cluster = dataLocationFromTo?.cluster.map((b) => ({
      label: b.name,
      value: b.code,
    }));

    locationFromOptionsRef.current = from;
    locationDestinationOptionsRef.current = destination;
    locationClusterOptionsRef.current = cluster;
    let arrBody = {
      category_code: '03',
    };
    getTripType(
      arrBody,
      locationFromOptionsRef.current,
      locationDestinationOptionsRef.current,
      locationClusterOptionsRef.current,
      cell,
      dataSource,
      newRow,
      text
    );
    setIsLoading(false);
  };
  useEffect(() => {
    if (dataLocationFromTo) {
      getDataLoationAll(dataLocationFromTo);
    }
  }, [dataLocationFromTo]);

  useEffect(() => {
    if (errorBzps) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load TrailerLane',
        okText: 'Retry',
        onOk: refetchBzps,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzps, refetchBzps]);

  const createGenarateJobCode = async (value) => {
    let newData = [];

    if (value) {
      let result = await dispatch(
        createTripApi.endpoints.createGenarateJobCode.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          newData = result.data;
        }
      }
    }
    return newData;
  };
  const createGenarateCombineCode = async () => {
    let newData = null;
    let result = await dispatch(
      createTripApi.endpoints.createGenarateCombineCode.initiate(null, {
        forceRefetch: true,
      })
    );
    if (result && result.error) {
      setErrorData(result.error);
    } else {
      if (result.data) {
        newData = result.data.combine_code;
      }
    }
    return newData;
  };
  const createTripSave = async (value) => {
    let newData = null;

    if (value) {
      let result = await dispatch(
        createTripApi.endpoints.createTripSave.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          newData = result.data;
        }
      }
    }
    return newData;
  };
  const createTripSaveDraft = async (value) => {
    let newData = null;

    if (value) {
      let result = await dispatch(
        createTripApi.endpoints.createTripDraft.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          newData = result.data;
        }
      }
    }
    return newData;
  };
  const findLocation = (value) => {
    let location = dataLocationFromToRef.current.destination.find(
      (f) => f.code === value
    );
    return location.short_name;
  };
  const msgWarning = (text, window_time) => {
    disableBtnSaveRef.current = false;
    if (window_time) {
      return Modal.error({
        title: <div style={{ color: 'red' }}>Not pass Window time</div>,
        icon: <ExclamationCircleOutlined />,
        content: (
          <div>
            Please noted that you can recieve job time <br />
            {window_time &&
              window_time.warning_msg.map((w, index) => (
                <div key={index}>
                  {w.location_from
                    ? `[Trip no. ${w.id}] ${findLocation(
                        window_time.warning_msg[index].location_from
                      )} : ${
                        window_time.warning_msg[index].can_receive_time
                          ? dateTimeTimestamptzFormatter({
                              value:
                                window_time.warning_msg[index].can_receive_time,
                            })
                          : window_time.warning_msg[index]?.error_msg
                      }
                  `
                    : w.error_msg}
                </div>
              ))}
            <div style={{ whiteSpace: 'pre-line', color: 'red' }}>
              {text ? (
                <>
                  Warning : <br />
                  {text}
                </>
              ) : null}
            </div>
          </div>
        ),
        width: 460,
      });
    } else {
      return Modal.error({
        icon: <ExclamationCircleOutlined />,
        content: text ? (
          <div style={{ whiteSpace: 'pre-line', color: 'red' }}>
            Warning : <br /> {text}
          </div>
        ) : null,
      });
    }
  };
  const checkButton = async (value) => {
    isCheckButtonRef.current = true;
    let newData = [];
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.checkButton.initiate(value, {
            forceRefetch: true,
          })
        );
        if (result && result.error) {
          Modal.error({
            title: result.error.data.errorList[0].errorMessage,
            icon: <ExclamationCircleOutlined />,
            content: result.error.data.errorList[0].errorDetail,
          });
        } else {
          if (result.data) {
            warningSaveDraft.current = false;
            disableSaveDraftRef.current = false;
            disableBtnSaveRef.current = false;
            newData = result.data?.warning;
            if (
              newData &&
              newData?.check_window_time?.result &&
              newData?.impact_another_plan?.result &&
              newData?.location_and_return_place?.result &&
              newData?.route_master?.result &&
              newData?.check_price_master?.result
            ) {
              warningSaveDraft.current = false;
              disableSaveDraftRef.current = false;
              disableBtnSaveRef.current = false;
              Modal.success({
                title: 'Check window time passed!',
                icon: <CheckCircleOutlined />,
              });
            } else {
              if (newData?.impact_another_plan?.warning_msg) {
                warningSaveDraft.current = true;
              } else {
                warningSaveDraft.current = false;
                disableSaveDraftRef.current = false;
                disableBtnSaveRef.current = false;
              }

              let check_window_time = newData?.check_window_time?.warning_msg
                ? newData?.check_window_time?.warning_msg
                : null;
              let impact_another_plan = newData?.impact_another_plan
                ?.warning_msg
                ? `- ${newData?.impact_another_plan?.warning_msg} \n`
                : null;
              let location_and_return_place = newData?.location_and_return_place
                ?.warning_msg
                ? `- ${newData?.location_and_return_place?.warning_msg} \n`
                : null;
              let route_master = newData?.route_master?.warning_msg
                ? `- ${newData?.route_master?.warning_msg} \n`
                : null;
              let check_price_master = newData?.check_price_master?.warning_msg
                ? `- ${newData?.check_price_master?.warning_msg} \n`
                : null;

              if (check_window_time) {
                let data = {
                  check_window_time: true,
                };
                updateDataTable(data);
              } else {
                let data = {
                  check_window_time: false,
                };
                updateDataTable(data);
              }

              if (
                check_window_time &&
                impact_another_plan &&
                location_and_return_place &&
                route_master &&
                check_price_master
              ) {
                msgWarning(
                  impact_another_plan +
                    location_and_return_place +
                    route_master +
                    check_price_master,
                  newData.check_window_time
                );
              } else if (
                check_window_time &&
                impact_another_plan &&
                location_and_return_place &&
                check_price_master
              ) {
                msgWarning(
                  impact_another_plan +
                    location_and_return_place +
                    check_price_master,
                  newData.check_window_time
                );
              } else if (
                check_window_time &&
                impact_another_plan &&
                check_price_master
              ) {
                msgWarning(
                  impact_another_plan + check_price_master,
                  newData.check_window_time
                );
              } else if (
                check_window_time &&
                impact_another_plan &&
                location_and_return_place
              ) {
                msgWarning(
                  impact_another_plan + location_and_return_place,
                  newData.check_window_time
                );
              } else if (check_window_time && location_and_return_place) {
                msgWarning(
                  location_and_return_place,
                  newData.check_window_time
                );
              } else if (check_window_time && route_master) {
                msgWarning(route_master, newData.check_window_time);
              } else if (check_window_time && check_price_master) {
                msgWarning(check_price_master, newData.check_window_time);
              } else if (check_window_time && impact_another_plan) {
                msgWarning(impact_another_plan, newData.check_window_time);
              } else if (
                impact_another_plan &&
                location_and_return_place &&
                route_master &&
                check_price_master
              ) {
                msgWarning(
                  impact_another_plan +
                    location_and_return_place +
                    route_master +
                    check_price_master
                );
              } else if (impact_another_plan && location_and_return_place) {
                msgWarning(impact_another_plan + location_and_return_place);
              } else if (location_and_return_place && route_master) {
                msgWarning(location_and_return_place + route_master);
              } else if (location_and_return_place && check_price_master) {
                msgWarning(location_and_return_place + check_price_master);
              } else if (impact_another_plan && route_master) {
                msgWarning(impact_another_plan + route_master);
              } else if (impact_another_plan && check_price_master) {
                msgWarning(impact_another_plan + check_price_master);
              } else if (route_master && check_price_master) {
                msgWarning(route_master + check_price_master);
              } else if (check_window_time) {
                msgWarning(null, newData.check_window_time);
              } else if (impact_another_plan) {
                msgWarning(impact_another_plan);
              } else if (location_and_return_place) {
                msgWarning(location_and_return_place);
              } else if (route_master) {
                msgWarning(route_master);
              } else if (check_price_master) {
                msgWarning(check_price_master);
              }
            }
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      disableBtnSaveRef.current = false;
    }
  };

  const selectProps = {
    showSearch: true,
    size: 'small',
    style: {
      width: '100%',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoading,
  };

  const onEditTable = () => {
    try {
      if (editDisabled.current !== true) {
        setEditTableDisabled(false);
        let arrData = [];
        for (let index = 0; index < dataTable.length; index++) {
          for (let index2 = 0; index2 < dataDiagramOwner.length; index2++) {
            if (
              dataTable[index].actual_from.short_name ===
                dataDiagramOwner[index2].location_from?.short_name &&
              dataTable[index].actual_destination?.short_name ===
                dataDiagramOwner[index2].location_destination?.short_name
            ) {
              arrData.push(dataDiagramOwner[index2]);
            }
          }
        }

        maximumLoadingRef.current = formData.trailer_type.maximum_loading;
        let arrLoading = [];
        for (
          let index = 1;
          index <= formData.trailer_type.maximum_loading;
          index++
        ) {
          arrLoading.push({
            label: index,
            value: index,
          });
        }

        dataDiagramOwnerCheckRef.current = arrData;
        let arrDataTable = [];
        arrDataTable = dataTable.map((item) => ({
          ...item,
          btnEdit: true,

          inputData: true,
          tripTypeOptions: tripTypeDefaultRef.current,
          editDiagramOwner: arrData,
          locationFromToOptions: locationFromOptionsRef.current,
          locationDestinationOptions: locationDestinationOptionsRef.current,
          locationClusterOptions: locationClusterOptionsRef.current,
          disableFormRow: false,
          maximum_loading: maximumLoadingRef.current,
          loadingUnitOptions: arrLoading,
        }));
        dataTableRef.current = [...arrDataTable];
        setDataTable([...arrDataTable]);
        editDisabled.current = true;
      }
    } catch (err) {
      setEditTableDisabled(true);
      console.log('err', err);
    }
  };
  const onChangeOptionBzp = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findCreateTripByTypes.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
            setTrailerTypeOptions(
              resData.map((b) => ({
                label: b.name,
                value: b.id,
                maximum_loading: b.maximum_loading,
              }))
            );
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const onGetETA = async (value, indexFirst) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findGetETA.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error && indexFirst === 1) {
          Modal.warning({
            title:
              result.error.data.errorList[0].errorDetail ===
              'Unable to parse string [Invalid date]'
                ? 'ไม่สามารถสร้างทริปได้เนื่องจากหา Return Time ไม่ได้'
                : result.error.data.errorList[0].errorDetail,
            icon: <ExclamationCircleOutlined />,
            okText: 'OK',
          });

          disableSaveDraftRef.current = false;
          disableBtnSaveRef.current = false;
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const onOperationLeadtimes = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findOperationLeadtimes.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const onRouteSpeedDistanceETATime = async (value, row_id) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findRouteSpeedDistance.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          resData = result.error;
          if (row_id) {
            let obj_eta_time = {
              eta_datetime_edit: 'none',
            };
            updateDataTable(obj_eta_time, row_id);
          }
          if (isCheckButtonRef.current === false) {
            // Modal.warning({
            //   title: result.error.data.errorList[0].errorDetail,
            //   icon: <ExclamationCircleOutlined />,
            //   okText: 'OK',
            // });
          }

          // disableSaveDraftRef.current = true;
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onChangeOptionTrailerType = (value) => {
    try {
      let objFind = trailerTypeOptions.find((t) => t.value === value);
      maximumLoadingRef.current = objFind;
      let arrLoading = [];
      for (let index = 1; index <= objFind.maximum_loading; index++) {
        arrLoading.push({
          label: index,
          value: index,
        });
      }

      let obj_maximum_loading = {
        maximum_loading: objFind.maximum_loading,
        loadingUnitOptions: arrLoading,
      };
      updateDataTable(obj_maximum_loading);
    } catch (err) {
      console.log('err', err);
    }
  };

  const onFromField = async (value, row_id) => {
    let findFrom = dataLocationFromToRef.current.from.find(
      (d) => d.code === value
    );
    let arr;
    arr = {
      actual_from: findFrom,
    };
    findFromRef.current = findFrom;

    updateDataTable(arr, row_id);
  };
  const onReceiveJobTimeField = async (value, row_id, check_window_time) => {
    form.getFieldsValue(true);
    receiveJobTimeFieldRef.current = value;
    let receiveJobDatetime = {
      receive_job_datetime: value,
      check_window_time: check_window_time,
    };

    updateDataTable(receiveJobDatetime, row_id);
  };
  const tripTypeField = (value, row_id) => {
    let tripType = {
      trip_type: value,
    };
    tripTypeRef.current = value;
    updateDataTable(tripType, row_id);
  };
  const onLoadingUnitField = (value, row_id) => {
    let actual_loading_unit = {
      actual_loading_unit: value,
    };
    updateDataTable(actual_loading_unit, row_id);
  };

  const onClusterField = async (cluster, row_id) => {
    isCheckButtonRef.current = false;
    let arrFilterDestinationId = dataLocationFromToRef.current.destination
      .filter((d) => d.cluster && d.cluster.code === cluster)
      .map((d) => d.id);
    let findCluster = dataLocationFromToRef.current.cluster.find(
      (d) => d.code === cluster
    );
    findClusterRef.current = findCluster;

    let findFrom = dataTableRef.current.find((item) => item.id === row_id);

    if (findFrom && findFrom.actual_from && findFrom.actual_from.id) {
      let bodyLeadTime = {
        location_from: findFrom.actual_from.id,
        location_to: arrFilterDestinationId,
      };
      let leadTime = await onRouteSpeedDistanceETATime(bodyLeadTime, row_id);

      let arrFilterDestination = dataLocationFromToRef.current.destination
        .filter((d) => d.code === leadTime?.location_to?.code)
        .map((d) => ({
          label: d.short_name,
          value: d.code,
        }));
      let findDestination = [];
      if (arrFilterDestination.length > 0) {
        findDestination = dataLocationFromToRef.current.destination.filter(
          (d) => d.code === arrFilterDestination[0].value
        );
      }

      let arrFilterDestinationBefore = dataLocationFromToRef.current.destination
        .filter((d) => d.cluster && d.cluster.code === cluster)
        .map((d) => ({
          label: d.short_name,
          value: d.code,
        }));

      let findDestinationNone = [];
      if (arrFilterDestinationBefore.length > 0) {
        findDestinationNone = dataLocationFromToRef.current.destination.filter(
          (d) => d.code === arrFilterDestinationBefore[0].value
        );
      }

      let objLocationDestinationOptions = {
        locationDestinationOptions:
          arrFilterDestinationBefore.length > 0
            ? arrFilterDestinationBefore
            : undefined,
        cluster: findCluster.name,
        actual_destination:
          findDestination.length > 0
            ? findDestination[0]
            : findDestinationNone.length > 0
            ? findDestinationNone[0]
            : undefined,
        actual_cluster_to: findCluster,
      };

      updateDataTable(objLocationDestinationOptions, row_id);
    } else {
      let arrFilterDestination = dataLocationFromToRef.current.destination
        .filter((d) => d.cluster && d.cluster.code === cluster)
        .map((d) => ({
          label: d.short_name,
          value: d.code,
        }));

      let findCluster = dataLocationFromToRef.current.cluster.find(
        (d) => d.code === cluster
      );
      findClusterRef.current = findCluster;

      let objLocationDestinationOptions = {
        locationDestinationOptions: arrFilterDestination,
        cluster: findCluster,
        actual_cluster_to: findCluster,
      };
      updateDataTable(objLocationDestinationOptions, row_id);
    }
  };

  const onDestinationField = async (actual_destination, row_id) => {
    isCheckButtonRef.current = false;
    let objFindDestination = dataLocationFromToRef.current.destination.find(
      (d) => d.code === actual_destination
    );

    let clusterCode =
      objFindDestination.cluster && objFindDestination.cluster.code;

    let objLocationClusterOptions = {
      actual_destination: objFindDestination,
      cluster: clusterCode,
      actual_cluster_to: objFindDestination,
    };

    findDestinationRef.current = objFindDestination;
    updateDataTable(objLocationClusterOptions, row_id);
  };
  const onResetDestinationOption = (row_id) => {
    let defaultLocationDestinationOptions = {
      cluster: undefined,
      actual_cluster_to: undefined,
      actual_destination: undefined,
      locationClusterOptions: locationClusterOptionsRef.current,
      locationDestinationOptions: locationDestinationOptionsRef.current,
    };

    findClusterRef.current = null;
    updateDataTable(defaultLocationDestinationOptions, row_id);
  };

  const onResetResetClustrOption = (row_id) => {
    let defaultLocationClusterOptions = {
      actual_destination: undefined,
      locationClusterOptions: locationClusterOptionsRef.current,
    };
    findDestinationRef.current = undefined;
    updateDataTable(defaultLocationClusterOptions, row_id);
    onCheckDisabled();
  };

  const onDropdownClusterOptions = async (row_id, record) => {
    if (record.actual_destination && record.actual_destination.cluster) {
      let arrFilterCluster = dataLocationFromToRef.current.cluster
        .filter((d) => d.code === record.actual_destination?.cluster?.code)
        .map((d) => ({
          label: d.name,
          value: d.code,
        }));

      let objLocationClusterOptions = {
        locationClusterOptions: arrFilterCluster,
      };
      updateDataTable(objLocationClusterOptions, row_id);
    }
  };
  const onDropdownDestinationOptions = async (row_id, record) => {
    if (record.actual_cluster_to && record.actual_cluster_to.cluster) {
      let arrFilterDestination = dataLocationFromToRef.current.destination
        .filter(
          (d) =>
            d.cluster &&
            d.cluster.code === record.actual_cluster_to?.cluster?.code
        )
        .map((d) => ({
          label: d.short_name,
          value: d.code,
        }));

      let objLocationDestinationOptions = {
        locationDestinationOptions: arrFilterDestination,
      };
      updateDataTable(objLocationDestinationOptions, row_id);
    } else if (record.actual_cluster_to && record.actual_cluster_to) {
      let arrFilterDestination = dataLocationFromToRef.current.destination
        .filter(
          (d) => d.cluster && d.cluster.code === record.actual_cluster_to?.code
        )
        .map((d) => ({
          label: d.short_name,
          value: d.code,
        }));

      let objLocationDestinationOptions = {
        locationDestinationOptions: arrFilterDestination,
      };
      updateDataTable(objLocationDestinationOptions, row_id);
    } else {
      let objLocationDestinationOptions = {
        locationDestinationOptions: locationDestinationOptionsRef.current,
      };
      updateDataTable(objLocationDestinationOptions, row_id);
    }
  };

  const updateDataTable = async (objValue, row_id, check_window_time) => {
    try {
      if (formData && dataTableRef.current && row_id) {
        let arrDataTable = [];

        arrDataTable = dataTableRef.current
          .filter((item) => item.id === row_id)
          .map((item) => ({
            ...item,
            ...objValue,
          }));

        let dataTableNew = dataTableRef.current;
        const index = dataTableRef.current.findIndex(
          (item) => item.id === row_id
        );

        dataTableNew.splice(index, 1, ...arrDataTable);
        dataTableRef.current = [...dataTableNew];
        setDataTable([...dataTableNew]);
      } else {
        let arrDataTable = [];
        arrDataTable = dataTableRef.current.map((item) => ({
          ...item,
          ...objValue,
        }));
        dataTableRef.current = arrDataTable;
        setDataTable(arrDataTable);
      }
      disableSaveDraftRef.current = true;
      disableBtnSaveRef.current = true;
    } catch (error) {
      console.log('error', error);
    }
  };

  const isTimeBetween = function (startTime, endTime, serverTime) {
    let start = moment(startTime, 'H:mm');
    let end = moment(endTime, 'H:mm');
    let server = moment(serverTime, 'H:mm');

    if (end < start) {
      return (
        (server >= start && server <= moment('23:59:59', 'h:mm:ss')) ||
        (server >= moment('0:00:00', 'h:mm:ss') && server < end)
      );
    }
    return server >= start && server < end;
  };

  const postCheckReturnPlace = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.checkReturnPlace.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const processReturnPlaceDataTable = async (dataTable) => {
    let save_trip = null;
    let save_combine = null;
    let total_trip = 0;
    let result = [];
    for (let index = 0; index < dataTable.length; index++) {
      if (save_trip == null) {
        if (dataTable[index].trip == null) {
          save_trip = 1;
          save_combine = dataTable[index].combine;
        } else {
          save_trip = dataTable[index].trip;
          save_combine = dataTable[index].combine;
        }

        total_trip += 1;

        dataTable[index].trip = save_trip;
        dataTable[index].combine = save_combine;

        result.push({
          trip: save_trip,
          combine: save_combine,
        });
      } else {
        if (
          save_combine == dataTable[index].combine &&
          dataTable[index].combine != null
        ) {
          result.push({
            trip: save_trip,
            combine: save_combine,
          });
          dataTable[index].trip = save_trip;
          dataTable[index].combine = save_combine;
        } else {
          save_trip = save_trip + 1;
          save_combine = dataTable[index].combine;
          dataTable[index].combine = save_combine;
          total_trip += 1;
          result.push({
            trip: save_trip,
            combine: save_combine,
          });
          dataTable[index].trip = save_trip;
          dataTable[index].combine = save_combine;
        }
      }
    }

    for (let index = 0; index < result.length; index++) {
      dataTable[index].trip_no = `${result[index].trip}/${total_trip}`;
    }

    let arrReturnPlace = dataTable.map((d) => ({
      trip_no: d.trip,
      location_from: {
        code: d.actual_from && d.actual_from.short_name,
      },
    }));

    let payload = {
      dispatch_boards: arrReturnPlace,
    };

    let res = await postCheckReturnPlace(payload);

    for (let index = 0; index < res.result.length; index++) {
      for (let index = 0; index < dataTable.length; index++) {
        dataTable[index].actual_return_place =
          res.result[index].return_place.code;
      }
    }

    return dataTable;
  };
  const processLeadTimeBackLastDataTable = async (data_, nextTrip) => {
    let lead_time_back_last = 0;
    if (
      data_.combine &&
      data_.actual_from?.code === nextTrip?.actual_from?.code &&
      data_.trip === nextTrip.trip
    ) {
      let _from = nextTrip?.actual_from?.id;

      let bodyLeadTime = {
        location_from: data_?.actual_destination?.id,
        location_to: _from,
      };

      let leadTime_back = await onRouteSpeedDistanceETATime(bodyLeadTime);

      lead_time_back_last =
        (leadTime_back?.distance / leadTime_back?.speed) * 60;
    } else if (
      data_.combine &&
      data_?.actual_from?.code !== nextTrip?.actual_from?.code &&
      data_.trip === nextTrip.trip
    ) {
      let _from = nextTrip?.actual_from?.id;

      let bodyLeadTime = {
        // location_from: dataTable[index].actual_destination? dataTable[index].actual_destination?.id :  arrFilterDestinationId[0],
        location_from: _from,
        location_to: data_?.actual_destination?.id,
      };
      let leadTime_back = await onRouteSpeedDistanceETATime(bodyLeadTime);

      lead_time_back_last =
        (leadTime_back?.distance / leadTime_back?.speed) * 60;
    }

    return lead_time_back_last;
  };

  const getReceiveJobsSort = async (dataTable_, index) => {
    try {
      const values = await form.validateFields();
      let obj_planType = null;

      let arrBody = {
          category: 200,
          category_code: '02',
        };
        let planType = await getPlanType(arrBody);

      obj_planType =
          planType && planType.find((b) => b.name_en === 'Over Diagram');
      
      if (dataTable_?.receive_job_datetime) {
        if (dataTable_?.receive_job_datetime && index == 0) {
          let receive_job_datetime_first = dataTable_?.receive_job_datetime;
          let shiftCalulate = null;
          let day = isTimeBetween(
            '07:30',
            '19:30',
            moment(receive_job_datetime_first).format('H:mm')
          );
          let night = isTimeBetween(
            '19:30',
            '07:30',
            moment(receive_job_datetime_first).format('H:mm')
          );

          if (day) {
            shiftCalulate = 'Day';
          } else if (night) {
            shiftCalulate = 'Night';
          }

          if (!id) {
            const now = moment(receive_job_datetime_first);
            const cutoff = moment(receive_job_datetime_first)
              .hour(7)
              .minute(30)
              .second(0);
            let day_ = now < cutoff ? now.add(-1, 'day') : now;
            const result = day_.format('YYYY-MM-DD');

            let arr = {
              company: values?.bzp,
              plan_type: obj_planType && obj_planType.id,
              trailer_type: values?.trailer_type,
              working_day: result ? result : null,
              shift: shiftCalulate,
            };
            let arrGenarateJobCode = await createGenarateJobCode(arr);
            if (arrGenarateJobCode) {
              form.setFieldsValue({
                job_code: arrGenarateJobCode?.jobcode,
                shift: shiftCalulate,
                working_day: day_,
              });
            }

            let arrBodys = {
              trip_no: !id ? '1/1' : null,
              trip: !id ? 1 : null,
              trip_no_original: !id ? 1 : null,
              plan_type: obj_planType,
              job_code: arrGenarateJobCode?.jobcode,
              receive_job_datetime: day_,
              working_day: result,
            };
            return arrBodys;
          } else {
            const now = moment(receive_job_datetime_first);
            const cutoff = moment(receive_job_datetime_first)
              .hour(7)
              .minute(30)
              .second(0);
            let day_ = now < cutoff ? now.add(-1, 'day') : now;

            form.setFieldsValue({
              shift: shiftCalulate,
              working_day: day_ ? day_ : null,
            });
            const result = day_.format('YYYY-MM-DD');

            let arrPlanType = {
              trip_no: dataTable_.trip_no,
              trip: dataTable_.trip,
              trip_no_original: dataTable_?.trip_no_original,
              plan_type: obj_planType,
              working_day: result,
            };

            return arrPlanType;
          }
        } else {
          let arrBodys = {
            trip_no: dataTable_.trip_no,
            trip: dataTable_.trip,
            trip_no_original: dataTable_?.trip_no_original,
            plan_type: obj_planType,
          };

          return arrBodys;
        }
      }

    } catch (error) {
      console.log('error', error);
    }
  };

  const getYardOutTimeSort = async (value) => {
    try {
      if (value.receive_job_datetime && value?.actual_from) {
        let body = {
          location: value?.actual_from?.code,
          unit: value.actual_loading_unit && value.actual_loading_unit,
          type: 'loading',
          mode: 'display',
        };
        let load_time = await onOperationLeadtimes(body);
        if (!load_time.length) {
          Modal.warning({
            title: 'Yard out time: Not found loading.',
            icon: <ExclamationCircleOutlined />,
            okText: 'OK',
          });
        }

        if (load_time.length > 0) {
          let yardout_datetime = moment(value.receive_job_datetime).add(
            load_time[0].time,
            'm'
          );

          yardOutTimeRef.current = yardout_datetime;
          let obj_yard_out_time = null;
          if (!id) {
            obj_yard_out_time = {
              yardout_datetime: yardout_datetime.format(),
              yardout_datetime_ref: yardout_datetime,
              loading_time: load_time[0].time,
            };
          } else {
            obj_yard_out_time = {
              yardout_datetime: yardout_datetime.format(),
              yardout_datetime_ref: yardout_datetime,
              loading_time: load_time[0].time,
            };
          }
          return obj_yard_out_time;
        }
      } else {
        let un_yard_out_time = {
          yardout_datetime: null,
          yardout_datetime_ref: null,
        };
        return un_yard_out_time;
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const getETASortAndReturnTime = async (
    value,
    loading_time,
    nextTrip,
    index,
    indexFirst
  ) => {
    try {
      if (
        (value.actual_from && value.actual_destination) ||
        (value.actual_from && value.actual_cluster_to)
      ) {
        let resETA = null;
        let leadTime = null;

        if (!value?.combine) {
          let clusterNew = value.actual_cluster_to?.cluster
            ? value.actual_cluster_to?.cluster?.code
            : value.actual_cluster_to
            ? value.actual_cluster_to?.code
            : null;

          let arrFilterDestinationId = dataLocationFromToRef.current.destination
            .filter((d) => d.cluster && d.cluster.code == clusterNew)
            .map((d) => d.id);

          let bodyLeadTime = {
            location_from: value?.actual_from?.id,
            location_to: arrFilterDestinationId,
          };
          leadTime = await onRouteSpeedDistanceETATime(bodyLeadTime, value.id);

          let bodyUnLoadTime = {
            location: value.actual_destination
              ? value.actual_destination?.code
              : value.actual_cluster_to
              ? leadTime?.location_to?.code
              : null,
            unit: value.actual_loading_unit && value.actual_loading_unit,
            type: 'unloading',
            mode: 'display',
          };

          let unLoadTime = await onOperationLeadtimes(bodyUnLoadTime);
          let location_return = dataLocationFromToRef.current.from.find(
            (f) => f.short_name === value.actual_return_place
          );
          if (
            value?.receive_job_datetime &&
            value?.receive_job_datetime !== 'Invalid date' &&
            value?.receive_job_datetime !== null
          ) {
            let body = {
              location_from: value?.actual_from?.id,
              location_to: value.actual_destination
                ? value.actual_destination?.id
                : value.actual_cluster_to
                ? leadTime?.location_to?.id
                : null,
              location_return: location_return?.id,
              receive_job_time: value.receive_job_datetime,
              loading_time: loading_time,
              unloading_time: unLoadTime[0]?.time,
            };

            resETA = await onGetETA(body, indexFirst);
          }
        }

        let obj_eta_time = {
          eta_datetime_edit: resETA?.eta_time ? resETA?.eta_time : 'none',
          return_time_edit: resETA?.return_time ? resETA?.return_time : 'none',

          destination_farthest: leadTime?.location_to,
        };

        return obj_eta_time;
      } else {
        let un_obj_eta_time = {
          eta_datetime_edit: 'none',
          eta_datetime_ref: null,
          return_place_datetime_edit: 'none',
        };
        return un_obj_eta_time;
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const getYardOutTimeSortCombine = async (value) => {
    try {
      if (value.receive_job_datetime && value?.actual_from) {
        let body = {
          location: value?.actual_from?.code,
          unit: value.actual_loading_unit && value.actual_loading_unit,
          type: 'loading',
          mode: 'display',
        };
        let load_time = await onOperationLeadtimes(body);
        if (!load_time.length) {
          Modal.warning({
            title: 'Yard out time: Not found loading.',
            icon: <ExclamationCircleOutlined />,
            okText: 'OK',
          });
        }

        if (load_time.length > 0) {
          let yardout_datetime = moment(value.receive_job_datetime).add(
            load_time[0].time,
            'm'
          );

          yardOutTimeRef.current = yardout_datetime;
          let obj_yard_out_time = null;
          if (!id) {
            obj_yard_out_time = {
              yardout_datetime: yardout_datetime.format(),
              yardout_datetime_ref: yardout_datetime,
              loading_time: load_time[0].time,
            };
          } else {
            obj_yard_out_time = {
              yardout_datetime: yardout_datetime.format(),
              yardout_datetime_ref: yardout_datetime,
              loading_time: load_time[0].time,
            };
          }
          return obj_yard_out_time;
        }
      } else {
        let un_yard_out_time = {
          yardout_datetime: null,
          yardout_datetime_ref: null,
        };
        return un_yard_out_time;
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const getETASortAndReturnTimeCombine = async (
    value,
    loading_time,
    nextTrip,
    index,
    indexFirst
    // new_datetime_index_first
  ) => {
    try {
      if (
        (value.actual_from && value.actual_destination) ||
        (value.actual_from && value.actual_cluster_to)
      ) {
        let resETA = null;
        let leadTime = null;

        if (value?.actual_from?.code === nextTrip?.actual_from?.code) {
          let clusterNew = value?.objLeadTimeBack?.actual_cluster_to?.cluster
            ? value?.objLeadTimeBack?.actual_cluster_to?.cluster?.code
            : value?.objLeadTimeBack?.actual_cluster_to
            ? value?.objLeadTimeBack?.actual_cluster_to?.code
            : null;

          let arrFilterDestinationId = dataLocationFromToRef.current.destination
            .filter((d) => d.cluster && d.cluster.code == clusterNew)
            .map((d) => d.id);

          let location_return = dataLocationFromToRef.current.from.find(
            (f) => f.short_name === value.actual_return_place
          );

          let bodyLeadTime = {
            location_from: value?.objLeadTimeBack?.actual_from?.id,
            location_to: arrFilterDestinationId,
          };
          leadTime = await onRouteSpeedDistanceETATime(bodyLeadTime, value.id);
          let bodyUnLoadTime = {
            location: value?.objLeadTimeBack?.actual_destination
              ? value?.objLeadTimeBack?.actual_destination?.code
              : value?.objLeadTimeBack?.actual_cluster_to
              ? leadTime?.location_to?.code
              : arrFilterDestinationId
              ? arrFilterDestinationId[0]
              : null,
            unit: value.actual_loading_unit && value.actual_loading_unit,
            type: 'unloading',
            mode: 'display',
          };

          let unLoadTime = await onOperationLeadtimes(bodyUnLoadTime);
          if (
            value?.receive_job_datetime &&
            value?.receive_job_datetime !== 'Invalid date' &&
            value?.receive_job_datetime !== null
          ) {
            let body = {
              location_from: nextTrip?.actual_from?.id,
              location_to: value?.objLeadTimeBack?.actual_destination
                ? value?.objLeadTimeBack?.actual_destination?.id
                : value?.objLeadTimeBack?.actual_cluster_to
                ? leadTime?.location_to?.id
                : arrFilterDestinationId
                ? arrFilterDestinationId[0]
                : null,
              location_return: location_return?.id,
              receive_job_time: value.receive_job_datetime,
              loading_time: loading_time,
              unloading_time: unLoadTime[0]?.time,
            };

            resETA = await onGetETA(body, indexFirst);
          }
        } else if (value?.actual_from?.code !== nextTrip?.actual_from?.code) {
          let checkIndex = findLastDataTableIndex(
            dataTable,
            dataTable[index]?.trip
          );

          let clusterNew = dataTable[index]?.actual_cluster_to?.cluster
            ? dataTable[index]?.actual_cluster_to?.cluster?.code
            : dataTable[index]?.actual_cluster_to
            ? dataTable[index]?.actual_cluster_to?.code
            : null;

          let arrFilterDestinationId = dataLocationFromToRef.current.destination
            .filter((d) => d.cluster && d.cluster.code == clusterNew)
            .map((d) => d.id);

          let location_return = dataLocationFromToRef.current.from.find(
            (f) => f.short_name === value.actual_return_place
          );

          let bodyLeadTime = {
            location_from: dataTable[index]?.actual_from?.id,
            location_to: arrFilterDestinationId,
          };
          leadTime = await onRouteSpeedDistanceETATime(bodyLeadTime, value.id);
          let bodyUnLoadTime = {
            location: dataTable[index]?.actual_destination
              ? dataTable[index]?.actual_destination?.code
              : dataTable[index]?.actual_cluster_to
              ? leadTime?.location_to?.code
              : arrFilterDestinationId
              ? arrFilterDestinationId[0]
              : null,
            unit: value.actual_loading_unit && value.actual_loading_unit,
            type: 'unloading',
            mode: 'display',
          };

          let receiveJobNew =
            checkIndex[checkIndex?.length - 1]?.receive_job_datetime;
          let unLoadTime = await onOperationLeadtimes(bodyUnLoadTime);
          if (
            value?.receive_job_datetime &&
            value?.receive_job_datetime !== 'Invalid date' &&
            value?.receive_job_datetime !== null
          ) {
            let body = {
              location_from: checkIndex[checkIndex?.length - 1]?.actual_from?.id
                ? checkIndex[checkIndex?.length - 1]?.actual_from?.id
                : dataTable[index]?.actual_from?.id,
              location_to: dataTable[index]?.actual_destination
                ? dataTable[index]?.actual_destination?.id
                : dataTable[index]?.actual_cluster_to
                ? leadTime?.location_to?.id
                : arrFilterDestinationId
                ? arrFilterDestinationId[0]
                : null,
              location_return: location_return?.id,
              receive_job_time:
                checkIndex?.length > 0
                  ? receiveJobNew
                  : dataTable[index]?.receive_job_datetime,
              loading_time: loading_time,
              unloading_time: unLoadTime[0]?.time,
            };

            resETA = await onGetETA(body, indexFirst);
          }
        }

        let obj_eta_time = {
          eta_datetime_edit: resETA?.eta_time ? resETA?.eta_time : 'none',
          return_time_edit: resETA?.return_time ? resETA?.return_time : 'none',

          destination_farthest: leadTime?.location_to,
        };

        return obj_eta_time;
      } else {
        let un_obj_eta_time = {
          eta_datetime_edit: 'none',
          eta_datetime_ref: null,
          return_place_datetime_edit: 'none',
        };
        return un_obj_eta_time;
      }
    } catch (e) {
      console.log('e', e);
    }
  };

  const processJobNotCombineDataTable = async (
    dataTable_,
    index,
    nextTrip,
    new_dataTable,
    indexFirst
  ) => {
    if (index !== 0 && !dataTable_.combine) {
      let maxReturnPlaceDateTime = findMaxReturnDatetime(
        new_dataTable,
        dataTable_?.trip - 1
      );

      let new_receive_job_datetime = moment(maxReturnPlaceDateTime).add(
        1,
        'minutes'
      );
      dataTable[index].receive_job_datetime = moment(
        new_receive_job_datetime
      ).format();
    }

    let getReceiveJobs = await getReceiveJobsSort(dataTable_, index);
    dataTable_.job_code = getReceiveJobs && getReceiveJobs.job_code;
    // if (!combineRef.current && !dataTable_.combine) {
    //   dataTable_.trip_no_original = dataTable_?.trip;
    // }

    if (dataTable_?.plan_type?.name_en !== 'Diagram') {
      dataTable_.plan_type = getReceiveJobs && getReceiveJobs.plan_type;
    }

    let getYardOutTimeSortVal = await getYardOutTimeSort(dataTable_);

    dataTable_.yardout_datetime =
      getYardOutTimeSortVal && getYardOutTimeSortVal.yardout_datetime;

    let getETAReturnTime = await getETASortAndReturnTime(
      dataTable_,
      getYardOutTimeSortVal?.loading_time,
      nextTrip,
      index,
      indexFirst
    );
    dataTable[index].eta_datetime_edit =
      getETAReturnTime && getETAReturnTime.eta_datetime_edit;
    dataTable[index].return_place_datetime_edit =
      getETAReturnTime && getETAReturnTime.return_time_edit
        ? getETAReturnTime.return_time_edit
        : 'none';
    dataTable[index]['action_check'] = true;
    return dataTable_;
  };
  function findLastDataTableIndex(new_dataTable, tripValue) {
    // กรองรายการตามเงื่อนไขที่กำหนด
    const filtered = new_dataTable.filter(
      (item, index) =>
        item.combine !== null &&
        item.trip === tripValue &&
        item.combine ===
          new_dataTable.find((el) => el.trip === tripValue)?.combine
    );
    return filtered;
  }

  function findMaxYardOutDatetime(new_dataTable, tripValue) {
    // กรองรายการตามเงื่อนไขที่กำหนด
    const filtered = new_dataTable.filter(
      (item) =>
        item.combine !== null &&
        item.trip === tripValue &&
        // เปรียบเทียบค่า combine กับรายการแรกของ trip ที่กำหนด
        item.combine ===
          new_dataTable.find((el) => el.trip === tripValue)?.combine
    );

    // หา yardout_datetime ที่มีค่ามากที่สุด
    let maxDatetime = null;
    for (const item of filtered) {
      if (!maxDatetime || item.yardout_datetime > maxDatetime) {
        maxDatetime = item.yardout_datetime;
      }
    }

    return maxDatetime;
  }
  function findMaxEtaDatetime(new_dataTable, tripValue) {
    // กรองรายการตามเงื่อนไขที่กำหนด
    const filtered = new_dataTable.filter(
      (item) =>
        item.combine !== null &&
        item.trip === tripValue &&
        // เปรียบเทียบค่า combine กับรายการแรกของ trip ที่กำหนด
        item.combine ===
          new_dataTable.find((el) => el.trip === tripValue)?.combine
    );

    // หา eta_datetime ที่มีค่ามากที่สุด
    let maxDatetime = null;
    for (const item of filtered) {
      if (!maxDatetime || item.eta_datetime_edit > maxDatetime) {
        maxDatetime = item.eta_datetime_edit;
      }
    }

    return maxDatetime;
  }

  function findMaxReturnDatetime(new_dataTable, tripValue) {
    // กรองรายการตามเงื่อนไขที่กำหนด
    const filtered = new_dataTable.filter((item) => item.trip === tripValue);

    // หา return ที่มีค่ามากที่สุด
    let maxDatetime = null;
    for (const item of filtered) {
      if (!maxDatetime || item.return_place_datetime_edit > maxDatetime) {
        maxDatetime = item.return_place_datetime_edit;
      }
    }

    return maxDatetime;
  }
  function findMaxReturnDatetimeCombine(new_dataTable, tripValue) {
    // กรองรายการตามเงื่อนไขที่กำหนด
    const filtered = new_dataTable.filter((item) => item.trip === tripValue);

    // หา eta_datetime ที่มีค่ามากที่สุด
    let maxDatetime = null;
    for (const item of filtered) {
      if (!maxDatetime || item.return_place_datetime_edit > maxDatetime) {
        maxDatetime = item.return_place_datetime_edit;
      }
    }

    return maxDatetime;
  }

  const processJobCombineDataTable = async (
    dataTable_,
    index,
    nextTrip,
    new_dataTable,
    indexFirst
  ) => {
    const objLeadTimeBack = new_dataTable.reduce((max, item) => {
      if (item.lead_time_back_last > max.lead_time_back_last) {
        return item;
      }
      return max;
    }, new_dataTable[0]);

    new_dataTable[index]['objLeadTimeBack'] = objLeadTimeBack;
    // let getYardOutTimeSortVal = null;
    let new_receive_job_datetime = null;
    // let new_datetime_index_first = null;
    if (
      index !== 0 &&
      // dataTable[index].first_combine !== true &&
      dataTable_?.actual_from?.code !==
        new_dataTable[index - 1]?.actual_from?.code &&
      dataTable_?.trip === nextTrip?.trip &&
      dataTable_?.combine === nextTrip?.combine
    ) {
      let bodyLeadTime = {
        location_from: new_dataTable[index - 1]?.actual_from?.id,
        location_to: dataTable_?.actual_from?.id,
      };

      let res_leadTime = await onRouteSpeedDistanceETATime(bodyLeadTime);
      let leadTime = (res_leadTime?.distance / res_leadTime?.speed) * 60;

      new_receive_job_datetime = moment(
        new_dataTable[index - 1]?.yardout_datetime
      ).add(leadTime, 'minutes');
      // Update receive_job_datetime based on conditions
      new_dataTable[index].receive_job_datetime = moment(
        new_receive_job_datetime
      ).format();
    } else if (
      index !== 0 &&
      dataTable_?.actual_from?.code ===
        new_dataTable[index - 1]?.actual_from?.code &&
      dataTable_?.trip === nextTrip?.trip &&
      dataTable_?.combine === nextTrip?.combine
    ) {
      // Update receive_job_datetime based on conditions
      new_dataTable[index].receive_job_datetime = moment(
        new_dataTable[index - 1]?.receive_job_datetime
      ).format();
    }

    if (
      (index !== 0 &&
        dataTable_?.combine &&
        dataTable_?.combine !== new_dataTable[index - 1]?.combine) ||
      !dataTable_?.combine
    ) {
      // not same from

      let maxReturnPlaceDateTime = findMaxReturnDatetimeCombine(
        new_dataTable,
        dataTable_?.trip - 1
      );

      let new_receive_job_datetime = moment(maxReturnPlaceDateTime).add(
        1,
        'minutes'
      );
      dataTable[index].receive_job_datetime = moment(
        new_receive_job_datetime
      ).format();
    }
    let getReceiveJobs = await getReceiveJobsSort(dataTable_, index);
    dataTable_.job_code = getReceiveJobs && getReceiveJobs.job_code;

    if (dataTable_?.plan_type?.name_en !== 'Diagram') {
      dataTable_.plan_type = getReceiveJobs && getReceiveJobs.plan_type;
    }

    let getYardOutTimeSortVal = await getYardOutTimeSortCombine(dataTable_);
    dataTable[index].yardout_datetime =
      getYardOutTimeSortVal && getYardOutTimeSortVal.yardout_datetime;

    let getETAReturnTime = await getETASortAndReturnTimeCombine(
      dataTable_,
      getYardOutTimeSortVal?.loading_time,
      nextTrip,
      index,
      indexFirst
    );

    dataTable[index].eta_datetime_edit =
      getETAReturnTime && getETAReturnTime.eta_datetime_edit;
    dataTable[index].return_place_datetime_edit =
      getETAReturnTime && getETAReturnTime.return_time_edit
        ? getETAReturnTime.return_time_edit
        : 'none';
    dataTable[index]['destination_farthest'] =
      getETAReturnTime?.destination_farthest;
    dataTable[index]['action_check'] = true;
    return dataTable_;
  };

  const onBtnCheck = async () => {
    try {
      setIsLoading(true);
      const new_dataTable = dataTable;
      await processReturnPlaceDataTable(new_dataTable);

      for (let indexFirst = 0; indexFirst < 2; indexFirst++) {
        for (let index = 0; index < new_dataTable.length; index++) {
          const new_dataTable_object = new_dataTable[index];
          //เช็คว่าเป็น combine ไหม
          if (!new_dataTable_object?.combine) {
            await processJobNotCombineDataTable(
              new_dataTable_object,
              index,
              new_dataTable[index + 1],
              new_dataTable,
              indexFirst
            );
          } else {
            let result = [];

            // 1. ใช้ filter เพื่อเอาแค่ object ที่มีค่า combine
            let withCombine = new_dataTable.filter(
              (item) => item.combine !== null
            );

            // 2. ใช้ loop ภายใน loop เพื่อเปรียบเทียบ trip ที่มีค่า combine เหมือนกัน
            for (let i = 0; i < withCombine.length; i++) {
              for (let j = i + 1; j < withCombine.length; j++) {
                if (
                  withCombine[i].combine === withCombine[j].combine &&
                  withCombine[i].trip === withCombine[j].trip
                ) {
                  result.push(withCombine[j]);
                  break;
                }
              }
            }

            if (
              (result?.length > 0 && !new_dataTable_object?.combine) ||
              (result !== undefined && !new_dataTable_object?.combine)
            ) {
              let leadTime = await processLeadTimeBackLastDataTable(
                new_dataTable_object,
                new_dataTable[index - 1]
              );
              new_dataTable[index]['lead_time_back_last'] = leadTime;
            }

            await processJobCombineDataTable(
              new_dataTable_object,
              index,
              new_dataTable[index - 1],
              new_dataTable,
              indexFirst
            );
          }
        }
        // let firstSameFromCount = 0;
        for (let index = 0; index < new_dataTable.length; index++) {
          const new_dataTable_object = new_dataTable[index];
          let resultSameForm = [];

          // 1. ใช้ filter เพื่อเอาแค่ object ที่มีค่า combine
          let withCombineSameForm = new_dataTable.filter(
            (item) => item.combine !== null
          );

          // 2. ใช้ loop ภายใน loop เพื่อเปรียบเทียบ trip ที่มีค่า combine เหมือนกัน
          for (let i = 0; i < withCombineSameForm.length; i++) {
            for (let j = i + 1; j < withCombineSameForm.length; j++) {
              if (
                withCombineSameForm[i].combine ===
                  withCombineSameForm[j].combine &&
                withCombineSameForm[i].trip === withCombineSameForm[j].trip &&
                withCombineSameForm[i].actual_from?.code ===
                  withCombineSameForm[j].actual_from?.code
              ) {
                resultSameForm.push(withCombineSameForm[j]);
                break;
              }
            }
          }

          if (
            (new_dataTable_object?.combine &&
              new_dataTable_object?.combine === resultSameForm[0]?.combine &&
              new_dataTable_object?.actual_from?.code ===
                resultSameForm[0]?.actual_from?.code &&
              new_dataTable_object?.trip === resultSameForm[0]?.trip) ||
            (new_dataTable_object?.combine &&
              new_dataTable_object?.combine === resultSameForm[1]?.combine &&
              new_dataTable_object?.actual_from?.code ===
                resultSameForm[1]?.actual_from?.code &&
              new_dataTable_object?.trip === resultSameForm[1]?.trip)
          ) {
            // if (firstSameFromCount > 0) {
            //   dataTable[index].receive_job_datetime =
            //     dataTable[index - 1]?.receive_job_datetime;
            // }

            // firstSameFromCount += 1;

            let findMaxYardOutTime = findMaxYardOutDatetime(
              new_dataTable,
              new_dataTable_object?.trip
            );
            dataTable[index].yardout_datetime = findMaxYardOutTime;
          }

          if (new_dataTable_object?.combine) {
            let findMaxEtaTime = findMaxEtaDatetime(
              new_dataTable,
              new_dataTable_object?.trip
            );
            dataTable[index].eta_datetime_edit = findMaxEtaTime;
          }
          new_dataTable[index].trip_no_original = index + 1;
        }
      }

      dataTableRef.current = new_dataTable;
      setDataTable(new_dataTable);
      let dataCheckReceiveJob = new_dataTable.filter(
        (d) => d.receive_job_datetime === 'Invalid date'
      ).length;
      if (dataCheckReceiveJob > 0) {
        warningSaveDraft.current = false;
        disableSaveDraftRef.current = false;
        disableBtnSaveRef.current = true;
        Modal.warning({
          title:
            'ไม่สามารถ save ได้ เนื่องจากไม่พบ return time ของ trip ก่อนหน้า',
          icon: <ExclamationCircleOutlined />,
          okText: 'OK',
        });
      } else {
        await onCheckPostData();
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const [
    deleteSaveDraft,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
      isLoading: isDeleting,
    },
  ] = useDeleteSaveDraftCreateTripMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      setTimeout(() => {
        window.location.reload();
      }, 100);
    }
  }, [isSuccessDeleting, isUninitDeleting]);

  const onBtnLoadSaveDraft = async () => {
    Modal.confirm({
      title: 'Do you want to load save draft?',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk: onLoadSaveDraft,
      cancelText: 'Cancel',
    });
  };
  const onBtnDeleteSaveDraft = async () => {
    Modal.confirm({
      title: 'Do you want to delete save draft?',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk: onDeleteSaveDraft,
      cancelText: 'Cancel',
    });
  };
  const onDeleteSaveDraft = () => {
    if (dataDraft) {
      let body = {
        id: formData.job_code,
        working_day:
          formData && moment(formData?.working_day).format('YYYY-MM-DD'),
      };
      deleteSaveDraft(body);
    }
  };

  const onLoadSaveDraft = async () => {
    try {
      if (dataDraft?.length > 0) {
        let shiftCalulate = null;
        let day = isTimeBetween(
          '07:30',
          '19:30',
          moment(dataDraft[0]?.receive_job_datetime).format('H:mm')
        );

        let night = isTimeBetween(
          '19:30',
          '07:30',
          moment(dataDraft[0]?.receive_job_datetime).format('H:mm')
        );

        if (day) {
          shiftCalulate = 'Day';
        } else if (night) {
          shiftCalulate = 'Night';
        }

        const now = moment(dataDraft[0]?.receive_job_datetime);
        const cutoff = moment(dataDraft[0]?.receive_job_datetime)
          .hour(7)
          .minute(30)
          .second(0);
        let day_ = now < cutoff ? now.add(-1, 'day') : now;
        const result = day_.format('YYYY-MM-DD');
        form.setFieldsValue({
          shift: shiftCalulate,
          working_day: day_,
        });
      }

      setDisabledDraftTable(true);
      showDeleteRef.current = true;
      disableBtnSaveRef.current = true;
      warningSaveDraft.current = false;
      dataTableRef.current = dataDraft;
      setDataTable(dataDraft);
    } catch (e) {
      console.log('e :>> ', e);
    }
  };

  const handleConfirmSaveAction = async () => {
    try {
      let value = form.getFieldsValue(true);
      let obj_planType = null;

      let arrBody = {
          category: 200,
          category_code: '02',
        };
        let planType = await getPlanType(arrBody);

      obj_planType =
          planType && planType.find((b) => b.name_en === 'Over Diagram');
      
      let mapDataTable = dataTableRef.current.map((d) => ({
        id: !d.id || d.id.length !== 16 ? null : d.id,
        callingsheet: d.callingsheet,
        trip_no: d.trip,
        trip_no_original: d.trip_no_original,
        receive_job_time: moment(d.receive_job_datetime).format(),
        location_from: d.actual_from ? d.actual_from : d.actual_from,
        location_destination: d.actual_destination
          ? d.actual_destination
          : null,
        cluster_to: {
          id: d.actual_cluster_to?.cluster
            ? d.actual_cluster_to?.cluster?.id
            : d.actual_cluster_to
            ? d.actual_cluster_to?.id
            : null,
        },
        yard_out_time: moment(d.yardout_datetime).format(),
        loading_unit: d.actual_loading_unit ? d.actual_loading_unit : null,
        trip_type: {
          id:
            d.trip_type && d.trip_type.id
              ? d.trip_type.id
              : d.tripTypeOptions.find((f) =>
                  f?.label === d?.trip_type ? d?.trip_type : null
                )?.value,
        },
        combine_code: d.combine,
        eta_time:
          d.eta_datetime_edit === 'none'
            ? null
            : d.eta_datetime_edit
            ? moment(d.eta_datetime_edit).format()
            : moment(d.eta_datetime).format(),

        return_place: dataLocationFromToRef.current.from.find(
          (f) => f.short_name === d.actual_return_place
        ),
        plan_type: d.plan_type ? d.plan_type : obj_planType,
        return_time:
          d.return_place_datetime_edit === 'none'
            ? null
            : d.return_place_datetime_edit
            ? moment(d.return_place_datetime_edit).format()
            : moment(d.return_place_datetime).format(),
      }));
      mapDataTable = Sorter.DEFAULTLISTDES(mapDataTable)
      let checkCase = null;
      if (dataTable.find((d) => d.combine)) {
        checkCase = 'combine';
      } else {
        checkCase = 'normal';
      }

      if (warningSaveDraft.current) {
        let bodySave = {
          job_code: value.job_code,
          shift: value.shift,
          working_day: formData?.working_day
            ? formData?.working_day
            : moment(value.working_day).format('YYYY-MM-DD'),
          company: value.bzp,
          trailer_type: value.trailer_type,
          case: checkCase,
          dispatch_boards: mapDataTable,
        };
        let resDataDraft = await createTripSaveDraft(bodySave);
        if (resDataDraft) {
          message.success('Data was successfully saved');
          setEditTableDisabled(true);
          disableBtnSaveRef.current = true;

          if (!id) {
            setTimeout(() => {
              dispatch(push(`/app/delivery_mng/lists`));
            }, 1000);
          } else {
            setTimeout(() => {
              window.location.reload();
            }, 100);
          }
        }
      } else {
        let bodySave = {
          job_code: value.job_code,
          shift: value.shift,

          working_day: moment(value.working_day).format('YYYY-MM-DD'),
          company: value.bzp,
          trailer_type: value.trailer_type,
          case: checkCase,
          dispatch_boards: mapDataTable,
        };

        let resData = await createTripSave(bodySave);
        if (resData) {
          message.success('Data was successfully saved');
          setEditTableDisabled(true);
          disableBtnSaveRef.current = true;

          if (!id) {
            setTimeout(() => {
              dispatch(
                push(
                  `/app/delivery_mng/create_trip/${resData?.first_dispatch_id}`
                )
              );
            }, 1000);
          } else {
            setTimeout(() => {
              dispatch(
                push(
                  `/app/delivery_mng/create_trip/${resData?.first_dispatch_id}`
                )
              );
              window.location.reload();
            }, 1000);
          }
        }
      }

      setIsModalConfirmVisible(false);
    } catch (error) {
      console.log('error', error);
    }
  };

  const onCheckDisabled = () => {
    for (const element of dataTable) {
      if (
        (!id && element.receive_job_datetime === undefined) ||
        (!id && !element.receive_job_datetime)
      ) {
        disableSaveDraftRef.current = true;
        disableBtnSaveRef.current = true;
        return true;
      }
      if (element.actual_from === undefined || !element.actual_from) {
        disableSaveDraftRef.current = true;
        disableBtnSaveRef.current = true;
        return true;
      }
      if (
        element.actual_cluster_to === undefined ||
        !element.actual_cluster_to
      ) {
        disableSaveDraftRef.current = true;
        disableBtnSaveRef.current = true;
        return true;
      }
      if (
        element.actual_destination === undefined ||
        !element.actual_destination
      ) {
        disableSaveDraftRef.current = true;
        disableBtnSaveRef.current = true;
        return true;
      }
      if (
        element.actual_loading_unit === undefined ||
        !element.actual_loading_unit
      ) {
        disableSaveDraftRef.current = true;
        disableBtnSaveRef.current = true;
        return true;
      }
      if (element.trip_type === undefined || !element.trip_type) {
        disableSaveDraftRef.current = true;
        disableBtnSaveRef.current = true;
        return true;
      }
    }
  };
  const onCheckPostData = async () => {
    try {
      let val = await form.validateFields();
      if (val) {
        let check = true;
        for (const element of dataTableRef.current) {
          if (!element.receive_job_datetime || !element.yardout_datetime) {
            check = false;
          }
        }

        if (check) {
          let workingDay = form.getFieldValue('working_day');
          let mapDataTable = dataTableRef.current.map((d) => ({
            trip_no_original: d.trip_no_original,
            id: d.id,
            trip_no: d.trip,
            working_day: workingDay ? workingDay.format('YYYY-MM-DD') : null,
            receive_job_time: moment(d.receive_job_datetime).format(),
            cluster_to: {
              id: d.actual_cluster_to?.cluster
                ? d.actual_cluster_to?.cluster?.id
                : d.actual_cluster_to
                ? d.actual_cluster_to?.id
                : null,
            },
            location_from: d?.actual_from,
            location_destination: d.actual_destination
              ? d.actual_destination
              : d.destination_farthest
              ? {
                  id: d.destination_farthest?.id,
                  code: d.destination_farthest?.code,
                }
              : null,
            yard_out_time: moment(d.yardout_datetime).format(),
            loading_unit: d.actual_loading_unit ? d.actual_loading_unit : null,
            trip_type: {
              id:
                d.trip_type && d.trip_type.id
                  ? d.trip_type.id
                  : d.tripTypeOptions.find((f) =>
                      f?.label === d?.trip_type ? d?.trip_type : null
                    )?.value,
            },
            combine_code: d.combine,
            eta_time:
              d.eta_datetime_edit === 'none'
                ? null
                : d.eta_datetime_edit
                ? moment(d.eta_datetime_edit).format()
                : moment(d.eta_datetime).format(),
            return_place: dataLocationFromToRef.current.from.find(
              (f) => f.short_name === d.actual_return_place
            ),
            plan_type: d.plan_type,
          }));
          let checkCase = null;
          if (dataTable.find((d) => d.combine)) {
            checkCase = 'combine';
          } else {
            checkCase = 'normal';
          }
          let bodyCheck = {
            company: val.bzp,
            case: checkCase,
            job_code:
              formData && formData.job_code ? formData.job_code : val.job_code,
            dispatch_boards: mapDataTable,
            trailer_type: formData
              ? formData.trailer_type.id
              : form.getFieldValue('trailer_type'),
          };
          await checkButton(bodyCheck);
        }
      } else {
        requiredFormRef.current = true;
      }
    } catch (e) {
      requiredFormRef.current = 'true';
      console.log('e', e);
    }
  };

  useEffect(() => {
    if (formData && formData.bzp && formData.bzp && formData.bzp.id) {
      onChangeOptionBzp(formData.bzp.id);
    }

    if (formData) {
      setIsLoading(true);
      let resData =
        formData &&
        formData.dispatch_boards.map((d, i) => ({
          ...d,
          index: i,
          trip_no_before: d.trip_no,
          receive_job_datetime_before: d.receive_job_datetime,
          actual_from_before: d?.actual_from,
          yardout_datetime_before: d.yardout_datetime,
          actual_cluster_to_before: d.actual_cluster_to,
          actual_destination_before: d.actual_destination,
          actual_loading_unit_before: d.actual_loading_unit,
          trip_type_before: d.trip_type,
          combine_code_before: d.combine,
          eta_datetime_before: d.eta_datetime,
          actual_return_place_before: d.actual_return_place,
          return_place_datetime_before: d.return_place_datetime,
          plan_type_before: d.plan_type,
        }));

      let body = {
        id: formData.job_code,
        working_day:
          formData && moment(formData?.working_day).format('YYYY-MM-DD'),
      };
      setDataTable(resData);
      getDraft(body);
      setIsLoading(false);
    }
  }, [formData]);

  const onAddTripBefore = (cell, dataSource, newRow, text) => {
    disableBtnSaveRef.current = true;
    addTripBefore.current = text;
    getDataLoationAll(dataLocationFromTo, cell, dataSource, newRow, text);
  };

  const onAddTripAfter = (cell, dataSource, newRow, text) => {
    if (cell.return_place_datetime) {
      disableBtnSaveRef.current = true;
      addTripAfter.current = text;
      getDataLoationAll(dataLocationFromTo, cell, dataSource, newRow, text);
    } else {
      Modal.warning({
        title:
          'ไม่สามารถเพิ่ม next trip ได้ เนื่องจากไม่พบ return time ของ trip ปัจจุบัน',
        icon: <ExclamationCircleOutlined />,
        okText: 'OK',
        onOk: confirmCancelCombine,
        cancelText: 'Cancel',
      });
    }
  };

  const confirmCombine = async () => {
    warningSaveDraft.current = false;
    combineRef.current = true;
    let genarateCombineCode = await createGenarateCombineCode();

    const combine = [...dataTable];
    for (let index = 0; index < combine.length; index++) {
      for (let index2 = 0; index2 < selectedRowsData.length; index2++) {
        if (combine[index].trip === selectedRowsData[index2].trip) {
          combine[index].trip = 1;
          combine[index].trip_no_original = index + 1;
          combine[index].combine = genarateCombineCode;
        }
      }
    }

    let newCombine = combine.filter((d) => d.combine);
    let objTrip = [];
    let objNewCombineFrom = [];
    let objNewCombineTo = [];
    let objNewCombineReceiveTime = [];
    for (let index = 0; index < newCombine.length; index++) {
      objTrip.push(newCombine[index].trip_no);
      objNewCombineFrom.push(newCombine[index]?.actual_from?.short_name);
      objNewCombineTo.push(newCombine[index].actual_cluster_to?.name);
      objNewCombineReceiveTime.push(
        timeFormatter({
          value: newCombine[index].receive_job_datetime,
        })
      );
    }
    let displayCombine = {
      trip: [...new Set(objTrip)].toString(),
      from: objNewCombineFrom.toString(),
      to: objNewCombineTo.toString(),
      receive_job_datetime: objNewCombineReceiveTime.toString(),
    };

    combineData.current = displayCombine;
    disableSaveDraftRef.current = true;
    disableBtnSaveRef.current = true;
    setDataTable(combine);
  };
  const confirmCancelCombine = () => {
    warningSaveDraft.current = false;
    combineData.current = null;
    combineRef.current = false;
    const combine = [...dataTable];
    for (let index2 = 0; index2 < selectedRowsData.length; index2++) {
      let valueCombine1 = selectedRowsData[index2]?.combine;
      for (let index = 0; index < dataTable.length; index++) {
        let valueCombine2 = combine[index]?.combine;
        if (valueCombine1 === valueCombine2) {
          combine[index].trip = index + 1;
          combine[index].trip_no_original = index + 1;
          combine[index].combine = null;
          combine[index].first_combine = false;
        }
      }
    }

    dataTableRef.current = combine;
    disableSaveDraftRef.current = true;
    disableBtnSaveRef.current = true;
    setDataTable(combine);
    setSelectedRowsData([]);
  };
  const popupCombine = () => {
    Modal.confirm({
      title: 'Confirm Combine Trip',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk: confirmCombine,
      cancelText: 'Cancel',
    });
  };
  const cancelCombine = () => {
    Modal.confirm({
      title: 'Cancel Combine Trip',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk: confirmCancelCombine,
      cancelText: 'Cancel',
    });
  };
  const onSaveModal = () => {
    let objTrip = [];
    let objNewFrom = [];
    let objNewTo = [];
    let objNewReceiveTime = [];
    for (let index = 0; index < dataTable.length; index++) {
      objTrip.push(dataTable[index].trip_no);
      objNewFrom.push(dataTable[index]?.actual_from?.short_name);
      objNewTo.push(
        dataTable[index].actual_cluster_to.cluster
          ? dataTable[index].actual_cluster_to?.cluster?.name
          : dataTable[index].actual_cluster_to?.name
      );
      objNewReceiveTime.push(
        timeFormatter({
          value: dataTable[index].receive_job_datetime,
        })
      );
    }

    let display = {
      trip: objTrip.toString(),
      from: objNewFrom.toString(),
      to: objNewTo.toString(),
      receive_job_datetime: objNewReceiveTime.toString(),
    };

    continueRef.current = display;
    setIsModalConfirmVisible(true);
  };

  const checkSelectdRowsData = (dataTable) => {
    // if (selectedRowsData) {
    let objCombineCheck = true;
    for (let index = 0; index < dataTable.length; index++) {
      if (dataTable[index]?.combine && dataTable.length >= 2) {
        objCombineCheck = false;
      }
    }
    return objCombineCheck;
  };

  return (
    <>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{ padding: '10px' }}
        autoComplete='off'
      >
        <CardStyleForm>
          <Row>
            <Col span={23}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label='Job Code'
                    name='job_code'
                    initialValue={formData && formData.job_code}
                  >
                    <Input
                      disabled
                      size='small'
                      placeholder={'(auto fill)'}
                      ref={trailerInputRef}
                      style={{ color: '#000' }}
                    />
                    {/* )} */}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='BzP'
                    name='bzp'
                    initialValue={
                      formData &&
                      formData.bzp &&
                      formData.bzp &&
                      formData.bzp.job_name
                        ? formData.bzp.id
                        : []
                    }
                    rules={[
                      {
                        required: formData ? false : true,
                        message: 'Please select bzp',
                      },
                    ]}
                  >
                    <Select
                      disabled={formData || isLoading}
                      {...selectProps}
                      placeholder={'BzP'}
                      onSelect={(value) => {
                        form.setFieldsValue({
                          trailer_type: [],
                        });
                        setFilterTrailerType(null);
                        onChangeOptionBzp(value);
                      }}
                      onClear={() => {
                        setTrailerTypeOptions([]);
                        form.setFieldsValue({
                          trailer_type: [],
                        });

                        let arrTable = {
                          disableFormRow: true,
                        };
                        updateDataTable(arrTable);
                      }}
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {bzpOptions &&
                        bzpOptions.map((c, index) => (
                          <Option key={index} value={c.value}>
                            {c.label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Trailer Type'
                    name='trailer_type'
                    initialValue={
                      formData &&
                      formData.trailer_type &&
                      formData.trailer_type.id
                        ? formData.trailer_type.id
                        : filterTrailerType
                    }
                    rules={[
                      {
                        required: formData ? false : true,
                        message: 'Please select trailer type',
                      },
                    ]}
                  >
                    <Select
                      disabled={formData || isLoading}
                      {...selectProps}
                      placeholder={'Trailer Type'}
                      loading={isLoading}
                      onSelect={(value) => {
                        onChangeOptionTrailerType(value);
                        let arrTable = null;
                        let val = trailerTypeOptions.find(
                          (t) => t.value === value
                        );
                        arrTable = {
                          disableFormRow: false,
                          actual_loading_unit: val?.maximum_loading,
                        };
                        updateDataTable(arrTable);
                      }}
                      onClear={() => {
                        let arrTable = {
                          disableFormRow: true,
                        };
                        updateDataTable(arrTable);
                      }}
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                    >
                      {trailerTypeOptions.map((c, index) => (
                        <Option key={index} value={c.value}>
                          {c.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    label='Working Date'
                    name='working_day'
                    initialValue={formData && moment(formData?.working_day)}
                  >
                    <DatePicker
                      placeholder={'(auto fill)'}
                      disabled
                      style={{
                        textAlign: 'center',
                        width: '100%',
                        color: '#000',
                      }}
                      size='small'
                      showTime
                      format={'DD/MM/YY'}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Shift'
                    name='shift'
                    initialValue={formData && formData.shift}
                  >
                    <Input
                      size='small'
                      style={{ color: '#000' }}
                      placeholder={'(auto fill)'}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </CardStyleForm>
      </Form>

      <Row style={{ margin: 10, marginBottom: 15 }}>
        <Col flex={2}>
          <Space size={25}>
            {selectedRowsData.length >= 2 &&
            checkSelectdRowsData(selectedRowsData) ? (
              <Button
                style={{ width: 100 }}
                type='secondary'
                onClick={() => popupCombine()}
                disabled={
                  (combineData.current &&
                    selectedRowsData.find((d) => d.combine)) ||
                  isLoading
                }
              >
                Combine
              </Button>
            ) : null}
            {selectedRowsData.find((d) => d.combine) ? (
              <Button
                disabled={isLoading}
                type='danger'
                onClick={() => cancelCombine()}
              >
                Cancel combine
              </Button>
            ) : null}
          </Space>
        </Col>

        <Col flex={2} style={{ textAlign: 'right' }}>
          <Space size={25}>
            {id ? (
              <Button
                style={{ width: 100 }}
                disabled={
                  disabledDraftTable ||
                  addTripAfter.current ||
                  addTripBefore.current ||
                  (dataDraft && dataDraft.length) ||
                  isLoading
                }
                loading={isLoadingLocationFromTo}
                type='secondary'
                onClick={onEditTable}
              >
                Edit{' '}
              </Button>
            ) : null}

            <Button
              style={{ width: 100 }}
              type='primary'
              onClick={() => onBtnCheck()}
              loading={isLoading || isLoadingLocationFromTo}
              disabled={
                onCheckDisabled() ||
                disabledDraftTable ||
                (dataDraft && dataDraft.length)
              }
            >
              Check
            </Button>

            {dataDraft && dataDraft.length && !showDeleteRef.current ? (
              <Button
                loading={isLoading}
                disabled={isLoading}
                type='secondary'
                onClick={() => onBtnLoadSaveDraft()}
              >
                Load Save Draft
              </Button>
            ) : showDeleteRef.current ? (
              <Button
                type='primary'
                danger
                onClick={() => onBtnDeleteSaveDraft()}
                disabled={isLoading}
              >
                Delete Draft
              </Button>
            ) : null}
            {warningSaveDraft.current ? (
              <Button
                style={{ width: 100 }}
                type='secondary'
                onClick={onSaveModal}
                disabled={isLoading || onCheckDisabled()}
              >
                Save draft
              </Button>
            ) : (
              <Button
                style={{ width: 100 }}
                disabled={
                  onCheckDisabled() || disableBtnSaveRef.current || isLoading
                }
                type='secondary'
                onClick={onSaveModal}
              >
                Save
              </Button>
            )}
          </Space>
        </Col>
      </Row>

      <Table
        dataDraft={dataDraft}
        editTableDisabled={editTableDisabled}
        setSelectedRowsData={setSelectedRowsData}
        selectedRowsData={selectedRowsData}
        addTripAfter={onAddTripAfter}
        addTripBefore={onAddTripBefore}
        requiredForm={requiredFormRef.current}
        destinationField={onDestinationField}
        dropdownClusterOptions={onDropdownClusterOptions}
        dropdownDestinationOptions={onDropdownDestinationOptions}
        resetDestinationOption={onResetDestinationOption}
        resetClustrOption={onResetResetClustrOption}
        tripTypeField={tripTypeField}
        disabledDraftTable={disabledDraftTable}
        clusterField={onClusterField}
        fromField={onFromField}
        receiveJobTimeField={onReceiveJobTimeField}
        loadingUnitField={onLoadingUnitField}
        rowData={dataTable}
        isLoading={isLoading}
        dataLocationFromToRef={dataLocationFromToRef}
      />

      <AntModal
        visible={isModalConfirmVisible}
        onCancel={() => {
          setIsModalConfirmVisible(false);
        }}
        onOk={handleConfirmSaveAction}
        titleModal={
          <Text>
            {' '}
            {warningSaveDraft.current
              ? 'Final confirm for saving draft trip.'
              : combineRef.current
              ? 'Final confirm for saving the combine trip.'
              : addTripAfter.current || addTripBefore.current
              ? 'Final confirm for saving the add trip.'
              : 'Final confirm for saving the new trip.'}
          </Text>
        }
        centered
        width={500}
        style={{ textAlign: 'center' }}
      >
        {dataTableRef.current && (
          <>
            <Row align='center'>
              <div style={{ textAlign: 'left' }}>
                Job code:{' '}
                {!id
                  ? dataTableRef.current[0]?.job_code
                  : formData && formData.job_code
                  ? formData.job_code
                  : null}
                <br />
                Trip No.:{' '}
                {continueRef.current ? continueRef.current?.trip : null}
                <br />
                From: {continueRef.current ? continueRef.current?.from : null}
                <br />
                To: {continueRef.current ? continueRef.current?.to : null}
                <br />
                Receive Job Time:{' '}
                {continueRef.current
                  ? continueRef.current?.receive_job_datetime
                  : null}
                <br />
              </div>
            </Row>
          </>
        )}
      </AntModal>
    </>
  );
};

const CardStyleForm = styled(Card)`
  .ant-card-body {
    padding-bottom: 0px;
  }
  .ant-picker-input > input[disabled] {
    color: #000;
  }
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    color: #000;
  }
`;

export default CreateTripFormComponent;
