import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { DatePicker, Select, message } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import { useParams } from 'react-router-dom';
import '../index.css';
import moment from 'moment';
const { Option } = Select;
const TableComponent = (props) => {
  const { id } = useParams();
  const {
    dataDraft,
    editTableDisabled,
    disabledDraftTable,
    rowData,
    isLoading,
    fromField,
    receiveJobTimeField,
    clusterField,
    tripTypeField,
    destinationField,
    loadingUnitField,
    resetDestinationOption,
    resetClustrOption,
    addTripAfter,
    addTripBefore,
    setSelectedRowsData,
    selectedRowsData,
    dropdownClusterOptions,
    dropdownDestinationOptions,
  } = props;
  const editingRecordRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const { height } = useWindowSize();
  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const rowDataLengthRef = useRef(0);
  const dataSourceRef = useRef([]);

  useEffect(() => {
    rowDataLengthRef.current = rowData && rowData.length;
    dataSourceRef.current = rowData;
    setDataSource(rowData);
  }, [rowData]);

  useEffect(() => {
    if (selectedRowsData.length === 0) {
      setSelectedRowKeys([]);
    }
  }, [selectedRowsData]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      if (selectedRows) {
        setSelectedRowsData(selectedRows);
      }
    },
  };

  function capFirst(str) {
    return str[0].toUpperCase() + str.slice(1);
  }

  const selectProps = {
    style: {
      width: '120px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
  };

  const columns = [
    {
      title: 'Trip no.',
      dataIndex: 'trip_no',
      align: 'center',
      width: 30,
      className: 'bg-grey',
      render: (text, record) => {
        return (
          <div
            style={{
              color:
                !record.newTrip &&
                record.action_check &&
                text !== record.trip_no_before
                  ? 'red'
                  : null,
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: 'Receive Job Time',
      dataIndex: 'receive_job_datetime',
      width: 140,
      align: 'center',
      render: (text, record, index) => {
        if (
          (index === 0 && record.check_window_time === true) ||
          (record.inputData === true && !id && index === 0) ||
          (record?.receive_job_add_trip_before === true && index === 0) ||
          (record.inputData === true && record.editDiagramOwner && index === 0)
        ) {
          return (
            <DatePicker
              className={
                !record.newTrip &&
                record.action_check &&
                moment(record.receive_job_datetime).format() !==
                  record.receive_job_datetime_before
                  ? 'date-picker-text-color'
                  : null
              }
              value={
                record && record.receive_job_datetime
                  ? moment(record && record.receive_job_datetime)
                  : null
              }
              disabled={
                (record.btnEdit && !record.is_own_job) || record.disableFormRow
              }
              loading={isLoading}
              size='small'
              showTime
              format={'DD/MM/YY HH:mm'}
              onChange={(value) => {
                receiveJobTimeField(
                  value
                    ? moment(value.seconds(0).milliseconds(0).toISOString())
                    : null,
                  record.id,
                  record.check_window_time
                );
              }}
            />
          );
        } else {
          return (
            <div
              style={{
                color:
                  !record.newTrip &&
                  record.action_check &&
                  moment(record.receive_job_datetime).format() !==
                    record.receive_job_datetime_before
                    ? 'red'
                    : null,
              }}
            >
              {text !== 'Invalid date'
                ? dateTimeTimestamptzFormatter({ value: text })
                : null}
            </div>
          );
        }
      },
    },
    {
      title: 'From',
      dataIndex: 'actual_from',
      width: 200,
      align: 'center',
      render: (text, record, index) => {
        if (record.inputData === true || (record.inputData === true && !id)) {
          return (
            <Select
              defaultValue={record?.actual_from?.code}
              disabled={
                (record.btnEdit && !record.is_own_job) || record.disableFormRow
              }
              {...selectProps}
              style={{
                color:
                  !record.newTrip &&
                  record.action_check &&
                  record.actual_from?.code !== record.actual_from_before?.code
                    ? 'red'
                    : null,
                width: '200px',
              }}
              size='small'
              loading={isLoading}
              placeholder={'Select a from'}
              onChange={(values) => {
                console.log('record.id :>> ', record.id);
                fromField(values, record.id, record);
              }}
              showSearch
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {record.locationFromToOptions.map((c, index) => (
                <Option key={index} value={c.value}>
                  {c.label}
                </Option>
              ))}
            </Select>
          );
        } else {
          return (
            <div
              style={{
                color:
                  !record.newTrip &&
                  record.action_check &&
                  record.actual_from?.code !== record.actual_from_before?.code
                    ? 'red'
                    : null,
              }}
            >
              {record.actual_from && record.actual_from.short_name}
            </div>
          );
        }
      },
    },
    {
      title: 'Yard out time',
      dataIndex: 'yardout_datetime',
      width: 110,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return (
          <div
            style={{
              color:
                !record.newTrip &&
                record.action_check &&
                record.yardout_datetime !== record.yardout_datetime_before
                  ? 'red'
                  : null,
            }}
          >
            {record?.yardout_datetime !== 'Invalid date'
              ? dateTimeTimestamptzFormatter({ value: text })
              : null}
          </div>
        );
      },
    },
    {
      title: 'Destination',
      children: [
        {
          title: 'Cluster',
          dataIndex: ['actual_cluster_to', 'name'],
          width: 80,
          align: 'center',
          ellipsis: true,
          className: ['bg-cluster'],
          render: (text, record) => {
            let txt = record.actual_cluster_to?.cluster
              ? record.actual_cluster_to?.cluster?.name
              : text
              ? text
              : undefined;

            if (record.inputData === true) {
              return {
                props: {
                  style: {
                    borderRight: '1px solid #bfbfbf',
                  },
                },
                children: (
                  <Select
                    value={
                      record.actual_cluster_to?.cluster
                        ? record.actual_cluster_to?.cluster?.name
                        : text
                        ? text
                        : undefined
                    }
                    disabled={
                      (record.btnEdit && !record.is_own_job) ||
                      record.disableFormRow
                    }
                    {...selectProps}
                    style={{
                      color:
                        !record.newTrip &&
                        record.action_check &&
                        txt !== record.actual_cluster_to_before?.name
                          ? 'red'
                          : null,
                      width: '80px',
                    }}
                    loading={isLoading}
                    size='small'
                    placeholder={'Select a cluster'}
                    onSelect={(cluster) => {
                      clusterField(cluster, record.id);
                    }}
                    onClear={() => {
                      resetDestinationOption(record.id);
                    }}
                    onDropdownVisibleChange={() => {
                      dropdownClusterOptions(record.id, record);
                    }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option?.value
                        .toLowerCase()
                        .includes(input?.toLowerCase());
                    }}
                  >
                    {record?.locationClusterOptions.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                ),
              };
            } else {
              return {
                props: {
                  style: {
                    borderRight: '1px solid #bfbfbf',
                  },
                },
                children: (
                  <div
                    style={{
                      color:
                        !record.newTrip &&
                        record.action_check &&
                        txt !== record.actual_cluster_to_before?.name
                          ? 'red'
                          : null,
                    }}
                  >
                    {text}
                  </div>
                ),
              };
            }
          },
        },
        {
          title: 'Destination',
          dataIndex: ['actual_destination', 'code'],
          width: 200,
          align: 'center',
          ellipsis: true,
          className: ['bg-destination'],
          render: (text, record) => {
            if (record.inputData === true) {
              return (
                <Select
                  value={text ? text : undefined}
                  disabled={
                    (record.btnEdit && !record.is_own_job) ||
                    record.disableFormRow
                  }
                  {...selectProps}
                  style={{
                    color:
                      !record.newTrip &&
                      record.action_check &&
                      text !== record.actual_destination_before?.code
                        ? 'red'
                        : null,
                    width: '180px',
                  }}
                  size='small'
                  loading={isLoading}
                  placeholder={'Select a destination'}
                  onSelect={(actual_destination) => {
                    destinationField(actual_destination, record.id);
                  }}
                  onClear={() => {
                    resetClustrOption(record.id);
                  }}
                  onDropdownVisibleChange={() => {
                    dropdownDestinationOptions(record.id, record);
                  }}
                  showSearch
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {record.locationDestinationOptions &&
                    record.locationDestinationOptions.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                </Select>
              );
            } else {
              return (
                <div
                  style={{
                    color:
                      !record.newTrip &&
                      record.action_check &&
                      text !== record.actual_destination_before?.code
                        ? 'red'
                        : null,
                  }}
                >
                  {record.actual_destination &&
                    record.actual_destination.short_name}
                </div>
              );
            }
          },
        },
      ],
    },
    {
      title: () => {
        return (
          <>
            Loading
            <br />
            Unit
          </>
        );
      },
      dataIndex: 'actual_loading_unit',
      width: 20,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (record.inputData === true) {
          return (
            <Select
              value={text ? text : undefined}
              disabled={
                (record.btnEdit && !record.is_own_job) || record.disableFormRow
              }
              {...selectProps}
              style={{
                color:
                  !record.newTrip &&
                  record.action_check &&
                  record.actual_loading_unit_before !==
                    record.actual_loading_unit
                    ? 'red'
                    : null,
                width: '50px',
              }}
              size='small'
              placeholder={'Select a loading unit'}
              onChange={(value) => {
                loadingUnitField(value, record.id);
              }}
              loading={isLoading}
              allowClear
              showSearch
            >
              {record?.loadingUnitOptions?.map((c, index) => (
                <Option key={index} value={c.value}>
                  {c.label}
                </Option>
              ))}
            </Select>
          );
        } else {
          return (
            <div
              style={{
                color:
                  !record.newTrip &&
                  record.action_check &&
                  record.actual_loading_unit_before !==
                    record.actual_loading_unit
                    ? 'red'
                    : null,
              }}
            >
              {text}
            </div>
          );
        }
      },
    },
    {
      title: 'Trip Type',
      dataIndex: 'trip_type',
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        let txt = record.trip_type?.name_en
          ? record.trip_type?.name_en
          : text
          ? text
          : null;

        if (record.inputData === true) {
          return (
            <Select
              defaultValue={
                record?.trip_type?.name_en
                  ? record?.trip_type?.name_en
                  : 'Normal'
              }
              disabled={
                (record.btnEdit && !record.is_own_job) || record.disableFormRow
              }
              {...selectProps}
              style={{
                color:
                  !record.newTrip &&
                  record.action_check &&
                  txt !== record.trip_type_before?.name_en
                    ? 'red'
                    : null,
                width: '120px',
              }}
              size='small'
              placeholder={'Select a trip type'}
              onChange={(text) => {
                tripTypeField(text, record.id, record.tripTypeOptions);
              }}
              loading={isLoading}
              allowClear
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {record?.tripTypeOptions?.map((c, index) => (
                <Option key={index} value={c.label}>
                  {c.label}
                </Option>
              ))}
            </Select>
          );
        } else {
          return (
            <div
              style={{
                color:
                  !record.newTrip && txt !== record.trip_type_before?.name_en
                    ? 'red'
                    : null,
              }}
            >
              {record.trip_type && record.trip_type.name_en
                ? capFirst(record.trip_type.name_en)
                : null}
            </div>
          );
        }
      },
    },
    {
      title: 'Combine Code',
      dataIndex: 'combine_code',
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        let combine_new = record.combine ? record.combine : text;
        return (
          <div
            style={{
              color:
                !record.newTrip &&
                record.action_check &&
                combine_new !== record.combine_code_before
                  ? 'red'
                  : null,
            }}
          >
            {record.combine ? record.combine : text}
          </div>
        );
      },
    },
    {
      title: 'ETA Time',
      dataIndex: 'eta_datetime',
      width: 110,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        let txt =
          record.eta_datetime_edit === 'none'
            ? null
            : record?.eta_datetime_edit
            ? moment(record?.eta_datetime_edit).format()
            : text
            ? moment(text).format()
            : null;
        return (
          <div
            style={{
              color:
                !record.newTrip &&
                record.action_check &&
                txt !== record.eta_datetime_before
                  ? 'red'
                  : null,
            }}
          >
            {record.eta_datetime_edit === 'none'
              ? null
              : record?.eta_datetime_edit
              ? dateTimeTimestamptzFormatter({
                  value: record?.eta_datetime_edit,
                })
              : text
              ? dateTimeTimestamptzFormatter({ value: text })
              : null}
          </div>
        );
      },
    },
    {
      title: 'Return Place',
      dataIndex: 'actual_return_place',
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return (
          <div
            style={{
              color:
                !record.newTrip &&
                record.action_check &&
                text !== record.actual_return_place_before
                  ? 'red'
                  : null,
            }}
          >
            {text}
          </div>
        );
      },
    },
    {
      title: 'Return Time',
      dataIndex: 'return_place_datetime',
      width: 110,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        let txt =
          record.return_place_datetime_edit === 'none'
            ? null
            : record?.return_place_datetime_edit
            ? moment(record?.return_place_datetime_edit).format()
            : text
            ? moment(text).format()
            : null;

        return (
          <div
            style={{
              color:
                !record.newTrip &&
                record.action_check &&
                txt !== record.return_place_datetime_before
                  ? 'red'
                  : null,
            }}
          >
            {record.return_place_datetime_edit === 'none'
              ? null
              : record?.return_place_datetime_edit
              ? dateTimeTimestamptzFormatter({
                  value: record?.return_place_datetime_edit,
                })
              : text
              ? dateTimeTimestamptzFormatter({ value: text })
              : null}
          </div>
        );
      },
    },
    {
      title: 'Plan Type',
      dataIndex: ['plan_type', 'name_en'],
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return (
          <div
            style={{
              color:
                !record.newTrip &&
                record.action_check &&
                text !== record.plan_type_before?.name_en
                  ? 'red'
                  : null,
            }}
          >
            {text ? capFirst(text) : null}{' '}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  const handleClicked = (cell, dataSource, text) => {
    try {
      if (text === 'after') {
        let findDataKey = dataSource.find((d) => d.key);
        if (findDataKey) {
          message.error('Please enter valid input and save data');
        } else {
          editingRecordRef.current = {
            ...cell,
          };
          addTripAfter(cell, dataSource, true, 'after');
        }
      } else {
        let findDataKey = dataSource.find((d) => d.key);
        if (findDataKey) {
          message.error('Please enter valid input and save data');
        } else {
          editingRecordRef.current = {
            ...cell,
          };
          addTripBefore(cell, dataSource, true, 'before');
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <StyledTable
        rowKey='id'
        tableId={'newCreateTrip'}
        className={'createTrip'}
        rowSelection={{
          hideSelectAll: true,
          ...rowSelection,
          getCheckboxProps: (record) => {
            if (
              (record.disableFormRow && !id) ||
              disabledDraftTable ||
              (dataDraft && dataDraft.length)
            ) {
              return {
                disabled: true,
              };
            }
          },
        }}
        loading={isLoading}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        height={tableHeight}
        scroll={{
          // y: tableHeight,
          x: 'max-content',
        }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={
          id && !disabledDraftTable && editTableDisabled
            ? [
                {
                  label: 'Add trip before',
                  action: (row) => handleClicked(row, dataSource, 'before'),
                },
                {
                  label: 'Add trip after',
                  action: (row) => handleClicked(row, dataSource, 'after'),
                },
              ]
            : []
        }
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body tr td {
      font-size: 14px;
    }
    .disabled-row {
      background-color: #000;
    }
    .ant-table-selection-column {
      border-left: 1px solid #000;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td {
      border-right: 1px solid #000;
      // border-left: 1px solid #000;
      border-bottom: 1px solid #000;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th {
      border-top: 1px solid #000;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr:not(:last-child)
      > th {
      border-bottom: 1px solid #000;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th {
      border-bottom: 1px solid #000;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr:not(:last-child)
      > th {
      border-right: 1px solid #000;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th {
      border-right: 1px solid #000;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > td {
      border-right: 1px solid #000;
    }
  }
`;
export default TableComponent;
