/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import TableToolbar from './Toolbar';
import TableEdit from './TableEdit';
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from 'hooks/windowSize';
import { changeReasonLaneApi } from 'reducers/changeReasonLane/api';
import {
  useFindAllTrailerLanesQuery,
  useFindTrailerLaneByIdQuery,
  useUpdateTrailerLaneMutation,
  useUpdateTrailerLaneReceiveJobsMutation,
  trailerApi,
} from 'reducers/masterData/trailer/api';
import styled from 'styled-components';
import { message, Modal } from 'antd';
import FormReason from './FormReason';
import { useErrorHandler } from 'common_components/ErrorContext';
import { getLanguage } from 'reducers/language';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const LIMIT = 100;

const CallingSheetTableComponent = (props) => {
  const language = useSelector(getLanguage);
  const [isLoading, setIsLoading] = useState(false);
  const dataSourceEditRef = useRef();
  const trailerLaneFromOptionsRef = useRef();
  const trailerLaneDestinationOptionsRef = useRef();
  const trailerLaneOptionsAllRef = useRef();
  const dataTrailerLaneIdRef = useRef();
  const modeReasonRef = useRef();
  const reasonLastIdRef = useRef();
  const callingRef = useRef();
  const [showFilter, setShowFilter] = useState(true);
  const { setErrorData } = useErrorHandler();
  const [formData, setFormData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [actualFrom, setActualFrom] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [dataSourceEdit, setDataSourceEdit] = useState([
    {
      id: 1,
      reasonTrailerLane: 'Reason',
    },
    {
      id: 2,
      reasonTrailerLane: 'Trailer lane',
    },
  ]);
  const [tableHeight, setTableHeight] = useState(0);
  const [selectedLane, setSelectedLane] = useState(null);
  const [selectedLaneDestiation, setSelectedLaneDestiation] = useState(null);
  const [laneId, setLaneId] = useState(null);
  const dispatch = useDispatch();
  const { height } = useWindowSize();

  useEffect(() => {
    if (height) {
      let h = height - 360;
      if (!showFilter) {
        h = h + 100;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  const { data: dataTrailerLaneId, refetch: refetchTrailerLaneId } =
    useFindTrailerLaneByIdQuery(laneId, {
      skip: !laneId,
    });

  useEffect(() => {
    if (dataTrailerLaneId) {
      dataTrailerLaneIdRef.current = dataTrailerLaneId;
    }
  }, [dataTrailerLaneId]);

  const apiLaneByCode = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          trailerApi.endpoints.findTrailerLaneByCode.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          resData = result.error;
        } else {
          if (result.data) {
            let mapData = result.data.map((d) => ({
              id: d.id,
              label: d.lane_no,
              value: d.lane_no,
              yard: d.yard.id,
              amount: d.amount,
              used: d.used,
              lane_no: d.lane_no,
              is_available: d.is_available,
            }));

            resData = mapData;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const { data: dataTrailerLaneAll, refetch: refetchTrailerLaneAll } =
    useFindAllTrailerLanesQuery({
      yard: actualFrom,
    });

  useEffect(() => {
    if (dataTrailerLaneAll) {
      let mapData = dataTrailerLaneAll.map((d) => ({
        id: d.id,
        label: d.lane_no,
        value: d.lane_no,
        yard: d.yard.id,
        amount: d.amount,
        used: d.used,
        lane_no: d.lane_no,
        is_available: d.is_available,
      }));
      trailerLaneOptionsAllRef.current = mapData;
    }
  }, [dataTrailerLaneAll]);

  const onQuery = async (value) => {
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.findStatusChangeByCallingNo.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          clearAll();
          Modal.error({
            title: 'Not found data for calling no.',
            icon: <ExclamationCircleOutlined />,
          });
        } else {
          if (result.data) {
            let receive_job = null;
            let actual_arrival = null;
            if (
              result.data.reason_and_lane?.actual_receive_job_datetime &&
              result.data.reason_and_lane?.actual_arrival_time
            ) {
              receive_job = await apiLaneByCode(
                result.data.reason_and_lane?.actual_from?.code
              );
              actual_arrival = await apiLaneByCode(
                result.data.reason_and_lane?.actual_destination?.code
              );
            } else if (
              result.data.reason_and_lane?.actual_receive_job_datetime
            ) {
              receive_job = await apiLaneByCode(
                result.data.reason_and_lane?.actual_from?.code
              );
            } else if (result.data.reason_and_lane?.actual_arrival_time) {
              receive_job = await apiLaneByCode(
                result.data.reason_and_lane?.actual_destination?.code
              );
            }
            // trailerLaneOptionsRef.current =
            trailerLaneFromOptionsRef.current = receive_job;
            trailerLaneDestinationOptionsRef.current = actual_arrival;
            let dataEdit = [
              {
                id: 1,
                reasonTrailerLane: 'Reason',
                reason_receive_job: result.data.reason_and_lane
                  ? result.data.reason_and_lane.reason_receive_job
                  : null,
                reason_yard_out: result.data.reason_and_lane
                  ? result.data.reason_and_lane.reason_yard_out
                  : null,
                reason_arrive: result.data.reason_and_lane
                  ? result.data.reason_and_lane.reason_arrive
                  : null,
                reason_arrive_from_shuttle_in: result.data.reason_and_lane
                  ? result.data.reason_and_lane.reason_arrive_from_shuttle_in
                  : null,
              },
              {
                id: 2,
                actual_receive_job_datetime:
                  result.data?.reason_and_lane?.actual_receive_job_datetime,
                actual_arrival_time:
                  result.data?.reason_and_lane?.actual_arrival_time,
                reasonTrailerLane: 'Trailer lane',
                trailer_lane_receive_job: result.data.reason_and_lane
                  ? result.data.reason_and_lane.trailer_lane_receive_job
                  : null,
                trailerLaneFromOptions: receive_job ? [...receive_job] : null,
                trailerLaneDestinationOptions: actual_arrival
                  ? [...actual_arrival]
                  : null,
                shuttle_in_id: result.data?.reason_and_lane?.shuttle_in_id,
                trailer_lane_arrive: result.data.reason_and_lane
                  ? result.data.reason_and_lane.trailer_lane_arrive
                  : null,
              },
            ];
            callingRef.current = value;
            reasonLastIdRef.current = result.data.reason_and_lane;
            setActualFrom(result.data.reason_and_lane.actual_from.id);
            dataSourceEditRef.current = dataEdit;
            setDataSourceEdit(dataEdit);

            setDataSource([result.data.reason_and_lane]);
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };
  const onQueryDefault = async (value) => {
    let resultNew = null;
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.findStatusChangeByCallingNo.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        } else {
          if (result.data) {
            resultNew =
              result.data.reason_and_lane &&
              result.data.reason_and_lane.trailer_lane_receive_job?.id;
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
    return resultNew;
  };

  const onCheckSave = async (data) => {
    setIsLoading(true);
    let findLaneFrom = null;
    if (trailerLaneFromOptionsRef.current) {
      findLaneFrom = trailerLaneFromOptionsRef.current.find(
        (t) => t.id === selectedLane
      );
      setLaneId(findLaneFrom && findLaneFrom.id);
    }
    let findLaneDestination = null;
    if (trailerLaneDestinationOptionsRef.current) {
      findLaneDestination = trailerLaneDestinationOptionsRef.current.find(
        (t) => t.id === selectedLaneDestiation
      );
      setSelectedLaneDestiation(findLaneDestination && findLaneDestination.id);
    }

    await refetchTrailerLaneId();
    let objReasonConfirmReceiveJob = null;
    let objReasonConfirmYardOut = null;
    let objReasonConfirmArrival = null;
    if (
      data[0].reason_receive_job &&
      data[0].reason_receive_job.reasonEdit &&
      data[0].reason_yard_out &&
      data[0].reason_yard_out.reasonEdit
    ) {
      objReasonConfirmReceiveJob = {
        mode: 'Receive Job',
        receive_job_and_yard_out_id: reasonLastIdRef.current.receive_job_id,
        reason_id: data[0].reason_receive_job.reasonEdit.value.id,
        reason_description:
          data[0].reason_receive_job.reasonEdit.value.description,
      };
      objReasonConfirmYardOut = {
        mode: 'Yard Out',
        receive_job_and_yard_out_id: reasonLastIdRef.current.yard_out_id,
        reason_id: data[0].reason_yard_out.reasonEdit.value.id,
        reason_description:
          data[0].reason_yard_out.reasonEdit.value.description,
      };
      onSave(objReasonConfirmReceiveJob);
      onSave(objReasonConfirmYardOut);
    }

    if (data[0].reason_receive_job && data[0].reason_receive_job.reasonEdit) {
      objReasonConfirmReceiveJob = {
        mode: 'Receive Job',
        receive_job_and_yard_out_id: reasonLastIdRef.current.receive_job_id,
        reason_id: data[0].reason_receive_job.reasonEdit.value.id,
        reason_description:
          data[0].reason_receive_job.reasonEdit.value.description,
      };
      onSave(objReasonConfirmReceiveJob);
    }
    if (data[0].reason_yard_out && data[0].reason_yard_out.reasonEdit) {
      objReasonConfirmYardOut = {
        mode: 'Yard Out',
        receive_job_and_yard_out_id: reasonLastIdRef.current.yard_out_id,
        reason_id: data[0].reason_yard_out.reasonEdit.value.id,
        reason_description:
          data[0].reason_yard_out.reasonEdit.value.description,
      };
      onSave(objReasonConfirmYardOut);
    }

    if (data[0].reason_arrive && data[0].reason_arrive.reasonEdit) {
      objReasonConfirmArrival = {
        reason_id: data[0].reason_arrive.reasonEdit.value.id,
        reason_description: data[0].reason_arrive.reasonEdit.value.description,
        reason_version: reasonLastIdRef.current?.reason_arrive?.reason_version,
      };
      if (reasonLastIdRef.current.realtime_tracking_id) {
        onSaveArrivalRealtimeTracking({
          ...objReasonConfirmArrival,
          id: reasonLastIdRef.current.realtime_tracking_id,
        });
      }

      if (reasonLastIdRef.current.shuttle_in_id) {
        onSaveArrivalShuttleIn({
          ...objReasonConfirmArrival,
          id: reasonLastIdRef.current.shuttle_in_id,
        });
      }
    }
    if (findLaneDestination && data[1]?.shuttle_in_id) {
      let body = {
        id: data[1]?.shuttle_in_id,
        trailer_lane: findLaneDestination?.id,
      };
      onSaveArrivalLane(body);
    }
    if (findLaneFrom) {
      let result = await onQueryDefault(callingRef.current);
      if (selectedLane) {
        if (selectedLane !== result) {
          await updateTrailerLaneForCreate(result);
        }
      }
    }

    message.success('Data was successfully saved');
    await onQuery(callingRef.current);
    await refetchTrailerLaneAll();
    setIsLoading(false);
  };

  const [
    updateTrailerLane,
    {
      isSuccess: isTrailerLaneSuccessUpdating,
      isUninitialized: isTrailerLaneUninitUpdating,
      error: errorTrailerLaneUpdating,
    },
  ] = useUpdateTrailerLaneMutation();

  useEffect(() => {
    reasonLastIdRef.current = null;
  }, [isTrailerLaneSuccessUpdating, isTrailerLaneUninitUpdating]);

  useEffect(() => {
    if (errorTrailerLaneUpdating) {
      setErrorData(errorTrailerLaneUpdating);
    }
  }, [errorTrailerLaneUpdating, setErrorData]);

  const [
    updateTrailerLaneReceiveJobs,
    {
      isSuccess: isTrailerLaneReceiveJobsSuccessUpdating,
      isUninitialized: isTrailerLaneReceiveJobsUninitUpdating,
      error: errorTrailerLaneReceiveJobsUpdating,
    },
  ] = useUpdateTrailerLaneReceiveJobsMutation();

  useEffect(() => {}, [
    isTrailerLaneReceiveJobsSuccessUpdating,
    isTrailerLaneReceiveJobsUninitUpdating,
  ]);

  useEffect(() => {
    if (errorTrailerLaneReceiveJobsUpdating) {
      setErrorData(errorTrailerLaneReceiveJobsUpdating);
    }
  }, [errorTrailerLaneReceiveJobsUpdating, setErrorData]);

  const updateTrailerLaneForCreate = async (result) => {
    let findLane = trailerLaneFromOptionsRef.current.find(
      (t) => t.id === selectedLane
    );
    refetchTrailerLaneAll();
    if (findLane) {
      let payload = {
        id: reasonLastIdRef.current?.receive_job_id,
        trailer_lane: selectedLane
          ? selectedLane
          : reasonLastIdRef.current?.trailer_lane_receive_job?.id,
      };
      await updateTrailerLaneReceiveJobs(payload);

      let total_used = findLane.used + 1;

      if (total_used === findLane.amount) {
        let postLane = {
          id: findLane?.id,
          is_available: false,
          used: total_used,
        };
        await updateTrailerLane(postLane);
      }
      if (total_used < findLane.amount) {
        let postLane = {
          id: findLane?.id,
          used: total_used,
        };
        await updateTrailerLane(postLane);
      }

      if (total_used > findLane.amount) {
        message.error('Error used more than > amount');
      }

      let findLaneCan = trailerLaneOptionsAllRef.current.find(
        (t) => t.id === result
      );
      await setLaneId(findLaneCan && findLaneCan.id);
      await refetchTrailerLaneId();

      let total_used_cancel = findLaneCan?.used;
      if (total_used_cancel === findLaneCan?.amount) {
        let postLaneCancel = {
          id: findLaneCan && findLaneCan.id,
          is_available: total_used_cancel === 1 ? true : false,
          used: total_used_cancel === 1 ? 0 : total_used_cancel,
        };
        await updateTrailerLane(postLaneCancel);
      }
      if (total_used_cancel < 0) {
        let postLaneCancel = {
          id: findLaneCan && findLaneCan.id,
          is_available: true,
          used: 0,
        };
        await updateTrailerLane(postLaneCancel);
      }
      if (total_used_cancel < findLaneCan?.amount) {
        let postLaneCancel = {
          id: findLaneCan && findLaneCan.id,
          is_available: true,
          used: total_used_cancel,
        };
        await updateTrailerLane(postLaneCancel);
      }
    }
  };
  const onSave = async (value) => {
    try {
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.updateChangeReasonLaneByReason.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const onSaveArrivalRealtimeTracking = async (value) => {
    try {
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.updateChangeReasonLaneArrivalRealtimeTracking.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const onSaveArrivalShuttleIn = async (value) => {
    try {
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.updateChangeReasonLaneArrivalShuttleIn.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const onSaveArrivalLane = async (value) => {
    try {
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.updateChangeReasonLaneArrivalLane.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Job code',
        dataIndex: 'job_code',
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'From',
        dataIndex: ['actual_from', 'name'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Destination',
        dataIndex: ['actual_destination', 'name'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Receive job time',
        dataIndex: 'actual_receive_job_datetime',
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Yard out time',
        dataIndex: 'actual_yardout_datetime',
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },

      {
        title: 'Arrival time',
        dataIndex: 'actual_arrival_time',
        width: 100,
        align: 'center',
        movable: true,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Trailer No.',
        dataIndex: ['trailers', 0, 'trailer_card_id'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Driver No.1',
        dataIndex: ['drivers', 0, 'tlep_code'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Driver Name.1',
        dataIndex: ['drivers', 0],
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          return text ? `${text.name_th}${text.surname_th}` : null;
        },
      },
      {
        title: 'Driver No.2',
        dataIndex: ['drivers', 1, 'tlep_code'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Driver Name.2',
        dataIndex: ['drivers', 1],
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          return text ? `${text.name_th}${text.surname_th}` : null;
        },
      },
    ];

    return cols;
  });

  const editReason = async (value, mode) => {
    let newData = null;
    modeReasonRef.current = mode;
    console.log('value 2:>> ', value);
    try {
      if ((value && value.id) || (value.reason && value.reason.id)) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.findReasonMasterChangeReasonLaneById.initiate(
            value.id ? value.id : value.reason.id
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            console.log('result.data :>> ', result.data);
            if (value.id) {
              newData = {
                description: value.description,
                nameGroup: value.group,
                groupCode: value.group.substring(1, 4),
                nameSubject: value.subject,
                mode: mode,
              };
            } else {
              newData = {
                subject_reason_id: result.data?.subject_reason?.id,
                description: value.reason_description,
                nameGroup: `(${result.data.subject_reason.group.code}) ${result.data.subject_reason.group.name}`,
                groupCode: result.data.subject_reason.group.code,
                nameSubject: `(${value.reason.subject_reason.code}) ${
                  language === 'th'
                    ? value.reason.subject_reason.name_th
                    : value.reason.subject_reason.name_en
                }`,
                mode: mode,
              };
            }
          }
        }
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
    setFormData(newData ? newData : value);

    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const postReason = (value) => {
    let reasonLabel = {
      label: value.subject.substring(1, 4),
      value: value,
    };
    let newData = [];

    newData.push(
      {
        ...dataSourceEdit[0],
        reason_receive_job:
          modeReasonRef.current === 'Receive Job' &&
          dataSourceEdit[0].reason_receive_job
            ? { reasonEdit: reasonLabel }
            : dataSourceEdit[0].reason_receive_job,
        reason_yard_out:
          modeReasonRef.current === 'Yard Out' &&
          dataSourceEdit[0].reason_yard_out
            ? { reasonEdit: reasonLabel }
            : dataSourceEdit[0].reason_yard_out,
        reason_arrive:
          modeReasonRef.current === 'Arrival' && dataSourceEdit[0].reason_arrive
            ? { reasonEdit: reasonLabel }
            : dataSourceEdit[0].reason_arrive,
      },
      {
        ...dataSourceEdit[1],
      }
    );
    dataSourceEditRef.current = newData;
    setDataSourceEdit(newData);
  };

  const clearAll = () => {
    setDataSourceEdit([
      {
        id: 1,
        reasonTrailerLane: 'Reason',
      },
      {
        id: 2,
        reasonTrailerLane: 'Trailer lane',
      },
    ]);
    setDataSource([]);
  };

  const onSelectedFromLaneField = async (lane, id) => {
    let findLane = trailerLaneFromOptionsRef.current.find(
      (t) => t.value === lane
    );
    let dataEdit = [
      { ...dataSourceEditRef.current[0] },
      {
        ...dataSourceEditRef.current[1],
        trailer_lane_receive_job: findLane ? { lane_no: findLane.value } : null,
      },
    ];
    setDataSourceEdit(dataEdit);
    setSelectedLane(findLane && findLane.id);
  };
  const onSelectedDestinationLaneField = async (lane, id) => {
    let findLane = trailerLaneDestinationOptionsRef.current.find(
      (t) => t.value === lane
    );
    let dataEdit = [
      {
        ...dataSourceEditRef.current[0],
        reasonEditArrival: findLane?.reason_arrive?.reason?.id,
      },
      {
        ...dataSourceEditRef.current[1],
        trailer_lane_arrive: findLane ? { lane_no: findLane.value } : null,
      },
    ];
    setDataSourceEdit(dataEdit);
    setSelectedLaneDestiation(findLane && findLane.id);
  };

  return (
    <Container>
      <TableToolbar
        onQuery={onQuery}
        columns={columns}
        isLoading={isLoading}
        setColumns={setColumns}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        limit={LIMIT}
      />

      <AntTable
        rowKey='id'
        tableId={'callingsheet'}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        pagination={false}
      />
      <div style={{ marginBottom: 20 }}></div>
      <TableEdit
        selectedFromLaneField={onSelectedFromLaneField}
        selectedDestinationLaneField={onSelectedDestinationLaneField}
        onSave={onCheckSave}
        dataSourceEdit={dataSourceEdit}
        editReason={editReason}
        isLoading={isLoading}
      />

      <FormReason
        formData={formData}
        showModal={isModalVisible}
        setIsModalVisible={handleCancel}
        postReason={postReason}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
export default CallingSheetTableComponent;
