import { List, Row, Col } from 'antd';
import AntTable from 'common_components/AntTable';
import './index.css';
import { dateTimeYearTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import constants from 'common_components/PrintDocument/constants';

var Barcode = require('react-barcode');

const Dn = ({ dnDataList }) => {
  const columns = [
    {
      title: 'No.',
      dataIndex: 'index',
      align: 'center',
      width: 50,
      textWrap: 'word-break',
      className: ['bg-white', 'borderBottomCss'],
      render: (text, record) => {
        if (text === '-') {
          return <div style={{ color: 'white' }}>{text}</div>;
        } else {
          return <div>{text}</div>;
        }
      },
    },
    {
      title: 'Vin No.',
      dataIndex: 'vin_no',
      align: 'center',
      width: 130,
      textWrap: 'word-break',
      className: ['bg-white', 'borderBottomCss'],
    },

    {
      title: 'หมายเลขเครื่อง',
      dataIndex: 'engine',
      align: 'center',
      width: 130,
      textWrap: 'word-break',
      className: ['bg-white', 'borderBottomCss'],
    },
    {
      title: 'แบบรถ',
      dataIndex: 'series',
      align: 'center',
      width: 160,
      textWrap: 'word-break',
      className: ['bg-white', 'borderBottomCss'],
      render: (text, record) => {
        return <div>{`${record?.model_code} ${record?.suffix}`}</div>;
      },
    },
    {
      title: 'สี',
      dataIndex: 'color',
      align: 'center',
      width: 130,
      textWrap: 'word-break',
      className: ['bg-white', 'borderBottomCss'],
    },
    {
      title: 'หมายเหตุ',
      width: 100,
      align: 'center',

      textWrap: 'word-break',
      className: ['bg-white', 'borderBottomCss'],
    },
  ];

  const getPrinterName = (item) => {
    if (!item?.user_login_print) {
      return '-';
    }
    let fullname = item.user_login_print?.fullname_th;
    const nameList = fullname.split(' ');
    return nameList[0];
  };
  const getCreatedAt = (item) => {
    if (!item?.delivery_note?.created_at) {
      return '-';
    }
    return dateTimeYearTimestamptzFormatter({
      value: item?.delivery_note?.created_at,
    });
  };
  return (
    <Pagebreak>
      <List
        itemLayout='horizontal'
        dataSource={dnDataList}
        renderItem={(item) => {
          console.log('item', item);
          return (
            <List.Item
              style={{
                padding: '5px',
                borderBottom: 'none',
                height: `${constants.A5.landscape[1]}px`,
              }}
            >
              <div style={{ width: '100%' }}>
                <Row align='end' justify='end'>
                  <Col
                    align='end'
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }}
                  >
                    <SizeTextSpan>
                      เลขทะเบียนการค้าและเลขประจำตัวผู้เสียภาษี : 0115536007768
                      QF-VLS-002 Rev.4
                    </SizeTextSpan>
                  </Col>
                </Row>
                <Row style={{ border: '1px solid black', padding: '0px' }}>
                  <Col
                    span={6}
                    align='start'
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }}
                  >
                    <SizeTextSpan>ใบส่งสินค้า (Delivery Note)</SizeTextSpan>
                  </Col>
                  <Col
                    span={18}
                    align='end'
                    style={{
                      paddingLeft: '5px',
                      paddingRight: '5px',
                      paddingTop: '0px',
                      paddingBottom: '0px',
                    }}
                  >
                    <SizeTextSpan>
                      บริษัทโตโยต้า ทรานสปอร์ต(ประเทศไทย)จำกัด{' '}
                    </SizeTextSpan>
                    <SizeTextSpan>
                      11/3 หมู่ที่ 1 ต.แสนภูดาษ อ.บ้านโพธิ์ จ.ฉะเชิงเทรา 24140
                      โทร.038-578125-8 แฟกซ์. 038-578129
                    </SizeTextSpan>
                  </Col>
                </Row>
                <Row
                  style={{
                    border: '1px solid black',
                    marginTop: '5px',
                    // height: '245px',
                  }}
                >
                  <Col
                    span={14}
                    style={{
                      paddingLeft: '5px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignTtems: 'center',
                    }}
                  >
                    <TextLabel>
                      วันที่ : &nbsp;<TextValue>{getCreatedAt(item)}</TextValue>
                    </TextLabel>

                    <TextLabel>
                      รหัสลูกค้า :{' '}
                      {item.customer?.code ? item.customer?.code : '-'}
                    </TextLabel>

                    <TextTwoLineWrap>
                      ชื่อตัวแทนจำหน่าย :{' '}
                      {item.customer?.name ? item.customer?.name : '-'}
                    </TextTwoLineWrap>

                    <TextTwoLineWrap>
                      ที่อยู่ :{' '}
                      {item.customer?.address_location
                        ? item.customer?.address_location
                        : '-'}
                    </TextTwoLineWrap>

                    <SizeTextSpanWidth>
                      จาก :{' '}
                      {item.location_from
                        ? item.location_from?.full_name_th
                        : '-'}{' '}
                      {item.location_from?.branch
                        ? item.location_from?.branch
                        : ''}
                    </SizeTextSpanWidth>

                    <SizeTextSpanWidth>
                      ปลายทาง :{' '}
                      {item.location_to ? item.location_to?.full_name_th : '-'}{' '}
                      {item.location_to?.branch ? item.location_to?.branch : ''}
                    </SizeTextSpanWidth>
                  </Col>
                  <Col
                    span={10}
                    style={{
                      borderLeft: '1px solid black',
                    }}
                  >
                    <Row
                      gutter={[24]}
                      style={{
                        paddingLeft: '5px',
                        marginBottom: '-10px',
                        paddingBottom: '-10px',
                      }}
                    >
                      <Col span={14}>
                        <TextLabel>
                          เลขที่ DN. :&nbsp;
                          <TextValue>
                            {item.delivery_note
                              ? item.delivery_note?.dn_no
                              : '-'}
                          </TextValue>
                        </TextLabel>
                        <SizeTextSpanWidth>
                          Calling No. :&nbsp;
                          <TextValue>
                            {item.callingsheet
                              ? item.callingsheet?.callingsheet_no
                              : '-'}
                          </TextValue>
                        </SizeTextSpanWidth>
                      </Col>
                      <Col span={10} align='left' style={{ marginLeft: '-14px' }}>
                        <div style={{ marginLeft: '-12px' }}>
                          <Barcode
                            value={item?.delivery_note?.dn_no}
                            flat
                            displayValue={false}
                            height={40}
                            width={2}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[24]} style={{ paddingLeft: '5px' }}>
                      <Col span={14}>
                        <SizeTextSpanWidth>
                          ชื่อ พขร. 1 :{' '}
                          {item.callingsheet?.dispatch_board_calling_sheet[0]
                            ?.drivers[0]
                            ? item.callingsheet?.dispatch_board_calling_sheet[0]
                                ?.drivers[0]?.name_th +
                              ' ' +
                              item.callingsheet?.dispatch_board_calling_sheet[0]
                                ?.drivers[0]?.surname_th
                            : ' '}
                        </SizeTextSpanWidth>

                        <SizeTextSpanWidth>
                          ชื่อ พขร. 2 :{' '}
                          {item.callingsheet?.dispatch_board_calling_sheet[0]
                            ?.drivers[1]
                            ? item.callingsheet?.dispatch_board_calling_sheet[0]
                                ?.drivers[1]?.name_th +
                              ' ' +
                              item.callingsheet?.dispatch_board_calling_sheet[0]
                                ?.drivers[1]?.surname_th
                            : ' '}
                        </SizeTextSpanWidth>

                        <SizeTextSpanWidth style={{ width: '100%' }}>
                          หมายเลขรถเทเลอร์ :{' '}
                          {item.callingsheet?.dispatch_board_calling_sheet
                            ? item.callingsheet?.dispatch_board_calling_sheet[0]
                                ?.trailers[0]?.trailer_card_id
                            : '-'}
                        </SizeTextSpanWidth>

                        <TextOverFlow>
                          เอกสารจัดทำโดย : {getPrinterName(item)}
                        </TextOverFlow>
                      </Col>
                      <Col
                        span={10}
                        align='center'
                        justify='center'
                        style={{ textAlign: 'center' }}
                      >
                        <TextOverFlow
                          style={{ paddingTop: '0px', textAlign: 'center' }}
                        >
                          ลายเช็นต์พขร.
                        </TextOverFlow>
                        <div
                          style={{
                            border: '1px solid black',
                            height: '38px',
                            width: '90%',
                            marginLeft: '5px',
                          }}
                        ></div>
                        <div
                          style={{
                            border: '1px solid black',
                            height: '38px',
                            marginTop: '5px',
                            width: '90%',
                            marginLeft: '5px',
                          }}
                        ></div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row gutter={[24]} style={{ marginTop: '10px' }}>
                  <Col span={24}>
                    <StyledAntTable
                      tableId={'vin_sub'}
                      bordered
                      style={{
                        borderRight: '0.5px solid #4d4d4d',
                        paddingRight: '2px',
                        borderTop: '0.5px solid #262626',
                        borderBottom: '0.5px solid #262626',
                      }}
                      dataSource={item.vins}
                      columns={columns}
                      pagination={false}
                    />
                  </Col>
                </Row>
                <Row gutter={[24]} style={{ paddingTop: '10px' }}>
                  <Col span={12} align='center'>
                    <SizeTextSpan>
                      ข้าพเจ้าได้รับสินค้าดังกล่าวข้างต้นในสภาพที่เรียบร้อยแล้ว
                    </SizeTextSpan>
                    <SizeTextSpan style={{ marginTop: '10px' }}>
                      _ _ _ _ _ _ _ _ _ _ _ _ _ _
                    </SizeTextSpan>
                    <SizeTextSpan style={{ marginTop: '10px' }}>
                      ลายมือชื่อผู้รับของ (ชื่อตัวบรรจง)
                    </SizeTextSpan>
                  </Col>
                  <Col span={12} align='center'>
                    <SizeTextSpan>วันที่ / เวลารับสินค้า</SizeTextSpan>
                    <SizeTextSpan style={{ marginTop: '10px' }}>
                      วันที่_ _ _ _ _ _ _ _ _ เวลา_ _ _ _ _ _ _ _ _
                    </SizeTextSpan>

                    <Barcode
                      style={{ marginTop: '4px' }}
                      value={item?.callingsheet?.callingsheet_no}
                      flat
                      fontSize={16}
                      height={34}
                      width={2}
                      margin={10}
                    />
                  </Col>
                </Row>
              </div>
            </List.Item>
          );
        }}
      />
    </Pagebreak>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tr
      td {
      border-bottom: 0.1px dotted #8c8c8c;

      border-left: 1px solid #262626;
      padding: 0px;
      height: 28px;
      font-size: 20px;
    }
  }
`;

const Pagebreak = styled.div`
  @media print {
    .pagebreak {
      page-break-before: always;
    } /* page-break-after works, as well */
  }
  .pagebreak {
    page-break-before: always;
  }
`;

const SizeTextSpan = styled.div`
  font-size: 20px;
  font-weight: 500;
`;
const TextLabel = styled.div`
  display: flex;
  align-items: left;
  font-size: 18px;
  font-weight: 500;
`;
const SizeTextSpanWidth = styled.div`
  display: flex;
  align-items: left;
  font-size: 18px;
  font-weight: 500;
  width: 98%;
`;
const TextTwoLineWrap = styled.div`
  font-size: 18px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 98%;
`;
const TextOverFlow = styled.div`
  font-size: 18px;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
`;
const TextValue = styled.div`
  font-size: 19px;
  font-weight: 750;
  margin-top: -1px;
`;
export default Dn;
