import React from 'react';
import { Typography } from 'antd';
const { Text } = Typography;

const DeliveryStatusRenderer = (props) => {
  const { data } = props;

  if (data) {
    if (data.toLowerCase().includes('delay')) {
      return <Text type='danger'>{data}</Text>;
    } else {
      return <Text>{data}</Text>;
    }
  } else {
    return '';
  }
};
export default DeliveryStatusRenderer;
