import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Checkbox,
  Button,
  Space,
  Select,
  Typography,
  DatePicker,
  message,
  Collapse,
  Switch,
  Statistic,
  InputNumber,
} from 'antd';
import styled from 'styled-components';
import moment from 'moment';
import { tttOperationDashboardApi } from 'reducers/digitalmap/tttOperationDashboard/api';
import { useSelector, useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { useDebounce } from 'hooks/debounce';
import {
  setTTTOperationDashboardFilter,
  setTTTOperationDashboardFilterPayload,
} from 'reducers/digitalmap/tttOperationDashboard';
import { SearchOutlined } from '@ant-design/icons';
import { Sorter } from 'utils/sorter';
import { RemoveDuplicates } from 'utils/removeDuplicates';
import jobMonitoringConstants from './constants';

const { Panel } = Collapse;
const { Countdown } = Statistic;
const CheckboxGroup = Checkbox.Group;
const onTheWayOptions = ['normal', 'warning', 'true', 'false'];
const finishedOptions = ['normal', 'warning', 'true', 'false'];
const defaultOnTheWayCheckedList = ['normal', 'warning', 'true', 'false'];
const defaultFinishedCheckedList = ['true'];
const validateDatetime = (limitDay, filterTTTDatetime, displayMode) => {
  if (displayMode !== 'default') {
    if (
      !filterTTTDatetime ||
      !filterTTTDatetime.fromTime ||
      !filterTTTDatetime.toTime
    ) {
      message.error('Please input datetime range');
      return false;
    }

    if (
      !moment(filterTTTDatetime.toTime).isSameOrAfter(
        moment(filterTTTDatetime.fromTime)
      )
    ) {
      message.error('Datetime range is invalid');
      return false;
    }

    if (
      moment(filterTTTDatetime.toTime).diff(
        moment(filterTTTDatetime.fromTime),
        'days'
      ) > limitDay
    ) {
      message.error(`Datetime range is longer than ${limitDay}`);
      return false;
    }
  }
  return true;
};

const createPayload = ({
  filterToCompany,
  filterToTrailer,
  filterToRegion,
  filterToCluster,
  filterToDestinationsCode,
  filterToDestinationsName,
  filterTrailerStatus,
  filterEvaOnTheWay,
  filterReasonOnTheWay,
  filterEvaFinished,
  filterReasonFinished,
  filterTTTDatetime,
  filterMode,
}) => {
  return {
    company: filterToCompany,
    trailer: filterToTrailer,
    region: filterToRegion,
    cluster: filterToCluster,
    location_code: filterToDestinationsCode,
    location_name: filterToDestinationsName,
    trailer_status: filterTrailerStatus,
    eva_on_the_way: filterEvaOnTheWay,
    reason_on_the_way: filterReasonOnTheWay,
    eva_finished: filterEvaFinished,
    reason_finished: filterReasonFinished,
    yard_out_time_from: filterTTTDatetime.fromTime
      ? filterTTTDatetime.fromTime.clone().utc().format('YYYY-MM-DD HH:mm:00')
      : null,
    yard_out_time_to: filterTTTDatetime.toTime
      ? filterTTTDatetime.toTime.clone().utc().format('YYYY-MM-DD HH:mm:00')
      : null,
    mode: filterMode,
  };
};

const defaultFilter = {
  filterToCompany: [],
  filterToTrailer: [],
  filterToRegion: undefined,
  filterToCluster: undefined,
  filterToDestinationsCode: undefined,
  filterToDestinationsName: undefined,
  filterTrailerStatus: ['on_the_way', 'finished'],
  filterEvaOnTheWay: [],
  filterReasonOnTheWay: [],
  filterEvaFinished: ['delay'],
  filterReasonFinished: ['true'],
  filterTTTDatetime: {
    toTime: moment('23:59', 'HH:mm'),
  },
  filterMode: 'default',
};

const TableToolbar = (props) => {
  const {
    isLoading,
    onInit,
    onQuery,
    showFilter,
    setShowFilter,
    onAutoRefetch,
  } = props;
  const dispatch = useDispatch();
  // Store filters
  const storedFilters = useSelector((state) => {
    return state.tttOperationDashboard.tttFilters;
  });

  const [auto, setAuto] = useState(true);
  const [countdownMins, setCountdownMins] = useState(5);
  const [checkedOnTheWayList, setCheckedOnTheWayList] = useState(
    storedFilters && storedFilters.checkedOnTheWayList
      ? storedFilters.checkedOnTheWayList
      : defaultOnTheWayCheckedList
  );
  const [checkedFinishedList, setCheckedFinishedList] = useState(
    storedFilters && storedFilters.checkedFinishedList
      ? storedFilters.checkedFinishedList
      : defaultFinishedCheckedList
  );
  const { setErrorData } = useErrorHandler();
  const [toCompanyOptions, setToCompanyOptions] = useState(
    storedFilters && storedFilters.toCompanyOptions
      ? storedFilters.toCompanyOptions
      : []
  );
  const [toTrailerOptions, setToTrailerOptions] = useState(
    storedFilters && storedFilters.toTrailerOptions
      ? storedFilters.toTrailerOptions
      : []
  );
  const [toRegionOptions, setToRegionOptions] = useState(
    storedFilters && storedFilters.toRegionOptions
      ? storedFilters.toRegionOptions
      : []
  );
  const [toClusterOptions, setToClusterOptions] = useState(
    storedFilters && storedFilters.toClusterOptions
      ? storedFilters.toClusterOptions
      : []
  );
  const [toDestinationsCodeOptions, setToDestinationsCodeOptions] = useState(
    storedFilters && storedFilters.toDestinationsCodeOptions
      ? storedFilters.toDestinationsCodeOptions
      : []
  );
  const [toDestinationsNameOptions, setToDestinationsNameOptions] = useState(
    storedFilters && storedFilters.toDestinationsNameOptions
      ? storedFilters.toDestinationsNameOptions
      : []
  );
  const [defaultCompanyOptions, setDefaultCompanyOptions] = useState(
    storedFilters && storedFilters.defaultCompanyOptions
      ? storedFilters.defaultCompanyOptions
      : []
  );
  const [defaultTrailerOptions, setDefaultTrailerOptions] = useState(
    storedFilters && storedFilters.defaultTrailerOptions
      ? storedFilters.defaultTrailerOptions
      : []
  );
  const [defaultRegionOptions, setDefaultRegionOptions] = useState(
    storedFilters && storedFilters.defaultRegionOptions
      ? storedFilters.defaultRegionOptions
      : []
  );
  const [defaultClusterOptions, setDefaultClusterOptions] = useState(
    storedFilters && storedFilters.defaultClusterOptions
      ? storedFilters.defaultClusterOptions
      : []
  );
  const [defaultDestinationsCodeOptions, setDefaultDestinationsCodeOptions] =
    useState(
      storedFilters && storedFilters.defaultDestinationsCodeOptions
        ? storedFilters.defaultDestinationsCodeOptions
        : []
    );
  const [defaultDestinationsNameOptions, setDefaultDestinationsNameOptions] =
    useState(
      storedFilters && storedFilters.defaultDestinationsNameOptions
        ? storedFilters.defaultDestinationsNameOptions
        : []
    );
  const [filterTTTDatetime, setFilterDatetime] = useState(
    storedFilters && storedFilters.filterTTTDatetime
      ? storedFilters.filterTTTDatetime
      : defaultFilter.filterTTTDatetime
  );
  const [filterToCompany, setFilterToCompany] = useState(
    storedFilters && storedFilters.filterToCompany
      ? storedFilters.filterToCompany
      : defaultFilter.filterToCompany
  );
  const [filterToTrailer, setFilterToTrailer] = useState(
    storedFilters && storedFilters.filterToTrailer
      ? storedFilters.filterToTrailer
      : defaultFilter.filterToTrailer
  );
  const [filterToRegion, setFilterToRegion] = useState(
    storedFilters && storedFilters.filterToRegion
      ? storedFilters.filterToRegion
      : defaultFilter.filterToRegion
  );
  const [filterToCluster, setFilterToCluster] = useState(
    storedFilters && storedFilters.filterToCluster
      ? storedFilters.filterToCluster
      : defaultFilter.filterToCluster
  );
  const [filterToDestinationsCode, setFilterToDestinationsCode] = useState(
    storedFilters && storedFilters.filterToDestinationsCode
      ? storedFilters.filterToDestinationsCode
      : defaultFilter.filterToDestinationsCode
  );
  const [filterToDestinationsName, setFilterToDestinationsName] = useState(
    storedFilters && storedFilters.filterToDestinationsName
      ? storedFilters.filterToDestinationsName
      : defaultFilter.filterToDestinationsName
  );

  const [filterEvaOnTheWay, setFilterEvaOnTheWay] = useState(
    storedFilters && storedFilters.filterEvaOnTheWay
      ? storedFilters.filterEvaOnTheWay
      : defaultFilter.filterEvaOnTheWay
  );

  const [filterReasonOnTheWay, setFilterReasonOnTheWay] = useState(
    storedFilters && storedFilters.filterReasonOnTheWay
      ? storedFilters.filterReasonOnTheWay
      : defaultFilter.filterReasonOnTheWay
  );
  const [filterEvaFinished, setfilterEvaFinished] = useState(
    storedFilters && storedFilters.filterEvaFinished
      ? storedFilters.filterEvaFinished
      : defaultFilter.filterEvaFinished
  );
  const [filterReasonFinished, setfilterReasonFinished] = useState(
    storedFilters && storedFilters.filterReasonFinished
      ? storedFilters.filterReasonFinished
      : defaultFilter.filterReasonFinished
  );

  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [checkAutoRefetch, setCheckAutoRefetch] = useState(false);

  const onChangeOnTheWay = (checkedvalue, label) => {
    let newSelectedOnTheWay = checkedOnTheWayList.filter((p) => p !== label);

    if (checkedvalue) {
      newSelectedOnTheWay.push(label);
    } else {
      const index = newSelectedOnTheWay.indexOf(label);
      if (index > -1) {
        newSelectedOnTheWay.splice(index, 1);
      }
    }

    let dup = [...new Set(newSelectedOnTheWay)];
    let onTheWay = [];
    let reasonOnTheWay = [];

    for (let index = 0; index < dup.length; index++) {
      if (dup[index] === 'normal') {
        onTheWay.push(dup[index]);
      }
      if (dup[index] === 'warning') {
        onTheWay.push(dup[index]);
      }

      if (dup[index] === 'true') {
        reasonOnTheWay.push(dup[index]);
      }
      if (dup[index] === 'false') {
        reasonOnTheWay.push(dup[index]);
      }

      if (dup[index] === 'true' || dup[index] === 'false') {
        onTheWay.push('delay');
      }
    }

    setCheckedOnTheWayList(dup);
    if (dup.length === onTheWayOptions.length) {
      setFilterEvaOnTheWay([]);
      setFilterReasonOnTheWay([]);
    } else {
      setFilterEvaOnTheWay(onTheWay);
      setFilterReasonOnTheWay(reasonOnTheWay);
    }
  };

  const onChangeFinished = (checkedvalue, label) => {
    let newSelectedFinished = checkedFinishedList.filter((p) => p !== label);

    if (checkedvalue) {
      newSelectedFinished.push(label);
    } else {
      const index = newSelectedFinished.indexOf(label);
      if (index > -1) {
        newSelectedFinished.splice(index, 1);
      }
    }

    let dup = [...new Set(newSelectedFinished)];
    let finished = [];
    let reasonFinished = [];
    for (let index = 0; index < dup.length; index++) {
      if (dup[index] === 'normal') {
        finished.push(dup[index]);
      }
      if (dup[index] === 'warning') {
        finished.push(dup[index]);
      }
      if (dup[index] === 'true') {
        reasonFinished.push(dup[index]);
      }
      if (dup[index] === 'false') {
        reasonFinished.push(dup[index]);
      }
      if (dup[index] === 'true' || dup[index] === 'false') {
        finished.push('delay');
      }
    }

    setCheckedFinishedList(dup);
    if (dup.length === finishedOptions.length) {
      setfilterEvaFinished([]);
      setfilterReasonFinished([]);
    } else {
      setfilterEvaFinished(finished);
      setfilterReasonFinished(reasonFinished);
    }
  };

  const onCheckOnTheWayAllChange = (e) => {
    setCheckedOnTheWayList(e.target.checked ? onTheWayOptions : []);
    setFilterEvaOnTheWay([]);
    setFilterReasonOnTheWay([]);
  };

  const onCheckFinishedAllChange = (e) => {
    setCheckedFinishedList(e.target.checked ? finishedOptions : []);
    setfilterEvaFinished([]);
    setfilterReasonFinished([]);
  };

  const checkFilterStoredDisplayMode = (trailerStatus) => {
    let displayMode = 'default';
    if (
      !filterEvaOnTheWay.length &&
      !filterReasonOnTheWay.length &&
      filterEvaFinished.includes('delay') &&
      trailerStatus.includes('on_the_way') &&
      trailerStatus.includes('finished') &&
      filterReasonFinished.includes('true') &&
      !filterReasonFinished.includes('false') &&
      !filterTTTDatetime.fromTime &&
      filterTTTDatetime.toTime
    ) {
      displayMode = 'default';
    } else {
      displayMode = 'display';
    }
    return displayMode;
  };

  const checkFilterStoredTrailerStatus = () => {
    let onthewayCheck = false;
    let finishedCheck = false;
    let postFilterTrailerStatus = [];
    if (checkedOnTheWayList.length > 0) {
      onthewayCheck = true;
      postFilterTrailerStatus = ['on_the_way'];
    }
    if (checkedFinishedList.length > 0) {
      finishedCheck = true;
      postFilterTrailerStatus = ['finished'];
    }
    if (onthewayCheck && finishedCheck) {
      postFilterTrailerStatus = ['on_the_way', 'finished'];
    }
    return postFilterTrailerStatus;
  };

  useEffect(() => {
    const trailerStatus = checkFilterStoredTrailerStatus();
    const displayMode = checkFilterStoredDisplayMode(trailerStatus);
    if (onInit) {
      if (!storedFilters) {
        onInit(createPayload(defaultFilter));
        onQueryOption(createPayload(defaultFilter));
      } else {
        let payload = createPayload({
          filterMode: displayMode,
          filterToCompany,
          filterToTrailer,
          filterToRegion,
          filterToCluster,
          filterToDestinationsCode,
          filterToDestinationsName,
          filterTrailerStatus: trailerStatus,
          filterEvaOnTheWay,
          filterReasonOnTheWay,
          filterEvaFinished,
          filterReasonFinished,
          filterTTTDatetime,
        });
        onInit(payload);
        onQueryOption(payload);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const debouncedFilterDatetime = useDebounce(filterTTTDatetime, 1000);

  useEffect(() => {
    const trailerStatus = checkFilterStoredTrailerStatus();
    const displayMode = checkFilterStoredDisplayMode(trailerStatus);
    dispatch(
      setTTTOperationDashboardFilter({
        filterMode: displayMode,
        filterToCompany,
        filterToTrailer,
        filterToRegion,
        filterToCluster,
        filterToDestinationsCode,
        filterToDestinationsName,
        filterTrailerStatus: trailerStatus,
        filterEvaOnTheWay,
        filterReasonOnTheWay,
        filterEvaFinished,
        filterReasonFinished,
        filterTTTDatetime,
        checkedOnTheWayList,
        checkedFinishedList,
        toCompanyOptions,
        toTrailerOptions,
        toRegionOptions,
        toClusterOptions,
        toDestinationsCodeOptions,
        toDestinationsNameOptions,
        defaultCompanyOptions,
        defaultTrailerOptions,
        defaultRegionOptions,
        defaultClusterOptions,
        defaultDestinationsCodeOptions,
        defaultDestinationsNameOptions,
      })
    );
    if (checkAutoRefetch) {
      dispatch(
        setTTTOperationDashboardFilterPayload(createPayload(defaultFilter))
      );
    } else {
      dispatch(
        setTTTOperationDashboardFilterPayload({
          ...createPayload({
            filterMode: displayMode,
            filterToCompany,
            filterToTrailer,
            filterToRegion,
            filterToCluster,
            filterToDestinationsCode,
            filterToDestinationsName,
            filterTrailerStatus: trailerStatus,
            filterEvaOnTheWay,
            filterReasonOnTheWay,
            filterEvaFinished,
            filterReasonFinished,
            filterTTTDatetime,
          }),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    filterEvaFinished,
    filterEvaOnTheWay,
    filterReasonFinished,
    filterReasonOnTheWay,
    filterTTTDatetime,
    filterToCluster,
    filterToCompany,
    filterToDestinationsCode,
    filterToDestinationsName,
    filterToRegion,
    filterToTrailer,
    checkedOnTheWayList,
    checkedFinishedList,
    toCompanyOptions,
    toTrailerOptions,
    toRegionOptions,
    toClusterOptions,
    toDestinationsCodeOptions,
    toDestinationsNameOptions,
    defaultCompanyOptions,
    defaultTrailerOptions,
    defaultRegionOptions,
    defaultClusterOptions,
    defaultDestinationsCodeOptions,
    defaultDestinationsNameOptions,
    debouncedFilterDatetime,
  ]);

  const selectProps = {
    mode: 'multiple',
    style: {
      width: '170px',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoadingFilter,
  };

  const selectSingleProps = {
    showSearch: true,
    style: {
      width: '170px',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoadingFilter,
  };

  const inputProps = {
    style: {
      width: '170px',
    },
  };

  const onQueryOption = async (payload) => {
    try {
      setIsLoadingFilter(true);
      let result = await dispatch(
        tttOperationDashboardApi.endpoints.findOrderRealtimeTracking.initiate(
          payload,
          { forceRefetch: true }
        )
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          let companyOptions = [];
          let trailerOptions = [];
          let clustersOptions = [];
          let regionsOptions = [];
          let destinationsCodeOptions = [];
          let destinationsNameOptions = [];

          let conpanys = Object.keys(result.data.companys);
          for (let index = 0; index < conpanys.length; index++) {
            let key = conpanys[index];
            let value = result.data.companys[key];
            companyOptions.push({
              label: key,
              value: key,
            });

            for (let index = 0; index < value.length; index++) {
              trailerOptions.push({
                label: value[index].trailer,
                value: value[index].trailer,
                company: value[index].company,
              });
            }
          }

          let clusters = Object.keys(result.data.clusters);
          for (let index = 0; index < clusters.length; index++) {
            let key = clusters[index];
            let value = result.data.clusters[key];
            clustersOptions.push({
              label: key,
              value: key,
            });

            for (let index = 0; index < value.length; index++) {
              regionsOptions.push({
                label: value[index].region,
                value: value[index].region,
                cluster: value[index].cluster,
              });
            }
          }
          let destinations = Object.keys(result.data.destinations);
          for (let index = 0; index < destinations.length; index++) {
            let key = destinations[index];
            let value = result.data.destinations[key];

            destinationsCodeOptions.push({
              label: key,
              value: key,
            });

            for (let index = 0; index < value.length; index++) {
              destinationsNameOptions.push({
                code: value[index].code,
                label: value[index].location,
                value: value[index].location,
                region: value[index].region,
                cluster: value[index].cluster,
              });
            }
          }
          regionsOptions = RemoveDuplicates.LISTKEY(regionsOptions, 'value');
          regionsOptions = Sorter.DEFAULTLIST(regionsOptions, 'value');

          companyOptions = RemoveDuplicates.LISTKEY(companyOptions, 'value');
          companyOptions = Sorter.DEFAULTLIST(companyOptions, 'value');

          trailerOptions = RemoveDuplicates.LISTKEY(trailerOptions, 'value');
          trailerOptions = Sorter.DEFAULTLIST(trailerOptions, 'value');

          clustersOptions = RemoveDuplicates.LISTKEY(clustersOptions, 'value');
          clustersOptions = Sorter.DEFAULTLIST(clustersOptions, 'value');

          destinationsCodeOptions = RemoveDuplicates.LISTKEY(
            destinationsCodeOptions,
            'value'
          );
          destinationsCodeOptions = Sorter.DEFAULTLIST(
            destinationsCodeOptions,
            'value'
          );

          destinationsNameOptions = RemoveDuplicates.LISTKEY(
            destinationsNameOptions,
            'value'
          );
          destinationsNameOptions = Sorter.DEFAULTLIST(
            destinationsNameOptions,
            'value'
          );

          setToCompanyOptions(companyOptions);
          setToTrailerOptions(trailerOptions);
          setToRegionOptions(regionsOptions);
          setToClusterOptions(clustersOptions);
          setToDestinationsCodeOptions(destinationsCodeOptions);
          setToDestinationsNameOptions(destinationsNameOptions);

          setDefaultCompanyOptions(companyOptions);
          setDefaultTrailerOptions(trailerOptions);
          setDefaultRegionOptions(regionsOptions);
          setDefaultClusterOptions(clustersOptions);
          setDefaultDestinationsCodeOptions(destinationsCodeOptions);
          setDefaultDestinationsNameOptions(destinationsNameOptions);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
    setIsLoadingFilter(false);
  };

  const onDisplayButton = () => {
    let trailerStatus = checkFilterStoredTrailerStatus();
    let displayMode = checkFilterStoredDisplayMode(trailerStatus);
    setCheckAutoRefetch(false);
    if (
      !validateDatetime(
        jobMonitoringConstants.LIMIT_SEARCH_DAYS,
        filterTTTDatetime,
        displayMode
      )
    ) {
      return;
    }

    if (onQuery) {
      onQuery({
        ...createPayload({
          filterMode: displayMode,
          filterToCompany,
          filterToTrailer,
          filterToRegion,
          filterToCluster,
          filterToDestinationsCode,
          filterToDestinationsName,
          filterTrailerStatus: trailerStatus,
          filterEvaOnTheWay,
          filterReasonOnTheWay,
          filterEvaFinished,
          filterReasonFinished,
          filterTTTDatetime,
        }),
      });
    }
    onQueryOption({
      ...createPayload({
        filterMode: displayMode,
        filterToCompany,
        filterToTrailer,
        filterToRegion,
        filterToCluster,
        filterToDestinationsCode,
        filterToDestinationsName,
        filterTrailerStatus: trailerStatus,
        filterEvaOnTheWay,
        filterReasonOnTheWay,
        filterEvaFinished,
        filterReasonFinished,
        filterTTTDatetime,
      }),
    });
  };

  const resetUIToDefault = () => {
    onQueryOption(createPayload(defaultFilter));
    setCheckedOnTheWayList(defaultOnTheWayCheckedList);
    setCheckedFinishedList(defaultFinishedCheckedList);
    setFilterDatetime({
      toTime: defaultFilter.filterTTTDatetime.toTime,
      fromTime: undefined,
    });

    setFilterToCompany(defaultFilter.filterToCompany);
    setFilterToTrailer(defaultFilter.filterToTrailer);
    setFilterToRegion(defaultFilter.filterToRegion);
    setFilterToCluster(defaultFilter.filterToCluster);
    setFilterToDestinationsCode(defaultFilter.filterToDestinationsCode);
    setFilterToDestinationsName(defaultFilter.filterToDestinationsName);
    setFilterEvaOnTheWay(defaultFilter.filterEvaOnTheWay);
  };

  const resetReason = () => {
    if (filterToCluster && !filterToDestinationsCode) {
      setToClusterOptions(defaultClusterOptions);
      checkCluster(filterToCluster);
    }
    if (!filterToCluster && !filterToDestinationsCode) {
      setToRegionOptions(defaultRegionOptions);
      setToClusterOptions(defaultClusterOptions);
      setToDestinationsCodeOptions(defaultDestinationsCodeOptions);
      setToDestinationsNameOptions(defaultDestinationsNameOptions);
    }
  };
  const resetCluster = () => {
    if (filterToRegion && !filterToDestinationsCode) {
      setToRegionOptions(defaultRegionOptions);
      checkRegion(filterToRegion);
    }

    if (!filterToRegion && !filterToDestinationsCode) {
      setToClusterOptions(defaultClusterOptions);
      setToRegionOptions(defaultRegionOptions);
      setToDestinationsCodeOptions(defaultDestinationsCodeOptions);
      setToDestinationsNameOptions(defaultDestinationsNameOptions);
    }
  };
  const resetCodeAndNameToRegion = () => {
    let findCluster = defaultDestinationsNameOptions
      .filter((p) => p.region === filterToRegion)
      .map((d) => ({
        label: d.cluster,
        value: d.cluster,
      }));

    findCluster = RemoveDuplicates.LISTKEY(findCluster, 'value');
    findCluster = Sorter.DEFAULTLIST(findCluster, 'value');

    let findName = defaultDestinationsNameOptions
      .filter((p) => p.region === filterToRegion)
      .map((d) => ({
        label: d.value,
        value: d.value,
      }));

    findName = RemoveDuplicates.LISTKEY(findName, 'value');
    findName = Sorter.DEFAULTLIST(findName, 'value');

    let findCode = defaultDestinationsNameOptions
      .filter((p) => p.region === filterToRegion)
      .map((d) => ({
        label: d.code,
        value: d.code,
      }));

    findCode = RemoveDuplicates.LISTKEY(findCode, 'value');
    findCode = Sorter.DEFAULTLIST(findCode, 'value');
    setToClusterOptions(findCluster);
    setToDestinationsCodeOptions(findCode);
    setToDestinationsNameOptions(findName);
  };

  const resetCodeAndNameToCluster = () => {
    let findRegion = defaultDestinationsNameOptions
      .filter((p) => p.cluster === filterToCluster)
      .map((d) => ({
        label: d.region,
        value: d.region,
      }));
    findRegion = RemoveDuplicates.LISTKEY(findRegion, 'value');
    findRegion = Sorter.DEFAULTLIST(findRegion, 'value');
    let findName = defaultDestinationsNameOptions
      .filter((p) => p.cluster === filterToCluster)
      .map((d) => ({
        label: d.value,
        value: d.value,
      }));

    findName = RemoveDuplicates.LISTKEY(findName, 'value');
    findName = Sorter.DEFAULTLIST(findName, 'value');

    let findCode = defaultDestinationsNameOptions
      .filter((p) => p.cluster === filterToCluster)
      .map((d) => ({
        label: d.code,
        value: d.code,
      }));

    findCode = RemoveDuplicates.LISTKEY(findCode, 'value');
    findCode = Sorter.DEFAULTLIST(findCode, 'value');

    setToRegionOptions(findRegion);
    setToDestinationsCodeOptions(findCode);
    setToDestinationsNameOptions(findName);
  };
  const checkResetCodeAndName = () => {
    if (filterToRegion && filterToCluster) {
      resetCodeAndNameToRegion();
      resetCodeAndNameToCluster();
      setFilterToDestinationsCode(undefined);
      setFilterToDestinationsName(undefined);
    }
    if (filterToRegion && !filterToCluster) {
      setToRegionOptions(defaultRegionOptions);
      resetCodeAndNameToRegion();
      setFilterToDestinationsName(undefined);
      setFilterToDestinationsCode(undefined);
    }
    if (!filterToRegion && filterToCluster) {
      setToClusterOptions(defaultClusterOptions);
      setFilterToDestinationsName(undefined);
      setFilterToDestinationsCode(undefined);
      resetCodeAndNameToCluster();
    }

    if (!filterToRegion && !filterToCluster) {
      setToRegionOptions(defaultRegionOptions);
      setToClusterOptions(defaultClusterOptions);
      setToDestinationsCodeOptions(defaultDestinationsCodeOptions);
      setToDestinationsNameOptions(defaultDestinationsNameOptions);
      setFilterToDestinationsName(undefined);
      setFilterToDestinationsCode(undefined);
    }
  };

  const checkRegion = (value) => {
    try {
      if (value) {
        let findCluster = defaultDestinationsNameOptions
          .filter((p) => p.region === value)
          .map((d) => ({
            label: d.cluster,
            value: d.cluster,
          }));

        findCluster = RemoveDuplicates.LISTKEY(findCluster, 'value');
        findCluster = Sorter.DEFAULTLIST(findCluster, 'value');

        let findName = defaultDestinationsNameOptions
          .filter((p) => p.region === value)
          .map((d) => ({
            label: d.value,
            value: d.value,
          }));

        findName = RemoveDuplicates.LISTKEY(findName, 'value');
        findName = Sorter.DEFAULTLIST(findName, 'value');

        let findCode = defaultDestinationsNameOptions
          .filter((p) => p.region === value)
          .map((d) => ({
            label: d.code,
            value: d.code,
          }));

        findCode = RemoveDuplicates.LISTKEY(findCode, 'value');
        findCode = Sorter.DEFAULTLIST(findCode, 'value');
        if (filterToDestinationsName) {
          checkDestinationCode(filterToDestinationsCode);
          checkDestinationName(filterToDestinationsName);
        } else {
          setToClusterOptions(findCluster);
          setToDestinationsCodeOptions(findCode);
          setToDestinationsNameOptions(findName);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const checkCluster = (value) => {
    try {
      if (value) {
        let findRegion = defaultDestinationsNameOptions
          .filter((p) => p.cluster === value)
          .map((d) => ({
            label: d.region,
            value: d.region,
          }));

        findRegion = RemoveDuplicates.LISTKEY(findRegion, 'value');

        findRegion = Sorter.DEFAULTLIST(findRegion, 'value');

        let findName = defaultDestinationsNameOptions
          .filter((p) => p.cluster === value)
          .map((d) => ({
            label: d.value,
            value: d.value,
          }));

        findName = RemoveDuplicates.LISTKEY(findName, 'value');
        findName = Sorter.DEFAULTLIST(findName, 'value');

        let findCode = defaultDestinationsNameOptions
          .filter((p) => p.cluster === value)
          .map((d) => ({
            label: d.code,
            value: d.code,
          }));

        findCode = RemoveDuplicates.LISTKEY(findCode, 'value');
        findCode = Sorter.DEFAULTLIST(findCode, 'value');
        if (filterToDestinationsName) {
          checkDestinationCode(filterToDestinationsCode);
          checkDestinationName(filterToDestinationsName);
        } else {
          setToRegionOptions(findRegion);
          setToDestinationsCodeOptions(findCode);
          setToDestinationsNameOptions(findName);
        }
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const checkDestinationCode = async (value) => {
    try {
      if (value) {
        let findRegion = defaultDestinationsNameOptions
          .filter((p) => p.code === value)
          .map((d) => ({
            label: d.region,
            value: d.region,
          }));

        findRegion = RemoveDuplicates.LISTKEY(findRegion, 'value');
        findRegion = Sorter.DEFAULTLIST(findRegion, 'value');
        let findCluster = defaultDestinationsNameOptions
          .filter((p) => p.code === value)
          .map((d) => ({
            label: d.cluster,
            value: d.cluster,
          }));

        findCluster = RemoveDuplicates.LISTKEY(findCluster, 'value');
        findCluster = Sorter.DEFAULTLIST(findCluster, 'value');

        let findName = defaultDestinationsNameOptions
          .filter((p) => p.code === value)
          .map((d) => ({
            label: d.value,
            value: d.value,
          }));

        findName = RemoveDuplicates.LISTKEY(findName, 'value');
        findName = Sorter.DEFAULTLIST(findName, 'value');

        let findCode = defaultDestinationsNameOptions
          .filter((p) => p.code === value)
          .map((d) => ({
            label: d.code,
            value: d.code,
          }));

        findCode = RemoveDuplicates.LISTKEY(findCode, 'value');
        findCode = Sorter.DEFAULTLIST(findCode, 'value');
        setToRegionOptions(findRegion);
        setToClusterOptions(findCluster);
        setToDestinationsCodeOptions(findCode);
        setToDestinationsNameOptions(findName);
        setFilterToDestinationsName(findName[0].value);
      }
    } catch (err) {
      console.log('err', err);
    }
  };
  const checkDestinationName = (value) => {
    try {
      if (value) {
        let findRegion = defaultDestinationsNameOptions
          .filter((p) => p.value === value)
          .map((d) => ({
            label: d.region,
            value: d.region,
          }));
        findRegion = RemoveDuplicates.LISTKEY(findRegion, 'value');
        findRegion = Sorter.DEFAULTLIST(findRegion, 'value');
        let findCluster = defaultDestinationsNameOptions
          .filter((p) => p.value === value)
          .map((d) => ({
            label: d.cluster,
            value: d.cluster,
          }));

        findCluster = RemoveDuplicates.LISTKEY(findCluster, 'value');
        findCluster = Sorter.DEFAULTLIST(findCluster, 'value');

        let findName = defaultDestinationsNameOptions
          .filter((p) => p.value === value)
          .map((d) => ({
            label: d.value,
            value: d.value,
          }));

        findName = RemoveDuplicates.LISTKEY(findName, 'value');
        findName = Sorter.DEFAULTLIST(findName, 'value');

        let findCode = defaultDestinationsNameOptions
          .filter((p) => p.value === value)
          .map((d) => ({
            label: d.code,
            value: d.code,
          }));

        findCode = RemoveDuplicates.LISTKEY(findCode, 'value');
        findCode = Sorter.DEFAULTLIST(findCode, 'value');
        let find = defaultDestinationsNameOptions.find(
          (v) => v.value === value
        );
        setFilterToDestinationsCode(find && find.code);
        setToRegionOptions(findRegion);
        setToClusterOptions(findCluster);
        setToDestinationsCodeOptions(findCode);
        setToDestinationsNameOptions(findName);
      }
    } catch (err) {
      console.log('err', err);
    }
  };

  const onCountdownFinish = () => {
    let displayMode = 'default';
    if (
      !validateDatetime(
        jobMonitoringConstants.LIMIT_SEARCH_DAYS,
        filterTTTDatetime,
        displayMode
      )
    ) {
      return;
    }
    if (onAutoRefetch) {
      setCheckAutoRefetch(true);
      resetUIToDefault();
      onAutoRefetch(createPayload(defaultFilter));
    }
  };

  return (
    <Container>
      <Space direction='horizontal' size={16} align='end'>
        <StyledCollapse
          defaultActiveKey={1}
          onChange={() => {
            setShowFilter(!showFilter);
          }}
        >
          <Panel header='Filter Options' key={1} style={{ fontSize: '13px' }}>
            <Space
              direction='horizontal'
              size={6}
              style={{ padding: '5px' }}
              align='end'
            >
              <SearchBox style={{ width: '340px', marginRight: '20px' }}>
                <Space direction='vertical'>
                  <Row gutter={[12]}>
                    <Col span={11}>
                      <Typography>Yard out date from</Typography>
                      <DatePicker
                        {...inputProps}
                        size='small'
                        showTime
                        value={filterTTTDatetime.fromTime}
                        format={'DD/MM/YYYY HH:mm'}
                        onChange={(value) => {
                          setFilterDatetime({
                            ...filterTTTDatetime,
                            fromTime: value,
                          });
                        }}
                      />
                    </Col>
                    <Col
                      justify='right'
                      offset={1}
                      style={{
                        fontSize: '13px',
                        marginTop: 'auto',
                        marginBottom: '5px',
                        paddingLeft: '9px',
                        paddingRight: '0px',
                        marginRight: '-1px',
                      }}
                    >
                      -
                    </Col>
                    <Col span={11}>
                      <Typography>Yard out date to</Typography>
                      <DatePicker
                        size='small'
                        // style={{ fontSize: 11 }}
                        {...inputProps}
                        value={filterTTTDatetime.toTime}
                        showTime={{
                          defaultValue: moment('23:59', 'HH:mm'),
                        }}
                        format={'DD/MM/YYYY HH:mm'}
                        onChange={(value) => {
                          setFilterDatetime({
                            ...filterTTTDatetime,
                            toTime: value,
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row gutter={[12]}>
                    <Col span={11}>
                      <div>
                        <Typography>Company</Typography>
                        <Select
                          size='small'
                          {...selectProps}
                          placeholder={'Select Company'}
                          value={filterToCompany}
                          options={toCompanyOptions}
                          onChange={(value) => {
                            setFilterToCompany(value);
                            let findTrailerOptions = [];
                            for (let i = 0; i < value.length; i++) {
                              for (
                                let j = 0;
                                j < defaultTrailerOptions.length;
                                j++
                              ) {
                                if (
                                  value[i] === defaultTrailerOptions[j].company
                                ) {
                                  findTrailerOptions.push({
                                    label: defaultTrailerOptions[j].label,
                                    value: defaultTrailerOptions[j].value,
                                  });
                                }
                              }
                            }

                            findTrailerOptions = RemoveDuplicates.LISTKEY(
                              findTrailerOptions,
                              'value'
                            );
                            findTrailerOptions = Sorter.DEFAULTLIST(
                              findTrailerOptions,
                              'value'
                            );
                            setToTrailerOptions(findTrailerOptions);

                            if (!value.length) {
                              setToTrailerOptions(defaultTrailerOptions);
                            }
                          }}
                        />
                      </div>
                    </Col>
                    <Col
                      span={2}
                      style={{
                        fontSize: '13px',
                        marginTop: 'auto',
                        marginBottom: '5px',
                        paddingLeft: '23px',
                        paddingRight: '0px',
                      }}
                    ></Col>
                    <Col span={11}>
                      <div>
                        <Typography>Trailer</Typography>
                        <Select
                          size='small'
                          {...selectProps}
                          placeholder={'Select Trailer'}
                          value={filterToTrailer}
                          options={toTrailerOptions}
                          onChange={(value) => {
                            setFilterToTrailer(value);
                            let findCompanyOptions = [];
                            for (let i = 0; i < value.length; i++) {
                              for (
                                let j = 0;
                                j < defaultTrailerOptions.length;
                                j++
                              ) {
                                if (
                                  value[i] === defaultTrailerOptions[j].value
                                ) {
                                  findCompanyOptions.push({
                                    label: defaultTrailerOptions[j].company,
                                    value: defaultTrailerOptions[j].company,
                                  });
                                }
                              }
                            }

                            findCompanyOptions = RemoveDuplicates.LISTKEY(
                              findCompanyOptions,
                              'value'
                            );
                            findCompanyOptions = Sorter.DEFAULTLIST(
                              findCompanyOptions,
                              'value'
                            );
                            setToCompanyOptions(findCompanyOptions);
                            if (!value.length) {
                              setToCompanyOptions(defaultCompanyOptions);
                            }
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Space>
              </SearchBox>

              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Region</Typography>
                    <Select
                      size='small'
                      {...selectSingleProps}
                      placeholder={'Select region'}
                      value={filterToRegion}
                      options={toRegionOptions}
                      onChange={(value) => {
                        setFilterToRegion(value);
                      }}
                      onSelect={(value) => {
                        checkRegion(value);
                      }}
                      onClear={() => {
                        resetReason();
                      }}
                    />
                  </div>

                  <div>
                    <Typography>Cluster</Typography>
                    <Select
                      size='small'
                      {...selectSingleProps}
                      placeholder={'Select cluster no.'}
                      value={filterToCluster}
                      options={toClusterOptions}
                      onChange={(value) => {
                        setFilterToCluster(value);
                      }}
                      onSelect={(value) => {
                        checkCluster(value);
                      }}
                      onClear={() => {
                        resetCluster();
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>
              <SearchBox style={{ marginLeft: '-5px' }}>
                <Space direction='vertical'>
                  <div>
                    <Typography>Destination Code</Typography>
                    <Select
                      size='small'
                      {...selectSingleProps}
                      placeholder={'Code'}
                      value={filterToDestinationsCode}
                      options={toDestinationsCodeOptions}
                      onChange={(value) => {
                        setFilterToDestinationsCode(value);
                      }}
                      onSelect={(value) => {
                        checkDestinationCode(value);
                      }}
                      onClear={() => {
                        checkResetCodeAndName();
                      }}
                    />
                  </div>
                  <div>
                    <Typography>Destination Name</Typography>
                    <Select
                      size='small'
                      {...selectSingleProps}
                      placeholder={'Name'}
                      value={filterToDestinationsName}
                      options={toDestinationsNameOptions}
                      onChange={(value) => {
                        setFilterToDestinationsName(value);
                      }}
                      onSelect={(value) => {
                        checkDestinationName(value);
                      }}
                      onClear={() => {
                        checkResetCodeAndName();
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>
              <SearchBox style={{ width: '374px' }}>
                <Space direction='vertical'>
                  <Row gutter={[24]} style={{ marginLeft: '-5px' }}>
                    <Col
                      span={7}
                      style={{
                        marginTop: 'auto',
                        marginBottom: 'auto',
                      }}
                    >
                      <Typography>Trailer status</Typography>
                    </Col>
                    <Col span={15} style={{ paddingLeft: 16 }}>
                      <Typography>Delivery status & Reason</Typography>
                    </Col>
                    <Row>
                      <Col>
                        <Row style={{ height: '44px' }}></Row>
                        <Box1 style={{ textAlign: 'left', padding: '3.43px' }}>
                          <Checkbox
                            style={{ fontSize: '13px' }}
                            indeterminate={
                              checkedOnTheWayList.length === 4
                                ? false
                                : checkedOnTheWayList.length > 0
                                ? true
                                : false
                            }
                            onChange={onCheckOnTheWayAllChange}
                            checked={
                              checkedOnTheWayList.length === 4 ? true : false
                            }
                          >
                            On the way
                          </Checkbox>
                        </Box1>

                        <Box1 style={{ textAlign: 'left', padding: '3.43px' }}>
                          <Checkbox
                            style={{ fontSize: '13px' }}
                            indeterminate={
                              checkedFinishedList.length === 4
                                ? false
                                : checkedFinishedList.length > 0
                                ? true
                                : false
                            }
                            onChange={onCheckFinishedAllChange}
                            checked={
                              checkedFinishedList.length === 4 ? true : false
                            }
                          >
                            Finished
                          </Checkbox>
                        </Box1>
                      </Col>

                      <Col>
                        <Box2>
                          <Typography>Normal</Typography>
                        </Box2>
                        <Box2 style={{ textAlign: 'center', padding: 3 }}>
                          <CheckboxGroup value={checkedOnTheWayList}>
                            <Checkbox
                              onChange={(value) => {
                                onChangeOnTheWay(
                                  value.target.checked,
                                  value.target.value
                                );
                              }}
                              // filterTrailerStatus: ['on_the_way', 'finished'],
                              // filterEvaOnTheWay: [],
                              // value={checkedOnTheWayList.find(v => v === 'normal') ? 'normal' : null}
                              value={'normal'}
                            ></Checkbox>
                          </CheckboxGroup>
                        </Box2>
                        <Box2 style={{ textAlign: 'center', padding: 3 }}>
                          <CheckboxGroup value={checkedFinishedList}>
                            <Checkbox
                              onChange={(value) => {
                                onChangeFinished(
                                  value.target.checked,
                                  value.target.value
                                );
                              }}
                              value='normal'
                            ></Checkbox>
                          </CheckboxGroup>
                        </Box2>
                      </Col>
                      <Col>
                        <Box2>
                          <Typography>Warning</Typography>
                        </Box2>
                        <Box2 style={{ textAlign: 'center', padding: 3 }}>
                          <CheckboxGroup value={checkedOnTheWayList}>
                            <Checkbox
                              onChange={(value) => {
                                onChangeOnTheWay(
                                  value.target.checked,
                                  value.target.value
                                );
                              }}
                              value='warning'
                            ></Checkbox>
                          </CheckboxGroup>
                        </Box2>
                        <Box2 style={{ textAlign: 'center', padding: 3 }}>
                          <CheckboxGroup value={checkedFinishedList}>
                            <Checkbox
                              onChange={(value) => {
                                onChangeFinished(
                                  value.target.checked,
                                  value.target.value
                                );
                              }}
                              value='warning'
                            ></Checkbox>
                          </CheckboxGroup>
                        </Box2>
                      </Col>
                      <Col>
                        <Row>
                          <Col span={24} align='center'>
                            <Box3 style={{ padding: '2.4' }}>
                              <Typography>Delay</Typography>
                            </Box3>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <Box3 style={{ padding: '2.4' }}>
                              <Typography>Confirm</Typography>
                            </Box3>
                            <Box3 style={{ textAlign: 'center', padding: 3 }}>
                              <CheckboxGroup value={checkedOnTheWayList}>
                                <Checkbox
                                  onChange={(value) => {
                                    onChangeOnTheWay(
                                      value.target.checked,
                                      value.target.value
                                    );
                                  }}
                                  value='false'
                                ></Checkbox>
                              </CheckboxGroup>
                            </Box3>
                            <Box3 style={{ textAlign: 'center', padding: 3 }}>
                              <CheckboxGroup value={checkedFinishedList}>
                                <Checkbox
                                  onChange={(value) => {
                                    onChangeFinished(
                                      value.target.checked,
                                      value.target.value
                                    );
                                  }}
                                  value='false'
                                ></Checkbox>
                              </CheckboxGroup>
                            </Box3>
                          </Col>
                          <Col>
                            <Box3 style={{ padding: '2.4' }}>
                              <Typography>Not Confirm</Typography>
                            </Box3>
                            <Box3 style={{ textAlign: 'center', padding: 3 }}>
                              <CheckboxGroup value={checkedOnTheWayList}>
                                <Checkbox
                                  onChange={(value) => {
                                    onChangeOnTheWay(
                                      value.target.checked,
                                      value.target.value
                                    );
                                  }}
                                  value='true'
                                ></Checkbox>
                              </CheckboxGroup>
                            </Box3>
                            <Box3 style={{ textAlign: 'center', padding: 3 }}>
                              <CheckboxGroup value={checkedFinishedList}>
                                <Checkbox
                                  onChange={(value) => {
                                    onChangeFinished(
                                      value.target.checked,
                                      value.target.value
                                    );
                                  }}
                                  value='true'
                                ></Checkbox>
                              </CheckboxGroup>
                            </Box3>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Row>
                </Space>
              </SearchBox>

              <SearchBox>
                <Space direction='vertical'>
                  <Row>
                    <Col>
                      <div style={{ marginTop: 17, textAlign: 'right' }}>
                        <Space>
                          <Switch
                            checkedChildren='Auto'
                            unCheckedChildren='Manual'
                            defaultChecked={auto}
                            onChange={(value) => {
                              setAuto(value);
                            }}
                          />
                          {auto && (
                            <InputNumber
                              size='small'
                              placeholder='mins.'
                              min={5}
                              max={60}
                              value={countdownMins}
                              onChange={(value) => {
                                setCountdownMins(value);
                              }}
                              formatter={(value) => `${value} mins`}
                              parser={(value) => value.replace(' mins', '')}
                            />
                          )}
                        </Space>
                        {auto && (
                          <StyledCountdown
                            style={{ textAlign: 'left', marginLeft: 40 }}
                            value={Date.now() + 1000 * 60 * countdownMins}
                            onFinish={onCountdownFinish}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div
                      style={{
                        textAlign: 'center',

                        marginLeft: '30px',
                        marginTop: !auto ? '30px' : 0,
                        marginRight: !auto ? '27px' : 0,
                      }}
                    >
                      <StyledButton
                        icon={<SearchOutlined />}
                        type='primary'
                        loading={isLoading}
                        onClick={onDisplayButton}
                      >
                        Search
                      </StyledButton>
                    </div>
                  </Row>
                </Space>
              </SearchBox>
            </Space>
          </Panel>
        </StyledCollapse>
      </Space>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  width: auto;
`;
const SearchBox = styled.div`
  font-size: 13px;
  display: block;
  padding: 0px 5px;
  border: 1px none #ececec;
  height: 125px;
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
const Box1 = styled.div`
  border: 1px #ececec;
  border-radius: 0 0 2px 2px;
  border: 1px solid #d9d9d9;
  padding: 2px 10px;
`;
const Box2 = styled.div`
  border: 1px #ececec;
  border-radius: 0 0 2px 2px;
  border: 1px solid #d9d9d9;
  padding: 11px 5px;
`;
const Box3 = styled.div`
  border: 1px #ececec;
  border-radius: 0 0 2px 2px;
  border: 1px solid #d9d9d9;
  padding: 0px 7px;
`;
const StyledCountdown = styled(Countdown)`
  &&& {
    .ant-statistic-content {
      font-size: 18px;
    }
  }
`;
export default TableToolbar;
