import React, { useEffect, useState, useRef } from 'react';
import BaseMap from 'digitalmap_components/Map/BaseMap';
import CarMarker from '../Map/CarMarker';
import TrackingPositionBox from './TrackingPositionBox';
import StartMarker from './StartMarker';
import RoutePolyline from './RoutePolyline';
import OrderMarker from './OrderMarker';
import { useDispatch } from 'react-redux';
import digitalMapUtils from '../Utils';

import { mapSetBounds } from 'reducers/map';
import { SET_MARKER_ORIGIN_DESC } from 'reducers/map';
const LocationMasterMap = (props) => {
  const { gpsData, panelData } = props;
  const dispatch = useDispatch();
  const mapRef = useRef(null);
  const [focusVehicle, setFocusVehicle] = useState(true);
  const [markerColor, setMarkerColor] = useState('green');

  useEffect(() => {
    if (
      gpsData &&
      panelData.routes.length > 0 &&
      panelData &&
      panelData.header &&
      panelData.header.trailer_status !== 'Finished' &&
      gpsData &&
      gpsData.latitude &&
      gpsData.longitude &&
      panelData.routes.find((r) => r.polyline !== null)
    ) {
      let waypoints = {
        lat: null,
        lng: null,
      };
      panelData.routes.forEach((r, index) => {
        if (r.latitude && r.longitude && r.polyline) {
          waypoints = {
            lat: r.latitude,
            lng: r.longitude,
          };
        }
      });

      let location_trailer = {
        lat: gpsData.latitude,
        lng: gpsData.longitude,
      };

      dispatch(
        SET_MARKER_ORIGIN_DESC({
          marker_origin: location_trailer,
          marker_desc: waypoints,
        })
      );

      let bounds = digitalMapUtils.setOdBounds(location_trailer, waypoints);
      setTimeout(() => {
        dispatch(mapSetBounds(bounds));
      }, 100);
    } else {
      let waypoints = {
        lat: null,
        lng: null,
      };
      panelData &&
        panelData.routes.forEach((r, index) => {
          if (r.latitude && r.longitude) {
            waypoints = {
              lat: r.latitude,
              lng: r.longitude,
            };
          }
        });

      let location_start = {
        lat: panelData && panelData.location_start_point.latitude,
        lng: panelData && panelData.location_start_point.longitude,
      };

      dispatch(
        SET_MARKER_ORIGIN_DESC({
          marker_origin: {
            lat: panelData && panelData.location_start_point.latitude,
            lng: panelData && panelData.location_start_point.longitude,
          },
          marker_desc: waypoints,
        })
      );

      let bounds = digitalMapUtils.setOdBounds(location_start, waypoints);

      setTimeout(() => {
        dispatch(mapSetBounds(bounds));
      }, 100);
    }
  }, [dispatch, gpsData, panelData]);

  useEffect(() => {
    if (mapRef.current && gpsData && focusVehicle) {
      mapRef.current.panTo({
        lat:
          gpsData && gpsData.latitude
            ? gpsData.latitude
            : panelData && panelData.location_start_point.latitude,
        lng:
          gpsData && gpsData.longitude
            ? gpsData.longitude
            : panelData && panelData.location_start_point.longitude,
      });
    }
  }, [gpsData, focusVehicle, panelData]);

  useEffect(() => {
    if (panelData?.header?.delivery_status) {
      switch (panelData?.header?.delivery_status) {
        case 'delay':
          setMarkerColor('red');
          break;
        case 'warning':
          setMarkerColor('yellow');
          break;
        default:
          setMarkerColor('green');
          break;
      }
    } else {
      setMarkerColor('grey');
    }
  }, [panelData]);

  return (
    <BaseMap
      {...props}
      onDragEnd={() => {
        setFocusVehicle(false);
      }}
      onLoad={(map) => {
        mapRef.current = map;
        if (gpsData && gpsData.latitude && gpsData.longitude) {
          setTimeout(() => {
            mapRef.current.panTo({
              lat: gpsData.latitude,
              lng: gpsData.longitude,
            });
          }, 200);
        }
      }}
    >
      <TrackingPositionBox focus={focusVehicle} setFocus={setFocusVehicle} />
      {panelData &&
        panelData.header &&
        panelData.header.trailer_status !== 'Finished' &&
        gpsData &&
        gpsData.latitude &&
        gpsData.longitude && (
          <CarMarker {...props.gpsData} color={markerColor} />
        )}
      {panelData && panelData.location_start_point && (
        <StartMarker location={panelData && panelData.location_start_point} />
      )}
      {panelData &&
        panelData.truck_schedules &&
        panelData.truck_schedules.map((m, index) => (
          <OrderMarker
            key={index}
            title={index + 1}
            info={m.location}
            location={{ lat: m.location.lat, lng: m.location.lng }}
          />
        ))}

      <RoutePolyline
        routes={panelData && panelData.routes}
        gpsPoint={
          gpsData
            ? gpsData.latitude && gpsData.longitude
              ? [gpsData.latitude, gpsData.longitude]
              : null
            : null
        }
      />
    </BaseMap>
  );
};

export default LocationMasterMap;
