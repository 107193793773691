import React, { useState, useEffect } from 'react';
import { setSidebarVisibility } from 'reducers/ui';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Spin } from 'antd';
import BaseMap from './Mapview';
import InfoPanel from './InfoPanel';
import { getCurrentUser } from 'reducers/user';
import { useSelector } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { useFindTruckScheduleByIdQuery } from 'reducers/digitalmap/operationDashboard/api';

const JobTrackingMapview = (props) => {
  const { truckScheduleId, orderId } = useParams();
  const [gpsData, setGpsData] = useState(null);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const [min, setMin] = useState(60000);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const { data, error, isFetching } = useFindTruckScheduleByIdQuery(
    truckScheduleId,
    {
      skip: !user || !truckScheduleId,
      pollingInterval: min, //1 min
    }
  );

  useEffect(() => {
    if (
      data &&
      data.traffic_polyline &&
      data.traffic_polyline.vehicleLocation
    ) {
      let location = {
        blackBoxId: data.delivery_realtime_tracking.black_box_id,
        latitude: data.traffic_polyline.vehicleLocation.lat,
        longitude: data.traffic_polyline.vehicleLocation.lng,
        ts: data.traffic_polyline.vehicleLocation.timestamp,
        direction: data.traffic_polyline.vehicleLocation.direction,
      };
      setGpsData(location);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  const updateMin = (value) => {
    if (value === 0) {
      setMin(0);
    }
    if (value === 60000) {
      setMin(60000);
    }
  };

  return (
    <SplitPane split='vertical'>
      <Pane initialSize='30%'>
        <div style={{ height: 'calc(100vh - 48px)', overflowY: 'auto' }}>
          <div
            style={{
              height: 'calc(100vh - 48px)',
              overflowY: 'auto',
              background: 'white',
            }}
          >
            {isFetching === true ? (
              <RootCircular>
                <CenterSpin indicator={antIcon} size='large' />
              </RootCircular>
            ) : (
              <InfoPanel
                panelData={data}
                gpsData={gpsData}
                orderId={orderId}
                polling={updateMin}
              />
            )}
          </div>
        </div>
      </Pane>
      <Pane>
        <BaseMap gpsData={gpsData} panelData={data} />
      </Pane>
    </SplitPane>
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default JobTrackingMapview;
