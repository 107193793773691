import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const DnListSlice = createSlice({
  name: 'dn_list',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setDnListTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setDnListDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setDnListFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setDnListFilter,
  setCurrentPage,
  setCurrentOffset,
  setDnListDataSource,
  setDnListTotalRows,
  setDnListCompleteCount,
} = DnListSlice.actions;

export const DnListSelector = (state) => state.DnList;

export default DnListSlice.reducer;
