import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader, Divider } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import './index.css';
import TableOverAll from './TableOverAll';
import TableSummaryDetail from './TableSummaryDetail';

const SummaryAllocationComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Summary Allocation'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <TableOverAll />
        <TableSummaryDetail />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default SummaryAllocationComponent;
