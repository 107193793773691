import React, { Component } from 'react';
import { connect } from 'react-redux';
import WpPolyline from './WpPolyline';
import WpMarker from './WpMarker';

class Waypoints extends Component {
  render() {
    let totalDistance = 0;
    let totalETA = 0;
    let infoDistance = 0;
    let infoETA;
    return (
      <React.Fragment>
        {this.props.legs &&
          this.props.legs.map((leg, index) => {
            return <WpPolyline path={leg.path} key={index} id={index} />;
          })}

        {this.props.waypoints &&
          this.props.waypoints.map((waypoint, index) => {
            let isFirst = false;
            let isLast = false;

            if (index === this.props.waypoints.length - 1) {
              isLast = true;
            }
            if (index === 0) {
              isFirst = true;
            }

            if (this.props.legs && this.props.legs.length > 0 && index > 0) {
              try {
                totalDistance =
                  totalDistance +
                  Number(this.props.modified_route.Legs[index - 1].Distance);
                totalETA =
                  totalETA +
                  Number(this.props.modified_route.Legs[index - 1].ETA);

                infoDistance = (totalDistance / 1000).toFixed(2) + ' KM';
                infoETA = totalETA + ' min';
              } catch (e) {
                infoDistance = (totalDistance / 1000).toFixed(2) + ' KM';
                infoETA = totalETA + ' min';
              }
            } else {
              infoDistance = '0 KM';
              infoETA = '0 min';
            }

            return (
              <WpMarker
                key={index}
                id={index}
                isFirst={isFirst}
                isLast={isLast}
                defaultDraggable={
                  isFirst || isLast || this.props.map_loading ? false : true
                }
                infoDistance={infoDistance}
                infoETA={infoETA}
                position={waypoint.position}
                isGroup={waypoint.isGroup}
                description={waypoint.description}
              />
            );
          })}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    markers: state.map.markers,
    legs: state.map.legs,
    modified_route: state.map.modified_route,
    map_loading: state.map.map_loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Waypoints);
