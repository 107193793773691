import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import Root from './Root';

// Add MSW during development
if (process.env.NODE_ENV === 'development') {
  // const { worker } = require('./mocks/browser');
  // worker.start();

  serviceWorker.unregister(); //pro
} else {
  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister(); //pro
}

ReactDOM.render(<Root />, document.getElementById('root'));
