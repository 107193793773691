import React from 'react';
import { connect } from 'react-redux';
import ImCircle from './ImCircle';
import ImPolygon from './ImPolygon';
import MarkerLocation from './MarkerLocation';

const LocationMaster = (props) => {
  return (
    <div>
      {props.markerList.length > 0 &&
        props.markerList.map((marker, index) => {
          return (
            <MarkerLocation
              key={marker.id}
              id={marker.id}
              position={marker.position}
              info={marker.info}
              editable={marker.editable}
              isGroup={marker.isGroup}
            />
          );
        })}
      {props.circleList &&
        props.circleList.length > 0 &&
        props.circleList.map((circle, index) => {
          return (
            <ImCircle
              key={circle.id}
              id={circle.id}
              center={circle.center}
              color='blue'
              info={circle.info}
              radius={circle.radius}
              editable={circle.editable}
            />
          );
        })}
      {props.polygonList &&
        props.polygonList.length > 0 &&
        props.polygonList.map((polygon, index) => {
          return (
            <ImPolygon
              key={polygon.id}
              id={polygon.id}
              path={polygon.path}
              editable={polygon.editable}
              info={polygon.info}
            />
          );
        })}
    </div>
  );
};

const mapStateToProps = function (state) {
  return {
    markerList: state.map.markerList,
    circleList: state.map.circleList,
    polygonList: state.map.polygonList,
  };
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(LocationMaster);
