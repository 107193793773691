import moment from 'moment-timezone';

/**
 * @param {string} dateA - a date, represented in string format
 * @param {string} dateB - a date, represented in string format
 */
const dateSort = (dateA, dateB) => moment(dateA).diff(moment(dateB));

/**
 *
 * @param {number|string} a
 * @param {number|string} b
 */
const defaultSort = (a, b) => {
  if (a < b) return -1;
  if (b < a) return 1;
  return 0;
};

const defaultSortList = (list, key) => {
  if (list) {
    return list.sort((a, b) => {
      if (a[key] < b[key]) return -1;
      if (a[key] > b[key]) return 1;
      return 0;
    });
  }
};

const defaultSortListDes = (list) => {
  if (list) {  
    return list.sort((a, b) => {
        let indexA = list.indexOf(a);
        let indexB = list.indexOf(b);
        if (indexA > indexB) {
            return -1;
        }
        if (indexA < indexB) {
            return 1;
        }
        return 0;
    });
  };
}

export const Sorter = {
  DEFAULT: defaultSort,
  DATE: dateSort,
  DEFAULTLIST: defaultSortList,
  DEFAULTLISTDES: defaultSortListDes,
};
