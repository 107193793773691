import React, { useRef, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Typography,
  message,
  Collapse,
  Divider,
  Modal,
} from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ExclamationCircleOutlined, PrinterOutlined } from '@ant-design/icons';
import {
  setManageCallingFilter,
  setManageCallingDataSourceForModeCreateNewCalling,
  setManageCallingDataSourceForModeAddVin,
} from 'reducers/manageCalling';

const { Panel } = Collapse;
const { confirm } = Modal;
const Toolbar = (props) => {
  const {
    showFilter,
    setShowFilter,
    loadingParkingLane,
    loadingSearchCallingNo,
    loadingLocationFrom,
    //filters
    filterFrom,
    filterOptionFrom,
    setFilterFrom,
    filterCallingno,
    setFilterCallingno,
    filterCluster,
    setFilterCluster,
    //data
    dataSource,
    setDataSource,
    //function
    onSearchCallingNo,
    showNotFoundCallingNo,
    checkoutDataSource,
    checkCallingTrDv,
    getDataPrintCalling,
    //getParkingLaneData,
    param_mode,
    setparam_mode,
    setparam_calling_no,
    setparam_from,
    setparam_filterfrom,
    showModalSave,
    isLoadingCheckCalling,
    loadingCreateManageCalling,
    onClearAllDataSource,
  } = props;
  const dispatch = useDispatch();
  const { Option } = Select;
  const fromOldRef = useRef(filterFrom);

  const storedFilters = useSelector((state) => {
    return state.manageCalling.filters;
  });

  useEffect(() => {
    if (!fromOldRef.current) {
      fromOldRef.current = filterFrom;
    }
  }, [filterFrom]);

  const onClickCreateNewCalling = async () => {
    setparam_mode('CREATE_NEW_CALLING_FIRST');
    setparam_calling_no('NO');
    setparam_from('MANAGE_CALLING');
    setparam_filterfrom(filterFrom);
    dispatch(setManageCallingDataSourceForModeCreateNewCalling([]));
    dispatch(setManageCallingDataSourceForModeAddVin([]));

    setTimeout(() => {
      if (!filterCallingno && filterFrom !== '') {
        dispatch(
          push(
            `/app/delivery_mng/vins/managecalling/create_new_calling/calling_no/${
              filterFrom ? filterFrom : 'from'
            }`
          )
        );
      } else {
        message.warning('Please clear calling no for create new calling.');
      }
    }, 1000);
  };

  const onClickAddvin = async () => {
    setparam_mode('ADD_VIN');
    setparam_calling_no(filterCallingno);
    setparam_from('MANAGE_CALLING');
    setparam_filterfrom(filterFrom);
    dispatch(
      setManageCallingFilter({
        ...storedFilters,
        filterFromOld: fromOldRef.current,
      })
    );

    setTimeout(() => {
      if (filterCallingno) {
        dispatch(
          push(
            `/app/delivery_mng/vins/managecalling/addVin/${
              filterCallingno ? filterCallingno : 'calling_no'
            }/${filterFrom ? filterFrom : 'from'}`
          )
        );
      } else {
        message.warning('Please clear calling no for add vin calling.');
      }
    }, 1000);
  };

  const onClickEditvin = async () => {
    setparam_mode('EDIT_VIN');
    setparam_calling_no(filterCallingno);
    setparam_from('MANAGE_CALLING');
    setparam_filterfrom(filterFrom);

    dispatch(
      setManageCallingFilter({
        ...storedFilters,
        filterFromOld: fromOldRef.current,
      })
    );

    setTimeout(() => {
      if (filterCallingno) {
        dispatch(
          push(
            `/app/delivery_mng/vins/managecalling/editVin/${
              filterCallingno ? filterCallingno : 'calling_no'
            }/${filterFrom ? filterFrom : 'from'}`
          )
        );
      } else {
        message.warning('Please clear calling no for Edit vin calling.');
      }
    }, 1000);
  };

  //------------------------------------------------------------------------------------
  // onSearch
  const onSearch = async () => {
    if (filterCallingno) {
      // setIsLoadingSearchCallingNo(true);
      let payload = {
        calling_no: filterCallingno ? filterCallingno : '',
      };
      onSearchCallingNo(payload);
    } else {
      showNotFoundCallingNo();
    }
  };

  //------------------------------------------------------------------------------------
  function onClickPrintDN() {
    if (filterCallingno) {
      showConfirmPrintDN();
    } else {
      message.error('Calling no is null.');
    }
  }
  function showConfirmPrintDN() {
    if (filterCallingno !== '') {
      confirm({
        title: 'Would you like to print out the DN Sheet ?',
        icon: <ExclamationCircleOutlined />,
        content: `Calling No. : ${filterCallingno}`,
        onOk() {
          checkCallingTrDv();
        },
        onCancel() {},
      });
    } else {
      message.error('Something went wrong.');
    }
  }
  const handleChangeLocationFrom = (newValue) => {
    if (newValue) {
      setDataSource(
        dataSource.map((d) => ({ ...d, parking_lane: { id: '', label: '' } }))
      );
    }
    // Store from_old
    fromOldRef.current = filterFrom;
    setFilterFrom(newValue);
  };

  //model
  function showConfirmPrintCalling() {
    if (filterCallingno !== '') {
      confirm({
        title: 'Would you like to pring out the Calling Sheet ?',
        icon: <ExclamationCircleOutlined />,
        content: `Calling No. : ${filterCallingno}`,
        onOk() {
          getDataPrintCalling(filterCallingno);
        },
        onCancel() {},
      });
    } else {
      message.error('Something went wrong.');
    }
  }

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      onSearch()
    }
  };

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[22]}
            style={{ marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='start'>
              <Typography>Calling No.</Typography>
            </Col>
            <Col span={4}>
              <Input
                style={{
                  align: 'center',
                }}
                size='small'
                placeholder='Code'
                allowClear
                value={filterCallingno}
                onChange={(e) => {
                  setFilterCallingno(e.target.value.trim());
                }}
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              />
            </Col>

            <Col span={2}>
              <Button
                style={{ width: '110px', textAlign: 'center' }}
                type='primary'
                onClick={onSearch}
                loading={loadingSearchCallingNo}
                disabled={filterCallingno === ''}
              >
                Search
              </Button>
            </Col>
            <Col span={2} align='end'>
              <Typography>From</Typography>
            </Col>
            <Col span={3}>
              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                maxTagCount='responsive'
                placeholder={'From'}
                loading={loadingLocationFrom}
                disabled={loadingParkingLane}
                value={filterFrom}
                onChange={(value) => {
                  if (value !== undefined) {
                    handleChangeLocationFrom(value);
                  } else {
                    setFilterFrom(undefined);
                  }
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionFrom &&
                  filterOptionFrom.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={2} align='end'>
              <Typography>Cluster</Typography>
            </Col>
            <Col span={3}>
              <Input
                style={{
                  width: '100%',
                  align: 'center',
                }}
                size='small'
                placeholder='Code'
                allowClear
                disabled
                value={filterCluster}
                onChange={(e) => {
                  setFilterCluster(e.target.value);
                }}
              />
            </Col>
            <Col span={3} align='end'>
              <Button
                style={{ alignContent: 'center' }}
                type='primary'
                onClick={onClickCreateNewCalling}
                loading={loadingParkingLane}
                disabled={
                  !filterFrom ||
                  filterCallingno ||
                  param_mode === 'CREATE_NEW_CALLING' ||
                  loadingParkingLane
                }
              >
                Create New Calling
              </Button>
            </Col>
          </Row>
          <Divider style={{ margin: '0px' }}></Divider>
          <Row
            gutter={[22]}
            style={{ marginTop: '10px' }}
            align='center'
            justify='center'
          >
            <Col span={3} align='start'>
              <Button
                type='primary'
                onClick={showConfirmPrintCalling}
                disabled={filterCallingno === '' || checkoutDataSource !== true}
                icon={<PrinterOutlined />}
              >
                Print Calling
              </Button>
            </Col>
            <Col span={3} align='start'>
              <Button
                type='primary'
                onClick={onClickPrintDN}
                loading={isLoadingCheckCalling}
                disabled={filterCallingno === '' || checkoutDataSource !== true}
                icon={<PrinterOutlined />}
              >
                Print DN
              </Button>
            </Col>
            <Col span={2} align='start'>
              <Button
                type='primary'
                onClick={onClearAllDataSource}
                loading={loadingParkingLane}
              >
                Clear All
              </Button>
            </Col>

            <Col span={5}></Col>
            <Col span={3} align='end'>
              <Button
                type='primary'
                disabled={filterCallingno === '' || checkoutDataSource !== true}
                onClick={onClickEditvin}
              >
                Edit Vin
              </Button>
            </Col>
            <Col span={3} align='end'>
              <Button
                type='primary'
                disabled={filterCallingno === '' || checkoutDataSource !== true}
                onClick={onClickAddvin}
              >
                Add Vin
              </Button>
            </Col>
            <Col span={3} align='end'>
              <Button
                style={{ width: '150px' }}
                type='primary'
                loading={loadingCreateManageCalling}
                disabled={
                  (dataSource?.length === 0 && filterCallingno === '') ||
                  dataSource?.length === 0
                }
                onClick={showModalSave}
              >
                Save
              </Button>
            </Col>
            <Col span={1} align='center'></Col>
          </Row>
        </Panel>
      </StyledCollapse>

      {/* model */}
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default Toolbar;
