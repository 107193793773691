import React, { useState, useEffect } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { connect } from 'react-redux';

import {
  MARKER_MOVE,
  MARKER_DRAGGING,
  MARKER_SELECTING,
  DEL_MARKER,
} from 'reducers/map';

const WpMarker = (props) => {
  const { id } = props;
  const [selected, setSelected] = useState(false);

  useEffect(() => {
    if (props.marker_selecting === id) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [props.marker_selecting, id]);

  const selectInfo = (arg) => {
    props.MARKER_SELECTING(props.id);
  };

  const onDragStart = (arg) => {
    closeInfo();
    props.MARKER_DRAGGING(props.id);
  };

  const onDragEnd = (e) => {
    props.MARKER_MOVE({
      ...props,
      position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    });
  };

  const closeInfo = () => {
    props.MARKER_SELECTING(-1);
    setSelected(false);
  };

  let icon_url;
  let label = '';
  let size = new window.google.maps.Size(40, 40);

  //https://sites.google.com/site/gmapicons/home/
  if (props.isFirst) {
    icon_url = 'http://www.google.com/mapfiles/dd-start.png';
    size = new window.google.maps.Size(22, 36);
  } else if (props.isLast) {
    icon_url = 'http://www.google.com/mapfiles/dd-end.png';
    size = new window.google.maps.Size(22, 36);
  } else if (props.marker_MovingAdding === id) {
    icon_url = 'http://maps.google.com/mapfiles/ms/icons/red.png';
    label = id.toString();
  } else {
    icon_url = 'http://maps.google.com/mapfiles/ms/icons/blue.png';
    label = id.toString();
  }

  return (
    <Marker
      draggable={true}
      position={props.position}
      label={label}
      icon={{
        url: icon_url,
        labelOrigin: new window.google.maps.Point(19, 14),
        scaledSize: size,
        size: size,
      }}
      key={props.key}
      defaultDraggable={props.defaultDraggable}
      onClick={selectInfo}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onUnmount={() => {
        setSelected(false);
      }}
    >
      {selected ? (
        <InfoWindow onCloseClick={closeInfo}>
          <div>
            <div>
              <div>Distance : {props.infoDistance}</div>
              <div>ETA : {props.infoETA}</div>
            </div>
            <div>
              {!(props.isFirst || props.isLast) && (
                <button onClick={() => props.DEL_MARKER(id)}>Delete</button>
              )}
            </div>
          </div>
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

const mapStateToProps = (state) => {
  return {
    marker_selecting: state.map.marker_selecting,
    marker_MovingAdding: state.map.marker_MovingAdding,
    marker_Dragging: state.map.marker_MovingAdding,
  };
};

const mapActionsToProps = {
  MARKER_MOVE: MARKER_MOVE,
  MARKER_DRAGGING: MARKER_DRAGGING,
  MARKER_SELECTING: MARKER_SELECTING,
  DEL_MARKER: DEL_MARKER,
};

export default connect(mapStateToProps, mapActionsToProps)(WpMarker);
