import React, { useEffect, useState, useRef } from 'react';
import {
  PageHeader,
  Divider,
  Typography,
  Row,
  Col,
  Space,
  Button,
  Modal,
  Form,
  DatePicker,
  message,
  Tooltip,
} from 'antd';
import Draggable from 'react-draggable';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { goBack } from 'connected-react-router';
import styled from 'styled-components';
import { mapResetMap } from 'reducers/map';
import {
  EnvironmentTwoTone,
  ExclamationCircleTwoTone,
  ExclamationCircleOutlined,
  WarningTwoTone,
} from '@ant-design/icons';
import {
  useUpdateTruckSchedulesMutation,
  tttOperationDashboardApi,
  useDeleteRealtimeTrackingByIdMutation,
} from 'reducers/digitalmap/tttOperationDashboard/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { setTTTDigitalMap } from 'reducers/digitalmap/tttOperationDashboard';
import { getLanguage } from 'reducers/language';
import FormReason from '../TTTJobMonitoring/List/FormReason';
const { Text, Title } = Typography;

const InfoPanel = (props) => {
  const { panelData, polling, refetch, realtimeTrackingId } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);
  const { setErrorData } = useErrorHandler();
  const [idIdTruckSchedule, setIdTruckSchedule] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalReasonVisible, setIsModalReasonVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const draggleRef = useRef(null);
  const [disabled, setDisabled] = useState(false);
  const [actualDeparture, setActualDeparture] = useState(false);
  const [actualArrival, setActualArrival] = useState(false);

  const [
    deleteRealtimeTrackingById,
    { isSuccess: isSuccessDeleting, error: errorDeleting },
  ] = useDeleteRealtimeTrackingByIdMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  useEffect(() => {
    if (isSuccessDeleting) {
      message.success('Data was successfully deleted');
      setTimeout(() => {
        dispatch(goBack());
      }, 1000);
    }
  }, [dispatch, isSuccessDeleting]);

  const validateDatetime = () => {
    if (!actualArrival || !actualDeparture) {
      message.error('Please input datetime range');
      return false;
    }

    if (
      !moment(actualDeparture, 'minute').isSameOrAfter(
        moment(actualArrival, 'minute').add(10, 'm')
      )
    ) {
      message.error(
        'Please input actual departure time more than >10 minutes of actual arrival time.'
      );

      return false;
    }

    if (
      !moment().isSameOrAfter(moment(actualDeparture)) ||
      !moment().isSameOrAfter(moment(actualArrival))
    ) {
      message.error('Datetime range is invalid');
      return false;
    }

    // if (
    //   !moment(actualArrival).isSameOrAfter(
    //     moment(panelData.header.yard_out_time)
    //   )
    // ) {
    //   message.error(
    //     'Please input actual arrival time more than yard out time.'
    //   );
    //   return false;
    // }

    return true;
  };
  const [
    updateUpdateTruckSchedules,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
    },
  ] = useUpdateTruckSchedulesMutation();

  useEffect(() => {
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, setErrorData]);

  useEffect(() => {
    if (!isUninitUpdating && isSuccessUpdating) {
      message.success('Data was successfully saved');
      if (isSuccessUpdating) {
        setIsModalVisible(false);
        form.resetFields();
        dispatch(setTTTDigitalMap(true));
      }
    }
  }, [dispatch, form, isSuccessUpdating, isUninitUpdating]);

  const refreshData = () => {
    setIsModalReasonVisible(false);
    form.resetFields();
    refetch();
    dispatch(setTTTDigitalMap(true));
  };
  const showModal = (id) => {
    polling(0);
    setIdTruckSchedule(id);
    setIsModalVisible(true);
  };
  const cancelModalConfirm = () => {
    polling(0);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    polling(60000);
    form.resetFields();
    setIsModalVisible(false);
  };
  const handleCancelReason = () => {
    polling(60000);
    form.resetFields();
    setIsModalReasonVisible(false);
  };

  const onFinish = async () => {
    if (!validateDatetime()) {
      return;
    } else {
      try {
        await form.validateFields();
        setIsModalVisible(false);
        let validData = form.getFieldsValue(true);
        Modal.confirm({
          title:
            'Do you want to confirm for actual arrival and actual departure?',
          icon: <ExclamationCircleOutlined />,
          content: (
            <>
              Actual Arrival :{' '}
              {validData.actual_human_arrival_time
                ? moment(validData.actual_human_arrival_time).format(
                    'DD/MM/YYYY HH:mm'
                  )
                : null}
              <br />
              Actual Departure :{' '}
              {validData.actual_human_departure_time
                ? moment(validData.actual_human_departure_time).format(
                    'DD/MM/YYYY HH:mm'
                  )
                : null}
              <br />
            </>
          ),
          onOk: handleConfirmAction,
          onCancel: cancelModalConfirm,
          cancelText: 'Cancel',
          okText: 'Confirm',
          width: 500,
        });
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    }
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let postActualHumanArrivalTime = moment(validData.actual_human_arrival_time)
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:00');

    let postActualHumanDepartureTime = moment(
      validData.actual_human_departure_time
    )
      .clone()
      .utc()
      .format('YYYY-MM-DD HH:mm:00');

    let postData = {
      id: idIdTruckSchedule,
      ...validData,
      actual_human_arrival_time: postActualHumanArrivalTime,
      actual_human_departure_time: postActualHumanDepartureTime,
    };
    updateUpdateTruckSchedules(postData);
  };

  const onStart = (event, uiData) => {
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
  };

  const showModalReason = async (value) => {
    let newData = null;
    try {
      if (value.reason && value.reason.id) {
        let result = await dispatch(
          tttOperationDashboardApi.endpoints.findTTTReasonMasterById.initiate(
            value.reason && value.reason.id
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            newData = {
              ...value,
              nameGroup: `(${result.data.subject_reason.group.code}) ${result.data.subject_reason.group.name}`,
              groupCode: result.data.subject_reason.group.code,
              nameSubject: `(${value.reason.subject_reason.code}) ${
                language === 'th'
                  ? value.reason.subject_reason.name_th
                  : value.reason.subject_reason.name_en
              }`,
            };
          }
        }
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
    setFormData(newData ? newData : value);

    setIsModalReasonVisible(true);
  };

  return (
    <>
      <StyledPageHeader
        ghost={false}
        onBack={() => {
          dispatch(mapResetMap());
          setTimeout(() => {
            polling(0);
            dispatch(goBack());
          }, 100);
        }}
        title={
          <Text style={{ fontSize: '24px' }}>TTT Tracking Dashboard </Text>
        }
        extra={[
          <Button
            key='tracking-delete-btn'
            type='primary'
            danger
            onClick={() => {
              Modal.confirm({
                title: 'Are you sure want to delete?',
                icon: <ExclamationCircleOutlined />,
                okButtonProps: {
                  style: { backgroundColor: 'red', borderColor: 'red' },
                },
                okText: 'Confirm',
                cancelText: 'Cancel',
                onOk: () => {
                  deleteRealtimeTrackingById(realtimeTrackingId);
                },
              });
            }}
          >
            Delete
          </Button>,
        ]}
      >
        {panelData && panelData.header && (
          <>
            <Row gutter={[16, 16]}>
              <Col flex={2}>
                <Title
                  level={3}
                  style={{ marginBottom: '0px', color: '#3F49F5' }}
                >
                  {panelData.header.job_dashboard}
                </Title>
                <Space>
                  <StyledText>{`Last Update : `}</StyledText>
                  <StyledText>
                    {`${
                      panelData.header.latest_update
                        ? moment(panelData.header.latest_update).format(
                            'DD/MM/YYYY HH:mm'
                          )
                        : '-'
                    }`}
                  </StyledText>
                </Space>

                <Row gutter={[16, 16]}>
                  <StyledCol xs={12}>
                    {`Route Group : `}
                    <StyledText>
                      {panelData.header.route_group
                        ? panelData.header.route_group
                        : '-'}
                    </StyledText>
                  </StyledCol>
                  <StyledCol xs={12}>
                    {`Cluster : `}
                    <StyledText>{panelData.header.cluster_name}</StyledText>
                  </StyledCol>
                </Row>
                <Row gutter={[16, 16]}>
                  <StyledCol xs={12}>
                    {`Sub-Route : `}
                    <StyledText>
                      {panelData.header.sub_group
                        ? panelData.header.sub_group
                        : '-'}
                    </StyledText>
                  </StyledCol>

                  <StyledCol xs={12}>
                    {`Trailer Code : `}
                    <StyledText>{panelData.header.trailer_code}</StyledText>
                  </StyledCol>
                </Row>
              </Col>
              <Col flex={1}>
                <Title level={1} style={{ marginBottom: '0px' }}>
                  {panelData.header.departure_from}
                </Title>

                <StyledText style={{ color: '#3B7BF5' }}>
                  {`Yard Out Time : `}{' '}
                  {`${
                    panelData.header.yard_out_time
                      ? moment(panelData.header.yard_out_time).format(
                          'DD/MM HH:mm'
                        )
                      : '-'
                  }`}
                </StyledText>
              </Col>
            </Row>
            {panelData &&
              panelData.truck_schedules &&
              panelData.truck_schedules.map((t, index) => (
                <div key={index}>
                  <StyledDivider
                    orientation='left'
                    style={{ fontSize: '16' }}
                  ></StyledDivider>
                  <Row style={{ marginTop: 25 }}>
                    <Text style={{ fontSize: '18px' }} strong>
                      <EnvironmentTwoTone style={{ fontSize: '18px' }} />{' '}
                      {t.location.short_name}
                    </Text>
                  </Row>

                  <Row gutter={[16, 16]}>
                    <Col style={{ width: 320, marginRight: '-45px' }}>
                      <Row gutter={[16, 13]}>
                        <StyledCol xs={12}>VIN No.</StyledCol>
                        <StyledCol xs={12} style={{ paddingLeft: 0 }}>
                          Model Code
                        </StyledCol>
                      </Row>
                      {t.order_truck_schedule.map((o, index2) => (
                        <div key={index2}>
                          <Row gutter={[16, 13]}>
                            <StyledCol xs={12}>
                              <Text strong>{o.vin_no}</Text>
                            </StyledCol>
                            <StyledCol xs={12} style={{ paddingLeft: 0 }}>
                              <Text strong>{o.model}</Text>
                            </StyledCol>
                          </Row>
                          <StyledDivideTruck
                            orientation='left'
                            style={{ fontSize: '16' }}
                          ></StyledDivideTruck>
                        </div>
                      ))}
                    </Col>
                    <Col flex='1 1 322px'>
                      <Row gutter={[16, 16]}>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          Original ETA
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          Update ETA
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          Diff
                        </StyledCol>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          <Text strong>
                            {moment(t.original_eta).format('DD/MM/YYYY HH:mm')}
                          </Text>
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          <Text strong>
                            {t.update_eta
                              ? moment(t.update_eta).format('DD/MM/YYYY HH:mm')
                              : '-'}
                          </Text>
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          <Text strong>{t.diff_1 ? t.diff_1 : '+/-'}</Text>
                        </StyledCol>
                      </Row>
                      <br />
                      <Row gutter={[16, 16]}>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          Actual Arrival
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          Diff
                        </StyledCol>
                        <StyledCol
                          style={{
                            fontSize: 13,
                            paddingLeft: 'unset',
                            paddingRight: 'unset',
                          }}
                          align={'center'}
                          xs={8}
                        >
                          Actual Departure
                        </StyledCol>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          <Text strong>
                            {!t.input_ata_status ? (
                              <Text
                                strong
                                type={(() => {
                                  if (t.actual_arrival) {
                                    return 'success';
                                  } else {
                                    return 'default';
                                  }
                                })()}
                              >
                                {t.actual_arrival
                                  ? moment(t.actual_arrival).format(
                                      'DD/MM/YYYY HH:mm'
                                    )
                                  : '-'}
                              </Text>
                            ) : (
                              <>
                                <Space>
                                  <ExclamationCircleTwoTone
                                    style={{ fontSize: 13, marginTop: 5 }}
                                    twoToneColor='red'
                                  />

                                  <Button
                                    size='small'
                                    style={{
                                      backgroundColor: '#ffccc7',
                                      width: 70,
                                    }}
                                    danger
                                    onClick={() => showModal(t.id)}
                                  >
                                    &nbsp;
                                  </Button>
                                </Space>
                              </>
                            )}
                          </Text>
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          <Text strong>{t.diff_2 ? t.diff_2 : '+/-'}</Text>
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={8}
                        >
                          {!t.input_ata_status ? (
                            <Text
                              strong
                              type={(() => {
                                if (t.actual_arrival) {
                                  return 'success';
                                } else {
                                  return 'default';
                                }
                              })()}
                            >
                              {t.actual_departure
                                ? moment(t.actual_departure).format(
                                    'DD/MM/YYYY HH:mm'
                                  )
                                : '-'}
                            </Text>
                          ) : (
                            <>
                              <Space>
                                <Button
                                  size='small'
                                  style={{
                                    backgroundColor: '#ffccc7',
                                    width: 70,
                                  }}
                                  danger
                                  onClick={() => showModal(t.id)}
                                >
                                  &nbsp;
                                </Button>
                              </Space>
                            </>
                          )}
                        </StyledCol>
                      </Row>
                      <Row gutter={[16, 16]}>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={5}
                        ></StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={13}
                        >
                          {t.reason_symbol === 'delay' ? (
                            <>
                              <Space>
                                <WarningTwoTone
                                  style={{ fontSize: 18, top: 10 }}
                                  twoToneColor='red'
                                />
                                <Button
                                  size='small'
                                  style={{
                                    backgroundColor: '#ffccc7',
                                    width: 80,
                                  }}
                                  danger
                                  onClick={() => showModalReason(t)}
                                >
                                  &nbsp;
                                </Button>
                                {/* <Button
                                  size='small'
                                  style={{
                                    backgroundColor: '#ffccc7',
                                    width: '70px',
                                    height: 20,
                                    top: 1,
                                  }}
                                  danger
                                  onClick={() => showModalReason(t)}
                                >
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </Button> */}
                              </Space>
                            </>
                          ) : t.reason_symbol === 'show' ? (
                            <div
                              style={{
                                marginLeft: 20,
                                marginTop: 10,
                              }}
                            >
                              <Tooltip
                                placement='bottom'
                                title={
                                  language === 'th'
                                    ? t.reason_description
                                      ? t.reason_description
                                      : t.reason.subject_reason.name_th
                                    : t.reason_description
                                    ? t.reason_description
                                    : t.reason.subject_reason.name_en
                                }
                              >
                                <Button
                                  shape='round'
                                  size='small'
                                  style={{ fontSize: 12 }}
                                  onClick={() => showModalReason(t)}
                                >
                                  {t.reason && t.reason.subject_reason.code}
                                </Button>
                              </Tooltip>
                            </div>
                          ) : (
                            ''
                          )}
                        </StyledCol>
                        <StyledCol
                          style={{ fontSize: 13 }}
                          align={'center'}
                          xs={6}
                        ></StyledCol>
                      </Row>
                    </Col>
                  </Row>
                  <br />
                </div>
              ))}
          </>
        )}

        <Form
          form={form}
          name='basic'
          labelCol={{
            span: 10,
          }}
          wrapperCol={{
            span: 10,
          }}
          style={{ padding: '20px' }}
          autoComplete='off'
        >
          <Modal
            width={600}
            maskClosable={false}
            title={
              <div
                style={{
                  width: '100%',
                  cursor: 'move',
                }}
                onMouseOver={() => {
                  if (disabled) {
                    setDisabled(false);
                  }
                }}
                onMouseOut={() => {
                  setDisabled(true);
                }}
              >
                <ExclamationCircleTwoTone
                  style={{ fontSize: 20, marginRight: 3 }}
                  twoToneColor='red'
                />{' '}
                Please input date/time of actual arrival and actual departure.
              </div>
            }
            visible={isModalVisible}
            onOk={onFinish}
            onCancel={handleCancel}
            modalRender={(modal) => (
              <StyledModal>
                <Draggable
                  bounds={{ top: -100, left: -500, right: 500, bottom: 500 }}
                  onStart={(event, uiData) => onStart(event, uiData)}
                >
                  <div ref={draggleRef}>{modal}</div>
                </Draggable>
              </StyledModal>
            )}
          >
            <Form.Item
              label='Actual Arrival'
              name='actual_human_arrival_time'
              rules={[
                {
                  required: true,
                  message: 'Please select actual arrival',
                },
              ]}
            >
              <DatePicker
                onChange={(value) => setActualArrival(value)}
                showTime
                format={'DD/MM/YYYY HH:mm'}
              />
            </Form.Item>

            <Form.Item
              label='Actual Departure'
              name='actual_human_departure_time'
              rules={[
                {
                  required: true,
                  message: 'Please input actual departure',
                },
              ]}
            >
              <DatePicker
                onChange={(value) => setActualDeparture(value)}
                showTime
                format={'DD/MM/YYYY HH:mm'}
              />
            </Form.Item>
          </Modal>
        </Form>
        <FormReason
          formData={formData}
          showModal={isModalReasonVisible}
          setIsModalVisible={handleCancelReason}
          refreshData={refreshData}
        />
      </StyledPageHeader>
    </>
  );
};

const StyledModal = styled.div`
  height: calc(100vh - 80px);
  padding: 20px;
`;
const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
  .ant-page-header-content {
    padding-top: 5px;
    padding-bottom: 5px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 4px;
  margin-bottom: 4px;
`;
const StyledDivideTruck = styled(Divider)`
  &&& {
    margin-top: 4px;
    margin-bottom: 4px;
    width: 88%;
    min-width: 88%;
  }
`;

const StyledCol = styled(Col)`
  font-size: 12px;
  font-weight: bold;
`;
const StyledText = styled(Text)`
  font-size: 12px;
  font-weight: bold;
`;

export default InfoPanel;
