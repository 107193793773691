import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const StatusSummarySlice = createSlice({
  name: 'statusSummary',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setStatusSummaryTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setStatusSummaryDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setStatusSummaryFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setStatusSummaryFilter,
  setCurrentPage,
  setCurrentOffset,
  setStatusSummaryDataSource,
  setStatusSummaryTotalRows,
  setStatusSummaryCompleteCount,
} = StatusSummarySlice.actions;

export const StatusSummarySelector = (state) => state.statusSummary;

export default StatusSummarySlice.reducer;
