import React from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import styled from 'styled-components';
import { HomeOutlined } from '@ant-design/icons';

const BreadcrumbsComponent = ({ crumbs }) => {
  const extraBreadcrumbItems = crumbs
    ? crumbs.map(({ name, path }, key) => (
        <StyledBreadcrumItem key={`item-${key}`}>
          {key + 1 === crumbs.length ? (
            <span key={key}>{name}</span>
          ) : (
            <Link key={key} to={path}>
              {name}
            </Link>
          )}
        </StyledBreadcrumItem>
      ))
    : [];

  const breadcrumbItems = [
    <StyledBreadcrumItem key='home'>
      <Link to='/app' style={{ color: 'white' }}>
        <HomeOutlined color='white' />
      </Link>
    </StyledBreadcrumItem>,
  ].concat(extraBreadcrumbItems);

  return <StyledBreadcrum>{breadcrumbItems}</StyledBreadcrum>;
};

const StyledBreadcrum = styled(Breadcrumb)`
  margin-left: 20px;
  .ant-breadcrumb-separator {
    color: white;
  }
  a {
    color: white;
  }

`;
const StyledBreadcrumItem = styled(Breadcrumb.Item)`
  color: white;
`;
export default BreadcrumbsComponent;
