import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import AntTable from 'common_components/AntTable';
import styled from 'styled-components';
import { Row, Col, Button } from 'antd';
import './index.css';
import { dateFormatterVLP } from 'common_components/AntTable/tableUtils';

const TableSummaryYardComponent = (props) => {
  const { dataSourceDate, headerTableRef, filterWorkingDateRef } = props;
  const dispatch = useDispatch();

  const styleColorColumn = (data, fdate) => {
    if (data === 'Over') {
      return {
        fontWeight: '700',
        color: 'red',
        fontSize: '14px',
        background: filterWorkingDateRef.current === fdate ? '#FFA39E' : null,
      };
    } else {
      return {
        background: filterWorkingDateRef.current === fdate ? '#FFA39E' : null,
      };
    }
  };
  const columns = [
    {
      title: () => {
        let day = headerTableRef.current && headerTableRef.current[0]?.day;
        let date = headerTableRef.current && headerTableRef.current[0]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date1Value',
      align: 'center',
      className:
        (filterWorkingDateRef.current && filterWorkingDateRef.current) ===
        (headerTableRef.current && headerTableRef.current[0]?.fdate)
          ? 'color-head-red'
          : 'color-head-6',

      render(text, record) {
        return {
          props: {
            style: styleColorColumn(
              text,
              headerTableRef.current && headerTableRef.current[0]?.fdate
            ),
          },
          children: <div>{text ? text : '-'}</div>,
        };
      },
    },
    {
      title: () => {
        let day = headerTableRef.current && headerTableRef.current[1]?.day;
        let date = headerTableRef.current && headerTableRef.current[1]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date2Value',
      align: 'center',
      className:
        (filterWorkingDateRef.current && filterWorkingDateRef.current) ===
        (headerTableRef.current && headerTableRef.current[1]?.fdate)
          ? 'color-head-red'
          : 'color-head-6',
      render(text, record) {
        return {
          props: {
            style: styleColorColumn(
              text,
              headerTableRef.current && headerTableRef.current[1]?.fdate
            ),
          },
          children: <div>{text ? text : '-'}</div>,
        };
      },
    },
    {
      title: () => {
        let day = headerTableRef.current && headerTableRef.current[2]?.day;
        let date = headerTableRef.current && headerTableRef.current[2]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date3Value',
      align: 'center',
      className:
        (filterWorkingDateRef.current && filterWorkingDateRef.current) ===
        (headerTableRef.current && headerTableRef.current[2]?.fdate)
          ? 'color-head-red'
          : 'color-head-6',
      render(text, record) {
        return {
          props: {
            style: styleColorColumn(
              text,
              headerTableRef.current && headerTableRef.current[2]?.fdate
            ),
          },
          children: <div>{text ? text : '-'}</div>,
        };
      },
    },
    {
      title: () => {
        let day = headerTableRef.current && headerTableRef.current[3]?.day;
        let date = headerTableRef.current && headerTableRef.current[3]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date4Value',
      align: 'center',
      className:
        (filterWorkingDateRef.current && filterWorkingDateRef.current) ===
        (headerTableRef.current && headerTableRef.current[3]?.fdate)
          ? 'color-head-red'
          : 'color-head-6',
      render(text, record) {
        return {
          props: {
            style: styleColorColumn(
              text,
              headerTableRef.current && headerTableRef.current[3]?.fdate
            ),
          },
          children: <div>{text ? text : '-'}</div>,
        };
      },
    },
    {
      title: () => {
        let day = headerTableRef.current && headerTableRef.current[4]?.day;
        let date = headerTableRef.current && headerTableRef.current[4]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date5Value',
      align: 'center',
      className:
        (filterWorkingDateRef.current && filterWorkingDateRef.current) ===
        (headerTableRef.current && headerTableRef.current[4]?.fdate)
          ? 'color-head-red'
          : 'color-head-6',
      render(text, record) {
        return {
          props: {
            style: styleColorColumn(
              text,
              headerTableRef.current && headerTableRef.current[4]?.fdate
            ),
          },
          children: <div>{text ? text : '-'}</div>,
        };
      },
    },
    {
      title: () => {
        let day = headerTableRef.current && headerTableRef.current[5]?.day;
        let date = headerTableRef.current && headerTableRef.current[5]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date6Value',
      align: 'center',
      className:
        (filterWorkingDateRef.current && filterWorkingDateRef.current) ===
        (headerTableRef.current && headerTableRef.current[5]?.fdate)
          ? 'color-head-red'
          : 'color-head-6',
      render(text, record) {
        return {
          props: {
            style: styleColorColumn(
              text,
              headerTableRef.current && headerTableRef.current[5]?.fdate
            ),
          },
          children: <div>{text ? text : '-'}</div>,
        };
      },
    },
    {
      title: () => {
        let day = headerTableRef.current && headerTableRef.current[6]?.day;
        let date = headerTableRef.current && headerTableRef.current[6]?.fdate;
        return (
          <div>
            {day} <br /> {date ? dateFormatterVLP({ value: date }) : '-'}
          </div>
        );
      },
      dataIndex: 'date7Value',
      align: 'center',
      className:
        (filterWorkingDateRef.current && filterWorkingDateRef.current) ===
        (headerTableRef.current && headerTableRef.current[6]?.fdate)
          ? 'color-head-red'
          : 'color-head-6',
      render(text, record) {
        return {
          props: {
            style: styleColorColumn(
              text,
              headerTableRef.current && headerTableRef.current[6]?.fdate
            ),
          },
          children: <div>{text ? text : '-'}</div>,
        };
      },
    },
  ];
  const onBtnGotoTourList = () => {
    dispatch(push(`/app/planning/manage_tour_list`));
  };
  return (
    <Container>
      <Row justify='end' style={{ margin: '20px' }}>
        <Col>
          <Button type='primary' onClick={onBtnGotoTourList}>
            <p>Manage Tour list</p>
          </Button>
        </Col>
      </Row>

      <StyledAntTable
        tableId={'id'}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSourceDate}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />
    </Container>
  );
};
const StyledAntTable = styled(AntTable)`
  &&& {
    tr td {
      padding: 0px;
    }
  }
`;
const Container = styled.div`
  padding: 10px;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default TableSummaryYardComponent;
