import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import TableToolbar from './Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from 'hooks/windowSize';
import { statusSummaryApi } from 'reducers/statusSummary/api';
import styled from 'styled-components';
import { message, Typography, Space } from 'antd';
import PrintDN from '../../common_components/PrintDocument/PrintingDN/PrintDN';
import './index.css';
const { Link } = Typography;
const LIMIT = 100;

const CallingSheetTableComponent = (props) => {
  const calRowSpan = (dataInput, fieldName) => {
    let tmp = [];
    let rowSpanList = [];
    for (let d of dataInput) {
      let value = d[fieldName];
      if (tmp.includes(value)) {
        rowSpanList.push(0);
      } else {
        const occurCount = dataInput.filter(
          (d) => d[fieldName] === value
        ).length;
        tmp.push(value);
        rowSpanList.push(occurCount);
      }
    }
    return rowSpanList;
  };

  const subsChars = (arr) => {
    for (let [i, obj] of Object.entries(arr)) {
      const newobj = Object.fromEntries(
        Object.entries(obj).map(([key, value]) => [
          key.replace(/\s+/g, '_').toLowerCase(),
          value,
        ])
      );
      return (arr[+i] = newobj ? newobj : 0);
    }
  };
  const sharedOnCell = () => {
    return {
      colSpan: 0,
    };
  };
  const colSpanCell = sharedOnCell();

  const [isLoading, setIsLoading] = useState(false);
  const valueUnitRef = useRef();
  const valueKeyRef = useRef();
  const printDNComponentRef = useRef();
  const formDataRef = useRef();
  const rowSpanListRef = useRef();
  const vlOptionsRef = useRef();
  const permissionRef = useRef();
  const totalAllRef = useRef({});
  const vlRef = useRef({});
  const [showFilter, setShowFilter] = useState(true);

  // Redux Store
  const storedDataSource = useSelector((state) => {
    return state.callingsheet.dataSource;
  });
  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [tableHeight, setTableHeight] = useState(0);
  const dispatch = useDispatch();
  const { height } = useWindowSize();

  useEffect(() => {
    if (height) {
      let h = height - 360;
      if (!showFilter) {
        h = h + 100;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  const onQuery = async (value) => {
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          statusSummaryApi.endpoints.findAllStatusSummaryByPagination.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        } else {
          if (result.data) {
            let resultPermission = await dispatch(
              statusSummaryApi.endpoints.findAllStatusSummaryByPermission.initiate(
                value.vl_id
              )
            );

            if (value.vl_id === 'All') {
              permissionRef.current = true;
            } else {
              permissionRef.current = resultPermission.data;
            }
            let codeVl = vlOptionsRef.current.find(
              (v) => v.value === value.vl_id
            );
            vlRef.current = codeVl.code;
            let arrayTable = [];
            var unit = Object.keys(result.data.unit.data);
            var calling = Object.keys(result.data.calling.data);
            var total_row_unit = Object.keys(result.data.unit.total_row);
            var total_row_calling = Object.keys(result.data.calling.total_row);

            var region_link = [];
            totalAllRef.current = {
              total_column_unit: result.data.unit.total_column
                ? subsChars([result.data.unit?.total_column])
                : 0,
              total_all_unit: result.data.unit?.total_all,
              total_column_calling: result.data.calling
                ? subsChars([result.data.calling?.total_column])
                : 0,
              total_all_calling: result.data.calling
                ? result.data.calling?.total_all
                : 0,
            };
            for (let index = 0; index < calling.length; index++) {
              let key = unit[index];
              let keyCalling = calling[index];
              let key_total_row = total_row_unit[index];
              let key_total_row_calling = total_row_calling[index];
              let valueUnit = null;
              let value_total_row = null;
              let value_total_row_calling = null;
              let valueKeyTotalRow = null;
              let valueKeyTotalRowCalling = null;
              let valueKey = null;

              if (key) {
                valueUnit = result.data.unit.data[key];
                valueUnitRef.current = result.data.unit.data[key];
                value_total_row = result.data.unit.total_row[key_total_row];
                value_total_row_calling =
                  result.data.calling.total_row[key_total_row_calling];
                valueKey = Object.keys(valueUnit).toString();
                valueKeyRef.current = Object.keys(valueUnit).toString();
                valueKeyTotalRow = Object.keys(value_total_row).toString();
                valueKeyTotalRowCalling = Object.keys(
                  value_total_row_calling
                ).toString();
                region_link.push({
                  cluster: Object.keys(valueUnit),
                });
              }

              let valueCalling = result.data.calling.data[keyCalling];
              let valueKeyCalling = Object.keys(valueCalling).toString();

              if (Object.keys(valueCalling).length > 1) {
                for (const keyObj in valueCalling) {
                  let objData = null;

                  for (const keyObjUnit in valueUnitRef.current) {
                    if (keyObj === keyObjUnit) {
                      objData = subsChars([valueUnitRef.current[keyObjUnit]]);
                    }
                  }

                  let objDataCalling = subsChars([valueCalling[keyObj]]);

                  let objTotalRow = value_total_row
                    ? value_total_row[keyObj]
                    : null;

                  let objTotalRowCalling = value_total_row_calling
                    ? value_total_row_calling[keyObj]
                    : null;

                  arrayTable.push({
                    region: keyCalling,
                    cluster: keyObj,
                    on_the_way: objData && objData.on_the_way,
                    not_have_calling: objData && objData.not_have_calling,
                    calling: objData && objData.have_calling,
                    total_row_unit: objTotalRow,
                    not_match_calling: objDataCalling.not_match,
                    match_diagram: objDataCalling.match,
                    match_tr_dv: objDataCalling.match_tr_dv,
                    total_row_calling: objTotalRowCalling,
                    region_link: valueUnit
                      ? Object.keys(valueUnit)
                      : valueCalling
                      ? Object.keys(valueCalling).toString()
                      : null,
                    from: codeVl.code,
                  });
                }
              } else {
                let objData = valueUnit
                  ? subsChars([valueUnit[valueKey]])
                  : null;

                let objDataCalling = subsChars([valueCalling[valueKeyCalling]]);
                arrayTable.push({
                  region: key ? key : keyCalling,
                  cluster: valueUnit
                    ? Object.keys(valueUnit).toString()
                    : valueCalling
                    ? Object.keys(valueCalling).toString()
                    : null,
                  on_the_way: objData ? objData.on_the_way : null,
                  not_have_calling: objData ? objData.not_have_calling : null,
                  calling: objData ? objData.have_calling : null,
                  total_row_unit: valueKeyTotalRow
                    ? value_total_row[valueKeyTotalRow]
                    : null,
                  total_row_calling: valueKeyTotalRowCalling
                    ? value_total_row_calling[valueKeyTotalRowCalling]
                    : null,
                  not_match_calling: objDataCalling.not_match,
                  match_diagram: objDataCalling.match,
                  match_tr_dv: objDataCalling.match_tr_dv,
                  region_link: valueUnit
                    ? Object.keys(valueUnit)
                    : valueCalling
                    ? Object.keys(valueCalling).toString()
                    : null,
                  from: codeVl.code,
                });
              }
            }

            rowSpanListRef.current = calRowSpan(arrayTable, 'region');
            setDataSource(arrayTable);
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const onLinkRegion = (record) => {
    window.open(
      `/app/delivery_mng/vins_filter/${vlRef.current}/${record.region_link}/Not have Calling`,
      '_blank'
    );
  };

  const onLinkVehicleStatus = (vehicleStatus) => {
    window.open(
      `/app/delivery_mng/vins_filter/${vlRef.current}/All/${vehicleStatus}`,
      '_blank'
    );
  };
  const onLinkOnTheWayCell = (record, vehicleStatus) => {
    window.open(
      `/app/delivery_mng/vins_filter/All/${vlRef.current}/${record.cluster}/${vehicleStatus}`,
      '_blank'
    );
  };
  const onLinkCell = (record, vehicleStatus) => {
    window.open(
      `/app/delivery_mng/vins_filter/${vlRef.current}/${record.cluster}/${vehicleStatus}`,
      '_blank'
    );
  };

  const onLinkCallingList = () => {
    window.open(
      `/app/delivery_mng/calling_filter/${vlRef.current}/All/All`,
      '_blank'
    );
  };

  const onLinkCallingListCell = (record) => {
    window.open(
      `/app/delivery_mng/calling_filter/${vlRef.current}/${record.cluster}/${record.region}`,
      '_blank'
    );
  };

  const onLinkDispatchBoardDiagramCell = (record, calling) => {
    window.open(
      `/app/delivery_mng/lists_filter/${vlRef.current}/${record.cluster}/Diagram`,
      '_blank'
    );
  };

  const onLinkDispatchBoardDiagramHeader = () => {
    window.open(
      `/app/delivery_mng/lists_filter/${vlRef.current}/All/Diagram`,
      '_blank'
    );
  };

  const onLinkDispatchBoardMatchHeader = () => {
    window.open(
      `/app/delivery_mng/lists_filter/${vlRef.current}/All/Match`,
      '_blank'
    );
  };

  const onLinkDispatchBoardTRDVHeader = () => {
    window.open(
      `/app/delivery_mng/lists_filter/${vlRef.current}/All/TR_DV`,
      '_blank'
    );
  };

  const onLinkDispatchBoardCell = (record, calling) => {
    window.open(
      `/app/delivery_mng/lists_filter/${vlRef.current}/${record.cluster}/TR_DV`,
      '_blank'
    );
  };

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Unit',
        className: ['width-60', 'color-head-1'],
        children: [
          {
            title: 'Status',
            align: 'center',

            className: 'color-head-2',
            rowSpan: 3,
            colSpan: 2,
            width: 120,
            children: [
              {
                rowSpan: 0,
                children: [
                  {
                    colSpan: 0,
                    rowSpan: 0,
                    children: [
                      {
                        title: 'Total',
                        className: 'color-head-3',
                        children: [
                          {
                            title: 'Region',
                            dataIndex: 'region',
                            align: 'center',
                            width: 120,
                            className: 'color-head-2',
                            render: (text, record, index) => {
                              return {
                                children: permissionRef.current ? (
                                  text ? (
                                    <Link
                                      style={{
                                        textDecoration: 'underline',
                                      }}
                                      onClick={() => onLinkRegion(record)}
                                    >
                                      {text}
                                    </Link>
                                  ) : (
                                    0
                                  )
                                ) : (
                                  text
                                ),
                                props: {
                                  rowSpan:
                                    rowSpanListRef.current &&
                                    rowSpanListRef.current[index],
                                },
                              };
                            },
                          },
                          {
                            title: 'Cluster',
                            dataIndex: 'cluster',
                            align: 'center',
                            width: 120,
                            className: 'color-head-2',
                          },
                        ],
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_column_unit &&
                              totalAllRef.current.total_column_unit.on_the_way
                                ? totalAllRef.current.total_column_unit
                                    .on_the_way
                                : 0}
                            </b>
                          );
                        },
                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'on_the_way',
                        align: 'center',
                        width: 120,
                        className: 'color-head-3',
                        render: (text, record) => {
                          return {
                            children: permissionRef.current ? (
                              text ? (
                                <Link
                                  style={{ textDecoration: 'underline' }}
                                  onClick={() =>
                                    onLinkOnTheWayCell(record, 'On the way')
                                  }
                                >
                                  {text}
                                </Link>
                              ) : (
                                0
                              )
                            ) : text ? (
                              text
                            ) : (
                              0
                            ),
                          };
                        },
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_column_unit &&
                              totalAllRef.current.total_column_unit
                                .not_have_calling
                                ? totalAllRef.current.total_column_unit
                                    .not_have_calling
                                : 0}
                            </b>
                          );
                        },
                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'not_have_calling',
                        align: 'center',
                        width: 120,
                        className: 'color-head-3',
                        render: (text, record) => {
                          return {
                            children: permissionRef.current ? (
                              text ? (
                                <Link
                                  style={{ textDecoration: 'underline' }}
                                  onClick={() =>
                                    onLinkCell(record, 'Not have Calling')
                                  }
                                >
                                  {text}
                                </Link>
                              ) : (
                                0
                              )
                            ) : text ? (
                              text
                            ) : (
                              0
                            ),
                          };
                        },
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_column_unit &&
                              totalAllRef.current.total_column_unit.have_calling
                                ? totalAllRef.current.total_column_unit
                                    .have_calling
                                : 0}
                            </b>
                          );
                        },
                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'calling',
                        align: 'center',
                        width: 120,
                        className: 'color-head-3',
                        render: (text, record) => {
                          return {
                            children: permissionRef.current ? (
                              text ? (
                                <Link
                                  style={{ textDecoration: 'underline' }}
                                  onClick={() =>
                                    onLinkCell(record, "In Yard Calling")
                                  }
                                >
                                  {text}
                                </Link>
                              ) : (
                                0
                              )
                            ) : text ? (
                              text
                            ) : (
                              0
                            ),
                          };
                        },
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_all_unit &&
                              totalAllRef.current.total_all_unit
                                ? totalAllRef.current.total_all_unit
                                : 0}
                            </b>
                          );
                        },
                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'total_row_unit',
                        align: 'center',
                        width: 120,
                        className: 'color-head-3',
                        render: (text) => {
                          return <b>{text ? text : 0}</b>;
                        },
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_column_calling &&
                              totalAllRef.current.total_column_calling.not_match
                                ? totalAllRef.current.total_column_calling
                                    .not_match
                                : 0}
                            </b>
                          );
                        },
                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'not_match_calling',
                        align: 'center',
                        width: 120,
                        className: 'color-head-6',
                        render: (text, record) => {
                          return {
                            children: permissionRef.current ? (
                              text ? (
                                <Link
                                  style={{ textDecoration: 'underline' }}
                                  onClick={() => onLinkCallingListCell(record)}
                                >
                                  {text}
                                </Link>
                              ) : (
                                0
                              )
                            ) : text ? (
                              text
                            ) : (
                              0
                            ),
                          };
                        },
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_column_calling &&
                              totalAllRef.current.total_column_calling.match
                                ? totalAllRef.current.total_column_calling.match
                                : 0}
                            </b>
                          );
                        },

                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'match_diagram',
                        align: 'center',
                        width: 120,
                        className: 'color-head-6',
                        render: (text, record) => {
                          return {
                            children: permissionRef.current ? (
                              text ? (
                                <Link
                                  style={{ textDecoration: 'underline' }}
                                  onClick={() =>
                                    onLinkDispatchBoardDiagramCell(record)
                                  }
                                >
                                  {text}
                                </Link>
                              ) : (
                                0
                              )
                            ) : text ? (
                              text
                            ) : (
                              0
                            ),
                          };
                        },
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_column_calling &&
                              totalAllRef.current.total_column_calling
                                .match_tr_dv
                                ? totalAllRef.current.total_column_calling
                                    .match_tr_dv
                                : 0}
                            </b>
                          );
                        },
                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'match_tr_dv',
                        align: 'center',
                        width: 120,
                        className: 'color-head-6',
                        render: (text, record) => {
                          return {
                            children: permissionRef.current ? (
                              text ? (
                                <Link
                                  style={{ textDecoration: 'underline' }}
                                  onClick={() =>
                                    onLinkDispatchBoardCell(record)
                                  }
                                >
                                  {text}
                                </Link>
                              ) : (
                                0
                              )
                            ) : text ? (
                              text
                            ) : (
                              0
                            ),
                          };
                        },
                      },
                      {
                        title: () => {
                          return (
                            <b>
                              {totalAllRef.current.total_all_calling &&
                              totalAllRef.current.total_all_calling
                                ? totalAllRef.current.total_all_calling
                                : 0}
                            </b>
                          );
                        },
                        colSpan: 1,
                        rowSpan: 2,
                        dataIndex: 'total_row_calling',
                        align: 'center',
                        width: 120,
                        className: 'color-head-6',
                        render: (text) => {
                          return <b>{text ? text : 0}</b>;
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            title: () => {
              return permissionRef.current ? (
                <Link
                  style={{ textDecoration: 'underline', color: '#000' }}
                  onClick={() => onLinkVehicleStatus('On the way')}
                >
                  On The Way
                </Link>
              ) : (
                'On The Way'
              );
            },
            align: 'center',
            rowSpan: 3,
            className: 'color-head-2',
            render: (text, record, index) => {
              return {
                children: {},
                props: colSpanCell,
              };
            },
          },
          {
            title: 'In Yard',
            align: 'center',
            className: 'color-head-2',
            colSpan: 2,
            children: [
              {
                title: () => {
                  return permissionRef.current ? (
                    <Link
                      style={{ textDecoration: 'underline', color: '#000' }}
                      onClick={() => onLinkVehicleStatus('Not have Calling')}
                    >
                      Not Create Calling
                    </Link>
                  ) : (
                    'Not Create Calling'
                  );
                },
                align: 'center',
                rowSpan: 2,
                className: 'color-head-2',
                render: (text, record, index) => {
                  return {
                    children: {},
                    props: colSpanCell,
                  };
                },
              },
              {
                title: () => {
                  return permissionRef.current ? (
                    <Link
                      style={{ textDecoration: 'underline', color: '#000' }}
                      onClick={() => onLinkVehicleStatus("In Yard Calling")}
                    >
                      Calling
                    </Link>
                  ) : (
                    'Calling'
                  );
                },
                align: 'center',
                className: 'color-head-2',
                rowSpan: 2,
                render: (text, record, index) => {
                  return {
                    children: {},
                    props: colSpanCell,
                  };
                },
              },
            ],
          },
          {
            title: 'Total',
            width: 120,
            className: 'color-head-2',
            align: 'center',
            rowSpan: 3,
            render: (text, record, index) => {
              return {
                children: {},
                props: colSpanCell,
              };
            },
          },
        ],
      },

      {
        title: 'Calling Status (Trip)',
        className: ['width-40', 'color-head-4'],
        children: [
          {
            title: 'Not Match',
            align: 'center',
            className: 'color-head-5',
            children: [
              {
                title: () => {
                  return permissionRef.current ? (
                    <Link
                      style={{ textDecoration: 'underline', color: '#fff' }}
                      onClick={() => onLinkCallingList()}
                    >
                      Calling
                    </Link>
                  ) : (
                    'Calling'
                  );
                },
                hidden: true,
                align: 'center',
                width: 120,
                rowSpan: 2,
                className: 'color-head-5',
                render: (text, record, index) => {
                  return {
                    children: {},
                    props: colSpanCell,
                  };
                },
              },
            ],
          },
          {
            title: () => {
              return permissionRef.current ? (
                <Link
                  style={{ textDecoration: 'underline', color: '#fff' }}
                  onClick={() => onLinkDispatchBoardMatchHeader()}
                >
                  Match
                </Link>
              ) : (
                'Match'
              );
            },
            colSpan: 2,
            className: 'color-head-5',
            children: [
              {
                title: () => {
                  return permissionRef.current ? (
                    <Link
                      style={{ textDecoration: 'underline', color: '#fff' }}
                      onClick={() => onLinkDispatchBoardDiagramHeader()}
                    >
                      Diagram
                    </Link>
                  ) : (
                    'Diagram'
                  );
                },
                align: 'center',
                width: 120,
                rowSpan: 2,
                className: 'color-head-5',
                render: (text, record, index) => {
                  return {
                    children: {},
                    props: colSpanCell,
                  };
                },
              },
              {
                title: () => {
                  return permissionRef.current ? (
                    <Link
                      style={{ textDecoration: 'underline', color: '#fff' }}
                      onClick={() => onLinkDispatchBoardTRDVHeader()}
                    >
                      TR/DV
                    </Link>
                  ) : (
                    'TR/DV'
                  );
                },
                width: 120,
                align: 'center',
                rowSpan: 2,
                className: 'color-head-5',
                render: (text, record, index) => {
                  return {
                    children: {},
                    props: colSpanCell,
                  };
                },
              },
            ],
          },
          {
            title: 'Total',
            width: 120,
            rowSpan: 3,
            align: 'center',
            className: 'color-head-5',
            render: (text, record, index) => {
              return {
                children: {},
                props: colSpanCell,
              };
            },
          },
        ],
      },
    ];
    return cols;
  });

  const onVlOptions = (options) => {
    vlOptionsRef.current = options;
  };

  return (
    <Container>
      <TableToolbar
        vlOptions={onVlOptions}
        onQuery={onQuery}
        columns={columns}
        isLoading={isLoading}
        setColumns={setColumns}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        limit={LIMIT}
      />
      <StyledAntTable
        rowKey='id'
        tableId={'callingsheet'}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        height={tableHeight}
        scroll={{
          scrollToFirstRowOnChange: true,
        }}
        pagination={false}
      />
      <PrintDN
        componentRef={printDNComponentRef}
        dnDataList={formDataRef.current}
      ></PrintDN>
    </Container>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;
const Container = styled.div`
  padding: 0 10px;
`;

export default CallingSheetTableComponent;
