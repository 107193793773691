import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Select, Button, Modal, message } from 'antd';

import {
  useFindAllvehicleTypeChangesQuery,
  useFindvehicleTypeChangesByIdQuery,
  useCreatevehicleTypeChangesMutation,
  useUpdatevehicleTypeChangesMutation,
} from 'reducers/masterData/VehicleTypeChange/api';
import { useFindAllMasterDataVinQuery } from 'reducers/vin/api';
import { RemoveDuplicates } from 'utils/removeDuplicates';
import { Sorter } from 'utils/sorter';
import { goBack } from 'connected-react-router';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const DiagramOwnerFormComponent = (props) => {
  const { id } = useParams();

  const { formData, isCreate } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { setErrorData } = useErrorHandler();
  //parameter

  const [optionVehicleTypes, setOptionVehicleTypes] = useState();
  const [valueVehicletype, setValueVehicletype] = useState();
  const [valueVehicletypeChange, setValueVehicletypeChange] = useState();

  const { data, error, isLoading, isFetching, refetch } =
    useFindAllMasterDataVinQuery();

  useEffect(() => {
    if (data) {
      // vehicle_type
      let findVehicleTypes = data.vehicle_type.map((d) => ({
        label: d.name,
        value: d.id,
      }));

      findVehicleTypes = RemoveDuplicates.LISTKEY(findVehicleTypes, 'value');
      findVehicleTypes = Sorter.DEFAULTLIST(findVehicleTypes, 'value');
      setOptionVehicleTypes(findVehicleTypes);
    }
  }, [data]);

  // Query by ID
  const {
    data: dataVehicleTypeById,
    error: errorParkingById,
    isFetching: isParkingByIdFetching,
    refetch: refetchParkingById,
  } = useFindvehicleTypeChangesByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (dataVehicleTypeById) {
      form.setFieldsValue({
        vehicle_type: dataVehicleTypeById?.vehicle_type?.id,
      });
      form.setFieldsValue({
        vehicle_type_change: dataVehicleTypeById?.vehicle_type_change?.id,
      });

      setValueVehicletype(dataVehicleTypeById?.vehicle_type?.id);
      setValueVehicletypeChange(dataVehicleTypeById?.vehicle_type_change?.id);
    }
  }, [dataVehicleTypeById]);

  useEffect(() => {
    if (errorParkingById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load parking lane',
        okText: 'Retry',
        onOk: refetchParkingById,
        cancelText: 'Cancel',
      });
    }
  }, [errorParkingById, refetchParkingById]);

  const [
    createVehicleType,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreatevehicleTypeChangesMutation();

  const [
    updateVehicleType,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdatevehicleTypeChangesMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onClickSubmit = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    if (valueVehicletype && valueVehicletypeChange) {
      let postData = {
        vehicle_type: valueVehicletype,
        vehicle_type_change: valueVehicletypeChange,
      };
      let putData = {
        id: id,
        vehicle_type: valueVehicletype,
        vehicle_type_change: valueVehicletypeChange,
      };
      if (isCreate) {
        createVehicleType(postData);
      } else {
        updateVehicleType(putData);
      }
    } else {
      message.error('Please input data !!');
    }
  };
  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      style={{ padding: '20px' }}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 16,
          span: 8,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </Form.Item>

      <Form.Item
        name='vehicle_type'
        label='Vehicle Type'
        rules={[
          {
            required: true,
            message: 'Please input vehicle type',
          },
        ]}
      >
        <Select
          mode='single'
          showSearch
          allowClear={true}
          maxTagCount='responsive'
          placeholder={'Vehicle Type'}
          loading={isLoading}
          value={valueVehicletype}
          options={optionVehicleTypes}
          onChange={(values) => {
            setValueVehicletype(values);
          }}
        />
      </Form.Item>

      <Form.Item
        name='vehicle_type_change'
        label='Vehicle Type Change'
        rules={[
          {
            required: true,
            message: 'Please input vehicle type Change',
          },
        ]}
      >
        <Select
          mode='single'
          showSearch
          allowClear={true}
          maxTagCount='responsive'
          placeholder={'Vehicle Type Change'}
          loading={isLoading}
          value={valueVehicletypeChange}
          options={optionVehicleTypes}
          onChange={(values) => {
            setValueVehicletypeChange(values);
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default DiagramOwnerFormComponent;
