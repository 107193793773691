import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Modal,
  Button,
  message,
  Radio,
  Divider,
  PageHeader,
  Select,
  Space,
  TimePicker,
} from 'antd';
import Icon from '@ant-design/icons';
import {
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  PlusOutlined,
  MinusCircleOutlined,
  ArrowRightOutlined,
} from '@ant-design/icons';
import { useErrorHandler } from 'common_components/ErrorContext';
import digitalMapUtils from '../../../../digitalmap_components/Utils';

import { ReactComponent as CircleSvg } from 'assets/icons/circle.svg';
import { ReactComponent as PolygonSvg } from 'assets/icons/polygon.svg';
import { getCurrentUser } from 'reducers/user';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import {
  useCreateLocationMasterMutation,
  useUpdateLocationMasterMutation,
} from 'reducers/masterData/locationMaster/api';
import { goBack } from 'connected-react-router';
import { mapDrawingMode, mapResetMap, mapAreaType } from 'reducers/map';
import { useFindAllLocationTypesQuery } from 'reducers/digitalmap/locationType/api';
import { useFindAllClustersQuery } from 'reducers/masterData/cluster/api';
import {
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  mapSetBounds,
} from 'reducers/map';
import moment from 'moment';

const { Option } = Select;

const ManageForm = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const user = useSelector(getCurrentUser);
  const [locationTypes, setLocationTypes] = useState([]);
  const [clusters, setClusters] = useState([]);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  // Query all location types
  const {
    data: dataLocationTypes,
    error: errorLocationTypes,
    isFetching: isLocationTypesFetching,
  } = useFindAllLocationTypesQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataLocationTypes) {
      setLocationTypes(
        dataLocationTypes.map((d) => ({ id: d.id, label: d.name }))
      );
    }
  }, [dataLocationTypes]);

  useEffect(() => {
    if (errorLocationTypes) {
      setErrorData(errorLocationTypes);
    }
  }, [errorLocationTypes, setErrorData]);

  // Query all clusters
  const {
    data: dataClusters,
    error: errorClusters,
    isFetching: isClustersFetching,
    refetch: refetchClusters,
  } = useFindAllClustersQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataClusters) {
      setClusters(dataClusters.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataClusters]);

  useEffect(() => {
    if (errorClusters) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load clusters',
        okText: 'Retry',
        onOk: refetchClusters,
        cancelText: 'Cancel',
      });
    }
  }, [errorClusters, refetchClusters]);

  const markerList = useSelector((state) => {
    return state.map.markerList;
  });
  const polygonList = useSelector((state) => {
    return state.map.polygonList;
  });
  const circleList = useSelector((state) => {
    return state.map.circleList;
  });
  const drawingMode = useSelector((state) => {
    return state.map.drawingMode;
  });

  const getGeofenceType = useCallback(function callback(
    polygonList,
    circleList
  ) {
    if (polygonList && polygonList[0]) {
      return 'polygon';
    } else if (circleList && circleList[0]) {
      return 'circle';
    } else {
      return null;
    }
  },
  []);

  useEffect(() => {
    if (formData) {
      // Dispatch location data
      let markers = digitalMapUtils.setMarkers(
        [{ location_master: formData }],
        true
      );
      let circles = digitalMapUtils.setCircles(
        [{ location_master: formData }],
        true
      );
      let polygons = digitalMapUtils.setPolygons(
        [{ location_master: formData }],
        true
      );

      let bounds = digitalMapUtils.setLocationMasterBounds(
        [{ location_master: formData }],
        true
      );
      let geofenceType = getGeofenceType(polygons, circles);
      if (geofenceType) {
        dispatch(mapDrawingMode(geofenceType));
      }
      dispatch(mapSetMarkers(markers));
      dispatch(mapSetCircles(circles));
      dispatch(mapSetPolygons(polygons));
      dispatch(mapSetBounds(bounds));

      form.setFieldsValue({
        location_type: formData.location_type && formData.location_type.name,
      });

      form.setFieldsValue({
        cluster: formData.cluster && formData.cluster.code,
      });
    }
  }, [formData, dispatch, form, getGeofenceType]);

  useEffect(() => {
    if (markerList && markerList.length > 0) {
      form.setFieldsValue({
        latitude: parseFloat(markerList[0].position.lat.toFixed(6)),
      });
      form.setFieldsValue({
        longitude: parseFloat(markerList[0].position.lng.toFixed(6)),
      });
    }
  }, [markerList, form]);

  useEffect(() => {
    if (circleList && circleList.length > 0) {
      form.setFieldsValue({
        radius: parseFloat(circleList[0].radius.toFixed(0)),
      });
    }
  }, [circleList, form]);

  useEffect(() => {
    // Reset radius when changing to polygon
    if (polygonList && polygonList.length > 0) {
      form.setFieldsValue({
        radius: 0,
      });
    }
  }, [polygonList, form]);

  const [
    createLocationMaster,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateLocationMasterMutation();

  const [
    updateLocationMaster,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateLocationMasterMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = async (values) => {
    if (values.time_windows && values.time_windows.length > 0) {
      const uniqueValues = new Set(
        values.time_windows.map(
          (v) =>
            `${v.start_time.format('HH:mm:ss')}-${v.end_time.format(
              'HH:mm:ss'
            )}`
        )
      );

      if (uniqueValues.size < values.time_windows.length) {
        message.error('Found duplicate from/to time');
        return;
      }
    }
    if (markerList.length === 0) {
      message.error('Please input location center');
      return;
    }
    if (polygonList.length === 0 && circleList.length === 0) {
      message.error('Please input location area');
      return;
    }
    Modal.confirm({
      title: 'Are you sure want to submit?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          let geometryType = getGeofenceType(polygonList, circleList);
          let cluster = clusters.find((c) => c.label === values.cluster);
          let location_type = locationTypes.find(
            (c) => c.label === values.location_type
          );
          const postData = {
            id: formData && formData.id,
            lat: markerList[0].position.lat,
            lng: markerList[0].position.lng,
            geofence_type: geometryType,
            radius:
              geometryType === 'circle' ? parseInt(circleList[0].radius) : null,
            circle_lat:
              geometryType === 'circle' ? circleList[0].center.lat : null,
            circle_lng:
              geometryType === 'circle' ? circleList[0].center.lng : null,
            polygon:
              geometryType === 'polygon'
                ? (() => {
                    // Encode polygon from lat/lng list
                    let poly = new window.google.maps.Polygon({
                      paths: polygonList[0].path,
                    });

                    return window.google.maps.geometry.encoding.encodePath(
                      poly.getPath()
                    );
                  })()
                : null,
            cluster: cluster && cluster.id,
            location_type: location_type && location_type.id,
            code: values.code,
            name: values.name,
            short_name: values.short_name,
            time_windows:
              values.time_windows &&
              values.time_windows.map((d) => ({
                // start_time: d.start_time.clone().utc().format('HH:mm:ss'),
                // end_time: d.end_time.clone().utc().format('HH:mm:ss'),
                start_time: d.start_time.clone().format('HH:mm:ss'),
                end_time: d.end_time.clone().format('HH:mm:ss'),
              })),
          };
          if (isCreate) {
            createLocationMaster(postData);
          } else {
            updateLocationMaster(postData);
          }
        } catch (err) {
          console.log(err);
          message.error('Something went wrong');
        }
      },
    });
  };

  const onBack = () => {
    dispatch(mapDrawingMode(null));
    dispatch(mapResetMap());

    setTimeout(() => {
      dispatch(goBack());
    }, 100);
  };

  const handleDrawingMode = (mode) => {
    if (mode !== 'marker') {
      dispatch(mapAreaType(mode));
    }
    dispatch(mapDrawingMode(mode));
  };

  return (
    <>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}
        style={{ padding: '10px' }}
        onFinish={onFinish}
        autoComplete='off'
        initialValues={{
          time_windows:
            formData &&
            formData.time_windows &&
            formData.time_windows.map((d) => ({
              // start_time: moment.utc(d.start_time, 'HH:mm:ss').local(),
              // end_time: moment.utc(d.end_time, 'HH:mm:ss').local(),
              start_time: moment(d.start_time, 'HH:mm:ss'),
              end_time: moment(d.end_time, 'HH:mm:ss'),
            })),
        }}
      >
        <StyledPageHeader
          ghost={false}
          onBack={onBack}
          title='Edit Location'
          subTitle=''
          extra={[
            <Button
              size='small'
              key='search-button'
              type='primary'
              htmlType='submit'
              loading={isUpdating || isCreating}
            >
              Submit
            </Button>,
          ]}
        >
          <Divider style={{ marginTop: '0px' }} />
          <Form.Item
            label='Cluster'
            name='cluster'
            initialValue={formData && formData.cluster && formData.cluster.name}
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              showSearch
              placeholder='Select a cluster code'
              optionFilterProp='children'
              loading={isClustersFetching}
              disabled={readonly}
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {clusters &&
                clusters.map((c, index) => (
                  <Option key={index} value={c.label}>
                    {c.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label='Type'
            name='location_type'
            rules={[
              {
                required: false,
              },
            ]}
          >
            <Select
              placeholder='Type'
              optionFilterProp='children'
              loading={isLocationTypesFetching}
              disabled={readonly}
              onChange={(value) => {
                form.setFieldsValue({ location_type: value });
              }}
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {locationTypes &&
                locationTypes.map((c, index) => {
                  return (
                    <Option key={index} value={c.label}>
                      {c.label}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            label='Code'
            name='code'
            initialValue={formData && formData.code}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={readonly} />
          </Form.Item>
          <Form.Item
            label='Name'
            name='name'
            initialValue={formData && formData.name}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={readonly} />
          </Form.Item>{' '}
          <Form.Item
            label='Short name'
            name='short_name'
            initialValue={formData && formData.short_name}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={readonly} />
          </Form.Item>
          <Divider orientation='left'>Location Center</Divider>
          <MarkerLatLngSection>
            <Button
              style={{ marginRight: '15px' }}
              type={drawingMode === 'marker' && 'primary'}
              shape='circle'
              icon={<EnvironmentOutlined />}
              onClick={() => handleDrawingMode('marker')}
            />
            <Form.Item
              name='latitude'
              initialValue={
                formData && formData.location_type && formData.location_type.lat
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input disabled={true} placeholder={'latitude'} />
            </Form.Item>
            <Form.Item
              name='longitude'
              initialValue={
                formData && formData.location_type && formData.location_type.lng
              }
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input disabled={true} placeholder={'longitude'} />
            </Form.Item>
          </MarkerLatLngSection>
          <Divider orientation='left'>Location Area</Divider>
          <Radio.Group
            value={drawingMode}
            buttonStyle='solid'
            onChange={(event) => handleDrawingMode(event.target.value)}
            style={{ display: 'flex', justifyContent: 'end' }}
          >
            <Radio.Button value='circle'>
              <Icon
                component={CircleSvg}
                style={{ fontSize: '14px', marginRight: '4px' }}
              />
              Circle
            </Radio.Button>
            <Radio.Button value='polygon'>
              <Icon
                component={PolygonSvg}
                style={{ fontSize: '14px', marginRight: '4px' }}
              />
              Polygon
            </Radio.Button>
          </Radio.Group>
          {drawingMode === 'circle' && (
            <Form.Item
              style={{ marginTop: '10px' }}
              label='Radius (m)'
              name='radius'
              initialValue={formData && formData.radius}
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input disabled={true} placeholder={'radius'} />
            </Form.Item>
          )}
          <Divider orientation='left'>Time windows</Divider>
          <Form.List name='time_windows'>
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, fieldKey, ...restField }) => {
                  return (
                    <Space key={key} align='baseline'>
                      <Form.Item
                        {...restField}
                        name={[name, 'start_time']}
                        fieldKey={[fieldKey, 'start_time']}
                        rules={[
                          { required: true, message: 'Missing from time' },
                        ]}
                      >
                        <TimePicker
                          style={{ width: 140 }}
                          placeholder='From time'
                        />
                      </Form.Item>
                      <ArrowRightOutlined />
                      <Form.Item
                        {...restField}
                        name={[name, 'end_time']}
                        fieldKey={[fieldKey, 'end_time']}
                        rules={[{ required: true, message: 'Missing to time' }]}
                      >
                        <TimePicker
                          style={{ width: 140 }}
                          placeholder='To time'
                        />
                      </Form.Item>
                      <MinusCircleOutlined
                        style={{ marginLeft: '10px' }}
                        onClick={() => remove(name)}
                      />
                    </Space>
                  );
                })}
                <Form.Item>
                  <Button
                    type='dashed'
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add time window
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </StyledPageHeader>
      </Form>
    </>
  );
};
const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
  .ant-page-header-content {
    padding-top: 5px;
    padding-bottom: 15px;
  }
`;

const MarkerLatLngSection = styled.div`
  display: flex;
`;

export default ManageForm;
