import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Modal,
  Button,
  Select,
  Typography,
  Collapse,
  Table,
  Progress,
} from 'antd';
import moment from 'moment';
import styled from 'styled-components';
import AntTable from 'common_components/AntTable';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { createLotApi } from 'reducers/createLot/api';

const { Text } = Typography;
const { Panel } = Collapse;

const TableToolbar = (props) => {
  const {
    //parameter
    isLoadingGetFilter,
    enableSearch,
    createLotSummaryAll,
    filterSelectFrom,
    filterOptionFrom,
    setFilterSelectFrom,
    //function
    isLoadingEntry,
    apiSearchTable,
    apiCreateLot,
    progress,
    statusSummary,
  } = props;
  const [disableCreateLotBtn, setDisableCreateLotBtn] = useState(false);
  const [IsDownloadEnableBtn, SetIsDownloadEnableBtn] = useState(false);
  const [isStatusCreateLot, setIsStatusCreateLot] = useState(false);
  const [isStatusCreateLotProcessing, setIsStatusCreateLotProcessing] =
    useState(false);
  const [IsDownloading, SetIsDownloading] = useState(false);
  const dispatch = useDispatch();

  const onBtnCreateLot = async () => {
    let payload = {
      id: createLotSummaryAll?.id,
    };
    apiCreateLot(payload);
    setDisableCreateLotBtn(true);
    setIsStatusCreateLotProcessing(true);
  };
  const onBtnDownload = async () => {
    SetIsDownloading(true);
    let payload = {
      plan_id: createLotSummaryAll?.id,
    };
    console.log('payload: ', payload);
    const resp = await dispatch(
      createLotApi.endpoints.download.initiate(payload),
    );
    // console.log('createLotApi: ', createLotApi.endpoints.download.initiate);
    console.log('resp: ', resp);
    if (!resp.isSuccess || !resp.data) {
      Modal.error({
        title: resp.status,
        icon: <ExclamationCircleOutlined />,
        content: 'download failed',
      });
      return;
    }
    const now = moment().format('YYYYMMDD_HHmmss');
    const outputFilename = `create_lot_${now}.csv`;
    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(
      new Blob([`\ufeff${resp.data}`], { type: 'text/csv;charset=utf-8' }),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();

    SetIsDownloading(false);
  };

  const onBtnSearch = async () => {
    apiSearchTable(false);
  };

  useEffect(() => {
    if (statusSummary) {
      console.log('statusSummary33', statusSummary);
      if (
        statusSummary?.status === 'not_ready' ||
        statusSummary?.status === 'completed'
      ) {
        setIsStatusCreateLot(true);
      }

      if (statusSummary?.status === 'ready') {
        setIsStatusCreateLot(false);
      }

      if (statusSummary?.status === 'processing') {
        setIsStatusCreateLotProcessing(true);
      } else {
        setIsStatusCreateLotProcessing(false);
        setDisableCreateLotBtn(false);
      }
    }
  }, [statusSummary]);

  useEffect(() => {
    const IsLotCreated = statusSummary?.status === 'completed' || false;
    SetIsDownloadEnableBtn(!IsDownloading && IsLotCreated);
  }, [IsDownloading, statusSummary]);

  const [columnsHeader, setColumnsHeader] = useState([
    {
      title: 'Total Volume',
      dataIndex: 'total_volume',
      align: 'center',
      width: '150px',
    },
    {
      title: 'Temp lot',
      dataIndex: 'temp_lot',
      align: 'center',
      width: '150px',
    },
    {
      title: 'Remain',
      dataIndex: 'remain',
      align: 'center',
      width: '150px',
    },
  ]);

  return (
    <Container>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[20]}
            style={{ margin: '0px 10px 5px 10px' }}
            align='middle'
            justify='center'
          >
            <Col span='12'>
              {' '}
              <Row
                gutter={[20]}
                style={{ marginBottom: '20px' }}
                align='middle'
                justify='center'
              >
                <Col>
                  <Typography style={{ marginBottom: '5px' }}>
                    {' '}
                    Summary all unit
                  </Typography>
                  <AntTable
                    rowKey={() => {
                      return 1;
                    }}
                    tableId={'id'}
                    bordered
                    showSorterTooltip={false}
                    columns={columnsHeader}
                    dataSource={
                      createLotSummaryAll && [
                        createLotSummaryAll.summary_all_unit,
                      ]
                    }
                    scroll={{ y: 300, x: 'max-content' }}
                    pagination={false}
                    summary={(pageData) => {
                      console.log('pageData', pageData);

                      let total_volumes = 0;
                      let temp_lots = 0;
                      let remains = 0;
                      if (pageData.length > 0 && pageData[0] !== undefined) {
                        pageData?.forEach(
                          ({ total_volume, temp_lot, remain }) => {
                            total_volumes = (total_volume / total_volume) * 100;
                            temp_lots += (temp_lot / total_volume) * 100;
                            remains += (remain / total_volume) * 100;
                          },
                        );
                      }

                      return (
                        <>
                          <Table.Summary.Row
                            style={{ backgroundColor: '#D9D9D9' }}
                          >
                            <Table.Summary.Cell index={0} align='center'>
                              <Text>
                                {total_volumes
                                  ? `${Math.round(total_volumes)}%`
                                  : '0%'}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={1} align='center'>
                              <Text>
                                {temp_lots ? `${Math.round(temp_lots)}%` : '0%'}
                              </Text>
                            </Table.Summary.Cell>
                            <Table.Summary.Cell index={2} align='center'>
                              <Text>
                                {remains ? `${Math.round(remains)}%` : '0%'}
                              </Text>
                            </Table.Summary.Cell>
                          </Table.Summary.Row>
                        </>
                      );
                      // }
                    }}
                  />
                </Col>
              </Row>
              <Row gutter={[20]} align='middle' justify='center'>
                <Col align='end'>
                  <Typography>From</Typography>
                </Col>
                <Col>
                  <Select
                    disabled={
                      (enableSearch && progress === 100) ||
                      isStatusCreateLotProcessing
                    }
                    mode='single'
                    style={{
                      textAlign: 'center',
                      width: '200px',
                    }}
                    loading={isLoadingEntry}
                    maxTagCount='responsive'
                    placeholder={'from'}
                    value={filterSelectFrom}
                    options={filterOptionFrom}
                    onChange={(values) => {
                      setFilterSelectFrom(values);
                    }}
                  />
                </Col>
                <Col span={3} align='end'>
                  <Button
                    disabled={
                      filterSelectFrom.length < 1 || isStatusCreateLotProcessing
                    }
                    type='primary'
                    style={{ width: '100px' }}
                    onClick={onBtnSearch}
                    loading={isLoadingGetFilter}
                  >
                    Search
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col span='12'>
              <Row
                gutter={[20]}
                style={{ marginBottom: '10px' }}
                align='middle'
                justify='center'
              >
                <Col align='end'>
                  <Progress percent={progress} steps={5} />
                </Col>
              </Row>
              <Row gutter={[10]} align='middle' justify='center'>
                <Col>
                  <Button
                    type='primary'
                    style={{
                      marginTop: '10px',
                      marginRight: '0.1rem',
                      marginLeft: '0.1rem',
                    }}
                    onClick={onBtnCreateLot}
                    loading={disableCreateLotBtn || isStatusCreateLotProcessing}
                    disabled={isStatusCreateLot}
                  >
                    Create Lot
                  </Button>
                  <Button
                    type='primary'
                    style={{
                      marginTop: '10px',
                      marginRight: '0.1rem',
                      marginLeft: '0.1rem',
                    }}
                    onClick={() => {
                      dispatch(push(`/app/planning/manage_trip_board`));
                    }}
                    disabled={isStatusCreateLotProcessing}
                  >
                    Manage Trip Dashboard
                  </Button>
                  <Button
                    type='primary'
                    style={{
                      marginTop: '10px',
                      marginRight: '0.1rem',
                      marginLeft: '0.1rem',
                    }}
                    onClick={() => {
                      dispatch(push(`/app/planning/assign_volume/`));
                    }}
                    disabled={isStatusCreateLotProcessing}
                  >
                    Assign Volume
                  </Button>
                  <Button
                    type='ghost'
                    style={{
                      marginTop: '10px',
                      marginRight: '0.1rem',
                      marginLeft: '0.1rem',
                    }}
                    onClick={onBtnDownload}
                    disabled={!IsDownloadEnableBtn}
                    loading={IsDownloading}
                  >
                    Download
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default TableToolbar;
