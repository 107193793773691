import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, message, Upload, InputNumber } from 'antd';
import { useUpdateDriverMessagesMutation } from 'reducers/driverMessages/api';

import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { UploadOutlined } from '@ant-design/icons';
const { TextArea } = Input;

const DriverMessagesFormComponent = (props) => {
  const { formData, readonly } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();

  const spilt = (value) => {
    if (value) {
      let cut1 = value.split('driverMessage_images/');
      let cut2 = cut1[1].split('?AWSAccessKeyId');
      let url = cut2[0];
      return url;
    }
  };

  const fileListDisplay = [
    {
      name:
        formData && formData.audio_url
          ? spilt(formData && formData.audio_url)
          : null,
      uid: '-1',
      status: 'done',
      url: formData && formData.audio_url,
      thumbUrl: formData && formData.audio_url,
    },
  ];

  const [
    updateDriverMessages,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateDriverMessagesMutation();

  useEffect(() => {
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, setErrorData]);

  useEffect(() => {
    if (!isUninitUpdating && isSuccessUpdating) {
      message.success('Data was successfully saved');
    }
  }, [isSuccessUpdating, isUninitUpdating, dispatch]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let formDataImage = new FormData();
    if (fileList.length === 0) {
      let data = {
        id: formData.id,
        year_value: validData.year_value,
        text: validData.text,
      }
      updateDriverMessages({ file: data, id: formData.id });
    } else if (fileList.status === 'removed') {
      
      let data = {
        id: formData.id,
        year_value: validData.year_value,
        text: validData.text,
        audio_url: null,
      }
      updateDriverMessages({ file: data, id: formData.id });
    }
      else{
      formDataImage.append('audio_url', fileList);
      formDataImage.append('year_value', 3);
      formDataImage.append('id', formData && formData.id);
      if (fileList.status === 'removed') {
        formDataImage.append('audio_url', '');
      }
      if (formData && !formData.audio_url) {
        formDataImage.append('audio_url', fileList);
      }
      formDataImage.append('text', validData.text);
      updateDriverMessages({ file: formDataImage, id: formData.id });    }
  };

  const getFile = (e) => {
    setFileList(e.file);
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 10,
        }}
        style={{ padding: '20px' }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <Form.Item
          wrapperCol={{
            offset: 12,
            span: 12,
          }}
        >
          <Button
            type='primary'
            htmlType='submit'
            loading={isUpdating}
            disabled={readonly}
          >
            Submit
          </Button>
        </Form.Item>

        <Form.Item
          label='Category'
          name='category'
          initialValue={formData && formData.category}
        >
          <Input
            disabled
            placeholder={'Category'}
            style={{ color: '#000', backgroundColor: 'transparent' }}
          />
        </Form.Item>
        <Form.Item
          label='Criteria'
          name='criteria'
          initialValue={formData && formData.criteria}
        >
          <Input
            disabled
            placeholder={'Criteria'}
            style={{ color: '#000', backgroundColor: 'transparent' }}
          />
        </Form.Item>
        <Form.Item
          label='Criteria Type'
          name='criteria_type'
          initialValue={formData && formData.criteria_type}
        >
          <Input
            disabled
            placeholder={'criteria type'}
            style={{ color: '#000', backgroundColor: 'transparent' }}
          />
        </Form.Item>
        <Form.Item
          label='Experience Year'
          name='year_value'
          initialValue={formData && formData.year_value}
          rules={[
            {
              required: true,
              message: 'Please input experience year',
            },
          ]}
        >
          <InputNumber
            disabled={readonly}
            placeholder={'experience Year'}
            min={0}
          />
        </Form.Item>
        <Form.Item
          label='Text'
          name='text'
          initialValue={formData && formData.text}
          rules={[
            {
              required: true,
              message: 'Please input text',
            },
          ]}
        >
          <TextArea
            autoSize={{ minRows: 7, maxRows: 7 }}
            disabled={readonly}
            placeholder={'Text'}
          />
        </Form.Item>
        <Form.Item
          name='audio_url'
          label='Upload Audio'
          valuePropName='fileList'
          getValueFromEvent={getFile}
        >
          <Upload
            accept='audio/mp3,audio/wav'
            listType='text'
            maxCount={1}
            defaultFileList={
              formData && formData.audio_url ? [...fileListDisplay] : []
            }
            beforeUpload={(value) => {
              if (value.size / 1024 / 1024 > 10) {
                Modal.error({
                  title: 'Error',
                  icon: <ExclamationCircleOutlined />,
                  content:
                    'File size not over 10 MB per file (can play about 10 minutes).',
                  okText: 'OK',
                });
              }
              return false;
            }}
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </>
  );
};

export default DriverMessagesFormComponent;
