import React, { useState, useEffect } from 'react';
import {
  Button,
  Space,
  Input,
  Select,
  Typography,
  Modal,
  Collapse,
} from 'antd';
import styled from 'styled-components';

import { ExclamationCircleOutlined, FileAddOutlined } from '@ant-design/icons';
import { useFindAllYardsQuery } from 'reducers/masterData/yard/api';
import { useFindAllClustersQuery } from 'reducers/masterData/cluster/api';
import { useFindAllRegionsQuery } from 'reducers/masterData/region/api';
import { getCurrentUser } from 'reducers/user';

import { useSelector, useDispatch } from 'react-redux';

import { setOperationDashboardFilter } from 'reducers/digitalmap/operationDashboard';
const { Search } = Input;
const { Panel } = Collapse;

const createPayload = ({ filterYards, filterRegions, filterClusters }) => {
  return {
    yards: filterYards && filterYards.length > 0 ? filterYards : null,
    regions: filterRegions && filterRegions.length > 0 ? filterRegions : null,
    clusters:
      filterClusters && filterClusters.length > 0 ? filterClusters : null,
  };
};

const TableToolbar = (props) => {
  const {
    isLoading,
    onInit,
    onCreate,
    showFilter,
    setShowFilter,
    callingCompleteCount,
  } = props;
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  // Store filters
  const storedFilters = useSelector((state) => {
    return state.callingsheet.filters;
  });

  const [yardOptions, setYardOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [clusterOptions, setClusterOptions] = useState([]);

  const [filterYards, setfilterYards] = useState(
    storedFilters && storedFilters.filterYards ? storedFilters.filterYards : []
  );
  const [filterRegions, setFilterRegions] = useState(
    storedFilters && storedFilters.filterRegions
      ? storedFilters.filterRegions
      : []
  );
  const [filterClusters, setFilterClusters] = useState(
    storedFilters && storedFilters.filterClusters
      ? storedFilters.filterClusters
      : []
  );

  useEffect(() => {
    if (onInit) {
      onInit(
        createPayload({
          filterYards: [],
          filterRegions: [],
          filterClusters: [],
        })
      );
    }
  }, [onInit]);

  useEffect(() => {
    dispatch(
      setOperationDashboardFilter({
        filterYards,
        filterClusters,
        filterRegions,
      })
    );
  }, [filterYards, filterClusters, filterRegions, dispatch]);

  // Query all yards
  const {
    data: dataYards,
    error: errorYards,
    isFetching: isYardsFetching,
    refetch: refetchYards,
  } = useFindAllYardsQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataYards) {
      setYardOptions(dataYards.map((d) => ({ id: d.id, label: d.code })));
    }
  }, [dataYards]);

  useEffect(() => {
    if (errorYards) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load yards',
        okText: 'Retry',
        onOk: refetchYards,
        cancelText: 'Cancel',
      });
    }
  }, [errorYards, refetchYards]);

  // Query all regions
  const {
    data: dataRegions,
    error: errorRegions,
    isFetching: isRegionsFetching,
    refetch: refetchRegions,
  } = useFindAllRegionsQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataRegions) {
      setRegionOptions(dataRegions.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataRegions]);

  useEffect(() => {
    if (errorRegions) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load regions',
        okText: 'Retry',
        onOk: refetchRegions,
        cancelText: 'Cancel',
      });
    }
  }, [errorRegions, refetchRegions]);

  // Query all clusters
  const {
    data: dataClusters,
    error: errorClusters,
    isFetching: isClustersFetching,
    refetch: refetchClusters,
  } = useFindAllClustersQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataClusters) {
      setClusterOptions(dataClusters.map((d) => ({ id: d.id, label: d.code })));
    }
  }, [dataClusters]);

  useEffect(() => {
    if (errorClusters) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load clusters',
        okText: 'Retry',
        onOk: refetchClusters,
        cancelText: 'Cancel',
      });
    }
  }, [errorClusters, refetchClusters]);

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Filter Options' key={1}>
          <Space
            direction='horizontal'
            size={16}
            style={{ padding: '5px' }}
            align='start'
          >
            <Space direction='vertical'>
              <div>
                <Typography>Calling no.</Typography>
                <Search
                  // value={filterCallingNo}
                  placeholder='Search calling no.'
                  allowClear
                  onChange={(event) => {
                    // setFilterCallingNo(event.target.value);
                  }}
                />
              </div>
            </Space>
            <Space direction='vertical'>
              <div>
                <Typography>Yard</Typography>
                <Select
                  mode='multiple'
                  style={{
                    width: '200px',
                  }}
                  allowClear={true}
                  maxTagCount='responsive'
                  loading={isYardsFetching}
                  placeholder={'Yard'}
                  value={filterYards}
                  options={yardOptions}
                  onChange={(values) => {
                    setfilterYards(values);
                  }}
                />
              </div>
              <div>
                <Typography>Cluster</Typography>
                <Select
                  mode='multiple'
                  style={{
                    width: '200px',
                  }}
                  allowClear={true}
                  maxTagCount='responsive'
                  loading={isClustersFetching}
                  placeholder={'Cluster'}
                  value={filterClusters}
                  options={clusterOptions}
                  onChange={(values) => {
                    setFilterClusters(values);
                  }}
                />
              </div>
            </Space>
            <Space direction='vertical'>
              <div>
                <Typography>Region</Typography>
                <Select
                  mode='multiple'
                  style={{
                    width: '200px',
                  }}
                  allowClear={true}
                  maxTagCount='responsive'
                  loading={isRegionsFetching}
                  placeholder={'Region'}
                  value={filterRegions}
                  options={regionOptions}
                  onChange={(values) => {
                    setFilterRegions(values);
                  }}
                />
              </div>
              <div>
                <Typography>Destination</Typography>
                <Select
                  mode='multiple'
                  style={{
                    width: '200px',
                  }}
                  allowClear={true}
                  maxTagCount='responsive'
                  loading={isRegionsFetching}
                  placeholder={'Destination'}
                  value={filterRegions}
                  options={regionOptions}
                  onChange={(values) => {
                    setFilterRegions(values);
                  }}
                />
              </div>
            </Space>
            <Space direction='vertical' style={{ marginLeft: '30px' }}>
              <div>
                <Typography>Calling complete</Typography>
                <ReadonlyText>
                  {callingCompleteCount ? callingCompleteCount : '-'}
                </ReadonlyText>
              </div>
              <StyledButton
                type='primary'
                loading={isLoading}
                onClick={onCreate}
                icon={<FileAddOutlined />}
              >
                Create
              </StyledButton>
            </Space>
          </Space>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;
const ReadonlyText = styled(Typography)`
  background: #fff;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  padding: 2px;
  text-align: center;
`;
const StyledButton = styled(Button)`
  display: block;
  margin-top: 15px;
  width: 150px;
`;

const Container = styled.div`
  padding: 10px 0;
`;

export default TableToolbar;
