import { handleActions } from 'redux-actions';
// ACTION CREATORS
import { createAction } from 'redux-actions';

export const setSidebarVisibility = createAction('SET_SIDEBAR_VISIBILITY');
export const uiSetBreadcrumbs = createAction('UI_SET_BREADCRUMBS');

const initialState = {
  sidebarOpen: true,
  crumbs: null,
};

// REDUCERS
export default handleActions(
  {
    [setSidebarVisibility](state, { payload }) {
      return { ...state, sidebarOpen: payload };
    },

    [uiSetBreadcrumbs](state, { payload }) {
      return { ...state, crumbs: payload };
    },
  },
  initialState
);

// SELECTORS
export const getSidebarOpen = (state) => state.ui.sidebarOpen;
export const getBreadcrumbs = (state) => state.ui.crumbs;
