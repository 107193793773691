import React, { useState, useEffect, useCallback } from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import {
  PageHeader,
  Modal,
  Row,
  Col,
  Button,
  message,
  Space,
  Card,
} from 'antd';
import styled from 'styled-components';
import Table from './Table';
import { useFindConfirmTrailerDriverByIdQuery } from 'reducers/dispatchBoard/ConfirmDriverTrailer/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { confirmDriverTrailerApi } from 'reducers/dispatchBoard/ConfirmDriverTrailer/api';
import Trailer from './Trailer/Table';
import Driver from './Driver/Table';
import { useParams } from 'react-router-dom';
import { push } from 'connected-react-router';

const ReceiveJobComponent = (props) => {
  const [selectedRowsDriver1, setSelectedRowsDriver1] = useState(null);
  const [selectedRowsDriver2, setSelectedRowsDriver2] = useState(null);
  const [rowData, setRowData] = useState([]);
  const [trailersConfirm, setTrailersConfirm] = useState(null);
  const [driverConfirm, setDriverConfirm] = useState(null);
  const [driverConfirm1, setDriverConfirm1] = useState(null);
  const [driverConfirm2, setDriverConfirm2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const { job_code, id, working_date } = useParams();
  const [trailerDriverSame, setTrailerDriverSame] = useState(null);
  const [clearTable, setClearTable] = useState(false);
  const { data, error } = useFindConfirmTrailerDriverByIdQuery({
    job_code: job_code,
    working_day: working_date,
  });
  const [loadingUnit, setLoadingUnit] = useState(null);
  const [clusterId, setClusterId] = useState(null);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  const onQueryOption = useCallback(
    async (value) => {
      let resData = null;
      try {
        if (value) {
          setIsLoading(true);
          let result = await dispatch(
            confirmDriverTrailerApi.endpoints.findConfirmTrailerDriverByCallingNo.initiate(
              value,
              {
                forceRefetch: true,
              }
            )
          );
          if (result && result.error) {
            setErrorData(result.error);
          } else {
            if (result.data) {
              resData = result.data.vins;
            }
          }
        }
        return resData;
      } catch (err) {
        console.log('err', err);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, setErrorData]
  );

  useEffect(() => {
    if (data && job_code && id) {
      async function fetchData() {
        let arr = [];
        let arrNew = [];
        let checkTrailerSame = null;
        for (let index = 0; index < data?.length; index++) {
          arr = await onQueryOption(data[index].callingsheet?.callingsheet_no);
          arrNew.push({ ...data[index], vin: arr });
        }
        const search = data?.find(
          (d) =>
            d.trailer ||
            d.driver_1 ||
            d.driver_2 ||
            (d.trailer && d.driver_1) ||
            (d.trailer && d.driver_1 && d.driver_2)
        );
        if (search) {
          checkTrailerSame = false;
        } else {
          checkTrailerSame = true;
        }
        setRowData(arrNew);
        setTrailerDriverSame(checkTrailerSame);

        let unit = data.find((d) => d.id === id)
          ? data.find((d) => d.id === id)
          : null;
        setClusterId(unit && unit.destination?.id);
        setLoadingUnit(unit && unit.actual_loading_unit);
      }

      fetchData();
    }
  }, [data, onQueryOption, id, job_code]);

  const handleConfirmAction = async () => {
    setClearTable(false);
    let splitTriler = Number(
      trailersConfirm && trailersConfirm.trailer_type?.code
    );
    let splitDriver1 =
      driverConfirm1 && driverConfirm1
        ? Number(
            driverConfirm1 && driverConfirm1.base_trailer_trailer_type?.code
          )
        : null;

    let splitDriver2 =
      driverConfirm2 && driverConfirm2
        ? Number(
            driverConfirm2 && driverConfirm2.base_trailer_trailer_type?.code
          )
        : null;

    let errExperience = null;
    let errMatchingTlepType = null;

    if (
      (driverConfirm1 && driverConfirm1.experience === 'Yes') ||
      (driverConfirm1 &&
        driverConfirm1.experience === 'Yes' &&
        driverConfirm2 &&
        driverConfirm2.experience === 'Yes') ||
      (driverConfirm1 &&
        driverConfirm1.experience === 'Yes' &&
        driverConfirm2 &&
        driverConfirm2.experience === 'No') ||
      (driverConfirm1 &&
        driverConfirm1.experience === 'No' &&
        driverConfirm2 &&
        driverConfirm2.experience === 'Yes')
    ) {
      errExperience = true;
    } else {
      errExperience = false;
    }

    let post = null;

    if (
      splitTriler === splitDriver1 ||
      (splitTriler === splitDriver2 && splitDriver1 === splitDriver2) ||
      (splitTriler === splitDriver1 &&
        splitTriler === splitDriver2 &&
        splitDriver1 === splitDriver2)
    ) {
      if (splitDriver1 && splitDriver2) {
        if (splitDriver1 === splitDriver2) {
          post = {
            dispatch_board_id: id,
            trailer_id: trailersConfirm.id,
            driver_1_id: driverConfirm && driverConfirm[0]?.id,
            driver_2_id: driverConfirm && driverConfirm[1]?.id,
          };

          errMatchingTlepType = true;
        } else {
          errMatchingTlepType = false;
        }
      } else {
        post = {
          dispatch_board_id: id,
          trailer_id: trailersConfirm?.id,
          driver_1_id: driverConfirm && driverConfirm[0]?.id,
          driver_2_id: driverConfirm && driverConfirm[1]?.id,
        };
        errMatchingTlepType = true;
      }
    } else {
      errMatchingTlepType = false;
    }

    if (errExperience && errMatchingTlepType) {
      if (post) {
        try {
          let result = await dispatch(
            confirmDriverTrailerApi.endpoints.findAllConfirm.initiate(post, {
              forceRefetch: true,
            })
          );
          if (result && result.error) {
            setErrorData(result.error);
          } else {
            if (result.data) {
              message.success('Data was successfully saved');
              setTimeout(() => {
                dispatch(push(`/app/delivery_mng/lists`));
              }, 1000);
            }
          }
        } catch (err) {
          message.error('Something went wrong.');
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      if (!errExperience && !errMatchingTlepType) {
        return msgErrorAll();
      } else if (!errExperience) {
        msgErrorExperienced();
      } else if (!errMatchingTlepType) {
        msgErrorMatchingTlepType();
      }
    }
  };

  const msgConfirm = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>Would you like to confirm the driver and trailer?</h4>
          <CardStyle>
            Confirm driver & trailer by <br />
            Trailer: {trailersConfirm?.trailer_card_id} <br />
            Driver 1: {driverConfirm && driverConfirm[0]?.driver_name_th}
            <br />
            Driver 2: {driverConfirm && driverConfirm[1]?.driver_name_th}
            <br />
          </CardStyle>
        </>
      ),
      onOk: handleConfirmAction,
      cancelText: 'Cancel',
      okText: 'OK',
      width: 500,
    });
  };

  const beforeConfirm = () => {
    let bzpTriler = trailersConfirm?.bzp?.code;
    let bzpDriver1 = driverConfirm1?.bzp?.code;
    let bzpDriver2 = driverConfirm2?.bzp?.code;
    let dataJobCode =
      data && data.find((d) => d.job_code === job_code)?.bzp?.code;
    let warningMatchingBzp = null;

    if (bzpTriler && bzpDriver1 && bzpDriver2) {
      let arrBzp = [bzpTriler, bzpDriver1, bzpDriver2, dataJobCode];
      let uniq = new Set(arrBzp);

      if (uniq.size === 1) {
        warningMatchingBzp = true;
      } else {
        warningMatchingBzp = false;
      }
      if (!warningMatchingBzp) {
        msgWarningMatchingBzp();
      } else {
        btnConfirm();
      }
    } else {
      let arrBzp = [bzpTriler, bzpDriver1, dataJobCode];
      let uniq = new Set(arrBzp);

      if (uniq.size === 1) {
        warningMatchingBzp = true;
      } else {
        warningMatchingBzp = false;
      }
      if (
        (!warningMatchingBzp && bzpTriler && bzpDriver1 && bzpDriver2) ||
        (!warningMatchingBzp && bzpTriler && bzpDriver1)
      ) {
        msgWarningMatchingBzp();
      } else {
        btnConfirm();
      }
    }
  };
  const msgErrorSelect = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>We found your selection has problems!!</h4>
          <div style={{ color: 'red' }}>
            {' '}
            Please recheck your selection: <br />
          </div>

          <div style={{ color: 'red' }}>
            -Incomplete information, Please input data completely.
          </div>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };
  const msgErrorExperienced = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>We found your selection has problems!!</h4>
          <div style={{ color: 'red' }}>
            {' '}
            Please recheck your selection: <br />
          </div>

          <div style={{ color: 'red' }}>
            -Please select the experienced driver.
          </div>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };
  const msgErrorAll = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>We found your selection has problems!!</h4>
          <div style={{ color: 'red' }}>
            {' '}
            Please recheck your selection: <br />
          </div>

          <div style={{ color: 'red' }}>
            -Trailer Type is not matching TLEP Type.
            <br />
            -Please select the experienced driver.
          </div>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };

  const msgErrorMatchingTlepType = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>We found your selection has problems!!</h4>
          <div style={{ color: 'red' }}>
            {' '}
            Please recheck your selection: <br />
          </div>

          <div style={{ color: 'red' }}>
            -Trailer Type is not matching TLEP Type.
          </div>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };

  const msgWarningMatchingBzp = () => {
    Modal.warning({
      title: 'Warning!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div style={{ color: 'red' }}>
            คุณเลือกบริษัทที่ Trailer หรือ Driver ไม่ตรงกับบริษัทในงาน
            <br />
            คุณยืนยันที่จะรับงานไหม!!
          </div>
        </>
      ),
      okText: 'OK',
      onOk: btnConfirm,
      width: 500,
    });
  };
  const msgCallingNo = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>We found your selection has problems!!</h4>
          <div style={{ color: 'red' }}> Please recheck your Calling No.</div>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };

  const btnConfirm = () => {
    let callingNo = data.find((d) => d.callingsheet && d.id === id);
    let dataJob = data.find((d) => d.destination && d.id === id);
    if (
      !callingNo &&
      dataJob?.destination?.diagram_type?.name_en !== 'Indochina'
    ) {
      msgCallingNo();
    } else {
      if (
        (trailersConfirm && driverConfirm1) ||
        (trailersConfirm && driverConfirm1 && driverConfirm2)
      ) {
        let combineCheck = false;
        let combineSelectd = null;
        combineSelectd = data.find((d) => d.combine && !d.driver_1);
        for (let index = 0; index < data?.length; index++) {
          if (
            data[index].combine &&
            data[index].trailer &&
            data[index].driver_1
          ) {
            if (data[index].combine === combineSelectd?.combine) {
              combineCheck = true;
            }
          }
        }
        if (combineCheck) {
          Modal.warning({
            title:
              'Job code has combine job. Please confirm the job information.',
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                <CardStyle>
                  Trailer: {trailersConfirm?.trailer_card_id} <br />
                  Driver 1: {selectedRowsDriver1}{' '}
                  <br />
                  Driver 2: {selectedRowsDriver2}{' '}
                  <br />
                </CardStyle>
              </>
            ),
            onOk: msgConfirm,
            okText: 'OK',
            width: 500,
          });
        } else {
          msgConfirm();
        }
      } else {
        msgErrorSelect();
      }
    }
  };

  const onTrailersConfirm = (value) => {
    setTrailersConfirm(value && value[0]);
  };

  const onDriverConfirm = (value) => {
    setDriverConfirm(value && value);
    setDriverConfirm1(value && value[0]);
    setDriverConfirm2(value && value[1]);
  };

  const onClear = () => {
    setClearTable(true);
    setTrailersConfirm(null);
    setDriverConfirm(null);
    setDriverConfirm1(null);
    setDriverConfirm2(null);
  };

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Back to Dispatch Board'
        subTitle=''
      />

      <Content>
        <Row justify='end' style={{ paddingRight: 10, paddingBottom: 15 }}>
          <Col>
            <Space size={25}>
              <Button
                loading={isLoading}
                style={{ width: 100 }}
                type='primary'
                onClick={() => beforeConfirm()}
              >
                Confirm
              </Button>
              <Button
                loading={isLoading}
                style={{ width: 100 }}
                type='secondary'
                onClick={() => onClear()}
              >
                Clear all
              </Button>
            </Space>
          </Col>
        </Row>

        <Table
          isLoading={isLoading}
          rowData={rowData}
          id={id}
        />

        <Row style={{ padding: 10 }}>
          <Col span={12}>
            <CardStyle>
              <Trailer
                trailerSameJob={trailerDriverSame}
                trailersConfirm={onTrailersConfirm}
                dataTrip={data}
                clearTable={clearTable}
                setClearTable={setClearTable}
                loadingUnit={loadingUnit}
                clusterId={clusterId}
              />
            </CardStyle>
          </Col>
            <Col span={12}>
              <CardStyle>
                <Driver
                  driverSameJob={trailerDriverSame}
                  onDriverConfirm={onDriverConfirm}
                  dataTrip={data}
                  clearTable={clearTable}
                  setClearTable={setClearTable}
                  loadingUnit={loadingUnit}
                  clusterId={clusterId}
                  selectedRowsDriver1={selectedRowsDriver1}
                  selectedRowsDriver2={selectedRowsDriver2}
                  setSelectedRowsDriver1={setSelectedRowsDriver1}
                  setSelectedRowsDriver2={setSelectedRowsDriver2}
                />{' '}
              </CardStyle>
            </Col>
        </Row>
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;
const CardStyle = styled(Card)`
  .ant-card-body {
    padding-top: 0px;
    padding-bottom: 10px;
    padding-left: 0px;
    padding-right: 0px;
  }
  width: 100%;
`;
export default ReceiveJobComponent;
