const { orderBy } = require('lodash');
export const sortByActualReceiveJobDatetime = (jobs) => {
  let sorted = orderBy(jobs, [
    'actual_receive_job_datetime_edit',
    'actual_receive_job_datetime',
    'trip_no',
  ]);
  let tripNo = 0;
  sorted.forEach((job, _) => {
    if (!job.trip_no_new) {
      job.trip_no_new = ++tripNo;
      if (job.combine) {
        sorted
          .filter(
            (j) => j.combine === job.combine && j.trip_no_new === undefined
          )
          .forEach((j) => (j.trip_no_new = job.trip_no_new));
      }
    }
  });
  return sorted;
};
