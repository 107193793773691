import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  locationType: null,
  locationGroup: null,
  logisticPoint: null,
};

export const locationMasterSlice = createSlice({
  name: 'locationMaster',
  initialState,
  reducers: {
    clearState: (state) => {
      return initialState;
    },
    setLocationType: (state, action) => {
      state.locationType = action.payload;
    },
    setLocationGroup: (state, action) => {
      state.locationGroup = action.payload;
    },
    setLogisticPoint: (state, action) => {
      state.logisticPoint = action.payload;
    },
  },
});

export const {
  clearState,
  setLocationType,
  setLocationGroup,
  setLogisticPoint,
} = locationMasterSlice.actions;

export default locationMasterSlice.reducer;
