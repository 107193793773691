import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Col,
  Row,
  Card,
  message,
  Typography,
  Space,
} from 'antd';
import { useCreateYardOutMutation } from 'reducers/yardout/api';
import styled from 'styled-components';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useFindAllCriteriaQuery } from 'reducers/criteria/api';
import FormReason from './FormReason';
import { yardoutApi } from 'reducers/yardout/api';
import Table from './Table';
import AntTable from 'common_components/AntTable';
import AntModal from 'common_components/AntModal';

const { Text } = Typography;
const YardOutFormComponent = (props) => {
  const trailerInputRef = useRef(null);
  const [formData, setFormData] = useState(null);
  const confirmRef = useRef([]);
  const missing_Vins_Ref = useRef();
  const dispatchBoardRef = useRef();
  const statusDiff = useRef();
  const arrSearchRef = useRef();
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const [criteriaEarly, setCriteriaEarly] = useState(null);
  const [criteriaLate, setCriteriaLate] = useState(null);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trailerFrom, setTrailerFrom] = useState(null);
  const [driver1From, setDriver1From] = useState(null);
  const [driver2From, setDriver2From] = useState(null);
  const [vin_1_Ref, setVin_1_Ref] = useState(null);
  const [vin_2_Ref, setVin_2_Ref] = useState(null);
  const [vin_3_Ref, setVin_3_Ref] = useState(null);
  const [vin_4_Ref, setVin_4_Ref] = useState(null);
  const [vin_5_Ref, setVin_5_Ref] = useState(null);
  const [vin_6_Ref, setVin_6_Ref] = useState(null);
  const [vin_7_Ref, setVin_7_Ref] = useState(null);
  const [vin_8_Ref, setVin_8_Ref] = useState(null);
  const [vin_9_Ref, setVin_9_Ref] = useState(null);
  const [vin_10_Ref, setVin_10_Ref] = useState(null);
  const [vin_11_Ref, setVin_11_Ref] = useState(null);
  const [vin_12_Ref, setVin_12_Ref] = useState(null);

  const [vinListTemp, setVinListTemp] = useState(null);
  const [yardOutConfirm, setYardOutConfirm] = useState(null);
  const [dispatchBoard, setDispatchBoard] = useState(null);
  const [vinError, setVinError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [popupData, setPopupData] = useState(null);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [reasonDelay, setReasonDelay] = useState(true);
  const [disabledYardOut, setDisabledYardOut] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const mapDriver1Ref = useRef();
  const mapDriver2Ref = useRef();
  const driversRef = useRef();

  const apiFindCurrentTimeYardOutApi = async () => {
    let resData = null;
    try {
      let result = await dispatch(
        yardoutApi.endpoints.findCurrentTimeYardOut.initiate(null, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        resData = result.error;
      } else {
        if (result.data) {
          resData = result.data;
        }
      }

      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const {
    data: dataCriteria,
    error: errorCriteria,
    refetch: refetchCriteria,
  } = useFindAllCriteriaQuery();
  const [
    createYardOut,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
    },
  ] = useCreateYardOutMutation();

  const clearAll = () => {
    setFormData(null);
    setTrailerFrom(null);
    setDriver1From(null);
    setDriver2From(null);
    setVin_1_Ref(null);
    setVin_2_Ref(null);
    setVin_3_Ref(null);
    setVin_4_Ref(null);
    setVin_5_Ref(null);
    setVin_5_Ref(null);
    setVin_6_Ref(null);
    setVin_7_Ref(null);
    setVin_8_Ref(null);
    setVin_9_Ref(null);
    setVin_10_Ref(null);
    setVin_11_Ref(null);
    setVin_11_Ref(null);
    setVin_12_Ref(null);
    setVinListTemp(null);
    setYardOutConfirm(null);
    setDispatchBoard(null);
    setIsModalVisible(false);
    setYardOutConfirm(null);
    setReasonDelay(true);
    setDisabledSave(true);
    setRowData([]);
    confirmRef.current = null;
  };

  useEffect(() => {
    if (!isUninitCreating && isSuccessCreating) {
      clearAll();
      form.resetFields();
      message.success('Data was successfully saved');
    }
  }, [form, isSuccessCreating, isUninitCreating]);

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
  }, [errorCreating, setErrorData]);

  useEffect(() => {
    if (dataCriteria) {
      let arrEarly = dataCriteria.find((val) => val.code === 501);
      let arrLate = dataCriteria.find((val) => val.code === 502);
      setCriteriaEarly(arrEarly);
      setCriteriaLate(arrLate);
    }
  }, [dataCriteria]);

  useEffect(() => {
    if (errorCriteria) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load TrailerLane',
        okText: 'Retry',
        onOk: refetchCriteria,
        cancelText: 'Cancel',
      });
    }
  }, [errorCriteria, refetchCriteria]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const checkVin = (value) => {
    if (
      value.vin_1 ||
      value.vin_2 ||
      value.vin_3 ||
      value.vin_4 ||
      value.vin_5 ||
      value.vin_6 ||
      value.vin_7 ||
      value.vin_8 ||
      value.vin_9 ||
      value.vin_10 ||
      value.vin_11 ||
      value.vin_12
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onBtnCheck = async (values) => {
    try {
      setDisabledSave(true);
      setDisabledYardOut(false);
      let arrTrailer = await getTrailer(values.trailer);
      if (values.trailer) {
      } else {
        setTrailerFrom('blank');
      }

      let arrDriver1 = null;
      let arrDriver2 = null;
      if (values.driver_1) {
        arrDriver1 = await getDriver1(values.driver_1);
      } else {
        setDriver1From('blank');
      }

      if (values.driver_2) {
        arrDriver2 = await getDriver2(values.driver_2);
      } else {
        setDriver2From('blank');
      }

      let trailerAndDriver = null;
      let vin_no_list = [
        values.vin_1?.trim(),
        values.vin_2?.trim(),
        values.vin_3?.trim(),
        values.vin_4?.trim(),
        values.vin_5?.trim(),
        values.vin_6?.trim(),
        values.vin_7?.trim(),
        values.vin_8?.trim(),
        values.vin_9?.trim(),
        values.vin_10?.trim(),
        values.vin_11?.trim(),
        values.vin_12?.trim(),
      ];

      vin_no_list = vin_no_list.filter(Boolean);
      let driver_list = [
        arrDriver1 && arrDriver1.id,
        arrDriver2 && arrDriver2.id,
      ];

      driver_list = driver_list.filter(function (element) {
        return element !== null;
      });
      if (values.trailer && values.driver1_name) {
        trailerAndDriver = {
          trailer_id: arrTrailer?.id,
          driver_id: driver_list,
          vin_no: vin_no_list,
        };
      }
      setVinListTemp(vin_no_list);
      let findDuplicates = (arr) =>
        arr.filter((item, index) => arr.indexOf(item) !== index);
      let drivers = [values.driver_1, values.driver_2];
      let dupDrivers = [...new Set(findDuplicates(drivers))];
      let arrSearch = [];

      if (dupDrivers[0]) {
        message.error('Drivers is duplicated');
      } else {
        if (!arrTrailer || !arrDriver1) {
          setTrailerFrom('error');
          setDriver1From('error');
          Modal.error({
            title: 'Please recheck your job.',
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                <h4 style={{ color: 'red' }}>
                  Job not match with driver and trailer.
                </h4>
              </>
            ),
            okText: 'OK',
          });
        } else {
          arrSearch = await getSearchMatchVin(trailerAndDriver);
        }
      }

      let findVin_1 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_1?.trim()
      );

      let findVin_2 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_2?.trim()
      );
      let findVin_3 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_3?.trim()
      );
      let findVin_4 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_4?.trim()
      );
      let findVin_5 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_5?.trim()
      );
      let findVin_6 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_6?.trim()
      );
      let findVin_7 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_7?.trim()
      );
      let findVin_8 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_8?.trim()
      );
      let findVin_9 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_9?.trim()
      );
      let findVin_10 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_10?.trim()
      );
      let findVin_11 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_11?.trim()
      );
      let findVin_12 = arrSearch.find(
        (a) => a.status === true && a.vin === values.vin_12?.trim()
      );

      if (values.vin_1 && values.vin_1) {
        if (findVin_1) {
          if (
            vin_no_list.filter((v) => v === values.vin_1?.trim()).length > 1
          ) {
            setVin_1_Ref('error');
          } else {
            setVin_1_Ref('success');
          }
        } else {
          setVin_1_Ref('error');
        }
      } else {
        setVin_1_Ref('blank');
      }

      if (values.vin_2 && values.vin_2) {
        if (findVin_2) {
          if (
            vin_no_list.filter((v) => v === values.vin_2?.trim()).length > 1
          ) {
            setVin_2_Ref('error');
          } else {
            setVin_2_Ref('success');
          }
        } else {
          setVin_2_Ref('error');
        }
      } else {
        setVin_2_Ref('blank');
      }
      if (values.vin_3 && values.vin_3) {
        if (findVin_3) {
          if (
            vin_no_list.filter((v) => v === values.vin_3?.trim()).length > 1
          ) {
            setVin_3_Ref('error');
          } else {
            setVin_3_Ref('success');
          }
        } else {
          setVin_3_Ref('error');
        }
      } else {
        setVin_3_Ref('blank');
      }
      if (values.vin_4 && values.vin_4) {
        if (findVin_4) {
          if (
            vin_no_list.filter((v) => v === values.vin_4?.trim()).length > 1
          ) {
            setVin_4_Ref('error');
          } else {
            setVin_4_Ref('success');
          }
        } else {
          setVin_4_Ref('error');
        }
      } else {
        setVin_4_Ref('blank');
      }
      if (values.vin_5 && values.vin_5) {
        if (findVin_5) {
          if (
            vin_no_list.filter((v) => v === values.vin_5?.trim()).length > 1
          ) {
            setVin_5_Ref('error');
          } else {
            setVin_5_Ref('success');
          }
        } else {
          setVin_5_Ref('error');
        }
      } else {
        setVin_5_Ref('blank');
      }
      if (values.vin_6 && values.vin_6) {
        if (findVin_6) {
          if (
            vin_no_list.filter((v) => v === values.vin_6?.trim()).length > 1
          ) {
            setVin_6_Ref('error');
          } else {
            setVin_6_Ref('success');
          }
        } else {
          setVin_6_Ref('error');
        }
      } else {
        setVin_6_Ref('blank');
      }
      if (values.vin_7 && values.vin_7) {
        if (findVin_7) {
          if (
            vin_no_list.filter((v) => v === values.vin_7?.trim()).length > 1
          ) {
            setVin_7_Ref('error');
          } else {
            setVin_7_Ref('success');
          }
        } else {
          setVin_7_Ref('error');
        }
      } else {
        setVin_7_Ref('blank');
      }
      if (values.vin_8 && values.vin_8) {
        if (findVin_8) {
          if (
            vin_no_list.filter((v) => v === values.vin_8?.trim()).length > 1
          ) {
            setVin_8_Ref('error');
          } else {
            setVin_8_Ref('success');
          }
        } else {
          setVin_8_Ref('error');
        }
      } else {
        setVin_8_Ref('blank');
      }
      if (values.vin_9 && values.vin_9) {
        if (findVin_9) {
          if (
            vin_no_list.filter((v) => v === values.vin_9?.trim()).length > 1
          ) {
            setVin_9_Ref('error');
          } else {
            setVin_9_Ref('success');
          }
        } else {
          setVin_9_Ref('error');
        }
      } else {
        setVin_9_Ref('blank');
      }
      if (values.vin_10 && values.vin_10) {
        if (findVin_10) {
          if (
            vin_no_list.filter((v) => v === values.vin_10?.trim()).length > 1
          ) {
            setVin_10_Ref('error');
          } else {
            setVin_10_Ref('success');
          }
        } else {
          setVin_10_Ref('error');
        }
      } else {
        setVin_10_Ref('blank');
      }
      if (values.vin_11 && values.vin_11) {
        if (findVin_11) {
          if (
            vin_no_list.filter((v) => v === values.vin_11?.trim()).length > 1
          ) {
            setVin_11_Ref('error');
          } else {
            setVin_11_Ref('success');
          }
        } else {
          setVin_11_Ref('error');
        }
      } else {
        setVin_11_Ref('blank');
      }
      if (values.vin_12 && values.vin_12) {
        if (findVin_12) {
          if (
            vin_no_list.filter((v) => v === values.vin_12?.trim()).length > 1
          ) {
            setVin_12_Ref('error');
          } else {
            setVin_12_Ref('success');
          }
        } else {
          setVin_12_Ref('error');
        }
      } else {
        setVin_12_Ref('blank');
      }

      let vin_no_list_count = [
        findVin_1 && findVin_1.vin?.trim(),
        findVin_2 && findVin_2.vin?.trim(),
        findVin_3 && findVin_3.vin?.trim(),
        findVin_4 && findVin_4.vin?.trim(),
        findVin_5 && findVin_5.vin?.trim(),
        findVin_6 && findVin_6.vin?.trim(),
        findVin_7 && findVin_7.vin?.trim(),
        findVin_8 && findVin_8.vin?.trim(),
        findVin_9 && findVin_9.vin?.trim(),
        findVin_10 && findVin_10.vin?.trim(),
        findVin_11 && findVin_11.vin?.trim(),
        findVin_12 && findVin_12.vin?.trim(),
      ];

      let vin_cal = [];
      for (let index1 = 0; index1 < 12; index1++) {
        if (!vin_no_list_count[index1]) {
          if (index1 === 0) {
            vin_cal.push('vin1');
          }
          if (index1 === 1) {
            vin_cal.push('vin2');
          }
          if (index1 === 2) {
            vin_cal.push('vin3');
          }
          if (index1 === 3) {
            vin_cal.push('vin4');
          }
          if (index1 === 4) {
            vin_cal.push('vin5');
          }
          if (index1 === 5) {
            vin_cal.push('vin6');
          }
          if (index1 === 6) {
            vin_cal.push('vin7');
          }
          if (index1 === 7) {
            vin_cal.push('vin8');
          }
          if (index1 === 8) {
            vin_cal.push('vin9');
          }
          if (index1 === 9) {
            vin_cal.push('vin10');
          }
          if (index1 === 10) {
            vin_cal.push('vin11');
          }
          if (index1 === 11) {
            vin_cal.push('vin12');
          }
        }
      }

      for (let index1 = 0; index1 < missing_Vins_Ref.current; index1++) {
        if (vin_cal[index1] === 'vin1') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_1_Ref('error');
          } else {
            setVin_1_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin2') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_2_Ref('error');
          } else {
            setVin_2_Ref('blank');
          }
          setVin_2_Ref('error');
        }
        if (vin_cal[index1] === 'vin3') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_3_Ref('error');
          } else {
            setVin_3_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin4') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_4_Ref('error');
          } else {
            setVin_4_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin5') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_5_Ref('error');
          } else {
            setVin_5_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin6') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_6_Ref('error');
          } else {
            setVin_6_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin7') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_7_Ref('error');
          } else {
            setVin_7_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin8') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_8_Ref('error');
          } else {
            setVin_8_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin9') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_9_Ref('error');
          } else {
            setVin_9_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin10') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_10_Ref('error');
          } else {
            setVin_10_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin11') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_11_Ref('error');
          } else {
            setVin_11_Ref('blank');
          }
        }
        if (vin_cal[index1] === 'vin12') {
          if (missing_Vins_Ref.current !== 0) {
            setVin_12_Ref('error');
          } else {
            setVin_12_Ref('blank');
          }
        }
      }

      if (checkVin(values)) {
        if (
          (values.trailer && values.driver1_name) ||
          (values.trailer && values.driver2_name) ||
          (values.trailer && values.driver1_name && values.driver2_name)
        ) {
          driversRef.current = [mapDriver1Ref.current, mapDriver2Ref.current];
          setIsLoading(true);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const msgConfirm = async () => {
    let arr = {
      vin_no: vinListTemp,
      dispatch_board_id: dispatchBoard,
    };
    arrSearchRef.current = arr;
    let arrConfirm = await getYardOutConfirm(arr);
    setPopupData(arrConfirm);
    let rowNo = arrConfirm.callings.length + 1;
    let resData = arrConfirm.callings.map((d, i) => ({
      ...d,
      id: i + rowNo,
    }));
    confirmRef.current = resData;
    setIsModalConfirmVisible(true);
    setDisabledYardOut(true);
  };

  function timeDiffCalc(dateNow, dateFuture) {
    let diffInMilliSeconds =
      Math.abs(moment(dateNow).diff(moment(dateFuture))) / 1000;
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    diffInMilliSeconds -= days * 24;
    // calculate hours
    let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    hours += days * 24;
    // calculate minutes
    let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    let difference = '';
    let differenceReason = '';

    difference += hours === 0 || hours === 1 ? `${hours}:` : `${hours}:`;

    difference += minutes === 0 || hours === 1 ? `${minutes}` : `${minutes}`;

    if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') >=
        criteriaLate.dateNow &&
      moment(dateNow).diff(moment(dateFuture), 'minutes') <= criteriaEarly.value
    ) {
      setIsModalVisible(false);
    } else if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') > criteriaLate.value
    ) {
      differenceReason = `+ ` + difference;
      statusDiff.current = 'delay';
      setIsModalVisible(true);
    } else if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') < criteriaEarly.value
    ) {
      differenceReason = `- ` + difference;
      statusDiff.current = 'early';
      setIsModalVisible(true);
    }

    if (moment(dateNow).isSameOrAfter(moment(dateFuture), 'minutes')) {
      difference = `+ ` + difference;
    } else {
      difference = `- ` + difference;
    }

    let objDif = {
      differenceReason: differenceReason && differenceReason,
      difference: difference && difference,
    };
    return objDif;
  }

  const handleConfirmAction = async () => {
    setDisabledSave(true);
    setDisabledYardOut(true);
    setIsModalConfirmVisible(false);
    var nowServer = await apiFindCurrentTimeYardOutApi();

    var then = rowData[0].plan_yard_out;
    let timeDiff = timeDiffCalc(nowServer, then);
    let reasonLabelText = {
      label: '-',
    };
    let reasonLabelDelay = {
      label: 'delay',
    };

    if (!timeDiff?.differenceReason) {
      setDisabledSave(false);
      setReasonDelay(false);
    } else {
      setDisabledSave(true);
      setDisabledSave(true);
    }

    let arrData = [
      {
        ...rowData[0],
        actual_yard_out_datetime: nowServer,
        diff: timeDiff?.difference,
        reason:
          timeDiff?.differenceReason.includes('-') ||
          timeDiff?.differenceReason.includes('+')
            ? reasonLabelDelay
            : reasonLabelText,
      },
    ];
    setRowData(arrData);
  };

  const handleEnter = (event, name) => {
    if (event.keyCode === 13 || (name === 'driver_2' && event.code === 'Tab')) {
      if (name) {
        onChangeTrailerAndDriver(event, name);
      }

      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      if (index !== 3 || index !== 4 || index !== 5 || index !== 6) {
        if (index === 2) {
          form.elements[6 + 1].focus();
          event.preventDefault();
        } else if (index === 18) {
          document.getElementById('check').focus();
          event.preventDefault();
        } else {
          form.elements[index + 1].focus();
          event.preventDefault();
        }
      }
    }
  };

  const onChangeTrailerAndDriver = async (e, label) => {
    try {
      let driver1_name = null;
      let driver2_name = null;
      if (label === 'trailer') {
        await getTrailer(e.target.value);
      }

      if (label === 'driver_1') {
        driver1_name = await getDriver1(e.target.value, label);

        form.setFieldsValue({
          driver1_name: driver1_name && driver1_name,
        });
      }
      if (label === 'driver_2') {
        driver2_name = await getDriver2(e.target.value, label);
        form.setFieldsValue({
          driver2_name: driver2_name && driver2_name,
        });
      }
    } catch (err) {
      // message.error('Something went wrong');
    }
  };

  const getTrailer = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          yardoutApi.endpoints.findYardOutTrailerbyCardId.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data && result.data.length > 0) {
            const trailer = result.data[0];
            resData = trailer;
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const getDriver1 = async (value, label) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          yardoutApi.endpoints.findYardOutDriverbyCode.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data && result.data.length > 0) {
            let driver = null;
            driver = result.data[0];
            let mapDriver1 = {
              id: driver.id,
              tlep_code: driver.tlep_code,
            };
            mapDriver1Ref.current = mapDriver1;
            if (label) {
              resData = driver?.driver_name_th;
            } else {
              resData = mapDriver1;
            }
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const getDriver2 = async (value, label) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          yardoutApi.endpoints.findYardOutDriverbyCode.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data && result.data.length > 0) {
            const driver = result.data[0];
            let mapDriver2 = {
              id: driver.id,
              tlep_code: driver.tlep_code,
            };
            mapDriver2Ref.current = mapDriver2;

            if (label) {
              resData = driver?.driver_name_th;
            } else {
              resData = mapDriver2;
            }
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onReset = () => {
    setDisabledYardOut(false);
    setReasonDelay(true);
    form.resetFields();
    setRowData([]);
    setVin_1_Ref('blank');
    setVin_2_Ref('blank');
    setVin_3_Ref('blank');
    setVin_4_Ref('blank');
    setVin_5_Ref('blank');
    setVin_6_Ref('blank');
    setVin_7_Ref('blank');
    setVin_8_Ref('blank');
    setVin_9_Ref('blank');
    setVin_10_Ref('blank');
    setVin_11_Ref('blank');
    setVin_12_Ref('blank');
    setTrailerFrom('blank');
    setDriver1From('blank');
    setDriver2From('blank');
    setTimeout(() => {
      if (trailerInputRef.current) {
        trailerInputRef.current.focus();
      }
    }, 100);
  };

  const getSearchMatchVin = async (value) => {
    let newData = [];

    if (value) {
      let result = await dispatch(
        yardoutApi.endpoints.findCheckMatchVin.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setDisabledYardOut(false);
        setReasonDelay(true);
        setRowData([]);
        Modal.error({
          title: result.error.data.errorList[0].errorMessage,
          icon: <ExclamationCircleOutlined />,
          content: (
            <div style={{ color: 'red' }}>
              {result.error.data.errorList[0].errorDetail}
            </div>
          ),
          okText: 'OK',
        });

        if (value.driver_id.length > 1) {
          setDriver1From('error');
          setDriver2From('error');
        } else {
          setDriver1From('error');
        }

        setTrailerFrom('error');
      } else {
        if (result.data) {
          if (value.driver_id.length > 1) {
            setDriver1From('success');
            setDriver2From('success');
          } else {
            setDriver1From('success');
          }

          setTrailerFrom('success');

          missing_Vins_Ref.current = result.data.missing_vins;
          const arrSearch = [];
          Object.entries(result.data.vins).forEach(([key, value]) => {
            arrSearch.push({
              vin: key,
              status: value,
              missing_vins: result.data.missing_vins,
            });
          });
          newData = arrSearch;
          let findVin = arrSearch.find((r) => r.status === false);
          let findVinSuccess = arrSearch.filter((r) => r.status === true);
          setVinError(findVin);
          let findDuplicates = (arr) =>
            arr.filter((item, index) => arr.indexOf(item) !== index);
          let dupVin = [...new Set(findDuplicates(value.vin_no))];
          if (
            (!findVin && result.data.missing_vins === 0 && dupVin) ||
            (findVinSuccess.length === 12 && dupVin)
          ) {
            let rowData = [
              {
                plan_yard_out: result.data.dispatch_board.estimate_yard_out,
              },
            ];

            if (dupVin.length > 0) {
              message.error(
                `Vin No. ${dupVin.filter(Boolean).toString()} is duplicated`,
                [4]
              );
              setRowData([]);
            } else {
              setRowData(rowData);
            }
          } else {
            if (dupVin.length > 0) {
              message.error(
                `Vin No. ${dupVin.filter(Boolean).toString()} is duplicated`,
                [4]
              );
              setRowData([]);
            } else {
              let countCheckVin =
                findVinSuccess.length + result.data.missing_vins;

              if (countCheckVin === value.vin_no.length) {
                Modal.error({
                  title: 'Please recheck your job.',
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      <h4 style={{ color: 'red' }}>
                        {' '}
                        Unit was correct, but Vin No. incorrect.
                      </h4>
                    </>
                  ),
                  okText: 'OK',
                });
              } else {
                Modal.error({
                  title: 'Please recheck your job.',
                  icon: <ExclamationCircleOutlined />,
                  content: (
                    <>
                      <h4 style={{ color: 'red' }}>Unit incorrect.</h4>
                    </>
                  ),
                  okText: 'OK',
                });
              }
            }
            setDisabledYardOut(false);
            setReasonDelay(true);
            setRowData([]);
          }

          setDispatchBoard(result.data.dispatch_board.id);
          dispatchBoardRef.current = result.data.dispatch_board.id;
        } else {
          Modal.error({
            title: 'Error!!',
            icon: <ExclamationCircleOutlined />,
            content: (
              <>
                <h4>
                  Not found Driver, Vin No. with receive job data in the system
                </h4>
                <div style={{ color: 'red' }}>
                  {' '}
                  Prease recheck your job. <br />
                </div>
                <div style={{ color: 'red' }}>
                  -Unit was correct, but Vin No. incorrect.
                </div>
              </>
            ),
            okText: 'OK',
            width: 500,
          });
        }
      }
    }
    return newData;
  };
  const getYardOutConfirm = async (value) => {
    let newData = [];

    if (value) {
      let result = await dispatch(
        yardoutApi.endpoints.findYardOutConfirm.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          newData = result.data;
          setYardOutConfirm(newData);
        }
      }
    }
    return newData;
  };

  const [columnsPopup] = useState(() => {
    let cols = [
      {
        title: 'From',
        dataIndex: ['from', 'short_name'],
        width: 100,
        align: 'center',
      },
      {
        title: 'Cluster',
        dataIndex: ['cluster', 'name'],
        width: 60,
        align: 'center',
      },
      {
        title: 'Calling No',
        dataIndex: ['calling_no', 'callingsheet_no'],
        width: 100,
        align: 'center',
      },
    ];
    return cols;
  });

  const postReason = (value) => {
    let reasonLabel = {
      label: value?.subject?.substring(1, 4),
      value: value,
    };

    let newData = [];
    for (let index = 0; index < rowData.length; index++) {
      newData = [
        {
          ...rowData[index],
          reason: reasonLabel,
        },
      ];
    }
    setReasonDelay(false);
    setDisabledSave(false);
    var res = rowData.map((obj) => newData.find((o) => o.id === obj.id) || obj);
    setRowData(res);
  };

  const editReason = (value) => {
    setIsModalVisible(true);
    setFormData(value.reason.value);
  };

  const handleConfirmSaveAction = async () => {
    let data = rowData[0];
    let postDriverConfirm = {
      dispatch_board: dispatchBoardRef.current,
      yardout_datetime: moment(data.actual_yard_out_datetime).format(),
      reason: data.reason && data.reason.value && data.reason.value.id,
      reason_description:
        data.reason && data.reason.value && data.reason.value.description,
    };
    createYardOut(postDriverConfirm);
  };

  const btnSave = () => {
    if (rowData) {
      let data = rowData[0];
      if (data.actual_yard_out_datetime) {
        Modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: 'Would you like to confirm for saving the Yard out?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          width: 500,
          onOk: handleConfirmSaveAction,
        });
      }
    }
  };
  return (
    <>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{ padding: '20px' }}
        onFinish={onBtnCheck}
        autoComplete='off'
      >
        <CardStyleForm>
          <Row>
            <Col span={21}>
              <Row>
                <Col span={8}>
                  <Form.Item
                    labelCol={{
                      span: 7,
                    }}
                    wrapperCol={{
                      span: 15,
                    }}
                    label='Trailer'
                    name='trailer'
                    onKeyDown={(e) => {
                      handleEnter(e);
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input trailer',
                      },
                    ]}
                  >
                    <Input
                      placeholder={'(for scan)'}
                      ref={trailerInputRef}
                      style={{
                        backgroundColor:
                          trailerFrom === 'success'
                            ? '#1BD39C'
                            : trailerFrom === 'error'
                            ? '#FF6767'
                            : '',
                      }}
                    />
                    {/* )} */}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Driver 1'
                    name='driver_1'
                    onKeyDown={(e) => {
                      handleEnter(e, 'driver_1');
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input driver 1',
                      },
                    ]}
                  >
                    <Input
                      onChange={(e) => {
                        onChangeTrailerAndDriver(e, 'driver_1');
                      }}
                      placeholder={'(for scan)'}
                      style={{
                        backgroundColor:
                          driver1From === 'success'
                            ? '#1BD39C'
                            : driver1From === 'error'
                            ? '#FF6767'
                            : '',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label='Driver 2'
                    name='driver_2'
                    onKeyDown={(e) => {
                      handleEnter(e, 'driver_2');
                    }}
                  >
                    <Input
                      onChange={(e) => {
                        onChangeTrailerAndDriver(e, 'driver_2');
                      }}
                      placeholder={'(for scan)'}
                      style={{
                        backgroundColor:
                          driver2From === 'success'
                            ? '#1BD39C'
                            : driver2From === 'error'
                            ? '#FF6767'
                            : '',
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}></Col>
                <Col span={8}>
                  <Form.Item label='Driver 1 name' name='driver1_name'>
                    <Input
                      style={{ color: '#000', backgroundColor: 'transparent' }}
                      placeholder={'(auto fill)'}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label='Driver 2 name' name='driver2_name'>
                    <Input
                      style={{ color: '#000', backgroundColor: 'transparent' }}
                      placeholder={'(auto fill)'}
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <Col>
                <Form.Item wrapperCol={{ offset: 3 }} shouldUpdate>
                  {() => (
                    <Space size={25} style={{ marginLeft: '3px' }}>
                      <Button
                        id='check'
                        style={{ width: 100 }}
                        type='primary'
                        htmlType='submit'
                        loading={isLoading}
                      >
                        Check
                      </Button>
                    </Space>
                  )}
                </Form.Item>
              </Col>
              <Col>
                <Form.Item id='clear' wrapperCol={{ offset: 3 }} shouldUpdate>
                  {() => (
                    <Space size={25} style={{ marginLeft: '3px' }}>
                      <Button style={{ width: 100 }} onClick={onReset}>
                        Clear all
                      </Button>
                    </Space>
                  )}
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </CardStyleForm>

        <Row style={{ marginTop: 15 }} gutter={15}>
          <Col span={9}>
            <Card title='Vin No.'>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_1'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_1_Ref === 'success'
                        ? '#1BD39C'
                        : vin_1_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
                {/* )} */}
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_2'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_2_Ref === 'success'
                        ? '#1BD39C'
                        : vin_2_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_3'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_3_Ref === 'success'
                        ? '#1BD39C'
                        : vin_3_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_4'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_4_Ref === 'success'
                        ? '#1BD39C'
                        : vin_4_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_5'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_5_Ref === 'success'
                        ? '#1BD39C'
                        : vin_5_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_6'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_6_Ref === 'success'
                        ? '#1BD39C'
                        : vin_6_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_7'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_7_Ref === 'success'
                        ? '#1BD39C'
                        : vin_7_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_8'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_8_Ref === 'success'
                        ? '#1BD39C'
                        : vin_8_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_9'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_9_Ref === 'success'
                        ? '#1BD39C'
                        : vin_9_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_10'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_10_Ref === 'success'
                        ? '#1BD39C'
                        : vin_10_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_11'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_11_Ref === 'success'
                        ? '#1BD39C'
                        : vin_11_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
              <Form.Item
                style={{ marginBottom: '13px' }}
                labelCol={{
                  span: 7,
                }}
                wrapperCol={{ offset: 2, span: 20 }}
                name='vin_12'
                onKeyDown={(e) => {
                  handleEnter(e);
                }}
              >
                <Input
                  placeholder={'(for scan)'}
                  style={{
                    backgroundColor:
                      vin_12_Ref === 'success'
                        ? '#1BD39C'
                        : vin_12_Ref === 'error'
                        ? '#FF6767'
                        : '',
                  }}
                />
              </Form.Item>
            </Card>
          </Col>
          <Col span={15}>
            <Card title='Yard out detail'>
              <Row align='end' style={{ margin: 10, marginBottom: 15 }}>
                <Col>
                  <Space size={25}>
                    <Button
                      style={{ width: 100 }}
                      disabled={!rowData.length || vinError || disabledYardOut}
                      type='primary'
                      onClick={() => msgConfirm()}
                    >
                      Yard Out
                    </Button>
                    <Button
                      style={{ width: 100 }}
                      disabled={reasonDelay || disabledSave}
                      type='secondary'
                      onClick={() => btnSave()}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>

              <Table rowData={rowData} editReason={editReason} />
            </Card>
          </Col>
        </Row>
      </Form>
      <FormReason
        formData={formData}
        showModal={isModalVisible}
        setIsModalVisible={handleCancel}
        postReason={postReason}
        statusDiff={statusDiff.current}
      />

      <AntModal
        visible={isModalConfirmVisible}
        onCancel={() => {
          setIsModalConfirmVisible(false);
          setDisabledYardOut(false);
        }}
        onOk={() => handleConfirmAction()}
        titleModal={<Text>Confirm Yard Out</Text>}
        centered
        width={600}
        style={{ textAlign: 'center' }}
        maskClosable={false}
      >
        {confirmRef.current && (
          <>
            <AntTable
              rowKey={(record) => record.id}
              tableId={'yard_out_confirm'}
              bordered
              showSorterTooltip={false}
              columns={columnsPopup}
              dataSource={confirmRef.current}
              pagination={false}
              width={450}
            />
            <br />
            <Row align='center'>
              <div style={{ textAlign: 'left' }}>
                Trailer no:{' '}
                {popupData && popupData.trailer.length > 0
                  ? popupData.trailer[0].trailer_card_id
                  : ''}
                <br />
                Driver 1:{' '}
                {driversRef.current && driversRef.current.length > 0
                  ? driversRef.current[0].tlep_code
                  : ''}{' '}
                <br />
                Driver 2:{' '}
                {popupData && popupData.drivers.length > 1
                  ? driversRef.current[1].tlep_code
                  : ''}{' '}
                <br />
                {popupData && popupData.combine_calling ? (
                  <>
                    <div style={{ color: '#0050b3', marginTop: '10px' }}>
                      Combine Calling: {popupData.combine_calling} <br />
                      From: {popupData.from} <br />
                      Cluster: {popupData.cluster}
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
            </Row>
          </>
        )}
      </AntModal>
    </>
  );
};

const CardStyleForm = styled(Card)`
  .ant-card-body {
    padding-bottom: 0px;
  }
`;

export default YardOutFormComponent;
