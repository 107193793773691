import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import ui from "./ui";
import table from "./table";
import locale from "./language";
import user from "./user";
import map from "./map";
import operationDashboard from "./digitalmap/operationDashboard";
import tttOperationDashboard from "./digitalmap/tttOperationDashboard";
import dispatchBoard from "./dispatchBoard";
import vin from "./vin";
import callingsheet from "./callingsheet";
import routeGroup from "./digitalmap/routeGroup";
import reasonMaster from "./masterData/reasonMaster";
import originalETAMaster from "./masterData/originalETAMaster";
import receivejob from "./receivejob";
import confirmDriverTrailer from "./dispatchBoard/ConfirmDriverTrailer";
import recommendTrip from "./recommendTrip";
import dnList from "./dnList";
import ChangeReasonLane from "./changeReasonLane";
import manageCalling from "./manageCalling";
import locationMaster from "./masterData/locationMaster";
import routeMaster from "./digitalmap/routeMaster";
import assignVolume from "./assignVolume";
import statusSummary from "./statusSummary";
import print from "./print";

import storage from "redux-persist/lib/storage";
import { connectRouter } from "connected-react-router";
import { rootApi } from "./user/api";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";

export const history = createBrowserHistory();

const reducers = combineReducers({
  router: connectRouter(history),
  ui,
  table,
  user,
  locale,
  locationMaster,
  routeMaster,
  operationDashboard,
  dispatchBoard,
  assignVolume,
  vin,
  manageCalling,
  callingsheet,
  map,
  routeGroup,
  tttOperationDashboard,
  reasonMaster,
  originalETAMaster,
  receivejob,
  confirmDriverTrailer,
  recommendTrip,
  dnList,
  statusSummary,

  ChangeReasonLane,
  print,
  // RTK Query
  [rootApi.reducerPath]: rootApi.reducer,
});

const persistConfig = {
  key: "dms",
  storage,
  whitelist: ["locale", "table"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: (state, action) => {
    // Clear all states when logout
    if (action.type.includes("logout")) {
      state = { router: state.router };
    }
    return persistedReducer(state, action);
  },
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([
      routerMiddleware(history),
      rootApi.middleware,
    ]),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

let persistor = persistStore(store);

const rootReducer = { store, persistor };

export default rootReducer;
