import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const TTToperationDashboardSlice = createSlice({
  name: 'dm_ttt_operation_dashboard',
  initialState,
  reducers: {
    setTTTCurrentPage: (state, { payload }) => {
      state.tttCurrentPage = payload;
    },
    setTTTCurrentOffset: (state, { payload }) => {
      state.tttCurrentOffset = payload;
    },
    setTTTOperationDashboardTotalRows: (state, { payload }) => {
      state.tttTotalRows = payload;
    },
    setTTTOperationDashboardDataSource: (state, { payload }) => {
      state.tttDataSource = payload;
    },
    setTTTOperationDashboardFilter: (state, { payload }) => {
      // debugger
      state.tttFilters = {
        ...state.filters,
        ...payload,
      };
    },
    setTTTOperationDashboardFilterPayload: (state, { payload }) => {
      // debugger
      state.tttFiltersPayload = {
        ...state.filters,
        ...payload,
      };
    },
    setTTTDigitalMap: (state, payload) => {
      state.tttDigitalMap = payload;
    },
  },
});

export const {
  setTTTDigitalMap,
  setTTTOperationDashboardFilterPayload,
  setTTTOperationDashboardFilter,
  setTTTCurrentPage,
  setTTTCurrentOffset,
  setTTTOperationDashboardDataSource,
  setTTTOperationDashboardTotalRows,
} = TTToperationDashboardSlice.actions;

export const TTToperationDashboardSelector = (state) =>
  state.tttOperationDashboard;

export default TTToperationDashboardSlice.reducer;
