import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';
import EditButtonRenderer from 'common_components/EditButtonRenderer';

import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { mapResetMap } from 'reducers/map';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';
import { message } from 'antd';
import { routeMasterApi } from 'reducers/digitalmap/routeMaster/api';
import {
  mapSetBounds,
  SELECT_MASTER_OR_CANDIDATE,
  MAP_SELECT_ROUTE,
  mapSetMasterPolylines,
} from 'reducers/map';
import digitalMapUtils from '../../Utils';

const RouteMasterTable = (props) => {
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  // Auto select row when user click route on the map
  const routeSelecting = useSelector((state) => {
    return state.map.routeMaster_selecting;
  });

  useEffect(() => {
    if (dataSource.length > 0 && routeSelecting) {
      let selectedRow = dataSource.find((d) => d.id === routeSelecting);
      if (selectedRow) {
        setSelectedRowKeys([selectedRow.priority]);
      }
    }
  }, [routeSelecting, dataSource]);

  useEffect(() => {
    setDataSource(rowData);

    let polylines = digitalMapUtils.createRouteMasterPolylines(rowData, false);
    let bounds = digitalMapUtils.createRouteMasterBounds(rowData);
    dispatch(mapSetMasterPolylines(polylines));

    dispatch(SELECT_MASTER_OR_CANDIDATE(1));
    if (rowData && rowData.length > 0) {
      dispatch(MAP_SELECT_ROUTE(rowData[0].id));
    }
    setTimeout(() => {
      dispatch(mapSetBounds(bounds));
    }, 100);
  }, [rowData, dispatch]);

  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        dispatch(SELECT_MASTER_OR_CANDIDATE(1));
        setSelectedRowKeys([record.priority]);
        event.stopPropagation();

        setTimeout(() => {
          dispatch(MAP_SELECT_ROUTE(record.id));
        }, 100);
      },
    };
  };

  const onRowEdit = (cell, readOnly) => {
    dispatch(mapResetMap());
    setTimeout(() => {
      dispatch(
        push(`/app/digitalmap/route_masters/route_group/routes/${cell.id}`)
      );
    }, 100);
  };
  const DragHandle = sortableHandle(() => (
    <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
  ));
  const SortableItem = sortableElement((props) => {
    return <tr {...props} />;
  });
  const SortableContainer = sortableContainer((props) => {
    return <tbody {...props} />;
  });

  const onSortEnd = async ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const tmpData = arrayMoveImmutable(
        [].concat(dataSource),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      const newData = tmpData.map((value, index) => {
        return { ...value, priority: index + 1 };
      });
      setDataSource(newData);
      try {
        setIsUpdating(true);
        for (let row of newData) {
          const payload = {
            id: row.id,
            priority: row.priority,
          };
          let result = await dispatch(
            routeMasterApi.endpoints.updateRouteMaster.initiate(payload)
          );
          if (result && result.error) {
            setErrorData(result.error);
            return;
          }
        }
      } catch (err) {
        message.error('Something went wrong');
      } finally {
        setIsUpdating(false);
      }
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(
      (x) => x.priority === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const [columns] = useState(() => {
    let cols = [
      {
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () => <DragHandle />,
      },
      {
        title: '',
        dataIndex: 'edit',
        align: 'center',
        render: (text, record) => {
          return <EditButtonRenderer data={record} onClick={onRowEdit} />;
        },
        width: 50,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: 'Distance (km)',
        dataIndex: 'TotalDistance',
        width: 100,
        render: (text, record) => {
          return (parseInt(text) / 1000).toFixed(2);
        },
      },
      {
        title: 'Time (min)',
        dataIndex: 'TotalTime',
        width: 80,
      },
      {
        title: 'Priority',
        dataIndex: 'priority',
        width: 60,
      },
      {
        title: 'Toll',
        dataIndex: 'TollUsage',
        render: (text, record) => {
          return text === '1' ? 'yes' : 'no';
        },
        width: 80,
      },
    ];

    return cols;
  });

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);

      if (selectedRows.length > 0) {
        dispatch(SELECT_MASTER_OR_CANDIDATE(1));
        selectedRows.forEach((r) => dispatch(MAP_SELECT_ROUTE(r.id)));
      } else {
        dispatch(MAP_SELECT_ROUTE(-1));
      }
    },
  };

  const onRowDelete = async (row) => {
    try {
      setIsUpdating(true);
      let result = await dispatch(
        routeMasterApi.endpoints.deleteRouteMaster.initiate(row.id)
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        message.success('Data was successfully deleted');

        //Update dataSource
        setDataSource(dataSource.filter((d) => d.id !== row.id));
      }
    } catch (err) {
      message.error('Something went wrong');
    } finally {
      setIsUpdating(false);
      dispatch(mapResetMap());
    }
  };
  return (
    <Container>
      <AntTable
        rowKey='priority'
        bordered
        pagination={false}
        rowSelection={rowSelection}
        showSorterTooltip={false}
        loading={isLoading || isUpdating}
        columns={columns}
        dataSource={dataSource}
        scroll={{ y: 100 }}
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        onRow={onRow}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: 'Edit', action: onRowEdit },
          {
            label: 'Delete',
            action: onRowDelete,
            delete: true,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 10px;
  height: 160px;
  overflow-y: auto;
  background: white;
`;

export default RouteMasterTable;
