import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Modal, Divider, Checkbox } from "antd";
import styled from "styled-components";
import AntTable from "common_components/AntTable";
import {
  dateFormatter,
  dateISOFormatter,
  dateTimeNotYearTimestamptzFormatter,
  timeFormatter,
} from "common_components/AntTable/tableUtils";

const DialogCreateByManualComponent = (props) => {
  const {
    modalCreateTourManual,
    setModalCreateTourManual,
    selectedRows,
    currentJobCode,
    apiCreateByManual,
    isLoadingCreateByManual,
    checkTour,
  } = props;

  const selectRowsRef = useRef([]);
  const [Rows, SetRows] = useState([]);
  const [OtherTours, SetOtherTours] = useState([]);
  const [OtherTourSelection, SetOtherTourSelection] = useState({});
  const handleOk = () => {
    let arrTrips = checkTour?.data?.updated.map((i) => i.id);
    let simTours = [];
    OtherTours.map((tour) => {
      const day = dateISOFormatter({ value: tour[0].receive_job_time });
      if (!OtherTourSelection[day]) return null;
      simTours.push(tour);
      return null;
    });
    let payload = {
      job_code: currentJobCode,
      trips: arrTrips,
      similar_tours: simTours,
    };
    apiCreateByManual(payload);
  };
  const handleCancel = () => {
    setModalCreateTourManual(false);
  };

  useEffect(() => {
    if (selectedRows) {
      let arrSelectedRows = selectedRows.map((item, index) => ({
        ...item,
        key: index + 1,
        arrival_time: item.arrival_time,
        departure_time: item.departure_time,
        id: item.id,
        location_from: item.location_from,
        location_to: item.location_to,
        plan_job_id: item.plan_job_id,
        receive_job_time: item.receive_job_time,
        return_time: item.return_time,
        unit: item.unit,
        vehicle_type: item.vehicle_type,
        yard_out_time: item.yard_out_time,
      }));
      selectRowsRef.current = arrSelectedRows;
    }
  }, [selectedRows]);

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: "Trip No.",
        width: 100,
        dataIndex: "key",
        align: "center",
        render: (text, key, index) => {
          return index + 1;
        },
      },
      {
        title: "Receive job time",
        dataIndex: "receive_job_time",
        width: 150,
        align: "center",
        render: (text, record) => {
          if (record.arrival_time_updated) {
            return (
              <>
                <del>
                  {dateTimeNotYearTimestamptzFormatter({
                    value: record.receive_job_time,
                  })}
                </del>
                <br />
                {dateTimeNotYearTimestamptzFormatter({
                  value: record.receive_job_time_updated,
                })}
              </>
            );
          }
          return dateTimeNotYearTimestamptzFormatter({
            value: record.receive_job_time,
          });
        },
      },

      {
        title: "From",
        dataIndex: "location_from",
        width: 150,
        align: "center",
        render: (text, record) => {
          return text?.name;
        },
      },
      {
        title: "Unit",
        dataIndex: "unit",
        width: 100,
        align: "center",
        render: (text, record) => {
          return text;
        },
      },
      {
        title: "To",
        width: 150,
        align: "center",
        dataIndex: "location_to",
        render: (text, record) => {
          return text?.name;
        },
      },

      {
        title: "Arrival time",
        width: 100,
        dataIndex: "arrival_time",
        align: "center",
        render: (text, record) => {
          if (record.arrival_time_updated) {
            return (
              <>
                <del>
                  {dateTimeNotYearTimestamptzFormatter({
                    value: record.arrival_time,
                  })}
                </del>
                <br />
                {dateTimeNotYearTimestamptzFormatter({
                  value: record.arrival_time_updated,
                })}
              </>
            );
          }

          return dateTimeNotYearTimestamptzFormatter({
            value: record.arrival_time,
          });
        },
      },
    ];

    return cols;
  });

  useEffect(() => {
    const og = checkTour?.data.original;
    const updated = checkTour?.data.updated;
    if (!og || !updated) {
      return;
    }
    const fields = [
      "receive_job_time",
      "yard_out_time",
      "arrival_time",
      "departure_time",
      "return_time",
    ];
    const r = og.map((lot, idx) => {
      let o = { ...lot };
      fields.map((f) => {
        if (updated[idx][f] !== lot[f]) {
          o[`${f}_updated`] = updated[idx][f];
        }
        return null;
      });
      // console.log("og map", idx, o);
      return o;
    });
    SetRows(r);

    if (checkTour?.similar_tours) {
      const x = Object.keys(checkTour?.similar_tours).map(
        (k) => checkTour?.similar_tours[k]
      );
      SetOtherTours(x);
      let selOTour = {};
      Object.keys(checkTour?.similar_tours).map((k) => {
        selOTour[k] = true; // default to pick all
        return null;
      });
      SetOtherTourSelection(selOTour);
    }
  }, [checkTour]);

  function handleSimilarTourPicker(day, checked) {
    SetOtherTourSelection((curr) => {
      let updated = {
        ...curr,
      };
      updated[day] = checked;
      return updated;
    });
  }

  // console.log("tour updated: ", checkTour?.data?.updated);
  // console.log("tourData: ", Rows);
  return (
    <div>
      <Modal
        title="Create tour (by manual) result"
        visible={modalCreateTourManual}
        width={1000}
        onCancel={handleCancel}
        footer={[
          <Button
            key="save"
            onClick={handleOk}
            type="primary"
            loading={isLoadingCreateByManual}
          >
            Save
          </Button>,
          <Button
            key="cancel"
            onClick={handleCancel}
            loading={isLoadingCreateByManual}
          >
            Cancel
          </Button>,
        ]}
      >
        <Row gutter={24}>
          <Col span={12} style={{ align: "start" }}>
            <p>Job code : {currentJobCode}</p>
          </Col>
          <Col span={24}>
            <p>
              <del>
                <b>Original</b>
              </del>
              &nbsp;
              <b>Updated</b>
            </p>
          </Col>
          <Col span={24}>
            <StyledAntTable
              rowKey="idog"
              tableId={"idog"}
              bordered
              showSorterTooltip={false}
              columns={columns}
              dataSource={Rows ? Rows : null}
              scroll={{ x: "max-content" }}
              pagination={false}
            />
          </Col>
          <Col span={24} style={{ marginTop: "10px" }}>
            <p>
              <b>Update</b> &nbsp; Total working Hour :{" "}
              {checkTour?.data?.working_hour}
            </p>
          </Col>
          <Col span={24}>
            {OtherTours.map((tour, idx) => (
              <>
                <Divider
                  orientation="left"
                  plain
                  style={{ verticalMargin: 2, margin: 5 }}
                >
                  {/* {dateFormatter({ value: tour[0].receive_job_time })} */}
                </Divider>
                <Row
                  key={`sim-t-${idx}`}
                  justify="start"
                  align="middle"
                  gutter={[24, 24]}
                >
                  <Col span={4} justify="space-around" align="middle">
                    <Checkbox
                      defaultChecked
                      name={dateISOFormatter({
                        value: tour[0].receive_job_time,
                      })}
                      onChange={(e) => {
                        handleSimilarTourPicker(
                          e.target.name,
                          e.target.checked
                        );
                      }}
                    >
                      {dateFormatter({ value: tour[0].receive_job_time })}
                    </Checkbox>
                  </Col>
                  {tour.map((smLot, smIdx) => {
                    return (
                      <Col
                        key={`smlx-${smLot.id}`}
                        style={{ backgroundColor: "#f8f8f8", marginRight: 5 }}
                      >
                        <h5 style={{ color: "gray" }}>Trip No. {smIdx + 1}</h5>
                        {smLot.location_from.name} - {smLot.location_to.name}
                        <br />
                        {timeFormatter({ value: smLot.receive_job_time })} -
                        {timeFormatter({ value: smLot.arrival_time })}
                      </Col>
                    );
                  })}
                </Row>
              </>
            ))}
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default DialogCreateByManualComponent;
