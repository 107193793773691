import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

class MapLoadingBox extends Component {
  render() {
    return (
      <div
        style={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          marginLeft: '-50px',
          marginTop: '-50px',
        }}
      >
        <Spin indicator={antIcon} size='large' />
      </div>
    );
  }
}
const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

const mapStateToProps = (state) => {
  return {
    map_loading: state.map.map_loading,
  };
};

const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(MapLoadingBox);
