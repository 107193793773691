import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  dataSource: [],
};
export const ReasonMasterSlice = createSlice({
  name: 'reason_master',
  initialState,
  reducers: {
    setReasonDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setReasonMasterFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setReasonMasterFilter,
  setReasonDataSource,
} = ReasonMasterSlice.actions;

export const reasonDataSourceSelector = (state) => state.reason_master;

export default ReasonMasterSlice.reducer;
