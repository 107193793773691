import React, { useState, useEffect } from 'react';
import { setSidebarVisibility } from 'reducers/ui';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { useParams } from 'react-router';
import { useFindLocationMasterByIdQuery } from 'reducers/masterData/locationMaster/api';
import { useDispatch, useSelector } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { getCurrentUser } from 'reducers/user';
import permission from 'permissions';
import { Spin, message } from 'antd';
import { goBack } from 'connected-react-router';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

//Component
import BaseMap from '../LocationMasterMap';
import ManageForm from './ManageForm';

const LocationMasterDetails = () => {
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const user = useSelector(getCurrentUser);
  const { setErrorData } = useErrorHandler();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const { data, error, isFetching } = useFindLocationMasterByIdQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (user) {
      if (!permission.checkPermission(user, 'location_master')) {
        message.error('Permission denied');
        dispatch(goBack());
      } else {
        if (!permission.checkPermission(user, 'location_master', ['u'])) {
          setReadonly(true);
        } else {
          setReadonly(false);
        }
      }
    }
  }, [user, dispatch]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  return (
    <SplitPane split='vertical'>
      <Pane initialSize='30%'>
        <div style={{ height: 'calc(100vh - 48px)', overflowY: 'auto' }}>
          {isFetching === true ? (
            <RootCircular>
              <CenterSpin indicator={antIcon} size='large' />
            </RootCircular>
          ) : (
            <ManageForm
              formData={data || {}}
              isCreate={!id}
              readonly={id ? readonly : false}
            />
          )}
        </div>
      </Pane>
      <Pane>
        <BaseMap showDrawing />
      </Pane>
    </SplitPane>
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default LocationMasterDetails;
