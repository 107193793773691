import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';

// It's required to create separete Marker from BaseMap component
// Due to window.google object will be created by BaseMap first
const StartMarker = ({ location }) => {
  let infoLocation = location && location.traffic_polyline.legs[0].from;
  let arrLocation = location.delivery_location.find(
    (l) => l.truck_schedule_id === infoLocation.truck_schedule_id
  );

  const [onHover, setOnHover] = useState(false);
  const info = arrLocation && arrLocation.location;
  return (
    <Marker
      position={{
        lat: infoLocation && infoLocation.lat,
        lng: infoLocation && infoLocation.lng,
      }}
      icon={{
        url: 'http://www.google.com/mapfiles/dd-start.png',
        labelOrigin: window.google && new window.google.maps.Point(19, 14),
        scaledSize: window.google && new window.google.maps.Size(24, 38),
        size: window.google && new window.google.maps.Size(24, 38),
      }}
      onMouseOver={() => {
        setOnHover(true);
      }}
      onMouseOut={() => {
        setOnHover(false);
      }}
    >
      {onHover && info && (
        <InfoWindow>
          <>
            {info ? `Shortname : ${info?.short_name}` : ''}
            <br />
            {info?.name ? `Name : ${info?.name}` : ''}
          </>
        </InfoWindow>
      )}
    </Marker>
  );
};
export default StartMarker;
