import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { Popover, Typography, Space } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import { InfoCircleOutlined } from '@ant-design/icons';
const TableComponent = (props) => {
  const { id, rowData, isLoading } = props;
  const { height } = useWindowSize();
  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);

  const columnsPopup = [
    {
      title: 'Destination',
      dataIndex: ['location_to', 'short_name'],
      width: 200,
      align: 'center',
    },
    {
      title: 'New Car',
      dataIndex: 'series',
      width: 150,
      align: 'center',
    },
  ];
  const columns = [
    {
      title: 'Job Code',
      dataIndex: 'job_code',
      width: 150,
      align: 'center',
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },

    {
      title: 'Trip no.',
      dataIndex: 'trip_no',
      width: 80,
      align: 'center',
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: (
        <>
          Calling No.{' '}
          <InfoCircleOutlined
            style={{ paddingBottom: '2px', fontSize: '10px' }}
          />
        </>
      ),
      dataIndex: ['callingsheet', 'callingsheet_no'],
      movable: true,
      align: 'center',
      width: 150,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: (
            <div>
              <Popover
                placement='topLeft'
                title={'Detail of Calling'}
                content={contentPopup(record)}
              >
                {text}
              </Popover>
            </div>
          ),
        };
      },
    },
    {
      title: 'Combine',
      dataIndex: 'combine',
      movable: true,
      align: 'center',
      width: 150,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Working Date',
      dataIndex: 'working_date',
      movable: true,
      align: 'center',
      width: 120,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Plan Receive Job Time',
      dataIndex: 'plan_receive_job_time',
      movable: true,
      align: 'center',
      width: 120,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: (
            <div>
              {text ? dateTimeTimestamptzFormatter({ value: text }) : ''}
            </div>
          ),
        };
      },
    },
    {
      title: 'Actual Receive Job Time',
      dataIndex: 'actual_receive_job_time',
      width: 120,
      align: 'center',
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: (
            <div>
              {text ? dateTimeTimestamptzFormatter({ value: text }) : ''}
            </div>
          ),
        };
      },
    },
    {
      title: 'From',
      dataIndex: ['from', 'name'],
      width: 100,
      align: 'center',
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Destination',
      dataIndex: ['destination', 'code'],
      width: 100,
      align: 'center',
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Loading Unit',
      dataIndex: 'actual_loading_unit',
      align: 'center',
      width: 100,
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Trailer',
      dataIndex: ['trailer', 'trailer_card_id'],
      align: 'center',
      width: 100,
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: 'Driver 1',
      dataIndex: ['driver_1', 'fullname_th'],
      align: 'center',
      width: 100,
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: (
            <div>
              {text
                ? `${record.driver_1?.fullname_th} ${record.driver_1?.surname_th}`
                : ''}
            </div>
          ),
        };
      },
    },
    {
      title: 'Driver 2',
      dataIndex: ['driver_2', 'fullname_th'],
      align: 'center',
      width: 100,
      movable: true,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                id === record.id && !record.driver_1 && !record.trailer
                  ? '#A6CBF5'
                  : '',
            },
          },
          children: (
            <div>
              {text
                ? `${record.driver_2?.fullname_th} ${record.driver_2?.surname_th}`
                : ''}
            </div>
          ),
        };
      },
    },
  ];

  const contentPopup = (value) => {
    let vins = [];
    if (value?.vin) {
      vins = [...value?.vin].sort(
        (a, b) => a.location_to.code - b.location_to.code
      );
    }
    try {
      return (
        <>
          <Space size={15} direction='vertical'>
            <Space>
              <StyledText>Calling no.:</StyledText>
              <StyledText style={{ color: 'blue' }}>
                {value.callingsheet?.callingsheet_no}
              </StyledText>
            </Space>
            <AntTable
              rowKey='id'
              tableId={'confirm_driver_trailer_calling'}
              showSorterTooltip={false}
              columns={columnsPopup}
              dataSource={vins}
              pagination={false}
              width={450}
            />
          </Space>
        </>
      );
    } catch (err) {
      console.log('err', err);
    }
  };
  useEffect(() => {
    if (rowData) {
      setDataSource(rowData);
    }
  }, [rowData]);

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  return (
    <Container>
      <AntTable
        rowKey='id'
        loading={isLoading}
        tableId={'confirm_driver_trailer'}
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;

export default TableComponent;
