import { useEffect, useState } from 'react';

export default function ConflictRecvJobTimeErrMessage({ effTours }) {
  const [cannotAdjust, SetCannotAdjust] = useState([]);
  useEffect(() => {
    let cannot = [];
    effTours.map((tour) => {
      if (tour.multiple_tours_days.length > 0) {
        cannot = cannot.concat(tour.multiple_tours_days);
      }
      return null;
    });
    SetCannotAdjust(cannot);
  }, [effTours]);

  return (
    <div>
      {effTours.length > 0 && (
        <>
          <h4>
            พบ receive job time สำหรับ {effTours[0].job_code} ที่ไม่ตรงกัน
          </h4>
          <ol>
            {effTours.map((tour) => (
              <li>
                tour วันที่ {tour.days.join(', ')}
                <div>มี receive job time = {tour.recv_job_hhmm}</div>
              </li>
            ))}
          </ol>
        </>
      )}
      {cannotAdjust.length === 0 && (
        <h4>ระบบจะทำการแก้ไขให้เป็นเวลา receive job time ที่ตรงกัน</h4>
      )}
      {cannotAdjust.length > 0 && (
        <>
          <h4 style={{ color: 'red' }}>
            Cannot resolve receive job time conflict
          </h4>
          <p>- มี tour วันเดียวกัน: {cannotAdjust.join(',')}</p>
        </>
      )}
    </div>
  );
}
