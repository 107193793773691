import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  message,
  Modal,
  Switch,
  Select,
  Popconfirm,
} from 'antd';
import {
  useUpdateUserMutation,
  useCreateUserMutation,
  resetUserMfa,
} from 'reducers/user/api';
import {
  useFindAllCompaniesQuery,
  useFindCompanyByIdQuery,
} from 'reducers/company/api';
import { getToken, getSession } from 'utils/token';
import { useFindAllGroupsOfCompanyQuery } from 'reducers/user/api';
import { useFindAllDiagramOwnersQuery } from 'reducers/diagramOwners/api';
import permission from 'permissions';
import { goBack } from 'connected-react-router';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { useFindAllLocationMastersByYardQuery } from 'reducers/masterData/locationMaster/api';
import { useFindAllPermissionQuery } from 'reducers/configurationPermission/api';
import { Sorter } from 'utils/sorter';
import { set } from 'lodash';
import { RemoveDuplicates } from 'utils/removeDuplicates';
const { Option } = Select;

const UserFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const [changePassword, setChangePassword] = useState(false);
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const [locationOptions, setLocationOptions] = useState([]);
  const [permissionOptions, setPermissionOptions] = useState([]);

  const dispatch = useDispatch();

  const [selectedCompanyId, setSelectedCompanyId] = useState(
    formData && formData.company
      ? formData.company.id
      : user
      ? user.company_id
      : null,
  );
  const companyTextRef = useRef(null);

  const [companies, setCompanies] = useState(
    formData && formData.company
      ? [{ id: formData.company.name, label: formData.company.id }]
      : [],
  );

  const [groupsOfCompnay, setGroupsOfCompany] = useState(
    formData && formData.groups
      ? formData.groups.map((g) => ({
          id: g.id,
          label: g.name,
        }))
      : [],
  );

  const [diagramOwner, setDiagramOwner] = useState(
    formData && formData.diagram_owner
      ? [{ id: formData.diagram_owner.id, label: formData.diagram_owner.name }]
      : [],
  );
  // Query all companies
  const {
    data: dataCompanies,
    error: errorCompanies,
    isFetching: isCompaniesFetching,
    refetch: refetchCompanies,
  } = useFindAllCompaniesQuery(
    {
      is_active: true,
      location_type__code: '005',
      display: 'company',
      ordering: 'full_name_en, branch',
    },
    {
      skip: !user || readonly || !user.is_superadmin,
    },
  );

  useEffect(() => {
    if (dataCompanies) {
      const dataCompaniesOption = dataCompanies.map((d) => ({
        id: d.id,
        label: d.company_bzp,
      }));
      setCompanies(dataCompaniesOption);

      // if (formData && formData.company) {
      //   const companyText = dataCompanies.find(
      //     (g) => g.id === formData.company.id
      //   );
      //   console.log('companyText', companyText);
      //   setCompanyText(companyText);
      //   // companyTextRef.current = companyText.label;
      // }
    }
  }, [dataCompanies]);
  // const [companyText, setCompanyText] = useState(dataCompanies.find(
  //   (g) => g.id === formData.company.id
  // )?.company_bzp);
  useEffect(() => {
    if (errorCompanies) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load companies',
        okText: 'Retry',
        onOk: refetchCompanies,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanies, refetchCompanies]);

  // Query company by ID, when admin of a company
  const {
    data: dataCompanyById,
    error: errorCompanyById,
    isFetching: isCompanyByIdFetching,
    refetch: refetchCompanyById,
  } = useFindCompanyByIdQuery(user && user.company_id, {
    skip: !user || readonly || user.is_superadmin,
  });

  useEffect(() => {
    if (dataCompanyById) {
      setCompanies([{ id: dataCompanyById.id, label: dataCompanyById.name }]);
      form.setFieldsValue({ company: dataCompanyById.name });
    }
  }, [dataCompanyById, form]);

  useEffect(() => {
    if (errorCompanyById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load company',
        okText: 'Retry',
        onOk: refetchCompanyById,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanyById, refetchCompanyById]);

  // Query All Groups of Company
  const {
    data: dataGroupsOfCompany,
    error: errorGroupsOfCompany,
    isFetching: isGroupsOfCompanyFetching,
    refetch: refetchGroupsOfCompany,
  } = useFindAllGroupsOfCompanyQuery(selectedCompanyId, {
    skip: !selectedCompanyId || readonly,
  });

  useEffect(() => {
    if (dataGroupsOfCompany) {
      setGroupsOfCompany(
        dataGroupsOfCompany.map((d) => ({ id: d.id, label: d.name })),
      );
    }
  }, [dataGroupsOfCompany]);

  useEffect(() => {
    if (errorGroupsOfCompany) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load groups of company',
        okText: 'Retry',
        onOk: refetchGroupsOfCompany,
        cancelText: 'Cancel',
      });
    }
  }, [errorGroupsOfCompany, refetchGroupsOfCompany]);

  const [
    createUser,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateUserMutation();

  const [
    updateUser,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateUserMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const onCompanyChange = (companyName) => {
    let company = companies.find((c) => c.label === companyName);
    setSelectedCompanyId(company.id);
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);

    let company = companies.find((c) => c.label === validData.company);
    let diagramOwnerData = diagramOwner.find(
      (c) => c.label === validData.diagram_owner,
    );
    let permissionData = permissionOptions.find(
      (c) => c.label === validData.function_permission,
    );
    let locationYard = locationOptions.find((c) => c.label === validData.yard);
    let groups = validData.groups
      ? groupsOfCompnay.filter((c) =>
          validData.groups.some((g) => g === c.label),
        )
      : [];
    let postData = {
      id: formData && formData.id,
      ...validData,
      company: company && company.id,
      groups: groups.map((g) => g.id),
      diagram_owner: diagramOwnerData && diagramOwnerData.id,
      yard: locationYard && locationYard.id,
      function_permission: permissionData && permissionData.id,
    };

    if (isCreate) {
      createUser(postData);
    } else {
      updateUser(postData);
    }
  };

  const onChangePassword = (value) => {
    setChangePassword(value);
  };

  // Query All Diagram Owner
  const {
    data: dataDiagramOwner,
    error: errorDiagramOwner,
    isFetching: isDiagramOwnerFetching,
    refetch: refetchDiagramOwner,
  } = useFindAllDiagramOwnersQuery(selectedCompanyId, {
    skip: !selectedCompanyId || readonly,
  });

  useEffect(() => {
    if (dataDiagramOwner) {
      setDiagramOwner(
        dataDiagramOwner.map((d) => ({ id: d.id, label: d.name })),
      );
    }
  }, [dataDiagramOwner]);

  useEffect(() => {
    if (errorDiagramOwner) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load groups of company',
        okText: 'Retry',
        onOk: refetchDiagramOwner,
        cancelText: 'Cancel',
      });
    }
  }, [errorDiagramOwner, refetchDiagramOwner]);

  // Query all Location Master
  const {
    data: dataLocationMaster,
    error: errorLocationMaster,
    isFetching: isLocationMasterFetching,
    refetch: refetchLocationMaster,
  } = useFindAllLocationMastersByYardQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataLocationMaster) {
      let mapData = dataLocationMaster.map((d) => ({
        id: d.id,
        label: d.short_name,
        value: d.name,
      }));
      // mapData = RemoveDuplicates.LISTKEY(mapData, 'value');
      mapData = Sorter.DEFAULTLIST(mapData, 'value');
      setLocationOptions(mapData);
    }
  }, [dataLocationMaster]);

  useEffect(() => {
    if (errorLocationMaster) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load location',
        okText: 'Retry',
        onOk: refetchLocationMaster,
        cancelText: 'Cancel',
      });
    }
  }, [errorLocationMaster, refetchLocationMaster]);

  // Query all Location Master
  const {
    data: dataPermission,
    error: errorPermission,
    isFetching: isPermissionFetching,
    refetch: refetchPermission,
  } = useFindAllPermissionQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataPermission) {
      let mapData = dataPermission.map((d) => ({
        id: d.id,
        label: d.name,
        value: d.name,
      }));
      setPermissionOptions(Sorter.DEFAULTLIST(mapData, 'label'));
    }
  }, [dataPermission]);

  useEffect(() => {
    if (errorPermission) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load permission',
        okText: 'Retry',
        onOk: refetchPermission,
        cancelText: 'Cancel',
      });
    }
  }, [errorPermission, refetchPermission]);

  const handleResetMfa = () => {
    let resetConfirmation = '';

    Modal.confirm({
      title: `Do you want to reset MFA for ${formData.username}?`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <Input
          placeholder="Type 'reset' to confirm"
          onChange={(e) => (resetConfirmation = e.target.value)}
        />
      ),
      onOk: async () => {
        if (resetConfirmation.toLowerCase() === 'reset') {
          try {
            const resetResult = await dispatch(
              resetUserMfa({
                token: getToken(),
                session: getSession(),
                user_id: formData.id,
              }),
            ).unwrap();

            if (resetResult) {
              message.success(
                'MFA has been reset. User will be required to re-enroll.',
              );
            } else {
              message.error('Failed to update user data after MFA reset.');
            }
          } catch (error) {
            setErrorData(error);
            message.error('An error occurred while resetting MFA.');
          }
        } else {
          message.error('Incorrect confirmation word. MFA reset cancelled.');
        }
      },
    });
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>

        {!isCreate && !readonly && user && user.is_superadmin && (
          <Popconfirm
            title={`Reset MFA for ${formData.username}?`}
            onConfirm={handleResetMfa}
            okText='Yes'
            cancelText='No'
          >
            <Button type='danger' style={{ marginLeft: '10px' }}>
              Reset MFA
            </Button>
          </Popconfirm>
        )}
      </Form.Item>
      <Form.Item
        label='Username'
        name='username'
        initialValue={formData && formData.username}
        rules={[
          {
            required: true,
            message: 'Please input your username',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Username'} />
      </Form.Item>
      <Form.Item
        label='Fullname (EN)'
        name='fullname_en'
        initialValue={formData && formData.fullname_en}
        rules={[
          {
            required: true,
            message: 'Please input your fullname (EN)',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Fullname (EN)'} />
      </Form.Item>
      <Form.Item
        label='Fullname (TH)'
        name='fullname_th'
        initialValue={formData && formData.fullname_th}
        rules={[
          {
            required: true,
            message: 'Please input your fullname (TH)',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Fullname (TH)'} />
      </Form.Item>
      <Form.Item
        label='Email'
        name='email'
        initialValue={formData && formData.email}
        rules={[
          {
            required: true,
            message: 'Please input email',
          },
        ]}
      >
        <Input disabled={readonly} type='email' placeholder={'Email'} />
      </Form.Item>
      {user && user.is_superadmin && (
        <>
          <Form.Item
            label='Is Active'
            name='is_active'
            initialValue={formData && formData.is_active}
            valuePropName='checked'
          >
            <Switch />
          </Form.Item>
          {!isCreate && (
            <Form.Item
              label='Change Password'
              initialValue={changePassword}
              valuePropName='checked'
            >
              <Switch onChange={onChangePassword} />
            </Form.Item>
          )}
        </>
      )}
      {(isCreate || changePassword) && (
        <>
          <Form.Item
            label='Password'
            name='password'
            initialValue={formData && formData.password}
            rules={[
              {
                required: true,
                message: 'Please input your password',
              },
              {
                validator(_, value) {
                  if (value && value.length < 6) {
                    return Promise.reject(
                      'Password must be at least 6 characters!',
                    );
                  }
                  return Promise.resolve();
                  // let regExp =
                  //   /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
                  // if (regExp.test(value)) {
                  //   return Promise.resolve();
                  // } else {
                  //   return Promise.reject(
                  //     'Password must be at least 6 letter password, with at least a symbol, upper and lower case letters and a number'
                  //   );
                  // }
                },
              },
            ]}
            hasFeedback
          >
            <Input
              disabled={readonly}
              type='password'
              placeholder={'Password'}
            />
          </Form.Item>
          <Form.Item
            name='confirm'
            label='Confirm Password'
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(
                      'The two passwords that you entered do not match!',
                    ),
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder={'Confirm password'} />
          </Form.Item>
        </>
      )}
      <Form.Item
        label='Company'
        name='company'
        initialValue={
          formData && formData.company && formData.company.company_bzp
        }
        rules={[
          {
            required: user && user.is_superadmin ? false : true,
            message: 'Please select company',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a company'
          optionFilterProp='children'
          loading={isCompaniesFetching || isCompanyByIdFetching}
          disabled={!user || !user.is_superadmin}
          onChange={onCompanyChange}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {companies &&
            companies.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Groups'
        name='groups'
        initialValue={
          formData && formData.groups && formData.groups.map((g) => g.name)
        }
      >
        <Select
          mode='multiple'
          showSearch
          placeholder='Select group'
          optionFilterProp='children'
          loading={isGroupsOfCompanyFetching}
          disabled={
            readonly ||
            !user ||
            (!user.is_superadmin &&
              !permission.checkPermission(user, 'user', ['c', 'u']))
          }
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {groupsOfCompnay &&
            groupsOfCompnay.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Diagram Owner'
        name='diagram_owner'
        initialValue={
          formData && formData.diagram_owner && formData.diagram_owner.name
        }
      >
        <Select
          showSearch
          placeholder='Select diagram owner'
          optionFilterProp='children'
          loading={isDiagramOwnerFetching}
          disabled={
            readonly ||
            !user ||
            (!user.is_superadmin &&
              !permission.checkPermission(user, 'user', ['c', 'u']))
          }
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {diagramOwner &&
            diagramOwner.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Yard'
        name='yard'
        initialValue={formData && formData.yard && formData.yard.short_name}
        rules={[
          {
            required: true,
            message: 'Please input yard',
          },
        ]}
      >
        <Select
          showSearch
          placeholder={'Select a yard'}
          optionFilterProp='children'
          loading={isLocationMasterFetching}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
          allowClear
          onClear={() => {
            setLocationOptions(locationOptions);
          }}
        >
          {locationOptions &&
            locationOptions.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Permission'
        name='function_permission'
        initialValue={
          formData &&
          formData.function_permission &&
          formData.function_permission.name
        }
        rules={[
          {
            required: true,
            message: 'Please input permission',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select permission'
          optionFilterProp='children'
          loading={isPermissionFetching}
          disabled={
            readonly ||
            !user ||
            (!user.is_superadmin &&
              !permission.checkPermission(user, 'user', ['c', 'u']))
          }
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {permissionOptions &&
            permissionOptions.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default UserFormComponent;
