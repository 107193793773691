import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    setTableState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
});

export const { setTableState } = tableSlice.actions;

export const selectTableStateById = (state, id) => {
  return state.table[id];
};

export const tableStateSelector = (state) => {
  return state.table;
};

export default tableSlice.reducer;
