import React, { useState, useEffect } from 'react';
import { setSidebarVisibility } from 'reducers/ui';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { Spin } from 'antd';
import BaseMap from './Mapview';
import InfoPanel from './InfoPanel';
import { getCurrentUser } from 'reducers/user';
import { useErrorHandler } from 'common_components/ErrorContext';
import { useFindRealtimeTrackingByIdQuery } from 'reducers/digitalmap/tttOperationDashboard/api';
import { notification } from 'antd';

const TTTJobTrackingMapview = (props) => {
  const { realtimeTrackingId } = useParams();
  const [gpsData, setGpsData] = useState(null);
  const [min, setMin] = useState(60000);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const messageError = (text) =>
    notification['error']({
      message: 'Error',
      description: `Something went wrong, Details: ${text}  , Please contact your administrator.`,
      duration: 0,
      style: {
        backgroundColor: '#fff2f0',
        border: '1px solid #ffccc7',
      },
    });

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const { data, error, isFetching, refetch } = useFindRealtimeTrackingByIdQuery(
    realtimeTrackingId,
    {
      skip: !user || !realtimeTrackingId,
      pollingInterval: min, //1 min
    }
  );

  useEffect(() => {
    if (data) {
      setGpsData(
        data && data.location_trailer ? data && data.location_trailer : null
      );
      if (data && !data.truck_schedules) {
        messageError('truck_schedules is null');
      }
      if (
        (data && data.routes === undefined) ||
        (data && data.routes.length === 0)
      ) {
        messageError('routes is null');
      }
      if (data && !data.location_trailer) {
        messageError('location_trailer latitude and longitude is null');
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  const updateMin = (value) => {
    if (value === 0) {
      setMin(0);
    }

    if (value === 60000) {
      setMin(60000);
    }
  };

  return (
    <SplitPane split='vertical'>
      <Pane initialSize='49%'>
        <div style={{ height: 'calc(100vh - 48px)', overflowY: 'auto' }}>
          <div
            style={{
              height: 'calc(100vh - 48px)',
              overflowY: 'auto',
              background: 'white',
            }}
          >
            {isFetching === true ? (
              <RootCircular>
                <CenterSpin indicator={antIcon} size='large' />
              </RootCircular>
            ) : (
              <InfoPanel
                realtimeTrackingId={realtimeTrackingId}
                panelData={data}
                polling={updateMin}
                refetch={refetch}
              />
            )}
          </div>
        </div>
      </Pane>
      <Pane>
        <BaseMap gpsData={gpsData} panelData={data} />
      </Pane>
    </SplitPane>
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default TTTJobTrackingMapview;
