import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { dateTimeFormatterWorking } from 'common_components/AntTable/tableUtils';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { Typography, Row, Col, Input } from 'antd';
import ToolbarYard from './ToolbarYard';
import { useSearchDataYardAssignVolumeMutation } from 'reducers/assignVolume/api';

const TableComponent = (props) => {
  const {
    filterDateFrom,
    filterDateTo,
    filterSelectFrom,
    filterOptionFrom,
    setFilterDateFrom,
    setFilterDateTo,
    setFilterSelectFrom,
    setFilterOptionFrom,
    statusCheckReadyEntry,
    setStatusCheckReadyEntry,
    isLoadingEntry,
    dataSourceTableYard,
    setDataSourceTableYard,
    onGetFilterDataVolumeTable,
    getEntryAssignVolumeQuery,
    isLoadingClearDataAll,
    apiClearDataAll,
    statusConfirm,
    setStatusConfirm,
    apiConfirmData,
    isLoadingConfirmData,
    loadingConfirm,
    setLoadingConfirm,

    uploadFile,
    setUploadFile,
    progressPercentRef,
    progressPercent,
    fileList,
    setFileList,
    selectFrom,
    setDataSourceVolume,
    setFilterVehicleType,
    setFilterTo,
    setFilterRegion,
    checkEditDetailVolume,
    apiConfirmDataVerify
  } = props;

  const { setErrorData } = useErrorHandler();
  const rowMeargeRef = useRef([]);
  const headerDateTableYardRef = useRef([]);

  const colorText = (record, date) => {
    let color = null;
    if (
      record?.vehicle_type ===
        record[record?.vehicle_type]?.vehicle_type_temp &&
      record[record?.vehicle_type]?.date_teme === date
    ) {
      color = { color: 'red' };
    } else {
      color = null;
    }

    return color;
  };
  const [columnsHeaderYard, setColumnsHeaderYard] = useState([
    {
      title: 'Yard',
      dataIndex: 'yard',
      align: 'center',

      render: (text, record, index) => {
        return {
          children: text,
          props: {
            rowSpan: rowMeargeRef.current && rowMeargeRef.current[index],
          },
        };
      },
    },
    {
      title: 'Vehicle Type',
      dataIndex: 'vehicle_type',
      align: 'center',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      align: 'center',

      children: [
        {
          title: () => {
            let date =
              headerDateTableYardRef.current &&
              headerDateTableYardRef.current[0]?.date1Date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },

          align: 'center',
          dataIndex: 'date1Value',
          key: 1,
          render: (text, record, index) => {
            return (
              <div style={colorText(record, record.date1?.date)}>{text}</div>
            );
          },
        },
        {
          title: () => {
            let date =
              headerDateTableYardRef.current &&
              headerDateTableYardRef.current[0]?.date2Date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          align: 'center',
          dataIndex: 'date2Value',
          key: 2,
          render: (text, record, index) => {
            return (
              <div style={colorText(record, record.date2?.date)}>{text}</div>
            );
          },
        },
        {
          title: () => {
            let date =
              headerDateTableYardRef.current &&
              headerDateTableYardRef.current[0]?.date3Date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          align: 'center',
          dataIndex: 'date3Value',
          key: 3,
          render: (text, record, index) => {
            return (
              <div style={colorText(record, record.date3?.date)}>{text}</div>
            );
          },
        },
        {
          title: () => {
            let date =
              headerDateTableYardRef.current &&
              headerDateTableYardRef.current[0]?.date4Date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          align: 'center',
          dataIndex: 'date4Value',
          key: 4,
          render: (text, record, index) => {
            return (
              <div style={colorText(record, record.date4?.date)}>{text}</div>
            );
          },
        },
        {
          title: () => {
            let date =
              headerDateTableYardRef.current &&
              headerDateTableYardRef.current[0]?.date5Date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          align: 'center',
          dataIndex: 'date5Value',
          key: 5,
          render: (text, record, index) => {
            return (
              <div style={colorText(record, record.date5?.date)}>{text}</div>
            );
          },
        },
        {
          title: () => {
            let date =
              headerDateTableYardRef.current &&
              headerDateTableYardRef.current[0]?.date6Date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          align: 'center',
          dataIndex: 'date6Value',
          key: 6,
          render: (text, record, index) => {
            return (
              <div style={colorText(record, record.date6?.date)}>{text}</div>
            );
          },
        },
        {
          title: () => {
            let date =
              headerDateTableYardRef.current &&
              headerDateTableYardRef.current[0]?.date7Date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          align: 'center',
          dataIndex: 'date7Value',
          key: 7,
          render: (text, record, index) => {
            return (
              <div style={colorText(record, record.date7?.date)}>{text}</div>
            );
          },
        },
      ],
    },
    {
      title: 'Total',
      dataIndex: 'total',
      align: 'center',
    },
  ]);

  // function
  const calRowSpan = (dataInput, fieldName) => {
    let tmp = [];
    let rowSpanList = [];
    for (let d of dataInput) {
      let value = d[fieldName];
      if (tmp.includes(value)) {
        rowSpanList.push(0);
      } else {
        const occurCount = dataInput.filter(
          (d) => d[fieldName] === value
        ).length;
        tmp.push(value);
        rowSpanList.push(occurCount);
      }
    }
    return rowSpanList;
  };

  // api
  //apiSearchYardTable -------------------------------------------------------
  const [
    apiSearchYardTable,
    {
      data: dataSearchYardTable,
      error: errorSearchYardTable,
      isLoading: isLoadingSearchYardTable,
    },
  ] = useSearchDataYardAssignVolumeMutation();

  useEffect(() => {
    if (dataSearchYardTable) {
      rowMeargeRef.current = calRowSpan(dataSearchYardTable, 'yard');
      headerDateTableYardRef.current = dataSearchYardTable;
      let arrAddIndexTable = dataSearchYardTable.map((item, index) => ({
        ...item,
        key: index + 1,
        date1Value: item.date1?.count,
        date2Value: item.date2?.count,
        date3Value: item.date3?.count,
        date4Value: item.date4?.count,
        date5Value: item.date5?.count,
        date6Value: item.date6?.count,
        date7Value: item.date7?.count,

        date1Date: item.date1?.date,
        date2Date: item.date2?.date,
        date3Date: item.date3?.date,
        date4Date: item.date4?.date,
        date5Date: item.date5?.date,
        date6Date: item.date6?.date,
        date7Date: item.date7?.date,
      }));

      setDataSourceTableYard(arrAddIndexTable);
      setDataSourceVolume([]);
      setFilterVehicleType(['All']);
      setFilterTo(['All']);
      setFilterRegion(['All']);
      headerDateTableYardRef.current = arrAddIndexTable;
    }
  }, [dataSearchYardTable]);

  useEffect(() => {
    if (errorSearchYardTable) {
      setErrorData(errorSearchYardTable);
    }
  }, [errorSearchYardTable, setErrorData]);

  return (
    <Container style={{ paddingTop: '10px' }}>
      <ToolbarYard
        //parameter
        checkEditDetailVolume={checkEditDetailVolume}
        fileList={fileList}
        setFileList={setFileList}
        filterDateFrom={filterDateFrom}
        filterDateTo={filterDateTo}
        filterSelectFrom={filterSelectFrom}
        filterOptionFrom={filterOptionFrom}
        setFilterDateFrom={setFilterDateFrom}
        setFilterDateTo={setFilterDateTo}
        setFilterSelectFrom={setFilterSelectFrom}
        setFilterOptionFrom={setFilterOptionFrom}
        isLoadingEntry={isLoadingEntry}
        dataSourceTableYard={dataSourceTableYard}
        setDataSourceTableYard={setDataSourceTableYard}
        isLoadingSearchYardTable={isLoadingSearchYardTable}
        statusCheckReadyEntry={statusCheckReadyEntry}
        setStatusCheckReadyEntry={setStatusCheckReadyEntry}
        statusConfirm={statusConfirm}
        setStatusConfirm={setStatusConfirm}
        isLoadingClearDataAll={isLoadingClearDataAll}
        uploadFile={uploadFile}
        setUploadFile={setUploadFile}
        //function
        onGetFilterDataVolumeTable={onGetFilterDataVolumeTable}
        getEntryAssignVolumeQuery={getEntryAssignVolumeQuery}
        rowMeargeRef={rowMeargeRef}
        apiSearchYardTable={apiSearchYardTable}
        headerDateTableYardRef={headerDateTableYardRef}
        apiClearDataAll={apiClearDataAll}
        apiConfirmData={apiConfirmData}
        apiConfirmDataVerify={apiConfirmDataVerify}
        isLoadingConfirmData={isLoadingConfirmData}
        loadingConfirm={loadingConfirm}
        setLoadingConfirm={setLoadingConfirm}
        progressPercentRef={progressPercentRef}
        progressPercent={progressPercent}
        selectFrom={selectFrom}
      />
      <Row
        gutter={[20]}
        style={{ marginBottom: '10px' }}
        align='start'
        justify='start'
      >
        <Col span={4}>
          <Typography>Summary volume upload </Typography>
        </Col>
        <Col span={2} align='center'>
          <Typography>Date</Typography>
        </Col>
        <Col span={4}>
          <Input
            value={filterDateFrom ? filterDateFrom.format('DD/MM/YY') : ''}
            placeholder='Date'
            disabled
            size='small'
            // inputreadonly={true}
          />
        </Col>
        <Col span={1} align='start'>
          <Typography>To</Typography>
        </Col>
        <Col span={4}>
          <Input
            value={filterDateTo ? filterDateTo.format('DD/MM/YY') : ''}
            placeholder='To'
            disabled
            size='small'
          />
        </Col>
      </Row>
      <AntTable
        rowKey='key'
        tableId={'key'}
        bordered
        showSorterTooltip={false}
        columns={columnsHeaderYard}
        dataSource={dataSourceTableYard}
        scroll={{ x: 'max-content' }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
