import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  originType: null,
  originLocation: null,
  destinationType: null,
  destinationLocation: null,
};

export const routeMasterSlice = createSlice({
  name: 'routeMaster',
  initialState,
  reducers: {
    clearState: (state) => {
      return initialState;
    },
    setOriginType: (state, action) => {
      state.originType = action.payload;
    },
    setOriginLocation: (state, action) => {
      state.originLocation = action.payload;
    },
    setDestinationType: (state, action) => {
      state.destinationType = action.payload;
    },
    setDestinationLocation: (state, action) => {
      state.destinationLocation = action.payload;
    },
  },
});

export const {
  clearState,
  setOriginType,
  setOriginLocation,
  setDestinationType,
  setDestinationLocation,
  setRouteGroup,
} = routeMasterSlice.actions;

export default routeMasterSlice.reducer;
