import React from 'react';
import {
  Row,
  Col,
  Button,
  Select,  Typography,
  Collapse,
  Divider,
  Modal,
  Input,
  Progress,
} from 'antd';
import styled from 'styled-components';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

const { Panel } = Collapse;
const { Option } = Select;
const Toolbar = (props) => {
  const {
    // onInit,
    filterDateFrom,
    setFilterDateFrom,
    filterDateTo,
    setFilterDateTo,
    filterVehicleType,
    setFilterVehicleType,
    filterOptionVehicleType,
    filterSelectFrom,
    setFilterSelectFrom,
    filterOptionSelectFrom,
    filterSelectTo,
    setFilterSelectTo,
    filterOptionSelectTo,
    isLoadingCreateByRemainTrip,
    isLoadingInit,
    remainTotal,
    onCreateBySystem,
    onCreateByRemainTrip,
    planJobStatus,
    filterOptionDateFrom,
    filterOptionDateTo,
    onBtnSearch,
    createTourState,
    onBtnDownload,
    isLoadingDownloadCSV,
    isLoadingSearch,
    loadingProgressBySystem,
    progressPercentRef,
    selectedRows,
    isLoadingCreateByManualCheck,
    onClickModalCreateTourManual,
    totalRows
  } = props;

  // useEffect(() => {
  //   if (onInit) {
  //     let payload = {
  //       from: flterDateFromOriginal,
  //       to: flterDateToOriginal,
  //       vehicle_type: filterVehicleType,
  //       location_from: filterSelectFrom,
  //       location_to: filterSelectTo,
  //       limit: LIMIT,
  //       offset: offset,
  //     };
  //     console.log('payload :>> ', payload);
  //     onInit(payload);
  //   }
  // }, [onInit]);
  const onConvertDate = (date) => {
    if (date !== '') {
      const [dateFormat] = date.split(' ');
      const [day, month, year] = dateFormat.split('/');
      const result = ['20' + year, month, day].join('-');
      return result;
    } else {
      return '';
    }
  };
  const onBtnSearchValue = () => {
    let payload = {
      from: onConvertDate(filterDateFrom !== '' ? filterDateFrom : ''),
      to: onConvertDate(filterDateTo !== '' ? filterDateTo : ''),
      vehicle_type: filterVehicleType,
      location_from: filterSelectFrom,
      location_to: filterSelectTo,
    };

    const _dateFrom = moment(payload?.from);
    const _dateTo = moment(payload?.to);

    if (_dateFrom.isAfter(_dateTo)) {
      Modal.error({
        title: 'ไม่สามารถเลือกเวลาย้อนหลังได้',
        icon: <ExclamationCircleOutlined />,
      });
    } else {
      onBtnSearch(payload);
    }
  };
  return (
    <Container>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Row gutter={[20]} align='middle' justify='center'>
            <Col span={3} align='start'>
              <Typography>Receive job date</Typography>
            </Col>

            <Col span={2} align='center'>
              <Typography>From</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                style={{
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingInit}
                maxTagCount='responsive'
                placeholder='Select date from'
                disabled={
                  planJobStatus === false ||
                  isLoadingCreateByRemainTrip === true ||
                  loadingProgressBySystem === true
                }
                value={filterDateFrom}
                onChange={(values) => {
                  setFilterDateFrom(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionDateFrom &&
                  filterOptionDateFrom.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={2} align='center'>
              <Typography>To</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                style={{
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingInit}
                maxTagCount='responsive'
                placeholder='Select date to'
                disabled={
                  planJobStatus === false ||
                  isLoadingCreateByRemainTrip === true ||
                  loadingProgressBySystem === true
                }
                value={filterDateTo}
                onChange={(values) => {
                  setFilterDateTo(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionDateTo &&
                  filterOptionDateTo.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='center'>
              <Typography>Vehicle Type</Typography>
            </Col>

            <Col span={5}>
              <Select
                mode='multiple'
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                allowClear={true}
                loading={isLoadingInit}
                maxTagCount='responsive'
                placeholder={'Vehicle Type'}
                disabled={
                  planJobStatus === false ||
                  isLoadingCreateByRemainTrip === true ||
                  loadingProgressBySystem === true
                }
                value={filterVehicleType}
                onChange={(values) => {
                  setFilterVehicleType(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionVehicleType &&
                  filterOptionVehicleType.map((c, index) => (
                    <Option key={index} value={c.code}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>

          <Row
            gutter={[20]}
            style={{ paddingTop: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2}></Col>

            <Col span={2} align='center'>
              <Typography>From</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='multiple'
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                allowClear={true}
                loading={isLoadingInit}
                maxTagCount='responsive'
                placeholder={'Select From'}
                disabled={
                  planJobStatus === false ||
                  isLoadingCreateByRemainTrip === true ||
                  loadingProgressBySystem === true
                }
                value={filterSelectFrom}
                onChange={(values) => {
                  setFilterSelectFrom(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionSelectFrom &&
                  filterOptionSelectFrom.map((c, index) => (
                    <Option key={index} value={c.code}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={2} align='center'>
              <Typography>To</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='multiple'
                style={{
                  width: '100%',
                }}
                defaultValue={'All'}
                allowClear={true}
                loading={isLoadingInit}
                maxTagCount='responsive'
                placeholder={'Select To'}
                disabled={
                  planJobStatus === false ||
                  isLoadingCreateByRemainTrip === true ||
                  loadingProgressBySystem === true
                }
                value={filterSelectTo}
                onChange={(values) => {
                  setFilterSelectTo(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionSelectTo &&
                  filterOptionSelectTo.map((c, index) => (
                    <Option key={index} value={c.code}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3}></Col>

            <Col span={2} align='end'>
              <Button
                type='primary'
                disabled={
                  planJobStatus === false ||
                  isLoadingCreateByRemainTrip === true ||
                  loadingProgressBySystem === true ||
                  !filterDateFrom ||
                  !filterDateTo
                }
                style={{
                  textAlign: 'center',
                  width: '100px',
                }}
                onClick={onBtnSearchValue}
                loading={isLoadingSearch}
              >
                Search
              </Button>
            </Col>
            <Col span={2} align='end'>
              <Button
                type='primary'
                disabled={
                  planJobStatus === false ||
                  isLoadingCreateByRemainTrip === true ||
                  loadingProgressBySystem === true
                }
                style={{
                  textAlign: 'center',
                  width: '100px',
                }}
                onClick={onBtnDownload}
                loading={isLoadingDownloadCSV}
              >
                Download
              </Button>
            </Col>
          </Row>

          <Divider style={{ margin: 10 }} />

          <Row
            gutter={[20]}
            style={{ marginBottom: '5px' }}
            align='start'
            justify='start'
          >
            <Col span={2} align='start' style={{ paddingTop: '5px' }}>
              <Typography>Search result : </Typography>
            </Col>

            <Col span={3}>
                <Input
                value={totalRows}
                placeholder='result'
                disabled={true}
              />
            </Col>
            <Col span={2} align='center' style={{ paddingTop: '5px' }}>
              <Typography>Remain total :</Typography>
            </Col>
            <Col span={3}>
              <Input value={remainTotal} disabled={true} placeholder='total' />
            </Col>
            <Col span={3} align='end' style={{ paddingTop: '5px' }}>
              <Progress percent={progressPercentRef} steps={5} />
            </Col>
            <Col span={3} >
              <Button
                type='primary'
                onClick={onCreateBySystem}
                disabled={
                  planJobStatus === false ||
                  createTourState === 'manual_started' ||
                  createTourState === 'remain_started' ||
                  isLoadingCreateByRemainTrip === true
                }
                loading={loadingProgressBySystem}
              >
                Create Tour (by system)
              </Button>
            </Col>
            <Col span={3} >
              <Button
                type='primary'
                onClick={() => {
                  onClickModalCreateTourManual();
                }}
                loading={isLoadingCreateByManualCheck}
                disabled={selectedRows?.length === 0}
              >
                Create Tour(by manual)
              </Button>
            </Col>
            <Col span={3} >
              <Button
                type='primary'
                onClick={onCreateByRemainTrip}
                disabled={
                  planJobStatus === false ||
                  // createTourState === "ready" ||
                  // createTourState === 'remain_started' ||
                  loadingProgressBySystem === true
                }
                loading={isLoadingCreateByRemainTrip}
              >
                Create Tour(by Remain trip)
              </Button>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default Toolbar;
