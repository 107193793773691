import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';
import { Tooltip } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
import moment from 'moment-timezone';
import './index.css';
const TableComponent = (props) => {
  const { rowData, isLoading } = props;
  const { height } = useWindowSize();
  const [tableHeight, setTableHeight] = useState(0);

  const columns = [
    {
      title: 'Job code',
      dataIndex: 'job_code',
      align: 'center',
      width: 70,
      className: 'bg-grey',
      render: (text, record) => {
        if (record.job_code_new) {
          return (
            <Tooltip placement='bottom' title={text}>
              <div
                style={{
                  color: '#ff0000',
                }}
              >
                {record.job_code_new}
              </div>
            </Tooltip>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: 'Trip no.',
      dataIndex: 'trip_no',
      align: 'center',
      width: 70,
      className: 'bg-grey',
      render: (text, record) => {
        if (text !== record.trip_no_new) {
          return (
            <Tooltip placement='bottom' title={text}>
              <div
                style={{
                  color: '#ff0000',
                }}
              >
                {record.trip_no_new}
              </div>
            </Tooltip>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: 'Combine Code',
      dataIndex: 'combine',
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        if (text !== record?.combine_before) {
          return (
            <Tooltip placement='bottom' title={record?.combine_before}>
              <div
                style={{
                  color: '#ff0000',
                }}
              >
                {record?.combine}
              </div>
            </Tooltip>
          );
        } else {
          return text;
        }
      },
    },

    {
      title: 'From',
      dataIndex: ['actual_from', 'short_name'],
      width: 150,
      align: 'center',
      render: (text, record) => {
        if (text) {
          if (record.actual_from_edit) {
            return (
              <Tooltip placement='bottom' title={text}>
                <div
                  style={{
                    color:
                      record.actual_from_edit?.label !==
                      record.actual_from_before
                        ? '#ff0000'
                        : 'initial',
                  }}
                >
                  {record.actual_from_edit.label}
                </div>
              </Tooltip>
            );
          } else {
            return text;
          }
        } else {
          return null;
        }
      },
    },

    {
      title: 'Destination',
      dataIndex: ['actual_cluster_to', 'name'],
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (record.actual_destination_edit) {
          return (
            <Tooltip placement='bottom' title={text}>
              <div
                style={{
                  color:
                    record.actual_destination_edit?.cluster?.name !==
                      record.actual_cluster_to_before?.name
                      ? '#ff0000'
                      : 'initial',
                }}
              >
                {record.actual_destination_edit
                  ? record.actual_destination_edit?.cluster?.name
                  : text}
              </div>
            </Tooltip>
          );
        } else {
          return text;
        }
      },
    },
    {
      title: 'Return Place',
      dataIndex: 'actual_return_place_before',
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text, record) => {
        return record.actual_return_place_before !==
          record.actual_return_place ? (
          <Tooltip placement='bottom' title={text}>
            <div
              style={{
                color:
                  record.actual_return_place_before !==
                  record.actual_return_place
                    ? '#ff0000'
                    : 'initial',
              }}
            >
              {record.actual_return_place ? record.actual_return_place : text}
            </div>
          </Tooltip>
        ) : (
          text
        );
      },
    },

    {
      title: 'Trailer type',
      dataIndex: ['trailer_type', 'name'],
      width: 120,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',

      render: (text, record) => {
        if (text) {
          if (record.trailer_type_edit) {
            return (
              <Tooltip placement='bottom' title={text}>
                <div
                  style={{
                    color:
                      record.trailer_type_edit?.name !== text
                        ? '#ff0000'
                        : 'initial',
                  }}
                >
                  {record.trailer_type_edit.name}
                </div>
              </Tooltip>
            );
          } else {
            return text;
          }
        } else {
          return null;
        }
      },
    },
    {
      title: 'Calling no.',
      dataIndex: ['callingsheet', 'callingsheet_no'],
      width: 100,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
    },
    {
      title: 'Trip Type',
      dataIndex: ['trip_type', 'name_en'],
      width: 120,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (text) {
          if (record.trip_type_edit) {
            return (
              <Tooltip placement='bottom' title={text}>
                <div
                  style={{
                    color: record.trip_type_edit ? '#ff0000' : 'initial',
                  }}
                >
                  {record.trip_type_edit.label}
                </div>
              </Tooltip>
            );
          } else {
            return text;
          }
        } else {
          return null;
        }
      },
    },
    {
      title: 'Trailer code',
      dataIndex: ['trailers', 0, 'trailer_card_id'],
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (text || record.trailersNew) {
          if (record.trailersNew) {
            return (
              <Tooltip placement='bottom' title={text}>
                <div
                  style={{
                    color: record.trailersNew ? '#ff0000' : 'initial',
                  }}
                >
                  {record.trailersNew.label}
                </div>
              </Tooltip>
            );
          } else {
            return text;
          }
        } else {
          return null;
        }
      },
    },
    {
      title: 'Driver code 1',
      dataIndex: ['drivers', 0, 'tlep_code'],
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (text || (record.driversCode1 && record.driversCode1New)) {
          if (record.driversCode1 && record.driversCode1New) {
            return (
              <Tooltip placement='bottom' title={text}>
                <div
                  style={{
                    color: record.driversCode1 ? '#ff0000' : 'initial',
                  }}
                >
                  {record.driversCode1.label}
                </div>
              </Tooltip>
            );
          } else {
            return text;
          }
        } else {
          return null;
        }
      },
    },
    {
      title: 'Driver code 2',
      dataIndex: ['drivers', 1, 'tlep_code'],
      width: 150,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (text || (record.driversCode2 && record.driversCode2New)) {
          if (record.driversCode2 && record.driversCode2New) {
            return (
              <Tooltip placement='bottom' title={text}>
                <div
                  style={{
                    color: record.driversCode2 ? '#ff0000' : 'initial',
                  }}
                >
                  {record.driversCode2.label}
                </div>
              </Tooltip>
            );
          } else {
            return text;
          }
        } else {
          return null;
        }
      },
    },

    {
      title: 'Receive Job Time',
      dataIndex: 'actual_receive_job_datetime_before',
      width: 170,
      align: 'center',
      render: (text, record) => {
        if (text || record.actual_receive_job_datetime_edit) {
          // Clear case
          if (text && record.actual_receive_job_datetime_edit === null) {
            return (
              <div
                style={{
                  color: '#ff0000',
                }}
              >
                {'-'}
              </div>
            );
          } else if (record.actual_receive_job_datetime_edit) {
            return (
              <Tooltip
                placement='bottom'
                title={dateTimeTimestamptzFormatter({ value: text })}
              >
                <div
                  style={{
                    color: record.actual_receive_job_datetime_edit
                      ? '#ff0000'
                      : 'initial',
                  }}
                >
                  {dateTimeTimestamptzFormatter({
                    value: record.actual_receive_job_datetime_edit,
                  })}
                </div>
              </Tooltip>
            );
          } else {
            return dateTimeTimestamptzFormatter({
              value: text,
            });
          }
        } else {
          return <div>{'-'}</div>;
        }
      },
    },
    {
      title: 'Yard out time',
      dataIndex: 'actual_yardout_datetime_before',
      width: 170,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (text || record.actual_yardout_datetime_edit) {
          // Clear case
          if (text && record.actual_yardout_datetime_edit === null) {
            return (
              <div
                style={{
                  color: '#ff0000',
                }}
              >
                {'-'}
              </div>
            );
          } else if (record.actual_yardout_datetime_edit) {
            return (
              <Tooltip
                placement='bottom'
                title={dateTimeTimestamptzFormatter({ value: text })}
              >
                <div
                  style={{
                    color: record.actual_yardout_datetime_edit
                      ? '#ff0000'
                      : 'initial',
                  }}
                >
                  {dateTimeTimestamptzFormatter({
                    value: record.actual_yardout_datetime_edit,
                  })}
                </div>
              </Tooltip>
            );
          } else {
            return dateTimeTimestamptzFormatter({
              value: text,
            });
          }
        } else {
          return <div>{'-'}</div>;
        }
      },
    },
    {
      title: 'Arrival time',
      dataIndex: 'actual_arrival_time_before',
      width: 170,
      align: 'center',
      ellipsis: true,
      render: (text, record) => {
        if (text || record.actual_arrival_time_max_date) {
          // Clear case
          if (text && record.actual_arrival_time_max_date === null) {
            return (
              <div
                style={{
                  color: '#ff0000',
                }}
              >
                {'-'}
              </div>
            );
          } else if (record.actual_arrival_time_max_date) {
            return (
              <Tooltip
                placement='bottom'
                title={dateTimeTimestamptzFormatter({ value: text })}
              >
                <div
                  style={{
                    color: !record.actual_arrival_time_max_date.isSame(
                      moment(text)
                    )
                      ? '#ff0000'
                      : 'initial',
                  }}
                >
                  {dateTimeTimestamptzFormatter({
                    value: record.actual_arrival_time_max_date,
                  })}
                </div>
              </Tooltip>
            );
          } else {
            return dateTimeTimestamptzFormatter({
              value: text,
            });
          }
        } else {
          return <div>{'-'}</div>;
        }
      },
    },

    {
      title: 'Last update',
      dataIndex: 'updated_at',
      width: 170,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
      render: (text) => {
        return dateTimeTimestamptzFormatter({ value: text });
      },
    },
    {
      title: 'Updater',
      dataIndex: ['updated_by', 'fullname_en'],
      width: 170,
      align: 'center',
      ellipsis: true,
      className: 'bg-grey',
    },
  ];

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  return (
    <Container>
      <StyledTable
        rowKey='id'
        tableId={'newStatusChange'}
        className={'statusChange'}
        rowSelection={{
          hideSelectAll: true,
          getCheckboxProps: () => {
            return {
              disabled: true,
            };
          },
        }}
        loading={isLoading}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={rowData}
        pagination={false}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          x: 'max-content',
        }}
      />
    </Container>
  );
};
const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body tr td {
      // padding: 3px;
      font-size: 14px;
    }
    .disabled-row {
      background-color: #000;
    }
  }
`;

export default TableComponent;
