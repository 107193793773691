import LocationTypeList from 'digitalmap_components/LocationType/List';
import LocationTypeDetails from 'digitalmap_components/LocationType/Details';
import RouteMasterList from 'digitalmap_components/RouteMaster/List';
import RouteGroupDetails from 'digitalmap_components/RouteMaster/RouteGroup';
import RouteMasterDetails from 'digitalmap_components/RouteMaster/RouteMaster';
import JobStatusMonitoring from 'digitalmap_components/JobMonitoring/List';
import JobTrackingMapview from 'digitalmap_components/JobTrackingMapview';
import TTTJobStatusMonitoring from 'digitalmap_components/TTTJobMonitoring/List';
import TTTJobTrackingMapview from 'digitalmap_components/TTTJobTrackingMapview';
import Configuration from 'digitalmap_components/Configuration/List';

const digitalMapRoutes = [
  {
    path: '/app/digitalmap/monitoring_jobs',
    name: 'Vehicle Status Monitoring',
    Component: JobStatusMonitoring,
  },
  {
    path: '/app/digitalmap/monitoring_jobs/truck_schedules/:truckScheduleId/orders/:orderId',
    name: 'Job Tracking',
    Component: JobTrackingMapview,
  },
  {
    path: '/app/digitalmap/ttt_monitoring_jobs',
    name: 'Trailer Status Monitoring',
    Component: TTTJobStatusMonitoring,
  },
  {
    path: '/app/digitalmap/ttt_monitoring_jobs/realtime_tracking/:realtimeTrackingId',
    name: 'Job Tracking',
    Component: TTTJobTrackingMapview,
  },
  {
    path: '/app/digitalmap/route_masters',
    name: 'Route Master',
    Component: RouteMasterList,
  },
  {
    path: '/app/digitalmap/route_masters/route_group/create',
    name: 'Create Route Group',
    Component: RouteGroupDetails,
  },
  {
    path: '/app/digitalmap/route_masters/route_group/routes/:id',
    name: 'Edit Route Master',
    Component: RouteMasterDetails,
  },
  {
    path: '/app/digitalmap/route_masters/route_group/:id',
    name: 'Edit Route Group',
    Component: RouteGroupDetails,
  },
  {
    path: '/app/digitalmap/location_types',
    name: 'Location Types',
    Component: LocationTypeList,
  },
  {
    path: '/app/digitalmap/location_types/create',
    name: 'Location Type Detail',
    Component: LocationTypeDetails,
  },
  {
    path: '/app/digitalmap/location_types/:id',
    name: 'Location Type Detail',
    Component: LocationTypeDetails,
  },
  {
    path: '/app/digitalmap/configuration',
    name: 'Configuration',
    Component: Configuration,
  },
];

export default digitalMapRoutes;
