import React, { useEffect } from 'react';
import { Form, Input, Button, Modal, Typography, Space, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { InfoCircleOutlined, UserOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { clearState, userSelector } from '../reducers/user';
import { passwordRecovery } from '../reducers/user/api';

const ForgotPasswordForm = (props) => {
  const { isVisible, setVisible, onClose } = props;
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { isLoading, isForgotPwdSuccess } = useSelector(userSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isForgotPwdSuccess) {
      dispatch(clearState());
      message.success(t('forgotPassword.success'));
      setTimeout(() => {
        setVisible(false);
      }, 1000);
    }
  }, [isForgotPwdSuccess, setVisible, t, dispatch]);

  const handleSendForgot = (value) => {
    if (value.forgot_email) {
      dispatch(passwordRecovery({ email: value.forgot_email }));
    } else {
      message.error(t('login.email-input-warning'));
    }
  };

  return (
    <Modal
      title={
        <Space>
          <InfoCircleOutlined style={{ color: 'blue' }} />
          {t('forgotPassword.text')}
        </Space>
      }
      visible={isVisible}
      onOk={form.submit}
      confirmLoading={isLoading}
      onCancel={onClose}
      footer={[
        <Button key='back' onClick={onClose}>
          {t('modal.cancel')}
        </Button>,

        <Button
          key='forgot-submit'
          type='primary'
          htmlType='submit'
          loading={isLoading}
          onClick={form.submit}
        >
          {t('modal.submit')}
        </Button>,
      ]}
    >
      <Typography.Title level={5}>
        {t('forgotPassword.description')}
      </Typography.Title>
      <Form form={form} onFinish={handleSendForgot}>
        <Form.Item
          name='forgot_email'
          rules={[
            {
              required: true,
              type: 'email',
              message: t('login.email-input-warning'),
            },
          ]}
        >
          <Input
            prefix={<UserOutlined />}
            placeholder={t('login.email')}
            type='email'
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ForgotPasswordForm;
