import dispatchBoardConstants from './constants';
import moment from 'moment';
import { setDispatchBoardFilter } from 'reducers/dispatchBoard';
import {
  dateFormatterYear,
  reasonFormatter,
  statusCallingFormatter,
  statusMatchPlanFormatter,
  statusConfirmTrDvFormatter,
} from 'common_components/AntTable/tableUtils';
import { message } from 'antd';

export const calOffsetFromPage = (page) => {
  return (page - 1 < 0 ? 0 : page - 1) * dispatchBoardConstants.PAGE_SIZE;
};

export const createPayload = (
  dispatch,
  {
    cluster_to_code,
    filterCompany,
    filterDiagramOwner,
    filterJobCode,
    filterStatus,
    filterFromYards,
    filterDiagramType,
    filterCalling,
    wait_Input_Tr_Dv,
    filterDatetime,
  }
) => {
  let from = filterDatetime?.fromTime;
  let to = filterDatetime?.toTime;

  // Logic to adjust from to in case of some empty
  if (!from && !to) {
    to = moment();
  }
  if (!from) {
    from = to
      .clone()
      .subtract(dispatchBoardConstants.LIMIT_SEARCH_DAYS, 'days');
  }
  if (!to) {
    to = from.clone().add(dispatchBoardConstants.LIMIT_SEARCH_DAYS, 'days');
  }

  dispatch(
    setDispatchBoardFilter({
      filterDatetime: {
        toTime: to,
        fromTime: from,
      },
    })
  );

  const selectedCompany = filterCompany
    ? filterCompany?.flat(1)
    : filterCompany;

  let array_diagram_owner_id = [filterDiagramOwner];
  return {
    cluster_to_code: cluster_to_code,
    bzp_code: selectedCompany,
    callingsheet_no: filterCalling ? [filterCalling] : null,
    job_code: filterJobCode,
    diagram_owner_id: array_diagram_owner_id,
    status: filterStatus,
    location_from: filterFromYards,
    diagram_type: filterDiagramType,
    wait_input_tr_dv: wait_Input_Tr_Dv ? wait_Input_Tr_Dv : false,
    working_day_from: from?.format('YYYY-MM-DD'),
    working_day_to: to?.format('YYYY-MM-DD'),
  };
};

export const formatDownloadData = (inputData, criteria) => {
  return inputData.map((d) => ({
    'Working date': d['working_date'] || '-',
    'Job code': d['job_code'] || '-',
    BZP: d['bzp']?.job_name || '-',
    Shift: d['shift'] || '-',
    'Trip No.': d['trip_no'] || '-',
    'Plan type': d['plan_type']?.name_en || '-',
    'Plan from': d['plan_from']?.short_name || '-',
    'Plan Destination': d['cluster_to']?.name || '-',
    'Plan Receive job': dateFormatterYear({
      value: d['plan_receive_job'],
    }),
    'Plan Yard out': dateFormatterYear({
      value: d['plan_yard_out'],
    }),
    'Plan Arrival': dateFormatterYear({
      value: d['plan_arrival'] || d['eta_datetime'],
    }),
    'Plan Return place': d['plan_return_place'] || '-',
    'Plan Loading Unit': d['plan_loading_unit'] || '-',
    'Trip type': d['trip_type']?.name_en || '-',
    'Combine Code': d['combine'] || '-',
    'Status Plan': d['status_plan'] || '-',
    'Reason cancel plan': reasonFormatter({
      reason: d['reason'],
      description: d['reason_description'],
    }),
    'Calling No.': d['callingsheet_no'] || '-',
    'Calling Making Time': dateFormatterYear({
      value: d['calling_making_time'],
    })
      ? dateFormatterYear({ value: d['calling_making_time'] })
      : '',
    'Status Calling': statusCallingFormatter({
      receive_job_datetime: d['receive_job_datetime'],
      calling_making_time: d['calling_making_time'],
    }),
    'Actual from': d['actual_from']?.short_name || '-',
    'Best Receive Job': dateFormatterYear({
      value: d['best_receive_job'],
    }),
    'Actual Destination': d['actual_cluster_to']?.name || '-',
    'Actual Return place': d['actual_return_place'] || '-',
    'Actual Loading Unit': d['actual_loading_unit'] || '-',
    'Match Plan time': dateFormatterYear({
      value: d['actual_match_calling_datetime'],
    }),
    'Status Match Plan': statusMatchPlanFormatter({
      receive_job_datetime: d['receive_job_datetime'],
      actual_match_calling_datetime: d['actual_match_calling_datetime'],
      criteria,
    }),
    'Trailer No.': d['trailer_no'] || '-',
    'Driver code 1': d['driver_code_1'] || '-',
    'Driver name 1': d['driver_name_1']?.fullname_th || '-',
    'Driver code 2': d['driver_code_2'] || '-',
    'Driver name 2': d['driver_name_2']?.fullname_th || '-',
    'Match TR/DV time': dateFormatterYear({
      value: d['actual_confirm_tr_dv_datetime'],
    }),
    'Status Match TR/DV': statusConfirmTrDvFormatter({
      receive_job_datetime: d['receive_job_datetime'],
      actual_confirm_tr_dv_datetime: d['actual_confirm_tr_dv_datetime'],
      criteria,
    }),
    'Actual Receive Job': dateFormatterYear({ value: d['actual_receive_job'] }),
    'Reason Receive Job': reasonFormatter({
      reason: d['reason_receive_job'],
      description: d['reason_receive_job']?.reason_description,
    }),
    'Trailer lane no. Receive': d['trailer_lane_no_receive']?.lane_no || '-',
    'Actual Yard out': dateFormatterYear({
      value: d['actual_yard_out'],
    }),
    'Reason Yard out': reasonFormatter({
      reason: d['reason_yard_out'],
      description: d['reason_yard_out']?.reason_description,
    }),
    'Actual Arrival': dateFormatterYear({
      value: d['actual_arrival'],
    }),
    'Trailer lane Arrival':
      d['trailer_lane_arrive']?.lane_no || d['trailer_lane_arrive'] || '-',
    'Reason Arrival': reasonFormatter({
      reason: d['reason_arrival'],
      description: d['reason_arrival']?.reason_description,
    }),
  }));
};

export const validateDatetime = (limitDay, filterDatetimeParam) => {
  let from = filterDatetimeParam?.fromTime;
  let to = filterDatetimeParam?.toTime;

  if (!from && !to) {
    to = moment();
  }

  if (!from) {
    from = to
      .clone()
      .subtract(dispatchBoardConstants.LIMIT_SEARCH_DAYS, 'days');
  }

  if (!to) {
    to = from.clone().add(dispatchBoardConstants.LIMIT_SEARCH_DAYS, 'days');
  }

  if (!moment(to).isSameOrAfter(moment(from))) {
    message.error('Datetime range is invalid');
    return false;
  }

  if (moment(to).diff(moment(from), 'days') > limitDay) {
    message.error(`Datetime range is longer than ${limitDay}`);
    return false;
  }

  return true;
};
