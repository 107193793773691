import React from 'react';
import { Row, Col, Button, Select, Typography, Collapse, Divider } from 'antd';
import styled from 'styled-components';
const { Panel } = Collapse;
const { Option } = Select;
const TableToolbar = (props) => {
  const {
    filterDate,
    filterVehicleType,
    setFilterVehicleType,
    setFilterRegion,
    filterTo,
    setFilterTo,
    filterRegion,
    filterOptionVehicleType,
    filterOptionTo,
    filterOptionRegion,
    filterSelectFrom,
    setFilterDate,
    isLoadingGetFilter,
    dataSourceVolume,
    setDataSourceVolume,
    isLoadingSearchVolumeTable,
    apiSearchVolumeTable,
    onBtnUpdate,
    isLoadingEditUnitVolume,
    isLoadingEntry,
    filterOptionDate,
    checkEditDetailVolume,
    setCheckEditDetailVolume,
    onBtnCancel,
    loadingConfirm,
    statusConfirm,
    newDataUnit,
    dataSourceTableYard
  } = props;
  //button-----------------------------------------------------------
  const onBtnSearchVolume = async () => {
    let payload = {
      volume_date: filterDate ? onConvertDate(filterDate) : null,
      vehicle_type: filterVehicleType ? filterVehicleType : 'all',
      location_from: filterSelectFrom ? filterSelectFrom : 'all',
      location_to: filterTo ? filterTo : 'all',
      region: filterRegion ? filterRegion : 'all',
    };
    apiSearchVolumeTable(payload);
  };
  const onConvertDate = (date) => {
    if (date !== '') {
      const [dateFormat] = date.split(' ');
      const [day, month, year] = dateFormat.split('/');
      const result = ['20' + year, month, day].join('-');
      return result;
    } else {
      return '';
    }
  };

  const onBtnEditVolume = async () => {
    setCheckEditDetailVolume(true);
    let arrDataTable = [];
    arrDataTable = dataSourceVolume;

    let arrAddEditRowTable = arrDataTable.map((item) => ({
      ...item,
      editingRow: true,
    }));

    setDataSourceVolume(arrAddEditRowTable);
  };
  return (
    <Container>
      <StyledCollapse defaultActiveKey={1}>
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[20]}
            style={{ marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='center'>
              <Typography>Date</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingGetFilter}
                maxTagCount='responsive'
                placeholder={'Date'}
                disabled={
                  checkEditDetailVolume ||
                  !filterSelectFrom ||
                  isLoadingEntry === true ||
                  loadingConfirm === true ||
                  dataSourceTableYard?.length === 0
                }
                value={filterDate}
                onChange={(values) => {
                  setFilterDate(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionDate &&
                  filterOptionDate.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>

            <Col span={2} align='end'>
              <Typography>Vehicle Type</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingGetFilter}
                maxTagCount='responsive'
                placeholder={'Vehicle Type'}
                disabled={
                  checkEditDetailVolume ||
                  !filterSelectFrom ||
                  isLoadingEntry ||
                  loadingConfirm ||
                  dataSourceTableYard?.length === 0
                }
                value={filterVehicleType}
                onChange={(values) => {
                  setFilterVehicleType(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionVehicleType &&
                  filterOptionVehicleType.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>

            <Col span={1} align='end'>
              <Typography>To</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingGetFilter}
                maxTagCount='responsive'
                placeholder={'To'}
                disabled={
                  checkEditDetailVolume ||
                  !filterSelectFrom ||
                  isLoadingEntry ||
                  loadingConfirm||
                  dataSourceTableYard?.length === 0
                }
                value={filterTo}
                onChange={(values) => {
                  setFilterTo(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionTo &&
                  filterOptionTo.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>

            <Col span={2} align='end'>
              <Typography>Region</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                allowClear={true}
                loading={isLoadingGetFilter}
                maxTagCount='responsive'
                placeholder={'Region'}
                disabled={
                  checkEditDetailVolume ||
                  !filterSelectFrom ||
                  isLoadingEntry ||
                  loadingConfirm||
                  dataSourceTableYard?.length === 0
                }
                value={filterRegion}
                onChange={(values) => {
                  setFilterRegion(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {filterOptionRegion &&
                  filterOptionRegion.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
          <Divider style={{ margin: '0px' }}></Divider>

          <Row
            gutter={[20]}
            style={{ marginTop: '10px' }}
            align='center'
            justify='center'
          >
            <Col span={15}></Col>
            <Col span={3} align='center'>
              <Button
                type='primary'
                style={{ width: '100px' }}
                onClick={onBtnSearchVolume}
                loading={isLoadingSearchVolumeTable}
                disabled={
                  !newDataUnit ||
                  !filterSelectFrom ||
                  isLoadingEntry ||
                  loadingConfirm ||
                  checkEditDetailVolume ||
                  dataSourceTableYard?.length === 0
                }
              >
                Search
              </Button>
            </Col>
            <Col span={3} align='center'>
              <Button
                type='primary'
                style={{ width: '100px' }}
                onClick={onBtnUpdate}
                loading={isLoadingEditUnitVolume}
                disabled={newDataUnit || loadingConfirm === true}
              >
                Update
              </Button>
            </Col>

            <Col span={3} align='center'>
              <Button
                type='primary'
                style={{ width: '100px' }}
                onClick={onBtnEditVolume}
                disabled={dataSourceVolume.length == 0 || statusConfirm}
                hidden={
                  checkEditDetailVolume === true || loadingConfirm === true
                }
              >
                Edit
              </Button>

              <Button
                type='primary'
                style={{ width: '100px' }}
                onClick={onBtnCancel}
                hidden={
                  checkEditDetailVolume === false || loadingConfirm === true
                }
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      padding: unset
      padding: 1px 0px;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default TableToolbar;
