import React from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';
import Sidebar from './Sidebar';
import Header from './Header';

const AdminlayoutHoc = (props) => {
  return (
    <Layout>
      <Header />
      <ContentLayout>
        <Sidebar />
        <Layout>
          <CenterLayout>{props.children}</CenterLayout>
        </Layout>
      </ContentLayout>
    </Layout>
  );
};

const ContentLayout = styled(Layout)`
  height: calc(100vh - 48px);
`;

const CenterLayout = styled.div`
  height: calc(100vh - 48px);
`;

export default AdminlayoutHoc;
