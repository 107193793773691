import { Button, Col, Modal, Row } from 'antd';
import { StyledAntTable } from './misc';

export default function EditRecvJobTimeModal({
  isVisible,
  handleOk,
  onBtnBackEdit,
  initialTour,
  btnBackEdit,
  loadingDataTableEditInit,
  columnsDisplayEditTour,
  onBtnResetEdit,
  modalSaveEdit,
  isLoadingCheckAndSave,
  checkCalculate,
  isLoadingEdit,
  columnsEditTour,
  dataTableEdit,
}) {
  return (
    <Modal
      title='Edit Tour'
      visible={isVisible}
      onOk={handleOk}
      onCancel={onBtnBackEdit}
      width={1200}
      footer={false}
      maskClosable={false}
    >
      <h3>Original</h3>
      <Row gutter={[20]}>
        <Col span={12}>
          <h4>
            Job code : {'   '}
            <span style={{ fontWeight: '400', marginRight: '20px' }}>
              {initialTour?.job_code}
            </span>
            {'   '}
            Shift : {'   '}
            <span style={{ fontWeight: '400', marginRight: '20px' }}>
              {initialTour?.shift}
            </span>
            {'   '}
            Trip type : {'   '}
            <span style={{ fontWeight: '400', marginRight: '20px' }}>
              {initialTour?.trip_type}
            </span>
          </h4>
        </Col>
        <Col span={12} align='end'>
          <h4>
            Working hour :{' '}
            <span style={{ fontWeight: '400' }}>
              {' '}
              {initialTour?.working_hour}
            </span>
          </h4>
        </Col>
      </Row>

      <StyledAntTable
        rowKey='id'
        tableId={'id'}
        bordered
        size='small'
        showSorterTooltip={false}
        loading={loadingDataTableEditInit}
        columns={columnsDisplayEditTour}
        dataSource={initialTour?.lots}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />

      {/* <Row gutter={[20]} style={{ paddingTop: '10px' }}>
        <Col span={2} style={{ paddingTop: '20px' }}>
          <h3>Edit</h3>
        </Col>
        <Col span={4} align='start'>
          <Typography>Company</Typography>
          <Select
            showSearch
            style={{
              textAlign: 'center',
              width: '100%',
            }}
            size='small'
            allowClear={true}
            loading={loadingDataTableEditOptionInit}
            placeholder={'Company'}
            value={filterCompanyEdit}
            onChange={(values) => {
              setFilterJobCodeEdit(undefined);
              setFilterCompanyEdit(values);
              setFilterJobCodeEditOption(undefined);
            }}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {filterCompanyEditOption?.map((c, index) => (
              <Select.Option key={index} value={c.value}>
                {c.label}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col span={4} align='start'>
          <Typography>Trailer type</Typography>
          <Select
            showSearch
            style={{
              textAlign: 'center',
              width: '100%',
            }}
            allowClear={true}
            defaultValue={filterTrailerTypeEdit}
            size='small'
            pagination={false}
            loading={loadingDataTableEditOptionInit}
            placeholder={'Trailer type'}
            value={filterTrailerTypeEdit}
            // options={filterTripTypeOption}
            onChange={(values) => {
              setFilterTrailerTypeEdit(values);
              setFilterJobCodeEdit(undefined);
              setFilterJobCodeEditOption(undefined);
            }}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {filterTrailerTypeEditOption?.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        </Col>

        <Col span={4} align='start'>
          <Typography>Match job code</Typography>
          <Select
            showSearch
            style={{
              textAlign: 'center',
              width: '100%',
            }}
            allowClear={true}
            defaultValue={[filterMatchJobCodeEdit]}
            size='small'
            pagination={false}
            loading={loadingDataTableEditOptionInit}
            placeholder={'Trailer type'}
            value={filterMatchJobCodeEdit}
            onChange={(values) => {
              setFilterMatchJobCodeEdit(values);
              setFilterJobCodeEdit(undefined);
              setFilterJobCodeEditOption(undefined);
            }}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {filterMatchJobCodeEditOption?.map((c, index) => (
              <Select.Option key={index} value={c.value}>
                {c.label}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col span={4} align='start'>
          <Typography>Trip type</Typography>
          <Select
            showSearch
            style={{
              textAlign: 'center',
              width: '100%',
            }}
            defaultValue={filterTripTypeEdit}
            allowClear={false}
            size='small'
            loading={loadingDataTableEditOptionInit}
            placeholder={'Trip type'}
            value={filterTripTypeEdit}
            onChange={(values) => {
              setFilterTripTypeEdit(values);
              setFilterJobCodeEdit(undefined);
              setFilterJobCodeEditOption(undefined);
            }}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {filterTripTypeEditOption?.map((c, index) => (
              <Select.Option key={index} value={c.value}>
                {c.label}
              </Select.Option>
            ))}
          </Select>
        </Col>

        <Col span={4} align='start'>
          <Typography>Job code</Typography>
          <Select
            showSearch
            style={{
              textAlign: 'center',
              width: '100%',
            }}
            allowClear={true}
            size='small'
            loading={loadingDataTableEditOptionInit}
            placeholder={'Job code'}
            value={filterJobCodeEdit}
            onChange={(values) => {
              filterJobCodeEditRef.current = values;
              setFilterJobCodeEdit(values);
              onBtnCheck(true);
              if (values) {
                setCheckCalculate(true);
              } else {
                setCheckCalculate(false);
              }
            }}
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {filterJobCodeEditOption?.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        </Col>
      </Row> */}

      <Row gutter={[20]}>
        <Col span={12} align='end'></Col>
        <Col span={12} align='end'>
          <h4>
            Shift :{' '}
            <span style={{ fontWeight: '400', marginRight: '20px' }}>
              {initialTour?.shift}
            </span>{' '}
            Working hour :{' '}
            <span style={{ fontWeight: '400' }}>
              {initialTour?.working_hour}
            </span>
          </h4>
        </Col>
      </Row>

      <StyledAntTable
        rowKey='id'
        tableId={'id'}
        bordered
        size='small'
        loading={loadingDataTableEditInit || isLoadingEdit}
        showSorterTooltip={false}
        columns={columnsEditTour}
        dataSource={dataTableEdit}
        scroll={{ x: 'max-content' }}
        pagination={false}
      />

      <Row gutter={[20]}>
        <Col span={12} align='start'>
          <Button
            type='primary'
            size='small'
            style={{
              width: '90px',
              marginTop: '20px',
              marginRight: '20px',
              height: 50,
            }}
            onClick={btnBackEdit}
          >
            Back
          </Button>
        </Col>
        <Col span={12} align='end'>
          <Button
            type='primary'
            size='small'
            style={{
              width: '90px',
              marginTop: '20px',
              marginRight: '20px',
              height: 50,
            }}
            onClick={onBtnResetEdit}
          >
            Reset
          </Button>
          <Button
            type='primary'
            size='small'
            style={{ width: '90px', marginTop: '20px', height: 50 }}
            onClick={modalSaveEdit}
            loading={isLoadingCheckAndSave}
            disabled={!checkCalculate}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}
