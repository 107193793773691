import React, { useState, useEffect, useCallback, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { dateFormatterYear } from 'common_components/AntTable/tableUtils';
import TableToolbar from './Toolbar';
import TableSub from './TableSub';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { message, Typography, Popover, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import {
  useFindAllRecommendTripBySelectionQuery,
  useFindAllRecommendTripByCallingQuery,
  recommendTripApi,
} from 'reducers/recommendTrip/api';
import {
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { goBack } from 'connected-react-router';
import { setIsNeedRefresh } from 'reducers/callingsheet';
const { Title } = Typography;

const RecommendTripTableComponent = (props) => {
  // Store filters
  const { totalRows } = props;
  const { id, calling_unit, from, cluster } = useParams();
  const [dataTable, setDataTable] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingMatch, setIsLoadingMatch] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [filterDiagramType, setFilterDiagramType] = useState([]);

  const LIMIT = 500;
  const PAGE_SIZE = 15;
  const payloadRef = useRef(null);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  //parameter
  const [filterOptionCompany, setFilterOptionCompany] = useState();
  const [filterOptionFrom, setFilterOptionFrom] = useState();
  const [filterOptionCluster, setFilterOptionCluster] = useState();
  const [filterOptionTrailerType, setFilterOptionTrailerType] = useState();
  const [filterOptionDiagramType, setFilterOptionDiagramType] = useState();
  const [filterTrailerSageProp, setFilterTrailerSageProp] = useState();
  const [filterCllingUnitProp, setCallingUnitProp] = useState();
  const [filteBestReceiveJobTimeProp, setFilteBestReceiveJobTimeProp] =
    useState();

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Best Receive Job',
        dataIndex: 'best_receive_job_time',
        align: 'center',
        render: (text, record) => {
          return dateFormatterYear({ value: text });
        },
        width: 80,
        movable: true,
      },
      {
        title: 'From',
        dataIndex: ['location_from', 'short_name'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Cluster',
        dataIndex: ['cluster', 'name'],
        align: 'center',
        width: 50,
        movable: true,
      },
      {
        title: 'Region',
        dataIndex: ['cluster', 'region', 'name'],
        align: 'center',
        width: 50,
        movable: true,
      },
      {
        title: 'Calling No.',
        dataIndex: 'callingsheet_no',
        align: 'center',
        width: 80,
        movable: true,
      },
      {
        title: (
          <>
            Destination{' '}
            <InfoCircleOutlined
              style={{ paddingBottom: '2px', fontSize: '10px' }}
            />
          </>
        ),
        dataIndex: 'destinations',
        width: 80,
        align: 'center',
        movable: true,
        render(text, record) {
          return text ? (
            <Popover placement='topLeft' content={contentPopup(record)}>
              {record && record.destinations.length > 0
                ? record && record.destinations[0]?.destination
                : ''}
            </Popover>
          ) : (
            ''
          );
        },
        ellipsis: true,
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        width: 50,
        align: 'center',
        movable: true,
      },
      {
        title: 'Trailer usage',
        dataIndex: 'trailer_usage',
        width: 100,
        align: 'center',
        movable: true,
        render(text, record) {
          return text ? text + ' Day' : '';
        },
      },
      {
        title: 'Diagram Type',
        dataIndex: ['diagram_type', 'name_en'],
        width: 100,
        align: 'center',
        movable: true,
      },
      {
        title: 'Calling Date & Time',
        dataIndex: 'calling_datetime',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return dateFormatterYear({ value: text });
        },
        movable: true,
      },
    ];

    return cols;
  });

  //All Selection
  const {
    data: masterData,
    error: errorMasterData,
    isLoading: isLoadingMasterData,
  } = useFindAllRecommendTripBySelectionQuery(calling_unit, {
    skip: !calling_unit,
  });

  useEffect(() => {
    if (masterData) {
      let findClustersData = masterData.cluster.map((d) => ({
        label: d.name,
        value: d.id,
      }));
      let findCompany = masterData.company.map((d) => ({
        label: d.job_name,
        value: d.id,
      }));

      let findFrom = masterData.location_from.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));

      let findTrailerType = masterData.trailer_type.map((d) => ({
        label: d.name,
        value: d.id,
      }));
      let findDiagramType = masterData.diagram_type.map((d) => ({
        label: d.name_en,
        value: d.id,
      }));
      setFilterOptionCompany(findCompany);
      setFilterOptionFrom(findFrom);
      setFilterOptionCluster(findClustersData);
      setFilterOptionTrailerType(findTrailerType);
      setFilterOptionDiagramType(findDiagramType);
    }
  }, [masterData]);

  const executeQuery = useCallback(
    async (curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoadingSearch(true);
        let result = await dispatch(
          recommendTripApi.endpoints.findAllRecommendTripByPagination.initiate(
            payload,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          Modal.error({
            title: result.error.data.errorList[0].errorMessage,
            icon: <ExclamationCircleOutlined />,
            content: result.error.data.errorList[0].errorDetail,
          });
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              no: i + rowNo,
            }));

            newData = curData.concat(resData);
            setDataSource(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoadingSearch(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setDataSource, setErrorData]
  );

  const onSearchList = useCallback(
    async (payload) => {
      try {
        if (!isLoadingSearch) {
          let bufferData = [];
          let requiredRows = 1 * PAGE_SIZE;
          requiredRows =
            requiredRows > totalRows && totalRows !== 0
              ? totalRows
              : requiredRows;

          if (requiredRows > bufferData.length) {
            // Fetch more data
            let curOffset = 0;
            payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };

            do {
              let queryRes = await executeQuery(
                bufferData,
                {
                  ...payloadRef.current,
                  limit: LIMIT,
                  offset: curOffset,
                },
                true
              );
              curOffset += LIMIT;
              bufferData = queryRes.data;
              requiredRows =
                requiredRows > queryRes.totalRows
                  ? queryRes.totalRows
                  : requiredRows;
            } while (requiredRows > bufferData.length);
          }
        }
      } catch (err) {
        console.log('err', err);
      }
    },
    [executeQuery, isLoadingSearch, totalRows]
  );

  //All mainData
  const {
    data: mainData,
    error: errorMainData,
    isLoading: isLoadingMainData,
  } = useFindAllRecommendTripByCallingQuery(id, {
    skip: !id,
  });

  useEffect(() => {
    if (mainData) {
      console.log('mainData :>> ', mainData);
      if (mainData?.best_receive_job_time) {
        let body = {
          location_from: from,
          cluster: cluster,
          trailer_usage: mainData?.trailer_usage,
          best_receive_job_time: mainData?.best_receive_job_time
            ? moment(mainData?.best_receive_job_time).format()
            : null,
          calling_unit: calling_unit,
        };
        onSearchList(body);
      }
      setDataTable([{ ...mainData }]);
      setFilterTrailerSageProp(mainData?.trailer_usage);
      setCallingUnitProp(calling_unit);
      setFilteBestReceiveJobTimeProp(mainData?.best_receive_job_time);
      setFilterDiagramType(
        mainData?.diagram_type?.id ? [mainData?.diagram_type?.id] : []
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cluster, from, mainData]);

  useEffect(() => {
    if (errorMasterData) {
      setErrorData(errorMasterData);
    }
    if (errorMainData) {
      setErrorData(errorMainData);
    }
  }, [errorMasterData, errorMainData, setErrorData]);

  const columnsPopup = [
    {
      title: 'No.',
      dataIndex: 'no',
      width: 30,
      align: 'center',
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      width: 150,
      align: 'center',
    },
    {
      title: 'Unit',
      dataIndex: 'unit',
      width: 60,
      align: 'center',
    },
    {
      title: 'Vehicle type',
      dataIndex: 'vehicle_type',
      width: 100,
      align: 'center',
    },
  ];
  const contentPopup = (value) => {
    let resData =
      value &&
      value.destinations.map((d, i) => ({
        ...d,
        no: i + 1,
      }));
    try {
      return (
        <>
          <AntTable
            rowKey='id'
            tableId={'recommend_trip'}
            showSorterTooltip={false}
            columns={columnsPopup}
            dataSource={resData && resData}
            pagination={false}
            width={750}
          />
        </>
      );
    } catch (err) {
      console.log('err', err);
    }
  };
  const msgWarning = (text) => {
    return Modal.confirm({
      title: 'Would you like to confirm for the matching trip?',
      icon: <ExclamationCircleOutlined />,
      content: text ? (
        <div style={{ whiteSpace: 'pre-line', color: 'red' }}>
          Warning : <br /> {text}
        </div>
      ) : null,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };
  const onMatch = async () => {
    try {
      setIsLoadingMatch(true);
      let findRow = dataSource.find((d) => d.id === selectedRowKeys[0]);
      let actual_cluster_to = false;
      let trailer_usage = false;
      let trailer_type = false;
      if (findRow.actual_cluster_to.code !== dataTable[0].cluster.code) {
        actual_cluster_to = true;
      }
      if (findRow && findRow.trailer_usage !== mainData.trailer_usage) {
        trailer_usage = true;
      }
      if (
        findRow &&
        findRow.trailer_type &&
        findRow.trailer_type.maximum_loading !== mainData.unit
      ) {
        trailer_type = true;
      }

      let warningCluster = `- Cluster in calling not match with cluster in selected diagram plan \n`;
      let warningUsage = `- Trailer usage of selected trip not same calling (both less & more) \n`;
      let warningTrailer = `- Trailer type not full load \n`;
      if (actual_cluster_to && trailer_usage && trailer_type) {
        msgWarning(warningCluster + warningUsage + warningTrailer);
      } else if (actual_cluster_to && trailer_usage) {
        msgWarning(warningCluster + warningUsage);
      } else if (trailer_usage && trailer_type) {
        msgWarning(warningUsage + warningTrailer);
      } else if (actual_cluster_to && trailer_type) {
        msgWarning(warningCluster + warningTrailer);
      } else if (actual_cluster_to) {
        msgWarning(warningCluster);
      } else if (trailer_usage) {
        msgWarning(warningUsage);
      } else if (trailer_type) {
        msgWarning(warningTrailer);
      } else {
        Modal.confirm({
          title: 'Would you like to confirm for the matching trip?',
          icon: <ExclamationCircleOutlined />,
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk: handleConfirmAction,
        });
      }
    } catch (err) {
      console.log('err', err);
      handleConfirmAction();
    } finally {
      setIsLoadingMatch(false);
    }
  };

  const handleConfirmAction = async () => {
    try {
      setIsLoadingMatch(true);
      let body = {
        calling_id: id,
        dispatch_board_id: selectedRowKeys,
      };
      let result = await dispatch(
        recommendTripApi.endpoints.findAllRecommendTripByMatchCalling.initiate(
          body,
          {
            forceRefetch: true,
          }
        )
      );

      if (result && result.error) {
        Modal.error({
          title: result.error.data.errorList[0].errorMessage,
          icon: <ExclamationCircleOutlined />,
          content: result.error.data.errorList[0].errorDetail,
        });
      } else {
        if (result.data?.warning) {
          dispatch(setIsNeedRefresh(true));

          Modal.warning({
            title: result.data?.warning,
            icon: <ExclamationCircleOutlined />,
            onOk: () => {
              message.success('Data was successfully saved');
              setTimeout(() => {
                dispatch(goBack());
              }, 1000);
            },
          });
        } else if (result.data) {
          message.success('Data was successfully saved');

          setTimeout(() => {
            dispatch(goBack());
          }, 1000);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoadingMatch(false);
    }
  };

  return (
    <Container>
      <TableToolbar
        selectedRowKeys={selectedRowKeys}
        showReload={true}
        isLoading={isLoadingSearch}
        isLoadingMatch={isLoadingMatch}
        showSearch={true}
        columns={columns}
        setColumns={setColumns}
        onSearchList={onSearchList}
        onMatch={onMatch}
        filterOptionCompany={filterOptionCompany}
        filterOptionFrom={filterOptionFrom}
        filterOptionCluster={filterOptionCluster}
        filterOptionTrailerType={filterOptionTrailerType}
        filterOptionDiagramType={filterOptionDiagramType}
        isLoadingMasterData={isLoadingMasterData}
        filterCllingUnitProp={filterCllingUnitProp}
        filterTrailerSageProp={filterTrailerSageProp}
        filteBestReceiveJobTimeProp={filteBestReceiveJobTimeProp}
        diagramType={filterDiagramType}
      />
      <Title level={5}>Calling Detail</Title>

      <AntTable
        rowKey='id'
        tableId={'recommendTrip'}
        bordered
        showSorterTooltip={false}
        loading={isLoadingMainData}
        pagination={false}
        columns={columns}
        dataSource={dataTable || []}
        scroll={{ x: 'max-content' }}
      />

      <Title level={5} style={{ marginTop: 20 }}>
        Recommend trip list
      </Title>
      <TableSub
        dataSource={dataSource}
        isLoadingSearch={isLoadingSearch}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default RecommendTripTableComponent;
