import React, { useEffect } from 'react';
import { useFindAllTrailersQuery } from 'reducers/masterData/trailer/api';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Spin, Button } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { push, goBack } from 'connected-react-router';

import Table from './Table';

const TrailerListComponent = (props) => {
  const dispatch = useDispatch();
  const { data, error, isLoading, isFetching, refetch } =
    useFindAllTrailersQuery();
  const { setErrorData } = useErrorHandler();
  const refetchData = () => {
    refetch();
  };

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Trailers List'
        subTitle=''
        extra={[
          <Button
            key='trailer_type'
            type='primary'
            onClick={() => {
              dispatch(push('/app/master/trailers/trailer_types'));
            }}
          >
            Trailer Type
          </Button>
        ]}
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        {isLoading === true ? (
          <RootCircular>
            <CenterSpin indicator={antIcon} size='large' />
          </RootCircular>
        ) : (
          <Table
            rowData={data}
            refetchData={refetchData}
            isLoading={isFetching}
          />
        )}
      </Content>
    </Container>
  );
};

const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;

const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default TrailerListComponent;
