import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const RecommendTripSlice = createSlice({
  name: 'recommendTrip',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setRecommendTripTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setRecommendTripDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setRecommendTripFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setCallingListCell: (state, { payload }) => {
      state.callingListCell = payload;
    },
  },
});

export const {
  setRecommendTripFilter,
  setCurrentPage,
  setCurrentOffset,
  setRecommendTripDataSource,
  setRecommendTripTotalRows,
  setRecommendTripCompleteCount,
  setCallingListCell
} = RecommendTripSlice.actions;

export const RecommendTripSelector = (state) => state.RecommendTrip;

export default RecommendTripSlice.reducer;
