import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { Typography } from 'antd';
const { Link } = Typography;

const LinkRenderer = (props) => {
  const { data, field, name, path } = props;
  const dispatch = useDispatch();

  let id = null;
  let display = '-';
  if (data) {
    let obj = data[field];
    if (obj) {
      id = obj.id;
      if (id) {
        display = obj[name];
      }
    }
  }

  return (
    <Link
      onClick={() => {
        if (id) {
          dispatch(push(`${path}/${id}`));
        }
      }}
    >
      {display}
    </Link>
  );
};

export default LinkRenderer;
