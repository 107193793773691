import moment from 'moment';
import { message } from 'antd';

export const styleBackgroundReceiveJobTime = (record) => {
  try {
    //criteria
    let criteriaData = record.listCriteria[4];
    let min_criteria = criteriaData?.min ? criteriaData.min : 0;
    let value_criteria = criteriaData?.value ? criteriaData.value : 120;
    //date
    let re_job_time = record?.receive_job_datetime
      ? moment(record.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;
    let date_re_job = moment(re_job_time).format('YYYY-MM-DD HH:mm:ss');
    let dateNow = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    if (record?.callingsheet === true) {
      return;
    }
    if (
      moment(dateNow).diff(moment(date_re_job), 'minutes') <= min_criteria &&
      moment(dateNow).diff(moment(date_re_job), 'minutes') >= -value_criteria &&
      record.callingsheet === false
    ) {
      return { background: '#FBF54F', padding: 0 };
    } else if (
      moment(dateNow).diff(moment(date_re_job), 'minutes') >= min_criteria &&
      record.callingsheet === false
    ) {
      return { background: '#FA5050', padding: 0 };
    } else {
      return { padding: 0 };
    }
  } catch (error) {
    message.error('Something went wrong.');
  }
};

export const styleBackgroundActualReceiveJob = (record) => {
  try {
    //criteria for color
    let criteria_color = record.listCriteria[5];
    let min_color = criteria_color?.min ? criteria_color.min : 0;
    let value_color = criteria_color?.value ? criteria_color.value : 60;

    //date
    let re_job_time = record?.receive_job_datetime
      ? moment(record.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;
    let actual_re_job_time = record?.actual_receive_job_datetime
      ? moment(record.actual_receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;
    let dateNow = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    if (record?.callingsheet === true && record?.trailer_or_driver === true) {
      if (actual_re_job_time == null) {
        if (
          moment(dateNow).diff(moment(re_job_time), 'minutes') >= min_color &&
          moment(dateNow).diff(moment(re_job_time), 'minutes') <= value_color
        ) {
          return { background: '#FBF54F', padding: 0 };
        } else if (
          moment(dateNow).diff(moment(re_job_time), 'minutes') > value_color
        ) {
          return { background: '#FA5050', padding: 0 };
        } else {
          return { padding: 0 };
        }
      } else {
        if (
          moment(actual_re_job_time).diff(moment(re_job_time), 'minutes') >=
            min_color &&
          moment(actual_re_job_time).diff(moment(re_job_time), 'minutes') <=
            value_color
        ) {
          return { background: '#FBF54F', padding: 0 };
        } else if (
          moment(actual_re_job_time).diff(moment(re_job_time), 'minutes') >
          value_color
        ) {
          return { background: '#FA5050', padding: 0 };
        } else {
          return { padding: 0 };
        }
      }
    }
  } catch (error) {
    message.error('Something went wrong.');
  }
};

//style Actual Yard Out for table
export const styleBackgroundActualYardOut = (record) => {
  try {
    if (!record) return;
    //criteria for Warning
    let criteria_warning = record.listCriteria[7];
    let value_warning = criteria_warning?.value ? criteria_warning.value : 5;

    //criteria for symbol
    // let criteria_symbol = record.listCriteria[8];

    // master loading
    let master_loading = record.master_loading;
    //loading time
    let dateNow = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');
    // let dateNow = '2022-06-01 15:00:00'
    let re_job_time = record?.receive_job_datetime
      ? moment(record.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;
    let loading_time = moment(dateNow).diff(moment(re_job_time), 'minutes');
    //yardout_datetime
    // let yardout_datetime = record?.yardout_datetime
    //   ? moment(record.yardout_datetime).format('YYYY-MM-DD HH:mm:ss')
    //   : null;

    //actual_yardout_datetime
    let actual_yardout_datetime = record?.actual_yardout_datetime
      ? moment(record.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;

    //new

    let actual_re_job_time = record?.actual_receive_job_datetime
      ? moment(record.actual_receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;
    let actual_yard_out_time = record?.actual_yardout_datetime
      ? moment(record.actual_yardout_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;

    if (actual_yardout_datetime) {
      if (
        moment(actual_yard_out_time).diff(
          moment(actual_re_job_time),
          'minutes'
        ) > master_loading
      ) {
        return { background: '#FA5050', padding: 0 };
      } else {
        return { padding: 0 };
      }
    } else {
      if (loading_time - master_loading < 0) {
        return { padding: 0 };
      } else if (
        loading_time - master_loading >= 0 &&
        loading_time - master_loading <= value_warning
      ) {
        return { background: '#FBF54F', padding: 0 };
      } else {
        return { background: '#FA5050', padding: 0 };
      }
    }
  } catch (error) {
    message.error('Something went wrong.');
  }
};

export const styeDestination = (record) => {
  if (record?.edit_cluster_to === true) {
    return { fontWeight: '800', color: 'red' };
  }

  if (record?.actual_cluster_to?.code !== record?.cluster_to?.code) {
    return { fontWeight: '500', color: 'red' };
  }

  return {};
};

export const fomattingDestination = (record) => {
  try {
    if (record?.actual_cluster_to == null) {
      return '-';
    } else {
      return record?.actual_cluster_to?.code;
    }
  } catch (error) {
    message.error('Something went wrong.');
  }
};

export const styleBackgroundCalling = (record) => {
  try {
    //criteria
    let criteriaData = record.listCriteria[9];
    let min_criteria = criteriaData?.min ? criteriaData.min : 0;
    let value_criteria = criteriaData?.value ? criteriaData.value : 120;

    let re_job_time = record?.receive_job_datetime
      ? moment(record.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;
    let date_re_job = moment(re_job_time).format('YYYY-MM-DD HH:mm:ss');
    let dateActual = record?.actual_match_calling_datetime
      ? moment(record.actual_match_calling_datetime).format(
          'YYYY-MM-DD HH:mm:ss'
        )
      : moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    if (
      moment(dateActual).diff(moment(date_re_job), 'minutes') <= min_criteria &&
      moment(dateActual).diff(moment(date_re_job), 'minutes') >= -value_criteria
    ) {
      return { background: '#FBF54F', padding: 0 };
    } else if (
      moment(dateActual).diff(moment(date_re_job), 'minutes') >= min_criteria
    ) {
      return { background: '#FA5050', padding: 0 };
    } else {
      return { padding: 0 };
    }
  } catch (error) {
    message.error('Something went wrong.');
  }
};
export const styleBackgroundTrailerDriver = (record) => {
  try {
    //criteria
    let criteriaData = record?.listCriteria[10];
    let min_criteria = criteriaData?.min ? criteriaData?.min : 0;
    let value_criteria = criteriaData?.value ? criteriaData?.value : 60;

    let re_job_time = record?.receive_job_datetime
      ? moment(record.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
      : null;
    let date_re_job = moment(re_job_time).format('YYYY-MM-DD HH:mm:ss');
    let dateActual = record?.actual_confirm_tr_dv_datetime
      ? moment(record.actual_confirm_tr_dv_datetime).format('YYYY-MM-DD HH:mm:ss')
      : moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

    if (record.callingsheet === true) {
      if (
        moment(dateActual).diff(moment(date_re_job), 'minutes') <= min_criteria &&
        moment(dateActual).diff(moment(date_re_job), 'minutes') >= -value_criteria
      ) {
        return { background: '#FBF54F', padding: 0 };
      } else if (
        moment(dateActual).diff(moment(date_re_job), 'minutes') >= min_criteria
      ) {
        return { background: '#FA5050', padding: 0 };
      } else {
        return { padding: 0 };
      }
    } else {
      return { padding: 0 };
    }
  } catch (error) {
    message.error('Something went wrong.');
  }
};

export const styleBackgroundDN = (record) => {
  try {
    let value_dn = record?.d_or_n;

    if (value_dn === true) {
      return { padding: 0 };
    } else if (value_dn == null) {
      return { padding: 0 };
    } else {
      return { background: '#52c41a', padding: 0 };
    }
  } catch (error) {
    message.error('Something went wrong.');
  }
};
