import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Col,
  Row,
  Card,
  message,
  Typography,
  Space,
  Select,
  Radio,
} from 'antd';
import { useUpdateShuttleInShipmentTrackingMutation } from 'reducers/shuttleIn/api';
import styled from 'styled-components';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { useFindAllCriteriaQuery } from 'reducers/criteria/api';
import FormReason from './FormReason';
import { shuttleInApi } from 'reducers/shuttleIn/api';
import Table from './Table';
import AntTable from 'common_components/AntTable';
import { getCurrentUser } from 'reducers/user';
import { Sorter } from 'utils/sorter';
import AntModal from 'common_components/AntModal';
import { trailerApi } from 'reducers/masterData/trailer/api';

import { changeReasonLaneApi } from 'reducers/changeReasonLane/api';
import PrintKakudai from 'common_components/PrintDocument/PrintingKakudai/PrintKakudai';
import usePrint from 'hooks/usePrint';

const { Option } = Select;
const { Text } = Typography;
const ShuttleInFormComponent = (props) => {
  const [dataKakudai, setDataKakudai] = useState([]);
  // const dataKakudai = useRef();
  const printKakudaiComponentRef = useRef();
  const user = useSelector(getCurrentUser);
  const trailerInputRef = useRef(null);
  const statusDiff = useRef();
  const [formData, setFormData] = useState(null);
  const confirmRef = useRef([]);
  const confirmTruckScheduleRef = useRef([]);
  const trailerLaneOptionsRef = useRef();
  const dataVinSourceRef = useRef();
  const trailerAndDriverRef = useRef();
  const mapDriver1Ref = useRef();
  const mapDriver2Ref = useRef();
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const [isLoadingParkingLane, setIsLoadingParkingLane] = useState(false);
  const [radioShuttle, setRadioShuttle] = useState('shuttle_in');
  const [criteriaEarly, setCriteriaEarly] = useState(null);
  const [criteriaLate, setCriteriaLate] = useState(null);
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [trailerFrom, setTrailerFrom] = useState(null);
  const [callingNoFrom, setCallingNoFrom] = useState(null);
  const [driver1From, setDriver1From] = useState(null);
  const [driver2From, setDriver2From] = useState(null);
  const [selectedLane, setSelectedLane] = useState(null);
  const [saveShuttleOut, setSaveShuttleOut] = useState(true);
  const [selectedLaneTemp, setSelectedLaneTemp] = useState(null);
  const [shuttleInConfirm, setShuttleInConfirm] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [rowVinData, setRowVinData] = useState([]);
  const [isModalConfirmVisible, setIsModalConfirmVisible] = useState(false);
  const [reasonDelay, setReasonDelay] = useState(true);
  const [disabledShuttleIn, setDisabledShuttleIn] = useState(false);
  const apiFindCurrentTimeShuttleIn = async () => {
    let resData = null;
    try {
      let result = await dispatch(
        shuttleInApi.endpoints.findCurrentTimeShuttleIn.initiate(null, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        resData = result.error;
      } else {
        if (result.data) {
          resData = result.data;
        }
      }

      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };
  const {
    data: dataCriteria,
    error: errorCriteria,
    refetch: refetchCriteria,
  } = useFindAllCriteriaQuery();

  useEffect(() => {
    if (dataCriteria) {
      let arrEarly = dataCriteria.find((val) => val.code === 401);
      let arrLate = dataCriteria.find((val) => val.code === 402);
      setCriteriaEarly(arrEarly);
      setCriteriaLate(arrLate);
    }
  }, [dataCriteria]);

  useEffect(() => {
    if (errorCriteria) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load TrailerLane',
        okText: 'Retry',
        onOk: refetchCriteria,
        cancelText: 'Cancel',
      });
    }
  }, [errorCriteria, refetchCriteria]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [
    updateShipmentTracking,
    {
      isSuccess: isShipmentTrackingSuccessUpdating,
      isUninitialized: isShipmentTrackingUninitUpdating,
      error: errorShipmentTrackingUpdating,
    },
  ] = useUpdateShuttleInShipmentTrackingMutation();

  useEffect(() => {
    if (errorShipmentTrackingUpdating) {
      setErrorData(errorShipmentTrackingUpdating);
    }
  }, [errorShipmentTrackingUpdating, setErrorData]);

  useEffect(() => {}, [
    isShipmentTrackingSuccessUpdating,
    isShipmentTrackingUninitUpdating,
  ]);

  const getParkingLane = async () => {
    setIsLoadingParkingLane(true);
    let resData = null;
    let result = await dispatch(
      shuttleInApi.endpoints.findParkingsByUser.initiate(null, {
        forceRefetch: true,
      })
    );

    if (result && result.error) {
      setErrorData(result.error);
    } else {
      if (result.data) {
        let mapData = result.data.map((d) => ({
          id: d.id,
          label: d.lane_no,
          value: d.id,
          amount: d.amount,
          used: d.used,
        }));

        resData = Sorter.DEFAULTLIST(mapData, 'label');
      }
    }
    setIsLoadingParkingLane(false);
    return resData;
  };

  const clearAll = () => {
    setIsLoadingParkingLane(false);
    setTrailerFrom(null);
    setCallingNoFrom(null);
    setDriver1From(null);
    setDriver2From(null);
    setSelectedLane(null);
    setSelectedLaneTemp(null);
    setShuttleInConfirm(null);
    setIsModalVisible(false);
    setReasonDelay(true);
    setRowData([]);
    setRowVinData([]);
    confirmRef.current = null;
    setSaveShuttleOut(true);
    if (radioShuttle === 'shuttle_in') {
      setRadioShuttle('shuttle_in');
      form.resetFields();
      form.setFieldsValue({
        radioShuttle: 'shuttle_in',
      });
    } else {
      setRadioShuttle('shuttle_out');
      form.resetFields();
      form.setFieldsValue({
        radioShuttle: 'shuttle_out',
      });
    }
  };

  const onBtnCheck = async (values) => {
    try {
      setCallingNoFrom(null);
      setTrailerFrom(null);
      setDriver1From(null);
      setDriver2From(null);

      if (radioShuttle === 'shuttle_out') {
        setSaveShuttleOut(true);
        let arrTrailer = await getTrailer(values?.trailer?.trim());
        if (values.trailer) {
          if (arrTrailer) {
            setTrailerFrom('success');
          } else {
            setTrailerFrom('error');
          }
        } else {
          setTrailerFrom('blank');
        }

        setCallingNoFrom('success');
        let body = {
          trailer_id: arrTrailer.id,
          calling_no: values?.calling_no,
        };

        if (!arrTrailer) {
          msgError();
        } else {
          await getSearchShuttleOut(body);
        }

        if (values.trailer && values.calling_no) {
          setIsLoading(true);
        } else {
          msgError();
          setRowData([]);
          setRowVinData([]);
        }
      } else {
        setDisabledShuttleIn(false);
        setSelectedLaneTemp(null);
        setReasonDelay(true);
        setRowData([]);
        setRowVinData([]);
        let arrTrailer = await getTrailer(values.trailer);
        if (values.trailer) {
          if (arrTrailer) {
            setTrailerFrom('success');
          } else {
            setTrailerFrom('error');
          }
        } else {
          setTrailerFrom('blank');
        }
        // Skip checking calling no for shuttle in
        // let arrCallingNo = await getCalling(values.calling_no);
        // if (values.calling_no) {
        //   if (arrCallingNo) {
        //     setCallingNoFrom('success');
        //   } else {
        //     setCallingNoFrom('error');
        //   }
        // } else {
        //   setCallingNoFrom('blank');
        // }
        let driver = [values.driver_1, values.driver_2];
        let arrDriver1 = null;
        let arrDriver2 = null;
        if (values.driver_1) {
          arrDriver1 = await getDriver1(values.driver_1);
          if (
            arrDriver1 &&
            driver.filter((v) => v === values.driver_1).length === 1
          ) {
            setDriver1From('success');
          } else {
            if (!arrDriver1) {
              setDriver1From('error');
            } else {
              if (
                values.driver_1 &&
                driver.filter((v) => v === values.driver_1).length > 1
              ) {
                setDriver1From('error');
              } else {
                setDriver1From('blank');
              }
            }
          }
        } else {
          setDriver1From('blank');
        }

        if (values.driver_2) {
          arrDriver2 = await getDriver2(values.driver_2);
          if (
            arrDriver2 &&
            driver.filter((v) => v === values.driver_2).length === 1
          ) {
            setDriver2From('success');
          } else {
            if (!arrDriver2) {
              setDriver2From('error');
            } else {
              if (
                values.driver_2 &&
                driver.filter((v) => v === values.driver_2).length > 1
              ) {
                setDriver2From('error');
              } else {
                setDriver2From('blank');
              }
            }
          }
        } else {
          setDriver2From('blank');
        }

        let trailerAndDriver = null;
        let driver_list = [
          arrDriver1 && arrDriver1.id,
          arrDriver2 && arrDriver2.id,
        ];
        driver_list = driver_list.filter(function (element) {
          return element !== null;
        });

        if (values.trailer && values.driver1_name && values.calling_no) {
          trailerAndDriver = {
            trailer_id: arrTrailer && arrTrailer.id,
            driver_id: driver_list,
            calling_no: values.calling_no && values.calling_no.trim(),
          };
        }

        trailerAndDriverRef.current = trailerAndDriver;

        let findDuplicates = (arr) =>
          arr.filter((item, index) => arr.indexOf(item) !== index);
        let drivers = [values.driver_1, values.driver_2];
        let dupDrivers = [...new Set(findDuplicates(drivers))];
        let search = null;

        if (dupDrivers[0]) {
          message.error('Drivers is duplicated');
        } else {
          if (!arrTrailer || !arrDriver1) {
            msgError();
          } else {
            search = await getSearchMatch(trailerAndDriver);
          }
        }
        let arrSearch = [];
        Object.entries(search).forEach(([key, value]) => {
          arrSearch.push({ vin: key, status: value });
        });

        if (
          (values.trailer && values.driver1_name && values.calling_no) ||
          (values.trailer && values.driver2_name && values.calling_no) ||
          (values.trailer &&
            values.driver1_name &&
            values.driver2_name &&
            values.calling_no)
        ) {
          setIsLoading(true);
        } else {
          msgError();
          setRowData([]);
          setRowVinData([]);
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };

  const msgError = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>Please check the information again.</h4>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };
  const msgErrorBackend = (value) => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>{value}</h4>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };
  const msgErrorOwner = () => {
    Modal.error({
      title: 'Error!!',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <h4>
            Please completed Yard Out process or Please check whether your owner
            job or not.
          </h4>
        </>
      ),
      okText: 'OK',
      width: 500,
    });
  };

  const msgConfirm = async () => {
    if (radioShuttle === 'shuttle_out') {
      setDisabledShuttleIn(true);
      setIsModalConfirmVisible(false);
      var nowServer = await apiFindCurrentTimeShuttleIn();
      var then = rowData[0].plan_arrival_time;
      let timeDiff = timeDiffCalc(nowServer, then);

      let arrData = [
        {
          ...rowData[0],
          actual_shullte_In_datetime: nowServer,
          diff: timeDiff?.difference,
        },
      ];
      setRowData(arrData);
      setDisabledShuttleIn(false);
      setSaveShuttleOut(false);
    } else {
      let arrConfirm = await getPlanArrivalTime(trailerAndDriverRef.current);
      let body = { calling_no: rowData[0]?.calling_no?.callingsheet_no };
      let trailerLaneOptions = await onQueryCalling(
        rowData.length > 0 ? body : null
      );
      trailerLaneOptionsRef.current = trailerLaneOptions;

      if (arrConfirm) {
        let resData = [
          {
            ...rowData[0],
            trailerLaneOptions: [...trailerLaneOptions],
            truck_schedule_id: arrConfirm && arrConfirm.truck_schedule?.id,
            plan_arrival_time:
              arrConfirm && arrConfirm.truck_schedule?.plan_arrival_time,
          },
        ];
        setRowData(resData);
        confirmRef.current = arrConfirm && arrConfirm.confirm_shuttle_in;
        confirmTruckScheduleRef.current =
          arrConfirm && arrConfirm.truck_schedule;
        setIsModalConfirmVisible(true);
        setDisabledShuttleIn(true);
      }
    }
  };

  function timeDiffCalc(dateNow, dateFuture) {
    let diffInMilliSeconds = 0;
    if (dateFuture) {
      diffInMilliSeconds =
        Math.abs(moment(dateNow).diff(moment(dateFuture))) / 1000;
    }
    // calculate days
    const days = Math.floor(diffInMilliSeconds / 86400);
    diffInMilliSeconds -= days * 86400;
    diffInMilliSeconds -= days * 24;
    // calculate hours
    let hours = Math.floor(diffInMilliSeconds / 3600) % 24;
    diffInMilliSeconds -= hours * 3600;
    hours += days * 24;
    // calculate minutes
    let minutes = Math.floor(diffInMilliSeconds / 60) % 60;
    diffInMilliSeconds -= minutes * 60;

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    let difference = '';
    let differenceReason = '';

    difference += hours === 0 || hours === 1 ? `${hours}:` : `${hours}:`;

    difference += minutes === 0 || hours === 1 ? `${minutes}` : `${minutes}`;
    if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') >=
        criteriaLate.dateNow &&
      moment(dateNow).diff(moment(dateFuture), 'minutes') <= criteriaEarly.value
    ) {
      setIsModalVisible(false);
    } else if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') > criteriaLate.value
    ) {
      differenceReason = `+ ` + difference;
      statusDiff.current = 'late';
      if (radioShuttle === 'shuttle_in') {
        setIsModalVisible(true);
      }
    } else if (
      moment(dateNow).diff(moment(dateFuture), 'minutes') < criteriaEarly.value
    ) {
      differenceReason = `- ` + difference;
      statusDiff.current = 'early';
      if (radioShuttle === 'shuttle_in') {
        setIsModalVisible(true);
      }
    }

    if (moment(dateNow).isSameOrAfter(moment(dateFuture), 'minutes')) {
      difference = `+ ` + difference;
    } else {
      difference = `- ` + difference;
    }

    let objDif = {
      differenceReason: differenceReason,
      difference: difference,
    };
    return objDif;
  }

  const handleConfirmAction = async () => {
    setDisabledShuttleIn(true);
    setIsModalConfirmVisible(false);
    var nowServer = await apiFindCurrentTimeShuttleIn();
    var then = rowData[0].plan_arrival_time;
    let timeDiff = timeDiffCalc(nowServer, then);
    let reasonLabelText = {
      label: '-',
    };
    let reasonLabelDelay = {
      label: 'delay',
    };

    setReasonDelay(
      timeDiff?.differenceReason.includes('-') ||
        timeDiff?.differenceReason.includes('+')
        ? true
        : false
    );

    let arrData = [
      {
        ...rowData[0],
        actual_shullte_In_datetime: nowServer,
        diff: timeDiff?.difference,
        reason:
          timeDiff?.differenceReason.includes('-') ||
          timeDiff?.differenceReason.includes('+')
            ? reasonLabelDelay
            : reasonLabelText,
      },
    ];
    setRowData(arrData);
  };

  const handleEnter = (event, name) => {
    if (event.keyCode === 13) {
      if (name) {
        onChangeTrailerAndDriver(event, name);
      }
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();

      if (event.target.id === 'basic_calling_no') {
        document.getElementById('check').focus();
        event.preventDefault();
      }
    } else if (event.code === 'Tab' && event.target.id === 'basic_calling_no') {
      document.getElementById('check').focus();
      event.preventDefault();
    }
  };

  const onChangeTrailerAndDriver = async (e, label) => {
    try {
      let driver1_name = null;
      let driver2_name = null;
      if (label === 'trailer') {
        await getTrailer(e.target.value);
      }

      if (label === 'driver_1') {
        driver1_name = await getDriver1(e.target.value, label);

        form.setFieldsValue({
          driver1_name: driver1_name && driver1_name,
        });
      }
      if (label === 'driver_2') {
        driver2_name = await getDriver2(e.target.value, label);
        form.setFieldsValue({
          driver2_name: driver2_name && driver2_name,
        });
      }
    } catch (err) {
      message.error('Something went wrong');
    }
  };

  const getTrailer = async (value) => {
    let resData = null;
    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.findShuttleInTrailerbyCardId.initiate(
          value.trim(),
          {
            forceRefetch: true,
          }
        )
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data && result.data.length > 0) {
          const trailer = result.data[0];
          resData = trailer;
        }
      }
    }
    return resData;
  };
  const getCalling = async (value) => {
    let resData = null;
    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.findCallingsheetsNewId.initiate(value.trim(), {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data && result.data.length > 0) {
          const calling = result.data[0];
          resData = calling;
        }
      }
    }
    return resData;
  };
  const getDriver1 = async (value, label) => {
    let resData = null;
    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.findShuttleInDriverbyCode.initiate(
          value.trim(),
          {
            forceRefetch: true,
          }
        )
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data && result.data.length > 0) {
          let driver = null;
          driver = result.data[0];
          let mapDriver1 = {
            id: driver.id,
            tlep_code: driver.tlep_code,
          };
          mapDriver1Ref.current = mapDriver1;
          if (label) {
            resData = driver?.driver_name_th;
          } else {
            resData = mapDriver1;
          }
        }
      }
    }
    return resData;
  };
  const getDriver2 = async (value, label) => {
    let resData = null;
    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.findShuttleInDriverbyCode.initiate(
          value.trim(),
          {
            forceRefetch: true,
          }
        )
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data && result.data.length > 0) {
          const driver = result.data[0];
          let mapDriver2 = {
            id: driver.id,
            tlep_code: driver.tlep_code,
          };
          mapDriver2Ref.current = mapDriver2;

          if (label) {
            resData = driver?.driver_name_th;
          } else {
            resData = mapDriver2;
          }
        }
      }
    }
    return resData;
  };

  const onReset = () => {
    setDisabledShuttleIn(false);
    setReasonDelay(true);
    form.resetFields();
    setRowData([]);
    setRowVinData([]);
    setTrailerFrom('blank');
    setDriver1From('blank');
    setDriver2From('blank');
    setTimeout(() => {
      if (trailerInputRef.current) {
        trailerInputRef.current.focus();
      }
    }, 100);
  };

  const apiFindLaneByCode = async () => {
    let resData = null;
    try {
      let result = await dispatch(
        trailerApi.endpoints.findTrailerLaneByUser.initiate(null, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        resData = result.error;
      } else {
        if (result.data) {
          let mapData = result.data.map((d) => ({
            id: d.id,
            label: d.lane_no,
            value: d.lane_no,
            yard: d.yard.id,
            amount: d.amount,
            used: d.used,
            lane_no: d.lane_no,
            is_available: d.is_available,
          }));
          resData = mapData;
        }
      }

      return resData;
    } catch (err) {
      console.log('err', err);
    }
  };

  const onQueryCalling = async (value) => {
    let resData = null;
    try {
      if (value) {
        let result = await dispatch(
          changeReasonLaneApi.endpoints.findStatusChangeByCallingNo.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
        } else {
          if (result.data) {
            let callingLane = null;
            if (
              (result.data.reason_and_lane?.actual_receive_job_datetime &&
                result.data.reason_and_lane?.actual_yardout_datetime) ||
              result.data.reason_and_lane?.realtime_tracking_id
            ) {
              callingLane = await apiFindLaneByCode();
            }

            resData = callingLane ? [...callingLane] : null;
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      return resData;
    }
  };

  const getSearchMatch = async (value) => {
    let newData = [];

    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.findCheckMatch.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setDisabledShuttleIn(false);
        setReasonDelay(true);
        setRowData([]);
        setRowVinData([]);

        if (
          result.error.data.errorList[0].errorDetail ===
          'This job is not your owner.'
        ) {
          msgErrorOwner();
        } else {
          setCallingNoFrom('error');
          msgErrorBackend(result.error.data.errorList[0].errorDetail);
        }
      } else {
        if (result.data) {
          newData = result.data.vins;
          setCallingNoFrom('success');
          setShuttleInConfirm(value);

          let rowData = [
            {
              calling_no: result.data.calling,
            },
          ];

          let vins = [];
          let res = await getParkingLane();
          for (let index = 0; index < result.data.vins.length; index++) {
            vins.push({
              ...result.data.vins[index],
              parkingOptions: [...res],
            });
          }
          setRowData(rowData);
          setRowVinData(vins);
        } else {
          msgError();
        }
      }
    }
    return newData;
  };
  const getSearchShuttleOut = async (value) => {
    let newData = [];

    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.findCheckMatchShuttleOut.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setDisabledShuttleIn(false);
        setReasonDelay(true);
        setRowData([]);
        setRowVinData([]);

        if (
          result.error.data.errorList[0].errorDetail ===
          'This job is not your owner.'
        ) {
          msgErrorOwner();
        } else {
          msgErrorBackend(result.error.data.errorList[0].errorDetail);
        }
      } else {
        if (result.data) {
          let rowData = [
            {
              ...result.data,
              calling_no: result.data.calling,
              plan_arrival_time: result.data?.actual_arrival_time,
              lane_no: result.data?.trailer_lane?.lane_no,
            },
          ];

          setRowData(rowData);
        } else {
          msgError();
        }
      }
    }
    return newData;
  };
  const getPlanArrivalTime = async (value) => {
    let newData = null;

    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.findPlanArrivalTime.initiate(value, {
          forceRefetch: true,
        })
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          newData = result.data;
        }
      }
    }
    return newData;
  };

  const [columnsPopupConfirm] = useState(() => {
    let cols = [
      {
        title: 'From',
        dataIndex: 'from_short_name',
        width: 100,
        align: 'center',
      },
      {
        title: 'To',
        dataIndex: 'to_short_name',
        width: 60,
        align: 'center',
      },
      {
        title: 'Calling No',
        dataIndex: 'callingsheet_no',
        width: 100,
        align: 'center',
      },
    ];
    return cols;
  });

  const postReason = (value) => {
    let reasonLabel = {
      label:
        value && value.description
          ? value.description
          : value.subject.substring(1, 4),
      value: value,
    };

    let newData = [];
    for (let index = 0; index < rowData.length; index++) {
      newData = [
        {
          ...rowData[index],
          reason: reasonLabel,
          // trailerLaneOptions: [...trailerLaneOptionsRef.current],
        },
      ];
    }
    setReasonDelay(false);
    var res = rowData.map((obj) => newData.find((o) => o.id === obj.id) || obj);
    setRowData(res);
  };

  const editReason = (value) => {
    setIsModalVisible(true);
    setFormData(value.reason.value);
  };

  const createShuttleIn = async (value) => {
    let resData = null;
    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.createShuttleIn.initiate(value, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        message.error(result.error.data.errorList[0].errorDetail);
      } else {
        if (result.data) {
          message.success('Data was successfully saved');
          resData = result.data;
        }
      }
    }
    return resData;
  };

  const createShuttleOut = async (value) => {
    let resData = null;
    if (value) {
      let result = await dispatch(
        shuttleInApi.endpoints.updateShuttleOut.initiate(value, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        message.error(result.error.data.errorList[0].errorDetail);
      } else {
        if (result.data) {
          message.success('Data was successfully saved');
          clearAll();

          resData = result.data;
        }
      }
    }
    return resData;
  };

  const convertVinToDisplay = (result_res) => {
    return result_res.map((d, idx) => ({
      vin_no: d?.vin_no ? d?.vin_no : '',
      model_code: d?.model_code ? d?.model_code : '',
      series: d?.series ? d?.series : '',
      color: d?.color ? d?.color : '',
      vehicle_type: d?.vehicle_type?.name ? d?.vehicle_type?.name : '',
      vehicle_type_id: d?.vehicle_type?.id ? d?.vehicle_type?.id : '',
      cluster_kakudai: d?.location_destination?.cluster?.name || '',
      location_from: d?.location_from?.short_name
        ? d?.location_from?.short_name
        : '',
      id: d?.id ? d?.id : idx + 1,
      location_destination_kakudai: d?.location_destination?.name || '',
    }));
  };

  const handleConfirmSaveAction = async () => {
    if (radioShuttle === 'shuttle_out') {
      let data = rowData[0];
      let postData = {
        id: data.id,
        shuttle_out_at: moment(data?.actual_shullte_In_datetime).format(),
      };
      let response = await createShuttleOut(postData);
    } else {
      let data = rowData[0];
      let arrDriver = [];
      let drivers = [mapDriver1Ref.current, mapDriver2Ref.current];
      if (drivers.length > 1 && drivers && drivers[1] !== undefined) {
        arrDriver = {
          driver_id: [drivers && drivers[0]?.id, drivers && drivers[1]?.id],
        };
      } else {
        arrDriver = {
          driver_id: drivers && drivers[0]?.id,
        };
      }

      let arrVin = rowVinData.filter(
        (vin) => vin.vehicle_type?.name === 'Locator'
      );

      let arrVinData = rowVinData.map((v) => ({
        id: v.id,
        vin_no: v.vin_no,
        parking_id: v.parking_id,
      }));

      let postData = {
        calling_no: data.calling_no.callingsheet_no,
        truck_schedule_id: data.truck_schedule_id,
        trailer_id: shuttleInConfirm.trailer_id,
        ...arrDriver,
        plan_arrival_time: moment(data.plan_arrival_time).format(),

        actual_arrival_time: moment(data.actual_shullte_In_datetime).format(),
        reason: data.reason && data.reason.value && data.reason.value.id,
        reason_description:
          data.reason && data.reason.value && data.reason.value.description,
        trailer_lane: selectedLane,
        vins: arrVinData,
      };

      if (arrVin.length > 0) {
        Modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: 'Would you like to print Kakudai?',
          onOk: async () => {
            setDataKakudai(convertVinToDisplay(arrVin));
            getDataKakudai(arrVin);
            let response = await createShuttleIn(postData);
            if (response) {
              let body = {
                dispatch_board_id: response.dispatch_board_id,
                arrival_time: moment(response.actual_arrival_time).format(),
              };
              updateShipmentTracking(body);
            }
            clearAll();
            Modal.destroyAll();
          },
          onCancel: async () => {
            let response = await createShuttleIn(postData);
            if (response) {
              let body = {
                dispatch_board_id: response.dispatch_board_id,
                arrival_time: moment(response.actual_arrival_time).format(),
              };
              updateShipmentTracking(body);
            }
            clearAll();
            Modal.destroyAll();
          },
        });
      } else {
        let response = await createShuttleIn(postData);
        if (response) {
          let body = {
            dispatch_board_id: response.dispatch_board_id,
            arrival_time: moment(response.actual_arrival_time).format(),
          };
          updateShipmentTracking(body);
        }
        clearAll();
      }
    }
  };

  const btnSave = () => {
    if (rowData) {
      let data = rowData[0];
      if (data.actual_shullte_In_datetime) {
        Modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content:
            radioShuttle === 'shuttle_in'
              ? 'Would you like to confirm for saving the Shuttle In?'
              : 'Would you like to confirm for saving the Shuttle Out?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          width: 500,
          onOk: handleConfirmSaveAction,
        });
      }
    }
  };

  const onSelectedLane = (lane) => {
    setSelectedLane(lane);
    setSelectedLaneTemp(lane);
    setDisabledShuttleIn(false);
  };

  useEffect(() => {
    dataVinSourceRef.current = rowVinData;
  }, [rowVinData]);

  function changeParkingLane(e, record, value) {
    let dataSoureRef = dataVinSourceRef.current;
    let resultData = {
      ...record,
      parking_lane: value?.children,
      parking_id: value.value,
    };
    for (var i in dataSoureRef) {
      if (dataSoureRef[i].vin_no === resultData.vin_no) {
        dataSoureRef[i] = resultData;
        break; //Stop this loop, we found it!
      }
    }
    setRowVinData(dataSoureRef);
  }
  const columnsVin = [
    {
      title: 'Vin No.',
      dataIndex: 'vin_no',
      width: 100,
      align: 'center',
    },
    {
      title: 'Lane',
      dataIndex: 'parkingOptions',
      width: 80,
      align: 'center',
      render: (text, record) => {
        return (
          <Select
            style={{
              width: '100px',
            }}
            showSearch
            placeholder={'select'}
            optionFilterProp='children'
            onSelect={(e, value) => {
              changeParkingLane(e, record, value);
            }}
          >
            {text &&
              text.map((c, index) => (
                <Option key={index} value={c.id}>
                  {c.label}
                </Option>
              ))}
          </Select>
        );
      },
    },
  ];

  const contentVin = () => {
    return (
      <AntTable
        rowKey='id'
        tableId={'confirm_driver_trailer_calling'}
        showSorterTooltip={false}
        columns={columnsVin}
        loading={isLoadingParkingLane}
        dataSource={rowVinData}
        pagination={false}
        width={750}
      />
    );
  };

  // functon
  const [printData, print] = usePrint('Kakudai', dataKakudai);
  //print PDF
  const getDataKakudai = (arr) => {
    if (arr.length !== 0) {
      handlePrintKakudai();
    }
  };

  const handlePrintKakudai = useCallback(() => {
    setTimeout(() => {
      print(printKakudaiComponentRef.current, 'print-kakudai');
    }, 500);
  }, [print]);

  return (
    <div>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{ padding: '20px' }}
        onFinish={onBtnCheck}
        autoComplete='off'
      >
        <CardStyleForm>
          <Row>
            <Col span={21}>
              <Row>
                <Col span={20}>
                  <Form.Item
                    name='radioShuttle'
                    wrapperCol={{ offset: 2 }}
                    style={{ marginBottom: '14px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please Select',
                      },
                    ]}
                    initialValue={'shuttle_in'}
                  >
                    <Radio.Group
                      onChange={(value) => {
                        setRadioShuttle(value.target.value);
                        setRowData([]);
                        setSaveShuttleOut(true);
                      }}
                    >
                      <Space direction='horizontal'>
                        <Radio value='shuttle_in'>Shuttle In</Radio>
                        <Radio value='shuttle_out'>Shuttle Out</Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    labelCol={{
                      span: 7,
                    }}
                    wrapperCol={{
                      span: 15,
                    }}
                    label='Trailer'
                    name='trailer'
                    onKeyDown={(e) => {
                      handleEnter(e);
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input trailer',
                      },
                    ]}
                  >
                    <Input
                      placeholder={'(for scan)'}
                      ref={trailerInputRef}
                      style={{
                        backgroundColor:
                          trailerFrom === 'success'
                            ? '#1BD39C'
                            : trailerFrom === 'error'
                            ? '#FF6767'
                            : '',
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {radioShuttle === 'shuttle_in' ? (
                    <Form.Item
                      label='Driver 1'
                      name='driver_1'
                      onKeyDown={(e) => {
                        handleEnter(e, 'driver_1');
                      }}
                      rules={[
                        {
                          required: true,
                          message: 'Please input driver 1',
                        },
                      ]}
                    >
                      <Input
                        onChange={(e) => {
                          onChangeTrailerAndDriver(e, 'driver_1');
                        }}
                        placeholder={'(for scan)'}
                        style={{
                          backgroundColor:
                            driver1From === 'success'
                              ? '#1BD39C'
                              : driver1From === 'error'
                              ? '#FF6767'
                              : '',
                        }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
                <Col span={8}>
                  {radioShuttle === 'shuttle_in' ? (
                    <Form.Item
                      label='Driver 2'
                      name='driver_2'
                      onKeyDown={(e) => {
                        handleEnter(e, 'driver_2');
                      }}
                    >
                      <Input
                        onChange={(e) => {
                          onChangeTrailerAndDriver(e, 'driver_2');
                        }}
                        placeholder={'(for scan)'}
                        style={{
                          backgroundColor:
                            driver2From === 'success'
                              ? '#1BD39C'
                              : driver2From === 'error'
                              ? '#FF6767'
                              : '',
                        }}
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  <Form.Item
                    labelCol={{
                      span: 7,
                    }}
                    wrapperCol={{
                      span: 15,
                    }}
                    label='Calling No.'
                    name='calling_no'
                    onKeyDown={(e) => {
                      handleEnter(e);
                    }}
                    rules={[
                      {
                        required: true,
                        message: 'Please input calling no.',
                      },
                    ]}
                  >
                    <Input
                      placeholder={'(for scan)'}
                      style={{
                        backgroundColor:
                          callingNoFrom === 'success'
                            ? '#1BD39C'
                            : callingNoFrom === 'error'
                            ? '#FF6767'
                            : '',
                      }}
                    />
                    {/* )} */}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {radioShuttle === 'shuttle_in' ? (
                    <Form.Item label='Driver 1 name' name='driver1_name'>
                      <Input
                        style={{
                          color: '#000',
                          backgroundColor: 'transparent',
                        }}
                        placeholder={'(auto fill)'}
                        disabled
                      />
                    </Form.Item>
                  ) : null}
                </Col>
                <Col span={8}>
                  {radioShuttle === 'shuttle_in' ? (
                    <Form.Item label='Driver 2 name' name='driver2_name'>
                      <Input
                        style={{
                          color: '#000',
                          backgroundColor: 'transparent',
                        }}
                        placeholder={'(auto fill)'}
                        disabled
                      />
                    </Form.Item>
                  ) : null}
                </Col>
              </Row>
            </Col>
            <Col span={3}>
              <Col style={{ marginTop: 72 }} offset={9}>
                <Form.Item shouldUpdate>
                  {() => (
                    <Space size={25} style={{ marginTop: '30px' }}>
                      <Button style={{ width: 100 }} onClick={clearAll}>
                        Clear all
                      </Button>
                    </Space>
                  )}
                </Form.Item>
              </Col>
            </Col>
          </Row>
        </CardStyleForm>
        <Row style={{ marginTop: 15 }} gutter={15}>
          {radioShuttle === 'shuttle_in' ? (
            <Col span={7}>
              <Card>{contentVin()}</Card>
            </Col>
          ) : null}
          <Col span={radioShuttle === 'shuttle_in' ? 17 : 24}>
            <Card>
              <Row align='end' style={{ margin: 10, marginBottom: 15 }}>
                <Col>
                  <Space size={25}>
                    <Button
                      id={'check'}
                      style={{ width: 100 }}
                      type='primary'
                      htmlType='submit'
                      loading={isLoading || isLoadingParkingLane}
                    >
                      Check
                    </Button>
                    <Button
                      style={{ width: 100 }}
                      disabled={
                        !rowData.length ||
                        disabledShuttleIn ||
                        (!disabledShuttleIn && selectedLaneTemp)
                      }
                      type='primary'
                      onClick={() => msgConfirm()}
                    >
                      {radioShuttle === 'shuttle_in'
                        ? 'Shuttle In'
                        : 'Shuttle Out'}
                    </Button>

                    <Button
                      style={{ width: 100 }}
                      disabled={
                        radioShuttle === 'shuttle_in'
                          ? reasonDelay || !selectedLane
                          : saveShuttleOut
                      }
                      type='secondary'
                      onClick={() => btnSave()}
                    >
                      Save
                    </Button>
                  </Space>
                </Col>
              </Row>

              <Table
                isLoadingParkingLane={isLoadingParkingLane}
                rowData={rowData}
                editReason={editReason}
                selectedLane={onSelectedLane}
                radioShuttle={radioShuttle}
              />
            </Card>

            <FormReason
              formData={formData}
              showModal={isModalVisible}
              setIsModalVisible={handleCancel}
              postReason={postReason}
              statusDiff={statusDiff.current}
            />

            <AntModal
              visible={isModalConfirmVisible}
              onCancel={() => {
                setIsModalConfirmVisible(false);
                setDisabledShuttleIn(false);
              }}
              onOk={() => handleConfirmAction()}
              titleModal={<Text>Confirm Shuttle In</Text>}
              centered
              width={600}
              style={{ textAlign: 'center' }}
              maskClosable={false}
            >
              {confirmRef.current && (
                <>
                  <AntTable
                    rowKey={(record) => record.id}
                    tableId={'shullte_In_confirm'}
                    bordered
                    showSorterTooltip={false}
                    columns={columnsPopupConfirm}
                    dataSource={confirmRef.current}
                    pagination={false}
                    width={450}
                  />
                  <br />
                  {confirmTruckScheduleRef.current &&
                  confirmTruckScheduleRef.current &&
                  confirmTruckScheduleRef.current.case === 'combine_2' ? (
                    <Row gutter={[24]} align='center' justify='center'>
                      <Col style={{ textAlign: 'left' }}>
                        Calling combine :{' '}
                        {confirmTruckScheduleRef.current &&
                        confirmTruckScheduleRef.current.calling_combine
                          ? confirmTruckScheduleRef.current.calling_combine.map(
                              (val, index) => <div key={index}>{val}</div>
                            )
                          : null}
                        <br />
                        From:{' '}
                        {confirmTruckScheduleRef.current &&
                        confirmTruckScheduleRef.current.from_combine
                          ? confirmTruckScheduleRef.current &&
                            confirmTruckScheduleRef.current.from_combine.map(
                              (val) => {
                                return val;
                              }
                            )
                          : null}
                        <br />
                        Cluster:{' '}
                        {confirmTruckScheduleRef.current &&
                        confirmTruckScheduleRef.current.to_combine
                          ? confirmTruckScheduleRef.current &&
                            confirmTruckScheduleRef.current.to_combine.map(
                              (val) => {
                                return val;
                              }
                            )
                          : null}
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </>
              )}
            </AntModal>
          </Col>
        </Row>
      </Form>
      <PrintKakudai
        componentRef={printKakudaiComponentRef}
        KakudaiData={printData}
      ></PrintKakudai>
    </div>
  );
};
const CardStyleForm = styled(Card)`
  .ant-card-body {
    padding-bottom: 0px;
  }
`;

export default ShuttleInFormComponent;
