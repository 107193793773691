import React, { useState } from 'react';
import AntTable from 'common_components/AntTable';
import './index.css';
import {
  Row,
  Col,
  Button,
  Select,
  Modal,
  Card,
  Progress,
} from 'antd';
import styled from 'styled-components';
const { Option } = Select;
const StatusComponent = (props) => {
  const {
    filterCompanyBZP,
    setFilterCompanyBZP,
    filterCompanyOptionBZP,
    valueStatusList,
    valueTotalList,
    callMatchJobcode,
    isLoadingMatch,
    dataInitDisabled,
    callNotifySubmit,
    dataInitRef,
    callDataNotifyBzpCheck,
    isLoadingOpenToBZP,
    isLoadingReConfirmToBzP,
    progress,
    isStatusCreateLot,
    setIsStatusCreateLot,
    dataManageTourListStatus,
    isLoadingStatusTotal,
  } = props;
  // const [isStatusCreateLot, setIsStatusCreateLot] = useState(false);

  const [columnsStatus, setColumnsStatus] = useState(() => {
    let cols = [
      {
        title: 'Status',
        dataIndex: 'status',
        align: 'center',
        className: 'color-head-1',
        children: [
          {
            title: 'Blank',
            dataIndex: 'blank',
            className: 'bg-white',
            align: 'center',
            width: 50,
          },
          {
            title: 'Accept',
            dataIndex: 'accept',
            className: 'bg-white',
            align: 'center',
            width: 50,
          },
          {
            title: 'Request',
            dataIndex: 'request',
            className: 'bg-white',
            align: 'center',
            width: 50,
          },
          {
            title: 'Reject',
            dataIndex: 'reject',
            className: 'bg-white',
            align: 'center',
            width: 50,
          },
        ],
      },

      {
        title: 'Total',
        dataIndex: 'total',
        width: 50,
        render: (text, record) => {
          return text;
        },

        align: 'center',
      },
    ];

    return cols;
  });

  const [columnsTotalRecord, setColumnsTotalRecord] = useState(() => {
    let cols = [
      {
        title: 'Total Record',
        dataIndex: 'total_record',
        align: 'center',
        render(text) {
          return (
            <div style={{ height: '48px', paddingTop: '15px' }}>{text}</div>
          );
        },
        width: 50,
        className: 'color-head-6',
      },
      {
        title: 'Not match BzP',
        dataIndex: 'not_match_bzp',
        className: 'color-head-6',
        width: 50,
        render(text) {
          return (
            <div style={{ height: '48px', paddingTop: '15px' }}>{text}</div>
          );
        },

        align: 'center',
      },
    ];

    return cols;
  });

  const onMatchJobcode = async () => {
    Modal.confirm({
      title: 'คุณต้องการ Match Job code หรือไม่',
      onOk() {
        callMatchJobcode();
        setIsStatusCreateLot(true);
      },
    });
  };

  //modal
  const modalOpenToBZP = async () => {
    Modal.confirm({
      title: 'คุณต้องการ Open to BzP หรือไม่',
      onOk() {
        callNotifySubmit();
      },
    });
  };
  const modalReConfirmBZP = async () => {
    callDataNotifyBzpCheck(filterCompanyBZP);
  };
  return (
    <div style={{ margin: '10px' }}>
      <Row gutter={[20]}>
        <Col span={7}>
          <StyledAntTable
            rowKey='id'
            tableId={'status'}
            bordered
            size='small'
            showSorterTooltip={false}
            columns={columnsStatus}
            dataSource={valueStatusList}
            pagination={false}
            loading={isLoadingStatusTotal}
          />
        </Col>
        <Col span={4}>
          <StyledAntTable
            rowKey='id'
            tableId={'total_notmatch'}
            bordered
            size='small'
            showSorterTooltip={false}
            columns={columnsTotalRecord}
            pagination={false}
            dataSource={valueTotalList}
            loading={isLoadingStatusTotal}
          />
        </Col>

        <Col span={4} align='center'>
          <Card
            bodyStyle={{
              padding: '10px',
              height: '88px',
              color: 'grey',
              backgroundColor: '#ffffff',
            }}
          >
            <Progress
              percent={progress}
              steps={5}
              style={{
                marginBottom: '4px',
                cursor: progress === 100 ? 'pointer' : 'default',
              }}
              onClick={() => {
                if (progress === 100) {
                  Modal.success({
                    title: 'Match Job code !!',
                    content: (
                      <div>
                        <Row style={{ paddingTop: '20px' }}>
                          <Col style={{ marginRight: '20px' }}>
                            <p>
                              TEM Before :{' '}
                              {dataManageTourListStatus?.result?.pre?.TEM}
                            </p>

                            <p>
                              TEM After :{' '}
                              {dataManageTourListStatus?.result?.post?.TEM}{' '}
                            </p>

                            <p>
                              TEM Matched :{' '}
                              {dataManageTourListStatus?.result?.post?.assigned}{' '}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    ),

                    onOk() {},
                  });
                }
              }}
            />
            <br />
            <Button
              style={{
                width: '80%',
                height: '50px',
                // marginTop: '8px',
                backgroundColor: '#DBDADA',
              }}
              onClick={onMatchJobcode}
              loading={
                isStatusCreateLot
                  ? isStatusCreateLot
                  : progress === 0 || progress === 100
                  ? false
                  : true
              }
              disabled={dataInitDisabled}
            >
              Match Job code
            </Button>
          </Card>
        </Col>

        <Col span={4} align='center'>
          <Card
            bodyStyle={{
              padding: '10px',
              height: '88px',
              color: 'grey',
              backgroundColor: '#ffffff',
            }}
          >
            <Button
              style={{
                width: '80%',
                height: '50px',
                marginTop: '24px',
                backgroundColor: '#DBDADA',
              }}
              onClick={modalOpenToBZP}
              disabled={
                dataInitDisabled ||
                isLoadingMatch ||
                dataInitRef?.tem_tour_count !== 0 ||
                dataInitRef?.open_to_bzp
              }
              loading={isLoadingOpenToBZP}
            >
              Open to BzP
            </Button>
          </Card>
        </Col>

        <Col span={5} align='center'>
          <Card
            bodyStyle={{
              padding: '5px',

              color: 'grey',
              backgroundColor: '#ffffff',
            }}
          >
            <h5>Company</h5>
            <Select
              disabled={dataInitDisabled}
              showSearch
              style={{
                textAlign: 'center',
                width: '80%',
                fontSize: '12px',
              }}
              size='small'
              allowClear={true}
              placeholder={'Company'}
              value={filterCompanyBZP}
              onChange={(values) => {
                setFilterCompanyBZP(values);
              }}
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.children
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {filterCompanyOptionBZP?.map((c, index) => (
                <Option key={index} value={c.value}>
                  {c.label}
                </Option>
              ))}
            </Select>

            <Button
              type='primary'
              style={{ width: '80%', marginTop: '5px', fontSize: '12px' }}
              size='small'
              onClick={modalReConfirmBZP}
              disabled={
                dataInitDisabled ||
                !dataInitRef?.open_to_bzp ||
                !filterCompanyBZP ||
                (!filterCompanyBZP && dataInitRef?.open_to_bzp === null)
              }
              loading={isLoadingReConfirmToBzP}
            >
              Re-confirm to BzP
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};


const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default StatusComponent;
