import React, { useState, useEffect } from 'react';
import { G2, Line } from '@ant-design/plots';

const ChartLineComponent = (props) => {
  const { detailGraph } = props;
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState({
    time: {
      type: 'cat',
    },
    max: detailGraph?.trailer_lane?.max,
    min: detailGraph?.trailer_lane?.min,
  });

  console.log('detailGraph :>> ', detailGraph);
  useEffect(() => {
    if (detailGraph) {
      try {
        setData(detailGraph?.usage_all_by_min);
      } catch (err) {
        console.log('err', err);
      }
    }
  }, [detailGraph]);

  G2.registerShape('point', 'breath-point', {
    draw(cfg, container) {
      const group = container.addGroup();
      return group;
    },
  });
  const config = {
    autoFit: true,
    height: 200,
    data,
    meta: meta,
    xField: 'time',
    xAxis: {
      label: {
        autoRotate: true,
        autoHide: true,
        autoEllipsis: true,
      },
    },
    yField: 'value',
    seriesField: 'label',
    tooltip: {
      showMarkers: true,
    },
    point: {
      shape: 'breath-point',
    },
  };

  return <Line {...config} />;
};

export default ChartLineComponent;
