import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Space,
  Select,
  Typography,
  Collapse,
  Row,
  message,
  Card,
  Col,
  DatePicker,
  Form,
} from 'antd';
import styled from 'styled-components';

import { useErrorHandler } from 'common_components/ErrorContext';
import { useDispatch } from 'react-redux';
import { downloadReportApi } from 'reducers/downloadReport/api';
import { DownloadOutlined } from '@ant-design/icons';
import moment from 'moment';
const { RangePicker } = DatePicker;
const { Panel } = Collapse;
const { Option } = Select;

const createPayload = ({
  filterStartDate,
  filterDates,
  filterCompany,
  filterReport,
}) => {
  return {
    start: filterDates[0] ? moment(filterDates[0]).format() : null,
    end: filterDates[1] ? moment(filterDates[1]).format() : null,
    company: filterCompany && filterCompany.length > 0 ? filterCompany : null,
    report: filterReport && filterReport.length > 0 ? filterReport : null,
  };
};

const TableToolbar = (props) => {
  const { isLoading, isLoadingOnDownload, onQuery, showFilter, setShowFilter } =
    props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterDates, setFilterDates] = useState(null);
  const [companyOptions, setCompanyOptions] = useState();
  const [reportOptions, setReportOptions] = useState();
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);

  const [filterCompany, setFilterCompany] = useState();
  const [filterReport, setFilterReport] = useState();

  const today = new Date();

  const threeYearsAgo = new Date();
  threeYearsAgo.setFullYear(today.getFullYear() - 3);

  const disabledDate = (current) => {
    if (!filterStartDate) {
      return false;
    } else {
      if (filterStartDate[0] && !filterStartDate[1]) {
        return false;
      }

      // ปิดใช้งานวันที่ย้อนหลังไม่เกิน 3 เดือน

      if (current < threeYearsAgo) {
        return true;
      }

      // ปิดใช้งานวันที่ย้อนหลังไม่เกิน 93 วัน
      const tooLate =
        filterStartDate[0] && current.diff(filterStartDate[0], 'days') > 93;
      const tooEarly =
        filterStartDate[1] && filterStartDate[1].diff(current, 'days') > 93;
      return !!tooEarly || !!tooLate || false;
    }
  };

  const onOpenChange = (open) => {
    if (open) {
      setFilterStartDate([null, null]);
    } else {
      setFilterStartDate(null);
    }
  };
  const onQueryOption = useCallback(async () => {
    try {
      setIsLoadingFilter(true);
      let result = await dispatch(
        downloadReportApi.endpoints.findOnInitDownloadReport.initiate()
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          let companyAll = [
            {
              label: 'All',
              value: 'All',
            },
          ];

          companyAll.push(
            ...result.data?.company.map((d) => ({
              label: d.label,
              value: d.value,
            }))
          );

          setReportOptions(result.data.report);
          setCompanyOptions(companyAll);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
    setIsLoadingFilter(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setErrorData]);

  useEffect(() => {
    onQueryOption();
  }, [onQueryOption]);

  useEffect(() => {
    onQuery();
  }, [onQuery]);

  const onDisplayButton = () => {
    let validData = form.getFieldsValue(true);
    console.log('validData', validData);
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterStartDate,
          filterDates,
          filterCompany,
          filterReport,
        }),
      });
    }
  };

  const selectProps = {
    showSearch: true,
    size: 'small',
    style: {
      width: '165px',
      fontSize: '13px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoadingFilter,
  };

  return (
    <Container>
      <Form form={form} onFinish={onDisplayButton} autoComplete='off'>
        <StyledCollapse
          defaultActiveKey={1}
          onChange={() => {
            setShowFilter(!showFilter);
          }}
        >
          <Panel header='Filter Options' key={1}>
            <CardStyleFrom>
              <Row
                gutter={[12]}
                style={{ marginTop: '10px' }}
                align='center'
                justify='center'
              >
                <Col>
                  <Space direction='vertical'>
                    <>
                      <Form.Item
                        label='Working date'
                        name='working_day'
                        rules={[
                          {
                            required: true,
                            message: 'Please select working date',
                          },
                        ]}
                      >
                        <RangePicker
                          size='small'
                          style={{ fontSize: '13px' }}
                          // value={filterDates}
                          disabledDate={disabledDate}
                          // onCalendarChange={onCalendarChange}
                          onCalendarChange={(val) => setFilterStartDate(val)}
                          onChange={(val) => {
                            if (val) {
                              const start = moment(val[0]);
                              const end = moment(val[1]);
                              const diff = end.diff(start, 'days');
                              console.log('diff', diff);
                              setFilterDates(val);
                            }
                          }}
                          onOpenChange={onOpenChange}
                          onBlur={() => console.log('blur has been triggered')}
                          format='DD/MM/YY HH:mm'
                          showTime={{
                            format: 'HH:mm',
                          }}
                        />
                      </Form.Item>
                      {/* <StyledText>Working date</StyledText> */}
                    </>
                  </Space>
                </Col>
                <Col>
                  <Space direction='vertical'>
                    <>
                      <Form.Item
                        label='Company'
                        name='company'
                        rules={[
                          {
                            required: true,
                            message: 'Please select company',
                          },
                        ]}
                      >
                        <Select
                          {...selectProps}
                          loading={isLoading}
                          mode='multiple'
                          allowClear={true}
                          placeholder={'Company'}
                          value={filterCompany}
                          onChange={(values) => {
                            setFilterCompany(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {companyOptions &&
                            companyOptions.map((c, index) => (
                              <Option key={index} value={c.value}>
                                {c.label}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </>
                  </Space>
                </Col>
                <Col>
                  <Space direction='vertical'>
                    <>
                      <Form.Item
                        label='Report'
                        name='report'
                        rules={[
                          {
                            required: true,
                            message: 'Please select report',
                          },
                        ]}
                      >
                        <Select
                          loading={isLoading}
                          {...selectProps}
                          placeholder={'Report'}
                          value={filterReport}
                          onChange={(values) => {
                            setFilterReport(values);
                          }}
                          optionFilterProp='children'
                          filterOption={(input, option) => {
                            return option.children
                              .toLowerCase()
                              .includes(input.toLowerCase());
                          }}
                        >
                          {reportOptions &&
                            reportOptions.map((c, index) => (
                              <Option key={index} value={c.value}>
                                {c.label}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </>
                  </Space>
                </Col>
                <Col>
                  <Space direction='vertical'>
                    <>
                      <Form.Item wrapperCol={{ offset: 3 }} shouldUpdate>
                        {() => (
                          <Button
                            type='primary'
                            loading={isLoadingOnDownload}
                            htmlType='submit'
                            icon={<DownloadOutlined />}
                          >
                            Download
                          </Button>
                        )}
                      </Form.Item>
                    </>
                  </Space>
                </Col>
              </Row>
            </CardStyleFrom>
          </Panel>
        </StyledCollapse>
      </Form>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;
const Container = styled.div`
  padding: 10px 0;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
const CardStyleFrom = styled(Card)`
  .ant-card-body {
    padding-top: 10px;
    padding-bottom: 18px;
    padding-left: 20px;
    padding-right: 0px;
  }
  width: 100%;
  border: 1px solid #d9d9d9;
`;
export default TableToolbar;
