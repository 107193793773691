import React from 'react';
import DN from './DN';

const PrintDN = ({ componentRef, dnData }) => {
  return (
    <div style={{ display: 'none' }}>
      <div ref={componentRef}>
        <DN dnData={dnData} />
      </div>
    </div>
  );
};

export default PrintDN;
