import React, { useEffect, useState, useRef } from 'react';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import {
  PageHeader,
  Divider,
  Row,
  Col,
  Card,
  Button,
  message,
  Typography,
} from 'antd';
import styled from 'styled-components';
import {
  useFindAllDriverConfirmByUserQuery,
  useUpdateReceiveJobDriverConfirmMutation,
} from 'reducers/receivejob/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import Icon from '@ant-design/icons';
import { ReloadOutlined } from '@ant-design/icons';
import { ReactComponent as ClockSvg } from 'assets/icons/sand-clock.svg';
import { useTranslation } from 'react-i18next';
import ReactAudioPlayer from 'react-audio-player';
import { setSidebarVisibility } from 'reducers/ui';

const { Meta } = Card;
const { Title } = Typography;
const DriverConfirmComponent = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const arrDVRef = useRef();
  const [arrDV, setArrDV] = useState([]);

  const [confirmStatus, setConfirmStatus] = useState(false);
  const { setErrorData } = useErrorHandler();

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const {
    data: dataDV,
    error: errorDriverConfirmByUser,
    refetch: refetchDriverConfirmByUser,
    isFetching: isFetchingDV,
  } = useFindAllDriverConfirmByUserQuery(null, {
    skip: confirmStatus,
    pollingInterval: 5000, //5s
  });

  useEffect(() => {
    if (dataDV && dataDV?.confirm_status !== true) {
      setConfirmStatus(true);
      let arrDV = [];
      if (dataDV?.experience_driver) {
        arrDV.push(dataDV?.experience_driver?.audio_url);
      }
      if (dataDV?.next_trip) {
        arrDV.push(dataDV?.next_trip?.audio_url);
      }
      if (dataDV?.receive_job_time) {
        arrDV.push(dataDV?.receive_job_time?.audio_url);
      }
      setArrDV(arrDV);
      arrDVRef.current = arrDV;
    } else {
      setConfirmStatus(false);
    }
  }, [dataDV]);

  useEffect(() => {
    if (errorDriverConfirmByUser) {
      setErrorData(errorDriverConfirmByUser);
    }
  }, [errorDriverConfirmByUser, setErrorData]);

  const [
    updateDriverConfirm,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isLoadingDriverConfirm,
    },
  ] = useUpdateReceiveJobDriverConfirmMutation();

  useEffect(() => {
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, setErrorData]);

  useEffect(() => {
    if (!isUninitUpdating && isSuccessUpdating) {
      message.success('Data was successfully saved');
      setConfirmStatus(false);
      refetchDriverConfirmByUser();
    }
  }, [isSuccessUpdating, isUninitUpdating]);

  const handleConfirmActionDriver = () => {
    try {
      let postDriverConfirm = {
        id: dataDV.id,
        confirm_status: true,
      };
      updateDriverConfirm(postDriverConfirm);
    } catch (err) {
      console.log(`err`, err);
    }
  };

  const onCheckAudioAll = (value) => {
    if (value === 1) {
      if (dataDV?.next_trip?.audio_url) {
        document.getElementById('audio2').play();
        pauseAll(13);
      } else {
        if (dataDV?.receive_job_time?.audio_url) {
          document.getElementById('audio3').play();
          pauseAll(12);
        }
      }
    }

    if (value === 2) {
      if (dataDV?.receive_job_time?.audio_url) {
        document.getElementById('audio3').play();
        pauseAll(12);
      } else {
        if (dataDV?.experience_driver?.audio_url) {
          document.getElementById('audio1').play();
          pauseAll(23);
        }
      }
    }

    if (value === 3) {
      if (dataDV?.experience_driver?.audio_url) {
        document.getElementById('audio1').play();
        pauseAll(23);
      } else {
        if (dataDV?.next_trip?.audio_url) {
          document.getElementById('audio2').play();
          pauseAll(13);
        }
      }
    }
  };

  const pauseAll = (value) => {
    switch (value) {
      case 13:
        if (dataDV?.experience_driver?.audio_url) {
          document.getElementById('audio1').pause();
        }
        if (dataDV?.receive_job_time?.audio_url) {
          document.getElementById('audio3').pause();
        }
        break;
      case 23:
        if (dataDV?.next_trip?.audio_url) {
          document.getElementById('audio2').pause();
        }
        if (dataDV?.receive_job_time?.audio_url) {
          document.getElementById('audio3').pause();
        }
        break;
      case 12:
        if (value === 12) {
          if (dataDV?.experience_driver?.audio_url) {
            document.getElementById('audio1').pause();
          }
          if (dataDV?.next_trip?.audio_url) {
            document.getElementById('audio2').pause();
          }
        }
        break;

      default:
        break;
    }
  };

  const displayAudioPlayerAuto1 = (value) => {
    if (dataDV && !dataDV.experience_driver?.audio_url) {
      return null;
    } else {
      return (
        <ReactAudioPlayer
          id='audio1'
          style={{ textAlign: 'center' }}
          src={value}
          autoPlay={dataDV.experience_driver?.audio_url ? true : false}
          controls
          controlsList={'nodownload'}
          onPlay={() => {
            pauseAll(23);
          }}
          onEnded={() => {
            onCheckAudioAll(1);
          }}
        />
      );
    }
  };

  const displayAudioPlayerAuto2 = (value) => {
    if (dataDV && !dataDV.next_trip?.audio_url) {
      return null;
    } else {
      return (
        <ReactAudioPlayer
          id='audio2'
          style={{ textAlign: 'center' }}
          src={value}
          autoPlay={
            !dataDV.experience_driver?.audio_url && dataDV.next_trip?.audio_url
              ? true
              : false
          }
          controls
          controlsList={'nodownload'}
          onEnded={() => {
            onCheckAudioAll(2);
          }}
          onPlay={() => {
            pauseAll(13);
          }}
        />
      );
    }
  };
  const displayAudioPlayerAuto3 = (value) => {
    if (dataDV && !dataDV.receive_job_time?.audio_url) {
      return null;
    } else {
      return (
        <ReactAudioPlayer
          id='audio3'
          style={{ textAlign: 'center' }}
          src={value}
          autoPlay={
            !dataDV.experience_driver?.audio_url &&
            !dataDV.next_trip?.audio_url &&
            dataDV.receive_job_time?.audio_url
              ? true
              : false
          }
          controls
          controlsList={'nodownload'}
          onEnded={() => {
            onCheckAudioAll(3);
          }}
          onPlay={() => {
            pauseAll(12);
          }}
        />
      );
    }
  };

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Driver Confirm Receive Job'
        subTitle=''
      />
      <Divider style={{ margin: 0 }} />
      <Content style={{ padding: 20 }}>
        <Row style={{ marginBottom: 15 }} align='end'>
          <Col>
            <Button
              loading={isFetchingDV}
              icon={<ReloadOutlined />}
              size='small'
              onClick={() => {
                setConfirmStatus(false);
                refetchDriverConfirmByUser();
              }}
            >
              Reload
            </Button>
          </Col>
        </Row>
        {dataDV && dataDV.confirm_status === null ? (
          <>
            <Row gutter={16}>
              <Col span={8}>
                <Card>
                  <Row align='center' style={{ marginBottom: 15 }}>
                    <Col style={{ height: 60 }}>
                      {displayAudioPlayerAuto1(arrDV[0])}
                    </Col>
                  </Row>
                  <Row align='left'>
                    <Col>
                      <Meta
                        style={{ whiteSpace: 'pre-line' }}
                        title={
                          <div style={{ fontSize: 28 }}>ประสบการณ์คนขับรถ</div>
                        }
                        description={
                          <div style={{ fontSize: 23, color: 'black' }}>
                            {dataDV && dataDV.experience_driver.text}
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Row align='center' style={{ marginBottom: 15 }}>
                    <Col style={{ height: 60 }}>
                      {displayAudioPlayerAuto2(arrDV[1])}
                    </Col>
                  </Row>
                  <Row align='left'>
                    <Col>
                      <Meta
                        style={{ whiteSpace: 'pre-line' }}
                        title={<div style={{ fontSize: 28 }}>ทริปต่อไป</div>}
                        description={
                          <div style={{ fontSize: 23, color: 'black' }}>
                            {dataDV && dataDV.next_trip.text}
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={8}>
                <Card>
                  <Row align='center' style={{ marginBottom: 15 }}>
                    <Col style={{ height: 60 }}>
                      {' '}
                      {displayAudioPlayerAuto3(arrDV[2])}
                    </Col>
                  </Row>
                  <Row align='left'>
                    <Col>
                      <Meta
                        style={{ whiteSpace: 'pre-line' }}
                        title={<div style={{ fontSize: 28 }}>เวลารับงาน</div>}
                        description={
                          <div style={{ fontSize: 23, color: 'black' }}>
                            {dataDV && dataDV.receive_job_time.text}
                          </div>
                        }
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Row gutter={16} align='center' style={{ marginTop: 20 }}>
              <Col span={11}>
                <Button
                  style={{
                    width: '100%',
                    height: '200px',
                    fontSize: 70,
                  }}
                  size='large'
                  type='primary'
                  loading={isLoadingDriverConfirm}
                  block
                  onClick={() => handleConfirmActionDriver()}
                >
                  ยืนยัน
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row align='center'>
              <Col>
                <Icon
                  component={ClockSvg}
                  style={{ fontSize: '50px', marginTop: 28, marginBottom: 20 }}
                />
              </Col>
            </Row>
            <Row align='center'>
              <Col>
                <Title level={4}>กรุณารอการตรวจสอบการรับงานสักครู่</Title>
              </Col>
            </Row>
          </>
        )}
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default DriverConfirmComponent;
