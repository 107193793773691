import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
  isNeedRefresh: false,
};
export const CallingSheetSlice = createSlice({
  name: 'calling_sheet',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setCallingSheetTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setCallingSheetDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setCallingSheetFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    setCallingSheetCompleteCount: (state, { payload }) => {
      state.callingCompleteCount = payload;
    },
    setIsNeedRefresh: (state, { payload }) => {
      state.isNeedRefresh = payload;
    },
  },
});

export const {
  setCallingSheetFilter,
  setCurrentPage,
  setCurrentOffset,
  setCallingSheetDataSource,
  setCallingSheetTotalRows,
  setCallingSheetCompleteCount,
  setIsNeedRefresh
} = CallingSheetSlice.actions;

export const CallingSheetSelector = (state) => state.CallingSheet;

export default CallingSheetSlice.reducer;
