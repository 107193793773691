import DntaList from 'dms_components/Dnta/List';
import DntaDetails from 'dms_components/Dnta/Details';
import DispatchBoard from 'dms_components/DispatchBoard/List';
import VinList from 'dms_components/Vin/List';
import CallingSheetList from 'dms_components/CallingSheet/List';
import CallingListDetail from 'dms_components/CallingList/List';
import CallingList from 'dms_components/CallingList/List';
import CallingSheetDetails from 'dms_components/CallingSheet/Details';
import YardInList from 'dms_components/YardIn/List';
import YardInDetails from 'dms_components/YardIn/Details';
import ReceiveJobForm from 'dms_components/ReceiveJob';
import YardOutForm from 'dms_components/YardOut';
import DiagramOwner from 'dms_components/DiagramOwner/List';
import DiagramOwnerDetails from 'dms_components/DiagramOwner/Details';
import Permission from 'dms_components/ConfigurationPermission/List';
import PermissionDetails from 'dms_components/ConfigurationPermission/Details';
import ConfirmDriverTrailer from 'dms_components/DispatchBoard/ConfirmDriverTrailer';
import ManageCalling from 'dms_components/ManageCalling/List';
import RecommendTrip from 'dms_components/CallingList/RecommendTrip/List';
import ShuttleIn from 'dms_components/ShuttleIn';
import DnList from 'dms_components/DnList';
import CreateTrip from 'dms_components/CreateTrip/Create';
import CreateTripContinue from 'dms_components/CreateTrip/Create';
import StatusSummary from 'dms_components/StatusSummary';
import StatusChange from 'dms_components/StatusChange';
import ChangeReasonLane from 'dms_components/ChangeReasonLane';

const deliveryListRoutes = [
  {
    path: '/app/delivery_mng/lists',
    name: 'Dispatch Board',
    Component: DispatchBoard,
  },
  {
    path: '/app/delivery_mng/lists_filter/:fromUrl/:clusterUrl/:trailer_or_driver',
    name: 'Dispatch Board',
    Component: DispatchBoard,
  },
  {
    path: '/app/delivery_mng/callingsheets',
    name: 'Calling Sheet',
    Component: CallingSheetList,
  },
  {
    path: '/app/delivery_mng/callingsheets/create',
    name: 'Calling Sheet Detail',
    Component: CallingSheetDetails,
  },
  {
    path: '/app/delivery_mng/callingsheets/:id',
    name: 'Calling Sheet Detail',
    Component: CallingSheetDetails,
  },
  {
    path: '/app/delivery_mng/calling_lists',
    name: 'Calling List',
    Component: CallingList,
  },
  {
    path: '/app/delivery_mng/calling_filter/:fromUrl/:clusterUrl/:regionUrl',
    name: 'Calling List',
    Component: CallingList,
  },
  {
    path: '/app/delivery_mng/calling_list/:diagram_owner_id',
    name: 'Calling List',
    Component: CallingListDetail,
  },

  {
    path: '/app/delivery_mng/dntas',
    name: 'Delivery Note and Trip Allowance',
    Component: DntaList,
  },
  {
    path: '/app/delivery_mng/dntas/:dntaId',
    name: 'Detail',
    Component: DntaDetails,
  },
  {
    path: '/app/delivery_mng/yardins',
    name: 'Yard In',
    Component: YardInList,
  },
  {
    path: '/app/delivery_mng/yardins/create',
    name: 'Yard In Detail',
    Component: YardInDetails,
  },
  {
    path: '/app/delivery_mng/yardins/:id',
    name: 'Yard In Detail',
    Component: YardInDetails,
  },
  {
    path: '/app/delivery_mng/yardouts',
    name: 'Yard Out',
    Component: YardOutForm,
  },
  {
    path: '/app/delivery_mng/receive_jobs',
    name: 'Receive Job & Cancel Receive Job',
    Component: ReceiveJobForm,
  },
  {
    path: '/app/delivery_mng/vins/:from/:mode/:calling_no/:filterfrom',
    name: 'VIN',
    Component: VinList,
  },
  {
    path: '/app/delivery_mng/vins_filter/:fromUrl/:toUrl/:clusterUrl/:vehicleStatusUrl',
    name: 'VIN',
    Component: VinList,
  },
  {
    path: '/app/delivery_mng/vins_filter/:fromUrl/:clusterUrl/:vehicleStatusUrl',
    name: 'VIN',
    Component: VinList,
  },
  {
    path: '/app/delivery_mng/vins/:mode',
    name: 'VIN Detail',
    Component: VinList,
  },
  {
    path: '/app/delivery_mng/vins/:id',
    name: 'VIN Detail',
    Component: VinList,
  },
  {
    path: '/app/user_mng/configuration_diagram_owner',
    name: 'Configuration DiagramOwner',
    Component: DiagramOwner,
  },
  {
    path: '/app/user_mng/configuration_diagram_owner/:mode/details',
    name: 'DiagramOwnerDetails',
    Component: DiagramOwnerDetails,
  },
  {
    path: '/app/user_mng/configuration_diagram_owner/:mode/:id',
    name: 'DiagramOwnerDetails',
    Component: DiagramOwnerDetails,
  },
  {
    path: '/app/user_mng/configuration_permission',
    name: 'Configuration Permission',
    Component: Permission,
  },
  {
    path: '/app/user_mng/configuration_permission/details',
    name: 'Permission Details',
    Component: PermissionDetails,
  },
  {
    path: '/app/user_mng/configuration_permission/:id',
    name: 'Permission Details',
    Component: PermissionDetails,
  },

  {
    path: '/app/delivery_mng/lists/confirm_driver_trailer/:job_code&:id&:working_date',
    name: 'Confirm Driver&Trailer',
    Component: ConfirmDriverTrailer,
  },
  {
    path: '/app/delivery_mng/manage_calling/:calling',
    name: 'Manage Calling',
    Component: ManageCalling,
  },
  {
    path: '/app/delivery_mng/calling_lists/recommend_trip/:id/:calling_unit/:from/:cluster',
    name: 'Recommend Trip',
    Component: RecommendTrip,
  },
  {
    path: '/app/delivery_mng/shuttle_in',
    name: 'Shuttle In',
    Component: ShuttleIn,
  },
  {
    path: '/app/delivery_mng/dn_list',
    name: 'DN List',
    Component: DnList,
  },
  {
    path: '/app/delivery_mng/create_trip',
    name: 'Create Trip',
    Component: CreateTrip,
  },
  {
    path: '/app/delivery_mng/create_trip/:id',
    name: 'Create Trip Continue',
    Component: CreateTripContinue,
  },
  {
    path: '/app/delivery_mng/status_summary',
    name: 'Status Summary',
    Component: StatusSummary,
  },
  {
    path: '/app/delivery_mng/status_change',
    name: 'Status Change',
    Component: StatusChange,
  },
  {
    path: '/app/delivery_mng/status_change/:id_param',
    name: 'Status Change',
    Component: StatusChange,
  },
  {
    path: '/app/delivery_mng/change_reason_lane',
    name: 'Change Reason & Lane',
    Component: ChangeReasonLane,
  },
];

export default deliveryListRoutes;
