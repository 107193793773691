import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { Typography, Row, Col, InputNumber, message } from 'antd';
import ToolbarDetailVolume from './ToolbarDetailVolume';
import { useEditVolumeAssignVolumeMutation } from 'reducers/assignVolume/api';
import moment from 'moment';

const TableComponent = (props) => {
  const {
    filterDate,
    filterVehicleType,
    filterTo,
    filterRegion,
    filterDateFrom,
    filterDateTo,
    filterOptionVehicleType,
    filterOptionTo,
    filterOptionRegion,
    filterSelectFrom,

    setFilterDate,
    setFilterVehicleType,
    setFilterTo,
    setFilterRegion,

    setFilterOptionVehicleType,
    setFilterOptionTo,
    setFilterOptionRegion,
    isLoadingGetFilter,
    isLoadingEntry,

    dataSourceVolume,
    setDataSourceVolume,
    apiSearchVolumeTable,
    isLoadingSearchVolumeTable,
    dataEditUnit,
    setDataEditUnit,
    dataSourceVolumeTableRef,
    filterOptionDate,
    setFilterOptionDate,
    statusCheckReadyEntry,
    setStatusCheckReadyEntry,
    checkEditDetailVolume,
    setCheckEditDetailVolume,
    statusConfirm,
    apiSearchYardTable,
    loadingConfirm,
    setDataSourceVolumeTemp,
    newDataUnit,
    setNewDataUnit,
    page,
    PAGE_SIZE,
    offset,
    setPage,
    LIMIT,
    payloadRef,
    executeQuery,
    totalRows,
    dataSourceTableYard,
  } = props;
  const { setErrorData } = useErrorHandler();

  const onChangeUnit = (value, record) => {
    let dataAll = dataSourceVolumeTableRef.current;
    let objectData = {
      ...record,
      newDataUnit: value,
    };
    var res = dataAll?.map(
      (obj) => [objectData].find((o) => o.id === obj.id) || obj
    );

    let arrAddEditRowTable = res.map((item) => ({
      ...item,
      editingRow: true,
    }));
    dataSourceVolumeTableRef.current = arrAddEditRowTable;
    setDataSourceVolumeTemp(arrAddEditRowTable);
    setDataSourceVolume(arrAddEditRowTable);
  };

  const onBtnUpdate = async () => {
    let dataForUpdate = [];
    let dataSoureUpdateRef = [];
    dataSoureUpdateRef = dataSourceVolumeTableRef.current;
    for (const i in dataSoureUpdateRef) {
      if (Object.hasOwnProperty.call(dataSoureUpdateRef, i)) {
        if (dataSoureUpdateRef[i].newDataUnit) {
          dataForUpdate.push(dataSoureUpdateRef[i]);
        }
      }
    }
    let convertDataForUpdate = await dataForUpdate.map((d) => ({
      id: d.id,
      unit: d.newDataUnit,
    }));

    await apiEditUnitVolume(convertDataForUpdate);
  };

  const onBtnCancel = async () => {
    setNewDataUnit(true);
    setCheckEditDetailVolume(false);
    let arrDataTable = [];
    arrDataTable = dataSourceVolume;

    let arrAddEditRowTable = arrDataTable.map((item) => ({
      ...item,
      editingRow: false,
      newDataUnit: null,
    }));
    dataSourceVolumeTableRef.current = arrAddEditRowTable;
    setDataSourceVolume(arrAddEditRowTable);
  };
  //apiGetFilterdisplay_summary_volume -------------------------------------------------------
  const [
    apiEditUnitVolume,
    {
      data: dataEditUnitVolume,
      error: errorEditUnitVolume,
      isLoading: isLoadingEditUnitVolume,
    },
  ] = useEditVolumeAssignVolumeMutation();

  useEffect(() => {
    if (dataEditUnitVolume) {
      message.success('Update data is success !!');
      setNewDataUnit(true);
      setCheckEditDetailVolume(false);

      let payloadYardTable = {
        period_from: filterDateFrom.format('YYYY-MM-DD'),
        period_to: filterDateTo.format('YYYY-MM-DD'),
        location_from: filterSelectFrom,
      };

      apiSearchYardTable(payloadYardTable);

      let payload = {
        volume_date: onConvertDate(filterDate),
        vehicle_type: filterVehicleType ? filterVehicleType : 'all',
        location_from: filterSelectFrom ? filterSelectFrom : 'all',
        location_to: filterTo ? filterTo : 'all',
        region: filterRegion ? filterRegion : 'all',
      };
      apiSearchVolumeTable(payload);

      let arrDataTable = [];
      arrDataTable = dataSourceVolume;

      let arrAddEditRowTable = arrDataTable.map((item) => ({
        ...item,
        editingRow: false,
      }));
      setDataSourceVolume(arrAddEditRowTable);
    }
  }, [dataEditUnitVolume]);

  const onConvertDate = (date) => {
    const [dateFormat] = date.split(' ');
    const [day, month, year] = dateFormat.split('/');
    const result = ['20' + year, month, day].join('-');
    return result;
  };
  useEffect(() => {
    if (errorEditUnitVolume) {
      setErrorData(errorEditUnitVolume);
    }
  }, [errorEditUnitVolume, setErrorData]);

  const colorUnit = (record) => {
    if (record.newDataUnit) {
      setNewDataUnit(false);
      return {
        color: 'red',
      };
    } else {
      return { color: 'initial' };
    }
  };

  useEffect(() => {
    if (dataSourceVolume) {
      setDataSourceVolume(dataSourceVolume);
    }
  }, [dataSourceVolume]);

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Date',
        dataIndex: 'date',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return text ? moment(text).format('DD/MM/YY') : '';
        },
      },
      {
        title: 'Vehicle Type',
        dataIndex: 'vehicle_type',
        width: 100,
        render: (text, record) => {
          return text?.name;
        },

        align: 'center',
      },
      {
        title: 'From',
        dataIndex: 'location_from',
        width: 100,
        render: (text, record) => {
          return text?.short_name;
        },

        align: 'center',
      },
      {
        title: 'To',
        dataIndex: 'location_to',
        width: 100,
        render: (text, record) => {
          return text?.name;
        },

        align: 'center',
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        width: 100,

        render: (text, record) => {
          if (record.editingRow === true) {
            return (
              <InputNumber
                defaultValue={record.unit}
                placeholder='unit'
                value={dataEditUnit}
                size='small'
                style={colorUnit(record)}
                onChange={(value) => {
                  onChangeUnit(value, record);
                }}
              />
            );
          } else {
            return text;
          }
        },

        align: 'center',
      },
    ];

    return cols;
  });

  return (
    <Container style={{ marginTop: '10px' }}>
      <ToolbarDetailVolume
        dataSourceTableYard={dataSourceTableYard}
        filterDate={filterDate}
        filterVehicleType={filterVehicleType}
        filterTo={filterTo}
        filterRegion={filterRegion}
        filterSelectFrom={filterSelectFrom}
        filterOptionVehicleType={filterOptionVehicleType}
        filterOptionTo={filterOptionTo}
        filterOptionRegion={filterOptionRegion}
        setFilterDate={setFilterDate}
        setFilterVehicleType={setFilterVehicleType}
        setFilterTo={setFilterTo}
        setFilterRegion={setFilterRegion}
        isLoadingEntry={isLoadingEntry}
        setFilterOptionVehicleType={setFilterOptionVehicleType}
        setFilterOptionTo={setFilterOptionTo}
        setFilterOptionRegion={setFilterOptionRegion}
        isLoadingGetFilter={isLoadingGetFilter}
        dataSourceVolume={dataSourceVolume}
        setDataSourceVolume={setDataSourceVolume}
        apiSearchVolumeTable={apiSearchVolumeTable}
        isLoadingSearchVolumeTable={isLoadingSearchVolumeTable}
        dataEditUnit={dataEditUnit}
        setDataEditUnit={setDataEditUnit}
        isLoadingEditUnitVolume={isLoadingEditUnitVolume}
        onBtnUpdate={onBtnUpdate}
        newDataUnit={newDataUnit}
        filterOptionDate={filterOptionDate}
        setFilterOptionDate={setFilterOptionDate}
        statusCheckReadyEntry={statusCheckReadyEntry}
        setStatusCheckReadyEntry={setStatusCheckReadyEntry}
        checkEditDetailVolume={checkEditDetailVolume}
        setCheckEditDetailVolume={setCheckEditDetailVolume}
        onBtnCancel={onBtnCancel}
        loadingConfirm={loadingConfirm}
        statusConfirm={statusConfirm}
      />

      <Row
        gutter={[20]}
        style={{ marginBottom: '10px' }}
        align='start'
        justify='start'
      >
        <Col span={4}>
          <Typography>Detail volume</Typography>
        </Col>
      </Row>
      <StyledTable
        rowKey='id'
        tableId={'id'}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSourceVolume}
        scroll={{ x: 'max-content' }}
        pagination={{
          pageSize: PAGE_SIZE,
          total: totalRows,
          current: page,
          size: 'small',
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: async (page, _) => {
            let bufferData = dataSourceVolume;
            let requiredRows = page * PAGE_SIZE;
            requiredRows =
              requiredRows > totalRows && totalRows !== 0
                ? totalRows
                : requiredRows;

            if (requiredRows > bufferData.length) {
              // Fetch more data
              let curOffset = offset;
              do {
                let queryRes = await executeQuery(curOffset, bufferData, {
                  ...payloadRef.current,
                  limit: LIMIT,
                  offset: curOffset,
                });
                curOffset += LIMIT;
                bufferData = queryRes.data;
                requiredRows =
                  requiredRows > queryRes.totalRows
                    ? queryRes.totalRows
                    : requiredRows;
              } while (requiredRows > bufferData.length);
            }
            setPage(page);
          },
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(AntTable)`
  &&& {
    .ant-table {
      line-height: 2.2;
    }
  }
`;

export default TableComponent;
