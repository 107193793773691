import {
  Button,
  Checkbox,
  Col,
  Modal,
  Radio,
  Row,
  Select,
  Typography,
  List,
  Tag,
} from 'antd';
import { StyledAntTable } from './misc';
import {
  useFindSimilarToursQuery,
  useFindAvailableJobcodesMutation,
  useApplyJobcodeMutation,
  useRecvJobCheckMutation,
} from 'reducers/manageTourList/api';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  dateTimeFormatterWorking,
  timeFormatterNoHyphen,
} from 'common_components/AntTable/tableUtils';
import { CalendarOutlined, CheckOutlined } from '@ant-design/icons';
import ConflictRecvJobTimeErrMessage from './ConflictRecvJobTimeErrMessage';

/* This modal should get the initial tour data then
(1) fetch similar TEM tour if exists
  (1.1) this will show as checkbox on DoW
(2) fetch available jobcode on BZP selection
  (2.1) show available jobcode
(3) save
  (3.1) check if the selected jobcode has all available days to work w/selected tour(+day)
    (3.1.1) if the availability does not meet, no submit --> error message
  (3.2) save tour + similiar tours + jobcode
  (3.3) close the modal and force reload on the tour list table
*/

export default function ApplyJobcodeModal({
  isVisible,
  handleOk,
  onBtnBackEdit,
  initialTour,
  btnBackEdit,
  loadingDataTableEditInit,
  onBtnResetEdit,
  // modalSaveEdit,
  // isLoadingCheckAndSave,
  // checkCalculate,
  headerDateTableRef,
  refetchDataAll,
}) {
  const [TourID, SetTourID] = useState(null);
  const [OGTour, SetOGTour] = useState(null);
  const [DayCount, SetDayCount] = useState(7);
  const [BZPOptions, SetBZPOptions] = useState([]);
  const [RecvTimeCheck, SetRecvTimeCheck] = useState([]);
  const [SelBZP, SetSelBZP] = useState(null);
  const [PickSimTour, SetPickSimTour] = useState({});
  const [TourConv, SetTourConv] = useState({});
  const [JobcodeChoices, SetJobcodeChoices] = useState([]);
  const [IncapableJobcodes, SetIncapableJobcodes] = useState({});
  const [PickJobcode, SetPickJobcode] = useState(null);
  const [ErrMsg, SetErrMsg] = useState('');
  const [TourDetailColumns, SetTourDetailColumns] = useState(null);
  const [JobcodeAvailabilityColumns, SetJobcodeAvailabilityColumns] =
    useState(null);
  const [
    findAvailableJobcodes,
    {
      isLoading: availableJobcodesIsLoading,
      // isSuccess: availableJobcodesIsSuccess,
      // isUninitialized: availableJobcodesIsUninitialized,
      // error: availableJobcodesError,
    },
  ] = useFindAvailableJobcodesMutation();
  const [
    applyJobcode,
    {
      isLoading: applyJobcodeIsLoading,
      // isSuccess: availableJobcodesIsSuccess,
      // isUninitialized: availableJobcodesIsUninitialized,
      // error: applyJobcodeError,
    },
  ] = useApplyJobcodeMutation();
  const [
    recvJobCheck,
    {
      isLoading: recvJobCheckIsLoading,
      // isSuccess: availableJobcodesIsSuccess,
      // isUninitialized: availableJobcodesIsUninitialized,
      // error: applyJobcodeError,
    },
  ] = useRecvJobCheckMutation();

  const {
    data: similarToursData,
    isLoading: similarToursIsLoading,
    // refetch: refetchSimilarTours,
  } = useFindSimilarToursQuery(TourID, {
    skip: !TourID,
  });

  useEffect(() => {
    if (!isVisible || !initialTour) return;
    console.log('tourID = ', TourID, 'initial tour = ', initialTour);
    SetOGTour(initialTour);
    SetTourID(initialTour.id);
    SetBZPOptions([{ value: '-', name: '-' }, ...initialTour.filters.company]);

    return () => {
      SetOGTour(null);
      SetTourID(null);
      SetBZPOptions([]);
    };
  }, [isVisible, initialTour]);

  useEffect(() => {
    // merge with OGTour
    if (!similarToursData) return;
    if (!OGTour) return;
    // console.log('[useEffect] similarToursData=', similarToursData);
    // console.log('[useEffect] OGTour.similarTourAdded=', OGTour);
    if (OGTour.similarTourAdded) return;

    let simTourDict = {};
    let pickSimTour = {};
    let tourConv = {}; // day to jobcode
    // add OG tour day to tourConv
    OGTour.lots[0].calendar.map((i) => {
      if (i.work) {
        tourConv[i.date] = OGTour.job_code;
      }
      return null;
    });
    similarToursData.result.map((i) => {
      simTourDict[i.day] = i;
      pickSimTour[i.job_code] = true;
      tourConv[i.day] = i.job_code;
      return null;
    });
    let modLots = OGTour.lots.map((lot) => {
      const modCalendar = lot.calendar.map((i) => {
        return {
          ...i,
          similar_tour: simTourDict[i.date],
        };
      });
      return {
        ...lot,
        calendar: modCalendar,
      };
    });
    let newTour = {
      ...OGTour,
      lots: modLots,
      similarTourAdded: true,
    };
    const dayCount = OGTour.lots[0].calendar.length;
    SetDayCount(dayCount);
    SetOGTour(newTour);
    SetPickSimTour(pickSimTour);
    SetTourConv(tourConv);
  }, [similarToursData, OGTour]);

  useEffect(() => {
    SetRecvTimeCheck([]); // clear pick jobcode related info
    SetPickJobcode(null);
    SetErrMsg('');
    const getJobcodes = async (body) => {
      const resp = await findAvailableJobcodes(body);
      SetJobcodeChoices(resp.data.result);
      if (resp.data.incapable_list) {
        const bad = {};
        resp.data.incapable_list.map((i) => {
          const [reason, jc, drv] = i;
          if (!bad[reason]) bad[reason] = [];
          bad[reason].push([jc, drv]);
          return null;
        });
        SetIncapableJobcodes(bad);
      } else {
        SetIncapableJobcodes({});
      }
    };

    if (!SelBZP) {
      SetJobcodeChoices([]);
      return;
    }
    const jobcodes = similarToursData?.result.map((i) => i.job_code) || [];
    const body = {
      bzp: SelBZP,
      tourIDs: [OGTour.job_code, ...jobcodes],
    };
    getJobcodes(body);

    return () => {
      SetJobcodeChoices([]);
      SetIncapableJobcodes([]);
    };
  }, [SelBZP, similarToursData, findAvailableJobcodes, OGTour]);

  const isJobcodeAndWorkdayAligned = useCallback(
    async (job_code, pickSimTour) => {
      const code = job_code || PickJobcode;
      if (!code) return [];
      const currSimTour = pickSimTour || PickSimTour;
      if (!currSimTour) return [];

      const jcInfo = JobcodeChoices.filter((i) => i.job_code === code);
      if (!jcInfo) return [];

      let conflicts = jcInfo[0].availability.map((i) => {
        const jobCodeThisDay = TourConv[i.day];
        if (OGTour.job_code === jobCodeThisDay) {
          if (i.status === 'available') return null;

          return `${i.day} ${i.status}`;
        }
        if (i.status === 'available') return null;

        const hasTour = currSimTour[jobCodeThisDay];
        if (hasTour === undefined || hasTour === false) return null;
        return `${i.day} ${i.status}`;
      });

      // get rid of null
      SetErrMsg(conflicts.filter((i) => i));
      // if no conflicts we should call recv job check
      // TODO: level-recv-job-check
      const body = {
        jobcode: code,
        tourID: TourID,
      };
      const resp = await recvJobCheck(body);
      console.log('recvJob info= ', resp?.data?.info);
      if (resp?.data?.info) {
        SetRecvTimeCheck(resp.data.info);
      }
    },
    [PickJobcode, OGTour, TourConv, PickSimTour, JobcodeChoices],
  );

  const handleJobcodeChange = useCallback(
    (e) => {
      SetRecvTimeCheck([]); // reset picked jobcode related info
      SetPickJobcode(e.target.value);
      isJobcodeAndWorkdayAligned(e.target.value);
    },
    [isJobcodeAndWorkdayAligned],
  );

  const handleSimTourChange = useCallback(
    (day, checked) => {
      SetPickSimTour((curr) => {
        let updated = {
          ...curr,
        };
        updated[day] = checked;
        isJobcodeAndWorkdayAligned(null, updated);
        return updated;
      });
    },
    [isJobcodeAndWorkdayAligned],
  );

  function resetModalState() {
    SetSelBZP('');
    SetPickSimTour({});
    SetTourConv({});
    SetJobcodeChoices([]);
    SetPickJobcode(null);
    SetErrMsg('');
    SetTourID(null);
    SetBZPOptions([]);
    SetOGTour(null);
    SetTourDetailColumns(null);
    SetRecvTimeCheck([]);
  }

  useEffect(() => {
    if (!OGTour) return;
    if (!headerDateTableRef?.current) return;
    const fn = () => {
      const TourDetailColumns = GetTourDetailColumns(
        headerDateTableRef.current[0]?.calendar,
        handleSimTourChange,
        DayCount,
      );
      const JobcodeAvailabilityColumns = GetJobcodeAvailabilityColumns(
        headerDateTableRef.current[0]?.calendar,
        handleJobcodeChange,
        DayCount,
      );
      SetTourDetailColumns(TourDetailColumns);
      SetJobcodeAvailabilityColumns(JobcodeAvailabilityColumns);
    };
    if (TourDetailColumns) return;

    fn();
  }, [
    TourDetailColumns,
    similarToursData,
    DayCount,
    OGTour,
    headerDateTableRef,
    handleSimTourChange,
    handleJobcodeChange,
  ]);

  const Good2Save =
    !similarToursIsLoading &&
    !availableJobcodesIsLoading &&
    !applyJobcodeIsLoading &&
    PickJobcode &&
    ErrMsg.length === 0;

  if (!OGTour) return <></>;

  return (
    <Modal
      title='Manual Jobcode Match'
      visible={isVisible}
      onOk={handleOk}
      onCancel={() => {
        resetModalState();
        btnBackEdit();
      }}
      width={1200}
      footer={false}
      maskClosable={false}
    >
      <Row gutter={[20]}>
        <Col span={12}>
          <h4>
            Job code : {'   '}
            <span style={{ fontWeight: '400', marginRight: '20px' }}>
              {OGTour?.job_code}
            </span>
            {'   '}
            Shift : {'   '}
            <span style={{ fontWeight: '400', marginRight: '20px' }}>
              {OGTour?.shift}
            </span>
            {'   '}
            Trip type : {'   '}
            <span style={{ fontWeight: '400', marginRight: '20px' }}>
              {OGTour?.trip_type}
            </span>
          </h4>
        </Col>
        <Col span={12} align='end'>
          <h4>
            Working hour :{' '}
            <span style={{ fontWeight: '400' }}> {OGTour?.working_hour}</span>
          </h4>
        </Col>
      </Row>

      {TourDetailColumns && (
        <StyledAntTable
          rowKey='trip_no'
          tableId={'manual-tour'}
          bordered
          size='small'
          showSorterTooltip={false}
          loading={loadingDataTableEditInit}
          columns={TourDetailColumns}
          dataSource={OGTour?.lots}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      )}

      <Row style={{ marginTop: 10, marginBottom: 10 }}>
        <Col span={22} align='center' style={{ color: 'red' }}>
          {ErrMsg && <span>{ErrMsg.join(', ')}</span>}
        </Col>
      </Row>

      <Row>
        <Col span={4} align='start'>
          <Typography>Company</Typography>
          <Select
            defaultValue={BZPOptions.length > 0 ? BZPOptions[0].value : ''}
            style={{ minWidth: 100 }}
            key={'bzp-key'}
            options={BZPOptions.map((i) => ({ ...i, label: i.name }))}
            onChange={(val, opts) => {
              SetSelBZP(val);
            }}
          />
        </Col>
        <Col span={18}>
          {availableJobcodesIsLoading && (
            <Typography>กำลังตรวจสอบ job code...</Typography>
          )}
          {!SelBZP &&
            !availableJobcodesIsLoading &&
            JobcodeChoices.length === 0 && (
              <Typography>โปรดเลือก Company เพื่อแสดง Jobcode</Typography>
            )}
          {!availableJobcodesIsLoading &&
            SelBZP &&
            JobcodeChoices.length === 0 &&
            Object.keys(IncapableJobcodes).length === 0 && (
              <Typography>{SelBZP} ไม่มี job code ที่พร้อมรับงาน</Typography>
            )}
          {!availableJobcodesIsLoading &&
            SelBZP &&
            JobcodeChoices.length === 0 &&
            Object.keys(IncapableJobcodes).length > 0 && (
              <IncapableList incapableDict={IncapableJobcodes} />
            )}

          {RecvTimeCheck.length > 0 && (
            <ConflictRecvJobTimeErrMessage effTours={RecvTimeCheck} />
          )}

          <Radio.Group name='jobcode-choices' onChange={handleJobcodeChange}>
            {!availableJobcodesIsLoading &&
              JobcodeAvailabilityColumns &&
              JobcodeChoices.length > 0 && (
                <StyledAntTable
                  rowKey='job_code'
                  tableId={'jobcode-availability'}
                  bordered
                  size='small'
                  showSorterTooltip={false}
                  loading={availableJobcodesIsLoading}
                  columns={JobcodeAvailabilityColumns}
                  dataSource={JobcodeChoices}
                  pagination={false}
                />
              )}
          </Radio.Group>
        </Col>
      </Row>

      <Row gutter={[20]}>
        <Col span={12} align='start'>
          <Button
            type='primary'
            size='small'
            style={{
              width: '90px',
              marginTop: '20px',
              marginRight: '20px',
              height: 50,
            }}
            onClick={() => {
              resetModalState();
              btnBackEdit();
            }}
          >
            Back
          </Button>
        </Col>
        <Col span={12} align='end'>
          <Button
            type='primary'
            size='small'
            style={{ width: '90px', marginTop: '20px', height: 50 }}
            onClick={async () => {
              let tourIDs = [OGTour.job_code];
              // eslint-disable-next-line array-callback-return
              Object.keys(PickSimTour).map((k) => {
                if (PickSimTour[k]) tourIDs.push(k);
              });
              const body = {
                tourIDs,
                jobcode: PickJobcode,
              };
              await applyJobcode(body);
              // reset modal
              resetModalState();

              refetchDataAll();
              handleOk();
            }}
            loading={applyJobcodeIsLoading}
            disabled={!Good2Save}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Modal>
  );
}

const busyStyle = {
  color: 'red',
  fontWeight: 'bolder',
  fontSize: '1.2rem',
  lineHeight: '1rem',
};

const okStyle = {
  fontWeight: 'lighter',
  fontSize: '1.1rem',
  lineHeight: '1rem',
};

function jobcode_status(day, availability) {
  const avail = availability.filter((i) => i.day === day);
  if (avail.length === 0) return null;

  if (avail[0].status === 'available') {
    return <Typography style={okStyle}>○</Typography>;
  }

  if (avail[0].status === 'busy') {
    return <Typography style={busyStyle}>×</Typography>;
  }
  return null;
}

function TourDetailCalendarRender(handler, text, record, dayIdx) {
  // console.log('[TourDetailCalendarRender]', text, dayIdx, record);
  const day = record.calendar[dayIdx];
  if (!day) {
    return <></>;
  }
  const st = day.similar_tour;
  if (record.trip_no === 1 && st) {
    const d = timeFormatterNoHyphen({ value: st.receive_job_time });
    return (
      <>
        <Checkbox
          defaultChecked
          name={st.job_code}
          onChange={(e) => {
            handler(e.target.name, e.target.checked);
          }}
        >
          {d}
        </Checkbox>
      </>
    );
  }
  if (text) {
    return <CheckOutlined style={{ fontSize: '15px' }} />;
  } else {
    return <></>;
  }
}

function GetJobcodeAvailabilityColumns(calendar, handler, total_days) {
  const dayChildren = [...Array(total_days).keys()].map((d) => ({
    title: () => {
      let date = calendar[d]?.date;
      return (
        <div>{date ? dateTimeFormatterWorking({ value: date }) : '-'}</div>
      );
    },
    dataIndex: ['calendar', `${d}`],
    className: 'color-head-6',
    key: d + 1,
    fixed: 'right',
    align: 'center',
    render: (text, record) => {
      const day = calendar[d]?.date;
      return jobcode_status(day, record.availability);
    },
  }));
  return [
    {
      title: 'Job code',
      dataIndex: 'job_code',
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return <Radio value={text}>{text}</Radio>;
      },
    },
    {
      title: 'Shift',
      dataIndex: 'shift',
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return text;
      },
    },
    {
      title: 'Base Trailer',
      dataIndex: 'base_trailer',
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return text;
      },
    },
    {
      title: 'No. Driver',
      dataIndex: 'driver_count',
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return text;
      },
    },
    ...dayChildren,
  ];
}

function GetTourDetailColumns(calendar, simTourChangeHandler, total_days) {
  // console.log('[TourDetailColumns]', calendar, total_days);
  const dayChildren = [...Array(total_days).keys()].map((d) => ({
    title: () => {
      let date = calendar[d]?.date;
      return (
        <div style={{ margin: 10 }}>
          {date ? dateTimeFormatterWorking({ value: date }) : '-'}
        </div>
      );
    },
    dataIndex: ['calendar', `${d}`, 'work'],
    key: d + 1,
    align: 'center',
    fixed: 'right',
    className: 'color-head-6',
    render: (text, record) => {
      // console.log('calendar -- ', d, text, record);
      return TourDetailCalendarRender(simTourChangeHandler, text, record, d);
    },
  }));
  return [
    {
      title: 'Trip No.',
      dataIndex: 'trip_no',
      align: 'center',
      className: 'bg-grey',
    },
    {
      title: 'From',
      dataIndex: ['lots', 'location_from__name'],
      align: 'center',
      className: 'bg-grey',
    },
    {
      title: 'Receive job',
      dataIndex: ['lots', 'receive_job_time', 'timestamp'],
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return `${timeFormatterNoHyphen({ value: text })} ${
          record?.lots?.receive_job_time?.day_offset !== 0
            ? '(+' + record?.lots?.receive_job_time?.day_offset + ')'
            : ''
        }`;
      },
    },
    {
      title: 'Unit',
      dataIndex: ['lots', 'unit'],
      align: 'center',
      className: 'bg-grey',
    },
    {
      title: 'Yard out',
      dataIndex: ['lots', 'yard_out_time', 'timestamp'],
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return `${timeFormatterNoHyphen({ value: text })} ${
          record?.lots?.yard_out_time?.day_offset !== 0
            ? '(+' + record?.lots?.yard_out_time?.day_offset + ')'
            : ''
        }`;
      },
    },
    {
      title: 'To',
      dataIndex: ['lots', 'location_to__name'],
      align: 'center',
      className: 'bg-grey',
    },
    {
      title: 'Arrive time',
      dataIndex: ['lots', 'arrival_time', 'timestamp'],
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return `${timeFormatterNoHyphen({ value: text })} ${
          record?.lots?.arrival_time?.day_offset !== 0
            ? '(+' + record?.lots?.arrival_time?.day_offset + ')'
            : ''
        }`;
      },
    },
    {
      title: 'Departure time',
      dataIndex: ['lots', 'departure_time', 'timestamp'],
      align: 'center',
      className: 'bg-grey',
      render: (text, record) => {
        return `${timeFormatterNoHyphen({ value: text })} ${
          record?.lots?.departure_time?.day_offset !== 0
            ? '(+' + record?.lots?.departure_time?.day_offset + ')'
            : ''
        }`;
      },
    },
    {
      title: (
        <>
          <CalendarOutlined style={{ fontSize: '15px' }} /> Calendar{' '}
        </>
      ),

      dataIndex: 'calendar',
      width: 50,
      fixed: 'right',
      className: 'color-head-6',
      render: (text, record) => {
        return text;
      },

      align: 'center',
      children: dayChildren,
    },
  ];
}

const IncapableList = ({ incapableDict }) => {
  if (!incapableDict) return <></>;
  const badReasonList = Object.keys(incapableDict);
  if (badReasonList.length === 0) return <></>;
  return (
    <Row>
      {badReasonList.map((badKey, badColIdx) => (
        <Col span={12} key={`bc-${badColIdx}`}>
          <IncapableItemList title={badKey} itemList={incapableDict[badKey]} />
        </Col>
      ))}
    </Row>
  );
};

const IncapableItemList = ({ title, itemList }) => {
  return (
    <>
      <Typography.Title level={5} style={{ margin: 0 }}>
        Job code ไม่มี ability "<strong>{title}</strong>"
      </Typography.Title>
      <List
        size='small'
        itemLayout='horizontal'
        dataSource={itemList}
        renderItem={(item, itemIdx) => (
          <List.Item key={`x${title}-${itemIdx}`}>
            <List.Item.Meta
              title={item[0]}
              description={
                <ul>
                  {item[1].map((drv, idx) => (
                    <li>
                      <Tag>{drv[0]}</Tag> {drv[1]}
                    </li>
                  ))}
                </ul>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};
