import React, { useState, useEffect, useCallback } from 'react';

import AntTable from 'common_components/AntTable';

import EditButtonRenderer from 'common_components/EditButtonRenderer';
import TableToolbar from 'common_components/AntTable/Toolbar';
import { push } from 'connected-react-router';
import permission from 'permissions';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { useDeleteClusterMutation } from 'reducers/masterData/cluster/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { Sorter } from 'utils/sorter';
import styled from 'styled-components';
import { message } from 'antd';
import { selectTableStateById } from 'reducers/table';

const TableComponent = (props) => {
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'cluster')
  );

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: '',
        dataIndex: 'edit',
        align: 'center',
        render: (text, record) => {
          return (
            <EditButtonRenderer data={record} onClick={handleEditClicked} />
          );
        },
        width: 50,
      },
      {
        title: 'Code',
        dataIndex: 'code',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Name (EN)',
        dataIndex: 'name_en',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Name (TH)',
        dataIndex: 'name_th',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Zone',
        dataIndex: 'zone',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Region',
        dataIndex: 'region',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Category',
        dataIndex: 'category',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Standard Allowance',
        dataIndex: 'std_allowance',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Standard OT Hours',
        dataIndex: 'std_ot_hours',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Loading Unit',
        dataIndex: 'loading_unit',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Calling Code',
        dataIndex: 'calling_code',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Calling Shirt Name',
        dataIndex: 'calling_shortname',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Total Lead Time Hours',
        dataIndex: 'total_lead_time_hours',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Loading',
        dataIndex: 'loading',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Operation Time',
        dataIndex: 'operation_time',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
      },
      {
        title: 'TTL Rest',
        dataIndex: 'ttl_rest',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
      },
      {
        title: 'Unload',
        dataIndex: 'unload',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Driver Rest',
        dataIndex: 'driver_rest',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
      },
      {
        title: 'Trailer Lead Time',
        dataIndex: 'trailer_lead_time',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Driver Lead Time',
        dataIndex: 'driver_lead_time',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Trailer Requirement',
        dataIndex: 'trailer_req',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Dealer Requirement',
        dataIndex: 'dealer_req',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }
    return cols;
  });
  
  const [
    deleteCluster,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
      isLoading: isDeleting,
    },
  ] = useDeleteClusterMutation();

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      clearSelectedRows();
    }
  }, [isSuccessDeleting, isUninitDeleting]);

  const onSearch = useCallback(
    (searchTerm) => {
      if (rowData && rowData.length > 0) {
        let searchCols = columns.filter((c) => c.searchable);
        if (searchCols.length > 0 && searchTerm) {
          let filterData = rowData.filter((d) =>
            searchCols.some(
              (s) =>
                d[s.dataIndex] &&
                String(d[s.dataIndex])
                  .toLowerCase()
                  .includes(searchTerm.trim().toLowerCase())
            )
          );
          setDataSource(filterData);
        } else {
          setDataSource(rowData);
        }
      }
      clearSelectedRows();
    },
    [columns, rowData]
  );

  const onCreate = () => {
    dispatch(push(`/app/master/clusters/create`));
  };

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/master/clusters/${cell.id}`));
  };

  const onDelete = () => {
    for (let row of selectedRows) {
      deleteCluster({ id: row.id });
    }

    clearSelectedRows();
  };

  const onReload = () => {
    props.refetchData();
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setShowDelete(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);

      if (permission.checkPermission(user, 'cluster', ['d'])) {
        setShowDelete(selectedRows.length > 0 ? true : false);
      }
    },
  };

  return (
    <Container>
      <TableToolbar
        onCreate={onCreate}
        onDelete={onDelete}
        onReload={onReload}
        showDelete={showDelete}
        showReload={true}
        isLoading={isLoading}
        isDeleting={isDeleting}
        showAdd={permission.checkPermission(user, 'cluster', ['c'])}
        showSearch={true}
        columns={columns}
        setColumns={setColumns}
        onSearch={onSearch}
      />
      <AntTable
        tableId={'cluster'}
        rowSelection={rowSelection}
        bordered
        showSorterTooltip={false}
        rowKey='id'
        loading={isLoading || isDeleting}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content' }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: 'Edit', action: (row) => handleEditClicked(row) },
          {
            label: 'Delete',
            action: (row) => deleteCluster({ id: row.id }),
            delete: true,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
