import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const ChangeReasonLaneSlice = createSlice({
  name: 'changeReasonLane',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setChangeReasonLaneTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setChangeReasonLaneDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setChangeReasonLaneDataSourceEdit: (state, { payload }) => {
      state.dataSourceEdit = payload;
    },
    setChangeReasonLaneFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setChangeReasonLaneFilter,
  setCurrentPage,
  setCurrentOffset,
  setChangeReasonLaneDataSource,
  setChangeReasonLaneDataSourceEdit,
  setChangeReasonLaneTotalRows,
  setChangeReasonLaneCompleteCount,
} = ChangeReasonLaneSlice.actions;

export const ChangeReasonLaneSelector = (state) => state.ChangeReasonLane;

export default ChangeReasonLaneSlice.reducer;
