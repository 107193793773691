import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import TableToolbar from './Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from 'hooks/windowSize';
import { dnListApi } from 'reducers/dnList/api';
import styled from 'styled-components';
import { message, Popover, Typography, Space } from 'antd';
import PrintDN from '../../common_components/PrintDocument/PrintingDN/PrintDN';
import { InfoCircleOutlined } from '@ant-design/icons';
import usePrint from 'hooks/usePrint';

const CallingSheetTableComponent = (props) => {
  const { setTotalRows } = props;
  const [isLoading, setIsLoading] = useState(false);
  const printDNComponentRef = useRef();
  const [formData, setFormData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showFilter, setShowFilter] = useState(true);

  const [dataSource, setDataSource] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);
  const dispatch = useDispatch();
  const { height } = useWindowSize();

  const [printData, print] = usePrint('DNList', formData);

  useEffect(() => {
    if (height) {
      let h = height - 360;
      if (!showFilter) {
        h = h + 100;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  const handleClicked = async (cell) => {
    try {
      if (!cell.callingsheet) {
        message.info('This DN is already cancelled');
        return;
      }
      await onQueryReprint(cell.delivery_note.id);
      handlePrintDN();
    } catch (err) {
      console.log(err);
    }
  };

  const onQuery = async (value) => {
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          dnListApi.endpoints.findAllDnListByPagination.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        } else {
          if (result.data) {
            let arrData = result.data.map((d, i) => ({
              ...d,
              id: i + 1,
            }));
            setDataSource(arrData);
            setTotalRows(arrData.length);
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
    }
  };
  const onQueryReprint = async (value) => {
    let arrData = [];
    try {
      setIsLoading(true);
      if (value) {
        let result = await dispatch(
          dnListApi.endpoints.findAllDnListReprintByDelivery_note_id.initiate(
            value,
            {
              forceRefetch: true,
            }
          )
        );

        if (result && result.error) {
          message.error(result.error.data.errorList[0].errorDetail);
        } else {
          if (result.data) {
            arrData = result.data;

            let dataVin = arrData.vins.map((d, index) => ({
              index: index + 1,
              assigned_date: d.assigned_date,
              color: d.color ? d.color : '',
              destination: d.destination,
              engine: d.engine ? d.engine : '',
              id: d.id,
              lot_no_cbu: d.lot_no_cbu ? d.lot_no_cbu : '',
              model_code: d.model_code ? d.model_code : '',
              series: d.series ? d.series : '',
              upload_date: d.upload_date,
              vin_no: d.vin_no ? d.vin_no : '',
              suffix: d?.suffix ? d?.suffix : '',
            }));

            if (dataVin.length !== 11) {
              let empty = {
                index: '-',
                assigned_date: '',
                color: '',
                destination: '',
                engine: '',
                id: '',
                lot_no_cbu: null,
                model_code: '',
                series: '',
                upload_date: '',
                vin_no: '',
                suffix: '',
              };
              let number_loop = 9 - dataVin.length;

              for (let i = 0; i < number_loop; i++) {
                dataVin.push(empty);
              }
            }
            let dataDN = [
              {
                callingsheet: result.data.callingsheet,
                customer: result.data.customer,
                datetime_now: result.data.datetime_now,
                delivery_note: result.data.delivery_note,
                location_destination: result.data.location_destination,
                location_from: result.data.location_from,
                location_to: result.data.location_to,
                user_login_print: result.data.user_login_print,
                vins: dataVin,
              },
            ];
            setFormData(dataDN);
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoading(false);
      return arrData;
    }
  };

  const columnsPopup = [
    {
      title: 'No.',
      dataIndex: 'no',
      width: 50,
      align: 'center',
    },
    {
      title: 'Vin No.',
      dataIndex: 'vin',
      width: 150,
      align: 'center',
    },
  ];
  const contentPopup = (value) => {
    let resData = [];
    try {
      if (value) {
        resData =
          value &&
          value.detail_dn &&
          value.detail_dn.map((d, i) => ({
            id: i + 1,
            no: 1 + i,
            vin: d,
          }));
      }
      return (
        <>
          <Space size={15} direction='vertical'>
            <StyledText>Detail DN</StyledText>
            <AntTable
              rowKey='id'
              tableId={'calling_no_combine'}
              showSorterTooltip={false}
              columns={columnsPopup}
              dataSource={resData}
              pagination={false}
            />
          </Space>
        </>
      );
    } catch (err) {
      console.log('err', err);
    }
  };

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: (
          <>
            DN No.{' '}
            <InfoCircleOutlined
              style={{ paddingBottom: '2px', fontSize: '10px' }}
            />
          </>
        ),
        dataIndex: ['delivery_note', 'dn_no'],
        align: 'center',
        width: 100,
        movable: true,
        render(text, record) {
          return (
            <Popover placement='topLeft' content={contentPopup(record)}>
              {text}
            </Popover>
          );
        },
      },
      {
        title: 'Calling No.',
        dataIndex: ['callingsheet', 'callingsheet_no'],
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          if (text) {
            return text;
          } else {
            return '-';
          }
        },
      },
      {
        title: 'Yard Out Date',
        dataIndex: ['callingsheet', 'dispatch_board_calling_sheet'],
        align: 'center',
        width: 100,
        render: (text, record) => {
          if (text && text.length > 0) {
            return dateTimeTimestamptzFormatter({
              value: text[0].yardout_datetime,
            });
          } else {
            return '-';
          }
        },
        movable: true,
      },
      {
        title: 'Company',
        dataIndex: ['callingsheet', 'dispatch_board_calling_sheet'],
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          return text && text.length > 0
            ? text[0].bzp
              ? text[0].bzp.job_name
              : '-'
            : '-';
        },
      },
      {
        title: 'Destination',
        dataIndex: ['location_to', 'short_name'],
        render: (text, record) => {
          if (text) {
            return text;
          } else {
            return '-';
          }
        },
        align: 'center',
        width: 100,
        movable: true,
      },

      {
        title: 'Customer Code ',
        dataIndex: ['customer', 'code'],
        render: (text, record) => {
          if (text) {
            return text;
          } else {
            return '-';
          }
        },
        width: 100,
        align: 'center',
        movable: true,
      },
    ];

    return cols;
  });

  const handlePrintDN = () => {
    setTimeout(() => {
      print(printDNComponentRef.current, 'print-dn');
    }, 500);
  };

  return (
    <Container>
      <TableToolbar
        onQuery={onQuery}
        columns={columns}
        isLoading={isLoading}
        setColumns={setColumns}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <StyledAntTable
        rowKey='id'
        tableId={'callingsheet'}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          size: 'small',
          defaultPageSize: 50,
          showSizeChanger: true,
          pageSizeOptions: ['50', '100', '200', '500'],
          showQuickJumper: true,
        }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          {
            label: 'Print DN',
            action: (row) => handleClicked(row),
          },
        ]}
      />
      <PrintDN
        componentRef={printDNComponentRef}
        dnDataList={printData}
      ></PrintDN>
    </Container>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body {
      height: ${(props) => {
        return props.height + 'px';
      }};
    }
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: auto;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`;
const Container = styled.div`
  padding: 0 10px;
`;
const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
export default CallingSheetTableComponent;
