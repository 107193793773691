import React from 'react';
import { List, Row, Col, Space } from 'antd';
import styled from 'styled-components';
import constants from 'common_components/PrintDocument/constants';

var Barcode = require('react-barcode');

const Kakudai = ({ KakudaiData }) => {
  // console.log('KakudaiData',KakudaiData)

  let arr_item_list = [
    {
      vin_no: 1,
    },
    {
      vin_no: 2,
    },
    {
      vin_no: 3,
    },
    {
      vin_no: 4,
    },
    {
      vin_no: 5,
    },
  ];
  let newData = [];

  if (KakudaiData.length !== 0) {
    newData = KakudaiData.map((item) => ({
      ...item,
      arr_item_list: [...arr_item_list],
    }));
  }
  const renderVinComponent = (item) => {
    return (
      <Col span={12} align='left' style={{ padding: '0px' }}>
        <div
          style={{
            border: '1px solid grey',
            paddingLeft: '10px',
            paddingRight: '10px',
            paddingTop: '3px',
          }}
        >
          <Row
            gutter={[24]}
            align='center'
            justify='center'
            style={{ padding: '0px' }}
          >
            <Col span={6} style={{ zIndex: 1 }}>
              <VinContent style={{ marginLeft: '4px' }}>Vin no.</VinContent>
            </Col>
            <Col
              span={18}
              align='center'
              style={{
                marginRight: 'auto',
                marginTop: '6px',
                position: 'relative',
                zIndex: 0,
              }}
            >
              <Barcode
                value={item?.vin_no}
                flat
                fontSize={24}
                height={42}
                width={2}
                style={{ marginBottom: '-6px' }}
              />
            </Col>
          </Row>
          <Row
            gutter={[24]}
            style={{ paddingTop: '-8px', position: 'relative', zIndex: 2 }}
          >
            <div style={{ flex: 1, marginLeft: '15px', marginTop: '-20px' }}>
              <VinContainer>
                <VinTextLabel>Model code : </VinTextLabel>
                <VinTextValue>
                  {item?.model_code ? item?.model_code : '-'}
                </VinTextValue>
              </VinContainer>
              <VinContainer>
                <VinTextLabel>Series : </VinTextLabel>
                <VinTextValue>{item?.series ? item?.series : '-'}</VinTextValue>
              </VinContainer>
              <VinContainer>
                <VinTextLabel>Colour : </VinTextLabel>
                <VinTextValue>{item?.color ? item?.color : '-'}</VinTextValue>
              </VinContainer>
            </div>
            <div
              style={{
                width: '180px',
                marginRight: '15px',
                marginTop: '-13px',
                marginbottom: '3px',
              }}
            >
              <VinTypeLabel>
                {item?.vehicle_type ? item?.vehicle_type : '-'}
              </VinTypeLabel>
              <VinClusterLabel>
                <div style={{marginTop: '-3px'}}>
                  {' '}
                  {item?.cluster_kakudai ? item?.cluster_kakudai : '-'}
                </div>
              </VinClusterLabel>
            </div>
            <Space
              size={10}
              style={{
                marginTop: '-26px',
                marginBottom: '0px',
                paddingTop: '-26px',
                paddingBottom: '0px',
                paddingLeft: '8px',
                marginLeft: '8px',
              }}
            >
              <VinContent>From :</VinContent>
              <VinContentBold>
                {item?.location_from ? item?.location_from : '-'}
              </VinContentBold>
              <VinContent>To :</VinContent>
              <VinContentBold>
                {item?.location_destination_kakudai
                  ? item?.location_destination_kakudai
                  : '-'}
              </VinContentBold>
            </Space>
          </Row>
        </div>
      </Col>
    );
  };
  return (
    <List
      itemLayout='vertical'
      dataSource={newData}
      renderItem={(item) => (
        <List.Item
          style={{
            margin: '0px 20px',
            borderColor: 'transparent',
            height: constants.A4.portrait[1],
          }}
        >
          <Row
            gutter={[24]}
            justify='center'
            align='middle'
            style={{ height: '128px', margin: '0px' }}
          >
            <HeaderCol span={3}>
              <TitleLabelHeader>Vin no. </TitleLabelHeader>
            </HeaderCol>
            <HeaderCol span={17} align='center'>
              <CaptionVinNoText>{item.vin_no}</CaptionVinNoText>
            </HeaderCol>
            <HeaderCol span={4}>
              <TitleLabel
                style={{
                  textAlign: 'center',
                }}
              >
                {item?.vehicle_type ? item?.vehicle_type : '-'}
              </TitleLabel>
            </HeaderCol>
          </Row>
          <Row
            gutter={[24]}
            align='middle'
            justify='center'
            style={{ height: '450px', margin: '0px' }}
          >
            <HeaderCol span={3} align='center'>
              <TitleLabelHeader>Destination : </TitleLabelHeader>
            </HeaderCol>

            <HeaderCol span={11}>
              <CaptionDestinationText>
                {item?.location_destination_kakudai}
              </CaptionDestinationText>
            </HeaderCol>
            <Col
              span={10}
              style={{ padding: 0, border: '1px solid grey', height: '100%' }}
            >
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                }}
              >
                <TextContainer>
                  <TitleLabelBorder>Cluster :</TitleLabelBorder>
                  <TitleClusterValue>
                    {item.cluster_kakudai ? item.cluster_kakudai : '-'}
                  </TitleClusterValue>
                </TextContainer>
                <TextContainer>
                  <TitleLabelBorder>Series Name :</TitleLabelBorder>
                  <TitleValue>{item.series ? item?.series : '-'}</TitleValue>
                </TextContainer>
                <TextContainer>
                  <TitleLabelBorder>Model :</TitleLabelBorder>
                  <TitleValue>
                    {item.model_code ? item?.model_code : '-'}
                  </TitleValue>
                </TextContainer>
                <TextContainer>
                  <TitleLabelBorder>Colour :</TitleLabelBorder>
                  <TitleValue>{item?.color ? item?.color : '-'}</TitleValue>
                </TextContainer>
              </div>
            </Col>
          </Row>
          <Row
            gutter={[24]}
            align='middle'
            justify='center'
            style={{ margin: '0px', height: '128px' }}
          >
            <HeaderCol span={3}>
              <TitleLabelHeader>Route :</TitleLabelHeader>
            </HeaderCol>
            <HeaderCol span={9}>
              <TitleLabel>
                {item?.location_from ? item?.location_from : '-'}
              </TitleLabel>
            </HeaderCol>
            <HeaderCol span={2}>
              <TitleLabelHeader>ไป</TitleLabelHeader>
            </HeaderCol>
            <HeaderCol span={10}>
              <TitleLabel>
                {item?.location_destination_kakudai
                  ? item?.location_destination_kakudai
                  : '-'}
              </TitleLabel>
            </HeaderCol>
          </Row>
          <List
            itemLayout='vertical'
            dataSource={item.arr_item_list}
            renderItem={(item_2) => (
              <List.Item
                style={{
                  padding: '0px',
                  borderColor: 'transparent',
                  textAlign: 'left',
                  borderBottom: '0px',
                }}
              >
                <Row
                  gutter={[24]}
                  align='center'
                  justify='center'
                  style={{ margin: '0px' }}
                >
                  {renderVinComponent(item)}
                  {renderVinComponent(item)}
                </Row>
              </List.Item>
            )}
          />
        </List.Item>
      )}
    />
  );
};
const HeaderCol = styled(Col)`
  border: 1px solid grey;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const CaptionVinNoText = styled.div`
  font-size: 55px;
  font-weight: 500;
`;
const CaptionDestinationText = styled.div`
  font-size: 120px;
  font-weight: 500;
  text-align: center;
  word-wrap: break-word;
`;
const TextContainer = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  text-align: left;
  border: 1px solid grey;
  width: 100%;
`;
const TitleClusterValue = styled.div`
  font-size: 60px;
  font-weight: 500;
  padding-left: 10px;
  height: 100%;
  border-left: 1px solid grey;
  flex: 1;
  padding-top: 5px;
`;
const TitleValue = styled.div`
  font-size: 35px;
  font-weight: 500;
  padding-left: 10px;
  border-left: 1px solid grey;
  height: 100%;
  padding-top: 25px;
  flex: 1;
`;
const TitleLabelHeader = styled.div`
  font-size: 22px;
  font-weight: 500;
`;
const TitleLabel = styled.div`
  font-size: 35px;
  font-weight: 500;
`;
const TitleLabelBorder = styled.div`
  font-size: 22px;
  font-weight: 500;
  text-align: right;
  width: 150px;
  padding-right: 4px;
`;
const VinTypeLabel = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding-right: 4px;
  border: 1px solid grey;
  text-align: center;
  width: 100%;
  margin-bottom: 4px;
`;
const VinClusterLabel = styled.div`
  // margin-top: 4px;
  font-size: 34px;
  font-weight: 500;
  padding-right: 4px;
  border: 1px solid grey;
  text-align: center;
  width: 100%;
  height: 53%;
`;

const VinContainer = styled.div`
  display: flex;
  margin-bottom: -5px;
  padding-bottom: -5px;
`;
const VinTextLabel = styled.div`
  font-size: 18px;
  width: 140px;
`;
const VinTextValue = styled.div`
  flex: 1;
  font-size: 18px;
`;
const VinContent = styled.div`
  font-size: 18px;
`;
const VinContentBold = styled.div`
  font-size: 18px;
  font-weight: 500;
  // text-overflow: ellipsis;
  // overflow: hidden;
  // white-space: nowrap;
`;

export default Kakudai;
