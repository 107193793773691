import React from 'react';
import { Polyline } from '@react-google-maps/api';
// import nearestPointOnLine from '@turf/nearest-point-on-line';
// import lineSplit from '@turf/line-split';
// import { point, lineString } from '@turf/helpers';
import { decode } from '@googlemaps/polyline-codec';
import uuidv4 from 'uuid/v4';

const renderPolylinePath = (route, color, opacity, zIndex) => {
  if (route && route.length > 0) {
    const polylinePath = route.map((p) => ({ lat: p[0], lng: p[1] }));
    return (
      <div key={uuidv4()}>
        <Polyline
          path={polylinePath}
          geodesic={true}
          options={{
            strokeColor: '#ffffff',
            strokeOpacity: opacity,
            strokeWeight: 10,
            zIndex,
          }}
        />
        <Polyline
          path={polylinePath}
          geodesic={true}
          options={{
            strokeColor: color,
            strokeOpacity: opacity,
            strokeWeight: 5,
            zIndex,
          }}
        />
      </div>
    );
  }
};

const RoutePolyline = ({ routes, gpsPoint }) => {
  // let startToCurrentPolyline = [];
  let currentToEndPolyline = [];
  if (routes) {
    currentToEndPolyline = routes
      .map((r) => r.polyline && decode(decodeURIComponent(r.polyline)))
      .flat()
      .filter((r) => r !== null);

    // if (gpsPoint && currentToEndPolyline.length > 1) {
    //   const line = lineString(currentToEndPolyline);
    //   const pt = point(gpsPoint);
    //   const snapped = nearestPointOnLine(line, pt);

    //   const splittedLines = lineSplit(
    //     line,
    //     point(snapped.geometry.coordinates)
    //   );
    //   // Splitted line would return 2 line features
    //   // Get last index for current position to end of the line
    //   if (splittedLines.features.length > 1) {
    //     // startToCurrentPolyline =
    //     //   splittedLines.features[splittedLines.features.length - 2].geometry
    //     //     .coordinates;

    //     currentToEndPolyline =
    //       splittedLines.features[splittedLines.features.length - 1].geometry
    //         .coordinates;
    //   } else {
    //     currentToEndPolyline =
    //       splittedLines.features[splittedLines.features.length - 1].geometry
    //         .coordinates;
    //   }
    // }
  }
  return [
    // renderPolylinePath(startToCurrentPolyline, '#bfbfbf', 1.0, 1000),
    renderPolylinePath(currentToEndPolyline, '#2772ff', 1.0, 1000),
  ];
};
export default RoutePolyline;
