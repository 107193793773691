import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import AntTable from 'common_components/AntTable';
import { timeFormatter } from 'common_components/AntTable/tableUtils';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import {
  Row,
  Col,
  message,
  Modal,
  TimePicker,
  Popconfirm,
  Select,
  Checkbox,
  Button,
} from 'antd';
import Toolbar from './Toolbar';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import {
  dateTimeFormatterWorking,
  dateFormatter,
} from 'common_components/AntTable/tableUtils';
import EditButtonRenderer from 'common_components/EditButtonRenderer';
import moment from 'moment';
import Json2CSV from 'utils/json2csv';
import {
  useUploadFileAssignFixTourMutation,
  useDeleteAllRowMutation,
  useMatchingFixTourMutation,
  useFindAlternateJobcodeMutation,
  fixTourApi,
  useDeleteByIdMutation,
  useFindLocationFromToQuery,
  useEditFixTourRowMutation,
  useConfirmFixTourBypassQuery,
} from 'reducers/fixTour/api';

import { useFindDownloadJobCodeCSVManageTourListMutation } from 'reducers/manageTourList/api';
import './index.css';

const { Option } = Select;

const TableComponent = (props) => {
  const {
    planJobId,
    filterOptionCompany,
    filterOptionTrailerType,
    filterOptionVehicleType,
    companyJobcodeOptions,
    setFilterSelectStatus,
    setFilterSelectCompany,

    setFilterSelectTrailerType,
    filterSelectStatus,
    filterSelectCompany,
    filterSelectTrailerType,
    isLoadingEntry,
    dataSourceTableYard,
    setDataSourceTableYard,
    onGetFilterDataVolumeTable,
    getEntryAssignVolumeQuery,
    optionTripType,
    optionFromTrip,
    optionToTrip,
    dateOfSet,
    onChangeOptionBzp,
    disableFrom,
  } = props;

  const LIMIT = 100;
  const PAGE_SIZE = 50;
  const payloadRef = useRef(null);
  const [totalRows, setTotalRows] = useState(0);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const rowMeargeRef = useRef([]);
  const headerDateTableRef = useRef([]);

  // const receiveJobTimeRef = useRef();

  const [fileList, setFileList] = useState([]);

  const matchingRef = useRef(false);
  const [dataSource, setDataSource] = useState([]);
  const [FailedJobcodeTours, SetFailedJobcodeTours] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  // Editing
  const editingRecordRef = useRef();
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isLoadingDownLoad, setIsLoadingDownLoad] = useState(false);
  const [visibleModalLoading, setVisibleModalLoading] = useState(false);

  const handleOkLoading = () => {
    setVisibleModalLoading(false);
  };

  const handleCancelLoading = () => {
    setVisibleModalLoading(false);
  };
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const { confirm } = Modal;
  const [editingKey, setEditingKey] = useState('');
  const isEditing = (record) => record.id === editingKey;
  const formatTime = 'HH:mm';

  const onEdit = (record) => {
    if (record.status === 'Failed') {
      editingRecordRef.current = {
        ...record,
      };

      setEditingKey(record.id);
    } else {
      Modal.info({
        title: 'ไม่สามารถ แก้ไขข้อมูลได้ !!',
        content: (
          <div>
            <p>เนื่องจาก Status ไม่เท่ากับ Fail</p>
          </div>
        ),

        onOk() {},
      });
    }
  };

  const [
    apiEditFixRow,
    {
      data: dataEditFixRow,
      error: errorEditFixRow,
      isLoading: isLoadingEditFixRow,
    },
  ] = useEditFixTourRowMutation();

  useEffect(() => {
    if (dataEditFixRow) {
      setVisibleModalLoading(false);
      setIsLoadingSearch(false);
      if (dataEditFixRow.message) {
        Modal.info({
          title: dataEditFixRow.message,
          width: 500,
          onOk() {},
        });
        setEditingKey('');
        let filterCompany =
          filterSelectCompany && filterSelectCompany.find((f) => f === 'All');
        let filterTrailerType =
          filterSelectTrailerType &&
          filterSelectTrailerType.find((f) => f === 'All');

        let payload = {
          plan_job_id: planJobId,
          status: filterSelectStatus,
          company: filterCompany ? null : filterSelectCompany,
          trailer_type: filterTrailerType ? null : filterSelectTrailerType,
        };
        setTimeout(() => {
          onBtnSearch(payload);
        }, 3000);
      } else if (dataEditFixRow.status === 'error') {
        var errorList = dataEditFixRow?.errorList;
        var arr_errorList = [];
        for (const [key, value] of Object.entries(errorList)) {
          var oject_key = {
            key: key,
            value: value,
          };

          arr_errorList.push(oject_key);
        }

        const messageErrorList = arr_errorList.map((item) => {
          let title = item.key;
          let content = '';
          if (Array.isArray(item.value)) {
            content = item.value[0];
          } else {
            const keys = Object.keys(item.value);
            keys.forEach((key) => {
              content += item.value[key].unit
                ? `${Number(key) + 1}. unit : ${item.value[key].unit[0]}\n`
                : '';
              content += item.value[key][0]
                ? item.value[key][0]
                : item.value[key].id
                ? `${item.value[key].id[0]}\n`
                : '';
            });
          }
          return { title, content };
        });

        Modal.error({
          title: 'แก้ไขข้อมูล ไม่สำเร็จ',
          content: (
            <div style={{ padding: '0px' }}>
              {' '}
              {messageErrorList.map((item, index) => (
                <div key={index}>
                  <h4>{item.title}</h4>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{item.content}</p>
                </div>
              ))}
            </div>
          ),
          width: 500,
          onOk() {},
        });
      }
    }
  }, [dataEditFixRow]);

  useEffect(() => {
    if (errorEditFixRow) {
      setVisibleModalLoading(false);
      setIsLoadingSearch(false);
      Modal.error({
        title: errorEditFixRow.data.errorList[0].errorMessage,
        icon: <ExclamationCircleOutlined />,
        content: errorEditFixRow.data.errorList[0].errorDetail,
      });
    }
  }, [errorEditFixRow]);
  //----------------------------------------------------------------
  // onDeleteById
  const onDeleteById = (row) => {
    confirm({
      width: 500,
      title: 'Would you like to confirm delete a row data?',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        let payload = {
          planId: planJobId,
          tourId: row.id,
        };
        apiDeleteById(payload);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const updateEditingFieldValue = (newFieldValue) => {
    editingRecordRef.current = {
      ...editingRecordRef.current,
      ...newFieldValue,
    };
  };

  //apiDeleteAllRow -------------------------------------------------------
  const [
    apiDeleteAllRow,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleteAllRow,
      isLoading: isLoadingDeleteAllRow,
    },
  ] = useDeleteAllRowMutation();

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      clearAll();
    }
  }, [isSuccessDeleting, isUninitDeleting]);

  useEffect(() => {
    if (errorDeleteAllRow) {
      Modal.error({
        title: errorDeleteAllRow.errorList[0].errorMessage,
        icon: <ExclamationCircleOutlined />,
        content: errorDeleteAllRow.errorList[0].errorDetail,
      });
    }
  }, [errorDeleteAllRow]);
  // -------------------------------------------------

  // apiDeleteById
  const [
    apiDeleteById,
    {
      data: dataDeleteById,
      error: errorDeleteById,
      isLoading: isLoadingDeleteById,
    },
  ] = useDeleteByIdMutation();

  useEffect(() => {
    if (dataDeleteById) {
      Modal.success({
        content: 'Delete success !!',
      });
      let filterCompany =
        filterSelectCompany && filterSelectCompany.find((f) => f === 'All');
      let filterTrailerType =
        filterSelectTrailerType &&
        filterSelectTrailerType.find((f) => f === 'All');

      let payload = {
        plan_job_id: planJobId,
        status: filterSelectStatus,
        company: filterCompany ? null : filterSelectCompany,
        trailer_type: filterTrailerType ? null : filterSelectTrailerType,
      };
      onBtnSearch(payload);
    }
  }, [dataDeleteById]);

  useEffect(() => {
    if (errorDeleteById) {
      Modal.error({
        title: errorDeleteById.data.errorList[0].errorMessage,
        icon: <ExclamationCircleOutlined />,
        content: errorDeleteById.data.errorList[0].errorDetail,
      });
    }
  }, [errorDeleteById]);

  // -----------------------------------------------------------------
  const [
    apiMatching,
    {
      data: dataMatching,
      isSuccess: isSuccessMatching,
      isUninitialized: isUninitMatching,
      error: errorMatching,
      isLoading: isLoadingMatching,
    },
  ] = useMatchingFixTourMutation();

  const [
    findAlternative,
    {
      data: dataFindAlternative,
      isSuccess: isSuccessFindAlternative,
      isUninitialized: isUninitFindAlternative,
      error: errorFindAlternative,
      isLoading: isLoadingFindAlternative,
    },
  ] = useFindAlternateJobcodeMutation();

  useEffect(() => {
    if (errorFindAlternative) {
      Modal.error({
        title: errorFindAlternative.data.errorList[0].errorMessage,
        icon: <ExclamationCircleOutlined />,
        content: errorFindAlternative.data.errorList[0].errorDetail,
      });
    }
  }, [errorFindAlternative]);

  useEffect(() => {
    if (errorMatching) {
      Modal.error({
        title: errorMatching.data.errorList[0].errorMessage,
        icon: <ExclamationCircleOutlined />,
        content: errorMatching.data.errorList[0].errorDetail,
      });
    }
  }, [errorMatching]);

  useEffect(() => {
    if (dataMatching) {
      Modal.success({
        title: 'Matching completed',
        content: (
          <div>
            <Row style={{ paddingTop: '20px' }}>
              <Col style={{ marginRight: '20px' }}>
                <p>Total : </p>
                <p>Matched : </p>
                <p>Failed : </p>
              </Col>
              <Col>
                <p> {dataMatching?.total}</p>
                <p> {dataMatching?.matched}</p>
                <p> {dataMatching?.failed}</p>
              </Col>
            </Row>
          </div>
        ),
        onOk() {},
      });

      let payload = {
        plan_job_id: planJobId,
      };
      onBtnSearch(payload);
      matchingRef.current = true;
    }
    setUploadFile(null);
  }, [isSuccessMatching, isUninitMatching, dataMatching]);

  useEffect(() => {
    if (!dataFindAlternative) return;

    const data = dataFindAlternative;
    Modal.success({
      title: 'Failed job code resolver',
      content: (
        <div>
          <Row style={{ paddingTop: '20px' }}>
            <Col style={{ marginRight: '20px' }}>
              <p>Pre-Failed Job code: </p>
            </Col>
            <Col>
              <p>{data['pre-failed']}</p>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginRight: '20px' }}>
              <p>Changed Job code : </p>
            </Col>
            <Col>
              <p>{data['post-changed']}</p>
            </Col>
          </Row>
          {data.ok.length > 0 && (
            <Row>
              <Col style={{ marginRight: '20px' }}>Detail:</Col>
              <Col>
                <ol>
                  {data.ok.map((i) => (
                    <li>
                      <del>{i.pre_jobcode}</del> {i.post_jobcode}
                    </li>
                  ))}
                </ol>
              </Col>
            </Row>
          )}
        </div>
      ),
      onOk() {},
    });

    let payload = {
      plan_job_id: planJobId,
    };
    onBtnSearch(payload);
  }, [isSuccessFindAlternative, isUninitFindAlternative, dataFindAlternative]);

  const [
    apiUploadFile,
    {
      data: dataUploadFile,
      isSuccess: isSuccessUploadFile,
      isUninitialized: isUninitUploadFile,
      error: errorUploadFile,
      isLoading: isLoadingUploadFile,
    },
  ] = useUploadFileAssignFixTourMutation();

  useEffect(() => {
    if (dataUploadFile) {
      if (dataUploadFile?.upload_success === false) {
        Modal.error({
          title: 'Error Message file upload !!',
          icon: <ExclamationCircleOutlined />,
          content: (
            <div>
              <Row style={{ paddingTop: '20px' }}>
                <Col style={{ marginRight: '20px' }}>
                  <p>1. Date error : </p>
                  <p>2. Company error : </p>
                  <p>3. Trailer Type error : </p>
                  <p>4. Job code duplicate : </p>
                  <p>5. Trip Type error : </p>
                  <p>6. Primary Data missing : </p>
                  <p>7. First trip missing : </p>
                  <p>8. Second trip missing : </p>
                  <p>9. Third trip missing : </p>
                  <p>10. Four trip missing : </p>
                  <p>11. Five trip missing : </p>
                  <p>12. Location from error : </p>
                  <p>13. Cluster to error : </p>
                  <p>14. Unit error : </p>
                </Col>
                <Col>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.datetime_error?.length > 0
                      ? `[${dataUploadFile?.result?.datetime_error?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.company_error?.length > 0
                      ? `[${dataUploadFile?.result?.company_error?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.trailer_type_error?.length > 0
                      ? `[${dataUploadFile?.result?.trailer_type_error?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.job_code_duplicate?.length > 0
                      ? `[${dataUploadFile?.result?.job_code_duplicate?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.trip_type_error?.length > 0
                      ? `[${dataUploadFile?.result?.trip_type_error?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.primary_data_missing?.length > 0
                      ? `[${dataUploadFile?.result?.primary_data_missing?.toString()}]`
                      : '-'}
                  </p>

                  <p>
                    {' '}
                    {dataUploadFile?.result?.first_trip_missing?.length > 0
                      ? `[${dataUploadFile?.result?.first_trip_missing?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.second_trip_missing?.length > 0
                      ? `[${dataUploadFile?.result?.second_trip_missing?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.third_trip_missing?.length > 0
                      ? `[${dataUploadFile?.result?.third_trip_missing?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.four_trip_missing?.length > 0
                      ? `[${dataUploadFile?.result?.four_trip_missing?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.five_trip_missing?.length > 0
                      ? `[${dataUploadFile?.result?.five_trip_missing?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.location_from_error?.length > 0
                      ? `[${dataUploadFile?.result?.location_from_error?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.cluster_to_error?.length > 0
                      ? `[${dataUploadFile?.result?.cluster_to_error?.toString()}]`
                      : '-'}
                  </p>
                  <p>
                    {' '}
                    {dataUploadFile?.result?.unit_error?.length > 0
                      ? `[${dataUploadFile?.result?.unit_error?.toString()}]`
                      : '-'}
                  </p>
                </Col>
              </Row>
            </div>
          ),

          onOk() {
            setFileList([]);
          },
          width: 600,
        });
      } else {
        Modal.success({
          title: 'Success Message file upload !!',
          content: (
            <div>
              <Row style={{ paddingTop: '20px' }}>
                <Col style={{ marginRight: '20px' }}>
                  <p>Added Record : </p>
                </Col>
                <Col>
                  <p> {dataUploadFile?.result?.number_of_added_record} trips</p>
                </Col>
              </Row>
            </div>
          ),

          onOk() {
            setFileList([]);
          },
        });
      }
      let payload = {
        plan_job_id: planJobId,
      };
      onBtnSearch(payload);
    }
    setUploadFile(null);
  }, [isSuccessUploadFile, isUninitUploadFile, dataUploadFile]);

  useEffect(() => {
    if (errorUploadFile) {
      Modal.error({
        title: errorUploadFile.data.errorList[0].errorMessage,
        icon: <ExclamationCircleOutlined />,
        content: errorUploadFile.data.errorList[0].errorDetail,
      });
      setUploadFile(null);
      setFileList([]);
    }
  }, [errorUploadFile]);

  const clearAll = () => {
    setDataSource([]);
  };

  const confirmEditRow = () => {
    setVisibleModalLoading(true);
    setIsLoadingSearch(true);

    let dataRecord = editingRecordRef.current;
    let body = {
      plan_job_id: planJobId,
      tour_id: dataRecord?.id,
      company: { id: dataRecord?.company?.id },
      trailer_type: { id: dataRecord?.trailer_type?.id },
      job_code: dataRecord?.job_code,
      trip_type: { id: dataRecord?.trip_type?.id },
      trips: dataRecord?.trips,
      calendar: dataRecord?.calendar,
    };
    apiEditFixRow(body);
  };

  const columnsHeaderYard = [
    {
      title: '',
      width: 40,
      dataIndex: 'action',
      align: 'center',
      fixed: 'left',
      hide: editingKey != null && editingKey !== '' ? false : true,
      render: (_, record) => {
        const editable = isEditing(record);
        if (editable) {
          return (
            <Popconfirm
              title='Please selection action'
              onConfirm={() => {
                confirmEditRow();
              }}
              onCancel={() => {
                setEditingKey('');
              }}
              okText='Save'
              cancelText='Cancel'
            >
              <EditButtonRenderer />
            </Popconfirm>
          );
        } else {
          return <div>-</div>;
        }
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      align: 'center',
      fixed: 'left',
    },
    {
      title: 'Select',
      align: 'center',
      fixed: 'left',
      children: [
        {
          title: 'Company',
          dataIndex: ['company', 'company'],
          align: 'center',
          fixed: 'left',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '80px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value) => {
                    let findValue = filterOptionCompany.find(
                      (d) => d.value === value
                    );
                    if (findValue) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[0] = updatedTrips[0]
                        ? {
                            ...updatedTrips[0],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[1] = updatedTrips[1]
                        ? {
                            ...updatedTrips[1],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[2] = updatedTrips[2]
                        ? {
                            ...updatedTrips[2],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[3] = updatedTrips[3]
                        ? {
                            ...updatedTrips[3],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[4] = updatedTrips[4]
                        ? {
                            ...updatedTrips[4],
                            unit: undefined,
                          }
                        : null;
                      updateEditingFieldValue({
                        trips: updatedTrips.filter((item) => item !== null),
                        company: {
                          id: findValue.value,
                          company: findValue.label,
                        },
                        // job_code: null,
                        // trailer_type: value ? record?.trailer_type : null,
                      });
                      onChangeOptionBzp(value);
                      // Force re-render to update job codes options
                      forceUpdate();
                    } else {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[0] = updatedTrips[0]
                        ? {
                            ...updatedTrips[0],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[1] = updatedTrips[1]
                        ? {
                            ...updatedTrips[1],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[2] = updatedTrips[2]
                        ? {
                            ...updatedTrips[2],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[3] = updatedTrips[3]
                        ? {
                            ...updatedTrips[3],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[4] = updatedTrips[4]
                        ? {
                            ...updatedTrips[4],
                            unit: undefined,
                          }
                        : null;
                      updateEditingFieldValue({
                        trips: updatedTrips.filter((item) => item !== null),

                        company: null,
                        job_code: null,
                        trailer_type: null,
                      });
                      forceUpdate();
                    }
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Company'}
                  defaultValue={record.company.id}
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {filterOptionCompany.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'Trailer type',
          dataIndex: ['trailer_type', 'name'],
          align: 'center',
          fixed: 'left',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '150px',
                    fontSize: 12,
                  }}
                  size='small'
                  loading={isLoadingEditFixRow}
                  onSelect={(value) => {
                    let findValue = filterOptionTrailerType.find(
                      (d) => d.value === value
                    );

                    if (findValue) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[0] = updatedTrips[0]
                        ? {
                            ...updatedTrips[0],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[1] = updatedTrips[1]
                        ? {
                            ...updatedTrips[1],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[2] = updatedTrips[2]
                        ? {
                            ...updatedTrips[2],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[3] = updatedTrips[3]
                        ? {
                            ...updatedTrips[3],
                            unit: undefined,
                          }
                        : null;
                      updatedTrips[4] = updatedTrips[4]
                        ? {
                            ...updatedTrips[4],
                            unit: undefined,
                          }
                        : null;
                      updateEditingFieldValue({
                        trips: updatedTrips.filter((item) => item !== null),
                        trailer_type: {
                          id: findValue.value,
                          name: findValue.label,
                          maximum_loading: findValue?.maximum_loading,
                        },
                      });
                      // Force re-render
                      forceUpdate();
                    }
                  }}
                  onClear={() => {
                    const updatedTrips = [...editingRecordRef.current.trips];
                    updatedTrips[0] = updatedTrips[0]
                      ? {
                          ...updatedTrips[0],
                          unit: undefined,
                        }
                      : null;
                    updatedTrips[1] = updatedTrips[1]
                      ? {
                          ...updatedTrips[1],
                          unit: undefined,
                        }
                      : null;
                    updatedTrips[2] = updatedTrips[2]
                      ? {
                          ...updatedTrips[2],
                          unit: undefined,
                        }
                      : null;
                    updatedTrips[3] = updatedTrips[3]
                      ? {
                          ...updatedTrips[3],
                          unit: undefined,
                        }
                      : null;
                    updatedTrips[4] = updatedTrips[4]
                      ? {
                          ...updatedTrips[4],
                          unit: undefined,
                        }
                      : null;
                    updateEditingFieldValue({
                      trips: updatedTrips.filter((item) => item !== null),
                      trailer_type: null,
                    });
                    // Force re-render
                    forceUpdate();
                  }}
                  onDropdownVisibleChange={(d) => {
                    onChangeOptionBzp(editingRecordRef.current?.company?.id);
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  // defaultValue={record.trailer_type.id}
                  value={editingRecordRef.current?.trailer_type?.id}
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {filterOptionTrailerType?.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return {
                props: {
                  style: {
                    background: ['trailer'].includes(record.failed_reason)
                      ? '#ffaa00'
                      : '',
                  },
                },
                children: <div>{value}</div>,
              };
            }
          },
        },
        {
          title: 'Job code',
          dataIndex: 'job_code',
          align: 'center',
          fixed: 'left',
          render: (text, record) => {
            const editable = isEditing(record);
            if (editable) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '100px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value) => {
                    updateEditingFieldValue({
                      job_code: value,
                    });
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Job code'}
                  // defaultValue={record.job_code}
                  value={editingRecordRef.current?.job_code}
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {(() => {
                    let formValues = editingRecordRef.current;
                    let companyId = formValues?.company?.id;
                    let findValue = companyJobcodeOptions.find(
                      (c) => c.id === companyId
                    );
                    if (findValue) {
                      return findValue.jobcodes.map((d, index) => {
                        return (
                          <Option key={index} value={d}>
                            {d}
                          </Option>
                        );
                      });
                    }
                  })()}
                </Select>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background: ['both', 'job_code'].includes(
                          record.failed_reason
                        )
                          ? '#ff0000'
                          : ['trailer'].includes(record.failed_reason)
                          ? '#ffaa00'
                          : '',
                      },
                    },
                    children: <div>{text}</div>,
                  };
                } else {
                  return text;
                }
              } else {
                return null;
              }
            }
          },
        },
        {
          title: 'Trip type',
          dataIndex: ['trip_type', 'name_en'],
          align: 'center',
          fixed: 'left',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '100px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value) => {
                    let findValue = optionTripType.find(
                      (d) => d.value === value
                    );
                    if (findValue) {
                      updateEditingFieldValue({
                        trip_type: {
                          id: findValue.value,
                          name: findValue.label,
                        },
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trip type'}
                  defaultValue={record.trip_type.id}
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionTripType.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
      ],
    },
    {
      title: 'Trip no.1',
      align: 'center',
      children: [
        {
          title: () => {
            return (
              <div>
                Receive job <br />
                time
              </div>
            );
          },
          dataIndex: ['trips', '0', 'time', 'receive_job_time'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[0]
              ? record?.trips[0]?.receive_job_time?.time
              : '-';
            if (editable && record?.trips[0] !== undefined) {
              return (
                <div>
                  <TimePicker
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[0] = {
                          ...updatedTrips[0],
                          receive_job_time: {
                            time: value.format('HH:mm'),
                            day_offset:
                              updatedTrips[0].receive_job_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      width: '50px',
                      fontSize: 12,
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      // console.log('value', value);
                      updatedTrips[0] = {
                        ...updatedTrips[0],
                        receive_job_time: {
                          time: updatedTrips[0].receive_job_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });

                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[0]
                        ? record.trips[0]?.receive_job_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[0] ? (
                <div>
                  {(record?.trips[0]?.receive_job_time?.time).substring(0, 5)}{' '}
                  {record?.trips[0]?.receive_job_time?.day_offset !== 0
                    ? '(+' +
                      record?.trips[0]?.receive_job_time?.day_offset +
                      ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'Yard out',
          dataIndex: ['trips', '0', 'time', 'yardout_time'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[0]
              ? record?.trips[0]?.yardout_time?.time
              : '-';
            if (editable && record?.trips[0] !== undefined) {
              return (
                <div>
                  <TimePicker
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[0] = {
                          ...updatedTrips[0],
                          yardout_time: {
                            time: value.format('HH:mm'),
                            day_offset: updatedTrips[0].yardout_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      width: '50px',
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[0] = {
                        ...updatedTrips[0],
                        yardout_time: {
                          time: updatedTrips[0].yardout_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[0]
                        ? record.trips[0]?.yardout_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[0] ? (
                <div>
                  {(record?.trips[0]?.yardout_time?.time).substring(0, 5)}{' '}
                  {record?.trips[0]?.yardout_time?.day_offset !== 0
                    ? '(+' + record?.trips[0]?.yardout_time?.day_offset + ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'From',
          dataIndex: ['trips', '0', 'location_from', 'short_name'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[0] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '120px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[0] = {
                        ...updatedTrips[0],
                        location_from: {
                          bzp_code: value,
                          company: value,
                          id: value,
                          name: value,
                          short_name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[0] ? record.trips[0]?.location_from?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionFromTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'To',
          dataIndex: ['trips', '0', 'location_to', 'name'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[0] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '80px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[0] = {
                        ...updatedTrips[0],
                        location_to: {
                          id: value,
                          name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[0] ? record.trips[0]?.location_to?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionToTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'Unit',
          dataIndex: ['trips', '0', 'unit'],
          align: 'center',
          render: (value, record) => {
            let arrLoading = [];
            for (
              let index = 1;
              index <= editingRecordRef.current?.trailer_type?.maximum_loading;
              index++
            ) {
              arrLoading.push({
                label: index,
                value: index,
              });
            }

            const editable = isEditing(record);
            if (editable && record?.trips[0] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '50px',
                    fontSize: 12,
                  }}
                  size='small'
                  onSelect={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[0] = {
                        ...updatedTrips[0],
                        unit: value,
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }

                    // Force re-render
                    forceUpdate();
                  }}
                  onClear={() => {
                    const updatedTrips = [...editingRecordRef.current.trips];
                    updatedTrips[0] = {
                      ...updatedTrips[0],
                      unit: undefined,
                    };
                    updateEditingFieldValue({
                      trips: updatedTrips,
                    });
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'unit'}
                  value={
                    editingRecordRef.current.trips.length > 0
                      ? editingRecordRef.current.trips[0]?.unit
                      : undefined
                  }
                >
                  {arrLoading.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
      ],
    },
    {
      title: 'Trip no.2',
      align: 'center',
      children: [
        {
          title: () => {
            return (
              <div>
                Receive job <br />
                time
              </div>
            );
          },
          dataIndex: ['trips', '1', 'time', 'receive_job_time'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[1]
              ? record?.trips[1]?.receive_job_time?.time
              : '-';
            if (editable && record?.trips[1] !== undefined) {
              return (
                <div>
                  <TimePicker
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[1] = {
                          ...updatedTrips[1],
                          receive_job_time: {
                            time: value.format('HH:mm'),
                            day_offset:
                              updatedTrips[1].receive_job_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      width: '50px',
                      fontSize: 12,
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[1] = {
                        ...updatedTrips[1],
                        receive_job_time: {
                          time: updatedTrips[1].receive_job_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[1]
                        ? record.trips[1]?.receive_job_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[1] ? (
                <div>
                  {(record?.trips[1]?.receive_job_time?.time).substring(0, 5)}{' '}
                  {record?.trips[1]?.receive_job_time?.day_offset !== 0
                    ? '(+' +
                      record?.trips[1]?.receive_job_time?.day_offset +
                      ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'Yard out',
          dataIndex: ['trips', '1', 'time', 'yardout_time'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[1]
              ? record?.trips[1]?.yardout_time?.time
              : '-';
            if (editable && record?.trips[1] !== undefined) {
              return (
                <div>
                  <TimePicker
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[1] = {
                          ...updatedTrips[1],
                          yardout_time: {
                            time: value.format('HH:mm'),
                            day_offset: updatedTrips[1].yardout_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      width: '50px',
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[1] = {
                        ...updatedTrips[1],
                        yardout_time: {
                          time: updatedTrips[1].yardout_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[1]
                        ? record.trips[1]?.yardout_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[1] ? (
                <div>
                  {(record?.trips[1]?.yardout_time?.time).substring(0, 5)}{' '}
                  {record?.trips[1]?.yardout_time?.day_offset !== 0
                    ? '(+' + record?.trips[1]?.yardout_time?.day_offset + ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'From',
          dataIndex: ['trips', '1', 'location_from', 'short_name'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[1] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '120px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[1] = {
                        ...updatedTrips[1],
                        location_from: {
                          bzp_code: value,
                          company: value,
                          id: value,
                          name: value,
                          short_name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[1] ? record.trips[1]?.location_from?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionFromTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'To',
          dataIndex: ['trips', '1', 'location_to', 'name'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[1] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '80px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[1] = {
                        ...updatedTrips[1],
                        location_to: {
                          id: value,
                          name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[1] ? record.trips[1]?.location_to?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionToTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },

        {
          title: 'Unit',
          dataIndex: ['trips', '1', 'unit'],
          align: 'center',
          render: (value, record) => {
            let arrLoading = [];
            for (
              let index = 1;
              index <= editingRecordRef.current?.trailer_type?.maximum_loading;
              index++
            ) {
              arrLoading.push({
                label: index,
                value: index,
              });
            }

            const editable = isEditing(record);
            if (editable && record?.trips[1] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '50px',
                    fontSize: 12,
                  }}
                  size='small'
                  onSelect={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[1] = {
                        ...updatedTrips[1],
                        unit: value,
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }

                    // Force re-render
                    forceUpdate();
                  }}
                  onClear={() => {
                    const updatedTrips = [...editingRecordRef.current.trips];
                    updatedTrips[1] = {
                      ...updatedTrips[1],
                      unit: undefined,
                    };
                    updateEditingFieldValue({
                      trips: updatedTrips,
                    });
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'unit'}
                  value={
                    editingRecordRef.current.trips.length > 0
                      ? editingRecordRef.current.trips[1]?.unit
                      : undefined
                  }
                >
                  {arrLoading.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
      ],
    },

    {
      title: 'Trip no.3',
      align: 'center',
      children: [
        {
          title: () => {
            return (
              <div>
                Receive job <br />
                time
              </div>
            );
          },
          dataIndex: ['trips', '2', 'time', 'receive_job_time'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[2]
              ? record?.trips[2]?.receive_job_time?.time
              : '-';
            if (editable && record?.trips[2] !== undefined) {
              return (
                <div>
                  <TimePicker
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[2] = {
                          ...updatedTrips[2],
                          receive_job_time: {
                            time: value.format('HH:mm'),
                            day_offset:
                              updatedTrips[2].receive_job_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      width: '50px',
                      fontSize: 12,
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[2] = {
                        ...updatedTrips[2],
                        receive_job_time: {
                          time: updatedTrips[2].receive_job_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[2]
                        ? record.trips[2]?.receive_job_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[2] ? (
                <div>
                  {(record?.trips[2]?.receive_job_time?.time).substring(0, 5)}{' '}
                  {record?.trips[2]?.receive_job_time?.day_offset !== 0
                    ? '(+' +
                      record?.trips[2]?.receive_job_time?.day_offset +
                      ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'Yard out',
          dataIndex: ['trips', '2', 'time', 'yardout_time'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[2]
              ? record?.trips[2]?.yardout_time?.time
              : '-';
            if (editable && record?.trips[2] !== undefined) {
              return (
                <div>
                  <TimePicker
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    format={formatTime}
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[2] = {
                        ...updatedTrips[2],
                        yardout_time: {
                          time: value.format('HH:mm'),
                          day_offset: updatedTrips[2].yardout_time.day_offset,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      width: '50px',
                    }}
                    size='small'
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[2] = {
                          ...updatedTrips[2],
                          yardout_time: {
                            time: updatedTrips[2].yardout_time.time,
                            day_offset: value,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[2]
                        ? record.trips[2]?.yardout_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[2] ? (
                <div>
                  {(record?.trips[2]?.yardout_time?.time).substring(0, 5)}{' '}
                  {record?.trips[2]?.yardout_time?.day_offset !== 0
                    ? '(+' + record?.trips[2]?.yardout_time?.day_offset + ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'From',
          dataIndex: ['trips', '2', 'location_from', 'short_name'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[2] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '120px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[2] = {
                        ...updatedTrips[2],
                        location_from: {
                          bzp_code: value,
                          company: value,
                          id: value,
                          name: value,
                          short_name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[2] ? record.trips[2]?.location_from?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionFromTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'To',
          dataIndex: ['trips', '2', 'location_to', 'name'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[2] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '80px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[2] = {
                        ...updatedTrips[2],
                        location_to: {
                          id: value,
                          name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[2] ? record.trips[2]?.location_to?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionToTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'Unit',
          dataIndex: ['trips', '2', 'unit'],
          align: 'center',
          render: (value, record) => {
            let arrLoading = [];
            for (
              let index = 1;
              index <= editingRecordRef.current?.trailer_type?.maximum_loading;
              index++
            ) {
              arrLoading.push({
                label: index,
                value: index,
              });
            }

            const editable = isEditing(record);
            if (editable && record?.trips[2] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '50px',
                    fontSize: 12,
                  }}
                  size='small'
                  onSelect={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[2] = {
                        ...updatedTrips[2],
                        unit: value,
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }

                    // Force re-render
                    forceUpdate();
                  }}
                  onClear={() => {
                    const updatedTrips = [...editingRecordRef.current.trips];
                    updatedTrips[2] = {
                      ...updatedTrips[2],
                      unit: undefined,
                    };
                    updateEditingFieldValue({
                      trips: updatedTrips,
                    });
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'unit'}
                  value={
                    editingRecordRef.current.trips.length > 0
                      ? editingRecordRef.current.trips[2]?.unit
                      : undefined
                  }
                >
                  {arrLoading.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
      ],
    },
    {
      title: 'Trip no.4',
      align: 'center',
      children: [
        {
          title: () => {
            return (
              <div>
                Receive job <br />
                time
              </div>
            );
          },
          dataIndex: ['trips', '3', 'time', 'receive_job_time'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[3]
              ? record?.trips[3]?.receive_job_time?.time
              : '-';

            if (editable && record?.trips[3] !== undefined) {
              return (
                <div>
                  <TimePicker
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[3] = {
                          ...updatedTrips[3],
                          receive_job_time: {
                            time: value.format('HH:mm'),
                            day_offset:
                              updatedTrips[3].receive_job_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      width: '50px',
                      fontSize: 12,
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[3] = {
                        ...updatedTrips[3],
                        receive_job_time: {
                          time: updatedTrips[3].receive_job_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[3]
                        ? record.trips[3]?.receive_job_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[3] ? (
                <div>
                  {(record?.trips[3]?.receive_job_time?.time).substring(0, 5)}{' '}
                  {record?.trips[3]?.receive_job_time?.day_offset !== 0
                    ? '(+' +
                      record?.trips[3]?.receive_job_time?.day_offset +
                      ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'Yard out',
          dataIndex: ['trips', '3', 'time', 'yardout_time'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[3]
              ? record?.trips[3]?.yardout_time?.time
              : '-';
            if (editable && record?.trips[3] !== undefined) {
              return (
                <div>
                  <TimePicker
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[3] = {
                          ...updatedTrips[3],
                          yardout_time: {
                            time: value.format('HH:mm'),
                            day_offset: updatedTrips[3].yardout_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      width: '50px',
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[3] = {
                        ...updatedTrips[3],
                        yardout_time: {
                          time: updatedTrips[3].yardout_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[3]
                        ? record.trips[3]?.yardout_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[3] ? (
                <div>
                  {(record?.trips[3]?.yardout_time?.time).substring(0, 5)}{' '}
                  {record?.trips[3]?.yardout_time?.day_offset !== 0
                    ? '(+' + record?.trips[3]?.yardout_time?.day_offset + ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'From',
          dataIndex: ['trips', '3', 'location_from', 'short_name'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[3] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '120px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[3] = {
                        ...updatedTrips[3],
                        location_from: {
                          bzp_code: value,
                          company: value,
                          id: value,
                          name: value,
                          short_name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[3] ? record.trips[3]?.location_from?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionFromTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'To',
          dataIndex: ['trips', '3', 'location_to', 'name'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[3] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '80px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[3] = {
                        ...updatedTrips[3],
                        location_to: {
                          id: value,
                          name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[3] ? record.trips[3]?.location_to?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionToTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'Unit',
          dataIndex: ['trips', '3', 'unit'],
          align: 'center',
          render: (value, record) => {
            let arrLoading = [];
            for (
              let index = 1;
              index <= editingRecordRef.current?.trailer_type?.maximum_loading;
              index++
            ) {
              arrLoading.push({
                label: index,
                value: index,
              });
            }

            const editable = isEditing(record);
            if (editable && record?.trips[3] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '50px',
                    fontSize: 12,
                  }}
                  size='small'
                  onSelect={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[3] = {
                        ...updatedTrips[3],
                        unit: value,
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }

                    // Force re-render
                    forceUpdate();
                  }}
                  onClear={() => {
                    const updatedTrips = [...editingRecordRef.current.trips];
                    updatedTrips[3] = {
                      ...updatedTrips[3],
                      unit: undefined,
                    };
                    updateEditingFieldValue({
                      trips: updatedTrips,
                    });
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'unit'}
                  value={
                    editingRecordRef.current.trips.length > 0
                      ? editingRecordRef.current.trips[3]?.unit
                      : undefined
                  }
                >
                  {arrLoading.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
      ],
    },
    {
      title: 'Trip no.5',
      align: 'center',
      children: [
        {
          title: () => {
            return (
              <div>
                Receive job <br />
                time
              </div>
            );
          },
          dataIndex: ['trips', '4', 'time', 'receive_job_time'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[4]
              ? record?.trips[4]?.receive_job_time?.time
              : '-';
            if (editable && record?.trips[4] !== undefined) {
              return (
                <div>
                  <TimePicker
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[4] = {
                          ...updatedTrips[4],
                          receive_job_time: {
                            time: value.format('HH:mm'),
                            day_offset:
                              updatedTrips[4].receive_job_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      width: '50px',
                      fontSize: 12,
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[4] = {
                        ...updatedTrips[4],
                        receive_job_time: {
                          time: updatedTrips[4].receive_job_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[4]
                        ? record.trips[4]?.receive_job_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[4] ? (
                <div>
                  {(record?.trips[4]?.receive_job_time?.time).substring(0, 5)}{' '}
                  {record?.trips[4]?.receive_job_time?.day_offset !== 0
                    ? '(+' +
                      record?.trips[4]?.receive_job_time?.day_offset +
                      ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'Yard out',
          dataIndex: ['trips', '4', 'time', 'yardout_time'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            const time = record?.trips[4]
              ? record?.trips[4]?.yardout_time?.time
              : '-';
            if (editable && record?.trips[4] !== undefined) {
              return (
                <div>
                  <TimePicker
                    style={{
                      textAlign: 'center',
                      width: '70px',
                      fontSize: 12,
                    }}
                    defaultValue={moment(time, formatTime)}
                    size='small'
                    format={formatTime}
                    onChange={(value, record) => {
                      if (value) {
                        const updatedTrips = [
                          ...editingRecordRef.current.trips,
                        ];
                        updatedTrips[4] = {
                          ...updatedTrips[4],
                          yardout_time: {
                            time: value.format('HH:mm'),
                            day_offset: updatedTrips[4].yardout_time.day_offset,
                          },
                        };
                        updateEditingFieldValue({
                          trips: updatedTrips,
                        });
                      }
                      // Force re-render
                      forceUpdate();
                    }}
                  />

                  <Select
                    style={{
                      textAlign: 'center',
                      fontSize: 12,
                      width: '50px',
                    }}
                    size='small'
                    onChange={(value, record) => {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[4] = {
                        ...updatedTrips[4],
                        yardout_time: {
                          time: updatedTrips[4].yardout_time.time,
                          day_offset: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                      // Force re-render
                      forceUpdate();
                    }}
                    maxTagCount='responsive'
                    placeholder={'day offset'}
                    defaultValue={
                      record?.trips[4]
                        ? record.trips[4]?.yardout_time?.day_offset
                        : '-'
                    }
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                  >
                    {dateOfSet.map((c, index) => (
                      <Option key={index} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                  </Select>
                </div>
              );
            } else {
              return record?.trips[4] ? (
                <div>
                  {(record?.trips[4]?.yardout_time?.time).substring(0, 5)}{' '}
                  {record?.trips[4]?.yardout_time?.day_offset !== 0
                    ? '(+' + record?.trips[4]?.yardout_time?.day_offset + ')'
                    : ''}
                </div>
              ) : (
                '-'
              );
            }
          },
        },
        {
          title: 'From',
          dataIndex: ['trips', '4', 'location_from', 'short_name'],
          align: 'center',

          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[4] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '120px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[4] = {
                        ...updatedTrips[4],
                        location_from: {
                          bzp_code: value,
                          company: value,
                          id: value,
                          name: value,
                          short_name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[4] ? record.trips[4]?.location_from?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionFromTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'To',
          dataIndex: ['trips', '4', 'location_to', 'name'],
          align: 'center',
          render: (value, record) => {
            const editable = isEditing(record);
            if (editable && record?.trips[4] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '80px',
                    fontSize: 12,
                  }}
                  size='small'
                  onChange={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[4] = {
                        ...updatedTrips[4],
                        location_to: {
                          id: value,
                          name: value,
                        },
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'Trailer Type'}
                  defaultValue={
                    record?.trips[4] ? record.trips[4]?.location_to?.id : '-'
                  }
                  optionFilterProp='children'
                  filterOption={(input, option) => {
                    return option.children
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {optionToTrip.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
        {
          title: 'Unit',
          dataIndex: ['trips', '4', 'unit'],
          align: 'center',
          render: (value, record) => {
            let arrLoading = [];
            for (
              let index = 1;
              index <= editingRecordRef.current?.trailer_type?.maximum_loading;
              index++
            ) {
              arrLoading.push({
                label: index,
                value: index,
              });
            }

            const editable = isEditing(record);
            if (editable && record?.trips[4] !== undefined) {
              return (
                <Select
                  style={{
                    textAlign: 'center',
                    width: '50px',
                    fontSize: 12,
                  }}
                  size='small'
                  onSelect={(value, record) => {
                    if (value) {
                      const updatedTrips = [...editingRecordRef.current.trips];
                      updatedTrips[4] = {
                        ...updatedTrips[4],
                        unit: value,
                      };
                      updateEditingFieldValue({
                        trips: updatedTrips,
                      });
                    }

                    // Force re-render
                    forceUpdate();
                  }}
                  onClear={() => {
                    const updatedTrips = [...editingRecordRef.current.trips];
                    updatedTrips[4] = {
                      ...updatedTrips[4],
                      unit: undefined,
                    };
                    updateEditingFieldValue({
                      trips: updatedTrips,
                    });
                    // Force re-render
                    forceUpdate();
                  }}
                  showSearch
                  allowClear={true}
                  maxTagCount='responsive'
                  placeholder={'unit'}
                  value={
                    editingRecordRef.current.trips.length > 0
                      ? editingRecordRef.current.trips[4]?.unit
                      : undefined
                  }
                >
                  {arrLoading.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
                </Select>
              );
            } else {
              return <div>{value}</div>;
            }
          },
        },
      ],
    },
    {
      title: 'Calendar',
      dataIndex: 'date',
      align: 'center',
      children: [
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[0]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '0', 'work'],
          key: 1,
          align: 'center',
          fixed: 'right',

          render: (text, record) => {
            const editable = isEditing(record);
            if (
              editable &&
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[0]?.date
            ) {
              return (
                <Checkbox
                  defaultChecked={
                    record?.calendar[0] ? record?.calendar[0].work : false
                  }
                  onChange={(e) => {
                    if (e) {
                      const updatedDate = [
                        ...editingRecordRef.current.calendar,
                      ];

                      updatedDate[0] = {
                        ...updatedDate[0],
                        date: updatedDate[0].date,
                        work: e.target.checked,
                      };
                      updateEditingFieldValue({
                        calendar: updatedDate,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                ></Checkbox>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background:
                          record.failed_reason === 'calendar' ||
                          record.failed_reason === 'both'
                            ? '#ff0000'
                            : '',
                      },
                    },
                    children: (
                      <div>
                        {<CheckOutlined style={{ fontSize: '15px' }} />}
                      </div>
                    ),
                  };
                } else {
                  return <CheckOutlined style={{ fontSize: '15px' }} />;
                }
              } else {
                return null;
              }
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[1]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '1', 'work'],
          key: 2,
          fixed: 'right',
          align: 'center',
          render: (text, record) => {
            const editable = isEditing(record);
            if (
              editable &&
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[1]?.date
            ) {
              return (
                <Checkbox
                  defaultChecked={
                    record?.calendar[1] ? record?.calendar[1].work : false
                  }
                  onChange={(e) => {
                    if (e) {
                      const updatedDate = [
                        ...editingRecordRef.current.calendar,
                      ];

                      updatedDate[1] = {
                        ...updatedDate[1],
                        date: updatedDate[1].date,
                        work: e.target.checked,
                      };
                      updateEditingFieldValue({
                        calendar: updatedDate,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                ></Checkbox>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background:
                          record.failed_reason === 'calendar' ||
                          record.failed_reason === 'both'
                            ? '#ff0000'
                            : '',
                      },
                    },
                    children: (
                      <div>
                        {<CheckOutlined style={{ fontSize: '15px' }} />}
                      </div>
                    ),
                  };
                } else {
                  return <CheckOutlined style={{ fontSize: '15px' }} />;
                }
              } else {
                return null;
              }
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[2]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '2', 'work'],
          key: 3,
          align: 'center',
          fixed: 'right',
          render: (text, record) => {
            const editable = isEditing(record);
            if (
              editable &&
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[2]?.date
            ) {
              return (
                <Checkbox
                  defaultChecked={
                    record?.calendar[2] ? record?.calendar[2].work : false
                  }
                  onChange={(e) => {
                    if (e) {
                      const updatedDate = [
                        ...editingRecordRef.current.calendar,
                      ];

                      updatedDate[2] = {
                        ...updatedDate[2],
                        date: updatedDate[2].date,
                        work: e.target.checked,
                      };
                      updateEditingFieldValue({
                        calendar: updatedDate,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                ></Checkbox>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background:
                          record.failed_reason === 'calendar' ||
                          record.failed_reason === 'both'
                            ? '#ff0000'
                            : '',
                      },
                    },
                    children: (
                      <div>
                        {<CheckOutlined style={{ fontSize: '15px' }} />}
                      </div>
                    ),
                  };
                } else {
                  return <CheckOutlined style={{ fontSize: '15px' }} />;
                }
              } else {
                return null;
              }
            }
          },
        },

        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[3]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '3', 'work'],
          key: 4,
          fixed: 'right',
          align: 'center',
          render: (text, record) => {
            const editable = isEditing(record);
            if (
              editable &&
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[3]?.date
            ) {
              return (
                <Checkbox
                  defaultChecked={
                    record?.calendar[3] ? record?.calendar[3].work : false
                  }
                  onChange={(e) => {
                    if (e) {
                      const updatedDate = [
                        ...editingRecordRef.current.calendar,
                      ];

                      updatedDate[3] = {
                        ...updatedDate[3],
                        date: updatedDate[3].date,
                        work: e.target.checked,
                      };
                      updateEditingFieldValue({
                        calendar: updatedDate,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                ></Checkbox>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background:
                          record.failed_reason === 'calendar' ||
                          record.failed_reason === 'both'
                            ? '#ff0000'
                            : '',
                      },
                    },
                    children: (
                      <div>
                        {<CheckOutlined style={{ fontSize: '15px' }} />}
                      </div>
                    ),
                  };
                } else {
                  return <CheckOutlined style={{ fontSize: '15px' }} />;
                }
              } else {
                return null;
              }
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[4]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '4', 'work'],
          key: 5,
          fixed: 'right',
          align: 'center',
          render: (text, record) => {
            const editable = isEditing(record);
            if (
              editable &&
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[4]?.date
            ) {
              return (
                <Checkbox
                  defaultChecked={
                    record?.calendar[4] ? record?.calendar[4].work : false
                  }
                  onChange={(e) => {
                    if (e) {
                      const updatedDate = [
                        ...editingRecordRef.current.calendar,
                      ];

                      updatedDate[4] = {
                        ...updatedDate[4],
                        date: updatedDate[4].date,
                        work: e.target.checked,
                      };
                      updateEditingFieldValue({
                        calendar: updatedDate,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                ></Checkbox>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background:
                          record.failed_reason === 'calendar' ||
                          record.failed_reason === 'both'
                            ? '#ff0000'
                            : '',
                      },
                    },
                    children: (
                      <div>
                        {<CheckOutlined style={{ fontSize: '15px' }} />}
                      </div>
                    ),
                  };
                } else {
                  return <CheckOutlined style={{ fontSize: '15px' }} />;
                }
              } else {
                return null;
              }
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[5]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '5', 'work'],
          key: 6,
          fixed: 'right',
          align: 'center',
          render: (text, record) => {
            const editable = isEditing(record);
            if (
              editable &&
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[5]?.date
            ) {
              return (
                <Checkbox
                  defaultChecked={
                    record?.calendar[5] ? record?.calendar[5].work : false
                  }
                  onChange={(e) => {
                    if (e) {
                      const updatedDate = [
                        ...editingRecordRef.current.calendar,
                      ];
                      updatedDate[5] = {
                        ...updatedDate[5],
                        date: updatedDate[5].date,
                        work: e.target.checked,
                      };
                      updateEditingFieldValue({
                        calendar: updatedDate,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                ></Checkbox>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background:
                          record.failed_reason === 'calendar' ||
                          record.failed_reason === 'both'
                            ? '#ff0000'
                            : '',
                      },
                    },
                    children: (
                      <div>
                        {<CheckOutlined style={{ fontSize: '15px' }} />}
                      </div>
                    ),
                  };
                } else {
                  return <CheckOutlined style={{ fontSize: '15px' }} />;
                }
              } else {
                return null;
              }
            }
          },
        },
        {
          title: () => {
            let date =
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[6]?.date;
            return (
              <div>
                {date ? dateTimeFormatterWorking({ value: date }) : '-'}
              </div>
            );
          },
          dataIndex: ['calendar', '6', 'work'],
          key: 7,
          fixed: 'right',
          align: 'center',
          render: (text, record) => {
            const editable = isEditing(record);
            if (
              editable &&
              headerDateTableRef.current &&
              headerDateTableRef.current[0]?.calendar[6]?.date
            ) {
              return (
                <Checkbox
                  defaultChecked={
                    record?.calendar[6] ? record?.calendar[6].work : false
                  }
                  onChange={(e) => {
                    if (e) {
                      const updatedDate = [
                        ...editingRecordRef.current.calendar,
                      ];
                      updatedDate[6] = {
                        ...updatedDate[6],
                        date: updatedDate[6].date,
                        work: e.target.checked,
                      };
                      updateEditingFieldValue({
                        calendar: updatedDate,
                      });
                    }
                    // Force re-render
                    forceUpdate();
                  }}
                ></Checkbox>
              );
            } else {
              if (text) {
                if (record.failed_reason) {
                  return {
                    props: {
                      style: {
                        background:
                          record.failed_reason === 'calendar' ||
                          record.failed_reason === 'both'
                            ? '#ff0000'
                            : '',
                      },
                    },
                    children: (
                      <div>
                        {<CheckOutlined style={{ fontSize: '15px' }} />}
                      </div>
                    ),
                  };
                } else {
                  return <CheckOutlined style={{ fontSize: '15px' }} />;
                }
              } else {
                return null;
              }
            }
          },
        },
      ],
    },
  ];

  //apiSearchTable -------------------------------------------------------
  //  async function onBtnSearch(value) {
  //   try {
  //     matchingRef.current = false;
  //     setIsLoading(true);
  //     if (value) {
  //       let result = await dispatch(
  //         fixTourApi.endpoints.findAllFixTourByPagination.initiate(value, {
  //           forceRefetch: true,
  //         })
  //       );

  //       if (result && result.error) {
  //         setErrorData(result.error);
  //       } else {
  //         if (result.data) {
  //           headerDateTableRef.current = result.data.results;
  //           setDataSource(result.data.results);
  //         }
  //       }
  //     }
  //   } catch (err) {
  //     console.log('err', err);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // }

  const onBtnSearch = async (payload) => {
    try {
      if (!isLoading) {
        setPage(1);
        let bufferData = [];
        let requiredRows = page * PAGE_SIZE;
        requiredRows =
          requiredRows > totalRows && totalRows !== 0
            ? totalRows
            : requiredRows;
        if (requiredRows > bufferData.length) {
          // Fetch more data
          let curOffset = 0;
          payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };
          do {
            let queryRes = await executeQuery(
              curOffset,
              bufferData,
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            curOffset += LIMIT;
            bufferData = queryRes.data;
            if (!bufferData) {
              message.info('There is no data');
              return;
            }
            requiredRows =
              requiredRows > queryRes.totalRows
                ? queryRes.totalRows
                : requiredRows;
          } while (requiredRows > bufferData.length);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          fixTourApi.endpoints.findAllFixTourByPagination.initiate(payload, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          Modal.error({
            title: result.error.data.errorList[0].errorMessage,
            icon: <ExclamationCircleOutlined />,
            content: result.error.data.errorList[0].errorDetail,
          });
        } else {
          if (result.data && result.data.results) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((item, i) => ({
              ...item,
              no: i + rowNo,
            }));
            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            // setDataSourceTable(newData);
            totalRows = result.data.count;
            setEditingKey('');

            headerDateTableRef.current = newData;
            setDataSource(newData);

            // find if tour failed
            const failedTours = newData.filter((tour) =>
              ['job_code', 'both', 'trailer'].includes(tour.failed_reason)
            );
            SetFailedJobcodeTours(failedTours);
          }
        }
      } catch (err) {
        // console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setTotalRows]
  );

  const sumDif = (valMax, valMin) => {
    let diff = valMax - valMin;
    return diff;
  };
  //apiSearchTable -------------------------------------------------------
  async function onDownload(value) {
    try {
      matchingRef.current = false;
      setIsLoadingDownLoad(true);
      if (value) {
        let result = await dispatch(
          fixTourApi.endpoints.findAllFixTourDownload.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          Modal.error({
            title: result.error.data.errorList[0].errorMessage,
            icon: <ExclamationCircleOutlined />,
            content: result.error.data.errorList[0].errorDetail,
          });
        } else {
          if (result.data) {
            let excelData = result?.data?.results;

            excelData = excelData.map((d, index) => ({
              Date: d.date,
              From: d.from,
              To: d.to,
              'Assign volume': d.assign_volume,
              'Fix volume': d.fix_volume,
              'Dif.': sumDif(d.fix_volume, d.assign_volume),
              Stock: d.stock,
              'Fix trip': d.fix_trip,
            }));
            // console.log('excelData', excelData);
            Json2CSV(
              excelData,
              `fix_tour_${moment().format('YYYYMMDD_HHmmss')}.csv`,
              ','
            );
          }
        }
      }
    } catch (err) {
      console.log('err', err);
    } finally {
      setIsLoadingDownLoad(false);
    }
  }

  const [
    apiDownloadJobCodeCSV,
    {
      data: dataDownloadJobCodeCSV,
      error: errorDownloadJobCodeCSV,
      isLoading: isLoadingDownloadJobCodeCSV,
    },
  ] = useFindDownloadJobCodeCSVManageTourListMutation();

  useEffect(() => {
    if (errorDownloadJobCodeCSV) {
      // console.log('3807', errorUploadFile.data.errorList[0].errorMessage);
      Modal.error({
        title: errorUploadFile.data.errorList[0].errorMessage,
        icon: <ExclamationCircleOutlined />,
        content: errorUploadFile.data.errorList[0].errorDetail,
      });
    }
  }, [errorDownloadJobCodeCSV]);

  useEffect(() => {
    if (dataDownloadJobCodeCSV) {
      downloadJobCodeCSV(dataDownloadJobCodeCSV);
    }
  }, [dataDownloadJobCodeCSV]);

  const downloadJobCodeCSV = async (response) => {
    const outputFilename = `${moment().format('DDMMYY')}_Job_Code_Calendar.csv`;

    // If you want to download file automatically using link attribute.
    const url = URL.createObjectURL(
      new Blob([`\ufeff${response}`], { type: 'text/csv;charset=utf-8' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  const onBtnDownloadJobCodeCalendar = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to download?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        let payload = {
          plan_id: planJobId && planJobId,
        };
        apiDownloadJobCodeCSV(payload);
      },
    });
  };

  const apiConfirmFixTourBypass = async (payload) => {
    try {
      setIsLoading(true);
      let result = await dispatch(
        fixTourApi.endpoints.confirmFixTourBypass.initiate(payload, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
      } else {
        if (result.data) {
          if (result.data?.success) {
            // let body = {
            //   plan_job_id: payload?.plan_job_id,
            // };
            // onBtnSearch(body);
            Modal.success({
              title: 'Success',
              icon: <ExclamationCircleOutlined />,
              content: 'ข้อมูลพร้อมสำหรับหน้า Status Check แล้ว',
            });
          }
        }
      }
    } catch (err) {
      // console.log(err);
      message.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  const onCheckFixTourBypass = async (payload) => {
    try {
      setIsLoading(true);
      let result = await dispatch(
        fixTourApi.endpoints.checkFixTourBypass.initiate(payload, {
          forceRefetch: true,
        })
      );

      if (result && (result.error || !result.data)) {
        Modal.error({
          title: 'Error',
          icon: <ExclamationCircleOutlined />,
          content: result.error,
        });
        return;
      }

      if (!result.data?.success) {
        Modal.warning({
          title: 'Warning',
          content: (
            <div style={{ color: 'red' }}>
              {result &&
                result.data?.errorList &&
                result &&
                result.data?.errorList.map((e, index) => (
                  <div key={index}>- {e}</div>
                ))}
            </div>
          ),
          width: 600,
          onOk() {
            Modal.confirm({
              title: 'Confirm',
              icon: <ExclamationCircleOutlined />,
              content: 'Would you like to confirm?',
              okText: 'Confirm',
              cancelText: 'Cancel',
              onOk: () => {
                let payload = {
                  plan_job_id: planJobId,
                  confirm: true,
                };
                apiConfirmFixTourBypass(payload);
              },
            });
          },
        });
        return;
      }
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Would you like to confirm?',
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => {
          apiConfirmFixTourBypass(payload);
        },
      });
    } catch (err) {
      console.log(err);
      message.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };
  const onConfirmOverDiagramBypass = async (payload) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Would you like to confirm?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: () => {
        apiConfirmOverDiagramBypass(payload);
      },
    });
  };

  const apiConfirmOverDiagramBypass = async (payload) => {
    try {
      setIsLoading(true);
      let result = await dispatch(
        fixTourApi.endpoints.confirmOverDiagramBypass.initiate(payload, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
      } else {
        if (result.data) {
          if (result.data?.success === true) {
            Modal.success({
              title: 'Success',
              icon: <ExclamationCircleOutlined />,
              content: 'ข้อมูล plan ถูกส่งออกไปยังหน้า dispatch board แล้ว',
              onOk: () => {
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              },
            });
          }
        }
      }
    } catch (err) {
      console.log(err);
      message.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container style={{ paddingTop: '10px' }}>
      <Toolbar
        fileList={fileList}
        setFileList={setFileList}
        planJobId={planJobId}
        setFilterSelectStatus={setFilterSelectStatus}
        setFilterSelectCompany={setFilterSelectCompany}
        setFilterSelectTrailerType={setFilterSelectTrailerType}
        filterSelectStatus={filterSelectStatus}
        filterSelectCompany={filterSelectCompany}
        filterSelectTrailerType={filterSelectTrailerType}
        filterOptionVehicleType={filterOptionVehicleType}
        filterOptionCompany={filterOptionCompany}
        filterOptionTrailerType={filterOptionTrailerType}
        isLoadingEntry={isLoadingEntry}
        dataSourceTableYard={dataSourceTableYard}
        setDataSourceTableYard={setDataSourceTableYard}
        apiUploadFile={apiUploadFile}
        apiDeleteAllRow={apiDeleteAllRow}
        isLoadingUploadFile={isLoadingUploadFile}
        onGetFilterDataVolumeTable={onGetFilterDataVolumeTable}
        getEntryAssignVolumeQuery={getEntryAssignVolumeQuery}
        rowMeargeRef={rowMeargeRef}
        apiSearchTable={onBtnSearch}
        onDownload={onDownload}
        onBtnDownloadJobCodeCalendar={onBtnDownloadJobCodeCalendar}
        isLoadingDownloadJobCodeCSV={isLoadingDownloadJobCodeCSV}
        isLoadingDownLoad={isLoadingDownLoad}
        headerDateTableRef={headerDateTableRef}
        isLoading={isLoading}
        dataSource={dataSource}
        setUploadFile={setUploadFile}
        uploadFile={uploadFile}
        apiMatching={apiMatching}
        isLoadingMatching={isLoadingMatching}
        isLoadingDeleteAllRow={isLoadingDeleteAllRow}
        isLoadingEditFixRow={isLoadingEditFixRow}
        FailedJobcodeTours={FailedJobcodeTours}
        findAlternative={findAlternative}
        isLoadingFindAlternative={isLoadingFindAlternative}
        dataFindAlternative={dataFindAlternative}
        errorFindAlternative={errorFindAlternative}
        disableFrom={disableFrom}
        apiCheckFixTourBypass={onCheckFixTourBypass}
        apiConfirmOverDiagramBypass={onConfirmOverDiagramBypass}
      />

      <StyledAntTable
        rowKey='id'
        tableId={'id'}
        bordered
        showSorterTooltip={false}
        columns={columnsHeaderYard}
        loading={isLoadingEditFixRow || isLoading}
        dataSource={dataSource}
        // Below is for right click context menu
        showContextMenu={true}
        menuActions={[
          { label: 'Edit', action: (row) => onEdit(row) },
          {
            label: 'Delete',
            action: (row) => onDeleteById(row),
          },
        ]}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        pagination={{
          pageSize: PAGE_SIZE,
          total: totalRows,
          current: page,
          size: 'small',
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: async (page, _) => {
            let bufferData = dataSource;
            let requiredRows = page * PAGE_SIZE;
            requiredRows =
              requiredRows > totalRows && totalRows !== 0
                ? totalRows
                : requiredRows;

            if (requiredRows > bufferData.length) {
              // Fetch more data
              let curOffset = offset;
              do {
                let queryRes = await executeQuery(curOffset, bufferData, {
                  ...payloadRef.current,
                  limit: LIMIT,
                  offset: curOffset,
                });
                curOffset += LIMIT;
                bufferData = queryRes.data;
                requiredRows =
                  requiredRows > queryRes.totalRows
                    ? queryRes.totalRows
                    : requiredRows;
              } while (requiredRows > bufferData.length);
            }
            setPage(page);
          },
        }}
      />

      <Modal
        visible={visibleModalLoading}
        onOk={handleOkLoading}
        onCancel={handleCancelLoading}
        okButtonProps={false}
        maskClosable={false}
        closable={false}
        footer={[
          <Button
            key='submit'
            type='primary'
            loading={isLoadingSearch}
            onClick={handleOkLoading}
          >
            Ok
          </Button>,
        ]}
      >
        <p>Please wait in progress...</p>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default TableComponent;
