import React, { useState } from 'react';
import AntTable from 'common_components/AntTable';
import styled from 'styled-components';
import { message } from 'antd';
import { dateTimeNotYearTimestamptzFormatter } from 'common_components/AntTable/tableUtils';

const TableResult = (props) => {
  const {
    dataSourceTable,
    setSelectedRows,
    selectedRowKeys,
    setSelectedRowKeys,
    totalRows,
    onCreateByManual,
    PAGE_SIZE,
    page,
    offset,
    payloadRef,
    LIMIT,
    setPage,
    executeQuery,
    isLoading,
    checkRow,
    uncheckRow,
  } = props;

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Receive job time',
        dataIndex: 'receive_job_time',
        width: 100,
        align: 'center',
        render: (text) => {
          return dateTimeNotYearTimestamptzFormatter({ value: text });
        },
      },
      {
        title: 'Vehicle type',
        width: 100,
        dataIndex: 'vehicle_type',
        align: 'center',
        render: (text) => {
          return text?.name;
        },
      },
      {
        title: 'From',
        dataIndex: 'location_from',
        width: 150,
        align: 'center',
        render: (text) => {
          return text?.name;
        },
      },
      {
        title: 'To',
        width: 150,
        align: 'center',
        dataIndex: 'location_to',
        render: (text) => {
          return text?.name;
        },
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        width: 100,
        align: 'center',
        render: (text) => {
          return text;
        },
      },
    ];

    return cols;
  });

  const rowSelection = {
    selectedRowKeys,
    onSelect: (record, selected, selectedRows) => {
      if (selectedRows?.length <= 5) {
        if (!selected) {
          uncheckRow(record);
          return;
        }
        checkRow(record);
      } else {
        uncheckRow(record);
        return;
      }
    },
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRows?.length <= 5) {
        setSelectedRowKeys(selectedRowKeys);

        for (let i = 0; i < selectedRows.length; i++) {
          selectedRows[i].index = i + 1;
        }

        setSelectedRows(selectedRows);
        onCreateByManual(selectedRows);
      } else {
        message.error('Please noted that your selected max 5 Trips.');
      }
    },
  };

  return (
    <Container style={{ marginTop: '10px' }}>
      <StyledAntTable
        rowKey='id'
        tableId={'id'}
        rowSelection={rowSelection}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSourceTable}
        loading={isLoading}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
        pagination={{
          pageSize: PAGE_SIZE,
          total: totalRows,
          current: page,
          size: 'small',
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: async (page, _) => {
            let bufferData = dataSourceTable;
            let requiredRows = page * PAGE_SIZE;
            requiredRows =
              requiredRows > totalRows && totalRows !== 0
                ? totalRows
                : requiredRows;

            if (requiredRows > bufferData.length) {
              // Fetch more data
              let curOffset = offset;
              do {
                let queryRes = await executeQuery(curOffset, bufferData, {
                  ...payloadRef.current,
                  limit: LIMIT,
                  offset: curOffset,
                });
                curOffset += LIMIT;
                bufferData = queryRes.data;
                requiredRows =
                  requiredRows > queryRes.totalRows
                    ? queryRes.totalRows
                    : requiredRows;
              } while (requiredRows > bufferData.length);
            }
            setPage(page);
          },
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default TableResult;
