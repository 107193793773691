import React, { useState, useEffect, useRef, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import LinkRenderer from 'common_components/LinkRenderer';
import {
  dateFormatter,
  dateTimeTimestamptzFormatter,
} from 'common_components/AntTable/tableUtils';
import TableToolbar from './Toolbar';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from 'hooks/windowSize';
import {
  setCurrentPage,
  setCurrentOffset,
  setCallingSheetDataSource,
  setCallingSheetCompleteCount,
} from 'reducers/callingsheet';
import { callingsheetApi } from 'reducers/callingsheet/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { Sorter } from 'utils/sorter';
import styled from 'styled-components';
import { message } from 'antd';

const LIMIT = 500;

const CallingSheetTableComponent = (props) => {
  const { setTotalRows } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showFilter, setShowFilter] = useState(true);

  // Redux Store
  const storedPage = useSelector((state) => {
    return state.callingsheet.currentPage;
  });
  const storedOffset = useSelector((state) => {
    return state.callingsheet.currentOffset;
  });
  const storedDataSource = useSelector((state) => {
    return state.callingsheet.dataSource;
  });
  const storedCallingCompleteCount = useSelector((state) => {
    return state.callingsheet.callingCompleteCount;
  });

  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [offset, setOffset] = useState(storedOffset || 0);
  const [page, setPage] = useState(storedPage || 1);
  const [tableHeight, setTableHeight] = useState(0);
  const [callingCompleteCount, setCallingCompleteCount] = useState(
    storedCallingCompleteCount || null
  );

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  const { height } = useWindowSize();
  const payloadRef = useRef(null);

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (!showFilter) {
        h = h + 150;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  useEffect(() => {
    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(setCurrentOffset(offset));
  }, [offset, dispatch]);

  useEffect(() => {
    dispatch(setCallingSheetDataSource(dataSource));
  }, [dataSource, dispatch]);

  useEffect(() => {
    if (callingCompleteCount) {
      dispatch(setCallingSheetCompleteCount(callingCompleteCount));
    }
  }, [callingCompleteCount, dispatch]);

  const onSearch = useCallback((searchTerm) => {}, []);

  const onCreate = () => {
    dispatch(push(`/app/delivery_mng/callingsheets/create`));
  };

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/delivery_mng/callingsheets/${cell.id}`));
  };

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          callingsheetApi.endpoints.findAllCallingSheetsByPagination.initiate(
            payload,
            {
              forceRefetch: forceRefetch ? true : false,
            }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              id: i + rowNo,
            }));
            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            setDataSource(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  const getCallingCompleteCount = useCallback(async () => {
    let result = await dispatch(
      callingsheetApi.endpoints.getCallingCompleteCount.initiate(null, {
        forceRefetch: true,
      })
    );

    if (result && result.error) {
      setErrorData(result.error);
    } else {
      if (result.data) {
        setCallingCompleteCount(result.data);
      }
    }
  }, [dispatch, setErrorData]);

  const onInit = useCallback(
    (payload) => {
      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(0, [], queryPayload);
      getCallingCompleteCount();
    },
    [executeQuery, getCallingCompleteCount]
  );

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'No.',
        dataIndex: 'id',
        width: 40,
        align: 'center',
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: 'Job code',
        dataIndex: 'dispatch_board_calling_sheet',
        render: (text, record) => {
          if (text && text.length > 0) {
            return text[0].job_code;
          } else {
            return '-';
          }
        },
        align: 'center',
        width: 100,
        sorter: {
          compare: Sorter.DEFAULT,
          field: 'job_code',
        },
      },
      {
        title: 'Calling No.',
        dataIndex: 'callingsheet_no',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return text ? text : '-';
        },
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: 'Calling date',
        dataIndex: 'created_at',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return dateFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: 'Adjust time',
        dataIndex: 'adjust_time',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },

      {
        title: 'Yard',
        dataIndex: 'yard',
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='yard'
              name='name'
              path='/app/master/yards'
            />
          );
        },
        align: 'center',
        width: 100,
        sorter: {
          compare: Sorter.DEFAULT,
          field: 'name',
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Region',
        dataIndex: 'region',
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='region'
              name='name'
              path='/app/master/regions'
            />
          );
        },
        align: 'center',
        width: 100,
        sorter: {
          compare: Sorter.DEFAULT,
          field: 'name',
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster',
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='cluster'
              name='name'
              path='/app/master/clusters'
            />
          );
        },
        align: 'center',
        width: 100,
        sorter: {
          compare: Sorter.DEFAULT,
          field: 'name',
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='cluster'
              name='name'
              path='/app/master/clusters'
            />
          );
        },
        align: 'center',
        width: 100,
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: 'Unit',
        dataIndex: 'vins',
        render: (text, record) => {
          if (text && text.length > 0) {
            return text.length;
          } else {
            return '-';
          }
        },
        width: 100,
        align: 'center',
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
    ];

    return cols;
  });

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: true,
    }),
  };

  return (
    <Container>
      <TableToolbar
        onCreate={onCreate}
        onSearch={onSearch}
        onInit={onInit}
        isLoading={isLoading}
        callingCompleteCount={callingCompleteCount}
        columns={columns}
        setColumns={setColumns}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />
      <StyledAntTable
        rowKey='id'
        tableId={'callingsheet'}
        bordered
        rowSelection={rowSelection}
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: 'Edit', action: (row) => handleEditClicked(row) },
        ]}
      />
    </Container>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body {
      height: ${(props) => {
        return props.height + 'px';
      }};
    }
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: auto;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`;
const Container = styled.div`
  padding: 0 10px;
`;

export default CallingSheetTableComponent;
