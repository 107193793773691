import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
  searchTerm: '',
  searchOrigin: '',
  searchDestination: '',
};

export const originalETAMasterSlice
 = createSlice({
  name: 'originalETAMaster',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setOriginalETAMasterTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setOriginalETAMasterDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setSearchFilters: (state, { payload }) => {
      if (payload) {
        state.searchTerm = payload.searchTerm;
        state.searchOrigin = payload.searchOrigin;
        state.searchDestination = payload.searchDestination;
      }
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
    setSearchOrigin: (state, { payload }) => {
      state.searchOrigin = payload;
    },
    setSearchDestination: (state, { payload }) => {
      state.searchDestination = payload;
    },
    setOriginalETAMasterFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    clearSearch: (state) => {
      state.searchTerm = '';
      state.searchOrigin = '';
      state.searchDestination = '';
    },
  },
});

export const {
  setOriginalETAMasterFilter,
  setCurrentPage,
  setCurrentOffset,
  setOriginalETAMasterDataSource,
  setOriginalETAMasterTotalRows,
  setSearchFilters,
  setSearchTerm,
  setSearchOrigin,
  setSearchDestination,
  clearSearch,
} = originalETAMasterSlice
.actions;

export const originalETAMasterSelector = (state) => state.originalETAMaster;

export default originalETAMasterSlice
.reducer;
