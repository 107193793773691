import React from 'react';
import AntTable from 'common_components/AntTable';
import {
  DatePicker,
  Select,
  Typography,
  Space,
  Row,
  Col,
  Checkbox,
  Modal,
  message,
} from 'antd';
import AntModal from 'common_components/AntModal';
import styled from 'styled-components';
import './index.css';
import moment from 'moment';
import dayjs from 'dayjs';
import { orderBy } from 'lodash';

var utc = require('dayjs/plugin/utc');
dayjs.extend(utc);
const { Option } = Select;
const { Text } = Typography;
const TableComponent = (props) => {
  const {
    isLoading,
    isModalConfirmVisible,
    setIsModalConfirmVisible,
    customerField,
    destinationField,
    checkAccidentField,
    actualArrivalTimeField,
    callingNo,
    totalUnit,
    vinDescription,
    isInvoiceAbleField,
    isPaymentAbleField,
    savePopup,
    idVin,
    idJobCodeRef,
    rowData,
  } = props;
  const selectProps = {
    style: {
      fontSize: '14px',
      width: '130px',
    },
    allowClear: false,
    maxTagCount: 'responsive',
  };
  const selectVinProps = {
    style: {
      fontSize: '14px',
      width: '170px',
    },
    allowClear: false,
    maxTagCount: 'responsive',
  };

  let sortedDataTable = orderBy(vinDescription, [
    'actual_arrival_time_edit',
    'actual_arrival_time',
    'location_destination_edit.code',
    'location_to.code',
    'customer_edit.code',
    'customer.code',
  ]);

  const disabledDate = (current) => {
    // ปิดใช้งานวันที่ที่น้อยกว่า xx หรือมากกว่า xx
    return current && current > moment().startOf('day').add(1, 'days');
  };

  const spliteSeconds = (
    value,
    actual_receive_job_datetime,
    actual_yardout_datetime,
    actual_arrival_time
  ) => {
    let format = 'YYYY-MM-DDTHH:mm:ssZ';
    let value_seconds = null;
    let receive_job_seconds = null;
    let yardout_seconds = null;
    let arrival_seconds = null;
    if (value) {
      value_seconds = moment(value, format).seconds(0).format(format);
    }
    if (actual_receive_job_datetime) {
      receive_job_seconds = moment(actual_receive_job_datetime, format)
        .seconds(0)
        .format(format);
    }
    if (actual_yardout_datetime) {
      yardout_seconds = moment(actual_yardout_datetime, format)
        .seconds(0)
        .format(format);
    }

    if (actual_arrival_time) {
      arrival_seconds = moment(actual_arrival_time, format)
        .seconds(0)
        .format(format);
    }

    let val = {
      value_seconds: value_seconds,
      receive_job_seconds: receive_job_seconds,
      yardout_seconds: yardout_seconds,
      arrival_seconds: arrival_seconds,
    };
    return val;
  };

  const onCheckArrival = (record, value) => {
    let val = spliteSeconds(
      value,
      record.actual_receive_job_datetime,
      record.actual_yardout_datetime,
      record.actual_arrival_time
    );
    if (!record.actual_receive_job_datetime) {
      return Modal.error({
        title: 'โปรดเลือกเวลารับงานก่อน',
        onOk: () => {
          actualArrivalTimeField(
            null,
            record.id,
            record.vehicle_type?.code,
            record.vehicleTypeOptions,
            record
          );
        },
      });
    }
    if (!record.actual_yardout_datetime) {
      return Modal.error({
        title: 'โปรดเลือกเวลา Yard out time ก่อนเวลาจบงาน',
        onOk: () => {
          actualArrivalTimeField(
            null,
            record.id,
            record.vehicle_type?.code,
            record.vehicleTypeOptions,
            record
          );
        },
      });
    }

    if (moment(val.value_seconds).isSameOrAfter(moment())) {
      Modal.error({
        title: 'เวลาที่เลือกมากกว่าเวลาปัจจุบัน โปรดเลือกเวลาใหม่อีกครั้ง',
        onOk: () => {
          actualArrivalTimeField(
            null,
            record.id,
            record.vehicle_type?.code,
            record.vehicleTypeOptions,
            record
          );
        },
      });
    } else {
      if (record?.combine) {
        if (
          moment(val?.receive_job_seconds).isSameOrAfter(val?.value_seconds)
        ) {
          Modal.error({
            title: 'กรุณาใส่เวลา Arrival time มากกว่าเวลารับงาน',
          });
        } else if (
          moment(record.actual_yardout_datetime).isSameOrAfter(val?.value_seconds)
        ) {
          Modal.error({
            title: 'กรุณาใส่เวลา Arrival time มากกว่าเวลา Yard out time',
            onOk: () => {
              actualArrivalTimeField(
                null,
                record.id,
                record.vehicle_type?.code,
                record.vehicleTypeOptions,
                record
              );
            },
          });
        } else {
          actualArrivalTimeField(
            value ? value.seconds(0).milliseconds(0) : null,
            record.id,
            record.vehicle_type?.code,
            record.vehicleTypeOptions,
            record
          );
        }
      } else {
        let lengthData = rowData.length - 1;
        for (let index = 0; index < rowData.length; index++) {
          if (index !== 0 && record?.key !== lengthData) {
            if (
              Date.parse(val.value_seconds) >
              Date.parse(rowData[index]?.actual_receive_job_datetime)
            ) {
              Modal.error({
                title:
                  'กรุณาใส่เวลา Arrival time น้อยกว่าเวลารับงานของ trip ถัดไป',
                onOk: () => {
                  actualArrivalTimeField(
                    null,
                    record.id,
                    record.vehicle_type?.code,
                    record.vehicleTypeOptions,
                    record
                  );
                },
              });
              break;
            } else {
              if (index === record?.key) {
                actualArrivalTimeField(
                  value ? value.seconds(0).milliseconds(0) : null,
                  record.id,
                  record.vehicle_type?.code,
                  record.vehicleTypeOptions,
                  record
                );
              }
            }
            break;
          } else {
            if (index !== 0 && index === record?.key) {
              if (
                Date.parse(val.value_seconds) <
                Date.parse(rowData[index]?.actual_yardout_datetime)
              ) {
                Modal.error({
                  title: 'กรุณาใส่เวลา Arrival time มากกว่าเวลา Yard out time',
                  onOk: () => {
                    actualArrivalTimeField(
                      null,
                      record.id,
                      record.vehicle_type?.code,
                      record.vehicleTypeOptions,
                      record
                    );
                  },
                });
                break;
              } else {
                actualArrivalTimeField(
                  value ? value.seconds(0).milliseconds(0) : null,
                  record.id,
                  record.vehicle_type?.code,
                  record.vehicleTypeOptions,
                  record
                );
              }
            } else {
              if (
                Date.parse(val.value_seconds) <
                Date.parse(rowData[index]?.actual_yardout_datetime)
              ) {
                Modal.error({
                  title: 'กรุณาใส่เวลา Arrival time มากกว่าเวลา Yard out time',
                  onOk: () => {
                    actualArrivalTimeField(
                      null,
                      record.id,
                      record.vehicle_type?.code,
                      record.vehicleTypeOptions,
                      record
                    );
                  },
                });
                break;
              } else {
                actualArrivalTimeField(
                  value ? value.seconds(0).milliseconds(0) : null,
                  record.id,
                  record.vehicle_type?.code,
                  record.vehicleTypeOptions,
                  record
                );
              }
            }
          }
        }
      }
    }
  };

  const columnsPopup = [
    {
      title: 'Vin No.',
      dataIndex: 'vin_no',
      width: 150,
      align: 'center',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      width: 150,
      align: 'center',
      render: (text, record, index) => {
        return (
          <Select
            value={
              record.customer_edit
                ? record.customer_edit.code
                : record?.customer?.code
            }
            disabled={record.disableFormRow}
            {...selectProps}
            size='small'
            loading={isLoading}
            placeholder={'Select a from'}
            onSelect={(values) => {
              customerField(values, record.id, record.customerOption);
            }}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {record.customerOption.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Destination',
      dataIndex: ['location_to', 'code'],
      width: 170,
      align: 'center',
      render: (text, record, index) => {
        return (
          <Select
            value={
              record.location_destination_edit
                ? record.location_destination_edit.code
                : text
            }
            disabled={record.disableFormRow}
            {...selectVinProps}
            size='small'
            loading={isLoading}
            placeholder={'Select a from'}
            onSelect={(values) => {
              destinationField(
                values,
                record.id,
                record.locationDestinationOptions
              );
            }}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
          >
            {record.locationDestinationOptions.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Vehicle type',
      dataIndex: ['vehicle_type', 'code'],
      width: 170,
      align: 'center',
      render: (text, record, index) => {
        return (
          <Select
            value={
              record?.vehicle_type_edit ? record.vehicle_type_edit.code : text
            }
            disabled={record.disableFormRow}
            {...selectVinProps}
            size='small'
            loading={isLoading}
            placeholder={'Select a from'}
            showSearch
            optionFilterProp='children'
            filterOption={(input, option) => {
              return option.children
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            onChange={(value) => {
              checkAccidentField(
                value,
                record.id,
                record.vehicleTypeOptions,
                record
              );
            }}
          >
            {record.vehicleTypeOptions.map((c, index) => (
              <Option key={index} value={c.value}>
                {c.label}
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'Arrival time',
      dataIndex: 'actual_arrival_time',
      width: 160,
      align: 'center',
      render: (text, record) => {
        let value = null;
        if (record?.actual_arrival_time_edit !== 'clear') {
          let actual_arrival_time = record?.actual_arrival_time;
          if (typeof record?.actual_arrival_time_edit !== 'undefined') {
            actual_arrival_time = record?.actual_arrival_time_edit;
          }
          if (moment(actual_arrival_time).isValid()) {
            value = moment(actual_arrival_time);
          }
        }
        return (
          <DatePicker
            disabledDate={disabledDate}
            disabled={
              record.actual_arrival_time_clear === 'clear' &&
              record.actual_arrival_time_clear_destination !== 'destination'
            }
            value={value}
            style={{
              fontSize: '14px',
              width: '160px',
            }}
            allowClear={true}
            loading={isLoading}
            size='small'
            showTime
            format={'DD/MM/YY HH:mm'}
            onOk={(value) => {
              onCheckArrival(record, value);
            }}
            onChange={(value) => {
              if (value === null) {
                actualArrivalTimeField(
                  value,
                  record.id,
                  record.vehicle_type?.code,
                  record.vehicleTypeOptions,
                  record
                );
              }
            }}
            showNow={false}
          />
        );
      },
    },
    {
      title: 'Invoice',
      dataIndex: 'is_invoice_able',
      width: 150,
      align: 'center',
      render: (text, record) => {
        return (
          <Checkbox
            checked={text}
            onChange={(value) => {
              isInvoiceAbleField(value.target.checked, record.id);
            }}
          ></Checkbox>
        );
      },
    },
    {
      title: 'Payment',
      dataIndex: 'is_payment_able',
      width: 150,
      align: 'center',
      render: (text, record) => {
        return (
          <Checkbox
            checked={text}
            onChange={(value) => {
              isPaymentAbleField(value.target.checked, record.id);
            }}
          ></Checkbox>
        );
      },
    },
  ];
  return (
    <Container>
      <AntModal
        visible={isModalConfirmVisible}
        onCancel={() => {
          setIsModalConfirmVisible(false);
        }}
        onOk={() => {
          savePopup(sortedDataTable, idJobCodeRef, idVin);
          setIsModalConfirmVisible(false);
        }}
        titleModal={<Text></Text>}
        width={1000}
        style={{ textAlign: 'center' }}
      >
        <Space size={15} direction='vertical'>
          <Row>
            <Col style={{ marginRight: 10 }}>
              Calling No. {callingNo && callingNo}
            </Col>
            <Col>Total unit {totalUnit && totalUnit}</Col>
          </Row>
          <AntTable
            rowKey='id'
            tableId={'calling_no_combine_2'}
            showSorterTooltip={false}
            columns={columnsPopup}
            dataSource={sortedDataTable}
            pagination={false}
          />
        </Space>
      </AntModal>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
export default TableComponent;
