import React, { Component } from 'react';
import { Polyline } from '@react-google-maps/api';
import { connect } from 'react-redux';

import { ADD_WAYPOINT, CLEAR_SELECTING } from 'reducers/map';
class WpPolyline extends Component {
  state = {
    isMouseOver: false,
  };

  addWaypoint = (e) => {
    let new_waypoint = {
      id: this.props.id,
      position: { lat: e.latLng.lat(), lng: e.latLng.lng() },
    };
    this.props.ADD_WAYPOINT(new_waypoint);
  };

  onMouseOver = (e) => {
    this.setState({ isMouseOver: true });
  };

  onMouseOut = (e) => {
    this.setState({ isMouseOver: false });
  };

  render() {
    const id = this.props.id;
    let isMoving = false;

    if (
      this.props.marker_MovingAdding === id ||
      this.props.marker_MovingAdding === id + 1
    ) {
      isMoving = true;
    }

    let strokeWeight = 4;
    if (this.state.isMouseOver === true) {
      strokeWeight = 7;
    } else {
      strokeWeight = 4;
    }

    const normalSymbol = {
      strokeColor: '#1E90FF',
      strokeOpacity: 1,
      strokeWeight: strokeWeight,
      icons: [],
    };

    const higlightSymbol = {
      strokeColor: 'red',
      strokeOpacity: 0.8,
      strokeWeight: strokeWeight,
      icons: [],
    };

    return (
      <React.Fragment>
        <Polyline
          {...this.props}
          onClick={this.addWaypoint}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          options={isMoving ? higlightSymbol : normalSymbol}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    marker_selecting: state.map.marker_selecting,
    marker_MovingAdding: state.map.marker_MovingAdding,
  };
};

const mapActionsToProps = {
  ADD_WAYPOINT: ADD_WAYPOINT,
  CLEAR_SELECTING: CLEAR_SELECTING,
};

export default connect(mapStateToProps, mapActionsToProps)(WpPolyline);
