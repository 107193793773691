import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Form, Input, Button, Modal, message, InputNumber, Select } from 'antd';
import {
  useCreateOriginalETAMasterMutation,
  useUpdateOriginalETAMasterMutation,
} from 'reducers/masterData/originalETAMaster/api';
import { useFindAllLocationMastersQuery } from 'reducers/masterData/locationMaster/api';
import { useFindAllTrailerTypesQuery } from 'reducers/masterData/trailer/api';
import {
  // useFindAllOriginalETAMastersQuery,
  originalETAMasterApi,
} from 'reducers/masterData/originalETAMaster/api';

import { getCurrentUser } from 'reducers/user';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
import { Sorter } from 'utils/sorter';
const { Option } = Select;

const CreateOriginalETAMasterFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const user = useSelector(getCurrentUser);
  const { setErrorData } = useErrorHandler();
  const dispatch = useDispatch();
  const [LocationFrom, setLocationFrom] = useState(
    formData && formData.location_from
      ? [
          {
            id: formData.location_from.id,
            label: formData.location_from.short_name,
          },
        ]
      : []
  );
  const [LocationTo, setLocationTo] = useState(
    formData && formData.location_to
      ? [
          {
            id: formData.location_to.id,
            label: formData.location_to.short_name,
          },
        ]
      : []
  );
  const [locationOptions, setLocationOptions] = useState([]);
  const [defaulttLocationOptions, setDefaulttLocationOptions] = useState([]);
  const [locationToOptions, setLocationToOptions] = useState([]);
  const [trailerTypes, setTrailerTypes] = useState([]);
  const [originalETAList, setOriginalETAList] = useState([]);
  const [roureCodelist, setRoureCodelist] = useState({});
  const [
    createCreateOriginalETAMaster,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateOriginalETAMasterMutation();

  const [
    updateCreateOriginalETAMaster,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateOriginalETAMasterMutation();

  const {
    data: dataLocationMaster,
    error: errorLocationMaster,
    isFetching: isLocationMasterFetching,
    refetch: refetchLocationMaster,
  } = useFindAllLocationMastersQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataLocationMaster) {
      let mapData = dataLocationMaster.map((d) => ({
        id: d.id,
        label: d.short_name,
        value: d.name,
      }));
      setLocationOptions(Sorter.DEFAULTLIST(mapData, 'value'));
      setDefaulttLocationOptions(Sorter.DEFAULTLIST(mapData, 'value'));
      setLocationToOptions(Sorter.DEFAULTLIST(mapData, 'value'));
    }
  }, [dataLocationMaster]);

  useEffect(() => {
    if (errorLocationMaster) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load location',
        okText: 'Retry',
        onOk: refetchLocationMaster,
        cancelText: 'Cancel',
      });
    }
  }, [errorLocationMaster, refetchLocationMaster]);

  // const {
  //   data: dataOriginalETA,
  //   error: errorOriginalETA,
  //   isFetching: isOriginalETAFetching,
  //   refetch: refetchOriginalETA,
  // } = useFindAllOriginalETAMastersQuery(null, {
  //   skip: !user,
  // });

  // useEffect(() => {
  //   if (dataOriginalETA) {
  //     setOriginalETAList(
  //       dataOriginalETA.map((d) => ({
  //         id: d.id,
  //         label: d.name,
  //       }))
  //     );
  //   }
  // }, [dataOriginalETA]);

  // useEffect(() => {
  //   if (errorOriginalETA) {
  //     Modal.confirm({
  //       title: 'Confirm',
  //       icon: <ExclamationCircleOutlined />,
  //       content: 'Failed to load original eta',
  //       okText: 'Retry',
  //       onOk: refetchOriginalETA,
  //       cancelText: 'Cancel',
  //     });
  //   }
  // }, [errorOriginalETA, refetchOriginalETA]);

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
    props,
  ]);

  const onChangeRouteCode = async (value) => {
    try {
      let result = await dispatch(
        originalETAMasterApi.endpoints.findOriginalETAMastersByRouteCode.initiate(
          value.target.value
        )
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          form.setFieldsValue({
            seq:
              result.data && result.data.next_route_code
                ? result.data && result.data.next_route_code
                : '1',
            location_from: result.data && result.data.location_from.short_name,
            speed: result.data && result.data.speed,
          });
        }
      }
    } catch (errorInfo) {
      form.setFieldsValue({
        seq: '1',
        location_from: '1',
      });
      form.resetFields(['location_from']);
      form.resetFields(['speed']);
      console.log('Failed:', errorInfo);
    }
  };
  const onFinish = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let locationFrom = locationOptions.find(
      (c) => c.label === validData.location_from
    );
    let locationTo = locationOptions.find(
      (c) => c.label === validData.location_to
    );
    let trailerType = trailerTypes.find(
      (c) => c.label === validData.trailer_type
    );
    let postData = {
      id: formData && formData.id,
      ...validData,
      location_from: locationFrom && locationFrom.id,
      location_to: locationTo && locationTo.id,
      trailer_type: trailerType && trailerType.id,
    };

    if (isCreate) {
      createCreateOriginalETAMaster(postData);
    } else {
      updateCreateOriginalETAMaster(postData);
    }
  };
  let schema = yup.object().shape({
    route_code: yup
      .number()
      .required('Please input route code')
      .typeError('Number only.')
      .positive()
      .integer()
      .test(
        'len',
        'Must be exactly 5 digits',
        (val) => val.toString().length === 5
      ),
    // route_code: yup.number().typeError('Number only.').min(5, 'Must be exactly 5 digits')
    // .max(5, 'Must be exactly 5 digits'),
    //   route_code: yup.number()
    // .typeError('Number only.', 'Must be exactly 5 characters', val => val.length === 5)
  });

  const yupSync = {
    async validator({ field }, value) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  };
  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Route Code'
        name='route_code'
        initialValue={formData && formData.route_code}
        rules={[{ required: true, message: '' }, yupSync]}
      >
        <Input
          onChange={onChangeRouteCode}
          placeholder={'Route Code'}
          disabled={readonly || !user || !isCreate}
          maxlength={5}
          style={{ width: 150 }}
        />
      </Form.Item>
      <Form.Item
        label='Seq'
        name='seq'
        initialValue={formData && formData.seq}
        // rules={[
        //   {
        //     required: true,
        //     message: 'Please input seq',
        //   },
        // ]}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={!form.getFieldValue('code')}
          min={0}
          maxLength={9}
          placeholder={'Seq'}
        />
      </Form.Item>
      {/* {formData &&
          formData.location_from &&
          formData.location_from.short_name} */}

      {/* {          roureCodelist.location_from && roureCodelist.location_from.short_name ? roureCodelist.location_from && roureCodelist.location_from.short_name :
          formData &&
          formData.location_from &&
          formData.location_from.short_name } */}
      <Form.Item
        label='Origin'
        name='location_from'
        initialValue={
          formData &&
          formData.location_from &&
          formData.location_from.short_name
        }
        rules={[
          {
            required: true,
            message: 'Please input origin',
          },
        ]}
      >
        {/* <Input placeholder={roureCodelist && roureCodelist.next_route_code} */}

        <Select
          showSearch
          placeholder={'Select a origin'}
          optionFilterProp='children'
          loading={isLocationMasterFetching}
          // disabled={form.getFieldValue('location_from')}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
          allowClear
          onClear={() => {
            setLocationOptions(defaulttLocationOptions);
            setLocationToOptions(defaulttLocationOptions);
          }}
          onSelect={(value) => {
            console.log(`value`, value);
            if (value) {
              let locationTo = locationOptions.filter(
                (el) => el.label !== value
              );
              setLocationToOptions(locationTo);
              
            }
          }}
        >
          {locationOptions &&
            locationOptions.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Destination'
        name='location_to'
        initialValue={
          formData && formData.location_to && formData.location_to.short_name
        }
        rules={[
          {
            required: true,
            message: 'Please input destination',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a destination'
          optionFilterProp='children'
          loading={isLocationMasterFetching}
          disabled={readonly || !user || !isCreate}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
          allowClear
          onClear={() => {
            setLocationOptions(defaulttLocationOptions);
            setLocationToOptions(defaulttLocationOptions);
          }}
          onSelect={(value) => {
            console.log(`value`, value);
            if (value) {
              let locationTo = locationOptions.filter(
                (el) => el.label !== value
              );
              setLocationOptions(locationTo);
              
            }
          }}
        >
          {locationToOptions &&
            locationToOptions.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Distance'
        name='distance'
        initialValue={formData && formData.distance}
        rules={[
          {
            required: true,
            message: 'Please input distance',
          },
        ]}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
          placeholder='Distance'
        />
      </Form.Item>

      <Form.Item
        label='Speed'
        name='speed'
        initialValue={formData && formData.speed}
        rules={[
          {
            required: true,
            message: 'Please input speed',
          },
        ]}
      >
        <InputNumber
          style={{ width: 150 }}
          disabled={readonly}
          min={0}
          maxLength={9}
          placeholder='Speed'
        />
      </Form.Item>
    </Form>
  );
};

export default CreateOriginalETAMasterFormComponent;
