// import Foo from 'dms_components/Foo';
import DriverConfirmList from 'dms_components/DriverConfirm';

const masterDataControlRoutes = [
  {
    path: '/app/driver_confirm',
    name: 'Driver Confirm Receive Job',
    Component: DriverConfirmList,
  },
];

export default masterDataControlRoutes;
