import React, { useState, useEffect } from 'react';
import {
  Badge,
  Row,
  Col,
  Popover,
  Checkbox,
  Button,
  Space,
  Input,
  Upload,
  Modal,
} from 'antd';
import styled from 'styled-components';
import { useDebounce } from 'hooks/debounce';
import {
  FilterOutlined,
  DeleteTwoTone,
  PlusCircleOutlined,
  UploadOutlined,
  ExportOutlined,
  ReloadOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import DeleteModal from 'common_components/DeleteModal';

const TableToolbar = (props) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {
    showDelete,
    showAdd,
    showExport,
    showUpload,
    showReload,
    showPermanentDelete,
    showSearch,
    onDelete,
    onCreate,
    onExport,
    onUpload,
    onReload,
    isDeleting,
    isExporting,
    isUploading,
    isLoading,
    columns,
    setColumns,
    onSearch,
  } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm, onSearch]);

  const onInputSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const onColsChange = (e, col) => {
    const nextColumns = [...columns];
    let selectedCol = nextColumns.find((c) => c.title === col.title);
    selectedCol['hide'] = !e.target.checked;
    setColumns(nextColumns);
  };
  const onHideAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = true;
    }
    setColumns(nextColumns);
  };
  const onShowAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = false;
    }
    setColumns(nextColumns);
  };
  const onCancelDelete = () => {
    setShowConfirmDelete(false);
  };

  const onOkDelete = (isPermanentDelete) => {
    setShowConfirmDelete(false);
    onDelete(isPermanentDelete);
  };

  return (
    <Container>
      <Row align='middle'>
        <Col span={8} style={{ display: 'flex', alignItems: 'center' }}>
          <Popover
            key={'popover'}
            zIndex='10000'
            placement='right'
            content={(() => {
              return [
                <div key={'show-hide-all'}>
                  <Button type='link' onClick={onHideAllCols}>
                    Hide all
                  </Button>
                  /
                  <Button type='link' onClick={onShowAllCols}>
                    Show all
                  </Button>
                  ,
                </div>,
                columns
                  .filter((c) => c.title)
                  .map((c, index) => {
                    return (
                      <div key={`root-${index}`}>
                        <Checkbox
                          key={index}
                          checked={!c.hide}
                          onChange={(e) => onColsChange(e, c)}
                        >
                          {c.title}
                        </Checkbox>
                      </div>
                    );
                  }),
              ];
            })()}
            title='Show/hide Columns'
            trigger='click'
          >
            <Badge dot={columns && columns.some((c) => c.hide)}>
              <Button icon={<FilterOutlined />} size='small' />
            </Badge>
          </Popover>
          {showSearch && (
            <Input
              placeholder='Search...'
              style={{ marginLeft: '10px' }}
              onChange={onInputSearchChange}
            />
          )}
        </Col>

        <RightAlignedCol offset='12' span='12'>
          <Space size='middle'>
            {showDelete && (
              <Button
                loading={isDeleting}
                icon={<DeleteTwoTone twoToneColor='white' />}
                size='small'
                style={{ backgroundColor: 'red', marginRight: '20px' }}
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              />
            )}
            {showExport && (
              <Button
                loading={isExporting}
                icon={<ExportOutlined />}
                size='small'
                onClick={onExport}
              >
                Export
              </Button>
            )}
            {showUpload && (
              <Upload
                // onChange={}
                showUploadList={false}
                accept='.xlsx'
                beforeUpload={(file) => {
                  Modal.confirm({
                    title: 'Are you sure want to upload?',
                    icon: <ExclamationCircleOutlined />,
                    content: `filename: ${file && file.name}`,
                    okText: 'Confirm',
                    cancelText: 'Cancel',
                    onOk: () => {
                      onUpload(file);
                    },
                  });

                  return false;
                }}
              >
                <Button
                  raised='true'
                  loading={isUploading}
                  icon={<UploadOutlined />}
                  size='small'
                >
                  Upload
                </Button>
              </Upload>
            )}
            {showReload && (
              <Button
                loading={isLoading}
                icon={<ReloadOutlined />}
                size='small'
                onClick={onReload}
              >
                Reload
              </Button>
            )}
            {showAdd && (
              <Button
                icon={<PlusCircleOutlined />}
                size='small'
                onClick={onCreate}
              >
                Add
              </Button>
            )}
          </Space>
        </RightAlignedCol>
      </Row>
      <DeleteModal
        visible={showConfirmDelete}
        onCancel={onCancelDelete}
        onDelete={onOkDelete}
        showPermanentDelete={showPermanentDelete}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 0;
`;

const RightAlignedCol = styled(Col)`
  text-align: right;
  margin-left: auto;
`;

export default TableToolbar;
