import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PageHeader, Divider, Modal } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import {
  useFindAllFixTourBySelectionQuery,
  useFindTripTypeQuery,
  useFindLocationFromToQuery,
} from 'reducers/fixTour/api';
import Table from './Table';
import { useErrorHandler } from 'common_components/ErrorContext';
import { Sorter } from 'utils/sorter';
import { createTripApi } from 'reducers/createTrip/api';

const FixTourIndexComponent = () => {
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const [filterSelectStatus, setFilterSelectStatus] = useState();
  const [filterSelectCompany, setFilterSelectCompany] = useState();
  const [filterSelectTrailerType, setFilterSelectTrailerType] = useState();
  const [dataSourceTable, setDataSourceTable] = useState([]);
  const [dateOfSet, setDateOfSet] = useState([]);
  const [planJobId, setPlanJobId] = useState();
  const [filterOptionVehicleType, setFindOptionStatusFilter] = useState([]);
  const [filterOptionCompany, setFilterOptionCompany] = useState([]);
  const [filterOptionTrailerType, setFilterOptionTrailerType] = useState([]);
  const [companyJobcodeOptions, setCompanyJobcodeOptions] = useState([]);
  //trip type
  const [optionTripType, setOptionTripType] = useState([]);
  // edit from to
  const [optionFromTrip, setOptionFromTrip] = useState([]);
  const [optionToTrip, setOptionToTrip] = useState([]);
  const [loadingOptionBzp, setLoadingOptionBzp] = useState(false);
  const [disableFrom, setDisableFrom] = useState(false);

  // save edit row

  // get option  trip type
  const { data: dataTripType, error: errorTripType } = useFindTripTypeQuery();
  useEffect(() => {
    if (dataTripType) {
      let findOptionTripType = dataTripType.map((d) => ({
        label: d.name_en,
        value: d.id,
      }));

      setOptionTripType(findOptionTripType);
    }
  }, [dataTripType]);

  useEffect(() => {
    if (errorTripType) {
      setErrorData(errorTripType);
    }
  }, [errorTripType, setErrorData]);
  //----------------------------------------------------------------
  // get option  trip type
  const { data: dataLocationFromTo, error: errorLocationFromTo } =
    useFindLocationFromToQuery();
  useEffect(() => {
    if (dataLocationFromTo) {
      let findOptionSelectFrom = dataLocationFromTo.from.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));

      let findOptionSelectTo = dataLocationFromTo.cluster.map((d) => ({
        label: d.name,
        value: d.id,
      }));

      findOptionSelectFrom = Sorter.DEFAULTLIST(findOptionSelectFrom, 'label');
      findOptionSelectTo = Sorter.DEFAULTLIST(findOptionSelectTo, 'label');

      setOptionFromTrip(findOptionSelectFrom);
      setOptionToTrip(findOptionSelectTo);
    }
  }, [dataLocationFromTo]);

  useEffect(() => {
    if (errorLocationFromTo) {
      setErrorData(errorLocationFromTo);
    }
  }, [errorLocationFromTo, setErrorData]);
  //----------------------------------------------------------------
  const {
    data: dataGetFilterSelectionData,
    error: errorGetFilter,
    refetch: refetchDataGetFilterSelectionData,
    isLoading: isLoadingGetFilter,
  } = useFindAllFixTourBySelectionQuery();

  useEffect(() => {
    refetchDataGetFilterSelectionData();
  }, []);

  useEffect(() => {
    if (errorGetFilter) {
      setErrorData(errorGetFilter);
    }
  }, [errorGetFilter, setErrorData]);

  useEffect(() => {
    if (dataGetFilterSelectionData?.id === null) {
      return Modal.error({
        title: 'Not found plan job',
        onOk: () => {
          setDisableFrom(dataGetFilterSelectionData?.id ? false : true);
        },
      });
    }

    if (dataGetFilterSelectionData?.id) {
      let arrCompany = dataGetFilterSelectionData?.company?.map((d) => ({
        label: d.company,
        value: d.id,
        id: d.id,
      }));

      let arrTypeFilter = dataGetFilterSelectionData?.trailer_type?.map(
        (d) => ({
          label: d.name,
          value: d.id,
          maximum_loading: d.maximum_loading,
        })
      );

      let arrNumber = [];
      for (let i = 0; i < 11; i++) {
        let ojectNumber = {
          label: i,
          value: i,
        };
        arrNumber.push(ojectNumber);
      }
      setDateOfSet(arrNumber);

      setPlanJobId(dataGetFilterSelectionData?.id);
      setFilterOptionCompany(arrCompany);
      setFilterOptionTrailerType(arrTypeFilter);
      setCompanyJobcodeOptions(dataGetFilterSelectionData.company);
    }
  }, [dataGetFilterSelectionData]);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const onChangeOptionBzp = async (value) => {
    let resData = null;
    try {
      setLoadingOptionBzp(true);
      if (value) {
        let result = await dispatch(
          createTripApi.endpoints.findCreateTripByTypes.initiate(value, {
            forceRefetch: true,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            resData = result.data;
            setFilterOptionTrailerType(
              resData.map((b) => ({
                label: b.name,
                value: b.id,
                maximum_loading: b.maximum_loading,
              }))
            );
          }
        }
      }
      return resData;
    } catch (err) {
      console.log('err', err);
    } finally {
      setLoadingOptionBzp(false);
    }
  };

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Fix Tour'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Table
          planJobId={planJobId}
          filterSelectStatus={filterSelectStatus}
          filterSelectCompany={filterSelectCompany}
          filterSelectTrailerType={filterSelectTrailerType}
          filterOptionVehicleType={filterOptionVehicleType}
          filterOptionCompany={filterOptionCompany}
          filterOptionTrailerType={filterOptionTrailerType}
          companyJobcodeOptions={companyJobcodeOptions}
          setFilterSelectStatus={setFilterSelectStatus}
          setFilterSelectCompany={setFilterSelectCompany}
          setFilterSelectTrailerType={setFilterSelectTrailerType}
          dataSourceTable={dataSourceTable}
          setDataSourceTable={setDataSourceTable}
          optionTripType={optionTripType}
          optionFromTrip={optionFromTrip}
          optionToTrip={optionToTrip}
          dateOfSet={dateOfSet}
          onChangeOptionBzp={onChangeOptionBzp}
          loadingOptionBzp={loadingOptionBzp}
          setLoadingOptionBzp={setLoadingOptionBzp}
          disableFrom={disableFrom}
        />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default FixTourIndexComponent;
