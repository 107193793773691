import React, { useState, useEffect, useRef, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import EditButtonRenderer from 'common_components/EditButtonRenderer';
import TableToolbar from './Toolbar';
import { push } from 'connected-react-router';
import { message } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import permission from 'permissions';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import {
  useDeleteRouteGroupMutation,
  routeMasterApi,
} from 'reducers/digitalmap/routeMaster/api';

import {
  setOriginType,
  setDestinationType,
  setOriginLocation,
  setDestinationLocation,
} from 'reducers/digitalmap/routeMaster';

import {
  setCurrentPage,
  setCurrentOffset,
  setRouteGroupDataSource,
  setSearchFilters,
} from 'reducers/digitalmap/routeGroup';
import useWindowSize from 'hooks/windowSize';
import { useVT } from 'virtualizedtableforantd4';

const LIMIT = 100;
const PAGE_SIZE = 15;

const TableComponent = (props) => {
  const { setTotalRows, totalRows } = props;

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const { height } = useWindowSize();

  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'route_group')
  );

  // Redux Store
  const storedPage = useSelector((state) => {
    return state.routeGroup.currentPage;
  });
  const storedOffset = useSelector((state) => {
    return state.routeGroup.currentOffset;
  });
  const storedDataSource = useSelector((state) => {
    return state.routeGroup.dataSource;
  });

  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef();

  const [offset, setOffset] = useState(storedOffset || 0);
  const [page, setPage] = useState(storedPage || 1);
  const [tableHeight, setTableHeight] = useState(0);

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const payloadRef = useRef(null);

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: '',
        dataIndex: 'edit',
        align: 'center',
        render: (text, record) => {
          return (
            <EditButtonRenderer data={record} onClick={handleEditClicked} />
          );
        },
        width: 50,
      },
      {
        title: 'No.',
        dataIndex: 'rowNo',
        width: 40,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Code',
        dataIndex: 'name',
        width: 150,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Origin Type',
        dataIndex: ['origin', 'location_type', 'name'],
        render: (text, record) => {
          return text ? text : '-';
        },
        width: 150,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Origin Name',
        dataIndex: ['origin', 'short_name'],
        render: (text, record) => {
          return text ? text : '-';
        },
        width: 150,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Destination Type',
        dataIndex: ['destination', 'location_type', 'name'],
        render: (text, record) => {
          return text ? text : '-';
        },
        width: 150,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Destination Name',
        dataIndex: ['destination', 'short_name'],
        render: (text, record) => {
          return text ? text : '-';
        },
        width: 150,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        // searchable: true,
      },
      {
        title: 'Route Counts',
        dataIndex: 'route_master',
        render: (text, record) => {
          return text.length;
        },
        width: 150,
        align: 'center',
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Created By',
        dataIndex: ['created_by', 'fullname_en'],
        width: 100,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        ellipsis: true,
      },
      {
        title: 'Updated By',
        dataIndex: ['updated_by', 'fullname_en'],
        width: 100,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        ellipsis: true,
      },
      {
        title: 'Created Time',
        dataIndex: 'created_at',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        width: 150,
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
        ellipsis: true,
      },
      {
        title: 'Updated Time',
        dataIndex: 'updated_at',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        width: 150,
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
        ellipsis: true,
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }
    return cols;
  });

  const [
    deleteRouteGroup,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
      isLoading: isDeleting,
    },
  ] = useDeleteRouteGroupMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          routeMasterApi.endpoints.findAllRouteGroupsByPagination.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              rowNo: i + rowNo,
            }));

            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            setDataSource(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      clearSelectedRows();

      let queryPayload = { ...payloadRef.current, limit: LIMIT, offset: 0 };
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(0, [], queryPayload, true);
    }
  }, [isSuccessDeleting, isUninitDeleting, executeQuery]);

  const onSearch = useCallback(
    (payload) => {
      const { searchTerm, searchOrigin, searchDestination } = payload;
      dispatch(setSearchFilters(payload));

      clearSelectedRows();
      let queryPayload = {
        ...payloadRef.current,
        limit: LIMIT,
        offset: 0,
        search: searchTerm,
        origin__short_name__icontains: searchOrigin,
        destination__short_name__icontains: searchDestination,
      };

      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(0, [], queryPayload, true);
    },
    [executeQuery, dispatch]
  );

  const onCreate = () => {
    // Clear OD redux state
    dispatch(setOriginLocation(null));
    dispatch(setOriginType(null));
    dispatch(setDestinationLocation(null));
    dispatch(setDestinationType(null));

    dispatch(push(`/app/digitalmap/route_masters/route_group/create`));
  };

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/digitalmap/route_masters/route_group/${cell.id}`));
  };

  const onDelete = () => {
    // TODO: Bulk delete
    for (let row of selectedRows) {
      deleteRouteGroup({ id: row.id });
    }
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setShowDelete(false);
  };

  useEffect(() => {
    if (height) {
      let h = height - 240;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  const [vt] = useVT(
    () => ({
      onScroll: async ({ top, isEnd }) => {
        if (isEnd && dataSource.length < totalRows) {
          await executeQuery(offset, dataSource, {
            ...payloadRef.current,
            limit: LIMIT,
            offset: offset,
          });
          ref.current?.scrollTo(top);
        }
      },
      ref,
      scroll: { y: tableHeight },
    }),
    [dataSource, tableHeight]
  );

  useEffect(() => {
    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(setCurrentOffset(offset));
  }, [offset, dispatch]);

  useEffect(() => {
    dispatch(setRouteGroupDataSource(dataSource));
  }, [dataSource, dispatch]);

  const onReload = async () => {
    clearSelectedRows();

    let bufferData = [];
    let requiredRows = page * PAGE_SIZE;
    requiredRows =
      requiredRows > totalRows && totalRows !== 0 ? totalRows : requiredRows;

    if (requiredRows > bufferData.length) {
      // Fetch more data
      let curOffset = 0;
      do {
        let queryRes = await executeQuery(
          curOffset,
          bufferData,
          {
            ...payloadRef.current,
            limit: LIMIT,
            offset: curOffset,
          },
          true
        );
        curOffset += LIMIT;
        bufferData = queryRes.data;
        requiredRows =
          requiredRows > queryRes.totalRows ? queryRes.totalRows : requiredRows;
      } while (requiredRows > bufferData.length);
    }
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);

      if (permission.checkPermission(user, 'route_group', ['d'])) {
        setShowDelete(selectedRows.length > 0 ? true : false);
      }
    },
  };

  return (
    <Container>
      <TableToolbar
        onCreate={onCreate}
        onDelete={onDelete}
        onReload={onReload}
        showDelete={showDelete}
        showReload={true}
        isLoading={isLoading}
        isDeleting={isDeleting}
        showAdd={permission.checkPermission(user, 'route_group', ['c'])}
        showSearch={true}
        columns={columns}
        setColumns={setColumns}
        onSearch={onSearch}
      />
      <AntTable
        rowKey='id'
        tableId={'route_group'}
        rowSelection={rowSelection}
        showSorterTooltip={false}
        loading={isLoading || isDeleting}
        columns={columns}
        dataSource={dataSource}
        // height={tableHeight}
        scroll={{
          y: tableHeight,
          // scrollToFirstRowOnChange: true,
        }}
        components={vt}
        pagination={false}
        // Below is for right click context menu
        showContextMenu={true}
        showFilterDropdown={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: 'Edit', action: (row) => handleEditClicked(row) },
          {
            label: 'Delete',
            action: (row) => {
              deleteRouteGroup({ id: row.id });
            },
            delete: true,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
