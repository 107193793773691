import { handleActions, createAction } from 'redux-actions';

export const mapDrawingMode = createAction('MAP_DRAWING_MODE');
export const mapAreaType = createAction('MAP_AREA_TYPE');
export const mapDrawingSelected = createAction('MAP_DRAWING_SELECTED');

export const mapResetMap = createAction('MAP_RESET_MAP');
export const mapCenterChanged = createAction('MAP_CENTER_CHANGE');
export const mapZoomChanged = createAction('MAP_ZOOM_CHANGE');
export const mapSetBounds = createAction('MAP_SET_BOUNDS');

//Map Object
export const mapSetMarkerPlace = createAction('MAP_SET_PLACE');
export const mapSetMarkers = createAction('MAP_SET_MARKERS');
export const mapSetCircles = createAction('MAP_SET_CIRCLE');
export const mapSetPolygons = createAction('MAP_SET_POLYGON');
export const mapSetPolylines = createAction('MAP_SET_POLYLINES');
export const mapSetCandidatePolylines = createAction(
  'MAP_SET_CANDIDATE_POLYLINES'
);
export const mapSetMasterPolylines = createAction('MAP_SET_MASTER_POLYLINES');

export const mapSetMasterLegs = createAction('MAP_SET_MASTER_WAYPOINTS');

//Selection
export const mapSelectedMarker = createAction('MAP_SELECT_MARKER');
export const mapSelectedMarkerSearch = createAction('MAP_SELECT_MARKER_SEARCH');
export const mapSelectedCircle = createAction('MAP_SELECT_CIRCLE');
export const mapSelectedPolygon = createAction('MAP_SELECT_POLYGON');
export const MAP_SELECT_CANDIDATE = createAction('MAP_SELECT_CANDIDATE');

export const mapMoveMarker = createAction('MAP_MOVE_MARKER');
export const mapChangePolygon = createAction('MAP_CHANGE_POLYGON');
export const mapChangeCircle = createAction('MAP_CHANGE_CIRCLE');
export const mapMoveMarkerGroup = createAction('MAP_MOVE_MARKERGROUP');

export const mapClearMarker = createAction('MAP_CLEAR_MARKER');
export const mapClearCircle = createAction('MAP_CLEAR_CIRCLE');
export const mapClearPolygon = createAction('MAP_CLEAR_POLYGON');

export const mapSetLogisticPoint = createAction('MAP_SET_LOGISTICPOINT');
export const mapSetLocationGroup = createAction('MAP_SET_LOCATIONGROUP');
export const mapSetLocationGroupType = createAction(
  'MAP_SET_LOCATIONGROUPTYPE'
);

export const SET_MAP_LOADING = createAction('SET_MAP_LOADING');

export const MAP_LOG = createAction('MAP_LOG');
export const ADD_WAYPOINT = createAction('ADD_WAYPOINT');
export const LOAD_MODIFIED_ROUTE_SUCCESS = createAction(
  'LOAD_MODIFIED_ROUTE_SUCCESS'
);
export const MARKER_MOVE = createAction('MARKER_MOVE');
export const MARKER_DRAGGING = createAction('MARKER_DRAGGING');
export const MARKER_SELECTING = createAction('MARKER_SELECTING');
export const CLEAR_SELECTING = createAction('CLEAR_SELECTING');
export const DEL_MARKER = createAction('DEL_MARKER');

export const SET_MARKER_ORIGIN_DESC = createAction('SET_MARKER_ORIGIN_DESC');

export const SELECT_MASTER_OR_CANDIDATE = createAction(
  'SELECT_MASTER_OR_CANDIDATE'
);

export const SET_USE_TOLL = createAction('SET_USE_TOLL');

export const SET_ROUTESVIEW = createAction('SET_ROUTESVIEW');

export const MAP_SELECT_HAZARD = createAction('MAP_SELECT_HAZARD');
export const MAP_SELECT_ROUTE = createAction('MAP_SELECT_ROUTE');

const initialState = {
  show_master_or_candidate: 1,

  markerList: [],
  circleList: [],
  polygonList: [],
  candidatePolylineList: [],
  masterPolylineList: [],
  suggestionPolylineList: [],
  waypoints: [],
  routesview: [],
  path_onMouseOver: -1,
  paths: [],
  legs: [],

  marker_search_selecting: '',
  routeMaster_selecting: '',
  routeCandidate_selecting: -1,
  circle_selecting: '',
  polygon_selecting: '',

  drawingMode: null,
  areaType: null,

  markerPlace: null,
  center: {
    lat: 13.95,
    lng: 101.33,
  },
  zoom: 11,
  bounds: [],
  boundsIsSet: false,
  map_loading: false,

  //--- Route Modifying
  marker_selecting: -1,
  marker_Dragging: -1,
  marker_MovingAdding: -1,
  modified_route: [],

  marker_origin: null,
  marker_desc: null,
  TollUsage: null,
  use_toll: 1,
};

// REDUCERS
export default handleActions(
  {
    [SET_ROUTESVIEW](state, { payload }) {
      return {
        ...state,
        routesview: payload,
      };
    },

    [SET_USE_TOLL](state, { payload }) {
      return {
        ...state,
        use_toll: payload,
      };
    },

    [SELECT_MASTER_OR_CANDIDATE](state, { payload }) {
      return {
        ...state,
        show_master_or_candidate: payload,
      };
    },

    [SET_MARKER_ORIGIN_DESC](state, { payload }) {
      let this_bounds = [];
      this_bounds.push({
        lat: payload.marker_origin.lat,
        lng: payload.marker_origin.lng,
      });
      this_bounds.push({
        lat: payload.marker_desc.lat,
        lng: payload.marker_desc.lng,
      });

      return {
        ...state,
        bounds: this_bounds,
        marker_origin: payload.marker_origin,
        marker_desc: payload.marker_desc,
      };
    },

    [SET_MAP_LOADING](state, { payload }) {
      return {
        ...state,
        map_loading: payload,
      };
    },

    [DEL_MARKER](state, { payload }) {
      let id = payload;
      var newArray = state.waypoints.slice();
      newArray.splice(id, 1);

      return {
        ...state,
        waypoints: newArray,
        marker_selecting: -1,
      };
    },
    [CLEAR_SELECTING](state, { payload }) {
      return {
        ...state,
        marker_selecting: -1,
        marker_MovingAdding: -1,
        hazard_selecting: -1,
        marker_search_selecting: null,
      };
    },
    [MARKER_SELECTING](state, { payload }) {
      return {
        ...state,
        marker_selecting: payload,
        marker_MovingAdding: payload,
      };
    },
    [MARKER_DRAGGING](state, { payload }) {
      return {
        ...state,
        marker_Dragging: payload,
        marker_MovingAdding: payload,
      };
    },
    [MARKER_MOVE](state, { payload }) {
      const moving_marker = payload;

      var newArray = state.waypoints.slice();
      newArray[moving_marker.id] = { position: moving_marker.position };

      return {
        ...state,
        waypoints: newArray,
        marker_Dragging: -1,
        marker_MovingAdding: moving_marker.id,
      };
    },
    [LOAD_MODIFIED_ROUTE_SUCCESS](state, { payload }) {
      return {
        ...state,
        legs: payload.legs,
        modified_route: payload.modified_route,
        TollUsage: payload.TollUsage,
      };
    },
    [ADD_WAYPOINT](state, { payload }) {
      let id = payload.id;
      let position = payload.position;

      var cpWaypoints = state.waypoints.slice();

      cpWaypoints.splice(id + 1, 0, { position: position });

      return {
        ...state,
        marker_MovingAdding: id + 1,
        marker_selecting: -1,
        waypoints: cpWaypoints,
      };
    },

    [mapSetMarkerPlace](state, { payload }) {
      return { ...state, markerPlace: payload };
    },

    [mapSetMarkers](state, { payload }) {
      return { ...state, markerList: payload };
    },
    [mapSetCircles](state, { payload }) {
      return { ...state, circleList: payload };
    },
    [mapSetPolygons](state, { payload }) {
      return { ...state, polygonList: payload };
    },
    [mapSetPolylines](state, { payload }) {
      return { ...state, polylineList: payload };
    },
    [mapSetCandidatePolylines](state, { payload }) {
      return { ...state, candidatePolylineList: payload };
    },
    [mapSetMasterPolylines](state, { payload }) {
      return { ...state, masterPolylineList: payload };
    },

    [mapSetMasterLegs](state, { payload }) {
      return { ...state, legs: payload.legs, waypoints: payload.waypoints };
    },

    [mapSelectedMarker](state, { payload }) {
      return { ...state, marker_selecting: payload };
    },
    [mapSelectedMarkerSearch](state, { payload }) {
      return { ...state, marker_search_selecting: payload };
    },

    [mapMoveMarker](state, { payload }) {
      let markers = state.markerList.slice();
      markers.forEach((marker, i) => {
        if (marker.id === payload.id) markers[i] = payload;
      });
      return { ...state, markerList: markers };
    },

    [mapSelectedCircle](state, { payload }) {
      return { ...state, circle_selecting: payload };
    },

    [MAP_SELECT_CANDIDATE](state, { payload }) {
      return { ...state, routeCandidate_selecting: payload };
    },

    [MAP_SELECT_ROUTE](state, { payload }) {
      return { ...state, routeMaster_selecting: payload };
    },

    [mapChangePolygon](state, { payload }) {
      let polygons = state.polygonList.slice();
      polygons.forEach((polygon, i) => {
        if (polygon.id === payload.id) polygons[i] = payload;
      });
      return { ...state, polygonList: polygons };
    },
    [mapChangeCircle](state, { payload }) {
      let circles = state.circleList.slice();
      circles.forEach((circle, i) => {
        if (circle.id === payload.id) circles[i] = payload;
      });
      return { ...state, circleList: circles };
    },
    ////////////CLAR/////////////
    [mapClearMarker](state, { payload }) {
      return { ...state, markerList: [] };
    },
    [mapClearCircle](state, { payload }) {
      return { ...state, circleList: [] };
    },
    [mapClearPolygon](state, { payload }) {
      return { ...state, polygonList: [] };
    },
    [mapResetMap](state, { payload }) {
      return {
        ...state,
        markerList: [],
        circleList: [],
        polygonList: [],
        candidatePolylineList: [],
        marker_selecting: -1,
        routeCandidate_selecting: -1,
        routeMaster_selecting: -1,
        masterPolylineList: [],
        routesview: [],
        waypoints: [],
        legs: [],
        marker_origin: null,
        marker_desc: null,
        markerPlace: null,
        marker_search_selecting: null,
      };
    },

    /////////CENTER////////////
    [mapCenterChanged](state, { payload }) {
      return { ...state, center: payload };
    },
    [mapZoomChanged](state, { payload }) {
      return { ...state, zoom: payload, boundsIsSet: false };
    },
    [mapSetBounds](state, { payload }) {
      if (payload.length > 0)
        return { ...state, bounds: payload, boundsIsSet: true };
      else return { ...state };
    },
    ////////////DRAWING//////////////
    [mapDrawingMode](state, { payload }) {
      return { ...state, drawingMode: payload };
    },

    [mapAreaType](state, { payload }) {
      return { ...state, areaType: payload };
    },
  },
  initialState
);
