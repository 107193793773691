import React, { useState, useEffect, useRef, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import TableToolbar from './Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import DeliveryStatusRenderer from './DeliveryStatusRenderer';
import EvaStatusRenderer from './EvaStatusRenderer';
import VehicleStatusRenderer from './VehicleStatusRenderer';
import moment from 'moment';
import { operationDashboardApi } from 'reducers/digitalmap/operationDashboard/api';
import { message, Modal, Progress, Typography } from 'antd';
import { useErrorHandler } from 'common_components/ErrorContext';
import useWindowSize from 'hooks/windowSize';
import {
  setCurrentPage,
  setCurrentOffset,
  setOperationDashboardDataSource,
} from 'reducers/digitalmap/operationDashboard';

import Json2CSV from 'utils/json2csv';
// import { push } from 'connected-react-router';
import { getLanguage } from 'reducers/language';

const LIMIT = 20;
const LIMIT_DOWNLOAD = 2000;
const PAGE_SIZE = 20;

const calOffsetFromPage = (page) => {
  return (page - 1 < 0 ? 0 : page - 1) * PAGE_SIZE;
};

const OperationDashboardTable = (props) => {
  const { setTotalRows, totalRows } = props;
  const language = useSelector(getLanguage);

  // Redux Store
  const storedPage = useSelector((state) => {
    return state.operationDashboard.currentPage;
  });
  const storedOffset = useSelector((state) => {
    return state.operationDashboard.currentOffset;
  });
  const storedDataSource = useSelector((state) => {
    return state.operationDashboard.dataSource;
  });

  const payloadRef = useRef(null);

  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [offset, setOffset] = useState(storedOffset || 0);
  const [page, setPage] = useState(storedPage || 1);
  const [tableHeight, setTableHeight] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // Download state
  const isDownloadingRef = useRef(false);
  const [downloadVisible, setDownloadVisible] = useState(false);
  const [totalDownloadRows, setTotalDownloadRows] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadCount, setDownloadCount] = useState(0);

  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'dmoperation')
  );

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const { height } = useWindowSize();

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (!showFilter) {
        h = h + 170;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  useEffect(() => {
    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(setCurrentOffset(offset));
  }, [offset, dispatch]);

  useEffect(() => {
    dispatch(setOperationDashboardDataSource(dataSource));
  }, [dataSource, dispatch]);

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'No.',
        dataIndex: 'id',
        width: 40,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster',
        width: 50,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        //    searchable: true,
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        width: 150,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'VIN No',
        dataIndex: 'vin_no',
        width: 150,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Series',
        dataIndex: 'series',
        width: 120,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Model/sfx',
        dataIndex: 'model',
        width: 150,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Calling no.',
        dataIndex: 'calling_no',
        width: 120,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Departure From',
        dataIndex: 'depart_from',
        width: 120,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
      },
      {
        title: 'Gate out time',
        dataIndex: 'gate_out_time',
        width: 110,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
      },
      {
        title: 'Original ETA',
        dataIndex: 'original_eta',
        width: 110,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
      },
      {
        title: 'Update ETA',
        dataIndex: 'update_eta',
        width: 110,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
      },
      {
        title: 'ATA',
        dataIndex: 'ata',
        width: 110,
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        // sorter: {
        //   compare: Sorter.DATE,
        // },
        movable: true,
      },
      {
        title: 'Vehicle Status',
        dataIndex: 'vehicle_status',
        width: 100,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        align: 'center',
        render: (text, record) => {
          return <VehicleStatusRenderer data={text} />;
        },
        movable: true,
      },
      {
        title: 'Delivery Status',
        dataIndex: 'delivery_status',
        width: 120,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        align: 'center',
        render: (text, record) => {
          return <DeliveryStatusRenderer data={text} />;
        },
        movable: true,
      },
      {
        title: 'EVA',
        dataIndex: 'eva',
        width: 50,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        align: 'center',
        render: (text, record) => {
          return <EvaStatusRenderer data={record} />;
        },
      },
      {
        title: 'Reason',
        dataIndex: 'reason_description',
        width: 100,
        // sorter: {
        //   compare: Sorter.DEFAULT,
        // },
        movable: true,
        render: (text, record) => {
          if (text && record.delivery_status === 'delay') {
            return text;
          } else if (
            language === 'th' &&
            record.reason &&
            record.reason.subject_reason.name_th &&
            record.delivery_status === 'delay'
          ) {
            return record.reason.subject_reason.name_th;
          } else if (
            language === 'en' &&
            record.reason &&
            record.reason.subject_reason.name_en &&
            record.delivery_status === 'delay'
          ) {
            return record.reason.subject_reason.name_en;
          } else {
            return '';
          }
        },
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }

    return cols;
  });

  const executeQuery = useCallback(
    async (payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          operationDashboardApi.endpoints.findAllJobsByDatetime.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = payload.offset + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              id: i + rowNo,
            }));

            newData = resData;
            setTotalRows(result.data.count);
            setDataSource(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  const onInit = useCallback(
    (payload) => {
      // If having data, no need to load on init
      if (offset > 0) {
        payloadRef.current = { ...payload, limit: LIMIT, offset: offset };
        return;
      }
      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(queryPayload);
    },
    [executeQuery, offset]
  );

  const onQuery = async (payload) => {
    try {
      if (!isLoading) {
        // Force to load from offset 0 when user push search button
        setOffset(0);
        setPage(1);
        payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };
        await executeQuery(
          {
            ...payloadRef.current,
          },
          true
        );
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const executeDownload = async (curOffset, curData, payload) => {
    let newData = null;
    let totalRows = null;
    try {
      setIsLoading(true);
      let result = await dispatch(
        operationDashboardApi.endpoints.findAllJobsByDatetime.initiate(
          payload,
          { forceRefetch: true }
        )
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          let rowNo = curData.length + 1;
          let resData = result.data.results.map((d, i) => ({
            id: i + rowNo,
            ...d,
          }));

          newData = curData.concat(resData);
          totalRows = result.data.count;
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    } finally {
      setIsLoading(false);
      return {
        totalRows: totalRows,
        offset: curOffset + LIMIT_DOWNLOAD,
        rowData: newData,
      };
    }
  };
  const onAutoRefetch = async (payload) => {
    let curOffset = calOffsetFromPage(page);
    let queryPayload = { ...payload, limit: LIMIT, offset: curOffset };
    payloadRef.current = queryPayload;
    await executeQuery(queryPayload, true);
  };

  const onDownload = async (payload) => {
    let curOffset = 0;
    let bufferData = [];
    let total = null;

    setDownloadVisible(true);
    isDownloadingRef.current = true;

    setTotalDownloadRows(0);
    setDownloadProgress(0);
    setDownloadCount(0);
    try {
      do {
        let queryPayload = {
          ...payload,
          mode: 'download',
          limit: LIMIT_DOWNLOAD,
          offset: curOffset,
        };
        let result = await executeDownload(curOffset, bufferData, queryPayload);
        if (result.totalRows !== null) {
          total = result.totalRows;
          let count = result.rowData.length;

          setTotalDownloadRows(total);
          setDownloadProgress(parseInt((count / total) * 100));
          setDownloadCount(count);

          // Update
          bufferData = result.rowData;
          curOffset = result.offset;
        } else {
          break;
        }
      } while (total > bufferData.length && isDownloadingRef.current === true);
      // Cancel download
      if (isDownloadingRef.current === false) {
        return;
      }
      if (total === 0) {
        setDownloadProgress(100);
        message.info('Downloaded data is empty');
        return;
      }

      if (total && total === bufferData.length) {
        let truckStatusResp = await dispatch(
          operationDashboardApi.endpoints.findAllTruckStatuses.initiate()
        );

        if (truckStatusResp) {
          let truckStatuses = truckStatusResp.data;
          bufferData = bufferData.map((d) => ({
            'No.': d.id,
            Cluster: d.cluster,
            Destination: d.destination,
            'VIN No': d.vin_no,
            Series: d.series,
            'Model/sfx': d.model,
            'Calling no.': d.calling_no,
            'Departure From': d.depart_from,
            'Gate out time': dateTimeTimestamptzFormatter({
              value: d.gate_out_time,
            }),
            'Original ETA': dateTimeTimestamptzFormatter({
              value: d.original_eta,
            }),
            'Update ETA': dateTimeTimestamptzFormatter({ value: d.update_eta }),
            ATA: dateTimeTimestamptzFormatter({ value: d.ata }),
            'Vehicle Status': (() => {
              let status = truckStatuses.find(
                (t) => t.code === d.vehicle_status.toString()
              );
              if (status) {
                return status.name;
              } else {
                return d.vehicle_status;
              }
            })(),
            'Delivery Status': d.delivery_status,
            EVA: d.eva,
            Reason: d.reason_description
              ? d.reason_description
              : language === 'en'
              ? d.reason.subject_reason.name_en
              : d.reason.subject_reason.name_th,
          }));
        }

        Json2CSV(
          bufferData,
          `dm_monitoring_${moment().format('YYYYMMDD_HHmmss')}.csv`,
          ','
        );
      } else {
        message.error('Something when wrong, please try again');
      }
    } catch (error) {
      message.error('Something when wrong');
    } finally {
      setTimeout(() => {
        isDownloadingRef.current = false;
        setDownloadVisible(false);
      }, 1000);
    }
  };

  const handleDownloadCancel = () => {
    setDownloadVisible(false);
    isDownloadingRef.current = false;
  };

  return (
    <Container>
      <TableToolbar
        isLoading={isLoading}
        columns={columns}
        setColumns={setColumns}
        onInit={onInit}
        onQuery={onQuery}
        onDownload={onDownload}
        onAutoRefetch={onAutoRefetch}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />

      <StyledTable
        rowKey='id'
        tableId={'dmoperation'}
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        pagination={{
          pageSize: PAGE_SIZE,
          total: totalRows,
          current: page,
          size: 'small',
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: async (_page, _) => {
            let curOffset = calOffsetFromPage(_page);
            await executeQuery(
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            setPage(_page);
          },
        }}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        // Below is for right click context menu
        showContextMenu={false}
        // menuActions={[
        //   {
        //     label: 'View',
        //     action: (row) => {
        //       dispatch(
        //         push(
        //           `/app/digitalmap/monitoring_jobs/truck_schedules/${row.truck_schedule_id}/orders/${row.order_id}`
        //         )
        //       );
        //     },
        //   },
        // ]}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <Modal
        title='Downloading...'
        okButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        visible={downloadVisible}
        onCancel={handleDownloadCancel}
      >
        <Progress
          type='circle'
          percent={downloadProgress}
          width={80}
          style={{ width: '100%', textAlign: 'center' }}
        />
        <Typography
          style={{ width: '100%', textAlign: 'center' }}
        >{`Downloaded items: ${downloadCount} / ${totalDownloadRows}`}</Typography>
      </Modal>
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body {
      height: ${(props) => {
        return props.height + 'px';
      }};
    }
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: auto;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`;
export default OperationDashboardTable;
