import React, { useState, useEffect, useCallback } from 'react';

import AntTable from 'common_components/AntTable';

import EditButtonRenderer from 'common_components/EditButtonRenderer';
import LinkRenderer from 'common_components/LinkRenderer';
import TableToolbar from 'common_components/AntTable/Toolbar';
import { push } from 'connected-react-router';
import permission from 'permissions';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { useDeleteYardMutation } from 'reducers/masterData/yard/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { Sorter } from 'utils/sorter';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import { message } from 'antd';

const TableComponent = (props) => {
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'yard')
  );

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: '',
        dataIndex: 'edit',
        align: 'center',
        render: (text, record) => {
          return (
            <EditButtonRenderer data={record} onClick={handleEditClicked} />
          );
        },
        width: 50,
      },
      {
        title: 'Cluster Code',
        dataIndex: 'cluster',
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='cluster'
              name='name'
              path='/app/master/clusters'
            />
          );
        },
        width: 200,
        movable: true,
      },
      {
        title: 'Code',
        dataIndex: 'code',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Name (EN)',
        dataIndex: 'name_en',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Name (TH)',
        dataIndex: 'name_th',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Company',
        dataIndex: 'company',
        render: (text, record) => {
          return (
            <LinkRenderer
              data={record}
              field='company'
              name='name'
              path='/app/user_mng/companies'
            />
          );
        },
        width: 200,
        movable: true,
      },
      {
        title: 'DN Created Code',
        dataIndex: 'dn_created_code',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Yard Type',
        dataIndex: 'yard_type',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Trailer Total Lane',
        dataIndex: 'trailer_total_lane',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Trailer Lane 8L',
        dataIndex: 'trailer_lane_8l',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Trailer Lane 4L',
        dataIndex: 'trailer_lane_4l',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }
    return cols;
  });

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  const [
    deleteYard,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
      isLoading: isDeleting,
    },
  ] = useDeleteYardMutation();

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      clearSelectedRows();
    }
  }, [isSuccessDeleting, isUninitDeleting]);

  const onSearch = useCallback(
    (searchTerm) => {
      if (rowData && rowData.length > 0) {
        let searchCols = columns.filter((c) => c.searchable);
        if (searchCols.length > 0 && searchTerm) {
          let filterData = rowData.filter((d) =>
            searchCols.some(
              (s) =>
                d[s.dataIndex] &&
                String(d[s.dataIndex])
                  .toLowerCase()
                  .includes(searchTerm.trim().toLowerCase())
            )
          );
          setDataSource(filterData);
        } else {
          setDataSource(rowData);
        }
      }
      clearSelectedRows();
    },
    [columns, rowData]
  );

  const onCreate = () => {
    dispatch(push(`/app/master/yards/create`));
  };

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/master/yards/${cell.id}`));
  };

  const onDelete = (isPermanentDelete) => {
    for (let row of selectedRows) {
      deleteYard({ id: row.id });
    }
    clearSelectedRows();
  };

  const onReload = () => {
    props.refetchData();
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setShowDelete(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);

      if (permission.checkPermission(user, 'yard', ['d'])) {
        setShowDelete(selectedRows.length > 0 ? true : false);
      }
    },
  };

  return (
    <Container>
      <TableToolbar
        onCreate={onCreate}
        onDelete={onDelete}
        onReload={onReload}
        showDelete={showDelete}
        showReload={true}
        isLoading={isLoading}
        isDeleting={isDeleting}
        showAdd={permission.checkPermission(user, 'yard', ['c'])}
        showSearch={true}
        columns={columns}
        setColumns={setColumns}
        onSearch={onSearch}
      />
      <AntTable
        tableId={'yard'}
        rowSelection={rowSelection}
        bordered
        showSorterTooltip={false}
        rowKey='id'
        loading={isLoading || isDeleting}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content' }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: 'Edit', action: (row) => handleEditClicked(row) },
          {
            label: 'Delete',
            action: (row) => deleteYard({ id: row.id }),
            delete: true,
          },
        ]}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
