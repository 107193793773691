import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { setSidebarVisibility } from 'reducers/ui';
import Table from './Table';
import { useGetCallingCompleteQuery } from 'reducers/dispatchBoard/api';
import { setDispatchBoardTotalRows } from 'reducers/dispatchBoard';

const DeliveryListComponent = (props) => {
  const storedTotalRows = useSelector((state) => {
    return state.dispatchBoard.totalRows;
  });
  const [totalRows, setTotalRows] = useState(storedTotalRows || 0);
  const [numberCallingComplete, setNumberCallingComplete] = useState(0);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  useEffect(() => {
    if (totalRows) {
      dispatch(setDispatchBoardTotalRows(totalRows));
    }
  }, [totalRows, dispatch]);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  const {
    data: dataCallingComplete,
    error: errorCallingComplete,
    refetch: refetchDriverConfirmByUser,
    isFetching: isFetchingDV,
  } = useGetCallingCompleteQuery(null, {
    skip: false,
  });

  useEffect(() => {
    if (dataCallingComplete) {
      setNumberCallingComplete(dataCallingComplete);
    }
  }, [dataCallingComplete]);

  useEffect(() => {
    if (errorCallingComplete) {
      setErrorData(errorCallingComplete);
    }
  }, [errorCallingComplete, setErrorData]);


  return (
    <Container>
      <Content>
        <Table
          totalRows={totalRows}
          setTotalRows={setTotalRows}
          numberCallingComplete={numberCallingComplete}
          setNumberCallingComplete={setNumberCallingComplete}
        />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100%);
  overflow: auto;
  padding: 10px;
`;

export default DeliveryListComponent;
