import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  PageHeader,
  Modal,
  message,
  Row,
  Col,
  Button,
  Select,
  Typography,
} from 'antd';

import { setCurrentPage, setVinTotalRows } from 'reducers/vin';
import styled from 'styled-components';
import { setSidebarVisibility } from 'reducers/ui';
import { RemoveDuplicates } from 'utils/removeDuplicates';
import { csv } from 'utils/csv';

import { useFindLocationFromQuery } from 'reducers/manageCalling/api';
import Draggable from 'react-draggable';
import Table from './Table';
import Toolbar from './Toolbar';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Sorter } from 'utils/sorter';
import { useSelector, useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import moment from 'moment';
import {
  setManageCallingNoStatusMode,
  setManageCallingDataSourceForModeCreateNewCalling,
  setManageCallingDataSourceForModeEditVin,
  setManageCallingDataSourceForModeAddVin,
} from 'reducers/manageCalling';
import { useParams } from 'react-router-dom';
import {
  useFindAllMasterDataVinQuery,
  useFindAllEditVinDataMutation,
  useUploadVinListUploadMutation,
  useEditVinListMutation,
  useFindAllVinListMutation,
} from 'reducers/vin/api';

import PrintKakudai from 'common_components/PrintDocument/PrintingKakudai/PrintKakudai';
import { setvinListDataSelection } from 'reducers/vin';
import { push } from 'connected-react-router';
import usePrint from 'hooks/usePrint';
import vinListConstants from './constants';

const calOffsetFromPage = (page, size) => {
  return (page - 1 < 0 ? 0 : page - 1) * size;
};

const VinListComponent = () => {
  const { mode, calling_no, from, filterfrom } = useParams();
  const dispatch = useDispatch();
  const printKakudaiComponentRef = useRef();
  const { confirm, warning } = Modal;
  const { setErrorData } = useErrorHandler();
  const { Option } = Select;
  const payloadRef = useRef(null);
  const selectedRowsRef = useRef([]);

  const arrDataRef = useRef([]);

  const valueToModelRef = useRef({
    toId: '',
    to: '',
    cluster: '',
  });
  const valueCustomerModelRef = useRef({
    customerId: '',
    customer: '',
    customerCode: '',
  });

  const valueVehicleTypeModelRef = useRef({
    vehicleTypeId: '',
    vehicleType: '',
  });

  const { Title } = Typography;
  const draggleRef = useRef(null);
  const { fromUrl, toUrl, clusterUrl, vehicleStatusUrl } = useParams();
  const [dataKakudai, setDataKakudai] = useState([]);
  const onStart = () => {
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
  };

  // Redux Store
  const storedTotalRows = useSelector((state) => {
    return state.vin.totalRows;
  });
  const storedPage = useSelector((state) => {
    return state.vin.currentPage;
  });
  const storedPageSize = useSelector((state) => {
    return state.vin.currentPageSize;
  });
  const storedOffset = useSelector((state) => {
    return state.vin.currentOffset;
  });
  const storedDataSourceForModeEditVin = useSelector((state) => {
    return state.manageCalling.dataSourceForModeEditVin;
  });

  const storedDataSourceForModeAddVin = useSelector((state) => {
    return state.manageCalling.dataSourceForModeAddVin;
  });

  const storedStatusMode = useSelector((state) => {
    return state.manageCalling.statusMode;
  });
  const storedFilters = useSelector((state) => {
    return state.vin.filters;
  });
  const storedDataModeEditVin = useSelector((state) => {
    return state.vin.dataListVinEdit;
  });

  const [showFilter, setShowFilter] = useState(true);
  const [dataSource, setDataSource] = useState();
  const [dataSourceForModeAddVin, setDataSourceAllAddVin] = useState(
    storedDataSourceForModeAddVin ? storedDataSourceForModeAddVin : []
  );
  const [offset, setOffset] = useState(storedOffset || 0);
  const [pageSize, setPageSize] = useState(
    storedPageSize || vinListConstants.LIMIT
  );
  const [page, setPage] = useState(storedPage || 1);
  const [totalRows, setTotalRows] = useState(storedTotalRows || 0);
  const [dataSourceUrl, setDataSourceUrl] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [dataUploadFile, setDataUploadFile] = useState([]);
  const [clusterFilter, setClusterFilter] = useState(null);
  const [vehicelStatusFilter, setVehicleStatusFilter] = useState(null);
  const [toTalSelect, setToTalSelect] = useState(0);
  const [loadingonAddToCalling, setLoadingonAddToCalling] = useState(0);
  const [masterOptionTo, setMasterOptionTo] = useState();
  const [masterOptionCustomer, setMasterOptionCustomer] = useState();
  const [masterOptionVehicleTypes, setMasterOptionVehicleTypes] = useState();
  const [disableFilterUrl, setDisableFilterUrl] = useState(false);

  // for model
  const [visible, setVisible] = useState(false);

  //parameter
  const [filterCluster, setFilterCluster] = useState(
    storedFilters && storedFilters.filterCluster
      ? storedFilters.filterCluster
      : undefined
  );
  const [filterFrom, setFilterFrom] = useState(
    storedFilters && storedFilters.filterFrom
      ? storedFilters.filterFrom
      : undefined
  );

  const [filterVehicle, setFilterVehicle] = useState(
    storedFilters && storedFilters.filterVehicle
      ? storedFilters.filterVehicle
      : undefined
  );

  const [filterTo, setFilterTo] = useState(
    storedFilters && storedFilters.filterTo ? storedFilters.filterTo : undefined
  );
  const [filterVehicleType, setFilterVehicleType] = useState(
    storedFilters && storedFilters.filterVehicleType
      ? storedFilters.filterVehicleType
      : undefined
  );
  const [filterVinno, setFilterVinno] = useState(
    storedFilters && storedFilters.filterVinno
      ? storedFilters.filterVinno
      : undefined
  );
  const [filterDestination, setFilterDestination] = useState(
    storedFilters && storedFilters.filterDestination
      ? storedFilters.filterDestination
      : undefined
  );
  const [filterLotno, setFilterLotno] = useState(
    storedFilters && storedFilters.filterLotno
      ? storedFilters.filterLotno
      : undefined
  );
  const [filterCallingno, setFilterCallingno] = useState(undefined);
  const [filterUploadDate, setfilterUploadDate] = useState(
    storedFilters && storedFilters.filterUploadDate
      ? storedFilters.filterUploadDate
      : null
  );

  const [flistFileUpload, setListFileUpload] = useState([]);
  const [filterOptionFrom, setFilterOptionFrom] = useState();
  const [filterOptionVehicleTypes, setFilterOptionVehicleTypes] = useState();
  const [filterOptionCluster, setFilterOptionCluster] = useState();
  const [filterOptionStatus, setFilterOptionVehicleStatus] = useState();
  const [filterOptionTo, setFilterOptionTo] = useState();
  const [filterOptionDestination, setFilterOptionDestination] = useState();

  const [valueToModel, setValueToModel] = useState([]);
  const [valueCustomerModel, setValueCustomerModel] = useState([]);
  const [valueVehicleTypesModel, setValueVehicleTypesModel] = useState([]);

  const [fileList, setFileList] = useState();

  const [param_mode, setparam_mode] = useState(
    storedStatusMode && storedStatusMode.param_mode
      ? storedStatusMode.param_mode
      : 'VIEW'
  );
  const [param_calling_no, setparam_calling_no] = useState(
    storedStatusMode && storedStatusMode.param_calling_no
      ? storedStatusMode.param_calling_no
      : 'NO'
  );
  const [param_from, setparam_from] = useState(
    storedStatusMode && storedStatusMode.param_from
      ? storedStatusMode.param_from
      : 'MENU'
  );
  const [param_filterfrom, setparam_filterfrom] = useState(
    storedStatusMode && storedStatusMode.param_filterfrom
      ? storedStatusMode.param_filterfrom
      : 'NO'
  );
  // functon
  const [printData, print] = usePrint('Kakudai', dataKakudai);

  //print PDF
  const getDataKakudai = async () => {
    if (dataKakudai.length !== 0) {
      handlePrintKakudai();
    } else {
      message.error('Please select rows data');
    }
  };

  const handlePrintKakudai = useCallback(() => {
    setTimeout(() => {
      print(printKakudaiComponentRef.current, 'print-kakudai');
    }, 500);
  }, [print]);

  function onModalComfrimUpload() {
    let arr_convert = dataUploadFile.map((d) => ({
      vin_no: d.vin_no,
      engine: d.engine,
      color: d.color,
      model_code: d.model_code,
      suffix: d.suffix,
      series: d.series,
      location_from: d.location_from,
      cluster: d.cluster,
      location_to: d.location_to,
      location_destination: d.location_destination,
      vehicle_type: d.vehicle_type,
      customer: d.customer,
      lot_no_cbu: d.lot_no_cbu,
      parking_lane: d.parking_lane,
      upload_date: moment(d.upload_date).format(),
      assigned_date: moment(d.assigned_date).format(),
      vehicle_status: d.vehicle_status,
    }));

    confirm({
      title: 'Do you Want to upload ?',
      icon: <ExclamationCircleOutlined />,
      content: '',
      onOk() {
        let vin_data = {
          vins: arr_convert,
        };
        uploadVinlistAPI(vin_data);
      },
      onCancel() {},
    });
  }
  const createPayload = ({
    mode,
    filterCluster,
    filterVehicle,

    filterFrom,
    filterTo,
    filterVehicleType,

    filterVinno,
    filterDestination,
    filterLotno,

    filterCallingno,
    filterUploadDate,
    limit,
    offset,
  }) => {
    return {
      mode: mode,
      limit,
      offset,
      vin_no: filterVinno ? filterVinno : null,
      lot_no_cbu: filterLotno ? filterLotno : null,
      from: filterFrom,
      upload_date: filterUploadDate
        ? filterUploadDate.format('DD-MM-YYYY')
        : filterUploadDate,
      calling_no: filterCallingno ? filterCallingno : null,
      to: filterTo,
      destination: filterDestination,
      cluster: filterCluster,
      vehicle_status: filterVehicle,
      vehicle_type: filterVehicleType,
    };
  };
  const onSearch = (isResetPage, newPage, newSize) => {
    if (isLoadingSearch) return;
    let loadPage = newPage || page;

    let newSelectedRows = selectedRows;
    if (isResetPage) {
      setPage(1);
      loadPage = 1;
      if (
        param_mode !== 'ADD_VIN' &&
        param_mode !== 'CREATE_NEW_CALLING' &&
        mode !== 'create_new_calling'
      ) {
        onClearAll();
        newSelectedRows = [];
      }
    }

    selectedRowsRef.current = newSelectedRows;
    let curOffset = calOffsetFromPage(loadPage, newSize || pageSize);

    onSearchVinList({
      ...createPayload({
        filterCluster,
        filterVehicle,

        filterFrom,
        filterTo,
        filterVehicleType,

        filterVinno,
        filterDestination,
        filterLotno,

        filterCallingno,
        filterUploadDate,
        limit: newSize || pageSize,
        offset: curOffset,
      }),
    });
  };

  const onClearAll = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setToTalSelect(0);
  };
  const onAddToCalling = async () => {
    let newDataSource = [];
    let summaryList = [];

    setLoadingonAddToCalling(true);
    setTimeout(() => {
      newDataSource = selectedRows.map((item) => {
        return {
          ...item,
        };
      });
  
      for (const i in dataSourceForModeAddVin) {
        if (Object.hasOwnProperty.call(dataSourceForModeAddVin, i)) {
          summaryList.push(dataSourceForModeAddVin[i]);
        }
      }

      for (const i in newDataSource) {
        if (Object.hasOwnProperty.call(newDataSource, i)) {
          summaryList.push(newDataSource[i]);
        }
      }

      setDataSourceAllAddVin(summaryList);
      setSelectedRows(summaryList);
    }, 2000);

    setTimeout(() => {
      dispatch(push('/app/delivery_mng/manage_calling/no_calling'));
      setLoadingonAddToCalling(false);
    }, 2000);
  };
  const showModalEditVinlist = () => {
    if (selectedRows.length !== 0) {
      valueToModelRef.current = {
        toId: selectedRows[0].location_to_id,
        to: selectedRows[0].location_to,
        cluster: selectedRows[0].cluster,
      };

      valueCustomerModelRef.current = {
        customerId: selectedRows[0].customer_id,
        customer: selectedRows[0].customer,
        customerCode: selectedRows[0].customer_code || selectedRows[0].customer, // Fallback when coming from managecalling page which customer contain customer code instead of customer ID
      };

      valueVehicleTypeModelRef.current = {
        vehicleTypeId: selectedRows[0].vehicle_type_id,
        vehicleType: selectedRows[0].vehicle_type,
      };

      let location_to = masterOptionTo.find(
        (o) => o.label === selectedRows[0].location_to
      );
      setValueToModel(location_to?.value || selectedRows[0].location_to);
      setValueCustomerModel(selectedRows[0].customer);
      setValueVehicleTypesModel(selectedRows[0].vehicle_type);

      setVisible(true);
    } else {
      message.error('Please select rows data');
    }
  };
  const handleOkSave = async () => {
    // find label for display in table location to
    for (const i in masterOptionTo) {
      if (Object.hasOwnProperty.call(masterOptionTo, i)) {
        if (masterOptionTo[i].value === valueToModel) {
          valueToModelRef.current = {
            toId: masterOptionTo[i].value,
            to: masterOptionTo[i].label,
            cluster: masterOptionTo[i].cluster,
          };
        }
      }
    }
    // find label for display in table customer
    for (const i in masterOptionCustomer) {
      if (Object.hasOwnProperty.call(masterOptionCustomer, i)) {
        if (masterOptionCustomer[i].value === valueCustomerModel) {
          valueCustomerModelRef.current = {
            customerId: masterOptionCustomer[i].value,
            customer: masterOptionCustomer[i].label,
            customerCode: masterOptionCustomer[i].customerCode,
          };
        }
      }
    }
    // find label for display in table vehicle types
    for (const i in masterOptionVehicleTypes) {
      if (Object.hasOwnProperty.call(masterOptionVehicleTypes, i)) {
        if (masterOptionVehicleTypes[i].value === valueVehicleTypesModel) {
          valueVehicleTypeModelRef.current = {
            vehicleTypeId: masterOptionVehicleTypes[i].value,
            vehicleType: masterOptionVehicleTypes[i].label,
          };
        }
      }
    }
    // add key for new value
    let arrData = selectedRows.map((d) => ({
      ...d,
      cluster: valueToModelRef.current.cluster,
      customerId: valueCustomerModelRef.current.customerId,
      customer: valueCustomerModelRef.current.customerCode,
      locationToId: valueToModelRef.current.toId,
      location_to: valueToModelRef.current.to,
      vehicleTypeId: valueVehicleTypeModelRef.current.vehicleTypeId,
      vehicle_type: valueVehicleTypeModelRef.current.vehicleType,
    }));

    let arrEditData = [];
    let arrOldData = dataSource;

    // push object
    for (let i = 0; i < dataSource.length; i++) {
      for (let j = 0; j < arrData.length; j++) {
        if (dataSource[i].vin_no === arrData[j].vin_no) {
          arrEditData.push(arrData[j]);
        }
      }
    }
    // for summary array data list
    var arrSummary = arrOldData.map(
      (obj) => arrEditData.find((o) => o.vin_no === obj.vin_no) || obj
    );
    arrDataRef.current = await arrData;
    // set select row and data source
    await setSelectedRows(arrData);
    await setDataSource(arrSummary);

    // When on editing vin mode (coming from manage calling page)
    // Do not call api to save data on VIN page
    // Just transfer modified data to manage calling page and save data there
    if (param_mode === 'EDIT_VIN') {
      setVisible(false);
    } else {
      // for body api
      let body = arrData.map((item) => ({
        vin_no: item.vin_no,
        customer_id: item.customerId,
        location_to_id: item.locationToId,
        vehicle_type_id: item.vehicleTypeId,
      }));

      apiEditVinList(body);
    }
  };
  const handleCancelSave = () => {
    setVisible(false);
  };

  const [
    apiSearchVinList,
    { data: dataSearchVin, isLoading: isLoadingSearch },
  ] = useFindAllVinListMutation();

  const onSearchVinList = useCallback(
    (payload) => {
      apiSearchVinList(payload);
    },
    [apiSearchVinList]
  );

  const convertVinToDisplay = (result_res) => {
    return result_res.map((d, idx) => ({
      parking_lane_select: [],
      editStatus: false,
      vin_no: d?.vin_no ? d?.vin_no : '',
      id: d?.id ? d?.id : idx + 1,
      assigned_date: d?.assigned_date ? d?.assigned_date : '',
      model_code: d?.model_code ? d?.model_code : '',
      series: d?.series ? d?.series : '',
      engine: d?.engine ? d?.engine : '',
      color: d?.color ? d?.color : '',
      lot_no_cbu: d?.lot_no_cbu ? d?.lot_no_cbu : '',
      cluster: d?.location_to ? d?.location_to?.cluster?.name : '',

      from: d?.from ? d?.from : '',
      location_from: d?.location_from?.short_name
        ? d?.location_from?.short_name
        : '',
      location_to: d?.location_to?.short_name ? d?.location_to?.short_name : '',

      location_to_id: d?.location_to?.id ? d?.location_to?.id : '',

      location_destination: d?.location_destination?.short_name
        ? d?.location_destination?.short_name
        : '',
      parking_lane_old: d?.parking || { id: '', lane_no: '' },
      parking_lane: d?.parking || { id: '', lane_no: '' },
      destination: d?.destination ? d?.destination : '',
      vehicle_type: d?.vehicle_type?.name ? d?.vehicle_type?.name : '',
      vehicle_type_id: d?.vehicle_type?.id ? d?.vehicle_type?.id : '',
      customer: d?.customer?.code ? d?.customer?.code : '',
      customer_id: d?.customer?.id ? d?.customer?.id : '',
      customer_code: d?.customer?.code ? d?.customer?.code : '',

      print_dn: d?.print_dn ? d?.print_dn : '',
      upload_date: d?.upload_date ? d?.upload_date : '',
      vehicle_status: d?.vehicle_status?.name_en
        ? d?.vehicle_status?.name_en
        : '',
      location_destination_kakudai: d?.location_destination?.name || '',
      cluster_kakudai: d?.location_destination?.cluster?.name || '',
    }));
  };
  useEffect(() => {
    if (dataSearchVin) {
      let result_res = dataSearchVin.results;
      setTotalRows(dataSearchVin.count);
      setFileList([]);

      let arr_convert = convertVinToDisplay(result_res);

      if (fromUrl || toUrl || clusterUrl || vehicleStatusUrl) {
        setDataSourceUrl(arr_convert);
      } else {
        setSelectedRows(selectedRowsRef.current);

        if (arr_convert.length !== 0) {
          setDataSource(arr_convert);
        } else {
          warning({
            title: 'หา vin ไม่เจอตามเงื่อนไข Filter ปัจจุบัน',
            icon: <ExclamationCircleOutlined />,
            // content: `Calling No. : ${filterCallingno}`,
            onOk() {},
            onCancel() {},
          });

          setDataSource(selectedRowsRef.current);
        }
      }
    }
  }, [dataSearchVin]);

  //for menu
  // upload file excel
  const onUpload = (files) => {
    csv.read(files, onFileLoad, {});
  };

  const onFileLoad = async (resp = {}) => {
    let array_file_upload = [];

    try {
      const { data } = resp;
      for (let row of data) {
        array_file_upload.push(row);
      }

      let arr_convert = [];
      arr_convert = array_file_upload.map((d) => ({
        vin_no: d?.vin_no ? d?.vin_no.trim() : '',
        engine: d?.engine_no ? d?.engine_no.trim() : '',
        color: d?.colour ? d?.colour.trim() : '',
        model_code: d?.model ? d?.model.trim() : '',
        suffix: d?.suffix ? d?.suffix.trim() : '',
        series: d?.series ? d?.series.trim() : '',
        location_from: d?.from_code ? d?.from_code.trim() : '',
        cluster: d?.cluster_name ? d?.cluster_name.trim() : '',
        location_to: d?.to_code ? d?.to_code.trim() : '',
        location_destination: d?.destination_code ? d?.destination_code.trim() : '',
        vehicle_type: d?.vehicle_type_code ? d?.vehicle_type_code.trim() : '',
        customer: d?.customer_code ? d?.customer_code.trim() : '',
        lot_no_cbu: d?.lot_no ? d?.lot_no.trim() : '',
        parking_lane: d?.parking_lane ? d?.parking_lane.trim() : '',

        assigned_date: onConvertDate(d?.['Assign Date']),
        vehicle_status: d?.vehicle_status
          ? d?.vehicle_status.trim()
          : 'not have calling',
      }));
      setDataUploadFile(arr_convert);
    } catch (err) {
    } finally {
    }
  };

  const onConvertDate = (date) => {
    const [dateFormat] = date.split(' ');
    const time_format = date.split(' ');
    const time_cut = time_format[1];
    const [day, month, year] = dateFormat.split('/');
    const result = [month, day, year].join('/');

    return result + ' ' + time_cut;
  };

  // api
  // Upload Vin list
  const [
    apiEditVinList,
    { data: dataEditVins, error: errorEditVins, isLoading: isLoadingEditVins },
  ] = useEditVinListMutation();

  useEffect(() => {
    if (dataEditVins) {
      let arrSelectRows = arrDataRef.current.map((d) => ({
        //edit status false ==> edit success
        ...d,
        editStatus: false,
      }));

      setSelectedRows(arrSelectRows);

      message.success(`Edit VIN successfully !!`);

      setVisible(false);

      setDataKakudai(arrSelectRows);
    }
  }, [dataEditVins]);

  useEffect(() => {
    if (errorEditVins) {
      let arrSelectRows = arrDataRef.current.map((d) => ({
        //edit status true ==> edit error warning red text
        ...d,
        editStatus: true,
      }));

      setSelectedRows(arrSelectRows);

      setErrorData(errorEditVins);
    }
  }, [errorEditVins, setErrorData]);

  // Upload Vin list
  const [
    uploadVinlistAPI,
    {
      data: dataUploadVin,
      error: errorUploadVin,
      isLoading: isLoadingUploadVin,
    },
  ] = useUploadVinListUploadMutation();

  useEffect(() => {
    if (dataUploadVin) {
      if (dataUploadVin.status === 'fail') {
        var object_nf = dataUploadVin?.result?.error_nf;
        var arr_nf = [];
        for (const [key, value] of Object.entries(object_nf)) {
          var oject_key = {
            key: key,
            value: value,
          };

          arr_nf.push(oject_key);
        }

        Modal.error({
          title: 'Upload ข้อมูล Vin ไม่สำเร็จ',
          width: 500,
          content: (
            <div style={{ padding: '0px' }}>
              {arr_nf.map((option) => (
                <span key={option.key}>{option.key} : </span>
              ))}
              {arr_nf.map((option) => (
                <span key={option.value}>
                  {option.value + ','}
                  <br></br>
                </span>
              ))}
            </div>
          ),
        });
        setFileList([]);
      } else {
        Modal.success({
          title: 'Upload ข้อมูล Vin เรียบร้อย',
          content: (
            <div style={{ padding: '0px' }}>
              <p style={{ padding: '0px' }}>
                Create new vin no : {dataUploadVin.created}
              </p>
              <p style={{ padding: '0px' }}>
                Updated vin no : {dataUploadVin.updated}
              </p>
            </div>
          ),
        });

        setTotalRows(dataUploadVin.vins.length);
        let arr_convert = convertVinToDisplay(dataUploadVin.vins);
        setDataSource(arr_convert);
      }
      setDataUploadFile([]);
    }
  }, [dataUploadVin]);

  useEffect(() => {
    if (errorUploadVin) {
      setFileList([]);
      setErrorData(errorUploadVin);
    }
  }, [errorUploadVin, setErrorData]);

  // from
  const { data: fromOptionLocationData, error: errorFromOptionLocation } =
    useFindLocationFromQuery();

  useEffect(() => {
    if (fromOptionLocationData) {
      let findFromLocation = fromOptionLocationData.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));
      findFromLocation = RemoveDuplicates.LISTKEY(findFromLocation, 'label');
      findFromLocation = Sorter.DEFAULTLIST(findFromLocation, 'label');

      setFilterOptionFrom(findFromLocation);

      if (fromUrl) {
        if (fromUrl !== 'All') {
          let filterFromUrl =
            fromOptionLocationData &&
            fromOptionLocationData.find((f) => f.code === fromUrl);
          setFilterFrom(filterFromUrl?.id);
        }
      }

      if (toUrl) {
        if (toUrl !== 'All') {
          let filterToUrl =
            fromOptionLocationData &&
            fromOptionLocationData.find((f) => f.code === toUrl);
          setFilterTo(filterToUrl?.id);
        }
      }
    }
  }, [fromOptionLocationData, fromUrl, toUrl]);

  useEffect(() => {
    if (errorFromOptionLocation) {
      setErrorData(errorFromOptionLocation);
    }
  }, [errorFromOptionLocation, setErrorData]);

  //AllMasterDataVin
  const {
    data: masterData,
    error: errorMasterData,
    isLoading: isLoadingMasterData,
  } = useFindAllMasterDataVinQuery();
  useEffect(() => {
    if (masterData) {
      // Cluster
      let findClustersData = masterData.cluster.map((d) => ({
        label: d.name,
        value: d.id,
      }));
      findClustersData = RemoveDuplicates.LISTKEY(findClustersData, 'label');
      findClustersData = Sorter.DEFAULTLIST(findClustersData, 'label');

      setFilterOptionCluster(findClustersData);
      // vehicleStatusData
      let findVehicleStatus = masterData.vehicle_status.map((d) => ({
        label: d.name_en,
        value: d.id,
      }));
      findVehicleStatus = RemoveDuplicates.LISTKEY(findVehicleStatus, 'label');
      if (mode === 'create_new_calling' || param_mode === 'ADD_VIN') {
        let notHaveCallingStatus = findVehicleStatus.filter(
          (d) => d.label === 'Not have Calling'
        );
        setFilterOptionVehicleStatus(findVehicleStatus);
        if (notHaveCallingStatus.length > 0) {
          setFilterVehicle(notHaveCallingStatus[0].value);
        }
      } else {
        setFilterOptionVehicleStatus(findVehicleStatus);
      }
      // vehicle_type
      let findVehicleTypes = masterData.vehicle_type.map((d) => ({
        label: d.name,
        value: d.id,
      }));

      findVehicleTypes = RemoveDuplicates.LISTKEY(findVehicleTypes, 'label');
      setFilterOptionVehicleTypes(findVehicleTypes);
      // Location To
      let findLocationTo = masterData.location_to.map((d) => ({
        id: d.id,
        code: d.code,
        label: d.short_name,
        value: d.id,
      }));
      findLocationTo = RemoveDuplicates.LISTKEY(findLocationTo, 'label');
      findLocationTo = Sorter.DEFAULTLIST(findLocationTo, 'label');
      setFilterOptionTo(findLocationTo);

      // location_destination
      let findDestination = masterData.location_to.map((d) => ({
        id: d.id,
        code: d.code,
        label: d.short_name,
        value: d.id,
      }));
      findDestination = RemoveDuplicates.LISTKEY(findDestination, 'label');
      findDestination = Sorter.DEFAULTLIST(findDestination, 'label');
      setFilterOptionDestination(findDestination);

      if (fromUrl) {
        if (fromUrl !== 'All') {
          let filterFromUrl =
            fromOptionLocationData &&
            fromOptionLocationData.find((f) => f.code === fromUrl);
          setFilterFrom(filterFromUrl?.id);
        }
      }

      if (toUrl) {
        if (toUrl !== 'All') {
          let filterToUrl =
            fromOptionLocationData &&
            fromOptionLocationData.find((f) => f.code === toUrl);
          setFilterTo(filterToUrl?.id);
        }
      }

      if (clusterUrl && clusterUrl !== 'All') {
        let arr = clusterUrl.split(',');
        let newArr = [];
        for (let index = 0; index < arr.length; index++) {
          let filterCluster =
            findClustersData &&
            findClustersData.find((f) => f.label === arr[index]);
          newArr.push(filterCluster.value);
        }
        setClusterFilter(newArr);
      }

      if (vehicleStatusUrl && vehicleStatusUrl !== 'All') {
        let status = [vehicleStatusUrl];
        if (vehicleStatusUrl === 'In Yard Calling') {
          status = ['Have Calling', 'Match Plan', 'Match TR/DV', 'Loading'];
        }
        let filterVehicleStatus =
          findVehicleStatus &&
          findVehicleStatus
            .filter((f) => status.includes(f.label))
            .map((d) => d.value);
        setVehicleStatusFilter(filterVehicleStatus);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterData]);

  useEffect(() => {
    if (errorMasterData) {
      setErrorData(errorMasterData);
    }
  }, [errorMasterData, setErrorData]);

  // master for Edit
  const [getDataMasterEdit, { data: dataMasterEdit }] =
    useFindAllEditVinDataMutation();

  useEffect(() => {
    if (dataMasterEdit) {
      // To
      let findTo = dataMasterEdit.location_to.map((d) => ({
        label: d.short_name,
        value: d.id,
        cluster: d.cluster?.name,
      }));
      findTo = RemoveDuplicates.LISTKEY(findTo, 'label');
      findTo = Sorter.DEFAULTLIST(findTo, 'label');
      setMasterOptionTo(findTo);

      // Customer
      let findCustomer = dataMasterEdit.customer.map((d) => ({
        label: d.customer_name,
        value: d.id,
        customerCode: d.code,
      }));
      findCustomer = RemoveDuplicates.LISTKEY(findCustomer, 'label');
      findCustomer = Sorter.DEFAULTLIST(findCustomer, 'label');
      setMasterOptionCustomer(findCustomer);

      // VehicleType
      let findVehicleType = dataMasterEdit.vehicle_type.map((d) => ({
        label: d.name,
        value: d.id,
      }));

      findVehicleType = RemoveDuplicates.LISTKEY(findVehicleType, 'label');
      findVehicleType = Sorter.DEFAULTLIST(findVehicleType, 'label');
      setMasterOptionVehicleTypes(findVehicleType);
    }
  }, [dataMasterEdit]);

  useEffect(() => {
    if (param_mode === 'EDIT_VIN' && storedDataModeEditVin.dataSource) {
      setDataSource(storedDataModeEditVin.dataSource);
    }
  }, [param_mode, storedDataModeEditVin?.dataSource]);

  useEffect(() => {
    if (
      mode === 'create_new_calling' ||
      param_mode === 'EDIT_VIN' ||
      param_mode === 'ADD_VIN'
    ) {
      setFilterFrom(param_filterfrom);
      setTotalRows(0)
      setOffset(0)
      setPage(1)
    }
  }, [param_filterfrom, param_mode]);

  useEffect(() => {
    if (from === 'menu') {
      setTotalRows(0);
      setparam_calling_no('NO');
      setparam_filterfrom('NO');
      setparam_from('MENU');
      setparam_mode('VIEW');
    }
  }, [mode, calling_no, from, filterfrom]);

  useEffect(() => {
    if (param_mode === 'EDIT_VIN' || param_mode === 'VIEW') {
      getDataMasterEdit();
    }
  }, [getDataMasterEdit, param_mode]);

  useEffect(() => {
    if (totalRows) {
      dispatch(setVinTotalRows(totalRows));
    }
  }, [totalRows, dispatch]);

  // dispatch
  useEffect(() => {
    dispatch(
      setManageCallingNoStatusMode({
        param_mode,
        param_calling_no,
        param_from,
        param_filterfrom,
      })
    );
  }, [param_mode, param_calling_no, param_from, param_filterfrom, dispatch]);

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  useEffect(() => {
    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(
      setvinListDataSelection({
        selectedRows,
      })
    );
  }, [selectedRows, dispatch]);

  //for data source
  useEffect(() => {
    if (storedDataSourceForModeEditVin && param_mode === 'EDIT_VIN') {
      setDataSource(storedDataSourceForModeEditVin);
    }
  }, [param_mode, storedDataSourceForModeEditVin]);

  useEffect(() => {
    if (param_mode === 'VIEW') {
      setDataSource([]);
    }
  }, [param_mode]);

  // setManageCallingDataSourceForModeCreateNewCalling
  useEffect(() => {
    dispatch(setManageCallingDataSourceForModeCreateNewCalling(selectedRows));
  }, [selectedRows, dispatch]);

  //setManageCallingDataSourceForModeAddVin
  useEffect(() => {
    if (dataSourceForModeAddVin.length !== 0) {
      dispatch(
        setManageCallingDataSourceForModeAddVin(dataSourceForModeAddVin)
      );
    }
  }, [dataSourceForModeAddVin, dispatch]);

  // setManageCallingDataSourceForModeEditVin
  useEffect(() => {
    dispatch(setManageCallingDataSourceForModeEditVin(dataSource));
  }, [dataSource, dispatch]);
  useEffect(() => {
    if (
      param_mode === 'VIEW' &&
      param_calling_no === 'NO' &&
      param_filterfrom === 'NO' &&
      param_from === 'MENU'
    ) {
      let listempty = [];
      dispatch(setManageCallingDataSourceForModeCreateNewCalling(listempty));
      dispatch(setManageCallingDataSourceForModeEditVin(listempty));
      dispatch(setManageCallingDataSourceForModeAddVin(listempty));
    }
  }, [param_mode, param_calling_no, param_filterfrom, param_from, dispatch]);

  useEffect(() => {
    if (param_mode === 'EDIT_VIN') {
      dispatch(
        setvinListDataSelection({
          selectedRows: dataSource,
        })
      );
    }
  }, [dataSource, dispatch, param_mode]);

  useEffect(() => {
    if (
      clusterFilter ||
      vehicelStatusFilter ||
      (clusterFilter && vehicelStatusFilter)
    ) {
      setDisableFilterUrl(true);
      if (clusterFilter) {
        setFilterCluster(clusterFilter);
      }
      if (vehicelStatusFilter) {
        setFilterVehicle(vehicelStatusFilter);
      }
      let curOffset = calOffsetFromPage(page, pageSize);
      onSearchVinList({
        ...createPayload({
          mode: 'status_summary',
          filterCluster: clusterFilter,
          filterVehicle: vehicelStatusFilter,

          filterFrom: filterFrom,
          filterTo,
          filterVehicleType,

          filterVinno,
          filterDestination,
          filterLotno,

          filterCallingno,
          filterUploadDate,
          limit: pageSize,
          offset: curOffset,
        }),
      });
    } else {
      setDisableFilterUrl(false);
    }
  }, [
    clusterFilter,
    filterCallingno,
    filterDestination,
    filterFrom,
    filterLotno,
    filterTo,
    filterUploadDate,
    filterVehicleType,
    filterVinno,
    onSearchVinList,
    vehicelStatusFilter,
  ]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => {
          dispatch(
            setManageCallingNoStatusMode({
              param_mode: 'VIEW',
              param_calling_no: 'NO',
              param_from: 'MENU',
              param_filterfrom: 'NO',
            })
          );
          window.history.back();
        }}
        title='Vins List'
        extra={
          totalRows != null && <Title level={5}>{`Total ${totalRows}`}</Title>
        }
      />
      <Content>
        <Toolbar
          onUpload={onUpload}
          dataSource={dataSource}
          setDataSource={setDataSource}
          onModalComfrimUpload={onModalComfrimUpload}
          isLoadingUploadVin={isLoadingUploadVin}
          dataUploadFile={dataUploadFile}
          setDataUploadFile={setDataUploadFile}
          onSearch={onSearch}
          isLoadingSearch={isLoadingSearch}
          isLoadingMasterData={isLoadingMasterData}
          toTalSelect={toTalSelect}
          setToTalSelect={setToTalSelect}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          //param
          param_mode={param_mode}
          setparam_mode={setparam_mode}
          param_calling_no={param_calling_no}
          setparam_calling_no={setparam_calling_no}
          param_from={param_from}
          setparam_from={setparam_from}
          param_filterfrom={param_filterfrom}
          setparam_filterfrom={setparam_filterfrom}
          //parameter
          disableFilterUrl={disableFilterUrl}
          vehicelStatusFilter={vehicelStatusFilter}
          clusterFilter={clusterFilter}
          filterFrom={filterFrom}
          setFilterFrom={setFilterFrom}
          flistFileUpload={flistFileUpload}
          setListFileUpload={setListFileUpload}
          filterCluster={filterCluster}
          setFilterCluster={setFilterCluster}
          filterVehicle={filterVehicle}
          setFilterVehicle={setFilterVehicle}
          filterTo={filterTo}
          setFilterTo={setFilterTo}
          filterVehicleType={filterVehicleType}
          setFilterVehicleType={setFilterVehicleType}
          filterVinno={filterVinno}
          setFilterVinno={setFilterVinno}
          filterDestination={filterDestination}
          setFilterDestination={setFilterDestination}
          filterLotno={filterLotno}
          setFilterLotno={setFilterLotno}
          filterCallingno={filterCallingno}
          setFilterCallingno={setFilterCallingno}
          filterUploadDate={filterUploadDate}
          setfilterUploadDate={setfilterUploadDate}
          // Option
          filterOptionFrom={filterOptionFrom}
          filterOptionVehicleTypes={filterOptionVehicleTypes}
          filterOptionStatus={filterOptionStatus}
          filterOptionCluster={filterOptionCluster}
          filterOptionTo={filterOptionTo}
          setFilterOptionDestination={setFilterOptionDestination}
          filterOptionDestination={filterOptionDestination}
          onAddToCalling={onAddToCalling}
          loadingonAddToCalling={loadingonAddToCalling}
          setLoadingonAddToCalling={setLoadingonAddToCalling}
          onClearAll={onClearAll}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          fileList={fileList}
          setFileList={setFileList}
        />

        <Table
          dataSource={dataSourceUrl ? dataSourceUrl : dataSource}
          payloadRef={payloadRef.current}
          setDataSource={setDataSource}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          totalRows={totalRows}
          setTotalRows={setTotalRows}
          pageSize={pageSize}
          setPageSize={setPageSize}
          page={page}
          setPage={setPage}
          offset={offset}
          setOffset={setOffset}
          onSearch={onSearch}
          //param
          param_mode={param_mode}
          setparam_mode={setparam_mode}
          param_calling_no={param_calling_no}
          setparam_calling_no={setparam_calling_no}
          param_from={param_from}
          setparam_from={setparam_from}
          param_filterfrom={param_filterfrom}
          setparam_filterfrom={setparam_filterfrom}
          //parameter
          filterFrom={filterFrom}
          setFilterFrom={setFilterFrom}
          flistFileUpload={flistFileUpload}
          setListFileUpload={setListFileUpload}
          filterCluster={filterCluster}
          setFilterCluster={setFilterCluster}
          filterVehicle={filterVehicle}
          setFilterVehicle={setFilterVehicle}
          filterTo={filterTo}
          setFilterTo={setFilterTo}
          filterVehicleType={filterVehicleType}
          setFilterVehicleType={setFilterVehicleType}
          filterVinno={filterVinno}
          setFilterVinno={setFilterVinno}
          filterDestination={filterDestination}
          setFilterDestination={setFilterDestination}
          filterLotno={filterLotno}
          setFilterLotno={setFilterLotno}
          filterCallingno={filterCallingno}
          setFilterCallingno={setFilterCallingno}
          filterUploadDate={filterUploadDate}
          setfilterUploadDate={setfilterUploadDate}
          isLoadingSearch={isLoadingSearch}
          //function
          getDataKakudai={getDataKakudai}
          dataKakudai={dataKakudai}
          setDataKakudai={setDataKakudai}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          toTalSelect={toTalSelect}
          setToTalSelect={setToTalSelect}
          onClearAll={onClearAll}
          showModalEditVinlist={showModalEditVinlist}
          dataSourceForModeAddVin={dataSourceForModeAddVin}
          setDataSourceAllAddVin={setDataSourceAllAddVin}
        />

        <Modal
          title='Edit Data Vin'
          visible={visible}
          onOk={handleOkSave}
          onCancel={handleCancelSave}
          footer={[
            <Button
              key='save'
              type='primary'
              loading={isLoadingEditVins}
              disabled={
                valueToModel === '' ||
                valueCustomerModel === '' ||
                valueVehicleTypesModel === ''
              }
              onClick={handleOkSave}
            >
              Save
            </Button>,
            <Button key='back' onClick={handleCancelSave}>
              Cancel
            </Button>,
          ]}
          modalRender={(modal) => (
            <StyledModal>
              <Draggable
                bounds={{ top: -100, left: -500, right: 500, bottom: 500 }}
                onStart={(event, uiData) => onStart(event, uiData)}
              >
                <div ref={draggleRef}>{modal}</div>
              </Draggable>
            </StyledModal>
          )}
        >
          <Row
            gutter={20}
            justify='center'
            align='center'
            style={{ marginLeft: '30px', marginRight: '30px' }}
          >
            <Col span={10} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <p>To</p>
              <p>Customer </p>
              <p>Vehicle Type</p>
            </Col>
            <Col span={14} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginBottom: '10px',
                }}
                allowClear={true}
                maxTagCount='responsive'
                rules={[
                  {
                    required: true,
                  },
                ]}
                placeholder={'To'}
                value={valueToModel}
                onChange={(values) => {
                  setValueToModel(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {masterOptionTo &&
                  masterOptionTo.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>

              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginBottom: '10px',
                }}
                allowClear={true}
                maxTagCount='responsive'
                rules={[
                  {
                    required: true,
                  },
                ]}
                placeholder={'Customer'}
                value={valueCustomerModel}
                onChange={(values) => {
                  setValueCustomerModel(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {masterOptionCustomer &&
                  masterOptionCustomer.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>

              <Select
                mode='single'
                size='small'
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginBottom: '10px',
                }}
                allowClear={true}
                maxTagCount='responsive'
                rules={[
                  {
                    required: true,
                  },
                ]}
                placeholder={'Vehicle Type'}
                value={valueVehicleTypesModel}
                onChange={(values) => {
                  setValueVehicleTypesModel(values);
                }}
                showSearch
                optionFilterProp='children'
              >
                {masterOptionVehicleTypes &&
                  masterOptionVehicleTypes.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </Modal>

        <PrintKakudai
          componentRef={printKakudaiComponentRef}
          KakudaiData={printData}
        ></PrintKakudai>
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;
const StyledModal = styled.div`
  height: calc(100vh - 80px);
  padding: 20px;
`;
export default VinListComponent;
