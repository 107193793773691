import React, { useState, useRef, useCallback } from 'react';
import { Polygon } from '@react-google-maps/api';
import { useDispatch } from 'react-redux';
import { mapChangePolygon } from 'reducers/map';

const Impolygon = (props) => {
  const dispatch = useDispatch();
  const polygonRef = useRef(null);
  const listenersRef = useRef([]);

  const [path, setPath] = useState(props.path ? props.path : []);

  const onEdit = useCallback(() => {
    if (polygonRef.current) {
      const nextPath = polygonRef.current
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
      setPath(nextPath);

      let obj = { ...props, path: nextPath };
      dispatch(mapChangePolygon(obj));
    }
  }, [props, dispatch]);

  const onLoad = useCallback(
    (polygon) => {
      polygonRef.current = polygon;
      const path = polygon.getPath();
      listenersRef.current.push(
        path.addListener('set_at', onEdit),
        path.addListener('insert_at', onEdit),
        path.addListener('remove_at', onEdit)
      );
    },
    [onEdit]
  );

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove());
    polygonRef.current = null;
  }, []);

  return (
    <Polygon
      key={props.id}
      id={props.id}
      path={path}
      draggable={true}
      editable={props.editable}
      onMouseUp={onEdit}
      onDragEnd={onEdit}
      onLoad={onLoad}
      onUnmount={onUnmount}
    ></Polygon>
  );
};

export default Impolygon;
