import React, { useEffect, useState } from 'react';
import {
  useFindLanesByIdQuery,
  useFindYardByLocationmasterQuery,
  useFindCategoryForTypeLanesQuery,
} from 'reducers/masterData/lanes/api';
import { goBack } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import Form from './Form';
import { Sorter } from 'utils/sorter';
import { RemoveDuplicates } from 'utils/removeDuplicates';

const ParkingLaneDetailComponent = (props) => {
  const [readonly, setReadonly] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { setErrorData } = useErrorHandler();

  const [yardOptionLocation, setYardOptionLocation] = useState();
  const [yardLocation, setYardLocation] = useState();
  const [optionCategory, setOptionCategory] = useState();
  const [valuecategory, setValuecategory] = useState();

  const [checkedSwitch, setCheckedSwitch] = useState(true);

  //---data-------------------------------------------------------------
  const { data, error, isFetching } = useFindLanesByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (data) {

    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  //---data dataYard-------------------------------------------------------------
  const {
    data: dataYard,
    error: errorYard,
    isLoading: isLoadingYard,
  } = useFindYardByLocationmasterQuery();
  useEffect(() => {
    if (dataYard) {
      let findYardLocation = dataYard.map((d) => ({
        label: d.name,
        value: d.id,
      }));
      findYardLocation = RemoveDuplicates.LISTKEY(findYardLocation, 'label');
      findYardLocation = Sorter.DEFAULTLIST(findYardLocation, 'label');

      setYardOptionLocation(findYardLocation);
    }
  }, [dataYard]);

  //---data Category-------------------------------------------------------------
  const {
    data: dataCategory,
    error: errorCategory,
    isLoading: isLoadingCategory,
  } = useFindCategoryForTypeLanesQuery();
  useEffect(() => {
    if (dataCategory) {
   
      let findCategoryLocation = dataCategory.map((d) => ({
        label: d.name_en,
        value: d.id,
      }));
      findCategoryLocation = RemoveDuplicates.LISTKEY(
        findCategoryLocation,
        'label'
      );
      findCategoryLocation = Sorter.DEFAULTLIST(findCategoryLocation, 'label');


      setOptionCategory(findCategoryLocation);
    }
  }, [dataCategory]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Lanes Detail'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        {isFetching === true ? (
          <RootCircular>
            <CenterSpin indicator={antIcon} size='large' />
          </RootCircular>
        ) : (
          <Form
            yardOptionLocation={yardOptionLocation}
            setYardOptionLocation={setYardOptionLocation}
            yardLocation={yardLocation}
            setYardLocation={setYardLocation}
            formData={data}
            isCreate={!id}
            optionCategory={optionCategory}
            setOptionCategory={setOptionCategory}
            valuecategory={valuecategory}
            setValuecategory={setValuecategory}
            checkedSwitch={checkedSwitch}
            setCheckedSwitch={setCheckedSwitch}
          />
        )}
      </Content>
    </Container>
  );
};
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;
const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default ParkingLaneDetailComponent;
