import React, { useState, useEffect, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
// import { Spin } from "antd";
import { useFindSummaryAlLocationOverallQuery } from 'reducers/summaryAllocation/api';
import { OverallHeaderHandler } from './else';

const TableOverAllComponent = (props) => {
  // const {} = props;
  const [dataSourceOverAll, setDataSourceOverAll] = useState([]);
  const { setErrorData } = useErrorHandler();
  const timer = useRef(null);

  function styleBackgroundTotal(text) {
    if (text === 'Match BzP') {
      return { background: '#82EBB1', padding: 0 };
    } else if (text === 'Not match BzP') {
      return { background: '#FFCAD4', padding: 0 };
    } else if (text === 'total') {
      return { background: '#C4C4C4', padding: 0 };
    } else {
      return { background: '', padding: 0 };
    }
  }

  const headerTableRef = useRef(null);

  const [columns, setColumns] = useState([]);

  // -------------API--------------------------------------------------
  const {
    data: dataOverall,
    error: errorOverall,
    isFetching,
    refetch,
  } = useFindSummaryAlLocationOverallQuery();

  useEffect(() => {
    if (isFetching) {
      return;
    }
    clearInterval(timer);
    timer.current = setTimeout(async () => {
      await refetch();
    }, 7000);
    return () => clearInterval(timer);
  }, [isFetching, refetch]);

  useEffect(() => {
    if (!dataOverall) return;
    const { header } = dataOverall;
    setColumns(() => [
      {
        title: () => {
          return <b>Total</b>;
        },
        dataIndex: 'title',
        width: 100,
        align: 'center',
        className: 'color-head-6',
        render: (text, record, index) => {
          return {
            props: {
              style: styleBackgroundTotal(text),
            },
            children: <h4>{text ? text : '-'}</h4>,
          };
        },
      },
      ...(header || []).map((one, idx) => OverallHeaderHandler(one, idx)),
    ]);
    let arr_res = dataOverall?.header;
    let arr_list = [
      {
        title: 'Total',
        title_value: '',
        dataIndex: 'total',
        key: 1,
      },
      ...(arr_res || []).map((col, idx) => ({
        title: col.title,
        title_value: col.title_value,
        dataIndex: 'date1Value',
        key: idx + 1,
      })),
    ];
    headerTableRef.current = arr_list;
    setDataSourceOverAll(dataOverall?.data ? dataOverall.data : []);
  }, [dataOverall]);

  useEffect(() => {
    if (errorOverall) {
      setErrorData(errorOverall);
    }
  }, [errorOverall, setErrorData]);
  //-------------------------------------------------------------------------
  return (
    <Container style={{ paddingTop: '10px' }}>
      <h3>Overall</h3>

      {/* {isLoadingOverall === true ? (
        <RootCircular>
          <CenterSpin indicator={antIcon} size="large" />
        </RootCircular>
      ) : ( */}
      <StyledAntTable
        rowKey='id'
        tableId={'id'}
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSourceOverAll}
        pagination={false}
        scroll={{ x: 'max-content' }}
      />
      {/* )} */}
    </Container>
  );
};

// const CenterSpin = styled(Spin)`
//   display: table-cell;
//   vertical-align: middle;
// `;
// const RootCircular = styled.div`
//   display: table;
//   height: calc(100%);
//   margin: auto;
// `;

const Container = styled.div`
  padding: 0 10px;
`;
const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default TableOverAllComponent;
