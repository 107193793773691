import React, { useEffect, useState, useCallback } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  message,
  Select,
  Switch,
  Checkbox,
  Row,
  Col,
} from 'antd';
import {
  useUpdateSubjectReasonMutation,
  useCreateSubjectReasonMutation,
  useFindAllGroupReasonsQuery,
  useFindAllModuleReasonsQuery,
  useUpdateReasonMasterMutation,
  useCreateReasonMasterMutation,
  useDeleteReasonMasterMutation,
  useFindAllSubjectReasonsMaxCodeQuery,
} from 'reducers/masterData/reasonMaster/api';
import { getCurrentUser } from 'reducers/user';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
const { Option } = Select;

const SubjectReasonFormComponent = (props) => {
  const dispatch = useDispatch();
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const [postGroup, setPostGroup] = useState(
    formData[0] &&
      formData[0].subject_reason &&
      formData[0].subject_reason.group.code
  );

  const user = useSelector(getCurrentUser);
  const { setErrorData } = useErrorHandler();
  const [groupReasons, setGroupReasons] = useState(
    formData[0] && formData[0].group
      ? [
          {
            id: formData[0].group.id,
            label: `(${formData[0].group.code}) ${formData[0].group.name}`,
            code: formData[0].group.code,
          },
        ]
      : []
  );
  const [moduleReasons, setModuleReasons] = useState(
    formData[0] && formData[0].module
      ? [
          {
            value: formData[0].module.id,
            label: formData[0].module.name,
          },
        ]
      : []
  );
  const [selectedModule, setSelectedModule] = useState([]);
  const [subjectReasonsMaxCode, setSubjectReasonsMaxCode] = useState(
    formData && formData.code ? formData && formData.code : null
  );
  // Query all Subject Reasons MaxCode
  const {
    data: dataSubjectReasonsMaxCode,
    error: errorSubjectReasonsMaxCode,
    refetch: refetchSubjectReasonsMaxCode,
  } = useFindAllSubjectReasonsMaxCodeQuery(postGroup, {
    skip: !postGroup,
  });

  useEffect(() => {
    setSubjectReasonsMaxCode(
      dataSubjectReasonsMaxCode && dataSubjectReasonsMaxCode.next_code
        ? dataSubjectReasonsMaxCode.next_code
        : null
    );
  }, [dataSubjectReasonsMaxCode]);

  useEffect(() => {
    if (errorSubjectReasonsMaxCode) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load reasons',
        okText: 'Retry',
        onOk: refetchSubjectReasonsMaxCode,
        cancelText: 'Cancel',
      });
    }
  }, [errorSubjectReasonsMaxCode]);

  useEffect(() => {
    if (errorCreatingReason) {
      setErrorData(errorCreatingReason);
    }
    if (errorUpdatingReason) {
      setErrorData(errorUpdatingReason);
    }
  }, [errorUpdating, errorCreatingReason]);

  const [
    createReasonMaster,
    {
      isSuccess: isSuccessCreatingReason,
      isUninitialized: isUninitCreatingReason,
      error: errorCreatingReason,
      isLoading: isCreatingReason,
    },
  ] = useCreateReasonMasterMutation();

  const [
    {
      isSuccess: isSuccessUpdatingReason,
      isUninitialized: isUninitUpdatingReason,
      error: errorUpdatingReason,
      isLoading: isUpdatingReason,
    },
  ] = useUpdateReasonMasterMutation();

  useEffect(() => {}, [
    isSuccessUpdatingReason,
    isUninitUpdatingReason,
    isSuccessCreatingReason,
    isUninitCreatingReason,
  ]);

  const [
    deleteReasonMaster,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
    },
  ] = useDeleteReasonMasterMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting]);

  useEffect(() => {}, [isSuccessDeleting, isUninitDeleting]);

  // Query all Group Reasons
  const {
    data: dataGroupReasons,
    error: errorGroupReasons,
    refetch: refetchGroupReasons,
  } = useFindAllGroupReasonsQuery(null, {
    skip: !user || readonly,
  });

  useEffect(() => {
    if (dataGroupReasons) {
      setGroupReasons(
        dataGroupReasons
          .map((d) => ({
            id: d.id,
            label: `(${d.code}) ${d.name}`,
            code: d.code,
          }))
          .sort((a, b) => a.code - b.code)
      );
    }
  }, [dataGroupReasons]);

  useEffect(() => {
    if (errorGroupReasons) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load reasons',
        okText: 'Retry',
        onOk: refetchGroupReasons,
        cancelText: 'Cancel',
      });
    }
  }, [errorGroupReasons]);

  // Query all Module
  const {
    data: dataModuleReasons,
    error: errorModuleReasons,
    refetch: refetchModuleReasons,
  } = useFindAllModuleReasonsQuery(null, {
    skip: !user || readonly,
  });

  useEffect(() => {
    if (dataModuleReasons) {
      setModuleReasons(
        dataModuleReasons.map((d) => ({
          value: d.id,
          label: d.name,
        }))
      );
    }
  }, [dataModuleReasons]);

  useEffect(() => {
    if (errorModuleReasons) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load reasons',
        okText: 'Retry',
        onOk: refetchModuleReasons,
        cancelText: 'Cancel',
      });
    }
  }, [errorModuleReasons, refetchModuleReasons]);

  const [
    createSubjectReason,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      data: isDataSubject,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateSubjectReasonMutation();

  const [
    updateSubjectReason,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateSubjectReasonMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  const updateAndCreate = () => {
    if (isCreate) {
      if (selectedModule.length > 0) {
        for (let index = 0; index < selectedModule.length; index++) {
          let postDataReason = {
            id: formData[0] && formData[0].id,
            module: selectedModule[index].value,
            subject_reason: isDataSubject.id,
            is_active: true,
          };

          createReasonMaster(postDataReason);
        }
      }
      if (selectedModule.length === 0) {
        for (let index = 0; index < moduleReasons.length; index++) {
          let postDataReason = {
            id: formData[0] && formData[0].id,
            module: moduleReasons[index].value,
            subject_reason: isDataSubject.id,
            is_active: false,
          };
          createReasonMaster(postDataReason);
        }
      }
    } else {
      for (var index = 0; index < selectedModule.length; index++) {
        var postDataReason = {
          id: selectedModule[index].reasonId,
          module: selectedModule[index].value,
          subject_reason: formData[0].subject_reason.id,
          is_active: !selectedModule[index].value ? false : true,
        };

        if (selectedModule[index].value) {
          createReasonMaster(postDataReason);
        } else {
          deleteReasonMaster(postDataReason);
        }
      }
    }
  };

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      updateAndCreate();
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const errorsMaxcode = () =>
    Modal.warning({
      title: `คุณได้ทำการเพิ่ม Subject Code ถึง ${subjectReasonsMaxCode} แล้ว ไม่สามารถเพิ่มใหม่ได้`,
      icon: <ExclamationCircleOutlined />,
    });
  const confirmSubmitted = () =>
    Modal.confirm({
      title: isCreate
        ? 'คุณต้องการยืนยันการสร้างเหตุผลใหม่ใช่หรือไม่'
        : 'คุณต้องการยืนยันการแก้ไขรายละเอียดเหตุผลใหม่ใช่หรือไม่',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          Group : {form.getFieldValue('group')}
          <br />
          Subject Code :{' '}
          {form.getFieldValue('code')
            ? form.getFieldValue('code')
            : subjectReasonsMaxCode}
          <br />
          Subject Name (Eng) : {form.getFieldValue('name_en')}
          <br />
          Subject Name (Thai) : {form.getFieldValue('name_th')}
          <br />
          Is Other : {form.getFieldValue('is_other') ? 'True' : 'False'}
          <br />
          <br />
          Operation (select)
          {displayReceiveJobModule(formData)}
        </>
      ),
      onOk: handleConfirmAction,
      cancelText: 'Cancel',
      okText: 'Confirm',
      width: 500,
    });

  const showModal = () => {
    switch (isCreate && subjectReasonsMaxCode) {
      case 199:
        errorsMaxcode();
        break;
      case 299:
        errorsMaxcode();
        break;
      case 399:
        errorsMaxcode();
        break;
      case 499:
        errorsMaxcode();
        break;
      case 599:
        errorsMaxcode();
        break;
      case 699:
        errorsMaxcode();
        break;
      case 799:
        errorsMaxcode();
        break;
      case 899:
        errorsMaxcode();
        break;
      case 999:
        errorsMaxcode();
        break;
      default:
        confirmSubmitted();
        break;
    }
  };

  const onFinish = () => {
    showModal();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let postGroup = groupReasons.find((c) => c.label === validData.group);
    let postData = {
      id: formData[0] && formData[0].subject_reason.id,
      ...validData,
      group: postGroup && postGroup.id,
      code:
        formData[0] && formData[0].subject_reason.code
          ? formData[0] && formData[0].subject_reason.code
          : subjectReasonsMaxCode,
    };

    if (isCreate) {
      createSubjectReason(postData);
    } else {
      updateSubjectReason(postData);
    }
  };

  const onGroupValues = async (id) => {
    let postGroup = groupReasons.find((c) => c.label === id);
    setPostGroup(postGroup.code);
    setSubjectReasonsMaxCode(
      dataSubjectReasonsMaxCode && dataSubjectReasonsMaxCode.next_code
        ? dataSubjectReasonsMaxCode.next_code
        : null
    );
  };

  const checkOperation = (data) => {
    let arr = [];
    for (let index = 0; index < data.length; index++) {
      if (
        data[index].module &&
        data[index].module.id &&
        data[index].is_active === true
      ) {
        arr.push(data[index].module.id);
      }
    }
    return arr;
  };

  const displayReceiveJobModule = (data) => {
    let arr = [];
    for (let index = 0; index < data.length; index++) {
      if (
        data[index].module &&
        data[index].module.id &&
        data[index].is_active === true
      ) {
        arr.push({
          label: data[index].module.name,
          value: '',
          id: true,
        });
      }
    }

    var conModuleSelect = selectedModule.concat(arr);
    let postReceiveJob = 'No';
    let postYardOut = 'No';
    let postArrival = 'No';

    const filteredArr = conModuleSelect.reduce((acc, current) => {
      const x = acc.find((item) => current.label === item.label);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);

    filteredArr.forEach((d) => {
      if (
        (d.label === 'Receive Job' && d.value) ||
        (d.label === 'Receive Job' && d.id)
      ) {
        postReceiveJob = 'Yes';
      }

      if (
        (d.label === 'Yard Out' && d.value) ||
        (d.label === 'Yard Out' && d.id)
      ) {
        postYardOut = 'Yes';
      }

      if (
        (d.label === 'Arrival' && d.value) ||
        (d.label === 'Arrival' && d.id)
      ) {
        postArrival = 'Yes';
      }
    });
    return (
      <>
        <br />
        Receive Job : {postReceiveJob}
        <br />
        Yard Out : {postYardOut}
        <br />
        Arrival : {postArrival}
      </>
    );
  };

  const onChangeSelectedModule = (checkedValues, label) => {
    let newSelectedModule = selectedModule.filter((p) => p.label !== label);
    let id = null;
    if (!isCreate) {
      id = formData.find((f) => f.module.name === label);
    }

    let privilege = {
      label: label,
      value: checkedValues[0],
      reasonId: id && id.id,
    };

    newSelectedModule.push(privilege);
    setSelectedModule(newSelectedModule);
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={
            isCreating || isUpdating || isCreatingReason || isUpdatingReason
          }
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Group'
        name='group'
        initialValue={
          formData[0] &&
          formData[0].subject_reason &&
          formData[0].subject_reason.group.name
            ? `(${formData[0].subject_reason.group.code}) ${formData[0].subject_reason.group.name}`
            : null
        }
        rules={[
          {
            required: true,
            message: 'Please input group',
          },
        ]}
      >
        <Select
          disabled={formData[0] && formData[0].id}
          onChange={(checkedGroupValues) => {
            onGroupValues(checkedGroupValues);
          }}
          showSearch
          placeholder='Select a group'
          optionFilterProp='children'
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {groupReasons &&
            groupReasons.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Subject Code'
        name='code'
        initialValue={
          formData[0] &&
          formData[0].subject_reason &&
          formData[0].subject_reason.code
        }
      >
        <Input disabled placeholder={subjectReasonsMaxCode} />
      </Form.Item>

      <Form.Item
        label='Subject Name (Eng)'
        name='name_en'
        initialValue={
          formData[0] &&
          formData[0].subject_reason &&
          formData[0].subject_reason.name_en
        }
        rules={[
          {
            required: true,
            message: 'Please input name (eng)',
          },
        ]}
      >
        <Input placeholder={'subject name (eng)'} disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Subject Name (Thai)'
        name='name_th'
        initialValue={
          formData[0] &&
          formData[0].subject_reason &&
          formData[0].subject_reason.name_th
        }
        rules={[
          {
            required: true,
            message: 'Please input name (thai)',
          },
        ]}
      >
        <Input placeholder={'subject name (thai)'} disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Is Other'
        name='is_other'
        initialValue={
          formData[0] &&
          formData[0].subject_reason &&
          formData[0].subject_reason.is_other
        }
        valuePropName='checked'
      >
        <Switch disabled={readonly} />
      </Form.Item>
      <Form.Item
        label='Operation (select)'
        valuePropName='checked'
        rules={[
          {
            required: true,
            message: 'Please select module',
          },
        ]}
        initialValue={checkOperation(formData)}
      >
        {moduleReasons.map((data, pIndex) => {
          return (
            <Checkbox.Group
              key={`checkbox-${pIndex}`}
              onChange={(checkedValues) => {
                onChangeSelectedModule(checkedValues, data.label, data.value);
              }}
              defaultValue={checkOperation(formData)}
              style={{ width: '100%' }}
              disabled={readonly}
            >
              <Row>
                <Col span={12}>
                  <Checkbox value={data.value}>{data.label}</Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          );
        })}
      </Form.Item>
    </Form>
  );
};

export default SubjectReasonFormComponent;
