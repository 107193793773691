import React, { useState, useEffect } from 'react';
import {
  Badge,
  Popover,
  Checkbox,
  Button,
  Space,
  Input,
  Select,
  Typography,
  Collapse,
} from 'antd';
import styled from 'styled-components';
import {
  useFindAllModuleReasonsQuery,
  useFindAllGroupReasonsQuery,
} from 'reducers/masterData/reasonMaster/api';
import { useSelector, useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { FilterOutlined, ClearOutlined } from '@ant-design/icons';
import { setReasonMasterFilter } from 'reducers/masterData/reasonMaster';
import { Sorter } from 'utils/sorter';
const { Option } = Select;
const { Panel } = Collapse;

const defaultFilter = {
  filterModule: [],
  filterGroup: [],
  filterSearchAll: null,
};

const createPayload = ({ filterModule, filterGroup, filterSearchAll }) => {
  return {
    module__name:
      filterModule && filterModule.length > 0
        ? filterModule.join('&module__name=')
        : null,
    subject_reason__group__code:
      filterGroup && filterGroup.length > 0
        ? filterGroup.join('&subject_reason__group__code=')
        : null,
    search: filterSearchAll,
  };
};

const TableToolbar = (props) => {
  const {
    isLoading,
    columns,
    setColumns,
    onInit,
    onQuery,
    showFilter,
    setShowFilter,
  } = props;
  const dispatch = useDispatch();
  // Store filters
  const storedFilters = useSelector((state) => {
    return state.reasonMaster.filters;
  });

  const { setErrorData } = useErrorHandler();
  const [moduleOptions, setModuleOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);

  const [filterModule, setFilterModule] = useState(
    storedFilters && storedFilters.filterModule
      ? storedFilters.filterModule
      : defaultFilter.filterModule
  );

  const [filterGroup, setFilterGroup] = useState(
    storedFilters && storedFilters.filterGroup
      ? storedFilters.filterGroup
      : defaultFilter.filterGroup
  );

  const [filterSearchAll, setFilterSearchAll] = useState(
    storedFilters && storedFilters.filterSearchAll
      ? storedFilters.filterSearchAll
      : defaultFilter.filterSearchAll
  );

  useEffect(() => {
    if (onInit) {
      onInit({
        ...createPayload({
          filterModule,
          filterGroup,
          filterSearchAll,
        }),
      });
    }
  }, []);

  useEffect(() => {
    dispatch(
      setReasonMasterFilter({
        filterModule,
        filterGroup,
        filterSearchAll,
      })
    );
  }, [dispatch, filterGroup, filterModule, filterSearchAll]);

  const {
    data: dataOrderModule,
    error: errorOrderModule,
    isFetching: isOrderModuleLoading,
  } = useFindAllModuleReasonsQuery();

  useEffect(() => {
    if (errorOrderModule) {
      setErrorData(errorOrderModule);
    }
  }, [errorOrderModule, setErrorData]);

  useEffect(() => {
    if (dataOrderModule) {
      let moduleOptions = [];
      dataOrderModule.forEach((d) => {
        moduleOptions.push({
          label: d.name,
          value: d.name,
        });
      });

      setModuleOptions(moduleOptions);
    }
  }, [dataOrderModule]);

  const {
    data: dataOrderGroup,
    error: errorOrderGroup,
    isFetching: isOrderGroupLoading,
  } = useFindAllGroupReasonsQuery();

  useEffect(() => {
    if (errorOrderGroup) {
      setErrorData(errorOrderGroup);
    }
  }, [errorOrderGroup, setErrorData]);

  useEffect(() => {
    if (dataOrderGroup) {
      let groupOptions = [];
      dataOrderGroup.forEach((d) => {
        groupOptions.push({
          label: d.name,
          code: d.code,
        });
      });
      let sortGroupOptions = Sorter.DEFAULTLIST(groupOptions, 'code');
      setGroupOptions(sortGroupOptions);
    }
  }, [dataOrderGroup]);

  const selectPropsModlue = {
    mode: 'multiple',
    style: {
      width: '200px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isOrderModuleLoading,
  };

  const inputProps = {
    style: {
      width: '200px',
    },
  };

  const selectPropsGroup = {
    mode: 'multiple',
    style: {
      width: '200px',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isOrderGroupLoading,
  };

  const onColsChange = (e, col) => {
    const nextColumns = [...columns];
    let selectedCol = nextColumns.find((c) => c.title === col.title);
    selectedCol['hide'] = !e.target.checked;
    setColumns(nextColumns);
  };
  const onHideAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = true;
    }
    setColumns(nextColumns);
  };
  const onShowAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = false;
    }
    setColumns(nextColumns);
  };

  const onDisplayButton = () => {
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterModule,
          filterGroup,
          filterSearchAll,
        }),
      });
    }
  };

  return (
    <Container>
      <Space direction='horizontal' size={16} align='end'>
        <Popover
          key={'popover'}
          zIndex='10000'
          placement='right'
          content={(() => {
            return [
              <div key={'show-hide-all'}>
                <Button type='link' onClick={onHideAllCols}>
                  Hide all
                </Button>
                /
                <Button type='link' onClick={onShowAllCols}>
                  Show all
                </Button>
                ,
              </div>,
              columns
                .filter((c) => c.title)
                .map((c, index) => {
                  return (
                    <div key={`root-${index}`}>
                      <Checkbox
                        key={index}
                        checked={!c.hide}
                        onChange={(e) => onColsChange(e, c)}
                      >
                        {c.title}
                      </Checkbox>
                    </div>
                  );
                }),
            ];
          })()}
          title='Show/hide Columns'
          trigger='click'
        >
          <Badge dot={columns && columns.some((c) => c.hide)}>
            <Button icon={<FilterOutlined />} size='small' />
          </Badge>
        </Popover>
        <StyledCollapse
          defaultActiveKey={1}
          onChange={() => {
            setShowFilter(!showFilter);
          }}
        >
          <Panel header='Filter Options' key={1}>
            <Space
              direction='horizontal'
              size={16}
              style={{ padding: '5px' }}
              align='end'
            >
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Module</Typography>
                    <Select
                      {...selectPropsModlue}
                      placeholder={'Select module'}
                      value={filterModule}
                      options={moduleOptions}
                      onChange={(values) => {
                        setFilterModule(values);
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>

              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Group</Typography>
                    <Select
                      {...selectPropsGroup}
                      placeholder={'Select group'}
                      value={filterGroup}
                      onChange={(values) => {
                        setFilterGroup(values);
                      }}
                    >
                      {groupOptions &&
                        groupOptions.map((c, index) => (
                          <Option key={index} value={c.code}>
                            {c.label} (No.{c.code})
                          </Option>
                        ))}
                    </Select>
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Typography>Search all</Typography>
                    <Input
                      {...inputProps}
                      value={filterSearchAll}
                      placeholder='Search all'
                      allowClear
                      onChange={(event) => {
                        setFilterSearchAll(event.target.value);
                      }}
                    />
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Space
                      direction='vertical'
                      style={{ width: '100%', marginTop: '22px' }}
                    >
                      <StyledButton
                        type='primary'
                        loading={isLoading}
                        onClick={onDisplayButton}
                      >
                        Display
                      </StyledButton>
                    </Space>
                  </div>
                </Space>
              </SearchBox>
              <SearchBox>
                <Space direction='vertical'>
                  <div>
                    <Space
                      direction='vertical'
                      style={{ width: '100%', marginTop: '22px' }}
                    >
                      <StyledButton
                        icon={<ClearOutlined />}
                        loading={isLoading}
                        onClick={() => {
                          setFilterModule(defaultFilter.filterModule);
                          setFilterGroup(defaultFilter.filterGroup);
                          setFilterSearchAll(defaultFilter.filterSearchAll);
                          onInit();
                        }}
                      >
                        Clear
                      </StyledButton>
                    </Space>
                  </div>
                </Space>
              </SearchBox>
            </Space>
          </Panel>
        </StyledCollapse>
      </Space>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 0px;
    }
  }
`;

const StyledButton = styled(Button)`
  display: block;
  margin-left: auto;
  width: 120px;
`;
const SearchBox = styled.div`
  display: block;
  padding: 0px 5px;
  // border-radius: 5px;
  border: 1px none #ececec;

  height: 80px;
`;
const Container = styled.div`
  padding: 10px 0;
`;

export default TableToolbar;
