import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import { PageHeader, Divider, message, Modal, Row, Col, Statistic } from 'antd';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import {
  useFindEntryAssignVolumeMutation,
  useGetProgressStatusMutation,
} from 'reducers/assignVolume/api';
import moment from 'moment';
import TableYard from './TableYard';
import TableDetailVolume from './TableDetailVolume';
import {
  useFindFilterDataAssignVolumeMutation,
  useClearDataVolumeAssignVolumeMutation,
  useConfirmDataVolumeAssignVolumeMutation,
  useConfirmAssignVolumeVerifyMutation,
  useSearchDataYardAssignVolumeMutation,
  assignVolumeApi,
} from 'reducers/assignVolume/api';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const AssignVolumeIndexComponent = () => {
  const LIMIT = 100;
  const PAGE_SIZE = 50;
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const [totalRows, setTotalRows] = useState(0);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(1);
  const payloadRef = useRef(null);
  const [newDataUnit, setNewDataUnit] = useState(true);

  const [selectFrom, setSelectFrom] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [filterDateFrom, setFilterDateFrom] = useState();
  const [filterDateTo, setFilterDateTo] = useState();
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [isLoadingSearchVolumeTable, setIsLoadingSearchVolumeTable] =
    useState(false);
  const [statusCheckReadyEntry, setStatusCheckReadyEntry] = useState(false);
  const [statusConfirm, setStatusConfirm] = useState(false);
  const [planJobId, setPlanJobId] = useState();

  const [filterSelectFrom, setFilterSelectFrom] = useState();
  const [filterOptionFrom, setFilterOptionFrom] = useState();
  const [uploadFile, setUploadFile] = useState();
  // for table Volume
  const [filterDate, setFilterDate] = useState();
  const [filterOptionDate, setFilterOptionDate] = useState();

  const [filterVehicleType, setFilterVehicleType] = useState(['All']);
  const [filterTo, setFilterTo] = useState(['All']);
  const [filterRegion, setFilterRegion] = useState(['All']);
  const [dataSourceVolume, setDataSourceVolume] = useState([]);
  const [dataSourceVolumeTemp, setDataSourceVolumeTemp] = useState([]);
  const [dataSourceTableYard, setDataSourceTableYard] = useState([]);
  const [checkEditDetailVolume, setCheckEditDetailVolume] = useState(false);
  const [filterOptionVehicleType, setFilterOptionVehicleType] = useState([
    'All',
  ]);
  const [filterOptionTo, setFilterOptionTo] = useState();
  const [filterOptionRegion, setFilterOptionRegion] = useState();

  const [dataEditUnit, setDataEditUnit] = useState();
  const dataSourceVolumeTableRef = useRef([]);
  const progressPercentRef = useRef(0);
  const [progressPercent, setProgressPercent] = useState(0);
  //button------------------------------------------------------------
  const onGetFilterDataVolumeTable = async () => {
    let payload = {
      period_from: filterDateFrom.format('YYYY-MM-DD'),
      period_to: filterDateTo.format('YYYY-MM-DD'),
      location_from: filterSelectFrom,
    };

    apiGetFilterSummaryVolume(payload);
  };

  // api----------------------------------------------------------------
  //  get first time coming in page assign volume------------------
  const [
    getEntryAssignVolumeQuery,
    { data, error, isLoading: isLoadingEntry },
  ] = useFindEntryAssignVolumeMutation();

  useEffect(async () => {
    if (data) {
      let selectFrom = data?.location_from ? data?.location_from : [];
      setSelectFrom(selectFrom);
      // set selectFrom
      let findSelectFrom = selectFrom.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));
      setPlanJobId(data?.plan_job_id ? data?.plan_job_id : '');

      setFilterDateFrom(data?.period_from ? moment(data?.period_from) : '');
      setFilterDateTo(data?.period_to ? moment(data?.period_to) : '');

      if (data?.period_from === null && data?.period_to === null) {
        setStatusCheckReadyEntry(false);
      } else {
        var arr_days_dropdown = [];
        var date_moment_from = moment(data?.period_from);
        var date_moment_to = moment(data?.period_to);
        var diff_date = date_moment_to.diff(date_moment_from, 'days');

        for (let i = 0; i <= diff_date; i++) {
          var date_add_days = moment(data?.period_from).add(i, 'days').toDate();
          var date_add_format = moment(date_add_days).format('DD/MM/YY');
          arr_days_dropdown.push(date_add_format);
        }

        // for dropdown days
        let datalist_days = arr_days_dropdown.map((d) => ({
          label: d,
          value: d,
        }));

        setFilterOptionDate(datalist_days);
        setStatusCheckReadyEntry(true);

        if (datalist_days.length !== 0) {
          setFilterDate(datalist_days[0].value);
        }
      }

      setStatusConfirm(data?.assign_volume_status);
      setFilterOptionFrom(findSelectFrom);

      if (data.plan_job_id) {
        let payload = {
          plan_job_id: data.plan_job_id,
        };
        await apiGetProgressData(payload);
      }
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      setErrorData(error);
    }
  }, [error, setErrorData]);

  useEffect(() => {
    getEntryAssignVolumeQuery();
  }, [getEntryAssignVolumeQuery]);

  const [
    apiGetFilterSummaryVolume,
    {
      data: dataGetFilterSummaryVolume,
      error: errorGetFilter,
      isLoading: isLoadingGetFilter,
    },
  ] = useFindFilterDataAssignVolumeMutation();

  useEffect(() => {
    if (dataGetFilterSummaryVolume) {
      let optionLocationToFilter = dataGetFilterSummaryVolume?.location_to
        ? dataGetFilterSummaryVolume?.location_to
        : [];
      let optionVehicleTypeFilter = dataGetFilterSummaryVolume?.vehicle_type
        ? dataGetFilterSummaryVolume?.vehicle_type
        : [];
      let optionRegionFilter = dataGetFilterSummaryVolume?.region
        ? dataGetFilterSummaryVolume?.region
        : [];

      // set optionLocationToFilter
      let findOptionLocationToFilter = optionLocationToFilter.map((d) => ({
        label: d.name,
        value: d.id,
      }));

      // set optionVehicleTypeFilter
      let findOptionVehicleTypeFilter = optionVehicleTypeFilter.map((d) => ({
        label: d.name,
        value: d.id,
      }));
      // set optionRegionFilter
      let findOptionRegionFilter = optionRegionFilter.map((d) => ({
        label: d.name,
        value: d.id,
      }));
      // push all
      let optionLocationTo = [
        {
          label: 'All',
          value: 'all',
          code: 'all',
        },
      ];
      let optionVehicleType = [
        {
          label: 'All',
          value: 'all',
          code: 'all',
        },
      ];
      let optionRegion = [
        {
          label: 'All',
          value: 'all',
          code: 'all',
        },
      ];
      optionLocationTo.push(...findOptionVehicleTypeFilter);
      optionVehicleType.push(...findOptionLocationToFilter);
      optionRegion.push(...findOptionRegionFilter);

      setFilterOptionVehicleType(optionLocationTo);
      setFilterOptionTo(optionVehicleType);
      setFilterOptionRegion(optionRegion);
    }
  }, [dataGetFilterSummaryVolume]);

  useEffect(() => {
    if (errorGetFilter) {
      setErrorData(errorGetFilter);
    }
  }, [errorGetFilter, setErrorData]);

  // api
  // apiSearchVolumeTable -------------------------------------------------------

  const apiSearchVolumeTable = async (payload) => {
    try {
      if (!isLoadingSearchVolumeTable) {
        let bufferData = [];
        let requiredRows = page * PAGE_SIZE;
        requiredRows =
          requiredRows > totalRows && totalRows !== 0
            ? totalRows
            : requiredRows;
        if (requiredRows > bufferData.length) {
          // Fetch more data
          let curOffset = 0;
          payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };
          do {
            let queryRes = await executeQuery(
              curOffset,
              bufferData,
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            curOffset += LIMIT;
            bufferData = queryRes.data;
            requiredRows =
              requiredRows > queryRes.totalRows
                ? queryRes.totalRows
                : requiredRows;
          } while (requiredRows > bufferData.length);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoadingSearchVolumeTable(true);
        let result = await dispatch(
          assignVolumeApi.endpoints.findFilterSearchVolume.initiate(payload, {
            forceRefetch: forceRefetch ? true : false,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              no: i + rowNo,
            }));
            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            setDataSourceVolume(newData);
            totalRows = result.data.count;
            setCheckEditDetailVolume(false);
            dataSourceVolumeTableRef.current = newData;
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoadingSearchVolumeTable(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  //apiConfirmData -------------------------------------------------------
  const [
    apiConfirmData,
    {
      data: dataConfirmData,
      error: errorConfirmData,
      isLoading: isLoadingConfirmData,
    },
  ] = useConfirmDataVolumeAssignVolumeMutation();

  useEffect(() => {
    if (dataConfirmData) {
      if (dataConfirmData.confirm_sucess === true) {
        const id = setInterval(frame, 1000);
        function frame() {
          if (progressPercentRef.current === 100) {
            clearInterval(id);
            setLoadingConfirm(false);

            Modal.success({
              title: 'Set period & Confirm Success !!',
              content: (
                <div>
                  <Row style={{ paddingTop: '20px' }}>
                    <Col style={{ marginRight: '20px' }}>
                      <p>1. Period from : </p>
                      <p>2. Period to : </p>
                    </Col>
                    <Col>
                      <p>
                        {' '}
                        {moment(dataConfirmData?.result?.period_from).format(
                          'DD/MM/YY'
                        )}
                      </p>
                      <p>
                        {' '}
                        {moment(dataConfirmData?.result?.period_to).format(
                          'DD/MM/YY'
                        )}
                      </p>
                    </Col>
                  </Row>
                </div>
              ),

              onOk() {},
            });
            getEntryAssignVolumeQuery();
          } else {
            let payload = {
              plan_job_id: planJobId,
            };
            apiGetProgressData(payload);
          }
        }
      }
    }
  }, [dataConfirmData]);

  useEffect(() => {
    if (errorConfirmData) {
      setErrorData(errorConfirmData);
    }
  }, [errorConfirmData, setErrorData]);

  //apiConfirmData -------------------------------------------------------
  const [
    apiConfirmDataVerify,
    {
      data: dataConfirmDataVerify,
      error: errorConfirmDataVerify,
      isLoading: isLoadingConfirmDataVerify,
    },
  ] = useConfirmAssignVolumeVerifyMutation();

  useEffect(() => {
    if (dataConfirmDataVerify) {
      if (dataConfirmDataVerify?.success === false) {
        Modal.warning({
          title: dataConfirmDataVerify?.message,
          onOk() {
            Modal.confirm({
              title: 'Confirm',
              icon: <ExclamationCircleOutlined />,
              content: 'Would you like to confirm?',
              okText: 'Confirm',
              cancelText: 'Cancel',
              onOk: () => {
                setLoadingConfirm(true);
                let payload = {
                  period_from: filterDateFrom?.format('YYYY-MM-DD'),
                  period_to: filterDateTo?.format('YYYY-MM-DD'),
                  location_from: [],
                };
                apiConfirmData(payload);
              },
            });
          },
        });
      } else {
        Modal.confirm({
          title: 'Confirm',
          icon: <ExclamationCircleOutlined />,
          content: 'Would you like to confirm?',
          okText: 'Confirm',
          cancelText: 'Cancel',
          onOk: () => {
            setLoadingConfirm(true);
            let payload = {
              period_from: filterDateFrom?.format('YYYY-MM-DD'),
              period_to: filterDateTo?.format('YYYY-MM-DD'),
              location_from: [],
            };
            apiConfirmData(payload);
          },
        });
      }
    }
  }, [dataConfirmDataVerify]);

  useEffect(() => {
    if (errorConfirmDataVerify) {
      setErrorData(errorConfirmDataVerify);
    }
  }, [errorConfirmDataVerify, setErrorData]);

  //apiGetProgressData -------------------------------------------------------
  const [
    apiGetProgressData,
    {
      data: dataProgressData,
      error: errorProgressData,
      isLoading: isLoadingProgressData,
    },
  ] = useGetProgressStatusMutation();

  useEffect(() => {
    if (dataProgressData) {
      progressPercentRef.current = dataProgressData.progress;
      setProgressPercent(
        dataProgressData?.progress ? dataProgressData?.progress : 0
      );
    }
  }, [dataProgressData]);

  useEffect(() => {
    if (errorProgressData) {
      setErrorData(errorProgressData);
    }
  }, [errorProgressData, setErrorData]);

  //apiClearDataAll -------------------------------------------------------
  const [
    apiClearDataAll,
    {
      data: dataClearDataAll,
      error: errorClearDataAll,
      isLoading: isLoadingClearDataAll,
    },
  ] = useClearDataVolumeAssignVolumeMutation();

  useEffect(() => {
    if (dataClearDataAll) {
      setFilterDateFrom('');
      setFilterDateTo('');
      setFilterSelectFrom([]);
      setStatusCheckReadyEntry(false);
      setStatusCheckReadyEntry(false);
      setStatusConfirm(true);
      setDataSourceVolume([]);
      setDataSourceTableYard([]);
      setUploadFile(undefined);
      setProgressPercent(0);
      Modal.success({
        title: 'Clear all data Success !!',
        content: (
          <div>
            <Row style={{ paddingTop: '20px' }}>
              <Col style={{ marginRight: '20px' }}>
                <p>1. Assign time target delete : </p>
                <p>2. Assign volume number delete : </p>
                <p>3. Diagram lot target delete : </p>

                <p>4. Plan job number delete : </p>
              </Col>
              <Col>
                <p>
                  {' '}
                  {dataClearDataAll?.assign_time_target_delete[0]
                    ? dataClearDataAll?.assign_time_target_delete[0]
                    : '-'}
                </p>
                <p>
                  {' '}
                  {dataClearDataAll?.assign_volume_number_delete[0]
                    ? dataClearDataAll?.assign_volume_number_delete[0]
                    : '-'}
                </p>
                <p>
                  {' '}
                  {dataClearDataAll?.diagram_lot_target_delete[0]
                    ? dataClearDataAll?.diagram_lot_target_delete[0]
                    : '-'}
                </p>
                <p>
                  {' '}
                  {dataClearDataAll?.plan_job_number_delete[0]
                    ? dataClearDataAll?.plan_job_number_delete[0]
                    : '-'}
                </p>
              </Col>
            </Row>
          </div>
        ),

        onOk() {},
      });
      getEntryAssignVolumeQuery();
    }
  }, [dataClearDataAll]);

  useEffect(() => {
    if (errorClearDataAll) {
      setErrorData(errorClearDataAll);
    }
  }, [errorClearDataAll, setErrorData]);

  //---------------------------------------------------------

  // ----------------------------------------------------------------

  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  //apiSearchYardTable -------------------------------------------------------
  const [apiSearchYardTable, { data: dataSearchYardTable }] =
    useSearchDataYardAssignVolumeMutation();

  useEffect(() => {
    if (dataSearchYardTable) {
      let arrDataSourceVolumeTemp = [];
      for (let index = 0; index < dataSourceVolumeTemp.length; index++) {
        if (dataSourceVolumeTemp[index].newDataUnit) {
          arrDataSourceVolumeTemp.push(dataSourceVolumeTemp[index]);
        }
      }
      let mapArrTemp = arrDataSourceVolumeTemp.map((item) => ({
        date_teme: item.date,
        vehicle_type_temp: item.vehicle_type.name,
      }));

      const result = mapArrTemp.reduce((acc, curr) => {
        acc[curr.vehicle_type_temp] = Object.assign(
          {},
          acc[curr.vehicle_type_temp],
          curr
        );
        return acc;
      }, {});

      let arrAddIndexTable = dataSearchYardTable.map((item, index) => ({
        ...item,
        key: index + 1,
        date1Value: item.date1?.count,
        date2Value: item.date2?.count,
        date3Value: item.date3?.count,
        date4Value: item.date4?.count,
        date5Value: item.date5?.count,
        date6Value: item.date6?.count,
        date7Value: item.date7?.count,

        date1Date: item.date1?.date,
        date2Date: item.date2?.date,
        date3Date: item.date3?.date,
        date4Date: item.date4?.date,
        date5Date: item.date5?.date,
        date6Date: item.date6?.date,
        date7Date: item.date7?.date,
        ...result,
      }));

      setDataSourceTableYard(arrAddIndexTable);
    }
  }, [dataSearchYardTable]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Assign Volume'
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <TableYard
          //parameter
          fileList={fileList}
          setFileList={setFileList}
          filterDateFrom={filterDateFrom}
          filterDateTo={filterDateTo}
          filterSelectFrom={filterSelectFrom}
          filterOptionFrom={filterOptionFrom}
          setFilterDateFrom={setFilterDateFrom}
          setFilterDateTo={setFilterDateTo}
          setFilterSelectFrom={setFilterSelectFrom}
          setFilterOptionFrom={setFilterOptionFrom}
          isLoadingEntry={isLoadingEntry}
          dataSourceTableYard={dataSourceTableYard}
          apiClearDataAll={apiClearDataAll}
          setDataSourceTableYard={setDataSourceTableYard}
          statusCheckReadyEntry={statusCheckReadyEntry}
          setStatusCheckReadyEntry={setStatusCheckReadyEntry}
          statusConfirm={statusConfirm}
          setStatusConfirm={setStatusConfirm}
          //function
          onGetFilterDataVolumeTable={onGetFilterDataVolumeTable}
          getEntryAssignVolumeQuery={getEntryAssignVolumeQuery}
          checkEditDetailVolume={checkEditDetailVolume}
          setCheckEditDetailVolume={setCheckEditDetailVolume}
          isLoadingClearDataAll={isLoadingClearDataAll}
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          apiConfirmData={apiConfirmData}
          apiConfirmDataVerify={apiConfirmDataVerify}
          isLoadingConfirmData={isLoadingConfirmData}
          loadingConfirm={loadingConfirm}
          progressPercentRef={progressPercentRef.current}
          progressPercent={progressPercent}
          apiSearchYardTable={apiSearchYardTable}
          selectFrom={selectFrom}
          setDataSourceVolume={setDataSourceVolume}
          setFilterDate={setFilterDate}
          setFilterVehicleType={setFilterVehicleType}
          setFilterTo={setFilterTo}
          setFilterRegion={setFilterRegion}
        />

        <TableDetailVolume
          dataSourceTableYard={dataSourceTableYard}
          newDataUnit={newDataUnit}
          setNewDataUnit={setNewDataUnit}
          filterDateFrom={filterDateFrom}
          filterDateTo={filterDateTo}
          filterDate={filterDate}
          filterVehicleType={filterVehicleType}
          filterTo={filterTo}
          filterRegion={filterRegion}
          filterOptionVehicleType={filterOptionVehicleType}
          filterOptionTo={filterOptionTo}
          filterOptionRegion={filterOptionRegion}
          filterSelectFrom={filterSelectFrom}
          setFilterDate={setFilterDate}
          setFilterVehicleType={setFilterVehicleType}
          setFilterTo={setFilterTo}
          setFilterRegion={setFilterRegion}
          isLoadingEntry={isLoadingEntry}
          setFilterOptionVehicleType={setFilterOptionVehicleType}
          setFilterOptionTo={setFilterOptionTo}
          setFilterOptionRegion={setFilterOptionRegion}
          isLoadingGetFilter={isLoadingGetFilter}
          dataSourceVolume={dataSourceVolume}
          setDataSourceVolume={setDataSourceVolume}
          setDataSourceVolumeTemp={setDataSourceVolumeTemp}
          apiSearchVolumeTable={apiSearchVolumeTable}
          isLoadingSearchVolumeTable={isLoadingSearchVolumeTable}
          dataEditUnit={dataEditUnit}
          setDataEditUnit={setDataEditUnit}
          dataSourceVolumeTableRef={dataSourceVolumeTableRef}
          filterOptionDate={filterOptionDate}
          setFilterOptionDate={setFilterOptionDate}
          statusCheckReadyEntry={statusCheckReadyEntry}
          setStatusCheckReadyEntry={setStatusCheckReadyEntry}
          checkEditDetailVolume={checkEditDetailVolume}
          setCheckEditDetailVolume={setCheckEditDetailVolume}
          loadingConfirm={loadingConfirm}
          statusConfirm={statusConfirm}
          apiSearchYardTable={apiSearchYardTable}
          executeQuery={executeQuery}
          PAGE_SIZE={PAGE_SIZE}
          totalRows={totalRows}
          page={page}
          offset={offset}
          payloadRef={payloadRef}
          LIMIT={LIMIT}
          setPage={setPage}
        />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default AssignVolumeIndexComponent;
