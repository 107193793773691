import React, { useState, useEffect, useRef, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import { dateFormatterYear } from 'common_components/AntTable/tableUtils';
import TableToolbar from './Toolbar';
import { useSelector, useDispatch } from 'react-redux';
import useWindowSize from 'hooks/windowSize';
import { push } from 'connected-react-router';
import {
  setCurrentPage,
  setCurrentOffset,
  setCallingSheetDataSource,
  setCallingSheetCompleteCount,
  setIsNeedRefresh,
} from 'reducers/callingsheet';
import { callingsheetApi } from 'reducers/callingsheet/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { message, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { setCallingListCell } from 'reducers/recommendTrip';
import moment from 'moment';
const LIMIT = 200;
const PAGE_SIZE = 200;

const calOffsetFromPage = (page) => {
  return (page - 1 < 0 ? 0 : page - 1) * PAGE_SIZE;
};

const CallingSheetTableComponent = (props) => {
  const { setTotalRows, totalRows } = props;
  const [isLoading, setIsLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showFilter, setShowFilter] = useState(true);

  // Redux Store
  const storedPage = useSelector((state) => {
    return state.callingsheet.currentPage;
  });
  const storedOffset = useSelector((state) => {
    return state.callingsheet.currentOffset;
  });
  const storedDataSource = useSelector((state) => {
    return state.callingsheet.dataSource;
  });
  const isNeedRefresh = useSelector((state) => {
    return state.callingsheet.isNeedRefresh;
  });
  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [offset, setOffset] = useState(storedOffset || 0);
  const [page, setPage] = useState(storedPage || 1);
  const [tableHeight, setTableHeight] = useState(0);
  const [callingCompleteCount, setCallingCompleteCount] = useState(null);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  const { height } = useWindowSize();
  const payloadRef = useRef(null);
  const cellRef = useRef(null);

  useEffect(() => {
    if (height) {
      let h = height - 360;
      if (!showFilter) {
        h = h + 100;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  useEffect(() => {
    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(setCurrentOffset(offset));
  }, [offset, dispatch]);

  useEffect(() => {
    dispatch(setCallingSheetDataSource(dataSource));
  }, [dataSource, dispatch]);

  useEffect(() => {
    if (callingCompleteCount) {
      dispatch(setCallingSheetCompleteCount(callingCompleteCount));
    }
  }, [callingCompleteCount, dispatch]);

  const handleClicked = (cell, dataSource) => {
    try {
      let arr = [];
      for (let index = 0; index < dataSource.length; index++) {
        if (
          dataSource[index].best_receive_job_time &&
          dataSource[index].cluster?.code === cell.cluster?.code
        ) {
          arr.push(dataSource[index].best_receive_job_time);
        }
      }
      cellRef.current = cell;
      if (cell.cluster) {
        if (cell.best_receive_job_time !== arr[0]) {
          Modal.confirm({
            title:
              'Calling ที่คุณเลือกมี Calling ก่อนหน้านี้!!! คุณต้องการเลือกใช่ไหม',
            icon: <ExclamationCircleOutlined />,
            onOk: handleConfirmAction,
            cancelText: 'Cancel',
            okText: 'OK',
            width: 500,
          });
        } else {
          let from_ = {
            label: cellRef.current && cellRef.current.location_from?.short_name,
            value: cellRef.current && cellRef.current.cluster?.id,
          };
          let cluster_ = {
            label: cellRef.current && cellRef.current.cluster?.name,
            value: cellRef.current && cellRef.current.cluster?.id,
          };
          let callingCell = {
            from: from_,
            cluster: cluster_,
            id: cellRef.current.id,
          };
          dispatch(setCallingListCell(callingCell));
          dispatch(
            push(
              `/app/delivery_mng/calling_lists/recommend_trip/${cell.id}/${
                cell.unit
              }/${cell.location_from && cell.location_from.id}/${
                cell.cluster && cell.cluster.id
              }`
            )
          );
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleConfirmAction = () => {
    let from_ = {
      label: cellRef.current && cellRef.current.location_from?.short_name,
      value: cellRef.current && cellRef.current.cluster?.id,
    };
    let cluster_ = {
      label: cellRef.current && cellRef.current.cluster?.name,
      value: cellRef.current && cellRef.current.cluster?.id,
    };
    let callingCell = {
      from: from_,
      cluster: cluster_,
      id: cellRef.current.id,
    };

    dispatch(setCallingListCell(callingCell));
    dispatch(
      push(
        `/app/delivery_mng/calling_lists/recommend_trip/${cellRef.current.id}/${
          cellRef.current.unit
        }/${
          cellRef.current.location_from && cellRef.current.location_from.id
        }/${cellRef.current.cluster && cellRef.current.cluster.id}`
      )
    );
  };

  const executeQuery = useCallback(
    async (payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          callingsheetApi.endpoints.findAllCallingSheetsByPagination.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = payload.offset + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              no: i + rowNo,
            }));
            newData = resData;
            setTotalRows(result.data.count);
            setDataSource(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
      }
      return { data: newData, totalRows: totalRows };
    },
    [dispatch, setErrorData, setTotalRows]
  );

  const getCallingCompleteCount = useCallback(
    async (payload) => {
      let result = await dispatch(
        callingsheetApi.endpoints.findAllNumberCalling.initiate(payload, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          setCallingCompleteCount(result.data);
        }
      }
    },
    [dispatch, setErrorData]
  );

  const onInit = useCallback(
    (payload) => {
      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      let queryCallingCompleteCount = { ...payload };
      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(queryPayload, true);
      getCallingCompleteCount(queryCallingCompleteCount);
    },
    [executeQuery, getCallingCompleteCount]
  );

  const onQuery = useCallback(
    async (payload) => {
      try {
        if (!isLoading) {
          // Force to load from offset 0 when user push search button
          setOffset(0);
          setPage(1);
          payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };
          await executeQuery(
            {
              ...payloadRef.current,
            },
            true
          );
        }
      } catch (err) {
        message.error('Something went wrong.');
      }
    },
    [executeQuery, isLoading]
  );

  useEffect(() => {
    if (isNeedRefresh) {
      dispatch(setIsNeedRefresh(false));
      onInit(payloadRef.current);
    }
  }, [isNeedRefresh, dispatch, onInit]);

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'No.',
        dataIndex: 'no',
        width: 40,
        align: 'center',
        movable: true,
      },
      {
        title: 'Best Receive Job Time',
        dataIndex: 'best_receive_job_time',
        render(text, record) {
          return {
            props: {
              style: {
                background: moment().isSameOrAfter(moment(text))
                  ? '#FFA39E'
                  : '',
              },
            },
            children: <>{text ? dateFormatterYear({ value: text }) : ''}</>,
          };
        },
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'From',
        dataIndex: ['location_from', 'short_name'],
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Cluster',
        dataIndex: ['cluster', 'name'],
        align: 'center',
        width: 40,
        movable: true,
      },
      {
        title: 'Region',
        dataIndex: ['cluster', 'region', 'name'],
        align: 'center',
        width: 40,
        movable: true,
      },
      {
        title: 'Calling No.',
        dataIndex: 'callingsheet_no',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return text ? text : '-';
        },
        movable: true,
      },
      {
        title: 'Unit',
        dataIndex: 'unit',
        render: (text, record) => {
          if (text) {
            return text;
          } else {
            return '-';
          }
        },
        width: 40,
        align: 'center',
        movable: true,
      },
      {
        title: 'Calling Date & Time',
        dataIndex: 'calling_datetime',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return dateFormatterYear({ value: text });
        },
        movable: true,
      },
    ];

    return cols;
  });

  return (
    <Container>
      <TableToolbar
        onInit={onInit}
        onQuery={onQuery}
        callingCompleteCount={callingCompleteCount}
        columns={columns}
        isLoading={isLoading}
        setColumns={setColumns}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        limit={LIMIT}
      />
      <StyledAntTable
        rowKey='id'
        tableId={'callingsheet'}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        pagination={{
          pageSize: PAGE_SIZE,
          total: totalRows,
          current: page,
          size: 'small',
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: async (page, _) => {
            let curOffset = calOffsetFromPage(page);
            await executeQuery(
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            setPage(page);
          },
        }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          {
            label: 'Recommend trip',
            action: (row) => handleClicked(row, dataSource),
          },
        ]}
      />
    </Container>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table > .ant-table-container > .ant-table-body {
      height: ${(props) => {
        return props.height + 'px';
      }};
    }
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: auto;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`;
const Container = styled.div`
  padding: 0 10px;
`;

export default CallingSheetTableComponent;
