import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';
import { useSelector, useDispatch } from 'react-redux';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { mapResetMap } from 'reducers/map';
import { Button, message } from 'antd';
import { routeMasterApi } from 'reducers/digitalmap/routeMaster/api';

import digitalMapUtils from '../../Utils';
import {
  // mapSetCandidateRoutes,
  mapSetCandidatePolylines,
  mapSetBounds,
  SELECT_MASTER_OR_CANDIDATE,
  MAP_SELECT_CANDIDATE,
} from 'reducers/map';

const RouteCandidateTable = (props) => {
  const { routeGroup } = props;
  const [dataSource, setDataSource] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  // const user = useSelector(getCurrentUser);

  const originLocation = useSelector((state) => {
    return state.routeMaster.originLocation;
  });
  const destinationLocation = useSelector((state) => {
    return state.routeMaster.destinationLocation;
  });

  // Auto select row when user click route on the map
  const candidateSelecting = useSelector((state) => {
    return state.map.routeCandidate_selecting;
  });

  useEffect(() => {
    if (originLocation || destinationLocation) {
      setDataSource([]);
      dispatch(mapSetCandidatePolylines([]));
      setShowAddButton(false);
    }
  }, [originLocation, destinationLocation, dispatch]);

  useEffect(() => {
    if (dataSource.length > 0 && candidateSelecting) {
      setSelectedRowKeys([candidateSelecting]);
      let selectedRow = dataSource.find(
        (d) => d.Vehicle === candidateSelecting
      );

      if (selectedRow) {
        setSelectedRows([selectedRow]);
      }
    }
  }, [candidateSelecting, dataSource]);

  useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      setShowAddButton(true);
    } else {
      setShowAddButton(false);
    }
  }, [dataSource]);

  const [columns] = useState(() => {
    let cols = [
      {
        title: 'Route',
        dataIndex: 'Vehicle',
        width: 80,
      },
      {
        title: 'Distance (km)',
        dataIndex: 'TotalDistance',
        render: (text, record) => {
          return (parseInt(text) / 1000).toFixed(2);
        },
        width: 80,
      },
      {
        title: 'Time (min)',
        dataIndex: 'TotalTime',
        width: 80,
      },
      {
        title: 'Toll',
        dataIndex: 'TollUsage',
        render: (text, record) => {
          return text === '1' ? 'yes' : 'no';
        },
        width: 80,
      },
    ];

    return cols;
  });

  const rowSelection = {
    type: 'radio',
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);

      if (selectedRows.length > 0) {
        dispatch(SELECT_MASTER_OR_CANDIDATE(2));
        selectedRows.forEach((r) => dispatch(MAP_SELECT_CANDIDATE(r.Vehicle)));
      } else {
        dispatch(MAP_SELECT_CANDIDATE(-1));
      }
    },
  };
  const onFindRoute = async () => {
    if (originLocation && destinationLocation) {
      setIsLoading(true);
      try {
        let result = await dispatch(
          routeMasterApi.endpoints.findOdRouting.initiate({
            st_lat: originLocation.lat,
            st_lng: originLocation.lng,
            en_lat: destinationLocation.lat,
            en_lng: destinationLocation.lng,
          })
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result) {
            let polylines = digitalMapUtils.createRouteCandidatePolylines(
              result.data.list,
              false
            );

            let bounds = digitalMapUtils.createCandidateRoutesBounds(
              result.data.list
            );
            dispatch(mapSetCandidatePolylines(polylines));

            dispatch(mapSetBounds(bounds));
            dispatch(SELECT_MASTER_OR_CANDIDATE(2));

            setDataSource(result.data.list);
          }
        }
      } catch (err) {
        message.error('Something went wrong');
      } finally {
        setIsLoading(false);
      }
    }
  };
  const onRow = (record, rowIndex) => {
    return {
      onClick: (event) => {
        dispatch(SELECT_MASTER_OR_CANDIDATE(2));

        setSelectedRowKeys([record.Vehicle]);
        setSelectedRows([record]);
        event.stopPropagation();

        setTimeout(() => {
          dispatch(MAP_SELECT_CANDIDATE(record.Vehicle));
        }, 100);
      },
    };
  };

  const onAddRoute = async () => {
    if (selectedRows.length === 0) {
      message.warning('Please select route');
      return;
    }

    try {
      const payload = {
        route_group: routeGroup.id,
        name: selectedRows[0].Vehicle,
        routes: selectedRows[0],
      };
      setIsLoading(true);
      const result = await dispatch(
        routeMasterApi.endpoints.createRouteMaster.initiate(payload)
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        message.success('Data was successfully saved');
      }
    } catch (err) {
      message.error('Something went wrong');
    } finally {
      setIsLoading(false);
      dispatch(mapResetMap());
    }
  };

  return (
    <Container>
      <Toolbar>
        {showAddButton && (
          <Button
            type='primary'
            size={'small'}
            onClick={onAddRoute}
            loading={isLoading}
            style={{ marginRight: '10px' }}
          >
            Add
          </Button>
        )}
        {originLocation && destinationLocation && (
          <Button
            type='primary'
            size={'small'}
            onClick={onFindRoute}
            loading={isLoading}
          >
            Find Route
          </Button>
        )}
      </Toolbar>
      <AntTable
        rowKey='Vehicle'
        bordered
        pagination={false}
        rowSelection={rowSelection}
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        scroll={{ y: 100 }}
        onRow={onRow}
      />
    </Container>
  );
};
const Toolbar = styled.div`
  display: flex;
  justify-content: end;
  margin-bottom: 10px;
`;
const Container = styled.div`
  padding: 10px;
  height: 210px;
  overflow-y: auto;
  background: white;
`;

export default RouteCandidateTable;
