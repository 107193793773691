import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Form, Input, Button, Modal, message } from 'antd';

import {
  useFindVehicleTypeByIdQuery,
  useCreateVehicleTypeMutation,
  useUpdateVehicleTypeMutation,
} from 'reducers/masterData/VehicleType/api';

import { goBack } from 'connected-react-router';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const DiagramOwnerFormComponent = (props) => {
  const { id } = useParams();

  const { formData, isCreate } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { setErrorData } = useErrorHandler();

  // Query Parking Lane by ID
  const {
    data: dataVehicleTypeById,
    error: errorParkingById,
    isFetching: isParkingByIdFetching,
    refetch: refetchParkingById,
  } = useFindVehicleTypeByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (dataVehicleTypeById) {
      form.setFieldsValue({ name: dataVehicleTypeById.name });
    }
  }, [dataVehicleTypeById]);

  useEffect(() => {
    if (errorParkingById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load parking lane',
        okText: 'Retry',
        onOk: refetchParkingById,
        cancelText: 'Cancel',
      });
    }
  }, [errorParkingById, refetchParkingById]);

  const [
    createParkingLane,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateVehicleTypeMutation();

  const [
    updateParkingLane,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateVehicleTypeMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onClickSubmit = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);

    let postData = {
      name: validData && validData.name,
      ...validData,
    };
    let putData = {
      name: validData && validData.name,
      ...validData,
      id: id,
    };

    if (isCreate) {
      createParkingLane(postData);
    } else {
      updateParkingLane(putData);
    }
  };

  return (
    <Form
      form={form}
      name="basic"
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      style={{ padding: '20px' }}
      autoComplete="off"
    >
      <Form.Item
        wrapperCol={{
          offset: 16,
          span: 8,
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          loading={isCreating || isUpdating}
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label="Name"
        name="name"
        rules={[
          {
            required: true,
            message: 'Please input vehicle types name',
          },
        ]}
      >
        <Input placeholder={'Vehicle Types name'} />
      </Form.Item>
    </Form>
  );
};

export default DiagramOwnerFormComponent;
