import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import intervalPlural from "i18next-intervalplural-postprocessor";
import resources from "./translations";

const missingKeyHandler = (lngs, _, key, fallbackValue, _2) => {
  if (key) {
    // eslint-disable-next-line no-alert
    console.log(
      `Language: ${lngs.toString()}, Missing key: ${key}, Instead got: ${fallbackValue}`
    );
  }
};

const i18n = i18next
  .createInstance()
  .use(LanguageDetector)
  .use(intervalPlural)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    lng: "en",
    saveMissing: true,
    missingKeyHandler: missingKeyHandler,
  });

export default i18n;
