import React, { useState, useEffect, useRef, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import { dateTimeNotYearTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import TableToolbar from './Toolbar';
import { getLanguage } from 'reducers/language';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import EvaStatusRenderer from './EvaStatusRenderer';
import { tttOperationDashboardApi } from 'reducers/digitalmap/tttOperationDashboard/api';
import { message, Button, Tooltip, Space } from 'antd';
import { useErrorHandler } from 'common_components/ErrorContext';
import useWindowSize from 'hooks/windowSize';
import {
  setTTTCurrentPage,
  setTTTCurrentOffset,
  setTTTOperationDashboardDataSource,
} from 'reducers/digitalmap/tttOperationDashboard';

import { WarningTwoTone, ClockCircleTwoTone } from '@ant-design/icons';
import FormReason from './FormReason';
import { useVT } from 'virtualizedtableforantd4';

const LIMIT = 50;
const PAGE_SIZE = 20;

const OperationDashboardTable = (props) => {
  const language = useSelector(getLanguage);
  const { setTotalRows, totalRows } = props;
  const ref = useRef();
  const dispatch = useDispatch();
  // Redux Store
  const storedPage = useSelector((state) => {
    return state.tttOperationDashboard.tttCurrentPage;
  });
  const storedOffset = useSelector((state) => {
    return state.tttOperationDashboard.tttCurrentOffset;
  });
  const storedDataSource = useSelector((state) => {
    return state.tttOperationDashboard.tttDataSource;
  });
  const storedFiltersPayload = useSelector((state) => {
    return state.tttOperationDashboard.tttFiltersPayload;
  });
  const storedDigitalMap = useSelector((state) => {
    return state.tttOperationDashboard.tttDigitalMap;
  });

  const payloadRef = useRef(null);
  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const [offset, setOffset] = useState(storedOffset || 0);
  const [page, setPage] = useState(storedPage || 1);
  const [tableHeight, setTableHeight] = useState(0);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'dm_ttt_operation_dashboard')
  );

  const { setErrorData } = useErrorHandler();
  const { height } = useWindowSize();

  useEffect(() => {
    if (height) {
      let h = height - 327;
      if (!showFilter) {
        h = h + 240;
      }
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  const [vt] = useVT(
    () => ({
      onScroll: async ({ top, isEnd }) => {
        try {
          if (isEnd && dataSource.length < totalRows) {
            await executeQuery(
              offset,
              dataSource,
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: offset,
              },
              true
            );
            ref.current?.scrollTo(top);
          }
        } catch (err) {
          message.error('Something went wrong.');
        }
      },
      ref,
      scroll: { y: tableHeight },
    }),
    [dataSource, tableHeight]
  );

  useEffect(() => {
    dispatch(setTTTCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(setTTTCurrentOffset(offset));
  }, [offset, dispatch]);

  useEffect(() => {
    dispatch(setTTTOperationDashboardDataSource(dataSource));
  }, [dataSource, dispatch]);

  const [formData, setFormData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'No.',
        dataIndex: 'id',
        width: 30,
        movable: true,
      },
      {
        title: 'Delivery Status',
        dataIndex: 'eva',
        width: 85,
        movable: true,
        align: 'center',
        render: (text, record) => {
          return <EvaStatusRenderer data={record} />;
        },
      },
      {
        title: 'Original ETA',
        dataIndex: 'original_eta',
        width: 100,
        render: (text, record) => {
          return dateTimeNotYearTimestamptzFormatter({ value: text });
        },
        movable: true,
        align: 'center',
      },

      {
        title: 'Update ETA',
        dataIndex: 'update_eta',
        width: 100,
        align: 'center',
        render: (text, record) => {
          if (record.trailer_status === 'Finished') {
            return '';
          } else if (text === 'N/A') {
            return text;
          } else {
            return dateTimeNotYearTimestamptzFormatter({ value: text });
          }
        },
        movable: true,
      },
      {
        title: 'Diff',
        dataIndex: 'diff',
        width: 70,
        movable: true,
        align: 'center',
      },
      {
        title: 'Yard Out Time',
        dataIndex: 'yard_out_time',
        width: 100,
        movable: true,
        align: 'center',
      },

      {
        title: 'Trailer no.',
        dataIndex: 'trailer_no',
        width: 100,
        movable: true,
        align: 'center',
      },
      {
        title: 'Gate Out Yard',
        dataIndex: 'gate_out_yard',
        width: 140,
        movable: true,
        align: 'center',
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster',
        width: 70,
        movable: true,
        align: 'center',
      },
      {
        title: 'Current Location',
        dataIndex: 'current_location',
        width: 140,
        movable: true,
        render: (text, record) => {
          if (record.trailer_status === 'Finished') {
            return '';
          } else {
            return text;
          }
        },
        align: 'center',
      },

      {
        title: 'Reason',
        dataIndex: 'reason_symbol',
        width: 70,
        align: 'center',
        movable: true,
        render: (text, record) => {
          if (text === 'delay') {
            return (
              <>
                <Space>
                  <WarningTwoTone
                    style={{ fontSize: 18, top: 10 }}
                    twoToneColor='red'
                  />

                  <Button
                    size='small'
                    style={{
                      backgroundColor: '#ffccc7',
                      width: '40px',
                      height: 20,
                      top: 1,
                    }}
                    danger
                    onClick={() => showModal(record)}
                  >
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  </Button>
                </Space>
              </>
            );
          } else if (text === 'show') {
            return (
              <Tooltip
                placement='bottom'
                title={
                  language === 'th'
                    ? record.reason_description
                      ? record.reason_description
                      : record.reason.subject_reason.name_th
                    : record.reason_description
                    ? record.reason_description
                    : record.reason.subject_reason.name_en
                }
              >
                <Button
                  shape='round'
                  size='small'
                  style={{ fontSize: 12 }}
                  onClick={() => showModal(record)}
                >
                  {record.reason && record.reason.subject_reason.code}
                </Button>
              </Tooltip>
            );
          } else if (text === 'recovery') {
            return (
              <ClockCircleTwoTone
                style={{ fontSize: 20, marginRight: 3 }}
                twoToneColor='#64CFA1'
              />
            );
          }
        },
      },
      {
        title: 'Trailer Status',
        dataIndex: 'trailer_status',
        width: 100,
        align: 'center',
        movable: true,
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }

    return cols;
  });

  const executeQuery = useCallback(
    async (curOffset, curData, payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      try {
        setIsLoading(true);
        let result = await dispatch(
          tttOperationDashboardApi.endpoints.findTTTAllJobsByDatetime.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = curData.length + 1;
            let resData = result.data.results.map((d, i) => ({
              ...d,
              id: i + rowNo,
            }));

            newData = curData.concat(resData);
            setTotalRows(result.data.count);
            setOffset(curOffset + LIMIT);
            setDataSource(newData);
            totalRows = result.data.count;
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch, setErrorData, setTotalRows]
  );

  const onInit = useCallback(
    (payload) => {
      // If having data, no need to load on init
      if (offset > 0) {
        payloadRef.current = { ...payload, limit: LIMIT, offset: offset };
        return;
      }
      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      payloadRef.current = queryPayload;
      setOffset(0);
      setPage(1);
      setDataSource([]);
      executeQuery(0, [], queryPayload, true);
    },
    [executeQuery, offset]
  );

  const onQuery = async (payload) => {
    try {
      if (!isLoading) {
        let bufferData = [];
        let requiredRows = page * PAGE_SIZE;
        requiredRows =
          requiredRows > totalRows && totalRows !== 0
            ? totalRows
            : requiredRows;

        if (requiredRows > bufferData.length) {
          // Fetch more data
          let curOffset = 0;
          payloadRef.current = { ...payload, limit: LIMIT, offset: 0 };

          do {
            let queryRes = await executeQuery(
              curOffset,
              bufferData,
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            curOffset += LIMIT;
            bufferData = queryRes.data;
            requiredRows =
              requiredRows > queryRes.totalRows
                ? queryRes.totalRows
                : requiredRows;
          } while (requiredRows > bufferData.length);
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };

  const onAutoRefetch = useCallback(
    async (payload) => {
      try {
        let requiredRows = page * PAGE_SIZE;

        let bufferData = [];
        let curOffset = 0;
        setOffset(curOffset);

        do {
          let queryPayload = { ...payload, limit: LIMIT, offset: curOffset };
          payloadRef.current = queryPayload;

          let queryRes = await executeQuery(
            curOffset,
            bufferData,
            queryPayload,
            true
          );
          curOffset += LIMIT;
          bufferData = queryRes.data;
          requiredRows =
            requiredRows > queryRes.totalRows
              ? queryRes.totalRows
              : requiredRows;
        } while (requiredRows > bufferData.length);
        setPage(1);
      } catch (err) {
        message.error('Something went wrong.');
      }
    },
    [executeQuery, page]
  );

  const refreshData = useCallback(() => {
    onAutoRefetch(storedFiltersPayload);
  }, [onAutoRefetch, storedFiltersPayload]);
  useEffect(() => {
    if (storedDigitalMap) {
      refreshData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storedDigitalMap]);
  const showModal = async (value) => {
    let newData = null;
    try {
      if (value.reason && value.reason.id) {
        let result = await dispatch(
          tttOperationDashboardApi.endpoints.findTTTReasonMasterById.initiate(
            value.reason && value.reason.id
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            newData = {
              ...value,
              nameGroup: `(${result.data.subject_reason.group.code}) ${result.data.subject_reason.group.name}`,
              groupCode: result.data.subject_reason.group.code,
              nameSubject: `(${value.reason.subject_reason.code}) ${
                language === 'th'
                  ? value.reason.subject_reason.name_th
                  : value.reason.subject_reason.name_en
              }`,
            };
          }
        }
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
    setFormData(newData ? newData : value);

    setIsModalVisible(true);
  };

  const handleCancel = (value) => {
    setIsModalVisible(value);
  };

  return (
    <Container>
      <TableToolbar
        isLoading={isLoading}
        columns={columns}
        setColumns={setColumns}
        onInit={onInit}
        onQuery={onQuery}
        onAutoRefetch={onAutoRefetch}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
      />

      <StyledTable
        rowKey='id'
        tableId={'dm_ttt_operation_dashboard'}
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        components={vt}
        pagination={false}
        scroll={{
          y: tableHeight,
        }}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
      />
      <FormReason
        formData={formData}
        showModal={isModalVisible}
        setIsModalVisible={handleCancel}
        refreshData={refreshData}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

const StyledTable = styled(AntTable)`
  &&& {
    @media screen and (max-width: 992px) {
      body {
        background-color: blue;
        color: white;
        font-size: 20px;
      }

      .ant-table > .ant-table-container > .ant-table-body {
        height: ${(props) => {
          return props.height + 'px';
        }};
      }
      .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
        cursor: auto;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #1890ff !important;
        border-color: #1890ff !important;
      }
      .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
        border-color: white;
      }
      .ant-table > .ant-table-container > .ant-table-body tr td {
        padding: 3px;
        // font-size: 11px;
      }
    }
  }
`;
export default OperationDashboardTable;
