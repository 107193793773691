import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
};
export const ReceivejobSlice = createSlice({
  name: 'receive_job',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.CurrentPage = payload;
    },
    setTTTCurrentOffset: (state, { payload }) => {
      state.CurrentOffset = payload;
    },
    setReceivejobTotalRows: (state, { payload }) => {
      state.TotalRows = payload;
    },
    setReceivejobDataSource: (state, { payload }) => {
      state.DataSource = payload;
    },
    setReceivejobFilter: (state, { payload }) => {
      // debugger
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setReceivejobFilter,
  setCurrentPage,
  setCurrentOffset,
  setReceivejobDataSource,
  setReceivejobTotalRows,
} = ReceivejobSlice.actions;

export const ReceivejobSelector = (state) => state.receivejob;

export default ReceivejobSlice.reducer;
