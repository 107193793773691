import { ddmmyyFormatter } from 'common_components/AntTable/tableUtils';
import { InfoCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd';
const { Link } = Typography;
function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const Alert = ({ matchingFailed = true }) => {
  if (!matchingFailed) return <></>;

  return (
    <b>
      <InfoCircleFilled
        style={{
          color: 'red',
        }}
      />
    </b>
  );
};

export const DetailHeaderHandler = (item, idx) => {
  const { title, title_value } = item;
  if (title === 'Total')
    return {
      title: 'Total',
      align: 'center',
      className: 'color-head-6-border',
      key: `dhh-${idx}`,
      children: [
        {
          title: 'Use / Capacity',
          align: 'center',
          className: 'color-head-6-border',
          key: `dhh-${idx}`,
          children: [
            {
              title: 'Payment',
              width: 120,
              align: 'center',
              dataIndex: 'payment',
              className: 'color-head-6',
              key: 1,
              render: (text, record, index) => {
                return {
                  children: (
                    <div style={{ align: 'center' }}>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                        }}
                      >
                        <span>
                          {numberWithCommas(
                            record.total_use ? record.total_use : 0
                          )}
                          {' / '}{' '}
                          {numberWithCommas(
                            record.total_capacity ? record.total_capacity : 0
                          )}
                        </span>
                        <b style={{ fontWeight: '800' }}>
                          {Number.parseFloat(record.percent_use).toFixed(1)} %
                        </b>
                      </div>
                      <h5 style={{ margin: '0px' }}>---------------------</h5>{' '}
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        {record.price_matching_failed ? (
                          <Alert
                            matchingFailed={record.price_matching_failed}
                          />
                        ) : (
                          <span>&nbsp;</span>
                        )}
                        <b>
                          {numberWithCommas(
                            record.total_payment ? record.total_payment : 0
                          )}
                        </b>
                      </div>
                    </div>
                  ),
                };
              },
            },
          ],
        },
      ],
    };
  //   console.log(idx, title, title_value);
  const remain_keys = {
    D: {
      className: 'color-head-6-remain_d',
      background: '#d8e2dc',
      children: [
        { title: 'S', var_suffix: '_day_short' },
        { title: 'L', var_suffix: '_day_long' },
      ],
    },
    N: {
      className: 'color-head-6-remain_n',
      background: '#ffe5d9',
      children: [
        { title: 'S', var_suffix: '_night_short' },
        { title: 'L', var_suffix: '_night_long' },
      ],
    },
    T: {
      className: 'color-head-6-remain_t',
      background: '#f4acb7',
      children: [
        { title: 'S', var_suffix: '_total_short' },
        { title: 'L', var_suffix: '_total_long' },
      ],
    },
  };
  const day_children = [
    {
      title: 'Use / Capacity',
      align: 'center',
      className: 'color-head-6-border',
      key: `dhh-${idx}`,
      children: [
        {
          title: 'Payment',
          width: 120,
          align: 'center',
          dataIndex: 'payment',
          className: 'color-head-6',
          key: 1,
          render: (text, record, index) => {
            let use = record[`day_${idx}_use`] ? record[`day_${idx}_use`] : 0;
            let capacity = record[`day_${idx}_capacity`]
              ? record[`day_${idx}_capacity`]
              : 0;
            let payment = record[`day_${idx}_payment`]
              ? record[`day_${idx}_payment`]
              : 0;
            let price_matching_failed = record[
              `day_${idx}_price_matching_failed`
            ]
              ? record[`day_${idx}_price_matching_failed`]
              : 0;

            return {
              children: (
                <div style={{ align: 'center' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around',
                    }}
                  >
                    {numberWithCommas(use)}
                    {' / '} {numberWithCommas(capacity)}
                  </div>
                  <h5 style={{ margin: '0px' }}>---------------------</h5>
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    {price_matching_failed ? (
                      <Alert matchingFailed={price_matching_failed} />
                    ) : (
                      <span>&nbsp;</span>
                    )}
                    {numberWithCommas(payment)}
                  </div>
                </div>
              ),
            };
          },
        },
      ],
    },
    {
      title: 'Use / Remain',
      className: 'color-head-6',
      align: 'center',
      key: `dhh-${idx}`,
      children: Object.keys(remain_keys).map((k, cIdx) => ({
        title: k,
        width: 10,
        align: 'center',
        dataIndex: 'short_total',
        className: remain_keys[k].className,
        key: cIdx,
        children: remain_keys[k].children.map((c, rIdx) => {
          return {
            title: c.title,
            width: 10,
            align: 'center',
            dataIndex: `day_${idx}${c.var_suffix}`,
            className: remain_keys[k].className,
            key: rIdx,
            render(text, record) {
              return {
                props: {
                  style: {
                    background: remain_keys[k].background,
                  },
                },
                children: (
                  <div style={{ align: 'center' }}>
                    {record[`day_${idx}_use${c.var_suffix}`]}
                    <h5 style={{ margin: '0px' }}>---</h5>{' '}
                    {record[`day_${idx}_remain${c.var_suffix}`]}
                  </div>
                ),
              };
            },
          };
        }),
      })),
    },
  ];

  return {
    title: () => {
      return (
        <div>
          <b>{title}</b>
          <p>{ddmmyyFormatter({ value: title_value })}</p>
        </div>
      );
    },
    className: 'color-head-6',
    dataIndex: 'date',
    align: 'center',
    key: `dhh-${idx}`,

    children: day_children,
  };
};

const onLinkManageTourList = (index, title, filter, value, date) => {
  let filteredList = null;
  if (index === 0 && title !== 'Total') {
    filteredList = `matched=${true}&tour=${filter}&working_day=${date}`;
  } else if (index === 1 && title !== 'Total') {
    filteredList = `matched=${false}&tour=${filter}&working_day=${date}`;
  } else if (date || (date !== undefined && title !== 'Total')) {
    if (index === 0) {
      filteredList = `matched=${true}&tour=${filter}&working_day=${date}`;
    } else if (index === 1) {
      filteredList = `matched=${false}&tour=${filter}&working_day=${date}`;
    } else {
      filteredList = `matched=${'total'}&tour=${filter}&working_day=${date}`;
    }
  } else {
    filteredList = ``;
  }

  window.open(`/app/planning/manage_tour_list/${filteredList}`, '_blank');
};

export const OverallHeaderHandler = (item, idx) => {
  const { title, title_value } = item;
  if (title === 'Total')
    return {
      title: () => {
        return <b>Total</b>;
      },
      dataIndex: `ohh-${idx}`,
      width: 100,
      align: 'center',
      className: 'color-head-6',
      children: [
        {
          title: () => {
            return <b>Short</b>;
          },
          width: 50,
          align: 'center',
          dataIndex: 'total_short',
          className: 'color-head-6',
          key: 1,
          render: (text, record, index) => {
            return (
              <Link
                style={{ textDecoration: 'underline', color: '#000' }}
                onClick={() =>
                  onLinkManageTourList(index, title, 'short', text, 'all')
                }
              >
                {text}
              </Link>
            );
          },
        },
        {
          title: () => {
            return <b>Long</b>;
          },
          width: 50,
          align: 'center',
          dataIndex: 'total_long',
          className: 'color-head-6',
          key: 2,
          render: (text, record, index) => {
            return (
              <Link
                style={{ textDecoration: 'underline', color: '#000' }}
                onClick={() =>
                  onLinkManageTourList(index, title, 'long', text, 'all')
                }
              >
                {text}
              </Link>
            );
          },
        },
        {
          title: () => {
            return <b>TTL</b>;
          },
          width: 50,
          align: 'center',
          dataIndex: 'total_ttl',
          className: 'color-head-6',
          key: 3,
          render: (text, record, index) => {
            return (
              <Link
                style={{ textDecoration: 'underline', color: '#000' }}
                onClick={() =>
                  onLinkManageTourList(index, title, 'total', text, 'all')
                }
              >
                {text}
              </Link>
            );
          },
        },
      ],
    };
  return {
    title: () => (
      <div>
        <b>{title}</b> <p>{ddmmyyFormatter({ value: title_value })}</p>
      </div>
    ),
    dataIndex: `ohh-${idx}`,
    align: 'center',
    className: 'color-head-6',
    children: [
      {
        title: () => {
          return <b>Short</b>;
        },

        width: 50,
        align: 'center',
        dataIndex: `day_${idx}_short`,
        className: 'color-head-6',
        key: 1,

        render: (text, record, index) => {
          return (
            <Link
              style={{ textDecoration: 'underline', color: '#000' }}
              onClick={() =>
                onLinkManageTourList(index, title, 'short', text, title_value)
              }
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: () => {
          return <b>Long</b>;
        },
        width: 50,
        align: 'center',
        dataIndex: `day_${idx}_long`,
        className: 'color-head-6',
        key: 2,
        render: (text, record, index) => {
          return (
            <Link
              style={{ textDecoration: 'underline', color: '#000' }}
              onClick={() =>
                onLinkManageTourList(index, title, 'long', text, title_value)
              }
            >
              {text}
            </Link>
          );
        },
      },
      {
        title: () => {
          return <b>TTL</b>;
        },
        width: 50,
        align: 'center',
        dataIndex: `day_${idx}_ttl`,
        className: 'color-head-6',
        key: 3,
        render: (text, record, index) => {
          return (
            <Link
              style={{ textDecoration: 'underline', color: '#000' }}
              onClick={() =>
                onLinkManageTourList(index, title, 'total', text, title_value)
              }
            >
              {text}
            </Link>
          );
        },
      },
    ],
  };
};
