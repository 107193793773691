import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, message } from 'antd';
import {
  useUpdateGroupReasonMutation,
  useCreateGroupReasonMutation,
  useFindAllGroupReasonsMaxCodeQuery,
} from 'reducers/masterData/reasonMaster/api';
import { getCurrentUser } from 'reducers/user';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { goBack } from 'connected-react-router';
const GroupReasonFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const [groupMaxCode, setGroupMaxCode] = useState(
    formData && formData.code ? formData && formData.code : null
  );
  const user = useSelector(getCurrentUser);
  const { setErrorData } = useErrorHandler();
  const dispatch = useDispatch();
  const [
    createGroupReason,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateGroupReasonMutation();

  const [
    updateGroupReason,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateGroupReasonMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  // Query all Group Reasons MaxCode

  const {
    data: dataGroupReasonsMaxCode,
    error: errorGroupReasonsMaxCode,
    refetch: refetchGroupReasonsMaxCode,
  } = useFindAllGroupReasonsMaxCodeQuery(null, {
    skip: !user || readonly,
  });

  useEffect(() => {
    setGroupMaxCode(
      dataGroupReasonsMaxCode && dataGroupReasonsMaxCode.next_code
        ? dataGroupReasonsMaxCode.next_code
        : null
    );
  }, [dataGroupReasonsMaxCode]);

  useEffect(() => {
    if (errorGroupReasonsMaxCode) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load reasons',
        okText: 'Retry',
        onOk: refetchGroupReasonsMaxCode,
        cancelText: 'Cancel',
      });
    }
  }, [errorGroupReasonsMaxCode, refetchGroupReasonsMaxCode]);

  const onFinish = () => {
    showModal();
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let postData = {
      id: formData && formData.id,
      ...validData,
      code:
        formData && formData.code ? formData && formData.code : groupMaxCode,
    };

    if (isCreate) {
      createGroupReason(postData);
    } else {
      updateGroupReason(postData);
    }
  };

  const showModal = () => {
    if (
      (isCreate && groupMaxCode > 900) ||
      (isCreate && groupMaxCode === 900)
    ) {
      Modal.warning({
        title: 'คุณได้ทำการเพิ่ม Group Code ถึง 900 แล้ว ไม่สามารถเพิ่มใหม่ได้',
        icon: <ExclamationCircleOutlined />,
      });
    } else {
      Modal.confirm({
        title: isCreate
          ? 'คุณต้องการยืนยันการสร้างกลุ่มใหม่ใช่หรือไม่'
          : 'คุณต้องการยืนยันการแก้ไขรายละเอียดกลุ่มใช่หรือไม่',
        icon: <ExclamationCircleOutlined />,
        content: (
          <>
            Group :{' '}
            {form.getFieldValue('code')
              ? form.getFieldValue('code')
              : groupMaxCode}
            <br />
            Group Name : {form.getFieldValue('name')}
            <br />
          </>
        ),
        onOk: handleConfirmAction,
        cancelText: 'Cancel',
        okText: 'Confirm',
        width: 500,
      });
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Group Code'
        name='code'
        initialValue={
          formData && formData.code ? formData && formData.code : groupMaxCode
        }
      >
        <Input disabled placeholder={groupMaxCode} />
      </Form.Item>
      <Form.Item
        label='Group Name'
        name='name'
        initialValue={formData && formData.name}
        rules={[
          {
            required: true,
            message: 'Please input group name',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'group name'} />
      </Form.Item>
    </Form>
  );
};

export default GroupReasonFormComponent;
