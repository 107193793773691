import React, { useState, useEffect, useRef, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import TableToolbar from './Toolbar';
import permission from 'permissions';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import useWindowSize from 'hooks/windowSize';
import { Table, message, Modal, Progress, Typography, Popover } from 'antd';
import { useErrorHandler } from 'common_components/ErrorContext';
import { dispatchBoardApi } from 'reducers/dispatchBoard/api';
import styled from 'styled-components';
import { manageCallingApi } from 'reducers/manageCalling/api';
import {
  setCurrentPage,
  // setCurrentOffset,
  setDispatchBoardDataSource,
  setDispatchBoardCallingCompleteCount,
  setDispatchBoardSummary,
  setDispatchBoardWaitInputTRDVCount,
} from 'reducers/dispatchBoard';
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CheckOutlined,
  ExclamationCircleTwoTone,
} from '@ant-design/icons';
import moment from 'moment';
import Json2CSV from 'utils/json2csv';
import {
  useCancelMatchTrailerDriverMutation,
  useCancelMatchPlanMutation,
  useCancelMatchCallingMutation,
} from 'reducers/dispatchBoard/api';
import {
  dateTimeNotYearTimestamptzFormatter,
  dateYearWorkingDate,
} from 'common_components/AntTable/tableUtils';
import DiagramPlanDetail from './DiagramPlanDetail';
import { push } from 'connected-react-router';
import PrintDN from 'common_components/PrintDocument/PrintingDN/PrintDN';
import { criteriaApi } from 'reducers/criteria/api';
import { useParams } from 'react-router-dom';
import FormReason from './FormReason';
import dispatchBoardConstants from './constants';
import {
  styleBackgroundReceiveJobTime,
  styeDestination,
  fomattingDestination,
  styleBackgroundActualReceiveJob,
  styleBackgroundCalling,
  styleBackgroundActualYardOut,
  styleBackgroundTrailerDriver,
  styleBackgroundDN,
} from './styles';
import { calOffsetFromPage, createPayload, formatDownloadData } from './utils';
import usePrint from 'hooks/usePrint';

const LIMIT = dispatchBoardConstants.LIMIT;
const PAGE_SIZE = dispatchBoardConstants.PAGE_SIZE;

const { confirm } = Modal;
const TableComponent = (props) => {
  const {
    setTotalRows,
    totalRows,
    numberCallingComplete,
    setNumberCallingComplete,
  } = props;

  const { fromUrl, clusterUrl, trailer_or_driver } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const printDNComponentRef = useRef();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [showDiagramPlanDetail, setShowDiagramPlanDetail] = useState(false);
  const selectedRowRef = useRef();
  const [showFilter, setShowFilter] = useState(true);

  // Download state
  const isDownloadingRef = useRef(false);
  const [downloadVisible, setDownloadVisible] = useState(false);
  const [totalDownloadRows, setTotalDownloadRows] = useState(0);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadCount, setDownloadCount] = useState(0);

  // Redux Store
  const storedPage = useSelector((state) => {
    return state.dispatchBoard.currentPage;
  });
  const storedDataSource = useSelector((state) => {
    return state.dispatchBoard.dataSource;
  });
  const storedCallingCompleteCount = useSelector((state) => {
    return state.dispatchBoard.callingCompleteCount;
  });
  // Store filters
  const storedFilters = useSelector((state) => {
    return state.dispatchBoard.filters;
  });

  const [dataSource, setDataSource] = useState(storedDataSource || []);
  const [page, setPage] = useState(storedPage || 1);
  const [tableHeight, setTableHeight] = useState(0);
  const [callingCompleteCount, setCallingCompleteCount] = useState(
    storedCallingCompleteCount || null
  );
  const storedSummary = useSelector((state) => {
    return state.dispatchBoard.summary;
  });

  const storedWaitInputTRDVCount = useSelector((state) => {
    return state.dispatchBoard.WaitInputTRDVCount;
  });

  const [diagramTypeOptions, setDiagramTypeOptions] = useState(
    storedFilters && storedFilters.diagramTypeOptions
      ? storedFilters.diagramTypeOptions
      : []
  );
  const [jobCodeOptions, setJobCodeOptions] = useState(
    storedFilters && storedFilters.jobCodeOptions
      ? storedFilters.jobCodeOptions
      : []
  );

  const [filterOptionFrom, setFilterOptionFrom] = useState(
    storedFilters && storedFilters.filterOptionFrom
      ? storedFilters.filterOptionFrom
      : []
  );

  const [summary, setSummary] = useState(storedSummary || null);
  const [waitInputTRDVCount, setWaitInputTRDVCount] = useState(
    storedWaitInputTRDVCount || null
  );
  //parameter
  // view list
  const [fpViewcompany, setFpViewcompany] = useState(1);
  const [fpViewDiagramOwner, setFpViewDiagramOwner] = useState(1);
  // permision list
  const [fpCompany, setFpCompany] = useState(1);
  const [fpDiagramOwner, setFpDiagramOwner] = useState(1);
  const [fpSearchfilterpart, setFpSearchfilterpart] = useState(1);
  const [fpAutobackefault, setFpAutobackdefault] = useState(1);
  const [fpDownload, setFpdownload] = useState(1);
  const [fpBackdefault, setFpBackdefault] = useState(1);
  const [fpCreateTrip, setFpCreateTrip] = useState(1);
  const [fpCallingComplete, setFpCallingComplete] = useState(3);

  // permision right click
  const [fpseeWaitinginputtrailer, setFpSeeWaitinginputtrailer] = useState(1);
  const [fpConfrimTrailerDriver, setFpConfrimTrailerDriver] = useState(1);
  const [fpViewCalling, setFpviewCalling] = useState(1);
  const [fpEditPlan, setFpEditPlan] = useState(1);
  const [fpStatusChange, setFpStatusChange] = useState(1);
  const [fpCancelMatchingCalling, setFpCancelMatchingCalling] = useState(1);
  const [fpCancelMatchingTRDV, setFpCancelMatchingTRDV] = useState(1);
  const [fpCancelDiagram, setFpCancelDiagram] = useState(1);
  const [fpPrintDN, setFpPrintDN] = useState(1);

  const [dnDataList, setDnDataList] = useState([]);
  const [formData, setFormData] = useState(null);

  const [waitInputTrDv, setWaitInputTrDv] = useState(false);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);

  const { height } = useWindowSize();
  const payloadRef = useRef(null);
  const criteriaDataRef = useRef([]);

  const [isModalVisibleCancelPlan, setIsModalVisibleCancelPlan] =
    useState(false);

  const filterCompany = storedFilters?.filterCompany || [];

  const filterDatetime = storedFilters?.filterDatetime || {
    toTime: moment(),
    fromTime: moment().subtract(
      dispatchBoardConstants.LIMIT_SEARCH_DAYS,
      'days'
    ),
  };

  const filterFromYards = storedFilters?.filterFromYards || ['All'];

  const filterJobCode = storedFilters?.filterJobCode || [];

  const filterStatus = storedFilters?.filterStatus || ['Yard out not complete'];

  const filterDiagramType = storedFilters?.filterDiagramType || ['All'];

  const filterDiagramOwner = storedFilters?.filterDiagramOwner || [];

  const filterCalling = storedFilters?.filterCalling;

  const [printData, print] = usePrint('DNList', dnDataList);

  const executeQuery = useCallback(
    async (payload, forceRefetch) => {
      setIsModalVisibleCancelPlan(false);

      //Remove limit and offset when query from status summary
      if (['Match', 'TR_DV', 'Diagram'].includes(trailer_or_driver)) {
        if (
          (fromUrl && clusterUrl && trailer_or_driver === 'Match') ||
          (fromUrl && trailer_or_driver === 'Match')
        ) {
          payload['callingsheet__isnull'] = 'False';
        } else if (
          (fromUrl && clusterUrl && trailer_or_driver === 'TR_DV') ||
          (fromUrl && trailer_or_driver === 'TR_DV')
        ) {
          payload['callingsheet__isnull'] = 'False';
          payload['trailer_or_driver'] = 'True';
        } else if (
          (fromUrl && clusterUrl && trailer_or_driver === 'Diagram') ||
          (fromUrl && trailer_or_driver === 'Diagram')
        ) {
          payload['callingsheet__isnull'] = 'False';
          payload['trailer_or_driver'] = 'False';
        }
      }

      let newData = null;
      let totalRows = 0;
      try {
        setIsLoading(true);
        let result = await dispatch(
          dispatchBoardApi.endpoints.findAllDispatchBoardsNewByDatetime.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let rowNo = 1;
            let resData = {};
            if (result?.data?.results) {
              rowNo = payload.offset + 1;
              resData = result.data.results.map((d, i) => ({
                id: i + rowNo,
                ...d,
              }));
              // Case no limit and offset, response object does not have results
            } else if (result?.data) {
              resData = result.data.map((d, i) => ({
                id: i + rowNo,
                ...d,
              }));
            }
            // for Criteria
            let dataCriteria = [
              {
                code: 101,
                item: '[Calling] Recommend Threshold (min)',
                max: 120,
                min: 0,
                updated_by: null,
                value: 120,
              },
            ];
            if (criteriaDataRef.current) {
              dataCriteria = criteriaDataRef.current;
            }

            let listCriteria = dataCriteria
              .filter(
                (p) =>
                  p?.code === 101 ||
                  p?.code === 102 ||
                  p?.code === 103 ||
                  p?.code === 104 ||
                  p?.code === 105 ||
                  p?.code === 106 ||
                  p?.code === 107 ||
                  p?.code === 108 ||
                  p?.code === 109 ||
                  p?.code === 110 ||
                  p?.code === 111
              )
              .map((d) => ({
                code: d?.code ? d?.code : 101,
                min: d?.min ? d?.min : 0,
                value: d?.value ? d?.value : 120,
                item: d?.item
                  ? d?.item
                  : '[Dispatch Board] Receive Job Summary, Calling-Warning (min)',
              }));

            for (const element of resData) {
              element.listCriteria = listCriteria;
            }

            newData = resData;

            // if (
            //   (fromUrl && clusterUrl && trailer_or_driver === 'Match') ||
            //   (fromUrl && trailer_or_driver === 'Match')
            // ) {
            //   let dataUrl = newData.filter(
            //     (n) =>
            //       n.callingsheet === true ||
            //       (n.callingsheet === true && n.trailer_or_driver === true)
            //   );
            //   setDataSource(dataUrl);
            //   setTotalRows(dataUrl.length);
            // } else if (
            //   (fromUrl && clusterUrl && trailer_or_driver === 'TR_DV') ||
            //   (fromUrl && trailer_or_driver === 'TR_DV')
            // ) {
            //   let dataUrl = newData.filter(
            //     (n) => n.callingsheet === true && n.trailer_or_driver === true
            //   );

            //   setDataSource(dataUrl);
            //   setTotalRows(dataUrl.length);
            // } else if (
            //   (fromUrl && clusterUrl && trailer_or_driver === 'Diagram') ||
            //   (fromUrl && trailer_or_driver === 'Diagram')
            // ) {
            //   let dataUrl = newData.filter(
            //     (n) => n.callingsheet === true && n.trailer_or_driver !== true
            //   );
            //   setDataSource(dataUrl);
            //   setTotalRows(dataUrl.length);
            // } else {
            //   setTotalRows(result.data.count);
            //   setDataSource(newData);
            // }

            totalRows = result?.data?.count || 0;

            setTotalRows(totalRows);
            setDataSource(newData);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        setPage(1);
        setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [
      clusterUrl,
      dispatch,
      fromUrl,
      setErrorData,
      setTotalRows,
      trailer_or_driver,
    ]
  );

  // Query Form Diagram Type
  const executeQuerySelectionFormDiagram = useCallback(
    async (payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      setIsModalVisibleCancelPlan(false);
      try {
        // setIsLoading(true);
        let result = await dispatch(
          dispatchBoardApi.endpoints.getDataSelectionFromDiagram.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          // setErrorData(result.error);
        } else {
          if (result.data) {
            let arrayOptionLocaltionFroms = [];
            let arrayOptionDiagramType = [];
            let arrayOptionJobCodes = [];

            let result_froms = result.data.localtion_froms;
            let result_diagram_type = result.data.diagram_types;
            let result_job_codes = result.data.job_codes;

            let defaultAll = {
              label: 'All',
              value: 'All',
            };
            arrayOptionLocaltionFroms.push(defaultAll);
            arrayOptionDiagramType.push(defaultAll);
            // set format data location from
            for (let index = 0; index < result_froms?.length; index++) {
              arrayOptionLocaltionFroms.push({
                label: result_froms[index].short_name,
                value: result_froms[index].code,
              });
            }
            // set format data Diagram type
            for (let index = 0; index < result_diagram_type?.length; index++) {
              arrayOptionDiagramType.push({
                label: result_diagram_type[index].name,
                value: result_diagram_type[index].name,
              });
            }
            // set format data job_codes
            for (let index = 0; index < result_job_codes?.length; index++) {
              arrayOptionJobCodes.push({
                label: result_job_codes[index],
                value: result_job_codes[index],
              });
            }
            setJobCodeOptions(arrayOptionJobCodes);
            setDiagramTypeOptions(arrayOptionDiagramType);
            setFilterOptionFrom(arrayOptionLocaltionFroms);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        // setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch]
  );

  // Query JobCode
  const executeQuerySelectionFormDiagramForJobCode = useCallback(
    async (payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      setIsModalVisibleCancelPlan(false);
      try {
        // setIsLoading(true);
        let result = await dispatch(
          dispatchBoardApi.endpoints.getDataSelectionFromDiagram.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          //setErrorData(result.error);
        } else {
          if (result.data) {
            let arrayOptionJobCodes = [];

            let result_job_codes = result.data.job_codes;

            // set format data job_codes
            for (let index = 0; index < result_job_codes?.length; index++) {
              arrayOptionJobCodes.push({
                label: result_job_codes[index],
                value: result_job_codes[index],
              });
            }
            setJobCodeOptions(arrayOptionJobCodes);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        // setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch]
  );

  // Query summary
  const executeQuerySummary = useCallback(
    async (payload, forceRefetch) => {
      let newData = null;
      let totalRows = null;
      setIsModalVisibleCancelPlan(false);
      try {
        // setIsLoading(true);
        let result = await dispatch(
          dispatchBoardApi.endpoints.findSummaryDispatchBoardsNewByDatetime.initiate(
            payload,
            { forceRefetch: forceRefetch ? true : false }
          )
        );

        if (result && result.error) {
          //setErrorData(result.error);
        } else {
          if (result.data) {
            let resultsummary = result.data;
            setCallingCompleteCount(resultsummary.calling_complete);
            setWaitInputTRDVCount(resultsummary.wait_input_tr_dv);

            let obj = {
              in_yard: resultsummary.in_yard,
              out_yard: resultsummary.out_yard,
              receive_job: resultsummary.receive_job,
            };
            setSummary(obj);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
        // setIsLoading(false);
        return { data: newData, totalRows: totalRows };
      }
    },
    [dispatch]
  );

  const onQuery = useCallback(
    async (payload, reqPage) => {
      if (!isLoading) {
        // Fetch more data
        let curOffset = calOffsetFromPage(reqPage || page);
        payloadRef.current = { ...payload, limit: LIMIT, offset: curOffset };

        await executeQuery(
          {
            ...payloadRef.current,
          },
          true
        );

        // Below queries are using payload object which does not have LIMIT and OFFSET params
        await executeQuerySummary(
          {
            ...payload,
          },
          true
        );

        await executeQuerySelectionFormDiagram(
          {
            ...payload,
          },
          true
        );
      }
    },
    [
      executeQuery,
      executeQuerySelectionFormDiagram,
      executeQuerySummary,
      isLoading,
      page,
    ]
  );

  useEffect(() => {
    (async () => {
      try {
        let result = await dispatch(
          dispatchBoardApi.endpoints.getPermissionGroup.initiate()
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          let data = result.data ? result.data?.privilege : [];
          for (const item in data) {
            if (Object.hasOwnProperty.call(data, item)) {
              const element = data[item];

              if (element.subject === 'view_company') {
                setFpViewcompany(element.actions);
              } else if (element.subject === 'view_diagram_owner') {
                setFpViewDiagramOwner(element.actions);
              }
              //-----------------------------------------------------
              else if (element.subject === 'fp_filter_company') {
                setFpCompany(element.actions);
              } else if (element.subject === 'fp_filter_diagram_owner') {
                setFpDiagramOwner(element.actions);
              } else if (element.subject === 'fp_search_filter_part') {
                setFpSearchfilterpart(element.actions);
              } else if (element.subject === 'fp_auto_back_default') {
                setFpAutobackdefault(element.actions);
              } else if (element.subject === 'fp_download') {
                setFpdownload(element.actions);
              } else if (element.subject === 'fp_back_default') {
                setFpBackdefault(element.actions);
              } else if (element.subject === 'fp_create_trip') {
                setFpCreateTrip(element.actions);
              } else if (element.subject === 'fp_calling_complete') {
                setFpCallingComplete(element.actions);
              }
              //-----------------------------------------------------
              else if (
                element.subject === 'fp_see_and_click_filter_tr_and_dr'
              ) {
                setFpSeeWaitinginputtrailer(element.actions);
              } else if (element.subject === 'fp_confirm_tr_or_dr') {
                setFpConfrimTrailerDriver(element.actions);
              } else if (element.subject === 'fp_view_calling') {
                setFpviewCalling(element.actions);
              } else if (element.subject === 'fp_edit_plan') {
                setFpEditPlan(element.actions);
              } else if (element.subject === 'fp_status_change') {
                setFpStatusChange(element.actions);
              } else if (
                element.subject === 'fp_cancel_matching_calling_and_diagram'
              ) {
                setFpCancelMatchingCalling(element.actions);
              } else if (element.subject === 'fp_cancel_matching_tr_and_dr') {
                setFpCancelMatchingTRDV(element.actions);
              } else if (element.subject === 'fp_cancel_diagram') {
                setFpCancelDiagram(element.actions);
              } else if (element.subject === 'fp_print_dn') {
                setFpPrintDN(element.actions);
              }
            }
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      }
    })();
  }, [dispatch, setErrorData]);

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (!showFilter) {
        h = h + 150;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  useEffect(() => {
    dispatch(setCurrentPage(page));
  }, [page, dispatch]);

  useEffect(() => {
    dispatch(setDispatchBoardDataSource(dataSource));
  }, [dataSource, dispatch]);

  useEffect(() => {
    if (callingCompleteCount) {
      dispatch(setDispatchBoardCallingCompleteCount(callingCompleteCount));
    }
  }, [callingCompleteCount, dispatch]);

  useEffect(() => {
    if (summary) {
      dispatch(setDispatchBoardSummary(summary));
    }
  }, [summary, dispatch]);

  useEffect(() => {
    if (waitInputTRDVCount) {
      dispatch(setDispatchBoardWaitInputTRDVCount(waitInputTRDVCount));
    }
  }, [waitInputTRDVCount, dispatch]);

  const onUpload = async (files) => {
    try {
      setIsUploading(true);

      let result = await dispatch(
        dispatchBoardApi.endpoints.uploadDiagramPlan.initiate(
          {
            file: files,
            utcOffset: moment().utcOffset(),
          },
          { forceRefetch: true }
        )
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        message.success('Data was successfully uploaded');
        onQuery(payloadRef.current);
      }
    } catch (err) {
      message.error('Something went wrong.');
    } finally {
      setIsUploading(false);
    }
  };

  const [cancelManageCalling] = useCancelMatchCallingMutation();
  const [cancelMatchTrailerDriver] = useCancelMatchTrailerDriverMutation();

  const getAllCriteria = useCallback(async () => {
    try {
      let result = await dispatch(
        criteriaApi.endpoints.findAllCriteria.initiate()
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          criteriaDataRef.current = result.data;
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  }, [dispatch, setErrorData]);

  const getDataPrintDN = async (calling_no) => {
    try {
      let result = await dispatch(
        manageCallingApi.endpoints.getPrintDN.initiate(
          {
            calling_no: calling_no,
          },
          { forceRefetch: true }
        )
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          let arrData = [];
          arrData = result.data;
          let dataDN = [];

          for (let dn of arrData) {
            let dataVin = dn.vins.map((d, index) => ({
              index: index + 1,
              assigned_date: d.assigned_date,
              color: d.color,
              destination: d.destination,
              engine: d.engine,
              id: d.id,
              lot_no_cbu: d.lot_no_cbu,
              model_code: d.model_code,
              series: d.series,
              upload_date: d.upload_date,
              vin_no: d.vin_no,
              suffix: d?.suffix ? d?.suffix : '',
            }));

            if (dataVin.length !== 11) {
              let empty = {
                index: '-',
                assigned_date: '',
                color: '',
                destination: '',
                engine: '',
                id: '',
                lot_no_cbu: null,
                model_code: '',
                series: '',
                upload_date: '',
                vin_no: '',
                suffix: ''
              };

              let number_loop = 9 - dataVin.length;

              for (let i = 0; i < number_loop; i++) {
                dataVin.push(empty);
              }
            }
            dataDN.push({
              callingsheet: dn.callingsheet,
              customer: dn.customer,
              datetime_now: dn.datetime_now,
              delivery_note: dn.delivery_note,
              location_destination: dn.location_destination,
              location_from: dn.location_from,
              location_to: dn.location_to,
              user_login_print: dn.user_login_print,
              vins: dataVin,
            });
          }

          setDnDataList(dataDN);
          handlePrintDN();
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    }
  };
  const [postReasonMatchPlan] = useCancelMatchPlanMutation();

  const onCancelMatchPlanWithReason = async (payload) => {
    let response = await postReasonMatchPlan(payload);
    if (response.error) {
      setErrorData(response.error);
    }
    if (response.data) {
      onQuery({
        ...createPayload(dispatch, {
          filterCompany,
          filterDiagramOwner,
          filterStatus,
          filterCalling,
          filterJobCode,
          filterFromYards,
          filterDiagramType,
          filterDatetime,
        }),
        mode: 'display',
      });

      setIsModalVisibleCancelPlan(false);

      message.success('Cancel Plan success !!');
    }
  };

  const onInit = useCallback(
    async (payload) => {
      await getAllCriteria();
      setIsModalVisibleCancelPlan(false);

      let queryPayload = { ...payload, limit: LIMIT, offset: 0 };
      let queryPayloadSelected = { ...payload };

      if (page > 1) {
        let offset = calOffsetFromPage(page);
        queryPayload = { ...payload, limit: LIMIT, offset: offset };
      }

      payloadRef.current = queryPayload;

      setDataSource([]);

      executeQuery(queryPayload, true);

      executeQuerySelectionFormDiagram(queryPayloadSelected, true);
      executeQuerySummary(queryPayloadSelected, true);
      executeQuerySelectionFormDiagramForJobCode(queryPayloadSelected, true);
    },
    [
      getAllCriteria,
      page,
      executeQuery,
      executeQuerySelectionFormDiagram,
      executeQuerySummary,
      executeQuerySelectionFormDiagramForJobCode,
    ]
  );

  const onInitForJobCode = useCallback(
    (payload) => {
      let queryPayloadSelected = { ...payload };

      executeQuerySelectionFormDiagramForJobCode(queryPayloadSelected);
    },
    [executeQuerySelectionFormDiagramForJobCode]
  );

  const onAdd = () => {
    window.open(`/app/delivery_mng/create_trip`, '_blank');
  };

  const executeDownload = async (curOffset, curData, payload) => {
    let newData = null;
    let totalRows = null;
    try {
      setIsLoading(true);
      let result = await dispatch(
        dispatchBoardApi.endpoints.findAllDispatchBoardsNewByDatetime.initiate(
          payload,
          { forceRefetch: true }
        )
      );
      if (result && result.error) {
        setErrorData(result.error);
      } else {
        if (result.data) {
          let rowNo = curData?.length + 1;
          let resData = result.data.results.map((d, i) => ({
            id: i + rowNo,
            ...d,
          }));

          newData = curData.concat(resData);
          totalRows = result.data.count;
        }
      }
    } catch (err) {
      message.error('Something went wrong.');
    } finally {
      setIsLoading(false);
    }
    return {
      totalRows: totalRows,
      offset: curOffset + LIMIT,
      rowData: newData,
    };
  };

  const onDownload = async (payload) => {
    let curOffset = 0;
    let bufferData = [];
    let total = 0;

    setDownloadVisible(true);
    isDownloadingRef.current = true;

    setTotalDownloadRows(0);
    setDownloadProgress(0);
    setDownloadCount(0);
    try {
      do {
        let queryPayload = {
          ...payload,
          mode: 'download',
          limit: LIMIT,
          offset: curOffset,
        };
        let result = await executeDownload(curOffset, bufferData, queryPayload);
        if (result.totalRows !== null) {
          total = result.totalRows;
          let count = result.rowData?.length;

          setTotalDownloadRows(total);
          setDownloadProgress(parseInt((count / total) * 100));
          setDownloadCount(count);

          // Update
          bufferData = result.rowData;
          curOffset = result.offset;
        } else {
          break;
        }
      } while (total > bufferData?.length && isDownloadingRef.current === true);
      // Cancel download
      if (isDownloadingRef.current === false) {
        return;
      }
      if (total === 0) {
        setDownloadProgress(100);
        message.info('Downloaded data is empty');
        return;
      }

      if (total && total === bufferData?.length) {
        bufferData = formatDownloadData(bufferData, criteriaDataRef.current);
        Json2CSV(
          bufferData,
          `dm_operation_${moment().format('YYYYMMDD_HHmmss')}.csv`,
          ','
        );
      } else {
        message.error('Something when wrong, please try again');
      }
    } catch (error) {
      message.error('Something when wrong');
    } finally {
      setTimeout(() => {
        isDownloadingRef.current = false;
        setDownloadVisible(false);
      }, 1000);
    }
  };

  const handleDownloadCancel = () => {
    setDownloadVisible(false);
    isDownloadingRef.current = false;
  };

  //data Actual Receive Job  for table
  function dataActualReceiveJob(record) {
    try {
      //criteria for symbol
      let criteria_symbol = record.listCriteria[6];
      let min_symbol = criteria_symbol?.min ? criteria_symbol.min : 0;
      let value_symbol = criteria_symbol?.value ? criteria_symbol.value : 120;

      let re_job_time = record?.receive_job_datetime
        ? moment(record.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
        : null;
      let actual_re_job_time = record?.actual_receive_job_datetime
        ? moment(record.actual_receive_job_datetime).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        : null;
      let dateNow = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

      if (
        record?.callingsheet === false ||
        record?.trailer_or_driver === false
      ) {
        return;
      }

      if (actual_re_job_time != null) {
        if (
          moment(actual_re_job_time).diff(moment(re_job_time), 'minutes') >=
          min_symbol
        ) {
          return dateTimeNotYearTimestamptzFormatter({
            value: actual_re_job_time,
          });
        } else {
          return dateTimeNotYearTimestamptzFormatter({
            value: actual_re_job_time,
          });
        }
      } else {
        if (
          moment(dateNow).diff(moment(re_job_time), 'minutes') > value_symbol
        ) {
          return <ExclamationCircleOutlined twoToneColor='#FA5050' />;
        } else {
          return '';
        }
      }
    } catch (error) {
      message.error('Something went wrong.');
    }
  }

  //data Actual YardOut  for table
  function dataYardOut(record) {
    try {
      if (!record) return;
      //criteria for symbol
      let criteria_symbol = record.listCriteria[8];
      let value_symbol = criteria_symbol?.value ? criteria_symbol.value : 120;

      let actual_yardout_datetime = record?.actual_yardout_datetime;

      let yardout_datetime = record?.yardout_datetime;

      let dateNow = moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

      if (actual_yardout_datetime) {
        return dateTimeNotYearTimestamptzFormatter({
          value: actual_yardout_datetime,
        });
      } else {
        if (
          moment(dateNow).diff(moment(yardout_datetime), 'minutes') >
          value_symbol
        ) {
          return <ExclamationCircleOutlined twoToneColor='#FA5050' />;
        } else {
          return '';
        }
      }
    } catch (error) {
      message.error('Something went wrong.');
    }
  }

  //data DN for table
  function dataDN(record) {
    try {
      let value_dn = record.d_or_n;

      if (value_dn) {
        return <CheckOutlined style={{ fontSize: '15px' }} />;
      } else {
        return '';
      }
    } catch (error) {
      message.error('Something went wrong.');
    }
  }

  const columnsPopupCalling = [
    {
      title: 'No.',
      dataIndex: 'no',
      width: 50,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      width: 150,
      align: 'center',
      render: (text) => {
        return text;
      },
    },
  ];

  const contentPopupCalling = (value) => {
    let resData = [];
    if (value?.calling_destinations.length !== 0) {
      resData =
        value &&
        value?.calling_destinations?.map((d, i) => ({
          no: i + 1,
          destination: d,
        }));
    }

    try {
      return (
        <>
          <h4>Calling no. : {value.callingsheet_no}</h4>
          <AntTable
            rowKey='id'
            tableId={'calling_no_combine'}
            showSorterTooltip={false}
            columns={columnsPopupCalling}
            dataSource={resData}
            pagination={false}
          />
        </>
      );
    } catch (err) {}
  };

  const columnsPopupTrailerDriver = [
    {
      title: 'Driver',
      dataIndex: 'no',

      align: 'center',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      width: '200',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',

      align: 'center',
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',

      align: 'center',
    },
  ];
  const contentPopupTrailerDriver = (value) => {
    let resData =
      value &&
      value.trailer_or_driver &&
      value.drivers_info.map((d, i) => ({
        no: i + 1,
        code: d.driver_code,
        name: d.driver_name?.fullname_th,
        last_name: d.driver_name?.surname_th,
      }));
    try {
      return (
        <>
          <h3>Trailer : {value.trailer_info?.trailer_card_id}</h3>
          <StyledAntTableTRDV
            rowKey='id'
            tableId={'trailer_or_driver'}
            showSorterTooltip={false}
            columns={columnsPopupTrailerDriver}
            dataSource={resData}
            pagination={false}
          />
        </>
      );
    } catch (err) {}
  };

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Job Code',
        dataIndex: 'job_code',
        width: 30,
        align: 'center',
        render: (text) => {
          return <div id='job_code'>{text}</div>;
        },
      },

      {
        title: 'Trip',
        dataIndex: 'total_trip_no',
        width: 30,
        align: 'center',
        render: (text) => {
          return <div id='total_trip_no'>{text}</div>;
        },
      },
      {
        title: 'Working date',
        dataIndex: 'working_day',
        render(text) {
          return {
            children: (
              <div>{dateYearWorkingDate({ value: text ? text : '' })}</div>
            ),
          };
        },
        width: 70,
        align: 'center',
      },

      {
        title: 'Receive job time',
        dataIndex: 'receive_job_datetime',
        render(text, record) {
          return {
            props: {
              style: styleBackgroundReceiveJobTime(record),
            },

            children: (
              <div id='receive_job_datetime'>
                {dateTimeNotYearTimestamptzFormatter({
                  value: text ? text : '',
                })}
              </div>
            ),
          };
        },
        width: 75,
        align: 'center',
      },
      {
        title: 'Estimate Lot Time',
        dataIndex: '',
        render(text) {
          return text;
        },
        width: 50,
        align: 'center',
      },
      {
        title: 'From',
        dataIndex: 'actual_from',
        width: 60,
        render: (text) => {
          return <div id='actual_from'>{text ? text.short_name : '-'}</div>;
        },
        align: 'center',
      },
      {
        title: 'Destination',
        dataIndex: 'actual_cluster_to',
        width: 40,
        render: (text, record) => {
          return {
            children: (
              <div id='actual_cluster_to' style={styeDestination(record)}>
                {fomattingDestination(record)}
              </div>
            ),
          };
        },
        align: 'center',
      },

      {
        title: 'Actual Receive Job',
        dataIndex: 'actual_receive_job_datetime',
        render(_, record) {
          return {
            props: {
              style: styleBackgroundActualReceiveJob(record),
            },
            children: (
              <div id='actual_receive_job_datetime'>
                {dataActualReceiveJob(record)}
              </div>
            ),
          };
        },
        width: 70,
        align: 'center',
      },
      {
        title: 'Actual Yard Out',
        dataIndex: 'actual_yardout_datetime',
        render(_, record) {
          return {
            props: {
              style: styleBackgroundActualYardOut(record),
            },
            children: (
              <div id='actual_yardout_datetime'>{dataYardOut(record)}</div>
            ),
          };
        },
        width: 70,
        align: 'center',
      },
      {
        title: 'Return Place',
        dataIndex: 'actual_return_place',
        width: 60,
        render: (text, record) => {
          return {
            children: (
              <div
                id='actual_return_place'
                style={
                  record?.trip_no_fraction !== true
                    ? { fontWeight: 'normal' }
                    : { fontWeight: 'bold' }
                }
              >
                {text ? text : ''}
              </div>
            ),
          };
        },
        align: 'center',
      },
      {
        title: 'Trip Type',
        dataIndex: ['trip_type', 'name_en'],
        width: 50,

        align: 'center',
      },
      {
        title: 'Combine code',
        dataIndex: 'combine',
        width: 10,
        align: 'center',
      },
      {
        title: 'Loading Unit',
        dataIndex: 'actual_loading_unit',
        width: 50,
        align: 'center',
        render: (text) => {
          return <div>{text ? text : ''}</div>;
        },
      },
      {
        title: (
          <>
            {' '}
            Calling{' '}
            <InfoCircleOutlined
              style={{ paddingBottom: '2px', fontSize: '10px' }}
            />
          </>
        ),
        dataIndex: 'callingsheet',
        align: 'center',
        width: 50,
        render: (text, record) => {
          return {
            props: {
              style: styleBackgroundCalling(record),
            },
            children: (
              <div id='callingsheet'>
                {text === true ? (
                  <Popover
                    content={contentPopupCalling(record)}
                    placement='bottom'
                  >
                    <CheckOutlined style={{ fontSize: '15px' }} />
                  </Popover>
                ) : (
                  ''
                )}
              </div>
            ),
          };
        },
      },
      {
        title: (
          <>
            {' '}
            Trailer/<br />
            Driver{' '}
            <InfoCircleOutlined
              style={{ paddingBottom: '2px', fontSize: '10px' }}
            />
          </>
        ),
        dataIndex: 'trailer_or_driver',
        render: (text, record) => {
          return {
            props: {
              style: styleBackgroundTrailerDriver(record),
            },
            children: (
              <div id='trailer_or_driver'>
                {text === true ? (
                  <Popover
                    content={contentPopupTrailerDriver(record)}
                    placement='bottom'
                  >
                    <CheckOutlined style={{ fontSize: '15px' }} />
                  </Popover>
                ) : (
                  ''
                )}
              </div>
            ),
          };
        },
        width: 50,
        align: 'center',
      },
      {
        title: 'D/N',
        dataIndex: 'd_or_n',
        render: (text, record) => {
          return {
            props: {
              style: styleBackgroundDN(record),
            },
            children: <div id='d_or_n'>{dataDN(record)}</div>,
          };
        },
        width: 50,
        align: 'center',
      },
      {
        title: 'Arrival',
        dataIndex: 'arrival',
        render: (text, record) => {
          // console.log(record);
          if (
            (record.tracking_status === 130 && record.arrival === null) ||
            (record.arrival !== null &&
              record.tracking_eva === 'delay' &&
              record.reason_arrival === null)
          ) {
            return <ExclamationCircleTwoTone twoToneColor='#FA5050' />;
          } else {
            return (
              <div id='arrival'>
                {text
                  ? dateTimeNotYearTimestamptzFormatter({ value: text })
                  : ''}
              </div>
            );
          }
        },
        width: 50,
        align: 'center',
      },
    ];

    return cols;
  });
  function showConfirmMatchingCalling(row) {
    confirm({
      title: 'Do you Want to cancel Matching Calling ?',
      icon: <ExclamationCircleOutlined />,
      content: `Matching Calling : ${row.callingsheet_no}`,
      onOk() {
        (async () => {
          try {
            setIsLoading(true);
            let response = await cancelManageCalling(row.callingsheet_id);
            if (response.error) {
              return setErrorData(response.error);
            }

            if (response.data) {
              confirm({
                title: 'ยกเลิก Match Calling เรียบร้อย',
                icon: <CheckCircleOutlined />,
              });

              onQuery({
                ...createPayload(dispatch, {
                  filterCompany,
                  filterDiagramOwner,
                  filterStatus,
                  filterCalling,
                  filterJobCode,
                  filterFromYards,
                  filterDiagramType,
                  filterDatetime,
                }),
                mode: 'display',
              });
            }
          } catch (error) {
          } finally {
            setIsLoading(false);
          }
        })();
      },
      onCancel() {},
    });
  }
  function showCancelMatchTrailerDriver(row) {
    let resData =
      row &&
      row.drivers_info.map((d, i) => ({
        no: i + 1,
        code: d.driver_code,
        name: d.driver_name?.fullname_th,
        last_name: d.driver_name?.surname_th,
      }));
    const displayContent = (
      <>
        <h3>Trailer : {row.trailer_info?.trailer_card_id}</h3>
        <Table
          rowKey='id'
          showSorterTooltip={false}
          columns={columnsPopupTrailerDriver}
          dataSource={resData}
          pagination={false}
        />
      </>
    );
    confirm({
      title: 'Do you Want to Cancel Match Trailer & Driver ?',
      icon: <ExclamationCircleOutlined />,
      width: 600,
      content: displayContent,

      onOk() {
        (async () => {
          try {
            setIsLoading(true);
            let result = await cancelMatchTrailerDriver(row.id);
            if (result && result.error) {
              setErrorData(result.error);
            } else {
              if (result.data) {
                confirm({
                  title: 'ยกเลิก Match Trailer Driver เรียบร้อย',
                  icon: <CheckCircleOutlined />,
                });

                onQuery({
                  ...createPayload(dispatch, {
                    filterCompany,
                    filterDiagramOwner,
                    filterStatus,
                    filterCalling,
                    filterJobCode,
                    filterFromYards,
                    filterDiagramType,
                    filterDatetime,
                  }),
                  mode: 'display',
                });
              }
            }
          } catch (err) {
          } finally {
            setIsLoading(false);
          }
        })();
      },
      onCancel() {},
    });
  }

  const handlePrintDN = () => {
    setTimeout(() => {
      print(printDNComponentRef.current, 'print-dn');
    }, 500);
  };

  function showConfirmDiagram(row) {
    confirm({
      title: 'Do you Want to cancel Diagram ?',
      icon: <ExclamationCircleOutlined />,
      content: `Job code: ${row.job_code}`,
      onOk() {
        setFormData(row);
        setIsModalVisibleCancelPlan(true);
      },
      onCancel() {},
    });
  }

  return (
    <Container id='dispatch_board'>
      <TableToolbar
        fromUrl={fromUrl}
        clusterUrl={clusterUrl}
        trailer_or_driver={trailer_or_driver}
        onInit={onInit}
        onQuery={onQuery}
        onAdd={onAdd}
        onDownload={onDownload}
        dataSource={dataSource}
        setDataSource={setDataSource}
        onUpload={onUpload}
        isLoading={isLoading}
        isUploading={isUploading}
        isDownloading={isDownloadingRef.current}
        showAdd={permission.checkPermission(user, 'dispatch_board', ['c'])}
        columns={columns}
        setColumns={setColumns}
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        callingCompleteCount={callingCompleteCount}
        totalRows={totalRows}
        setPage={setPage}
        // new
        setIsLoading={setIsLoading}
        diagramTypeOptions={diagramTypeOptions}
        setDiagramTypeOptions={setDiagramTypeOptions}
        jobCodeOptions={jobCodeOptions}
        //setJobCodeOptions={setJobCodeOptions}
        filterOptionFrom={filterOptionFrom}
        setFilterOptionFrom={setFilterOptionFrom}
        summary={summary}
        setSummary={setSummary}
        setWaitInputTRDVCount={setWaitInputTRDVCount}
        waitInputTRDVCount={waitInputTRDVCount}
        fpCompany={fpCompany}
        fpDiagramOwner={fpDiagramOwner}
        fpCallingComplete={fpCallingComplete}
        fpCreateTrip={fpCreateTrip}
        fpSearchfilterpart={fpSearchfilterpart}
        setFpSearchfilterpart={setFpSearchfilterpart}
        fpAutobackefault={fpAutobackefault}
        setFpAutobackdefault={setFpAutobackdefault}
        fpDownload={fpDownload}
        setFpdownload={setFpdownload}
        fpBackdefault={fpBackdefault}
        setFpBackdefault={setFpBackdefault}
        numberCallingComplete={numberCallingComplete}
        setNumberCallingComplete={setNumberCallingComplete}
        //createPayload={createPayload}
        fpViewcompany={fpViewcompany}
        setFpViewcompany={setFpViewcompany}
        fpViewDiagramOwner={fpViewDiagramOwner}
        setFpViewDiagramOwner={setFpViewDiagramOwner}
        fpseeWaitinginputtrailer={fpseeWaitinginputtrailer}
        setFpSeeWaitinginputtrailer={setFpSeeWaitinginputtrailer}
        fpConfrimTrailerDriver={fpConfrimTrailerDriver}
        setFpConfrimTrailerDriver={setFpConfrimTrailerDriver}
        fpViewCalling={fpViewCalling}
        setFpviewCalling={setFpviewCalling}
        fpEditPlan={fpEditPlan}
        setFpEditPlan={setFpEditPlan}
        fpStatusChange={fpStatusChange}
        setFpStatusChange={setFpStatusChange}
        fpCancelMatchingCalling={fpCancelMatchingCalling}
        setFpCancelMatchingCalling={setFpCancelMatchingCalling}
        fpCancelMatchingTRDV={fpCancelMatchingTRDV}
        setFpCancelMatchingTRDV={setFpCancelMatchingTRDV}
        fpCancelDiagram={fpCancelDiagram}
        setFpCancelDiagram={setFpCancelDiagram}
        fpPrintDN={fpPrintDN}
        setFpPrintDN={setFpPrintDN}
        waitInputTrDv={waitInputTrDv}
        setWaitInputTrDv={setWaitInputTrDv}
        onInitForJobCode={onInitForJobCode}
      />
      <StyledAntTable
        rowKey='id'
        tableId={'dispatch_board'}
        className='scrollToTop'
        pagination={{
          pageSize: PAGE_SIZE,
          total: totalRows,
          current: page,
          size: 'small',
          showSizeChanger: false,
          showQuickJumper: true,
          onChange: async (_page, _) => {
            let curOffset = calOffsetFromPage(_page);
            await executeQuery(
              {
                ...payloadRef.current,
                limit: LIMIT,
                offset: curOffset,
              },
              true
            );
            setPage(_page);
          },
        }}
        height={tableHeight}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSource}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        // Below is for right click context menu
        showContextMenu={true}
        menuActions={[
          {
            label: 'Confirm Trailer/Driver',
            action: (row) => {
              if (fpConfrimTrailerDriver === 1) {
                if (row.callingsheet === true && row.d_or_n !== true) {
                  selectedRowRef.current = row;
                  dispatch(
                    push(
                      `/app/delivery_mng/lists/confirm_driver_trailer/${row.job_code}&${row.id}&${row.working_date}`
                    )
                  );
                } else {
                  if (
                    row?.actual_cluster_to?.diagram_type?.name_en ===
                    'Indochina'
                  ) {
                    selectedRowRef.current = row;
                    dispatch(
                      push(
                        `/app/delivery_mng/lists/confirm_driver_trailer/${row.job_code}&${row.id}&${row.working_date}`
                      )
                    );
                  } else if (row?.d_or_n === true) {
                    Modal.error({
                      title:
                        'ไม่สามารถเข้าหน้า Confirm Trailer/Driver ได้ เนื่องจากปริ้น DN แล้ว',
                    });
                  } else {
                    Modal.error({
                      title:
                        'ไม่สามารถเข้าหน้า Confirm Trailer/Driver ได้ เนื่องจากยังไม่ match calling.',
                    });
                  }
                }
              } else {
                Modal.error({
                  title: 'กรุณาตรวจสอบสิทธิ์การเข้าถึงข้อมูล',
                });
              }
            },
          },
          {
            label: 'Manage Trip',
            children: [
              {
                label: <div id='sub_manage_calling'>Manage Calling</div>,
                action: (row) => {
                  if (fpViewCalling === 1) {
                    if (row.callingsheet) {
                      selectedRowRef.current = row;
                      window.open(
                        `/app/delivery_mng/manage_calling/${row.callingsheet_no}`,
                        '_blank'
                      );
                    } else {
                      Modal.error({
                        title:
                          'ไม่สามารถเข้าหน้า Manage Calling ได้ เนื่องจากไม่พบ calling no.',
                      });
                    }
                  } else {
                    Modal.error({
                      title: 'กรุณาตรวจสอบสิทธิ์การเข้าถึงข้อมูล',
                    });
                  }
                },
              },
              fpEditPlan !== 3
                ? {
                    label: <div id='sub_create_trip'>Create Trip</div>,
                    action: (row) => {
                      if (fpEditPlan === 1) {
                        if (row.callingsheet !== true) {
                          selectedRowRef.current = row;
                          window.open(
                            `/app/delivery_mng/create_trip/${row.id}`,
                            '_blank'
                          );
                        } else {
                          confirm({
                            width: 500,
                            title:
                              'ไม่สามารถแก้ไข Trip ได้ เนื่องจาก matched calling แล้ว !!',
                            icon: <ExclamationCircleOutlined />,
                            content:
                              'หากต้องการ ไปยังหน้า Create Trip โปรด กดปุ่ม Ok',

                            onCancel() {},
                            onOk() {
                              window.open(
                                `/app/delivery_mng/create_trip/${row.id}`,
                                '_blank'
                              );
                            },
                          });
                        }
                      } else {
                        Modal.error({
                          title: 'กรุณาตรวจสอบสิทธิ์การเข้าถึงข้อมูล',
                        });
                      }
                    },
                  }
                : {},
              {
                label: <div id='sub_status_change'>Status change</div>,
                action: (row) => {
                  if (fpStatusChange === 1) {
                    selectedRowRef.current = row;
                    window.open(
                      `/app/delivery_mng/status_change/${row.id}`,
                      '_blank'
                    );
                  } else {
                    Modal.error({
                      title: 'กรุณาตรวจสอบสิทธิ์การเข้าถึงข้อมูล',
                    });
                  }
                },
              },
            ],
          },
          {
            label: 'Cancel',
            children: [
              fpCancelMatchingCalling !== 3
                ? {
                    label: (
                      <div id='sub_matching_calling'>Matching Calling</div>
                    ),
                    action: (row) => {
                      if (row.trailer_or_driver === true) {
                        Modal.error({
                          title:
                            'ไม่สามารถ cancel match calling ได้ เนื่องจากมีการ confirm TR/DV แล้ว',
                        });
                      } else if (row.actual_receive_job_datetime !== null) {
                        Modal.error({
                          title:
                            'ไม่สามารถ cancel match calling ได้ เนื่องจากมีการ receive job แล้ว',
                        });
                      } else if (row.callingsheet !== true) {
                        Modal.error({
                          title:
                            'ไม่สามารถ cancel match calling ได้ เนื่องจากยังไม่ได้ match Calling',
                        });
                      } else {
                        showConfirmMatchingCalling(row);
                      }
                    },
                  }
                : {},

              fpCancelMatchingTRDV !== 3
                ? {
                    label: <div id='sub_matching_tr_dv'>Matching TR/DV</div>,
                    action: (row) => {
                      if (row.actual_receive_job_datetime === null) {
                        if (row.trailer_or_driver === true) {
                          showCancelMatchTrailerDriver(row);
                        } else {
                          Modal.error({
                            title:
                              'ไม่สามารถเข้าหน้า Matching TR/DV ได้ เนื่องจากมีการ receive job ไปแล้ว',
                          });
                        }
                      } else {
                        Modal.error({
                          title:
                            'ไม่สามารถเข้าหน้า Matching TR/DV ได้ เนื่องจากมีการ receive job ไปแล้ว',
                        });
                      }
                    },
                  }
                : {},
              fpCancelDiagram !== 3
                ? {
                    label: <div id='sub_diagram'>Diagram</div>,

                    action: (row) => {
                      if (row.callingsheet === true) {
                        Modal.error({
                          title:
                            'ไม่สามารถ cancel diagram ได้ เนื่องจาก match Calling ไปแล้ว',
                        });
                      } else if (row.trailer_or_driver === true) {
                        Modal.error({
                          title:
                            'ไม่สามารถ cancel diagram ได้ เนื่องจาก confrim TR/DV ไปแล้ว',
                        });
                      } else {
                        showConfirmDiagram(row);
                      }
                    },
                  }
                : {},
            ],
          },
          {
            label: 'Print D/N',
            action: (row) => {
              if (fpPrintDN !== 3) {
                if (
                  row.callingsheet_no !== null &&
                  row.trailer_or_driver === true
                ) {
                  if (row.d_or_n === true) {
                    Modal.error({
                      title: "Please noted that you've print the D/N already",
                      content: "If you need to re-print, please go to 'DN list",
                    });
                  } else {
                    onQuery({
                      ...createPayload(dispatch, {
                        filterCompany,
                        filterDiagramOwner,
                        filterStatus,
                        filterFromYards,
                        filterDiagramType,
                        filterCalling,
                        filterJobCode,
                        filterDatetime,
                      }),
                      mode: 'display',
                    });
                    getDataPrintDN(row.callingsheet_no);
                  }
                } else {
                  Modal.error({
                    title:
                      'ไม่สามารถ print DN ได้ เนื่องจากยังไม่ได้ match Calling หรือ confirm TR/DV ',
                  });
                }
              } else {
                Modal.error({
                  title: 'กรุณาตรวจสอบสิทธิ์การเข้าถึงข้อมูล',
                });
              }
            },
          },
        ]}
      />
      <FormReason
        formData={formData}
        showModal={isModalVisibleCancelPlan}
        isModalVisibleCancelPlan={isModalVisibleCancelPlan}
        setIsModalVisibleCancelPlan={setIsModalVisibleCancelPlan}
        isLoadingMatchlPlan={isLoading}
        isFetchingMatchlPlan={isLoading}
        postReason={onCancelMatchPlanWithReason}
      />
      <PrintDN
        componentRef={printDNComponentRef}
        dnDataList={printData}
      ></PrintDN>
      <Modal
        title='Diagram Plan Detail'
        centered
        width={1000}
        visible={showDiagramPlanDetail}
        maskClosable={false}
        onCancel={() => {
          setShowDiagramPlanDetail(false);
        }}
        footer={null}
      >
        {selectedRowRef.current && (
          <DiagramPlanDetail formData={selectedRowRef.current} />
        )}
      </Modal>
      <Modal
        title='Downloading...'
        okButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        visible={downloadVisible}
        onCancel={handleDownloadCancel}
      >
        <Progress
          type='circle'
          percent={downloadProgress}
          width={80}
          style={{ width: '100%', textAlign: 'center' }}
        />
        <Typography
          style={{ width: '100%', textAlign: 'center' }}
        >{`Downloaded items: ${downloadCount} / ${totalDownloadRows}`}</Typography>
      </Modal>
    </Container>
  );
};

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: auto;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
    }
    .ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
      border-color: white;
    }
  }
`;
const Container = styled.div`
  padding: 0 10px;
`;

const StyledAntTableTRDV = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      font-size: 18px;
    }
  }
`;

export default TableComponent;
