import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Modal, Switch, Select } from 'antd';
import {
  useUpdateDriverMutation,
  useCreateDriverMutation,
} from 'reducers/masterData/driver/api';
import {
  useFindAllBzpsQuery,
  useFindBzpByIdQuery,
} from 'reducers/masterData/bzp/api';

import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { goBack } from 'connected-react-router';
const { Option } = Select;

const UserFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);

  const [bzps, setBzps] = useState(
    formData && formData.bzp
      ? [{ id: formData.bzp.id, label: formData.bzp.name }]
      : []
  );

  // Query all bzps
  const {
    data: dataBzps,
    error: errorBzps,
    isFetching: isBzpsFetching,
    refetch: refetchBzps,
  } = useFindAllBzpsQuery(
    {
      ordering: 'job_name',
      location_type__code: '005',
      display: 'bzp',
      exclude_job_name: '',
    },
    {
      skip: !user || readonly || !user.is_superadmin,
    }
  );

  useEffect(() => {
    if (dataBzps) {
      setBzps(dataBzps.map((d) => ({ id: d.id, label: d.job_name })));
    }
  }, [dataBzps]);

  useEffect(() => {
    if (errorBzps) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load bzps',
        okText: 'Retry',
        onOk: refetchBzps,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzps, refetchBzps]);

  // Query bzp by ID, when admin of a bzp
  const {
    data: dataBzpById,
    error: errorBzpById,
    isFetching: isBzpByIdFetching,
    refetch: refetchBzpById,
  } = useFindBzpByIdQuery(user && user.bzp_id, {
    skip: !user || readonly || user.is_superadmin,
  });

  useEffect(() => {
    if (dataBzpById) {
      setBzps([{ id: dataBzpById.id, label: dataBzpById.name }]);
      form.setFieldsValue({ bzp: dataBzpById.name });
    }
  }, [dataBzpById, form]);

  useEffect(() => {
    if (errorBzpById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load bzp',
        okText: 'Retry',
        onOk: refetchBzpById,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzpById, refetchBzpById]);

  const [
    createDriver,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateDriverMutation();

  const [
    updateDriver,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateDriverMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let bzp = bzps.find((c) => c.label === validData.bzp);
    let postData = {
      id: formData && formData.id,
      ...validData,
      bzp: bzp && bzp.id,
    };

    if (isCreate) {
      createDriver(postData);
    } else {
      updateDriver(postData);
    }
  };
  const onBzpChange = (bzpName) => {
    // let bzp = bzps.find((c) => c.label === bzpName);
    // setSelectedBzpId(bzp.id);
  };
  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Employee Code'
        name='employee_code'
        initialValue={formData && formData.employee_code}
        rules={[
          {
            required: true,
            message: 'Please input employee code',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Employee code'} />
      </Form.Item>
      <Form.Item
        label='TLEP Code'
        name='tlep_code'
        initialValue={formData && formData.tlep_code}
        rules={[
          {
            required: true,
            message: 'Please input TLEP code',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'TLEP code'} />
      </Form.Item>
      <Form.Item
        label='Bzp'
        name='bzp'
        initialValue={formData && formData.bzp && formData.bzp.job_name}
        rules={[
          {
            required: user && user.is_superadmin ? false : true,
            message: 'Please select bzp',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a bzp'
          optionFilterProp='children'
          loading={isBzpsFetching || isBzpByIdFetching}
          disabled={!user || !user.is_superadmin}
          onChange={onBzpChange}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {bzps &&
            bzps.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Is Active'
        name='is_active'
        initialValue={formData && formData.is_active}
        valuePropName='checked'
      >
        <Switch />
      </Form.Item>
    </Form>
  );
};

export default UserFormComponent;
