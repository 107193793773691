import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  language: 'enUS',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage(state, { payload }) {
      state.language = payload;
    },
  },
});

export const { setLanguage } = languageSlice.actions;

export const getLanguage = (state) => {
  return state.locale.language;
};

export default languageSlice.reducer;