import React, { useCallback, useEffect, useState } from 'react';
import {
  Form,
  Input,
  Modal,
  Button,
  message,
  Divider,
  PageHeader,
  Row,
  Col,
  Select,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useErrorHandler } from 'common_components/ErrorContext';
import digitalMapUtils from '../../Utils';
import { push } from 'connected-react-router';
import { getCurrentUser } from 'reducers/user';
import { useDispatch, useSelector } from 'react-redux';
import { useFindAllLocationTypesQuery } from 'reducers/digitalmap/locationType/api';
import {
  setOriginType,
  setDestinationType,
  setOriginLocation,
  setDestinationLocation,
} from 'reducers/digitalmap/routeMaster';
import styled from 'styled-components';
import { routeMasterApi } from 'reducers/digitalmap/routeMaster/api';

import { mapDrawingMode, mapResetMap } from 'reducers/map';
import { mapSetBounds } from 'reducers/map';
import { SET_MARKER_ORIGIN_DESC } from 'reducers/map';
import RouteMasterTable from './RouteMasterTable';
import RouteCandidateTable from './RouteCandidateTable';
import { locationMasterApi } from 'reducers/masterData/locationMaster/api';

const ManageForm = (props) => {
  const { formData, isCreate } = props;
  const [form] = Form.useForm();
  const user = useSelector(getCurrentUser);
  const [locationTypes, setLocationTypes] = useState([]);

  const [routeGroup] = useState(formData);

  // Origin
  const [originLocations, setOriginLocations] = useState([]);
  const [isOriginLocationLoading, setIsOriginLocationLoading] = useState(false);

  // Destination
  const [destinationLocations, setDestinationLocations] = useState([]);
  const [isDestinationLocationLoading, setIsDestinationLocationLoading] =
    useState(false);

  const [routeMasters, setRouteMasters] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const { Option } = Select;

  // Redux Store
  const selectedOriginType = useSelector((state) => {
    return state.routeMaster.originType;
  });
  const originLocation = useSelector((state) => {
    return state.routeMaster.originLocation;
  });
  const selectedDestinationType = useSelector((state) => {
    return state.routeMaster.destinationType;
  });
  const destinationLocation = useSelector((state) => {
    return state.routeMaster.destinationLocation;
  });

  useEffect(() => {
    if (formData && formData.origin && formData.destination) {
      dispatch(setOriginLocation(formData.origin));
      dispatch(setOriginType(formData.origin.location_type));
      dispatch(setDestinationLocation(formData.destination));
      dispatch(setDestinationType(formData.destination.location_type));
    }
  }, [formData, dispatch]);

  // Query all location types
  const {
    data: dataLocationTypes,
    error: errorLocationTypes,
    isFetching: isLocationTypesFetching,
  } = useFindAllLocationTypesQuery(null, {
    skip: !user,
  });

  useEffect(() => {
    if (dataLocationTypes) {
      setLocationTypes(
        dataLocationTypes.map((d) => ({ id: d.id, name: d.name }))
      );
    }
  }, [dataLocationTypes]);

  useEffect(() => {
    if (errorLocationTypes) {
      setErrorData(errorLocationTypes);
    }
  }, [errorLocationTypes, setErrorData]);

  const queryLocationMasterByType = useCallback(
    async (locationType) => {
      let data = null;
      let result = await dispatch(
        locationMasterApi.endpoints.findAllLocationMastersByType.initiate(
          locationType
        )
      );
      if (result) {
        if (result.error) {
          message.error('Failed to load location masters');
        } else {
          data = result.data;
        }
      }
      return data;
    },
    [dispatch]
  );

  useEffect(() => {
    (async () => {
      try {
        if (selectedOriginType) {
          setIsOriginLocationLoading(true);
          let result = await queryLocationMasterByType(selectedOriginType.name);
          if (result) {
            setOriginLocations(
              result.map((d) => ({
                id: d.id,
                label: d.short_name,
                lat: d.lat,
                lng: d.lng,
              }))
            );
          } else {
            setOriginLocations([]);
          }
        }
      } catch (err) {
        message.error('Something went wrong');
      } finally {
        setIsOriginLocationLoading(false);
      }
    })();
  }, [selectedOriginType, queryLocationMasterByType]);

  useEffect(() => {
    (async () => {
      try {
        if (selectedDestinationType) {
          setIsDestinationLocationLoading(true);
          let result = await queryLocationMasterByType(
            selectedDestinationType.name
          );

          if (result) {
            setDestinationLocations(
              result.map((d) => ({
                id: d.id,
                label: d.short_name,
                lat: d.lat,
                lng: d.lng,
              }))
            );
          } else {
            setDestinationLocations([]);
          }
        }
      } catch (err) {
        console.log(err);
        message.error('Something went wrong');
      } finally {
        setIsDestinationLocationLoading(false);
      }
    })();
  }, [selectedDestinationType, queryLocationMasterByType]);

  useEffect(() => {
    if (routeGroup) {
      setRouteMasters(routeGroup.route_master);
    }
  }, [routeGroup]);

  useEffect(() => {
    if (originLocation && destinationLocation) {
      dispatch(
        SET_MARKER_ORIGIN_DESC({
          marker_origin: {
            lat: originLocation.lat,
            lng: originLocation.lng,
          },
          marker_desc: {
            lat: destinationLocation.lat,
            lng: destinationLocation.lng,
          },
        })
      );
      let bounds = digitalMapUtils.setOdBounds(
        originLocation,
        destinationLocation
      );
      setTimeout(() => {
        dispatch(mapSetBounds(bounds));
      }, 100);
    }
  }, [originLocation, destinationLocation, dispatch]);

  const submitAction = async (values, callback) => {
    Modal.confirm({
      title: 'Are you sure want to submit?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          setIsSubmitting(true);
          let result = null;
          if (isCreate) {
            result = await dispatch(
              routeMasterApi.endpoints.createRouteGroup.initiate({
                name: values.name,
                origin: originLocation.id,
                destination: destinationLocation.id,
              })
            );
          } else {
            result = await dispatch(
              routeMasterApi.endpoints.updateRouteGroup.initiate({
                id: formData.id,
                name: values.name,
                origin: originLocation.id,
                destination: destinationLocation.id,
              })
            );
          }

          if (result && result.error) {
            setErrorData(result.error);
          } else {
            if (callback) {
              await callback();
            } else {
              message.success('Data was successfully saved');
              if (isCreate) {
                dispatch(
                  push(
                    `/app/digitalmap/route_masters/route_group/${result.data.id}`
                  )
                );
              }
            }
          }
        } catch (error) {
          message.error('Something went wrong');
        } finally {
          setIsSubmitting(false);
        }
      },
    });
  };
  const onFinish = (values) => {
    submitAction(values);
  };

  // const onAddRoute = async (callback) => {
  //   try {
  //     let values = await form.validateFields();
  //     await submitAction(values, callback);
  //   } catch (errors) {
  //     // Do nothings
  //   }
  // };

  const onBack = () => {
    dispatch(mapDrawingMode(null));
    dispatch(mapResetMap());
    setTimeout(() => {
      dispatch(push('/app/digitalmap/route_masters'));
    }, 100);
  };

  return (
    <>
      <Form
        form={form}
        name='basic'
        wrapperCol={{
          span: 20,
        }}
        layout='vertical'
        style={{ padding: '10px 10px 0px 10px' }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <StyledPageHeader
          ghost={false}
          onBack={onBack}
          title='Edit Route'
          subTitle=''
          extra={[
            <Button
              size='small'
              key='search-button'
              type='primary'
              htmlType='submit'
              loading={isSubmitting}
            >
              Submit
            </Button>,
          ]}
        >
          <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />

          <Form.Item
            label='Name'
            name='name'
            initialValue={routeGroup && routeGroup.name}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={!isCreate} />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Origin Type'
                name='origin_type'
                initialValue={
                  formData &&
                  formData.origin &&
                  formData.origin.location_type &&
                  formData.origin.location_type.name
                }
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Type'
                  optionFilterProp='children'
                  loading={isLocationTypesFetching}
                  disabled={!user || !isCreate}
                  onChange={(value) => {
                    // setSelectedOriginType(value);
                    // Update redux store
                    let locationType = locationTypes.find(
                      (c) => c.name === value
                    );
                    dispatch(setOriginType(locationType));
                    dispatch(setOriginLocation(null));
                    dispatch(mapResetMap());
                    form.setFieldsValue({ origin_location: '' });
                  }}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {locationTypes &&
                    locationTypes.map((c, index) => {
                      return (
                        <Option key={index} value={c.name}>
                          {c.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Origin Location'
                name='origin_location'
                initialValue={
                  formData && formData.origin && formData.origin.short_name
                }
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='location'
                  optionFilterProp='children'
                  loading={isOriginLocationLoading}
                  disabled={!user || !isCreate}
                  onChange={(value) => {
                    // Update redux store
                    let location = originLocations.find(
                      (c) => c.label === value
                    );
                    dispatch(setOriginLocation(location));
                    // form.submit();
                  }}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {originLocations &&
                    originLocations.map((c, index) => {
                      return (
                        <Option key={index} value={c.label}>
                          {c.label}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Destination Type'
                name='destination_type'
                initialValue={
                  formData &&
                  formData.destination &&
                  formData.destination.location_type &&
                  formData.destination.location_type.name
                }
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='Type'
                  optionFilterProp='children'
                  loading={isLocationTypesFetching}
                  disabled={!user || !isCreate}
                  onChange={(value) => {
                    // Update redux store
                    let locationType = locationTypes.find(
                      (c) => c.name === value
                    );
                    dispatch(setDestinationType(locationType));
                    dispatch(setDestinationLocation(null));
                    dispatch(mapResetMap());
                    form.setFieldsValue({ destination_location: '' });
                  }}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {locationTypes &&
                    locationTypes.map((c, index) => {
                      return (
                        <Option key={index} value={c.name}>
                          {c.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Destination Location'
                name='destination_location'
                initialValue={
                  formData &&
                  formData.destination &&
                  formData.destination.short_name
                }
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder='location'
                  optionFilterProp='children'
                  loading={isDestinationLocationLoading}
                  disabled={!user || !isCreate}
                  onChange={(value) => {
                    // Update redux store
                    let location = destinationLocations.find(
                      (c) => c.label === value
                    );
                    dispatch(setDestinationLocation(location));
                    // form.submit();
                  }}
                  filterOption={(input, option) => {
                    return option.value
                      .toLowerCase()
                      .includes(input.toLowerCase());
                  }}
                >
                  {destinationLocations &&
                    destinationLocations.map((c, index) => {
                      return (
                        <Option key={index} value={c.label}>
                          {c.label}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </StyledPageHeader>
      </Form>
      {routeGroup && (
        <>
          {routeGroup && <> </>}
          <StyledDivider orientation='left'>Route Master</StyledDivider>
          <RouteMasterTable rowData={routeMasters} />
        </>
      )}

      {routeGroup && (
        <>
          <StyledDivider orientation='left'>Route Candidate</StyledDivider>
          <RouteCandidateTable
            routeGroup={routeGroup}
            // onAddRoute={onAddRoute}
          />
        </>
      )}
    </>
  );
};
const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
  .ant-page-header-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 8px 0px !important;
`;

export default ManageForm;
