import React from 'react';
import Dn from './PDFDn';
import constants from 'common_components/PrintDocument/constants';
const PrintVin = ({ componentRef, dnDataList }) => {
  return (
    <div
      id='print-dn'
      style={{
        display: 'none',
        width: `${constants.A5.landscape[0]}px`,
      }}
    >
      <div ref={componentRef}>
        <Dn dnDataList={dnDataList} />
      </div>
    </div>
  );
};

export default PrintVin;
