import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { Row, Col } from 'antd';

const EvaStatusRenderer = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const status = data.eva;
  const skipStatus = data.skip_status;
  const trailer_status = data.trailer_status;
  const update_eta = data.update_eta;

  const onClick = () => {
    dispatch(
      push(
        `/app/digitalmap/ttt_monitoring_jobs/realtime_tracking/${data.realtime_tracking_id}`
      )
    );
  };

  if (status === 'warning') {
    return (
      <>
        {skipStatus !== 'ok' ||
        (!update_eta && trailer_status === 'On the way') ? (
          <Row align='middle'>
            <Col span={10}>
              <ExclamationCircleTwoTone
                style={{
                  fontSize: 14,
                  marginLeft: 15,
                  paddingTop: 4,
                }}
                twoToneColor='#EC602C'
              />
            </Col>
            <Col span={12}>
              <OrangeDot style={{ marginRight: '40px' }} onClick={onClick} />
            </Col>
          </Row>
        ) : (
          <Col offset={10}>
            <OrangeDot onClick={onClick} />
          </Col>
        )}
      </>
    );
  } else if (status === 'delay') {
    return (
      <>
        {skipStatus !== 'ok' ||
        trailer_status === 'On the way' ||
        (!update_eta && trailer_status === 'On the way') ? (
          <Row align='middle'>
            <Col span={10}>
              <ExclamationCircleTwoTone
                style={{
                  fontSize: 14,
                  marginLeft: 15,
                  paddingTop: 4,
                }}
                twoToneColor='#EC602C'
              />
            </Col>
            <Col span={12}>
              <RedDot style={{ marginRight: '40px' }} onClick={onClick} />
            </Col>
          </Row>
        ) : (
          <Col offset={10}>
            <RedDot onClick={onClick} />
          </Col>
        )}
      </>
    );
  } else if (status === 'normal') {
    return (
      <>
        {skipStatus !== 'ok' ||
        (!update_eta && trailer_status === 'On the way') ? (
          <Row align='middle'>
            <Col span={10}>
              <ExclamationCircleTwoTone
                style={{
                  fontSize: 14,
                  marginLeft: 15,
                  paddingTop: 4,
                }}
                twoToneColor='#EC602C'
              />
            </Col>
            <Col span={12}>
              <GreenDot style={{ marginRight: '40px' }} onClick={onClick} />
            </Col>
          </Row>
        ) : (
          <Col offset={10}>
            <GreenDot onClick={onClick} />
          </Col>
        )}
      </>
    );
  } else {
    return '';
  }
};
const GreenDot = styled.div`
  height: 18px;
  width: 18px;
  background-color: green;
  border-radius: 50%;
  border: 2px solid #ececec;
  &:hover {
    border: 1px solid #ececec;
  }
  cursor: pointer;
`;
const OrangeDot = styled.div`
  height: 18px;
  width: 18px;
  background-color: #ffa701;
  border-radius: 50%;
  border: 2px solid #ececec;
  &:hover {
    border: 1px solid #ececec;
  }
  cursor: pointer;
`;
const RedDot = styled.div`
  height: 18px;
  width: 18px;
  background-color: red;
  border-radius: 50%;
  border: 2px solid #ececec;
  &:hover {
    border: 1px solid #ececec;
  }
  cursor: pointer;
`;
export default EvaStatusRenderer;
