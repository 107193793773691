import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  Input,
  Button,
  Modal,
  message,
  Select,
  Switch,
  InputNumber,
} from 'antd';

import {
  useCreateLanesMutation,
  useUpdateLanesMutation,
  useFindLanesByIdQuery,
} from 'reducers/masterData/lanes/api';

import { goBack } from 'connected-react-router';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

const DiagramOwnerFormComponent = (props) => {
  const { id } = useParams();
  const { Option } = Select;
  const {
    formData,
    isCreate,
    yardOptionLocation,
    setYardOptionLocation,
    yardLocation,
    setYardLocation,
    optionCategory,
    setOptionCategory,
    valuecategory,
    setValuecategory,
    checkedSwitch,
    setCheckedSwitch,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { setErrorData } = useErrorHandler();

  // Query Parking Lane by ID
  const {
    data: dataParkingById,
    error: errorParkingById,
    isFetching: isParkingByIdFetching,
    refetch: refetchParkingById,
  } = useFindLanesByIdQuery(id, {
    skip: !id,
  });
  useEffect(() => {
    if (dataParkingById) {
      form.setFieldsValue({ lane_no: dataParkingById.lane_no });
      form.setFieldsValue({ lane_type: dataParkingById.lane_type?.id });
      form.setFieldsValue({ yard: dataParkingById.yard?.id });
      form.setFieldsValue({ is_available: dataParkingById.is_available });
      form.setFieldsValue({ amount: dataParkingById.amount });
      form.setFieldsValue({ used: dataParkingById.used });
    }
  }, [dataParkingById]);

  useEffect(() => {
    if (errorParkingById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load parking lane',
        okText: 'Retry',
        onOk: refetchParkingById,
        cancelText: 'Cancel',
      });
    }
  }, [errorParkingById, refetchParkingById]);

  const [
    createLanes,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateLanesMutation();

  const [
    updateParkingLane,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateLanesMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onClickSubmit = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const onChangeSwitch = (checked) => {
    console.log('checked', checked);
    setCheckedSwitch(checked);
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);

    let postData = {
      name: validData && validData.name,
      is_available: checkedSwitch,

      ...validData,
    };
    let putData = {
      name: validData && validData.name,
      is_available: checkedSwitch,
      ...validData,

      id: id,
    };

    if (isCreate) {
      createLanes(postData);
    } else {
      updateParkingLane(putData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          // loading={isCreating || isUpdating}
          onClick={onClickSubmit}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='
        Lane No'
        name='lane_no'
        rules={[
          {
            required: true,
            message: 'Please input lane no name',
          },
        ]}
      >
        <Input placeholder={'lane no name'} />
      </Form.Item>

      <Form.Item
        label='Lane type'
        name='lane_type'
        rules={[
          {
            required: true,
            message: 'Please input Lane type',
          },
        ]}
      >
        <Select
          mode='single'
          style={{
            width: '100%',
          }}
          allowClear={true}
          maxTagCount='responsive'
          placeholder={'Lane type'}
          value={valuecategory}
          options={optionCategory}
          onChange={(values) => {
            setValuecategory(values);
          }}
        />
      </Form.Item>

      <Form.Item
        label='Yard'
        name='yard'
        rules={[
          {
            required: true,
            message: 'Please input Yard name',
          },
        ]}
      >
        <Select
          mode='single'
          style={{
            width: '100%',
          }}
          allowClear={true}
          maxTagCount='responsive'
          placeholder={'Yard'}
          value={yardLocation}
          options={yardOptionLocation}
          onChange={(values) => {
            setYardLocation(values);
          }}
        />
      </Form.Item>

      <Form.Item
        label='Is Available'
        name='is_available'
        initialValue={checkedSwitch}
        valuePropName='checked'
      >
        <Switch onChange={onChangeSwitch} />
      </Form.Item>

      <Form.Item
        label='
        Amount'
        name='amount'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber min={1} max={100} />
      </Form.Item>

      <Form.Item
        label='
        Used'
        name='used'
        rules={[
          {
            required: true,
          },
        ]}
      >
        <InputNumber min={0} max={100} />
      </Form.Item>
    </Form>
  );
};

export default DiagramOwnerFormComponent;
