import React from 'react';
import DN from './DN';

const PrintAll = ({ componentRef, deiveryNotesData }) => {
  return (
    <div style={{ display: 'none' }}>
      <div ref={componentRef}>
        <div
          id='print_area'
          style={{
            width: '90%',
            paddingLeft: 40,
            paddingRight: 40,
            paddingTop: 50,
            pageBreakAfter: 'always',
          }}
        >
          <table
            style={{
              height: '100%',
              width: '100%',
              border: '0px solid black',
            }}
          >
            <tr>
              <td
                style={{
                  textAlign: 'top',
                }}
              >
                <table
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '30px',
                    fontFamily: 'Arail',
                    borderCollapse: 'collapse',
                    border: '0px solid black',
                  }}
                >
                  <tr>
                    <td
                      style={{
                        width: '40%',
                      }}
                    >
                      Trip Allowance
                    </td>
                  </tr>
                </table>
                <br />
                <table
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '14px',
                    fontFamily: 'Arail',
                    border: '0px solid black',
                  }}
                >
                  <tr>
                    <td
                      style={{
                        width: '17%',
                        border: '0px solid black',
                        textAlign: 'left',
                      }}
                    >
                      Calling Sheet No.
                    </td>
                    <td
                      style={{
                        border: '0px solid black',
                        textAlign: 'left',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '14px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        202112-s0331
                      </div>
                    </td>
                    <td
                      style={{
                        width: '22%',
                        border: '0px solid black',
                        textAlign: 'right',
                      }}
                    >
                      วันที่ออกเอกสาร
                    </td>
                    <td
                      style={{
                        border: '0px solid black',
                        textAlign: 'left',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '14px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        30/08/2021
                      </div>
                    </td>
                    <td
                      style={{
                        border: '0px solid black',
                        textAlign: 'right',
                      }}
                    >
                      เวลา
                    </td>
                    <td
                      style={{
                        border: '0px solid black',
                        textAlign: 'left',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '14px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        15:30 PM
                      </div>
                    </td>
                    <td
                      style={{
                        border: '0px solid black',
                        textAlign: 'right',
                      }}
                    >
                      กรุ๊ปที่
                    </td>
                    <td
                      style={{
                        border: '0px solid black',
                        textAlign: 'left',
                      }}
                    >
                      <div
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '14px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        S07
                      </div>
                    </td>
                  </tr>
                  <td colspan='8'>
                    <hr color='black' size='1' />
                  </td>
                </table>

                <table
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '12px',
                    fontFamily: 'Arail',
                    border: '0px solid black',
                  }}
                >
                  <tr>
                    <td
                      style={{
                        width: '50%',
                        text: 'top',
                        textAlign: 'left',
                        verticalalign: 'top',
                        fontSize: '12px',
                        fontFamily: 'Arail',
                      }}
                    >
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '0px solid black',
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              width: '30%',
                            }}
                          >
                            Trailer No.:
                          </td>
                          <td>1234567890</td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: '30%',
                            }}
                          >
                            วันที่เดินทาง:
                          </td>
                          <td>30/08/2021</td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: '30%',
                            }}
                          >
                            เวลาเข้า/ออก VL.:
                          </td>
                          <td>19.00 PM</td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              width: '30%',
                            }}
                          >
                            เดินทางจาก:
                          </td>
                          <td>บริษัท โตโยต้า มอเตอร์ ประเทศไทย จำกัด</td>
                        </tr>
                      </table>
                    </td>

                    <td
                      style={{
                        width: '50%',
                        text: 'top',
                        textAlign: 'left',
                        position: 'topLeft',
                        fontSize: '12px',
                        fontFamily: 'Arail',
                      }}
                    >
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'left',
                          position: 'topLeft',
                          fontSize: '12px',
                          verticalalign: 'top',
                          fontFamily: 'Arail',
                          borderCollapse: 'collapse',
                          border: '0px solid black',
                        }}
                      >
                        <tr>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          >
                            รหัส
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          >
                            ชื่อ-นามสกุล
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          >
                            บริษัท
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          ></td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          ></td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          ></td>
                        </tr>{' '}
                        <tr>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          >
                            &nbsp;
                          </td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          ></td>
                          <td
                            style={{
                              textAlign: 'center',
                              fontSize: '12px',
                              fontFamily: 'Arail',
                              border: '1px solid #6C6B6B',
                            }}
                          ></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>

                <table
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '12px',
                    fontFamily: 'Arail',
                    border: '0px solid black',
                  }}
                >
                  <tr>
                    <td
                      style={{
                        textAlign: 'left',
                      }}
                      colspan='2'
                    >
                      &nbsp;
                    </td>
                    <td align='center'>จำนวน (คัน)</td>
                    <td align='center'>ระยะทางไป-กลับ (ก.ม.)</td>
                  </tr>

                  <tr>
                    <td
                      style={{
                        width: '16%',
                        textAlign: 'left',
                      }}
                    >
                      หมายเลขใบส่งสินค้า & จุดหมายเดินทาง:
                    </td>
                    <td
                      style={{
                        width: '54%',
                        textAlign: 'left',
                        border: '1px solid black',
                        borderRadius: '7px',
                      }}
                    >
                      &nbsp;S201205413 บริษัท โตโยต้า ตาปี จำกัด
                      <br />
                      &nbsp;S201205414 บริษัท โตโยต้าสุราษฎร์ธานี
                      ผู้จำหน่ายโตโยต้า จำกัด
                    </td>
                    <td
                      style={{
                        width: '10%',
                        textAlign: 'center',
                        border: '1px solid black',
                        borderRadius: '7px',
                      }}
                    >
                      2<br />5
                    </td>
                    <td
                      style={{
                        width: '20%',
                        textAlign: 'center',
                        border: '1px solid black',
                        borderRadius: '7px',
                      }}
                    >
                      653.00
                      <br />
                      660.00
                    </td>
                  </tr>
                </table>
                <br />
                <table
                  style={{
                    width: '100%',
                    textAlign: 'left',
                    fontSize: '12px',
                    fontFamily: 'Arail',
                    border: '0px solid black',
                  }}
                >
                  <tr>
                    <td
                      style={{
                        width: '15%',
                        textAlign: 'center',
                        border: '0px solid black',
                      }}
                      rowspan='3'
                    >
                      วันที่จ่ายงาน <br />
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>30/08/2021</td>
                        </tr>
                      </table>
                    </td>
                    <td
                      style={{
                        width: '25%',
                        textAlign: 'center',
                        border: '0px solid black',
                      }}
                      rowspan='3'
                    >
                      วันที่/เวลาออกจาก TTT (รปภ.) <br />
                      <table
                        style={{
                          width: '95%',
                          textAlign: 'center',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>30/08/2021 15.30 PM</td>
                        </tr>
                      </table>
                    </td>

                    <td width='15%'>ค่าขับรถ/เที่ยว/คน</td>
                    <td width='10%'>
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>0.00</td>
                        </tr>
                      </table>
                    </td>
                    <td>บาท</td>
                    <td>รวม</td>
                    <td width='10%'>
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>0.00</td>
                        </tr>
                      </table>
                    </td>
                    <td>บาท</td>
                  </tr>
                  <tr>
                    <td>จำนวนชั่วโมง O/T</td>
                    <td>
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>0.00</td>
                        </tr>
                      </table>
                    </td>
                    <td>บาท</td>
                    <td>ค่าทางด่วน</td>
                    <td width='10%'>
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>0.00</td>
                        </tr>
                      </table>
                    </td>
                    <td>บาท</td>
                  </tr>
                  <tr>
                    <td>อื่นๆ</td>
                    <td>
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>0.00</td>
                        </tr>
                      </table>
                    </td>
                    <td>บาท</td>
                    <td>จำนวนเงินรวม</td>
                    <td>
                      <table
                        style={{
                          width: '100%',
                          textAlign: 'right',
                          fontSize: '12px',
                          fontFamily: 'Arail',
                          border: '1px solid black',
                          borderRadius: '6px',
                        }}
                      >
                        <tr>
                          <td>0.00</td>
                        </tr>
                      </table>
                    </td>
                    <td>บาท</td>
                  </tr>
                </table>

                <table
                  style={{
                    position: 'fixed',
                    bottom: '80px',
                    width: '87%',
                    textAlign: 'left',
                    fontSize: '12px',
                    fontFamily: 'Arail',
                    borderCollapse: 'collapse',
                    border: '0px solid black',
                  }}
                >
                  <tr>
                    <td colspan='3'>
                      <hr color='black' size='1' />
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
        {deiveryNotesData.map((dnData, idx) => (
          <div style={{ pageBreakAfter: 'always' }}>
            <DN dnData={dnData} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PrintAll;
