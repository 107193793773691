import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  mapSetMarkers,
  mapSetCircles,
  mapSetPolygons,
  mapClearCircle,
  mapClearPolygon,
  mapDrawingMode,
} from 'reducers/map';
import uuidv4 from 'uuid/v4';
import { DrawingManager } from '@react-google-maps/api';
class DrawingMap extends Component {
  onMarkerComplete = (marker) => {
    marker.setMap(null);
    // this.props.DRAWING_MODE(null);

    let markerList = [];
    let data = {
      id: uuidv4(),
      position: { lat: marker.position.lat(), lng: marker.position.lng() },
      info: {
        name: marker.position.lat() + ',' + marker.position.lng(),
        description: '',
        logisticsPoint: '',
      },
      isGroup: false,
      editable: true,
    };
    markerList.push(data);
    this.props.SET_MARKERS(markerList);

    // Auto add circle when initial marker
    if (
      this.props.polygonList &&
      this.props.polygonList.length === 0 &&
      this.props.circleList &&
      this.props.circleList.length === 0
    ) {
      let circleList = [];
      let data = {
        id: uuidv4(),
        center: { lat: marker.position.lat(), lng: marker.position.lng() },
        radius: 300,
        info: {
          name: marker.position.lat() + ',' + marker.position.lng(),
          description: '',
          logisticsPoint: '',
        },
        isGroup: true,
        editable: true,
      };
      circleList.push(data);
      this.props.SET_CIRCLES(circleList);
      this.props.DRAWING_MODE('circle');
    }
  };
  onCircleComplete = (circle) => {
    circle.setMap(null);
    // this.props.DRAWING_MODE(null);
    this.props.CLEAR_POLYGON(null);
    let circleList = [];
    let data = {
      id: uuidv4(),
      center: { lat: circle.getCenter().lat(), lng: circle.getCenter().lng() },
      radius: circle.getRadius(),
      info: {
        name: circle.getCenter().lat() + ',' + circle.getCenter().lng(),
        description: '',
        logisticsPoint: '',
      },
      isGroup: true,
      editable: true,
    };
    circleList.push(data);
    this.props.SET_CIRCLES(circleList);
  };
  onPolygonComplete = (polygon) => {
    polygon.setMap(null);
    // this.props.DRAWING_MODE(null);
    this.props.CLEAR_CIRCLE(null);

    let polygonList = [];

    let data = {
      id: uuidv4(),
      path: polygon
        .getPath()
        .getArray()
        .map((latLng) => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        }),
      info: {
        name: '',
        description: '',
        logisticsPoint: '',
      },
      isGroup: true,
      editable: true,
    };
    polygonList.push(data);

    this.props.SET_POLYGON(polygonList);
  };

  render() {
    return (
      <DrawingManager
        options={{
          drawingControlOptions: {
            drawingModes: [
              this.props.drawingMode === 'marker' &&
                window.google.maps.drawing.OverlayType.MARKER,
              this.props.drawingMode === 'polygon' &&
                window.google.maps.drawing.OverlayType.POLYGON,
              this.props.drawingMode === 'circle' &&
                window.google.maps.drawing.OverlayType.CIRCLE,
            ],
          },
        }}
        drawingMode={this.props.drawingMode}
        onOverlayComplete={this.onOverlayComplete}
        onMarkerComplete={this.onMarkerComplete}
        onCircleComplete={this.onCircleComplete}
        onPolygonComplete={this.onPolygonComplete}
        onPolylineComplete={this.onPolylineComplete}
        onRectangleComplete={this.onRectangleComplete}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    markerList: state.map.markerList,
    circleList: state.map.circleList,
    polygonList: state.map.polygonList,
    drawingMode: state.map.drawingMode,
  };
};

const mapActionsToProps = {
  SET_MARKERS: mapSetMarkers,
  SET_CIRCLES: mapSetCircles,
  SET_POLYGON: mapSetPolygons,
  DRAWING_MODE: mapDrawingMode,
  CLEAR_POLYGON: mapClearPolygon,
  CLEAR_CIRCLE: mapClearCircle,
};

export default connect(mapStateToProps, mapActionsToProps)(DrawingMap);
