import React, { useState, useEffect } from 'react';
import {
  Badge,
  Row,
  Col,
  Popover,
  Checkbox,
  Button,
  Space,
  Input,
  Upload,
  Modal,
  Typography,
} from 'antd';
import styled from 'styled-components';
import { useDebounce } from 'hooks/debounce';
import {
  FilterOutlined,
  DeleteTwoTone,
  PlusCircleOutlined,
  UploadOutlined,
  ExportOutlined,
  ReloadOutlined,
  ClearOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import DeleteModal from 'common_components/DeleteModal';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const TableToolbar = (props) => {
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const {
    showDelete,
    showAdd,
    showExport,
    showUpload,
    showReload,
    showPermanentDelete,
    onDelete,
    onCreate,
    onExport,
    onUpload,
    onReload,
    isDeleting,
    isExporting,
    isUploading,
    isLoading,
    columns,
    setColumns,
    onSearch,
  } = props;

  // Redux Store
  const storedSearchTerm = useSelector((state) => {
    return state.routeGroup.searchTerm;
  });
  const storedSearchOrigin = useSelector((state) => {
    return state.routeGroup.searchOrigin;
  });
  const storedSearchDestination = useSelector((state) => {
    return state.routeGroup.searchDestination;
  });

  const [searchFilters, setSearchFilters] = useState({
    searchTerm: storedSearchTerm,
    searchOrigin: storedSearchOrigin,
    searchDestination: storedSearchDestination,
  });
  const debouncedSearchFilters = useDebounce(searchFilters, 500);

  useEffect(() => {
    if (onSearch && debouncedSearchFilters) {
      onSearch(debouncedSearchFilters);
    }
  }, [debouncedSearchFilters, onSearch]);

  const onInputSearchTermChange = (event) => {
    setSearchFilters((prevState) => ({
      ...prevState,
      searchTerm: event.target.value,
    }));
  };

  const onInputSearchOriginChange = (event) => {
    setSearchFilters((prevState) => ({
      ...prevState,
      searchOrigin: event.target.value,
    }));
  };

  const onInputSearchDestinationChange = (event) => {
    setSearchFilters((prevState) => ({
      ...prevState,
      searchDestination: event.target.value,
    }));
  };

  const onColsChange = (e, col) => {
    const nextColumns = [...columns];
    let selectedCol = nextColumns.find((c) => c.title === col.title);
    selectedCol['hide'] = !e.target.checked;
    setColumns(nextColumns);
  };

  const onHideAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = true;
    }
    setColumns(nextColumns);
  };

  const onShowAllCols = () => {
    const nextColumns = [...columns];
    for (let col of nextColumns) {
      col['hide'] = false;
    }
    setColumns(nextColumns);
  };

  const onCancelDelete = () => {
    setShowConfirmDelete(false);
  };

  const onOkDelete = (isPermanentDelete) => {
    setShowConfirmDelete(false);
    onDelete(isPermanentDelete);
  };

  return (
    <Container>
      <Row style={{ marginBottom: '10px' }}>
        <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
          <Popover
            key={'popover'}
            zIndex='10000'
            placement='right'
            content={(() => {
              return [
                <div key={'show-hide-all'}>
                  <Button type='link' onClick={onHideAllCols}>
                    Hide all
                  </Button>
                  /
                  <Button type='link' onClick={onShowAllCols}>
                    Show all
                  </Button>
                  ,
                </div>,
                columns
                  .filter((c) => c.title)
                  .map((c, index) => {
                    return (
                      <div key={`root-${index}`}>
                        <Checkbox
                          key={index}
                          checked={!c.hide}
                          onChange={(e) => onColsChange(e, c)}
                        >
                          {c.title}
                        </Checkbox>
                      </div>
                    );
                  }),
              ];
            })()}
            title='Show/hide Columns'
            trigger='click'
          >
            <Badge dot={columns && columns.some((c) => c.hide)}>
              <Button icon={<FilterOutlined />} size='small' />
            </Badge>
          </Popover>
          <Space
            align='horizontal'
            size='small'
            style={{ marginLeft: '10px', alignItems: 'center' }}
          >
            <Text strong>Search:</Text>
            <Input
              placeholder='Search...'
              value={searchFilters && searchFilters.searchTerm}
              onChange={onInputSearchTermChange}
            />
          </Space>
        </Col>
      </Row>
      <Row align='middle'>
        <Col span={16} style={{ display: 'flex', alignItems: 'center' }}>
          <Space
            align='horizontal'
            size='small'
            style={{ marginLeft: '10px', alignItems: 'center' }}
          >
            <Text strong>Search Origin:</Text>
            <Input
              placeholder='Origin'
              value={searchFilters && searchFilters.searchOrigin}
              onChange={onInputSearchOriginChange}
            />
            <Text strong>Search Destination:</Text>
            <Input
              placeholder='Destination'
              value={searchFilters && searchFilters.searchDestination}
              onChange={onInputSearchDestinationChange}
            />
            <Button
              icon={<ClearOutlined />}
              size='small'
              onClick={() => {
                setSearchFilters({
                  searchTerm: '',
                  searchOrigin: '',
                  searchDestination: '',
                });
              }}
            >
              Clear
            </Button>
          </Space>
        </Col>

        <RightAlignedCol>
          <Space size='middle'>
            {showDelete && (
              <Button
                loading={isDeleting}
                icon={<DeleteTwoTone twoToneColor='white' />}
                size='small'
                style={{ backgroundColor: 'red', marginRight: '20px' }}
                onClick={() => {
                  setShowConfirmDelete(true);
                }}
              />
            )}
            {showExport && (
              <Button
                loading={isExporting}
                icon={<ExportOutlined />}
                size='small'
                onClick={onExport}
              >
                Export
              </Button>
            )}
            {showUpload && (
              <Upload
                // onChange={}
                showUploadList={false}
                accept='.xlsx'
                beforeUpload={(file) => {
                  Modal.confirm({
                    title: 'Are you sure want to upload?',
                    icon: <ExclamationCircleOutlined />,
                    content: `filename: ${file && file.name}`,
                    okText: 'Confirm',
                    cancelText: 'Cancel',
                    onOk: () => {
                      onUpload(file);
                    },
                  });

                  return false;
                }}
              >
                <Button
                  raised='true'
                  loading={isUploading}
                  icon={<UploadOutlined />}
                  size='small'
                >
                  Upload
                </Button>
              </Upload>
            )}
            {showReload && (
              <Button
                loading={isLoading}
                icon={<ReloadOutlined />}
                size='small'
                onClick={onReload}
              >
                Reload
              </Button>
            )}
            {showAdd && (
              <Button
                icon={<PlusCircleOutlined />}
                size='small'
                onClick={onCreate}
              >
                Add
              </Button>
            )}
          </Space>
        </RightAlignedCol>
      </Row>
      <DeleteModal
        visible={showConfirmDelete}
        onCancel={onCancelDelete}
        onDelete={onOkDelete}
        showPermanentDelete={showPermanentDelete}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 10px 0;
`;

const RightAlignedCol = styled(Col)`
  text-align: right;
  margin-left: auto;
`;

export default TableToolbar;
