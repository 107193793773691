import React, { useState } from 'react';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { markerPinNumberSvgIcon } from 'common_components/Map/MarkerIconRenderer';

const OrderMarker = ({ title, location, info }) => {
  const [onHover, setOnHover] = useState(false);
  return (
    <Marker
      position={location}
      icon={markerPinNumberSvgIcon(title, 30, 40, '#0000ff', '#ffffff')}
      onMouseOver={() => {
        setOnHover(true);
      }}
      onMouseOut={() => {
        setOnHover(false);
      }}
    >
      {onHover && info && (
        <InfoWindow>
          <>
            {info?.short_name ? 'Shortname : ' + info?.short_name : ''}
            <br />
            {info?.name ? 'Name : ' + info?.name : ''}
          </>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default OrderMarker;
