import React, { useRef } from 'react';
import * as yup from 'yup';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Row,
  Col,
  TimePicker,
  DatePicker,
  Spin,
  Card,
} from 'antd';
import Table from 'common_components/AntTable';
import { PrinterOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import ButtonPrint from './Printing/ButtonPrint';
import PrintAll from './Printing/PrintAll';
import PrintAllDN from './Printing/PrintAllDN';

import DeliveryNote from './DeliveryNote';

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

/* eslint-disable no-template-curly-in-string */

const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};
/* eslint-enable no-template-curly-in-string */
const columns = [
  {
    title: 'รหัส',
    dataIndex: 'account',
    columnWidth: 100,
  },
  {
    title: 'ชื่อ-นามสกุล',
    dataIndex: 'firstName',
    columnWidth: 200,
  },
  {
    title: 'บริษัท',
    dataIndex: 'lastName',
    columnWidth: 200,
  },
];
const dataSource = [
  {
    account: 'UD0001T',
    firstName: 'Administrator',
    lastName: 'Toyota',
  },
  {
    account: 'UD0002T',
    firstName: 'Toyota',
    lastName: 'Tusho',
  },
  {
    account: 'UD0003T',
    firstName: 'Toyota',
    lastName: '-',
  },
  {
    account: 'UD0001T',
    firstName: 'Administrator',
    lastName: 'Toyota',
  },
  {
    account: 'UD0002T',
    firstName: 'Toyota',
    lastName: 'Tusho',
  },
  {
    account: 'UD0003T',
    firstName: 'Toyota',
    lastName: '-',
  },
];

const TripAllowanceForm = (props) => {
  const { formData: deiveryNotesData, isLoading } = props;
  const history = useHistory();

  const printAllComponentRef = useRef();
  const printAllDNComponentRef = useRef();

  let schema = yup.object().shape({
    callingNo: yup.string().required('Required.'),
    docDate: yup.date(),
    docTime: yup.date(),
    groupNo: yup
      .number()
      .required('Required.')
      .typeError('Number only.')
      .positive()
      .integer(),
    trailerNo: yup.string(),
    travelDate: yup.date(),
    timeFromVL: yup.date(),
    travelFrom: yup.string(),
    transNo: yup.string(),
    numTruck: yup.string(),
    distance2Way: yup.number().typeError('Number only.').positive(),
    assignDate: yup.date(),
    payment: yup.number().typeError('Number only.').positive(),
    priceTotal: yup.number().typeError('Number only.').positive(),
    leaveDateTime: yup.date(),
    ot: yup.number().typeError('Number only.').positive(),
    priceHightway: yup.number().typeError('Number only.').positive(),
    other: yup.number().typeError('Number only.').positive(),
    price: yup.number().typeError('Number only.').positive(),
  });

  const yupSync = {
    async validator({ field }, value) {
      await schema.validateSyncAt(field, { [field]: value });
    },
  };

  const onFinish = (values) => {
    console.log(values);
  };

  return (
    <Spin spinning={isLoading} tip='Loading...'>
      <div style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
        <div>
          <Card
            title='TRIP ALLOWANCE'
            extra={
              <>
                <ButtonPrint
                  type='primary'
                  componentRef={printAllComponentRef}
                  style={{ marginRight: 20 }}
                  icon={<PrinterOutlined />}
                >
                  Print All
                </ButtonPrint>
                <ButtonPrint
                  type='primary'
                  componentRef={printAllDNComponentRef}
                  style={{ marginRight: 20 }}
                  icon={<PrinterOutlined />}
                >
                  Delivery Notes
                </ButtonPrint>
              </>
            }
          >
            <PrintAll
              componentRef={printAllComponentRef}
              deiveryNotesData={deiveryNotesData || []}
            ></PrintAll>
            <PrintAllDN
              componentRef={printAllDNComponentRef}
              deiveryNotesData={deiveryNotesData || []}
            ></PrintAllDN>
            <Form
              {...layout}
              name='nest-messages'
              onFinish={onFinish}
              validateMessages={validateMessages}
            >
              <Row gutter={[8, 16]}>
                <Col xs={8}>
                  <Form.Item
                    name='callingNo'
                    label='Calling Sheet No.'
                    labelCol={{ flex: '135px' }}
                    wrap={false}
                    rules={[{ required: true, message: '' }, yupSync]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={7} align='center'>
                  <Form.Item
                    name='docDate'
                    label='วันที่กรอกเอกสาร'
                    labelCol={{ flex: '130px' }}
                    style={{ flex: 1 }}
                    wrap={false}
                    labelAlign='right'
                    rules={[yupSync]}
                  >
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={5}>
                  <Form.Item
                    name='docTime'
                    label='เวลา'
                    labelCol={{ flex: '60px' }}
                    style={{ flex: 1 }}
                    wrap={false}
                    rules={[yupSync]}
                  >
                    <TimePicker style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
                <Col xs={4}>
                  <Form.Item
                    name='groupNo'
                    // labelCol={{ flex: "120px" }}
                    style={{ flex: 1 }}
                    wrap={false}
                    label='กรุ๊ปที่'
                    rules={[{ required: true, message: '' }, yupSync]}
                  >
                    <InputNumber style={{ width: '100%' }} min={0} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[8, 16]}>
                <Col xs={8}>
                  <Form.Item
                    name='trailerNo'
                    labelCol={{ flex: '135px' }}
                    wrap={false}
                    label='Trailer No.'
                    rules={[yupSync]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    name='travelDate'
                    label='วันที่เดินทาง'
                    labelCol={{ flex: '135px' }}
                    wrap={false}
                    rules={[yupSync]}
                  >
                    <DatePicker />
                  </Form.Item>
                  <Form.Item
                    name='timeFromVL'
                    label='เวลาเข้าออก VL'
                    labelCol={{ flex: '135px' }}
                    wrap={false}
                    rules={[yupSync]}
                  >
                    <TimePicker />
                  </Form.Item>
                  <Form.Item
                    name='travelFrom'
                    label='เดินทางจาก'
                    labelCol={{ flex: '135px' }}
                    wrap={false}
                    rules={[yupSync]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={2}></Col>
                <Col xs={14}>
                  <div style={{ width: '100%', height: '200px' }}>
                    <div
                      className='ag-theme-balham'
                      style={{
                        height: '150px',
                        width: '100%',
                        marginTop: '10px',
                      }}
                    >
                      <Table
                        dataSource={dataSource}
                        columns={columns}
                        size='small'
                        scroll={{ y: 150 }}
                        pagination={false}
                      />
                    </div>
                  </div>
                </Col>
              </Row>

              <table width='100%'>
                <tr>
                  <td style={{ minWidth: '500px' }}>
                    <Form.Item
                      name='transNo'
                      label='หมายเลขใบส่งสินค้า & จุดหมาย'
                      labelCol={{ flex: '200px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <Input style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='numTruck'
                      label='จำนวน (คัน)'
                      labelCol={{ flex: '120px' }}
                      wrap={false}
                      style={{ flex: 1 }}
                      rules={[yupSync]}
                    >
                      <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='distance2Way'
                      label='ระยะทางไป-กลับ (กม.)'
                      labelCol={{ flex: '150px' }}
                      wrap={false}
                      style={{ flex: 1 }}
                      rules={[yupSync]}
                    >
                      <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Form.Item
                      name='assignDate'
                      label='วันที่จ่ายงาน'
                      labelCol={{ flex: '200px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <DatePicker />
                    </Form.Item>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='payment'
                      label='ค่าขับรถ/เที่ยว/คน'
                      labelCol={{ flex: '120px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='priceTotal'
                      label='รวม'
                      labelCol={{ flex: '150px' }}
                      wrap={false}
                      style={{ flex: 1 }}
                      rules={[yupSync]}
                    >
                      <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <td>
                    <Form.Item
                      name='leaveDateTime'
                      label='วันที่/เวลาออกจาก TTT (รปภ.)'
                      labelCol={{ flex: '200px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <DatePicker showTime format='YYYY-MM-DD HH:mm:ss' />
                    </Form.Item>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='ot'
                      label='จำนวนชั่วโมง O/T'
                      labelCol={{ flex: '120px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='priceHightway'
                      label='ค่าทางด่วน'
                      labelCol={{ flex: '150px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <Input min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                </tr>

                <tr>
                  <td>&nbsp;</td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='other'
                      label='อื่นๆ'
                      labelCol={{ flex: '120px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <Input min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                  <td style={{ paddingLeft: '10px' }}>
                    <Form.Item
                      // {...layout2}
                      name='price'
                      label='จำนวนเงิน'
                      labelCol={{ flex: '150px' }}
                      style={{ flex: 1 }}
                      wrap={false}
                      rules={[yupSync]}
                    >
                      <Input min={0} style={{ width: '100%' }} />
                    </Form.Item>
                  </td>
                </tr>
              </table>
              <Row>
                <Col xs={24}>
                  <Button
                    type='secondary'
                    htmlType='reset'
                    style={{ float: 'right', marginRight: 10 }}
                  >
                    Clear
                  </Button>
                  <Button
                    type='primary'
                    htmlType='submit'
                    style={{ float: 'right', marginRight: 20 }}
                  >
                    Submit
                  </Button>
                  <Button
                    type='secondary'
                    style={{ float: 'right', marginRight: 30 }}
                    onClick={() => history.push('/dn_ta')}
                  >
                    Back
                  </Button>
                </Col>
              </Row>
            </Form>
            <h3>DELIVERY NOTE</h3>
            <Form.Provider>
              {deiveryNotesData &&
                deiveryNotesData.map((data, idx) => {
                  return (
                    <DeliveryNote
                      index={idx}
                      data={data}
                      name={'delivery_' + idx}
                    />
                  );
                })}
            </Form.Provider>
          </Card>
        </div>
      </div>
    </Spin>
  );
};

export default TripAllowanceForm;
