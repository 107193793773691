import React, { useState, useCallback, useEffect, useRef } from 'react';
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { mapZoomChanged } from 'reducers/map';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import PageError from 'common_components/PageError500';
import SearchPlace from '../Map/SearchPlace';
import MapLoadingBox from '../Map/MapLoadingBox';
import { decode } from '@googlemaps/polyline-codec';
import Configs from 'config/config';
const env = process.env.NODE_ENV;

const containerStyle = {
  height: '100%',
};
// Declare outside functional component to prevent warning.
const enabledMapLibraries = ['places', 'drawing'];

const BaseMap = (props) => {
  const { onDragEnd, onLoad, panelData } = props;
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: Configs[env].MAP_KEY,
    libraries: enabledMapLibraries,
  });
  const dispatch = useDispatch();
  const [map, setMap] = useState(null);
  const mapRef = useRef();

  const mapBounds = useSelector((state) => state.map.bounds);
  const boundsIsSet = useSelector((state) => state.map.boundsIsSet);
  const mapLoading = useSelector((state) => state.map.map_loading);
  const zoom = useSelector((state) => state.map.zoom);
  const center = useSelector((state) => state.map.center);

  const onLoadHandler = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds();
      map.fitBounds(bounds);

      setMap(map);
      if (onLoad) {
        onLoad(map);
      }
    },
    [onLoad]
  );

  useEffect(() => {
    if (map) {
      // FIXME: Check below recenter map settimeout
      setTimeout(() => {
        map.panTo(center);
      }, 10);
    }
  }, [center, map]);

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const setBound = useCallback(
    (bounds) => {
      try {
        const latLngBounds = new window.google.maps.LatLngBounds();
        let countBounds = 0;
        bounds.forEach((bound, i) => {
          if (bound.lat && bound.lng) {
            latLngBounds.extend(
              new window.google.maps.LatLng(bound.lat, bound.lng)
            );
            countBounds++;
          }
        });
        if (countBounds > 0) map.fitBounds(latLngBounds);
      } catch (e) {}
    },
    [map]
  );

  const renderEncodedLine = useCallback(function callback(
    encodedLine,
    color,
    opacity,
    zIndex
  ) {
    if (encodedLine) {
      const decodedLine = decode(encodedLine);
      if (decodedLine && decodedLine.length > 0) {
        const polylinePath = decodedLine.map((p) => ({
          lat: p[0],
          lng: p[1],
        }));
        const lineStrokeWhite = new window.google.maps.Polyline({
          path: polylinePath,
          // default styles
          geodesic: true,
          strokeWeight: 10,
          strokeColor: '#ffffff',
          strokeOpacity: opacity,
        });
        lineStrokeWhite.setMap(mapRef.current);

        const line = new window.google.maps.Polyline({
          path: polylinePath,
          // default styles
          geodesic: true,
          strokeWeight: 5,
          strokeColor: color,
          strokeOpacity: opacity,
          zIndex,
        });

        line.setMap(mapRef.current);
      }
    }
  },
  []);

  const getTnosTrafficPolyline = useCallback(
    function callback(route) {
      if (route) {
        route.completedLegs.forEach((encodedLine) => {
          renderEncodedLine(encodedLine, '#999', 4, 1000);
        });
        route.legs.forEach((leg, i) => {
          renderEncodedLine(leg.route, '#00B0FF', 4, 1000);
          leg.moderateTraffic.forEach((encodedLine) => {
            renderEncodedLine(encodedLine, '#FFA000', 4, 1000);
          });
          leg.heavyTraffic.forEach((encodedLine) => {
            renderEncodedLine(encodedLine, '#F44336', 4, 1000);
          });
        });
      }
    },
    [renderEncodedLine]
  );
  useEffect(() => {
    if (panelData && panelData.traffic_polyline) {
      setTimeout(() => {
        getTnosTrafficPolyline(panelData.traffic_polyline);
      }, 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [panelData]);
  useEffect(() => {
    setBound(mapBounds);
  }, [mapBounds, setBound]);

  const onBoundsChanged = (e) => {
    if (map) {
      if (boundsIsSet && map.getZoom() > 17) {
        //FIXME: Check why have to call twice to change map zoom
        dispatch(mapZoomChanged(map.getZoom()));

        dispatch(mapZoomChanged(14));
      }
      mapRef.current = map;
    }
  };

  const renderMap = () => {
    return (
      <GoogleMap
        mapContainerStyle={containerStyle}
        options={{ gestureHandling: 'greedy', streetViewControl: false }}
        zoom={zoom}
        center={center}
        onLoad={onLoadHandler}
        onUnmount={onUnmount}
        onBoundsChanged={onBoundsChanged}
        onDragEnd={onDragEnd}
      >
        {props.children}
        {mapLoading && <MapLoadingBox />}
        <SearchPlace />
      </GoogleMap>
    );
  };

  if (loadError) {
    return <PageError />;
  }

  return isLoaded ? (
    renderMap()
  ) : (
    <RootCircular>
      <CenterSpin indicator={antIcon} size='large' />
    </RootCircular>
  );
};
//https://codesandbox.io/s/popr2?file=/src/index.js:2792-2845
const antIcon = <LoadingOutlined style={{ fontSize: 80 }} spin />;
const RootCircular = styled.div`
  display: table;
  height: calc(100%);
  margin: auto;
`;

const CenterSpin = styled(Spin)`
  display: table-cell;
  vertical-align: middle;
`;

export default BaseMap;
