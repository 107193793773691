import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Button, Select, Modal, Input, Popover, Form } from 'antd';
import styled from 'styled-components';
import AntTable from 'common_components/AntTable';
import { dateTimeFormatterWorking } from 'common_components/AntTable/tableUtils';
import {
  CheckCircleOutlined,
  CalendarOutlined,
  RightSquareFilled,
  ExclamationCircleOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { timeFormatterNoHyphen } from 'common_components/AntTable/tableUtils';

const { Option } = Select;
const { TextArea } = Input;

const TableComponent = (props) => {
  const {
    dataSourceTable,
    headerDateTableRef,
    updateManageTourBzp,
    PAGE_SIZE,
    totalRows,
    page,
    offset,
    payloadRef,
    LIMIT,
    setPage,
    executeQuery,
    isLoading,
    dataInitRef,
    updateDataTable,
    dataSourceTableEdit,
    setDataSourceTableEdit,
    disablePage,
    onBtnSave,
  } = props;

  const rowSelectDataRef = useRef();

  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [dataInitDisabled, setDataInitDisabled] = useState();
  const [statusData, setStatusData] = useState();
  const [isModalVisibleWarningEdit, setIsModalVisibleWarningEdit] =
    useState(false);
  const [isModalVisibleDeleteData, setIsModalVisibleDeleteData] =
    useState(false);
  const { t } = useTranslation();

  const contentMemo = (value) => {
    return (
      <>
        <h4>
          <RightSquareFilled style={{ color: 'grey' }} />{' '}
          {value?.manage_tour_list_memo_by?.fullname_th}
        </h4>
        <h6 style={{ color: 'grey' }}>
          {value?.manage_tour_list_memo_at
            ? moment(value?.manage_tour_list_memo_at).format('DD/MM/YY HH:mm')
            : null}{' '}
        </h6>
        <h4 style={{ whiteSpace: 'pre-line' }}>
          {value?.manage_tour_list_memo}
        </h4>
      </>
    );
  };
  useEffect(() => {
    if (dataInitRef) {
      setDataInitDisabled(dataInitRef);
    }
  }, [dataInitRef]);
  const ModalEditTable = async () => {
    setIsModalVisible(true);
  };

  const onCheckStatus = async (value, record) => {
    setStatusData(value);
    form.setFieldsValue({
      id: record?.id,
      description: record?.manage_tour_list_memo,
    });
    if (value === 'request' || value === 'reject') {
      setIsModalVisible(true);
    } else if (value === 'accept') {
      try {
        let payload = {
          id: record?.id,
          manage_tour_list_status_bzp: value,
          manage_tour_list_memo: '',
          edit: true,
        };
        updateDataTable(payload, record?.id);
        // updateManageTourBzp(payload);
        setIsModalVisible(false);
      } catch (errorInfo) {
        console.log('Failed:', errorInfo);
      }
    } else if (value === '-') {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Would you like to select blank?',
        okText: 'OK',
        onOk: () => {
          try {
            let payload = {
              id: record?.id,
              manage_tour_list_status_bzp: value,
              manage_tour_list_memo: '',
              edit: true,
            };
            updateDataTable(payload, record?.id);
            // updateManageTourBzp(payload);
            setIsModalVisible(false);
          } catch (errorInfo) {
            console.log('Failed:', errorInfo);
          }
        },
        cancelText: 'Cancel',
      });
    }
  };

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: 'Status',
        dataIndex: 'manage_tour_list_status_bzp',
        align: 'center',
        className: 'color-head-6',
        fixed: 'left',
        width: 80,
        render: (text, record) => {
          return {
            props: {
              style: {
                background:
                  (record?.edit &&
                    record?.original_memo !== record?.manage_tour_list_memo &&
                    record?.original_status_bzp?.toLowerCase() !== text) ||
                  (record?.edit &&
                    record?.original_memo !== record?.manage_tour_list_memo &&
                    record?.original_status_bzp?.toLowerCase() === text)
                    ? 'rgb(252, 228, 164)'
                    : '',
              },
            },
            children: (
              <>
                {' '}
                {record?.allow_edit === false ? (
                  text
                ) : (
                  <Select
                    defaultValue={text}
                    value={text}
                    size='small'
                    style={{
                      fontSize: '12px',
                      width: '100px',
                    }}
                    onChange={(value) => {
                      if (value) {
                        onCheckStatus(value, record);
                      }
                    }}
                  >
                    <Option value='accept'>Accept</Option>
                    <Option value='request'>Request</Option>
                    <Option value='reject'>Reject</Option>
                    {/* <Option value='bzp_checking'>BZP Checking</Option> */}
                  </Select>
                )}{' '}
              </>
            ),
          };
        },
      },
      {
        title: 'Memo',
        dataIndex: 'manage_tour_list_memo',
        align: 'center',
        className: 'color-head-6',
        fixed: 'left',
        width: 50,
        render: (text, record) => {
          return text ? (
            <Popover content={contentMemo(record)} placement='bottom'>
              <CheckCircleOutlined style={{ color: 'green' }} />
            </Popover>
          ) : (
            ''
          );
        },
      },

      {
        title: 'Job code',
        dataIndex: 'job_code',
        align: 'center',
        className: 'color-head-6',
        fixed: 'left',
        width: 80,
      },
      {
        title: 'Trailer type',
        dataIndex: 'trailer_type',
        align: 'center',
        className: 'color-head-6',
        fixed: 'left',
        width: 70,
      },
      {
        title: 'Shift',
        dataIndex: 'shift',
        align: 'center',
        className: 'color-head-6',
        fixed: 'left',
        width: 40,
      },
      {
        title: 'Trip type',
        dataIndex: 'trip_type',
        align: 'center',
        className: 'color-head-6',
        fixed: 'left',
        width: 80,
      },
      {
        title: 'Working hour',
        dataIndex: 'working_hour',
        align: 'center',
        className: 'color-head-6',
        fixed: 'left',
        width: 60,
      },

      {
        title: 'Trip No.1',
        align: 'center',
        className: 'color-head-3',
        children: [
          {
            title: 'From',
            className: 'bg_from_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'To',
                dataIndex: 'trip_1_to',
                className: 'bg_to_trip_no',
                align: 'center',
                width: 70,
                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#d8e2dc',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record?.trips[0]?.location_from?.name ? (
                            record?.trips[0]?.location_from?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b>
                          {record?.trips[0]?.location_to?.name ? (
                            record?.trips[0]?.location_to?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Receive',

            className: 'bg_receive_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Arrive',
                dataIndex: 'trip_1_arrival_time',
                className: 'bg_arrive_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#ffe5d9',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record.trips[0]?.receive_job_time ? (
                            <div>
                              {(record?.trips[0]?.receive_job_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[0]?.receive_job_time
                                ?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[0]?.receive_job_time
                                    ?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[0]?.arrival_time ? (
                            <div>
                              {(record?.trips[0]?.arrival_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[0]?.arrival_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[0]?.arrival_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Out',
            className: 'bg_out_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Departure',
                dataIndex: 'trip_1_yard_out_time',
                className: 'bg_out_color_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#F9CE7D',
                      },
                    },
                    children: (
                      <div>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[0]?.yard_out_time ? (
                            <div>
                              {(record?.trips[0]?.yard_out_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[0]?.yard_out_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[0]?.yard_out_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[0]?.departure_time ? (
                            <div>
                              {(record?.trips[0]?.departure_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[0]?.departure_time?.day_offset !==
                              0
                                ? '(+' +
                                  record?.trips[0]?.departure_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },

          {
            title: 'Unit',
            dataIndex: ['trip_1_unit'],
            align: 'center',
            className: 'bg-unit-trip_no',
            width: 50,
            render(text, record) {
              return {
                props: {
                  style: {
                    background: '#f4acb7',
                  },
                },
                children: record?.trips[0]?.unit,
              };
            },
          },
        ],
      },

      {
        title: 'Trip No.2',
        align: 'center',
        className: 'color-head-3',
        children: [
          {
            title: 'From',
            className: 'bg_from_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'To',
                dataIndex: 'trip_2_to',
                className: 'bg_to_trip_no',
                align: 'center',
                width: 70,
                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#d8e2dc',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record?.trips[1]?.location_from?.name ? (
                            record?.trips[1]?.location_from?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b>
                          {record?.trips[1]?.location_to?.name ? (
                            record?.trips[1]?.location_to?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Receive',

            className: 'bg_receive_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Arrive',
                dataIndex: 'trip_2_arrival_time',
                className: 'bg_arrive_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#ffe5d9',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record.trips[1]?.receive_job_time ? (
                            <div>
                              {(record?.trips[1]?.receive_job_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[1]?.receive_job_time
                                ?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[1]?.receive_job_time
                                    ?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[1]?.arrival_time ? (
                            <div>
                              {(record?.trips[1]?.arrival_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[1]?.arrival_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[1]?.arrival_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Out',
            className: 'bg_out_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Departure',
                dataIndex: 'trip_2_yard_out_time',
                className: 'bg_out_color_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#F9CE7D',
                      },
                    },
                    children: (
                      <div>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[1]?.yard_out_time ? (
                            <div>
                              {(record?.trips[1]?.yard_out_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[1]?.yard_out_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[1]?.yard_out_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[1]?.departure_time ? (
                            <div>
                              {(record?.trips[1]?.departure_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[1]?.departure_time?.day_offset !==
                              0
                                ? '(+' +
                                  record?.trips[1]?.departure_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },

          {
            title: 'Unit',
            dataIndex: ['trip_2_unit'],
            align: 'center',
            className: 'bg-unit-trip_no',
            width: 50,
            render(text, record) {
              return {
                props: {
                  style: {
                    background: '#f4acb7',
                  },
                },
                children: record?.trips[1]?.unit,
              };
            },
          },
        ],
      },

      {
        title: 'Trip No.3',
        align: 'center',
        className: 'color-head-3',
        children: [
          {
            title: 'From',
            className: 'bg_from_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'To',
                dataIndex: 'trip_3_to',
                className: 'bg_to_trip_no',
                align: 'center',
                width: 70,
                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#d8e2dc',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record?.trips[2]?.location_from?.name ? (
                            record?.trips[2]?.location_from?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b>
                          {record?.trips[2]?.location_to?.name ? (
                            record?.trips[2]?.location_to?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Receive',

            className: 'bg_receive_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Arrive',
                dataIndex: 'trip_3_arrival_time',
                className: 'bg_arrive_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#ffe5d9',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record.trips[2]?.receive_job_time ? (
                            <div>
                              {(record?.trips[2]?.receive_job_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[2]?.receive_job_time
                                ?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[2]?.receive_job_time
                                    ?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[2]?.arrival_time ? (
                            <div>
                              {(record?.trips[2]?.arrival_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[2]?.arrival_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[2]?.arrival_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Out',
            className: 'bg_out_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Departure',
                dataIndex: 'trip_3_yard_out_time',
                className: 'bg_out_color_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#F9CE7D',
                      },
                    },
                    children: (
                      <div>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[2]?.yard_out_time ? (
                            <div>
                              {(record?.trips[2]?.yard_out_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[2]?.yard_out_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[2]?.yard_out_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[2]?.departure_time ? (
                            <div>
                              {(record?.trips[2]?.departure_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[2]?.departure_time?.day_offset !==
                              0
                                ? '(+' +
                                  record?.trips[2]?.departure_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },

          {
            title: 'Unit',
            dataIndex: ['trip_3_unit'],
            align: 'center',
            className: 'bg-unit-trip_no',
            width: 50,
            render(text, record) {
              return {
                props: {
                  style: {
                    background: '#f4acb7',
                  },
                },
                children: record?.trips[2]?.unit,
              };
            },
          },
        ],
      },
      {
        title: 'Trip No.4',
        align: 'center',
        className: 'color-head-3',
        children: [
          {
            title: 'From',
            className: 'bg_from_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'To',
                dataIndex: 'trip_4_to',
                className: 'bg_to_trip_no',
                align: 'center',
                width: 70,
                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#d8e2dc',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record?.trips[3]?.location_from?.name ? (
                            record?.trips[3]?.location_from?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b>
                          {record?.trips[3]?.location_to?.name ? (
                            record?.trips[3]?.location_to?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Receive',

            className: 'bg_receive_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Arrive',
                dataIndex: 'trip_4_arrival_time',
                className: 'bg_arrive_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#ffe5d9',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record.trips[3]?.receive_job_time ? (
                            <div>
                              {(record?.trips[3]?.receive_job_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[3]?.receive_job_time
                                ?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[3]?.receive_job_time
                                    ?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[3]?.arrival_time ? (
                            <div>
                              {(record?.trips[3]?.arrival_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[3]?.arrival_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[3]?.arrival_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Out',
            className: 'bg_out_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Departure',
                dataIndex: 'trip_1_yard_out_time',
                className: 'bg_out_color_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#F9CE7D',
                      },
                    },
                    children: (
                      <div>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[3]?.yard_out_time ? (
                            <div>
                              {(record?.trips[3]?.yard_out_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[3]?.yard_out_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[3]?.yard_out_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[3]?.departure_time ? (
                            <div>
                              {(record?.trips[3]?.departure_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[3]?.departure_time?.day_offset !==
                              0
                                ? '(+' +
                                  record?.trips[3]?.departure_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },

          {
            title: 'Unit',
            dataIndex: ['trip_4_unit'],
            align: 'center',
            className: 'bg-unit-trip_no',
            width: 50,
            render(text, record) {
              return {
                props: {
                  style: {
                    background: '#f4acb7',
                  },
                },
                children: record?.trips[3]?.unit,
              };
            },
          },
        ],
      },
      {
        title: 'Trip No.5',
        align: 'center',
        className: 'color-head-3',
        children: [
          {
            title: 'From',
            className: 'bg_from_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'To',
                dataIndex: 'trip_5_to',
                className: 'bg_to_trip_no',
                align: 'center',
                width: 70,
                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#d8e2dc',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record?.trips[4]?.location_from?.name ? (
                            record?.trips[4]?.location_from?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b>
                          {record?.trips[4]?.location_to?.name ? (
                            record?.trips[4]?.location_to?.name
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Receive',

            className: 'bg_receive_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Arrive',
                dataIndex: 'trip_5_arrival_time',
                className: 'bg_arrive_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#ffe5d9',
                      },
                    },
                    children: (
                      <div>
                        <b>
                          {record.trips[4]?.receive_job_time ? (
                            <div>
                              {(record?.trips[4]?.receive_job_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[4]?.receive_job_time
                                ?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[4]?.receive_job_time
                                    ?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[4]?.arrival_time ? (
                            <div>
                              {(record?.trips[4]?.arrival_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[4]?.arrival_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[4]?.arrival_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },
          {
            title: 'Out',
            className: 'bg_out_trip_no',
            align: 'center',
            width: 70,
            children: [
              {
                title: 'Departure',
                dataIndex: 'trip_4_yard_out_time',
                className: 'bg_out_color_trip_no',
                align: 'center',
                width: 70,

                render(text, record) {
                  return {
                    props: {
                      style: {
                        background: '#F9CE7D',
                      },
                    },
                    children: (
                      <div>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[4]?.yard_out_time ? (
                            <div>
                              {(record?.trips[4]?.yard_out_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[4]?.yard_out_time?.day_offset !== 0
                                ? '(+' +
                                  record?.trips[4]?.yard_out_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                        <h5>------------</h5>
                        <b style={{ fontWeight: '400' }}>
                          {record.trips[4]?.departure_time ? (
                            <div>
                              {(record?.trips[4]?.departure_time?.time).substring(
                                0,
                                5,
                              )}{' '}
                              {record?.trips[4]?.departure_time?.day_offset !==
                              0
                                ? '(+' +
                                  record?.trips[4]?.departure_time?.day_offset +
                                  ')'
                                : ''}
                            </div>
                          ) : (
                            <>&nbsp;</>
                          )}
                        </b>
                      </div>
                    ),
                  };
                },
              },
            ],
          },

          {
            title: 'Unit',
            dataIndex: ['trip_5_unit'],
            align: 'center',
            className: 'bg-unit-trip_no',
            width: 50,
            render(text, record) {
              return {
                props: {
                  style: {
                    background: '#f4acb7',
                  },
                },
                children: record?.trips[4]?.unit,
              };
            },
          },
        ],
      },

      {
        title: (
          <>
            <CalendarOutlined style={{ fontSize: '15px' }} /> Calendar{' '}
          </>
        ),

        dataIndex: 'calendar',
        width: 70,
        fixed: 'right',
        className: 'color-head-6',
        render: (text, record) => {
          return text;
        },

        align: 'center',
        children: [
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[0]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '0', 'work'],
            key: 1,
            align: 'center',
            fixed: 'right',
            className: 'color-head-6',
            width: 70,
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[1]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '1', 'work'],
            key: 2,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            width: 70,
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[2]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '2', 'work'],
            key: 3,
            align: 'center',
            fixed: 'right',
            className: 'color-head-6',
            width: 70,
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },

          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[3]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '3', 'work'],
            key: 4,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            width: 70,
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[4]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '4', 'work'],
            key: 5,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            width: 70,
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[5]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '5', 'work'],
            key: 6,
            fixed: 'right',
            align: 'center',
            className: 'color-head-6',
            width: 70,
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
          {
            title: () => {
              let date =
                headerDateTableRef.current &&
                headerDateTableRef.current[0]?.calendar[6]?.date;
              return (
                <div>
                  {date ? dateTimeFormatterWorking({ value: date }) : '-'}
                </div>
              );
            },
            dataIndex: ['calendar', '6', 'work'],
            className: 'color-head-6',
            key: 7,
            fixed: 'right',
            align: 'center',
            width: 70,
            render: (text, record) => {
              if (text) {
                return <CheckOutlined style={{ fontSize: '15px' }} />;
              } else {
                return null;
              }
            },
          },
        ],
      },
    ];

    return cols;
  });

  const handleOk = async () => {
    try {
      let values = await form.validateFields();
      let payload = {
        id: values?.id,
        manage_tour_list_status_bzp: statusData,
        manage_tour_list_memo: values?.description,
        edit: true,
      };
      updateDataTable(payload, values?.id);
      // updateManageTourBzp(payload);
      setIsModalVisible(false);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const handleCancel = () => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Would you like to delete memo and status?',
      okText: 'OK',
      onOk: async () => {
        try {
          let values = await form.getFieldValue();
          let payload = {
            id: values?.id,
            manage_tour_list_status_bzp: '-',
            manage_tour_list_memo: null,
            edit: true,
          };
          updateDataTable(payload, values?.id);
          // updateManageTourBzp(payload);
          setIsModalVisible(false);
        } catch (errorInfo) {
          console.log('Failed:', errorInfo);
        }
      },
      cancelText: 'Cancel',
    });
  };
  //modal Warning edit
  const handleOkWarningEdit = () => {
    setIsModalVisibleWarningEdit(false);
  };

  const handleCancelWarningEdit = () => {
    setIsModalVisibleWarningEdit(false);
  };

  const modalSave = async () => {
    // setIsModalVisibleWarningEdit(true);
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Would you like to edit memo?',
      okText: 'OK',
      onOk: handleOk,
      cancelText: 'Cancel',
    });
  };
  //modal Delete Data
  const handleOkDeleteData = () => {
    setIsModalVisibleDeleteData(false);
  };

  const handleCancelDeleteData = () => {
    setIsModalVisibleDeleteData(false);
  };

  const defaultFooter = () => (
    <div style={{ textAlign: 'right' }}>
      <Row gutter={[20]}>
        <Col span={12} align='start'></Col>
        <Col span={12} align='end'>
          <Button
            type='primary'
            size='small'
            style={{ width: '100px', height: '30px' }}
            onClick={onBtnSave}
            disabled={!disablePage}
          >
            Save
          </Button>
        </Col>
      </Row>
    </div>
  );
  const tableProps = {
    // bordered,
    // loading,
    // size,
    // expandable,
    // title: showTitle ? defaultTitle : undefined,
    // showHeader,
    footer: defaultFooter,
    // rowSelection,
    // scroll,
    // tableLayout,
  };

  return (
    <div>
      <Container style={{ padding: 10 }}>
        <StyledAntTable
          {...tableProps}
          style={{ paddingTop: 10 }}
          rowKey='no'
          tableId={'manageTour_bzp'}
          bordered
          size='small'
          showSorterTooltip={false}
          columns={columns}
          dataSource={
            dataInitDisabled?.open_to_bzp === true ? dataSourceTableEdit : []
          }
          loading={isLoading}
          scroll={{
            x: 'max-content',
            y: 'calc(100vh - 320px)',
            scrollToFirstRowOnChange: true,
          }}
          showContextMenu={true}
          menuActions={
            dataInitDisabled?.allow_edit === true
              ? [
                  {
                    label: 'Edit memo',
                    action: (row) => {
                      rowSelectDataRef.current = row;
                      form.setFieldsValue({
                        id: row?.id,
                        description: row?.manage_tour_list_memo,
                      });
                      if (
                        row?.manage_tour_list_status_bzp?.toLowerCase() ===
                          'request' ||
                        row?.manage_tour_list_status_bzp?.toLowerCase() ===
                          'reject'
                      ) {
                        ModalEditTable();
                      } else {
                        Modal.warning({ title: 'ไม่สามารถ Edit memo ได้' });
                      }
                    },
                  },
                ]
              : []
          }
          pagination={{
            disabled: disablePage,
            pageSize: PAGE_SIZE,
            total: totalRows,
            current: page,
            size: 'small',
            showSizeChanger: false,
            showQuickJumper: true,
            onChange: async (page, _) => {
              let bufferData = dataSourceTable;
              let requiredRows = page * PAGE_SIZE;
              requiredRows =
                requiredRows > totalRows && totalRows !== 0
                  ? totalRows
                  : requiredRows;

              if (requiredRows > bufferData.length) {
                // Fetch more data
                let curOffset = offset;
                do {
                  let queryRes = await executeQuery(curOffset, bufferData, {
                    ...payloadRef.current,
                    limit: LIMIT,
                    offset: curOffset,
                  });
                  curOffset += LIMIT;
                  bufferData = queryRes.data;
                  requiredRows =
                    requiredRows > queryRes.totalRows
                      ? queryRes.totalRows
                      : requiredRows;
                } while (requiredRows > bufferData.length);
              }
              setPage(page);
            },
          }}
        />
        <Form
          form={form}
          name='basic'
          labelCol={{
            span: 5,
          }}
          wrapperCol={{
            span: 24,
          }}
          autoComplete='off'
        >
          <Modal
            title={'Request / Reject Memo'}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={() => {
              setIsModalVisible(false);
            }}
            width={800}
            footer={false}
          >
            <div style={{ padding: '0px 10px 0px 10px' }}>
              <Form.Item label={''} name='id' hidden />

              <Form.Item
                label={''}
                name='description'
                rules={[
                  {
                    required: true,
                    message: t('reasonDashboard.form.description.message'),
                  },
                ]}
              >
                <TextArea
                  autoSize={{ minRows: 8, maxRows: 8 }}
                  placeholder={t(
                    'reasonDashboard.form.description.placeholder',
                  )}
                />
              </Form.Item>
            </div>

            <Row gutter={[20]}>
              <Col span={12} align='start'>
                <Button
                  type='primary'
                  size='small'
                  style={{
                    width: '110px',
                    marginTop: '20px',
                    marginRight: '20px',
                    height: 50,
                  }}
                  onClick={handleCancel}
                >
                  Cancel select
                </Button>

                <Button
                  type='primary'
                  size='small'
                  style={{
                    width: '110px',
                    marginTop: '20px',
                    height: 50,
                  }}
                  onClick={() => {
                    form.setFieldsValue({
                      description: null,
                    });
                  }}
                >
                  Clear memo
                </Button>
              </Col>
              <Col span={12} align='end'>
                <Button
                  type='primary'
                  size='small'
                  style={{ width: '90px', marginTop: '20px', height: 50 }}
                  onClick={modalSave}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Modal>

          <Modal
            title='Would you like to edit memo?'
            visible={isModalVisibleWarningEdit}
            onOk={handleOkWarningEdit}
            onCancel={handleCancelWarningEdit}
          >
            <Row gutter={[20]} align='center'>
              <Col span={20}>
                <p style={{ color: 'red' }}>Warning</p>
                <p style={{ color: 'red' }}>
                  xx Yard is lane capacity over (
                  {rowSelectDataRef.current?.working_hour})
                </p>
              </Col>
            </Row>
          </Modal>

          <Modal
            title='Would you like to Delete ?'
            visible={isModalVisibleDeleteData}
            onOk={handleOkDeleteData}
            onCancel={handleCancelDeleteData}
          >
            <Row gutter={[20]} align='center'>
              <Col span={20}>
                <p>Job Code : {rowSelectDataRef.current?.job_code}</p>
              </Col>
            </Row>
          </Modal>
        </Form>
      </Container>
    </div>
  );
};

const Container = styled.div`
  font-size: 13px;
`;

const StyledAntTable = styled(AntTable)`
  &&& {
    .ant-table-cell-scrollbar {
      border-top: unset !important;
      border-right: unset !important;
      border-bottom: unset !important;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border: 0.1px solid #000;
    }
    && thead > tr:hover > td {
      background: rgba(224, 248, 232, 1);
    }
  }
`;

export default TableComponent;
