import React, { Component } from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as TollWaySvg } from 'assets/icons/tollway.svg';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { MAP_SELECT_CANDIDATE, SELECT_MASTER_OR_CANDIDATE } from 'reducers/map';
import moment from 'moment';

const styles = {
  div_hover: {
    backgroundColor: '#C3DFFF',
    cursor: 'pointer',
    fontSize: 'small',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
  },
  div_selecting: {
    backgroundColor: '#89BFFE',
    cursor: 'pointer',
    fontSize: 'small',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
  },
  normal: {
    cursor: 'pointer',
    fontSize: 'small',
    padding: '5px',
    display: 'flex',
    flexDirection: 'row',
  },
  avatar: {
    margin: 5,
    width: 15,
    height: 15,
  },
};
class RoutesViewBoxes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonIsHovered: null,
      routesview: this.props.routesview,
    };
  }

  componentDidMount() {
    if (this.props.routesview.length > 0) {
      this.props.MAP_SELECT_CANDIDATE(this.props.routesview[0].id);
      this.props.SELECT_MASTER_OR_CANDIDATE(1);
    }
  }

  setButtonHovered = (param) => {
    this.setState({
      buttonIsHovered: param,
    });
  };

  onRouteClick = (id) => {
    this.props.MAP_SELECT_CANDIDATE(id);
    this.props.SELECT_MASTER_OR_CANDIDATE(1);
  };

  render() {
    return (
      <div
        style={{
          background: `white`,
          border: `1px solid #ccc`,
          padding: '0px',
          position: 'absolute',
          left: '10px',
          top: '100px',
        }}
      >
        {this.props.routesview.length > 0 &&
          this.props.routesview.map((list, idx) => {
            let TotalDistance = (list.TotalDistance / 1000).toFixed(2) + ' KM';
            //TollUsage
            var seconds = parseInt(list.TotalTime); //because moment js dont know to handle number in string format
            var hours =
              Math.floor(moment.duration(seconds, 'minutes').asHours()) !== 0
                ? Math.floor(moment.duration(seconds, 'minutes').asHours()) +
                  ' Hour '
                : '';
            var mins = moment.duration(seconds, 'minutes').minutes() + ' Mins';
            let TotalTime = hours + ' ' + mins;

            let select_style = styles.normal;
            if (
              this.state.buttonIsHovered === idx &&
              this.props.routeCandidate_selecting === list.id
            )
              select_style = styles.div_selecting;
            else if (this.state.buttonIsHovered === idx)
              select_style = styles.div_hover;
            else if (this.props.routeCandidate_selecting === list.id)
              select_style = styles.div_selecting;

            return (
              <div
                key={idx}
                style={select_style}
                onMouseEnter={() => this.setButtonHovered(idx)}
                onMouseLeave={() => this.setButtonHovered(null)}
                onClick={() => this.onRouteClick(list.id)}
              >
                <b>{idx + 1})</b>&nbsp;{list.name} - {TotalDistance} /{' '}
                {TotalTime}&nbsp;
                {list.TollUsage === '1' && (
                  <Icon component={TollWaySvg} style={{ fontSize: '20px' }} />
                )}
              </div>
            );
          })}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    routeCandidate_selecting: state.map.routeCandidate_selecting,
  };
};

const mapActionsToProps = {
  SELECT_MASTER_OR_CANDIDATE: SELECT_MASTER_OR_CANDIDATE,
  MAP_SELECT_CANDIDATE,
};

const enhance = compose(connect(mapStateToProps, mapActionsToProps));
export default enhance(RoutesViewBoxes);
