import React from 'react';

const DN = ({ dnData }) => {
  return (
    <div
      id='print_area'
      style={{
        width: '90%',
        paddingLeft: 40,
        paddingRight: 40,
        paddingTop: 50,
      }}
    >
      <table
        style={{
          height: '100%',
          width: '100%',
          border: '0px solid black',
        }}
      >
        <tr>
          <td
            style={{
              textAlign: 'top',
            }}
          >
            <table
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: '30px',
                fontFamily: 'Arail',
                borderCollapse: 'collapse',
                border: '0px solid black',
              }}
            >
              <tr>
                <td
                  style={{
                    width: '40%',
                  }}
                >
                  Delivery Note
                </td>
              </tr>
            </table>
            <br />
            <table
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: '14px',
                fontFamily: 'Arail',
                border: '1px solid #6C6B6B',
              }}
            >
              <tr>
                <td
                  style={{
                    width: '7%',
                    border: '0px solid black',
                    textAlign: 'center',
                  }}
                >
                  วันที่
                </td>
                <td
                  style={{
                    width: '15%',
                    border: '0px solid black',
                    textAlign: 'left',
                  }}
                >
                  <table
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      fontSize: '14px',
                      fontFamily: 'Arail',
                      border: '1px solid black',
                      borderRadius: '6px',
                    }}
                  >
                    <tr>
                      <td>30/08/2021</td>
                    </tr>
                  </table>
                </td>
                <td
                  style={{
                    width: '15%',
                    border: '0px solid black',
                    textAlign: 'right',
                  }}
                >
                  เลขที่:
                </td>
                <td
                  style={{
                    width: '15%',
                    border: '0px solid black',
                    textAlign: 'left',
                  }}
                >
                  <table
                    style={{
                      width: '100%',
                      textAlign: 'left',
                      fontSize: '14px',
                      fontFamily: 'Arail',
                      border: '0px solid black',
                      borderRadius: '6px',
                    }}
                  >
                    <tr>
                      <td>{dnData.no}</td>
                    </tr>
                  </table>
                </td>
                <td
                  style={{
                    border: '0px solid black',
                    textAlign: 'right',
                  }}
                >
                  <table
                    style={{
                      width: '100%',
                      textAlign: 'right',
                      fontSize: '12px',
                      fontFamily: 'Arail',
                      border: '0px solid black',
                      borderRadius: '6px',
                    }}
                  >
                    <tr>
                      <td>บริษัท โตโยต้า ทรานสปอร์ต (ประเทศไทย) จำกัด</td>
                    </tr>
                    <tr>
                      <td>
                        11/3 หมู่ที่ 1 ต.แสนภูดาษ อ.บ้านโพธิ์ จ.ฉะเชิงเทรา 24140
                      </td>
                    </tr>
                    <tr>
                      <td>โทร. 038-578125-8 แฟกซ์ 038-578129,038-578231</td>
                    </tr>
                  </table>
                </td>
              </tr>
              {/* <tr><td colspan='4'>รหัสลูกค้า</td><td>รหัสลูกค้า</td></tr> */}
            </table>

            <table
              style={{
                width: '100%',
                textAlign: 'left',
                fontSize: '12px',
                fontFamily: 'Arail',
                borderCollapse: 'collapse',
                border: '1px solid #6C6B6B',
              }}
            >
              <tr>
                <td
                  rowspan='2'
                  style={{
                    width: '52%',
                    textAlign: 'left',
                    border: '1px solid #6C6B6B',
                  }}
                >
                  รหัสลูกค้า
                </td>
                <td>ชื่อ พขร.</td>
              </tr>
              <tr>
                <td
                  style={{
                    textAlign: 'left',
                    border: '1px solid #6C6B6B',
                  }}
                >
                  หมายเลขรถเทรลเลอร์ : {dnData.trialNo} <br />
                  เวลารถออก
                </td>
              </tr>
            </table>
            <br />
            <table
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '12px',
                fontFamily: 'Arail',
                borderCollapse: 'collapse',
                border: '1px solid #6C6B6B',
              }}
            >
              <tr>
                <td width='20%'>แบบรถ</td>
                <td width='20%'>หมายเลขเครื่อง</td>
                <td width='20%'>หมายเลขตัวถัง</td>
                <td width='20%'>สี</td>
                <td width='20%'>หมายเหตุ</td>
              </tr>
            </table>
            <table
              style={{
                width: '100%',
                textAlign: 'center',
                fontSize: '12px',
                fontFamily: 'Arail',
                borderCollapse: 'collapse',
                border: '1px solid #6C6B6B',
              }}
            >
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Silver Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Gray Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Black Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>White Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Red Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Orange Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Yellow Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Green Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Blue Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Purple Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Pink Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
              <tr>
                <td width='20%'>GUN122R-CTFLXT B2</td>
                <td width='20%'>2GD 0938885</td>
                <td width='20%'>MR0C88CC200322989</td>
                <td width='20%'>Silver Me.</td>
                <td width='20%'>&nbsp;</td>
              </tr>
            </table>
            <table
              style={{
                position: 'fixed',
                bottom: '80px',
                width: '87%',
                textAlign: 'left',
                fontSize: '12px',
                fontFamily: 'Arail',
                borderCollapse: 'collapse',
                border: '0px solid black',
              }}
            >
              <tr>
                <td colspan='3'>
                  <hr color='black' size='1' />
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default DN;
