import React from 'react';
import moment from 'moment-timezone';

export function userGroupFormatter(params) {
  if (params.value) {
    return Array.isArray(params.value)
      ? params.value.length > 0
        ? params.value.map((d) => d.name).join(',')
        : '-'
      : params.value;
  } else {
    return params.value;
  }
}

export function bzpYardsFormatter(params) {
  if (params.value) {
    return Array.isArray(params.value)
      ? params.value.length > 0
        ? params.value.map((d) => d.code).join(',')
        : '-'
      : params.value;
  } else {
    return params.value;
  }
}

export function latLngFormatter(params) {
  return params.value && typeof params.value.toFixed === 'function'
    ? parseFloat(params.value.toFixed(6))
    : params.value;
}

export function speedFormatter(params) {
  return params.value && typeof params.value.toFixed === 'function'
    ? parseFloat(params.value.toFixed(2))
    : params.value;
}

export function integerFormatter(params) {
  return params.value ? parseInt(params.value) : params.value;
}

export function numberFormatter(params) {
  return params.value ? formatNumber(params.value) : params.value;
}

export function formatNumber(number) {
  return Number.isInteger(number)
    ? Math.floor(number)
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    : number;
}

export function dateUnixFormatter(params) {
  return params.value ? moment(params.value * 1000).format('YYYY/MM/DD') : '-';
}

export function mileageToKmFormatter(params) {
  // Convert from meter to km
  return params.value ? parseInt(params.value / 1000) : '-';
}

export function timeUnixFormatter(params) {
  return params.value ? moment(params.value * 1000).format('HH:mm:ss') : '-';
}

export function dateTimeUnixFormatter(params) {
  return params.value
    ? moment(params.value * 1000).format('YYYY/MM/DD HH:mm:ss')
    : '-';
}
export function dateTimeTimestamptzFormatter(params) {
  return params.value ? moment(params.value).format('DD/MM/YY HH:mm') : '';
}
export function dateTimeForVinFormatter(params) {
  return params.value ? moment(params.value).format('MM/DD/YYYY HH:mm') : '';
}

export function dateTimeYearTimestamptzFormatter(params) {
  return params.value ? moment(params.value).format('DD/MM/YYYY') : '';
}
export function dateYearWorkingDate(params) {
  let date = params.value;
  const [dateFormat] = date.split(' ');
  const [day, month, year] = dateFormat.split('/');
  const result = [month, day, year].join('/');

  // console.log('result',   result)
  let t = moment(result).format();
  //  console.log('t',   t)
  return t ? moment(t).format('DD/MM/YY') : '';
}
export function dateTimeNotYearTimestamptzFormatter(params) {
  return params.value ? moment(params.value).format('DD/MM/YY HH:mm') : '';
}

export function ddmmyyFormatter(params) {
  return params.value ? moment(params.value).format('DD/MM/YY') : '-';
}

export function dateFormatter(params) {
  return params.value ? moment(params.value).format('YYYY/MM/DD') : '-';
}

export function dateISOFormatter(params) {
  return params.value ? moment(params.value).format('YYYY-MM-DD') : '-';
}

export function dateFormatterYear(params) {
  return params.value ? moment(params.value).format('DD/MM/YY HH:mm') : '-';
}

export function timeFormatter(params) {
  return params.value ? moment(params.value).format('HH:mm') : '-';
}

export function timeFormatterNoHyphen(params) {
  return params.value ? moment(params.value).format('HH:mm') : '';
}
export function timeFormatterYardOut(params) {
  return params.value ? moment(params.value).format('HH:mm') : '';
}
export function jobVehiclesFormatter(params) {
  return params.value && params.value.length > 0
    ? params.value[0].vehicle_license
    : '-';
}

export function jobRouteTypeFormatter(params) {
  return params.value === 0 ? 'any' : 'fixed';
}

export function jobProgressValueFormatter(params) {
  let totalSeqs = params.data.job_seqs.length;
  let currentSeq = params.data.current_seq
    ? params.data.current_seq.seq_order
    : 0;
  return `${currentSeq.toString()}/${totalSeqs.toString()}`;
}

export function jobPlanTimeFormatter(params) {
  if (params.value) {
    let value = params.value.filter(
      (v) => v.location_config.location_type === params.colDef.type
    );

    return value.length > 0 && moment(value[0].location_config.plan_time)
      ? moment(value[0].location_config.plan_time).format('HH:mm')
      : '-';
  } else {
    return '-';
  }
}

export function jobActualTimeFormatter(params) {
  if (params.value) {
    let value = params.value.filter(
      (v) => v.location_config.location_type === params.colDef.type
    );

    return value.length > 0 && value[0].location_config.actual_time
      ? moment(value[0].location_config.actual_time).format('HH:mm')
      : '-';
  } else {
    return '-';
  }
}

export function jobETAFormatter(params) {
  if (params.value) {
    let value = params.value.filter(
      (v) => v.location_config.location_type === params.colDef.type
    );

    return value.length > 0 && value[0].location_config.eta
      ? parseInt(value[0].location_config.eta / 60)
      : '-';
  } else {
    return '-';
  }
}

export function jobServiceTimeFormatter(params) {
  return params.value ? parseInt(params.value / 60) : '-';
}

export function dayOfWeekFormatter(params) {
  return params.value ? params.value : '-';
}

export function countValueFormatter(params) {
  return params.value ? params.value.length : 0;
}

export function countFleetAdminValueFormatter(params) {
  return params.value
    ? params.value.filter((u) => u.FleetUser.is_owner).length
    : 0;
}

export function countFleetOperatorValueFormatter(params) {
  return params.value
    ? params.value.filter((u) => u.FleetUser.is_owner === false).length
    : 0;
}

export function fleetDisplayFormatter(params) {
  return params.value && params.value.length > 0
    ? params.value.map((d) => d.name).join(',')
    : 'Unassigned';
}

export function vehicleLicenseFormatter(params) {
  return params.value ? params.value : 'Unassigned';
}

export function geofenceTypeFormatter(params) {
  return params.value != null ? (params.value === 1 ? 'IN' : 'OUT') : '-';
}

export function geofencePrivacyFormatter(params) {
  return params.value != null
    ? params.value === 1
      ? 'PUBLIC'
      : 'PRIVATE'
    : '-';
}

export function commandStatusFormatter(params) {
  switch (params.value) {
    case 0:
      return 'Waiting to send';
    case 1:
      return 'Waiting reply';
    default:
      return 'Done';
  }
}

export function deliveryStatusFormatter(params) {
  return params.value != null ? params.value : '-';
}

export function dateTimeFormatterWorking(params) {
  let date = params.value ? moment(params.value).format('ddd') : '';
  let datetime = params.value ? moment(params.value).format('DD/MM/YY') : '';
  return params.value ? (
    <>
      {date}
      <br />
      <div style={{ marginLeft: 2, marginRight: 2 }}>{datetime}</div>
    </>
  ) : (
    ''
  );
}
export function dateFormatterVLP(params) {
  return params.value
    ? moment(params.value, 'DD/MM/YYYY').format('DD/MM/YY')
    : '';
}

export function reasonFormatter(params) {
  let reason = params?.reason;
  if (!reason) {
    return '-';
  }
  let category = reason?.subject_reason?.group?.name;
  if (!category) {
    return '-';
  }
  let problem = reason?.subject_reason?.name_en;
  if (!problem) {
    return '-';
  }
  if (reason?.subject_reason?.is_other && params.description) {
    return `${category}/${problem}/${params.description}`;
  } else {
    return `${category}/${problem}`;
  }
}

export function statusCallingFormatter(params) {
  let re_job_time = params?.receive_job_datetime
    ? moment(params.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
    : null;
  if (!re_job_time) {
    return '-';
  }

  let calling_time = params?.calling_making_time
    ? moment(params.calling_making_time).format('YYYY-MM-DD HH:mm:ss')
    : null;
  if (!calling_time) {
    return '-';
  }

  let date_re_job = moment(re_job_time).format('YYYY-MM-DD HH:mm:ss');
  let date_calling = moment(calling_time).format('YYYY-MM-DD HH:mm:ss');

  if (moment(date_re_job) >= moment(date_calling)) {
    return 'On time';
  } else {
    return 'Delay';
  }
}
export function statusMatchPlanFormatter(params) {
  let criteriaData = params.criteria.find((c) => c.code === 110);
  let min_criteria = criteriaData?.min ? criteriaData.min : 0;
  let value_criteria = criteriaData?.value ? criteriaData.value : 120;

  let re_job_time = params?.receive_job_datetime
    ? moment(params.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
    : null;
  if (!re_job_time) {
    return '-';
  }

  let date_re_job = moment(re_job_time).format('YYYY-MM-DD HH:mm:ss');
  let dateActual = params?.actual_match_calling_datetime
    ? moment(params.actual_match_calling_datetime).format('YYYY-MM-DD HH:mm:ss')
    : moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

  if (
    moment(dateActual).diff(moment(date_re_job), 'minutes') <= min_criteria &&
    moment(dateActual).diff(moment(date_re_job), 'minutes') >= -value_criteria
  ) {
    return 'Warning';
  } else if (
    moment(dateActual).diff(moment(date_re_job), 'minutes') >= min_criteria
  ) {
    return 'Delay';
  } else {
    return 'On time';
  }
}

export function statusConfirmTrDvFormatter(params) {
  let criteriaData = params.criteria.find((c) => c.code === 111);
  let min_criteria = criteriaData?.min ? criteriaData?.min : 0;
  let value_criteria = criteriaData?.value ? criteriaData?.value : 60;

  let re_job_time = params?.receive_job_datetime
    ? moment(params.receive_job_datetime).format('YYYY-MM-DD HH:mm:ss')
    : null;
  if (!re_job_time) {
    return '-';
  }

  let date_re_job = moment(re_job_time).format('YYYY-MM-DD HH:mm:ss');
  let dateActual = params?.actual_confirm_tr_dv_datetime
    ? moment(params.actual_confirm_tr_dv_datetime).format('YYYY-MM-DD HH:mm:ss')
    : moment(Date.now()).format('YYYY-MM-DD HH:mm:ss');

  if (
    moment(dateActual).diff(moment(date_re_job), 'minutes') <= min_criteria &&
    moment(dateActual).diff(moment(date_re_job), 'minutes') >= -value_criteria
  ) {
    return 'Warning';
  } else if (
    moment(dateActual).diff(moment(date_re_job), 'minutes') >= min_criteria
  ) {
    return 'Delay';
  } else {
    return 'On time';
  }
}
export function dateTimeDDMM(params) {
  return params.value ? moment(params.value).format('DD/MM HH:mm') : '';
}
