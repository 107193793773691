import React, { useEffect, useState, useRef } from 'react';
import {
  Form,
  Input,
  Modal,
  Button,
  message,
  Divider,
  PageHeader,
  Row,
  Col,
} from 'antd';

import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useErrorHandler } from 'common_components/ErrorContext';
import digitalMapUtils from '../../Utils';
import { encode } from '@googlemaps/polyline-codec';

import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';
import { routeMasterApi } from 'reducers/digitalmap/routeMaster/api';
import { goBack } from 'connected-react-router';
import { mapDrawingMode, mapResetMap } from 'reducers/map';
import {
  mapSetBounds,
  mapSetMasterLegs,
  mapSetMasterPolylines,
  MAP_LOG,
  LOAD_MODIFIED_ROUTE_SUCCESS,
  SET_MAP_LOADING,
  SET_USE_TOLL,
} from 'reducers/map';

const RouteMasterForm = (props) => {
  const { formData } = props;
  const [form] = Form.useForm();
  const tollRef = useRef(formData && parseInt(formData.TollUsage));
  const [waypointsPayload, setWaypointsPayload] = useState(null);
  const [routeMaster, setRouteMaster] = useState(formData);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();

  const mapWaypoints = useSelector((state) => {
    return state.map.waypoints;
  });
  const useToll = useSelector((state) => {
    return state.map.use_toll;
  });
  const currentMarker = useSelector((state) => {
    return state.map.marker_MovingAdding;
  });
  const markerDragging = useSelector((state) => {
    return state.map.marker_Dragging;
  });

  useEffect(() => {
    if (formData) {
      let legs = digitalMapUtils.setLegs(formData.Legs, false);
      let bounds = digitalMapUtils.setBoundsWaypoints(formData.Legs);

      let waypoints = [];
      legs.forEach((leg, index) => {
        waypoints.push({
          position: { lat: leg.path[0].lat, lng: leg.path[0].lng },
        });
      });

      let last_leg_idx = legs.length - 1;
      let last_path_idx = legs[last_leg_idx].path.length - 1;

      waypoints.push({
        position: {
          lat: legs[last_leg_idx].path[last_path_idx].lat,
          lng: legs[last_leg_idx].path[last_path_idx].lng,
        },
      });

      dispatch(mapSetMasterLegs({ legs: legs, waypoints: waypoints }));
      dispatch(mapSetMasterPolylines(waypoints));
      dispatch(mapSetBounds(bounds));

      // First time display route from stored data
      dispatch(
        LOAD_MODIFIED_ROUTE_SUCCESS({
          legs: legs,
          modified_route: formData,
          TollUsage: formData.TollUsage,
        })
      );
      // Update map tollway checkbox to be the same as stored data
      dispatch(SET_USE_TOLL(parseInt(formData.TollUsage)));
    }
  }, [formData, dispatch]);

  useEffect(() => {
    if (mapWaypoints) {
      let paths = [];
      mapWaypoints.forEach((point, i) => {
        paths.push([point.position.lat, point.position.lng]);
      });
      let encodedURI = encodeURIComponent(encode(paths));

      // Request new route when
      // 1. Toggle tollway
      // 2. Add marker
      // 3. Move marker
      if (
        tollRef.current !== useToll ||
        (currentMarker !== -1 && markerDragging === -1)
      ) {
        setWaypointsPayload({
          encodedWayPoints: encodedURI,
          tollway: useToll !== null ? useToll : 0,
        });
      }
    }
  }, [mapWaypoints, useToll, currentMarker, markerDragging]);

  useEffect(() => {
    if (waypointsPayload && waypointsPayload.encodedWayPoints) {
      (async () => {
        try {
          dispatch(SET_MAP_LOADING(true));
          let result = await dispatch(
            routeMasterApi.endpoints.findWayPointsRouting.initiate(
              waypointsPayload
            )
          );
          if (result && result.error) {
            setErrorData(result.error);
          } else {
            if (
              result.data &&
              result.data.list &&
              result.data.list.length > 0
            ) {
              let modified_route = result.data.list[0];
              dispatch(MAP_LOG, modified_route);
              let legs = digitalMapUtils.setLegs(modified_route.Legs, false);
              let TollUsage = modified_route.TollUsage;
              dispatch(
                LOAD_MODIFIED_ROUTE_SUCCESS({
                  legs: legs,
                  modified_route: modified_route,
                  TollUsage: TollUsage,
                })
              );
              setRouteMaster(modified_route);
            }
          }
          // Update ref tollway for next changed comparison
          tollRef.current = waypointsPayload.tollway;
        } catch (err) {
          message.error('Something went wrong');
        } finally {
          dispatch(SET_MAP_LOADING(false));
        }
      })();
    }
  }, [waypointsPayload, dispatch, setErrorData]);

  const onFinish = async (values) => {
    Modal.confirm({
      title: 'Are you sure want to submit?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: async () => {
        try {
          setIsSubmitting(true);

          const payload = {
            id: formData.id,
            name: values.name,
            routes: routeMaster,
          };
          let result = await dispatch(
            routeMasterApi.endpoints.updateRouteMaster.initiate(payload)
          );
          if (result && result.error) {
            setErrorData(result.error);
          } else {
            message.success('Data was successfully saved');
          }
        } catch (error) {
          message.error('Something went wrong');
        } finally {
          setIsSubmitting(false);
        }
      },
    });
  };

  const onBack = () => {
    dispatch(mapDrawingMode(null));
    dispatch(mapResetMap());

    setTimeout(() => {
      dispatch(goBack());
    }, 100);
  };

  return (
    <>
      <Form
        form={form}
        name='basic'
        wrapperCol={{
          span: 20,
        }}
        layout='vertical'
        style={{ padding: '10px 10px 0px 10px' }}
        onFinish={onFinish}
        autoComplete='off'
      >
        <StyledPageHeader
          ghost={false}
          onBack={onBack}
          title='Edit Route Master'
          subTitle=''
          extra={[
            <Button
              size='small'
              key='search-button'
              type='primary'
              htmlType='submit'
              loading={isSubmitting}
            >
              Submit
            </Button>,
          ]}
        >
          <Divider style={{ marginTop: '0px', marginBottom: '0px' }} />

          <Form.Item
            label='Name'
            name='name'
            initialValue={formData && formData.name}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Priority'
                name='priority'
                initialValue={formData && formData.priority}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Toll'
                name='TollUsage'
                initialValue={(() => {
                  if (formData && formData.TollUsage != null) {
                    if (formData.TollUsage === '0') {
                      return 'No';
                    } else if (formData.TollUsage === '1') {
                      return 'Yes';
                    } else {
                      return '-';
                    }
                  } else {
                    return '-';
                  }
                })()}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Duration (min)'
                name='TotalTime'
                initialValue={formData && formData.TotalTime}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Distance (km)'
                name='TotalDistance'
                initialValue={(() => {
                  if (formData && formData.TotalDistance) {
                    return (formData.TotalDistance / 1000).toFixed(2);
                  }
                })()}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Divider
            orientation='left'
            style={{ marginTop: '0px', marginBottom: '0px' }}
          >
            Origin
          </Divider>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Type'
                name='originType'
                initialValue={(() => {
                  let result = '';
                  if (
                    formData &&
                    formData.origin &&
                    formData.origin.location_type
                  ) {
                    result = formData.origin.location_type.name;
                  }
                  return result;
                })()}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Name'
                name='origin'
                initialValue={(() => {
                  let result = '';
                  if (formData && formData.origin) {
                    result = formData.origin.short_name;
                  }
                  return result;
                })()}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
          <Divider
            orientation='left'
            style={{ marginTop: '0px', marginBottom: '0px' }}
          >
            Destination
          </Divider>
          <Row>
            <Col span={12}>
              <Form.Item
                label='Type'
                name='destinationType'
                initialValue={(() => {
                  let result = '';
                  if (
                    formData &&
                    formData.destination &&
                    formData.destination.location_type
                  ) {
                    result = formData.destination.location_type.name;
                  }
                  return result;
                })()}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label='Name'
                name='destination'
                initialValue={(() => {
                  let result = '';
                  if (formData && formData.destination) {
                    result = formData.destination.short_name;
                  }
                  return result;
                })()}
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
          </Row>
        </StyledPageHeader>
      </Form>
    </>
  );
};
const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
  .ant-page-header-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export default RouteMasterForm;
