import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Modal,
  Row,
  Col,
  message,
  Select,
  DatePicker,
} from 'antd';
import {
  useUpdateDiagramPlanMutation,
  useCreateDiagramPlanMutation,
} from 'reducers/dispatchBoard/api';
import { useFindAllTrailersOfBzpQuery } from 'reducers/masterData/trailer/api';
import { useFindAllDriversOfBzpQuery } from 'reducers/masterData/driver/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { goBack } from 'connected-react-router';
import moment from 'moment';
import styled from 'styled-components';

const { Option } = Select;

const DiagramPlanDetail = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const [trailers, setTrailers] = useState([]);
  const [drivers, setDrivers] = useState([]);

  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (formData) {
      form.setFieldsValue({
        job_code: formData.job_code,
        trip_no: formData.trip_no,
        receive_job_datetime: moment(formData.receive_job_datetime),
        yard_out_datetime: moment(formData.yard_out_datetime),
        status: formData.status,
        trailer:
          formData.trailers &&
          formData.trailers.length > 0 &&
          formData.trailers[0].trailer_card_id,
        driver1:
          formData.drivers &&
          formData.drivers.length > 0 &&
          formData.drivers[0].employee_code,
        driver2:
          formData.drivers &&
          formData.drivers.length > 1 &&
          formData.drivers[1].employee_code,
      });
    }
  }, [form, formData]);

  const [
    createDiagramPlan,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateDiagramPlanMutation();

  const [
    updateDiagramPlan,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateDiagramPlanMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  // Query all trailers
  const {
    data: dataTrailers,
    error: errorTrailers,
    isFetching: isTrailersFetching,
  } = useFindAllTrailersOfBzpQuery(
    formData && formData.bzp && formData.bzp.code,
    {
      skip: !user || !formData || !formData.bzp,
    }
  );

  useEffect(() => {
    if (dataTrailers) {
      setTrailers(
        dataTrailers.map((d) => ({ id: d.id, label: d.trailer_card_id }))
      );
    }
  }, [dataTrailers]);

  useEffect(() => {
    if (errorTrailers) {
      setErrorData(errorTrailers);
    }
  }, [errorTrailers, setErrorData]);

  // Query all drivers
  const { data: dataDrivers, error: errorDrivers } =
    useFindAllDriversOfBzpQuery(formData && formData.bzp && formData.bzp.code, {
      skip: !user || !formData || !formData.bzp,
    });

  useEffect(() => {
    if (dataDrivers) {
      setDrivers(
        dataDrivers.map((d) => ({ id: d.id, label: d.employee_code }))
      );
    }
  }, [dataDrivers]);

  useEffect(() => {
    if (errorDrivers) {
      setErrorData(errorDrivers);
    }
  }, [errorDrivers, setErrorData]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);

    let trailerList = [];
    let driverList = [];
    let updatedTrailer = trailers.find((c) => c.label === validData.trailer);
    let updatedDriver1 = drivers.find((c) => c.label === validData.driver1);
    let updatedDriver2 = drivers.find((c) => c.label === validData.driver2);

    let receiveJobDatetime = moment(validData.receive_job_datetime).format(
      'YYYY-MM-DD HH:mm:ss'
    );
    let yardOutDateTime = moment(validData.yard_out_datetime).format(
      'YYYY-MM-DD HH:mm:ss'
    );

    if (updatedTrailer) {
      trailerList.push(updatedTrailer.id);
    }
    if (updatedDriver1) {
      driverList.push(updatedDriver1.id);
    }
    if (updatedDriver2) {
      driverList.push(updatedDriver2.id);
    }
    let postData = {
      id: formData && formData.id,
      ...validData,
      receive_job_datetime: receiveJobDatetime,
      yard_out_datetime: yardOutDateTime,
      trailers: trailerList,
      drivers: driverList,
    };

    if (isCreate) {
      createDiagramPlan(postData);
    } else {
      updateDiagramPlan(postData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 6,
      }}
      wrapperCol={{
        span: 18,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Row>
        <Col span={12}>
          <Form.Item
            label='Job Code'
            name='job_code'
            initialValue={formData && formData.job_code}
            rules={[
              {
                required: true,
                message: 'Please input Job Code',
              },
            ]}
          >
            <Input disabled={readonly} placeholder={'Job Code'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Trailer'
            name='trailer'
            // initialValue={formData && formData.trailer}
          >
            <Select
              showSearch
              placeholder='Select a trailer'
              allowClear={true}
              optionFilterProp='children'
              loading={isTrailersFetching}
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {trailers &&
                trailers.map((c, index) => (
                  <Option key={index} value={c.label}>
                    {c.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            label='Trip No'
            name='trip_no'
            initialValue={formData && formData.trip_no}
            rules={[
              {
                required: true,
                message: 'Please input Trip No.',
              },
            ]}
          >
            <Input disabled={readonly} placeholder={'Trip no'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Driver 1'
            name='driver1'
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('driver2') !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The driver is already assigned')
                  );
                },
              }),
            ]}
          >
            <Select
              showSearch
              placeholder='Select a driver'
              allowClear={true}
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {drivers &&
                drivers.map((c, index) => (
                  <Option key={index} value={c.label}>
                    {c.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item
            label='Status'
            name='status'
            initialValue={formData && formData.status}
          >
            <Select
              showSearch
              placeholder='Select a status'
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {[
                'Yard out complete',
                'Yard out not complete',
                'Cancel',
                'Finish',
              ].map((c, index) => (
                <Option key={index} value={c}>
                  {c}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='Driver 2'
            name='driver2'
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('driver1') !== value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The driver is already assigned')
                  );
                },
              }),
            ]}
          >
            <Select
              showSearch
              placeholder='Select a driver'
              allowClear={true}
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {drivers &&
                drivers.map((c, index) => (
                  <Option key={index} value={c.label}>
                    {c.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <StyledFormItem
            label='Receive Date/Time'
            name='receive_job_datetime'
            initialValue={formData && moment(formData.receive_job_datetime)}
          >
            <DatePicker showTime />
          </StyledFormItem>
        </Col>
        <Col span={12}>
          <StyledFormItem
            label='Yard Out Date/Time'
            name='yard_out_datetime'
            initialValue={formData && moment(formData.yard_out_datetime)}
          >
            <DatePicker showTime />
          </StyledFormItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <Form.Item label='Delay Reason' name='reason'>
            <Input />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        wrapperCol={{
          offset: 22,
          span: 2,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

const StyledFormItem = styled(Form.Item)`
  &&& {
    .ant-form-item-label {
      white-space: normal;
    }
    .ant-form-item-label > label {
      height: 36px;
    }
  }
`;
export default DiagramPlanDetail;
