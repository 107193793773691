import React from 'react';
import { useReactToPrint } from 'react-to-print';
import { Button } from 'antd';

const ButtonPrint = (props) => {
  const { componentRef } = props;

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Button onClick={handlePrint} {...props}>
      {props.children}
    </Button>
  );
};

export default ButtonPrint;
