import React, { useState, useRef } from 'react';
import AntTable from 'common_components/AntTable';
import './index.css';
import styled from 'styled-components';
import { Typography, Row, Col, Button, Space } from 'antd';
const { Text } = Typography;
const TableSubCluster = (props) => {
  const {
    selectedRowKeys,
    setSelectedRowKeys,
    dataClusterByRegionTable,
    callApiGetShowSubCluster,
    isLoadingGetClusterByRegion,
  } = props;

  const disabledButton = useRef(false);
  const columnsHeader = [
    {
      title: 'To',
      dataIndex: ['cluster', 'name'],
      align: 'center',
      width: '40%',
      render(text, record) {
        return {
          props: {
            style: {
              background: record.isHighlightOrange ? '#FFC000' : null,
              color: record.isRedText ? 'rgb(255 13 0)' : null,
            },
          },
          children: <>{text}</>,
        };
      },
    },
    {
      title: 'Remain',
      dataIndex: 'remain',
      align: 'center',
      width: '40%',
      render(text, record) {
        return {
          props: {
            style: {
              background: record.isHighlightOrange ? '#FFC000' : null,
              color: record.isRedText ? 'rgb(255 13 0)' : null,
            },
          },
          children: <>{text}</>,
        };
      },
    },
  ];

  const onShowSubCluster = (select) => {
    callApiGetShowSubCluster([select]);
    disabledButton.current = true;
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      disabledButton.current = false;
    },
  };

  return (
    <Container>
      <Space size={10} style={{ marginBottom: '10px' }}>
        <Space size={10} direction='vertical'>
          <Row>
            <Col>
              <Text>Select cluster</Text>
            </Col>
          </Row>

          <Row gutter={[10]}>
            <Col>
              <Button
                onClick={() => onShowSubCluster(selectedRowKeys)}
                disabled={selectedRowKeys.length < 1 || disabledButton.current}
              >
                Show Sub cluster
              </Button>
            </Col>
          </Row>
        </Space>
      </Space>
      <AntTable
        rowKey={(record) => {
          return record.cluster.id;
        }}
        tableId={'id'}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
        }}
        bordered
        showSorterTooltip={false}
        columns={columnsHeader}
        dataSource={dataClusterByRegionTable}
        scroll={{ y: 300, x: 'max-content' }}
        pagination={false}
        loading={isLoadingGetClusterByRegion}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableSubCluster;
