import React, { useState, useEffect, useCallback } from 'react';

import AntTable from 'common_components/AntTable';

import EditButtonRenderer from 'common_components/EditButtonRenderer';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import TableToolbar from 'common_components/AntTable/Toolbar';
import { push } from 'connected-react-router';
import permission from 'permissions';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import {
  useDeleteCompanyMutation,
  useDestroyCompanyMutation,
} from 'reducers/company/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { Sorter } from 'utils/sorter';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import { message } from 'antd';

const TableComponent = (props) => {
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [showDelete, setShowDelete] = useState(false);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'company')
  );

  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: '',
        dataIndex: 'edit',
        align: 'center',
        render: (text, record) => {
          return (
            <EditButtonRenderer data={record} onClick={handleEditClicked} />
          );
        },
        width: 50,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Fullname (EN)',
        dataIndex: 'fullname_en',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Fullname (TH)',
        dataIndex: 'fullname_th',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Shortname (EN)',
        dataIndex: 'shortname_en',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Shortname (TH)',
        dataIndex: 'shortname_th',
        width: 150,
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
        searchable: true,
      },
      {
        title: 'Updated time',
        dataIndex: 'updated_at',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DATE,
        },
        movable: true,
      },
      {
        title: 'Created time',
        dataIndex: 'created_at',
        render: (text, record) => {
          return dateTimeTimestamptzFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DATE,
        },
        movable: true,
      },
    ];

    if (user && user.is_superadmin) {
      cols.splice(1, 0, {
        title: 'Is Active',
        dataIndex: 'is_active',
        width: 100,
        render: (text, record) => {
          return text.toString();
        },
        sorter: {
          compare: Sorter.DEFAULT,
        },
        movable: true,
      });
    }
    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }
    return cols;
  });

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  const [
    deleteCompany,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
      isLoading: isDeleting,
    },
  ] = useDeleteCompanyMutation();

  const [
    destroyCompany,
    {
      isSuccess: isSuccessDestroying,
      isUninitialized: isUninitDestroying,
      error: errorDestroying,
      isLoading: isDestroying,
    },
  ] = useDestroyCompanyMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
    if (errorDestroying) {
      setErrorData(errorDestroying);
    }
  }, [errorDeleting, errorDestroying, setErrorData]);

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      clearSelectedRows();
    }
  }, [isSuccessDeleting, isUninitDeleting]);

  useEffect(() => {
    if (!isUninitDestroying && isSuccessDestroying) {
      message.success('Data was successfully deleted');
    }
  }, [isSuccessDestroying, isUninitDestroying]);

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
    }
  }, [isSuccessDeleting, isUninitDeleting]);

  const onSearch = useCallback(
    (searchTerm) => {
      if (rowData && rowData.length > 0) {
        let searchCols = columns.filter((c) => c.searchable);
        if (searchCols.length > 0 && searchTerm) {
          let filterData = rowData.filter((d) =>
            searchCols.some(
              (s) =>
                d[s.dataIndex] &&
                d[s.dataIndex]
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
            )
          );
          setDataSource(filterData);
        } else {
          setDataSource(rowData);
        }
      }
      clearSelectedRows();
    },
    [rowData, columns]
  );

  const onCreate = () => {
    dispatch(push(`/app/user_mng/companies/create`));
  };

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/user_mng/companies/${cell.id}`));
  };

  const onDelete = (isPermanentDelete) => {
    // TODO: Bulk delete
    for (let row of selectedRows) {
      if (isPermanentDelete) {
        destroyCompany({ id: row.id });
      } else {
        deleteCompany({ id: row.id, is_active: false });
      }
    }

    clearSelectedRows();
  };

  const onReload = () => {
    props.refetchData();
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setSelectedRowKeys([]);
    setShowDelete(false);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRows);

      if (permission.checkPermission(user, 'company', ['d'])) {
        setShowDelete(selectedRows.length > 0 ? true : false);
      }
    },
  };

  return (
    <Container>
      <TableToolbar
        onSearch={onSearch}
        onCreate={onCreate}
        onDelete={onDelete}
        onReload={onReload}
        showDelete={showDelete}
        showSearch={true}
        showReload={true}
        isDeleting={isDeleting || isDestroying}
        isLoading={isLoading}
        showPermanentDelete={permission.isSuperAdmin(user)}
        showAdd={permission.checkPermission(user, 'company', ['c'])}
        columns={columns}
        setColumns={setColumns}
      />
      <AntTable
        rowKey='id'
        tableId={'company'}
        rowSelection={rowSelection}
        bordered
        showSorterTooltip={false}
        loading={isLoading || isDeleting || isDestroying}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content' }}
        // Below is for right click context menu
        showContextMenu={true}
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        menuActions={[
          { label: 'Edit', action: (row) => handleEditClicked(row) },
          {
            label: 'Delete',
            action: (row, isPermanentDelete) => {
              if (isPermanentDelete) {
                destroyCompany({ id: row.id });
              } else {
                deleteCompany({ id: row.id, is_active: false });
              }
            },
            delete: true,
          },
        ]}
        showPermanentDelete={permission.isSuperAdmin(user)}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default TableComponent;
