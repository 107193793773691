import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';
import { useSelector } from 'react-redux';
import useWindowSize from 'hooks/windowSize';
import { Select, Tooltip, Button, Row, Col, Modal } from 'antd';
import { getLanguage } from 'reducers/language';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { Option } = Select;

const TableEditComponent = (props) => {
  const {
    dataSourceEdit,
    onSave,
    isLoading,
    selectedFromLaneField,
    selectedDestinationLaneField,
  } = props;
  const language = useSelector(getLanguage);
  const [tableHeight, setTableHeight] = useState(0);
  const { height } = useWindowSize();

  useEffect(() => {
    const showFilter = true;
    if (height) {
      let h = height - 360;
      if (!showFilter) {
        h = h + 100;
      }
      setTableHeight(h);
    }
  }, [height]);

  const showModal = (value, mode) => {
    props.editReason(value, mode);
  };

  const columns = (() => {
    let cols = [
      {
        title: '',
        dataIndex: 'reasonTrailerLane',
        align: 'center',
        width: 100,
        movable: true,
      },
      {
        title: 'Receive job',
        dataIndex: ['reason_receive_job', 'reason', 'subject_reason', 'code'],
        width: 120,
        align: 'center',
        ellipsis: true,
        render: (text, record) => {
          // ID = 1 is reason
          // ID = 2 is lane
          if (record?.id === 1) {
            if (record?.reason_receive_job?.reasonEdit) {
              return (
                <Tooltip
                  placement='bottom'
                  title={
                    record.reason_receive_job?.reasonEdit?.value?.subject
                      ? record.reason_receive_job?.reasonEdit?.value?.subject.substring(
                          5
                        )
                      : ''
                  }
                >
                  <Button
                    shape='round'
                    size='small'
                    style={{
                      fontSize: 12,
                      whiteSpace: 'normal',
                      height: 'auto',
                      margin: '5px',
                    }}
                    onClick={() =>
                      showModal(
                        record.reason_receive_job?.reasonEdit?.value,
                        'Receive Job'
                      )
                    }
                  >
                    {record.reason_receive_job?.reasonEdit &&
                      record.reason_receive_job?.reasonEdit?.label}
                  </Button>
                </Tooltip>
              );
            } else if (text) {
              return (
                <Tooltip
                  placement='bottom'
                  title={
                    language === 'th'
                      ? record.reason_receive_job?.reason?.subject_reason
                          ?.name_th
                      : record.reason_receive_job?.reason?.subject_reason
                          ?.name_en
                  }
                >
                  <Button
                    shape='round'
                    size='small'
                    style={{
                      fontSize: 12,
                      whiteSpace: 'normal',
                      height: 'auto',
                      margin: '5px',
                    }}
                    onClick={() =>
                      showModal(record.reason_receive_job, 'Receive Job')
                    }
                  >
                    {text}
                  </Button>
                </Tooltip>
              );
            } else {
              return '-';
            }
          } else {
            if (
              record?.actual_receive_job_datetime &&
              record.trailerLaneFromOptions
            ) {
              return (
                <Select
                  id={'trailer_lane'}
                  size={'small'}
                  style={{
                    width: '180px',
                  }}
                  showSearch
                  placeholder={'select'}
                  optionFilterProp='children'
                  value={
                    record.trailer_lane_receive_job &&
                    record.trailer_lane_receive_job?.lane_no
                  }
                  onSelect={(value) => {
                    selectedFromLaneField(value, record.id);
                  }}
                >
                  {record.trailerLaneFromOptions &&
                    record.trailerLaneFromOptions.map((c) => (
                      <Option key={`lane-${c.label}`} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                </Select>
              );
            } else {
              return '-';
            }
          }
        },
      },
      {
        title: 'Yard out',
        dataIndex: ['reason_yard_out', 'reason', 'subject_reason', 'code'],
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          // ID = 1 is reason
          // ID = 2 is none
          if (record?.id === 1) {
            if (record.reason_yard_out && record.reason_yard_out.reasonEdit) {
              return (
                <Tooltip
                  placement='bottom'
                  title={
                    record.reason_yard_out?.reasonEdit?.value?.subject
                      ? record.reason_yard_out?.reasonEdit?.value?.subject?.substring(
                          5
                        )
                      : ''
                  }
                >
                  <Button
                    shape='round'
                    size='small'
                    style={{
                      fontSize: 12,
                      whiteSpace: 'normal',
                      height: 'auto',
                      margin: '5px',
                    }}
                    onClick={() =>
                      showModal(
                        record.reason_yard_out?.reasonEdit?.value,
                        'Yard Out'
                      )
                    }
                  >
                    {record.reason_yard_out?.reasonEdit &&
                      record.reason_yard_out?.reasonEdit?.label}
                  </Button>
                </Tooltip>
              );
            } else if (text) {
              return (
                <Tooltip
                  placement='bottom'
                  title={
                    language === 'th'
                      ? record.reason_yard_out?.reason?.subject_reason?.name_th
                      : record.reason_yard_out?.reason?.subject_reason?.name_en
                  }
                >
                  <Button
                    shape='round'
                    size='small'
                    style={{
                      fontSize: 12,
                      whiteSpace: 'normal',
                      height: 'auto',
                      margin: '5px',
                    }}
                    onClick={() =>
                      showModal(record.reason_yard_out, 'Yard Out')
                    }
                  >
                    {text}
                  </Button>
                </Tooltip>
              );
            } else {
              return '-';
            }
          } else {
            return '-';
          }
        },
      },

      {
        title: 'Arrival',
        dataIndex: ['reason_arrive', 'reason', 'subject_reason', 'code'],
        align: 'center',
        width: 100,
        movable: true,
        render: (text, record) => {
          // ID = 1 is reason
          // ID = 2 is lane
          if (record?.id === 1) {
            if (record?.reason_arrive?.reasonEdit) {
              return (
                <Tooltip
                  placement='bottom'
                  title={
                    record.reason_arrive?.reasonEdit?.value?.subject
                      ? record.reason_arrive.reasonEdit.value.subject.substring(
                          5
                        )
                      : ''
                  }
                >
                  <Button
                    shape='round'
                    size='small'
                    style={{
                      fontSize: 12,
                      whiteSpace: 'normal',
                      height: 'auto',
                      margin: '5px',
                    }}
                    onClick={() =>
                      showModal(
                        record.reason_arrive.reasonEdit.value,
                        'Arrival'
                      )
                    }
                  >
                    {record.reason_arrive.reasonEdit &&
                      record.reason_arrive.reasonEdit.label}
                  </Button>
                </Tooltip>
              );
            }
            let reason_arrive = record?.reason_arrive;
            if (record?.reason_arrive_from_shuttle_in?.reason) {
              reason_arrive = record?.reason_arrive_from_shuttle_in;
            }
            if (reason_arrive?.reason) {
              return (
                <Tooltip
                  placement='bottom'
                  title={
                    language === 'th'
                      ? reason_arrive?.reason?.subject_reason?.name_th
                      : reason_arrive?.reason?.subject_reason?.name_en
                  }
                >
                  <Button
                    shape='round'
                    size='small'
                    style={{
                      fontSize: 12,
                      whiteSpace: 'normal',
                      height: 'auto',
                      margin: '5px',
                    }}
                    onClick={() => showModal(reason_arrive, 'Arrival')}
                  >
                    {reason_arrive?.reason?.subject_reason?.code}
                  </Button>
                </Tooltip>
              );
            } else if (reason_arrive?.reason?.subject_reason?.code) {
              return (
                <Button
                  shape='round'
                  size='small'
                  style={{
                    fontSize: 12,
                    whiteSpace: 'normal',
                    height: 'auto',
                    margin: '5px',
                  }}
                  onClick={() => showModal(reason_arrive, 'Arrival')}
                >
                  {reason_arrive?.reason?.subject_reason?.code}
                </Button>
              );
            } else {
              return '-';
            }
          } else {
            if (
              record?.actual_arrival_time &&
              record?.trailerLaneDestinationOptions &&
              record?.shuttle_in_id
            ) {
              return (
                <Select
                  id={'trailer_lane'}
                  size={'small'}
                  style={{
                    width: '180px',
                  }}
                  showSearch
                  placeholder={'select'}
                  optionFilterProp='children'
                  value={
                    record.trailer_lane_arrive &&
                    record.trailer_lane_arrive?.lane_no
                  }
                  onSelect={(value) => {
                    selectedDestinationLaneField(value, record.id);
                  }}
                >
                  {record.trailerLaneDestinationOptions &&
                    record.trailerLaneDestinationOptions.map((c) => (
                      <Option key={`lane-${c.label}`} value={c.value}>
                        {c.label}
                      </Option>
                    ))}
                </Select>
              );
            } else {
              return '-';
            }
          }
        },
      },
    ];

    return cols;
  })();

  const btnCheckSave = () => {
    Modal.confirm({
      content: 'Are you sure want to save?',
      icon: <ExclamationCircleOutlined />,
      okText: 'OK',
      onOk: btnSave,
      cancelText: 'Cancel',
    });
  };
  const btnSave = () => {
    onSave(dataSourceEdit);
  };

  return (
    <>
      <AntTable
        rowKey='id'
        tableId={'callingsheet'}
        bordered
        showSorterTooltip={false}
        loading={isLoading}
        columns={columns}
        dataSource={dataSourceEdit}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
        pagination={false}
      />

      <Row gutter={[8, 16]} justify='end' style={{ marginTop: 20 }}>
        <Col>
          <Button
            style={{ width: 100 }}
            type='primary'
            onClick={btnCheckSave}
            loading={isLoading}
            disabled={
              dataSourceEdit.length > 0
                ? !dataSourceEdit[1].trailer_lane_receive_job
                : false
            }
          >
            Save
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default TableEditComponent;
