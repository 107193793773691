import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Modal, Select, message } from 'antd';
import {
  useUpdateBzpMutation,
  useCreateBzpMutation,
} from 'reducers/masterData/bzp/api';
import { useFindAllYardsQuery } from 'reducers/masterData/yard/api';
import {
  useFindAllCompaniesQuery,
  useFindCompanyByIdQuery,
} from 'reducers/company/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import { goBack } from 'connected-react-router';
import permission from 'permissions';

const { Option } = Select;

const BzpFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const dispatch = useDispatch();

  const [companies, setCompanies] = useState(
    formData && formData.company
      ? [{ id: formData.company.id, label: formData.company.name }]
      : []
  );
  const [yards, setYards] = useState(
    formData && formData.yards
      ? formData.yards.map((g) => ({
          id: g.id,
          label: g.code,
        }))
      : []
  );

  const [
    createBzp,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateBzpMutation();

  const [
    updateBzp,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateBzpMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  // Query all yards
  const {
    data: dataYards,
    error: errorYards,
    isFetching: isYardsFetching,
    refetch: refetchYards,
  } = useFindAllYardsQuery(null, {
    skip: !user || readonly || !user.is_superadmin,
  });

  useEffect(() => {
    if (dataYards) {
      setYards(dataYards.map((d) => ({ id: d.id, label: d.code })));
    }
  }, [dataYards]);

  useEffect(() => {
    if (errorYards) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load yards',
        okText: 'Retry',
        onOk: refetchYards,
        cancelText: 'Cancel',
      });
    }
  }, [errorYards, refetchYards]);

  // Query all companies
  const {
    data: dataCompanies,
    error: errorCompanies,
    isFetching: isCompaniesFetching,
    refetch: refetchCompanies,
  } = useFindAllCompaniesQuery(
    { is_active: true },
    {
      skip: !user || readonly || !user.is_superadmin,
    }
  );

  useEffect(() => {
    if (dataCompanies) {
      setCompanies(dataCompanies.map((d) => ({ id: d.id, label: d.name })));
    }
  }, [dataCompanies]);

  useEffect(() => {
    if (errorCompanies) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load companies',
        okText: 'Retry',
        onOk: refetchCompanies,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanies, refetchCompanies]);

  // Query company by ID, when admin of a company
  const {
    data: dataCompanyById,
    error: errorCompanyById,
    isFetching: isCompanyByIdFetching,
    refetch: refetchCompanyById,
  } = useFindCompanyByIdQuery(user && user.company_id, {
    skip: !user || readonly || user.is_superadmin,
  });

  useEffect(() => {
    if (dataCompanyById) {
      setCompanies([{ id: dataCompanyById.id, label: dataCompanyById.name }]);
      form.setFieldsValue({ company: dataCompanyById.name });
    }
  }, [dataCompanyById, form]);

  useEffect(() => {
    if (errorCompanyById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load company',
        okText: 'Retry',
        onOk: refetchCompanyById,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanyById, refetchCompanyById]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let company = companies.find((c) => c.label === validData.company);
    let postYards = validData.yards
      ? yards.filter((c) => validData.yards.some((g) => g === c.label))
      : [];

    let postData = {
      id: formData && formData.id,
      ...validData,
      company: company && company.id,
      yards: postYards.map((g) => g.id),
    };
    if (isCreate) {
      createBzp(postData);
    } else {
      updateBzp(postData);
    }
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 10,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 12,
          span: 12,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
          disabled={readonly}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Code'
        name='code'
        initialValue={formData && formData.code}
        rules={[
          {
            required: true,
            message: 'Please input bzp code',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Code'} />
      </Form.Item>
      <Form.Item
        label='Name'
        name='name'
        initialValue={formData && formData.name}
        rules={[
          {
            required: true,
            message: 'Please input bzp name',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Bzp name'} />
      </Form.Item>
      <Form.Item
        label='Contact Person'
        name='contact_person'
        initialValue={formData && formData.contact_person}
        rules={[
          {
            required: false,
            message: 'Please input contact person',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Contact person'} />
      </Form.Item>
      <Form.Item
        label='Yards'
        name='yards'
        initialValue={
          formData && formData.yards && formData.yards.map((g) => g.code)
        }
      >
        <Select
          mode='multiple'
          showSearch
          placeholder='Select Yards'
          optionFilterProp='children'
          loading={isYardsFetching}
          disabled={
            readonly ||
            !user ||
            (!user.is_superadmin &&
              !permission.checkPermission(user, 'bzp', ['c', 'u']))
          }
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {yards &&
            yards.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
      <Form.Item
        label='Company'
        name='company'
        initialValue={formData && formData.company && formData.company.name}
        rules={[
          {
            required: user && user.is_superadmin ? false : true,
            message: 'Please select company',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a company'
          optionFilterProp='children'
          loading={isCompaniesFetching || isCompanyByIdFetching}
          disabled={!user || !user.is_superadmin}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {companies &&
            companies.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>
    </Form>
  );
};

export default BzpFormComponent;
