import React, { useEffect, useState } from 'react';
import { goBack } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { PageHeader, Divider, Typography } from 'antd';
import styled from 'styled-components';

import { setTTTOperationDashboardTotalRows } from 'reducers/digitalmap/tttOperationDashboard';
import { setSidebarVisibility } from 'reducers/ui';
import Table from './Table';
const { Title } = Typography;

const TTTDashboardComponent = (props) => {
  const storedTotalRows = useSelector((state) => {
    return state.tttOperationDashboard.tttTotalRows;
  });
  const [totalRows, setTotalRows] = useState(storedTotalRows || null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  useEffect(() => {
    if (totalRows) {
      dispatch(setTTTOperationDashboardTotalRows(totalRows));
    }
  }, [totalRows, dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Trailer Status Monitoring'
        subTitle=''
        extra={
          totalRows != null && <Title level={5}>{`Total ${totalRows}`}</Title>
        }
      />
      <Divider style={{ margin: 0 }} />
      <Content>
        <Table totalRows={totalRows} setTotalRows={setTotalRows} />
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default TTTDashboardComponent;
