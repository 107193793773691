import React, { useState, useMemo, useEffect } from 'react';
import { Layout, Menu, Modal } from 'antd';
import styled from 'styled-components';
import useWindowSize from '../hooks/windowSize';
import { getSidebarOpen } from '../reducers/ui';
import adminLayoutRoutes from '../routes/menuRoutes';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../reducers/user/api';
import { getCurrentUser } from '../reducers/user';
import permission from '../permissions';
import { useTranslation } from 'react-i18next';
import {
  CreditCardOutlined,
  AuditOutlined,
  BarChartOutlined,
} from '@ant-design/icons';

import { LogoutOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import './sidebar.css';
import { setManageCallingFilter } from 'reducers/manageCalling';
const { SubMenu } = Menu;
const { Sider } = Layout;
const { checkPermission, checkPermissionToDisplayMenu } = permission;

const Sidebar = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const [checkPayment, setCheckPayment] = useState(false);
  const [checkVisualize, setCheckVisualize] = useState(false);
  const [masterData, setMasterData] = useState(false);

  const { width } = useWindowSize();
  const collapsed = !useSelector(getSidebarOpen);
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const { t } = useTranslation();
  const history = useHistory();

  const rootSubmenuKeys = useMemo(
    () => adminLayoutRoutes.map((r) => r.itemLink),
    []
  );

  useEffect(() => {
    if (user) {
      let checked = false;
      let checkedVisualize = false;
      for (let index = 0; index < user.privileges.length; index++) {
        if (
          user.privileges[index].actions[0] === '1' ||
          (user.privileges[index].actions[0] === '1' &&
            user.privileges[index].actions[1] === '0') ||
          (user.privileges[index].actions[0] === '0' &&
            user.privileges[index].actions[1] === '1')
        ) {
          if (
            user.privileges[index].subject === 'visualize_dash1' ||
            user.privileges[index].subject === 'visualize_dash2'
          ) {
            checkedVisualize = true;
          } else {
            checked = true;
          }
        }
      }
      setCheckPayment(checked);

      setCheckVisualize(checkedVisualize);

      if (
        permission.checkPermission(user, 'bzp_trip_payment_master', ['l']) ||
        permission.checkPermission(user, 'bzp_unit_payment_master', ['l']) ||
        permission.checkPermission(user, 'invoice_trip_payment_master', [
          'l',
        ]) ||
        permission.checkPermission(user, 'invoice_unit_payment_master', [
          'l',
        ]) ||
        permission.checkPermission(user, 'round_trip_master', ['l']) ||
        permission.checkPermission(user, 'payment_date_master', ['l']) ||
        permission.checkPermission(user, 'ttt_payment_master', ['l']) ||
        permission.checkPermission(user, 'route_speed_distance', ['l']) ||
        permission.checkPermission(user, 'speed_distance', ['l']) ||
        permission.checkPermission(user, 'location', ['l']) ||
        permission.checkPermission(user, 'tour_master', ['l']) ||
        permission.checkPermission(user, 'production_calendar', ['l']) ||
        permission.checkPermission(user, 'lane', ['l']) ||
        permission.checkPermission(user, 'reason_master', ['l']) ||
        permission.checkPermission(user, 'driver', ['l']) ||
        permission.checkPermission(user, 'driver_ability', ['l']) ||
        permission.checkPermission(user, 'trailer', ['l']) ||
        permission.checkPermission(user, 'trailer_calendar', ['l']) ||
        permission.checkPermission(user, 'driver_calendar', ['l']) ||
        permission.checkPermission(user, 'trailer_type', ['l']) ||
        permission.checkPermission(user, 'vehicle_type', ['l']) ||
        permission.checkPermission(user, 'can_match_cluster', ['l']) ||
        permission.checkPermission(user, 'route_lot', ['l']) ||
        permission.checkPermission(user, 'category', ['l']) ||
        permission.checkPermission(user, 'driver_message', ['l']) ||
        permission.checkPermission(user, 'dms_configuration', ['l']) ||
        permission.checkPermission(user, 'load_lead_time', ['l']) ||
        permission.checkPermission(user, 'time_window', ['l']) ||
        permission.checkPermission(user, 'route', ['l']) ||
        permission.checkPermission(user, 'priority_matching', ['l']) ||
        permission.checkPermission(user, 'cluster', ['l']) ||
        permission.checkPermission(user, 'region', ['l']) ||
        permission.checkPermission(user, 'calling_code', ['l']) ||
        permission.checkPermission(user, 'location_type', ['l']) ||
        permission.checkPermission(user, 'dm_configuration', ['l'])
      ) {
        setMasterData(true);
      }
    }
  }, [user]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <SideContainer>
      <SideLayout
        width={200}
        collapsedWidth={width < 500 ? 0 : 48}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <Menu
          theme='dark'
          mode='inline'
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          triggerSubMenuAction='click'
        >
          {adminLayoutRoutes.map((item, idx) => {
            return item.subItems
              ? checkPermissionToDisplayMenu(user, item) && (
                  <StyledSubMenu
                    location={location}
                    key={item.itemLink}
                    icon={item.itemIcon}
                    title={item.itemName}
                  >
                    {item.subItems.map((subItem, subIdx) => {
                      if (subItem.permission) {
                        if (
                          !checkPermission(
                            user,
                            subItem.permission.subject,
                            subItem.permission.actions
                          )
                        ) {
                          return null;
                        }
                      }

                      return (
                        <StyledMenuItem
                          key={subItem.itemLink}
                          icon={subItem.itemIcon}
                          location={location}
                        >
                          <Link
                            to={subItem.itemLink}
                            onClick={(e) => {
                              if (
                                subItem.itemLink ===
                                '/app/delivery_mng/manage_calling/no_calling'
                              ) {
                                dispatch(
                                  setManageCallingFilter({
                                    filterCallingno: null,
                                    filterCallingId: null,
                                    filterFrom: null,
                                    filterCluster: null,
                                  })
                                );
                              }
                            }}
                          >
                            {' '}
                            {subItem.itemName}
                          </Link>
                        </StyledMenuItem>
                      );
                    })}
                  </StyledSubMenu>
                )
              : checkPermissionToDisplayMenu(user, item) && (
                  <StyledMenuItem
                    key={item.itemLink}
                    icon={item.itemIcon}
                    location={location}
                  >
                    <Link to={item.itemLink}>{item.itemName}</Link>
                  </StyledMenuItem>
                );
          })}
          {checkPayment ? (
            <StyledMenuItem
              key='payment-link'
              icon={<CreditCardOutlined />}
              location={location}
            >
              <a href={`${window.location.origin}/app/payment`}>Payment</a>
            </StyledMenuItem>
          ) : null}
          {checkVisualize ? (
            <StyledMenuItem
              key='visualize-link'
              icon={<BarChartOutlined />}
              location={location}
            >
              <a href={`${window.location.origin}/app/payment`}>Visualize</a>
            </StyledMenuItem>
          ) : null}
          {masterData ? (
            <StyledMenuItem
              key='masterData-link'
              icon={<AuditOutlined />}
              location={location}
            >
              <a href={`${window.location.origin}/app/master`}>Master Data</a>
            </StyledMenuItem>
          ) : null}
        </Menu>
      </SideLayout>
      <SideFooterLayout
        width={200}
        collapsedWidth={width < 500 ? 0 : 48}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <FooterMenu theme='dark' mode='inline' triggerSubMenuAction='click'>
          <Menu.Item
            key='logout'
            icon={<LogoutOutlined />}
            onClick={() =>
              Modal.confirm({
                title: t('modal.confirm'),
                icon: <ExclamationCircleOutlined />,
                content: t('logout.logout-confirm'),
                okText: t('modal.confirm'),
                cancelText: t('modal.cancel'),
                onOk: () => {
                  history.push('/login');
                  setTimeout(() => {
                    dispatch(logoutUser());
                  }, 100);
                },
              })
            }
          >
            <span>{t('logout.text')}</span>
          </Menu.Item>
        </FooterMenu>
      </SideFooterLayout>
    </SideContainer>
  );
};
// Highlight root menuitem based on URL path when collapse
const StyledSubMenu = styled(SubMenu)`
  &&& {
    .ant-menu-submenu-title[aria-expanded='false'] {
      background-color: ${(props) => {
        if (props.location.pathname.includes(props.eventKey))
          return `#FF781F !important;`;
        else {
          return 'transparent';
        }
      }};
    }
  }
`;
// Highlight menu based on URL path
const StyledMenuItem = styled(Menu.Item)`
  &&& {
    background-color: ${(props) => {
      if (props.location.pathname.includes(props.eventKey))
        return `#FF781F !important;`;
      else {
        return 'transparent';
      }
    }};
  }
`;

const SideContainer = styled.div`
  height: calc(100%);
`;

const SideLayout = styled(Sider)`
  overflow: auto;
  height: calc(100% - 48px);
`;

const SideFooterLayout = styled(Sider)`
  height: 48px;
`;

const FooterMenu = styled(Menu)`
  &&& {
    .ant-menu-item-selected {
      background-color: transparent !important;
    }
  }
`;

export default Sidebar;
