import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Modal,
  Select,
  Collapse,
  Divider,
  Switch,
  message,
  Radio,
} from 'antd';
import {
  useUpdateGroupMutation,
  useCreateGroupMutation,
} from 'reducers/user/api';
import {
  useFindAllCompaniesQuery,
  useFindCompanyByIdQuery,
} from 'reducers/company/api';
import permission from 'permissions';
import styled from 'styled-components';
import { goBack } from 'connected-react-router';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import BzpForm from './BzpForm';
const { Option } = Select;
const { Panel } = Collapse;
const {
  getPermissionsBySubject,
  createPermissionOptions,
  mergePrivileges,
  createPaymentOptions,
} = permission;

const permissionOptions = [
  {
    group: 'User Management',
    permissions: [
      { label: 'User', subject: 'user' },
      { label: 'Group', subject: 'group' },
      { label: 'Company', subject: 'company' },
      { label: 'Diagram Owner', subject: 'diagram_owner' },
      { label: 'Function Permission', subject: 'function_permission' },
    ],
  },
  {
    group: 'Operation',
    permissions: [
      { label: 'Dispatch Board', subject: 'dispatch_board' },
      { label: 'Calling List', subject: 'callingsheet' },
      { label: 'Manage Calling', subject: 'manage_callingsheets' },
      { label: 'VIN List', subject: 'vin' },
      { label: 'Receive Job', subject: 'receive_job' },
      { label: 'Yard Out', subject: 'yard_out' },
      { label: 'Shuttle In', subject: 'shuttle_in' },
      { label: 'DN List', subject: 'dn_list' },
      { label: 'Create Trip', subject: 'create_trip' },
      { label: 'Status Summary', subject: 'status_summary' },
      { label: 'Status Change', subject: 'status_change' },
      { label: 'Change Reason & Lane', subject: 'change_reason_and_lane' },
    ],
  },
  {
    group: 'Driver Message',
    permissions: [
      { label: 'Driver Message', subject: 'driver_confirm_message' },
    ],
  },

  {
    group: 'Master Data',
    permissions: [
      { label: 'Allowance Payment Date', subject: 'payment_date_master' },
      { label: 'Can Matching Cluster', subject: 'can_match_cluster' },
      { label: 'Category', subject: 'category' },
      { label: 'Cluster', subject: 'cluster' },
      { label: 'Calling Code V2', subject: 'calling_code' },
      { label: 'Configuration', subject: 'dms_configuration' },
      { label: 'Digital Map Config', subject: 'dm_configuration' },
      { label: 'Driver', subject: 'driver' },
      { label: 'Driver Ability', subject: 'driver_ability' },
      { label: 'Driver Calendar', subject: 'driver_calendar' },
      { label: 'Driver Messages', subject: 'driver_message' },
      { label: 'Load Lead Time', subject: 'load_lead_time' },
      { label: 'Location Master', subject: 'location' },
      { label: 'Location Type', subject: 'location_type' },
      { label: 'Payment TTT', subject: 'ttt_payment_master' },
      { label: 'Priority Matching', subject: 'priority_matching' },
      { label: 'Production Calendar', subject: 'production_calendar' },
      { label: 'Reason Master', subject: 'reason_master' },
      { label: 'Region', subject: 'region' },
      { label: 'Route Lot', subject: 'route_lot' },
      { label: 'Route Master', subject: 'route' },
      { label: 'Route Speed Distance', subject: 'route_speed_distance' },
      { label: 'Round Trip Allowance & STD O.T', subject: 'round_trip_master' },
      { label: 'Speed Distance', subject: 'speed_distance' },
      { label: 'Time window', subject: 'time_window' },
      { label: 'Tour Master', subject: 'tour_master' },
      { label: 'Trailer', subject: 'trailer' },
      { label: 'Trailer Calendar', subject: 'trailer_calendar' },
      { label: 'Trailer Type', subject: 'trailer_type' },
      { label: 'Trip Payment (BZP)', subject: 'bzp_trip_payment_master' },
      { label: 'Trip Price (Invoice)', subject: 'invoice_trip_payment_master' },
      { label: 'Unit Payment (BZP)', subject: 'bzp_unit_payment_master' },
      { label: 'Unit Price (Invoice)', subject: 'invoice_unit_payment_master' },
      { label: 'Vehicle Lane', subject: 'lane' },
      { label: 'Vehicle Type', subject: 'vehicle_type' },
    ],
  },
  {
    group: 'Digital Map',
    permissions: [
      { label: 'TMT Operation Dashboard', subject: 'dm_operation_dashboard' },
      {
        label: 'TTT Operation Dashboard',
        subject: 'dm_ttt_operation_dashboard',
      },
    ],
  },
  {
    group: 'Planning',
    permissions: [
      { label: 'Assign Volume', subject: 'assign_volume' },
      { label: 'Fix tour', subject: 'assign_fixtour' },
      { label: 'Create lot', subject: 'create_lot' },
      { label: 'Manage Trip dashboard', subject: 'manage_trip_board' },
      { label: 'Create tour', subject: 'create_tour' },
      { label: 'Manage tour list', subject: 'manage_tour_list' },
      { label: 'Manage tour list BZP', subject: 'manage_tour_list_bzp' },
      { label: 'Summary Allocation', subject: 'summary_allocation' },
      { label: 'Summary Yard', subject: 'summary_yard' },
      { label: 'Status check', subject: 'status_check' },
    ],
  },
  {
    group: 'Download Report',
    permissions: [{ label: 'Download Report', subject: 'download_report' }],
  },
];

const permissionPaymentOptions = [
  {
    group: 'Payment List',
    permissions: [
      { label: 'Delivery Actual', subject: 'delivery_actual' },
      { label: 'TTT Payment', subject: 'ttt_payment' },
      { label: 'Delivery Actual Scan', subject: 'delivery_actual_scan' },
      { label: 'NG Allowance', subject: 'ng_allowance' },
      { label: 'BzP Payment', subject: 'bzp_payment' },
      { label: 'DN No. Scan', subject: 'dn_scan_check' },
      { label: 'Invoice', subject: 'invoice' },
    ],
  },
];

const visualizeOptions = [
  {
    group: 'Visualize',
    permissions: [
      { label: 'Visualize Dashboard 1', subject: 'visualize_dash1' },
      { label: 'Visualize Dashboard 2', subject: 'visualize_dash2' },
    ],
  },
];

const GroupFormComponent = (props) => {
  const { formData, isCreate, readonly } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [privileges, setPrivileges] = useState(
    formData && formData.permissions
      ? mergePrivileges(formData.permissions)
      : []
  );
  const [companies, setCompanies] = useState(
    formData && formData.company
      ? [{ id: formData.company.id, label: formData.company.name }]
      : []
  );

  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);

  // Query all companies. When Super Admin
  const {
    data: dataCompanies,
    error: errorCompanies,
    isFetching: isCompaniesFetching,
    refetch: refetchCompanies,
  } = useFindAllCompaniesQuery(
    {
      is_active: true,
      ordering: 'company_bzp',
      location_type__code: '005',
      display: 'company',
    },
    { skip: !user || !user.is_superadmin }
  );

  useEffect(() => {
    if (dataCompanies) {
      setCompanies(
        dataCompanies.map((d) => ({ id: d.id, label: d.company_bzp }))
      );
    }
  }, [dataCompanies]);

  useEffect(() => {
    if (errorCompanies) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load companies',
        okText: 'Retry',
        onOk: refetchCompanies,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanies, refetchCompanies]);

  // Query Company by ID. When Admin of a Company

  const {
    data: dataCompanyById,
    error: errorCompanyById,
    isFetching: isCompanyByIdFetching,
    refetch: refetchCompanyById,
  } = useFindCompanyByIdQuery(user && user.company_id, {
    skip: !user || user.is_superadmin,
  });

  useEffect(() => {
    if (dataCompanyById) {
      setCompanies([
        {
          id: dataCompanyById.id,
          label: dataCompanyById.name,
        },
      ]);
      form.setFieldsValue({ company: dataCompanyById.name });
    }
  }, [dataCompanyById, form]);

  useEffect(() => {
    if (errorCompanyById) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load company',
        okText: 'Retry',
        onOk: refetchCompanyById,
        cancelText: 'Cancel',
      });
    }
  }, [errorCompanyById, refetchCompanyById]);

  const [
    createGroup,
    {
      isSuccess: isSuccessCreating,
      isUninitialized: isUninitCreating,
      error: errorCreating,
      isLoading: isCreating,
    },
  ] = useCreateGroupMutation();

  const [
    updateGroup,
    {
      isSuccess: isSuccessUpdating,
      isUninitialized: isUninitUpdating,
      error: errorUpdating,
      isLoading: isUpdating,
    },
  ] = useUpdateGroupMutation();

  useEffect(() => {
    if (errorCreating) {
      setErrorData(errorCreating);
    }
    if (errorUpdating) {
      setErrorData(errorUpdating);
    }
  }, [errorUpdating, errorCreating, setErrorData]);

  useEffect(() => {
    if (
      (!isUninitUpdating && isSuccessUpdating) ||
      (!isUninitCreating && isSuccessCreating)
    ) {
      message.success('Data was successfully saved');
      if (isSuccessCreating) {
        dispatch(goBack());
      }
    }
  }, [
    isSuccessUpdating,
    isUninitUpdating,
    isSuccessCreating,
    isUninitCreating,
    dispatch,
  ]);

  const onFinish = (values) => {
    Modal.confirm({
      title: 'Confirm',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure want to submit?',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onOk: handleConfirmAction,
    });
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);

    let company = companies.find((c) => c.label === validData.company);
    let permissions = [
      {
        name: `${validData.name}-permission`,
        privilege: privileges,
      },
    ];

    let postData = {
      id: formData && formData.id,
      ...validData,
      permissions,
      company: company && company.id,
    };

    if (isCreate) {
      createGroup(postData);
    } else {
      updateGroup(postData);
    }
  };

  const onChange = (checkedValues, subject) => {
    let newPrivileges = privileges.filter((p) => p.subject !== subject);
    let privilege = { subject: subject, actions: checkedValues };
    newPrivileges.push(privilege);
    setPrivileges(newPrivileges);
  };

  const setSelectedBzps = (bzps) => {
    onChange(bzps, 'bzp_watchlist');
  };

  return (
    <Form
      form={form}
      name='basic'
      labelCol={{
        span: 4,
      }}
      wrapperCol={{
        span: 14,
      }}
      style={{ padding: '20px' }}
      onFinish={onFinish}
      autoComplete='off'
    >
      <Form.Item
        wrapperCol={{
          offset: 16,
          span: 8,
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          loading={isCreating || isUpdating}
        >
          Submit
        </Button>
      </Form.Item>
      <Form.Item
        label='Name'
        name='name'
        initialValue={formData && formData.name}
        rules={[
          {
            required: true,
            message: 'Please input group name',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Group name'} />
      </Form.Item>
      <Form.Item
        label='Description'
        name='description'
        initialValue={formData && formData.description}
        rules={[
          {
            required: true,
            message: 'Please input description',
          },
        ]}
      >
        <Input disabled={readonly} placeholder={'Group description'} />
      </Form.Item>
      <Form.Item
        label='Company'
        name='company'
        initialValue={
          formData && formData.company && formData.company.company_bzp
        }
        rules={[
          {
            required: true,
            message: 'Please select company',
          },
        ]}
      >
        <Select
          showSearch
          placeholder='Select a company'
          optionFilterProp='children'
          loading={isCompaniesFetching || isCompanyByIdFetching}
          disabled={!user || !user.is_superadmin}
          filterOption={(input, option) => {
            return option.value.toLowerCase().includes(input.toLowerCase());
          }}
        >
          {companies &&
            companies.map((c, index) => (
              <Option key={index} value={c.label}>
                {c.label}
              </Option>
            ))}
        </Select>
      </Form.Item>

      <Form.Item
        label='Can assign permissions'
        name='is_admin'
        tooltip='If enable, this group can assign permissions to others. Only super admin is allowed.'
        initialValue={formData && formData.is_admin}
        valuePropName='checked'
      >
        <Switch disabled={!user || !user.is_superadmin} />
      </Form.Item>

      <Form.Item label='Permissions List'>
        <Collapse defaultActiveKey={[]}>
          {permissionOptions.map((o, oIndex) => {
            let hasPerm = o.permissions.some((p) =>
              privileges.some(
                (priv) => priv.subject === p.subject && priv.actions.length > 0
              )
            );

            return (
              <Panel
                header={`${o.group} ${hasPerm ? '(*)' : ''}`}
                key={`panel-${oIndex}`}
              >
                {o.permissions.map((p, pIndex) => {
                  return (
                    <div key={pIndex}>
                      <StyledDivider
                        key={`divider-${pIndex}`}
                        orientation='left'
                      >
                        {p.label}
                      </StyledDivider>
                      <Checkbox.Group
                        key={`checkbox-${pIndex}`}
                        options={createPermissionOptions(
                          user,
                          p.subject,
                          formData && formData.is_admin,
                          p.extraOptions
                        )}
                        defaultValue={getPermissionsBySubject(
                          formData && formData.permissions,
                          p.subject
                        )}
                        onChange={(checkedValues) => {
                          onChange(checkedValues, p.subject);
                        }}
                      />
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      </Form.Item>

      <Form.Item label='Permissions Payment'>
        <Collapse defaultActiveKey={[]}>
          {permissionPaymentOptions.map((o, oIndex) => {
            let hasPerm = o.permissions.some((p) =>
              privileges.some(
                (priv) => priv.subject === p.subject && priv.actions.length > 0
              )
            );

            return (
              <Panel
                header={`${o.group} ${hasPerm ? '(*)' : ''}`}
                key={`panel-${oIndex}`}
              >
                {o.permissions.map((p, pIndex) => {
                  return (
                    <div key={pIndex}>
                      <StyledDivider
                        key={`divider-${pIndex}`}
                        orientation='left'
                      >
                        {p.label}
                      </StyledDivider>
                      <Radio.Group
                        key={`radio-${pIndex}`}
                        options={createPaymentOptions(
                          user,
                          p.subject,
                          formData && formData.is_admin,
                          p.extraOptions
                        )}
                        defaultValue={getPermissionsBySubject(
                          formData && formData.permissions,
                          p.subject
                        )}
                        onChange={(e) => {
                          onChange([`${e.target.value}`], p.subject);
                        }}
                      />
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      </Form.Item>

      <Form.Item label='Visualize'>
        <Collapse defaultActiveKey={[]}>
          {visualizeOptions.map((o, oIndex) => {
            let hasPerm = o.permissions.some((p) =>
              privileges.some(
                (priv) => priv.subject === p.subject && priv.actions.length > 0
              )
            );

            return (
              <Panel
                header={`${o.group} ${hasPerm ? '(*)' : ''}`}
                key={`panel-${oIndex}`}
              >
                {o.permissions.map((p, pIndex) => {
                  return (
                    <div key={pIndex}>
                      <StyledDivider
                        key={`divider-${pIndex}`}
                        orientation='left'
                      >
                        {p.label}
                      </StyledDivider>
                      <Radio.Group
                        key={`radio-${pIndex}`}
                        options={createPaymentOptions(
                          user,
                          p.subject,
                          formData && formData.is_admin,
                          p.extraOptions
                        )}
                        defaultValue={getPermissionsBySubject(
                          formData && formData.permissions,
                          p.subject
                        )}
                        onChange={(e) => {
                          onChange([`${e.target.value}`], p.subject);
                        }}
                      />
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      </Form.Item>

      {(user?.is_superadmin ||
        permission.checkPermission(user, 'bzp', ['wl'])) && (
        <BzpForm
          setSelectedBzps={setSelectedBzps}
          bzp_watchlist={privileges.find((p) => p.subject === 'bzp_watchlist')}
        />
      )}
    </Form>
  );
};

const StyledDivider = styled(Divider)`
  margin: 8px 0px !important;
`;

export default GroupFormComponent;
