import React, { useEffect, useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import { Sorter } from 'utils/sorter';
import { goBack } from 'connected-react-router';
import { setSidebarVisibility } from 'reducers/ui';
import Toolbar from './Toolbar';
import Table from './Table';
import { useErrorHandler } from 'common_components/ErrorContext';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { message, Button, Modal, Row, Col, PageHeader, Typography } from 'antd';
import { setManageCallingTotalRows } from 'reducers/manageCalling';
import { RemoveDuplicates } from 'utils/removeDuplicates';
import { manageCallingApi } from 'reducers/manageCalling/api';
import {
  setManageCallingFilter,
  setManageCallingNoStatusMode,
  setManageCallingDataParkingLane,
  setManageCallingDataSourceForModeCreateNewCalling,
  setManageCallingDataSourceForModeEditVin,
  setManageCallingDataSourceForModeAddVin,
} from 'reducers/manageCalling';

import useWindowSize from 'hooks/windowSize';
import {
  useDeleteManageCallingMutation,
  useLazyFindManageCallingByCallingnoQuery,
  useLazyFindLocationFromQuery,
  useCreateManageCallingMutation,
  useGetCheckTrDvMutation,
  useCheckBestReceiveJobTimeMutation,
} from 'reducers/manageCalling/api';
import { useParams } from 'react-router-dom';
import { useFindAllParkingLaneMutation } from 'reducers/masterData/parkingLane/api';
import PrintDN from 'common_components/PrintDocument/PrintingDN/PrintDN';
import PrintCalling from 'common_components/PrintDocument/PrintingCalling/PrintCalling';
import { push } from 'connected-react-router';
import { alphaNumericSort } from 'libs/sort';
import usePrint from 'hooks/usePrint';

const ManageCallingComponent = () => {
  const { confirm } = Modal;
  const dispatch = useDispatch();
  const { calling } = useParams();
  const { Title } = Typography;

  // Redux Store
  const storedDataSourceForModeCreateNewCalling = useSelector((state) => {
    return state.manageCalling.dataSourceForModeCreateNewCalling;
  });
  const storedTotalRows = useSelector((state) => {
    return state.manageCalling.totalRows;
  });
  const [totalRows, setTotalRows] = useState(storedTotalRows || null);
  const storedDataSourceForModeEditVin = useSelector((state) => {
    return state.manageCalling.dataSourceForModeEditVin;
  });

  const storedDataSourceForModeAddVin = useSelector((state) => {
    return state.manageCalling.dataSourceForModeAddVin;
  });

  const storedStatusMode = useSelector((state) => {
    return state.manageCalling.statusMode;
  });

  const storedDataParkingLane = useSelector((state) => {
    return state.manageCalling.dataParkingLane;
  });

  const [dataSource, setDataSource] = useState([]);

  const storedFilters = useSelector((state) => {
    return state.manageCalling.filters;
  });

  // State
  const [tableHeight, setTableHeight] = useState(0);
  const [callingDataList, setCallingDataList] = useState([]);
  const [isModalVisibleSave, setIsModalVisibleSave] = useState(false);
  const [isModalVisibleSaveNew, setIsModalVisibleSaveNew] = useState(false);

  const [dnDataList, setDnDataList] = useState([]);
  const [showFilter, setShowFilter] = useState(true);

  const [visibleModalLoading, setVisibleModalLoading] = useState(false);
  const [totalUnit, setTotalUnit] = useState(0);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [checkoutDataSource, setCheckoutDataSource] = useState(false);

  const [filterCallingno, setFilterCallingno] = useState(
    storedFilters && storedFilters.filterCallingno
      ? storedFilters.filterCallingno
      : ''
  );

  const [filterFrom, setFilterFrom] = useState(
    storedFilters && storedFilters.filterFrom
      ? storedFilters.filterFrom
      : undefined
  );

  const [filterOptionFrom, setFilterOptionFrom] = useState();
  const [filterOptionParking, setFilterOptionParking] = useState(
    storedDataParkingLane && storedDataParkingLane.dataParkingLane
      ? storedDataParkingLane.dataParkingLane
      : []
  );

  const [filterCluster, setFilterCluster] = useState(
    storedFilters && storedFilters.filterCluster
      ? storedFilters.filterCluster
      : null
  );

  const [param_mode, setparam_mode] = useState(
    storedStatusMode && storedStatusMode.param_mode
      ? storedStatusMode.param_mode
      : 'VIEW'
  );

  const [param_calling_no, setparam_calling_no] = useState(
    storedStatusMode && storedStatusMode.param_calling_no
      ? storedStatusMode.param_calling_no
      : 'NO'
  );
  const [param_from, setparam_from] = useState(
    storedStatusMode && storedStatusMode.param_from
      ? storedStatusMode.param_from
      : 'MENU'
  );

  const [param_filterfrom, setparam_filterfrom] = useState(
    storedStatusMode && storedStatusMode.param_filterfrom
      ? storedStatusMode.param_filterfrom
      : 'NO'
  );

  const callingIdRef = useRef(
    storedFilters && storedFilters.filterCallingId
      ? storedFilters.filterCallingId
      : ''
  );

  const locationFromOriginalRef = useRef(storedFilters?.filterFromOld);

  const locationFromLabelRef = useRef();

  const postBodyRef = useRef();

  const printCallingComponentRef = useRef();
  const printDNComponentRef = useRef();

  const { setErrorData } = useErrorHandler();
  const { height } = useWindowSize();

  const [callingListPrintData, printCalling] = usePrint(
    'Calling',
    callingDataList
  );
  const [dnListPrintData, printDn] = usePrint('DNList', dnDataList);

  // APIs
  const [apiCheckCallingTrDv, { isLoading: isLoadingCheckCalling }] =
    useGetCheckTrDvMutation();

  const [
    onSearchCallingNo,
    {
      data: dataSearchCallingNo,
      error: errorSearchCallingNo,
      isLoading: loadingSearchCallingNo,
    },
  ] = useLazyFindManageCallingByCallingnoQuery();

  const [
    getLocationData,
    {
      data: fromOptionLocationData,
      error: errorFromOptionLocation,
      isLoading: loadingLocationFrom,
    },
  ] = useLazyFindLocationFromQuery();

  // Libs
  const handlePrintCalling = useCallback(() => {
    setTimeout(() => {
      printCalling(printCallingComponentRef.current, 'print-calling');
    }, 500);
  }, [printCalling]);

  const handlePrintDN = useCallback(() => {
    setTimeout(() => {
      printDn(printDNComponentRef.current, 'print-dn');
    }, 500);
  }, [printDn]);

  // UseCallback
  const getDataPrintDN = useCallback(
    async (calling_no) => {
      try {
        let result = await dispatch(
          manageCallingApi.endpoints.getPrintDN.initiate({
            calling_no: calling_no,
          })
        );

        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let arrData = [];
            arrData = result.data;
            let dataDN = [];

            for (let dn of arrData) {
              let dataVin = dn.vins.map((d, index) => ({
                index: index + 1,
                assigned_date: d.assigned_date,
                color: d.color ? d.color : '',
                destination: d.destination,
                engine: d.engine ? d.engine : '',
                id: d.id,
                lot_no_cbu: d.lot_no_cbu ? d.lot_no_cbu : '',
                model_code: d.model_code ? d.model_code : '',
                series: d.series ? d.series : '',
                upload_date: d.upload_date,
                vin_no: d.vin_no ? d.vin_no.trim()  : '',
                suffix: d?.suffix ? d?.suffix : '',
              }));

              if (dataVin.length !== 11) {
                let empty = {
                  index: '-',
                  assigned_date: '',
                  color: '',
                  destination: '',
                  engine: '',
                  id: '',
                  lot_no_cbu: null,
                  model_code: '',
                  series: '',
                  upload_date: '',
                  vin_no: '',
                  suffix: ''
                };

                let number_loop = 9 - dataVin.length;

                for (let i = 0; i < number_loop; i++) {
                  dataVin.push(empty);
                }
              }
              dataDN.push({
                callingsheet: dn.callingsheet,
                customer: dn.customer,
                datetime_now: dn.datetime_now,
                delivery_note: dn.delivery_note,
                location_destination: dn.location_destination,
                location_from: dn.location_from,
                location_to: dn.location_to,
                user_login_print: dn.user_login_print,
                vins: dataVin,
              });
            }
            setDnDataList(dataDN);
            handlePrintDN();

            let payload = {
              calling_no: filterCallingno,
            };
            onSearchCallingNo(payload);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
      }
    },
    [dispatch, filterCallingno, handlePrintDN, onSearchCallingNo, setErrorData]
  );

  const checkCallingTrDvThenConfirm = useCallback(async () => {
    let payload = {
      id: callingIdRef.current,
    };
    let res = await apiCheckCallingTrDv(payload);
    if (res.data?.status === true) {
      confirm({
        title: 'คุณต้องการปริ้น DN หรือไม่',
        icon: <ExclamationCircleOutlined />,
        onOk() {
          getDataPrintDN(filterCallingno);
        },
      });
    }
  }, [apiCheckCallingTrDv, confirm, filterCallingno, getDataPrintDN]);

  const checkCallingTrDv = useCallback(async () => {
    let payload = {
      id: callingIdRef.current,
    };
    let res = await apiCheckCallingTrDv(payload);
    if (res.data?.status === false) {
      Modal.warning({
        title: 'ไม่สามารถ Print DN ได้',
        content: (
          <div>
            <p>เนื่องจาก ยังไม่ได้ confirm TR/DV</p>
          </div>
        ),
        width: 500,
        onOk() {},
      });
    } else {
      getDataPrintDN(filterCallingno);
    }
  }, [apiCheckCallingTrDv, filterCallingno, getDataPrintDN]);

  const getDataPrintCalling = useCallback(
    async (calling_no) => {
      try {
        let result = await dispatch(
          manageCallingApi.endpoints.getPrintCallingByCallingno.initiate(
            calling_no
          )
        );
        if (result && result.error) {
        } else {
          if (result.data) {
            setCallingDataList(result.data);

            handlePrintCalling();
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      } finally {
      }
    },
    [dispatch, handlePrintCalling]
  );

  // UseEffect
  useEffect(() => {
    dispatch(setSidebarVisibility(false));
  }, [dispatch]);

  useEffect(() => {
    if (height) {
      let h = height - 327;
      if (!showFilter) {
        h = h + 240;
      }
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height, showFilter]);

  useEffect(() => {
    if (fromOptionLocationData) {
      let findFromLocation = fromOptionLocationData.map((d) => ({
        label: d.short_name,
        value: d.id,
      }));
      findFromLocation = Sorter.DEFAULTLIST(findFromLocation, 'label');
      setFilterOptionFrom(findFromLocation);
    }
  }, [fromOptionLocationData]);

  useEffect(() => {
    if (errorFromOptionLocation) {
      setErrorData(errorFromOptionLocation);
    }
  }, [errorFromOptionLocation, setErrorData]);

  useEffect(() => {
    getLocationData();
  }, [getLocationData]);

  const [
    getParkingLaneData,
    {
      data: parkingLaneData,
      error: errorParkingLane,
      isLoading: loadingParkingLane,
    },
  ] = useFindAllParkingLaneMutation();

  useEffect(() => {
    if (filterFrom) {
      getParkingLaneData(filterFrom);
    } else {
      setFilterOptionParking([]);
    }
  }, [filterFrom, getParkingLaneData]);

  useEffect(() => {
    if (parkingLaneData) {
      let findparkingLane = parkingLaneData.map((d) => ({
        label: d.lane_no,
        value: d.id,
        amount: d.amount,
        used: d.used,
      }));
      findparkingLane = RemoveDuplicates.LISTKEY(findparkingLane, 'label');
      findparkingLane = Sorter.DEFAULTLIST(findparkingLane, 'label');

      alphaNumericSort(findparkingLane, 'label');
      setFilterOptionParking(findparkingLane);

      setVisibleModalLoading(false);
    }
  }, [parkingLaneData]);

  useEffect(() => {
    if (errorParkingLane) {
      setErrorData(errorParkingLane);
    }
  }, [errorParkingLane, setErrorData]);

  //------------------------------------------------------------------------------------

  // Cancel ManageCalllingData
  const [
    cancalManageCalling,
    { data: deleteManageCallingData, error: errorDeleteManageCalling },
  ] = useDeleteManageCallingMutation();

  // createManageCalling
  const [
    createManageCalling,
    {
      data: dataCreateManageCalling,
      error: errorCreateManageCalling,
      isLoading: loadingCreateManageCalling,
    },
  ] = useCreateManageCallingMutation();

  useEffect(() => {
    if (deleteManageCallingData) {
      callingIdRef.current = '';
      locationFromLabelRef.current = '';

      setFilterCallingno('');

      setDataSource([]);
      getLocationData();
      setFilterFrom(undefined);
      setFilterCluster('');

      setparam_calling_no('NO');
      setparam_filterfrom('NO');
      setparam_from('MENU');
      setparam_mode('VIEW');

      dispatch(push(`/app/delivery_mng/manage_calling/no_calling`));
    }
  }, [deleteManageCallingData, dispatch, getLocationData]);

  useEffect(() => {
    if (errorDeleteManageCalling) {
      setErrorData(errorDeleteManageCalling);
    }
  }, [errorDeleteManageCalling, setErrorData]);

  const showNotFoundCallingNo = useCallback(() => {
    Modal.warning({
      title:
        'Can`t found the Calling No. Please check your data and try again.',
    });

    callingIdRef.current = '';
    locationFromLabelRef.current = '';
    setDataSource([]);
    getLocationData();
    setFilterFrom(undefined);
    setFilterCluster('');

    setparam_calling_no('NO');
    setparam_filterfrom('NO');
    setparam_from('MENU');
    setparam_mode('VIEW');
  }, [getLocationData]);

  useEffect(() => {
    if (dataSearchCallingNo) {
      let newData = null;
      let clusterData = null;

      if (dataSearchCallingNo.length === 0) {
        showNotFoundCallingNo();
        setFilterFrom();
        setFilterCluster();
      } else {
        let from_location = dataSearchCallingNo[0]?.calling_location_from?.id;

        callingIdRef.current = dataSearchCallingNo[0].id;
        clusterData = dataSearchCallingNo[0]?.farthest_cluster?.name;
        newData = dataSearchCallingNo[0]?.vins;
        setTotalRows(dataSearchCallingNo[0]?.vins?.length);

        let arr_convert = newData.map((d) => ({
          vin_no: d?.vin_no.trim() || '',
          id: d?.id || '',
          assigned_date: d?.assigned_date || '',
          model_code: d?.model_code || '',
          series: d?.series || '',
          engine: d?.engine || '',
          color: d?.color || '',
          lot_no_cbu: d?.lot_no_cbu || '',
          cluster: d?.cluster?.name || '',
          location_to: d?.location_to?.short_name || '',
          location_from: d?.location_from?.short_name || '',
          location_destination: d?.location_destination?.short_name || '',
          parking_lane_old: d?.parking_lane || { id: '', lane_no: '' },
          parking_lane: d?.parking_lane || { id: '', lane_no: '' },
          destination: d?.destination || '',
          vehicle_type: d?.vehicle_type?.name || '',
          customer: d?.customer?.code || '',
          print_dn: d?.print_dn || false,
          upload_date: d?.upload_date || '',
          vehicle_status: d?.vehicle_status?.name_en || '',
          location_destination_kakudai: d?.location_destination?.name || '',
          cluster_kakudai: d?.location_destination?.cluster?.name || '',
        }));

        setDataSource(arr_convert);
        setFilterFrom(from_location);
        locationFromOriginalRef.current = from_location;

        setFilterCluster(clusterData);
      }
    }
  }, [dataSearchCallingNo, showNotFoundCallingNo]);

  useEffect(() => {
    if (errorSearchCallingNo) {
      setVisibleModalLoading(false);
      setFilterFrom(undefined);
      setFilterCluster();

      setErrorData(errorSearchCallingNo);
    }
  }, [errorSearchCallingNo, setErrorData]);

  function onCancelCalling() {
    confirm({
      title: 'Would you like to cancel Calling No. ?',
      icon: <ExclamationCircleOutlined />,
      content: `Calling No. : ${filterCallingno}`,
      onOk() {
        cancalManageCalling(callingIdRef.current);
      },
      onCancel() {},
    });
  }
  // button
  function onDeleteVinlist(id) {
    confirm({
      title: 'Would you like to remove the VIN no. selected? please confirm',
      icon: <ExclamationCircleOutlined />,

      onOk() {
        deleteVinlist(id);
      },
      onCancel() {},
    });
  }

  const showModalSave = () => {
    for (const i in filterOptionFrom) {
      if (Object.hasOwnProperty.call(filterOptionFrom, i)) {
        if (filterOptionFrom[i].value === filterFrom) {
          locationFromLabelRef.current = filterOptionFrom[i].label;
        }
      }
    }
    if (param_mode === 'CREATE_NEW_CALLING') {
      setIsModalVisibleSaveNew(true);
    } else {
      setIsModalVisibleSave(true);
    }
  };

  const handleOkSave = () => {
    onSaveManageCalling();
    setIsModalVisibleSave(false);
  };

  const handleOkSaveNew = () => {
    onSaveManageCalling();
    setIsModalVisibleSaveNew(false);
  };

  const handleCancelSave = () => {
    setIsModalVisibleSave(false);
  };
  const handleCancelSaveNew = () => {
    setIsModalVisibleSaveNew(false);
  };

  const onSaveManageCalling = async () => {
    setVisibleModalLoading(true);
    if (param_mode === 'CREATE_NEW_CALLING') {
      let result = await dispatch(
        manageCallingApi.endpoints.getGenerateCallingNo.initiate(null, {
          forceRefetch: true,
        })
      );

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        let dataGenerateCallingNo = result.data;
        setFilterCallingno(dataGenerateCallingNo);
        postCheckBestCalling(dataGenerateCallingNo);
      }
    } else {
      postCheckBestCalling(filterCallingno);
    }
  };

  const [apiCheckBestReceiveJob] = useCheckBestReceiveJobTimeMutation();

  const handleCheckBestReceiveJobResponse = useCallback(
    (dataCheckBestReceiveJob) => {
      if (dataCheckBestReceiveJob) {
        if (dataCheckBestReceiveJob?.error_msg === null) {
          createManageCalling(postBodyRef.current);
        } else {
          if (dataCheckBestReceiveJob?.error_msg?.msg) {
            var object_nf = dataCheckBestReceiveJob?.error_msg;
            var arr_nf = [];

            for (const [key, value] of Object.entries(object_nf)) {
              var oject_key = {
                key: key,
                value: value,
              };
              arr_nf.push(oject_key);
            }
            let content = null;
            // Case All route not found
            if (arr_nf.length >= 2 && arr_nf[1]?.value[0]?.from) {
              content = (
                <div style={{ padding: '0px' }}>
                  <p style={{ fontWeight: 'bold' }}>{arr_nf[1]?.key} </p>
                  <p>From : {arr_nf[1]?.value[0]?.from} </p>
                  <span>To : </span>
                  {arr_nf[1]?.value[0]?.to.map((option) => (
                    <span key={option}>{option + ','}</span>
                  ))}
                  <p style={{ fontWeight: 'bold' }}>
                    {'Would you like to save this Calling?'}
                  </p>
                </div>
              );
            }
            // Case Some route not found
            if (arr_nf.length >= 3 && arr_nf[2]?.value[0]?.from) {
              content = (
                <div style={{ padding: '0px' }}>
                  <p style={{ fontWeight: 'bold' }}>{arr_nf[2]?.key} </p>
                  <p>From : {arr_nf[2]?.value[0]?.from} </p>
                  <span>To : </span>
                  {arr_nf[2]?.value[0]?.to.map((option) => (
                    <span key={option}>{option + ','}</span>
                  ))}
                  <p style={{ fontWeight: 'bold' }}>
                    {'Would you like to save this Calling?'}
                  </p>
                </div>
              );
            }
            confirm({
              title: `Warning : ${dataCheckBestReceiveJob?.error_msg?.msg}`,
              width: 500,
              icon: <ExclamationCircleOutlined />,
              content: content,
              onOk() {
                createManageCalling(postBodyRef.current);
              },
              onCancel() {},
            });
          } else {
            // Case Route not found in speed distance master
            confirm({
              title: `Warning : ${
                dataCheckBestReceiveJob?.error_msg ||
                'Route not found in route speed distance master'
              }`,
              width: 500,
              icon: <ExclamationCircleOutlined />,
              content: (
                <div style={{ padding: '0px' }}>
                  <p style={{ fontWeight: 'bold' }}>
                    {'Would you like to save this Calling?'}
                  </p>
                </div>
              ),
              onOk() {
                createManageCalling(postBodyRef.current);
              },
              onCancel() {},
            });
          }
        }
      }
    },
    [confirm, createManageCalling]
  );
  const postCheckBestCalling = useCallback(
    async (callingNo) => {
      if (!callingNo) {
        return setVisibleModalLoading(false);
      }
      let convertKeyJson = dataSource.map((d) => ({
        id: d.id,
        vin_no: d.vin_no.trim(),
        model_code: d.model_code,
        series: d.series,
        engine: d.engine,
        color: d.color,
        lot_no_cbu: d.lot_no_cbu,
        cluster: d.cluster,
        location_to: d.location_to,
        location_destination: d.location_destination,
        parking_lane: d.parking_lane.lane_no || '',
        parking_lane_old: d.parking_lane_old.lane_no || '',
        customer: d.customer,
        vehicle_type: d.vehicle_type,
        vehicle_status: d.vehicle_status,
        assigned_date: d.assigned_date === '' ? null : d.assigned_date,
      }));

      let arr_clear = [];
      let arr_add = [];

      for (let i = 0; i < convertKeyJson.length; i++) {
        if (
          convertKeyJson[i].parking_lane !== convertKeyJson[i].parking_lane_old
        ) {
          if (convertKeyJson[i].parking_lane_old !== '') {
            arr_clear.push(convertKeyJson[i].parking_lane_old);
          }
          if (convertKeyJson[i].parking_lane !== '') {
            arr_add.push(convertKeyJson[i].parking_lane);
          }
        }
      }
      let ojectParking = {
        add: arr_add,
        clear: arr_clear,
      };
      let postData = {};
      if (arr_add.length === arr_clear.length) {
        postData = {
          calling: {
            callingsheet_no: callingNo.trim(),
            from: filterFrom,
            from_old: locationFromOriginalRef.current,
          },
          vins: convertKeyJson,
          parking: ojectParking,
        };
      } else {
        postData = {
          calling: {
            callingsheet_no: callingNo.trim(),
            from: filterFrom,
            from_old: locationFromOriginalRef.current,
          },
          vins: convertKeyJson,
        };
      }
      postBodyRef.current = postData;

      let result = await apiCheckBestReceiveJob(postData);

      if (result && result.error) {
        setErrorData(result.error);
      } else {
        handleCheckBestReceiveJobResponse(result?.data);
      }
      setVisibleModalLoading(false);
    },
    [
      apiCheckBestReceiveJob,
      dataSource,
      filterFrom,
      handleCheckBestReceiveJobResponse,
      setErrorData,
    ]
  );

  useEffect(() => {
    if (dataCreateManageCalling) {
      let resultData = dataCreateManageCalling.data;

      setFilterCallingno(dataCreateManageCalling.data.callingsheet_no);
      setVisibleModalLoading(false);

      setparam_mode('VIEW');
      setparam_calling_no(dataCreateManageCalling.data.callingsheet_no);

      let payload = {
        calling_no: dataCreateManageCalling.data.callingsheet_no,
      };
      onSearchCallingNo(payload);

      callingIdRef.current = resultData.id;

      setCheckoutDataSource(true);
      Modal.success({
        title: 'Save data successfully',
        width: 600,
        icon: <CheckCircleOutlined />,

        onOk() {
          confirm({
            title: 'คุณต้องการปริ้น Calling',
            icon: <ExclamationCircleOutlined />,
            content: `Calling no : ${resultData.callingsheet_no}`,
            onOk() {
              (async () => {
                await getDataPrintCalling(resultData.callingsheet_no);

                if (param_mode === 'EDIT_VIN') {
                  checkCallingTrDvThenConfirm();
                }
              })();
            },
            onCancel() {
              if (param_mode === 'EDIT_VIN') {
                checkCallingTrDvThenConfirm();
              }
            },
          });
        },
      });
    }
    //TODO: Check dependencies array, now exclude some object to prevent loop execution
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dataCreateManageCalling,
    // checkCallingTrDv,
    // confirm,
    // getDataPrintCalling,
    // onSearchCallingNo,
    // param_mode,
  ]);

  useEffect(() => {
    if (errorCreateManageCalling) {
      setVisibleModalLoading(false);

      setCheckoutDataSource(true);
      setErrorData(errorCreateManageCalling);
    }
  }, [errorCreateManageCalling, setErrorData]);

  const onClearAllDataSource = () => {
    confirm({
      title: 'Confirm to clear all data ?',
      icon: <ExclamationCircleOutlined />,
      content: 'Please check calling no. information. ',
      onOk() {
        callingIdRef.current = '';
        locationFromLabelRef.current = '';
        setTotalRows(null);
        setFilterCallingno('');
        setDataSource([]);
        getLocationData();
        setFilterFrom(undefined);
        setFilterCluster('');

        setparam_calling_no('NO');
        setparam_filterfrom('NO');
        setparam_from('MENU');
        setparam_mode('VIEW');

        message.success('Clear all success!!');

        dispatch(push(`/app/delivery_mng/manage_calling/no_calling`));
      },
      onCancel() {},
    });
  };

  const handleOkLoading = () => {
    setVisibleModalLoading(false);
  };

  const handleCancelLoading = () => {
    setVisibleModalLoading(false);
  };
  function deleteVinlist(item) {
    let oldData = dataSource;
    let arr_data_list = [];

    for (const i in oldData) {
      if (Object.hasOwnProperty.call(oldData, i)) {
        if (oldData[i].vin_no.trim()  !== item.vin_no.trim()) {
          arr_data_list.push(oldData[i]);
        }
      }
    }
    if (arr_data_list.length === 0) {
      setparam_mode('VIEW');
    }

    setDataSource(arr_data_list);
  }

  // condition
  //for data source
  useEffect(() => {
    if (
      storedDataSourceForModeCreateNewCalling.length === 0 ||
      param_mode === 'CREATE_NEW_CALLING_FIRST'
    ) {
      dispatch(
        setManageCallingNoStatusMode({
          param_from: 'MANAGE_CALLING',
        })
      );
    }
    if (
      storedDataSourceForModeCreateNewCalling.length > 0 &&
      param_mode === 'CREATE_NEW_CALLING' && param_mode
    ) {
      setDataSource(storedDataSourceForModeCreateNewCalling);
    }
  }, [param_mode, storedDataSourceForModeCreateNewCalling, dispatch]);

  useEffect(() => {
    if (storedDataSourceForModeEditVin && param_mode === 'EDIT_VIN') {
      setDataSource(storedDataSourceForModeEditVin);
    }
  }, [param_mode, storedDataSourceForModeEditVin]);

  useEffect(() => {
    if (storedDataSourceForModeAddVin && param_mode === 'ADD_VIN') {
      setDataSource(storedDataSourceForModeAddVin);
    }
  }, [param_mode, storedDataSourceForModeAddVin]);

  useEffect(() => {
    setTotalRows(dataSource.length);

    if (dataSource?.length !== 0) {
      setTotalUnit(dataSource?.length);
      setCheckoutDataSource(true);

      dispatch(setManageCallingDataSourceForModeEditVin(dataSource));
      dispatch(setManageCallingDataSourceForModeAddVin(dataSource));
    } else {
      setCheckoutDataSource(false);
    }
  }, [dataSource, dispatch]);

  useEffect(() => {
    if (filterFrom) {
      setparam_filterfrom(filterFrom);
    }
  }, [filterFrom]);

  useEffect(() => {
    dispatch(
      setManageCallingNoStatusMode({
        param_mode,
        param_calling_no,
        param_from,
        param_filterfrom,
      })
    );
  }, [param_mode, param_calling_no, param_from, param_filterfrom, dispatch]);

  useEffect(() => {
    dispatch(
      setManageCallingFilter({
        filterCallingno,
        filterCallingId: callingIdRef.current,
        filterFrom,
        filterCluster,
      })
    );
  }, [filterCallingno, filterFrom, filterCluster, dispatch]);

  useEffect(() => {
    dispatch(setManageCallingDataParkingLane(filterOptionParking));
  }, [filterOptionParking, dispatch]);

  useEffect(() => {
    if (filterCallingno === '' && param_mode !== 'CREATE_NEW_CALLING') {
      setDataSource([]);
      setFilterCluster();
    }
  }, [dispatch, filterCallingno, param_mode]);

  useEffect(() => {
    if (calling !== 'no_calling' && param_mode === 'VIEW') {
      setFilterCallingno(calling);

      let payload = {
        calling_no: calling ? calling : '',
      };
      onSearchCallingNo(payload);
    }
  }, [calling, onSearchCallingNo, param_mode]);

  useEffect(() => {
    if (
      param_mode === 'CREATE_NEW_CALLING' &&
      dataSource.length === 0 &&
      param_from !== 'MANAGE_CALLING' || 
      param_mode === 'CREATE_NEW_CALLING_FIRST' &&
      dataSource.length === 0 &&
      param_from !== 'MANAGE_CALLING'
    ) {
      setparam_mode('VIEW');
    }
  }, [param_mode, dataSource, param_from]);

  useEffect(() => {
    if (
      param_mode === 'VIEW' &&
      param_calling_no === 'NO' &&
      param_filterfrom === 'NO' &&
      param_from === 'MENU'
    ) {
      let listempty = [];
      dispatch(setManageCallingDataSourceForModeCreateNewCalling(listempty));
      dispatch(setManageCallingDataSourceForModeEditVin(listempty));
      dispatch(setManageCallingDataSourceForModeAddVin(listempty));
    }
  }, [param_mode, param_calling_no, param_filterfrom, param_from, dispatch]);

  useEffect(() => {
    if (totalRows) {
      dispatch(setManageCallingTotalRows(totalRows));
    }
  }, [totalRows, dispatch]);

  return (
    <Container>
      <StyledPageHeader
        ghost={false}
        onBack={() => dispatch(goBack())}
        title='Manage Calling'
        extra={
          totalRows != null && <Title level={5}>{`Total ${totalRows}`}</Title>
        }
      />
      <Content style={{ padding: '0px' }}>
        <Toolbar
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          loadingParkingLane={loadingParkingLane}
          filterFrom={filterFrom}
          filterOptionFrom={filterOptionFrom}
          setFilterFrom={setFilterFrom}
          loadingSearchCallingNo={loadingSearchCallingNo}
          loadingLocationFrom={loadingLocationFrom}
          onSearchCallingNo={onSearchCallingNo}
          filterCallingno={filterCallingno}
          setFilterCallingno={setFilterCallingno}
          dataSource={dataSource}
          setDataSource={setDataSource}
          showNotFoundCallingNo={showNotFoundCallingNo}
          filterCluster={filterCluster}
          setFilterCluster={setFilterCluster}
          checkoutDataSource={checkoutDataSource}
          setCheckoutDataSource={setCheckoutDataSource}
          param_mode={param_mode}
          setparam_mode={setparam_mode}
          param_calling_no={param_calling_no}
          setparam_calling_no={setparam_calling_no}
          param_from={param_from}
          setparam_from={setparam_from}
          param_filterfrom={param_filterfrom}
          setparam_filterfrom={setparam_filterfrom}
          getDataPrintCalling={getDataPrintCalling}
          getLocationData={getLocationData}
          showModalSave={showModalSave}
          handleCancelSave={handleCancelSave}
          loadingCreateManageCalling={loadingCreateManageCalling}
          onClearAllDataSource={onClearAllDataSource}
          totalRows={totalRows}
          setTotalRows={setTotalRows}
          checkCallingTrDv={checkCallingTrDv}
          isLoadingCheckCalling={isLoadingCheckCalling}
        />
        <Table
          loadingSearchCallingNo={loadingSearchCallingNo}
          loadingCreateManageCalling={loadingCreateManageCalling}
          visibleModalLoading={visibleModalLoading}
          setVisibleModalLoading={setVisibleModalLoading}
          filterCallingno={filterCallingno}
          setFilterCallingno={setFilterCallingno}
          dataSource={dataSource}
          setDataSource={setDataSource}
          filterCluster={filterCluster}
          setFilterCluster={setFilterCluster}
          totalUnit={totalUnit}
          setTotalUnit={setTotalUnit}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
          filterOptionParking={filterOptionParking}
          setFilterOptionParking={setFilterOptionParking}
          checkoutDataSource={checkoutDataSource}
          setCheckoutDataSource={setCheckoutDataSource}
          param_mode={param_mode}
          setparam_mode={setparam_mode}
          param_calling_no={param_calling_no}
          setparam_calling_no={setparam_calling_no}
          param_from={param_from}
          setparam_from={setparam_from}
          param_filterfrom={param_filterfrom}
          setparam_filterfrom={setparam_filterfrom}
          onCancelCalling={onCancelCalling}
          onDeleteVinlist={onDeleteVinlist}
          tableHeight={tableHeight}
          setTableHeight={setTableHeight}
        />

        <PrintDN
          componentRef={printDNComponentRef}
          dnDataList={dnListPrintData}
        ></PrintDN>

        <PrintCalling
          componentRef={printCallingComponentRef}
          callingDataList={callingListPrintData}
        ></PrintCalling>

        <Modal
          title='Would you like to confirm for saving calling no.?'
          visible={isModalVisibleSave}
          onOk={handleOkSave}
          onCancel={handleCancelSave}
        >
          <Row
            gutter={20}
            justify='center'
            align='center'
            style={{ marginLeft: '30px', marginRight: '30px' }}
          >
            <Col span={10} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <p>Calling No. </p>
              <p>From </p>
              <p>Cluster </p>
              <p>Total unit </p>
            </Col>

            <Col span={14} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <p> : {filterCallingno ? filterCallingno : '-'} </p>
              <p>
                {' '}
                :{' '}
                {locationFromLabelRef.current
                  ? locationFromLabelRef.current
                  : '-'}
              </p>
              <p>
                {' '}
                :{' '}
                {(() => {
                  let clusters = dataSource.map((item) => item.cluster);
                  if (clusters.length > 0) {
                    let unique_clusters = [...new Set(clusters)];
                    return unique_clusters.join(', ');
                  } else {
                    return '-';
                  }
                })()}
              </p>
              <p> : {totalUnit ? totalUnit : '-'}</p>
            </Col>
          </Row>
        </Modal>

        <Modal
          title='Would you like to confirm for saving new calling no.?'
          visible={isModalVisibleSaveNew}
          onOk={handleOkSaveNew}
          onCancel={handleCancelSaveNew}
        >
          <Row
            gutter={20}
            justify='center'
            align='center'
            style={{ marginLeft: '30px', marginRight: '30px' }}
          >
            <Col span={10} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <p>From </p>

              <p>Total unit </p>
            </Col>

            <Col span={14} style={{ paddingTop: '0px', paddingBottom: '0px' }}>
              <p>
                {' '}
                :{' '}
                {locationFromLabelRef.current
                  ? locationFromLabelRef.current
                  : '-'}
              </p>

              <p> : {totalUnit ? totalUnit : '-'}</p>
            </Col>
          </Row>
        </Modal>

        <Modal
          visible={visibleModalLoading}
          onOk={handleOkLoading}
          onCancel={handleCancelLoading}
          okButtonProps={false}
          maskClosable={false}
          closable={false}
          footer={[
            <Button
              key='submit'
              type='primary'
              loading={visibleModalLoading}
              onClick={handleOkLoading}
            >
              Ok
            </Button>,
          ]}
        >
          <p>Please wait in progress...</p>
        </Modal>
      </Content>
    </Container>
  );
};

const StyledPageHeader = styled(PageHeader)`
  padding: 0px 10px;
`;

const Container = styled.div`
  height: calc(100%);
  padding: 10px;
`;

const Content = styled.div`
  background: white;
  height: calc(100% - 42px);
  overflow: auto;
`;

export default ManageCallingComponent;
