import React, { useState, useEffect, useCallback } from 'react';
import AntTable from 'common_components/AntTable';
import EditButtonRenderer from 'common_components/EditButtonRenderer';
import TableToolbar from 'common_components/AntTable/Toolbar';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import { useDeleteDriverMessagesMutation } from 'reducers/driverMessages/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import styled from 'styled-components';
import { selectTableStateById } from 'reducers/table';
import { message, Image, Button } from 'antd';
import ReactAudioPlayer from 'react-audio-player';

const DriverMessagesTableComponent = (props) => {
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [visible, setVisible] = useState(false);
  const tableIdSelector = useSelector((state) =>
    selectTableStateById(state, 'driver_messages')
  );

  const [showDelete, setShowDelete] = useState(false);
  const [url, setUrl] = useState(null);

  const dispatch = useDispatch();
  const { setErrorData } = useErrorHandler();
  const onClickImage = (url) => {
    setUrl(url);
    setVisible(true);
  };
  const [columns, setColumns] = useState(() => {
    let cols = [
      {
        title: '',
        dataIndex: 'edit',
        align: 'center',
        render: (text, record) => {
          return (
            <EditButtonRenderer data={record} onClick={handleEditClicked} />
          );
        },
        width: 50,
      },
      {
        title: 'Category',
        dataIndex: 'category',
        width: 150,
        movable: true,
        searchable: true,
      },
      {
        title: 'Criteria',
        dataIndex: 'criteria',
        width: 150,
        movable: true,
        searchable: true,
      },
      {
        title: 'Text',
        dataIndex: 'text',
        width: 350,
        movable: true,
        searchable: true,
      },
      {
        title: 'Audio',
        dataIndex: 'audio_url',
        width: 100,
        align: 'center',
        movable: true,
        render: (text, record) => {
          return (
            <>
              {text ? (
                  <ReactAudioPlayer
                  src={text}
                  controls
                  controlsList={'nodownload'}
                />
              ) : (
                ''
              )}
            </>
          );
        },
      },
    ];

    // Add Show/Hide status from Redux store
    if (tableIdSelector) {
      cols = cols.map((c) => ({
        ...c,
        ...tableIdSelector.find((t) => t.dataIndex === c.dataIndex),
      }));
    }

    return cols;
  });

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  const [
    deleteDriverMessages,
    {
      isSuccess: isSuccessDeleting,
      isUninitialized: isUninitDeleting,
      error: errorDeleting,
      isLoading: isDeleting,
    },
  ] = useDeleteDriverMessagesMutation();

  useEffect(() => {
    if (errorDeleting) {
      setErrorData(errorDeleting);
    }
  }, [errorDeleting, setErrorData]);

  useEffect(() => {
    if (!isUninitDeleting && isSuccessDeleting) {
      message.success('Data was successfully deleted');
      clearSelectedRows();
    }
  }, [isSuccessDeleting, isUninitDeleting]);

  const onSearch = useCallback(
    (searchTerm) => {
      if (rowData && rowData.length > 0) {
        let searchCols = columns.filter((c) => c.searchable);
        if (searchCols.length > 0 && searchTerm) {
          let filterData = rowData.filter((d) =>
            searchCols.some(
              (s) =>
                d[s.dataIndex] &&
                d[s.dataIndex]
                  .toString()
                  .toLowerCase()
                  .includes(searchTerm.toLowerCase())
            )
          );
          setDataSource(filterData);
        } else {
          setDataSource(rowData);
        }
      }
      clearSelectedRows();
    },
    [columns, rowData]
  );

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/master/driver_messages/${cell.id}`));
  };

  const onDelete = () => {
    // TODO: Bulk delete
    for (let row of selectedRows) {
      deleteDriverMessages({ id: row.id });
    }
    clearSelectedRows();
  };

  const onReload = () => {
    props.refetchData();
    clearSelectedRows();
  };

  const clearSelectedRows = () => {
    setSelectedRows([]);
    setShowDelete(false);
  };

  return (
    <Container>
      <TableToolbar
        onDelete={onDelete}
        onReload={onReload}
        showDelete={showDelete}
        showReload={true}
        isLoading={isLoading}
        isDeleting={isDeleting}
        showSearch={true}
        columns={columns}
        setColumns={setColumns}
        onSearch={onSearch}
      />
      <AntTable
        rowKey='id'
        tableId={'driver_messages'}
        bordered
        showSorterTooltip={false}
        loading={isLoading || isDeleting}
        columns={columns}
        dataSource={dataSource}
        scroll={{ x: 'max-content' }}
        // Below is for right click context menu
        showContextMenu={true}
        menuActions={[
          { label: 'Edit', action: (row) => handleEditClicked(row) },
        ]}
      />
      <Image
        width={200}
        style={{ display: 'none' }}
        src={url}
        preview={{
          visible,
          src: url,
          onVisibleChange: (value) => {
            setVisible(value);
          },
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;

export default DriverMessagesTableComponent;
