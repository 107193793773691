import React, { useEffect, useState, useRef } from 'react';
import BaseMap from 'digitalmap_components/Map/BaseMap';
import CarMarker from '../Map/CarMarker';
import TrackingPositionBox from './TrackingPositionBox';
import StartMarker from './StartMarker';
import OrderMarker from './OrderMarker';
import { useDispatch } from 'react-redux';
import { SET_MARKER_ORIGIN_DESC } from 'reducers/map';
import { mapSetBounds } from 'reducers/map';
import digitalMapUtils from '../Utils';
const LocationMasterMap = (props) => {
  const { gpsData, panelData } = props;
  const mapRef = useRef(null);
  const [focusVehicle, setFocusVehicle] = useState(true);
  const [markerColor, setMarkerColor] = useState('green');
  const dispatch = useDispatch();

  useEffect(() => {
    if (gpsData) {
      let waypoints = {
        lat: null,
        lng: null,
      };

      [
        panelData.traffic_polyline.legs[
          panelData && panelData.traffic_polyline.legs.length - 1
        ],
      ].forEach((r, index) => {
        if (r.to.lat && r.to.lng) {
          waypoints = {
            lat: r.to.lat,
            lng: r.to.lng,
          };
        }
      });

      let location_trailer = {
        lat: gpsData.latitude,
        lng: gpsData.longitude,
      };
      dispatch(
        SET_MARKER_ORIGIN_DESC({
          marker_origin: location_trailer,
          marker_desc: waypoints,
        })
      );

      let bounds = digitalMapUtils.setOdBounds(location_trailer, waypoints);
      setTimeout(() => {
        dispatch(mapSetBounds(bounds));
      }, 250);
    }
  }, [dispatch, gpsData, panelData]);

  useEffect(() => {
    if (mapRef.current && gpsData && focusVehicle) {
      mapRef.current.panTo({
        lat: gpsData.latitude,
        lng: gpsData.longitude,
      });
    }
  }, [gpsData, focusVehicle]);

  useEffect(() => {
    if (panelData && panelData.eva) {
      switch (panelData.eva) {
        case 'delay':
          setMarkerColor('red');
          break;
        case 'warning':
          setMarkerColor('yellow');
          break;
        default:
          setMarkerColor('green');
          break;
      }
    } else {
      setMarkerColor('grey');
    }
  }, [panelData]);

  useEffect(() => {
    if (mapRef.current && gpsData && focusVehicle) {
      mapRef.current.panTo({
        lat: gpsData.latitude,
        lng: gpsData.longitude,
      });
    }
  }, [gpsData, focusVehicle, panelData]);

  return (
    <BaseMap
      {...props}
      onDragEnd={() => {
        setFocusVehicle(false);
      }}
      onLoad={(map) => {
        mapRef.current = map;
      }}
    >
      <TrackingPositionBox focus={focusVehicle} setFocus={setFocusVehicle} />
      {gpsData && <CarMarker {...props.gpsData} color={markerColor} />}

      {panelData && panelData.traffic_polyline && (
        <StartMarker location={panelData && panelData} />
      )}

      {panelData &&
        [
          panelData.traffic_polyline.legs[
            panelData && panelData.traffic_polyline.legs.length - 1
          ],
        ].map((m, index) => (
          <OrderMarker
            key={index}
            location={{ lat: m.to.lat, lng: m.to.lng }}
            locationTo={panelData && panelData}
            infoLocation={m.to.truck_schedule_id}
          />
        ))}
    </BaseMap>
  );
};

export default LocationMasterMap;
