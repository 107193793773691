import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';

const EvaStatusRenderer = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const status = data.eva;
  const onClick = () => {
    dispatch(
      push(
        `/app/digitalmap/monitoring_jobs/truck_schedules/${data.truck_schedule_id}/orders/${data.order_id}`
      )
    );
  };

  if (status === 'warning') {
    if (data.vehicle_status > 700 && data.vehicle_status !== 730) {
      return <OrangeDot style={{ cursor: 'default' }} />;
    } else {
      return <OrangeDot onClick={onClick} />;
    }
  } else if (status === 'delay') {
    if (data.vehicle_status > 700 && data.vehicle_status !== 730) {
      return <RedDot style={{ cursor: 'default' }} />;
    } else {
      return <RedDot onClick={onClick} />;
    }
  } else if (status === 'normal') {
    if (data.vehicle_status > 700 && data.vehicle_status !== 730) {
      return <GreenDot style={{ cursor: 'default' }} />;
    } else {
      return <GreenDot onClick={onClick} />;
    }
  } else {
    return '';
  }
};
const GreenDot = styled.div`
  height: 18px;
  width: 18px;
  margin: auto;
  background-color: green;
  border-radius: 50%;
  border: 2px solid #ececec;
  &:hover {
    border: 1px solid #ececec;
  }
  cursor: pointer;
`;
const OrangeDot = styled.div`
  height: 18px;
  width: 18px;
  margin: auto;
  background-color: #ffa701;
  border-radius: 50%;
  border: 2px solid #ececec;
  &:hover {
    border: 1px solid #ececec;
  }
  cursor: pointer;
`;
const RedDot = styled.div`
  height: 18px;
  width: 18px;
  margin: auto;
  background-color: red;
  border-radius: 50%;
  border: 2px solid #ececec;
  &:hover {
    border: 1px solid #ececec;
  }
  cursor: pointer;
`;
export default EvaStatusRenderer;
