import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
  dataParkingLane: [],
  callingNoById: '',
  statusMode: null,

  dataSourceForModeCreateNewCalling: [],
  dataSourceForModeEditVin: [],
  dataSourceForModeAddVin: [],

  paramData: null,
};
export const manageCallingSlice = createSlice({
  name: 'manage_calling',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setManageCallingTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setManageCallingDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setManageCallingDataSourceForModeCreateNewCalling: (state, { payload }) => {
      state.dataSourceForModeCreateNewCalling = payload;
    },
    setManageCallingDataSourceForModeEditVin: (state, { payload }) => {
      state.dataSourceForModeEditVin = payload;
    },
    setManageCallingDataSourceForModeAddVin: (state, { payload }) => {
      state.dataSourceForModeAddVin = payload;
    },
    setManageCallingDataParkingLane: (state, { payload }) => {
      state.dataParkingLane = payload;
    },
    setManageCallingNoById: (state, { payload }) => {
      state.callingNoById = payload;
    },
    setManageCallingNoStatusMode: (state, { payload }) => {
      state.statusMode = {
        ...state.statusMode,
        ...payload,
      };
    },
    setParamData: (state, { payload }) => {
      state.paramData = payload;
    },
    setManageCallingFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
  },
});

export const {
  setManageCallingFilter,
  setCurrentPage,
  setCurrentOffset,
  setManageCallingDataSource,
  setManageCallingTotalRows,
  setManageCallingNoById,
  setManageCallingNoStatusMode,
  setParamData,
  setManageCallingDataParkingLane,
  setManageCallingDataSourceForModeCreateNewCalling,
  setManageCallingDataSourceForModeEditVin,
  setManageCallingDataSourceForModeAddVin,
} = manageCallingSlice.actions;

export const manageCallingSelector = (state) => state.manageCalling;

export default manageCallingSlice.reducer;
