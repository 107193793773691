import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from 'react';
import AntTable from 'common_components/AntTable';
import { Button, Select, Popover, Typography } from 'antd';
import { dateTimeTimestamptzFormatter } from 'common_components/AntTable/tableUtils';
import styled from 'styled-components';
import useWindowSize from 'hooks/windowSize';
const { Text } = Typography;
const { Option } = Select;

const TableComponent = (props) => {
  const { rowData, selectedLane, radioShuttle, editReason, isLoadingParkingLane } = props;
  const radioShuttleRef = useRef('shuttle_in');
  const { height } = useWindowSize();
  const [dataSource, setDataSource] = useState([]);
  const [columns, setColumns] = useState([]);
  const [tableHeight, setTableHeight] = useState(0);

  const columnsPopup = useMemo(() => {
    return [
      {
        title: 'Calling no.',
        dataIndex: 'no',
        width: 150,
        align: 'center',
        render: (text, record) => {
          return (
            <>
              {record.combine ? (
                <>
                  <Text type='danger'>* </Text> {text}
                </>
              ) : (
                text
              )}
            </>
          );
        },
      },
    ];
  }, []);

  const contentPopup = useCallback(
    (value) => {
      let resData = [];

      try {
        if (value && value.calling_no && value.calling_no.calling_no_combine) {
          resData =
            value &&
            value.calling_no &&
            value.calling_no.calling_no_combine.map((d, i) => ({
              no: d,
            }));
        }
        return (
          <>
            <AntTable
              rowKey='id'
              tableId={'calling_no_combine'}
              showSorterTooltip={false}
              columns={columnsPopup}
              dataSource={resData}
              pagination={false}
            />
          </>
        );
      } catch (err) {
        console.log('err', err);
      }
    },
    [columnsPopup]
  );

  const showModal = useCallback(
    (value) => {
      editReason(value);
    },
    [editReason]
  );

  let columnsAll = useMemo(() => {
    return [
      {
        title: 'Calling No.',
        dataIndex: ['calling_no', 'callingsheet_no'],
        align: 'center',
        width: 120,
        render(text, record) {
          if (record.calling_no && record.calling_no.combine) {
            return (
              <Popover placement='topLeft' content={contentPopup(record)}>
                <Text type='danger'>* </Text> {text}
              </Popover>
            );
          } else {
            return text;
          }
        },
      },
      {
        title: 'Combine',
        dataIndex: ['calling_no', 'combine'],
        align: 'center',
        width: 120,
        render: (text, record) => {
          return record.combine ? record.combine : text;
        },
      },
      {
        title: () => {
          return radioShuttleRef.current &&
            radioShuttleRef.current === 'shuttle_in'
            ? 'Shuttle In'
            : 'Shuttle';
        },
        children: [
          {
            title: () => {
              return radioShuttleRef.current &&
                radioShuttleRef.current === 'shuttle_in'
                ? 'Shuttle'
                : 'Shuttle In Time';
            },
            dataIndex: 'plan_arrival_time',
            align: 'center',
            width: 120,
            render: (text, record) => {
              return dateTimeTimestamptzFormatter({
                value: text,
              });
            },
          },
          {
            title: () => {
              return radioShuttleRef.current &&
                radioShuttleRef.current === 'shuttle_in'
                ? 'Actual'
                : 'Shuttle Out Time';
            },
            dataIndex: 'actual_shullte_In_datetime',
            width: 120,
            align: 'center',
            render: (text, record) => {
              return dateTimeTimestamptzFormatter({ value: text });
            },
          },
          {
            title: 'Different (HH:MM)',
            dataIndex: 'diff',
            width: 100,
            align: 'center',
          },
        ],
      },

      {
        title: 'Reason',
        dataIndex: ['reason', 'label'],
        width: 100,
        align: 'center',
        ellipsis: true,
        render: (text, record) => {
          return (
            <>
              {text === 'delay' ? (
                <Button
                  size='small'
                  style={{
                    backgroundColor: '#ffccc7',
                    width: '60px',
                    top: 1,
                  }}
                  danger
                  onClick={() => showModal(record)}
                >
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Button>
              ) : text === '-' ? (
                text
              ) : (
                <Button
                  shape='round'
                  size='small'
                  style={{
                    fontSize: 12,
                    whiteSpace: 'normal',
                    height: 'auto',
                    margin: '5px',
                  }}
                  onClick={() => showModal(record)}
                >
                  {text}
                </Button>
              )}
            </>
          );
        },
      },
      {
        title: 'Trailer lane',
        dataIndex: 'trailerLaneOptions',
        align: 'center',
        width: 100,
        movable: true,
        ellipsis: true,
        render: (text, record) => {
          return radioShuttleRef.current &&
            radioShuttleRef.current === 'shuttle_in' ? (
            record.reason && record.reason.label !== 'delay' ? (
              <Select
                style={{
                  width: '100px',
                }}
                showSearch
                placeholder={'select'}
                optionFilterProp='children'
                onSelect={(e) => {
                  selectedLane(e);
                }}
              >
                {text &&
                  text.map((c, index) => (
                    <Option key={index} value={c.id}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            ) : (
              ''
            )
          ) : (
            record?.lane_no
          );
        },
      },
    ];
  }, [contentPopup, selectedLane, showModal]);

  useEffect(() => {
    if (radioShuttle) {
      radioShuttleRef.current = radioShuttle;
      if (radioShuttle === 'shuttle_in') {
        setColumns(columnsAll);
      } else {
        let filteredArr = columnsAll.filter((obj) => obj.title !== 'Reason');
        setColumns(filteredArr);
      }
    }
  }, [columnsAll, radioShuttle]);

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  useEffect(() => {
    if (height) {
      let h = height - 420;
      if (h % 2 !== 0) {
        h = h - 1;
      }
      setTableHeight(h);
    }
  }, [height]);

  return (
    <Container>
      <StyledTable
        bordered
        rowKey='id'
        loading={isLoadingParkingLane}
        tableId={'shullte_In'}
        showSorterTooltip={false}
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        height={tableHeight}
        scroll={{
          y: tableHeight,
          scrollToFirstRowOnChange: true,
        }}
      />
    </Container>
  );
};

const Container = styled.div`
  padding: 0 10px;
`;
const StyledTable = styled(AntTable)`
  &&& {
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tbody
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > tfoot
      > tr
      > td,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > tfoot
      > tr
      > td {
      border-right: 1px solid #cfcbcb;
    }
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-content
      > table
      > thead
      > tr:not(:last-child)
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-header
      > table
      > thead
      > tr:not(:last-child)
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-body
      > table
      > thead
      > tr:not(:last-child)
      > th,
    .ant-table.ant-table-bordered
      > .ant-table-container
      > .ant-table-summary
      > table
      > thead
      > tr:not(:last-child)
      > th {
      border-bottom: 1px solid #cfcbcb;
    }
  }
`;

export default TableComponent;
