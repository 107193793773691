import React, { useRef } from 'react';
import { Circle, InfoWindow } from '@react-google-maps/api';
import { connect } from 'react-redux';
import { mapSelectedCircle, mapChangeCircle } from 'reducers/map';

const ImCircle = (props) => {
  const circleRef = useRef(null);

  const selectInfo = (arg) => {
    props.CIRCLE_SELECTING(props.id);
  };

  const onCenterChanged = () => {
    if (circleRef && circleRef.current) {
      const center = circleRef.current.getCenter();
      const radius = circleRef.current.getRadius();
      let obj = {
        ...props,
        radius: radius,
        center: { lat: center.lat(), lng: center.lng() },
      };
      // Prevent recursive call after udating circle
      if (
        props.center.lat !== obj.center.lat ||
        props.center.lng !== obj.center.lng ||
        props.radius !== obj.radius
      ) {
        props.CIRCLE_CHANGE(obj);
      }
    }
  };

  const id = props.id;
  let selected = false;

  if (props.circle_selecting === id) {
    selected = true;
  }

  return (
    <Circle
      key={props.id}
      id={props.id}
      center={props.center}
      editable={props.editable}
      color='blue'
      radius={props.radius}
      onClick={selectInfo}
      onCenterChanged={onCenterChanged}
      onRadiusChanged={onCenterChanged}
      onLoad={(input) => (circleRef.current = input)}
    >
      {selected ? (
        <InfoWindow>
          <div>
            {props.info.logisticsPoint
              ? 'Logistic Point : ' + props.info.logisticsPoint
              : ''}
            <br />
            Name: <br />
            {props.info.name}
            Description: <br />
            {props.info.description}
            <br />
            {props.isGroup}
          </div>
        </InfoWindow>
      ) : null}
    </Circle>
  );
};

const mapStateToProps = (state) => {
  return {
    circle_selecting: state.map.circle_selecting,
  };
};

const mapActionsToProps = {
  CIRCLE_SELECTING: mapSelectedCircle,
  CIRCLE_CHANGE: mapChangeCircle,
};
export default connect(mapStateToProps, mapActionsToProps)(ImCircle);
