import React from 'react';
import RouteMaster from '../Map/RouteMaster';
import BaseMap from 'digitalmap_components/Map/BaseMap';

const RouteMasterMap = (props) => {
  return (
    <BaseMap {...props}>
      <RouteMaster />
    </BaseMap>
  );
};

export default RouteMasterMap;
