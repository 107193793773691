const removeDuplicatesList = (list, key) => {
  if(list){
    return Object.values(
      list.reduce((r, v) => {
        r[v[key]] = {
          ...v,
        };
        return r;
      }, {})
    );
  }
};


export const RemoveDuplicates = {
  LISTKEY: removeDuplicatesList,
};
