import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import {
  Button,
  Space,
  Select,
  Typography,
  Row,
  message,
  Col,
  Input,
} from 'antd';
import styled from 'styled-components';

import { useErrorHandler } from 'common_components/ErrorContext';
import { useDispatch } from 'react-redux';
import { setConfirmDriverTrailerFilter } from 'reducers/dispatchBoard/ConfirmDriverTrailer';
import { confirmDriverTrailerApi } from 'reducers/dispatchBoard/ConfirmDriverTrailer/api';
import { SearchOutlined } from '@ant-design/icons';
const { Option } = Select;
const createPayload = ({
  filterExperience,
  filterTlepType,
  filterDriverId,
  bzpId,
  unit,
  cluster_Id,
}) => {
  return {
    experience: filterExperience,
    tlep_type: filterTlepType,
    driver_id: filterDriverId,
    bzp_id: bzpId,
    loading_unit: unit,
    actual_cluster_to_id: cluster_Id,
  };
};

const TableToolbar = (props) => {
  const {
    bzp,
    isLoading,
    onInit,
    onQuery,
    selectedRowsDriver1,
    selectedRowsDriver2,
    clearTable,
    loadingUnit,
    clusterId,
  } = props;
  const dispatch = useDispatch();

  const clusterIdRef = useRef();
  const { setErrorData } = useErrorHandler();
  const [bzpId, setBzpId] = useState(bzp);
  const [unit, setUnit] = useState(loadingUnit);
  const [cluster_Id, setCluster_Id] = useState(clusterId);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [tlepTypeOptions, setTlepTypeOptions] = useState([]);
  const [driverIdOptions, setDriverIdOptions] = useState([]);
  const [isLoadingFilter, setIsLoadingFilter] = useState(false);
  const [filterExperience, setfilterExperience] = useState(['All']);
  const [filterTlepType, setfilterTlepType] = useState(['All']);
  const [filterDriverId, setfilterDriverId] = useState(['All']);
  const defaultFilter = useMemo(() => {
    return {
      filterExperience: ['All'],
      filterDriverId: ['All'],
      filterTlepType: ['All'],
      bzpId: bzp,
      unit: loadingUnit,
      actual_cluster_to: clusterId,
    };
  }, [bzp, loadingUnit, clusterId]);

  const resetToDefault = useCallback(() => {
    setfilterExperience(defaultFilter.filterExperience);
    setfilterTlepType(defaultFilter.filterTlepType);
    setfilterDriverId(defaultFilter.filterDriverId);
    setBzpId(defaultFilter.bzpId);
    setUnit(defaultFilter.unit);
    setCluster_Id(defaultFilter.cluster_Id);
    onDisplayButton();
  }, [
    defaultFilter.bzpId,
    defaultFilter.filterDriverId,
    defaultFilter.filterExperience,
    defaultFilter.filterTlepType,
    defaultFilter.unit,
    defaultFilter.cluster_Id,
  ]);

  const onQueryOption = useCallback(
    async (payload) => {
      let payloadNew = {
        ...payload,
        actual_cluster_to_id: clusterIdRef.current,
      };
      try {
        setIsLoadingFilter(true);
        let result = await dispatch(
          confirmDriverTrailerApi.endpoints.findAllDriversBySelection.initiate(
            payloadNew,
            {
              forceRefetch: true,
            }
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let arrExperiences = [
              {
                label: 'All',
                value: 'All',
              },
            ];

            let arrTlepTypes = [
              {
                label: 'All',
                value: 'All',
              },
            ];
            let arrDrivers = [
              {
                label: 'All',
                value: 'All',
              },
            ];
            let experiences = result.data.experiences.map((d) => ({
              label: d.experience,
              value: d.experience,
            }));
            let tlepTypes = result.data.tlep_types.map((d) => ({
              label: d.tlep_type,
              value: d.value,
            }));
            let drivers = result.data.drivers.map((d) => ({
              label: d.full_name_th,
              value: d.id,
            }));

            arrExperiences.push(...experiences);
            arrTlepTypes.push(...tlepTypes);
            arrDrivers.push(...drivers);

            setExperienceOptions(arrExperiences);
            setTlepTypeOptions(arrTlepTypes);
            setDriverIdOptions(arrDrivers);
          }
        }
      } catch (err) {
        message.error('Something went wrong.');
      }
      setIsLoadingFilter(false);
    },
    [dispatch, setErrorData]
  );

  useEffect(() => {
    if (clearTable) {
      resetToDefault();
    }
  }, [clearTable, resetToDefault]);

  useEffect(() => {
    if (clusterId) {
      clusterIdRef.current = clusterId;
    }
  }, [clusterId]);

  useEffect(() => {
    if (bzp && loadingUnit && clusterId) {
      setBzpId(bzp);
      setUnit(loadingUnit);
      setCluster_Id(clusterId);
      if (onInit) {
        onQueryOption(createPayload(defaultFilter));
        onQuery(createPayload(defaultFilter));
      }
    }
  }, [bzp, loadingUnit, clusterId, onInit]);

  useEffect(() => {
    dispatch(
      setConfirmDriverTrailerFilter({
        filterExperience,
        filterDriverId,
        filterTlepType,
        experienceOptions,
        tlepTypeOptions,
        driverIdOptions,
        bzpId,
        unit,
        cluster_Id,
      })
    );
  }, [
    filterExperience,
    filterDriverId,
    filterTlepType,
    experienceOptions,
    tlepTypeOptions,
    driverIdOptions,
    bzpId,
    dispatch,
    unit,
    cluster_Id,
  ]);

  const onDisplayButton = () => {
    if (onQuery) {
      onQuery({
        ...createPayload({
          filterExperience,
          filterDriverId,
          filterTlepType,
          bzpId,
          unit,
          cluster_Id,
        }),
      });

      onQueryOption({
        ...createPayload({
          filterExperience,
          filterDriverId,
          filterTlepType,
          bzpId,
          unit,
          cluster_Id,
        }),
      });
    }
  };

  const handleEnterDriver = (event) => {
    if (event.keyCode === 13) {
      onDisplayButton();
    }
  };

  return (
    <Container>
      <Space style={{ padding: 10 }}>
        <Space size={16}>
          <Space direction='vertical'>
            <Row gutter={[12]}>
              <Col>
                <Space direction='horizontal'>
                  <StyledText>Driver 1</StyledText>
                  <Input
                    value={selectedRowsDriver1}
                    style={{
                      width: '180px',
                      color: '#000',
                      backgroundColor: 'transparent',
                    }}
                    disabled
                  />
                </Space>
              </Col>
              <Col>
                <Space direction='horizontal'>
                  <StyledText>Driver 2</StyledText>
                  <Input
                    value={selectedRowsDriver2}
                    style={{
                      width: '180px',
                      color: '#000',
                      backgroundColor: 'transparent',
                    }}
                    disabled
                  />
                </Space>
              </Col>
            </Row>

            <Row gutter={[12]}>
              <Col>
                <Space direction='horizontal'>
                  <StyledText>Experience</StyledText>
                  <Select
                    style={{
                      width: '162px',
                    }}
                    allowClear={true}
                    maxTagCount='responsive'
                    placeholder={'Experience'}
                    loading={isLoadingFilter}
                    value={filterExperience}
                    onChange={(values) => {
                      setfilterExperience(values);
                    }}
                    showSearch
                    optionFilterProp='children'
                    filterOption={(input, option) => {
                      return option.children
                        .toLowerCase()
                        .includes(input.toLowerCase());
                    }}
                    onKeyDown={(e) => {
                      handleEnterDriver(e);
                    }}
                  >
                    {experienceOptions &&
                      experienceOptions.map((c, index) => (
                        <Option key={index} value={c.value}>
                          {c.label}
                        </Option>
                      ))}
                  </Select>
                </Space>
              </Col>
              <Col>
                <Space direction='horizontal'>
                  <>
                    <StyledText>TLEP Type</StyledText>
                    <Select
                      style={{
                        width: '164px',
                      }}
                      allowClear={true}
                      maxTagCount='responsive'
                      loading={isLoadingFilter}
                      placeholder={'TLEP Type'}
                      value={filterTlepType}
                      onChange={(values) => {
                        setfilterTlepType(values);
                      }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onKeyDown={(e) => {
                        handleEnterDriver(e);
                      }}
                    >
                      {tlepTypeOptions &&
                        tlepTypeOptions.map((c, index) => (
                          <Option key={index} value={c.value}>
                            {c.label}
                          </Option>
                        ))}
                    </Select>
                  </>
                </Space>
              </Col>
            </Row>
            <Row>
              <Space size={88}>
                <Col>
                  <Space direction='horizontal'>
                    <StyledText>Driver</StyledText>
                    <Select
                      style={{
                        width: '240px',
                      }}
                      allowClear={true}
                      maxTagCount='responsive'
                      placeholder={'Driver'}
                      loading={isLoadingFilter}
                      value={filterDriverId}
                      onChange={(values) => {
                        setfilterDriverId(values);
                      }}
                      showSearch
                      optionFilterProp='children'
                      filterOption={(input, option) => {
                        return option.children
                          .toLowerCase()
                          .includes(input.toLowerCase());
                      }}
                      onKeyDown={(e) => {
                        handleEnterDriver(e);
                      }}
                    >
                      {driverIdOptions &&
                        driverIdOptions.map((c, index) => (
                          <Option key={index} value={c.value}>
                            {c.label}
                          </Option>
                        ))}
                    </Select>
                  </Space>
                </Col>
                <Col>
                  <Space direction='vertical'>
                    <>
                      <Button
                        type='primary'
                        style={{ marginLeft: 10 }}
                        loading={isLoading}
                        onClick={() => onDisplayButton()}
                        icon={<SearchOutlined />}
                      >
                        Search
                      </Button>
                    </>
                  </Space>
                </Col>
              </Space>
            </Row>
          </Space>
        </Space>
      </Space>
    </Container>
  );
};

const Container = styled.div`
  @media screen and (max-width: 1366px) {
    font-size: 11px;
  }
  padding: 10px 0;
`;

const StyledText = styled(Typography)`
  font-size: 12px;
  font-weight: 500;
`;
export default TableToolbar;
