import Foo from 'dms_components/Foo';
import ClusterList from 'dms_components/MasterData/Cluster/List';
import ClusterDetails from 'dms_components/MasterData/Cluster/Details';
import YardList from 'dms_components/MasterData/Yard/List';
import YardDetails from 'dms_components/MasterData/Yard/Details';
import DealerList from 'dms_components/MasterData/Dealer/List';
import DealerDetails from 'dms_components/MasterData/Dealer/Details';
import DriverList from 'dms_components/MasterData/Driver/List';
import DriverDetails from 'dms_components/MasterData/Driver/Details';
import TrailerList from 'dms_components/MasterData/Trailer/List';
import TrailerDetails from 'dms_components/MasterData/Trailer/Details';
// import TrailerKindList from 'dms_components/MasterData/TrailerKind/List';
// import TrailerKindDetails from 'dms_components/MasterData/TrailerKind/Details';
import TrailerTypeList from 'dms_components/MasterData/TrailerType/List';
import TrailerTypeDetails from 'dms_components/MasterData/TrailerType/Details';
import TrailerLaneList from 'dms_components/MasterData/TrailerLane/List';
import TrailerLaneDetails from 'dms_components/MasterData/TrailerLane/Details';
import RegionList from 'dms_components/MasterData/Region/List';
import RegionDetails from 'dms_components/MasterData/Region/Details';
import BzpList from 'dms_components/MasterData/Bzp/List';
import BzpDetails from 'dms_components/MasterData/Bzp/Details';
import BorderList from 'dms_components/MasterData/Border/List';
import BorderDetails from 'dms_components/MasterData/Border/Details';
import PortList from 'dms_components/MasterData/Port/List';
import PortDetails from 'dms_components/MasterData/Port/Details';
import LocationMasterList from 'dms_components/MasterData/LocationMaster/List';
import LocationMasterDetails from 'dms_components/MasterData/LocationMaster/Details';
import ReasonMasterList from 'dms_components/MasterData/ReasonMaster/List';
import SubjectReasonDetails from 'dms_components/MasterData/ReasonMaster/SubjectReason/Details';
import GroupReasonDetails from 'dms_components/MasterData/ReasonMaster/GroupReason/Details';
import OriginalETAMasterList from 'dms_components/MasterData/OriginalETAMaster/List';
import OriginalETAMasterDetails from 'dms_components/MasterData/OriginalETAMaster/Details';
import ParkingLaneList from 'dms_components/MasterData/ParkingLane/List';
import ParkingLaneDetails from 'dms_components/MasterData/ParkingLane/Details';

import LanesList from 'dms_components/MasterData/Lanes/List';
import LanesDetails from 'dms_components/MasterData/Lanes/Details';

import VehicleTypeList from 'dms_components/MasterData/VehicleType/List';
import VehicleTypeDetails from 'dms_components/MasterData/VehicleType/Details';
import VehicleTypeChangeList from 'dms_components/MasterData/VehicleTypeChange/List';
import VehicleTypeChangeDetails from 'dms_components/MasterData/VehicleTypeChange/Details';
import DriverMessagesMasterList from 'dms_components/DriverMessages/List';
import DriverMessagesMasterDetails from 'dms_components/DriverMessages/Details';
import Criteria from 'dms_components/Criteria/List';

const masterDataRoutes = [
  {
    path: '/app/settings',
    name: 'Settings',
    Component: Foo,
  },
  {
    path: '/app/master/drivers',
    name: 'Driver',
    Component: DriverList,
  },
  {
    path: '/app/master/drivers/create',
    name: 'Create Driver',
    Component: DriverDetails,
  },
  {
    path: '/app/master/drivers/:id',
    name: 'Driver Detail',
    Component: DriverDetails,
  },
  {
    path: '/app/master/trailers',
    name: 'Trailer',
    Component: TrailerList,
  },
  {
    path: '/app/master/trailers/create',
    name: 'Create Trailer',
    Component: TrailerDetails,
  },
  // {
  //   path: '/app/master/trailers/trailer_kinds',
  //   name: 'Trailer Kind',
  //   Component: TrailerKindList,
  // },
  // {
  //   path: '/app/master/trailers/trailer_kinds/create',
  //   name: 'Create Trailer Kind',
  //   Component: TrailerKindDetails,
  // },
  // {
  //   path: '/app/master/trailers/trailer_kinds/:id',
  //   name: 'Create Trailer Kind',
  //   Component: TrailerKindDetails,
  // },

  {
    path: '/app/master/trailer_lanes',
    name: 'Trailer Lane',
    Component: TrailerLaneList,
  },

  {
    path: '/app/master/trailers/trailer_lanes/create',
    name: 'Create Trailer Lane',
    Component: TrailerLaneDetails,
  },
  {
    path: '/app/master/trailers/trailer_lanes/:id',
    name: 'Trailer Lene Detail',
    Component: TrailerLaneDetails,
  },
  {
    path: '/app/master/trailers/trailer_types',
    name: 'Trailer Types',
    Component: TrailerTypeList,
  },
  {
    path: '/app/master/trailers/trailer_types/create',
    name: 'Create Trailer Type',
    Component: TrailerTypeDetails,
  },
  {
    path: '/app/master/trailers/trailer_types/:id',
    name: 'Trailer Type Detail',
    Component: TrailerTypeDetails,
  },
  {
    path: '/app/master/trailers/:id',
    name: 'Trailer Detail',
    Component: TrailerDetails,
  },
  {
    path: '/app/master/parking_lane',
    name: 'Parking Lane',
    Component: ParkingLaneList,
  },
  {
    path: '/app/master/parking_detail/create',
    name: 'Parking Lane Detail',
    Component: ParkingLaneDetails,
  },
  {
    path: '/app/master/parking_detail/:id',
    name: 'Parking Lane Detail',
    Component: ParkingLaneDetails,
  },

  {
    path: '/app/master/lanes',
    name: 'Lanes List',
    Component: LanesList,
  },
  {
    path: '/app/master/lanes_detail/create',
    name: 'Lane Detail',
    Component: LanesDetails,
  },
  {
    path: '/app/master/lanes_detail/:id',
    name: 'Lane Detail',
    Component: LanesDetails,
  },

  {
    path: '/app/master/vehicle_type',
    name: 'Vehicle Type',
    Component: VehicleTypeList,
  },
  {
    path: '/app/master/vehicle_type/create',
    name: 'Vehicle Type Detail',
    Component: VehicleTypeDetails,
  },

  {
    path: '/app/master/vehicle_type_detail/:id',
    name: 'Vehicle Type Detail',
    Component: VehicleTypeDetails,
  },

  {
    path: '/app/master/vehicle_change',
    name: 'Vehicle Change',
    Component: VehicleTypeChangeList,
  },
  {
    path: '/app/master/vehicle_change/create',
    name: 'Vehicle Change Detail',
    Component: VehicleTypeChangeDetails,
  },
  {
    path: '/app/master/vehicle_change/:id',
    name: 'Vehicle Change Detail',
    Component: VehicleTypeChangeDetails,
  },
  {
    path: '/app/master/clusters',
    name: 'Cluster',
    Component: ClusterList,
  },
  {
    path: '/app/master/clusters/create',
    name: 'Cluster Detail',
    Component: ClusterDetails,
  },
  {
    path: '/app/master/clusters/:id',
    name: 'Cluster Detail',
    Component: ClusterDetails,
  },
  {
    path: '/app/master/location_masters',
    name: 'Location Master',
    Component: LocationMasterList,
  },
  {
    path: '/app/master/location_masters/create',
    name: 'Location Master Details',
    Component: LocationMasterDetails,
  },
  {
    path: '/app/master/location_masters/:id',
    name: 'Location Master Details',
    Component: LocationMasterDetails,
  },
  {
    path: '/app/master/yards',
    name: 'Yard',
    Component: YardList,
  },
  {
    path: '/app/master/yards/create',
    name: 'Yard Detail',
    Component: YardDetails,
  },
  {
    path: '/app/master/yards/:id',
    name: 'Yard Detail',
    Component: YardDetails,
  },
  {
    path: '/app/master/dealers',
    name: 'Dealer',
    Component: DealerList,
  },
  {
    path: '/app/master/dealers/create',
    name: 'Dealer Detail',
    Component: DealerDetails,
  },
  {
    path: '/app/master/dealers/:id',
    name: 'Dealer Detail',
    Component: DealerDetails,
  },
  {
    path: '/app/master/borders',
    name: 'Border',
    Component: BorderList,
  },
  {
    path: '/app/master/borders/create',
    name: 'Border Detail',
    Component: BorderDetails,
  },
  {
    path: '/app/master/borders/:id',
    name: 'Border Detail',
    Component: BorderDetails,
  },
  {
    path: '/app/master/ports',
    name: 'Port',
    Component: PortList,
  },
  {
    path: '/app/master/ports/create',
    name: 'Port Detail',
    Component: PortDetails,
  },
  {
    path: '/app/master/ports/:id',
    name: 'Port Detail',
    Component: PortDetails,
  },
  {
    path: '/app/master/regions',
    name: 'Region',
    Component: RegionList,
  },
  {
    path: '/app/master/regions/create',
    name: 'Region Detail',
    Component: RegionDetails,
  },
  {
    path: '/app/master/regions/:id',
    name: 'Region Detail',
    Component: RegionDetails,
  },
  {
    path: '/app/master/bzps',
    name: 'BZP',
    Component: BzpList,
  },
  {
    path: '/app/master/bzps/create',
    name: 'BZP Detail',
    Component: BzpDetails,
  },
  {
    path: '/app/master/bzps/:id',
    name: 'BZP Detail',
    Component: BzpDetails,
  },
  {
    path: '/app/master/reason_masters',
    name: 'Reason Master',
    Component: ReasonMasterList,
  },
  {
    path: '/app/master/subject_reasons/create',
    name: 'Subject Reasons Detail',
    Component: SubjectReasonDetails,
  },
  {
    path: '/app/master/subject_reasons/:id',
    name: 'Subject Reasons Detail',
    Component: SubjectReasonDetails,
  },
  {
    path: '/app/master/group_reasons/create',
    name: 'Groups Reasons Detail',
    Component: GroupReasonDetails,
  },
  {
    path: '/app/master/group_reasons/:id',
    name: 'Groups Reasons Detail',
    Component: GroupReasonDetails,
  },
  {
    path: '/app/master/original_eta_master',
    name: 'Original ETA Master',
    Component: OriginalETAMasterList,
  },
  {
    path: '/app/master/original_eta_master/create',
    name: 'Original ETA Master Detail',
    Component: OriginalETAMasterDetails,
  },
  {
    path: '/app/master/original_eta_master/:id',
    name: 'Original ETA Master Detail',
    Component: OriginalETAMasterDetails,
  },
  {
    path: '/app/master/driver_messages',
    name: 'Driver Confirm Receive Job Master',
    Component: DriverMessagesMasterList,
  },
  {
    path: '/app/master/driver_messages/create',
    name: 'Create Driver Confirm Receive Job Master',
    Component: DriverMessagesMasterDetails,
  },
  {
    path: '/app/master/driver_messages/:id',
    name: 'Driver Confirm Receive Job Master Detail',
    Component: DriverMessagesMasterDetails,
  },
  {
    path: '/app/delivery_mng/vlo_setting',
    name: 'VLO Setting',
    Component: Criteria,
  },
];

export default masterDataRoutes;
