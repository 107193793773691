import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  filters: null,
  currentPage: 1,
  currentOffset: 0,
  totalRows: null,
  dataSource: [],
  searchTerm: '',
  searchOrigin: '',
  searchDestination: '',
};

export const routeGroupSlice = createSlice({
  name: 'route_group',
  initialState,
  reducers: {
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    setCurrentOffset: (state, { payload }) => {
      state.currentOffset = payload;
    },
    setRouteGroupTotalRows: (state, { payload }) => {
      state.totalRows = payload;
    },
    setRouteGroupDataSource: (state, { payload }) => {
      state.dataSource = payload;
    },
    setSearchFilters: (state, { payload }) => {
      if (payload) {
        state.searchTerm = payload.searchTerm;
        state.searchOrigin = payload.searchOrigin;
        state.searchDestination = payload.searchDestination;
      }
    },
    setSearchTerm: (state, { payload }) => {
      state.searchTerm = payload;
    },
    setSearchOrigin: (state, { payload }) => {
      state.searchOrigin = payload;
    },
    setSearchDestination: (state, { payload }) => {
      state.searchDestination = payload;
    },
    setRouteGroupFilter: (state, { payload }) => {
      state.filters = {
        ...state.filters,
        ...payload,
      };
    },
    clearSearch: (state) => {
      state.searchTerm = '';
      state.searchOrigin = '';
      state.searchDestination = '';
    },
  },
});

export const {
  setRouteGroupFilter,
  setCurrentPage,
  setCurrentOffset,
  setRouteGroupDataSource,
  setRouteGroupTotalRows,
  setSearchFilters,
  setSearchTerm,
  setSearchOrigin,
  setSearchDestination,
  clearSearch,
} = routeGroupSlice.actions;

export const routeGroupSelector = (state) => state.routeGroup;

export default routeGroupSlice.reducer;
