import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { mapClearCircle, mapClearMarker, mapClearPolygon } from 'reducers/map';
import { DeleteTwoTone } from '@ant-design/icons';
const DrawingDeleteBox = () => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        background: `transparent`,
        padding: 5,
        position: 'absolute',
        right: '10px',
        top: '60px',
      }}
    >
      <Button
        shape='circle'
        size='large'
        onClick={() => {
          dispatch(mapClearCircle());
          dispatch(mapClearMarker());
          dispatch(mapClearPolygon());
        }}
        icon={<DeleteTwoTone twoToneColor='#eb2f96' />}
      />
    </div>
  );
};

export default DrawingDeleteBox;
