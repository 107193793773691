import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Button,
  Select,
  Typography,
  Collapse,
  Divider,
  DatePicker,
} from 'antd';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setRecommendTripFilter } from 'reducers/recommendTrip';
import moment from 'moment';
const { Option } = Select;
const { Panel } = Collapse;

const TableToolbar = (props) => {
  const {
    isLoading,
    isLoadingMatch,
    onSearchList,
    filterOptionCompany,
    filterOptionFrom,
    filterOptionCluster,
    filterOptionTrailerType,
    filterOptionDiagramType,
    isLoadingMasterData,
    filterTrailerSageProp,
    filteBestReceiveJobTimeProp,
    onMatch,
    filterCllingUnitProp,
    selectedRowKeys,
    diagramType,
  } = props;
  const dispatch = useDispatch();
  const { from, cluster } = useParams();
  const [showFilter, setShowFilter] = useState(true);
  const storedFilters = useSelector((state) => {
    return state.recommendTrip.filters;
  });
  console.log('storedFilters', storedFilters);
  //parameter
  const [filterCompany, setFilterCompany] = useState(
    storedFilters && storedFilters.filterCompany
      ? storedFilters.filterCompany
      : []
  );

  const [filterFrom, setFilterFrom] = useState(
    from
      ? [from]
      : storedFilters && storedFilters.filterFrom
      ? storedFilters.filterFrom
      : []
  );

  const [filterCluster, setFilterCluster] = useState(
    cluster
      ? [cluster]
      : storedFilters && storedFilters.filterCluster
      ? storedFilters.filterCluster
      : []
  );

  const [filterTrailerType, setFilterTrailerType] = useState(
    storedFilters && storedFilters.filterTrailerType
      ? storedFilters.filterTrailerType
      : []
  );
  const [filterDiagramType, setFilterDiagramType] = useState(
    diagramType
      ? diagramType
      : storedFilters && storedFilters.filterDiagramType
      ? storedFilters.filterDiagramType
      : []
  );
  const [filterCombine, setFilterCombine] = useState(
    storedFilters && storedFilters.filterCombine
      ? storedFilters.filterCombine
      : ['All']
  );
  const [filterReceiveJobTime, setFilterReceiveJobTime] = useState(
    storedFilters && storedFilters.filterReceiveJobTime
      ? storedFilters.filterReceiveJobTime
      : null
  );
  
  useEffect(() => {
    setFilterDiagramType(diagramType);
  }, [diagramType]);

  const createPayload = ({
    filterCompany,
    filterFrom,
    filterCluster,
    filterTrailerType,
    filterDiagramType,
    filterCombine,
    filterReceiveJobTime,
  }) => {
    return {
      company: filterCompany,
      location_from: filterFrom,
      cluster: filterCluster,
      trailer_type: filterTrailerType,
      diagram_type: filterDiagramType,
      combine: filterCombine ? filterCombine : 'All',
      receive_job_time: filterReceiveJobTime
        ? filterReceiveJobTime.format('DD/MM/YYYY HH:mm')
        : null,
      trailer_usage: filterTrailerSageProp,
      best_receive_job_time: filteBestReceiveJobTimeProp
        ? moment(filteBestReceiveJobTimeProp).format()
        : null,
      calling_unit: filterCllingUnitProp,
    };
  };

  // save data redux
  useEffect(() => {
    dispatch(
      setRecommendTripFilter({
        filterCompany,
        filterFrom,
        filterCluster,
        filterTrailerType,
        filterDiagramType,
        filterCombine,
        filterReceiveJobTime,
      })
    );
  }, [
    filterCompany,
    filterFrom,
    filterCluster,
    filterTrailerType,
    filterDiagramType,
    filterCombine,
    filterReceiveJobTime,
    dispatch,
  ]);

  const onSearch = () => {
    onSearchList({
      ...createPayload({
        filterCompany,
        filterFrom,
        filterCluster,
        filterTrailerType,
        filterDiagramType,
        filterCombine,
        filterReceiveJobTime,
      }),
    });
  };

  const selectSingleProps = {
    showSearch: true,
    style: {
      textAlign: 'center',
      width: '100%',
    },
    allowClear: true,
    maxTagCount: 'responsive',
    loading: isLoadingMasterData,
  };
  const btnMatch = () => {
    onMatch();
  };

  // const handleConfirmAction = () => {
  //   onMatch();
  // };

  return (
    <Container>
      <StyledCollapse
        defaultActiveKey={1}
        onChange={() => {
          setShowFilter(!showFilter);
        }}
      >
        <Panel header='Search & Filter' key={1}>
          <Row
            gutter={[24]}
            style={{ marginTop: '10px', marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='end'>
              <Typography>Company</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='multiple'
                size='small'
                {...selectSingleProps}
                value={filterCompany}
                onChange={(values) => {
                  setFilterCompany(values);
                }}
                placeholder={'Company'}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterOptionCompany &&
                  filterOptionCompany.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Typography>Receive job time</Typography>
            </Col>
            <Col span={4}>
              <DatePicker
                style={{
                  textAlign: 'center',
                  width: '100%',
                }}
                size='small'
                showTime
                value={filterReceiveJobTime}
                format={'DD/MM/YY HH:mm'}
                onChange={(value) => {
                  setFilterReceiveJobTime(value);
                }}
              />
            </Col>
            <Col span={3} align='end'>
              <Typography>Trailer type</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='multiple'
                size='small'
                {...selectSingleProps}
                value={filterTrailerType}
                onChange={(values) => {
                  setFilterTrailerType(values);
                }}
                placeholder={'Trailer type'}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterOptionTrailerType &&
                  filterOptionTrailerType.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={4} align='end'>
              <Button
                style={{ width: '80%', textAlign: 'center' }}
                type='primary'
                onClick={onSearch}
                loading={isLoading}
              >
                Search
              </Button>
            </Col>
          </Row>

          <Row
            gutter={[24]}
            style={{ marginTop: '10px', marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={2} align='end'>
              <Typography>From</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='multiple'
                size='small'
                {...selectSingleProps}
                value={filterFrom}
                onChange={(values) => {
                  setFilterFrom(values);
                }}
                placeholder={'From'}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterOptionFrom &&
                  filterOptionFrom.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Typography>Cluster</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='multiple'
                size='small'
                {...selectSingleProps}
                value={filterCluster}
                onChange={(values) => {
                  setFilterCluster(values);
                }}
                placeholder={'Cluster'}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterOptionCluster &&
                  filterOptionCluster.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>
            <Col span={3} align='end'>
              <Typography>Diagram Type</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='multiple'
                size='small'
                {...selectSingleProps}
                value={filterDiagramType}
                onChange={(values) => {
                  setFilterDiagramType(values);
                }}
                placeholder={'Diagram type'}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                {filterOptionDiagramType &&
                  filterOptionDiagramType.map((c, index) => (
                    <Option key={index} value={c.value}>
                      {c.label}
                    </Option>
                  ))}
              </Select>
            </Col>

            <Col span={4} align='end'>
              <Button
                disabled={!selectedRowKeys.length}
                style={{ width: '80%', textAlign: 'center' }}
                type='primary'
                onClick={() => btnMatch()}
                loading={isLoadingMatch}
              >
                Match
              </Button>
            </Col>
          </Row>

          <Row
            gutter={[24]}
            style={{ marginBottom: '10px' }}
            align='middle'
            justify='center'
          >
            <Col span={9}></Col>
            <Col span={3} align='end'>
              <Typography>Combine</Typography>
            </Col>
            <Col span={4}>
              <Select
                mode='single'
                size='small'
                {...selectSingleProps}
                value={filterCombine}
                onChange={(values) => {
                  setFilterCombine(values);
                }}
                placeholder={'Combine'}
                optionFilterProp='children'
                filterOption={(input, option) => {
                  return option.children
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
              >
                <Option value='All'>All</Option>
                <Option value='Have'>Have</Option>
                <Option value='Not have'>Not have</Option>
              </Select>
            </Col>
          </Row>

          <Row></Row>

          <Divider style={{ margin: '0px' }}></Divider>
        </Panel>
      </StyledCollapse>
    </Container>
  );
};

const StyledCollapse = styled(Collapse)`
  &&& {
    .ant-collapse-header {
      padding: 2px 10px;
    }
    .ant-collapse-content-box {
      padding: 10px;
    }
    .ant-picker-input > input {
      font-size: 13px;
      // padding: unset
      padding: 1px 0px;
      text-align: center;
    }
  }
`;
const Container = styled.div`
  // padding: 5px 0;
  padding-bottom: 10px;
  font-size: 13px;
`;
export default TableToolbar;
