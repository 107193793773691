import React, { useEffect, useState } from 'react';
import { Transfer, Modal, Table, Form, Collapse } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import difference from 'lodash/difference';
import { useFindAllBzpsQuery } from 'reducers/masterData/bzp/api';
const { Panel } = Collapse;

// Customize Table Transfer
const TableTransfer = ({ leftColumns, rightColumns, ...restProps }) => (
  <Transfer {...restProps} rowKey={(record) => record.code}>
    {({
      direction,
      filteredItems,
      onItemSelectAll,
      onItemSelect,
      selectedKeys: listSelectedKeys,
      disabled: listDisabled,
    }) => {
      const columns = direction === 'left' ? leftColumns : rightColumns;

      const rowSelection = {
        getCheckboxProps: (item) => ({
          disabled: listDisabled || item.disabled,
        }),
        onSelectAll(selected, selectedRows) {
          const treeSelectedKeys = selectedRows
            .filter((item) => !item.disabled)
            .map(({ code }) => code);
          const diffKeys = selected
            ? difference(treeSelectedKeys, listSelectedKeys)
            : difference(listSelectedKeys, treeSelectedKeys);
          onItemSelectAll(diffKeys, selected);
        },
        onSelect({ code }, selected) {
          onItemSelect(code, selected);
        },
        selectedRowKeys: listSelectedKeys,
      };

      return (
        <Table
          rowKey='code'
          loading={restProps.loading}
          rowSelection={rowSelection}
          columns={columns}
          dataSource={filteredItems}
          size='small'
          style={{ pointerEvents: listDisabled ? 'none' : null }}
          onRow={({ code, disabled: itemDisabled }) => ({
            onClick: () => {
              if (itemDisabled || listDisabled) return;
              onItemSelect(code, !listSelectedKeys.includes(code));
            },
          })}
        />
      );
    }}
  </Transfer>
);

const BzpFormComponent = (props) => {
  const { bzp_watchlist, setSelectedBzps } = props;
  const [targetKeys, setTargetKeys] = useState(bzp_watchlist?.actions || []);
  const [dataSource, setDataSource] = useState([]);
  const Columns = [
    {
      title: 'Code',
      dataIndex: 'code',
      width: 80,
    },
    {
      title: 'Name',
      dataIndex: 'job_name',
      width: 150,
    },
  ];

  // Query all BZP.
  const {
    data: dataBzps,
    error: errorBzps,
    isFetching: isBzpsFetching,
    refetch: refetchBzps,
  } = useFindAllBzpsQuery({
    ordering: 'job_name',
    location_type__code: '005',
    display: 'bzp',
    exclude_job_name:'',
  });

  useEffect(() => {
    if (dataBzps) {
      setDataSource(dataBzps);
    }
  }, [dataBzps]);

  useEffect(() => {
    if (errorBzps) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load BZPs',
        okText: 'Retry',
        onOk: refetchBzps,
        cancelText: 'Cancel',
      });
    }
  }, [errorBzps, refetchBzps]);

  const onChange = (nextTargetKeys) => {
    setTargetKeys(nextTargetKeys);
    if (setSelectedBzps) {
      setSelectedBzps(nextTargetKeys);
    }
  };

  return (
    <Form.Item label='BZP Watchlist'>
      <Collapse defaultActiveKey={['1']}>
        <Panel
          key='1'
          header={`Selected BZPs ${
            targetKeys.length > 0 ? `(${targetKeys.length})` : ''
          }`}
        >
          <TableTransfer
            loading={isBzpsFetching}
            dataSource={dataSource}
            targetKeys={targetKeys}
            titles={['Source', 'Selected']}
            disabled={false}
            showSearch={true}
            onChange={onChange}
            filterOption={(inputValue, item) =>
              item.code?.toLowerCase().indexOf(inputValue.toLowerCase()) !==
                -1 ||
              item.job_name?.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
            }
            leftColumns={Columns}
            rightColumns={Columns}
          />
        </Panel>
      </Collapse>
    </Form.Item>
  );
};
export default BzpFormComponent;
