import React, { useState, useEffect } from 'react';
import AntTable from 'common_components/AntTable';

import {
  dateFormatter,
  timeFormatter,
} from 'common_components/AntTable/tableUtils';
import { push } from 'connected-react-router';

import { useDispatch } from 'react-redux';

import { Sorter } from 'utils/sorter';

const CallingSheetTableComponent = (props) => {
  const { rowData, isLoading } = props;
  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const dispatch = useDispatch();

  const [columns] = useState(() => {
    let cols = [
      {
        title: 'Vin no.',
        dataIndex: 'vin_no',
        width: 100,
        align: 'center',
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: 'Region',
        dataIndex: 'region',
        render: (text, record) => {
          if (text) {
            return text.name;
          } else {
            return '-';
          }
        },
        align: 'center',
        width: 100,
        sorter: {
          compare: Sorter.DEFAULT,
          field: 'name',
        },
      },
      {
        title: 'Cluster',
        dataIndex: 'cluster',
        align: 'center',
        width: 100,
        render: (text, record) => {
          if (text) {
            return text.name;
          } else {
            return '-';
          }
        },

        sorter: {
          compare: Sorter.DEFAULT,
          field: 'name',
        },
      },
      {
        title: 'Destination',
        dataIndex: 'destination',
        align: 'center',
        width: 100,
        render: (text, record) => {
          if (text) {
            return text.name;
          } else {
            return '-';
          }
        },
        sorter: {
          compare: Sorter.DEFAULT,
          field: 'name',
        },
      },
      {
        title: 'Date',
        dataIndex: 'updated_at',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return dateFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: 'Time',
        dataIndex: 'updated_at',
        align: 'center',
        width: 100,
        render: (text, record) => {
          return timeFormatter({ value: text });
        },
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
      {
        title: 'Lane',
        dataIndex: 'lane',
        render: (text, record) => {
          if (text && text.length > 0) {
            return text;
          } else {
            return '-';
          }
        },
        width: 100,
        align: 'center',
        sorter: {
          compare: Sorter.DEFAULT,
        },
      },
    ];

    return cols;
  });

  useEffect(() => {
    setDataSource(rowData);
  }, [rowData]);

  const handleEditClicked = (cell, readOnly) => {
    dispatch(push(`/app/delivery_mng/callingsheets/${cell.id}`));
  };

  return (
    <AntTable
      rowKey='id'
      tableId={'callingsheet'}
      bordered
      showSorterTooltip={false}
      loading={isLoading}
      columns={columns}
      dataSource={dataSource}
      scroll={{ x: 'max-content' }}
      // Below is for right click context menu
      showContextMenu={true}
      selectedRowKeys={selectedRowKeys}
      setSelectedRowKeys={setSelectedRowKeys}
      menuActions={[{ label: 'Edit', action: (row) => handleEditClicked(row) }]}
    />
  );
};

export default CallingSheetTableComponent;
