import React, { Component } from 'react';
import { connect } from 'react-redux';

//Component
import MarkerPlace from './MarkerPlace';

//Reducer
import {
  mapCenterChanged,
  mapZoomChanged,
  mapSetMarkerPlace,
  mapSelectedMarkerSearch,
  CLEAR_SELECTING,
} from 'reducers/map';
import { StandaloneSearchBox } from '@react-google-maps/api';
class SearchPlace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      placename: true,
    };
  }

  onPlacesChanged = () => {
    const places = this.searchbox.getPlaces();
    const bounds = new window.google.maps.LatLngBounds();

    if (places.length > 0) {
      let name = '';
      places.forEach((place) => {
        if (place.geometry.viewport) {
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
        name = place.name;
      });
      const nextMarkers = places.map((place) => ({
        position: place.geometry.location,
      }));
      if (nextMarkers) {
        this.props.mapSetMarkerPlace({
          ...this.props.markerPlace,
          name: name,
          position: nextMarkers[0].position,
        });
        this.props.SET_CENTER(nextMarkers[0].position);
        this.props.SET_ZOOM(15);
        this.props.CLEAR_SELECTING();
        this.props.mapSelectedMarkerSearch('place_id');
      }
    }
  };
  render() {
    return (
      <React.Fragment>
        <StandaloneSearchBox
          onLoad={(input) => (this.searchbox = input)}
          controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
          onPlacesChanged={this.onPlacesChanged}
        >
          <input
            type='text'
            placeholder='Search Places'
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `192px`,
              height: `32px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              position: 'absolute',
              left: '0%',
              marginTop: '60px',
              marginLeft: '10px',
            }}
          />
        </StandaloneSearchBox>
        {this.props.markerPlace && (
          <MarkerPlace
            key='place_id'
            id='place_id'
            name={this.props.markerPlace.name}
            position={this.props.markerPlace.position}
          />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    bounds: state.map.bounds,
    markerPlace: state.map.markerPlace,
  };
};

const mapActionsToProps = {
  SET_CENTER: mapCenterChanged,
  SET_ZOOM: mapZoomChanged,
  mapSetMarkerPlace,
  mapSelectedMarkerSearch,
  CLEAR_SELECTING,
};

export default connect(mapStateToProps, mapActionsToProps)(SearchPlace);
