import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Select } from 'antd';
import { getLanguage } from 'reducers/language';
import { useTranslation } from 'react-i18next';
import {
  useFindReceiveJobReasonMasterByModuleQuery,
  receivejobApi,
} from 'reducers/receivejob/api';
import { useErrorHandler } from 'common_components/ErrorContext';
import { ExclamationCircleOutlined, WarningTwoTone } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from 'reducers/user';
import AntModal from 'common_components/AntModal';

const { Option } = Select;
const { TextArea } = Input;

const ReasonMasterFormComponent = (props) => {
  const dispatch = useDispatch();
  const language = useSelector(getLanguage);
  const { t } = useTranslation();
  const { formData, showModal, statusDiff } = props;
  const [form] = Form.useForm();
  const { setErrorData } = useErrorHandler();
  const user = useSelector(getCurrentUser);
  const [reasonsSubject, setReasonsSubject] = useState([]);
  const [reasonsGroup, setReasonsGroup] = useState([]);
  const [other, setOther] = useState(false);
  const [isReason, setIsReason] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    data: dataReasons,
    error: errorReasons,
    isFetching: isReasonsFetching,
    refetch: refetchReasons,
  } = useFindReceiveJobReasonMasterByModuleQuery('Receive Job', {
    skip: !user,
  });
  useEffect(() => {
    if (dataReasons) {
      let dups = [];
      let arr = dataReasons.filter(function (el) {
        if (dups.indexOf(el.subject_reason.group.code) === -1) {
          dups.push(el.subject_reason.group.code);
          return true;
        }
        return false;
      });

      setReasonsGroup(
        arr.map((d) => ({
          id: d.subject_reason.group.id,
          label: `(${d.subject_reason.group.code}) ${d.subject_reason.group.name}`,
        }))
      );
    }
  }, [dataReasons]);

  useEffect(() => {
    if (showModal) {
      checkModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);

  useEffect(() => {
    if (errorReasons) {
      Modal.confirm({
        title: 'Confirm',
        icon: <ExclamationCircleOutlined />,
        content: 'Failed to load Reasons',
        okText: 'Retry',
        onOk: refetchReasons,
        cancelText: 'Cancel',
      });
    }
  }, [errorReasons, refetchReasons]);

  const onGroupValues = async (id) => {
    let findGroup = reasonsGroup.find((c) => c.label === id);
    let postGroup = dataReasons.filter(
      (c) => c.subject_reason.group.id === findGroup.id
    );

    let dup = Object.values(
      postGroup.reduce((r, o) => {
        r[o.subject_reason.code] = {
          ...o,
        };
        return r;
      }, {})
    );

    if (language === 'th') {
      setReasonsSubject(
        dup.map((d) => ({
          id: d.id,
          idSub: d.subject_reason.id,
          label: `(${d.subject_reason.code}) ${d.subject_reason.name_th}`,
          is_other: d.subject_reason.is_other,
        }))
      );
    } else {
      setReasonsSubject(
        dup.map((d) => ({
          id: d.id,
          idSub: d.subject_reason.id,
          label: `(${d.subject_reason.code}) ${d.subject_reason.name_en}`,
          is_other: d.subject_reason.is_other,
        }))
      );
    }
    setOther(false);
    form.resetFields(['subject']);
    form.resetFields(['description']);
  };

  const showModalConfirm = () => {
    let validData = form.getFieldsValue(true);
    setIsModalVisible(false);
    Modal.confirm({
      title:
        language === 'th'
          ? `${t('reasonReceive.title')}${
              statusDiff === 'early'
                ? 'มาก่อนกำหนด'
                : statusDiff === 'delay'
                ? 'ล่าช้า'
                : ''
            }`
          : `${t('reasonReceive.title')} ${statusDiff}`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          {t('reasonDashboard.confirm.content')}
          <br />
          {t('reasonDashboard.confirm.group')} : {validData.group}
          <br />
          {t('reasonDashboard.confirm.subject')} : {validData.subject}
          <br />
          {t('reasonDashboard.confirm.description')} : {validData.description}
          <br />
        </>
      ),
      onOk: handleConfirmAction,
      onCancel: cancelModalConfirm,
      cancelText: 'Cancel',
      okText: 'Confirm',
      width: 500,
    });
  };

  const cancelModalConfirm = () => {
    setIsModalVisible(true);
    props.setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setOther(isReason && isReason.is_other);
    setIsModalVisible(false);
    props.setIsModalVisible();
  };

  const checkModal = async (value) => {
    try {
      if (value && formData && formData.group) {
        setIsModalVisible(true);
        setOther(formData && formData.description);
        form.setFieldsValue({
          group: formData && formData.group,
          subject: formData && formData.subject,
          description: formData && formData.description,
        });

        let result = await dispatch(
          receivejobApi.endpoints.findReceiveJobGroupReasonByCode.initiate(
            formData && formData.group.substring(1, 4)
          )
        );
        if (result && result.error) {
          setErrorData(result.error);
        } else {
          if (result.data) {
            let dup = Object.values(
              result.data.reduce((r, o) => {
                r[o.subject_reason.code] = {
                  ...o,
                };
                return r;
              }, {})
            );
            if (language === 'th') {
              setReasonsSubject(
                dup.map((d) => ({
                  id: d.id,
                  idSub: d.subject_reason.id,
                  label: `(${d.subject_reason.code}) ${d.subject_reason.name_th}`,
                  is_other: d.subject_reason.is_other,
                }))
              );
            } else {
              setReasonsSubject(
                dup.map((d) => ({
                  id: d.id,
                  idSub: d.subject_reason.id,
                  label: `(${d.subject_reason.code}) ${d.subject_reason.name_en}`,
                  is_other: d.subject_reason.is_other,
                }))
              );
            }
          }
        }
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
    setIsModalVisible(true);
  };

  const onFinish = async () => {
    try {
      await form.validateFields();
      showModalConfirm();
      setOther(isReason && isReason.is_other);
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const handleConfirmAction = () => {
    let validData = form.getFieldsValue(true);
    let postData = {
      id: isReason && isReason.id,
      ...validData,
    };
    setIsModalVisible(false);
    props.postReason(postData);
    props.setIsModalVisible(false);
    form.resetFields();
  };

  return (
    <>
      <Form
        form={form}
        name='basic'
        labelCol={{
          span: 5,
        }}
        wrapperCol={{
          span: 19,
        }}
        autoComplete='off'
      >
        <AntModal
          visible={isModalVisible}
          onCancel={handleCancel}
          onOk={onFinish}
          okText={'Save'}
          cancelText={'Cancel'}
          titleModal={
            <>
              <WarningTwoTone
                style={{ fontSize: 20, marginRight: 3 }}
                twoToneColor='red'
              />
              {language === 'th'
                ? `${t('reasonReceive.title')}${
                    statusDiff === 'early'
                      ? 'มาก่อนกำหนด'
                      : statusDiff === 'delay'
                      ? 'ล่าช้า'
                      : ''
                  }`
                : `${t('reasonReceive.title')} ${statusDiff}`}
            </>
          }
          centered
          width={700}
        >
          <Form.Item
            label={t('reasonDashboard.form.group.label')}
            name='group'
            rules={[
              {
                required: true,
                message: t('reasonDashboard.form.group.message'),
              },
            ]}
          >
            <Select
              onChange={(checkedGroupValues) => {
                onGroupValues(checkedGroupValues);
              }}
              showSearch
              placeholder={t('reasonDashboard.form.group.placeholder')}
              loading={isReasonsFetching}
              optionFilterProp='children'
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {reasonsGroup &&
                reasonsGroup.map((c, index) => (
                  <Option key={index} value={c.label}>
                    {c.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={t('reasonDashboard.form.subject.label')}
            name='subject'
            rules={[
              {
                required: true,
                message: t('reasonDashboard.form.subject.message'),
              },
            ]}
          >
            <Select
              onChange={(value) => {
                let findSubject = reasonsSubject.find((c) => c.label === value);
                setIsReason(findSubject);
                form.resetFields(['description']);
                setOther(findSubject && findSubject.is_other);
              }}
              showSearch
              placeholder={t('reasonDashboard.form.subject.placeholder')}
              optionFilterProp='children'
              loading={isReasonsFetching}
              disabled={!user || !form.getFieldValue('group')}
              filterOption={(input, option) => {
                return option.value.toLowerCase().includes(input.toLowerCase());
              }}
            >
              {reasonsSubject &&
                reasonsSubject.map((c, index) => (
                  <Option key={index} value={c.label}>
                    {c.label}
                  </Option>
                ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={t('reasonDashboard.form.description.label')}
            name='description'
            rules={[
              {
                required: other,
                message: t('reasonDashboard.form.description.message'),
              },
            ]}
          >
            <TextArea
              autoSize={{ minRows: 3, maxRows: 5 }}
              disabled={!other}
              placeholder={t('reasonDashboard.form.description.placeholder')}
            />
          </Form.Item>
        </AntModal>
      </Form>
    </>
  );
};

export default ReasonMasterFormComponent;
